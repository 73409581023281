import './EnveloppesBackToScan.css'

import { Col, Container, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { reactPlugin } from 'AppInsights'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { FaqGlobalItem } from 'core/models/Faq'
import useWindowSize from 'core/services/useWindowSize'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import EnveloppesBackToScanForm from '../components/EnveloppesBackToScanForm/EnveloppesBackToScanForm'

export interface EnveloppesBackToScanFormRhf {
    policyNumber?: number
    name: string
    documents: DroppedFile[]
}

const EnveloppesBackToScan = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const defaultValues: EnveloppesBackToScanFormRhf = {
        policyNumber: undefined,
        name: '',
        documents: []
    }

    const methods = useForm<EnveloppesBackToScanFormRhf>({
        defaultValues
    })

    // TEMPORARY: To hide "Need help" button
    const needHelpTranslationKey = 'ENVELOPPES.RESCAN_GOOD_TO_KNOW_NEED_HELP'

    return (
        <FullScreenContainer color="gray20">
            <Container className="position-relative">
                <Row className="enveloppes-submit-main">
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                        {!isMobile && (
                            <p
                                className="title c-black m-bottom-56"
                                data-testid="enveloppes-back-to-scan-title"
                            >
                                {t('ENVELOPPES.BACK_TO_SCAN_TITLE')}
                            </p>
                        )}
                        <FormProvider {...methods}>
                            <EnveloppesBackToScanForm />
                        </FormProvider>
                    </Col>
                    <Col
                        xs={{ span: 12, order: 1 }}
                        md={{ span: 4, order: 2 }}
                        xl={{ span: 4, offset: 1 }}
                    >
                        {isMobile && (
                            <p
                                className="title c-black m-bottom-56"
                                data-testid="enveloppes-back-to-scan-title"
                            >
                                {t('ENVELOPPES.BACK_TO_SCAN_TITLE')}
                            </p>
                        )}
                        <GoodToKnow
                            goodToKnowId="enveloppeRescan"
                            needHelpProps={{
                                query: {
                                    categoryId: 'Category_EnvoiJustificatifs',
                                    questionId: 'Question_TutorielRescan'
                                } as FaqGlobalItem,
                                ctaTranslationKey: needHelpTranslationKey
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </FullScreenContainer>
    )
}

export default withAITracking(
    reactPlugin,
    EnveloppesBackToScan,
    'EnveloppesBackToScan',
    'enveloppes-back-to-scan-container'
)
