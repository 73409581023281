import { EcStatus } from 'core/enums/FamilyGroup'

import { createSelector } from '@reduxjs/toolkit'

import { getSummaryCurrentYear } from './getSummaryCurrentYear'

export const getHasEcAccount = createSelector(
    [getSummaryCurrentYear],
    (summary) => {
        return summary.ecStatus === EcStatus.EC_PLUS || summary.ecStatus === EcStatus.EC
    }
)
