export enum MaritalStatusEnum {
    single = 'single',
    married = 'married',
    divorced = 'divorced',
    widower = 'widower',
    separated = 'separated',
    inPartnership = 'inPartnership',
    partnershipSeparated = 'partnershipSeparated'
}

export enum UndefinedMaritalStatusEnum {
    unknown = 'unknown',
    undefined = 'undefined'
}

export type MaritalStatusEnumWS = MaritalStatusEnum | UndefinedMaritalStatusEnum

export enum MaritalStatusCodesEnum {
    single = '1',
    married = '2',
    divorced = '3',
    widower = '4',
    unknown = '5',
    separated = '6',
    inPartnership = '7',
    partnershipSeparated = '8'
}

export enum GenderEnum {
    undetermined = 'undetermined',
    male = 'male',
    female = 'female'
}

export enum EcStatus {
    EC_PLUS = 'EC+',
    AEC = 'AEC',
    EC = 'EC'
}
