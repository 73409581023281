import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ClaimsSettlementTypeEnum } from 'core/enums/ClaimsSettlementTypeEnum'
import { PaymentTypeEnum } from 'core/enums/Documents'
import { ClaimsSettlement } from 'core/models/documents/ClaimsSettlement'
import { formatNumber } from 'core/utils/TextUtils'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'
import { getSelectedFinancialDocument } from 'shared/store/financialDocuments/financialDocuments.slice'

import PaymentTypeIcon from '../PaymentTypeIcon/PaymentTypeIcon'

interface ClaimsSettlementPanelCostsTpTgProps {
    selectedClaimsSettlement: ClaimsSettlement
}

const ClaimsSettlementPanelCostsTpTg = ({
    selectedClaimsSettlement
}: ClaimsSettlementPanelCostsTpTgProps): JSX.Element => {
    const { t } = useTranslation()

    const selectedDocument = useSelector(getSelectedFinancialDocument)

    const isInInsuredFavour = selectedDocument?.inFavour === 'STATEMENTS_VALUE_TYPE_CUSTOMER'
    return selectedClaimsSettlement.type === ClaimsSettlementTypeEnum.TPTG && selectedDocument ? (
        <LabelValueInlineBlock>
            <div>
                {t(
                    isInInsuredFavour
                        ? 'CLAIMS_SETTLEMENT.TOTAL_AMOUNT_INSURED'
                        : 'CLAIMS_SETTLEMENT.TOTAL_AMOUNT_ASSURA'
                )}
            </div>
            <div className="d-flex align-self-end no-wrap">
                <PaymentTypeIcon
                    paymentType={
                        isInInsuredFavour ? PaymentTypeEnum.REFUND : PaymentTypeEnum.INVOICE
                    }
                    testId="claims-settlement-panel-type-indicator"
                    isSmall
                    containerClassNames="claims-settlement-panel-type-indicator-container"
                />
                {t('COMMON.PRICE', {
                    price: formatNumber(selectedDocument?.valueAmount)
                })}
            </div>
        </LabelValueInlineBlock>
    ) : (
        <></>
    )
}

export default ClaimsSettlementPanelCostsTpTg
