import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { submitGeneralQuestionForm } from 'core/api/services/Contact'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory } from 'core/enums/AnalyticsEnums'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ConcatOrValueOnNull } from 'core/utils/ArrayUtils'
import { ifNull } from 'core/utils/TextUtils'

import { getFullPersonalData, PersonalDataView } from '../../selectors/getFullPersonalData'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import { onSubmitGeneralQuestion } from './contact.slice'

function* onSubmitGeneralQuestionSaga(action: ReturnType<typeof onSubmitGeneralQuestion>) {
    const generalQuestionForm = action.payload
    const analyticsParams = {
        form_category: FormCategory.CONTACT,
        request_purpose: generalQuestionForm.topic
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const personalData: PersonalDataView = yield select(getFullPersonalData)
        const address = personalData.address.data

        // Sanitize uncertain values
        const sanitizedPolicyNumber = ifNull(generalQuestionForm.selectedPolicyNumber, '')
        const sanitizedAddress = ConcatOrValueOnNull([
            address?.street,
            address?.streetNumber,
            address?.streetComp
        ])
        const sanitizedZipCodeAndCity = ConcatOrValueOnNull(
            [address?.npaCode, address?.locality],
            'N/A',
            ' '
        )
        const sanitizedPhoneNumber = ifNull(generalQuestionForm.phone)
        const sanitizedEMail = ifNull(generalQuestionForm.email)
        // end of sanitize
        const formToSubmit = new FormData()

        formToSubmit.append('PolicyNumber', sanitizedPolicyNumber)
        formToSubmit.append('DateOfBirth', personalData.basic.dateOfBirth)
        formToSubmit.append('LastName', personalData.basic.lastName)
        formToSubmit.append('FirstName', personalData.basic.firstName)
        formToSubmit.append('Address', sanitizedAddress)
        formToSubmit.append('ZipCodeAndCity', sanitizedZipCodeAndCity)
        formToSubmit.append('PhoneNumber', sanitizedPhoneNumber)
        formToSubmit.append('Email', sanitizedEMail)
        formToSubmit.append('Topic', generalQuestionForm.topic)
        formToSubmit.append('Message', generalQuestionForm.message)
        if (generalQuestionForm.attachment) {
            formToSubmit.append('Attachment', generalQuestionForm.attachment)
        }
        yield call(submitGeneralQuestionForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        console.error('onSubmitGeneralQuestionSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* onSubmitGeneralQuestionWatcher() {
    yield takeEvery(onSubmitGeneralQuestion.type, onSubmitGeneralQuestionSaga)
}

const watchers = [fork(onSubmitGeneralQuestionWatcher)]

export default watchers
