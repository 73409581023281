import './ProductCardList.css'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ProductCategoryEnum } from 'core/enums/Product'
import { AccidentVariant } from 'core/models/familyGroup/AccidentVariant'
import { Product } from 'core/models/Product'
import { getAccidentVariantsOfSelectedFamilyMember } from 'shared/store/combineSelectors'

import AccidentCard from '../AccidentCard/AccidentCard'
import ProductCard from '../ProductCard/ProductCard'
import ProductCardPanel from '../ProductCardPanel/ProductCardPanel'

interface ProductCardListProps {
    data: Product[]
}

const ProductCardList = (props: ProductCardListProps) => {
    const { data } = props
    const [productPanel, setProductPanel] = useState<Product | undefined>(undefined)

    const accidentVariants: AccidentVariant[] = useSelector(
        getAccidentVariantsOfSelectedFamilyMember
    )

    return (
        <>
            <div className="row">
                {data?.map((lcaProduct, index) => {
                    const isAccident: boolean =
                        ProductCategoryEnum.ACCIDENT === lcaProduct.categoryType
                    const isCapitalDeathDisability: boolean =
                        ProductCategoryEnum.CAPITAL_DEATH_DISABILITY === lcaProduct.categoryType

                    const accidentVariant: AccidentVariant | undefined =
                        isAccident || isCapitalDeathDisability
                            ? accidentVariants.find(
                                  (variant) => lcaProduct.insuranceId === variant.productCode
                              )
                            : undefined

                    const shouldDisplayAccidentCard =
                        accidentVariant &&
                        (accidentVariant.capitalsData || accidentVariant.allowancesData)
                    return (
                        <div
                            className={
                                shouldDisplayAccidentCard
                                    ? 'product-card-list-accident-container col-xl-8 col-md-12 col-12'
                                    : 'col-xl-4 col-md-6 col-12'
                            }
                            style={{ display: 'flex', marginTop: 32 }}
                            key={index.toString()}
                        >
                            <div
                                className="product-card-list-card-container"
                                style={{ display: 'flex', flex: 1 }}
                            >
                                <ProductCard
                                    product={lcaProduct}
                                    setProductPanel={setProductPanel}
                                />
                                {shouldDisplayAccidentCard && accidentVariant && (
                                    <AccidentCard data={accidentVariant} />
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <ProductCardPanel
                product={productPanel}
                closePanel={() => setProductPanel(undefined)}
            />
        </>
    )
}

export default ProductCardList
