import './InsuranceAttestation.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { shouldDisplayEmail } from 'core/helpers/services/ServicesFormHelper'
import {
    InsuranceAttestationForm,
    InsuranceAttestationRHFForm
} from 'core/models/services/certificates/InsuranceAttestationForm'
import FormPage from 'modules/services/components/FormPage/FormPage'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getHasEcAccount } from 'shared/store/selectors/getHasEcAccount'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { isThereInsuranceAttestationInRequests } from 'shared/store/selectors/isThereInRequests'
import { getHasActiveContractOfSelectedFamilyMember } from 'shared/store/selectors/services/getHasActiveContractOfSelectedFamilyMember'
import { onSubmitInsuranceAttestation } from 'shared/store/services/certificates/certificates.slice'
import {
    fetchContactDataAndProducts,
    getFamilyGroupResult
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AttestationType from '../../components/AttestationType/AttestationType'
import { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import NoActiveContract from '../../components/NoActiveContract/NoActiveContract'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import ReceptionMode from '../../components/ReceptionMode/ReceptionMode'

const InsuranceAttestation = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const defaultBaseInsurance = true
    const defaultComplementaryInsurance = true
    const hasEcAccount = useSelector(getHasEcAccount)
    const personalData = useSelector(getFullPersonalData)
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const hasPendingRequest = useSelector(isThereInsuranceAttestationInRequests)

    const defaultEmail = personalData.email.data ?? ''
    const hasEmail = shouldDisplayEmail(defaultEmail)
    const defaultReceptionMode = null

    const initialDefaultValues: InsuranceAttestationRHFForm = {
        baseInsurance: defaultBaseInsurance,
        complementaryInsurance: defaultComplementaryInsurance,
        receptionMode: defaultReceptionMode,
        email: defaultEmail,
        updateEmail: false
    }

    const [defaultValues, setDefaultValues] =
        useState<InsuranceAttestationRHFForm>(initialDefaultValues)

    const hasActiveContract = useSelector(getHasActiveContractOfSelectedFamilyMember)
    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)
    const memberDataLoadingStatus = getLoadingStatusFromLoaderList([
        contactDataLoadStatus,
        productsLoaderStatus
    ])

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactDataAndProducts({
                    policyNumber: familyGroupResult.selectedPolicyNumber
                })
            )
    }

    const onSuccess = (values: InsuranceAttestationRHFForm) => {
        const insuranceAttestationForm: InsuranceAttestationForm = {
            ...values,
            ...familyGroupResult
        }
        dispatch(onSubmitInsuranceAttestation(insuranceAttestationForm))
    }

    useEffect(() => {
        setDefaultValues({
            ...initialDefaultValues,
            email: personalData.email.data,
            receptionMode: hasEcAccount
                ? ServicesReceptionMode.ELECTRONIC
                : ServicesReceptionMode.MAIL
        })
    }, [personalData, hasEcAccount])

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.CERTIFICATE_CATTI}
            formTitle={{
                title: t('SERVICES.INSURANCE_ATTESTATION'),
                category: t('SERVICES.ATTESTATIONS_AND_INSURANCE_CARD')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{
                hasAllFamilyButton: true,
                selectAction: selectMember
            }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.CERTIFICATE_CATTI} />}
            isPending={hasPendingRequest ? <PendingRequest /> : undefined}
            notAllowed={!hasActiveContract ? <NoActiveContract /> : undefined}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    memberDataLoadingStatus,
                    missingDataLoadingStatus
                ]),
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactDataAndProducts({
                            policyNumber: familyGroupResult.selectedPolicyNumber as number,
                            reload: true
                        })
                    )
            }}
        >
            <>
                <div className="insurance-attestation-attestation-type-container bg-white bc-gray100 m-top-32 p-32">
                    <AttestationType />
                </div>
                <ReceptionMode
                    hasEcAccount={hasEcAccount}
                    hasRegularMail
                    hasEmail={hasEmail}
                    defaultEmail={defaultEmail}
                />
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    InsuranceAttestation,
    'InsuranceAttestation',
    'insurance-attestation-container'
)
