/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message'

import { InputWithValidationsProps } from './AssuraFormInputWithValidations'

type inputErrorsProps = {
    errors: { [x: string]: any }
    props: InputWithValidationsProps
}

const InputErrors = ({ errors, props }: inputErrorsProps): JSX.Element => {
    if (!errors || !props.validationResults.displayValidationErrors) return <></>
    return (
        <>
            <ErrorMessage
                errors={errors}
                name={props.name}
                render={({ message }) => (
                    <div
                        className="labelExtraSmall c-primary"
                        data-testid={props.dataTestId + '-error'}
                    >
                        {props.label !== message ? message : ''}
                    </div>
                )}
            />
        </>
    )
}

export default InputErrors
