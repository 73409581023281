import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useCguPanelContext } from 'shared/contexts/CguPanelContext'
import { fetchEcLinks } from 'shared/store/ecLinks/ecLinks.slice'
import { getEcLinksLocalized } from 'shared/store/selectors/getEcLinksLocalized'
import { useAppDispatch } from 'shared/store/store'

const CguPanelView = ({ ecLinkId }: { ecLinkId?: string }): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const { closeCguPanel } = useCguPanelContext()

    const currentLocation = useRef(location.pathname)

    const ecLinks = useSelector(getEcLinksLocalized)
    const cguEcLinkDefault = ecLinks.find((item) => item._id === '6490299266c78d43b30cf676')
    const cguEcLink = ecLinks?.find((item) => item._id === ecLinkId)

    const link = ecLinkId ? cguEcLink?.link_url : cguEcLinkDefault?.link_url

    const handleOnError = () => {
        dispatch(fetchEcLinks())
    }

    useEffect(() => {
        if (currentLocation.current !== location.pathname) closeCguPanel()
    }, [location])

    return link ? (
        <iframe
            key={link}
            title="cgu-iframe"
            src={`${link}?naked=true`} // To hide chatbot and others CTA from Public
            className="d-flex flex-1"
            style={{ border: 0 }}
        />
    ) : (
        <div
            data-testid="cgu-error-indicator"
            className="status-message-container"
            onClick={handleOnError}
        >
            <i className="icon assura-refresh size-32 c-primary refresh-button" />
            <div className="labelSmall">{t('ERROR_STATE.TITLE')}</div>
        </div>
    )
}

export default CguPanelView
