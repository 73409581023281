import './FamilyGroup.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServicesSource } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getPersonsWithPendingRequest } from 'core/helpers/services/ServicesHelper'
import { PersonSummaryWithPendingRequest } from 'core/models/services/PersonSummaryWithPendingRequest'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'
import { getSummaryCurrentYear } from 'shared/store/selectors/getSummaryCurrentYear'
import { getServicesRequests } from 'shared/store/services/requests/requests.slice'
import { setKeepSelectedMember } from 'shared/store/services/services.slice'

export interface FamilyGroupResult {
    selectedPolicyNumber: number
    isAllFamilyMembers: boolean
}

interface FamilyGroupProps {
    hasAllFamilyButton?: boolean
    selectAction: (datas: FamilyGroupResult) => void
    selectedMemberError?: string
    hasDefaultSelectedMember?: boolean
    serviceType?: ServiceType
    source: ServicesSource
    defaultSelectedPolicyNumber?: number
}

const FamilyGroup = ({
    hasAllFamilyButton = false,
    selectAction,
    selectedMemberError,
    hasDefaultSelectedMember,
    serviceType,
    source,
    defaultSelectedPolicyNumber
}: // eslint-disable-next-line sonarjs/cognitive-complexity
FamilyGroupProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    //Environement
    const requests = useSelector(getServicesRequests)
    const connectedUser = useSelector(getConnectedFamilyMember)
    const memberList = useSelector(getSummaryCurrentYear).insuredPersons

    const membersToFilter: PersonSummaryWithPendingRequest[] = serviceType
        ? getPersonsWithPendingRequest(requests, serviceType, memberList)
        : memberList
    const loggedMemberPolicyNumber = connectedUser?.policyNumber ?? 0

    const filteredMemberList =
        source === ServicesSource.WEBSITE
            ? membersToFilter.filter((member) => member.policyNumber === loggedMemberPolicyNumber)
            : membersToFilter

    const firstMemberWithNoPendingRequest = filteredMemberList.find(
        (member) => !member.hasPendingRequest
    )

    const shouldDisplayAllFamilyButton =
        hasAllFamilyButton && (source === ServicesSource.WEBSITE || filteredMemberList.length > 1) //todo à changer quand on aura le WS du chef de famille. Ca permettra de distinguer si une personne se connectant via public a plusieurs membres ou non dans son regroupement

    const shouldSelectMember =
        (hasDefaultSelectedMember && filteredMemberList.length > 0) ||
        (filteredMemberList.length === 1 && !shouldDisplayAllFamilyButton)

    const [results, setResults] = useState<FamilyGroupResult>({
        selectedPolicyNumber: shouldSelectMember ? filteredMemberList[0].policyNumber : 0,
        isAllFamilyMembers: false
    })

    const mainContainerWidth = 64

    const onAllClick = () => {
        setResults({
            selectedPolicyNumber:
                firstMemberWithNoPendingRequest?.policyNumber ?? loggedMemberPolicyNumber,
            isAllFamilyMembers: true
        })
    }

    const onAvatarClick = (selectedMember: PersonSummaryWithPendingRequest) => {
        // only when user select a member we reset the kept member.
        dispatch(setKeepSelectedMember())
        setResultFromPolicyNumber(selectedMember.policyNumber)
    }

    const setResultFromPolicyNumber = (policyNumber: number) => {
        setResults({
            selectedPolicyNumber: policyNumber,
            isAllFamilyMembers: false
        })
    }

    useEffect(() => {
        if (!defaultSelectedPolicyNumber) return
        setResultFromPolicyNumber(defaultSelectedPolicyNumber)
    }, [defaultSelectedPolicyNumber])

    useEffect(() => {
        selectAction(results)
    }, [results])

    if (shouldDisplayAllFamilyButton || filteredMemberList.length > 1) {
        return (
            <div
                className="family-group-container bg-white bc-gray100"
                data-testid="family-group-container"
                id="family-group-block"
            >
                <div className="labelMedium m-bottom-24 m-right-24">
                    {t('COMMON.CHOOSE_FAMILY_MEMBER')}
                </div>
                <div className="family-group-avatars-list" data-testid="family-group-avatars-list">
                    {/* ALL FAMILY AVATAR */}
                    {shouldDisplayAllFamilyButton && (
                        <>
                            <div
                                className="family-group-avatar"
                                onClick={onAllClick}
                                key="family-group-all-members-button"
                                style={{ cursor: 'pointer' }}
                                data-testid="family-group-all-members-button"
                            >
                                <AssuraAvatar
                                    id="family-group-member-all"
                                    label={{
                                        title: t('COMMON.ALL_FAMILY_MEMBERS'),
                                        classNames: `labelExtraSmallMedium ${
                                            results.isAllFamilyMembers ? 'c-black' : 'c-gray500'
                                        }`,
                                        marginTop: 4
                                    }}
                                    mainContainerWidth={mainContainerWidth}
                                    avatarContainerWidth={56}
                                    border={{
                                        width: 3,
                                        color: 'gray50'
                                    }}
                                    active={results.isAllFamilyMembers}
                                    hasBadge={!firstMemberWithNoPendingRequest}
                                >
                                    <i className="icon assura-home size-32" />
                                </AssuraAvatar>
                            </div>
                            <div className="family-group-separator bg-gray300" />
                        </>
                    )}
                    {/* MEMBER LIST */}
                    {filteredMemberList.map((member, index) => (
                        <div
                            className="family-group-avatar"
                            onClick={() => onAvatarClick(member)}
                            key={`family-group-avatar${index}`}
                            data-testid={`family-group-member-${index}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <AssuraAvatar
                                id={`family-group-avatar-${index}`}
                                label={{
                                    title: member.firstName,
                                    classNames: `labelExtraSmallMedium ${
                                        !results.isAllFamilyMembers &&
                                        member.policyNumber === results.selectedPolicyNumber
                                            ? 'c-black'
                                            : 'c-gray500'
                                    }`,
                                    marginTop: 4
                                }}
                                mainContainerWidth={mainContainerWidth}
                                avatarContainerWidth={56}
                                border={{
                                    width: 3,
                                    color: 'gray50'
                                }}
                                active={
                                    !results.isAllFamilyMembers &&
                                    member.policyNumber === results.selectedPolicyNumber
                                }
                                hasBadge={member.hasPendingRequest}
                            >
                                <div className="headlineSmallMedium">
                                    {(
                                        member.firstName.charAt(0) + member.lastName.charAt(0)
                                    ).toUpperCase()}
                                </div>
                            </AssuraAvatar>
                        </div>
                    ))}
                </div>
                {!!selectedMemberError && (
                    <div
                        data-testid="family-group-member-error"
                        className="p-bottom-24 labelExtraSmall c-error"
                    >
                        {selectedMemberError}
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
}

export default FamilyGroup
