import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import FormTitle from 'modules/services/components/FormTitle/FormTitle'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'

interface StepWrapperProps {
    children?: React.ReactNode | React.ReactNode[]
}
/** This is used in order to avoid repete responsiveness and good to know position */
const StepWrapper = ({ children }: StepWrapperProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const goodToKnow = () => <GoodToKnow goodToKnowId={ServiceType.ACCIDENT_REPORT} />
    return (
        <Container className="p-0">
            <Row style={{ marginTop: '126px' }} className="p-0 justify-content-start">
                <Col xs={{ span: 12 }} md={{ span: 7 }} xl={{ span: 7 }}>
                    <FormTitle
                        data-testid="services-form-personal-data-title"
                        title={t('SERVICE.ACCIDENT_REPORT.ACCIDENT_REPORT_FORM_TITLE')}
                        category={t('SERVICE.ACCIDENT_REPORT_FORM.CATEGORY')}
                    />
                    {isMobile && <div> {goodToKnow()} </div>}

                    {isMobile && <div style={{
                        width: '100%',
                        height: '1px',
                        background: '#E5E5E5',
                        position: 'relative',
                        display: 'flex',
                        marginTop: '27px',
                        marginBottom: '32px'
                    }} />}

                    <div>{children}</div>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 5 }} xl={{ span: 4, offset: 1 }}>
                    {!isMobile && goodToKnow()}
                </Col>
            </Row>
        </Container>
    )
}
export default StepWrapper
