import { FieldValues, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { DeleteAccountRHF } from 'core/models/DeleteAccount'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import {
    getDeleteAccountLoadingStatus,
    submitDeleteAccount
} from 'shared/store/settings/deleteAccount/deleteAccount.slice'
import { useAppDispatch } from 'shared/store/store'

const DeleteAccountFormCta = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const deleteAccountStatus = useSelector(getDeleteAccountLoadingStatus)

    const handleOnSubmit: SubmitHandler<FieldValues> = (data) => {
        const { policyNumber } = data as DeleteAccountRHF
        const policyNumberApi = +policyNumber
        if(typeof policyNumberApi === 'number') dispatch(submitDeleteAccount(policyNumberApi))
    }

    return (
        <div className="d-flex justify-content-start align-items-center m-top-32">
            <AssuraButton
                text={t('COMMON.CANCEL')}
                id="delete-account-cancel-cta"
                variant="secondary"
                onClick={() => navigate(`${navigationConstants.HOME.url}`)}
            />

            <SubmitButtonWithScroll
                text={t('DELETE_ACCOUNT.FORM_SUBMIT')}
                onSubmit={handleOnSubmit}
                id="delete-account-submit-cta"
                hasLoader={deleteAccountStatus === LoadingStatusEnum.LOADING}
                classNames="m-left-12"
            />
        </div>
    )
}

export default DeleteAccountFormCta
