import { DeepMap, ErrorOption, FieldError, FieldValues } from 'react-hook-form'

import {
    AventuraAdditionalFamilyMemberModel,
    getMemberField,
    membersSection
} from 'core/models/services/coverage/AventuraForm'
import { TFunction } from 'i18next'

export const rulesLengthAndRequired = (
    minValue: number,
    maxValue: number,
    lengthCheckMessage: string,
    mandatoryCheckMessage: string
): Record<string, Record<string, string | boolean | number> | string | boolean | number> => {
    return {
        minLength: {
            value: minValue,
            message: lengthCheckMessage
        },
        maxLength: {
            value: maxValue,
            message: lengthCheckMessage
        },
        required: {
            value: true,
            message: mandatoryCheckMessage
        }
    }
}

export const getMemberErrorMessage = (
    currentMember: AventuraAdditionalFamilyMemberModel,
    errorCollection: DeepMap<FieldValues, FieldError>,
    fieldName: string
): string => {
    try {
        if (!currentMember) return ''
        if (!errorCollection || Object.entries(errorCollection).length === 0) return ''
        if (!errorCollection[membersSection][currentMember.id][fieldName]) return ''
        if (!errorCollection[membersSection][currentMember.id][fieldName].message) return ''
        return errorCollection[membersSection][currentMember.id][fieldName].message
    } catch {
        // when validating 2 and change to more than 2 people errors collection doesn't know any of the new validations.
        // we just skip this case returning empty.
        // sumission will feed this collection with the correct amount of persons.
        return ''
    }
}

export const getErrorMessage = (
    errorCollection: DeepMap<FieldValues, FieldError>,
    fieldName: string
): string => {
    if (!errorCollection || Object.entries(errorCollection).length === 0) return ''
    if (!errorCollection[fieldName]) return ''
    return errorCollection[fieldName].message
}

export const isMemberValid = (
    currentMember: AventuraAdditionalFamilyMemberModel,
    errorCollection: DeepMap<FieldValues, FieldError>,
    section: string,
    fieldName: string
): boolean => {
    try {
        return !(
            !currentMember ||
            Object.entries(errorCollection).length === 0 ||
            !errorCollection[section][currentMember.id] ||
            !errorCollection[section][currentMember.id][fieldName]
        )
    } catch {
        return true
    }
}

export const isValid = (
    errorCollection: DeepMap<FieldValues, FieldError>,
    fieldName: string
): boolean =>
    !(
        Object.entries(errorCollection).length === 0 ||
        !errorCollection ||
        !errorCollection[fieldName]
    )

export const dateValidation = (value: string | Date | number | undefined): string | boolean => {
    if (!value) {
        return 'COMMON.MANDATORY_FIELD'
    } else {
        return true
    }
}

export const getDuplicateMember = (
    currentMember: AventuraAdditionalFamilyMemberModel,
    membersCollection: AventuraAdditionalFamilyMemberModel[]
): AventuraAdditionalFamilyMemberModel | undefined =>
    membersCollection.find(
        (o) =>
            o.birthDate === currentMember.birthDate &&
            o.lastName === currentMember.lastName &&
            o.firstName === currentMember.firstName
    )

export const isMemberEquals = (
    member1: AventuraAdditionalFamilyMemberModel,
    member2: AventuraAdditionalFamilyMemberModel
): boolean => JSON.stringify(member1) === JSON.stringify(member2)

export const isMemberEmpty = (member: AventuraAdditionalFamilyMemberModel): boolean =>
    !member || !(member.firstName && member.lastName && member.birthDate)

export const setDuplicateErrors = (
    memberId: number,
    setError: (
        name: string,
        error: ErrorOption,
        options?:
            | {
                  shouldFocus: boolean
              }
            | undefined
    ) => void,
    t: TFunction
): void => {
    const errorMessage = {
        message: t('SERVICE.AVENTURA_VALIDATION_MEMBER_DUPPLICATE')
    }
    const personSelector = getMemberField(memberId, 'personSelector')

    setError(personSelector, errorMessage, {
        shouldFocus: true
    })
}

export const clearMemberErrors = (
    memberId: number,
    clearErrors: (name?: string | string[] | readonly string[] | undefined) => void
): void => {
    clearErrors([
        getMemberField(memberId, 'personSelector'),
        getMemberField(memberId, 'firstName'),
        getMemberField(memberId, 'lastName'),
        getMemberField(memberId, 'birthDate')
    ])
}
