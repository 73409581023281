import { useTranslation } from 'react-i18next'

import { ServiceType } from 'core/enums/ServiceType'
import { RequestDetailsValues, RequestRow } from 'core/models/services/RequestData'
import AccidentReport from 'modules/requests/components/RequestsPanelComponents/AccidentReport'

import AddMember from './RequestsPanelComponents/AddMember'
import AventuraTravelDetails from './RequestsPanelComponents/AventuraTravelDetails'
import CertificateCatti from './RequestsPanelComponents/CertificateCatti'
import CertificateCattp from './RequestsPanelComponents/CertificateCattp'
import CertificateCdec from './RequestsPanelComponents/CertificateCdec'
import CertificateLpimp from './RequestsPanelComponents/CertificateLpimp'
import ContactGeneralQuestion from './RequestsPanelComponents/ContactGeneralQuestion'
import ContractFamilyDoctor from './RequestsPanelComponents/ContractFamilyDoctor'
import CoverageLamalAccident from './RequestsPanelComponents/CoverageLamalAccident'
import CoverageModelDeductible from './RequestsPanelComponents/CoverageModelDeductible'
import FinancialPaymentChange from './RequestsPanelComponents/FinancialPaymentChange'
import HospitalizationAnnouncement from './RequestsPanelComponents/HospitalizationAnnouncement'
import InsuranceCardOrder from './RequestsPanelComponents/InsuranceCardOrder'
import PaymentFrequencyModification from './RequestsPanelComponents/PaymentFrequencyModification'
import PersonalDataAddress from './RequestsPanelComponents/PersonalDataAddress'
import PersonalDataBasic from './RequestsPanelComponents/PersonalDataBasic'
import PersonalDataEmail from './RequestsPanelComponents/PersonalDataEmail'
import PersonalDataPhone from './RequestsPanelComponents/PersonalDataPhone'

export interface RequestsPanelComponent {
    title: string
    category?: string
    component: JSX.Element
}

const getRequestsPanelComponent = (
    type: ServiceType,
    selectedRequest: RequestRow,
    values: RequestDetailsValues[]
): RequestsPanelComponent => {
    const { t } = useTranslation()

    switch (type) {
        case ServiceType.CERTIFICATE_CATTP:
            return {
                title: t('SERVICES.BILLED_PRIME_DETAILS'),
                category: t('SERVICES.TAX_CERTIFICATE_REQUEST'),
                component: <CertificateCattp values={values} status={selectedRequest.status} />
            }
        case ServiceType.CERTIFICATE_CATTI:
            return {
                title: t('SERVICES.INSURANCE_ATTESTATION'),
                category: t('SERVICES.ATTESTATIONS_AND_INSURANCE_CARD'),
                component: <CertificateCatti values={values} status={selectedRequest.status} />
            }
        case ServiceType.LPIMP_REQUEST:
            return {
                title: t('SERVICES.MEDICAL_FEES_DETAILS'),
                category: t('SERVICES.TAX_CERTIFICATE_REQUEST'),
                component: <CertificateLpimp values={values} status={selectedRequest.status} />
            }
        case ServiceType.COVER_CARD_ORDER:
            return {
                title: t('SERVICES.INSURANCE_CARD_ORDER_TITLE'),
                category: t('SERVICES.ATTESTATIONS_AND_INSURANCE_CARD'),
                component: <InsuranceCardOrder status={selectedRequest.status} />
            }

        case ServiceType.CDEC_REQUEST:
            return {
                title: t('SERVICES.STATEMENT_DETAILS'),
                category: t('SERVICES.PAYMENT_AND_INVOICING'),
                component: <CertificateCdec values={values} status={selectedRequest.status} />
            }
        case ServiceType.NAME_CIVIL_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_NAME_CIVIL_CHANGE'),
                component: (
                    <PersonalDataBasic
                        values={values}
                        gender={selectedRequest.insuredPersonGender}
                    />
                )
            }
        case ServiceType.PHONE_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_PHONE_CHANGE'),
                component: <PersonalDataPhone values={values} />
            }
        case ServiceType.EMAIL_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_EMAIL_CHANGE'),
                component: <PersonalDataEmail values={values} />
            }
        case ServiceType.ADDRESS_CHANGE:
            return {
                title: t('PERSONAL_DATA.ADDRESS_FORM_TITLE'),
                component: <PersonalDataAddress values={values} />
            }
        case ServiceType.BANK_REL_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_BANK_REL_CHANGE'),
                component: <FinancialPaymentChange values={values} />
            }
        case ServiceType.DEDUCTIBLE_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_DEDUCTIBLE_CHANGE'),
                component: <CoverageModelDeductible values={values} />
            }
        case ServiceType.FAMILY_DOCTOR_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_FAMILY_DOCTOR_CHANGE'),
                component: <ContractFamilyDoctor values={values} />
            }
        case ServiceType.GENERAL_QUESTION:
            return {
                title: t('CONTACT.GENERAL_QUESTION_REQUEST_TITLE'),
                component: (
                    <ContactGeneralQuestion
                        values={values}
                        attachment={selectedRequest.documents.find(
                            (doc) => doc.documentType === 'attachment'
                        )}
                    />
                )
            }
        case ServiceType.AUTO_LAMAL_ACC_SUSP_INCL:
            return {
                title: t('SERVICES.COVERAGE_ACCIDENT_TITLE'),
                component: <CoverageLamalAccident values={values} />
            }
        case ServiceType.PAYMENT_FREQ_MODIFICATION:
            return {
                title: t('SERVICES.FINANCIAL_PAYMENT_FREQUENCY_TITLE'),
                component: <PaymentFrequencyModification values={values} />
            }
        case ServiceType.FAMILY_GROUP_CHANGE:
            return {
                title: t('SERVICES.REQUEST_TYPE_FAMILY_GROUP_CHANGE'),
                component: <AddMember values={values} />
            }
        case ServiceType.AVENTURA_INSURANCE_REQUEST:
            return {
                title: t('SERVICE.AVENTURA_REQUEST_DETAILS_TITLE'),
                component: <AventuraTravelDetails values={values} />
            }
        case ServiceType.HOSP_ANNOUNCEMENT:
            return {
                title: t('SERVICE.HOSPITALIZATION_ANNOUNCEMENT'),
                component: (
                    <HospitalizationAnnouncement
                        values={values}
                        attachment={selectedRequest.documents.find(
                            (doc) => doc.documentType === 'generatedPdf'
                        )}
                    />
                )
            }
        case ServiceType.ACCIDENT_REPORT_BIS:
            return {
                title: t('SERVICES.REQUEST_TYPE_ACCIDENT_REPORT_BIS'),
                component: (
                    <AccidentReport
                        generated={selectedRequest.documents.find(
                            (doc) => doc.documentType === 'generatedPdf'
                        )}
                        attachments={
                            selectedRequest.documents.filter(
                                (doc) => doc.documentType === 'attachment'
                            )
                        }
                    />
                )
            }
        default:
            return {
                title: '',
                component: <div />
            }
    }
}

export default getRequestsPanelComponent
