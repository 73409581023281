import {
    browserName,
    deviceType,
    fullBrowserVersion,
    mobileModel,
    mobileVendor,
    osName,
    osVersion
} from 'react-device-detect'

import { buildVersionKey } from 'index'

import { DEFAULT_LANGUAGE } from '../constants/lang'
import { generateGUID } from '../helpers/GuidHelper'
import { DeviceInfoHeaders } from '../models/DeviceInfoHeaders'

let deviceInfoHeaders: DeviceInfoHeaders

export const setDeviceInfoHeaders = (): void => {
    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
        deviceId = generateGUID()
        localStorage.setItem('deviceId', deviceId)
    }
    const lang = localStorage.getItem('lang') ?? DEFAULT_LANGUAGE

    deviceInfoHeaders = {
        'Accept-Language': lang,
        ClientDeviceId: deviceId,
        ClientType: 'EspaceClient',
        ClientVersion: localStorage.getItem(buildVersionKey) as string,
        OsName: osName,
        OsVersion: osVersion,
        Device: deviceType,
        Brand: mobileVendor !== 'none' ? mobileVendor : null,
        Model: mobileModel !== 'none' ? mobileModel : null,
        Browser: browserName,
        BrowserDetails: fullBrowserVersion
    }
}

export const getDeviceInfoHeaders = (): DeviceInfoHeaders => deviceInfoHeaders
