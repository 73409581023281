import { ProductTypeEnum } from 'core/enums/Product'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { getCheckDate, getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedMemberPolicyNumber } from '../../services/services.slice'
import {
    getSummaryCurrentYear,
    getSummaryLastYear,
    getSummaryNextYear
} from '../getSummaryCurrentYear'

export const getHasActiveContractOfSelectedFamilyMember = createSelector(
    [getSummaryCurrentYear, getSelectedMemberPolicyNumber],
    (summary, selectedMemberPolicyNumber) => {
        if (selectedMemberPolicyNumber) {
            const familyMember = summary.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )
            const activeProducts = familyMember?.productsPerson?.products?.filter(
                (productPerson) =>
                    new Date(productPerson.startDate) <= getCheckDate(getCurrentYear())
            )
            return !!activeProducts && activeProducts.length > 0
        } else {
            return true
        }
    }
)

const checkIfHasLamal = (
    summary: SummaryResult,
    selectedMemberPolicyNumber: number | undefined,
    yearToCheck: number
) => {
    if (selectedMemberPolicyNumber) {
        const familyMember = summary.insuredPersons.find(
            (person) => person.policyNumber === selectedMemberPolicyNumber
        )
        const activeProducts = familyMember?.productsPerson?.products?.filter(
            (productPerson) =>
                new Date(productPerson.startDate) <= getCheckDate(yearToCheck) &&
                productPerson.type === ProductTypeEnum.LAMAL
        )
        return !!activeProducts && activeProducts.length > 0
    } else {
        return true
    }
}

export const getHasActiveLamalOfSelectedFamilyMember = createSelector(
    [getSummaryCurrentYear, getSelectedMemberPolicyNumber],
    (summary, selectedMemberPolicyNumber) =>
        checkIfHasLamal(summary, selectedMemberPolicyNumber, getCurrentYear())
)

export const getHasActiveLamalOfSelectedFamilyMemberLastYear = createSelector(
    [getSummaryLastYear, getSelectedMemberPolicyNumber],
    (summary, selectedMemberPolicyNumber) =>
        checkIfHasLamal(summary, selectedMemberPolicyNumber, getCurrentYear() - 1)
)

export const getHasActiveLamalOfSelectedFamilyMemberNextYear = createSelector(
    [getSummaryNextYear, getSelectedMemberPolicyNumber],
    (summary, selectedMemberPolicyNumber) =>
        checkIfHasLamal(summary, selectedMemberPolicyNumber, getCurrentYear() + 1)
)
