import './AssuraFooter.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { PopinItemEnum } from 'core/enums/Popin'
import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import TrustID from 'shared/assets/images/trustID_logo.png'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { useCguPanelContext } from 'shared/contexts/CguPanelContext'
import { getIsDoubleAuthenticationActive } from 'shared/store/familySummaries/familySummaries.slice'
import { setSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { goToServicesForm } from 'shared/store/services/services.slice'
import { useAppDispatch } from 'shared/store/store'
import { getHasActiveWhatsNew, selectLastWhatsNew } from 'shared/store/whatsNew/whatsNew.slice'

import AssuraButton from '../AssuraButton/AssuraButton'
import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'

const AssuraFooter = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL, isMobile } = useWindowSize()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const doubleAuthIsActive = useSelector(getIsDoubleAuthenticationActive)
    const hasActiveWhatsNew = useSelector(getHasActiveWhatsNew)

    const { openCguPanel } = useCguPanelContext()

    const handleLastActiveWhatsNew = () => {
        dispatch(selectLastWhatsNew())
        dispatch(setSelectedPopinItem({ popinItem: PopinItemEnum.WHATSNEW }))
    }

    return (
        <div className="footer-main-container">
            {doubleAuthIsActive && (
                <FullScreenContainer
                    color="white"
                    complementaryClasses="d-flex flex-column align-items-center p-top-24 p-bottom-24"
                >
                    <div className="labelSmall c-gray500">Secured by</div>
                    <img src={TrustID} />
                </FullScreenContainer>
            )}
            <FullScreenContainer color="primary">
                <div
                    className={`footer-container${isXL ? ' container' : ''}`}
                    id="footer-container"
                    data-testid="footer-container"
                >
                    <nav className="footer-nav" role="navigation">
                        <ul className="footer-content-container labelSmall c-white">
                            <li className="copyright">&copy; Assura</li>
                            <li
                                data-testid="footer-legal-notices"
                                className="cursor-pointer"
                                onClick={() => openCguPanel()}
                            >
                                {t('COMMON.CGU')}
                            </li>
                            <AssuraEcLink
                                ecLinkId="649029ce5ef6e465bd04da42"
                                testId="footer-legal-notices"
                                classNames="cursor-pointer"
                                as="li"
                            >
                                {t('COMMON.LEGAL_NOTICES')}
                            </AssuraEcLink>
                        </ul>
                    </nav>
                    <div className="button-links d-flex flex-column flex-md-row">
                        {hasActiveWhatsNew && (
                            <AssuraButton
                                text={t('COMMON.NEWS')}
                                id="footer-whats-new-button"
                                variant="secondary"
                                colorBackground
                                onClick={handleLastActiveWhatsNew}
                                icon={{
                                    name: 'assura-opinion',
                                    size: 24
                                }}
                                classNames={`${isMobile ? ' m-bottom-16' : 'm-right-16'}`}
                            />
                        )}
                        <AssuraButton
                            text={t('FOOTER.FAQ')}
                            id="footer-faq-button"
                            variant="secondary"
                            colorBackground
                            onClick={() => navigate(`${navigationConstants.FAQ.url}`)}
                            icon={{
                                name: 'assura-help-circle',
                                size: 24
                            }}
                            classNames={`${isMobile ? ' m-bottom-16' : 'm-right-16'}`}
                        />
                        <AssuraButton
                            text={t('COMMON.NEED_HELP_BUTTON')}
                            id="footer-contact-button"
                            variant="primary"
                            colorBackground
                            onClick={() =>
                                dispatch(goToServicesForm({ type: ServiceType.GENERAL_QUESTION }))
                            }
                            icon={{
                                name: 'assura-help-circle',
                                size: 24
                            }}
                        />
                    </div>
                </div>
            </FullScreenContainer>
        </div>
    )
}

export default AssuraFooter
