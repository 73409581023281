import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import useWindowSize from 'core/services/useWindowSize'
import { openInNewTab } from 'core/utils/onClickUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { getLang } from 'shared/store/general/general.slice'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'

interface RecommendedProductCardFooterProps {
    recommendedProduct: RecommendedProduct
}

const RecommendedProductCardFooter = ({
    recommendedProduct
}: RecommendedProductCardFooterProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const selectedMember = useSelector(getSelectedFamilyMember)
    const currentLang = useSelector(getLang)

    // const onClick = (): void => {
    //     sendEvent(analyticsConstants.EVENTS.PRODUCT_CARD_CLICK, {
    //         [analyticsConstants.PARAMS.HREF]: recommendedProduct.url,
    //         [analyticsConstants.PARAMS.PRODUCT_CATEGORY]: recommendedProduct.subtitle,
    //         [analyticsConstants.PARAMS.PRODUCT_TITLE]: recommendedProduct.title
    //     })
    // }
    const onClick = (url: string): void => {
        sendEvent(analyticsConstants.EVENTS.PRODUCT_CARD_CLICK, {
            [analyticsConstants.PARAMS.HREF]: recommendedProduct.url,
            [analyticsConstants.PARAMS.PRODUCT_CATEGORY]: recommendedProduct.subtitle,
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: recommendedProduct.title
        })
        openInNewTab(url)
    }

    return (
        <div className="recommended-product-card-footer-container">
            {/* <form
                action={`${process.env.REACT_APP_PUBLIC?.toString()}/load/client`}
                method="POST"
                target="_blank"
            >
                <input type="hidden" name="lang" value={currentLang} />
                <input type="hidden" name="policyNumber" value={selectedMember.policyNumber} />
                <input
                    type="hidden"
                    name="recommendedProduct"
                    value={recommendedProduct.url as string}
                />
                <AssuraButton
                    id="recommended-product-card-button"
                    classNames="recommended-product-card-button"
                    onClick={() => (recommendedProduct.url ? onClick() : null)}
                    text={t('COMMON.LEARN_MORE')}
                    variant="secondary"
                    fullWidth={isMobile}
                />
            </form> */}
            <AssuraButton
                id="recommended-product-card-button"
                classNames="recommended-product-card-button"
                onClick={() => (recommendedProduct.url ? onClick(recommendedProduct.url) : null)}
                text={t('COMMON.LEARN_MORE')}
                variant="secondary"
                fullWidth={isMobile}
            />
        </div>
    )
}

export default RecommendedProductCardFooter
