import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormPageContext } from 'modules/services/components/FormPage/FormPage'

import LamalAccidentDownload from '../LamalAccidentDownload/LamalAccidentDownload'
import LamalAccidentDropzone from '../LamalAccidentDropzone/LamalAccidentDropzone'

const LamalAccidentAttestation = (): JSX.Element => {
    const { t } = useTranslation()
    const { addRhfExtraRef } = useFormPageContext()

    const lamalAccidentDropzoneRefId = 'lamal-accident-dropzone'
    useEffect(() => {
        addRhfExtraRef({
            name: 'attachedFile',
            id: lamalAccidentDropzoneRefId
        })
    }, [])

    return (
        <div
            className="bg-white bc-gray100 border-solid-width-1 p-32"
            id={lamalAccidentDropzoneRefId}
        >
            <div className="paragraphMedium m-bottom-32">
                {t('SERVICES.COVERAGE_ACCIDENT_LAA_TITLE')}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-start bg-gray20 bc-gray100 border-solid-width-1 p-32 paragraphSmallLight m-bottom-32">
                <div className="m-bottom-16">{t('SERVICES.COVERAGE_ACCIDENT_LAA_INFO_1')}</div>
                <ul className="services-lamal-accident-list">
                    <li>{t('SERVICES.COVERAGE_ACCIDENT_LAA_INFO_2')}</li>
                    <li>{t('SERVICES.COVERAGE_ACCIDENT_LAA_INFO_3')}</li>
                    <li>{t('SERVICES.COVERAGE_ACCIDENT_LAA_INFO_4')}</li>
                    <li>{t('SERVICES.COVERAGE_ACCIDENT_LAA_INFO_5')}</li>
                </ul>
                <LamalAccidentDownload />
            </div>
            <LamalAccidentDropzone />
        </div>
    )
}

export default LamalAccidentAttestation
