import { call, delay, fork, put, takeEvery } from 'redux-saga/effects'

import {
    fetchInvoiceToCheckApi,
    fetchInvoiceToCheckDetailsApi,
    refuseToAnswerInvoiceToCheckApi,
    submitInvoiceToCheckAnswersApi
} from 'core/api/InvoicesToCheck'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ToastType } from 'core/enums/ToastType'
import {
    InvoiceChecking,
    InvoiceCheckingAnswersWrapper,
    InvoiceCheckingDetails
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import i18next from 'i18next'

import { PayloadAction } from '@reduxjs/toolkit'

import { setBanner } from '../banners/banners.slice'
import { setToastInfo } from '../toast/toast.slice'
import {
    fetchInvoicesToCheck,
    fetchInvoicesToCheckDetails,
    refuseToAnswer,
    setInvoicesToCheck,
    setInvoicesToCheckDetails,
    setInvoicesToCheckDetailsLoadingStatus,
    setInvoicesToCheckLoadingStatus,
    submitInvoiceToCheckAnswers
} from './invoicesToCheck.slice'

function* fetchInvoicesToCheckSaga() {
    try {
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.LOADING))
        const invoicesToCheck: InvoiceChecking[] = yield call(fetchInvoiceToCheckApi)
        yield put(setInvoicesToCheck(invoicesToCheck))

        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchInvoicesToCheckSaga Error', e)
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchInvoicesToCheckDetailsSaga(action: PayloadAction<number>) {
    try {
        yield put(setInvoicesToCheckDetailsLoadingStatus(LoadingStatusEnum.LOADING))
        const invoicesToCheck: InvoiceCheckingDetails = yield call(
            fetchInvoiceToCheckDetailsApi,
            action.payload
        )
        yield put(setInvoicesToCheckDetails(invoicesToCheck))
        yield put(setInvoicesToCheckDetailsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchInvoicesToCheckDetailsSaga Error', e)
        yield put(setInvoicesToCheckDetailsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* submitInvoiceToCheckAnswersSaga(action: PayloadAction<InvoiceCheckingAnswersWrapper>) {
    try {
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.LOADING))

        yield call(submitInvoiceToCheckAnswersApi, action.payload)
        yield delay(500)
        yield put(fetchInvoicesToCheck())
        yield put(setInvoicesToCheckDetails(null))
        yield put(
            setToastInfo({
                message: i18next.t('COMMUNICATIONS.INVOICES_TO_CHECK_SUBMIT_SUCCESS'),
                type: ToastType.SUCCESS
            })
        )
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('submitInvoiceToCheckAnswersSaga Error', e)
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.ERROR))
        yield put(
            setBanner({
                dataTestId: 'refuseToAnswerSaga',
                message: 'COMMON.ERROR'
            })
        )
    }
}

function* refuseToAnswerSaga(action: PayloadAction<string>) {
    try {
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.LOADING))

        yield call(refuseToAnswerInvoiceToCheckApi, action.payload)
        yield delay(500)
        yield put(fetchInvoicesToCheck())
        yield put(setInvoicesToCheckDetails(null))
        yield put(
            setToastInfo({
                message: i18next.t('COMMUNICATIONS.INVOICES_TO_CHECK_REFUSAL_SUCCESS'),
                type: ToastType.SUCCESS
            })
        )
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('refuseToAnswerSaga Error', e)
        yield put(setInvoicesToCheckLoadingStatus(LoadingStatusEnum.ERROR))
        yield put(
            setBanner({
                dataTestId: 'refuseToAnswerSaga',
                message: 'COMMON.ERROR'
            })
        )
    }
}

function* fetchInvoicesToCheckSagaWatcher() {
    yield takeEvery(fetchInvoicesToCheck.type, fetchInvoicesToCheckSaga)
}

function* fetchInvoicesToCheckDetailsSagaWatcher() {
    yield takeEvery(fetchInvoicesToCheckDetails.type, fetchInvoicesToCheckDetailsSaga)
}

function* submitAnswersSagaWatcher() {
    yield takeEvery(submitInvoiceToCheckAnswers.type, submitInvoiceToCheckAnswersSaga)
}

function* refuseToAnswerSagaWatcher() {
    yield takeEvery(refuseToAnswer.type, refuseToAnswerSaga)
}

const watchers = [
    fork(submitAnswersSagaWatcher),
    fork(fetchInvoicesToCheckSagaWatcher),
    fork(fetchInvoicesToCheckDetailsSagaWatcher),
    fork(refuseToAnswerSagaWatcher)
]

export default watchers
