import './SplashScreensGroup.css'

import { Container } from 'react-bootstrap'

import CmsManagedSplashScreen from './CmsManaged/CmsManagedSplashScreen'
import MobileAppSplashScreen from './MobileApp/MobileAppSplashScreen'

const SplashScreensGroup = (): JSX.Element => {
    return (
        <Container
            className={`splashscreens-frame d-flex align-items-center justify-content-center`}
        >
            <MobileAppSplashScreen />
            <CmsManagedSplashScreen />
        </Container>
    )
}

export default SplashScreensGroup
