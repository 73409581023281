import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { fetchEcPlusLastDate, submitEcPlusActivation } from 'core/api/Account'
import { ToastType } from 'core/enums/ToastType'
import i18next from 'i18next'

import { setToastInfo } from '../../toast/toast.slice'
import {
    fetchLastUpdate,
    fetchLastUpdateSuccess,
    onSubmitLastUpdate,
    onSubmitLastUpdateAndActivate
} from './ecPlusMigration.slice'

export function* fetchLastUpdateSaga() {
    try {
        const lastUpdate: Date | null = yield call(fetchEcPlusLastDate)
        yield put(fetchLastUpdateSuccess({ lastUpdate }))
    } catch (e) {
        console.error('fetchLastUpdateSaga Error', e)
    }
}

export function* onSubmitLastUpdateSaga() {
    try {
        yield call(submitEcPlusActivation)
        yield call(fetchLastUpdateSaga)
    } catch (e) {
        console.error('onSubmitLastUpdateSaga Error', e)
    }
}

export function* onSubmitLastUpdateAndActivateSaga() {
    try {
        yield call(submitEcPlusActivation, true)
        yield call(fetchLastUpdateSaga)
        yield put(
            setToastInfo({
                message: i18next.t('MIGRATION_EC_PLUS.CONFIRMED'),
                type: ToastType.SUCCESS
            })
        )
    } catch (e) {
        yield put(
            setToastInfo({
                message: i18next.t('MIGRATION_EC_PLUS.ERROR'),
                type: ToastType.ERROR
            })
        )
        console.error('onSubmitLastUpdateAndActivateSaga Error', e)
    }
}

function* fetchLastUpdateWatcher() {
    yield takeEvery(fetchLastUpdate.type, fetchLastUpdateSaga)
}

function* onSubmitLastUpdateWatcher() {
    yield takeEvery(onSubmitLastUpdate.type, onSubmitLastUpdateSaga)
}

function* onSubmitLastUpdateAndActivateWatcher() {
    yield takeEvery(onSubmitLastUpdateAndActivate.type, onSubmitLastUpdateAndActivateSaga)
}

const watchers = [
    fork(fetchLastUpdateWatcher),
    fork(onSubmitLastUpdateWatcher),
    fork(onSubmitLastUpdateAndActivateWatcher)
]

export default watchers
