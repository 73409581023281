import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AccidentInsuranceModel } from 'core/models/services/report/AccidentReportModel'
import { commonMandatoryErrorKey } from 'core/validators/constants'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { StepItem } from 'modules/services/components/Stepper/core/models'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'

import { isTrue } from '../../../../core/helpers/FieldValidationHelper'
import AccidentReportDropzone from '../components/AccidentReportDropzone'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'ACCIDENT_INSURANCE'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const accidentInsuranceFormFieldNameList: Array<keyof AccidentInsuranceModel> = [
    'otherInsurance',
    'otherInsuranceName',
    'otherInsuranceCoverage',
    'otherDocuments',
    'cbconfirm'
]

export interface AccidentInsuranceFormProps {
    id: number
}

/**
 * Step8 description:
 * ACCIDENT INSURANCE
 *
 */
const AccidentInsuranceForm = ({ id }: AccidentInsuranceFormProps): JSX.Element => {
    const { t } = useTranslation()

    const { register, getValues, setValue, trigger, formState, resetField } = useFormContext<
        AccidentInsuranceModel | FieldValues
    >()
    const { stepWatch } = useStepperContext()
    const currentStep = stepWatch(id) as StepItem
    const { otherInsurance } = getValues()
    const { errors } = formState
    const { TextareaInput, RadioGroup } = useCustomControls(formName)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        if (otherInsurance === 'otherInsuranceNo') {
            resetField('otherInsuranceName', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
            resetField('otherInsuranceCoverage', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
        }
    }, [otherInsurance])

    const handleConfirm = async (field: keyof AccidentInsuranceModel, value: boolean) => {
        setValue(field, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: currentStep && currentStep?.hasBeenValidated
        })
    }

    const otherInsuranceValidateYes = isTrue(getValues('otherInsurance'), 'otherInsurance')()
    const { cbconfirm } = errors
    return (
        <div id={`${id}`} className="step-frame">
            <FormBlock
                titleTestId={'accident-insurance'}
                title={t(`${serviceName}.${formName}.ACCIDENT_INSURANCE`)}
                childrenMargin="m-bottom-48"
            >
                {RadioGroup(
                    'OTHER_INSURANCE',
                    'otherInsurance',
                    {
                        mandatory: true
                    },
                    () => trigger('otherInsurance'),
                    true
                )}
                {otherInsuranceValidateYes && (
                    <>
                        <Row className="m-bottom-48">
                            <Col>
                                {TextareaInput(
                                    'otherInsuranceName',
                                    'OTHER_INSURANCE_NAME',
                                    {
                                        mandatory: otherInsuranceValidateYes,
                                        maxLength: 400
                                    },
                                    false,
                                    undefined,
                                    () => trigger('otherInsuranceName')
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {TextareaInput(
                                    'otherInsuranceCoverage',
                                    'OTHER_INSURANCE_COVERAGE',
                                    {
                                        mandatory: otherInsuranceValidateYes,
                                        maxLength: 400
                                    },
                                    false,
                                    undefined,
                                    () => trigger('otherInsuranceCoverage')
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </FormBlock>
            <FormBlock
                titleTestId={'attachments'}
                title={t(`${serviceName}.${formName}.ATTACHMENTS`)}
                childrenMargin="m-bottom-48"
            >
                <AccidentReportDropzone
                    name="otherDocuments"
                    defaultValue={getValues('otherDocuments')}
                    mandatory={false}
                    clear={() => {
                        setValue('otherDocuments', undefined)
                    }}
                />
            </FormBlock>
            <FormBlock
                titleTestId={'confirm'}
                childrenMargin="m-bottom-48"
                frameMarginBottom="m-bottom-32"
            >
                <div className="m-0" style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckboxRHF
                        id="cbconfirm"
                        name="cbconfirm"
                        label={''}
                        register={register}
                        labelClassNames="paragraph"
                        classNames="flex-1"
                        onClick={(value) => handleConfirm('cbconfirm', value)}
                        rules={
                            // required: { value: true, message: t(commonMandatoryErrorKey),
                            {
                                validate: (): boolean | string => {
                                    const cbconfirmValue = getValues('cbconfirm') as boolean
                                    return cbconfirmValue === true || t(commonMandatoryErrorKey)
                                }
                            }
                        }
                    />
                    <div>
                        <AssuraMarkdown
                            message={t(`${serviceName}.${formName}.CHB_CONFIRM`)}
                            classNames="white-space-pre-line"
                        />
                    </div>
                </div>
                {cbconfirm && (
                    <div className="m-top-8">
                        <div
                            className="labelExtraSmall c-error m-left-16"
                            data-testid={'confirm-error'}
                        >
                            {cbconfirm?.message as string}
                        </div>
                    </div>
                )}
            </FormBlock>
            <StepperFooter />
        </div>
    )
}

export default AccidentInsuranceForm
