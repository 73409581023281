import './StatementDetails.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { getDefaultStartYear, shouldDisplayEmail } from 'core/helpers/services/ServicesFormHelper'
import {
    StatementDetailsForm,
    StatementDetailsFormRHF
} from 'core/models/services/certificates/StatementDetailsForm'
import FormPage from 'modules/services/components/FormPage/FormPage'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getHasEcAccount } from 'shared/store/selectors/getHasEcAccount'
import { isThereStatementDetailsInRequests } from 'shared/store/selectors/isThereInRequests'
import { onSubmitStatementDetails } from 'shared/store/services/certificates/certificates.slice'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import ReceptionMode from '../../components/ReceptionMode/ReceptionMode'
import YearSelector from '../../components/YearSelector/YearSelector'

const StatementDetails = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const defaultFromYear = getDefaultStartYear()
    const connectedUser = useSelector(getConnectedFamilyMember)
    const hasEcAccount = useSelector(getHasEcAccount)
    const personalData = useSelector(getFullPersonalData)
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const defaultEmail = personalData.email.data ?? ''
    const hasEmail = shouldDisplayEmail(defaultEmail)
    const defaultReceptionMode = null
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)
    const hasPendingRequest = useSelector(isThereStatementDetailsInRequests)

    const initialDefaultValues: StatementDetailsFormRHF = {
        fromYear: defaultFromYear,
        receptionMode: defaultReceptionMode,
        email: defaultEmail,
        updateEmail: false
    }

    const [defaultValues, setDefaultValues] =
        useState<StatementDetailsFormRHF>(initialDefaultValues)

    const contactDataLoadStatus = personalData.contactDataLoadStatus

    useEffect(() => {
        if (!connectedUser) return
        dispatch(
            fetchContactInfos({
                selectedPolicyNumber: connectedUser.policyNumber
            })
        )
    }, [connectedUser])

    const onSuccess = (values: StatementDetailsFormRHF) => {
        const statementDetailsForm: StatementDetailsForm = {
            ...values,
            ...familyGroupResult
        }
        dispatch(onSubmitStatementDetails(statementDetailsForm))
    }

    useEffect(() => {
        setDefaultValues({
            ...defaultValues,
            email: personalData.email.data,
            receptionMode: hasEcAccount
                ? ServicesReceptionMode.ELECTRONIC
                : ServicesReceptionMode.MAIL
        })
    }, [personalData, hasEcAccount])

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.CDEC_REQUEST}
            formTitle={{
                title: t('SERVICES.STATEMENT_DETAILS'),
                category: t('SERVICES.PAYMENT_AND_INVOICING')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            shouldDisplaySubmitButton={contactDataLoadStatus === LoadingStatusEnum.OK}
            onSuccess={onSuccess}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.CDEC_REQUEST} />}
            isPending={hasPendingRequest ? <PendingRequest /> : undefined}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    contactDataLoadStatus,
                    missingDataLoadingStatus
                ]),
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactInfos({
                            selectedPolicyNumber: connectedUser?.policyNumber as number,
                            reload: true
                        })
                    )
            }}
        >
            <>
                <YearSelector
                    label={t('SERVICES.FROM_JANUARY')}
                    name="fromYear"
                    testId="statement-details-from-year"
                    oldestYear={2023}
                />
                <ReceptionMode
                    hasEcAccount={hasEcAccount}
                    hasRegularMail
                    hasEmail={hasEmail}
                    defaultEmail={defaultEmail}
                />
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    StatementDetails,
    'StatementDetails',
    'statement-details-container'
)
