/* eslint-disable sonarjs/cognitive-complexity */
import './AutoCompleteRHF.css'

import { useEffect, useRef, useState } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import { RegisterOptions, useFormContext, ValidationValueMessage } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import useOnClickOutside from 'core/services/useOnClickOutside'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'

import { ErrorMessage } from '@hookform/error-message'

// Validations implémentées, si besoin d'une autre, ajouter au Pick et mettre à jour la méthode getRules
type AssuraAutoCompleteAllowedValidations = Pick<
    RegisterOptions,
    'required' | 'minLength' | 'maxLength'
>

/*********************
 * Pour forcer l'utilisateur à cliquer sur un résultat, il faut ajouter la key 'shouldMatch' aux rules:
 * Le formulaire sera en erreur avec message renseigné (value de shouldMatch) si la valeur n'est pas issue
 * d'un click dans la liste et si les autres validations de rules sont respectées
 *********************/

type AssuraAutoCompleteRules = AssuraAutoCompleteAllowedValidations & {
    shouldMatch?: string
}

export interface AutoCompleteRHFResult<T> {
    label: string
    value: T
}

/**
 * Added the extends string | number in order to avoid following error:
 * Issues checking in progress...
 * ERROR in ...AutoCompleteRHFProps.tsx
 * TS2589: Type instantiation is excessively deep and possibly infinite.
 */
export interface AutoCompleteRHFProps<T extends string | number> {
    id: string
    name: string
    charMinToStartSearch?: number
    results: AutoCompleteRHFResult<T>[]
    handleOnChange: (value: string) => void
    handleReset?: () => void
    placeHolder?: string
    rules: AssuraAutoCompleteRules
    loadingStatus: LoadingStatusEnum

    defaultValue?: string
    onResultSelection?: (selection: AutoCompleteRHFResult<T>) => void
}

const AutoCompleteRHF = <T extends string | number>({
    id,
    name,
    charMinToStartSearch = 2,
    results,
    handleOnChange,
    handleReset,
    placeHolder,
    loadingStatus,
    rules,
    defaultValue,
    onResultSelection
}: AutoCompleteRHFProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [input, setInput] = useState<string>('')

    useEffect(() => {
        setInput((_o) => {
            return defaultValue !== undefined ? defaultValue : ''
        })
    }, [defaultValue])

    const shouldMatch = rules.shouldMatch
    const {
        register,
        formState: { errors, isSubmitted },
        setValue,
        clearErrors,
        trigger
    } = useFormContext()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.currentTarget.value
        if (currentValue.length >= charMinToStartSearch) {
            if (!isOpen) {
                setIsOpen(true)
            }
            handleOnChange(currentValue)
        } else {
            handleCloseResults()
        }
        setValue(name, shouldMatch ? null : currentValue)
        setInput(currentValue)
        handleValidation()
    }

    const onClick = (item: AutoCompleteRHFResult<T>) => {
        setIsOpen(false)
        setInput(item.label)
        setValue(name, item.value)
        clearErrors(name)
        onResultSelection && onResultSelection(item)
    }

    const isInvalid = Boolean(errors[name])

    const getRules = () => {
        const currentValue = inputRef.current?.value

        return {
            validate: (value: T) => {
                if (rules && !value) {
                    if (currentValue) {
                        if (
                            rules.minLength &&
                            currentValue.length <
                                (rules.minLength as ValidationValueMessage<number>).value
                        )
                            return (rules.minLength as ValidationValueMessage).message
                        if (rules.shouldMatch) return rules.shouldMatch
                    } else if (rules.required) {
                        return (rules.required as ValidationValueMessage).message
                    }
                } else {
                    return true
                }
            }
        }
    }

    const handleValidation = () => {
        if (shouldMatch) register(name, { ...getRules() })
        if (isSubmitted) trigger(name)
    }

    const handleCloseResults = () => {
        setIsOpen(false)
        if (handleReset) {
            handleReset()
        }
    }

    const handleOutsideClick = () => {
        if (isOpen) {
            handleCloseResults()
        }
    }

    useOnClickOutside(wrapperRef, handleOutsideClick)

    const displayResults = (): JSX.Element | JSX.Element[] => {
        if (results.length > 0) {
            return results.map((result, index) => (
                <div
                    className="auto-complete-results-item labelSmall"
                    onClick={() => onClick(result)}
                    data-testid={`${id}-item-${index}`}
                    key={`${id}-item-${index}`}
                >
                    {result.label}
                </div>
            ))
        } else {
            if (shouldMatch) {
                return (
                    <div className="no-results-item labelSmall" data-testid={`${id}-no-results`}>
                        {t('SERVICES.AUTO_COMPLETE_NO_RESULT')}
                    </div>
                )
            } else {
                return <></>
            }
        }
    }

    return (
        <div className="d-flex flex-column auto-complete-container flex-1" ref={wrapperRef}>
            <input
                id={id}
                {...register(name, shouldMatch ? { ...getRules() } : { ...rules })}
                data-testid={id}
                className={`label form-control${isInvalid ? ' is-invalid' : ''}`}
                onChange={onChange}
                value={input}
                placeholder={placeHolder}
                ref={inputRef}
                autoComplete="off"
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <FormControl.Feedback type="invalid">
                        <div
                            key={`field-error`}
                            className="labelExtraSmall"
                            data-testid={id + '-error'}
                        >
                            {message}
                        </div>
                    </FormControl.Feedback>
                )}
            />
            {isOpen && (
                <div
                    className="auto-complete-results-container bg-white"
                    data-testid={`${id}-results-container`}
                >
                    <AssuraLoadAndError
                        status={loadingStatus}
                        defaultReloadAction={() => handleOnChange(input)}
                        shouldDisplayContainer
                        activityIndicatorSize={24}
                    >
                        {displayResults()}
                    </AssuraLoadAndError>
                </div>
            )}
        </div>
    )
}

export default AutoCompleteRHF
