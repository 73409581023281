import { AccountNumberChecked } from 'core/models/services/financial/PaymentChange'

// Check CCP and format value (2 digits "-" 6 digits "-" 1 digit) if CCP is valid
export default function checkCcp(field: string | null): AccountNumberChecked {
    const rules = new Map([
        ['format', false],
        ['content', false],
        ['validity', false]
    ])
    const res = { rules, value: field }

    if (field !== null) {
        // Check structure
        res.rules.set('format', new RegExp('^\\d{2}-\\d{1,6}-\\d{1}$').test(field))

        // Check content
        const onlyDigits = new RegExp('^\\d+$').test(field.replace(/-/g, ''))

        const aryCcp = field.split('-')
        const checkCcpMinusOk = aryCcp.length === 3

        res.rules.set('content', checkCcpMinusOk && onlyDigits)

        // Check checksum
        if (res.rules.get('format')) {
            // complete central part with '0' (length must be 6)
            while (aryCcp[1].length < 6) aryCcp[1] = '0' + aryCcp[1]

            // compute checksum
            const perm = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5]
            let pos = 0
            let result = '0'
            const num = aryCcp[0] + aryCcp[1]
            for (let n = 0; n < num.length; n++) {
                const idx = num.charCodeAt(n) - 48
                pos = perm[(pos + idx) % 10]
                result += pos
            }
            result += (10 - pos) % 10

            const checkCcpValid = result.charAt(9) === aryCcp[2]

            res.rules.set('validity', checkCcpValid)

            if (res.rules.get('validity')) res.value = aryCcp[0] + '-' + aryCcp[1] + '-' + aryCcp[2]
        }
    }

    return res
}

export const formatCcp = (number: string): string => {
    if (number.length === 9) {
        return `${number.slice(0, 2)}-${number.slice(2, 8)}-${number.slice(8)}`
    } else {
        return number
    }
}
