import { useSelector } from 'react-redux'

import { InvoicesToCheckTranslatedStatus } from 'core/enums/InvoicesToCheck'
import { InvoicesToCheckFilterSelectionValue } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import { TableFilterSelections, TableFilterState } from 'core/models/TableFilter'
import { getPolicyNumberSelectionsForFilter } from 'shared/store/selectors/getPolicyNumberSelectionsForFilter'

const InvoicesToCheckTableFilter = (
    defaultPolicyNumber?: number
): TableFilterState<InvoicesToCheckFilterSelectionValue> => {
    const filters: TableFilterState<InvoicesToCheckFilterSelectionValue> = {}

    const memberSelections = useSelector(getPolicyNumberSelectionsForFilter)
    const newSelections = memberSelections ? new Map(memberSelections) : null

    if (newSelections) {
        if (defaultPolicyNumber) {
            const defaultSelection = Array.from(newSelections.entries()).find(
                ([_key, item]) => item.value === defaultPolicyNumber
            )
            if (defaultSelection)
                newSelections.set(defaultSelection[0], {
                    ...defaultSelection[1],
                    isSelected: true
                })
        }
        filters['member'] = {
            label: 'COMMON_INSURED',
            selections: newSelections
        }
    }

    const statusSelections: TableFilterSelections<InvoicesToCheckTranslatedStatus> = new Map([
        [
            InvoicesToCheckTranslatedStatus.checked,
            {
                label: InvoicesToCheckTranslatedStatus.checked,
                value: InvoicesToCheckTranslatedStatus.checked,
                isSelected: false,
                shouldBeTranslated: true
            }
        ],
        [
            InvoicesToCheckTranslatedStatus.toCheck,
            {
                label: InvoicesToCheckTranslatedStatus.toCheck,
                value: InvoicesToCheckTranslatedStatus.toCheck,
                isSelected: false,
                shouldBeTranslated: true
            }
        ]
    ])

    filters['status'] = {
        label: 'COMMUNICATIONS.INVOICES_TO_CHECK.STATUS_FILTER_LABEL',
        selections: statusSelections
    }

    return filters
}

export default InvoicesToCheckTableFilter
