import './EnveloppesTableRow.css'

import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import StatusIconField from 'shared/components/AssuraTable/Fields/StatusIconField'

import EnveloppesTableDocumentsField from './EnveloppesTableDocumentsField'
import EnveloppesTableRowMobile from './EnveloppesTableRowMobile'

interface EnveloppesTableRowProps {
    row: EnveloppeRow
}

const EnveloppesTableRow = ({ row }: EnveloppesTableRowProps): JSX.Element => {
    const { isXL } = useWindowSize()

    const rowId = `enveloppes-${row.index}`

    return isXL ? (
        <>
            <StatusIconField statusColor={row.statusColor} rowId={rowId} />
            <DateField
                date={row.receiveDate ?? ''}
                rowId={rowId}
                classNames="label enveloppes-table-row-date"
            />
            <EnveloppesTableDocumentsField
                enveloppe={row}
                rowId={rowId}
                labelClassName="label"
                shouldDisplayName
            />
            <StatusField
                status={row.statusTranslated}
                statusIcon={row.statusIcon}
                statusColor={row.statusColor}
                rowId={rowId}
                classNames="enveloppes-table-row-status"
                labelClassNames="labelSmall"
            />
            <GoToField rowId={rowId} marginLeft={40} />
        </>
    ) : (
        <EnveloppesTableRowMobile row={row} />
    )
}

export default EnveloppesTableRow
