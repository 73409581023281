import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { QuickAccessCms } from 'core/models/cms/QuickAccessCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface QuickAccessState {
    quickAccessCms: QuickAccessCms[]
    quickAccessLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_QUICKACCESS: QuickAccessState = {
    quickAccessCms: [],
    quickAccessLoadingStatus: LoadingStatusEnum.LOADING
}
export const quickAccessSlice = createSlice({
    name: 'quickAccess',
    initialState: INITIAL_STATE_QUICKACCESS,
    reducers: {
        fetchQuickAccessCms: (state) => {
            return state
        },
        fetchQuickAccessCmsSuccess: (state, action: PayloadAction<QuickAccessCms[]>) => {
            state.quickAccessCms = action.payload
        },
        setQuickAccessLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.quickAccessLoadingStatus = action.payload
        }
    }
})

export const {
    fetchQuickAccessCms,
    fetchQuickAccessCmsSuccess,
    setQuickAccessLoadingStatus
} = quickAccessSlice.actions

export default quickAccessSlice.reducer

export const getQuickAccessCms: Selector<RootState, QuickAccessCms[]> = (state) =>
    state.quickAccess.quickAccessCms

export const getQuickAccessLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.quickAccess.quickAccessLoadingStatus
