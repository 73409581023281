import NavigationConstants, { SERVICES_FORM_ROUTES } from '../constants/navigationConstants'
import { NavigationRoute } from '../models/NavigationRoute'

export const getServiceRoute = (route: string): NavigationRoute | undefined => {
    return Object.values(SERVICES_FORM_ROUTES).find((serviceRoute) => serviceRoute.url === route)
}

export const getECRoute = (route: string): NavigationRoute | undefined => {
    return Object.values(NavigationConstants).find((ecRoute) => ecRoute.url === route)
}

export const getNavigationRoute = (route: string): NavigationRoute | undefined => {
    return getECRoute(route) || getServiceRoute(route)
}
