import { ServicesAddress } from 'core/models/services/personalData/Address'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const addressSlice = createSlice({
    name: 'address',
    initialState: {},
    reducers: {
        onSubmitAddress: (state, _action: PayloadAction<ServicesAddress>) => {
            return state
        }
    }
})

export const { onSubmitAddress } = addressSlice.actions

export default addressSlice.reducer
