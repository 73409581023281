import analyticsConstants from 'core/constants/analyticsConstants'
import { SymptomCheckerEcLinkId } from 'core/constants/ecLinkIds'
import { ButtonOrigin, LinkTitle } from 'core/enums/AnalyticsEnums'
import { RequestSource } from 'core/enums/RequestSource'
import { ServiceType } from 'core/enums/ServiceType'
import { Assets, AssetUrlCms } from 'core/models/cms/AssetCms'
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import { PersonSummaryWithPendingRequest } from 'core/models/services/PersonSummaryWithPendingRequest'
import { RequestData, RequestRow } from 'core/models/services/RequestData'
import { ServicesHubItem } from 'core/models/services/ServicesHub'
import ServiceAccident from 'shared/assets/images/Service-accident.svg'
import ServiceContract from 'shared/assets/images/Service-contract.svg'
import ServiceDocument from 'shared/assets/images/Service-document.svg'
import ServiceFather from 'shared/assets/images/Service-father.svg'
import ServicePaiements from 'shared/assets/images/Service-paiements.svg'
import SymptomChecker from 'shared/assets/images/symptom_checker.svg'
import { checkHasPending } from 'shared/store/selectors/isThereInRequests'

import { sendEvent } from '../AnalyticsHelper'
import { mapStatusColor, mapStatusIcon, mapStatusTranslation } from './StatusHelper'

export const mapRequestsToRows = (
    requests: RequestData[],
    personNames: EnveloppePerson[]
): RequestRow[] => {
    const shouldHideMigratedData = process.env.REACT_APP_SHOW_MIGRATED_DATA?.toString() === 'FALSE'

    return requests
        ? requests.map((request: RequestData, index: number) => {
              return {
                  id: request.id,
                  index: index,
                  status: request.status,
                  statusColor: mapStatusColor(request.status),
                  creationDate: request.creationDate,
                  statusIcon: mapStatusIcon(request.status),
                  statusTranslated: mapStatusTranslation(request.status, request.processingDate),
                  insuredPersonName:
                      personNames?.find((person) => person.policyNumber === request.policyNumber)
                          ?.personName ?? '',
                  insuredPersonLastName:
                      personNames?.find((person) => person.policyNumber === request.policyNumber)
                          ?.personLastName ?? '',
                  insuredPersonGender:
                      personNames?.find((person) => person.policyNumber === request.policyNumber)
                          ?.personGender ?? '',
                  typeTranslated: mapServiceTypeTranslation(request.type),
                  processingDate: request.processingDate,
                  policyNumber: request.policyNumber,
                  documents: request.documents ?? [],
                  source: request.source,
                  shouldHideData:
                      shouldHideMigratedData && request.source === RequestSource.MIGRATION
              }
          })
        : []
}

const mapServiceTypeTranslation = (type: ServiceType): string => {
    const serviceTypeKey = Object.keys(ServiceType).find((key) => {
        return ServiceType[key as keyof typeof ServiceType] === type
    })
    if (serviceTypeKey) {
        return `SERVICES.REQUEST_TYPE_${serviceTypeKey}`
    } else {
        return 'SERVICES.REQUEST_TYPE_UNKNOWN'
    }
}

export const getPersonsWithPendingRequest = (
    requests: RequestData[],
    type: ServiceType,
    persons: PersonSummary[]
): PersonSummaryWithPendingRequest[] => {
    const personsToReturn: PersonSummaryWithPendingRequest[] = []
    persons?.forEach((person) => {
        const hasPendingRequest = requests.find((request) =>
            checkHasPending(request, person.policyNumber, type)
        )
        personsToReturn.push({ ...person, hasPendingRequest: !!hasPendingRequest })
    })
    return personsToReturn
}

export const PharmaFile = (code: string, assets: Assets): AssetUrlCms => {
    switch (code) {
        case 'RMd':
            return assets.Pharmacies_ReseauDeSoins
        case 'ACL':
            return assets.Pharmacies_Assuracall
        default:
            return assets.Pharmacies_Pharmed
    }
}

export const getServices = (
    isSymptomCheckerCustomerPortalActivated: string | undefined = 'false'
): ServicesHubItem[] => {
    const LINK_ICON = (icon: string): string => {
        switch (icon) {
            case 'pdf':
                return 'assura-pdf'
            case 'external':
                return 'assura-external-link'
            case 'user':
                return 'assura-profile'
            default:
                return 'assura-right'
        }
    }

    const sendDocDownload = (link: string) => {
        sendEvent(analyticsConstants.EVENTS.DOC_DOWNLOAD, {
            [analyticsConstants.PARAMS.HREF]: link
        })
    }

    const symptomCheckerDiscoveryLink = {
        ecLinkId: SymptomCheckerEcLinkId,
        title: 'SYMPTOM_CHECKER.DISCOVERY_CARD_TITLE',
        icon: LINK_ICON('internal')
    }

    const symptomCheckerToolsLink = {
        ecLinkId: SymptomCheckerEcLinkId,
        title: 'SERVICE.SYMPTOM_CHECKER',
        icon: LINK_ICON('internal')
    }

    const data: ServicesHubItem[] = [
        {
            id: '01',
            title: 'SERVICE.DATA_MODIFY',
            icon: ServiceFather,
            message: 'SERVICES.REQUESTS_CARD_DATA_MODIFY_MESSAGE',
            links: [
                {
                    ecLinkId: '64639307fc453a5adc0a53c1',
                    title: 'SERVICE.PERSONAL_FINANCIAL_DATA_MODIFY',
                    icon: LINK_ICON('user')
                },
                {
                    ecLinkId: '646393278d334edee608c015',
                    title: 'SERVICE.CHANGE_LOGIN_DETAILS',
                    icon: LINK_ICON('user')
                },
                {
                    ecLinkId: '6461df21245786afe40315b2',
                    title: 'SERVICE.QUESTION_MODIFICATION_FAMILY_REUNIFICATION',
                    icon: LINK_ICON('internal')
                }
            ]
        },
        {
            id: '02',
            title: 'SERVICE.SUBSCRIBE_OFFER',
            icon: ServiceDocument,
            message: 'SERVICES.REQUESTS_CARD_CONTRACTS_MANAGEMENT_MESSAGE',
            links: [
                {
                    ecLinkId: '64638e2721673765340699e2',
                    title: 'SERVICE.CHANGE_MODEL_FRANCHISE',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638e3efc453a5adc0a53b2',
                    title: 'SERVICE.ACCIDENT_RISK_INCLUSION_SUSPENSION',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638e81fc453a5adc0a53b5',
                    title: 'SERVICE.CHANGE_FAMILY_DOCTOR',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '646391c0ec5ba3566e00d4e5',
                    title: 'SERVICE.SUBSCRIBE_CONTTRACT',
                    icon: LINK_ICON('external'),
                    callback: (href: string) => {
                        sendEvent(analyticsConstants.EVENTS.OFFER_SUGGEST_CLICK, {
                            [analyticsConstants.PARAMS.HREF]: href,
                            [analyticsConstants.PARAMS.LINK_TITLE]: LinkTitle.NEW_OFFER,
                            [analyticsConstants.PARAMS.BUTTON_ORIGIN]: ButtonOrigin.DEMARCHES
                        })
                    }
                }
            ]
        },
        {
            id: '03',
            title: 'SERVICE.DECLARE_CLAIM_ACCIDENT_EVENT',
            icon: ServiceAccident,
            message: 'SERVICES.REQUESTS_CARD_ACCIDENT_MESSAGE',
            links: [
                {
                    ecLinkId: '64638fdbb0b09225b701fd95',
                    title: 'SERVICE.CLAIM_ABROAD_REPORT',
                    icon: LINK_ICON('pdf'),
                    callback: sendDocDownload
                },
                {
                    ecLinkId: '64a2d6e66b6dfedf43050162',
                    title: 'SERVICE.TRIP_CANCEL',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638ed6534b2a4c54065182',
                    title: 'SERVICE.HOSP_ANNOUNCEMENT',
                    icon: LINK_ICON('internal')
                }
            ]
        },
        {
            id: '04',
            title: 'SERVICE.CERTIFICATES_INSURANCE_CARD',
            icon: ServiceContract,
            message: 'SERVICES.REQUESTS_CARD_CERTIFICATES_MESSAGE',
            links: [
                {
                    ecLinkId: '64638eeffc453a5adc0a53b8',
                    title: 'SERVICE.TAXES_MEDICAL_EXPENSES',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638f0b534b2a4c54065185',
                    title: 'SERVICE.TAXES_DETAILS_PREMIUMS_INVOICED',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638f24fbf14b3565055dc2',
                    title: 'SERVICE.REQUEST_INSURANCE_CERTIFICATE',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638f99b0b09225b701fd92',
                    title: 'SERVICES.CARD_ORDER',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638fb4fc453a5adc0a53bb',
                    title: 'SERVICE.REQUEST_PROVISIONAL_REPLACEMENT_CERTIFICATE',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638f5f5afb28b49a040237',
                    title: 'SERVICES.DELEGATION_LINK',
                    icon: LINK_ICON('internal')
                },
                {
                    ecLinkId: '64638f3fcc796c009008f452',
                    title: 'SERVICES.STATEMENT_DETAILS',
                    icon: LINK_ICON('internal')
                }
            ]
        },
        {
            id: '05',
            title: 'SERVICE.PAIEMENT_BILLING',
            icon: ServicePaiements,
            message: 'SERVICES.REQUESTS_CARD_PAIEMENT_MESSAGE',
            links: [
                {
                    ecLinkId: '64639052751301b2340e7897',
                    title: 'SERVICE.LSV_PAIEMENT_REQUEST',
                    icon: LINK_ICON('pdf'),
                    callback: sendDocDownload
                },
                {
                    ecLinkId: '64639080751301b2340e789a',
                    title: 'SERVICE.DIRECT_DEBIT_PAIEMENT_REQUEST',
                    icon: LINK_ICON('pdf'),
                    callback: sendDocDownload
                },
                {
                    ecLinkId: '64638e9b5afb28b49a040234',
                    title: 'SERVICES.PAYMENT_FREQUENCY',
                    icon: LINK_ICON('internal')
                }
            ]
        },
        {
            id: '06',
            title: '',
            icon: '',
            message: '',
            links: [
                {
                    ecLinkId: '6463909dfc453a5adc0a53be',
                    title: 'DOCUMENTS.COVER_PAGE_MEDICAL_EXPENSES',
                    icon: LINK_ICON('pdf')
                },
                {
                    ecLinkId: '646390cb5afb28b49a04023a',
                    title: 'DOCUMENTS.COVER_PAGE_OTHER_CORRESPONDENCE',
                    icon: LINK_ICON('pdf')
                },
                {
                    ecLinkId: '64639169ec5ba3566e00d4e2',
                    title: 'SERVICE.ASSURA_APP',
                    icon: LINK_ICON('external')
                },
                {
                    ecLinkId: '646391065afb28b49a04023d',
                    title: 'SERVICE.DISCOVER_BASIC_INSURANCE',
                    icon: LINK_ICON('external'),
                    callback: (link: string) => {
                        sendEvent(analyticsConstants.EVENTS.OFFER_SUGGEST_CLICK, {
                            [analyticsConstants.PARAMS.HREF]: link,
                            [analyticsConstants.PARAMS.LINK_TITLE]: LinkTitle.SEE_LAMAL
                        })
                    }
                },
                {
                    ecLinkId: '646390e9970ee2076806ee52',
                    title: 'SERVICE.DISCOVER_COMPLEMENTTARY_INSURRANCES',
                    icon: LINK_ICON('external'),
                    callback: (link: string) => {
                        sendEvent(analyticsConstants.EVENTS.OFFER_SUGGEST_CLICK, {
                            [analyticsConstants.PARAMS.HREF]: link,
                            [analyticsConstants.PARAMS.LINK_TITLE]: LinkTitle.SEE_LCA
                        })
                    }
                },
                symptomCheckerDiscoveryLink
            ]
        },
        {
            id: '07',
            title: 'SERVICE.TOOLS',
            icon: SymptomChecker,
            message: 'SERVICES.REQUESTS_CARD_TOOLS_MESSAGE',
            links: [symptomCheckerToolsLink]
        }
    ]

    const accidentCardIndex = data.findIndex((card) => card.id === '03')

    if (accidentCardIndex) {
        if (process.env.REACT_APP_SHOW_ACCIDENT_REPORT?.toString() !== 'TRUE') {
            data[accidentCardIndex].links.unshift({
                ecLinkId: '64639024b0b09225b701fd98',
                title: 'SERVICE.ACCIDENT_REPORT',
                icon: LINK_ICON('pdf'),
                callback: sendDocDownload
            })
        } else {
            data[accidentCardIndex].links.unshift({
                ecLinkId: '64638eb8763dd5b42000a6c2',
                title: 'SERVICE.ACCIDENT_REPORT',
                icon: LINK_ICON('internal')
            })
        }
    }

    const offerCardIndex = data.findIndex((card) => card.id === '02')

    if (offerCardIndex && process.env.REACT_APP_SHOW_AVENTURA?.toString() === 'TRUE') {
        data[offerCardIndex].links.push({
            ecLinkId: '64638f7ccc796c009008f455',
            title: 'SERVICE.AVENTURA_INSURANCE_MENU_TITLE',
            icon: LINK_ICON('internal')
        })
    }

    const discoveryCardIndex = data.findIndex((card) => card.id === '06')
    const toolsCardIndex = data.findIndex((card) => card.id === '07')

    if (isSymptomCheckerCustomerPortalActivated === 'false') {
        const symptomCheckerDiscoveryLinkIndex = data[discoveryCardIndex].links.indexOf(
            symptomCheckerDiscoveryLink
        )
        data[discoveryCardIndex].links.splice(symptomCheckerDiscoveryLinkIndex)

        const symptomCheckerToolsLinkIndex =
            data[toolsCardIndex].links.indexOf(symptomCheckerToolsLink)
        data[toolsCardIndex].links.splice(symptomCheckerToolsLinkIndex)
    }

    if (data[toolsCardIndex].links.length === 0) {
        data.splice(toolsCardIndex)
    }

    return data
}
