import { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { EmailFromForm } from 'core/models/services/personalData/ContactInfos'
import useWindowSize from 'core/services/useWindowSize'
import { emailRules } from 'core/utils/TextUtils'
import { AssuraFormInputIconButtonRHF } from 'modules/services/components/InputRHF/InputRHF'
import {
    removeAllNonPermanentBanners,
    removeBanner,
    setBanner,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { EmailView } from 'shared/store/selectors/getFullPersonalData'
import { postEmail } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getServiceSubmitStatus } from 'shared/store/services/services.slice'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'
import PersonalDataValidation from '../PersonalDataValidation/PersonalDataValidation'

interface EmailProps {
    email: EmailView
}

const Email = ({ email }: EmailProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const dispatch = useDispatch()
    const isEnabledRef = useRef<boolean>(false)
    const submitServiceStatus = useSelector(getServiceSubmitStatus)
    const invalidFormBannerId = 'personal-data-email'

    const {
        state: { selectedPolicyNumber, isRequestsAvailable, isOnSuccess },
        isFormSelected,
        selectForm,
        setNoChangeError,
        removeNoChangeError
    } = usePersonalDataContext()

    const isEnabled = isFormSelected(PersonalDataForm.EMAIL)

    const defaultValues = {
        newEmail: email.data ?? ''
    }

    const methods = useForm<EmailFromForm>({
        defaultValues
    })
    const { handleSubmit, formState, setValue, register, reset, getValues } = methods
    const { errors, isDirty, isSubmitted } = formState

    const handleCancel = () => {
        reset(defaultValues)
        dispatch(removeBanner(invalidFormBannerId))
    }

    const handleResetAction = () => {
        setValue('newEmail', '', {
            shouldValidate: isSubmitted
        })
    }

    const onSubmit = () => {
        if (submitServiceStatus !== LoadingStatusEnum.LOADING) {
            if (isDirty) {
                if (!hasWritePermission()) {
                    dispatch(setForbiddenBanner())
                    return
                }

                const newEmail = getValues('newEmail')
                dispatch(
                    postEmail({
                        newEmail
                    })
                )
                reset({ newEmail })
            } else {
                dispatch(removeBanner(invalidFormBannerId))
                setNoChangeError()
            }
        }
    }

    useEffect(() => {
        if (isEnabledRef.current && !isEnabled && !isOnSuccess) {
            handleCancel()
        }
        isEnabledRef.current = isEnabled
    }, [isEnabled])

    useEffect(() => {
        if (selectedPolicyNumber) handleCancel()
    }, [selectedPolicyNumber])

    useEffect(() => {
        if (isEnabled && formState.isSubmitted) {
            if (Object.keys(formState.errors).length > 0) {
                removeNoChangeError()
                dispatch(
                    setBanner({
                        id: invalidFormBannerId,
                        dataTestId: invalidFormBannerId,
                        message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
                    })
                )
            } else if (isDirty) {
                dispatch(removeAllNonPermanentBanners())
            }
        }
    }, [formState])

    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    return (
        <FormProvider {...methods}>
            <form>
                <AssuraFormInputIconButtonRHF
                    name="newEmail"
                    inline={isXL}
                    labelSize={4}
                    label={t('PERSONAL_DATA.EMAIL')}
                    id={`personal-data-email-${email.isPending ? 'is-pending' : 'edit'}`}
                    isInvalid={Boolean(errors.newEmail)}
                    error={t(errors.newEmail?.message as string)}
                    hasButton={
                        isRequestsAvailable && missingDataLoadingStatus === LoadingStatusEnum.OK
                    }
                    onButtonClick={() =>
                        !isEnabled ? selectForm(PersonalDataForm.EMAIL) : handleResetAction()
                    }
                    icon={!isEnabled ? 'assura-edit' : 'assura-close'}
                    buttonLabel={!isEnabled && isXL ? t('COMMON.MODIFY') : ''}
                    rules={emailRules}
                    disabled={!isEnabled}
                    register={register}
                    variant={!isEnabled ? 'primary' : 'default'}
                    isPending={email.isPending}
                    placeHolder={t('PERSONAL_DATA.EMAIL_PLACEHOLDER')}
                />
                <PersonalDataValidation
                    form={PersonalDataForm.EMAIL}
                    onSubmit={handleSubmit(onSubmit)}
                    classNames="m-top-48"
                />
            </form>
        </FormProvider>
    )
}

export default Email
