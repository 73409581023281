import { TableFilterSelections } from 'core/models/TableFilter'

import { createSelector } from '@reduxjs/toolkit'

import { getSummaryCurrentYear } from './getSummaryCurrentYear'

export const getUtnSelectionsForFilter = createSelector([getSummaryCurrentYear], (summary) => {
    const familyGroup = summary.insuredPersons

    if (familyGroup.length > 1) {
        const utnFilters: TableFilterSelections<number> = new Map()

        familyGroup.forEach((member, index) =>
            utnFilters.set(`member${index}`, {
                label: member.firstName,
                value: member.userTiersNumber,
                isSelected: false
            })
        )
        return utnFilters
    } else {
        return null
    }
})
