import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { AnswerBulletPointValue } from 'core/models/cms/FaqCms'
import { FaqCmsLocalized } from 'core/models/cms/FaqCmsLocalized'
import {
    GeneralQuestions,
    GeneralQuestionTopic,
    GeneralQuestionTopicLabel
} from 'core/models/cms/GeneralQuestionTopicCms'
import { GeneralQuestionTopicCmsLocalized } from 'core/models/cms/GeneralQuestionTopicCmsLocalized'
import { Faq } from 'core/models/Faq'

import { createSelector } from '@reduxjs/toolkit'

import { getFaqCms } from '../faq/faq.slice'
import { getLang } from '../general/general.slice'
import { getGeneralQuestionTopic } from '../generalQuestionTopic/generalQuestionTopic.slice'

export const getGeneralQuestions = createSelector(
    [getGeneralQuestionTopic, getFaqCms, getLang],

    (generalQuestionTopic, faqCms, lang): GeneralQuestionTopicLabel[] => {
        const faqQs: Faq[] = []
        // prepare faq questions translated
        for (const f of faqCms.filter((faqCms) => faqCms.is_active)) {
            const localizedItem: FaqCmsLocalized = getLocalizedObject(f, lang)

            const answer: AnswerBulletPointValue[] = localizedItem.answer?.map((a) => a.value) || []
            const answer_website: AnswerBulletPointValue[] =
                localizedItem.answer_website?.map((a) => a.value) || []

            const item: Faq = {
                _id: f._id,
                id: localizedItem.id,
                order: parseInt(localizedItem.order),
                question: localizedItem.question,
                faqCategory: {
                    _id: '-1',
                    id: '-1',
                    title: 'N/A'
                },
                answer: answer.map((answer) => ({
                    ...answer,
                    images: answer.images ? answer.images.map((imgValue) => imgValue.value) : []
                })),
                answer_website: answer_website.map((answer_website) => ({
                    ...answer_website,
                    images: answer_website.images
                        ? answer_website.images.map((imgValue) => imgValue.value)
                        : []
                }))
            }
            faqQs.push(item)
        }

        return generalQuestionTopic
            .filter((item: GeneralQuestionTopic) => item.is_active)
            .map((gqt) => {
                const localizedGqt: GeneralQuestionTopicCmsLocalized = getLocalizedObject(gqt, lang)
                const formsCollection = localizedGqt?.forms_collection?.map((o) => o?.value) ?? []
                const faqMainCollection = localizedGqt?.faq_collection?.map((o) => o?.value) ?? []

                const faqFinalCollection: Faq[] = []
                for (const faqItemId of faqMainCollection.map((o) => o?.faq?._id)) {
                    const fc = faqQs.find((o) => o._id === faqItemId)
                    fc && faqFinalCollection.push(fc)
                }

                return {
                    _id: localizedGqt._id,
                    id: localizedGqt.id,
                    topic: localizedGqt.topic,
                    order: Number(localizedGqt.order),
                    forms_icon: localizedGqt.forms_icon,
                    forms_title: localizedGqt.forms_title,
                    forms_collection: formsCollection,
                    faq_icon: localizedGqt.faq_icon,
                    faq_title: localizedGqt.faq_title,
                    faq_collection: faqFinalCollection.sort(
                        (a, b) => a && b && Number(a) - Number(b)
                    )
                } as GeneralQuestions
            })
            .sort((a, b) => a.order - b.order)
    }
)
