import './PickerFiveYears.css'

import { useRef, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useOnClickOutside from 'core/services/useOnClickOutside'

interface PickerFiveYearsProps {
    placeholder?: string
    testId: string
    name: string
    rules: RegisterOptions
    oldestyear?: number
}

const PickerFiveYears = ({
    placeholder,
    testId,
    name,
    rules,
    oldestyear: oldestYear
}: PickerFiveYearsProps): JSX.Element => {
    const { t } = useTranslation()

    const pickerRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState(false)

    const actualYear = new Date().getFullYear()
    const oldestYearFor = oldestYear && oldestYear > actualYear - 4 ? oldestYear : actualYear - 4

    const years = []
    for (let i = actualYear; i >= oldestYearFor; i--) {
        years.unshift(i)
    }

    const {
        setValue,
        trigger,
        watch,
        formState: { errors, isSubmitted }
    } = useFormContext()

    const isInError = Boolean(errors[name])

    const handleSelection = (year: number) => {
        setValue(name, year)
        if (isSubmitted) trigger(name)
        setIsOpen(false)
    }

    const userSelection = watch(name)

    const getPickerItem = (year: number): JSX.Element => {
        const isActive = year === userSelection

        return (
            <div
                className={`labelSmall picker-five-years-item${isActive ? ' active' : ''}`}
                onClick={() => handleSelection(year)}
                data-testid={`picker-five-years-${testId}-item-${year}`}
                key={`picker-five-years-${testId}-item-${year}`}
            >
                {year}
            </div>
        )
    }

    const picker = (
        <Popover id="picker-five-years-popover">
            <Popover.Title>
                <div className="text-center labelMedium c-white">
                    {`${years[0]} - ${years[years.length - 1]}`}
                </div>
            </Popover.Title>
            <Popover.Content>
                <div className="picker-five-years-container" ref={pickerRef}>
                    {years.map((year) => getPickerItem(year))}
                </div>
            </Popover.Content>
        </Popover>
    )

    const handleOutsideClick = () => {
        setIsOpen(false)
    }

    useOnClickOutside(pickerRef, handleOutsideClick)

    return (
        <Controller
            name={name}
            rules={rules}
            render={({ field: { ref, value, ...restField }, fieldState }) => (
                <>
                    <InputGroup>
                        <OverlayTrigger
                            trigger={['focus']}
                            placement="bottom"
                            overlay={picker}
                            flip
                            show={isOpen}
                        >
                            <input
                                id={`picker-five-years-${testId}-input`}
                                data-testid={`picker-five-years-${testId}-input`}
                                {...restField}
                                className={`label form-control picker-five-years-input${
                                    isInError ? ' is-invalid' : ''
                                }${value ? ' c-black' : ''}`}
                                readOnly
                                placeholder={placeholder}
                                type="text"
                                onClick={() => setIsOpen(true)}
                                value={value}
                                ref={ref}
                            />
                        </OverlayTrigger>
                        <InputGroup.Append className="assura-input-group-append">
                            <i
                                className="icon size-24 assura-calendar c-black m-right-8"
                                onClick={() => setIsOpen(true)}
                            />
                        </InputGroup.Append>
                    </InputGroup>
                    {fieldState.error?.message && (
                        <div
                            className="labelExtraSmall c-primary m-top-8"
                            data-testid={`picker-five-years-${testId}-error`}
                        >
                            {t(fieldState.error.message)}
                        </div>
                    )}
                </>
            )}
        />
    )
}

export default PickerFiveYears
