import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

export interface QualiMedRemindState {
    mustDisplay: boolean | null
}

const INITIAL_STATE_MIGRATION: QualiMedRemindState = {
    mustDisplay: null
}

export const qualiMedRemindSlice = createSlice({
    name: 'qualiMedRemind',
    initialState: INITIAL_STATE_MIGRATION,
    reducers: {
        fetchMustDisplaySuccess: (state, action: PayloadAction<QualiMedRemindState>) => {
            state.mustDisplay = action.payload.mustDisplay
        },
        onSubmitQualiMedRemindUpdate: (state, _action: PayloadAction) => {
            return state
        }
    }
})

export const { fetchMustDisplaySuccess, onSubmitQualiMedRemindUpdate } =
    qualiMedRemindSlice.actions

export const getQualiMedMustDisplay: Selector<RootState, boolean | null> = (state) =>
    state.qualiMedRemind.mustDisplay

export default qualiMedRemindSlice.reducer
