import error from 'shared/assets/images/error.png'

interface NotAllowedProps {
    title: string
    message: string | React.ReactElement
    link?: React.ReactElement
    dataTestId: string
}

const NotAllowed = ({ title, message, link, dataTestId }: NotAllowedProps): JSX.Element => {
    return (
        <div
            className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center"
            data-testid={`${dataTestId}-container`}
        >
            <img
                data-testid="not-allowed-error-image"
                src={error}
                className="image-size-56"
                alt="error-illustration"
            />
            <div className="headlineSmall m-top-24" data-testid={`${dataTestId}-title`}>
                {title}
            </div>
            <div
                className="labelSmallLight m-top-16 text-center"
                data-testid={`${dataTestId}-message`}
            >
                {message}
            </div>
            {link ?? null}
        </div>
    )
}

export default NotAllowed
