import './AssuraFormInputWithValidations.css'

import { useEffect, useRef, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { UseValidationOptions, ValidationResult } from 'core/validators/entities'
import { useFieldsContext } from 'shared/contexts/FieldsContext'

import InputErrors from './InputErrors'
import InputValidations from './InputValidations'
import PasswordToggle from './PasswordToggle'

export type FieldType = {
    isPassword: boolean
    isVisible: boolean
    type: string
}

export type InputWithValidationsProps = {
    label: string
    name: string
    dataTestId: string
    type?: string
    placeHolder: string
    // this will register te validation rules as parameter of the register function
    validationRules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    disabled?: boolean
    readOnly?: boolean
    validationResults: ValidationResult
    //TEMPORARY UNTIT WE REWORK Validations
    options?: UseValidationOptions
}

const AssuraFormInputWithValidations = ({ ...props }: InputWithValidationsProps) => {
    const { register, reset, formState, clearErrors } = useFormContext()
    const { errors } = formState
    const fieldsContext = useFieldsContext()

    const [isPristine, setIsPristine] = useState<boolean>(false)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [fieldType, setFieldType] = useState<FieldType>({
        type: props.type ? props.type : 'text',
        isPassword: props.type === 'password',
        isVisible: true
    })

    useEffect(() => {
        reset()
        clearErrors()
        const isPristineTemp = fieldsContext.isPristine(props.name)
        if (!isPristineTemp) return
        setIsPristine(isPristineTemp)
    }, [])

    const isFieldValid = (): boolean =>
        Object.entries(props.validationResults.validations).filter((o) => !o[1].valid).length === 0

    const isFieldOnError = (): boolean =>
        props.validationResults.displayValidationErrors ? !isPristine && !isFieldValid() : false

    useEffect(() => {
        if (!errors) clearErrors(props.name)
    }, [isFieldOnError()])

    const setFocus = (value: string) => {
        fieldsContext.setFocus(props.name, true)
        if (!isPristine) return
        setIsPristine(value !== '')
    }
    return (
        <>
            <Form.Group
                ref={wrapperRef}
                name={props.name}
                as={Col}
                className="assura-text-button-root"
            >
                <Form.Label className="labelMedium text-label" htmlFor={props.name}>
                    {props.label}
                </Form.Label>
                <Col className="assura-text-button-group">
                    <input
                        id={props.name}
                        data-testid={props.dataTestId}
                        // prepare input to be used with react hook form
                        {...register(props.name, { ...props.validationRules })}
                        className={`label form-control ${isFieldOnError() ? 'is-invalid' : ''}`}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        autoComplete="off"
                        maxLength={props.options?.max}
                        type={fieldType.type}
                        placeholder={props.placeHolder}
                        // if declare onBlur, onFocus, etc... it overrides the React-Hook-Forms behavior
                        onFocus={(e) => setFocus(e.currentTarget.value)}
                        onClick={(e) => setFocus(e.currentTarget.value)}
                    />
                    {fieldType.isPassword && (
                        // Encapsulate the password toggle
                        <PasswordToggle
                            dataTestId={props.dataTestId}
                            fieldType={fieldType}
                            setFieldType={setFieldType}
                        />
                    )}
                </Col>

                <div className="input-with-helper-text-container">
                    <>
                        {isFieldOnError() && (
                            <>
                                {!fieldsContext.isFocus(props.name) && (
                                    <InputErrors errors={errors} props={props} />
                                )}
                                {fieldsContext.isFocus(props.name) && (
                                    <InputValidations parentProps={props} />
                                )}
                            </>
                        )}
                    </>
                </div>
            </Form.Group>
        </>
    )
}
export default AssuraFormInputWithValidations
