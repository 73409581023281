import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import {
    fetchContactDataApi,
    postBasicInfoApi,
    postEmailApi,
    postPhoneApi
} from 'core/api/services/ContactInfos'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { FormCategory, PersonalDataRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { PhoneType } from 'core/enums/PhoneType'
import { sendEvent, updatePageViewParams } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import {
    ContactInfos,
    ContactInfosFetchResponse
} from 'core/models/services/personalData/ContactInfos'
import { ServicesCommonRequestBody } from 'core/models/services/ServicesCommonRequestBody'
import { setPersonalDataFormValues } from 'shared/store/familySummaries/familySummaries.slice'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'
import {
    getSelectedMemberIndexAndYear,
    SelectedMemberIndexAndYear
} from 'shared/store/selectors/getSelectedMemberIndexAndYear'
import { getServicesCommonRequestBody } from 'shared/store/selectors/getServicesCommonRequestBody'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import {
    fetchContactInfos,
    getContactInfos,
    postBasicInfo,
    postEmail,
    postPhone,
    setContactInfos,
    setEmailFormValue,
    setLoadingStatus,
    setPhoneFormValues
} from './contactInfos.slice'

export function* initContactInfos() {
    try {
        const connectedFamilyMember: ConnectedFamilyMember = yield select(getConnectedFamilyMember)

        yield call<typeof fetchContactInfosSaga>(fetchContactInfosSaga, {
            type: 'contactInfos/fetchContactInfos',
            payload: { selectedPolicyNumber: connectedFamilyMember?.policyNumber }
        })
    } catch (e) {
        console.error('initContactInfos Error', e)
        throw e
    }
}

function* fetchContactInfosSaga(action: ReturnType<typeof fetchContactInfos>) {
    const { selectedPolicyNumber, reload } = action.payload

    try {
        const personalDataList: Record<number, ContactInfos> = yield select(getContactInfos)

        if (
            !reload &&
            personalDataList[selectedPolicyNumber] &&
            personalDataList[selectedPolicyNumber].loadingStatus &&
            personalDataList[selectedPolicyNumber].loadingStatus === LoadingStatusEnum.OK
        ) {
            return
        }

        yield put(
            setLoadingStatus({
                value: LoadingStatusEnum.LOADING,
                selectedPolicyNumber
            })
        )
        const contactData: ContactInfosFetchResponse = yield call(
            fetchContactDataApi,
            selectedPolicyNumber
        )

        yield put(
            setContactInfos({
                newDatas: contactData,
                selectedPolicyNumber
            })
        )
        const shortContactLanguage: string = yield select(getShortContactLanguage)
        yield spawn(updatePageViewParams, {
            [analyticsConstants.PARAMS.CONTACT_LANGUAGE]: shortContactLanguage
        })

        yield put(
            setLoadingStatus({
                value: LoadingStatusEnum.OK,
                selectedPolicyNumber
            })
        )
    } catch (e) {
        console.error('onGetContactData Error', e)
        yield put(
            setLoadingStatus({
                value: LoadingStatusEnum.ERROR,
                selectedPolicyNumber
            })
        )
        throw e
    }
}

function* postBasicInfoSaga(action: ReturnType<typeof postBasicInfo>) {
    const analyticsParams = {
        form_category: FormCategory.PERSONAL_DATA,
        request_purpose: PersonalDataRequestPurpose.BASIC_INFO
    }
    try {
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )
        const datasFromForm = action.payload

        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))

        yield call(postBasicInfoApi, {
            ...datasFromForm,
            ...commonRequestBody
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())
        const selectedMemberIndexAndYear: SelectedMemberIndexAndYear = yield select(
            getSelectedMemberIndexAndYear
        )
        if (selectedMemberIndexAndYear.index !== -1) {
            yield put(
                setPersonalDataFormValues({
                    ...datasFromForm,
                    ...selectedMemberIndexAndYear
                })
            )
        }
        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.PERSONAL_DATA.url,
                ...analyticsParams
            })
        )
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        }
        console.error('onPostBasicInfoSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* postPhoneSaga(action: ReturnType<typeof postPhone>) {
    const analyticsParams = {
        form_category: FormCategory.PERSONAL_DATA,
        request_purpose: PersonalDataRequestPurpose.PHONE
    }
    try {
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )
        const datasFromForm = action.payload

        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))

        yield call(postPhoneApi, {
            ...datasFromForm,
            ...commonRequestBody
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())

        yield put(
            setPhoneFormValues({
                policyNumber: commonRequestBody.policyNumber,
                datas: {
                    type: datasFromForm.type as PhoneType,
                    internationalCode: 0,
                    regionalCode: null,
                    phoneNumber: datasFromForm.newPhoneNumber
                }
            })
        )

        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.PERSONAL_DATA.url,
                ...analyticsParams
            })
        )
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        }
        console.error('onPostPhoneSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* postEmailSaga(action: ReturnType<typeof postEmail>) {
    const analyticsParams = {
        form_category: FormCategory.PERSONAL_DATA,
        request_purpose: PersonalDataRequestPurpose.EMAIL
    }
    try {
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )
        const datasFromForm = action.payload

        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))

        yield call(postEmailApi, {
            ...datasFromForm,
            ...commonRequestBody
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())

        yield put(
            setEmailFormValue({
                policyNumber: commonRequestBody.policyNumber,
                newEmail: datasFromForm.newEmail
            })
        )

        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.PERSONAL_DATA.url,
                ...analyticsParams
            })
        )
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        }
        console.error('onPostEmailSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* fetchContactInfosWatcher() {
    yield takeEvery(fetchContactInfos.type, fetchContactInfosSaga)
}

function* postBasicInfoWatcher() {
    yield takeEvery(postBasicInfo.type, postBasicInfoSaga)
}

function* postPhoneWatcher() {
    yield takeEvery(postPhone.type, postPhoneSaga)
}

function* postEmailWatcher() {
    yield takeEvery(postEmail.type, postEmailSaga)
}

const watchers = [
    fork(fetchContactInfosWatcher),
    fork(postBasicInfoWatcher),
    fork(postPhoneWatcher),
    fork(postEmailWatcher)
]

export default watchers
