import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import {
    SymptomCheckerMappedActionsEnum,
    SymptomCheckerTriageEnum
} from 'core/enums/SymptomChecker'
import { isStringParsableInJSON } from 'core/helpers/ObjectHelper'
import {
    SymptomCheckerEvent,
    SymptomCheckerPartner,
    SymptomCheckerResponseDataFromInterviewFinished
} from 'core/models/SymptomChecker'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getSymptomCheckerUrls } from 'shared/store/selectors/getSymptomCheckerUrls'
import { useAppDispatch } from 'shared/store/store'
import {
    getConcernedPartner,
    getIsOnlyOneQualiMedPartner,
    getSymptomCheckerLoadingStatus,
    initSymptomCheckerForm,
    sendSymptomCheckerSessionInterStep,
    triggerSymptomCheckerPopin
} from 'shared/store/symptomChecker/symptomChecker.slice'

const SymptomCheckerForm = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const symptomCheckerUrls = useSelector(getSymptomCheckerUrls)
    const symptomCheckerLoadingStatus = useSelector(getSymptomCheckerLoadingStatus)
    const concernedPartner = useSelector(getConcernedPartner)
    const isOnlyOneQualiMedPartner = useSelector(getIsOnlyOneQualiMedPartner)

    const concernedPartnerRef = useRef<SymptomCheckerPartner | null>(null)
    const isSymptomCheckerFinishedRef = useRef(false)
    const scTriageLevelRef = useRef<SymptomCheckerTriageEnum | null>(null)

    const triggerSymptomCheckerRestart = () => {
        if (isOnlyOneQualiMedPartner && concernedPartnerRef.current) {
            dispatch(initSymptomCheckerForm(concernedPartnerRef.current))
        } else {
            dispatch(triggerSymptomCheckerPopin(true))
        }
    }

    const goHome = () => {
        navigate(navigationConstants.HOME.url)
    }

    const goToLandingPage = () => {
        if (concernedPartnerRef.current?.lamalProductCode === 'PAM') {
            navigate(navigationConstants.SYMPTOM_CHECKER_LANDING_PAGE.url)
        } else {
            goHome()
        }
    }

    const handleSessionInterStep = (origin: string, parsedData: SymptomCheckerEvent) => {
        const { action } = parsedData

        if (
            action === SymptomCheckerMappedActionsEnum.INITIALIZATION_COMPLETE &&
            isSymptomCheckerFinishedRef.current
        ) {
            return
        }

        dispatch(
            sendSymptomCheckerSessionInterStep({
                step: extractStepFromSymptomChecker(origin),
                data: parsedData
            })
        )
    }

    const handleFormActionBasedOnIframeEvent = (eventData: SymptomCheckerEvent) => {
        const { data, action } = eventData
        const isSymptomCheckerFinished = isSymptomCheckerFinishedRef.current
        const scTriageLevel = scTriageLevelRef.current

        if (
            action === SymptomCheckerMappedActionsEnum.INTERVIEW_FINISHED &&
            !isSymptomCheckerFinished
        ) {
            isSymptomCheckerFinishedRef.current = true
        }

        if (
            action === SymptomCheckerMappedActionsEnum.INITIALIZATION_COMPLETE &&
            isSymptomCheckerFinished
        ) {
            isSymptomCheckerFinishedRef.current = false
            triggerSymptomCheckerRestart()
        }

        if (action === SymptomCheckerMappedActionsEnum.INTERVIEW_FINISHED) {
            const { triageLevel } = data as SymptomCheckerResponseDataFromInterviewFinished
            scTriageLevelRef.current = triageLevel
        }

        if (action === SymptomCheckerMappedActionsEnum.CTA_EVENT) {
            if (
                scTriageLevel === SymptomCheckerTriageEnum.SELF_CARE ||
                scTriageLevel === SymptomCheckerTriageEnum.CONSULTATION ||
                scTriageLevel === SymptomCheckerTriageEnum.CONSULTATION_24
            ) {
                goToLandingPage()
            } else {
                goHome()
            }
        }
    }

    const extractStepFromSymptomChecker = (currentUrl: string): number | null => {
        const params = currentUrl.replace(symptomCheckerUrls.urlWithoutParameters, '')
        if (params[0] === '/') {
            return Number(params.split('?')[0].replace('/', ''))
        } else {
            return null
        }
    }

    const handleMessages = (event: MessageEvent) => {
        const scUrl = process.env.REACT_APP_SYMPTOM_CHECKER
        const { origin, data } = event

        if (scUrl && scUrl.startsWith(origin) && isStringParsableInJSON(data as string)) {
            const parsedData = JSON.parse(data) as SymptomCheckerEvent
            handleSessionInterStep(origin, parsedData)
            handleFormActionBasedOnIframeEvent(parsedData)
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleMessages)
        return () => {
            window.removeEventListener('message', handleMessages)
        }
    }, [])

    useEffect(() => {
        if (concernedPartner) {
            concernedPartnerRef.current = concernedPartner
        }
    }, [concernedPartner])

    return (
        <div className="d-flex justify-content-center align-items-stretch flex-1">
            <AssuraLoadAndError
                status={symptomCheckerLoadingStatus}
                defaultReloadAction={triggerSymptomCheckerRestart}
            >
                <iframe
                    key="symptom_checker_form"
                    title="Symptom checker"
                    src={symptomCheckerUrls.fullUrl}
                    className="flex-1"
                    style={{ border: 0 }}
                />
            </AssuraLoadAndError>
        </div>
    )
}

export default SymptomCheckerForm
