import { AppSearchSectionKeyType } from 'core/models/AppSearch'

export const AppSearchSectionTitle: Record<AppSearchSectionKeyType, string> = {
    PAGE: 'SEARCH.SECTION_PAGES',
    FAQ: 'NAVIGATION_BAR.FAQ',
    SERVICES: 'CONTACT.SERVICES_TITLE'
}

export const AppSearchCategoryIcons: Record<AppSearchSectionKeyType | 'EXTERNAL', string> = {
    PAGE: 'assura-search',
    FAQ: 'assura-help-circle',
    SERVICES: 'assura-edit',
    EXTERNAL: 'assura-external-link'
}
