import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

export interface EcPlusMigrationState {
    lastUpdate: Date | null
}

const INITIAL_STATE_MIGRATION: EcPlusMigrationState = {
    lastUpdate: null
}

export const ecPlusMigrationSlice = createSlice({
    name: 'ecPlusMigration',
    initialState: INITIAL_STATE_MIGRATION,
    reducers: {
        fetchLastUpdate: (state, _action: PayloadAction) => {
            return state
        },
        fetchLastUpdateSuccess: (state, action: PayloadAction<EcPlusMigrationState>) => {
            state.lastUpdate = action.payload.lastUpdate
        },

        onSubmitLastUpdate: (state, _action: PayloadAction) => {
            return state
        },
        onSubmitLastUpdateAndActivate: (state, _action: PayloadAction) => {
            return state
        }
    }
})

export const {
    fetchLastUpdate,
    fetchLastUpdateSuccess,
    onSubmitLastUpdate,
    onSubmitLastUpdateAndActivate
} = ecPlusMigrationSlice.actions

export const getLastUpdate: Selector<RootState, Date | null> = (state) =>
    state.ecPlusMigration.lastUpdate

export default ecPlusMigrationSlice.reducer
