import { fork, put, select, takeEvery } from 'redux-saga/effects'

import bannerProps from 'core/constants/bannerProps'
import { generateGUID } from 'core/helpers/GuidHelper'
import { BannerMessage, BannerProps, MainBannerItem } from 'core/models/Banners'

import { addBanner, getBanners, setBanner, setForbiddenBanner } from './banners.slice'

export function* setBannerSaga(action: ReturnType<typeof setBanner>) {
    try {
        const currentBanners: MainBannerItem[] = yield select(getBanners)
        const isDuplicate = currentBanners.some(
            (banner) => banner.message === action.payload.message
        )

        if (!isDuplicate) {
            const { id, variant, ...rest } = action.payload

            const newId = id || generateGUID()
            const newProps: BannerProps = bannerProps[variant ?? 'alert']

            const newBanner: MainBannerItem = {
                ...rest,
                id: newId,
                props: newProps
            }
            yield put(addBanner(newBanner))
        }
    } catch (e) {
        console.log('onSetBannerSaga Error', e)
    }
}

export function* setForbiddenBannerSaga() {
    try {
        const forbiddenBannerProps: BannerMessage = {
            dataTestId: 'forbidden-error-banner',
            message: 'SUPERVISION.FORBIDDEN'
        }

        yield put(setBanner(forbiddenBannerProps))
    } catch (e) {
        console.log('onSetBannerSaga Error', e)
    }
}

function* setBannerWatcher() {
    yield takeEvery(setBanner.type, setBannerSaga)
}

function* setForbiddenBannerWatcher() {
    yield takeEvery(setForbiddenBanner.type, setForbiddenBannerSaga)
}

const watchers = [fork(setBannerWatcher), fork(setForbiddenBannerWatcher)]

export default watchers
