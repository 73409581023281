import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { LoginForm } from 'core/models/services/settings/LoginForm'
import { PasswordForm } from 'core/models/services/settings/PasswordForm'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

export interface CredentialState {
    loading: LoadingStatusEnum
    forceReload: boolean
    updateResult: UpdateResult | undefined
}

export type UpdateResult = {
    type: string
    title: string
    status: number
    detail: string
    instance: string
    additionalProp1?: string
    additionalProp2?: string
    additionalProp3?: string
}

const INITIAL_STATE_CREDENTIAL: CredentialState = {
    loading: LoadingStatusEnum.OK,
    forceReload: false,
    updateResult: undefined
}

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState: INITIAL_STATE_CREDENTIAL,
    reducers: {
        onSubmitUpdateLogin: (state: CredentialState, _action: PayloadAction<LoginForm>) => {
            state
        },
        onSubmitUpdatePassword: (state: CredentialState, _action: PayloadAction<PasswordForm>) => {
            state
        },
        setUpdateStatus: (state: CredentialState, _action: PayloadAction<LoadingStatusEnum>) => {
            state.loading = _action.payload
        },
        setUpdateResult: (state: CredentialState, _action: PayloadAction<UpdateResult>) => {
            state.updateResult = _action.payload
        },
        resetUpdateResult: (state: CredentialState, _action: PayloadAction) => {
            state.updateResult = undefined
        },
        setForceReload: (state: CredentialState, _action: PayloadAction<boolean>) => {
            state.forceReload = _action.payload
        }
    }
})

export const {
    onSubmitUpdateLogin,
    onSubmitUpdatePassword,
    setUpdateStatus,
    setUpdateResult,
    resetUpdateResult,
    setForceReload
} = credentialsSlice.actions

export const getCredentialSubmissionStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.credentials.loading

export const getUpdateResults: Selector<RootState, UpdateResult | undefined> = (state) =>
    state.credentials.updateResult

export const getCredentialsForceReload: Selector<RootState, boolean> = (state) =>
    state.credentials.forceReload

export default credentialsSlice.reducer
