import './PickerDayHeader.css'

import { useSelector } from 'react-redux'

import locales from 'core/services/locales'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import { format, getYear } from 'date-fns'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getLang } from 'shared/store/general/general.slice'

interface PickerDayHeaderProps {
    date: Date
    changeYear: (year: number) => void
    changeMonth: (month: number) => void
    decreaseMonth: () => void
    increaseMonth: () => void
    prevMonthButtonDisabled: boolean
    nextMonthButtonDisabled: boolean
}

const PickerDayHeader = (props: PickerDayHeaderProps): JSX.Element => {
    const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } =
        props

    const lang = useSelector(getLang)
    const currentYear = getYear(date)
    const currentMonth = format(date, 'LLLL', { locale: locales.get(lang) })

    return (
        <div
            id="date-picker-day-custom-header"
            style={{
                display: 'flex'
            }}
        >
            <div
                className="d-flex"
                style={{ visibility: prevMonthButtonDisabled ? 'hidden' : 'visible' }}
            >
                <AssuraIconButton
                    id="headerMinus"
                    icon="assura-left"
                    variant="default"
                    size="medium"
                    onClick={decreaseMonth}
                    classNames="c-white m-right-8"
                />
            </div>
            <div className="labelMedium c-white flex-1">
                {`${stringUpperCaseFirstCharacter(currentMonth)} ${currentYear}`}
            </div>
            <div
                className="d-flex"
                style={{ visibility: nextMonthButtonDisabled ? 'hidden' : 'visible' }}
            >
                <AssuraIconButton
                    id="headerPlus"
                    icon="assura-right"
                    variant="default"
                    size="medium"
                    onClick={increaseMonth}
                    classNames="c-white m-left-8"
                />
            </div>
        </div>
    )
}

export default PickerDayHeader
