export const FRENCH_LANGUAGE = 'fr'
export const GERMAN_LANGUAGE = 'de'
export const ITALIAN_LANGUAGE = 'it'
export const ENGLISH_LANGUAGE = 'en'

export const DEFAULT_LANGUAGE = FRENCH_LANGUAGE

export const LANGUAGES = [FRENCH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE, ENGLISH_LANGUAGE]
export enum LANGUAGES_FULL {
    fr = 'Français',
    de = 'Deutsch',
    it = 'Italiano',
    en = 'English'
}
