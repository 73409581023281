import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { fetchNotificationsApi } from 'core/api/Notifications'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { Notifications } from 'core/models/Notifications'

import {
    fetchNotifications,
    setNotifications,
    setNotificationsLoadingStatus
} from './notifications.slice'

function* fetchNotificationsSaga() {
    try {
        yield put(setNotificationsLoadingStatus(LoadingStatusEnum.LOADING))

        const notifications: Notifications[] = yield call(fetchNotificationsApi)

        yield put(setNotifications(notifications))
        yield put(setNotificationsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchNotificationsSaga Error', e)
        yield put(setNotificationsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchNotificationsWatcher() {
    yield takeEvery(fetchNotifications.type, fetchNotificationsSaga)
}

const watchers = [fork(fetchNotificationsWatcher)]

export default watchers
