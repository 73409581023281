// Create array for all chars contained in stringified data
const toArray = (data: unknown[]): string[] => {
    return JSON.stringify(data).split('')
}

// Returns the difference beween two arrays
export const except = (data: unknown[], previous: unknown[]): boolean => {
    const A = toArray(data)
    const B = toArray(previous)
    return A.filter((o) => !B.includes(o)).length > 0
}

// ConcatOrValueOnNull gets only non null | undefined
// values and join them with separator otherwise returns returnIfNull
export const ConcatOrValueOnNull = (
    values: (string | number | null | undefined)[] = [],
    returnIfNull = 'N/A',
    separator = ', '
): string => {
    // gather only valued string | numbers
    const potentialValues = values.filter(
        (o) =>
            o !== null &&
            o !== undefined &&
            o !== '' &&
            !o.toString().toLowerCase().includes('null')
    )
    // if no any potential values then return 'returnIfNull'
    if (!potentialValues || potentialValues.length < 1) return returnIfNull
    // finally returns a concatenated version of the values separated by the value 'separator'
    return potentialValues.join(separator)
}
