import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { getGeneralQuestionTopics } from 'shared/store/selectors/getGeneralQuestionTopics'

const GeneralQuestionTopics = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const topics = useSelector(getGeneralQuestionTopics)

    const getTopics = () => {
        const output = new Map() as SelectMap<string>
        topics.forEach((item) => {
            output.set(item.topic, item.id)
        })
        return output
    }

    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <AssuraFormSelectRHF
                name="topic"
                id="services-general-question-topic"
                label={t('SERVICES.CONTACT_QUESTION')}
                items={getTopics()}
                inline={isXL}
                labelSize={4}
                placeHolder={t('SERVICES.GENERAL_QUESTION_TOPIC_PLACEHOLDER')}
            />
        </div>
    )
}

export default GeneralQuestionTopics
