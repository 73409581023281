import './EnveloppesTableDocumentsField.css'

import { useTranslation } from 'react-i18next'

import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import concatClassNames from 'core/utils/classNameUtils'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

interface EnveloppesTableDocumentsFieldProps {
    enveloppe: EnveloppeRow
    rowId: string
    labelClassName: string
    mobileStyle?: boolean
    shouldDisplayName?: boolean
}
const EnveloppesTableDocumentsField = ({
    enveloppe,
    rowId,
    labelClassName,
    mobileStyle,
    shouldDisplayName
}: EnveloppesTableDocumentsFieldProps): JSX.Element => {
    const { t } = useTranslation()

    let label = enveloppe.documentName
    if (shouldDisplayName)
        label += ` ${t('COMMON.FOR', {
            item: enveloppe.insuredPersonName
        })}`

    const mobileClass = mobileStyle ? 'mobile' : ''

    const containerClassNames = mobileStyle ? undefined : 'enveloppes-table-documents-container'
    const rowClassNames = concatClassNames(['enveloppes-table-documents-row', mobileClass])
    const labelClassNames = concatClassNames(['max-1-text-line', 'break-word', labelClassName])
    const countClassNames = concatClassNames([
        'labelExtraSmall enveloppes-table-documents-count',
        mobileClass
    ])

    return (
        <td
            className={containerClassNames}
            data-testid={`assura-table-row-${rowId}-documents-field`}
        >
            <table>
                <tbody>
                    <tr className={rowClassNames}>
                        <TextField
                            label={label}
                            rowId={rowId}
                            classNames={labelClassNames}
                            fieldId="documents-label"
                        />
                        {enveloppe.documents.length > 1 && (
                            <TextField
                                label={t('ENVELOPPES.FILES_COUNT', {
                                    count: enveloppe.documents.length
                                })}
                                rowId={rowId}
                                classNames={countClassNames}
                                fieldId="documents-count"
                            />
                        )}
                    </tr>
                </tbody>
            </table>
        </td>
    )
}

export default EnveloppesTableDocumentsField
