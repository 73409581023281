import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'

interface Country {
    name: string
    internationalCode: string
    internationalCodeLength: number
    phoneLength: number
    regionalCodeLength: number
    regionalCodeValues: string[]
    format: string
}

class CheckPhoneCountry {
    name: string
    internationalCode: string
    internationalCodeLength: number
    phoneLength: number
    regionalCodeLength: number
    regionalCodeValues: string[]
    format: string

    constructor(
        name: string,
        internationalCode: string,
        internationalCodeLength: number,
        phoneLength: number,
        regionalCodeLength: number,
        regionalCodeValues: string[],
        format: string
    ) {
        // Constructor for Country objects.
        //
        // Arguments:
        //   name                    - country name
        //   internationalCode       - International code (without "+" or "0" at begining)
        //   internationalCodeLength - International code length (without "+" or "0" at begining)
        //   phoneLength             - Number of digit in phone code (incl. int and reg code)
        //   regionalCodeLength      - Length of regional code
        //   regionalCodeValues      - Array with list of valid regional codes
        //   format                  - display format
        this.name = name
        this.internationalCode = internationalCode
        this.internationalCodeLength = internationalCodeLength
        this.phoneLength = phoneLength
        this.regionalCodeLength = regionalCodeLength
        this.regionalCodeValues = regionalCodeValues
        this.format = format
    }
}

// Data definition for countries
const checkPhoneData = new Array(
    new CheckPhoneCountry(
        'Suisse',
        '41',
        2,
        11,
        2,
        [
            '21',
            '22',
            '24',
            '26',
            '27',
            '31',
            '32',
            '33',
            '34',
            '41',
            '43',
            '44',
            '51',
            '52',
            '53',
            '55',
            '56',
            '58',
            '61',
            '62',
            '71',
            '74',
            '75',
            '76',
            '77',
            '78',
            '79',
            '81',
            '80',
            '84',
            '90',
            '91'
        ],
        '2 2 3 2 2'
    )
)

// Private functions ///////////////////////////////////////////////////////////////////

const checkPhone_Normalize = (phoneNumber: string) => {
    return phoneNumber.replace(/(\s|\.|_|-|\\|\/|\)|\()/g, '').replace(/^00/, '+')
}

const checkPhone_GetCountryFromNumber = (internationalCode: string) => {
    for (const phoneCountry of checkPhoneData) {
        if (
            phoneCountry.internationalCode ===
            internationalCode.substring(0, phoneCountry.internationalCodeLength)
        ) {
            return phoneCountry
        }
    }

    return new CheckPhoneCountry('default', internationalCode.substring(0, 2), 2, 0, 2, [], '')
}

const checkPhone_GetRegionalCode = (country: Country, phoneNumber: string): string => {
    return phoneNumber.substring(
        country.internationalCodeLength,
        country.internationalCodeLength + country.regionalCodeLength
    )
}

const checkPhone_RegionalCodeIsOk = (country: Country, regionalCode: string) => {
    if (country.regionalCodeValues.length === 0) return true

    for (const code of country.regionalCodeValues) {
        if (regionalCode === code) return true
    }

    return false
}

const checkPhone_VerifyLength = (country: Country, phoneNumber: string) => {
    if (country.phoneLength === 0) return true
    return country.phoneLength === phoneNumber.length
}

const checkPhone_VerifyPhoneFirstNumber = (country: Country, phoneNumber: string) => {
    const numberWithoutCodes = phoneNumber.substring(
        country.internationalCodeLength + country.regionalCodeLength
    )
    return /^[1-9]/.test(numberWithoutCodes)
}

const checkPhone = (field: string): boolean => {
    let phoneNumber = checkPhone_Normalize(field)
    if (phoneNumber === '') return false
    // remove + sign
    if (/^\+/.test(phoneNumber)) phoneNumber = phoneNumber.substring(1)
    // check if only numbers
    if (!/^\d{8,15}$/.test(phoneNumber)) return false
    const country = checkPhone_GetCountryFromNumber(phoneNumber)
    if (country.name === 'default') return false
    const regionalCode = checkPhone_GetRegionalCode(country, phoneNumber)
    return (
        checkPhone_RegionalCodeIsOk(country, regionalCode) &&
        checkPhone_VerifyLength(country, phoneNumber) &&
        checkPhone_VerifyPhoneFirstNumber(country, phoneNumber)
    )
}

// Format phone number for display (full number format : "+41218011234" [space allowed] => "+41 21 801 12 34")
const checkPhone_Format = (phoneNumber: string): string => {
    // remove + sign
    phoneNumber = checkPhone_Normalize(phoneNumber).substring(1)
    if (phoneNumber === '') return '+41 '

    const country = checkPhone_GetCountryFromNumber(phoneNumber)

    if (country.format === '')
        return `+${country.internationalCode} ${phoneNumber.substring(
            country.internationalCodeLength
        )}`

    let lastPos = 0
    let formatted = ''
    let space = ''
    for (const size of country.format.split(' ')) {
        const nextPos = lastPos + parseInt(size)
        formatted = formatted + space + phoneNumber.substring(lastPos, nextPos)
        lastPos = nextPos
        if (space === '') space = ' '
    }
    return `+${formatted}`
}

// Public functions ////////////////////////////////////////////////////////////////////

// Build phone number from international code, regional Code and number
export const getPhoneNumber = (data: PhoneDatas): string => {
    if (!data || !data.phoneNumber) return ''
    if (data.phoneNumber.indexOf('+') !== -1) return data.phoneNumber
    return `+${data.internationalCode}${data.regionalCode ?? ''}${data.phoneNumber}`
}

export const phoneFormatted = (phone: string): string => checkPhone_Format(phone)

export const phoneRulesValidate = (value: string): boolean | string => {
    return checkPhone(value) || 'COMMON.PHONE_ERROR_BAD_FORMAT'
}

export const phoneFormatter = (value: string): string => {
    if (!value || value.length === 0) return `+41`
    const match = new RegExp(/^\+41/).test(value.slice(0, 3))
    return !match ? `+41${value.slice(2)}` : value
}

export const phoneRules = {
    validate: phoneRulesValidate,
    required: { value: true, message: 'COMMON.MANDATORY_FIELD' }
}
