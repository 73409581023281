import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'

import PickerDay from 'modules/services/components/DatePicker/PickerDay/PickerDay'

import FormBlockWithLabel from '../FormBlockWithLabel/FormBlockWithLabel'

interface EndDateProps {
    name: string
    rules?: RegisterOptions
    testId: string
    startDateName: string
    setMinimumDate: (startDate: Date | undefined) => Date | undefined
    setMaximumDate: (startDate: Date | undefined) => Date | undefined
}

const EndDate = ({
    name,
    rules,
    testId,
    startDateName,
    setMinimumDate,
    setMaximumDate
}: EndDateProps): JSX.Element | null => {
    const { t } = useTranslation()

    const { control, watch, setValue } = useFormContext()

    const [minDate, setMinDate] = useState<Date | undefined>(undefined)
    const [maxDate, setMaxDate] = useState<Date | undefined>(undefined)

    useEffect(() => {
        setValue(name, undefined)
        setMinDate(setMinimumDate(watch(startDateName)))
        setMaxDate(setMaximumDate(watch(startDateName)))
    }, [watch(startDateName)])

    if (!watch(startDateName)) return null

    return (
        <div className="border-solid-width-1 bg-white bc-gray100 m-top-32 p-32">
            <FormBlockWithLabel label={t('SERVICES.END_DATE')} isFirstChildInput>
                <PickerDay
                    placeholder={t('SERVICES.SELECT_A_DATE')}
                    testId={testId}
                    control={control}
                    name={name}
                    minDate={minDate}
                    maxDate={maxDate}
                    rules={rules}
                />
            </FormBlockWithLabel>
        </div>
    )
}

export default EndDate
