import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from 'core/helpers/DateFormatHelper'
import useWindowSize from 'core/services/useWindowSize'
import { getSelectedClaimsSettlementReceipt } from 'shared/store/selectors/getSelectedClaimsSettlementReceipt'

const ClaimsSettlementReceiptPanelHeaderCenter = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()
    const selectedClaimsSettlementReceipt = useSelector(getSelectedClaimsSettlementReceipt)

    const getTreatmentDate = (startDate: string, endDate: string): string => {
        const treatmentStartDateFormatted = formatDate(startDate)
        const treatmentEndDateFormatted = formatDate(endDate)
        return treatmentStartDateFormatted === treatmentEndDateFormatted
            ? t('CLAIMS_SETTLEMENT.RECEIPT_TREATMENT_DAY', {
                  treatmentStartDate: treatmentStartDateFormatted
              })
            : t('CLAIMS_SETTLEMENT.RECEIPT_TREATMENT_PERIOD_SHORT', {
                  treatmentStartDate: treatmentStartDateFormatted,
                  treatmentEndDate: treatmentEndDateFormatted
              })
    }

    return selectedClaimsSettlementReceipt ? (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <div
                    data-testid="claims-settlement-receipt-panel-document-name"
                    className={`justify-content-center width-inherit d-flex ${
                        isXSD ? 'labelSmallMedium' : 'labelMedium'
                    }`}
                >
                    <span className="assura-panel-center-block-ellipsis">
                        {t('CLAIMS_SETTLEMENT.RECEIPT_DETAIL_TITLE')}
                    </span>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center m-top-4">
                {!isXSD && (
                    <>
                        <span
                            className="labelSmall c-gray500"
                            data-testid={`claims-settlement-receipt-panel-treatment-date`}
                        >
                            {selectedClaimsSettlementReceipt.treatmentStartDate &&
                            selectedClaimsSettlementReceipt.treatmentEndDate
                                ? getTreatmentDate(
                                      selectedClaimsSettlementReceipt.treatmentStartDate,
                                      selectedClaimsSettlementReceipt.treatmentEndDate
                                  )
                                : ''}
                        </span>
                    </>
                )}
            </div>
        </>
    ) : (
        <div />
    )
}

export default ClaimsSettlementReceiptPanelHeaderCenter
