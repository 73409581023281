import './PrimesPanelDiscountsInfo.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ProductPeriodicity } from 'core/enums/Product'
import { ServiceType } from 'core/enums/ServiceType'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getDiscountsOfSelectedFamilyMember } from 'shared/store/selectors/getDiscountsOfSelectedFamilyMember'
import { getProductPeriodicity } from 'shared/store/selectors/getProductPeriodicity'
import { getTotalPrimeOfSelectedFamilyMemberForDiscounts } from 'shared/store/selectors/getTotalPrimeOfSelectedFamilyMemberForDiscounts'
import { goToServicesForm } from 'shared/store/services/services.slice'

import PrimesPanelDiscountsAmount from './PrimesPanelDiscountsAmount'
import PrimesPanelDiscountsPeriodicity from './PrimesPanelDiscountsPeriodicity'

const PrimesPanelDiscountsInfo = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [totalDiscounts, setTotalDiscounts] = useState(0)

    const discountsInfo = useSelector(getDiscountsOfSelectedFamilyMember)
    const totalPrime = useSelector(getTotalPrimeOfSelectedFamilyMemberForDiscounts)
    const periodicity = useSelector(getProductPeriodicity)

    const shouldDisplayDiscountsDetails =
        discountsInfo?.length > 0 ||
        periodicity === ProductPeriodicity.ANNUAL ||
        periodicity === ProductPeriodicity.BIANNUAL

    useEffect(() => {
        const total = discountsInfo.reduce<number>((acc, item) => {
            return acc + item.value
        }, 0)
        setTotalDiscounts(total)
    }, [discountsInfo])

    return (
        <div className="m-top-32">
            <div className="d-flex">
                <div
                    className="headlineSmallMedium flex-1"
                    data-testid="primes-panel-discounts-info-title"
                >
                    {t('PRIMES.DISCOUNTS_TITLE')}
                </div>
                {Boolean(totalDiscounts) && (
                    <div
                        className="headlineSmallMedium m-left-16"
                        data-testid="primes-panel-discounts-info-total"
                    >
                        {formatNumber(totalDiscounts)}
                    </div>
                )}
            </div>
            {shouldDisplayDiscountsDetails ? (
                <>
                    {discountsInfo.map((discountInfo, index) => (
                        <PrimesPanelDiscountsAmount discountInfo={discountInfo} key={index} />
                    ))}
                    <PrimesPanelDiscountsPeriodicity periodicity={periodicity} />
                </>
            ) : (
                <div className="d-flex flex-column align-items-start m-top-16">
                    <div className="paragraphSmallLight">{t('PRIMES.DISCOUNTS_NO_DISCOUNTS')}</div>
                    <AssuraIconButton
                        id="primes-discounts-change-periodicity"
                        icon="assura-edit"
                        variant="primary"
                        size="small"
                        label={t('PRIMES.DISCOUNTS_CHANGE_PERIODICITY')}
                        onClick={() =>
                            dispatch(
                                goToServicesForm({ type: ServiceType.PAYMENT_FREQ_MODIFICATION })
                            )
                        }
                        classNames="primes-panel-discounts-info-change-periodicity-button"
                    />
                </div>
            )}
            <div className="p-top-16">
                <div className="paragraphExtraSmall c-gray500 white-space-pre-line">
                    {t('PRIMES.DISCOUNTS_DISCLAIMER')}
                </div>
            </div>
            <div className="primes-panel-separator horizontal-separator bg-gray100" />
            <div className="d-flex">
                <div className="headlineMedium flex-1">{t('PRIMES.DISCOUNTS_TOTAL')}</div>
                {Boolean(totalPrime) && (
                    <div className="headlineMedium m-left-16">
                        {formatNumber(totalPrime as number)}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PrimesPanelDiscountsInfo
