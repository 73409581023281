import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { AssuraDocument } from 'core/models/AssuraDocument'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraCmsLink from 'shared/components/AssuraCmsLink/AssuraCmsLink'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'
import { getSelectedClaimsSettlement } from 'shared/store/claimsSettlement/claimsSettlement.slice'
import { fetchDocumentFile } from 'shared/store/documents/documents.slice'
import { getSelectedFinancialDocumentsGroup } from 'shared/store/financialDocuments/financialDocuments.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { useAppDispatch } from 'shared/store/store'

import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'

interface ClaimsSettlementPanelDetailsProps {
    documentFile?: AssuraDocument
    toolbarPluginInstance: ToolbarPlugin
    isSelectedDocumentRead: boolean
    isCanceled?: boolean
    shouldDisplayBanner?: boolean
}

const ClaimsSettlementPdfViewer = ({
    documentFile,
    toolbarPluginInstance,
    isSelectedDocumentRead,
    isCanceled,
    shouldDisplayBanner = false
}: ClaimsSettlementPanelDetailsProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [isBannerVisible, setIsBannerVisible] = useState(shouldDisplayBanner)

    const selectedDocumentsGroup = useSelector(getSelectedFinancialDocumentsGroup)
    const selectedClaimsSettlement = useSelector(getSelectedClaimsSettlement)
    const assets = useSelector(getAssets)

    const renderPdfError = (): JSX.Element => (
        <AssuraDocumentsError
            onClick={() => {
                if (selectedClaimsSettlement) {
                    dispatch(
                        fetchDocumentFile({
                            documentId: selectedClaimsSettlement.documentIdentifier,
                            documentsGroup: selectedDocumentsGroup,
                            isRead: isSelectedDocumentRead,
                            reload: true
                        })
                    )
                }
            }}
        />
    )

    const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

    return (
        <AssuraLoadAndError
            status={documentFile?.loadStatus ?? LoadingStatusEnum.ERROR}
            ErrorComponent={renderPdfError()}
        >
            <div className="d-flex flex-column flex-1 position-relative overflow-auto">
                {isBannerVisible && (
                    <div className="claims-settlement-panel-banners-container">
                        <AssuraBanner
                            id="claims-settlement-pdf-banner"
                            message={t(
                                isCanceled
                                    ? 'FINANCIALS.CANCELED_DOCUMENT_BANNER'
                                    : 'STATEMENTS.DETAILS_TITLE'
                            )}
                            onClose={() => setIsBannerVisible(false)}
                            variant="default"
                            cmsLink={
                                isCanceled ? undefined : (
                                    <AssuraCmsLink
                                        asset={assets.Statements_How_To}
                                        title={t('STATEMENTS.DETAILS_LINK')}
                                        classNames="m-top-8 assura-link-default"
                                    />
                                )
                            }
                        />
                    </div>
                )}
                <AssuraPdfViewer
                    toolbarPluginInstance={toolbarPluginInstance}
                    doc={documentFile}
                    renderPdfError={renderPdfError}
                    renderPdfLoader={renderPdfLoader}
                />
            </div>
        </AssuraLoadAndError>
    )
}

export default ClaimsSettlementPdfViewer
