import { formatDate, isDateFormatValidDDMMYYYY } from 'core/helpers/DateFormatHelper'

import { AccidentReportModel } from '../../models/services/report/AccidentReportModel'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, sonarjs/cognitive-complexity
const fillFormData = (fieldName: string, value: any, formData: FormData): void => {
    /** Parse value */
    let output: Blob | string

    if (typeof value === 'string' && value.endsWith('Yes')) {
        output = 'true'
    } else if (typeof value === 'string' && value.endsWith('No')) {
        output = 'false'
    } else if (value instanceof Date && isDateFormatValidDDMMYYYY(formatDate(value))) {
        output = formatDate(value)
    } else if (value === undefined || value === 'null' || value === null) {
        //if the value is null we do not append the empty null field to the object
        return
    } else {
        output = value
    }

    /** specific values  */
    if (fieldName === 'EmploymentStatus') {
        output = value
            ? `${[
                  'EMPLOYED',
                  'UNPAID',
                  'INDEPENDANT',
                  'STUDENT',
                  'RETIRED',
                  'MILITARY',
                  'UNEMPLOYED',
                  'CHILD',
                  'APPRENTICE',
                  'HOUSEWIFE'
              ].indexOf(value)}`
            : '-1'
    }

    if (fieldName === 'TrafficPlace') {
        if (value === 'injured-pedestrian') output = '3'
        else if (value === 'injured-driver') output = '1'
        else if (value === 'injured-passenger') output = '2'
        else output = '0'
    }

    if (fieldName === 'AccidentTime') {
        const valDate = value as Date
        output = `${valDate.getHours()}:${valDate.getMinutes().toString().padStart(2, '0')}`
    }

    formData.append(fieldName, output)
}

export const accidentFormConverter = (inputData: AccidentReportModel): FormData => {
    const formData = new FormData()

    // PersonalDataForm
    fillFormData('PolicyNumber', inputData.policyNumber, formData)
    fillFormData('Email', inputData.email, formData)
    fillFormData('PhoneNumber', inputData.phoneNumber, formData)
    fillFormData('Coverage', inputData.coverage, formData)
    fillFormData('EmploymentStatus', inputData.employmentStatus, formData)
    fillFormData('WeeklyHoursWork', inputData.weeklyHoursWork, formData)
    fillFormData('StillUnemployed', inputData.stillUnemployed, formData)
    fillFormData('Profession', inputData.profession, formData)
    fillFormData('Employer', inputData.employer, formData)
    fillFormData('EmployerInsurance', inputData.employerInsurance, formData)
    fillFormData('EmployerInsuranceKnow', inputData.employerInsuranceKnow, formData)
    fillFormData(
        'EmployerInsuranceIncidentNumber',
        inputData.employerInsuranceIncidentNumber,
        formData
    )
    fillFormData('UnemploymentBenefits', inputData.unemploymentBenefits, formData)
    fillFormData('UnemploymentStartDate', inputData.unemploymentStartDate, formData)
    fillFormData('UnemploymentEndDate', inputData.unemploymentEndDate, formData)

    // AccidentDetailsForm
    fillFormData('AccidentDate', inputData.accidentDate, formData)
    fillFormData('AccidentTime', inputData.accidentTime, formData)
    fillFormData('AccidentLocation', inputData.accidentLocationLabel, formData)
    fillFormData('DentalAccident', inputData.cbdental, formData)
    fillFormData('TrafficAccident', inputData.cbcirculation, formData)
    fillFormData('StandardAccident', inputData.cbstandard, formData)
    fillFormData('EatingAccident', inputData.eating, formData)
    fillFormData('AccidentCause', inputData.accidentCause, formData)
    fillFormData('AccidentCompetition', inputData.training, formData)
    fillFormData('AccidentSchool', inputData.school, formData)
    fillFormData('AccidentCommute', inputData.work, formData)
    fillFormData('ThirdPartyAddress', inputData.thirdPartyAddress, formData)
    fillFormData('ThirdPartyInsurance', inputData.thirdPartyInsurance, formData)
    fillFormData('PoliceOfficerName', inputData.policeReport, formData)
    fillFormData('Witnesses', inputData.witnesses, formData)

    // WoundForm
    fillFormData('BodyPartInjured', inputData.bodyPartInjured, formData)
    fillFormData('InjuryNature', inputData.injuryNature, formData)
    fillFormData('FirstDoctor', inputData.firstDoctor, formData)
    fillFormData('ProcessStartDate', inputData.processStartDate, formData)
    fillFormData('ProcessOver', inputData.processOver, formData)
    fillFormData('ProcessDoctor', inputData.processDoctor, formData)
    fillFormData('Hospitalization', inputData.hospitalization, formData)
    fillFormData('StillHospitalized', inputData.stillHospitalized, formData)
    fillFormData('HospitalizationStartDate', inputData.hospitalizationStartDate, formData)
    fillFormData('HospitalizationEndDate', inputData.hospitalizationEndDate, formData)

    // IncapacityForWorkForm
    fillFormData('WorkIncapacity', inputData.workIncapacity, formData)
    fillFormData('IncapacityStartDate', inputData.incapacityStartDate, formData)
    fillFormData('RateIncapacity', inputData.rateIncapacity, formData)
    fillFormData('BackToWork', inputData.backToWork, formData)
    fillFormData('IncapacityEndDate', inputData.incapacityEndDate, formData)

    // DentalInjuriesForm
    fillFormData('FoodAccident', inputData.foodType, formData)
    fillFormData('UnusualEventOccurred', inputData.unusualEvent, formData)
    fillFormData('ForeignParticles', inputData.strange, formData)
    fillFormData('StillHaveForeignParticles', inputData.possession, formData)
    fillFormData('DentalAccidentDetails', inputData.acquisition, formData)
    fillFormData('AnnouncedWhereBought', inputData.announce, formData)
    fillFormData('StillHaveRelease', inputData.bill, formData)
    fillFormData('AttachedFileRelease', inputData.productBill, formData)
    fillFormData('AnnouncedWhereBoughtDetails', inputData.announceDetails, formData)
    fillFormData('StoreCoverage', inputData.sellerInsurance, formData)

    // VehiculeForm
    fillFormData('TrafficPlace', inputData.injured, formData)
    fillFormData('VehiculeDriver', inputData.vehicleDriver, formData)
    fillFormData('VehiculeOwner', inputData.vehicleDriver, formData)
    fillFormData('VehiculeDriverAccidentInsurance', inputData.ownerInsurance, formData)
    fillFormData('VehiculeNumberplate', inputData.ownerCarPlates, formData)
    fillFormData('OtherDriver', inputData.otherVehicleDriver, formData)
    fillFormData('OwnVehicule', inputData.owner, formData)
    fillFormData('OtherOwner', inputData.otherVehicleOwner, formData)
    fillFormData('OtherAccidentInsurance', inputData.otherVehicleInsurance, formData)
    fillFormData('AccidentAnnounced', inputData.otherInsuranceDeclared, formData)
    fillFormData('OtherNumberplate', inputData.otherVehiclePlates, formData)
    fillFormData('IncidentNumber', inputData.otherInsuranceNumber, formData)
    fillFormData('FindingAmicably', inputData.friendlyArrangement, formData)
    fillFormData('Report', inputData.friendlyDocument, formData)

    // AccidentInsuranceForm
    fillFormData('IsAnotherAccidentInsurance', inputData.otherInsurance, formData)
    fillFormData('AnotherAccidentInsurance', inputData.otherInsuranceName, formData)
    fillFormData('AnotherInsuranceType', inputData.otherInsuranceCoverage, formData)
    fillFormData('OtherDoc', inputData.otherDocuments, formData)
    fillFormData('AssuraAuthorization', inputData.cbconfirm, formData)

    return formData
}
