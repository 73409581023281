import './DatePickerRHF.css'

import React from 'react'
import { useTranslation } from 'react-i18next'

interface DatePickerCustomProps {
    placeholderText?: string
    dataTestId: string
    hasError?: boolean
    errorMessage?: string
    type?: 'date' | 'time'
    refId?: string
}

const DatePickerRHF = React.forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement> & DatePickerCustomProps,
        HTMLInputElement
    >
>(
    (
        {
            value,
            onClick,
            placeholderText,
            dataTestId,
            hasError,
            errorMessage,
            type = 'date',
            refId
        },
        ref
    ): JSX.Element => {
        const { t } = useTranslation()
        return (
            <>
                <div
                    id={refId}
                    data-testid={`date-picker-${dataTestId}`}
                    className={`date-picker-rhf-input${
                        hasError ? ' bg-error-background bc-primary' : ' bc-gray100'
                    }`}
                    onClick={onClick}
                    ref={ref}
                >
                    {value ? (
                        <div className="label m-right-8">{value}</div>
                    ) : (
                        <div className="label c-gray500 m-right-8">{placeholderText}</div>
                    )}

                    <i
                        className={`icon size-24 ${
                            type === 'date' ? 'assura-calendar' : 'assura-clock'
                        } c-black m-right-8`}
                        data-testid={`date-picker-${dataTestId}-icon`}
                    />
                </div>
                {errorMessage && (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid={`date-picker-${dataTestId}-error`}
                    >
                        {t(errorMessage)}
                    </div>
                )}
            </>
        )
    }
)

export default DatePickerRHF
