import { call, delay, fork, put, takeLatest } from 'redux-saga/effects'

import { fetchLocalities, fetchStreets } from 'core/api/Localities'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import Locality from 'core/models/Localities'
import { Street } from 'core/models/Street'

import {
    searchLocalities,
    searchStreets,
    setLocalities,
    setLocalitiesLoadingStatus,
    setStreets,
    setStreetsLoadingStatus
} from './localities.slice'

function* searchLocalitiesSaga(action: ReturnType<typeof searchLocalities>) {
    yield delay(500) // Avoid calling WS for each character written (Throttling)
    const localityFormField = action.payload

    try {
        yield put(setLocalitiesLoadingStatus(LoadingStatusEnum.LOADING))

        const localities: Locality[] = yield call(fetchLocalities, localityFormField)

        yield put(setLocalities(localities.slice(0, 100)))
        yield put(setLocalitiesLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('searchLocalitiesSaga Error', e)
        yield put(setLocalitiesLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* searchStreetsSaga(action: ReturnType<typeof searchStreets>) {
    yield delay(500) // Avoid calling WS for each character written (Throttling)
    const { localityId, street } = action.payload

    try {
        yield put(setStreetsLoadingStatus(LoadingStatusEnum.LOADING))

        const streets: Street[] = yield call(fetchStreets, localityId, street)

        yield put(setStreets(streets.slice(0, 100)))
        yield put(setStreetsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('searchStreetsSaga Error', e)
        yield put(setStreetsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* searchLocalitiesWatcher() {
    yield takeLatest(searchLocalities.type, searchLocalitiesSaga)
}

function* searchStreetsWatcher() {
    yield takeLatest(searchStreets.type, searchStreetsSaga)
}

const watchers = [fork(searchLocalitiesWatcher), fork(searchStreetsWatcher)]

export default watchers
