import { ElementType } from 'react'

import { PaymentTypeEnum } from 'core/enums/Documents'
import concatClassNames from 'core/utils/classNameUtils'

export type PaymentType = (typeof PaymentTypeEnum)[keyof typeof PaymentTypeEnum]

interface PaymentTypeIconProps<C extends ElementType> {
    paymentType: PaymentType
    testId: string
    containerClassNames?: string
    isSmall?: boolean
    as?: C
}

const PaymentTypeIcon = <C extends ElementType = 'div'>({
    paymentType,
    testId,
    containerClassNames,
    isSmall,
    as
}: PaymentTypeIconProps<C>): JSX.Element => {
    const As = as || 'div'

    const containerClasses = concatClassNames(['d-flex align-items-center', containerClassNames])
    const moneySize = isSmall ? 'size-16' : 'size-24'

    let typeIconClasses = 'assura-back c-success'
    if (paymentType === PaymentTypeEnum.INVOICE) typeIconClasses = 'assura-next c-error'
    if (paymentType === PaymentTypeEnum.CANCELED) typeIconClasses = 'assura-close c-gray500'
    const typeIconSize = isSmall ? 'size-12' : 'size-16'

    const typeIconClass = concatClassNames(['icon', typeIconSize, typeIconClasses])

    return (
        <As className={containerClasses}>
            <i className={`icon assura-money ${moneySize}`} />
            <i className={typeIconClass} data-testid={`${testId}-payment-icon`} />
        </As>
    )
}

export default PaymentTypeIcon
