import { MobileStoreEnum } from 'core/enums/MobileStore'
import fallbackImage from 'shared/assets/images/appstore-fr.png'

import MobileStoreBadge from './MobileStoreBadge'

const appStoreEcLink = '65f003dfc93854f9c603cd82'

interface AppStoreBadgeProps {
    testId: string
    width: number
    height: number
    classNames?: string
}
const AppStoreBadge = ({ testId, width, height, classNames }: AppStoreBadgeProps) => {
    return (
        <MobileStoreBadge
            store={MobileStoreEnum.APP_STORE}
            ecLinkId={appStoreEcLink}
            testId={testId}
            classNames={classNames}
            width={width}
            height={height}
            fallbackImage={fallbackImage}
        />
    )
}

export default AppStoreBadge
