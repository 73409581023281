import './NoEnveloppes.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'

interface NoEnveloppesProps {
    message: string
}

const NoEnveloppes = ({ message }: NoEnveloppesProps): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const sendEnveloppe = () => {
        navigate(navigationConstants.ENVELOPPES_SUBMISSION.url)
    }

    return (
        <tr className="home-no-enveloppes-container" data-testid="home-no-enveloppes">
            <td className="paragraphSmall text-center">{message}</td>
            <td>
                <AssuraButton
                    id="navigation-enveloppes-submission"
                    onClick={sendEnveloppe}
                    text={t('ENVELOPPES.SEND_ENVELOPPE_CTA')}
                    variant="secondary"
                    icon={{
                        name: 'assura-plus',
                        size: 24
                    }}
                    classNames="m-top-32"
                />
            </td>
        </tr>
    )
}

export default NoEnveloppes
