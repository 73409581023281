import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { hasFinancialWritePermission } from 'core/helpers/AuthenticationHelper'
import { PaymentChangeRHF } from 'core/models/services/financial/PaymentChange'
import { isEmailValid } from 'core/utils/TextUtils'
import ConsentCheckBoxRHF from 'modules/services/components/ConsentCheckBoxRHF/ConsentCheckBoxRHF'
import FormPage from 'modules/services/components/FormPage/FormPage'
import NoHead from 'modules/services/components/NoHead/NoHead'
import NoValidEmail from 'modules/services/components/NoValidEmail/NoValidEmail'
import PendingRequest from 'modules/services/components/PendingRequest/PendingRequest'
import { removeBanner, setBanner, setForbiddenBanner } from 'shared/store/banners/banners.slice'
import { getHeadOfFamily } from 'shared/store/general/general.slice'
import { getFamilyGroupSettings } from 'shared/store/selectors/getFamilyGroupSettings'
import { getFullFinancialData } from 'shared/store/selectors/getFullFinancialData'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'
import { onSubmitPaymentChange } from 'shared/store/services/financial/financial.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PaymentChangeNumber from '../components/PaymentChangeNumber/PaymentChangeNumber'
import PaymentChangeOwner from '../components/PaymentChangeOwner/PaymentChangeOwner'

const ServicesPaymentChange = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const familyGroup = useSelector(getFamilyGroupSettings)
    const isPending = useSelector(getFullFinancialData).isPending
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? false
    const headOfFamily = useSelector(getHeadOfFamily)
    const email = useSelector(getFullPersonalData).email.data
    const isValidEmail = email && isEmailValid(email)
    const isFromPublic = Source() === ServicesSource.WEBSITE

    const shouldConsent = isFromPublic || familyGroup.length > 1

    const defaultValues: PaymentChangeRHF = {
        accountOwnerPolicyNumber: familyGroup[0]?.policyNumber || 0,
        type: 'iban',
        iban: null,
        ccp: null,
        consent: !shouldConsent
    }

    const qrIban = 'qr-iban'

    const onSuccess = (values: PaymentChangeRHF) => {
        if (values.iban) {
            const qrCheck = +values.iban.replace(/ /g, '').substring(4, 9)
            if (qrCheck >= 30000 && qrCheck < 32000) {
                dispatch(
                    setBanner({
                        id: qrIban,
                        dataTestId: qrIban,
                        message: 'PERSONAL_DATA.FINANCIAL_IBAN_RULE_QR'
                    })
                )
                return
            } else {
                dispatch(removeBanner(qrIban))
            }
        }

        if (!hasFinancialWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }

        dispatch(
            onSubmitPaymentChange({
                accountOwnerPolicyNumber: values.accountOwnerPolicyNumber,
                type: values.type,
                iban: values.iban?.replace(/ /g, '') ?? null,
                ccp: values.ccp
            })
        )
    }

    const getNotAllowed = () => {
        if (!isHeadOfFamily)
            return (
                <NoHead
                    title={t('PERSONAL_DATA.FINANCIAL_NO_HEAD_TEXT')}
                    message={
                        <Trans
                            i18nKey="PERSONAL_DATA.FINANCIAL_NO_HEAD_OF_FAMILY_BOLD"
                            values={{ firstName: headOfFamily?.firstname ?? '' }}
                            components={{ b: <b /> }}
                        />
                    }
                />
            )

        if (isFromPublic && !isValidEmail)
            return <NoValidEmail text={t('FINANCIAL.PAYMENT_CHANGE_NO_EMAIL_MESSAGE')} />
        return undefined
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.BANK_REL_CHANGE}
            formTitle={{
                title: t('FINANCIAL.FORM_TITLE')
            }}
            onSuccess={onSuccess}
            userInfos
            goodToKnow={
                <GoodToKnow
                    goodToKnowId={ServiceType.BANK_REL_CHANGE}
                />
            }
            isPending={isPending ? <PendingRequest /> : undefined}
            notAllowed={getNotAllowed()}
        >
            <>
                <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
                    <PaymentChangeOwner familyGroup={familyGroup} />
                    <PaymentChangeNumber />
                </div>
                {shouldConsent && <ConsentCheckBoxRHF name="consent" />}
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    ServicesPaymentChange,
    'ServicesPaymentChange',
    'services-form-container'
)
