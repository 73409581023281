import { Assets } from 'core/models/cms/AssetCms'

import { createSelector } from '@reduxjs/toolkit'

import { getCmsAssets } from '../assets/assets.slice'

export const getAssets = createSelector(
    [getCmsAssets],
    (cmsAssets): Assets => {
        const cms = process.env.REACT_APP_CMS_ASSET

        return cmsAssets.reduce<Assets>((acc, item) => {
            return {
                ...acc,
                [item.code]: {
                    fr: item.assetName?.path ? `${cms}${item.assetName.path}` : null,
                    it: item.assetName_it?.path ? `${cms}${item.assetName_it.path}` : null,
                    de: item.assetName_de?.path ? `${cms}${item.assetName_de.path}` : null,
                    en: item.assetName_en?.path ? `${cms}${item.assetName_en.path}` : null
                }
            }
        }, {})
    }
)
