import { DEFAULT_LANGUAGE, ENGLISH_LANGUAGE } from '../constants/lang'

export const getLocalizedUrl = (url: string | undefined): string => {
    let lang = localStorage.getItem('lang') ?? DEFAULT_LANGUAGE

    if (!url) return ''
    if (lang === ENGLISH_LANGUAGE) {
        // if english then fallsback as requested in https://assura.visualstudio.com/ATLAS/_workitems/edit/254765 below in comments of 18 March 2022
        lang = DEFAULT_LANGUAGE
    }
    return url.replace('/load/client', `/${lang}/load/client`)
}
