import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface FamilyMemberState {
    index: number
    initialPolicyNumber: number
    // note: if using directly a primitive type
    // we must return state at each reducer...
}

interface FetchFamilyMember {
    selectedIndex: number
    reload?: boolean
}

interface FetchInitialPolicyNumber {
    initialPolicyNumber: number
}

const INITIAL_STATE_FAMILY_MEMBER: FamilyMemberState = {
    index: 0,
    initialPolicyNumber: 0
}

export const familyMemberSlice = createSlice({
    name: 'familyMember',
    initialState: INITIAL_STATE_FAMILY_MEMBER,
    reducers: {
        fetchFamilyMember: (state, action: PayloadAction<FetchFamilyMember>) => {
            state.index = action.payload.selectedIndex
        },
        fetchInitialPolicyNumber: (state, action: PayloadAction<FetchInitialPolicyNumber>) => {
            state.initialPolicyNumber = action.payload.initialPolicyNumber
        },
        reset: () => INITIAL_STATE_FAMILY_MEMBER
    }
})

export const { fetchFamilyMember, fetchInitialPolicyNumber, reset } = familyMemberSlice.actions

export const getFamilyMemberIndex: Selector<RootState, number> = (state: RootState) =>
    state.familyMember.index

export const getInitialPolicyNumber: Selector<RootState, number> = (state: RootState) =>
    state.familyMember.initialPolicyNumber

export default familyMemberSlice.reducer
