import './AccidentCard.css'

import { useTranslation } from 'react-i18next'

import { AccidentVariant } from 'core/models/familyGroup/AccidentVariant'
import { Variant } from 'core/models/familyGroup/Variant'
import useWindowSize from 'core/services/useWindowSize'
import { formatNumber } from 'core/utils/TextUtils'

interface AccidentCardProps {
    data: AccidentVariant
}
const AccidentCard = (props: AccidentCardProps): JSX.Element => {
    const { data } = props
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()

    const hasCapitalsData = data.capitalsData && data.capitalsData.length > 0
    const hasAllowancesData = data.allowancesData && data.allowancesData.length > 0

    const renderVariant = (variant: Variant, key: string) => {
        return (
            <div className="accident-card-variant-container btc-gray100" key={key}>
                <i className="icon assura-check c-primary size-20" />
                <div
                    className={`${
                        isXSD ? 'labelExtraSmall' : 'labelSmall'
                    } accident-card-variant-label`}
                >
                    {t(variant.label)}
                </div>
                <div
                    data-testid={`variant-label-${variant.label}-value-${data.productCode}`}
                    className={isXSD ? 'labelExtraSmallMedium' : 'labelSmallMedium'}
                >
                    {t(variant.labelValue, { price: formatNumber(variant.value, false) })}
                </div>
            </div>
        )
    }
    const renderCapitalsSection = (capitalsData: Variant[]) => {
        return (
            <div>
                <div className="headlineSmallMedium m-bottom-8">{t('COVERAGE.CAPITALS')}</div>
                {capitalsData.map((variant, index) => renderVariant(variant, index.toString()))}
            </div>
        )
    }

    const renderAllowancesSection = (allowancesData: Variant[]) => {
        return (
            <div style={{ marginTop: hasCapitalsData ? 32 : 0 }}>
                <div className="headlineSmallMedium m-bottom-8">{t('COVERAGE.ALLOWANCES')}</div>
                {allowancesData.map((variant, index) => renderVariant(variant, index.toString()))}
            </div>
        )
    }

    const renderHealingCostSection = () => {
        return (
            <div style={{ marginTop: hasCapitalsData || hasAllowancesData ? 32 : 0 }}>
                <div className="headlineSmallMedium m-bottom-8">{t('COVERAGE.HEALING_COST')}</div>
                <div className="accident-card-variant-container btc-gray100" key="healingCost">
                    <i className="icon assura-check c-primary size-20" />
                    <div
                        className={`${
                            isXSD ? 'labelExtraSmall' : 'labelSmall'
                        } accident-card-variant-label`}
                    >
                        {t('COMMON.COVERED')}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="accident-card-container bg-gray50">
            {hasCapitalsData && renderCapitalsSection(data.capitalsData)}
            {hasAllowancesData && renderAllowancesSection(data.allowancesData)}
            {data.healingCost === 1 && renderHealingCostSection()}
        </div>
    )
}

export default AccidentCard
