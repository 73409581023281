import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { emptyFunction } from '../../core/helpers/functionHelper'
import useWindowSize from '../../core/services/useWindowSize'

interface PanelWidthProviderProps {
    children: ReactNode
}

export interface PanelDatas {
    panelWidth: number
    hasLeftPanel: boolean
    hasPDFPanel: boolean
}

interface PanelDatasProps {
    hasLeftPanel?: boolean
    hasPDFPanel?: boolean
}

interface DatasContext {
    panelDatas: PanelDatas
    update: (props: PanelDatasProps) => void
}

export const leftPanelWidth = 498
const defaultFullWidth = 100
const initialPercent = Math.ceil((leftPanelWidth * 100) / window.innerWidth)

const initialPanelDatas: PanelDatas = {
    panelWidth: initialPercent,
    hasLeftPanel: false,
    hasPDFPanel: true
}

const PanelDatasContext = createContext<DatasContext>({
    panelDatas: initialPanelDatas,
    update: emptyFunction
})

const PanelDatasProvider = ({ children }: PanelWidthProviderProps): JSX.Element => {
    const [panelState, setPanelState] = useState(initialPanelDatas)

    const { width, isXL, isMobile } = useWindowSize()

    const setPanelWidth = () => {
        const { hasLeftPanel, hasPDFPanel } = panelState
        let panelWidth = 0
        if (hasLeftPanel && hasPDFPanel) {
            panelWidth = isXL ? 90 : defaultFullWidth
        } else if (hasLeftPanel) {
            panelWidth = isMobile ? defaultFullWidth : Math.round((leftPanelWidth * 100) / width)
        } else if (hasPDFPanel) {
            panelWidth = isXL ? 55 : defaultFullWidth
        }
        setPanelState({ hasLeftPanel, hasPDFPanel, panelWidth })
    }

    useEffect(() => {
        setPanelWidth()
    }, [isMobile, isXL, width, panelState.hasLeftPanel, panelState.hasPDFPanel])

    const updatePanelState = (props: PanelDatasProps): void => {
        setPanelState({
            ...panelState,
            ...props
        })
    }

    const value: DatasContext = { panelDatas: panelState, update: updatePanelState }

    return <PanelDatasContext.Provider value={value}>{children}</PanelDatasContext.Provider>
}

const usePanelDatasContext = (): DatasContext => {
    return useContext(PanelDatasContext)
}

export { PanelDatasProvider, usePanelDatasContext }
