import { SummaryResult } from 'core/models/familyGroup/SummaryResult'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getBeginDate } from '../services/coverage/coverage.slice'

export const getSummaryBeginDateYear = createSelector(
    [getFamilySummaries, getBeginDate],
    (summaries: Record<number, SummaryResult>, beginDate: Date | undefined) => {
        if (beginDate && summaries[beginDate.getFullYear()]) {
            return summaries[beginDate.getFullYear()]
        }
        return null
    }
)
