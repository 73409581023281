import React, { createContext, useContext, useRef, useState } from 'react'

import useOnClickOutside from 'core/services/useOnClickOutside'

import { emptyFunction } from '../../core/helpers/functionHelper'

// the main type which is responsible to describe state of fields
type FieldState = {
    name: string
    isDirty: boolean
    isFocus: boolean
    isPristine: boolean
}
// the fields Context model
interface FieldsContext {
    fieldStates: FieldState[]

    setDirty: (name: string, state: boolean) => void
    setFocus: (name: string, state: boolean) => void
    setPristine: (name: string, state: boolean) => void

    isDirty: (name: string) => boolean | undefined
    isFocus: (name: string) => boolean | undefined
    isPristine: (name: string) => boolean | undefined
}

/*  FYI:
 * Le problème c'est que react hook forms ne fournit pas l'état des inputs au moment souhaité
 */

// the fields Context CONTEXT definition
const FieldsContext = createContext<FieldsContext>({
    fieldStates: [],

    setDirty: emptyFunction,
    setFocus: emptyFunction,
    setPristine: emptyFunction,
    isDirty: () => false,
    isFocus: () => false,
    isPristine: () => false
})

type FieldsContextProviderProps = {
    children: React.ReactElement[] | React.ReactElement
    fieldList: FieldState[]
}

const FieldsContextProvider = ({
    children,
    fieldList
}: FieldsContextProviderProps): JSX.Element => {
    const [fields, setFields] = useState<FieldState[]>(fieldList)

    const divRef = useRef<HTMLDivElement>(null)

    const handleOutsideClick = () => {
        const tmpCollection = [...fields]
        clearFocus(tmpCollection)
        setFields(tmpCollection)
    }

    useOnClickOutside(divRef, handleOutsideClick)

    // get the field from the collection
    const getFiled = (name: string): FieldState | undefined => fields.find((o) => o.name === name)
    // clear all focus collection
    const clearFocus = (array: FieldState[]): void =>
        array.forEach((o) => {
            o.isFocus = false
        })
    // alter current field (clone => reproduce collection => set state)
    const alterField = (field: FieldState) => {
        const tmpCollection = [...fields]
        clearFocus(tmpCollection)
        const newCollection = [field, ...tmpCollection.filter((o) => o.name !== field.name)]
        setFields(newCollection)
    }

    const setDirty = (name: string, state: boolean) => {
        const field = { ...getFiled(name) } as FieldState
        if (!field) return
        field.isDirty = state
        alterField(field)
    }

    const setFocus = (name: string, state: boolean) => {
        const field = { ...getFiled(name) } as FieldState
        if (!field) return
        field.isFocus = state
        alterField(field)
    }

    const setPristine = (name: string, state: boolean) => {
        const field = { ...getFiled(name) } as FieldState
        if (!field) return
        field.isPristine = state
        alterField(field)
    }

    const isDirty = (name: string): boolean | undefined => getFiled(name)?.isDirty
    const isFocus = (name: string): boolean | undefined => getFiled(name)?.isFocus
    const isPristine = (name: string): boolean | undefined => getFiled(name)?.isPristine

    const exposeContext: FieldsContext = {
        fieldStates: fields,
        setDirty,
        setFocus,
        setPristine,
        isDirty,
        isFocus,
        isPristine
    }

    return (
        <FieldsContext.Provider value={exposeContext}>
            <div ref={divRef}>{children}</div>
        </FieldsContext.Provider>
    )
}

const useFieldsContext = (): FieldsContext => {
    return useContext(FieldsContext)
}

export { FieldsContextProvider, useFieldsContext }
