import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ViewAddress } from 'core/models/familyGroup/ViewAddress'
import { getInlineAddress } from 'core/utils/addressUtils'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'

import { ErrorMessage } from '@hookform/error-message'

interface AddMemberConsentProps {
    address: ViewAddress | null
}

const AddMemberConsent = ({ address }: AddMemberConsentProps): JSX.Element => {
    const { t } = useTranslation()

    const { register } = useFormContext()

    const rules = {
        required: {
            value: true,
            message: 'COMMON.MANDATORY_FIELD'
        }
    }

    const getErrorMessage = (name: string) => {
        return (
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid={`services-add-member-${name}-error`}
                    >
                        {t(message)}
                    </div>
                )}
            />
        )
    }

    return (
        <div className="provisional-certificate-container bg-white bc-gray100 m-top-32 p-32">
            <CheckboxRHF
                id="services-add-member-consentLogin"
                name="consentLogin"
                label={t('SERVICES.ADD_MEMBER_CONSENT_1')}
                register={register}
                labelClassNames="paragraph"
                rules={rules}
            />
            {getErrorMessage('consentLogin')}
            <CheckboxRHF
                id="services-add-member-consentAddress"
                name="consentAddress"
                label={t('SERVICES.ADD_MEMBER_CONSENT_2')}
                register={register}
                classNames="m-top-24"
                labelClassNames="paragraph"
                rules={rules}
            />
            <div className="paragraph m-left-32">{getInlineAddress(address)}</div>
            {getErrorMessage('consentAddress')}
        </div>
    )
}

export default AddMemberConsent
