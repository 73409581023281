import './AssuraTable.css'

import { useRef } from 'react'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { TableNoResultMessages } from 'core/models/Table'
import { FilterSelectionValue, TableFilterState } from 'core/models/TableFilter'
import concatClassNames from 'core/utils/classNameUtils'
import documentError from 'shared/assets/images/document_error.png'

import NoResults from '../NoResults/NoResults'
import ScrollToTopFloatingButton from '../ScrollToTopFloatingButton/ScrollToTopFloatingButton'
import AssuraTableHeader, { AssuraTableHeaderRef } from './AssuraTableHeader/AssuraTableHeader'
import AssuraTableLoadAndError from './AssuraTableLoadAndError/AssuraTableLoadAndError'
import AssuraTableMoreButton from './AssuraTableMoreButton/AssuraTableMoreButton'

interface AssuraTableProps<T, K extends FilterSelectionValue> {
    id: string
    rows: T[]
    rowComponent: (row: T) => JSX.Element
    loadStatus: LoadingStatusEnum
    paginationStatus: LoadingStatusEnum
    filteringStatus: LoadingStatusEnum
    noResultMessages: TableNoResultMessages
    onReload: () => void
    onRowSelection: (row: T) => void
    onMoreSelection?: () => void
    hasMore?: boolean
    filters?: TableFilterState<K>
    onFilterChange?: (state: TableFilterState<K>) => void
    extraRowClasses?: (row: T) => string
}

const AssuraTable = <T, K extends FilterSelectionValue>({
    id,
    rows,
    rowComponent,
    loadStatus,
    paginationStatus,
    filteringStatus,
    onReload,
    onRowSelection,
    onMoreSelection,
    noResultMessages,
    hasMore,
    onFilterChange,
    filters,
    extraRowClasses
}: AssuraTableProps<T, K>): JSX.Element => {
    const headerRef = useRef<AssuraTableHeaderRef>(null)

    const triggerRef = useRef<HTMLTableSectionElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const handleFilterGlobalReset = () => {
        headerRef.current && headerRef.current.globalResetAction()
    }

    const handleExtraRowClasses = (row: T): string => {
        let extraClasses = ''
        if (typeof extraRowClasses === 'function') {
            extraClasses = extraRowClasses(row)
        }
        return concatClassNames(['assura-table-row', extraClasses])
    }

    const tableId = `${id}-table`

    return (
        <div
            ref={containerRef}
            className="assura-table-main-container"
            id={tableId}
            data-testid={tableId}
        >
            <table
                className={`assura-table-container${
                    loadStatus !== LoadingStatusEnum.OK || filteringStatus !== LoadingStatusEnum.OK
                        ? ' load-and-error'
                        : ''
                }`}
            >
                <AssuraTableLoadAndError status={loadStatus} defaultReloadAction={onReload}>
                    {rows.length > 0 ||
                    headerRef.current?.hasActivatedFilter ||
                    filteringStatus === LoadingStatusEnum.LOADING ? (
                        <>
                            <thead className="assura-table-header" ref={triggerRef}>
                                {onFilterChange && filters && (
                                    <AssuraTableHeader
                                        context={id}
                                        filters={filters}
                                        onFilterChange={onFilterChange}
                                        ref={headerRef}
                                        filteringStatus={filteringStatus}
                                    />
                                )}
                            </thead>
                            <AssuraTableLoadAndError
                                status={filteringStatus}
                                defaultReloadAction={onReload}
                            >
                                <tbody>
                                    {rows.length !== 0 ? (
                                        rows.map((row, index) => (
                                            <tr
                                                className={handleExtraRowClasses(row)}
                                                key={`table-row-${index}`}
                                                onClick={() => onRowSelection(row)}
                                                data-testid={`assura-table-row-${index}`}
                                            >
                                                {rowComponent(row)}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <NoResults
                                                text={
                                                    noResultMessages.filters?.label ??
                                                    noResultMessages.datas.label
                                                }
                                                image={
                                                    noResultMessages.filters?.image ?? documentError
                                                }
                                                dataTestId="assura-table-filters"
                                                onReset={handleFilterGlobalReset}
                                                as="td"
                                            />
                                        </tr>
                                    )}
                                    {rows.length > 10 && (
                                        <tr>
                                            <ScrollToTopFloatingButton
                                                triggeringRef={triggerRef}
                                                as="td"
                                            />
                                        </tr>
                                    )}
                                </tbody>
                            </AssuraTableLoadAndError>
                            <tfoot>
                                {filteringStatus === LoadingStatusEnum.OK && (
                                    <tr>
                                        <AssuraTableMoreButton
                                            hasMore={hasMore}
                                            paginationStatus={paginationStatus}
                                            onMoreSelection={onMoreSelection}
                                        />
                                    </tr>
                                )}
                            </tfoot>
                        </>
                    ) : (
                        <tbody>
                            <tr>
                                <NoResults
                                    text={noResultMessages.datas.label}
                                    image={noResultMessages.datas.image ?? documentError}
                                    dataTestId="assura-table-data"
                                    complementaryClasses="assura-table-no-result"
                                    as="td"
                                />
                            </tr>
                        </tbody>
                    )}
                </AssuraTableLoadAndError>
            </table>
        </div>
    )
}

export default AssuraTable
