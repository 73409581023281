/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Form } from 'react-bootstrap'

export interface ErrorProps {
    id: string
    isInvalid: boolean
    error: string
}

export const withError = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithError extends React.Component<P & ErrorProps> {
        render() {
            const { id, isInvalid, error } = this.props
            return isInvalid ? (
                <>
                    <WrappedComponent {...(this.props as P)} />
                    <Form.Control.Feedback type="invalid">
                        <div
                            key={`field-error`}
                            className="labelExtraSmall"
                            data-testid={id + '-error'}
                        >
                            {error}
                        </div>
                    </Form.Control.Feedback>
                </>
            ) : (
                <WrappedComponent {...(this.props as P)} />
            )
        }
    }
