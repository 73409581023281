import axios, {
    AxiosError,
    AxiosRequestHeaders,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios'
import { maintenanceCmsData } from 'core/api/Cms'
import { getCookie } from 'core/helpers/CookieHelper'
import { IsMaintenanceInProgress } from 'core/helpers/maintenance/MaintenanceHelper'
import authService from 'index'

import { getDeviceInfoHeaders } from '../api/Helper'

const UNAUTHORIZED = 401

// Add JWT token in header
axios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        if (authService.User?.expired) {
            // if token expired, redirect to login page
            await authService.login()
        }

        // We add access token for Assura API calls
        if (
            config.url?.includes(process.env.REACT_APP_API_COMMON ?? '') ||
            config.url?.includes(process.env.REACT_APP_API_IMAGE ?? '')
        ) {
            const baseHeader = {
                ...config.headers,
                ...(getDeviceInfoHeaders() as unknown as AxiosRequestHeaders),
                Authorization: `Bearer ${authService.User?.access_token}`
            } as AxiosRequestHeaders

            config.headers = baseHeader
            return config
        }

        return config
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

// redirect to login page (using login) when receiving a 401
// redirect to idp (using logout) when there is a valid ongoing maintenance
axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        if (error?.response) {
            const { status, config } = error.response

            if (
                status === UNAUTHORIZED &&
                !config.url?.includes(process.env.REACT_APP_CMS_SERVER ?? '')
            ) {
                await authService.login()
            }

            if (
                status !== UNAUTHORIZED &&
                (await isAnyMaintenanceInProgressAsync()) &&
                !bypassCookieExists()
            ) {
                authService.logout()
            }
        }

        if (
            error.message.includes('Network Error') &&
            (await isAnyMaintenanceInProgressAsync()) &&
            !bypassCookieExists()
        ) {
            authService.logout()
        }
        return Promise.reject(error)
    }
)

const isAnyMaintenanceInProgressAsync = async (): Promise<boolean> => {
    const maintenances = await maintenanceCmsData()

    if (!maintenances || maintenances.entries.length === 0) return false

    for (const maintenance of maintenances.entries) {
        if (IsMaintenanceInProgress(maintenance)) {
            return true
        }
    }

    return false
}

const bypassCookieExists = () => {
    const bypassCookie = getCookie('MaintenanceBypass')
    return bypassCookie === 'bypass-8101310995914AB680D53B419D0FAC16'
}
