import '../../pages/Aventura.css'

import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { AventuraRHFModel } from 'core/models/services/coverage/AventuraForm'
import useWindowSize from 'core/services/useWindowSize'
import FormTitle from 'modules/services/components/FormTitle/FormTitle'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'
import UserInfo from 'modules/services/components/UserInfo/UserInfo'
import { removeBanner, setBanner } from 'shared/store/banners/banners.slice'

import AventuraProviderContent from './AventuraProviderContent'

const AventuraGoodToKnow = () => (
    <GoodToKnow goodToKnowId={ServiceType.AVENTURA_INSURANCE_REQUEST} />
)

const AventuraFormProvider = (): JSX.Element => {
    const { isMobile, isMD } = useWindowSize()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const hasGoodToKnow = !!AventuraGoodToKnow()

    const defaultValues: AventuraRHFModel = {
        policyNumber: 0,
        source: Source(),
        backOfficeUser: Source() === ServicesSource.BACKOFFICE ? '' : undefined,
        email: '',
        phoneNumber: '',
        travelReason: '',
        travelDestination: '',
        travelStart: '',
        travelEnd: '',
        paperFilled: true,
        aventuraCoverType: 'Single',
        personSelector: [],
        personCountSelector: [],
        additionalFamilyMembers: []
    }

    const methods = useForm<AventuraRHFModel>({
        defaultValues,
        shouldFocusError: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    })

    const isCustomerPortal = Source() === ServicesSource.CUSTOMER_PORTAL
    const invalidFormBannerId = 'aventura'

    useEffect(() => {
        if (Object.keys(methods.formState.errors).length === 0) {
            dispatch(removeBanner(invalidFormBannerId))
        } else {
            dispatch(
                setBanner({
                    id: invalidFormBannerId,
                    dataTestId: invalidFormBannerId,
                    message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
                })
            )
        }
    }, [methods.formState])

    return (
        <FormProvider {...methods}>
            <Row className="p-bottom-80">
                <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                    {(!hasGoodToKnow || (hasGoodToKnow && !isMobile)) && (
                        <FormTitle
                            data-testid="services-form-title"
                            title={t('SERVICE.AVENTURA_INSURANCE_HEADLINE_TITLE')}
                            category={t('SERVICE.AVENTURA_INSURANCE_CATEGORY')}
                        />
                    )}
                    <div
                        className="m-top-24 bg-gray100"
                        style={{
                            display: isMobile ? 'flex' : 'none',
                            height: '1px',
                            position: 'relative',
                            border: 'none'
                        }}
                    ></div>
                    <div
                        className={`headline ${isMobile ? 'm-top-32' : ''}`}
                        data-testid="services-form-sub-title"
                    >
                        {t('SERVICE.AVENTURA_INSURANCE_SUB_TITLE')}
                    </div>
                    <div className="av-userinfo-block bg-white bc-gray100 border-solid-width-1 ">
                        {Source() === ServicesSource.CUSTOMER_PORTAL && (
                            <div className="av-userinfo-block-disclamer paragraphSmall bg-gray50 bc-gray100 border-solid-width-1">
                                {t('SERVICE.AVENTURA_INSURANCE_DISCLAMER')}
                            </div>
                        )}
                        <div
                            className="av-userinfo-block-data"
                            style={{
                                marginTop: `${isCustomerPortal ? '32' : '0'}px`
                            }}
                        >
                            <UserInfo
                                allowedSources={[]}
                                style={{
                                    border: 'none',
                                    padding: 0,
                                    margin: 0
                                }}
                            />
                        </div>
                    </div>
                    <AventuraProviderContent isMobile={isMobile} isMD={isMD} />
                </Col>
                {hasGoodToKnow && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        md={{ span: 4, order: 2 }}
                        xl={{ span: 4, offset: 1 }}
                    >
                        {isMobile && (
                            <FormTitle
                                data-testid="services-form-title-mobile"
                                title={t('SERVICE.AVENTURA_INSURANCE_HEADLINE_TITLE')}
                                category={t('SERVICE.AVENTURA_INSURANCE_CATEGORY')}
                            />
                        )}
                        {AventuraGoodToKnow()}
                    </Col>
                )}
            </Row>
        </FormProvider>
    )
}

export default AventuraFormProvider
