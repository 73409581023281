import './LandingPageHealthRoute.css'

import { useTranslation } from 'react-i18next'

import plusmed_bis from 'shared/assets/images/plusmedbis.png'
import AppStoreBadge from 'shared/components/MobileStore/AppStoreBadge'
import PlayStoreBadge from 'shared/components/MobileStore/PlayStoreBadge'

const LandingPageHealthRoute = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="landing-page-health-route">
            <div className="d-flex flex-column">
                <img src={plusmed_bis} className="image-size-48" alt="parcours" />
            </div>
            <div className="landing-page-health-route-text">
                <div className="label">{t('QUALIMED.HEALTH_ROUTE')}</div>
                <div className="labelSmall m-top-4 c-gray500">
                    {t('SYMPTOM_CHECKER.LANDING_PAGE_HEALTH_ROUTE_TEXT')}
                </div>
            </div>
            <div className="landing-page-mobile-stores-container">
                <AppStoreBadge testId="landing-page-play-store" width={135} height={41} />
                <PlayStoreBadge testId="landing-page-play-store" width={135} height={41} />
            </div>
        </div>
    )
}

export default LandingPageHealthRoute
