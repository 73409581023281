import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getLamalAccidentWantedDate } from '../services/coverage/coverage.slice'

export const getSummaryLamalAccidentWantedDate = createSelector(
    [getFamilySummaries, getLamalAccidentWantedDate],
    (summaries, wantedDate) => summaries[wantedDate?.getFullYear() ?? getCurrentYear()]
)
