/* eslint-disable sonarjs/no-duplicate-string */
import { PropsWithChildren, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DentalInjuriesModel } from 'core/models/services/report/AccidentReportModel'
import { commonMandatoryErrorKey } from 'core/validators/constants'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { StepItem } from 'modules/services/components/Stepper/core/models'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'

import { isTrue } from '../../../../core/helpers/FieldValidationHelper'
import AccidentReportDropzone from '../components/AccidentReportDropzone'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'DENTAL_INJURIES'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const dentalInjuriesFormFieldNameList: Array<keyof DentalInjuriesModel> = [
    'foodType',
    'unusualEvent',
    'strange',
    'possession',
    'manufactured',
    'acquisition',
    'bill',
    'productBill',
    'announce',
    'announceDetails',
    'sellerInsurance'
]

export interface DentalInjuriesFormProps extends Partial<PropsWithChildren<JSX.Element>> {
    id: number
}

// Step 6 - DENTAL INJURIES
const DentalInjuriesForm = ({ id }: DentalInjuriesFormProps): JSX.Element => {
    const { stepWatch } = useStepperContext()
    const { getValues, setValue, trigger, resetField } = useFormContext<DentalInjuriesModel>()

    const { t } = useTranslation()

    const stepState = stepWatch(id) as StepItem

    const { TextareaInput, RadioGroup } = useCustomControls(formName)

    const { bill, manufactured } = getValues()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        if (bill !== undefined && bill === 'billNo') {
            setValue('productBill', undefined, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: stepState.hasBeenValidated
            })
            ///resetField('productBill', { keepDirty: true, keepError: false, keepTouched: true })
            trigger('productBill')
        }
        if (manufactured !== undefined && manufactured === 'manufacturedNo') {
            resetField('acquisition', { keepDirty: true, keepError: false, keepTouched: true })
            resetField('announce', { keepDirty: true, keepError: false, keepTouched: true })
            resetField('announceDetails', { keepDirty: true, keepError: false, keepTouched: true })
            resetField('sellerInsurance', { keepDirty: true, keepError: false, keepTouched: true })
            resetField('bill', { keepDirty: true, keepError: false, keepTouched: true })
            setValue('productBill', undefined, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: stepState.hasBeenValidated
            })
            ///resetField('productBill', { keepDirty: true, keepError: false, keepTouched: true })
            trigger('productBill')
        }
    }, [bill, manufactured])

    const manufacturedValidateYes = isTrue(getValues('manufactured'), 'manufactured')()
    const billValidateYes = isTrue(getValues('bill'), 'bill')()

    const billValidate = () => {
        const { bill } = getValues()
        return (manufacturedValidateYes && !!bill) || t(commonMandatoryErrorKey)
    }

    const announceValidate = () => {
        const { announce } = getValues()
        return (manufacturedValidateYes && !!announce) || t(commonMandatoryErrorKey)
    }

    return (
        <div id={`${id}`} className="step-frame">
            <FormBlock
                titleTestId={'dental-injuries-information'}
                title={t(`${serviceName}.${formName}.DENTAL_INJURIES_INFORMATION`)}
                childrenMargin="m-bottom-48"
            >
                {TextareaInput(
                    'foodType',
                    'FOODTYPE',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
                {TextareaInput(
                    'unusualEvent',
                    'UNUSUALEVENT',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
                {TextareaInput(
                    'strange',
                    'STRANGE',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
                {TextareaInput(
                    'possession',
                    'POSSESSION',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'product-source'}
                title={t(`${serviceName}.${formName}.PRODUCT_SOURCE`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom="m-bottom-32"
            >
                {RadioGroup(
                    'MANUFACTURED',
                    'manufactured',
                    {
                        mandatory: true
                    },
                    () => trigger('manufactured'),
                    true
                )}
                {manufacturedValidateYes && (
                    <>
                        <Row className={'m-bottom-48'}>
                            <Col>
                                {TextareaInput(
                                    'acquisition',
                                    'ACQUISITION',
                                    {
                                        mandatory: manufacturedValidateYes,
                                        maxLength: 400
                                    },
                                    false
                                )}
                            </Col>
                        </Row>
                        {RadioGroup(
                            'BILL',
                            'bill',
                            {
                                validate: billValidate
                            },
                            () => trigger('bill'),
                            true
                        )}
                        {billValidateYes ? (
                            <Row className={'m-top-48 m-bottom-48'}>
                                <Col>
                                    <div className="labelMedium m-bottom-24">
                                        {t(`${serviceName}.${formName}.ATTACHMENT`)}
                                    </div>
                                    <div className="m-bottom-24">
                                        <AccidentReportDropzone
                                            name="productBill"
                                            defaultValue={getValues('productBill')}
                                            mandatory={false}
                                            clear={() => {
                                                setValue('productBill', undefined, {
                                                    shouldDirty: true,
                                                    shouldTouch: true,
                                                    shouldValidate: stepState.hasBeenValidated
                                                })
                                                trigger('productBill')
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <div className="m-top-48"></div>
                        )}
                        {RadioGroup(
                            'ANNOUNCE',
                            'announce',
                            {
                                validate: announceValidate
                            },
                            () => trigger('announce'),
                            true
                        )}
                        <Row className={'m-top-48 m-bottom-48'}>
                            <Col>
                                {TextareaInput(
                                    'announceDetails',
                                    'ANNOUNCE_DETAILS',
                                    { mandatory: manufacturedValidateYes, maxLength: 400 },
                                    false
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {TextareaInput(
                                    'sellerInsurance',
                                    'SELLER_INSURANCE',
                                    { mandatory: manufacturedValidateYes, maxLength: 400 },
                                    false
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}

export default DentalInjuriesForm
