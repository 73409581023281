import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { GeneralQuestionRHFForm } from 'core/models/services/contact/GeneralQuestionForm'
import ContactBlock from 'modules/services/components/ContactBlock/ContactBlock'
import { useFormPageContext } from 'modules/services/components/FormPage/FormPage'

import GeneralQuestionDetails from '../GeneralQuestionDetails/GeneralQuestionDetails'

const GeneralQuestionMainBlock = (): JSX.Element | null => {
    const { watch, clearErrors } = useFormContext<GeneralQuestionRHFForm>()
    const [isTopicsCompleted, setIsTopicsCompleted] = useState(false)
    const { setDisplaySubmitButton } = useFormPageContext()

    const topic = watch('topic')

    useEffect(() => {
        const flag = !!topic
        setIsTopicsCompleted(flag)
        setDisplaySubmitButton(flag)
        if (flag) clearErrors()
    }, [topic])

    if (isTopicsCompleted) {
        return (
            <>
                <GeneralQuestionDetails />
                <ContactBlock />
            </>
        )
    } else {
        return null
    }
}

export default GeneralQuestionMainBlock
