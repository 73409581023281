import { useTranslation } from 'react-i18next'

interface TextFieldProps {
    label: string
    classNames?: string
    rowId: number | string
    fieldId: string
    translate?: boolean
}

const TextField = ({
    label,
    classNames,
    rowId,
    fieldId,
    translate
}: TextFieldProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <td data-testid={`assura-table-row-${rowId}-${fieldId}-field`} className={classNames}>
            {translate ? t(label) : label}
        </td>
    )
}

export default TextField
