import './RequestsTableRow.css'

import { useTranslation } from 'react-i18next'

import { RequestSource } from 'core/enums/RequestSource'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestRow } from 'core/models/services/RequestData'
import useWindowSize from 'core/services/useWindowSize'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import StatusIconField from 'shared/components/AssuraTable/Fields/StatusIconField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

import RequestsTableRowMobile from './RequestsTableRowMobile'

interface RequestsTableRowProps {
    row: RequestRow
}

const RequestsTableRow = ({ row }: RequestsTableRowProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    let type = t(row.typeTranslated)
    if (row.insuredPersonName) type += ` ${t('COMMON.FOR', { item: row.insuredPersonName })}`

    const isMigrationRow =
        row.source === RequestSource.MIGRATION &&
        process.env.REACT_APP_SHOW_MIGRATED_DATA?.toString() === 'FALSE'

    const goToExtraMigrationClass = isMigrationRow ? 'hidden' : undefined

    if (isMobile) {
        return <RequestsTableRowMobile row={row} />
    } else {
        return (
            <>
                <StatusIconField statusColor={row.statusColor} rowId={row.index} />
                <DateField
                    date={row.creationDate ?? ''}
                    rowId={row.index}
                    classNames="label requests-table-row-date"
                />
                <TextField
                    label={type}
                    classNames="requests-table-row-type label"
                    rowId={row.index}
                    fieldId="type"
                />
                <StatusField
                    status={row.statusTranslated}
                    statusIcon={row.statusIcon}
                    statusColor={row.statusColor}
                    rowId={row.index}
                    statusTranslatedOptions={
                        row.processingDate && row.statusTranslated === 'STATUS.COMPLETED_DATE'
                            ? { date: formatDate(row.processingDate) }
                            : undefined
                    }
                    classNames="requests-table-row-status"
                    labelClassNames="labelSmall"
                />
                <GoToField classNames={goToExtraMigrationClass} rowId={row.index} marginLeft={40} />
            </>
        )
    }
}

export default RequestsTableRow
