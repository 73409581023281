import { EnveloppeStatus, TranslatedEnveloppeStatus } from 'core/enums/EnveloppeStatus'

export const mapStatusColor = (status: EnveloppeStatus): string => {
    switch (status) {
        case EnveloppeStatus.VisibilityError:
        case EnveloppeStatus.TechnicalError:
            return 'c-error'
        case EnveloppeStatus.Received:
        case EnveloppeStatus.Validated:
        case EnveloppeStatus.PartiallyProcessed:
            return 'c-black'
        case EnveloppeStatus.Processed:
            return 'c-success'
        default:
            console.log(`Enveloppe status not supported ${status}`)
            return ''
    }
}

export const mapStatusTranslation = (status: EnveloppeStatus): string => {
    switch (status) {
        case EnveloppeStatus.VisibilityError:
        case EnveloppeStatus.TechnicalError:
            return TranslatedEnveloppeStatus.Rescan
        case EnveloppeStatus.Received:
        case EnveloppeStatus.Validated:
            return TranslatedEnveloppeStatus.Received
        case EnveloppeStatus.PartiallyProcessed:
            return TranslatedEnveloppeStatus.PartiallyProcessed
        case EnveloppeStatus.Processed:
            return TranslatedEnveloppeStatus.Processed
        default:
            console.log(`Enveloppe status not supported ${status}`)
            return ''
    }
}

export const mapStatusIcon = (status: EnveloppeStatus): string => {
    switch (status) {
        case EnveloppeStatus.VisibilityError:
        case EnveloppeStatus.TechnicalError:
            return 'assura-close'
        case EnveloppeStatus.Received:
        case EnveloppeStatus.Validated:
        case EnveloppeStatus.Processed:
            return 'assura-double-check'
        case EnveloppeStatus.PartiallyProcessed:
            return 'assura-partially-treated'
        default:
            console.log(`Enveloppe status not supported ${status}`)
            return ''
    }
}
