import { isEmpty } from 'core/helpers/ArrayHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { FaqCategoryCmsLocalized } from 'core/models/cms/FaqCategoryCmsLocalized'
import { AnswerBulletPointValue } from 'core/models/cms/FaqCms'
import { FaqCmsLocalized } from 'core/models/cms/FaqCmsLocalized'
import { Faq, FaqCategory, FaqGlobalItem, FaqQuestions } from 'core/models/Faq'
import { SearchBarResult, SearchBarSection } from 'shared/components/SearchBar/SearchBar'

import { createSelector } from '@reduxjs/toolkit'

import { getFaqCategoryCms, getFaqCms } from '../faq/faq.slice'
import { getLang } from '../general/general.slice'

export const getFaqCategories = createSelector(
    [getFaqCategoryCms, getLang],
    (faqCategoryCms, lang): FaqCategory[] => {
        return faqCategoryCms
            .filter((item) => item.is_active)
            .map((cat) => {
                const localizedCat: FaqCategoryCmsLocalized = getLocalizedObject(cat, lang)
                return {
                    _id: localizedCat._id,
                    id: localizedCat.id,
                    order: parseInt(localizedCat.order),
                    title: localizedCat.title
                }
            })
            .sort((a, b) => a.order - b.order)
    }
)

export const getDefaultFaqCategoryId = createSelector(
    [getFaqCategories],
    (faqCategories): string | null => {
        return faqCategories[0]?.id || null
    }
)

export const getFaqQuestions = createSelector(
    [getFaqCms, getFaqCategories, getLang],
    (faqCms, faqCategories, lang): FaqQuestions => {
        const faq = new Map<string, Faq[]>()

        faqCategories.forEach((cat) => {
            const faqList: Faq[] = faqCms
                .filter((faqCms) => faqCms.faq_category[0]._id === cat._id && faqCms.is_active)
                .map((item) => {
                    const localizedItem: FaqCmsLocalized = getLocalizedObject(item, lang)

                    const answer: AnswerBulletPointValue[] =
                        localizedItem.answer?.map((a) => a.value) || []
                    const answer_website: AnswerBulletPointValue[] =
                        localizedItem.answer_website?.map((a) => a.value) || []

                    return {
                        id: localizedItem.id,
                        order: parseInt(localizedItem.order),
                        question: localizedItem.question,
                        faqCategory: {
                            _id: cat._id,
                            id: cat.id,
                            title: cat.title
                        },
                        answer: answer.map((answer) => ({
                            ...answer,
                            images: answer.images
                                ? answer.images.map((imgValue) => imgValue.value)
                                : []
                        })),
                        answer_website: answer_website.map((answer_website) => ({
                            ...answer_website,
                            images: answer_website.images
                                ? answer_website.images.map((imgValue) => imgValue.value)
                                : []
                        }))
                    }
                })
                .sort((a, b) => a.order - b.order)

            faq.set(cat.id, faqList)
        })

        return faq
    }
)

export const getFaqDataForSearch = createSelector(
    [getFaqCategories, getFaqQuestions],
    (faqCategories, faqQuestions): SearchBarSection<FaqGlobalItem>[] => {
        const faqSearchSections = [] as SearchBarSection<FaqGlobalItem>[]

        if (!isEmpty(faqCategories)) {
            const categoriesResults: SearchBarResult<FaqGlobalItem>[] = faqCategories.map(
                (cat) => ({
                    label: cat.title,
                    value: {
                        categoryId: cat.id,
                        questionId: null
                    }
                })
            )

            faqSearchSections.push({
                sectionTitleKey: 'FAQ.SEARCH_BAR_SECTION_CATEGORIES',
                results: [...categoriesResults]
            })
        }

        if (faqQuestions.size > 0) {
            const questionsResults: SearchBarResult<FaqGlobalItem>[] = []

            for (const faqQuestion of faqQuestions.values()) {
                faqQuestion.forEach((faq) =>
                    questionsResults.push({
                        label: faq.question.replace(/\*/g, ''),
                        value: {
                            categoryId: faq.faqCategory.id,
                            questionId: faq.id
                        }
                    })
                )
            }

            faqSearchSections.push({
                sectionTitleKey: 'FAQ.SEARCH_BAR_SECTION_QUESTIONS',
                results: [...questionsResults]
            })
        }

        return faqSearchSections
    }
)
