/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { AventuraForm, AventuraPriceDetailsModel } from 'core/models/services/coverage/AventuraForm'

export const getAventuraPricingAsyncApi = async (): Promise<
    AventuraPriceDetailsModel[] | undefined
> => {
    const response = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Coverage/aventura/pricing`
    )
    return response.data
}

export const postAventuraRequestAsyncApi = async (data: AventuraForm): Promise<string> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Coverage/aventura`, data)
