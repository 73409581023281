import './FaqCategoriesListResponsive.css'

import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FaqCategory } from 'core/models/Faq'
import { useFaqContext } from 'modules/faq/pages/Faq'
import AssuraSelect, { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { getFaqLoadingStatus } from 'shared/store/faq/faq.slice'
import { getFaqCategories } from 'shared/store/selectors/getFaqData'

const FaqCategoriesListResponsive = (): JSX.Element => {
    const { selectedCategoryId, setSelectedCategoryId } = useFaqContext()
    const faqDataCategories = useSelector(getFaqCategories)
    const faqLoadingStatus = useSelector(getFaqLoadingStatus)

    const faqDataCategoriesMap = new Map() as SelectMap<FaqCategory['id']>
    for (const cat of faqDataCategories) {
        faqDataCategoriesMap.set(cat.title, cat.id)
    }

    const handleSelection = (id: FaqCategory['id'] | null) => {
        setSelectedCategoryId(id)
    }

    return (
        <div
            style={{
                visibility: faqLoadingStatus === LoadingStatusEnum.OK ? 'visible' : 'hidden'
            }}
        >
            <AssuraSelect
                id="faq-categories-responsive"
                value={selectedCategoryId ?? ''}
                items={faqDataCategoriesMap}
                onChange={(item) => handleSelection(item)}
                containerClassnames="faq-categories-responsive-select bg-white"
                toggleClassnames="faq-categories-responsive-select-toggle"
                menuClassnames="faq-categories-responsive-select-menu"
            />
        </div>
    )
}

export default FaqCategoriesListResponsive
