/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { InputGroup } from 'react-bootstrap'

interface RightIconProps {
    isFocus: boolean
    rightIcon: string
}

export const withRightIcon = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithRightIcon extends React.Component<P & RightIconProps> {
        render() {
            const { isFocus, rightIcon } = this.props
            return (
                <InputGroup>
                    <WrappedComponent {...(this.props as P)} />
                    <InputGroup.Append className="assura-input-group-append-to-right">
                        <i
                            className={`icon ${rightIcon} size-24 c-${
                                isFocus ? 'black' : 'gray300'
                            }`}
                            data-testid="right-icon"
                        />
                    </InputGroup.Append>
                </InputGroup>
            )
        }
    }
