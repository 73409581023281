import './LastRequests.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { RequestRow } from 'core/models/services/RequestData'
import concatClassNames from 'core/utils/classNameUtils'
import RequestsTableRowMobile from 'modules/requests/components/RequestsTable/RequestsTableRowMobile'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraTableLoadAndError from 'shared/components/AssuraTable/AssuraTableLoadAndError/AssuraTableLoadAndError'
import { getRequests } from 'shared/store/services/requests/requests.slice'
import { useAppDispatch } from 'shared/store/store'

interface LastRequestsProps {
    requests: RequestRow[]
    loadingStatus: LoadingStatusEnum
}

const LastRequests = ({ requests, loadingStatus }: LastRequestsProps): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleShowAll = () => {
        navigate(navigationConstants.REQUESTS.url)
    }

    const reloadLastRequests = () => {
        dispatch(getRequests())
    }

    const handleRowClasses = (row: RequestRow): string => {
        let extraClasses = ''
        if (row.shouldHideData) extraClasses = 'requests-table-row-migration'

        return concatClassNames(['services-hub-last-requests-row', extraClasses])
    }

    const handleOnSelection = (row: RequestRow) => {
        if (!row.shouldHideData) navigate(`${navigationConstants.REQUESTS.url}?request=${row.id}`)
    }

    return (
        <div className="services-hub-last-requests-container">
            <div className="d-flex justify-content-between align-items-center p-bottom-24">
                <div className="text-left headlineSmall">{t('SERVICES.HUB_LAST_REQUESTS')}</div>
                <AssuraIconButton
                    id="services-hub-last-requests-show-all"
                    icon="assura-next"
                    variant="primary"
                    size="medium"
                    onClick={handleShowAll}
                    label={t('COMMON.SEE_ALL')}
                />
            </div>
            <table className="services-hub-last-requests-table">
                <AssuraTableLoadAndError
                    status={loadingStatus}
                    defaultReloadAction={reloadLastRequests}
                >
                    <tbody className="services-hub-last-requests-body">
                        {requests.map((request) => (
                            <tr
                                data-testid={`services-hub-last-requests-item-${request.index}`}
                                className={handleRowClasses(request)}
                                onClick={() => handleOnSelection(request)}
                                key={request.id}
                            >
                                <RequestsTableRowMobile
                                    row={request}
                                    mainTextColorClass="c-gray500"
                                    typeTextClasses="labelMedium c-black"
                                />
                            </tr>
                        ))}
                    </tbody>
                </AssuraTableLoadAndError>
            </table>
        </div>
    )
}

export default LastRequests
