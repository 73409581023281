import './ProductCardHeader.css'

interface ProductCardHeaderProps {
    title: string
    subtitle?: string
    icon?: string
    productId: string
}

const ProductCardHeader = ({
    title,
    subtitle,
    icon,
    productId
}: ProductCardHeaderProps): JSX.Element => {
    return (
        <div className="product-card-header-container">
            <div className="product-card-header-title-container">
                <span
                    className="labelExtraSmall product-card-header-product-title c-gray500"
                    data-testid={`product-card-header-title-${productId}`}
                >
                    {title}
                </span>
                <span
                    className="titleSmall break-word"
                >
                    {subtitle}
                </span>
            </div>

            {icon && (
                <img
                    className="product-card-header-icon"
                    src={`${process.env.REACT_APP_CMS_ASSET}${icon}`}
                    alt={'icon'}
                    width="48"
                    height="48"
                />
            )}
        </div>
    )
}

export default ProductCardHeader
