import './Photo.css'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'

import PhotoItem from './PhotoItem'

interface PhotoProps {
    initials: string
    dateOfBirth: string
    gender: string | null
    policyNumber: string
}

const Photo = ({ initials, dateOfBirth, gender, policyNumber }: PhotoProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const avatarSize = isXL ? 160 : 104

    return (
        <>
            <div className="personal-data-photo-container bg-gray50">
                <AssuraAvatar
                    id="personal-data-photo-avatar"
                    mainContainerWidth={avatarSize}
                    avatarContainerWidth={avatarSize}
                    border={{
                        width: 4,
                        color: 'white'
                    }}
                >
                    <div className={isXL ? 'headlineLargeMedium' : 'headlineMedium'}>
                        {initials}
                    </div>
                </AssuraAvatar>
                <div className="personal-data-photo-items-container">
                    {dateOfBirth && (
                        <PhotoItem
                            id="birth"
                            label={t('PERSONAL_DATA.DATE_OF_BIRTH')}
                            value={formatDate(dateOfBirth)}
                        />
                    )}
                    {gender && (
                        <PhotoItem
                            id="gender"
                            label={t('PERSONAL_DATA.GENDER')}
                            value={t(gender)}
                        />
                    )}
                    <PhotoItem
                        id="policy"
                        label={t('PERSONAL_DATA.POLICY_NUMBER')}
                        value={policyNumber}
                    />
                </div>
            </div>
        </>
    )
}

export default Photo
