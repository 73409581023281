import './HospitalInfos.css'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesHospAnnouncementDivision } from 'core/enums/ServicesHospAnnouncementDivision'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { HospitalizationAnnouncementRHFForm } from 'core/models/services/report/HospitalizationAnnouncementForm'
import useWindowSize from 'core/services/useWindowSize'
import { getCurrentYear, getNextYear } from 'core/utils/dateUtils'
import isAfter from 'date-fns/isAfter'
import { useFormPageContext } from 'modules/services/components/FormPage/FormPage'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getHasOptimaFlexVariaLca } from 'shared/store/selectors/services/getHasOptimaFlexVariaLca'
import { setHospStartDate } from 'shared/store/services/report/report.slice'
import {
    fetchSummaryAndProducts,
    getSelectedMemberPolicyNumber
} from 'shared/store/services/services.slice'

import PickerDay from '../../components/DatePicker/PickerDay/PickerDay'
import FormBlockWithLabel from '../../components/FormBlockWithLabel/FormBlockWithLabel'
import { AssuraFormInputRHF } from '../../components/InputRHF/InputRHF'
import RadioRHF from '../../components/RadioRHF/RadioRHF'

const mandatoryField = 'COMMON.MANDATORY_FIELD'

const HospitalInfos = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const dispatch = useDispatch()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const hasOptimaFlexVaria = useSelector(getHasOptimaFlexVariaLca)

    const { addRhfExtraRef } = useFormPageContext()

    const {
        control,
        getValues,
        setValue,
        register,
        watch,
        formState: { errors, isSubmitted }
    } = useFormContext<HospitalizationAnnouncementRHFForm>()

    const hospStartDateId = 'hosp-start-date'
    const hospStartDateName = 'hospStartDate'

    const [isFocused, setIsFocused] = useState(false)

    const hospStartDateWatched = watch(hospStartDateName)

    useEffect(() => {
        if (selectedPolicyNumber && hospStartDateWatched) {
            dispatch(setHospStartDate(hospStartDateWatched.toISOString()))
            dispatch(
                fetchSummaryAndProducts({
                    policyNumber: selectedPolicyNumber,
                    year: hospStartDateWatched.getFullYear() ?? getCurrentYear()
                })
            )
        }
    }, [hospStartDateWatched])

    const handleResetAction = () => {
        setValue('hospitalName', '', {
            shouldValidate: isSubmitted
        })
    }

    const getHospitalNameRules = (): Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    > => {
        const min = 1
        const max = 1000
        const hospitalNameErrorMessage = t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
            min,
            max
        })
        return {
            required: { value: true, message: t(mandatoryField) },
            minLength: { value: min, message: hospitalNameErrorMessage },
            maxLength: { value: max, message: hospitalNameErrorMessage }
        }
    }

    const validateDivision = () => {
        const { division } = getValues()
        return !!division || mandatoryField
    }

    const validateHospStartDate = () => {
        const { symptomStartDate, hospStartDate } = getValues()
        if (symptomStartDate && hospStartDate) {
            return isAfter(symptomStartDate, hospStartDate)
                ? 'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_START_DATE_ERROR'
                : true
        }
    }

    useEffect(() => {
        addRhfExtraRef({
            name: hospStartDateName,
            id: hospStartDateId
        })
    }, [])

    return (
        <div className="d-flex flex-column bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <FormBlockWithLabel
                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_START_DATE')}
                isFirstChildInput
            >
                <PickerDay
                    placeholder={t('CALENDAR.INPUT_PLACEHOLDER')}
                    testId={hospStartDateId}
                    control={control}
                    name={hospStartDateName}
                    maxDate={new Date(getNextYear(), 11, 31)}
                    rules={{
                        required: { value: true, message: t(mandatoryField) },
                        validate: validateHospStartDate
                    }}
                    refId={hospStartDateId}
                />
            </FormBlockWithLabel>
            <AssuraLoadAndError
                status={
                    hospStartDateWatched
                        ? getLoadingStatusFromLoaderList([
                              summaryLoadingStatus,
                              productsLoaderStatus
                          ])
                        : LoadingStatusEnum.OK
                }
                shouldDisplayContainer
                defaultReloadAction={() =>
                    dispatch(
                        fetchSummaryAndProducts({
                            policyNumber: selectedPolicyNumber as number,
                            year: hospStartDateWatched?.getFullYear() ?? getCurrentYear(),
                            reload: true
                        })
                    )
                }
            >
                <>
                    {hospStartDateWatched && (
                        <>
                            <div className="m-top-32">
                                <AssuraFormInputRHF
                                    id="services-hospitalisation-announcement-hospital-name"
                                    name="hospitalName"
                                    label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_NAME')}
                                    placeHolder={t(
                                        'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_NAME_PLACEHOLDER'
                                    )}
                                    inline={isXL}
                                    labelSize={4}
                                    isInvalid={Boolean(errors?.hospitalName)}
                                    error={t(errors?.hospitalName?.message as string)}
                                    hasButton={isFocused}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    onButtonClick={() => handleResetAction()}
                                    iconClass="icon assura-close size-24"
                                    register={register}
                                    rules={getHospitalNameRules()}
                                />
                            </div>
                            <div className="m-top-32">
                                <FormBlockWithLabel
                                    label={t(
                                        'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_LABEL'
                                    )}
                                >
                                    <>
                                        <div className="flex-1 d-flex hospital-infos-division-container">
                                            <RadioRHF
                                                id={ServicesHospAnnouncementDivision.GENERAL}
                                                name="division"
                                                label={t(
                                                    'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_GENERAL'
                                                )}
                                                register={register}
                                                classNames="flex-1 hospital-infos-division"
                                                rules={{
                                                    validate: validateDivision
                                                }}
                                            />
                                            <RadioRHF
                                                id={ServicesHospAnnouncementDivision.SEMI_PRIVATE}
                                                name="division"
                                                label={t(
                                                    'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_SEMI_PRIVATE'
                                                )}
                                                register={register}
                                                classNames="flex-1 hospital-infos-division"
                                                rules={{
                                                    validate: validateDivision
                                                }}
                                            />
                                            <RadioRHF
                                                id={ServicesHospAnnouncementDivision.PRIVATE}
                                                name="division"
                                                label={t(
                                                    'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_PRIVATE'
                                                )}
                                                register={register}
                                                classNames="flex-1 hospital-infos-division"
                                                rules={{
                                                    validate: validateDivision
                                                }}
                                            />
                                        </div>
                                        {errors?.division?.message && (
                                            <div className="m-top-8 labelExtraSmall c-primary">
                                                {t(errors.division.message)}
                                            </div>
                                        )}
                                    </>
                                </FormBlockWithLabel>
                                {hasOptimaFlexVaria && (
                                    <div className="m-top-48 p-32 bg-gray20 bc-gray100 border-solid-width-1">
                                        <AssuraMarkdown
                                            message={t(
                                                'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_OPTIMA_VARIA'
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            </AssuraLoadAndError>
        </div>
    )
}

export default HospitalInfos
