import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { ecLinksCmsdata } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { EcLinkCms } from 'core/models/cms/EcLinkCms'

import { fetchEcLinks, setEcLinks, setEcLinksLoadingStatus } from './ecLinks.slice'

export function* fetchEcLinksSaga() {
    try {
        yield put(setEcLinksLoadingStatus(LoadingStatusEnum.LOADING))
        const ecLinksCms: EcLinkCms[] = yield call(ecLinksCmsdata)
        yield put(setEcLinks(ecLinksCms))
        yield put(setEcLinksLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setEcLinksLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchEcLinksSaga Error', e)
    }
}

function* fetchEcLinksWatcher() {
    yield takeEvery(fetchEcLinks.type, fetchEcLinksSaga)
}

const watchers = [fork(fetchEcLinksWatcher)]

export default watchers
