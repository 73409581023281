/* eslint-disable sonarjs/cognitive-complexity */
import navigationConstants from 'core/constants/navigationConstants'
import { HomeNotificationsColors } from 'core/enums/HomeNotificationsColors'
import { MissingDataType } from 'core/enums/MissingData'
import { RequestStatus } from 'core/enums/RequestStatus'
import {
    HomeNotificationsCardData,
    HomeNotificationsCriticality
} from 'core/models/HomeNotifications'
import { RequestData } from 'core/models/services/RequestData'
import differenceInDays from 'date-fns/differenceInDays'
import { PersonSettings } from 'shared/store/selectors/getFamilyGroupSettings'

const mapMissingsAndRequestsToHomeNotification = (
    missingData: Record<number, MissingDataType[]>,
    requests: RequestData[],
    familyGroup: PersonSettings[]
): HomeNotificationsCardData => {
    let criticality: HomeNotificationsCriticality = 0
    let color = HomeNotificationsColors.GRAY
    let count = 0
    let title = 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE'
    let link = `${navigationConstants.REQUESTS.url}`

    if (Object.keys(missingData).length > 0) {
        criticality = 3
        color = HomeNotificationsColors.RED

        let list = {
            email: false,
            phone: false,
            iban: false,
            maritalStatus: false,
            address: false
        }

        let memberIndex: number | undefined

        list = Object.entries(missingData).reduce<typeof list>(
            (acc, [policyNumber, missingDataTypes]) => {
                missingDataTypes.forEach((missingData) => {
                    const index = familyGroup.findIndex(
                        (member) => member.policyNumber === +policyNumber
                    )

                    if (index > -1 && (memberIndex === undefined || index < memberIndex))
                        memberIndex = index

                    switch (missingData) {
                        case MissingDataType.EMAIL_MISSING:
                            acc.email = true
                            break
                        case MissingDataType.PHONE_NUMBER_MISSING:
                            acc.phone = true
                            break
                        case MissingDataType.IBAN_MISSING:
                            acc.iban = true
                            break
                        case MissingDataType.MARITAL_STATUS_MISSING:
                            acc.maritalStatus = true
                            break
                        case MissingDataType.ADDRESS_MISSING:
                            acc.address = true
                            break
                        default:
                            break
                    }
                })
                return acc
            },
            list
        )

        const ibanAndSomePersonalDataIsMissing =
            list.iban && (list.phone || list.email || list.maritalStatus || list.address)

        const phoneAndSomePersonalDataIsMissing =
            list.phone && (list.email || list.maritalStatus || list.address)

        const emailAndSomePersonalDataIsMissing =
            list.email && (list.phone || list.maritalStatus || list.address)

        const maritalStatusAndSomePersonalDataIsMissing =
            list.maritalStatus && (list.phone || list.email || list.address)

        const addressAndSomePersonalDataIsMissing =
            list.address && (list.phone || list.email || list.maritalStatus)

        title =
            // Group
            ibanAndSomePersonalDataIsMissing
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_ALL'
                : phoneAndSomePersonalDataIsMissing ||
                  emailAndSomePersonalDataIsMissing ||
                  maritalStatusAndSomePersonalDataIsMissing ||
                  addressAndSomePersonalDataIsMissing
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_PERSONAL_DATA'
                : // Individual
                list.iban
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_FINANCIAL'
                : list.phone
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_PHONE'
                : list.email
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_EMAIL'
                : list.maritalStatus
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_MARITAL_STATUS'
                : list.address
                ? 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_3_ADDRESS'
                : '' // improbable

        link = `${navigationConstants.PERSONAL_DATA.url}?index=${memberIndex ?? 0}`
    } else if (
        requests.length > 0 &&
        requests.some(
            (request) =>
                request.status === RequestStatus.COMPLETED &&
                differenceInDays(new Date(), new Date(request.processingDate as string)) <= 30
        )
    ) {
        criticality = 2
        color = HomeNotificationsColors.BLACK
        title = 'HOME.NOTIFICATIONS_MISSING_REQUESTS_TITLE_2'

        count = requests.reduce<number>((acc, request) => {
            if (
                request.status === RequestStatus.COMPLETED &&
                differenceInDays(new Date(), new Date(request.processingDate as string)) <= 30
            )
                acc++
            return acc
        }, 0)
    } else if (requests.some((request) => request.status === RequestStatus.PENDING)) {
        criticality = 1
        color = HomeNotificationsColors.BLACK
        count = requests.filter((request) => request.status === RequestStatus.PENDING).length
    }

    return {
        testId: 'missings-requests',
        criticality,
        color,
        count,
        title,
        link
    }
}

export default mapMissingsAndRequestsToHomeNotification
