import { useTranslation } from 'react-i18next'

import coverageIcon from 'shared/assets/images/coverage.png'

const NoLamalWithDoctor = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center"
            data-testid="no-lamal-with-doctor-container"
        >
            <img src={coverageIcon} className="image-size-56" alt="no-active-contract-icon" />
            <div className="headlineSmall m-top-24 m-bottom-32">
                {t('SERVICES.DELEGATION_LAMAL_WITHOUT_DOCTOR_MESSAGE_1')}
            </div>
            <div className="headlineSmall">
                {t('SERVICES.DELEGATION_LAMAL_WITHOUT_DOCTOR_MESSAGE_2')}
            </div>
        </div>
    )
}

export default NoLamalWithDoctor
