import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { fetchFinancialApi, postPaymentChangeApi } from 'core/api/services/Financial'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { Financial, FinancialFetchResponse } from 'core/models/services/personalData/Financial'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getUserUtn } from 'shared/store/general/general.slice'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import {
    fetchFinancial,
    getFinancial,
    initFinancial,
    onSubmitPaymentChange,
    setFinancial,
    setLoadingStatus
} from './financial.slice'

export function* initFinancialSaga() {
    try {
        const connectedFamilyMember: ConnectedFamilyMember = yield select(getConnectedFamilyMember)

        yield put(fetchFinancial({ selectedPolicyNumber: connectedFamilyMember?.policyNumber }))
    } catch (e) {
        console.error('initFinancialSaga Error', e)
    }
}

export function* fetchFinancialSaga(action: ReturnType<typeof fetchFinancial>) {
    const { selectedPolicyNumber, reload } = action.payload

    try {
        const financialList: Record<number, Financial> = yield select(getFinancial)

        if (
            !reload &&
            financialList[selectedPolicyNumber] &&
            financialList[selectedPolicyNumber].loadingStatus === LoadingStatusEnum.OK
        ) {
            return
        }

        yield put(
            setLoadingStatus({
                value: LoadingStatusEnum.LOADING,
                selectedPolicyNumber
            })
        )

        const financialData: FinancialFetchResponse = yield call(
            fetchFinancialApi,
            selectedPolicyNumber
        )

        yield put(
            setFinancial({
                newDatas: financialData,
                selectedPolicyNumber,
                wsStatus: HttpResponseEnum.SUCCESS
            })
        )

        yield put(
            setLoadingStatus({
                value: LoadingStatusEnum.OK,
                selectedPolicyNumber
            })
        )
    } catch (e) {
        console.error('fetchFinancialSaga Error', e)
        if (
            axios.isAxiosError(e) &&
            (e.response?.status === HttpResponseEnum.NOT_FOUND ||
                e.response?.status === HttpResponseEnum.CONFLICT)
        ) {
            //We set empty values for financialData
            yield put(
                setFinancial({
                    selectedPolicyNumber,
                    wsStatus: e.response.status
                })
            )
            yield put(
                setLoadingStatus({
                    value: LoadingStatusEnum.OK,
                    selectedPolicyNumber
                })
            )
        } else {
            yield put(
                setLoadingStatus({
                    value: LoadingStatusEnum.ERROR,
                    selectedPolicyNumber
                })
            )
        }
    }
}

function* onSubmitPaymentChangeSaga(action: ReturnType<typeof onSubmitPaymentChange>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.PAYMENT_CHANGE
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const datasFromForm = action.payload
        const utn: number = yield select(getUserUtn)

        yield call(postPaymentChangeApi, {
            ...datasFromForm,
            utn: utn,
            affectsAllFamilyMembers: true
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())

        yield put(
            formSubmitted({
                redirectUrl:
                    Source() !== ServicesSource.WEBSITE
                        ? navigationConstants.PERSONAL_DATA.url
                        : undefined,
                ...analyticsParams
            })
        )
    } catch (e) {
        console.error('onSubmitPaymentChangeSaga Error', e)
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.BAD_REQUEST) {
            yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
            yield put(
                setBanner({
                    dataTestId: 'payment-change-iban-error',
                    message: 'PERSONAL_DATA.FINANCIAL_IBAN_ERROR'
                })
            )
        } else {
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        }
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* initFinancialWatcher() {
    yield takeEvery(initFinancial.type, initFinancialSaga)
}

function* fetchFinancialWatcher() {
    yield takeEvery(fetchFinancial.type, fetchFinancialSaga)
}

function* onSubmitPaymentChangeWatcher() {
    yield takeEvery(onSubmitPaymentChange.type, onSubmitPaymentChangeSaga)
}

const watchers = [
    fork(initFinancialWatcher),
    fork(fetchFinancialWatcher),
    fork(onSubmitPaymentChangeWatcher)
]

export default watchers
