import './FormPage.css'

import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServiceType } from 'core/enums/ServiceType'
import { emptyFunction } from 'core/helpers/functionHelper'
import { ExtraRHFRef } from 'core/models/services/RHF'
import { TranslationKey, TranslationKeyWithVariable } from 'core/models/Translations'
import { useFormRefresh } from 'core/services/useFormRefresh'
import useWindowSize from 'core/services/useWindowSize'
import FormTitle from 'modules/services/components/FormTitle/FormTitle'
import AssuraLoadAndError, {
    LoadingStatusProps
} from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getFormDataLoadingStatus, goToServicesForm } from 'shared/store/services/services.slice'

import UserInfo from '../UserInfo/UserInfo'
import FormPageFamilyGroup, { FamilyGroupProps } from './FormPageFamilyGroup'
import FormPageSubmitButton from './FormPageSubmitButton'

export interface FormPageProps<T> {
    _defaultValues: T
    serviceType: ServiceType
    formTitle: {
        title: string
        category?: string
    }
    onSuccess: (values: T) => void
    shouldDisplayFormContent?: boolean
    shouldDisplaySubmitButton?: boolean
    children: React.ReactNode
    familyGroupProps?: FamilyGroupProps
    userInfos?: boolean
    goodToKnow?: JSX.Element
    isPending?: JSX.Element
    notAllowed?: JSX.Element
    dataLoadingStatus?: LoadingStatusProps
    submitButtonText?: string
    shouldCheckNoChange?: boolean // if true, the form will not be submitted if there are no changes
    noChangeMessage?: TranslationKey | TranslationKeyWithVariable // Custom message for the no change error
}

interface FormPageContext {
    setDisplaySubmitButton: Dispatch<SetStateAction<boolean>>
    rhfExtraRefs: ExtraRHFRef[]
    addRhfExtraRef: (ref: ExtraRHFRef) => void
}

const FormPageContext = createContext<FormPageContext>({
    setDisplaySubmitButton: emptyFunction,
    rhfExtraRefs: [],
    addRhfExtraRef: emptyFunction
})

const FormPage = <T extends FieldValues>({
    _defaultValues,
    serviceType,
    formTitle,
    userInfos,
    children,
    shouldDisplayFormContent = true,
    shouldDisplaySubmitButton = true,
    familyGroupProps,
    goodToKnow,
    isPending,
    notAllowed,
    dataLoadingStatus,
    ...formSubmitButtonProps
}: FormPageProps<T>): JSX.Element => {
    useFormRefresh(serviceType)

    const { isMobile } = useWindowSize()
    const dispatch = useDispatch()

    const formDataLoadingStatus = useSelector(getFormDataLoadingStatus)
    const defaultValues = _defaultValues as DefaultValues<T>

    const [displaySubmitButton, setDisplaySubmitButton] = useState(true)
    const [rhfExtraRefs, setRhfExtraRefs] = useState<ExtraRHFRef[]>([])

    const addRhfExtraRef = (ref: ExtraRHFRef) => {
        const newList = [...rhfExtraRefs, ref]
        setRhfExtraRefs(newList)
    }

    const methods = useForm<T>({
        defaultValues,
        shouldFocusError: false
    })

    const value: FormPageContext = {
        setDisplaySubmitButton,
        rhfExtraRefs,
        addRhfExtraRef
    }

    useEffect(() => {
        methods.reset(defaultValues)
    }, [defaultValues])

    return (
        <FullScreenContainer
            color="gray20"
            complementaryClasses="container-load-error position-relative"
        >
            <AssuraLoadAndError
                status={formDataLoadingStatus}
                defaultReloadAction={() => dispatch(goToServicesForm({ type: serviceType }))}
            >
                <Container>
                    <FormProvider {...methods}>
                        <Row className="p-bottom-80">
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                                {(!goodToKnow || (goodToKnow && !isMobile)) && (
                                    <FormTitle
                                        title={formTitle.title}
                                        category={formTitle.category}
                                    />
                                )}
                                {userInfos && <UserInfo />}
                                {familyGroupProps && (
                                    <FormPageFamilyGroup
                                        {...familyGroupProps}
                                        serviceType={serviceType}
                                    />
                                )}
                                {shouldDisplayFormContent && (
                                    <AssuraLoadAndError
                                        status={dataLoadingStatus?.status ?? LoadingStatusEnum.OK}
                                        shouldDisplayContainer
                                        defaultReloadAction={dataLoadingStatus?.defaultReloadAction}
                                    >
                                        <>
                                            {notAllowed ? (
                                                notAllowed
                                            ) : isPending ? (
                                                isPending
                                            ) : (
                                                <FormPageContext.Provider value={value}>
                                                    {children}
                                                    {displaySubmitButton &&
                                                        shouldDisplaySubmitButton && (
                                                            <div className="m-top-32">
                                                                <FormPageSubmitButton
                                                                    {...formSubmitButtonProps}
                                                                />
                                                            </div>
                                                        )}
                                                </FormPageContext.Provider>
                                            )}
                                        </>
                                    </AssuraLoadAndError>
                                )}
                            </Col>
                            {goodToKnow && (
                                <Col
                                    xs={{ span: 12, order: 1 }}
                                    md={{ span: 4, order: 2 }}
                                    xl={{ span: 4, offset: 1 }}
                                >
                                    {isMobile && (
                                        <FormTitle
                                            title={formTitle.title}
                                            category={formTitle.category}
                                        />
                                    )}
                                    {goodToKnow}
                                </Col>
                            )}
                        </Row>
                    </FormProvider>
                </Container>
            </AssuraLoadAndError>
        </FullScreenContainer>
    )
}

export default FormPage

export const useFormPageContext = (): FormPageContext => {
    return useContext(FormPageContext)
}
