import { createSelector } from '@reduxjs/toolkit'

import { getContactInfos } from '../services/contactInfos/contactInfos.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export interface MemberFirstNameAndEmail {
    firstName: string
    email: string | null
}

export const getConnectedFamilyMemberAndEmail = createSelector([getConnectedFamilyMember, getContactInfos], (connectedPerson, contacts):
    | MemberFirstNameAndEmail
    | undefined => {
    if (connectedPerson) {
        return {
            firstName: connectedPerson.firstName,
            email: contacts[connectedPerson.policyNumber]?.datas?.email || null
        }
    }
    return undefined
})
