import { useNavigate } from 'react-router-dom'

import { DocumentsGroupEnum, PaymentTypeEnum } from 'core/enums/Documents'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'
import PaymentTypeIcon from 'modules/financials/components/PaymentTypeIcon/PaymentTypeIcon'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'
import { setSelectedFinancialDocumentId } from 'shared/store/financialDocuments/financialDocuments.slice'
import { useAppDispatch } from 'shared/store/store'

interface LastRefundsRowProps {
    row: DocumentRow
    url: string
}

const LastRefundsRow = ({ row, url }: LastRefundsRowProps): JSX.Element => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isXSD } = useWindowSize()

    const dateStatusClasses = 'labelExtraSmall c-gray500'
    const paymentType = row.canceled ? PaymentTypeEnum.CANCELED : PaymentTypeEnum.REFUND

    const handleOnClick = () => {
        dispatch(
            setSelectedFinancialDocumentId({
                documentId: row.documentId,
                documentsGroup: DocumentsGroupEnum.REFUNDS
            })
        )
        navigate(url)
    }

    const lastRefundsRowId = `last-refunds-${row.documentId}`

    return (
        <tr
            className="home-block-row"
            onClick={handleOnClick}
            data-testid={`home-block-refunds-row-${row.documentId}`}
        >
            <PaymentTypeIcon
                paymentType={paymentType}
                testId={lastRefundsRowId}
                containerClassNames="home-block-icon-container"
                as="td"
            />
            <td className="m-left-16 flex-1">
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8 ">
                            <DateField
                                date={row.creationDate}
                                rowId={lastRefundsRowId}
                                classNames={dateStatusClasses}
                                isMobileStyle
                            />
                        </tr>
                        <tr>
                            <TextField
                                label={row.documentName}
                                classNames="labelMedium c-black m-top-8"
                                rowId={lastRefundsRowId}
                                fieldId="documentName"
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            <GoToField rowId={lastRefundsRowId} marginLeft={isXSD ? undefined : 8} />
        </tr>
    )
}

export default LastRefundsRow
