import axios from 'axios'
import { NotificationType } from 'core/enums/Notifications'
import { Notifications } from 'core/models/Notifications'

export const fetchNotificationsApi = async (): Promise<Notifications[]> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Notifications`)
    const notifications: Notifications[] = result.data

    return notifications.filter((item) =>
        Object.values(NotificationType).includes(item.notificationType)
    )
}
