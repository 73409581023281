import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { formatDate, formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import useWindowSize from 'core/services/useWindowSize'
import { getCurrentYear } from 'core/utils/dateUtils'
import addMonths from 'date-fns/addMonths'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getLamalAccidentOfSelectedFamilyMemberToday } from 'shared/store/selectors/services/getLamalAccidentOfSelectedFamilyMember'
import { fetchSummaryAndProducts } from 'shared/store/services/services.slice'

const getEffectiveDates = (hasAccidentToday: boolean | null | undefined): SelectMap<string> => {
    const today = new Date()
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1)

    const values = new Map() as SelectMap<string>

    if (hasAccidentToday !== null && hasAccidentToday !== undefined && !hasAccidentToday) {
        values.set(formatDate(startDate), formatDateToApiFormat(startDate))
    }

    for (let i = 1; i < 13; i++) {
        const currrentDate = addMonths(startDate, i)
        values.set(formatDate(currrentDate), formatDateToApiFormat(currrentDate))
    }

    return values
}

interface LamalAccidentSelectProps {
    selectedPolicyNumber?: number
}

const LamalAccidentSelect = ({
    selectedPolicyNumber
}: LamalAccidentSelectProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const { watch } = useFormContext()
    const dispatch = useDispatch()

    const hasAccidentToday = useSelector(getLamalAccidentOfSelectedFamilyMemberToday)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)

    const items = getEffectiveDates(hasAccidentToday)

    const effectiveDate: string | undefined = watch('effectiveDate')

    const loadStatus = () => {
        if (effectiveDate !== undefined) return LoadingStatusEnum.OK
        return getLoadingStatusFromLoaderList([summaryLoadingStatus, productsLoaderStatus])
    }

    if (!selectedPolicyNumber) {
        return null
    }

    return (
        <div className="bg-white bc-gray100 border-solid-width-1 p-32 m-top-32 m-bottom-32">
            <AssuraLoadAndError
                status={loadStatus()}
                defaultReloadAction={() =>
                    dispatch(
                        fetchSummaryAndProducts({
                            policyNumber: selectedPolicyNumber,
                            year: getCurrentYear(),
                            reload: true
                        })
                    )
                }
            >
                <AssuraFormSelectRHF
                    name="effectiveDate"
                    id="services-lamal-accident-effective-date"
                    label={t('SERVICES.START_DATE')}
                    items={items}
                    inline={isXL}
                    labelSize={4}
                    placeHolder={t('SERVICES.SELECT_A_DATE')}
                />
            </AssuraLoadAndError>
        </div>
    )
}

export default LamalAccidentSelect
