import { Route, Routes } from 'react-router-dom'

import Requests from 'modules/requests/pages/Requests'
import AccidentReport from 'modules/services/accidentReport/pages/AccidentReport'
import Aventura from 'modules/services/aventura/pages/Aventura'
import BilledPrime from 'modules/services/certificates/pages/BilledPrime'
import Delegation from 'modules/services/certificates/pages/Delegation'
import InsuranceAttestation from 'modules/services/certificates/pages/InsuranceAttestation'
import InsuranceCard from 'modules/services/certificates/pages/InsuranceCard'
import MedicalFees from 'modules/services/certificates/pages/MedicalFees'
import ProvisionalReplacementCertificate from 'modules/services/certificates/pages/ProvisionalReplacementCertificate'
import StatementDetails from 'modules/services/certificates/pages/StatementDetails'
import WebsiteSuccess from 'modules/services/components/WebsiteSuccess/WebsiteSuccess'
import GeneralQuestion from 'modules/services/contact/pages/GeneralQuestion'
import FamilyDoctor from 'modules/services/contract/pages/FamilyDoctor'
import LamalAccident from 'modules/services/coverage/pages/LamalAccident'
import ModelDeductible from 'modules/services/coverage/pages/ModelDeductible'
import AddMember from 'modules/services/familyGroup/pages/AddMember'
import PaymentChange from 'modules/services/financial/pages/ServicesPaymentChange'
import ServicesPaymentFrequencyModification from 'modules/services/financial/pages/ServicesPaymentFrequencyModification'
import Address from 'modules/services/personalData/pages/ServicesAddress'
import PersonalData from 'modules/services/personalData/pages/ServicesPersonalData'
import HospitalizationAnnouncement from 'modules/services/report/pages/HospitalizationAnnouncement'
import TripCancellation from 'modules/services/report/pages/TripCancellation'
import ServicesHub from 'modules/servicesHub/pages/ServicesHub'
import AssuraNotFound from 'shared/components/AssuraNotFound/AssuraNotFound'

const ServicesRoutes = (): JSX.Element => {
    //The most specific routes should be written first ! for example /services/xxx before /services
    return (
        <Routes>
            <Route>
                <Route index element={<ServicesHub />} />
                <Route path="Certificates/BilledPrime" element={<BilledPrime />} />
                <Route path="Certificates/MedicalFees" element={<MedicalFees />} />
                <Route
                    path="Certificates/InsuranceAttestation"
                    element={<InsuranceAttestation />}
                />
                <Route
                    path="Certificates/ProvisionalReplacementCertificate"
                    element={<ProvisionalReplacementCertificate />}
                />
                <Route path="Certificates/InsuranceCardOrder" element={<InsuranceCard />} />
                <Route path="Offers/Aventura" element={<Aventura />} />
                <Route path="Report/AccidentReport" element={<AccidentReport />} />
                <Route path="Certificates/StatementDetails" element={<StatementDetails />} />
                <Route path="Certificates/Delegation" element={<Delegation />} />
                <Route path="PersonalData/Infos" element={<PersonalData />} />
                <Route path="Coverage/ModelDeductible" element={<ModelDeductible />} />
                <Route path="PersonalData/Address" element={<Address />} />
                <Route path="Financial/PaymentChange" element={<PaymentChange />} />
                <Route
                    path="Financial/PaymentFrequencyModification"
                    element={<ServicesPaymentFrequencyModification />}
                />
                <Route path="Contract/FamilyDoctor" element={<FamilyDoctor />} />
                <Route path="Contract/LamalAccident" element={<LamalAccident />} />
                <Route path="Contact/GeneralQuestion" element={<GeneralQuestion />} />
                <Route path="Family/AddMember" element={<AddMember />} />
                <Route
                    path="Report/HospitalizationAnnouncement"
                    element={<HospitalizationAnnouncement />}
                />
                <Route path="Report/TripCancellation" element={<TripCancellation />} />
                <Route path="Success" element={<WebsiteSuccess />} />
                <Route path="SubmittedRequests" element={<Requests />} />
                <Route path="*" element={<AssuraNotFound />} />
            </Route>
        </Routes>
    )
}

export default ServicesRoutes
