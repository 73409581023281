import { createSelector } from '@reduxjs/toolkit'

import { getContactInfos } from '../services/contactInfos/contactInfos.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'

export const getSelectedMemberContact = createSelector([getContactInfos, getSelectedMemberPolicyNumber], (contactList, policyNumber) => {
    if (policyNumber && contactList[policyNumber]) {
        return contactList[policyNumber]
    } else {
        return undefined
    }
})
