import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { postAddressApi } from 'core/api/services/ContactInfos'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ServicesCommonRequestBodyWithAllFamily } from 'core/models/services/ServicesCommonRequestBody'
import { getServicesCommonRequestBodyWithAllFamily } from 'shared/store/selectors/getServicesCommonRequestBody'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import { onSubmitAddress } from './address.slice'

function* onSubmitAddressSaga(action: ReturnType<typeof onSubmitAddress>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.ADDRESS
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const datasFromForm = action.payload
        const commonRequestBodyithAllFamily: ServicesCommonRequestBodyWithAllFamily = yield select(
            getServicesCommonRequestBodyWithAllFamily
        )

        yield call(postAddressApi, {
            ...datasFromForm,
            ...commonRequestBodyithAllFamily,
            affectsAllFamilyMembers: true
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())

        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.PERSONAL_DATA.url,
                ...analyticsParams
            })
        )
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitAddressSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitAddressWatcher() {
    yield takeEvery(onSubmitAddress.type, onSubmitAddressSaga)
}

const watchers = [fork(onSubmitAddressWatcher)]

export default watchers
