import './NumberInputRHF.css'

import React, { ClipboardEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import { withButton } from 'shared/hoc/WithButton'
import { withError } from 'shared/hoc/WithError'
import { withLabel } from 'shared/hoc/WithLabel'

import InputRHF, { InputProps } from '../InputRHF/InputRHF'

interface NumberInputRHFProps extends InputProps {
    maxLength?: number
}

const NumberInputRHF = ({ maxLength, rules, name, ...rest }: NumberInputRHFProps): JSX.Element => {
    const {
        setValue,
        formState: { isSubmitted }
    } = useFormContext()

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (['e', 'E', '+', '-'].includes(e.key)) e.preventDefault()
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        const newValue = event.target.value.slice(0, maxLength)
        event.target.value = newValue
        setValue(name, newValue, { shouldValidate: isSubmitted })
    }

    const handleOnPaste = (e: ClipboardEvent) => {
        e.preventDefault()
        const data = e.clipboardData.getData('text/plain')
        setValue(name, data, { shouldValidate: isSubmitted })
    }

    const enhancedRules = { ...rules, pattern: /^\d*$/g }

    return (
        <InputRHF
            {...rest}
            name={name}
            onChange={handleOnChange}
            onPaste={handleOnPaste}
            type="number"
            onKeyDown={handleKeyDown}
            onInput={handleOnChange}
            rules={enhancedRules}
        />
    )
}

export default NumberInputRHF

export const AssuraNumberInputRHF = withLabel(withButton(withError(NumberInputRHF)))
