import './EnveloppesPdfViewerHeader.css'

import { useTranslation } from 'react-i18next'

import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

interface EnveloppesPdfViewerHeaderProps {
    totalDocuments: number
    enveloppeSelected?: EnveloppeRow
    closePanel: () => void
}

const EnveloppesPdfViewerHeader = ({
    totalDocuments,
    enveloppeSelected,
    closePanel
}: EnveloppesPdfViewerHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()

    const insuredPersonName = enveloppeSelected?.insuredPersonName

    // LEFT-BLOCK
    const initials = insuredPersonName
        ? (
              insuredPersonName.charAt(0) + enveloppeSelected?.insuredPersonLastName.charAt(0)
          ).toUpperCase()
        : ''

    const EnveloppesHeaderLeft = (): JSX.Element => {
        return (
            <AssuraAvatar
                id="avatar-initials"
                label={
                    insuredPersonName
                        ? {
                              title: insuredPersonName,
                              classNames: 'labelExtraSmallMedium c-gray500',
                              marginTop: 4
                          }
                        : undefined
                }
                mainContainerWidth={64}
                avatarContainerWidth={32}
            >
                <div className="labelSmallMedium">{initials}</div>
            </AssuraAvatar>
        )
    }

    // CENTER-BLOCK
    const EnveloppesHeaderCenter = (): JSX.Element => {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center">
                    <i
                        data-testid="enveloppe-header-document-type"
                        className={`icon ${enveloppeSelected?.documentTypeIcon} size-24`}
                    />
                    <span
                        data-testid="enveloppe-header-document-name"
                        className={`p-left-8 ${
                            isXSD ? 'labelSmallMedium' : 'labelMedium'
                        } assura-panel-center-block-ellipsis`}
                    >
                        {enveloppeSelected?.documentName}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <i
                        data-testid="enveloppe-header-status-icon"
                        className={`icon ${enveloppeSelected?.statusIcon} ${enveloppeSelected?.statusColor} size-24`}
                    />
                    <span
                        data-testid="enveloppe-header-status"
                        className="labelSmall c-gray500 enveloppes-panel-header-status p-left-8"
                    >
                        {t(enveloppeSelected?.statusTranslated ?? '').toUpperCase()}
                    </span>
                    {totalDocuments > 1 && !isXSD && (
                        <>
                            <span className="enveloppes-pdf-viewer-header-separator"></span>
                            <span className="labelSmall c-gray500">
                                {t('ENVELOPPES.FILES_COUNT', { count: totalDocuments })}
                            </span>
                        </>
                    )}
                </div>
            </>
        )
    }

    return (
        <AssuraPanelHeader
            leftBlock={<EnveloppesHeaderLeft />}
            centerBlock={<EnveloppesHeaderCenter />}
            closePanel={closePanel}
        />
    )
}

export default EnveloppesPdfViewerHeader
