import './LamalDocumentsCard.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getSelectedFamilyMember } from 'shared/store/combineSelectors'
import { getPolicyDocumentIdOfSelectedFamilyMember } from 'shared/store/selectors/getPolicyDocumentIdOfSelectedFamilyMember'
import { getDoctorLamalOfSelectedFamilyMember } from 'shared/store/selectors/services/getDoctorLamalOfSelectedFamilyMember'

import DocumentsCardLinkDelegation from './DocumentsCardLinkDelegation'
import DocumentsCardLinkPolicy from './DocumentsCardLinkPolicy'
import DocumentsCardLinkReplacement from './DocumentsCardLinkReplacement'

interface LamalDocumentsCardProps {
    switchCard: () => void
    cardHeight: number
}

const LamalDocumentsCard = ({ switchCard, cardHeight }: LamalDocumentsCardProps): JSX.Element => {
    const { t } = useTranslation()

    const hasLamalWithDoctor = useSelector(getDoctorLamalOfSelectedFamilyMember)
    const summary = useSelector(getSelectedFamilyMember)
    const doctor = summary?.productsPerson?.doctor

    const shouldDisplayDelegation = hasLamalWithDoctor && doctor !== null

    const policyDocumentId = useSelector(getPolicyDocumentIdOfSelectedFamilyMember)

    return (
        <div className="d-flex flex-column" style={{ height: `${cardHeight}px` }}>
            <div className="d-flex align-items-start p-bottom-12 border-bottom-solid-width-1 bbc-gray100">
                <div className="d-flex flex-column align-items-start flex-1 m-right-16">
                    <div className="headlineSmallMedium">
                        {t('INSURANCES.SPECIFICATIONS_CARD_HEADER_SWITCH')}
                    </div>
                    <div className="labelSmall c-gray500" style={{ marginTop: '4px' }}>
                        {t('INSURANCES.DOCUMENTS_CARD_HEADER_SUBTITLE')}
                    </div>
                </div>
                <AssuraIconButton
                    id="lamal-documents-card-header-switch"
                    icon="assura-close"
                    variant="default"
                    size="medium"
                    onClick={switchCard}
                />
            </div>
            <div className="lamal-documents-card-links">
                {shouldDisplayDelegation && <DocumentsCardLinkDelegation />}
                <DocumentsCardLinkReplacement />
                {!!policyDocumentId && <DocumentsCardLinkPolicy documentId={policyDocumentId} />}
            </div>
        </div>
    )
}

export default LamalDocumentsCard
