import { Assets } from 'core/models/cms/AssetCms'
import { LocalizedBadges } from 'core/models/mobileStore'

export const getLocalizedBadges = (assets: Assets, lang: string): LocalizedBadges => {
    switch (lang) {
        case 'en':
            return {
                playStore: assets?.SplashScreen_Badge_PlayStore?.en,
                appStore: assets?.SplashScreen_Badge_AppStore?.en
            }
        case 'de':
            return {
                playStore: assets?.SplashScreen_Badge_PlayStore?.de,
                appStore: assets?.SplashScreen_Badge_AppStore?.de
            }
        case 'it':
            return {
                playStore: assets?.SplashScreen_Badge_PlayStore?.it,
                appStore: assets?.SplashScreen_Badge_AppStore?.it
            }
        default:
            return {
                playStore: assets?.SplashScreen_Badge_PlayStore?.fr,
                appStore: assets?.SplashScreen_Badge_AppStore?.fr
            }
    }
}
