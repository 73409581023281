import { Language } from 'core/enums/Language'
import { ViewAddress } from 'core/models/familyGroup/ViewAddress'

import { createSelector } from '@reduxjs/toolkit'

import { getContactInfos } from '../services/contactInfos/contactInfos.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export interface ConnectedUserInfos {
    policyNumber: number
    firstName: string
    lastName: string
    address: ViewAddress | null
    email?: string | null
    language: Language
    login: string
}

export const getConnectedUserInfos = createSelector([getContactInfos, getConnectedFamilyMember], (contactList, connectedPerson):
    | ConnectedUserInfos
    | undefined => {
    if (connectedPerson && contactList[connectedPerson.policyNumber]) {
        const contactData = contactList[connectedPerson.policyNumber].datas
        return {
            policyNumber: connectedPerson.policyNumber,
            firstName: connectedPerson.firstName,
            lastName: connectedPerson.lastName,
            address: contactData?.address ?? null,
            email: contactData?.email,
            login: connectedPerson?.login ?? '',
            language: contactData?.language ?? 'fr-CH'
        }
    } else {
        return undefined
    }
})
