import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { SpecificationLine } from './LamalSpecificationsCard'

interface RenderAccidentProps {
    accident: boolean
}

const Accident = (props: RenderAccidentProps): JSX.Element => {
    const { accident } = props
    const { t } = useTranslation()
    return (
        <SpecificationLine
            id="accident"
            code="accident"
            isNotDisplayingHorizontalSeparator={true}
            informationsContainer={
                <div className="accident-container">
                    <span
                        className={`labelExtraSmall 
                            ${accident ? 'c-black' : 'c-gray500'}`}
                        style={{ paddingRight: '8px' }}
                    >
                        {t(accident ? 'INSURANCE.ACCIDENT' : 'INSURANCE.NO_ACCIDENT')}
                    </span>
                    <i
                        className={
                            accident
                                ? 'icon assura-check-circle c-black size-16'
                                : 'icon assura-error c-gray500 size-16'
                        }
                    />
                </div>
            }
        />
    )
}

export default Accident
