import axios from 'axios'

export const is2faActive = async (): Promise<boolean> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Account/2fa/isactive`
    )
    return result.data
}

export const deactivate2fa = async (): Promise<boolean> =>
    axios.put(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/2fa/deactivate`)

export const request2fa = async (): Promise<boolean> =>
    axios.put(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/2fa/onboard`)
