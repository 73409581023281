import './DateField.css'

import { formatDate } from 'core/helpers/DateFormatHelper'
import concatClassNames from 'core/utils/classNameUtils'

interface DateFieldProps {
    date: string
    rowId: number | string
    classNames: string
    isMobileStyle?: boolean
}

const DateField = ({ date, rowId, isMobileStyle, classNames }: DateFieldProps): JSX.Element => {
    const responsivePadding = isMobileStyle ? undefined : 'date-field-container'
    const containerClasses = concatClassNames([responsivePadding, classNames])

    return (
        <td data-testid={`assura-table-row-${rowId}-date-field`} className={containerClasses}>
            {date !== '' ? formatDate(date) : ''}
        </td>
    )
}

export default DateField
