import { useEffect, useState } from 'react'

import BREAKPOINTS from 'core/constants/breakpoints'

import { prepareScreenInfos } from '../helpers/AnalyticsHelper'

export interface WindowSize {
    width: number
    outerWidth: number
    height: number
    isXSD: boolean
    isSD: boolean
    isXS: boolean
    isMD: boolean
    isXL: boolean
    isMobile: boolean
    maxContainerWidth: number
}

const getMaxContainerWidth = (width: number): number => {
    const { sm, md, lg, xl } = BREAKPOINTS

    if (width < sm) return 0
    if (width < md) return 640
    if (width < lg) return 864
    if (width < xl) return 1088
    return 1312
}

const getWindowSizes = (): WindowSize => {
    const width = window.innerWidth
    const outerWidth = window.outerWidth
    const height = window.innerHeight
    const { xs, sm, md, xl } = BREAKPOINTS
    return {
        width,
        outerWidth,
        height,
        isXSD: width < xs,
        isSD: width >= xs && width < sm,
        isXS: width >= sm && width < md,
        isMD: width >= md && width < xl,
        isXL: width >= xl,
        isMobile: width < md,
        maxContainerWidth: getMaxContainerWidth(width)
    }
}

const useWindowSize = (): WindowSize => {
    // Always start with hooks
    //Breakpoints
    const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSizes())

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            const newSizes = getWindowSizes()
            // Set window width/height to state
            setWindowSize(newSizes)
            prepareScreenInfos(newSizes)
        }

        // Add event listener
        window.addEventListener('resize', handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}

export default useWindowSize
