import { isAfter, isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getSearchSuggestionsCms } from '../appSearch/appSearch.slice'

export const getAppSearchFilteredSuggestionsCms = createSelector([getSearchSuggestionsCms], (searchSuggestionsCms) => {
    const today = new Date()

    return searchSuggestionsCms
        .filter((suggCms) => {
            const startDate = suggCms.start_date_publication
            const hasStartDate = !!startDate
            const hasValidStartDate = !!startDate && isAfter(today, new Date(startDate))

            const endDate = suggCms.end_date_publication
            const hasEndDate = !!endDate
            const hasValidEndDate = !!endDate && isBefore(today, new Date(endDate))

            return (
                (!hasStartDate && !hasEndDate) ||
                (hasValidStartDate && (!hasEndDate || hasValidEndDate)) ||
                (hasValidEndDate && !startDate)
            )
        })
        .sort((a, b) => parseInt(a.order) - parseInt(b.order))
        .slice(0, 6)
})
