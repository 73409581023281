import sizeProps from 'core/constants/assuraIconButtonSizeProps'
import { IconButtonVariants } from 'core/enums/IconButtonVariants'

import AssuraEcLink, { AssuraEcLinkProps } from '../AssuraEcLink/AssuraEcLink'

interface AssuraEcLinkIconButtonProps
    extends Pick<AssuraEcLinkProps, 'ecLinkId' | 'testId' | 'classNames'> {
    icon: string
    variant: IconButtonVariants
    size: 'small' | 'medium'
    label?: string
}

const AssuraEcLinkIconButton = ({
    ecLinkId,
    testId,
    icon,
    variant,
    size,
    classNames,
    label
}: AssuraEcLinkIconButtonProps): JSX.Element => {
    return (
        <AssuraEcLink
            ecLinkId={ecLinkId}
            testId={testId}
            classNames={`icon-container clickable ${variant} ${classNames ? classNames : ''}`}
        >
            <i
                className={`icon ${icon} size-${sizeProps[size].iconSize}`}
                data-testid={`${testId}-icon`}
            />
            {label && (
                <div
                    className={`link-label ${sizeProps[size].labelFont}`}
                    style={{ marginLeft: `${sizeProps[size].labelMargin}px` }}
                    data-testid={`${testId}-label`}
                >
                    {label}
                </div>
            )}
        </AssuraEcLink>
    )
}

export default AssuraEcLinkIconButton
