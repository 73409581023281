import './PickerDayWithYearHeader.css'

import { useSelector } from 'react-redux'

import locales from 'core/services/locales'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import { format, getMonth, getYear } from 'date-fns'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraSelect, { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { getLang } from 'shared/store/general/general.slice'

interface PickerDayWithYearHeaderProps {
    date: Date
    changeYear: (year: number) => void
    changeMonth: (month: number) => void
    decreaseMonth: () => void
    increaseMonth: () => void
    prevMonthButtonDisabled: boolean
    nextMonthButtonDisabled: boolean
    addFuturYearHeadroomCount?: number
}

const PickerDayWithYearHeader = (props: PickerDayWithYearHeaderProps): JSX.Element => {
    const {
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        addFuturYearHeadroomCount = 0
    } = props

    const lang = useSelector(getLang)

    const years = new Map() as SelectMap<number>
    for (
        let i = getYear(new Date()) + addFuturYearHeadroomCount;
        i > getYear(new Date()) - 90;
        i--
    ) {
        years.set(i.toString(), i)
    }

    const months = new Map() as SelectMap<number>
    for (let i = 0; i <= 11; i++) {
        months.set(
            stringUpperCaseFirstCharacter(
                format(new Date(2020, i, 1), 'LLLL', { locale: locales.get(lang) })
            ),
            i
        )
    }

    return (
        <div
            style={{
                display: 'flex'
            }}
        >
            <div
                className="d-flex"
                style={{ visibility: prevMonthButtonDisabled ? 'hidden' : 'visible' }}
            >
                <AssuraIconButton
                    id="headerMinus"
                    icon="assura-left"
                    variant="default"
                    size="medium"
                    onClick={decreaseMonth}
                    classNames="c-white m-right-8"
                />
            </div>
            <div className="picker-day-with-year-select-years">
                <AssuraSelect
                    id="headerYear"
                    items={years}
                    value={getYear(date)}
                    onChange={(value) => value && changeYear(value)}
                    toggleClassnames="picker-day-with-year-select-toggle"
                    menuClassnames="picker-day-with-year-select-menu labelSmall"
                    shouldDisplaySelectionInMenu
                    shouldUseValueForMenuItemTestId
                />
            </div>
            <div className="picker-day-with-year-select-months m-left-8">
                <AssuraSelect
                    id="headerMonth"
                    items={months}
                    value={getMonth(date)}
                    onChange={(value) => {
                        if (value !== null) return changeMonth(value)
                    }}
                    containerClassnames="w-100"
                    toggleClassnames="picker-day-with-year-select-toggle"
                    menuClassnames="picker-day-with-year-select-menu labelSmall"
                    shouldDisplaySelectionInMenu
                />
            </div>
            <div
                className="d-flex"
                style={{ visibility: nextMonthButtonDisabled ? 'hidden' : 'visible' }}
            >
                <AssuraIconButton
                    id="headerPlus"
                    icon="assura-right"
                    variant="default"
                    size="medium"
                    onClick={increaseMonth}
                    classNames="c-white m-left-8"
                />
            </div>
        </div>
    )
}

export default PickerDayWithYearHeader
