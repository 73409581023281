import navigationConstants from 'core/constants/navigationConstants'
import { getServiceRoute } from 'core/helpers/NavigationHelper'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilyMemberIndex } from '../familyMember/familyMember.slice'
import { getPath } from '../general/general.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'
import { getSummaryByPage } from './getSummaryByPage'

export const getSelectedPolicyMemberByPage = createSelector(
    [getSummaryByPage, getFamilyMemberIndex, getSelectedMemberPolicyNumber, getPath],
    (summary, familyMemberIndexCustomer, selectedPolicyNumberServices, page) => {
        const serviceRoute = getServiceRoute(page)

        if (!serviceRoute && page !== navigationConstants.PERSONAL_DATA.url) {
            return summary?.insuredPersons[familyMemberIndexCustomer]?.policyNumber
        } else {
            return selectedPolicyNumberServices
        }
    }
)
