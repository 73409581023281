import './InvoicesToCheckRowUI.css'

import { useTranslation } from 'react-i18next'

import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { InvoiceChecking } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import CheckStatus from 'modules/communications/components/InvoicesToCheckTable/fields/CheckStatus'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

interface InvoicesToCheckRowMobileProps {
    row: InvoiceChecking
}

const InvoicesToCheckRowMobile = ({ row }: InvoicesToCheckRowMobileProps): JSX.Element => {
    const { t } = useTranslation()
    const toCheck = row.status === InvoiceCheckingStatusEnum.TO_CHECK
    const dueDateValue = `${row.responseDeadline && formatDate(row.responseDeadline)}`

    return (
        <td className="invoice-to-check-row-mobile-table d-flex w-100">
            <table className="w-100">
                <tbody>
                    <tr className="invoice-to-check-row-mobile d-flex">
                        <td className={`invoice-to-check-row-mobile-header-container d-flex`}>
                            <table className={`invoice-to-check-row-mobile-header d-flex`}>
                                <tbody className="invoice-to-check-row-mobile-header-tbody d-flex flex-1">
                                    <tr className="invoice-to-check-row-mobile-header-tr d-flex">
                                        <DateField
                                            date={row.creationDate}
                                            rowId={row.id}
                                            isMobileStyle
                                            classNames={'labelExtraSmall'}
                                        />
                                        <Separator />
                                        <td>
                                            <CheckStatus status={row.status} size="16" />
                                        </td>
                                        <Separator />
                                        <TextField
                                            label={row.insuredPersonName ?? ''}
                                            rowId={row.id}
                                            classNames={'labelExtraSmall'}
                                            fieldId="invoices-to-check-insured-person"
                                        />
                                    </tr>

                                    <tr className={`invoice-to-check-row-mobile-title d-flex`}>
                                        <td className={`label d-flex`}>
                                            {t('COMMUNICATIONS.INVOICES_TO_CHECK.MOBILE.TITLE')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <GoToField rowId={row.id} marginLeft={8} />
                    </tr>

                    {toCheck && (
                        <>
                            <tr className="provider-row d-flex">
                                <td className={`provider-row-label labelExtraSmall d-flex`}>
                                    {t('COMMUNICATIONS.INVOICES_TO_CHECK.MOBILE.PROVIDER_TITLE')}
                                </td>
                                <TextField
                                    label={row.careProviderName}
                                    rowId={row.id}
                                    classNames={'labelExtraSmall provider-row-value d-flex'}
                                    fieldId="invoices-to-check-provider"
                                />
                            </tr>

                            <tr className="due-date-row d-flex">
                                <td className={`due-date-label labelExtraSmall d-flex`}>
                                    {t('COMMUNICATIONS.INVOICES_TO_CHECK.MOBILE.DUE_DATE_LABEL')}
                                </td>
                                <TextField
                                    label={dueDateValue}
                                    rowId={row.id}
                                    classNames={'labelExtraSmallMedium due-date-value d-flex'}
                                    fieldId="invoices-to-check-provider"
                                />
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </td>
    )
}

export default InvoicesToCheckRowMobile
