import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { MissingDataType } from 'core/enums/MissingData'
import { emptyFunction } from 'core/helpers/functionHelper'
import { TranslationKey } from 'core/models/Translations'
import {
    removeAllNonPermanentBanners,
    removeBanner,
    setBanner
} from 'shared/store/banners/banners.slice'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { getServicesRequestsLoadingStatus } from 'shared/store/services/requests/requests.slice'
import {
    getSelectedMemberPolicyNumber,
    getServiceSubmitStatus,
    setSubmitServiceStatus
} from 'shared/store/services/services.slice'

const getMissingDataErrorMessage = (errorCode: MissingDataType | 'multiple'): TranslationKey => {
    switch (errorCode) {
        case MissingDataType.EMAIL_MISSING:
            return 'PERSONAL_DATA.MISSING_BANNER_EMAIL'
        case MissingDataType.PHONE_NUMBER_MISSING:
            return 'PERSONAL_DATA.MISSING_BANNER_PHONE'
        case MissingDataType.IBAN_MISSING:
            return 'PERSONAL_DATA.MISSING_BANNER_IBAN'
        case MissingDataType.MARITAL_STATUS_MISSING:
            return 'PERSONAL_DATA.MISSING_BANNER_MARITAL'
        case MissingDataType.ADDRESS_MISSING:
            return 'PERSONAL_DATA.MISSING_BANNER_ADDRESS'
        default:
            return 'PERSONAL_DATA.MISSING_BANNER_MULTIPLE'
    }
}

export enum PersonalDataForm {
    BASIC = 'basic',
    PHONE = 'phone',
    EMAIL = 'email'
}

interface PersonalDataProviderProps {
    children: ReactNode
    initValues: PersonalDataState
}

interface PersonalDataState {
    isRequestsAvailable: boolean
    formSelected?: PersonalDataForm
    fieldFocused: string
    selectedPolicyNumber: number
    isOnSuccess: boolean
}

interface PersonalDataContext {
    state: PersonalDataState
    selectForm: (form: PersonalDataForm) => void
    isFormSelected: (form: PersonalDataForm) => boolean
    setFocus: (field: string) => void
    handleCancel: () => void
    setNoChangeError: () => void
    removeNoChangeError: () => void
}

export const initialPersonalDataState: PersonalDataState = {
    isRequestsAvailable: false,
    formSelected: undefined,
    fieldFocused: '',
    selectedPolicyNumber: 0,
    isOnSuccess: false
}

const PersonalDataContext = createContext<PersonalDataContext>({
    state: initialPersonalDataState,
    selectForm: emptyFunction,
    isFormSelected: () => false,
    setFocus: emptyFunction,
    handleCancel: emptyFunction,
    setNoChangeError: emptyFunction,
    removeNoChangeError: emptyFunction
})

const personalDataNoChangeId = 'personal-data-no-change'

const PersonalDataProvider = ({ children, initValues }: PersonalDataProviderProps): JSX.Element => {
    const dispatch = useDispatch()

    const [personalDataState, setPersonalDataState] = useState(initValues)

    const pendingRequestsLoadStatus = useSelector(getServicesRequestsLoadingStatus)
    const submitServiceStatus = useSelector(getServiceSubmitStatus)
    const submitServiceStatusRef = useRef<LoadingStatusEnum | undefined>(undefined)
    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)
    const missingData = useSelector(getMissingDataInRequest)
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    const changeFormSelection = (form?: PersonalDataForm): void => {
        dispatch(removeAllNonPermanentBanners())
        setPersonalDataState({
            ...personalDataState,
            formSelected: form,
            fieldFocused: '',
            isOnSuccess: false
        })
        if (submitServiceStatus !== LoadingStatusEnum.OK) {
            dispatch(setSubmitServiceStatus(LoadingStatusEnum.OK))
        }
    }

    const selectForm = (form: PersonalDataForm): void => {
        changeFormSelection(form)
    }

    const handleCancel = (): void => {
        changeFormSelection()
    }

    const isFormSelected = (form: PersonalDataForm): boolean => {
        return personalDataState.formSelected === form
    }

    const setFocus = (field: string): void => {
        setPersonalDataState({
            ...personalDataState,
            fieldFocused: field
        })
    }
    const setNoChangeError = (): void => {
        dispatch(
            setBanner({
                id: personalDataNoChangeId,
                dataTestId: personalDataNoChangeId,
                message: 'FORM.NO_CHANGE_ERROR'
            })
        )
    }

    const removeNoChangeError = (): void => {
        dispatch(removeBanner(personalDataNoChangeId))
    }

    useEffect(() => {
        setPersonalDataState({
            ...personalDataState,
            isRequestsAvailable: pendingRequestsLoadStatus === LoadingStatusEnum.OK
        })
    }, [pendingRequestsLoadStatus])

    useEffect(() => {
        const succes =
            submitServiceStatusRef.current === LoadingStatusEnum.LOADING &&
            submitServiceStatus === LoadingStatusEnum.OK
        if (submitServiceStatus === LoadingStatusEnum.OK) {
            setPersonalDataState((previousState) => ({
                ...previousState,
                formSelected: undefined,
                fieldFocused: '',
                isOnSuccess: succes
            }))
        }
        submitServiceStatusRef.current = submitServiceStatus
    }, [submitServiceStatus])

    useEffect(() => {
        if (selectedPolicyNumber && missingDataLoadingStatus !== LoadingStatusEnum.LOADING) {
            const currentMissingData = missingData[selectedPolicyNumber]
            if (currentMissingData?.length && !personalDataState.isOnSuccess)
                dispatch(
                    setBanner({
                        dataTestId: 'personal-data-missing-data',
                        message: getMissingDataErrorMessage(
                            currentMissingData.length > 1 ? 'multiple' : currentMissingData[0]
                        )
                    })
                )
            setPersonalDataState((previousState) => ({
                ...previousState,
                selectedPolicyNumber,
                formSelected: undefined,
                fieldFocused: '',
                isOnSuccess: false
            }))
            
        }
    }, [missingData, selectedPolicyNumber, missingDataLoadingStatus])

    const value: PersonalDataContext = {
        state: personalDataState,
        selectForm,
        isFormSelected,
        setFocus,
        handleCancel,
        setNoChangeError,
        removeNoChangeError
    }

    return <PersonalDataContext.Provider value={value}>{children}</PersonalDataContext.Provider>
}

const usePersonalDataContext = (): PersonalDataContext => {
    return useContext(PersonalDataContext)
}

export { PersonalDataProvider, usePersonalDataContext }
