/* eslint-disable sonarjs/no-nested-template-literals */
import { ButtonVariants } from 'core/enums/ButtonVariants'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'

import AssuraEcLink, { AssuraEcLinkProps } from '../AssuraEcLink/AssuraEcLink'

export interface AssuraIcon {
    name: string
    size: number
    marginRight?: number
    marginLeft?: number
}

export interface AssuraEcLinkButtonProps
    extends Pick<AssuraEcLinkProps, 'ecLinkId' | 'testId' | 'classNames'> {
    text: string
    variant: ButtonVariants
    colorBackground?: boolean
    disabled?: boolean
    fullWidth?: boolean
    icon?: AssuraIcon
    iconPosition?: 'left' | 'right'
    loaderPosition?: 'left' | 'right'
    hasLoader?: boolean
    callback?: (link_url: string) => void
}

const AssuraEcLinkButton = ({
    ecLinkId,
    testId,
    text,
    variant,
    classNames,
    colorBackground,
    disabled,
    fullWidth,
    icon,
    hasLoader,
    iconPosition = 'left',
    loaderPosition = 'right',
    callback
}: AssuraEcLinkButtonProps): JSX.Element => {
    return (
        <AssuraEcLink
            ecLinkId={ecLinkId}
            testId={testId}
            classNames={`assura-button btn btn-${variant}${colorBackground ? ' inverted' : ''}${
                fullWidth ? ' btn-block' : ''
            }${classNames ? ` ${classNames}` : ''}`}
            disabled={disabled}
            as="button"
            callback={callback}
        >
            <div className="d-flex align-items-center justify-content-center">
                {iconPosition === 'left' && icon && (
                    <i
                        className={`m-right-${icon.marginRight ? icon.marginRight : 8} m-left-${
                            icon.marginLeft ? icon.marginLeft : 0
                        } icon size-${icon.size} ${icon.name}`}
                        data-testid={`${testId}-icon-left`}
                    />
                )}
                {loaderPosition === 'left' && hasLoader && !disabled && (
                    <div className={`m-left-8`} data-testid={`${testId}-loader-left`}>
                        <ActivityIndicator />
                    </div>
                )}
                <span>{text}</span>
                {loaderPosition === 'right' && hasLoader && !disabled && (
                    <div className={`m-left-8`} data-testid={`${testId}-loader-right`}>
                        <ActivityIndicator />
                    </div>
                )}
                {iconPosition === 'right' && icon && (
                    <i
                        className={`m-right-${icon.marginRight ? icon.marginRight : 8} m-left-${
                            icon.marginLeft ? icon.marginLeft : 0
                        } icon size-${icon.size} ${icon.name}`}
                        data-testid={`${testId}-icon-right`}
                    />
                )}
            </div>
        </AssuraEcLink>
    )
}

export default AssuraEcLinkButton
