import { call, delay, fork, put, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { submitUpdateLoginForm, submitUpdatePasswordForm } from 'core/api/Account'
import navigationConstants from 'core/constants/navigationConstants'
import Credentials from 'core/constants/translationKeys/credentials'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { logout } from 'shared/store/authentication/authentication.slice'
import { formSubmittedWithCustomMessage } from 'shared/store/services/services.slice'

import {
    onSubmitUpdateLogin,
    onSubmitUpdatePassword,
    resetUpdateResult,
    setForceReload,
    setUpdateResult,
    setUpdateStatus,
    UpdateResult
} from './credentials.slice'

function* onSubmitUpdateLoginSaga(action: ReturnType<typeof onSubmitUpdateLogin>) {
    try {
        yield put(setForceReload(false))
        yield put(resetUpdateResult())

        yield put(setUpdateStatus(LoadingStatusEnum.LOADING))

        const loginForm = action.payload
        yield call(submitUpdateLoginForm, loginForm)

        yield put(
            formSubmittedWithCustomMessage({
                redirectRouteName: navigationConstants.SETTINGS.url,
                toastMessage: Credentials.login.edit.updateSuccess
            })
        )
        yield put(setUpdateStatus(LoadingStatusEnum.OK))
        yield put(setForceReload(true))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data) {
            yield put(setUpdateResult(e.response?.data as UpdateResult))
            yield put(setUpdateStatus(LoadingStatusEnum.OK))
        } else {
            yield put(setUpdateStatus(LoadingStatusEnum.ERROR))
        }
    }
}

function* onSubmitUpdatePasswordSaga(action: ReturnType<typeof onSubmitUpdatePassword>) {
    try {
        yield put(setForceReload(false))
        yield put(resetUpdateResult())

        yield put(setUpdateStatus(LoadingStatusEnum.LOADING))

        const passwordForm = action.payload

        yield call(submitUpdatePasswordForm, passwordForm)

        yield put(
            formSubmittedWithCustomMessage({
                redirectRouteName: navigationConstants.SETTINGS.url,
                toastMessage: Credentials.password.edit.updateSuccess
            })
        )
        yield put(setUpdateStatus(LoadingStatusEnum.OK))
        yield put(setForceReload(true))
        yield delay(1000)
        yield put(logout())
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data) {
            yield put(setUpdateResult(e?.response?.data as UpdateResult))
            yield put(setUpdateStatus(LoadingStatusEnum.OK))
        } else {
            yield put(setUpdateStatus(LoadingStatusEnum.ERROR))
        }
    }
}

function* onSubmitUpdateLoginWatcher() {
    yield takeEvery(onSubmitUpdateLogin.type, onSubmitUpdateLoginSaga)
}

function* onSubmitUpdatePasswordWatcher() {
    yield takeEvery(onSubmitUpdatePassword.type, onSubmitUpdatePasswordSaga)
}

const watchers = [fork(onSubmitUpdateLoginWatcher), fork(onSubmitUpdatePasswordWatcher)]

export default watchers
