import './RadioBoxQuestion.css'

import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getErrorMessage } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import { InvoiceCheckingResponse } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import useWindowSize from 'core/services/useWindowSize'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'

interface RadioBoxQuestionProps {
    question: FieldValues
    index: number
    formIsClosed: boolean
    register: UseFormRegister<FieldValues>
}
const RadioBoxQuestion = ({ index, question, register, formIsClosed }: RadioBoxQuestionProps) => {
    const { t } = useTranslation()
    const windowsSize = useWindowSize()
    const groupName = `QuestionGroup${question.code}`
    const { setValue, formState, trigger } = useFormContext()
    const { errors } = formState
    const error = getErrorMessage(errors, groupName)
    const answer: InvoiceCheckingResponse = question.responses.find(
        (o: InvoiceCheckingResponse) => o.value !== null
    )

    const handleClick = (id: number, currentResponse: InvoiceCheckingResponse) => {
        for (const questionElement of question.responses as InvoiceCheckingResponse[]) {
            // reset all
            setValue(`questions.${index}.responses.${Number(questionElement.code) - 1}.value`, null)
        }
        // retain 1
        setValue(`questions.${index}.responses.${Number(currentResponse.code) - 1}.value`, '1')
        trigger(groupName)
    }

    return (
        <div className={`question-item d-flex`}>
            <div className={`question-item-title paragraph d-flex`}>{question.label}</div>
            {/* we show inputs only when a question is requested === TO_CHECK otherwise we just keep
        the question text */}
            {formIsClosed ? (
                <>
                    {answer && (
                        <div
                            className={`question-item-value paragraphLight d-flex m-top-16 m-bottom-16 m-left-32`}
                        >{`${t('COMMUNICATIONS.INVOICES_TO_CHECK.YOURANSWER')}: ${answer.label}`}</div>
                    )}
                </>
            ) : (
                <div
                    className={`question-item-question-wrapper ${windowsSize.isXSD ? 'mobile' : ''} d-flex`}
                >
                    <div
                        className={`question-item-question ${windowsSize.isXSD ? 'mobile' : ''} d-flex`}
                    >
                        {question?.responses?.map((q: InvoiceCheckingResponse, index: number) => {
                            return (
                                <RadioRHF
                                    key={q.code}
                                    id={`${index}`}
                                    name={groupName}
                                    label={q.label}
                                    register={register}
                                    classNames={`radio radio-${q.code}`}
                                    onClick={() => handleClick(index, q)}
                                    rules={{
                                        required: { value: true, message: 'COMMON.MANDATORY_FIELD' }
                                    }}
                                />
                            )
                        })}
                    </div>
                    {error && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start'
                            }}
                            className={`m-left-32 m-bottom-8 error-label d-flex labelExtraSmall c-error`}
                            data-testid={`${groupName}-error`}
                        >
                            {t(error)}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default RadioBoxQuestion
