
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { goToServicesForm } from 'shared/store/services/services.slice'

const NoAddressData = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    return (
        <div className="d-flex flex-column justify-content-between align-items-center" style={{height: '100%'}} >
            <div className="d-flex m-bottom-40">
                <i
                    className="icon assura-warning-circle size-48 c-primary"
                    data-testid="personal-data-no-address-data-icon"
                />
                <div
                    className="paragraphSmallLight c-black m-left-24 flex-1"
                    data-testid="personal-data-no-address-data-message"
                >
                    {t('PERSONAL_DATA.NO_ADDRESS_DATA')}
                </div>
            </div>
            <AssuraButton
                text={t('PERSONAL_DATA.ADD_ADDRESS_DATA')}
                id="personal-data-address-add-button"
                variant="primary"
                onClick={() => dispatch(goToServicesForm({ type: ServiceType.ADDRESS_CHANGE }))}
                icon={{
                    name: 'assura-plus',
                    size: 24
                }}
                fullWidth
            />
        </div>
    )
}

export default NoAddressData
