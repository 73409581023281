export default {
    CONTRACT: 1,
    STATEMENT: 2,
    CLAIMS_SETTLEMENT: 3,
    COMMUNICATION: 4,
    PROMOTION: 5,
    MISCELLANEOUS: 6,
    REMINDER: 7,
    APPENDIX: 8,
    PREMIUM_NOTICE: 9
}
