import axios from 'axios'
import { accidentFormConverter } from 'core/helpers/services/AccidentReportHelper'
import { AccidentReportModel } from 'core/models/services/report/AccidentReportModel'
import { HospitalizationAnnouncementFormRequestBody } from 'core/models/services/report/HospitalizationAnnouncementForm'

export const submitHospitalizationAnnouncementForm = async (
    requestBody: HospitalizationAnnouncementFormRequestBody
): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Report/hospitalization`, requestBody)

export const postAccidentRequestAsyncApi = async (
    accidentReportModel: AccidentReportModel
): Promise<string> => {
    const formData = accidentFormConverter(accidentReportModel)
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/report/accident`,
        formData,
        {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }
    )
    return result.data
}
