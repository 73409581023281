import '../components/accidentReport.css'

import { useEffect } from 'react'
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Container } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import navigationConstants from 'core/constants/navigationConstants'
import { ServiceType } from 'core/enums/ServiceType'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { useFormRefresh } from 'core/services/useFormRefresh'
import Step from 'modules/services/components/Stepper/Step'
import Stepper from 'modules/services/components/Stepper/Stepper'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { setForbiddenBanner } from 'shared/store/banners/banners.slice'
import { submitData } from 'shared/store/services/accidentReport/accidentReport.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import {
    AccidentReportModel,
    defaultValues
} from '../../../../core/models/services/report/AccidentReportModel'
import StepWrapper from '../components/StepWrapper'
import AccidentDetailsForm, { accidentDetailsFormFieldNameList } from '../forms/AccidentDetailsForm'
import AccidentInsuranceForm, {
    accidentInsuranceFormFieldNameList
} from '../forms/AccidentInsuranceForm'
import DentalInjuriesForm, { dentalInjuriesFormFieldNameList } from '../forms/DentalInjuriesForm'
import EmploymentForm, { employmentFormFieldNameList } from '../forms/EmploymentForm'
import IncapacityForWorkForm, {
    incapacityForWorkFormFieldNameList
} from '../forms/IncapacityForWorkForm'
import PersonalDataForm, { personalDataFormFieldNameList } from '../forms/PersonalDataForm'
import VehiclesForm, { vehiclesFormFieldNameList } from '../forms/VehiclesForm'
import WoundForm, { woundFormFieldNameList } from '../forms/WoundForm'
import { defaultMockValues } from '../mock/AccidentReportMock'

/**
 * AccidentReport description:
 * this is the form definition
 * At this point you define the entire model structure that must match backend api submission body as you usualy do with others forms
 * You also configure the RHF Form provider.
 */
const AccidentReport = (): JSX.Element => {
    const navigate = useNavigate()
    useFormRefresh(ServiceType.ACCIDENT_REPORT)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (process.env.REACT_APP_SHOW_ACCIDENT_REPORT?.toString() === 'FALSE') {
            navigate(`${navigationConstants.SERVICES.url}`)
        }
    }, [])

    /** This will use the mock data for debug purpose. */
    const debugData = false

    const form = useForm<AccidentReportModel>({
        defaultValues: debugData ? defaultMockValues : defaultValues,
        shouldFocusError: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    })

    const outOfContext = (formInput: AccidentReportModel) => {
        if (!hasWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }
        dispatch(submitData(formInput))
    }

    return (
        <div className="accident-report-container">
            <div className="accident-report-background bg-gray20" />
            <FullScreenContainer complementaryClasses="container-load-error">
                {/* RHF FORM Provider  */}
                <FormProvider {...form}>
                    <Container style={{ minWidth: '360px' }} className="noborder">
                        {/* Stepper Context. It is agnostic in terms of business rules.
                         * It requires the useForm object.
                         * and of course minimal one zerobased step children
                         * */}
                        <Stepper
                            useForm={form}
                            id={0}
                            name={'stepper'}
                            /** use the debug mode in order to observe what's going on with the form internally. */
                            options={{ debug: false }}
                            submitMethod={outOfContext}
                        >
                            {/* Step object: It is agnostic in terms of business rules.
                             * It requires the key and id that MUST match the CUSTOM FORM id.
                             */}
                            <Step
                                key={0}
                                id={0}
                                label={t('SERVICE.ACCIDENT_REPORT.PERSONAL_DATA_FORM_TITLE')}
                                fieldList={personalDataFormFieldNameList}
                            >
                                {/* Step Wrapper: agnostic too, its role is to encapsulate responsiveness and good to know position. */}
                                <StepWrapper>
                                    {/* CUSTOM FORM */}
                                    <PersonalDataForm id={0} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={1}
                                id={1}
                                label={t('SERVICE.ACCIDENT_REPORT.EMPLOYMENT_FORM_TITLE')}
                                fieldList={employmentFormFieldNameList}
                            >
                                <StepWrapper>
                                    <EmploymentForm id={1} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={2}
                                id={2}
                                label={t('SERVICE.ACCIDENT_REPORT.ACCIDENT_DETAILS_FORM_TITLE')}
                                fieldList={accidentDetailsFormFieldNameList}
                            >
                                <StepWrapper>
                                    <AccidentDetailsForm id={2} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={3}
                                id={3}
                                label={t('SERVICE.ACCIDENT_REPORT.WOUND_FORM_TITLE')}
                                fieldList={woundFormFieldNameList}
                            >
                                <StepWrapper>
                                    <WoundForm id={3} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={4}
                                id={4}
                                label={t('SERVICE.ACCIDENT_REPORT.INCAPACITY_FOR_WORK_FORM_TITLE')}
                                fieldList={incapacityForWorkFormFieldNameList}
                            >
                                <StepWrapper>
                                    <IncapacityForWorkForm id={4} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={5}
                                id={5}
                                label={t('SERVICE.ACCIDENT_REPORT.DENTAL_INJURIES_FORM_TITLE')}
                                fieldList={dentalInjuriesFormFieldNameList}
                            >
                                <StepWrapper>
                                    <DentalInjuriesForm id={5} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={6}
                                id={6}
                                label={t('SERVICE.ACCIDENT_REPORT.VEHICLES_FORM_TITLE')}
                                fieldList={vehiclesFormFieldNameList}
                            >
                                <StepWrapper>
                                    <VehiclesForm id={6} />
                                </StepWrapper>
                            </Step>

                            <Step
                                key={7}
                                id={7}
                                label={t('SERVICE.ACCIDENT_REPORT.ACCIDENT_INSURANCE_FORM_TITLE')}
                                fieldList={accidentInsuranceFormFieldNameList}
                            >
                                <StepWrapper>
                                    <AccidentInsuranceForm id={7} />
                                </StepWrapper>
                            </Step>

                            {/** Please keep this following commented section as we will need it when they decide that we should implement it as discussed */}
                            {/* <Step key={8} id={8} label={t('Récapitulatif')}>
                                <StepWrapper>
                                    <StepperSummary />
                                </StepWrapper>
                            </Step> */}
                        </Stepper>
                    </Container>
                </FormProvider>
            </FullScreenContainer>
        </div>
    )
}
export default withAITracking(reactPlugin, AccidentReport, 'AccidentReport', 'acc-container')
