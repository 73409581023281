import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchDocumentsV2 } from 'core/api/Documents'
import { DocumentsGroupToCategories } from 'core/constants/documentsCategories'
import { DocumentsGroupEnum, FetchDocumentsTypeEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import {
    Document,
    DocumentsData,
    DocumentsDataWS,
    DocumentsRequestBody,
    FetchDocumentsCommonPayload
} from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { addDays, subDays } from 'date-fns'

import { getSortedCommunicationsForTable } from '../selectors/getSortedCommunicationsForTable'
import {
    CommunicationsState,
    fetchCommunications,
    fetchCommunicationsNotificationCount,
    getCommunications,
    getCommunicationsInitLoadingStatus,
    getCommunicationsState,
    setCommunications,
    setFilteringLoadingStatus,
    setInitLoadingStatus,
    setNotificationCount,
    setPaginatingLoadingStatus,
    setSelectedCommunicationId,
    setSelectedDocument
} from './communications.slice'

export function* getDocuments(payload: FetchDocumentsCommonPayload) {
    try {
        const { selectedDocumentsGroup, type, paginationDate, filters, docsByPage } = payload

        const { date = null, utn = null } = filters ?? {}

        const categoriesId = DocumentsGroupToCategories[selectedDocumentsGroup]
        const paginationArchivingStartDate =
            type === FetchDocumentsTypeEnum.PAGINATING ? paginationDate : null

        const requestBody: DocumentsRequestBody = {
            paymentStatus: null,
            userTiersNumber: utn,
            startDate: date?.startDate ?? null,
            endDate: date?.endDate ?? null,
            categoriesId,
            docsByPage: docsByPage || 10,
            paginationArchivingStartDate
        }

        const documentsData: DocumentsDataWS = yield call(fetchDocumentsV2, requestBody)

        let finalDocuments: Document[] = []

        if (type === FetchDocumentsTypeEnum.PAGINATING) {
            const currentDocuments: Document[] = yield select(getCommunications)
            finalDocuments = [...currentDocuments]
        }

        const newState: DocumentsData = {
            documents: finalDocuments.concat(...documentsData.documents),
            hasMore: documentsData.hasMore,
            paginationStartDate: documentsData.paginationStartDate
        }

        yield put(setCommunications(newState))
    } catch (e) {
        console.error('getDocuments Error', e)
        throw e
    }
}

export function* getFilteredDocumentsSaga(payload: FetchDocumentsCommonPayload) {
    try {
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.LOADING))
        yield call(getDocuments, payload)
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.OK))

        const initLoadingStatus: LoadingStatusEnum = yield select(
            getCommunicationsInitLoadingStatus
        )

        if (initLoadingStatus === LoadingStatusEnum.LOADING) {
            yield put(setInitLoadingStatus(LoadingStatusEnum.OK))
        }
    } catch (e) {
        console.error('getFilteredDocumentsSaga Error', e)
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

export function* getNextPageSaga(payload: FetchDocumentsCommonPayload) {
    try {
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.LOADING))
        yield call(getDocuments, payload)
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('getNextPageSaga Error', e)
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

export function* getDocumentsSaga(payload: FetchDocumentsCommonPayload) {
    try {
        const communicationsState: CommunicationsState = yield select(getCommunicationsState)
        const { filteringLoadingStatus, paginatingLoadingStatus } = communicationsState

        yield put(setInitLoadingStatus(LoadingStatusEnum.LOADING))

        if (filteringLoadingStatus === LoadingStatusEnum.ERROR) {
            yield put(setFilteringLoadingStatus(LoadingStatusEnum.OK))
        }

        if (paginatingLoadingStatus === LoadingStatusEnum.ERROR) {
            yield put(setPaginatingLoadingStatus(LoadingStatusEnum.OK))
        }

        yield call(getDocuments, payload)
        yield put(setInitLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('getDocumentsSaga Error', e)
        yield put(setInitLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchCommunicationsSaga(action: ReturnType<typeof fetchCommunications>) {
    try {
        switch (action.payload.type) {
            case FetchDocumentsTypeEnum.FILTERING:
                yield call(getFilteredDocumentsSaga, action.payload)
                break
            case FetchDocumentsTypeEnum.PAGINATING:
                yield call(getNextPageSaga, action.payload)
                break
            default:
                yield call(getDocumentsSaga, action.payload)
                break
        }
    } catch (e) {
        console.error('fetchCommunicationsSaga Error', e)
    }
}

export function* setSelectedCommunicationIdSaga(
    action: ReturnType<typeof setSelectedCommunicationId>
) {
    try {
        const documentId = action.payload

        let newSelectedDocument: DocumentRow | null = null

        if (documentId !== null) {
            const communicationRows: DocumentRow[] = yield select(getSortedCommunicationsForTable)
            const document = communicationRows.find(
                (doc: DocumentRow) => doc.documentId === documentId
            )
            newSelectedDocument = document ?? null
        }

        yield put(setSelectedDocument(newSelectedDocument))
    } catch (e) {
        console.error('setSelectedCommunicationIdSaga Error', e)
    }
}

export function* fetchCommunicationsNotificationCountSaga() {
    try {
        yield put(setInitLoadingStatus(LoadingStatusEnum.LOADING))

        const today = new Date()
        const startDate = subDays(today, 30)
        const endDate = addDays(today, 1)

        const requestBody: DocumentsRequestBody = {
            paymentStatus: null,
            userTiersNumber: null,
            startDate: formatDateToApiFormat(startDate),
            endDate: formatDateToApiFormat(endDate),
            categoriesId: [
                ...DocumentsGroupToCategories[DocumentsGroupEnum.CONTRACTUAL],
                ...DocumentsGroupToCategories[DocumentsGroupEnum.CORRESPONDENCE]
            ],
            docsByPage: 50,
            paginationArchivingStartDate: null
        }
        const documentsData: DocumentsDataWS = yield call(fetchDocumentsV2, requestBody)
        yield put(setNotificationCount(documentsData.documents.length))
        yield put(setInitLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchCommunicationsNotificationCountSaga Error', e)
        yield put(setInitLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* setSelectedCommunicationIdWatcher() {
    yield takeEvery(setSelectedCommunicationId.type, setSelectedCommunicationIdSaga)
}

function* fetchCommunicationsWatcher() {
    yield takeLatest(fetchCommunications.type, fetchCommunicationsSaga)
}

function* fetchCommunicationsNotificationCountWatcher() {
    yield takeLatest(
        fetchCommunicationsNotificationCount.type,
        fetchCommunicationsNotificationCountSaga
    )
}

const watchers = [
    fork(fetchCommunicationsWatcher),
    fork(setSelectedCommunicationIdWatcher),
    fork(fetchCommunicationsNotificationCountWatcher)
]

export default watchers
