import { getCurrentYear, getDifferenceInCalendarYears } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../../familySummaries/familySummaries.slice'
import { getWantedDate } from '../../services/doctors/doctors.slice'
import { getSelectedMemberPolicyNumber } from '../../services/services.slice'
import { getDoctorLamalOfSelectedFamilyMember } from './getDoctorLamalOfSelectedFamilyMember'

export const getCanHavePediatricianForFamilyDoctor = createSelector(
    [
        getFamilySummaries,
        getSelectedMemberPolicyNumber,
        getWantedDate,
        getDoctorLamalOfSelectedFamilyMember
    ],
    (familySummaries, selectedMemberPolicyNumber, wantedDate, doctorLamal) => {
        let canHavePediatrician = false

        const summaryResult = familySummaries[wantedDate?.getFullYear() ?? getCurrentYear()]
        if (summaryResult) {
            const selectedFamilyMember = summaryResult.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )
            if (
                selectedFamilyMember &&
                doctorLamal &&
                doctorLamal.code === 'RMd' &&
                wantedDate &&
                getDifferenceInCalendarYears(
                    wantedDate,
                    new Date(selectedFamilyMember.dateOfBirth)
                ) < 12
            ) {
                canHavePediatrician = true
            }
        }
        return canHavePediatrician
    }
)
