import { FileRejection, FileWithPath } from 'react-dropzone'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AssuraDropzone, { AssuraDropzoneProps } from 'shared/components/AssuraDropzone/AssuraDropzone'

export interface DropzoneRHFProps extends AssuraDropzoneProps {
    name: string
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    defaultValue?: FileWithPath
}

const DropzoneRHF = ({
    name,
    rules,
    handleAcceptedFile,
    handleRejectedFile,
    onDelete,
    defaultValue,
    ...props
}: DropzoneRHFProps): JSX.Element => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext()

    const hackedHandleAcceptedFile = (acceptedFile: FileWithPath) => {
        handleAcceptedFile(acceptedFile)
        setValue(name, acceptedFile)
    }

    const hackedHandleRejectedFile = (rejectedFile: FileRejection) => {
        handleRejectedFile(rejectedFile)
        setValue(name, undefined)
    }

    const hackedOnDelete = () => {
        setValue(name, undefined)
        if (onDelete) onDelete()
    }

    return (
        <>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ fieldState }) => (
                    <AssuraDropzone
                        {...props}
                        handleAcceptedFile={hackedHandleAcceptedFile}
                        handleRejectedFile={hackedHandleRejectedFile}
                        onDelete={hackedOnDelete}
                        mandatoryErrorText={t(fieldState.error?.message || '')}
                    />
                )}
                rules={rules}
            />
        </>
    )
}

export default DropzoneRHF
