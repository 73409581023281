import '../../pages/Aventura.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import { AventuraForm } from 'core/models/services/coverage/AventuraForm'
import { getPhoneNumber } from 'core/utils/phoneUtils'
import NoValidEmail from 'modules/services/components/NoValidEmail/NoValidEmail'
import NoValidPhoneNumber from 'modules/services/components/NoValidPhoneNumber/NoValidPhoneNumber'
import PendingRequest from 'modules/services/components/PendingRequest/PendingRequest'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getAventuraDailyPricesRate, getSummaryCurrentYear } from 'shared/store/combineSelectors'
import { getConnectedUserInfos } from 'shared/store/selectors/getConnectedUserInfos'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { isThereAventuraInRequests } from 'shared/store/selectors/isThereInRequests'
import { setFormData } from 'shared/store/services/aventura/aventura.slice'
import { getFormDataLoadingStatus, goToServicesForm } from 'shared/store/services/services.slice'

import Aventura from './Aventura'

type AventuraProviderContentProps = {
    isMobile: boolean
    isMD: boolean
}

type missingInfo = 'phone' | 'email'

const AventuraProviderContent = ({ isMobile, isMD }: AventuraProviderContentProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [missing, setMissing] = useState<missingInfo | undefined>(undefined)
    const memberList = useSelector(getSummaryCurrentYear).insuredPersons
    const connectedUserInfos = useSelector(getConnectedUserInfos)
    const isPending = useSelector(isThereAventuraInRequests)
    const dailyPricingRates = useSelector(getAventuraDailyPricesRate)

    const hof = memberList.find((o) => o.policyNumber === connectedUserInfos?.policyNumber)
    const personalData = useSelector(getFullPersonalData)
    const formDataLoadingStatus = useSelector(getFormDataLoadingStatus)
    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const loadingStatus = getLoadingStatusFromLoaderList([
        contactDataLoadStatus,
        formDataLoadingStatus
    ])

    useEffect(() => {
        if (!hof || !personalData) return

        const phone = personalData.phone
        if (!phone.data) phone.data = {} as PhoneDatas
        const fullPhoneNumber = getPhoneNumber(phone.data)

        setMissing((_state) => (_state = undefined))
        // prefixing by underscore _state   then compiler doesn't complains about not using it.
        if (!fullPhoneNumber) {
            setMissing((_state) => (_state = 'phone'))
            return
        }
        if (!connectedUserInfos) {
            setMissing((_state) => (_state = 'email'))
            return
        }

        const formData: AventuraForm = {
            policyNumber: hof?.policyNumber ?? 0,
            source: Source(),
            backOfficeUser: Source() === ServicesSource.BACKOFFICE ? '' : undefined,
            email: connectedUserInfos?.email ?? '',
            phoneNumber: fullPhoneNumber,
            travelReason: '',
            travelDestination: '',
            travelStart: '',
            travelEnd: '',
            paperFilled: true,
            aventuraCoverType: 'Single',
            additionalFamilyMembers: []
        }

        dispatch(setFormData(formData))
    }, [hof, personalData])

    return (
        <AssuraLoadAndError
            status={loadingStatus}
            shouldDisplayContainer
            defaultReloadAction={() =>
                dispatch(
                    goToServicesForm({
                        type: ServiceType.AVENTURA_INSURANCE_REQUEST
                    })
                )
            }
        >
            {isPending ? (
                <PendingRequest />
            ) : missing ? (
                <>
                    {missing === 'email' ? (
                        <NoValidEmail text={t('SERVICE.AVENTURA_MISSING_EMAIL')} />
                    ) : (
                        <NoValidPhoneNumber text={t('SERVICE.AVENTURA_MISSING_PHONE')} />
                    )}
                </>
            ) : (
                <Aventura
                    people={memberList}
                    t={t}
                    prices={dailyPricingRates}
                    isMobile={isMobile}
                    isTablet={isMD}
                />
            )}
        </AssuraLoadAndError>
    )
}

export default AventuraProviderContent
