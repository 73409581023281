import './FullScreenContainer.css'

import FullScreenOffset, { FullScreenColoredOffset } from './FullScreenOffset'

interface FullScreenContainerProps {
    id?: string
    color?: string
    secondColoredOffset?: FullScreenColoredOffset
    complementaryClasses?: string
    style?: React.CSSProperties
    children?: JSX.Element | JSX.Element[]
}

const FullScreenContainer = ({
    id,
    color,
    secondColoredOffset,
    complementaryClasses,
    style,
    children
}: FullScreenContainerProps): JSX.Element => {
    const setClassName = (): string => {
        let className = 'full-screen-container'

        if (color) className += ` bg-${color}`
        if (complementaryClasses) className += ` ${complementaryClasses}`

        return className
    }

    return (
        <div className={setClassName()} style={style} id={id}>
            <>
                {secondColoredOffset && (
                    <FullScreenOffset
                        offset={secondColoredOffset}
                        mainColor={`bg-${color ?? 'white'}`}
                    />
                )}
                {children}
            </>
        </div>
    )
}

export default FullScreenContainer
