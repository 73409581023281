import {
    ENGLISH_LANGUAGE,
    FRENCH_LANGUAGE,
    GERMAN_LANGUAGE,
    ITALIAN_LANGUAGE
} from 'core/constants/lang'
import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'

const locales = new Map()

locales.set(FRENCH_LANGUAGE, fr)
locales.set(ENGLISH_LANGUAGE, enGB)
locales.set(ITALIAN_LANGUAGE, it)
locales.set(GERMAN_LANGUAGE, de)

export default locales
