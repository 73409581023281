import { useTranslation } from 'react-i18next'

import { PaymentTypeEnum } from 'core/enums/Documents'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraPopover from 'shared/components/AssuraPopover/AssuraPopover'

import PaymentTypeIcon from '../PaymentTypeIcon/PaymentTypeIcon'

interface ClaimsSettlementReceiptPanelAmountCardProps {
    title?: string
    subTitle?: string
    price: number
    isRefund: boolean
    popoverLabel?: string
}

const ClaimsSettlementReceiptPanelAmountCard = ({
    title,
    subTitle,
    price,
    isRefund,
    popoverLabel
}: ClaimsSettlementReceiptPanelAmountCardProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="claims-settlement-panel-adaptive-padding-horizontal d-flex align-items-center p-top-16 p-bottom-16 bg-white"
            data-testid="claims-settlement-receipt-panel-amount-card"
        >
            <PaymentTypeIcon
                paymentType={isRefund ? PaymentTypeEnum.REFUND : PaymentTypeEnum.INVOICE}
                testId="claims-settlement-panel-receipt-label-payment-type-icon"
                containerClassNames="m-right-8"
            />
            <div className="flex-1 d-flex align-items-center">
                <div className="labelSmall d-flex flex-column">
                    <div>{title}</div>
                    {subTitle && <div>{subTitle}</div>}
                </div>
                {popoverLabel && (
                    <AssuraPopover
                        id={`receipt-panel-amount-${popoverLabel.toLowerCase()}-popover`}
                        position="bottom"
                        triggerType={['hover', 'focus']}
                        content={<div className="paragraphSmallLight">{t(popoverLabel)}</div>}
                        isInsidePanel
                    >
                        <div className="m-left-8">
                            <i
                                className="icon assura-info-circle c-gray500 size-16"
                                data-testid={`receipt-panel-amount-${popoverLabel.toLowerCase()}-icon`}
                            />
                        </div>
                    </AssuraPopover>
                )}
            </div>
            <div className="labelSmall m-left-16 c-gray500">
                {t('COMMON.PRICE', {
                    price: formatNumber(price)
                })}
            </div>
        </div>
    )
}

export default ClaimsSettlementReceiptPanelAmountCard
