import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { goToServicesForm } from 'shared/store/services/services.slice'

const NoFinancialData = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <>
            <div className="d-flex m-bottom-40">
                <i
                    className="icon assura-warning-circle size-48 c-primary"
                    data-testid="personal-data-no-financial-data-icon"
                />
                <div
                    className="paragraphSmallLight c-black m-left-24 flex-1"
                    data-testid="personal-data-no-financial-data-message"
                >
                    {t('PERSONAL_DATA.NO_FINANCIAL_DATA')}
                </div>
            </div>
            <AssuraButton
                text={t('PERSONAL_DATA.ADD_FINANCIAL_DATA')}
                id="personal-data-financial-add-button"
                variant="primary"
                onClick={() => dispatch(goToServicesForm({ type: ServiceType.BANK_REL_CHANGE }))}
                icon={{
                    name: 'assura-plus',
                    size: 24
                }}
                fullWidth
            />
        </>
    )
}

export default NoFinancialData
