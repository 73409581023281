import './ModelDeductible.css'

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import { Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import {
    ModelDeductibleForm,
    ModelDeductibleFormRHFForm
} from 'core/models/services/coverage/ModelDeductibleForm'
import { useFormRefresh } from 'core/services/useFormRefresh'
import useWindowSize from 'core/services/useWindowSize'
import PendingRequest from 'modules/services/components/PendingRequest/PendingRequest'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { removeBanner, setBanner, setForbiddenBanner } from 'shared/store/banners/banners.slice'
import { isThereDeductibleInRequests } from 'shared/store/selectors/isThereInRequests'
import {
    fetchMemberInformation,
    fetchModelDeductibles,
    getCurrentDeductible,
    getCurrentModel,
    getModelDeductibleDatesLoaderStatus,
    getModelDeductibles,
    getModelDeductiblesLoaderStatus,
    getModelDeductiblesWsStatus,
    MODEL_DEDUCTIBLE_UNSELECTED_DATE,
    onSubmitModelDeductible,
    setBeginDate,
    setCurrentSelectedModel
} from 'shared/store/services/coverage/coverage.slice'
import {
    getFamilyGroupResult,
    getFormDataLoadingStatus,
    getKeepSelectedMember,
    getServiceSubmitStatus,
    goToServicesForm,
    setFamilyGroupResult
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import FamilyGroup, { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import FormTitle from '../../components/FormTitle/FormTitle'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import UserInfo from '../../components/UserInfo/UserInfo'
import ModelDeductibleBeginDateSelect from '../components/ModelDeductibleBeginDateSelect/ModelDeductibleBeginDateSelect'
import ModelDeductibleCharacteristics from '../components/ModelDeductibleCharacteristics/ModelDeductibleCharacteristics'
import ModelDeductibleNotAllowed from '../components/ModelDeductibleNotAllowed/ModelDeductibleNotAllowed'
import ModelDeductibleSelectDeductible from '../components/ModelDeductibleSelectDeductible/ModelDeductibleSelectDeductible'
import ModelDeductibleSelectModel from '../components/ModelDeductibleSelectModel/ModelDeductibleSelectModel'
import ModelDeductibleSimulationDate from '../components/ModelDeductibleSimulationDate/ModelDeductibleSimulationDate'

interface BeginDateSectionProps {
    familyGroupResult: FamilyGroupResult
    modelDeductibleDatesLoaderStatus: LoadingStatusEnum
    simulationDate?: string
}

const BeginDateSection = ({
    familyGroupResult,
    modelDeductibleDatesLoaderStatus,
    simulationDate
}: BeginDateSectionProps): JSX.Element | null => {
    const dispatch = useDispatch()

    if (!familyGroupResult.selectedPolicyNumber) return null
    const hasPendingRequest = useSelector(isThereDeductibleInRequests)
    if (hasPendingRequest) return <PendingRequest />

    return (
        <div className="begin-date-container bg-white bc-gray100">
            <AssuraLoadAndError
                status={modelDeductibleDatesLoaderStatus}
                defaultReloadAction={() =>
                    dispatch(
                        fetchMemberInformation({
                            policyNumber: familyGroupResult.selectedPolicyNumber as number,
                            simulationDate
                        })
                    )
                }
            >
                <ModelDeductibleBeginDateSelect />
            </AssuraLoadAndError>
        </div>
    )
}

const ModelDeductible = (): JSX.Element => {
    useFormRefresh(ServiceType.DEDUCTIBLE_CHANGE)

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isMobile } = useWindowSize()

    const formDataLoadingStatus = useSelector(getFormDataLoadingStatus)
    const serviceSubmitStatus = useSelector(getServiceSubmitStatus)

    const currentDeductible = useSelector(getCurrentDeductible)
    const currentModel = useSelector(getCurrentModel)

    const modelDeductibles = useSelector(getModelDeductibles)
    const modelDeductiblesWsStatus = useSelector(getModelDeductiblesWsStatus)
    const modelDeductibleDatesLoaderStatus = useSelector(getModelDeductibleDatesLoaderStatus)
    const modelDeductiblesLoaderStatus = useSelector(getModelDeductiblesLoaderStatus)

    const keptMember = useSelector(getKeepSelectedMember)

    const defaultValues = {
        beginDate: MODEL_DEDUCTIBLE_UNSELECTED_DATE,
        deductible: undefined,
        model: undefined,
        doctorType: ServicesDoctorType.DOCTOR,
        searchDoctorName: '',
        newDoctorId: undefined,
        pharmacies: false,
        simulationDate: undefined
    }

    const methods = useForm<ModelDeductibleFormRHFForm>({ defaultValues })
    const { clearErrors, setValue, getValues, handleSubmit, watch } = methods

    const simulationDateWatch = watch('simulationDate')?.toLocaleDateString()
    const beginDateWatch = watch('beginDate')
    const deductibleWatch = watch('deductible')
    const modelWatch = watch('model')

    useEffect(() => {
        dispatch(setCurrentSelectedModel(modelWatch ? modelWatch : undefined))
    }, [modelWatch])

    const [familyMemberError, setFamilyMemberError] = useState('')
    const familyGroupResult = useSelector(getFamilyGroupResult)

    const isCoverageDifferent =
        currentDeductible !== +deductibleWatch || currentModel !== modelWatch

    // happens when the simulation date changes
    useEffect(() => {
        if (!simulationDateWatch) return
        setValue('beginDate', MODEL_DEDUCTIBLE_UNSELECTED_DATE)
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchMemberInformation({
                    policyNumber: familyGroupResult.selectedPolicyNumber,
                    simulationDate: simulationDateWatch
                })
            )
    }, [simulationDateWatch])

    useEffect(() => {
        // may change during fetchModelDeductibles dispatch
        setValue('deductible', String(currentDeductible))
        setValue('model', currentModel as string)
    }, [currentDeductible, currentModel])

    // happens when the begin date changes
    useEffect(() => {
        dispatch(setBeginDate(beginDateWatch ? beginDateWatch : undefined))
        dispatch(
            fetchModelDeductibles({
                policyNumber: familyGroupResult.selectedPolicyNumber as number,
                beginDate: beginDateWatch as string,
                simulationDate: simulationDateWatch
            })
        )
        clearAllErrors()
    }, [beginDateWatch])

    // happens when selected deductible / model changes
    useEffect(() => {
        clearAllErrors()
    }, [deductibleWatch, modelWatch])

    const clearAllErrors = () => {
        removeFormBannerById(noModelDeductibleId)
        removeFormBannerById(invalidFormBannerId)
        clearErrors('searchDoctorName')
        clearErrors('newDoctorId')
        clearErrors('pharmacies')
    }

    const selectMember = (family: FamilyGroupResult) => {
        // Reset all data
        setValue('beginDate', MODEL_DEDUCTIBLE_UNSELECTED_DATE)

        setFamilyMemberError('')
        dispatch(setFamilyGroupResult(family))
        family.selectedPolicyNumber &&
            dispatch(
                fetchMemberInformation({
                    policyNumber: family.selectedPolicyNumber,
                    simulationDate: simulationDateWatch
                })
            )
    }

    const submitSuccess = async () => {
        onSubmit(true)
    }

    const submitError = async () => {
        onSubmit(false)
    }

    const noModelDeductibleId = 'no-model-deductible-change'
    const invalidFormBannerId = 'model-deductible'

    const removeFormBannerById = (id: string) => {
        dispatch(removeBanner(id))
    }

    const handleCoverageDifference = (): void => {
        if (!isCoverageDifferent) {
            dispatch(
                setBanner({
                    id: noModelDeductibleId,
                    dataTestId: noModelDeductibleId,
                    message: 'SERVICES.MODEL_DEDUCTIBLE_NO_CHANGE'
                })
            )
        } else {
            removeFormBannerById(noModelDeductibleId)
        }
    }

    const onSubmit = (isRHFFormValid: boolean) => {
        const isFamilyMemberSelected = familyGroupResult.selectedPolicyNumber
        setFamilyMemberError(isFamilyMemberSelected ? '' : 'COMMON.MANDATORY_FIELD')
        handleCoverageDifference()

        if (isRHFFormValid && isFamilyMemberSelected && isCoverageDifferent) {
            if (!hasWritePermission()) {
                dispatch(setForbiddenBanner())
                return
            }

            const modelDeductibleForm: ModelDeductibleForm = {
                ...getValues(),
                ...familyGroupResult
            }
            dispatch(onSubmitModelDeductible(modelDeductibleForm))
        }
    }

    useEffect(() => {
        const { formState } = methods
        if (formState.isSubmitted) {
            if (Object.keys(formState.errors).length > 0) {
                dispatch(
                    setBanner({
                        id: invalidFormBannerId,
                        dataTestId: invalidFormBannerId,
                        message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
                    })
                )
            } else if (formState.isDirty) {
                removeFormBannerById(invalidFormBannerId)
            }
        }
    }, [methods.formState])

    return (
        <FullScreenContainer
            color="gray20"
            complementaryClasses="container-load-error position-relative"
        >
            <AssuraLoadAndError
                status={formDataLoadingStatus}
                defaultReloadAction={() =>
                    dispatch(goToServicesForm({ type: ServiceType.DEDUCTIBLE_CHANGE }))
                }
            >
                <FormProvider {...methods}>
                    <FullScreenContainer color="gray20">
                        <Container>
                            <Row className="p-bottom-56">
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                                    {!isMobile && (
                                        <FormTitle
                                            category={t('SERVICES.COVERAGE_REQUEST')}
                                            title={t('SERVICES.MODEL_DEDUCTIBLE')}
                                        />
                                    )}
                                    <div className="m-top-24">
                                        <UserInfo />
                                        {process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD' &&
                                            process.env.REACT_APP_ENVIRONMENT?.toString() !==
                                                'ACC' && <ModelDeductibleSimulationDate />}
                                        <FamilyGroup
                                            hasDefaultSelectedMember={!!keptMember}
                                            selectAction={selectMember}
                                            selectedMemberError={t(familyMemberError)}
                                            serviceType={ServiceType.DEDUCTIBLE_CHANGE}
                                            defaultSelectedPolicyNumber={
                                                keptMember?.selectedPolicyNumber
                                            }
                                            source={Source()}
                                        />
                                    </div>
                                    <BeginDateSection
                                        familyGroupResult={familyGroupResult}
                                        modelDeductibleDatesLoaderStatus={
                                            modelDeductibleDatesLoaderStatus
                                        }
                                        simulationDate={simulationDateWatch}
                                    />

                                    {modelDeductiblesWsStatus === HttpResponseEnum.BAD_REQUEST && (
                                        <ModelDeductibleNotAllowed />
                                    )}
                                </Col>
                                <Col
                                    xs={{ span: 12, order: 1 }}
                                    md={{ span: 4, order: 2 }}
                                    xl={{ span: 4, offset: 1 }}
                                >
                                    {isMobile && (
                                        <FormTitle title={t('SERVICES.MODEL_DEDUCTIBLE')} />
                                    )}
                                    <GoodToKnow
                                        goodToKnowId={ServiceType.DEDUCTIBLE_CHANGE}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </FullScreenContainer>
                    {familyGroupResult.selectedPolicyNumber !== 0 &&
                        beginDateWatch !== MODEL_DEDUCTIBLE_UNSELECTED_DATE &&
                        modelDeductiblesWsStatus !== HttpResponseEnum.BAD_REQUEST && (
                            <FullScreenContainer color="white">
                                <div className="d-flex flex-column" style={{ minHeight: 123 }}>
                                    <AssuraLoadAndError
                                        status={modelDeductiblesLoaderStatus}
                                        defaultReloadAction={() =>
                                            dispatch(
                                                fetchModelDeductibles({
                                                    policyNumber:
                                                        familyGroupResult.selectedPolicyNumber as number,
                                                    beginDate: beginDateWatch as string,
                                                    simulationDate: simulationDateWatch
                                                })
                                            )
                                        }
                                    >
                                        <>
                                            {modelDeductibles.length > 0 && (
                                                <>
                                                    <ModelDeductibleSelectDeductible />
                                                    <ModelDeductibleSelectModel />
                                                    <FullScreenContainer color="gray20">
                                                        <Container>
                                                            <ModelDeductibleCharacteristics />
                                                            <Row>
                                                                <Col
                                                                    xs={12}
                                                                    className="m-top-36 m-bottom-56"
                                                                >
                                                                    <div className="d-inline-flex model-deductible-buttons-container">
                                                                        <AssuraButton
                                                                            text={t('COMMON.SEND')}
                                                                            id="services-form-submit-button"
                                                                            variant="primary"
                                                                            onClick={handleSubmit(
                                                                                submitSuccess,
                                                                                submitError
                                                                            )}
                                                                            hasLoader={
                                                                                serviceSubmitStatus ===
                                                                                LoadingStatusEnum.LOADING
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </FullScreenContainer>
                                                </>
                                            )}
                                        </>
                                    </AssuraLoadAndError>
                                </div>
                            </FullScreenContainer>
                        )}
                </FormProvider>
            </AssuraLoadAndError>
        </FullScreenContainer>
    )
}

export default withAITracking(
    reactPlugin,
    ModelDeductible,
    'ModelDeductible',
    'model-deductible-container'
)
