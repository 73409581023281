import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'

import { ErrorMessage } from '@hookform/error-message'

interface LamalAccidentLegalNoticesProps {
    hasAccident: boolean
}

const LamalAccidentLegalNotices = ({
    hasAccident
}: LamalAccidentLegalNoticesProps): JSX.Element => {
    const { t } = useTranslation()
    const { register } = useFormContext()

    return (
        <div className="bg-white bc-gray100 border-solid-width-1 p-32 m-top-32">
            <div className="paragraphMedium m-bottom-24">{t('COMMON.LEGAL_NOTICES')}</div>
            <div className="d-flex flex-column justify-content-center align-items-start paragraphSmallLight">
                {hasAccident ? (
                    <div>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_REMOVE')}</div>
                ) : (
                    <ul className="services-lamal-accident-list">
                        <li>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_ADD_1')}</li>
                        <br />
                        <li>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_ADD_2')}</li>
                        <br />
                        <li>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_ADD_3')}</li>
                        <br />
                        <li>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_ADD_4')}</li>
                        <br />
                        <li>{t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_ADD_5')}</li>
                    </ul>
                )}
            </div>
            <div className="bg-white border-top-solid-width-1 btc-gray100 m-top-32 p-top-32">
                <CheckboxRHF
                    id="services-form-consent"
                    name="consent"
                    label={t('SERVICES.COVERAGE_ACCIDENT_LEGAL_NOTICES_CONSENT')}
                    register={register}
                    labelClassNames="paragraphSmallLight"
                    classNames="flex-1"
                    rules={{
                        required: {
                            value: true,
                            message: 'COMMON.MANDATORY_FIELD'
                        }
                    }}
                />
                <ErrorMessage
                    name="consent"
                    render={({ message }) => (
                        <div
                            className="labelExtraSmall c-primary m-top-8"
                            data-testid="services-form-consent-error"
                        >
                            {t(message)}
                        </div>
                    )}
                />
            </div>
        </div>
    )
}

export default LamalAccidentLegalNotices
