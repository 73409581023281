import './PersonalData.css'

import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { AssuraTabData } from 'core/models/TabData'
import useQuery from 'core/services/useQuery'
import { getPersonTabFromPolicyNumber, peopleToTab } from 'core/utils/TabUtils'
import PersonalDataBlock from 'modules/services/personalData/components/PersonalDataBlock/PersonalDataBlock'
import {
    PersonalDataProvider,
    initialPersonalDataState
} from 'modules/services/personalData/components/PersonalDataBlock/PersonalDataContext'
import PersonalDataFooter from 'modules/services/personalData/components/PersonalDataFooter/PersonalDataFooter'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { AssuraTabContainer } from 'shared/components/AssuraTabContainer/AssuraTabContainer'
import { ColorsEnum } from 'shared/components/AssuraTabDropdown/Icon'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { resetBanners } from 'shared/store/banners/banners.slice'
import { getSummaryCurrentYear } from 'shared/store/combineSelectors'
import { getConnectedUserInfos } from 'shared/store/selectors/getConnectedUserInfos'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { fetchFinancial } from 'shared/store/services/financial/financial.slice'
import {
    getKeepSelectedMember,
    getSelectedMemberPolicyNumber,
    setFamilyGroupResult,
    setKeepSelectedMember
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import PersonalDataTitle from '../components/PersonalDataTitle/PersonalDataTitle'

const PersonalData = (): JSX.Element | null => {
    const dispatch = useDispatch()
    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)
    const connectedUser = useSelector(getConnectedUserInfos)
    const familySummary = useSelector(getSummaryCurrentYear)
    const missingsDatas = useSelector(getMissingDataInRequest)
    const contactDataLoadStatus = useSelector(getFullPersonalData).contactDataLoadStatus

    const keptMember = useSelector(getKeepSelectedMember)

    const memberIndex = useQuery().get('index')
    const memberPolicyNumber = useQuery().get('policy')
    const initValues = { ...initialPersonalDataState }

    const [initialPerson, setInitialPerson] = useState<AssuraTabData | undefined>(undefined)

    useEffect(() => {
        const policyNumber = memberPolicyNumber && +memberPolicyNumber

        if (!policyNumber) {
            switchSelectedPersonById(0)
            return
        }
        const tmpSelectedPerson = getPersonTabFromPolicyNumber(peopleTabs, policyNumber)
        switchSelectedPerson(tmpSelectedPerson)
    }, [])

    useEffect(() => {
        if (memberIndex) {
            switchSelectedPersonById(Number.parseInt(memberIndex))
        }
    }, [memberIndex])

    useEffect(() => {
        if (!keptMember) return
        const tmpSelectedPerson = getPersonTabFromPolicyNumber(
            peopleTabs,
            keptMember.selectedPolicyNumber
        )

        switchSelectedPerson(tmpSelectedPerson)
        dispatch(setKeepSelectedMember(undefined))
    }, [keptMember])

    let peopleTabs = peopleToTab(familySummary.insuredPersons)

    if (Object.keys(missingsDatas).length > 0) {
        peopleTabs = peopleTabs.reduce<AssuraTabData[]>((acc, tab) => {
            let currentTab = tab
            if (missingsDatas[currentTab.businessId as number]) {
                currentTab = {
                    ...currentTab,
                    rightItem: {
                        value: '!',
                        color: ColorsEnum.white,
                        backgroundColor: ColorsEnum.redAssura
                    }
                }
            }
            acc.push(currentTab)
            return acc
        }, [] as AssuraTabData[])
    }

    const selectedPersonStoreAction = (policyNumber: number, reload?: boolean) => {
        if (initialPerson?.businessId === policyNumber) return
        dispatch(
            setFamilyGroupResult({ selectedPolicyNumber: policyNumber, isAllFamilyMembers: false })
        )
        dispatch(fetchContactInfos({ selectedPolicyNumber: policyNumber, reload }))
        dispatch(fetchFinancial({ selectedPolicyNumber: policyNumber, reload }))
    }

    const switchSelectedPersonById = (id: number) => {
        const person = peopleTabs.find((o) => o.uniqueKey === id)
        if (!person) return
        switchSelectedPerson(person)
    }

    const switchSelectedPerson = (person: AssuraTabData) => {
        if (person?.businessId === initialPerson?.businessId) return
        selectedPersonStoreAction(person?.businessId as number)
        setInitialPerson(person)
    }

    const handleTabSelection = (id: number) => {
        dispatch(resetBanners())
        switchSelectedPersonById(id)
    }

    useEffect(() => {
        if (!selectedPolicyNumber && connectedUser) {
            selectedPersonStoreAction(connectedUser.policyNumber)
        }
    }, [selectedPolicyNumber, connectedUser])

    if (!selectedPolicyNumber) return null

    return (
        <>
            <PersonalDataProvider initValues={initValues}>
                <FullScreenContainer color="gray50">
                    <Container>
                        <PersonalDataTitle />
                        {peopleTabs && peopleTabs.length > 1 && (
                            <AssuraTabContainer
                                data={peopleTabs}
                                selectedId={initialPerson?.uniqueKey}
                                dataId={'personal-data-tabs'}
                                onClick={handleTabSelection}
                            />
                        )}
                    </Container>
                </FullScreenContainer>
                <AssuraLoadAndError
                    status={contactDataLoadStatus}
                    defaultReloadAction={() =>
                        dispatch(
                            fetchContactInfos({
                                selectedPolicyNumber,
                                reload: true
                            })
                        )
                    }
                    shouldDisplayContainer
                >
                    <PersonalDataBlock />
                    <FullScreenContainer color="gray20">
                        <Container>
                            <PersonalDataFooter />
                        </Container>
                    </FullScreenContainer>
                </AssuraLoadAndError>
            </PersonalDataProvider>
        </>
    )
}

export default withAITracking(reactPlugin, PersonalData, 'PersonalData', 'personal-data-container')
