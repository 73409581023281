import { FileWithPath } from 'react-dropzone'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'

export interface AccidentReportState {
    submitStatus: LoadingStatusEnum
    errors: []
}

// this return types has also the name of the issued fieds
// starting with $.[fieldName]
// we can imagine to parde response and point out to the wrond field.
export interface AccidentReportErrorData {
    errors?: [x: string]
    status: number
    title: string
    traceId: string
    type: string
}

export interface AccidentReportHttpResponse {
    response?: AccidentReportServiceResponse
}
export interface AccidentReportServiceResponse {
    status?: number
    data?: AccidentReportErrorData
}

export interface PersonalDataModel {
    policyNumber?: number // => PolicyNumber
    email?: string // => Email
    phoneNumber?: string // => PhoneNumber
    coverage?: number // => Coverage
    employmentStatus?: string // => EmploymentStatus
    weeklyHoursWork?: number // => WeeklyHoursWork
    stillUnemployed?: string // => StillUnemployed
}

export interface EmploymentModel {
    profession?: string // => Profession
    employer?: string // => Employer
    employerInsurance?: string // => EmployerInsurance
    employerInsuranceKnow?: string // => EmployerInsuranceKnow
    employerInsuranceIncidentNumber?: string // => EmployerInsuranceIncidentNumber
    unemploymentBenefits?: string // => UnemploymentBenefits
    unemploymentStartDate?: Date // => UnemploymentStartDate
    unemploymentEndDate?: Date // => UnemploymentEndDate
}

export interface AccidentDetailsModel {
    accidentDate?: Date // => AccidentDate
    accidentTime?: Date // => AccidentTime
    accidentLocation?: number // => AccidentLocation
    accidentLocationLabel: string // => Used to forward value to backend and keep default value for the UI control
    cbstandard: boolean // => StandardAccident
    cbdental: boolean // => DentalAccident
    cbcirculation: boolean // => TrafficAccident
    eating?: string // => EatingAccident
    accidentCause?: string // => AccidentCause
    training?: string // => AccidentCompetition
    school?: string // => AccidentSchool
    work?: string // => AccidentCommute
    third?: string // JUSTE POUR AFFICHER OU PAS LES CHAMPS RELATIFS CI-DESSOUS
    thirdPartyAddress?: string // => ThirdPartyAddress
    thirdPartyInsurance?: string // => ThirdPartyInsurance
    police?: string // JUSTE POUR AFFICHER OU PAS LE CHAMP RELATIF CI-DESSOUS
    policeReport?: string // => PoliceOfficerName
    witnesses?: string // => Witnesses
}

export interface WoundModel {
    bodyPartInjured?: string // => BodyPartInjured
    injuryNature?: string // => InjuryNature
    firstDoctor?: string // => FirstDoctor
    processStartDate?: Date // => ProcessStartDate
    processOver?: string // => ProcessOver
    processDoctor?: string // => ProcessDoctor
    hospitalization?: string // => Hospitalization
    stillHospitalized?: string // => StillHospitalized
    hospitalizationStartDate?: Date // => HospitalizationStartDate
    hospitalizationEndDate?: Date // => HospitalizationEndDate
}

export interface IncapacityForWorkModel {
    workIncapacity?: string // => WorkIncapacity
    incapacityStartDate?: Date // => IncapacityStartDate
    rateIncapacity?: number // => RateIncapacity
    backToWork?: string // => BackToWork
    incapacityEndDate?: Date // => IncapacityEndDate
}

export interface DentalInjuriesModel {
    foodType?: string // FoodAccident
    unusualEvent?: string // => UnusualEventOccurred
    strange?: string // => ForeignParticles
    possession?: string // => StillHaveForeignParticles
    manufactured?: string // JUSTE POUR AFFICHER OU PAS LES CHAMPS RELATIFS CI-DESSOUS
    acquisition?: string // DentalAccidentDetails
    bill?: string // => StillHaveRelease
    productBill?: FileWithPath // => AttachedFileRelease
    announce?: string // => AnnouncedWhereBought
    announceDetails?: string // => AnnouncedWhereBoughtDetails
    sellerInsurance?: string // => StoreCoverage
}

export interface VehiclesModel {
    injured?: string // =>  ownVehicle | wasPassenger | standard => TrafficPlace
    vehicleDriver?: string // => VehicleDriver
    owner?: string // => JUSTE POUR AFFICHER OU PAS LES CHAMPS RELATIFS CI-DESSOUS
    vehicleOwner?: string // => VehicleOwner
    ownerInsurance?: string // => VehicleDriverAccidentInsurance
    ownerCarPlates?: string // => VehicleNumberplate
    otherVehicleDriver?: string // => OtherDriver
    otherVehicleOwner?: string // => OtherOwner
    otherVehicleInsurance?: string // => OtherAccidentInsurance
    otherVehiclePlates?: string // => OtherNumberplate
    otherInsuranceDeclared?: string // => IsAnotherAccidentInsurance
    otherInsuranceNumber?: string // => IncidentNumber
    friendlyArrangement?: string // => FindingAmicably
    friendlyDocument?: FileWithPath // => Report
}

export interface AccidentInsuranceModel {
    otherInsurance: string // JUSTE POUR AFFICHER OU PAS LES CHAMPS RELATIFS CI-DESSOUS
    otherInsuranceName?: string // => AnotherAccidentInsurance
    otherInsuranceCoverage?: string // => AnotherInsuranceType
    otherDocuments?: FileWithPath // =>  OtherDoc
    cbconfirm: boolean // => AssuraAuthorization
}

/** Main AccidentReport Model */
export type AccidentReportModel = PersonalDataModel &
    EmploymentModel &
    AccidentDetailsModel &
    WoundModel &
    IncapacityForWorkModel &
    DentalInjuriesModel &
    VehiclesModel &
    AccidentInsuranceModel

export const defaultValues: AccidentReportModel = {
    // Personal Data
    policyNumber: undefined,
    email: undefined,
    phoneNumber: undefined,
    coverage: undefined,
    employmentStatus: undefined,
    weeklyHoursWork: undefined,
    unemploymentBenefits: undefined,

    // Employment
    profession: undefined,
    employer: undefined,
    employerInsurance: undefined,
    employerInsuranceKnow: undefined,
    employerInsuranceIncidentNumber: undefined,
    stillUnemployed: undefined,
    unemploymentStartDate: undefined,
    unemploymentEndDate: undefined,

    //AccidentDetailsForm
    accidentDate: undefined,
    accidentTime: undefined,
    accidentLocation: undefined,
    accidentLocationLabel: '',
    cbstandard: false,
    cbdental: false,
    cbcirculation: false,
    eating: undefined,
    accidentCause: undefined,
    training: undefined,
    school: undefined,
    work: undefined,
    third: undefined,
    thirdPartyAddress: undefined,
    thirdPartyInsurance: undefined,
    police: undefined,
    policeReport: undefined,
    witnesses: undefined,

    // WoundForm
    bodyPartInjured: undefined,
    injuryNature: undefined,
    firstDoctor: undefined,
    processStartDate: undefined,
    processOver: undefined,
    processDoctor: undefined,
    hospitalization: undefined,
    stillHospitalized: undefined,
    hospitalizationStartDate: undefined,
    hospitalizationEndDate: undefined,

    // incapacity for work
    workIncapacity: undefined,
    incapacityStartDate: undefined,
    rateIncapacity: undefined,
    backToWork: undefined,
    incapacityEndDate: undefined,

    //DentalInjuriesForm
    foodType: undefined,
    unusualEvent: undefined,
    strange: undefined,
    possession: undefined,
    manufactured: undefined,
    acquisition: undefined,
    bill: undefined,
    productBill: undefined,
    announce: undefined,
    announceDetails: undefined,
    sellerInsurance: undefined,

    //VehiclesForm
    injured: undefined,
    vehicleDriver: undefined,
    owner: undefined,
    ownerInsurance: undefined,
    ownerCarPlates: undefined,
    otherVehicleDriver: undefined,
    otherVehicleOwner: undefined,
    otherVehicleInsurance: undefined,
    otherVehiclePlates: undefined,
    otherInsuranceDeclared: undefined,
    otherInsuranceNumber: undefined,
    friendlyArrangement: undefined,
    friendlyDocument: undefined,

    //AccidentInsuranceForm
    otherInsurance: '',
    otherInsuranceName: undefined,
    otherInsuranceCoverage: undefined,
    otherDocuments: undefined,
    cbconfirm: false
}

/** will be used for the existing requests panel so no need to delete it */
export interface AccidentApiModel {
    // Personal Data
    PolicyNumber: number
    Email: string
    PhoneNumber: string
    Coverage: number
    EmploymentStatus: string
    WeeklyHoursWork?: number
    UnemploymentBenefits?: boolean

    // Employment
    Profession: string
    Employer: string
    EmployerInsurance: string
    EmployerInsuranceKnow: boolean
    EmployerInsuranceIncidentNumber: string
    StillUnemployed?: boolean
    UnemploymentStartDate?: Date
    UnemploymentEndDate?: Date

    //AccidentDetailsForm
    AccidentDate?: Date
    AccidentTime: string
    AccidentLocation: string
    StandardAccident: boolean
    DentalAccident: boolean
    TrafficAccident: boolean
    EatingAccident?: boolean
    AccidentCause: string
    AccidentCompetition: boolean
    AccidentSchool: boolean
    AccidentCommute: boolean
    ThirdPartyAddress: string
    ThirdPartyInsurance: string
    PoliceOfficerName: string
    Witnesses: string

    // WoundForm
    BodyPartInjured: string
    InjuryNature: string
    FirstDoctor: string
    ProcessStartDate: Date
    ProcessOver: boolean
    ProcessDoctor: string
    Hospitalization: boolean
    StillHospitalized?: boolean
    HospitalizationStartDate?: Date
    HospitalizationEndDate?: Date

    // incapacity for work
    WorkIncapacity: boolean
    IncapacityStartDate?: Date
    RateIncapacity?: number
    BackToWork?: boolean
    IncapacityEndDate?: Date

    //DentalInjuriesForm
    FoodAccident: string
    UnusualEventOccurred: string
    ForeignParticles: string
    StillHaveForeignParticles: string
    DentalAccidentDetails: string
    StillHaveRelease?: boolean
    AttachedFileRelease?: FileWithPath
    AnnouncedWhereBought?: boolean
    AnnouncedWhereBoughtDetails: string
    AccidentAnnounced: boolean
    StoreCoverage: string

    /**trafficPlace:
     * Unspecified = 0,
     * Driver = 1,
     * Passenger = 2,
     * Pedestrian = 3
     */
    TrafficPlace: number

    VehicleDriver: string
    /// Own vehicle
    OwnVehicle?: boolean // conducteur
    /// Vehicle owner name and address
    VehicleOwner: string // vehicleOwner

    VehicleNumberplate: string
    VehicleDriverAccidentInsurance: string
    OtherDriver: string
    OtherOwner: string
    OtherNumberplate: StringConstructor
    OtherAccidentInsurance: string

    IsAnotherAccidentInsurance: boolean
    IncidentNumber: string
    FindingAmicably: boolean
    Report?: FileWithPath

    // AccidentInsuranceForm
    AnotherInsuranceType: string
    AnotherAccidentInsurance: string
    OtherDoc?: FileWithPath
    AssuraAuthorization: boolean
}
