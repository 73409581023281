import './SideNavigationMenu.css'

import { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SideNavigationItemId, SideNavigationList } from 'core/models/SideNavigation'
import useWindowSize from 'core/services/useWindowSize'

import SideNavigationElement from './SideNavigationElement'
import { useSideNavigationContext } from './SideNavigationProvider'

export interface SideNavigationMenuProps<T extends SideNavigationItemId> {
    title: string
    items: SideNavigationList<T>
}

export const containerHorizontalPadding = 16

const SideNavigationMenu = <T extends SideNavigationItemId>({
    title,
    items
}: SideNavigationMenuProps<T>): JSX.Element | null => {
    const { t } = useTranslation()

    const { width, isMobile } = useWindowSize()
    const { isMobileContentSelection, containerPadding, menuWidth } = useSideNavigationContext()

    useLayoutEffect(() => {
        const categoriesItems = document.querySelectorAll<HTMLElement>(
            '.side-navigation-element-background'
        )

        categoriesItems.forEach((item) => {
            item.style.left = `-${
                width <= 1440
                    ? containerPadding + containerHorizontalPadding
                    : containerHorizontalPadding
            }px`
            item.style.right =
                width <= 895 ? `-${containerPadding + containerHorizontalPadding}px` : '0'
        })
    }, [width, containerPadding, isMobileContentSelection])

    return !isMobileContentSelection ? (
        <div
            style={{
                flex: isMobile ? 1 : '0 0 400px',
                zIndex: 2
            }}
            data-testid="side-navigation-menu"
        >
            <div
                className="side-navigation-menu"
                style={{
                    width: isMobile ? '100%' : `${menuWidth}px`
                }}
            >
                <div className="title m-bottom-40" data-testid="side-navigation-title">
                    {t(title)}
                </div>
                <div id="side-navigation-menu-items">
                    {Array.from(items).map(([id, item]) =>
                        item.isHidden ? null : (
                            <SideNavigationElement id={id} key={`${id}`} item={item} />
                        )
                    )}
                </div>
            </div>
        </div>
    ) : null
}

export default SideNavigationMenu
