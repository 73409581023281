import './AppSearchBar.css'

import { KeyboardEvent, useEffect, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getUserText, setUserText } from 'shared/store/appSearch/appSearch.slice'
import { useAppDispatch } from 'shared/store/store'

interface AppSearchBarProps {
    hasAutoComplete?: boolean
    handleEnterKeyPress: () => void
}

const AppSearchBar = ({ hasAutoComplete, handleEnterKeyPress }: AppSearchBarProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const appSearchInputId = 'app-search-bar-input'
    const userInput = useSelector(getUserText)

    const [isFocused, setIsFocused] = useState(false)

    const handleUserTextValue = (value: string) => {
        dispatch(
            setUserText({
                text: value,
                shouldTriggerSearch: !!hasAutoComplete
            })
        )
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.currentTarget.value
        handleUserTextValue(currentValue)
    }

    const clearResults = () => {
        handleUserTextValue('')
    }

    const handleReset = (
        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        e.preventDefault()
        clearResults()
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleEnterKeyPress()
        }
    }

    const forceFocus = (
        e?: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        e && e.preventDefault()
        const input = document.getElementById(appSearchInputId)
        if (input && !isFocused) input.focus()
    }

    useEffect(() => {
        forceFocus()
    }, [])

    return (
        <div className="app-search-bar-container">
            <InputGroup>
                <InputGroup.Prepend
                    onMouseDown={(
                        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
                    ) => forceFocus(e)}
                    onTouchStart={(
                        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
                    ) => forceFocus(e)}
                >
                    <i
                        className={`icon assura-search size-24 p-right-8 c-${
                            isFocused ? 'black' : 'gray300'
                        }`}
                    />
                </InputGroup.Prepend>
                <input
                    id={appSearchInputId}
                    data-testid={appSearchInputId}
                    className="flex-1 label p-right-32"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={() => setIsFocused(false)}
                    value={userInput}
                    placeholder={t('FAQ.SEARCH_PLACEHOLDER')}
                    autoComplete="off"
                    onKeyPress={handleKeyDown}
                />
                {isFocused && (
                    <div
                        className="app-search-bar-input-clear-container"
                        data-testid="app-search-bar-input-clear-button"
                        onMouseDown={(e) => handleReset(e)}
                        onTouchStart={(e) => handleReset(e)}
                        style={{ visibility: userInput.length > 0 ? 'visible' : 'hidden' }}
                    >
                        <i className="icon assura-close size-24" />
                    </div>
                )}
            </InputGroup>
        </div>
    )
}

export default AppSearchBar
