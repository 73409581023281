import axios from 'axios'
import { BilledPrimeFormRequestBody } from 'core/models/services/certificates/BilledPrimeForm'
import { DelegationRequestBody } from 'core/models/services/certificates/Delegation'
import { InsuranceAttestationFormRequestBody } from 'core/models/services/certificates/InsuranceAttestationForm'
import { InsuranceCardFormRequestBody } from 'core/models/services/certificates/InsuranceCardForm'
import { MedicalFeesFormRequestBody } from 'core/models/services/certificates/MedicalFeesForm'
import { ProvisionalReplacementCertificateRequestBody } from 'core/models/services/certificates/ProvisionalReplacementCertificateForm'
import { StatementDetailsFormRequestBody } from 'core/models/services/certificates/StatementDetailsForm'
import { openInNewTab } from 'core/utils/onClickUtils'

export const submitBilledPrimeForm = async (
    requestBody: BilledPrimeFormRequestBody
): Promise<void> =>
    axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/billedPrimeDetails`,
        requestBody
    )

export const submitInsuranceCardForm = async (
    requestBody: InsuranceCardFormRequestBody
): Promise<void> =>
    axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/newInsuranceCard`,
        requestBody
    )

export const submitMedicalFeesForm = async (
    requestBody: MedicalFeesFormRequestBody
): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/TaxReturns/medicalfees`, requestBody)

export const submitInsuranceAttestationForm = async (
    requestBody: InsuranceAttestationFormRequestBody
): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/attestation`, requestBody)

export const submitStatementDetailsForm = async (
    requestBody: StatementDetailsFormRequestBody
): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/statement`, requestBody)

const directDownLoad = async <T extends object>(
    body: T,
    url: string,
    isOnError: (error: boolean) => void,
    isLoading: (error: boolean) => void
): Promise<void> => {
    axios
        .post(
            url,
            {
                ...body
            },
            {
                responseType: 'blob',
                onUploadProgress: function () {
                    isLoading(true)
                },
                onDownloadProgress: function () {
                    isLoading(true)
                }
            }
        )
        .then(async ({ data }) => {
            isLoading(false)
            const downloadUrl = window.URL.createObjectURL(data)
            openInNewTab(downloadUrl)
        })
        .catch((error) => {
            if (error) {
                isOnError(true)
            }
        })
}

export const submitDelegationDL = async (
    policyNumber: number,
    isOnError: (error: boolean) => void,
    isLoading: (error: boolean) => void
): Promise<void> => {
    const link = `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/delegation`
    directDownLoad({ policyNumber }, link, isOnError, isLoading)
}

export const submitDelegationFromForm = async (
    requestBody: DelegationRequestBody
): Promise<void> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/delegation`,
        requestBody,
        {
            responseType: 'blob'
        }
    )
    const downloadUrl = window.URL.createObjectURL(result.data)
    openInNewTab(downloadUrl)
}

export const submitProvisionalReplacementCertificateDL = async (
    body: ProvisionalReplacementCertificateRequestBody,
    isOnError: (error: boolean) => void,
    isLoading: (error: boolean) => void
): Promise<void> => {
    const link = `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/lamalCertificate`
    directDownLoad(body, link, isOnError, isLoading)
}

export const submitProvisionalReplacementCertificate = async (
    requestBody: ProvisionalReplacementCertificateRequestBody
): Promise<void> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Certificates/lamalCertificate`,
        requestBody,
        {
            responseType: 'blob'
        }
    )
    const downloadUrl = window.URL.createObjectURL(result.data)
    openInNewTab(downloadUrl)
}
