import { differenceInCalendarMonths, differenceInCalendarYears } from 'date-fns'

export const getCurrentYear = (): number => new Date().getFullYear()

export const getNextYear = (): number => getCurrentYear() + 1

export const getCheckDate = (year: number): Date =>
    year === getCurrentYear()
        ? new Date()
        : year < getCurrentYear()
        ? new Date(Date.UTC(year, 11, 31))
        : new Date(Date.UTC(year, 0, 1))

export const getDifferenceInCalendarYears = (laterDate: Date, earlierDate: Date): number => {
    return differenceInCalendarYears(laterDate, earlierDate)
}

export const getDifferenceInCalendarMonths = (laterDate: Date, earlierDate: Date): number => {
    return differenceInCalendarMonths(laterDate, earlierDate)
}
