import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedMemberPolicyNumber } from '../services/services.slice'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'

export interface SelectedMemberIndexAndYear {
    index: number
    year: number
}

export const getSelectedMemberIndexAndYear = createSelector(
    [getSummaryCurrentYear, getSelectedMemberPolicyNumber],
    (summary, policyNumber) => {
        const year = getCurrentYear()
        return {
            index: summary?.insuredPersons.findIndex(
                (person) => person.policyNumber === policyNumber
            ),
            year
        }
    }
)
