import axios from 'axios'

import { ClaimsSettlement } from '../models/documents/ClaimsSettlement'

export const fetchClaimsSettlementApi = async (
    claimsSettlementId: string
): Promise<ClaimsSettlement> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/ClaimsSettlements/${claimsSettlementId}`
    )

    return result.data
}
