import 'numeral/locales/fr-ch'

import numeral from 'numeral'

const badEmailFormat = 'COMMON.EMAIL_ERROR_BAD_FORMAT'

export const formatNumber = (value: number, withDecimal = true): string => {
    // fr-ch locale renders 1'000.00 or 1'000
    numeral.locale('fr-ch')
    return withDecimal ? numeral(value).format('0,0.00') : numeral(value).format('0,0')
}

const emailRegex = /^[a-zA-ZÀ-ÿ0-9._-]+@[a-zA-ZÀ-ÿ0-9.-]+\.[a-zA-ZÀ-ÿ0-9-]{2,}$/

export const isEmailValid = (text: string): boolean => {
    return emailRegex.test(text)
}

export const emailRules = {
    required: {
        value: true,
        message: 'COMMON.MANDATORY_FIELD'
    },
    pattern: {
        value: emailRegex,
        message: badEmailFormat
    },
    minLength: {
        value: 3,
        message: badEmailFormat
    },
    maxLength: {
        value: 100,
        message: badEmailFormat
    }
}

// returns 'returnIfNull' if null
export const ifNull = (value: string | number | null | undefined, returnIfNull = 'N/A'): string => {
    if (
        !value ||
        value.toString().toLowerCase().includes('null') ||
        value.toString().toLowerCase().includes('undefined')
    )
        return returnIfNull
    return value.toString()
}

export const stringUpperCaseFirstCharacter = (s: string): string => {
    return (s + '').charAt(0).toUpperCase() + s.substring(1)
}
