import './BSGuide.css'

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactDOM from 'react-dom'

import useWindowSize from 'core/services/useWindowSize'

interface BSGuideProps {
    paddingLeft: number
}

const BSGuide = ({ paddingLeft }: BSGuideProps): JSX.Element => {
    const windowSize = useWindowSize()
    const [bsGuideShow, setBsGuideShow] = useState(false)

    const handleUserKeyPress = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === 'K') setBsGuideShow(!bsGuideShow)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress)

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress)
        }
    }, [handleUserKeyPress])

    const getColNumber = () => {
        switch (true) {
            case windowSize.isXS:
                return 4
            case windowSize.isMD:
                return 8
            default:
                return 12
        }
    }

    const cols = []

    for (let i = 0; i < getColNumber(); i++) {
        cols.push(
            <Col key={`bsGuide${i}`} data-testid="bs-guide-col">
                <div className="bs-guide-content">&nbsp;</div>
            </Col>
        )
    }

    return ReactDOM.createPortal(
        <div
            className="bs-guide-view"
            data-testid="bs-guide-view"
            style={{ display: bsGuideShow ? 'block' : 'none', paddingLeft: `${paddingLeft}px` }}
        >
            <Container className="bs-guide-container">
                <Row className="bs-guide-row">{cols}</Row>
            </Container>
        </div>,
        document.body
    )
}
export default BSGuide
