import DocumentCategory from 'core/enums/DocumentCategory'
import { DocumentPaymentStatusEnum, DocumentsGroupEnum } from 'core/enums/Documents'
import { UnreadDocumentsByGroup } from 'core/models/documents/UnReadDocuments'

import { createSelector } from '@reduxjs/toolkit'

import { getUnreadDocuments } from '../documents/documents.slice'

export const getUnreadDocumentsByGroup = createSelector(
    [getUnreadDocuments],
    (unreadDocuments): UnreadDocumentsByGroup => {
        return unreadDocuments.reduce<UnreadDocumentsByGroup>(
            (acc, unreadDoc) => {
                switch (unreadDoc.categoryId) {
                    case DocumentCategory.REMINDER:
                    case DocumentCategory.PREMIUM_NOTICE:
                    case DocumentCategory.CLAIMS_SETTLEMENT:
                        if (unreadDoc.transactionType === DocumentPaymentStatusEnum.CREDIT) {
                            acc[DocumentsGroupEnum.REFUNDS]++
                        } else {
                            acc[DocumentsGroupEnum.INVOICES]++
                        }
                        break
                    case DocumentCategory.CONTRACT:
                        acc[DocumentsGroupEnum.CONTRACTUAL]++
                        break
                    case DocumentCategory.COMMUNICATION:
                    case DocumentCategory.PROMOTION:
                    case DocumentCategory.APPENDIX:
                        acc[DocumentsGroupEnum.CORRESPONDENCE]++
                        break
                    default:
                        break
                }

                return acc
            },
            {
                invoices: 0,
                refunds: 0,
                correspondence: 0,
                contractual: 0
            }
        )
    }
)
