import { ServiceType } from 'core/enums/ServiceType'
import { getServicesRequests } from 'shared/store/services/requests/requests.slice'

import { createSelector } from '@reduxjs/toolkit'

// Liste des requests à afficher
const allowedRequests = [
    ServiceType.CERTIFICATE_CATTP,
    ServiceType.CERTIFICATE_CATTI,
    ServiceType.COVER_CARD_ORDER,
    ServiceType.LPIMP_REQUEST,
    ServiceType.CDEC_REQUEST,
    ServiceType.NAME_CIVIL_CHANGE,
    ServiceType.PHONE_CHANGE,
    ServiceType.EMAIL_CHANGE,
    ServiceType.ADDRESS_CHANGE,
    ServiceType.BANK_REL_CHANGE,
    ServiceType.DEDUCTIBLE_CHANGE,
    ServiceType.FAMILY_DOCTOR_CHANGE,
    ServiceType.GENERAL_QUESTION,
    ServiceType.AUTO_LAMAL_ACC_SUSP_INCL,
    ServiceType.PAYMENT_FREQ_MODIFICATION,
    ServiceType.FAMILY_GROUP_CHANGE,
    ServiceType.AVENTURA_INSURANCE_REQUEST,
    ServiceType.HOSP_ANNOUNCEMENT,
    ServiceType.ACCIDENT_REPORT_BIS
]

export const getFilteredRequests = createSelector(
    [getServicesRequests],
    (requests) => {
        // Provisoire jusqu'à implémentation de toutes les requests details
        return requests.filter((r) => allowedRequests.includes(r.type))
    }
)
