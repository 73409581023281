import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { openInNewTab } from 'core/utils/onClickUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'

const ConflictedFinancialData = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = useSelector(getLang) as keyof AssetUrlCms
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms
    const assets = useSelector(getAssets)

    const AssetToLink = (item: AssetUrlCms) => {
        const cmsLink = (function () {
            if (lang === ENGLISH_LANGUAGE && !item.en) return item[contactLang] || undefined
            return item[lang] || undefined
        })()
        if (cmsLink) {
            return cmsLink
        }
    }

    const handleDownloadError = () => {
        dispatch(
            setBanner({
                dataTestId: 'personal-data-conflictual-financial-download-error',
                message: 'COMMON.ERROR'
            })
        )
    }

    return (
        <>
            <div className="d-flex m-bottom-40">
                <i
                    className="icon assura-warning-circle size-48 c-primary"
                    data-testid="personal-data-conflictual-financial-icon"
                />
                <div
                    className="paragraphSmallLight c-black m-left-24 flex-1 break-word"
                    data-testid="personal-data-conflictual-financial-message"
                >
                    {t('FINANCIAL.CONFLICTED_MESSAGE')}
                </div>
            </div>
            <AssuraButton
                text={t('FINANCIAL.DOWNLOAD_DOCUMENT')}
                id="personal-data-conflictual-financial-download-button"
                variant="primary"
                onClick={() => {
                    assets.Transmission_Coordonnees_Financieres
                        ? openInNewTab(
                              AssetToLink(assets.Transmission_Coordonnees_Financieres) as string
                          )
                        : handleDownloadError()
                }}
                icon={{
                    name: 'assura-pdf',
                    size: 24
                }}
                fullWidth
            />
        </>
    )
}
export default ConflictedFinancialData
