import './GoToField.css'

import concatClassNames from 'core/utils/classNameUtils'

interface GoToFieldProps {
    rowId: number | string
    classNames?: string
    marginLeft?: number
}

const GoToField = ({ rowId, classNames, marginLeft }: GoToFieldProps): JSX.Element => {
    const containerClasses = concatClassNames(['table-row-go-to-container', classNames])

    return (
        <td
            className={containerClasses}
            style={{ marginLeft }}
            data-testid={`assura-table-row-${rowId}-goTo-field`}
        >
            <i
                data-testid={`assura-table-row-${rowId}-goTo-field-icon`}
                className={`icon assura-right size-16 c-gray300`}
            />
        </td>
    )
}

export default GoToField
