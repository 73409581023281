import './RecommendedProductCardList.css'

import { useEffect, useState } from 'react'

import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import useWindowSize from 'core/services/useWindowSize'
import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'
import AssuraCarouselArrow from 'shared/components/AssuraCarousel/AssuraCarouselArrow'

import RecommendedProductCard from '../RecommendedProductCard/RecommendedProductCard'

interface RecommendedProductCardListProps {
    data: RecommendedProduct[]
}

const RecommendedProductCardList = (props: RecommendedProductCardListProps) => {
    const windowSize = useWindowSize()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentSlides, setCurrentSlides] = useState(0)

    const totalSlides = props.data?.length

    const { data } = props

    const handleBeforeChange: (oldIndex: number, newIndex: number) => void = (oldIndex, newIndex) =>
        setCurrentIndex(newIndex)

    const carouselItem = data?.map((recommendedProduct, index) => (
        <div className="slick-slide-item">
            <RecommendedProductCard
                recommendedProduct={recommendedProduct}
                key={index.toString()}
            />
        </div>
    ))

    useEffect(() => {
        if (windowSize.width && data?.length === totalSlides) {
            if (windowSize.width >= 1344) {
                setCurrentSlides(3)
            } else if (windowSize.width >= 896) {
                setCurrentSlides(2)
            } else {
                setCurrentSlides(1)
            }
        }
    }, [windowSize, data])

    return (
        <AssuraCarousel
            className="insurance-carousel"
            responsive={[
                {
                    breakpoint: 100000,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1344,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 896,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]}
            speed={300}
            nextArrow={
                <AssuraCarouselArrow
                    direction={'right'}
                    customClass="arrow-container arrow-product-right"
                    visible={currentIndex < totalSlides - currentSlides}
                />
            }
            prevArrow={
                <AssuraCarouselArrow
                    direction={'left'}
                    customClass="arrow-container arrow-product-left"
                    visible={currentIndex > 0}
                />
            }
            dotsClass={'slick-dots'}
            beforeChange={handleBeforeChange}
        >
            {carouselItem}
        </AssuraCarousel>
    )
}

export default RecommendedProductCardList
