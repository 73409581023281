/**
 * Profiles pictures are descoped for now
 * It is not implemented in backend
 * Will be done in future
 */
import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { is2faActive } from 'core/api/DoubleAuthentication'
import {
    discountsPersonData,
    productsPersonData,
    productVariantsData,
    summaryResultData
} from 'core/api/FamilyGroup'
import analyticsConstants from 'core/constants/analyticsConstants'
import { EcStatus } from 'core/enums/FamilyGroup'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { prepareInsuranceCombiInfos, updatePageViewParams } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { DiscountsPerson, DiscountsPersonResponse } from 'core/models/familyGroup/DiscountsPerson'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { ProductsPerson, ProductsPersonResponse } from 'core/models/familyGroup/ProductsPerson'
import { ProductVariant } from 'core/models/familyGroup/ProductVariant'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { getCurrentYear } from 'core/utils/dateUtils'

import { fetchDigcliParametersSaga } from '../digcliParameters/digcliParameters.saga'
import { getFamilyMemberIndex } from '../familyMember/familyMember.slice'
import { getConnectedFamilyMember } from '../selectors/getConnectedFamilyMember'
import { fetchLastUpdateSaga } from '../settings/ecPlusMigration/ecPlusMigration.saga'
import {
    fetchDiscountsByMemberAndByYear,
    fetchProductsByMemberAndByYear,
    fetchSummaryByYear,
    fetchSummaryByYearSuccess,
    getFamilySummaries,
    getIsDoubleAuthenticationActive,
    setFamilyProductsLoadingStatus,
    setIsDoubleAuthenticationActive,
    setSummaryLoadingStatus,
    updateDiscountsByMemberAndByYear,
    updateProductsByMemberAndByYear
} from './familySummaries.slice'

// import { fetchProfilePicture, getProfilePictures } from '../familyPictures/familyPictures.slice'
// import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
// import { ProfilePicture } from 'core/models/familyGroup/ProfilePicture'

export function* fetchSummaryByYearAsync(action: ReturnType<typeof fetchSummaryByYear>) {
    try {
        yield put(setSummaryLoadingStatus(LoadingStatusEnum.LOADING))
        yield fork(checkIf2faIsActive)

        // let familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)
        const familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)

        if (!familySummaries[action.payload.year] || action.payload.reload) {
            const summaryResult: SummaryResult = yield call(summaryResultData, action.payload.year)
            yield put(
                fetchSummaryByYearSuccess({
                    year: action.payload.year,
                    summary: summaryResult
                })
            )
            if (action.payload.year === getCurrentYear()) {
                yield spawn(updatePageViewParams, {
                    [analyticsConstants.PARAMS.FAMILY_COMPOSITION]:
                        summaryResult.insuredPersons.length
                })

                yield call(fetchDigcliParametersSaga)

                if (summaryResult.ecStatus === EcStatus.EC) {
                    yield call(fetchLastUpdateSaga)
                }
            }
        }
        yield put(setSummaryLoadingStatus(LoadingStatusEnum.OK))

        if (action.payload.shouldFetchProducts) {
            const familyMemberIndex: number = yield select(getFamilyMemberIndex)
            yield put(
                fetchProductsByMemberAndByYear({
                    year: action.payload.year,
                    index: familyMemberIndex,
                    reload: action.payload.reload
                })
            )

            // const profilePictures: Record<number, ProfilePicture> = yield select(getProfilePictures)
            // if (Object.keys(profilePictures).length === 0) {
            //     familySummaries = yield select(getFamilySummaries)
            //     const promises = familySummaries[action.payload.year]?.insuredPersons.map(
            //         (person: PersonSummary) => {
            //             return put(fetchProfilePicture(person.policyNumber))
            //         }
            //     )
            //     yield all(promises)
            // }
        }
    } catch (e) {
        console.error('fetchSummaryByYearAsync Error', e)
        yield put(setSummaryLoadingStatus(LoadingStatusEnum.ERROR))
        if (action?.payload?.shouldThrow) {
            throw e
        }
    }
}

export function* checkIf2faIsActive() {
    try {
        const isDoubleAuthenticationActive: null | boolean = yield select(
            getIsDoubleAuthenticationActive
        )
        if (Source() === ServicesSource.CUSTOMER_PORTAL && isDoubleAuthenticationActive === null) {
            const doubleAuth: boolean = yield call(is2faActive)
            yield put(setIsDoubleAuthenticationActive(doubleAuth))
        }
    } catch (e) {
        console.error('checkIf2faIsActive Error', e)
    }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function* fetchProductsByMemberAndByYearAsync(
    action: ReturnType<typeof fetchProductsByMemberAndByYear>
) {
    let productsPerson: ProductsPerson = {
        products: [],
        productVariants: [],
        doctor: null,
        previsiaMaxiPolicyNumber: undefined,
        loadingStatus: LoadingStatusEnum.LOADING
    }
    try {
        const familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)
        const familySummariesByYear = familySummaries[action.payload.year]
        const insuredPerson = familySummariesByYear?.insuredPersons[action.payload.index]

        if ((insuredPerson && !insuredPerson?.productsPerson) || action.payload.reload) {
            yield put(
                updateProductsByMemberAndByYear({
                    year: action.payload.year,
                    selectedMemberIndex: action.payload.index,
                    products: productsPerson
                })
            )
            const [productsPersonResponse, productVariants]: [
                ProductsPersonResponse,
                ProductVariant[]
            ] = yield all([
                call(productsPersonData, insuredPerson.userTiersNumber, action.payload.year),
                call(productVariantsData, insuredPerson.policyNumber, action.payload.year)
            ])

            //Fix perioditicy spelling mistake
            productsPersonResponse.products = productsPersonResponse.products.map((item) =>
                Object.entries(item).reduce<ProductPerson>((acc, [key, value]) => {
                    if (key !== 'perioditicy') {
                        return {
                            ...acc,
                            [key]: value
                        }
                    } else {
                        return {
                            ...acc,
                            periodicity: value
                        }
                    }
                }, {} as ProductPerson)
            )

            productsPersonResponse.productVariants = productVariants

            if (productsPersonResponse.previsiaMaxiPolicyNumber) {
                const productVariantsMaxi: ProductVariant[] = yield call(
                    productVariantsData,
                    productsPersonResponse.previsiaMaxiPolicyNumber,
                    action.payload.year
                )
                productsPersonResponse.productVariants = [
                    ...productsPersonResponse.productVariants,
                    ...productVariantsMaxi
                ]
            }

            productsPerson = { ...productsPersonResponse, loadingStatus: LoadingStatusEnum.OK }

            yield put(
                updateProductsByMemberAndByYear({
                    year: action.payload.year,
                    selectedMemberIndex: action.payload.index,
                    products: productsPerson
                })
            )
            const connectedFamilyMember: ConnectedFamilyMember =
                yield select(getConnectedFamilyMember)

            if (
                connectedFamilyMember.policyNumber === insuredPerson?.policyNumber &&
                action.payload.year === getCurrentYear()
            ) {
                yield spawn(prepareInsuranceCombiInfos, productsPerson.products)
            }
        }
    } catch (e) {
        console.error('fetchProductsByMemberAndByYearAsync Error', e)
        productsPerson = {
            ...productsPerson,
            loadingStatus: LoadingStatusEnum.ERROR
        }
        yield put(
            updateProductsByMemberAndByYear({
                year: action.payload.year,
                selectedMemberIndex: action.payload.index,
                products: productsPerson
            })
        )
        if (action?.payload?.shouldThrow) {
            throw e
        }
    }
}

export function* fetchDiscountsByMemberAndByYearSaga(
    action: ReturnType<typeof fetchDiscountsByMemberAndByYear>
) {
    let discountsPerson: DiscountsPerson = {
        products: [],
        combinationDiscountsTotal: 0,
        specialDiscountTotal: 0,
        anticipatedPMTDiscountTotal: 0,
        familyDiscountTotal: 0,
        childrenDiscountTotal: 0,
        tevDiscountTotal: 0,
        collectivityDiscountTotal: 0,
        premiumGrossTotal: 0,
        premiumNetTotal: 0,
        loadingStatus: LoadingStatusEnum.LOADING
    }
    try {
        const familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)
        const familySummariesByYear = familySummaries[action.payload.year]
        const insuredPerson = familySummariesByYear?.insuredPersons[action.payload.index]

        if ((insuredPerson && !insuredPerson?.discountsPerson) || action.payload.reload) {
            yield put(
                updateDiscountsByMemberAndByYear({
                    year: action.payload.year,
                    selectedMemberIndex: action.payload.index,
                    discounts: discountsPerson
                })
            )

            const discountsPersonResponse: DiscountsPersonResponse = yield call(
                discountsPersonData,
                insuredPerson?.policyNumber,
                action.payload.year
            )

            discountsPerson = { ...discountsPersonResponse, loadingStatus: LoadingStatusEnum.OK }

            yield put(
                updateDiscountsByMemberAndByYear({
                    year: action.payload.year,
                    selectedMemberIndex: action.payload.index,
                    discounts: discountsPerson
                })
            )
        }
    } catch (e) {
        console.error('fetchDiscountsByMemberAndByYearSaga Error', e)
        discountsPerson = {
            ...discountsPerson,
            loadingStatus: LoadingStatusEnum.ERROR
        }
        yield put(
            updateDiscountsByMemberAndByYear({
                year: action.payload.year,
                selectedMemberIndex: action.payload.index,
                discounts: discountsPerson
            })
        )
    }
}

export function* fetchFamilyProductsSaga() {
    try {
        const currentYear = getCurrentYear()
        const familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)
        const members = familySummaries[currentYear].insuredPersons

        yield all(
            members.map((_member, index) =>
                call<typeof fetchProductsByMemberAndByYearAsync>(
                    fetchProductsByMemberAndByYearAsync,
                    {
                        type: 'familySummaries/fetchProductsByMemberAndByYear',
                        payload: { year: currentYear, index }
                    }
                )
            )
        )
        yield put(setFamilyProductsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchFamilyProducts Error', e)
        yield put(setFamilyProductsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchSummaryByYearWatcher() {
    yield takeEvery(fetchSummaryByYear.type, fetchSummaryByYearAsync)
}

function* fetchProductsByMemberAndByYearWatcher() {
    yield takeEvery(fetchProductsByMemberAndByYear.type, fetchProductsByMemberAndByYearAsync)
}

function* fetchDiscountsByMemberAndByYearWatcher() {
    yield takeEvery(fetchDiscountsByMemberAndByYear.type, fetchDiscountsByMemberAndByYearSaga)
}

const watchers = [
    fork(fetchSummaryByYearWatcher),
    fork(fetchProductsByMemberAndByYearWatcher),
    fork(fetchDiscountsByMemberAndByYearWatcher)
]

export default watchers
