import './PersonalDataGood.css'

import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getHeadOfFamily } from 'shared/store/general/general.slice'

interface PersonalDataGoodProps {
    isPublic: boolean
    isHeadOfFamily: boolean
}

const PersonalDataGood = ({ isPublic, isHeadOfFamily }: PersonalDataGoodProps): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { isXL } = useWindowSize()

    const goToAccountManagement = () => {
        navigate(navigationConstants.SETTINGS.url)
    }

    const isPublicAndNoHead = isPublic && !isHeadOfFamily
    const headOfFamily = useSelector(getHeadOfFamily)

    return (
        <Col
            xs={12}
            md={6}
            xl={{ span: isPublic ? 5 : 3, offset: 1 }}
            className="personal-data-footer-good"
            style={{ marginTop: isXL ? 0 : '56px' }}
        >
            <div>
                <div className="titleSmall">{t('PERSONAL_DATA.ADDRESS_GOOD_TITLE')}</div>
                {!isPublic && (
                    <div className="paragraphSmallLight m-top-24">
                        {t('PERSONAL_DATA.GOOD_TO_KNOW_FINANCIAL')}
                    </div>
                )}
                {isPublicAndNoHead ? (
                    <>
                        <div className="paragraphSmallLight m-top-24">
                            {t('PERSONAL_DATA.ADDRESS_NO_HEAD_OF_FAMILY', {
                                firstName: headOfFamily?.firstname ?? ''
                            })}
                        </div>
                        <div className="horizontal-separator bg-gray100"></div>
                        <div className="paragraphMedium">{t('COMMON.GOOD_TO_KNOW')}</div>
                        <div className="paragraphSmallLight m-top-24 m-bottom-24">
                            {t('PERSONAL_DATA.ADDRESS_DO_YOU_KNOW')}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="paragraphSmallLight m-top-24">
                            {t('PERSONAL_DATA.ADDRESS_GOOD_DETAIL')}
                        </div>
                    </>
                )}
                {!isPublic && (
                    <>
                        <div className="horizontal-separator bg-gray100"></div>
                        <div className="link">
                            <AssuraIconButton
                                id="personal-data-manage-account-link"
                                icon="assura-house"
                                variant="primary"
                                size="medium"
                                onClick={goToAccountManagement}
                                label={t('PERSONAL_DATA.MANAGE_ACCOUNT')}
                            />
                        </div>
                    </>
                )}
            </div>
        </Col>
    )
}

export default PersonalDataGood
