type IconProps = {
    name: IconNamesEnum
    size: IconSizesEnum
    color: ColorsEnum
    dataId: string
}

const Icon = ({ ...props }: IconProps) => (
    <i
        className={IconClassName(props.name, props.size)}
        style={{ color: props.color }}
        data-testid={props.dataId}
    />
)
export default Icon

// Helper Method
export const isIcon = (property: IconNamesEnum): boolean =>
    property && !!Object.values(IconNamesEnum).find((o) => o === property)

// Icons enums
export enum IconNamesEnum {
    close = 'close',
    plus = 'plus',
    minus = 'minus',
    search = 'search',
    kebab = 'kebab',
    points = 'points',
    back = 'back',
    next = 'next',
    edit = 'edit',
    delete = 'delete',
    upload = 'upload',
    download = 'download',
    list = 'list',
    down = 'down',
    up = 'up',
    left = 'left',
    right = 'right',
    infoCircle = 'info-circle',
    helpCircle = 'help-circle',
    warningCircle = 'warning-circle',
    error = 'error',
    checkCircle = 'check-circle',
    add = 'add',
    clock = 'clock',
    drag = 'drag',
    settings = 'settings',
    logout = 'logout',
    calendar = 'calendar',
    print = 'print',
    menu = 'menu',
    profile = 'profile',
    home = 'home',
    pdf = 'pdf',
    table = 'table',
    discount = 'discount',
    photo = 'photo',
    flash = 'flash',
    send = 'send',
    send2 = 'send2',
    lock = 'lock',
    passcode = 'passcode',
    notif = 'notif',
    doc = 'doc',
    coverage = 'coverage',
    check = 'check',
    doubleCheck = 'double-check',
    visible = 'visible',
    notVisible = 'not-visible',
    draft = 'draft',
    webview = 'webview',
    externalLink = 'external-link',
    slider = 'slider',
    profileWaiting = 'profile-waiting',
    map = 'map',
    localization = 'localization',
    warning = 'warning',
    loader2 = 'loader2',
    travel = 'travel',
    tooth = 'tooth',
    child = 'child',
    plant = 'plant',
    hospital = 'hospital',
    complement = 'complement',
    capital = 'capital',
    birth = 'birth',
    accident = 'accident',
    house = 'house',
    phone = 'phone',
    mail = 'mail',
    mailCheck = 'mail-check',
    copy = 'copy',
    refresh = 'refresh',
    login = 'login',
    bvr = 'bvr',
    securisation = 'securisation',
    light = 'light',
    message = 'message',
    pending = 'pending',
    reception = 'reception',
    crop = 'crop',
    attachment = 'attachment',
    zoom = 'zoom',
    loader = 'loader',
    opinion = 'opinion',
    order = 'order',
    archive = 'archive',
    share = 'share',
    performance = 'performance',
    star = 'star',
    card = 'card',
    frame = 'frame',
    zoomIn = 'zoom-in',
    submission = 'submission',
    sendDoc = 'send-doc',
    balance = 'balance',
    share2 = 'share2',
    wallet = 'wallet',
    statement = 'statement',
    money = 'money',
    draft2 = 'draft2',
    pharmacy = 'pharmacy',
    doctor = 'doctor',
    location = 'location',
    docMoney = 'doc-money',
    scan = 'scan',
    scanAttachment = 'scan-attachment',
    inProgress = 'in-progress',
    partiallyTreated = 'partially-treated',
    mailError = 'mail-error',
    profileError = 'profile-error',
    profileValidated = 'profile-validated',
    zoomOut = 'zoom-out'
}
// start with the number of size so when we search for it type number
export enum IconSizesEnum {
    Size4 = 4,
    Size8 = 8,
    Size12 = 12,
    Size16 = 16,
    Size20 = 20,
    Size24 = 24,
    Size28 = 28,
    Size32 = 32,
    Size36 = 36,
    Size40 = 40
}
// return Assura-Bootstrap classname for a given icon.
export const IconClassName = (name: IconNamesEnum, size: IconSizesEnum): string =>
    `icon size-${size} assura-${name}`

export enum ColorsEnum {
    black = '#000000',
    gray700 = '#4D4D4D',
    gray500 = '#767676',
    gray300 = '#BDBDBD',
    gray100 = '#E5E5E5',
    gray50 = '#F5F5F5',
    gray20 = '#FAFAFA',
    white = '#FFFFFF',
    error = '#E4100F',
    errorBg = '#FFF6F6',
    success = '#00C14E',
    alert = '#EBE61E',
    hoverFocus = '#B81901',
    lightPink = '#FFD2D0',
    redAssura = '#E62D0A',
    successBackground = '#F2FCF6'
}
