import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'

import { createSelector } from '@reduxjs/toolkit'

import { getEnveloppesForDataTable } from './getEnveloppesForDataTable'
import { getSelectedFamilyMember } from './getSelectedFamilyMember'

export interface EnveloppesForLastSends {
    lastSends: EnveloppeRow[]
    totalEnveloppes: number
}

export const getEnveloppesForLastSends = createSelector(
    [getSelectedFamilyMember, getEnveloppesForDataTable],
    (member, list): EnveloppesForLastSends => {
        const memberList = list.filter(
            (enveloppe) => enveloppe.policyNumber === member.policyNumber
        )

        return {
            lastSends: memberList
                .filter((enveloppe) => enveloppe.status !== EnveloppeStatus.Processed)
                .slice(0, 3),
            totalEnveloppes: memberList.length
        }
    }
)
