import { useTranslation } from 'react-i18next'

import { PaymentStatusEnum } from 'core/enums/Documents'
import { ToastType } from 'core/enums/ToastType'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { differenceInDays } from 'date-fns'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import ReadStatusField from 'shared/components/AssuraTable/Fields/ReadStatusField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'
import { useAppDispatch } from 'shared/store/store'
import { setToastInfo } from 'shared/store/toast/toast.slice'

interface InvoicesTableRowMobileProps {
    row: DocumentRow
}

const InvoicesTableRowMobile = ({ row }: InvoicesTableRowMobileProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const unreadClass = row.isRead ? 'labelExtraSmall' : 'labelExtraSmallMedium c-black'
    const unreadLabelClass = row.isRead ? 'label' : 'labelMedium c-black'

    const below30 =
        row.deadLineDate && !row.fullyPaid
            ? differenceInDays(new Date(row.deadLineDate), new Date()) <= 30
            : false

    const copyBVR = () => {
        const bvr = row.bvrReference
        if (bvr) {
            navigator.clipboard.writeText(bvr)
            dispatch(
                setToastInfo({
                    message: t('STATEMENTS.COPIED_BVR'),
                    type: ToastType.SUCCESS
                })
            )
        }
    }

    const handleSubRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
    }

    return (
        <>
            <td className="invoices-table-row-content">
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8">
                            <ReadStatusField isRead={row.isRead} rowId={row.documentId} />
                            <DateField
                                date={row.creationDate}
                                rowId={row.documentId}
                                classNames={unreadClass}
                                isMobileStyle
                            />
                            {row.financialStatusData && (
                                <>
                                    <Separator />
                                    <StatusField
                                        status={row.financialStatusData.translationKey}
                                        statusIcon={row.financialStatusData.icon}
                                        statusColor={row.financialStatusData.color}
                                        rowId={row.documentId}
                                        labelClassNames={unreadClass}
                                        isMobileStyle
                                    />
                                </>
                            )}
                            <Separator />
                            <TextField
                                label={row.insuredPersonName}
                                classNames={unreadClass}
                                rowId={row.documentId}
                                fieldId="insured-person"
                            />
                        </tr>
                        <tr>
                            <TextField
                                label={row.documentName}
                                classNames={unreadLabelClass}
                                rowId={row.documentId}
                                fieldId="documentName"
                            />
                            <GoToField
                                rowId={row.documentId}
                                classNames="invoices-table-row-go-to"
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            {row.financialStatusData?.translationKey === PaymentStatusEnum.UNPAID &&
                !row.isMigrated && (
                    <td
                        className="invoices-table-row-content border-top-solid-width-1 btc-gray100"
                        style={{ cursor: 'default' }}
                        onClick={handleSubRowClick}
                    >
                        <table className="w-100">
                            <tbody>
                                <tr className="d-flex">
                                    <TextField
                                        label={row.valueAmount > 0 ? row.inFavour : ''}
                                        rowId={row.documentId}
                                        classNames="flex-1 labelExtraSmall c-gray500"
                                        fieldId="in-favour"
                                        translate={row.valueAmount > 0}
                                    />
                                    <TextField
                                        label={
                                            row.valueAmount > 0
                                                ? `CHF ${row.valueAmount.toFixed(2)}`
                                                : ''
                                        }
                                        rowId={row.documentId}
                                        classNames={`flex-1 text-right labelExtraSmall${
                                            !row.isRead ? ' c-black' : ''
                                        }`}
                                        fieldId="amount"
                                    />
                                </tr>
                                <tr className="d-flex m-top-8">
                                    <TextField
                                        label={t('INBOX.INVOICE_VALUE_TYPE_STATUS_LABEL')}
                                        rowId={row.documentId}
                                        classNames="flex-1 labelExtraSmall c-gray500"
                                        fieldId="status-label"
                                        translate={row.valueAmount > 0}
                                    />
                                    <td
                                        className={`flex-1 text-right labelExtraSmall${
                                            !row.isRead ? ' c-black' : ' c-gray700'
                                        }`}
                                    >
                                        <span>{`${t('INBOX.INVOICE_DEADLINE_BIS')} `}</span>
                                        <span
                                            className={below30 ? 'c-primary' : undefined}
                                            data-testid={`assura-table-row-${row.documentId}-amount-field-date`}
                                        >
                                            {formatDate(row.deadLineDate ?? '')}
                                        </span>
                                    </td>
                                </tr>
                                <tr className="d-flex d-flex justify-content-end m-top-8">
                                    <td
                                        onClick={copyBVR}
                                        className="d-flex justify-content-end cursor-pointer c-primary"
                                    >
                                        <i
                                            className="icon assura-copy size-16"
                                            style={{ marginRight: '4px' }}
                                        />
                                        <span
                                            className="labelExtraSmall c-primary responsive-statement-detail-bvr"
                                            data-testid={`assura-table-row-${row.documentId}-bvr-copy-field`}
                                        >
                                            {t('INBOX.COPY_BVR')}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                )}
        </>
    )
}

export default InvoicesTableRowMobile
