/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AccidentDetailsModel, WoundModel } from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import { dateValidation } from 'modules/services/aventura/helpers/validations'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'

import { badDateSequenceValidation, isTrue } from '../../../../core/helpers/FieldValidationHelper'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'WOUND'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const woundFormFieldNameList: Array<keyof WoundModel> = [
    'bodyPartInjured',
    'injuryNature',
    'firstDoctor',
    'processStartDate',
    'processOver',
    'processDoctor',
    'hospitalization',
    'stillHospitalized',
    'hospitalizationStartDate',
    'hospitalizationEndDate'
]

export interface WoundFormProps {
    id: number
}

/**
 * Step3 description:
 * WOUND
 *
 */
const WoundForm = ({ id }: WoundFormProps): JSX.Element => {
    const currentForm = useRef(null)

    const { getValues, setValue, trigger } = useFormContext<WoundModel & AccidentDetailsModel>()

    const { TextareaInput, RadioGroup, DatePicker, StartEndDateGroup } = useCustomControls(formName)
    const { isMobile, isMD } = useWindowSize()
    const { t } = useTranslation()

    const { processOver, hospitalization, stillHospitalized, accidentLocation } = getValues()

    const processOverYes = isTrue(getValues('processOver'), 'processOver')()
    const hospitalizationYes = isTrue(getValues('hospitalization'), 'hospitalization')()
    const stillHospitalizedYes = isTrue(getValues('stillHospitalized'), 'stillHospitalized')()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        if (processOverYes === undefined || processOverYes === true) {
            setValue('processDoctor', undefined)
        }
        if (!hospitalizationYes) {
            setValue('stillHospitalized', undefined)
            setValue('hospitalizationStartDate', undefined)
            setValue('hospitalizationEndDate', undefined)
        }
        if (stillHospitalizedYes) {
            setValue('hospitalizationEndDate', undefined)
        }
    }, [processOverYes, hospitalizationYes, stillHospitalizedYes])

    useEffect(() => {
        setValue('accidentLocation', accidentLocation)
    }, [accidentLocation])

    return (
        <div id={`${id}`} className="step-frame" ref={currentForm}>
            <FormBlock
                titleTestId={'wounds'}
                title={t(`${serviceName}.${formName}.WOUNDS`)}
                childrenMargin="m-bottom-48"
            >
                {TextareaInput(
                    'bodyPartInjured',
                    'IMPACTED_BODY_PARTS',
                    { mandatory: true, maxLength: 100 },
                    false
                )}

                {TextareaInput(
                    'injuryNature',
                    'INJURY_TYPE',
                    { mandatory: true, maxLength: 100 },
                    false
                )}

                {TextareaInput(
                    'firstDoctor',
                    'FIRST_CONTACTED_MEDICAL_SERVICE',
                    { mandatory: true, maxLength: 400 },
                    false
                )}

                {DatePicker(
                    'processStartDate',
                    'TREATMENT_START_DATE',
                    {
                        mandatory: true,
                        validate: (value: string | Date) => {
                            return badDateSequenceValidation(
                                getValues('accidentDate') as Date,
                                value as Date,
                                `DATE_CANNOT_BE_EARLIER_THAN_ACCIDENT_DATE`
                            )
                        }
                    },
                    !(isMobile || isMD),
                    () => trigger('processStartDate'),
                    'paragraphMedium'
                )}

                {RadioGroup(
                    'TREATMENT_COMPLETED_LABEL',
                    'processOver',
                    {
                        mandatory: true
                    },
                    () => trigger('processOver')
                )}

                {processOver === 'processOverNo' &&
                    TextareaInput(
                        'processDoctor',
                        'REMAINING_TREATMENT',
                        {
                            mandatory: processOver === 'processOverNo',
                            maxLength: 400
                        },
                        false
                    )}
            </FormBlock>

            <FormBlock
                titleTestId={'hospitalisations'}
                title={t(`${serviceName}.${formName}.HOSPITALIZATION`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom='m-bottom-32'
            >
                {RadioGroup(
                    'HAVE_YOU_BEEN_HOSPITALIZED_LABEL',
                    'hospitalization',
                    {
                        mandatory: true
                    },
                    () => trigger('hospitalization')
                )}

                {hospitalization === 'hospitalizationYes' &&
                    RadioGroup(
                        'ARE_YOU_STILL_HOSPITALIZED_LABEL',
                        'stillHospitalized',
                        {
                            mandatory: true
                        },
                        () => trigger('stillHospitalized')
                    )}

                {hospitalization === 'hospitalizationYes' &&
                    stillHospitalized !== undefined &&
                    StartEndDateGroup(
                        'hospitalization',
                        'HOSPITALIZATION_LABEL',
                        'HOSPITALIZATION',
                        {
                            mandatory: stillHospitalized !== undefined,
                            validate: (value: string | Date) => {
                                if (stillHospitalized === 'stillHospitalizedNo')
                                    return (
                                        badDateSequenceValidation(
                                            value as Date,
                                            getValues('hospitalizationEndDate') as Date
                                        ) &&
                                        badDateSequenceValidation(
                                            getValues('accidentDate') as Date,
                                            value as Date,
                                            `ACCIDENT_REPORT.START_HOSPITALIZATION_DATE_CANNOT_BE_EARLIER_THAN_ACCIDENT_DATE`
                                        )
                                    )
                                else stillHospitalized !== undefined && dateValidation(value)
                                return true
                            }
                        },
                        {
                            mandatory: stillHospitalized === 'stillHospitalizedNo',
                            validate: (value: string | Date) =>
                                badDateSequenceValidation(
                                    getValues('hospitalizationStartDate') as Date,
                                    value as Date
                                )
                        },
                        true,
                        stillHospitalized === 'stillHospitalizedNo',
                    )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}
export default WoundForm
