import './RequestsEcLink.css'

import { RequestEcLink } from 'core/models/services/ServicesHub'
import { useTranslation } from 'react-i18next'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'

interface RequestsEcLinkProps {
    link: RequestEcLink
    dataTestId?: string
    callback?: (link_url: string) => void
}

const RequestsEcLink = ({
    link,
    dataTestId = 'services-hub-request-link-',
    callback
}: RequestsEcLinkProps): JSX.Element => {
    const { t } = useTranslation()

    const { ecLinkId, title, ...rest } = link

    return (
        <AssuraEcLink
            ecLinkId={ecLinkId}
            testId={`${dataTestId}${ecLinkId}`}
            callback={callback}
            {...rest}
        >
            <div className="requests-list-container-item">
                <span className="labelSmall flex-1" data-testid={`${dataTestId}${ecLinkId}-label`}>
                    {t(title)}
                </span>
                <i className="icon assura-right c-primary size-16" />
            </div>
        </AssuraEcLink>
    )
}

export default RequestsEcLink
