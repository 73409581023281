import './Header.css'

import { useTranslation } from 'react-i18next'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface HeaderProps {
    switchCard: () => void
}
const Header = ({ switchCard }: HeaderProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="lamal-specification-card-header">
            <span className="headline flex-1">
                {t('COVERAGE.LAMAL_CONTRACT_SPECIFICATIONS_TITLE')}
            </span>
            <AssuraIconButton
                id="lamal-specification-card-header-switch-recto"
                icon="assura-next"
                variant="primary"
                size="medium"
                onClick={switchCard}
                classNames="lamal-specification-card-header-switch-recto"
                label={t('INSURANCES.SPECIFICATIONS_CARD_HEADER_SWITCH')}
            />
        </div>
    )
}

export default Header
