import { HospitalizationAnnouncementForm } from 'core/models/services/report/HospitalizationAnnouncementForm'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

interface ReportState {
    hospStartDate?: string
}

const INITIAL_STATE_REPORT: ReportState = {
    hospStartDate: undefined
}

export const reportSlice = createSlice({
    name: 'report',
    initialState: INITIAL_STATE_REPORT,
    reducers: {
        setHospStartDate: (state, action: PayloadAction<string>) => {
            state.hospStartDate = action.payload
        },
        onSubmitHospitalizationAnnouncement: (
            state,
            _action: PayloadAction<HospitalizationAnnouncementForm>
        ) => {
            return state
        }
    }
})

export const { setHospStartDate, onSubmitHospitalizationAnnouncement } = reportSlice.actions

export const getHospStartDate: Selector<RootState, Date | undefined> = (state) =>
    state.servicesReport.hospStartDate ? new Date(state.servicesReport.hospStartDate) : undefined

export default reportSlice.reducer
