const rootPath = 'https://www.assura.ch/problems/'

export const assuraProblems = {
    PasswordIncorrect: `${rootPath}PasswordIncorrect`,
    PasswordLength: `${rootPath}PasswordLength`,
    PasswordFormat: `${rootPath}PasswordFormat`,
    NotSamePassword: `${rootPath}NotSamePassword`,
    UsernameTaken: `${rootPath}UsernameTaken`,
    UsernameLength: `${rootPath}UsernameLength`
}
