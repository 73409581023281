import './NewMemberInfos.css'

import { Fragment, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NewMemberDatas } from 'core/models/services/familyGroup/AddMember'
import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'
import { AssuraNumberInputRHF } from 'modules/services/components/NumberInputRHF/NumberInputRHF'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

import { useAddMemberContext } from '../pages/AddMember'

const NewMemberInfos = (): JSX.Element => {
    const { isMobile, isXL } = useWindowSize()
    const { t } = useTranslation()

    const names: (keyof NewMemberDatas)[] = ['firstName', 'lastName', 'policyNumber']

    const labels: Record<keyof NewMemberDatas, string> = {
        firstName: 'FAMILY_GROUP.BASIC_INFO_EDIT_FIRST_NAME_LABEL',
        lastName: 'FAMILY_GROUP.BASIC_INFO_EDIT_LAST_NAME_LABEL',
        policyNumber: 'LOGIN.POLICE_NUMBER_LABEL'
    }

    const [focus, setFocus] = useState<keyof NewMemberDatas | null>(null)

    const {
        setValue,
        getValues,
        formState: { errors, isSubmitted },
        register
    } = useFormContext()

    const { updateValue, selectedMember, removeMember, members } = useAddMemberContext()

    const defaultRules = {
        required: { value: true, message: t('COMMON.MANDATORY_FIELD') }
    }

    const handleResetAction = (name: keyof NewMemberDatas) => {
        setValue(name, '', {
            shouldValidate: isSubmitted
        })
    }

    const handleOnBlur = (name: keyof NewMemberDatas) => {
        setFocus(null)
        const currentValue = getValues(name)
        updateValue(name, currentValue)
    }

    const handleOnDeleteMember = () => {
        removeMember()
    }

    const getRules = (name: keyof NewMemberDatas) => {
        const min = name === 'policyNumber' ? 4 : 2
        const max = name === 'policyNumber' ? 8 : 30

        const errorMessage = t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
            min,
            max
        })

        return {
            ...defaultRules,
            minLength: { value: min, message: errorMessage },
            maxLength: { value: max, message: errorMessage }
        }
    }

    const getComponent = (name: keyof NewMemberDatas) => {
        const commonProps = {
            id: `services-add-member-${name}`,
            name,
            inline: isXL,
            labelSize: 4,
            label: t(labels[name]),
            onBlur: () => handleOnBlur(name),
            onFocus: () => setFocus(name),
            hasButton: focus === name,
            onButtonClick: () => handleResetAction(name),
            iconClass: 'icon assura-close size-24',
            rules: getRules(name),
            isInvalid: Boolean(errors[name]),
            error: errors[name]?.message as string,
            placeHolder: t(labels[name]),
            register: register
        }
        return name === 'policyNumber' ? (
            <AssuraNumberInputRHF {...commonProps} maxLength={8} />
        ) : (
            <AssuraFormInputRHF {...commonProps} />
        )
    }

    useEffect(() => {
        if (selectedMember) {
            names.forEach((name) => {
                setValue(name, selectedMember[name], {
                    shouldValidate: isSubmitted
                })
            })
        }
    }, [selectedMember])

    return (
        <div className="d-flex flex-column bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <div className="d-flex justify-content-between align-items-center m-bottom-32">
                <div className="paragraphMedium">{t('PERSONAL_DATA.CONTACT_TITLE')}</div>
                {!isMobile && members.length > 1 && (
                    <AssuraIconButton
                        id="services-add-member-delete-member"
                        icon="assura-delete"
                        variant="primary"
                        size="medium"
                        onClick={handleOnDeleteMember}
                        label={t('COMMON.DELETE')}
                    />
                )}
            </div>
            <div className="dflex flex-column" id="new-member-infos-container">
                {names.map((name) => (
                    <Fragment key={`services-add-member-${name}`}>{getComponent(name)}</Fragment>
                ))}
            </div>
            {isMobile && members.length > 1 && (
                <AssuraIconButton
                    id="services-add-member-delete-member"
                    icon="assura-delete"
                    variant="primary"
                    size="medium"
                    onClick={handleOnDeleteMember}
                    label={t('COMMON.DELETE')}
                    classNames="align-self-start m-top-32"
                />
            )}
        </div>
    )
}

export default NewMemberInfos
