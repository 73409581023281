import { PaymentStatusEnum } from 'core/enums/Documents'
import { FinancialStatusData } from 'core/models/documents/DocumentRow'

export const FinancialStatusPaid: FinancialStatusData = {
    translationKey: PaymentStatusEnum.PAID,
    icon: 'assura-check-circle',
    color: 'c-success'
}

export const FinancialStatusUnpaid: FinancialStatusData = {
    translationKey: PaymentStatusEnum.UNPAID,
    icon: 'assura-error',
    color: 'c-error'
}

export const FinancialStatusCanceled: FinancialStatusData = {
    translationKey: PaymentStatusEnum.CANCELED,
    icon: 'assura-error',
    color: 'c-gray500'
}

export const getFinancialStatusData = (
    canceled: boolean,
    fullyPaid: boolean
): FinancialStatusData => {
    if (canceled) return FinancialStatusCanceled
    return fullyPaid ? FinancialStatusPaid : FinancialStatusUnpaid
}
