import './AppLoadAndError.css'

import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import assuraLogo from 'shared/assets/images/assuraLogo.png'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import { logout } from 'shared/store/authentication/authentication.slice'
import { getLang, initData } from 'shared/store/general/general.slice'

export const loadAndErrorTraductions: Record<string, Record<string, string>> = {
    errorLine1: {
        fr: 'Une erreur est survenue.',
        de: 'Ein Fehler ist aufgetreten.',
        it: 'Si è verificato un errore.',
        en: 'An error has occurred.'
    },
    errorLine2: {
        fr: 'Veuillez réessayer.',
        de: 'Bitte versuchen Sie es erneut.',
        it: 'Riprovi.',
        en: 'Please try again.'
    },
    backToLogin: {
        fr: 'Retour à la page de connexion',
        de: 'Zurück zur Anmeldeseite',
        it: 'Torna alla pagina di accesso',
        en: 'Back to login page'
    }
}
interface AppLoadAndErrorProps {
    status: LoadingStatusEnum
    children: React.ReactNode | React.ReactNode[]
}

const AppLoadAndError = ({ status, children }: AppLoadAndErrorProps): JSX.Element => {
    const dispatch = useDispatch()
    const lang = useSelector(getLang)

    if (status !== LoadingStatusEnum.OK) {
        return (
            <div className="d-flex flex-1 flex-column justify-content-center align-items-center p-top-32 p-bottom-32">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        data-testid="app-assura-logo"
                        src={assuraLogo}
                        width="204"
                        height="32"
                        alt="app-assura-logo"
                    />
                    <div className="m-top-64 m-bottom-64">
                        {status === LoadingStatusEnum.LOADING ? (
                            <ActivityIndicator />
                        ) : (
                            <>
                                <div
                                    data-testid="app-error-indicator"
                                    className="app-error-message-container"
                                    onClick={() => dispatch(initData({ reload: true }))}
                                >
                                    <i className="icon assura-refresh size-32 app-error-refresh-button c-primary" />
                                </div>
                                <div
                                    className="text-center paragraphSmall"
                                    data-testid="app-error-message-1"
                                >
                                    {loadAndErrorTraductions.errorLine1[lang]}
                                </div>
                                <div
                                    className="text-center paragraphSmall"
                                    data-testid="app-error-message-2"
                                >
                                    {loadAndErrorTraductions.errorLine2[lang]}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        data-testid="app-back-to-login"
                        className="labelExtraSmall assura-link"
                        onClick={() => dispatch(logout())}
                    >
                        {loadAndErrorTraductions.backToLogin[lang]}
                    </div>
                </div>
            </div>
        )
    } else {
        return <>{children}</>
    }
}

export default AppLoadAndError
