import { useAccordionToggle } from 'react-bootstrap'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface CustomToggleProps {
    eventKey: string
    callback: () => void
    icon: string
    label: string
}
const GoodToKnowAccordionToggle = ({
    eventKey,
    callback,
    icon,
    label
}: CustomToggleProps): JSX.Element => {
    const onClick = useAccordionToggle(eventKey, callback)

    return (
        <div className="d-flex justify-content-start m-top-24">
            <AssuraIconButton
                id="good-to-know-toggle"
                icon={icon}
                variant="primary"
                size="medium"
                onClick={onClick}
                label={label}
            />
        </div>
    )
}

export default GoodToKnowAccordionToggle
