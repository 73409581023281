import './NavBarProfileDropdown.css'

import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useWindowSize from 'core/services/useWindowSize'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'

import NavBarProfileContent from './NavBarProfileContent'

const NavBarProfileDropdown = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMD, isXL } = useWindowSize()
    const connectedFamilyMember = useSelector(getConnectedFamilyMember)

    return (
        <Dropdown>
            <Dropdown.Toggle
                className={`navbar-profile-selector-toggle no-border ${isXL ? '' : 'no-arrow'} ${
                    isMD && 'd-flex flex-column align-items-center'
                }`}
                data-testid="navbar-profile-toggle"
            >
                <>
                    <i
                        className={`icon assura-client-space c-black size-${isXL ? '16' : '24'}`}
                        data-testid="navbar-profile-toggle-icon"
                    />
                    {isMD && (
                        <span
                            className="labelExtraSmallMedium m-top-4 c-black"
                            data-testid="navbar-profile-toggle-tablet-label"
                        >
                            {t('NAVIGATION_BAR.SETTINGS_TABLET')}
                        </span>
                    )}
                    {isXL && (
                        <span
                            className="labelExtraSmall m-left-4"
                            data-testid="navbar-profile-toggle-name"
                        >
                            {`${connectedFamilyMember?.firstName} ${connectedFamilyMember?.lastName}`}
                        </span>
                    )}
                </>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <NavBarProfileContent />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default NavBarProfileDropdown
