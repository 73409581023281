import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ProductTypeCmsEnum } from 'core/enums/Product'
import { getCostsLabels, getReceiptCostsValues } from 'core/helpers/ClaimsSettlementPanelHelper'
import {
    ClaimsSettlementReceipt,
    ClaimsSettlementReceiptLcaProductAmount
} from 'core/models/documents/ClaimsSettlementReceipt'
import { formatNumber } from 'core/utils/TextUtils'
import { getProducts } from 'shared/store/selectors/getProducts'

import ClaimsSettlementReceiptPanelAmountCard from './ClaimsSettlementReceiptPanelAmountCard'

interface ClaimsSettlementReceiptPanelCoveredSectionProps {
    selectedClaimsSettlementReceipt: ClaimsSettlementReceipt
}

const ClaimsSettlementReceiptPanelCoveredSection = ({
    selectedClaimsSettlementReceipt
}: ClaimsSettlementReceiptPanelCoveredSectionProps): JSX.Element => {
    const { t } = useTranslation()
    const products = useSelector(getProducts)
    const costsLabels = getCostsLabels(selectedClaimsSettlementReceipt.type, true)
    const receiptCostsValues = getReceiptCostsValues(costsLabels, selectedClaimsSettlementReceipt)

    const showCoveredLamal =
        selectedClaimsSettlementReceipt.laMal &&
        selectedClaimsSettlementReceipt.costsCovered &&
        selectedClaimsSettlementReceipt.costsCovered.laMal > 0
    const coveredLcaList = selectedClaimsSettlementReceipt.costsCovered?.lca
    const hasCoveredLca = coveredLcaList && coveredLcaList.length > 0

    const getLamalCoveredCard = (): JSX.Element => {
        const selectedLamal = Object.values(products).find(
            (product) =>
                product.type === ProductTypeCmsEnum.LAMAL &&
                product.insuranceId === selectedClaimsSettlementReceipt.laMal?.code
        )
        const selectedLamalTitle = `${t('COMMON.LAMAL_TEXT')} ${selectedLamal?.title}`
        return (
            <ClaimsSettlementReceiptPanelAmountCard
                title={selectedLamalTitle}
                price={selectedClaimsSettlementReceipt?.costsCovered?.laMal as number} //validated through showCoveredLamal
                isRefund
            />
        )
    }

    const getLcaCoveredCards = (): JSX.Element[] => {
        return (coveredLcaList as ClaimsSettlementReceiptLcaProductAmount[]).map((lca, index) => {
            const selectedLca = Object.values(products).find(
                (product) =>
                    product.type === ProductTypeCmsEnum.LCA && product.insuranceId === lca.code
            )
            return (
                <ClaimsSettlementReceiptPanelAmountCard
                    key={index.toString()}
                    title={selectedLca?.title}
                    price={lca.amount}
                    isRefund
                />
            )
        })
    }

    return (
        <div>
            {receiptCostsValues.assuraCosts.value !== undefined && (
                <div className="claims-settlement-panel-adaptive-padding-horizontal d-flex align-items-center">
                    <div className="headlineSmallMedium flex-1">
                        {t(receiptCostsValues.assuraCosts.label)}
                    </div>
                    <div
                        className="labelSmall m-left-8"
                        data-testid="claims-settlement-receipt-panel-covered-price"
                    >
                        {t('COMMON.PRICE', {
                            price: formatNumber(receiptCostsValues.assuraCosts.value)
                        })}
                    </div>
                </div>
            )}
            {(showCoveredLamal || hasCoveredLca) && (
                <div className="m-top-24" data-testid="claims-settlement-receipt-covered-section">
                    {showCoveredLamal && getLamalCoveredCard()}
                    {hasCoveredLca && getLcaCoveredCards()}
                </div>
            )}
        </div>
    )
}

export default ClaimsSettlementReceiptPanelCoveredSection
