import './Search.css'

import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { AppSearchSectionKeyEnum } from 'core/models/AppSearch'
import useWindowSize from 'core/services/useWindowSize'
import AppSearchBar from 'shared/components/AppSearch/AppSearchBar'
import AppSearchNoResult from 'shared/components/AppSearch/AppSearchNoResult'
import AppSearchResultItem from 'shared/components/AppSearch/AppSearchResultItem'
import AppSearchSectionBlock from 'shared/components/AppSearch/AppSearchSectionBlock'
import AppSearchSuggestions from 'shared/components/AppSearch/AppSearchSuggestions'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getSearchText, getUserText, setUserText } from 'shared/store/appSearch/appSearch.slice'
import { getAppSearchFilteredSections } from 'shared/store/selectors/getAppSearchFilteredSections'
import { getAppSearchResultsCount } from 'shared/store/selectors/getAppSearchResultsCount'
import { useAppDispatch } from 'shared/store/store'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

const Search = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const dispatch = useAppDispatch()

    const userText = useSelector(getUserText)
    const searchText = useSelector(getSearchText)
    const isAllowedToSearch = userText.length === 0 || userText.trim().length > 2
    const shouldDisplayResults = searchText.length > 2

    const sections = useSelector(getAppSearchFilteredSections)
    const searchResultsCount = useSelector(getAppSearchResultsCount)

    const handleSelectionCallback = () => {
        dispatch(
            setUserText({
                text: '',
                shouldTriggerSearch: true
            })
        )
    }

    const triggerSearch = () => {
        isAllowedToSearch &&
            dispatch(
                setUserText({
                    text: userText,
                    shouldTriggerSearch: true
                })
            )
    }

    return (
        <>
            <FullScreenContainer color="gray20">
                <Container className="p-top-56 p-bottom-56">
                    <div className="title break-word m-bottom-24" data-testid="search-title">
                        {t('SEARCH.PAGE_TITLE')}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="search-input-container">
                            <AppSearchBar handleEnterKeyPress={triggerSearch} />
                        </div>
                        <div
                            id="app-search-input-cta-container"
                            className="d-flex align-items-center justify-content-center"
                        >
                            <AssuraButton
                                text={isMobile ? '' : t('COMMON.SEARCH')}
                                id="app-search-input-cta"
                                variant="primary"
                                onClick={triggerSearch}
                                classNames="search-input-cta"
                                icon={
                                    isMobile
                                        ? {
                                              name: 'assura-search',
                                              size: 24
                                          }
                                        : undefined
                                }
                                iconPosition="left"
                            />
                        </div>
                    </div>
                    {shouldDisplayResults && searchResultsCount > 0 && (
                        <div className="paragraph m-top-24" data-testid="app-search-results-count">
                            {t('SERVICES.RESULTS_NUMBER', { results: searchResultsCount })}
                        </div>
                    )}
                </Container>
            </FullScreenContainer>
            <Container id="search-sections-container">
                {shouldDisplayResults ? (
                    <>
                        {sections.length > 0 ? (
                            sections.map((section, i) => (
                                <AppSearchSectionBlock
                                    testId={`app-search-section-${i}`}
                                    sectionTitleKey={section.sectionTitleKey}
                                    hasTopBorder={i !== 0}
                                    key={`app-search-section-${i}`}
                                >
                                    <>
                                        {section.results.map((result, index) => (
                                            <Col
                                                md={
                                                    section.id === AppSearchSectionKeyEnum.FAQ
                                                        ? 12
                                                        : 6
                                                }
                                                xs={12}
                                                key={`app-search-section-${i}-item${index}`}
                                            >
                                                <AppSearchResultItem
                                                    {...result}
                                                    index={index}
                                                    section={section.id}
                                                    hasStrongText
                                                    callback={handleSelectionCallback}
                                                />
                                            </Col>
                                        ))}
                                    </>
                                </AppSearchSectionBlock>
                            ))
                        ) : (
                            <AppSearchSectionBlock testId="app-search-no-result-block">
                                <Col xs={12} md={4} xl={6} className="search-no-result-container">
                                    <AppSearchNoResult />
                                </Col>
                                <Col xs={12} md={8} xl={6} className="d-flex align-items-center">
                                    <AppSearchSuggestions
                                        selectionCallBack={handleSelectionCallback}
                                    />
                                </Col>
                            </AppSearchSectionBlock>
                        )}
                    </>
                ) : (
                    <AppSearchSectionBlock testId="app-search-suggestions-block">
                        <Col>
                            <AppSearchSuggestions selectionCallBack={handleSelectionCallback} />
                        </Col>
                    </AppSearchSectionBlock>
                )}
            </Container>
        </>
    )
}

export default withAITracking(reactPlugin, Search, 'Search', 'search-container')
