import './SettingsCredentialsEditLoginForm.css'

import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Credentials from 'core/constants/translationKeys/credentials'
import { assuraProblems } from 'core/enums/AssuraProblems'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { LoginForm } from 'core/models/services/settings/LoginForm'
import { setValue } from 'core/validators/helpers'
import { useLoginValidation } from 'core/validators/login'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraFormInputWithValidations from 'shared/components/AssuraFormInputWithValidations/AssuraFormInputWithValidations'
import AssuraText from 'shared/components/AssuraText/AssuraText'
import { FieldsContextProvider } from 'shared/contexts/FieldsContext'
import {
    getCredentialSubmissionStatus,
    getUpdateResults,
    onSubmitUpdateLogin,
    setUpdateStatus,
    UpdateResult
} from 'shared/store/settings/credentials/credentials.slice'
import { useAppDispatch } from 'shared/store/store'

const fieldNames = { newLogin: 'newLogin' }

const initialFieldsData: LoginForm = { newLogin: '' }

type SettingsCredentialsEditLoginFormProps = {
    loginData?: string
}

const SettingsCredentialsEditLoginForm = ({ loginData }: SettingsCredentialsEditLoginFormProps) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(LoadingStatusEnum.OK)

    const updateStatus = useSelector(getCredentialSubmissionStatus)

    const [unHandledErrorVisible, setUnHandledErrorVisible] = useState(false)

    const updateResults = useSelector(getUpdateResults)

    const [login] = useState<string>(loginData ? loginData : '')

    const formMethods = useForm<LoginForm>({
        mode: 'onSubmit',
        defaultValues: initialFieldsData
    })

    const { watch, setError, clearErrors, reset } = formMethods

    const fieldCollection = Object.entries(initialFieldsData).map((o) => {
        return {
            name: o[0],
            isDirty: false,
            isFocus: false,
            isPristine: true
        }
    })

    const data = watch()

    const [newLoginValidationResult, newLoginValidationRules, options] = useLoginValidation(
        setValue(data.newLogin)
    )

    useEffect(() => {
        return () => {
            dispatch(setUpdateStatus(LoadingStatusEnum.OK))
        }
    }, [])

    useEffect(() => {
        setUnHandledErrorVisible(updateStatus === LoadingStatusEnum.ERROR)
    }, [updateStatus])

    useEffect(() => {
        if (!updateResults) return
        handleUpdateResults(updateResults)
    }, [updateResults])

    const handleUpdateResults = (result: UpdateResult) => {
        setUnHandledErrorVisible(false)
        clearErrors()
        reset()
        switch (result.type) {
            case assuraProblems.UsernameTaken:
                setError('newLogin', {
                    message: t(Credentials.login.errors.alreadyExists)
                })
                return
            case assuraProblems.UsernameLength:
                setError('newLogin', {
                    message: t(Credentials.login.errors.lengthOverflow)
                })
                return
            default:
                setIsUpdating(LoadingStatusEnum.OK)
                setUnHandledErrorVisible(true)
                return
        }
    }

    const onSubmitResolved = (data: FieldValues) => {
        const formToSubmit: LoginForm = {
            newLogin: data.newLogin
        }
        dispatch(onSubmitUpdateLogin(formToSubmit))
    }

    return (
        <>
            {unHandledErrorVisible && (
                <div className="settings-credentials-error-banner-container">
                    <AssuraBanner
                        id="settings-credentials-error-banner-container-error"
                        data-testid="settings-credentials-error-banner-container-test-id"
                        message={t(Credentials.login.errors.unknownException)}
                        onClose={() => setUnHandledErrorVisible(false)}
                        variant="alert"
                    />
                </div>
            )}
            <div className="settings-credentials-edit-card">
                <div className="login-edit-col">
                    <Form.Label className="labelMedium text-label">
                        {t(Credentials.login.edit.currentLoginLabel)}
                    </Form.Label>
                    <AssuraText
                        id="current-email"
                        value={login}
                        type="text"
                        classNames="assura-text-button-value label"
                    />
                </div>
                <div>
                    <FormProvider {...formMethods}>
                        <Form>
                            <FieldsContextProvider fieldList={fieldCollection}>
                                <AssuraFormInputWithValidations
                                    name={fieldNames.newLogin}
                                    label={t(Credentials.login.edit.label)}
                                    placeHolder={t(Credentials.login.edit.placeholder)}
                                    dataTestId="credentials-edit-login"
                                    validationResults={newLoginValidationResult}
                                    validationRules={newLoginValidationRules()}
                                    options={options}
                                />
                            </FieldsContextProvider>
                            <div className="m-top-48">
                                <SubmitButtonWithScroll
                                    text={t(Credentials.sendButtonLabel)}
                                    disabled={isUpdating === LoadingStatusEnum.LOADING}
                                    onSubmit={onSubmitResolved}
                                    id="services-form-submit-button"
                                    hasLoader={isUpdating === LoadingStatusEnum.LOADING}
                                />
                            </div>
                        </Form>
                    </FormProvider>
                </div>
            </div>
        </>
    )
}

export default SettingsCredentialsEditLoginForm
