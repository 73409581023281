import { useDispatch } from 'react-redux'

import createSagaMiddleware from 'redux-saga'

import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './rootReducer'
import rootSaga from './sagas'

export const sagaMiddleware = createSagaMiddleware({
    onError(error: Error, { sagaStack }) {
        console.error('Something went wrong', error, sagaStack)
        //TODO : should find another way to handle properly errors to avoid having to restar the rootSaga
        sagaMiddleware.run(rootSaga)
    }
})

const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD'
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>

export default store
