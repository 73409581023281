const Credentials = {
    title: 'CREDENTIAL.TITLE',

    login: {
        label: `CREDENTIAL_LOGIN.LABEL`,
        edit: {
            label: 'CREDENTIAL_LOGIN_EDIT.LABEL',
            currentLoginLabel: 'CREDENTIAL_LOGIN_EDIT.CURRENT_LOGIN_LABEL',
            placeholder: 'CREDENTIAL_LOGIN_EDIT.PLACEHOLDER',
            updateSuccess: 'CREDENTIAL_LOGIN.UPDATE_SUCCESS'
        },
        errors: {
            alreadyExists: 'CREDENTIAL_LOGIN_ERROR.ALREADY_EXISTS',
            lengthOverflow: 'CREDENTIAL_LOGIN_ERROR.RANGE_OVERFLOWS',
            unknownException: 'CREDENTIAL_LOGIN_ERROR.UNKNOWN_EXCEPTION'
        },
        validations: {
            format: 'CREDENTIAL_LOGIN_VALIDATION.FORMAT',
            lengthRange: 'CREDENTIAL_LOGIN_VALIDATION.LENGTH_RANGE',
            specialChars: 'CREDENTIAL_LOGIN_VALIDATION.SPECIAL_CHARS'
        }
    },
    password: {
        label: 'CREDENTIAL_PASSWORD.LABEL',
        edit: {
            label: 'CREDENTIAL_PASSWORD_EDIT.LABEL',
            oldPasswordLabel: 'CREDENTIAL_PASSWORD_EDIT.OLD_PASSWORD_LABEL',
            newPasswordLabel: 'CREDENTIAL_PASSWORD_EDIT.NEW_PASSWORD_LABEL',
            confirmPasswordLabel: 'CREDENTIAL_PASSWORD_EDIT.CONFIRM_PASSWORD_LABEL',
            oldPasswordHint: 'CREDENTIAL_PASSWORD_EDIT.OLD_PASSWORD_HINT',
            newPasswordHint: 'CREDENTIAL_PASSWORD_EDIT.NEW_PASSWORD_HINT',
            confirmPasswordHint: 'CREDENTIAL_PASSWORD_EDIT.CONFIRM_PASSWORD_HINT',
            oldPasswordLabelPlaceHolder: 'CREDENTIAL_PASSWORD_EDIT.OLD_PASSWORD_PLACEHOLDER',
            newPasswordLabelPlaceHolder: 'CREDENTIAL_PASSWORD_EDIT.NEW_PASSWORD_PLACEHOLDER',
            confirmPasswordLabelPlaceHolder:
                'CREDENTIAL_PASSWORD_EDIT.CONFIRM_PASSWORD_PLACEHOLDER',
            updateSuccess: 'CREDENTIAL_PASSWORD.UPDATE_SUCCESS'
        },
        errors: {
            format: 'CREDENTIAL_PASSWORD_ERROR.FORMAT',
            lengthOverflow: 'CREDENTIAL_PASSWORD_ERROR.RANGE_OVERFLOWS',
            compareEqualityNotMatch: 'CREDENTIAL_PASSWORD_ERROR.COMPARE_EQUALITY_NOT_MATCH',
            currentIsIncorrect: 'CREDENTIAL_PASSWORD_ERROR.CURRENT_IS_INCORRECT',
            unknownException: 'CREDENTIAL_PASSWORD_ERROR.UNKNOWN_EXCEPTION'
        },
        validations: {
            lengthRange: 'CREDENTIAL_PASSWORD_VALIDATION.MIN_EIGHT_CHARS_LENGTH',
            mustHaveAtLeastOneLetter: 'CREDENTIAL_PASSWORD_VALIDATION.AT_LEAST_ONE_LETTER',
            mustHaveAtLeastOneNumber: 'CREDENTIAL_PASSWORD_VALIDATION.AT_LEAST_ONE_NUMBER'
        }
    },
    sendButtonLabel: 'CREDENTIAL.SEND_BUTTON_LABEL'
}

export default Credentials
