export enum Canton {
    ALL_SWITZERLAND = 'CH',
    APP_RHODES_EXT = 'AR',
    APP_RHODES_INT = 'AI',
    ARGOVIE = 'AG',
    BALE_CAMPAGNE = 'BL',
    BALE_VILLE = 'BS',
    BERNE = 'BE',
    FRIBOURG = 'FR',
    GENEVE = 'GE',
    GLARIS = 'GL',
    GRISONS = 'GR',
    JURA = 'JU',
    LUCERNE = 'LU',
    NEUCHATEL = 'NE',
    NIDWALD = 'NW',
    OBWALD = 'OW',
    SAINT_GALL = 'SG',
    SCHAFFHOUSE = 'SH',
    SCHWYTZ = 'SZ',
    SOLEURE = 'SO',
    TESSIN = 'TI',
    THURGOVIE = 'TG',
    URI = 'UR',
    VALAIS = 'VS',
    VAUD = 'VD',
    ZOUG = 'ZG',
    ZURICH = 'ZH'
}
