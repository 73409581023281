import './WhatsNewImage.css'

import { Col } from 'react-bootstrap'

interface WhatsNewImageProps {
    path: string
}

const WhatsNewImage = ({ path }: WhatsNewImageProps): JSX.Element => (
    <Col className="whats-new-pop-in-image-col" xs={12} xl={6}>
        <div className="whats-new-pop-in-image-container">
            <img
                src={`${process.env.REACT_APP_CMS_ASSET}${path}`}
                alt="what's new image"
                className="whats-new-pop-in-image"
                data-testid="whats-new-pop-in-image"
            />
        </div>
    </Col>
)

export default WhatsNewImage
