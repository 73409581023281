import './AssuraPopover.css'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import { PopoverPositionVariants, PopoverTriggerVariants } from 'core/enums/PopoverVariants'

interface AssuraPopoverProps {
    id: string
    position: PopoverPositionVariants
    triggerType: PopoverTriggerVariants
    title?: string
    content: JSX.Element
    isInsidePanel?: boolean
    children: JSX.Element
}

const AssuraPopover = ({
    id,
    position,
    triggerType,
    title,
    content,
    isInsidePanel,
    children
}: AssuraPopoverProps): JSX.Element => {
    let classNamesToApplied = position === 'bottom' && !title ? 'whiteArrow' : ''
    if (isInsidePanel) classNamesToApplied += ' popover-inside-panel'

    const popover = (
        <Popover id={id} className={classNamesToApplied} data-testid={id}>
            {title && <Popover.Title>{title}</Popover.Title>}
            <Popover.Content className="white-space-pre-line">{content}</Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger trigger={triggerType} placement={position} overlay={popover} flip>
            {children}
        </OverlayTrigger>
    )
}

export default AssuraPopover
