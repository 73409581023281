// Structure :
// return the custom hook with the validation configuration for the current field

import Credentials from 'core/constants/translationKeys/credentials'
import { ValidationTypeEnum } from 'core/enums/ValidationTypeEnum'
import { TFunction } from 'i18next'

import { UseValidationOptions, ValidationConfiguration } from './entities'
import { useValidation } from './useValidation'
import Validators from './validators'

// Define base field configuration
const options: UseValidationOptions = {
    displayTitle: false,
    displayValidationGuide: true,
    displayFullValidationGuide: false,
    displayValidationErrors: true,
    title: Credentials.login.edit.label,
    required: true,
    min: 6,
    max: 50
}

// Configure validators
export const loginConfiguration = (
    options: UseValidationOptions,
    t: TFunction<'translation', undefined>
): ValidationConfiguration[] => [
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.Required,
        hint: t('COMMON.MANDATORY_FIELD'),
        error: t('COMMON.MANDATORY_FIELD'),
        method: Validators.isMandatory
    },
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.Length,
        hint: t(Credentials.login.validations.lengthRange),
        error: t(Credentials.login.validations.lengthRange),
        method: Validators.isLengthOverflow(options)
    },
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.SpecialChars,
        hint: t(Credentials.login.validations.specialChars),
        error: t(Credentials.login.validations.specialChars),
        method: Validators.isLoginValid
    }
]

export const useLoginValidation = useValidation(options)(loginConfiguration)
