import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

import { HospitalizationAnnouncementRHFForm } from 'core/models/services/report/HospitalizationAnnouncementForm'
import useWindowSize from 'core/services/useWindowSize'

import { AssuraFormInputRHF } from '../../components/InputRHF/InputRHF'
import { AssuraNumberInputRHF } from '../../components/NumberInputRHF/NumberInputRHF'
import { AssuraFormTextAreaRHF } from '../../components/TextAreaRHF/TextAreaRHF'

const HospitalizationInfos = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const {
        setValue,
        register,
        formState: { errors, isSubmitted }
    } = useFormContext()
    const [fieldFocused, setFieldFocused] = useState('')

    const handleResetAction = (name: keyof HospitalizationAnnouncementRHFForm) => {
        setValue(name, '', {
            shouldValidate: isSubmitted
        })
    }

    const getDoctorNameRules = (): Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    > => {
        const min = 1
        const max = 1000
        const doctorNameErrorMessage = t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
            min,
            max
        })
        return {
            minLength: { value: min, message: doctorNameErrorMessage },
            maxLength: { value: max, message: doctorNameErrorMessage }
        }
    }

    const getDurationRules = (): Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    > => {
        const min = 1
        const max = 365
        const durationErrorMessage = t('COMMON.ERROR_FIELD_VALIDATION_NUMBER', {
            min,
            max
        })
        return {
            min: { value: min, message: durationErrorMessage },
            max: { value: max, message: durationErrorMessage }
        }
    }

    return (
        <div className="d-flex flex-column bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <AssuraFormInputRHF
                id="services-hospitalisation-announcement-doctor"
                name="doctor"
                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DOCTOR_NAME')}
                placeHolder={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DOCTOR_NAME_PLACEHOLDER')}
                inline={isXL}
                labelSize={4}
                isInvalid={Boolean(errors?.doctor)}
                error={t(errors?.doctor?.message as string)}
                hasButton={fieldFocused === 'doctor'}
                onFocus={() => setFieldFocused('doctor')}
                onBlur={() => setFieldFocused('')}
                onButtonClick={() => handleResetAction('doctor')}
                iconClass="icon assura-close size-24"
                register={register}
                rules={getDoctorNameRules()}
            />
            <div className="m-top-32">
                <AssuraNumberInputRHF
                    id="services-hospitalisation-announcement-duration"
                    name="duration"
                    label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DURATION')}
                    placeHolder={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DURATION_PLACEHOLDER')}
                    inline={isXL}
                    labelSize={4}
                    isInvalid={Boolean(errors?.duration)}
                    error={t(errors?.duration?.message as string)}
                    hasButton={fieldFocused === 'duration'}
                    onFocus={() => setFieldFocused('duration')}
                    onBlur={() => setFieldFocused('')}
                    onButtonClick={() => handleResetAction('duration')}
                    iconClass="icon assura-close size-24"
                    register={register}
                    rules={getDurationRules()}
                />
                <div className="m-top-32">
                    <AssuraFormTextAreaRHF
                        id="services-hospitalisation-announcement-remark"
                        name="remark"
                        label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REMARK')}
                        inline={false}
                        register={register}
                        isInvalid={Boolean(errors?.remark)}
                        error={t(errors?.remark?.message as string)}
                        placeholder={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REMARK_PLACEHOLDER')}
                        rules={{
                            maxLength: {
                                value: 2000,
                                message: t('FORM.INPUT_MAX_CHARACTERS', { number: 2000 })
                            }
                        }}
                        maxLength={2000}
                    />
                </div>
            </div>
        </div>
    )
}

export default HospitalizationInfos
