import { useTranslation } from 'react-i18next'

import PeriodicityTrads from 'core/constants/productPeriodicityTraductions'
import { ProductPeriodicity } from 'core/enums/Product'

interface FrequencySelectItemLabelProps {
    periodicity: ProductPeriodicity
    isCurrent: boolean
    isSelected: boolean
}

const FrequencySelectItemLabel = ({
    periodicity,
    isCurrent,
    isSelected
}: FrequencySelectItemLabelProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column justify-content-center align-items-start flex-1">
            <div className={`paragraphMedium ${isSelected ? 'c-primary' : 'c-black'}`}>
                {t(PeriodicityTrads[periodicity])}
            </div>
            {isCurrent && (
                <div className="services-payment-frequency-select-actual-item d-flex justify-content-center align-items-center">
                    <div
                        className="paragraphSmall"
                        data-testid={`services-payment-frequency-select-${periodicity}-current`}
                    >
                        {t('SERVICES.MODEL_DEDUCTIBLE_CURRENT')}
                    </div>
                    <i className="icon assura-check-circle size-16" />
                </div>
            )}
        </div>
    )
}

export default FrequencySelectItemLabel
