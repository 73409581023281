import './ScrollToDashboardFloatingButton.css'

import { useEffect, useState } from 'react'

import useNavBarHeights from 'core/services/useNavBarHeights'
import FloatingButton from 'shared/components/FloatingButton/FloatingButton'

interface ScrollToDashboardFloatingButtonProps {
    dashboardRef: React.RefObject<HTMLDivElement | null>
}

const ScrollToDashboardFloatingButton = ({
    dashboardRef
}: ScrollToDashboardFloatingButtonProps): JSX.Element | null => {
    const { navBarHeight } = useNavBarHeights()

    const [isDashboardHidden, setIsDashboardHidden] = useState(false)

    const dashboardOptions = {
        root: null,
        threshold: 0
    }

    const handleOnClick = () => {
        if (dashboardRef?.current)
            window.scrollTo({
                top: dashboardRef.current.offsetTop - navBarHeight,
                behavior: 'smooth'
            })
    }

    const dashboardCallbackFunction: IntersectionObserverCallback = (entries) => {
        const [entry] = entries
        const isFarFromTop = entries[0].boundingClientRect.y > 0
        setIsDashboardHidden(!entry.isIntersecting && isFarFromTop)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(dashboardCallbackFunction, dashboardOptions)
        if (dashboardRef?.current) observer.observe(dashboardRef.current)
        return () => observer.disconnect()
    }, [dashboardRef])

    return isDashboardHidden ? (
        <div className="scroll-to-dashboard-floating-button-container">
            <FloatingButton
                onClick={handleOnClick}
                testId="scroll-to-dashboard-floating-button"
                direction="down"
            />
        </div>
    ) : null
}

export default ScrollToDashboardFloatingButton
