/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BilledPrimeRHFForm } from 'core/models/services/certificates/BilledPrimeForm'
import PickerFiveYears from 'modules/services/components/DatePicker/PickerFiveYears/PickerFiveYears'

const BilledPrimePeriod = (): JSX.Element => {
    const { getValues, clearErrors, setError, formState } = useFormContext<BilledPrimeRHFForm>()
    const { t } = useTranslation()
    const { isSubmitted } = formState

    const [isPeriodError, setIsPeriodError] = useState(false)

    const validatePeriod = (_value: number, name: string) => {
        const { startYear, endYear } = getValues()
        if (startYear) {
            if (startYear - endYear <= 0) {
                clearErrors(['startYear', 'endYear'])
                return true
            } else {
                if (isSubmitted)
                    setError(name === 'startYear' ? 'endYear' : 'startYear', {
                        type: 'period',
                        message: undefined
                    })
                return ''
            }
        }
    }

    useEffect(() => {
        const { errors, isSubmitted } = formState
        if (isSubmitted)
            setIsPeriodError(
                errors.startYear?.type === 'period' && errors.endYear?.type === 'period'
            )
    }, [formState])

    return (
        <Row>
            <Col xl={4} md={12} className="d-flex">
                <div className="paragraphMedium">{t('SERVICES.WANTED_PERIOD')}</div>
            </Col>
            <Col xl={8} md={12} className="d-flex flex-column">
                <div className="d-flex billed-prime-details-period-container">
                    <div className="billed-prime-details-year-container">
                        <div className="paragraph">{t('COMMON.FROM')}</div>
                        <PickerFiveYears
                            placeholder={t('SERVICES.YEAR_FORMAT')}
                            testId="billed-prime-from"
                            name="startYear"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'COMMON.MANDATORY_FIELD'
                                },
                                validate: {
                                    period: (_v) => validatePeriod(_v, 'startYear')
                                }
                            }}
                        />
                    </div>
                    <div className="billed-prime-details-year-container">
                        <div className="paragraph">{t('COMMON.TO')}</div>
                        <PickerFiveYears
                            placeholder={t('SERVICES.YEAR_FORMAT')}
                            testId="billed-prime-to"
                            name="endYear"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'COMMON.MANDATORY_FIELD'
                                },
                                validate: {
                                    period: (_v) => validatePeriod(_v, 'endYear')
                                }
                            }}
                        />
                    </div>
                </div>
                {isPeriodError && (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid="services-billed-prime-period-error"
                    >
                        {t('SERVICES.YEAR_PERIOD_ERROR')}
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default BilledPrimePeriod
