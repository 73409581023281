import { appInsights } from 'AppInsights'
import { buildVersionKey } from 'index'

const consoleError = console.error
window.console = {
    ...window.console,
    error: (message: string, ...data: unknown[]) => {
        consoleError(message, ...data)
        const exception = new Error(message)
        const properties: { [key: string]: unknown } = {}

        properties['version'] =
            localStorage.getItem(buildVersionKey) ?? 'no version was found in localStorage!'

        if (data.length > 0) {
            data.forEach((element, index) => {
                properties['detail_' + index] = element
            })
        }

        appInsights.trackException({ exception, properties })
    }
}
export {}
