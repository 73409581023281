import './PersonalDataTitle.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { goToServicesForm } from 'shared/store/services/services.slice'

const PersonalDataTitle = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const dispatch = useDispatch()

    const addMemberRequest = () => {
        dispatch(
            goToServicesForm({
                type: ServiceType.FAMILY_GROUP_CHANGE
            })
        )
    }

    return (
        <Row className="personal-data-title-container">
            <Col xs={12} md={8} className="personal-data-title">
                <span className="title break-word" data-testid="personal-data-title">
                    {t('PERSONAL_DATA.TITLE')}
                </span>
            </Col>
            <Col xs={12} md={4} className="personal-data-request-button-container">
                <AssuraButton
                    id="navigation-add-member-request"
                    onClick={addMemberRequest}
                    text={t('PERSONAL_DATA.ADD_MEMBER')}
                    variant="primary"
                    fullWidth={isMobile}
                    icon={{
                        name: 'assura-plus',
                        size: 24
                    }}
                />
            </Col>
        </Row>
    )
}

export default PersonalDataTitle
