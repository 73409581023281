import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { MissingDataType } from 'core/enums/MissingData'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import FormPage from 'modules/services/components/FormPage/FormPage'
import NoHead from 'modules/services/components/NoHead/NoHead'
import NoValidAddress from 'modules/services/components/NoValidAddress/NoValidAddress'
import { getHeadOfFamily } from 'shared/store/general/general.slice'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getConnectedUserInfos } from 'shared/store/selectors/getConnectedUserInfos'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'
import { isThereAddMemberInRequests } from 'shared/store/selectors/isThereInRequests'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { onSubmitAddMember } from 'shared/store/services/familyGroup/familyGroup.slice'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import { newMemberDefaultValues, useAddMemberContext } from '../pages/AddMember'
import AddMemberConsent from './AddMemberConsent'
import Members from './Members'
import NewMemberInfos from './NewMemberInfos'

const AddMemberForm = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isHeadOfFamily: boolean = useSelector(isConnectedUserTheHeadOfFamily) ?? false
    const headOfFamily = useSelector(getHeadOfFamily)
    const personalData = useSelector(getFullPersonalData)
    const connectedUser = useSelector(getConnectedUserInfos)
    const isPending = useSelector(isThereAddMemberInRequests)

    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const { address } = personalData

    const missingData = useSelector(getMissingDataInRequest)
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    const { selectedMemberId, isFormValid, getAddFamilyMembers, selectFirstMemberInError } =
        useAddMemberContext()

    const getNotAllowed = () => {
        if (!isHeadOfFamily) {
            return (
                <NoHead
                    title={t('SERVICES.ADD_MEMBER_NO_HEAD_TITLE')}
                    message={
                        <Trans
                            i18nKey="SERVICES.ADD_MEMBER_NO_HEAD_MESSAGE"
                            values={{ firstName: headOfFamily?.firstname ?? '' }}
                            components={{ 1: <b /> }}
                        />
                    }
                />
            )
        }
        if (
            headOfFamily?.policyNumber &&
            missingData[headOfFamily?.policyNumber] &&
            missingData[headOfFamily?.policyNumber].includes(MissingDataType.ADDRESS_MISSING)
        ) {
            return <NoValidAddress message={t('SERVICES.COMMON_NO_VALID_ADDRESS')} />
        }
        return undefined
    }

    const handleSubmit = () => {
        if (isFormValid) {
            const addMemberFromProps = getAddFamilyMembers()
            dispatch(onSubmitAddMember(addMemberFromProps))
        } else {
            selectFirstMemberInError()
        }
    }

    return (
        <FormPage
            _defaultValues={newMemberDefaultValues}
            serviceType={ServiceType.FAMILY_GROUP_CHANGE}
            formTitle={{
                title: t('SERVICES.ADD_MEMBER_TITLE'),
                category: t('SERVICES.UPDATE_DATA_CATEGORY')
            }}
            onSuccess={handleSubmit}
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.FAMILY_GROUP_CHANGE} />}
            notAllowed={getNotAllowed()}
            shouldDisplaySubmitButton={selectedMemberId !== null}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    contactDataLoadStatus,
                    missingDataLoadingStatus
                ]),
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactInfos({
                            selectedPolicyNumber: connectedUser?.policyNumber ?? 0,
                            reload: true
                        })
                    )
            }}
            isPending={isPending ? <PendingRequest /> : undefined}
        >
            <>
                <Members />
                {selectedMemberId !== null && (
                    <>
                        <NewMemberInfos />
                        <AddMemberConsent address={address.data} />
                    </>
                )}
            </>
        </FormPage>
    )
}

export default AddMemberForm
