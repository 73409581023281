import './CheckStatus.css'

import { useTranslation } from 'react-i18next'

import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { InvoicesToCheckIsEquals } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import { InvoiceCheckingStatus } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import useWindowSize from 'core/services/useWindowSize'

interface CheckStatusProps {
    size: '24' | '16'
    status: InvoiceCheckingStatus
    dueDate?: string
}

const CheckStatus = ({ size = '24', status, dueDate }: CheckStatusProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile, isMD } = useWindowSize()

    const formIsClosed = InvoicesToCheckIsEquals(
        [
            InvoiceCheckingStatusEnum.EXPIRED,
            InvoiceCheckingStatusEnum.CHECKED,
            InvoiceCheckingStatusEnum.REFUSED
        ],
        status
    )

    const statusLabel = formIsClosed
        ? 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.CHECKED'
        : 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.TO_CHECK'

    const dueDateLabel = `${t(
        `COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS${isMobile || isMD ? '.MOBILE' : ''}.DUE_DATE_LABEL`
    )} : ${dueDate}`
    return (
        <div className={`check-status-container${isMobile || isMD ? '-mobile' : ''} d-flex`}>
            <div className={`icon-frame d-flex`}>
                <div
                    className={`prefix-icon d-flex `}
                    data-testid={
                        status === InvoiceCheckingStatusEnum.TO_CHECK
                            ? 'check-status-to-check'
                            : 'check-status-checked'
                    }
                >
                    <i
                        className={`icon assura-${status === InvoiceCheckingStatusEnum.TO_CHECK ? 'a-remplir c-primary' : 'controle-facture c-success'} size-${size} m-right-8`}
                        data-testid={`${status === InvoiceCheckingStatusEnum.TO_CHECK ? 'a-remplir' : 'controle-facture'}-icon`}
                    />
                </div>
                <div
                    className={`check-status-label ${isMobile || isMD ? 'labelExtraSmall' : 'labelSmall'} `}
                >
                    {t(statusLabel)}
                </div>
            </div>
            {status === InvoiceCheckingStatusEnum.TO_CHECK && dueDate && dueDateLabel ? (
                <div className={`due-date-frame d-flex labelExtraSmallMedium`}>
                    <div className={`due-date-label d-flex`}>{dueDateLabel}</div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default CheckStatus
