import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { ServicesAddressRHFForm } from 'core/models/services/personalData/Address'
import { resetStreets } from 'shared/store/localities/localities.slice'

import AddressStreetComp from './AddressStreetComp'
import AddressStreetNumber from './AddressStreetNumber'

const AddressFromLocality = (): JSX.Element | null => {
    const dispatch = useDispatch()
    const { setValue, watch } = useFormContext<ServicesAddressRHFForm>()

    const localityId = watch('localityId')

    useEffect(() => {
        setValue('street', '')
        setValue('streetNumber', null)
        setValue('streetComp', null)
        dispatch(resetStreets())
    }, [localityId])

    if (localityId) {
        return (
            <>
                <AddressStreetNumber />
                <AddressStreetComp />
            </>
        )
    } else {
        return null
    }
}

export default AddressFromLocality
