import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { EcLink } from 'core/models/EcLink'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getEcLinksFiltered } from './getEcLinksFiltered'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getEcLinksLocalized = createSelector(
    [getLang, getShortContactLanguage, getEcLinksFiltered],
    (lang, contactLang, ecLinksCms): EcLink[] => {
        return ecLinksCms.map((ecLinkCms) => {
            if (lang === ENGLISH_LANGUAGE && !ecLinkCms.link_url_en) {
                return getLocalizedObject(ecLinkCms, contactLang) as EcLink
            } else {
                return getLocalizedObject(ecLinkCms, lang) as EcLink
            }
        })
    }
)
