import './ReadStatusField.css'

interface ReadStatusFieldProps {
    isRead: boolean
    rowId: number | string
}

const ReadStatusField = ({ isRead, rowId }: ReadStatusFieldProps): JSX.Element => {
    return (
        <td
            data-testid={`assura-table-row-${rowId}-read-status-field`}
            className={`table-row-read-status ${isRead ? 'transparent' : 'bg-primary'}`}
        />
    )
}

export default ReadStatusField
