import './InvoicesToCheckRowUI.css'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { InvoiceChecking } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import useWindowSize from 'core/services/useWindowSize'
import CheckStatus from 'modules/communications/components/InvoicesToCheckTable/fields/CheckStatus'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'

import InvoicesToCheckRowMobile from './InvoicesToCheckRowUIMobile'

interface InvoicesToCheckRowProps {
    row: InvoiceChecking
}

const InvoicesToCheckRow = ({ row }: InvoicesToCheckRowProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const label = `${t('COMMUNICATIONS.INVOICES_TO_CHECK.TITLE')} ${t('COMMUNICATIONS.INVOICES_TO_CHECK.TITLE.FOR')} ${row.insuredPersonName}`

    const dueDateValue = `${row.responseDeadline && formatDate(row.responseDeadline)}`

    return isXL ? (
        <td className="invoices-to-check-wrapper d-flex">
            <table className="w-100 d-flex">
                <tbody className={`invoices-to-check-row-ui-tbody d-flex`}>
                    <tr className={`invoices-to-check-row-ui-container d-flex`}>
                        <DateField
                            date={row.creationDate}
                            rowId={row.id}
                            classNames={'label d-flex'}
                        />

                        <td className={`invoices-to-check-label-container d-flex`}>
                            <div className={`invoices-to-check-label label`}>{label}</div>
                            <div className={`invoices-to-check-provider label`}>
                                {row.careProviderName}
                            </div>
                        </td>

                        <td className={`invoices-to-check-status-container d-flex`}>
                            <CheckStatus status={row.status} dueDate={dueDateValue} size="24" />
                        </td>

                        <GoToField
                            rowId={row.id}
                            classNames={`invoices-to-check-goto d-flex`}
                            marginLeft={40}
                        />
                    </tr>
                </tbody>
            </table>
        </td>
    ) : (
        <InvoicesToCheckRowMobile row={row} />
    )
}

export default InvoicesToCheckRow
