import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { fetchMissingDataApi } from 'core/api/MissingData'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { MissingData } from 'core/models/MissingData'

import { fetchMissingData, setMissingData, setMissingDataLoadingStatus } from './missingData.slice'

export function* fetchMissingDataSaga() {
    try {
        yield put(setMissingDataLoadingStatus(LoadingStatusEnum.LOADING))

        const missingData: MissingData[] = yield call(fetchMissingDataApi)

        yield put(setMissingData(missingData))
        yield put(setMissingDataLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchMissingDataSaga Error', e)
        yield put(setMissingDataLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchMissingDataWatcher() {
    yield takeEvery(fetchMissingData.type, fetchMissingDataSaga)
}

const watchers = [fork(fetchMissingDataWatcher)]

export default watchers
