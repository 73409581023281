import './FrequentRequests.css'

import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getFrequentRequests } from 'shared/store/selectors/getFrequentRequests'
import { fetchFrequentRequests } from 'shared/store/services/frequentRequests/frequentRequests.slice'
import { useAppDispatch } from 'shared/store/store'

import FrequentRequestsItem from './FrequentRequestsItem'

interface FrequentRequestsProps {
    isFullWidth: boolean
    loadingStatus: LoadingStatusEnum
}

const FrequentRequests = ({ isFullWidth, loadingStatus }: FrequentRequestsProps): JSX.Element => {
    const dispatch = useAppDispatch()

    const frequentRequests = useSelector(getFrequentRequests)

    const containerClassnames = `services-hub-frequent-requests-container${
        isFullWidth ? ' full' : ''
    }`

    const reloadFrequentRequests = () => {
        dispatch(fetchFrequentRequests())
    }

    return (
        <>
            <div className="services-hub-header-block">
                <AssuraLoadAndError
                    status={loadingStatus}
                    defaultReloadAction={reloadFrequentRequests}
                >
                    <div className={containerClassnames}>
                        {frequentRequests.map((request) => (
                            <FrequentRequestsItem request={request} key={request._id} />
                        ))}
                    </div>
                </AssuraLoadAndError>
            </div>
        </>
    )
}

export default FrequentRequests
