import { DocumentRow } from 'core/models/documents/DocumentRow'
import { formatNumber } from 'core/utils/TextUtils'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import ReadStatusField from 'shared/components/AssuraTable/Fields/ReadStatusField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

interface RefundsTableRowMobileProps {
    row: DocumentRow
}

const RefundsTableRowMobile = ({ row }: RefundsTableRowMobileProps): JSX.Element => {
    const unreadClass = row.isRead ? 'labelExtraSmall' : 'labelExtraSmallMedium c-black'
    const unreadLabelClass = row.isRead ? 'label' : 'labelMedium c-black'

    return (
        <>
            <td className="w-100">
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8">
                            <ReadStatusField isRead={row.isRead} rowId={row.documentId} />
                            <DateField
                                date={row.creationDate}
                                rowId={row.documentId}
                                classNames={unreadClass}
                                isMobileStyle
                            />
                            <Separator />
                            <TextField
                                label={row.insuredPersonName}
                                classNames={unreadClass}
                                rowId={row.documentId}
                                fieldId="insured-person"
                            />
                            {row.valueAmount > 0 && (
                                <>
                                    <Separator />
                                    <TextField
                                        label={`CHF ${formatNumber(row.valueAmount, true)}`}
                                        rowId={row.documentId}
                                        classNames={unreadClass}
                                        fieldId="amount"
                                    />
                                </>
                            )}
                        </tr>
                        <tr>
                            <TextField
                                label={row.documentName}
                                classNames={unreadLabelClass}
                                rowId={row.documentId}
                                fieldId="documentName"
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            <GoToField rowId={row.documentId} marginLeft={8} />
        </>
    )
}

export default RefundsTableRowMobile
