/* eslint-disable sonarjs/no-duplicate-string */
import './Faq.css'

import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import { AbsolutePosition } from 'core/enums/AbsolutePosition'
import { emptyFunction } from 'core/helpers/functionHelper'
import { FaqGlobalItem } from 'core/models/Faq'
import useQuery from 'core/services/useQuery'
import useWindowSize from 'core/services/useWindowSize'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { fetchFaqCollections, getFaqLoadingStatus } from 'shared/store/faq/faq.slice'
import { getDefaultFaqCategoryId } from 'shared/store/selectors/getFaqData'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import FaqCategories from '../components/FaqCategoriesList/FaqCategoriesList'
import FaqHeader from '../components/FaqHeader/FaqHeader'
import FaqQuestionsList from '../components/FaqQuestionsList/FaqQuestionsList'

interface FaqProviderProps {
    children: ReactNode
}

interface FaqContext {
    selectedCategoryId: string | null
    selectedQuestionId: string | null
    setSelectedCategoryId: (id: string | null) => void
    setSelectedQuestionId: (id: string | null) => void
    setExternalSelection: (item: FaqGlobalItem) => void
}

const FaqContext = createContext<FaqContext>({
    selectedCategoryId: null,
    selectedQuestionId: null,
    setSelectedCategoryId: emptyFunction,
    setSelectedQuestionId: emptyFunction,
    setExternalSelection: emptyFunction
})

const FaqProvider = ({ children }: FaqProviderProps): JSX.Element => {
    const defaultFaqCategoryId = useSelector(getDefaultFaqCategoryId)
    const location = useLocation()

    const externalCategory = useQuery().get('category')
    const externalQuestion = useQuery().get('question')

    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
        externalCategory || defaultFaqCategoryId
    )
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(externalQuestion)

    const handleSelectedCategoryId = (id: string | null) => {
        setSelectedCategoryId(id)
        setSelectedQuestionId(null)
    }

    const handleSelectedQuestionId = (id: string | null) => {
        setSelectedQuestionId((previousId) => (previousId !== id ? id : null))
    }

    const setExternalSelection = (item: FaqGlobalItem) => {
        setSelectedCategoryId(item.categoryId)
        setSelectedQuestionId(item.questionId)
    }

    const value: FaqContext = {
        selectedCategoryId,
        selectedQuestionId,
        setSelectedCategoryId: handleSelectedCategoryId,
        setSelectedQuestionId: handleSelectedQuestionId,
        setExternalSelection
    }

    useEffect(() => {
        if (
            externalCategory &&
            (selectedCategoryId !== externalCategory || selectedQuestionId !== externalQuestion)
        ) {
            setExternalSelection({
                categoryId: externalCategory,
                questionId: externalQuestion
            })
        }
    }, [externalQuestion, externalCategory, location])

    return <FaqContext.Provider value={value}>{children}</FaqContext.Provider>
}

const Faq = (): JSX.Element => {
    const { isXL, isMobile } = useWindowSize()
    const dispatch = useDispatch()
    const faqLoadingStatus = useSelector(getFaqLoadingStatus)
    const questionsContainerRef = useRef<HTMLDivElement>(null)

    return (
        <FaqProvider>
            <FaqHeader />
            <AssuraLoadAndError
                status={faqLoadingStatus}
                defaultReloadAction={() => dispatch(fetchFaqCollections())}
            >
                <FullScreenContainer
                    color="white"
                    complementaryClasses="faq-content-container"
                    secondColoredOffset={
                        !isMobile
                            ? {
                                  secondColorClass: 'bg-gray50',
                                  bootStrapColumnNumber: isXL ? 4 : 3,
                                  position: AbsolutePosition.LEFT
                              }
                            : undefined
                    }
                >
                    <Container id="faq-container" className="d-flex flex-column flex-1">
                        <Row className="flex-1">
                            {!isMobile && (
                                <Col md={3} xl={4}>
                                    <FaqCategories />
                                </Col>
                            )}
                            <Col
                                xs={12}
                                md={8}
                                className="faq-questions-container"
                                ref={questionsContainerRef}
                            >
                                <FaqQuestionsList />
                            </Col>
                        </Row>
                    </Container>
                </FullScreenContainer>
            </AssuraLoadAndError>
        </FaqProvider>
    )
}

const useFaqContext = (): FaqContext => {
    return useContext(FaqContext)
}

export { FaqProvider, useFaqContext }

export default withAITracking(reactPlugin, Faq, 'Faq', 'faq-container')
