import './RequestsCommunicationLink.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { RequestStatus } from 'core/enums/RequestStatus'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

export type RequestReceptionType = ServicesReceptionMode | undefined

interface RequestsCommunicationLinkProps {
    receptionType: RequestReceptionType
    status: RequestStatus
}

const RequestsCommunicationLink = ({
    receptionType,
    status
}: RequestsCommunicationLinkProps): JSX.Element | null => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleOnClick = () => {
        navigate(navigationConstants.COMMUNICATIONS.url)
    }

    return receptionType === ServicesReceptionMode.ELECTRONIC ? (
        <div className="requests-communication-link-container">
            <div className="paragraphSmall" data-testid="requests-communication-link-message">
                {t('SERVICES.REQUESTS_ATTESTATIONS_MESSAGE')}
            </div>
            {status === RequestStatus.COMPLETED && (
                <AssuraIconButton
                    id="requests-communication-link"
                    icon="assura-next"
                    variant="primary"
                    size="medium"
                    onClick={handleOnClick}
                    label={t('SERVICES.SEE_COMMUNICATIONS')}
                    classNames="m-top-32"
                />
            )}
        </div>
    ) : null
}

export default RequestsCommunicationLink
