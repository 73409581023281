import { useTranslation } from 'react-i18next'

import PeriodicityTrads from 'core/constants/productPeriodicityTraductions'
import { ProductPeriodicity } from 'core/enums/Product'

interface PremiumsRebateProps {
    periodicity: ProductPeriodicity
    shouldDisplayPeriodicity?: boolean
    classNames: string
    dataTestId: string
}
const PremiumsRebate = ({
    periodicity,
    shouldDisplayPeriodicity,
    classNames,
    dataTestId
}: PremiumsRebateProps): JSX.Element | null => {
    const { t } = useTranslation()
    const shouldDisplayRebate =
        periodicity === ProductPeriodicity.ANNUAL || periodicity === ProductPeriodicity.BIANNUAL
    if (shouldDisplayPeriodicity || shouldDisplayRebate) {
        return (
            <div
                className={`d-flex align-items-center ${classNames}`}
                data-testid={`${dataTestId}-premiums-rebate`}
            >
                <i className="icon assura-info-circle size-24 c-gray500 align-self-start" />
                <div className="m-left-12">
                    {shouldDisplayPeriodicity && (
                        <div className="paragraphSmallMedium">{`${t(
                            'HOME.PREMIUMS_PERIODICITY'
                        )} : ${t(PeriodicityTrads[periodicity])}`}</div>
                    )}
                    {shouldDisplayRebate && (
                        <div
                            className="paragraphSmall"
                            data-testid={`${dataTestId}-premiums-rebate-value`}
                        >
                            {t('PREMIUMS.REBATE', {
                                rebate: periodicity === ProductPeriodicity.ANNUAL ? '2' : '1'
                            })}
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default PremiumsRebate
