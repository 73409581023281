import { ToastType } from 'core/enums/ToastType'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface ToastState {
    message: string
    type: ToastType | undefined
}

const INITIAL_STATE_TOAST: ToastState = {
    message: '',
    type: undefined
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState: INITIAL_STATE_TOAST,
    reducers: {
        setToastInfo: (state, action: PayloadAction<ToastState>) => {
            state.message = action.payload.message
            state.type = action.payload.type
        }
    }
})

export const { setToastInfo } = toastSlice.actions

export const getToastMessage: Selector<RootState, string> = (state) => state.toast.message

export const getToastType: Selector<RootState, ToastType | undefined> = (state) => state.toast.type

export default toastSlice.reducer
