import './AssuraToast.css'

import { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { getToastMessage, getToastType, setToastInfo } from 'shared/store/toast/toast.slice'

import { getToastDisplayByType } from '../../../core/helpers/ToastHelper'

const AssuraToast = (): JSX.Element => {
    const message = useSelector(getToastMessage)
    const type = useSelector(getToastType)
    const toastCharacteristics = getToastDisplayByType(type)
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (message && message !== '') {
            setShow(true)
        }
    }, [message])

    const resetToastInfo = () => {
        setShow(false)
        setTimeout(() => {
            dispatch(setToastInfo({ message: '', type: undefined }))
        }, 1000)
    }

    return (
        <div className="assura-toast-container">
            <Toast onClose={() => resetToastInfo()} show={show} autohide>
                <Toast.Body bsPrefix={`toast-body ${toastCharacteristics.backgroundColor}`}>
                    <div className="assura-toast-icon-container bg-white">
                        <i
                            data-testid="assura-toast-icon"
                            className={`icon ${toastCharacteristics.icon} ${toastCharacteristics.iconColor} size-24`}
                        />
                    </div>
                    <div
                        data-testid="assura-toast-message"
                        className="paragraphExtraSmall assura-toast-message"
                    >
                        {message}
                    </div>
                </Toast.Body>
            </Toast>
        </div>
    )
}

export default AssuraToast
