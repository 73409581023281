/// TODO:All this file / icons / illustrations are a temporary solution and should be replaced by a better alternative. We must lign with others teams in order to select the unique source where to get assets. Probably CMS.
/// For reference :
/// I've generated this list from Powershell => directory #:\Assets Get-ChildItems -name
/// Then copy paste in excel =>
/// the A column containes the raw file names where i decided to remove all the extentions.
/// This cleanes the iconName for the Imported Object =MINUSCULE(SUBSTITUE(SUBSTITUE(A1;"_";" ");"-";" "))                          => rendered in B1
/// This Pascal cases the previous cleaned name =SUBSTITUE(NOMPROPRE(B1);" ";"")                                                    => rendered in C1
/// This generates the code for the imports  =CONCATENER("import ";C1;" from 'shared/assets/images/"; A1;".png'")                   => rendered in D1
/// This generates the code for the object list =CONCATENER(A1;": "; C1;",")                                                        => rendered in E1

import Access from 'shared/assets/images/access.png'
import Accident from 'shared/assets/images/accident.png'
import Address from 'shared/assets/images/address.png'
import AlternativeMedicine from 'shared/assets/images/alternative_medicine.png'
import Assistance from 'shared/assets/images/assistance.png'
import Assuralogo from 'shared/assets/images/assuraLogo.png'
import Assurcall from 'shared/assets/images/assurcall.png'
import Baby from 'shared/assets/images/baby.png'
import Basis from 'shared/assets/images/basis.png'
import Bonus from 'shared/assets/images/bonus.png'
import Cancellation from 'shared/assets/images/cancellation.png'
import Capital from 'shared/assets/images/capital.png'
import CapitalChoice from 'shared/assets/images/capital_choice.png'
import Children from 'shared/assets/images/children.png'
import ClientCare from 'shared/assets/images/client_care.png'
import Contact from 'shared/assets/images/contact.png'
import Contract from 'shared/assets/images/contract.png'
import Coverage from 'shared/assets/images/coverage.png'
import Daughter from 'shared/assets/images/daughter.png'
import Declaration from 'shared/assets/images/declaration.png'
import Delete from 'shared/assets/images/delete.png'
import DentalCare from 'shared/assets/images/dental_care.png'
import Dentaplus from 'shared/assets/images/dentaplus.png'
import Direction from 'shared/assets/images/direction.png'
import DivisionChoice from 'shared/assets/images/division_choice.png'
import Doctor from 'shared/assets/images/doctor.png'
import DoctorChoice from 'shared/assets/images/doctor_choice.png'
import Document from 'shared/assets/images/document.png'
import DocumentError from 'shared/assets/images/document_error.png'
import Download from 'shared/assets/images/download.png'
import Efficiency from 'shared/assets/images/efficiency.png'
import Email from 'shared/assets/images/email.png'
import EmailNook from 'shared/assets/images/email_nook.png'
import EmailOk from 'shared/assets/images/email_ok.png'
import Error from 'shared/assets/images/error.png'
import Expert from 'shared/assets/images/expert.png'
import Family from 'shared/assets/images/family.png'
import Father from 'shared/assets/images/father.png'
import Files from 'shared/assets/images/files.png'
import Glasses from 'shared/assets/images/glasses.png'
import Home from 'shared/assets/images/home.png'
import HospitalChoice from 'shared/assets/images/hospital_choice.png'
import Hospitalization from 'shared/assets/images/hospitalization.png'
import Inscription from 'shared/assets/images/inscription.png'
import Login from 'shared/assets/images/login.png'
import Logout from 'shared/assets/images/logout.png'
import Maintenance from 'shared/assets/images/maintenance.png'
import MedicalEmergencies from 'shared/assets/images/medical_emergencies.png'
import Medicines from 'shared/assets/images/medicines.png'
import Megafon from 'shared/assets/images/megafon.png'
import Mother from 'shared/assets/images/mother.png'
import Network from 'shared/assets/images/network.png'
import Notif from 'shared/assets/images/notif.png'
import Notification from 'shared/assets/images/notification.png'
import OnlineServices from 'shared/assets/images/online_services.png'
import OptimaFlexVaria from 'shared/assets/images/optima_flex_varia.png'
import Pecunia from 'shared/assets/images/pecunia.png'
import Pending from 'shared/assets/images/pending.png'
import Pharmacy from 'shared/assets/images/pharmacy.png'
import Pharmed from 'shared/assets/images/pharmed.png'
import PhoneRinging from 'shared/assets/images/phone_ringing.png'
import PlusmedBis from 'shared/assets/images/plusmedbis.png'
import Price from 'shared/assets/images/price.png'
import PrivateDivision from 'shared/assets/images/private_division.png'
import Profile from 'shared/assets/images/profile.png'
import Question from 'shared/assets/images/question.png'
import Recommendedproductdefault from 'shared/assets/images/recommendedProductDefault.png'
import Refunds from 'shared/assets/images/refunds.png'
import Reimbursement from 'shared/assets/images/reimbursement.png'
import Repatriation from 'shared/assets/images/repatriation.png'
import Rescue from 'shared/assets/images/rescue.png'
import Search from 'shared/assets/images/search.png'
import SecurityCheck from 'shared/assets/images/security_check.png'
import SecurityError from 'shared/assets/images/security_error.png'
import SecurityQuestion from 'shared/assets/images/security_question.png'
import Send from 'shared/assets/images/send.png'
import Signal from 'shared/assets/images/signal.png'
import SimplifiedSteps from 'shared/assets/images/simplified_steps.png'
import Son from 'shared/assets/images/son.png'
import Supplement from 'shared/assets/images/supplement.png'
import SymptomCheckerForm from 'shared/assets/images/symptom_checker_form.png'
import Telemedicine from 'shared/assets/images/telemedicine.png'
import Time from 'shared/assets/images/time.png'
import ToggleOff from 'shared/assets/images/toggle_off.png'
import ToggleOn from 'shared/assets/images/toggle_on.png'
import Travel from 'shared/assets/images/travel.png'
import ValidWorldwide from 'shared/assets/images/valid_worldwide.png'
import Validated from 'shared/assets/images/validated.png'

// the list of illustrations
export const assets = {
    access: Access,
    accident: Accident,
    address: Address,
    alternative_medicine: AlternativeMedicine,
    assistance: Assistance,
    assuraLogo: Assuralogo,
    assurcall: Assurcall,
    baby: Baby,
    basis: Basis,
    bonus: Bonus,
    cancellation: Cancellation,
    capital: Capital,
    capital_choice: CapitalChoice,
    children: Children,
    client_care: ClientCare,
    contact: Contact,
    contract: Contract,
    coverage: Coverage,
    daughter: Daughter,
    declaration: Declaration,
    delete: Delete,
    dental_care: DentalCare,
    dentaplus: Dentaplus,
    direction: Direction,
    division_choice: DivisionChoice,
    doctor: Doctor,
    doctor_choice: DoctorChoice,
    document: Document,
    document_error: DocumentError,
    download: Download,
    efficiency: Efficiency,
    email: Email,
    email_nook: EmailNook,
    email_ok: EmailOk,
    error: Error,
    expert: Expert,
    family: Family,
    father: Father,
    files: Files,
    glasses: Glasses,
    home: Home,
    hospitalization: Hospitalization,
    hospital_choice: HospitalChoice,
    login: Login,
    logout: Logout,
    maintenance: Maintenance,
    medical_emergencies: MedicalEmergencies,
    medicines: Medicines,
    megafon: Megafon,
    mother: Mother,
    network: Network,
    notif: Notif,
    notification: Notification,
    online_services: OnlineServices,
    optima_flex_varia: OptimaFlexVaria,
    pecunia: Pecunia,
    pending: Pending,
    pharmacy: Pharmacy,
    pharmed: Pharmed,
    phone_ringing: PhoneRinging,
    plusmed_bis: PlusmedBis,
    price: Price,
    private_division: PrivateDivision,
    profile: Profile,
    question: Question,
    recommendedProductDefault: Recommendedproductdefault,
    refunds: Refunds,
    reimbursement: Reimbursement,
    repatriation: Repatriation,
    rescue: Rescue,
    search: Search,
    security_check: SecurityCheck,
    security_error: SecurityError,
    security_question: SecurityQuestion,
    send: Send,
    signal: Signal,
    simplified_steps: SimplifiedSteps,
    son: Son,
    supplement: Supplement,
    symptom_checker_form: SymptomCheckerForm,
    telemedicine: Telemedicine,
    time: Time,
    toggle_off: ToggleOff,
    toggle_on: ToggleOn,
    travel: Travel,
    validated: Validated,
    valid_worldwide: ValidWorldwide,
    inscription: Inscription
}

/// This converter can be used to map an icon name from other name
/// As I don't want to change the name in the source (where I get the data potentially used by other teams)
/// I need to target the corresponding Icon in my system.
/// TODO: This is a temporary solution and should be replaced by a better alternative.
export const assetNameCmsConverter = (assetName: string): string => {
    switch (assetName) {
        case 'familyDoctor':
            return 'doctor'
        case 'invoice':
            return 'contract'
        case 'pharmacie':
            return 'pharmacy'
        default:
            return assetName
    }
}

/// This get the icon reference, if you are sure about the name then omit the converter and spell it in asset parameter
/// You can also use the asset object (defined previously in this file) ... import asset from ... this file. then GetAssetIcon(asset.theWantedAssetName)
/// If the received name is not the correct one regarding the asset you can add the mapping in the converter : assetNameCmsConverter above
export const GetAssetIcon = (
    asset: string,
    converter?: (name: string) => string
): string | undefined => {
    let assetName: string
    if (!converter) {
        assetName = asset
    } else {
        assetName = converter(asset)
    }

    const output = Object.entries(assets).find((o) => o[0] === assetName)?.[1]

    return output ? output : undefined
}
