import './ServicesPersonalData.css'

import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import PersonalDataFooter from 'modules/services/personalData/components/PersonalDataFooter/PersonalDataFooter'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import {
    getRequests,
    getServicesRequestsLoadingStatus
} from 'shared/store/services/requests/requests.slice'
import { getSelectedMemberPolicyNumber } from 'shared/store/services/services.slice'

import PersonalDataBlock from '../components/PersonalDataBlock/PersonalDataBlock'
import {
    initialPersonalDataState,
    PersonalDataProvider
} from '../components/PersonalDataBlock/PersonalDataContext'
import ServicesPersonalDataTitle from '../components/ServicesPersonalDataTitle/ServicesPersonalDataTitle'

const ServicesPersonalData = (): JSX.Element => {
    const dispatch = useDispatch()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)
    const contactDataLoadStatus = useSelector(getFullPersonalData).contactDataLoadStatus
    const pendingRequestsLoadStatus = useSelector(getServicesRequestsLoadingStatus)

    const initValues = { ...initialPersonalDataState }

    return (
        <PersonalDataProvider initValues={initValues}>
            <AssuraLoadAndError
                status={getLoadingStatusFromLoaderList([
                    contactDataLoadStatus,
                    pendingRequestsLoadStatus
                ])}
                defaultReloadAction={() => {
                    if (contactDataLoadStatus === LoadingStatusEnum.ERROR && selectedPolicyNumber)
                        dispatch(
                            fetchContactInfos({
                                selectedPolicyNumber,
                                reload: true
                            })
                        )
                    if (pendingRequestsLoadStatus === LoadingStatusEnum.ERROR)
                        dispatch(getRequests())
                }}
            >
                <FullScreenContainer color="gray20">
                    <ServicesPersonalDataTitle />
                </FullScreenContainer>
                <PersonalDataBlock />
                <FullScreenContainer color="gray20">
                    <Container>
                        <PersonalDataFooter />
                    </Container>
                </FullScreenContainer>
            </AssuraLoadAndError>
        </PersonalDataProvider>
    )
}

export default ServicesPersonalData
