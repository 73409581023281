import './SideNavigationBackground.css'

import useWindowSize from 'core/services/useWindowSize'

import { useSideNavigationContext } from './SideNavigationProvider'

const SideNavigationBackground = (): JSX.Element => {
    const { isMobileContentSelection } = useSideNavigationContext()
    const { isMobile } = useWindowSize()

    return (
        <div className="side-navigation-page-background">
            <div
                className="bg-gray50"
                style={{ flex: isMobileContentSelection ? 0 : 1 }}
                data-testid="side-navigation-page-background-gray"
            />
            <div
                className="bg-white"
                style={{ flex: isMobileContentSelection || !isMobile ? 1 : 0 }}
                data-testid="side-navigation-page-background-white"
            />
        </div>
    )
}

export default SideNavigationBackground
