import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'

import FaqCategoriesResponsive from '../FaqCategoriesList/FaqCategoriesListResponsive'
import FaqSearch from '../FaqSearch/FaqSearch'

const FaqHeader = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    return (
        <FullScreenContainer color="gray50">
            <Container className="p-top-56 p-bottom-56">
                <div className="title break-word m-bottom-24" data-testid="faq-title">
                    {t('FAQ.TITLE')}
                </div>
                <div className="paragraph m-bottom-24">{t('FAQ.SUBTITLE')}</div>
                <FaqSearch />
                {isMobile && <FaqCategoriesResponsive />}
            </Container>
        </FullScreenContainer>
    )
}

export default FaqHeader
