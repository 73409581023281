import { useTranslation } from 'react-i18next'

import { Product } from 'core/models/Product'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface ProductCardFooterProps {
    product: Product
    openProductPanel: () => void
}

const ProductCardFooter = ({ product, openProductPanel }: ProductCardFooterProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()

    const accidentIconClass = product.accident
        ? 'assura-check-circle c-black'
        : 'assura-error c-gray500'
    const accidentTextClass = product.accident ? 'c-black' : 'c-gray500'
    const accidentText = product.accident ? 'INSURANCE.ACCIDENT' : 'INSURANCE.NO_ACCIDENT'

    return (
        <div className="product-card-footer-container btc-gray100">
            <div
                className="product-card-footer-accident-container"
                data-testid={`product-card-footer-${product.insuranceId}`}
            >
                <span className={'labelExtraSmall ' + accidentTextClass} style={{ marginRight: 8 }}>
                    {t(accidentText)}
                </span>
                <i className={'icon size-16 d-flex ' + accidentIconClass} />
            </div>
            {product.detailAvailable && !product.isFuturLamal && (
                <div className="product-card-footer-accident-link">
                    <AssuraIconButton
                        id={`product-card-footer-button-${product.insuranceId}`}
                        icon="assura-next"
                        variant="primary"
                        size="medium"
                        onClick={openProductPanel}
                        classNames={isXSD ? 'm-0' : 'm-left-8'}
                        label={t('COMMON.SEE_DETAIL')}
                    />
                </div>
            )}
        </div>
    )
}

export default ProductCardFooter
