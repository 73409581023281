import './PhotoItem.css'

interface PhotoItemProps {
    id: string
    label: string
    value: string
    classNames?: string
}

const PhotoItem = ({ id, label, value, classNames }: PhotoItemProps): JSX.Element => {
    return (
        <div
            className={`d-flex personal-data-photo-item-container${
                classNames ? ' ' + classNames : ''
            }`}
        >
            <div
                className="labelMedium personal-data-photo-item-label"
                data-testid={`personal-data-photo-${id}-label`}
            >
                {label}
            </div>
            <div
                className="label personal-data-photo-item-value c-gray500"
                data-testid={`personal-data-photo-${id}-value`}
            >
                {value}
            </div>
        </div>
    )
}

export default PhotoItem
