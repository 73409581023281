import { BannerMessage, MainBannerItem } from 'core/models/Banners'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_BANNERS: MainBannerItem[] = []

export const bannersSlice = createSlice({
    name: 'banners',
    initialState: INITIAL_STATE_BANNERS,
    reducers: {
        setBanner: (state, _action: PayloadAction<BannerMessage>) => {
            return state
        },
        setForbiddenBanner: (state) => {
            return state
        },
        addBanner: (state, action: PayloadAction<MainBannerItem>) => {
            state.push(action.payload)
        },
        removeBanner: (state, action: PayloadAction<string>) => {
            return state.filter((banner) => banner.id !== action.payload)
        },
        removeAllNonPermanentBanners: (state) => {
            return state.filter((banner) => banner.permanent)
        },
        resetBanners: () => INITIAL_STATE_BANNERS
    }
})

export const {
    addBanner,
    setForbiddenBanner,
    removeBanner,
    setBanner,
    removeAllNonPermanentBanners,
    resetBanners
} = bannersSlice.actions

export const getBanners: Selector<RootState, MainBannerItem[]> = (state) => state.banners

export default bannersSlice.reducer
