import { useSelector } from 'react-redux'

import { TranslatedEnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { getDateFilterSelections } from 'core/helpers/CommonAssuraTableFilters'
import { TableFilterSelections, TableFilterState } from 'core/models/TableFilter'
import { getPolicyNumberSelectionsForFilter } from 'shared/store/selectors/getPolicyNumberSelectionsForFilter'

import { EnveloppesFilterSelectionValue } from './FinancialsEnveloppes'

const getEnveloppesFilterState = (
    hasRescanStatus: boolean,
    defaultPolicyNumber?: number
): TableFilterState<EnveloppesFilterSelectionValue> => {
    const filters: TableFilterState<EnveloppesFilterSelectionValue> = {}

    filters['date'] = {
        label: 'DOCUMENTS.FILTER_BY_YEAR',
        selections: getDateFilterSelections()
    }

    const memberSelections = useSelector(getPolicyNumberSelectionsForFilter)
    const newSelections = memberSelections ? new Map(memberSelections) : null

    if (newSelections) {
        if (defaultPolicyNumber) {
            const defaultSelection = Array.from(newSelections.entries()).find(
                ([_key, item]) => item.value === defaultPolicyNumber
            )
            if (defaultSelection)
                newSelections.set(defaultSelection[0], {
                    ...defaultSelection[1],
                    isSelected: true
                })
        }
        filters['member'] = {
            label: 'COMMON_INSURED',
            selections: newSelections
        }
    }

    const statusSelections: TableFilterSelections<TranslatedEnveloppeStatus> = new Map([
        [
            TranslatedEnveloppeStatus.Received,
            {
                label: TranslatedEnveloppeStatus.Received,
                value: TranslatedEnveloppeStatus.Received,
                isSelected: false,
                shouldBeTranslated: true
            }
        ],
        [
            TranslatedEnveloppeStatus.Processed,
            {
                label: TranslatedEnveloppeStatus.Processed,
                value: TranslatedEnveloppeStatus.Processed,
                isSelected: false,
                shouldBeTranslated: true
            }
        ],
        [
            TranslatedEnveloppeStatus.PartiallyProcessed,
            {
                label: TranslatedEnveloppeStatus.PartiallyProcessed,
                value: TranslatedEnveloppeStatus.PartiallyProcessed,
                isSelected: false,
                shouldBeTranslated: true
            }
        ]
    ])

    if (hasRescanStatus) {
        statusSelections.set(TranslatedEnveloppeStatus.Rescan, {
            label: TranslatedEnveloppeStatus.Rescan,
            value: TranslatedEnveloppeStatus.Rescan,
            isSelected: false,
            shouldBeTranslated: true
        })
    }

    filters['status'] = {
        label: 'DOCUMENTS.FILTER_BY_STATUS',
        selections: statusSelections
    }

    return filters
}

export default getEnveloppesFilterState
