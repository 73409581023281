import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import {
    getEnveloppeLoadingStatus,
    onFetchEnveloppes
} from 'shared/store/enveloppes/enveloppes.slice'
import { getEnveloppesForLastSends } from 'shared/store/selectors/getEnveloppesForLastSends'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'

import HomeBlock from '../HomeBlock/HomeBlock'
import LastSendsRow from './LastSendsRow'
import NoEnveloppes from './NoEnveloppes'

const LastSends = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const enveloppesForLastSends = useSelector(getEnveloppesForLastSends)
    const hasEnveloppes = enveloppesForLastSends.lastSends.length > 0
    const hasMoreEnveloppes = enveloppesForLastSends.totalEnveloppes > 0

    const enveloppesLoadingStatus = useSelector(getEnveloppeLoadingStatus)

    const selectedMember = useSelector(getSelectedFamilyMember)

    let url = `${navigationConstants.FINANCIALS.url}?item=enveloppes`
    if (selectedMember?.policyNumber) {
        url = `${url}&policyNumber=${selectedMember.policyNumber}`
    }

    return (
        <HomeBlock
            title={t('HOME.ENVELOPPES_LAST_TITLE')}
            testId="enveloppes"
            loadingStatus={enveloppesLoadingStatus}
            reloadAction={() => dispatch(onFetchEnveloppes())}
            headerAction={hasMoreEnveloppes ? () => navigate(url) : undefined}
        >
            {hasEnveloppes ? (
                <>
                    {enveloppesForLastSends.lastSends.map((row) => (
                        <LastSendsRow row={row} url={url} key={row.id} />
                    ))}
                </>
            ) : (
                <NoEnveloppes
                    message={
                        enveloppesForLastSends.totalEnveloppes === 0
                            ? t('DOCUMENTS.ERROR_NOTHING_TO_SENT')
                            : t('HOME.ENVELOPPES_NO_LAST_MESSAGE')
                    }
                />
            )}
        </HomeBlock>
    )
}

export default LastSends
