import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'

import { RenderCurrentPageLabelProps } from '@react-pdf-viewer/page-navigation'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'

interface InvoicesToCheckPanelHeaderLeftProps {
    insuredPersonName: string | undefined
    insuredPersonLastName: string | undefined
    statusIsOk: boolean
    toolbarPluginInstance: ToolbarPlugin
}

const InvoicesToCheckPanelHeaderLeft = ({
    insuredPersonName,
    insuredPersonLastName,
    statusIsOk,
    toolbarPluginInstance
}: InvoicesToCheckPanelHeaderLeftProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const { hasLeftPanel, hasPDFPanel } = usePanelDatasContext().panelDatas

    const headerMargin = isMobile ? 16 : 32

    const initials =
        insuredPersonName && insuredPersonLastName
            ? (insuredPersonName.charAt(0) + insuredPersonLastName.charAt(0)).toUpperCase()
            : ''

    const { CurrentPageLabel } = toolbarPluginInstance.pageNavigationPluginInstance

    return (
        <>
            <AssuraAvatar
                id="itc-panel-avatar"
                label={
                    insuredPersonName
                        ? {
                              title: insuredPersonName,
                              classNames: 'labelExtraSmallMedium c-gray500',
                              marginTop: 4
                          }
                        : undefined
                }
                mainContainerWidth={64}
                avatarContainerWidth={32}
            >
                <div className="labelSmallMedium">{initials}</div>
            </AssuraAvatar>
            {!isMobile && statusIsOk && (!hasLeftPanel || hasPDFPanel) && (
                <div className="d-flex labelSmall c-gray700">
                    <div className="p-right-12" style={{ marginLeft: `${headerMargin}px` }}>
                        {t('COMMON.PAGE')}
                    </div>
                    <CurrentPageLabel>
                        {(props: RenderCurrentPageLabelProps) => (
                            <div>{`${props.currentPage + 1} / ${props.numberOfPages}`}</div>
                        )}
                    </CurrentPageLabel>
                </div>
            )}
        </>
    )
}

export default InvoicesToCheckPanelHeaderLeft
