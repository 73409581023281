interface RequestsPanelSubtitleProps {
    label: string
    children?: JSX.Element
}

const RequestsPanelSubtitle = ({ label, children }: RequestsPanelSubtitleProps): JSX.Element => {
    return (
        <div className="requests-panel-main-block d-flex justify-content-between align-items-center m-bottom-24">
            <div className="labelMedium">{label}</div>
            {children}
        </div>
    )
}

export default RequestsPanelSubtitle
