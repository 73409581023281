import './AssuraCarousel.css'

import React, { useRef } from 'react'
import Slider from 'react-slick'

interface ResponsiveSetting {
    breakpoint: number
    settings: {
        slidesToShow: number
        slidesToScroll: number
        infinite?: boolean
        dots?: boolean
        initialSlide?: number
    }
}
interface CarouselProps {
    children: JSX.Element[]
    responsive?: ResponsiveSetting[]
    autoplay?: boolean
    speed?: number
    autoplaySpeed?: number
    infinite?: boolean
    cssEase?: string
    slidesToShow?: number
    slidesToScroll?: number
    nextArrow?: JSX.Element | undefined
    prevArrow?: JSX.Element | undefined
    dotsClass?: string
    pauseOnHover?: boolean
    pauseOnDotsHover?: boolean
    beforeChange?: (oldIndex: number, newIndex: number) => void
    className?: string
}
const AssuraCarousel = ({
    responsive,
    autoplay,
    autoplaySpeed,
    speed,
    infinite,
    cssEase,
    slidesToShow,
    slidesToScroll,
    nextArrow,
    prevArrow,
    dotsClass,
    pauseOnHover,
    pauseOnDotsHover,
    children,
    beforeChange,
    className,
}: CarouselProps) => {
    const sliderRef = useRef<Slider | null>(null)
    const hasMultipleSlides = children?.length > 1

    const pauseAutoPlay = () => {
        if (autoplay && sliderRef.current) {
            sliderRef.current.slickPause()
        }
    }
    return (
        <div onClick={pauseAutoPlay}>
            <Slider
                ref={sliderRef}
                dots={hasMultipleSlides}
                responsive={responsive}
                autoplay={autoplay}
                infinite={infinite}
                speed={speed}
                autoplaySpeed={autoplaySpeed}
                cssEase={cssEase}
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                nextArrow={hasMultipleSlides ? nextArrow : undefined}
                prevArrow={hasMultipleSlides ? prevArrow : undefined}
                dotsClass={dotsClass}
                pauseOnHover={pauseOnHover}
                pauseOnDotsHover={pauseOnDotsHover}
                beforeChange={beforeChange}
                className={className}
            >
                {children}
            </Slider>
        </div>
    )
}

export default AssuraCarousel
