import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    BasicInfoFromForm,
    ContactInfos,
    ContactInfosLoadingPayload,
    EmailFormValues,
    EmailFromForm,
    PhoneFormValues,
    PhoneFromForm,
    SetContactInfosPayload
} from 'core/models/services/personalData/ContactInfos'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

interface FetchContactInfosPayload {
    reload?: boolean
    selectedPolicyNumber: number
}

const INITIAL_STATE_CONTACT_INFOS: Record<number, ContactInfos> = {}

const initialState = {
    datas: undefined,
    loadingStatus: LoadingStatusEnum.LOADING
}

export const contactInfosSlice = createSlice({
    name: 'contactInfos',
    initialState: INITIAL_STATE_CONTACT_INFOS,
    reducers: {
        fetchContactInfos: (
            state: Record<number, ContactInfos>,
            _action: PayloadAction<FetchContactInfosPayload>
        ) => {
            return state
        },
        setContactInfos: (
            state: Record<number, ContactInfos>,
            action: PayloadAction<SetContactInfosPayload>
        ) => {
            state[action.payload.selectedPolicyNumber] = {
                ...state[action.payload.selectedPolicyNumber],
                datas: action.payload.newDatas
            }
        },
        setLoadingStatus: (
            state: Record<number, ContactInfos>,
            action: PayloadAction<ContactInfosLoadingPayload>
        ) => {
            if (!state[action.payload.selectedPolicyNumber])
                state[action.payload.selectedPolicyNumber] = { ...initialState }

            state[action.payload.selectedPolicyNumber] = {
                ...state[action.payload.selectedPolicyNumber],
                loadingStatus: action.payload.value
            }
        },
        postBasicInfo: (
            state: Record<number, ContactInfos>,
            _action: PayloadAction<BasicInfoFromForm>
        ) => {
            return state
        },
        postPhone: (state: Record<number, ContactInfos>, _action: PayloadAction<PhoneFromForm>) => {
            return state
        },
        postEmail: (state: Record<number, ContactInfos>, _action: PayloadAction<EmailFromForm>) => {
            return state
        },
        setPhoneFormValues: (
            state: Record<number, ContactInfos>,
            action: PayloadAction<PhoneFormValues>
        ) => {
            const selectedMemberDatas = state[action.payload.policyNumber]?.datas

            if (selectedMemberDatas) {
                selectedMemberDatas.phone = action.payload.datas
            }
        },
        setEmailFormValue: (
            state: Record<number, ContactInfos>,
            action: PayloadAction<EmailFormValues>
        ) => {
            const selectedMemberDatas = state[action.payload.policyNumber]?.datas

            if (selectedMemberDatas) {
                selectedMemberDatas.email = action.payload.newEmail
            }
        },
        reset: () => INITIAL_STATE_CONTACT_INFOS
    }
})

export const {
    fetchContactInfos,
    setContactInfos,
    setLoadingStatus,
    postBasicInfo,
    postPhone,
    postEmail,
    setPhoneFormValues,
    setEmailFormValue,
    reset
} = contactInfosSlice.actions

export const getContactInfos: Selector<RootState, Record<number, ContactInfos>> = (state) =>
    state.servicesContactInfos

export default contactInfosSlice.reducer
