const assuraIconButtonSizeProps = {
    small: {
        iconSize: 16,
        labelFont: 'labelExtraSmallMedium',
        labelMargin: 4
    },
    medium: {
        iconSize: 24,
        labelFont: 'labelSmall',
        labelMargin: 8
    }
}

export default assuraIconButtonSizeProps
