import './HomePersonalDataPersonBig.css'

import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface HomePersonalDataPersonBigProps {
    firstName: string
    lastName: string
    policyNumber: number
    editAction: () => void
}

const HomePersonalDataPersonBig = ({
    firstName,
    lastName,
    policyNumber,
    editAction
}: HomePersonalDataPersonBigProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    return (
        <div className="home-personal-data-person-big-container">
            <AssuraAvatar
                id="home-avatar"
                avatarContainerWidth={isMobile ? 104 : 160}
                border={{
                    width: 4,
                    color: 'white'
                }}
            >
                <div className="headlineLargeMedium">
                    {(firstName.charAt(0) + lastName.charAt(0)).toUpperCase()}
                </div>
            </AssuraAvatar>
            <div className="home-personal-data-person-big-infos">
                <div
                    className={`c-black ${
                        isMobile ? 'labelMedium' : 'headlineSmallMedium'
                    } d-flex align-items-center flex-wrap`}
                    data-testid="home-avatar-label"
                >{`${firstName} ${lastName}`}</div>
                <div className="labelSmall c-gray500">
                    {t('FAMILY_GROUP.POLICY_NUMBER', { policyNumber })}
                </div>
            </div>
            <AssuraIconButton
                id="home-edit-button"
                icon="assura-edit"
                variant="primary"
                size="medium"
                onClick={editAction}
                classNames="home-personal-data-person-big-edit"
            />
        </div>
    )
}

export default HomePersonalDataPersonBig
