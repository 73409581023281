import './InvoicesToCheckPanelHeaderCenter.css'

import { useTranslation } from 'react-i18next'

import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { InvoicesToCheckIsEquals } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import { InvoiceCheckingDetails } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import useWindowSize from 'core/services/useWindowSize'

interface InvoicesToCheckPanelHeaderCenterProps {
    selectedInvoice?: InvoiceCheckingDetails
}

const InvoicesToCheckPanelHeaderCenter = ({
    selectedInvoice
}: InvoicesToCheckPanelHeaderCenterProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const isClosed = InvoicesToCheckIsEquals(
        [
            InvoiceCheckingStatusEnum.EXPIRED,
            InvoiceCheckingStatusEnum.CHECKED,
            InvoiceCheckingStatusEnum.REFUSED
        ],
        selectedInvoice?.status ?? ''
    )

    const statusLabel = isClosed
        ? 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.CHECKED'
        : 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.TO_CHECK'

    const label = `${t('COMMUNICATIONS.INVOICES_TO_CHECK.TITLE')} ${t('COMMUNICATIONS.INVOICES_TO_CHECK.TITLE.FOR')} ${selectedInvoice?.insuredPersonName}`

    const dueDateLabel = `${t(
        `COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS${!isXL ? '' : '.MOBILE'}.DUE_DATE_LABEL`
    )} : ${selectedInvoice?.responseDeadline && formatDate(selectedInvoice?.responseDeadline)}`

    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <div
                    data-testid="itc-panel-document-name"
                    className={`justify-content-center width-inherit d-flex ${
                        !isXL ? 'labelSmallMedium' : 'labelMedium'
                    }`}
                >
                    <span className="assura-panel-center-block-ellipsis">{label}</span>
                </div>
            </div>
            <div className="itc-panel-center-second-line">
                {selectedInvoice?.status === InvoiceCheckingStatusEnum.TO_CHECK && (
                    <>
                        <span
                            className={`labelSmall c-primary`}
                            data-testid={`itc-panel-creation-date`}
                        >
                            {dueDateLabel}
                        </span>
                        <div className="itc-panel-field-separator bg-gray300" />
                    </>
                )}
                <i
                    className={`icon assura-${selectedInvoice?.status === InvoiceCheckingStatusEnum.TO_CHECK ? 'a-remplir c-primary' : 'controle-facture c-success'} size-${!isXL ? '16' : '24'}`}
                    data-testid={`${selectedInvoice?.status === InvoiceCheckingStatusEnum.TO_CHECK ? 'a-remplir' : 'controle-facture'}-icon`}
                />
                <span
                    className={`${
                        !isXL ? 'labelExtraSmall' : 'labelSmall'
                    } itc-status-field text-ellipsis c-gray500`}
                    data-testid={`itc-panel-status`}
                >
                    {t(statusLabel ?? '')}
                </span>
            </div>
        </>
    )
}

export default InvoicesToCheckPanelHeaderCenter
