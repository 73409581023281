import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { MaritalStatusEnum } from 'core/enums/FamilyGroup'
import { translateMaritalStatus } from 'core/helpers/FamilyGroupHelper'
import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'

interface MaritalStatusSelectProps {
    userGender: string
}

const MaritalStatusSelect = ({ userGender }: MaritalStatusSelectProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const { isFormSelected } = usePersonalDataContext()

    const maritalTypeMap = new Map() as SelectMap<MaritalStatusEnum>

    for (const value in MaritalStatusEnum) {
        maritalTypeMap.set(t(translateMaritalStatus(value, userGender)), value as MaritalStatusEnum)
    }

    const rules: RegisterOptions = {
        required: {
            value: true,
            message: 'COMMON.MANDATORY_FIELD'
        }
    }

    return (
        <AssuraFormSelectRHF
            name="maritalStatus"
            id="services-personal-data-marital-status"
            label={t('FAMILY_GROUP.BASIC_INFO_EDIT_MARITAL_STATUS_LABEL')}
            items={maritalTypeMap}
            inline={isXL}
            labelSize={4}
            placeHolder={t('PERSONAL_DATA.MARITAL_STATUS_PLACEHOLDER')}
            rules={rules}
            disabled={!isFormSelected(PersonalDataForm.BASIC)}
        />
    )
}

export default MaritalStatusSelect
