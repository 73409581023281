import './RequestsPanelHeader.css'

import { useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestRow } from 'core/models/services/RequestData'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraIconButtonBelow from 'shared/components/AssuraIconButtonBelow/AssuraIconButtonBelow'

interface RequestsPanelHeaderProps {
    selectedRequest?: RequestRow
    closePanel: () => void
}

const RequestsPanelHeader = ({
    selectedRequest,
    closePanel
}: RequestsPanelHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL, isXSD, isMobile } = useWindowSize()
    const panelMargin = isMobile ? 16 : 32
    const insuredPersonName = selectedRequest?.insuredPersonName
    const insuredPersonLastName = selectedRequest?.insuredPersonLastName
    const initials =
        insuredPersonName && insuredPersonLastName
            ? (insuredPersonName.charAt(0) + insuredPersonLastName.charAt(0)).toUpperCase()
            : ''

    const panelRef = useRef<HTMLDivElement>(null)
    const leftRef = useRef<HTMLDivElement>(null)
    const centerRef = useRef<HTMLDivElement>(null)
    const rightRef = useRef<HTMLDivElement>(null)

    const statusLabelClass = isXSD ? 'labelExtraSmall' : 'labelSmall'

    useLayoutEffect(() => {
        if (panelRef.current && leftRef.current && rightRef.current && centerRef.current) {
            centerRef.current.style.width = `${
                panelRef.current.offsetWidth -
                leftRef.current.offsetWidth -
                rightRef.current.offsetWidth -
                4 * panelMargin
            }px`
        }
    })
    return (
        <div className="requests-panel-header-container bbc-gray100" ref={panelRef}>
            {selectedRequest && (
                <>
                    <div className="request-panel-avatar-container" ref={leftRef}>
                        <AssuraAvatar
                            id="requests-panel-header-avatar"
                            label={
                                insuredPersonName
                                    ? {
                                          title: insuredPersonName,
                                          classNames: 'labelExtraSmallMedium c-gray500',
                                          marginTop: 4
                                      }
                                    : undefined
                            }
                            mainContainerWidth={64}
                            avatarContainerWidth={32}
                        >
                            <div className="labelSmallMedium">{initials}</div>
                        </AssuraAvatar>
                    </div>
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        ref={centerRef}
                    >
                        <div
                            className={`${
                                isXSD ? 'labelSmallMedium' : 'labelMedium'
                            } text-center request-panel-header-ellipsis`}
                            style={{ marginBottom: '4px' }}
                        >
                            {t('COMMON_REQUEST')}
                        </div>
                        <div className="request-panel-status d-flex justify-content-center align-items-center">
                            {!isMobile && (
                                <>
                                    <div
                                        data-testid={`requests-panel-header-creation-date-${selectedRequest.index}`}
                                        className={`${
                                            isXSD ? 'labelExtraSmallMedium' : 'labelSmall'
                                        } c-gray500`}
                                        key={`requests-panel-header-creation-date-${selectedRequest.index}`}
                                    >
                                        {formatDate(selectedRequest.creationDate ?? '')}
                                    </div>
                                    <div className="requests-panel-header-separator bg-gray300" />
                                </>
                            )}
                            <div
                                className="d-flex align-items-center"
                                data-testid={`requests-panel-status-${selectedRequest.index}`}
                            >
                                <i
                                    className={`icon ${selectedRequest.statusIcon} ${selectedRequest.statusColor} size-24`}
                                />
                                <span
                                    className={`requests-panel-header-status-field ${statusLabelClass}`}
                                    key={`status-${selectedRequest.index}`}
                                >
                                    {selectedRequest.processingDate &&
                                    selectedRequest.statusTranslated === 'STATUS.COMPLETED_DATE'
                                        ? t('STATUS.COMPLETED_DATE', {
                                              date: formatDate(selectedRequest.processingDate)
                                          })
                                        : t(selectedRequest.statusTranslated)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="requests-panel-header-close-container" ref={rightRef}>
                        {isXL ? (
                            <AssuraIconButtonBelow
                                id="close-panel"
                                icon="assura-close"
                                variant="default"
                                iconSize={24}
                                onClick={closePanel}
                                label={t('COMMON.CLOSE')}
                                labelFontClass="labelExtraSmallMedium"
                                margin={4}
                            />
                        ) : (
                            <AssuraIconButton
                                id="close-panel"
                                icon="assura-close"
                                variant="default"
                                size="medium"
                                onClick={closePanel}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default RequestsPanelHeader
