import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getDeductibleContributionOfSelectedFamilyMember } from 'shared/store/selectors/getDeductibleContributionOfSelectedFamilyMember'
import { goToServicesForm } from 'shared/store/services/services.slice'

interface PrimesPanelLamalProps {
    lamal: ProductPerson
}
const PrimesPanelLamal = ({ lamal }: PrimesPanelLamalProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const deductibleContribution = useSelector(getDeductibleContributionOfSelectedFamilyMember)

    return (
        <div className="d-flex flex-column">
            <div className="d-flex">
                <div className="d-flex flex-1">
                    <div className="headlineSmallMedium">{t('COVERAGE.LAMAL_TITLE')}</div>
                    <AssuraIconButton
                        id="primes-change-model"
                        icon="assura-edit"
                        variant="primary"
                        size="small"
                        onClick={() =>
                            dispatch(goToServicesForm({ type: ServiceType.DEDUCTIBLE_CHANGE }))
                        }
                        classNames="m-left-8"
                    />
                </div>
                <div
                    className="headlineSmallMedium m-left-16"
                    data-testid="primes-panel-lamal-gross"
                >
                    {formatNumber(lamal.premium.gross)}
                </div>
            </div>
            <div className="d-flex flex-column m-top-16">
                <div className="paragraphSmallMedium" data-testid="primes-panel-lamal-title">
                    {lamal.title?.toUpperCase()}
                </div>
                <div className="paragraphSmallLight" data-testid="primes-panel-lamal-deductible">
                    {t('PRIMES.LAMAL_DEDUCTIBLE', {
                        deductible: formatNumber(deductibleContribution.deductible, false)
                    })}
                </div>
            </div>
        </div>
    )
}

export default PrimesPanelLamal
