import './ActivityIndicator.css'

import { ElementType } from 'react'
import Spinner from 'react-bootstrap/Spinner'

import concatClassNames from 'core/utils/classNameUtils'

export type ActivityIndicatorSize = 24 | 40

interface ActivityIndicatorProps<C extends ElementType> {
    variant?: string
    size?: ActivityIndicatorSize
    classNames?: string
    as?: C
}

const ActivityIndicator = <C extends ElementType = 'div'>({
    variant = 'primary',
    size = 24,
    classNames,
    as
}: ActivityIndicatorProps<C>): JSX.Element => {
    const As = as || 'div'

    const containerClass = concatClassNames(['loading-container', classNames])
    return (
        <As data-testid="loading-indicator-container" className={containerClass}>
            <Spinner
                animation="border"
                variant={variant}
                style={{ height: size, width: size, borderWidth: `${size === 24 ? 2 : 3}px` }}
                data-testid="loading-indicator"
            />
        </As>
    )
}

export default ActivityIndicator
