import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { frequentRequestsCmsData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FrequentRequestCms } from 'core/models/cms/FrequentRequestCms'

import {
    fetchFrequentRequests,
    setFrequentRequests,
    setFrequentRequestsLoadingStatus
} from './frequentRequests.slice'

export function* fetchFrequentRequestsSaga() {
    try {
        yield put(setFrequentRequestsLoadingStatus(LoadingStatusEnum.LOADING))
        const frequentRequests: FrequentRequestCms[] = yield call(frequentRequestsCmsData)
        yield put(setFrequentRequests(frequentRequests))
        yield put(setFrequentRequestsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchFrequentRequestsSaga Error', e)
        yield put(setFrequentRequestsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchFrequentRequestsWatcher() {
    yield takeEvery(fetchFrequentRequests.type, fetchFrequentRequestsSaga)
}

const watchers = [fork(fetchFrequentRequestsWatcher)]

export default watchers
