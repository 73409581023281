import './InsuranceCard.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { MissingDataType } from 'core/enums/MissingData'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import {
    InsuranceCardForm,
    InsuranceCardFormRHF
} from 'core/models/services/certificates/InsuranceCardForm'
import { getInlineAddress } from 'core/utils/addressUtils'
import { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import FormPage from 'modules/services/components/FormPage/FormPage'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'
import NoActiveContract from 'modules/services/components/NoActiveContract/NoActiveContract'
import NoValidAddress from 'modules/services/components/NoValidAddress/NoValidAddress'
import PendingRequest from 'modules/services/components/PendingRequest/PendingRequest'
import ProvisionalCertificate from 'modules/services/components/ProvisionalCertificate/ProvisionalCertificate'
import addressIcon from 'shared/assets/images/address.svg'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { isThereInsuranceCardInRequests } from 'shared/store/selectors/isThereInRequests'
import { getHasActiveLamalOfSelectedFamilyMember } from 'shared/store/selectors/services/getHasActiveContractOfSelectedFamilyMember'
import { onSubmitInsuranceCard } from 'shared/store/services/certificates/certificates.slice'
import {
    fetchContactDataAndProducts,
    getFamilyGroupResult,
    goToServicesForm
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

const InsuranceCard = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const personalData = useSelector(getFullPersonalData)
    const familyGroupResult = useSelector(getFamilyGroupResult)

    const initialDefaultValues: InsuranceCardFormRHF = {
        provisionalCertificate: false
    }
    const [defaultValues, setDefaultValues] = useState<InsuranceCardFormRHF>(initialDefaultValues)
    const hasActiveContract = useSelector(getHasActiveLamalOfSelectedFamilyMember)
    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const hasPendingRequest = useSelector(isThereInsuranceCardInRequests)

    const memberDataLoadingStatus = getLoadingStatusFromLoaderList([
        contactDataLoadStatus,
        productsLoaderStatus
    ])

    const missingData = useSelector(getMissingDataInRequest)
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    const onSuccess = (values: InsuranceCardFormRHF) => {
        const insuranceCardForm: InsuranceCardForm = {
            ...values,
            ...familyGroupResult
        }
        dispatch(onSubmitInsuranceCard(insuranceCardForm))
    }
    const { address } = personalData

    useEffect(() => {
        setDefaultValues({ ...defaultValues })
    }, [personalData])

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactDataAndProducts({
                    policyNumber: familyGroupResult.selectedPolicyNumber
                })
            )
    }

    const getNotAllowed = () => {
        if (
            familyGroupResult.selectedPolicyNumber &&
            missingData[familyGroupResult.selectedPolicyNumber] &&
            missingData[familyGroupResult.selectedPolicyNumber].includes(
                MissingDataType.ADDRESS_MISSING
            )
        ) {
            return <NoValidAddress message={t('SERVICES.NO_VALID_ADDRESS_DESCRIPTION')} />
        }
        if (!hasActiveContract) {
            return <NoActiveContract />
        }

        return undefined
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.COVER_CARD_ORDER}
            formTitle={{
                title: t('SERVICES.INSURANCE_CARD_ORDER_TITLE'),
                category: t('SERVICES.TAX_CERTIFICATE_REQUEST')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.COVER_CARD_ORDER} />}
            isPending={
                hasPendingRequest ? (
                    <PendingRequest>
                        <div className="provisional-dl-container m-top-32 bg-gray20 bc-gray100">
                            <div className="paragraphSmallLight m-bottom-32">
                                {t('SERVICES.INSURANCE_CARD_PROVISIONAL_LABEL')}
                            </div>
                            <AssuraIconButton
                                id="provisional-certificate-link"
                                icon="assura-next"
                                variant="primary"
                                size="medium"
                                onClick={() =>
                                    dispatch(
                                        goToServicesForm({
                                            type: ServiceType.PROVI_REPL_CERTIFICATE
                                        })
                                    )
                                }
                                label={t('CERTIFICATE.PROVISIONAL_DL')}
                            />
                        </div>
                    </PendingRequest>
                ) : undefined
            }
            notAllowed={getNotAllowed()}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    memberDataLoadingStatus,
                    missingDataLoadingStatus
                ]),
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactDataAndProducts({
                            policyNumber: familyGroupResult.selectedPolicyNumber as number,
                            reload: true
                        })
                    )
            }}
        >
            <>
                {Source() === ServicesSource.CUSTOMER_PORTAL && (
                    <div className="personal-data-address-container d-flex align-items-center paragraph c-black bg-white bc-gray100 m-top-32">
                        <img
                            src={addressIcon}
                            className="image-size-48 m-right-12"
                            alt="address-icon"
                        />
                        <div>
                            <div className="personal-data-address-title labelSmallMedium c-black">
                                {t('SERVICES.INSURANCE_CARD_SENT_HOME')}
                            </div>
                            <div className="labelExtraSmall c-gray500">
                                {getInlineAddress(address.data)}
                            </div>
                        </div>
                    </div>
                )}
                <ProvisionalCertificate />
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    InsuranceCard,
    'InsuranceCard',
    'insurance-card-container'
)
