import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import repatriationIcon from 'shared/assets/images/repatriation.svg'

interface NoValidAddressProps {
    message: string
}

const NoValidAddress = ({ message }: NoValidAddressProps): JSX.Element => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div
            data-testid="no-valid-address"
            className="border-solid-width-1 bg-white bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center"
        >
            <img src={repatriationIcon} className="image-size-56" alt="repatriation-icon" />
            <div className="headlineSmall m-top-24 m-bottom-16">
                {t('SERVICES.NO_VALID_ADDRESS_TITLE')}
            </div>
            <div className="labelSmallLight m-bottom-24">{message}</div>
            <div
                className="assura-link-default paragraphSmallLight c-primary"
                onClick={() => navigate(navigationConstants.PERSONAL_DATA.url)}
                data-testid="no-valid-address-link"
            >
                {t('SERVICES.NO_VALID_ADDRESS_LINK')}
            </div>
        </div>
    )
}

export default NoValidAddress
