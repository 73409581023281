import { useTranslation } from 'react-i18next'

import { ClaimsSettlement } from 'core/models/documents/ClaimsSettlement'

import ClaimsSettlementPanelReceiptItem from './ClaimsSettlementPanelReceiptItem'

interface ClaimsSettlementPanelReceiptListProps {
    selectedClaimsSettlement: ClaimsSettlement
}

const ClaimsSettlementPanelReceiptList = ({
    selectedClaimsSettlement
}: ClaimsSettlementPanelReceiptListProps): JSX.Element => {
    const { t } = useTranslation()

    if (selectedClaimsSettlement.documents?.length > 0) {
        return (
            <div className="claims-settlement-panel-receipt-list-container">
                <div className="claims-settlement-panel-adaptive-padding-horizontal headlineSmallMedium">
                    {t('CLAIMS_SETTLEMENT.RELATED_RECEIPTS')}
                </div>
                <div className="p-top-8">
                    {selectedClaimsSettlement.documents.map((receipt, index) => (
                        <ClaimsSettlementPanelReceiptItem
                            key={index.toString()}
                            index={index}
                            receipt={receipt}
                        />
                    ))}
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default ClaimsSettlementPanelReceiptList
