import './Footer.css'

import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { getECRoute } from 'core/helpers/NavigationHelper'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getPath, getRedirectionParameters } from 'shared/store/general/general.slice'
import { setKeepSelectedMember, setSelectedServiceForm } from 'shared/store/services/services.slice'

const Footer = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const redirectionParameters = useSelector(getRedirectionParameters)
    const path = useSelector(getPath)

    const isHowItWorks = () => {
        return getECRoute(path)?.url === navigationConstants.LAMAL_RULES.url
    }

    const goBack = () => {
        dispatch(setKeepSelectedMember(undefined))
        dispatch(setSelectedServiceForm(undefined))
        if (Source() === ServicesSource.WEBSITE) {
            window.location.href = redirectionParameters.origin
        } else {
            navigate(-1)
        }
    }

    const containerContent = () => (
        <div
            className="d-flex footer-container footer-back-container"
            {...(isHowItWorks() && { style: { marginLeft: '32px' } })}
        >
            <AssuraIconButton
                id="service-footer"
                icon="assura-back"
                variant="primary"
                size="medium"
                onClick={goBack}
                label={t('COMMON.GO_BACK_PREVIOUS_PAGE')}
            />
        </div>
    )

    return (
        <FullScreenContainer complementaryClasses="bbc-gray100 bg-white footer-full-container">
            {isHowItWorks() ? (
                <>{containerContent()}</>
            ) : (
                <Container>{containerContent()}</Container>
            )}
        </FullScreenContainer>
    )
}

export default Footer
