import './AssuraTableFilter.css'

import { forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
    FilterSelectionValue,
    TableFilter,
    TableFilterId,
    TableFilterSelectionId
} from 'core/models/TableFilter'
import { TranslationKeyWithVariable } from 'core/models/Translations'

interface AssuraTableFilterProps<T extends FilterSelectionValue> {
    id: TableFilterId
    filter: TableFilter<T>
    onFilterSelection: (filterId: TableFilterId, selectionId: TableFilterSelectionId | null) => void
    isDisabled?: boolean
}

const AssuraTableFilter = <T extends FilterSelectionValue>({
    id,
    filter,
    onFilterSelection,
    isDisabled
}: AssuraTableFilterProps<T>): JSX.Element => {
    const { label, selections } = filter
    const { t } = useTranslation()

    const handleSelection = (selectionId: string | null) => {
        onFilterSelection(id, selectionId)
    }

    const currentSelectionId = Array.from(selections.keys()).find(
        (id) => selections.get(id)?.isSelected
    )
    const currentSelection = selections.get(currentSelectionId ?? '')

    const getLabel = (
        label: string | TranslationKeyWithVariable,
        shouldBeTranslated: boolean | undefined
    ) => {
        if (shouldBeTranslated) {
            return typeof label === 'object' ? t(label.key, { ...label.variableObject }) : t(label)
        }
        return label as string
    }

    const CustomToggle = forwardRef<
        HTMLDivElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>
    >(({ onClick }, ref) => {
        return (
            <div
                ref={ref}
                className={`assura-table-filter-toggle${currentSelection ? ' no-arrow' : ''}`}
                onClick={(e) => {
                    if (!isDisabled && onClick) onClick(e)
                }}
            >
                <div
                    className={`flex-1 text-left ${
                        currentSelection ? 'labelSmallMedium c-black' : 'labelSmall c-gray700'
                    }`}
                    data-testid={`assura-table-filter-${id}-toggle`}
                >
                    {currentSelection
                        ? getLabel(currentSelection.label, currentSelection.shouldBeTranslated)
                        : t(label)}
                </div>
                {currentSelection && (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        onClick={(e) => {
                            e.stopPropagation()
                            if (!isDisabled) handleSelection(null)
                        }}
                        data-testid={`assura-table-filter-${id}-toggle-reset`}
                    >
                        <i className="icon assura-close size-24 c-black" />
                    </div>
                )}
            </div>
        )
    })

    return (
        <td>
            <Dropdown onSelect={handleSelection} className="w-100">
                <Dropdown.Toggle
                    id={`assura-table-filter-${id}-toggle`}
                    as={CustomToggle}
                    disabled={true}
                />
                <Dropdown.Menu className="assura-table-filter-menu">
                    {Array.from(selections).map(([selectionId, selectionData]) => (
                        <Dropdown.Item
                            data-testid={`assura-table-filter-${id}-selection-${selectionId}`}
                            key={`${id}-filter-selection-${selectionId}`}
                            eventKey={selectionId}
                            className={
                                selectionData.isSelected
                                    ? 'bg-error-background labelSmallMedium'
                                    : 'labelSmall'
                            }
                        >
                            <div className="max-2-text-lines">
                                {getLabel(selectionData.label, selectionData.shouldBeTranslated)}
                            </div>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </td>
    )
}

export default AssuraTableFilter
