/* eslint-disable sonarjs/cognitive-complexity */

import { TranslatedEnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { DateFilterSelectionValue } from 'core/helpers/CommonAssuraTableFilters'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import { TableFilterState } from 'core/models/TableFilter'
import { isAfter } from 'date-fns'

import { EnveloppesFilterSelectionValue } from './FinancialsEnveloppes'

export const getFilteredEnveloppes = (
    enveloppes: EnveloppeRow[],
    filterState: TableFilterState<EnveloppesFilterSelectionValue>
): EnveloppeRow[] => {
    let newEnveloppes = [...enveloppes]

    // Year filter
    const yearSelections = filterState['date']?.selections

    if (yearSelections) {
        for (const selection of yearSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as DateFilterSelectionValue
                newEnveloppes = newEnveloppes.filter((row) => {
                    return (
                        Boolean(row.receiveDate) &&
                        isAfter(new Date(row.receiveDate ?? ''), value.startDate) &&
                        isAfter(value.endDate, new Date(row.receiveDate ?? ''))
                    )
                })
            }
        }
    }

    // Member filter
    const memberSelections = filterState['member']?.selections

    if (memberSelections) {
        for (const selection of memberSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as number
                newEnveloppes = newEnveloppes.filter((row) => row.policyNumber === value)
            }
        }
    }

    // Status filter
    const statusSelections = filterState['status']?.selections

    if (statusSelections) {
        for (const selection of statusSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as TranslatedEnveloppeStatus
                newEnveloppes = newEnveloppes.filter((row) => row.statusTranslated === value)
            }
        }
    }

    return newEnveloppes
}
