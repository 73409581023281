import './TabDropdownItem.css'

import { AssuraTabData, ItemMetaData } from 'core/models/TabData'

import { AssuraTabBadge } from '../AssuraTabBadge/AssuraTabBadge'
import { IconNamesEnum, isIcon } from './Icon'
import TabAvatar from './TabAvatar'
import TabIcon from './TabIcon'
import TabInitials, { isInitials } from './TabInitials'

const defaultValue = (id: number, value: AssuraTabData) => (
    <span
        className={'tab-dropdown-item-value-container max-2-text-lines ellipsis'}
        data-testid={`${id}-dropdown-value-item`}
    >
        {value.label}
    </span>
)

const defaultLeftItem = (id: number, metaData: ItemMetaData) => (
    // this rule: ${metaData.imageUrl || metaData.value ? avoids to apply the css if no left item is needed
    <span className={`${metaData.imageUrl || metaData.value ? 'p-right-16' : ''}`}>
        {metaData.imageUrl ? (
            <TabAvatar src={metaData.imageUrl} dataId={`${id}-tab-avatar-img`} {...metaData} />
        ) : isIcon(metaData.value as IconNamesEnum) ? (
            <TabIcon
                name={metaData.value as IconNamesEnum}
                dataId={`${id}-dropdown-left-item`}
                {...metaData}
            />
        ) : isInitials(metaData.value as IconNamesEnum) ? (
            <TabInitials
                initials={metaData.value as IconNamesEnum}
                dataId={`${id}-dropdown-left-item`}
                {...metaData}
            />
        ) : (
            <>{/* HERE GOES MORE FALLBACKS */}</>
        )}
    </span>
)

const defaultRightItem = (id: number, metaData: ItemMetaData) => (
    <span className={'tab-dropdown-right-item'}>
        <AssuraTabBadge data={metaData} dataId="dropdown-right-item" id={id} />
    </span>
)

export type DropdownItemProps = {
    data: AssuraTabData
    show?: boolean
    // overrides the default value component.
    valueTemplate?: (data: AssuraTabData) => React.ReactElement
    // overrides the default left component.
    leftTemplate?: (data: ItemMetaData) => React.ReactElement
    // overrides the default right component.
    rightTemplate?: (data: ItemMetaData) => React.ReactElement
}

const TabDropdownItem = ({
    data,
    show,
    valueTemplate,
    leftTemplate,
    rightTemplate
}: DropdownItemProps) => {
    return (
        <div
            className={`tab-dropdown-item ${
                show ? 'tab-dropdown-item-show' : 'tab-dropdown-item-hidden'
            }`}
        >
            {data.leftItem &&
                (leftTemplate
                    ? leftTemplate(data.leftItem)
                    : defaultLeftItem(data.uniqueKey, data.leftItem))}
            {valueTemplate ? valueTemplate(data) : defaultValue(data.uniqueKey, data)}
            {data.rightItem &&
                (rightTemplate
                    ? rightTemplate(data.rightItem)
                    : defaultRightItem(data.uniqueKey, data.rightItem))}
        </div>
    )
}

export default TabDropdownItem
