import { IconButtonVariants } from 'core/enums/IconButtonVariants'

interface AssuraIconButtonBelowProps {
    id: string
    icon: string
    variant: IconButtonVariants
    iconSize: number
    onClick: () => void
    classNames?: string
    label: string
    labelFontClass: string
    margin: number
}

const AssuraIconButtonBelow = ({
    id,
    icon,
    variant,
    iconSize,
    onClick,
    classNames,
    label,
    labelFontClass,
    margin
}: AssuraIconButtonBelowProps): JSX.Element => {
    return (
        <div
            data-testid={id}
            className={`icon-container clickable ${variant} ${classNames ? classNames : ''}`}
            style={{
                flexDirection: 'column'
            }}
            onClick={onClick}
        >
            <i className={`icon ${icon} size-${iconSize}`} data-testid={`${id}-icon`} />
            <div
                className={labelFontClass}
                style={{
                    marginTop: `${margin}px`
                }}
            >
                {label}
            </div>
        </div>
    )
}

export default AssuraIconButtonBelow
