import { UndefinedMaritalStatusEnum } from 'core/enums/FamilyGroup'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { DiscountsPerson } from 'core/models/familyGroup/DiscountsPerson'
import { ProductsPerson } from 'core/models/familyGroup/ProductsPerson'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { HandleThrowError } from 'core/models/HandleThrowError'
import { BasicInfoFromForm } from 'core/models/services/personalData/ContactInfos'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface FamilySummariesState {
    isDoubleAuthenticationActive: null | boolean
    summaryLoadingStatus: LoadingStatusEnum
    familySummaries: Record<number, SummaryResult>
    familyProductsLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_FAMILY_SUMMARIES: FamilySummariesState = {
    isDoubleAuthenticationActive: null,
    summaryLoadingStatus: LoadingStatusEnum.LOADING,
    familySummaries: {},
    familyProductsLoadingStatus: LoadingStatusEnum.LOADING
}

export interface DataFetchSummaryByYear extends HandleThrowError {
    year: number
    shouldFetchProducts: boolean
    reload?: boolean
}

export interface DataFetchSummaryByYearSuccess {
    year: number
    summary: SummaryResult
}

interface DataFetchProductsByMemberAndByYear extends HandleThrowError {
    year: number
    index: number
    reload?: boolean
}

export interface DataUpdateProductsByMemberAndByYear {
    year: number
    selectedMemberIndex: number
    products: ProductsPerson
}

export type DataFetchDiscountsByMemberAndByYear = DataFetchProductsByMemberAndByYear

export interface DataUpdateDiscountsByMemberAndByYear {
    year: number
    selectedMemberIndex: number
    discounts: DiscountsPerson
}

export interface PersonalDataFormValues extends BasicInfoFromForm {
    year: number
    index: number
}

export const familySummariesSlice = createSlice({
    name: 'familySummaries',
    initialState: INITIAL_STATE_FAMILY_SUMMARIES,
    reducers: {
        fetchSummaryByYear: (state, _action: PayloadAction<DataFetchSummaryByYear>) => {
            return state
        },
        fetchSummaryByYearSuccess: (
            state,
            action: PayloadAction<DataFetchSummaryByYearSuccess>
        ) => {
            state.familySummaries[action.payload.year] = action.payload.summary
        },
        fetchProductsByMemberAndByYear: (
            state,
            _action: PayloadAction<DataFetchProductsByMemberAndByYear>
        ) => {
            return state
        },
        updateProductsByMemberAndByYear: (
            state,
            action: PayloadAction<DataUpdateProductsByMemberAndByYear>
        ) => {
            const member =
                state.familySummaries[action.payload.year]?.insuredPersons[
                    action.payload.selectedMemberIndex
                ]
            if (member) member.productsPerson = action.payload.products
        },
        fetchDiscountsByMemberAndByYear: (
            state,
            _action: PayloadAction<DataFetchDiscountsByMemberAndByYear>
        ) => {
            return state
        },
        updateDiscountsByMemberAndByYear: (
            state,
            action: PayloadAction<DataUpdateDiscountsByMemberAndByYear>
        ) => {
            const member =
                state.familySummaries[action.payload.year]?.insuredPersons[
                    action.payload.selectedMemberIndex
                ]
            if (member) member.discountsPerson = action.payload.discounts
        },
        setSummaryLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.summaryLoadingStatus = action.payload
        },
        setIsDoubleAuthenticationActive: (state, action: PayloadAction<boolean>) => {
            state.isDoubleAuthenticationActive = action.payload
        },
        setPersonalDataFormValues: (
            state: FamilySummariesState,
            action: PayloadAction<PersonalDataFormValues>
        ) => {
            const { firstName, lastName, maritalStatus } = action.payload
            const selectedMember =
                state.familySummaries[action.payload.year]?.insuredPersons[action.payload.index]

            if (selectedMember) {
                selectedMember.firstName = firstName
                selectedMember.lastName = lastName
                selectedMember.maritalStatus = maritalStatus ?? UndefinedMaritalStatusEnum.unknown
            }
        },
        setFamilyProductsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.familyProductsLoadingStatus = action.payload
        },
        reset: () => INITIAL_STATE_FAMILY_SUMMARIES
    }
})

export const {
    fetchSummaryByYear,
    fetchSummaryByYearSuccess,
    fetchProductsByMemberAndByYear,
    updateProductsByMemberAndByYear,
    fetchDiscountsByMemberAndByYear,
    updateDiscountsByMemberAndByYear,
    setSummaryLoadingStatus,
    setIsDoubleAuthenticationActive,
    setPersonalDataFormValues,
    setFamilyProductsLoadingStatus,
    reset
} = familySummariesSlice.actions

export const getFamilySummaries: Selector<RootState, Record<number, SummaryResult>> = (state) =>
    state.familySummaries.familySummaries

export const getSummaryLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.familySummaries.summaryLoadingStatus

export const getIsDoubleAuthenticationActive: Selector<RootState, null | boolean> = (state) =>
    state.familySummaries.isDoubleAuthenticationActive

export const getFamilyProductsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.familySummaries.familyProductsLoadingStatus

export default familySummariesSlice.reducer
