import { isSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { openBase64InNewTab } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

import { RenderDownloadProps } from '@react-pdf-viewer/get-file'
import { RenderCurrentPageLabelProps } from '@react-pdf-viewer/page-navigation'
import { RenderPrintProps } from '@react-pdf-viewer/print'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom'

interface CommunicationsPanelHeaderProps {
    selectedDocument: DocumentRow
    closePanel: () => void
    toolbarPluginInstance: ToolbarPlugin
    statusIsOk: boolean
    doc?: AssuraDocument
}

const CommunicationsPanelHeader = ({
    selectedDocument,
    closePanel,
    toolbarPluginInstance,
    statusIsOk,
    doc
}: CommunicationsPanelHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile, isXL } = useWindowSize()

    const insuredPersonName = selectedDocument?.insuredPersonName
    const insuredPersonLastName = selectedDocument?.insuredPersonLastName

    // LEFT-BLOCK
    const initials =
        insuredPersonName && insuredPersonLastName
            ? (insuredPersonName.charAt(0) + insuredPersonLastName.charAt(0)).toUpperCase()
            : ''

    const { CurrentPageLabel } = toolbarPluginInstance.pageNavigationPluginInstance

    const CommunicationsHeaderLeft = (): JSX.Element => {
        return (
            <>
                <AssuraAvatar
                    id="communications-avatar-initials"
                    label={
                        insuredPersonName
                            ? {
                                  title: insuredPersonName,
                                  classNames: 'labelExtraSmallMedium c-gray500',
                                  marginTop: 4
                              }
                            : undefined
                    }
                    mainContainerWidth={64}
                    avatarContainerWidth={32}
                >
                    <div className="labelSmallMedium">{initials}</div>
                </AssuraAvatar>
                <div className="d-flex align-items-center labelSmall c-gray700 communications-panel-m-left">
                    {!isMobile && statusIsOk && (
                        <>
                            <div className="p-right-12">{t('COMMON.PAGE')}</div>
                            <CurrentPageLabel>
                                {(props: RenderCurrentPageLabelProps) => (
                                    <div>{`${props.currentPage + 1} / ${props.numberOfPages}`}</div>
                                )}
                            </CurrentPageLabel>
                        </>
                    )}
                </div>
            </>
        )
    }

    // CENTER-BLOCK
    const CommunicationsHeaderCenter = (): JSX.Element => {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        data-testid="communications-header-document-name"
                        className="labelMedium assura-panel-center-block-ellipsis"
                    >
                        {selectedDocument?.documentName}
                    </div>
                </div>
            </>
        )
    }

    // RIGHT-BLOCK
    const { Download } = toolbarPluginInstance.getFilePluginInstance
    const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance
    const { Print } = toolbarPluginInstance.printPluginInstance

    // INFO: The Print plugin doesn't work on Safari
    const onSafariPrint = () => {
        if (doc) openBase64InNewTab(doc.base64)
    }

    const CommunicationsHeaderRight = (): JSX.Element => {
        return (
            <div className="d-flex justify-content-center align-items-center communications-panel-m-right">
                {statusIsOk && (
                    <>
                        {isXL && (
                            <>
                                <ZoomOut>
                                    {(props: RenderZoomOutProps) => (
                                        <AssuraIconButton
                                            id="communications-panel-header-zoom-out"
                                            icon="assura-zoom-out"
                                            variant="default"
                                            size="medium"
                                            onClick={props.onClick}
                                            classNames="m-right-16"
                                        />
                                    )}
                                </ZoomOut>
                                <ZoomIn>
                                    {(props: RenderZoomInProps) => (
                                        <AssuraIconButton
                                            id="communications-panel-header-zoom-in"
                                            icon="assura-zoom"
                                            variant="default"
                                            size="medium"
                                            onClick={props.onClick}
                                        />
                                    )}
                                </ZoomIn>
                            </>
                        )}
                        <Download>
                            {(props: RenderDownloadProps) => (
                                <AssuraIconButton
                                    id="communications-panel-header-download"
                                    icon="assura-download"
                                    variant="primary"
                                    size="medium"
                                    onClick={props.onClick}
                                    classNames="m-left-16"
                                />
                            )}
                        </Download>
                        {isXL && (
                            <Print>
                                {(props: RenderPrintProps) => (
                                    <AssuraIconButton
                                        id="communications-panel-header-print"
                                        icon="assura-print"
                                        variant="primary"
                                        size="medium"
                                        onClick={isSafari ? onSafariPrint : props.onClick}
                                        classNames="m-left-16"
                                    />
                                )}
                            </Print>
                        )}
                    </>
                )}
            </div>
        )
    }

    return (
        <AssuraPanelHeader
            leftBlock={<CommunicationsHeaderLeft />}
            centerBlock={<CommunicationsHeaderCenter />}
            rightBlock={<CommunicationsHeaderRight />}
            closePanel={closePanel}
        />
    )
}

export default CommunicationsPanelHeader
