import './ProductCard.css'

import { Product } from 'core/models/Product'
import ProductCardHeader from 'shared/components/ProductCardHeader/ProductCardHeader'

import ProductCardBody from './ProductCardBody'
import ProductCardFooter from './ProductCardFooter'

interface ProductCardProps {
    product: Product
    setProductPanel: (product: Product) => void
}

const ProductCard = (props: ProductCardProps) => {
    const { product, setProductPanel } = props

    const cardBackgroundColor = product.startDate ? 'bg-gray20' : 'bg-white'

    const openProductPanel = () => setProductPanel(product)

    return (
        <div className={'product-card-container bc-gray100 ' + cardBackgroundColor}>
            <div className="product-card-content-container">
                <ProductCardHeader
                    title={product.title}
                    subtitle={product.categoryTitle}
                    icon={product.iconPath}
                    productId={product.insuranceId}
                />
                <ProductCardBody product={product} />
                <ProductCardFooter product={product} openProductPanel={openProductPanel} />
            </div>
        </div>
    )
}

export default ProductCard
