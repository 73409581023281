import './HomePersonalDataContract.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import PeriodicityTrads from 'core/constants/productPeriodicityTraductions'
import { ProductPeriodicity } from 'core/enums/Product'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getNetPremiums } from 'shared/store/combineSelectors'
import {
    fetchFamilyMember,
    getFamilyMemberIndex
} from 'shared/store/familyMember/familyMember.slice'
import { getProductPeriodicity } from 'shared/store/selectors/getProductPeriodicity'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { useAppDispatch } from 'shared/store/store'

const HomePersonalDataContract = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const netPremiums = useSelector(getNetPremiums)
    const periodicity = useSelector(getProductPeriodicity)
    const familyMemberIndex = useSelector(getFamilyMemberIndex)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)

    const shouldDisplayRebate =
        periodicity === ProductPeriodicity.ANNUAL || periodicity === ProductPeriodicity.BIANNUAL

    return (
        <div className="home-personal-data-contract-container">
            <AssuraLoadAndError
                status={productsLoaderStatus}
                activityIndicatorSize={24}
                defaultReloadAction={() =>
                    dispatch(fetchFamilyMember({ selectedIndex: familyMemberIndex, reload: true }))
                }
            >
                <>
                    <div className="home-personal-data-contract-separator" />
                    <div className="d-flex flex-column m-bottom-16">
                        <div
                            className="d-flex labelExtraSmall m-bottom-4 c-gray500"
                            data-testid="home-personal-data-rate-amount-title"
                        >
                            {t('STATEMENTS.PANEL_AMOUNT')}
                        </div>
                        <div
                            className="d-flex labelMedium"
                            data-testid="home-personal-data-rate-amount-value"
                        >
                            {t('COMMON.PRICE_PER_MONTH', { price: netPremiums.toFixed(2) })}
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <div
                            className="d-flex labelExtraSmall m-bottom-4 c-gray500"
                            data-testid="home-personal-data-rate-periodicity-title"
                        >
                            {t('HOME.PREMIUMS_PERIODICITY')}
                        </div>
                        <div
                            className="d-flex labelMedium"
                            data-testid="home-personal-data-rate-periodicity-value"
                        >
                            {periodicity
                                ? t(PeriodicityTrads[periodicity])
                                : t('HOME.PREMIUMS_PERIODICITY')}
                        </div>
                    </div>
                    {shouldDisplayRebate && (
                        <div className="d-flex flex-row m-top-24">
                            <div className="d-flex m-right-8 home-personal-data-rate-disclamer-icon">
                                <i className="icon assura-info-circle size-16"></i>
                            </div>
                            <div className={`d-flex paragraphSmall`}>
                                <p
                                    className="paragraphSmall"
                                    data-testid="home-personal-data-rate-disclamer-text"
                                >
                                    {t('PREMIUMS.REBATE', {
                                        rebate:
                                            periodicity === ProductPeriodicity.ANNUAL ? '2' : '1'
                                    })}
                                </p>
                            </div>
                        </div>
                    )}
                </>
            </AssuraLoadAndError>
        </div>
    )
}

export default HomePersonalDataContract
