import './AssuraText.css'

import { Form } from 'react-bootstrap'

interface AssuraTextProp {
    id?: string
    isInvalid?: boolean
    placeholder?: string
    type?: string
    classNames?: string
    value: string
}

const AssuraText = ({ ...props }: AssuraTextProp) => (
    <Form.Control
        id={props.id}
        data-testid={props.id}
        type={props.type}
        disabled={false}
        readOnly={true}
        placeholder={props.placeholder}
        className={props.classNames ? props.classNames : 'label'}
        value={props.value}
    />
)

export default AssuraText
