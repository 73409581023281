import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getCurrentAndFutureDoctorsOfSelectedFamilyMember } from './getCurrentAndFutureDoctorsOfSelectedFamilyMember'

export const getSortedDoctorsOfSelectedFamilyMember = createSelector(
    [getCurrentAndFutureDoctorsOfSelectedFamilyMember],
    (currentAndFutureDoctors) => {
        return currentAndFutureDoctors.sort((a, b) => {
            return isBefore(new Date(a.startDate), new Date(b.startDate)) ? -1 : 1
        })
    }
)
