import './Insurances.css'

import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import { Product } from 'core/models/Product'
import { AssuraTabData } from 'core/models/TabData'
import useQuery from 'core/services/useQuery'
import { getCurrentYear } from 'core/utils/dateUtils'
import { openInNewTab } from 'core/utils/onClickUtils'
import { getPersonTabFromPolicyNumber, peopleToTab } from 'core/utils/TabUtils'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { AssuraTabContainer } from 'shared/components/AssuraTabContainer/AssuraTabContainer'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import PremiumsRebate from 'shared/components/PremiumsRebate/PremiumsRebate'
import { setBanner } from 'shared/store/banners/banners.slice'
import {
    getFutureProductsOfSelectedFamilyMember,
    getLamalProductOfSelectedFamilyMemberSelectedYear,
    getLcaProductsOfSelectedFamilyMember,
    getNetPremiums,
    getRecommendedProductsOfSelectedFamilyMember,
    getSummaryByPage
} from 'shared/store/combineSelectors'
import {
    fetchFamilyMember,
    fetchInitialPolicyNumber,
    getFamilyMemberIndex,
    getInitialPolicyNumber
} from 'shared/store/familyMember/familyMember.slice'
import {
    fetchDiscountsByMemberAndByYear,
    getSummaryLoadingStatus
} from 'shared/store/familySummaries/familySummaries.slice'
import { getLang } from 'shared/store/general/general.slice'
import { fetchProductsYear } from 'shared/store/products/products.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getProductPeriodicity } from 'shared/store/selectors/getProductPeriodicity'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'
import { RootState } from 'shared/store/store'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import InsurancesLamal from '../components/InsurancesLamal/InsurancesLamal'
import InsurancesNoLamal from '../components/InsurancesNoLamal/InsurancesNoLamal'
import InsurancesTitle from '../components/InsurancesTitle/InsurancesTitle'
import NoContract from '../components/NoContract/NoContract'
import PrimesPanel from '../components/PrimesPanel/PrimesPanel'
import ProductCardList from '../components/ProductCardList/ProductCardList'
import RecommendedProductCardList from '../components/RecommendedProductCardList/RecommendedProductCardList'

const Insurances = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = useSelector(getLang) as keyof AssetUrlCms
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms
    // Used to keep track of selected Tab
    const [selectedPerson, setSelectedPerson] = useState<AssuraTabData | undefined>(undefined)

    // Used to keep track of selected ID while changing year:
    const [reloadId, setReloadId] = useState<number | undefined>(undefined)

    // Get the current Year
    const selectedYear = useSelector((state: RootState) => state.products.year)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)

    const netPremiums = useSelector(getNetPremiums)
    const periodicity = useSelector(getProductPeriodicity)
    const familySummary = useSelector(getSummaryByPage)
    const familyMemberIndex = useSelector(getFamilyMemberIndex)

    const futureProductsOfSelectedFamilyMember = useSelector(
        getFutureProductsOfSelectedFamilyMember
    )

    const lcaProductsOfSelectedFamilyMember = useSelector(getLcaProductsOfSelectedFamilyMember)
    const recommendedProductsOfSelectedFamilyMember = useSelector(
        getRecommendedProductsOfSelectedFamilyMember
    )
    const lamalOfSelectedFamilyMemberSelectedYear = useSelector(
        getLamalProductOfSelectedFamilyMemberSelectedYear
    )

    const memberPolicyNumber = useQuery().get('policy')
    const initialPolicyNumber = useSelector(getInitialPolicyNumber)

    const [isDiscountsPanelOpen, setIsDiscountsPanelOpen] = useState(false)

    const peopleTabs = peopleToTab(familySummary?.insuredPersons)

    useEffect(() => {
        // if a initial policy number ("How It Works") is not set, reset the year to current one
        if (!initialPolicyNumber) {
            dispatch(fetchProductsYear({ year: getCurrentYear() }))
        }
        const policyNumber = memberPolicyNumber && +memberPolicyNumber
        // if policy was passed as query
        if (policyNumber) {
            switchPerson(policyNumber)
            return
        }
        // if policy number is in the store, it is the "How It Works" page closing
        if (initialPolicyNumber) {
            switchPerson(initialPolicyNumber)
            dispatch(fetchInitialPolicyNumber({ initialPolicyNumber: 0 }))
            return
        }
        // reset selected person
        setSelectedPerson(undefined)
        //reset to first tab
        switchSelectedPerson(0)
    }, [])

    // if year is changed, tabs collection changed, if selectedPerson is not the selected in the Tabs collection.
    // then set the flag to the selected parson flag to the policy number to reload and reset selected person
    useEffect(() => {
        if (peopleTabs.length === 0 || !selectedPerson) return
        if (!peopleTabs.find((o) => o.selectedValue === selectedPerson?.uniqueKey)) {
            setReloadId(selectedPerson?.uniqueKey)
            setSelectedPerson(undefined)
        }
    }, [peopleTabs.length, selectedPerson])

    // if reload flag is changed then change to the correct Tab
    useEffect(() => {
        if (!reloadId || peopleTabs.length === 0 || selectedPerson) return

        switchSelectedPerson(reloadId)
        setReloadId(undefined)
    }, [reloadId])

    // Select by policy number
    const switchPerson = (policy: number) => {
        if (peopleTabs.length === 0) return
        const tmpSelectedPerson = getPersonTabFromPolicyNumber(peopleTabs, policy)
        switchSelectedPerson(tmpSelectedPerson.uniqueKey)
    }

    // Select by person or tab ID
    const switchSelectedPerson = (id: number) => {
        const person = peopleTabs.find((o) => o.uniqueKey === id)
        if (!person || person.businessId === selectedPerson?.businessId) return
        setSelectedPerson(person)
        dispatch(fetchFamilyMember({ selectedIndex: id }))
    }

    const AssetToLink = (item: AssetUrlCms) => {
        const cmsLink = (function () {
            if (lang === ENGLISH_LANGUAGE && !item.en) return item[contactLang] || undefined
            return item[lang] || undefined
        })()
        if (cmsLink) {
            return cmsLink
        }
    }

    const shouldDisplayContract =
        lamalOfSelectedFamilyMemberSelectedYear ||
        lcaProductsOfSelectedFamilyMember.length > 0 ||
        futureProductsOfSelectedFamilyMember.lca.length > 0 ||
        futureProductsOfSelectedFamilyMember.lamal.length > 0

    const renderTabContainer = () =>
        peopleTabs &&
        peopleTabs.length > 1 && (
            <AssuraTabContainer
                data={peopleTabs}
                selectedId={selectedPerson?.uniqueKey}
                dataId={'insurances-tabs'}
                onClick={switchSelectedPerson}
            />
        )

    const renderRecommendedProducts = (
        recommendedProductsOfSelectedFamilyMember: RecommendedProduct[]
    ) => (
        <FullScreenContainer color="gray20">
            <Container>
                <div style={{ marginTop: 120, marginBottom: 120}}>
                    <div className="headlineLarge text-center break-word p-bottom-56">
                        {t('COVERAGE.COMPLEMENTARY_INSURANCES_TITLE')}
                    </div>
                    <div className="position-relative">
                        <RecommendedProductCardList
                            data={recommendedProductsOfSelectedFamilyMember}
                        />
                    </div>
                </div>
            </Container>
        </FullScreenContainer>
    )

    const assets = useSelector(getAssets)

    const renderFutureProducts = (
        title: string,
        futureProductsOfSelectedFamilyMember: Product[]
    ) => (
        <div style={{ marginTop: 64 }}>
            <div className="headlineSmallMedium">{title}</div>
            <div>
                <ProductCardList data={futureProductsOfSelectedFamilyMember} />
            </div>
        </div>
    )

    const renderLca = (lcaProductsOfSelectedFamilyMember: Product[]) => (
        <div style={{ marginTop: 120 }}>
            <div className="headline m-bottom-8">{t('COVERAGE.LCA_TITLE')}</div>
            <div className="d-flex m-bottom-12">
                <AssuraIconButton
                    id="lca-rules-button"
                    icon="assura-doc"
                    variant="primary"
                    size="medium"
                    onClick={() => {
                        assets.Conditions_LCA
                            ? openInNewTab(AssetToLink(assets.Conditions_LCA) as string)
                            : dispatch(
                                  setBanner({
                                      dataTestId: 'lca-rules-button-error',
                                      message: 'COMMON.ERROR'
                                  })
                              )
                    }}
                    label={t('INSURANCES.LCA_RULES')}
                />
            </div>

            {displayLcaWarning()}
            <div className="insurances-lca-list">
                <ProductCardList data={lcaProductsOfSelectedFamilyMember} />
            </div>
        </div>
    )
    //US277319: This section adds a banner that informs the user about pricing changes in the future
    // the key in CMS is `LCA_PRICING_WARNING_TEXT`
    // Wildcards could be used as they are replaced on the fly by their corresponding numbers YEAR, YEARPLUSONE
    // Sample text: (tarifs YEAR, YEARPLUSONE, suivront courant novembre)
    const displayLcaWarning = (): JSX.Element | null => {
        let warningText = t('LCA_PRICING_WARNING_TEXT')
        const yearsCheckCollection = [new Date().getFullYear(), new Date().getFullYear() + 1]
        const displayWarningCondition = !!warningText && yearsCheckCollection.includes(selectedYear)

        if (displayWarningCondition) {
            warningText = warningText
                .replace('YEAR', yearsCheckCollection[0].toString())
                .replace('YEARPLUSONE', yearsCheckCollection[1].toString())
            return (
                <div className="insurances-lca-warning-banner">
                    <AssuraBanner
                        id="1"
                        message={warningText}
                        variant="default"
                        styleOverride={'relative'}
                    />
                </div>
            )
        }
        return null
    }

    const renderSelectedFamilyMember = () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Container>
                <div style={{ marginTop: 80, marginBottom: 120 }}>
                    {shouldDisplayContract ? (
                        <>
                            <div>
                                <div className="headlineLarge">{t('INSURANCES.PREMIUMS')}</div>
                                {periodicity ? (
                                    <>
                                        <div className="insurance-premiums-container d-flex">
                                            <div
                                                className="headline c-primary"
                                                data-testid="insurance-premium-price"
                                            >
                                                {t('COMMON.PRICE_PER_MONTH', {
                                                    price: netPremiums.toFixed(2)
                                                })}
                                            </div>
                                            <div className="insurance-premiums-separator bg-gray300 m-left-12 m-right-12" />
                                            <AssuraIconButton
                                                id="premiums-detail"
                                                icon="assura-doc"
                                                variant="primary"
                                                size="medium"
                                                onClick={() => {
                                                    dispatch(
                                                        fetchDiscountsByMemberAndByYear({
                                                            index: familyMemberIndex,
                                                            year: selectedYear
                                                        })
                                                    )
                                                    setIsDiscountsPanelOpen(true)
                                                }}
                                                label={t('COMMON.SEE_DETAIL')}
                                            />
                                        </div>
                                        <PremiumsRebate
                                            periodicity={periodicity}
                                            shouldDisplayPeriodicity
                                            classNames="m-top-32"
                                            dataTestId="insurance"
                                        />
                                    </>
                                ) : (
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ marginTop: 4 }}
                                    >
                                        <i className="icon size-24 assura-info-circle c-gray500 m-right-8" />
                                        <div className="paragraphSmall">
                                            {t('SERVICES.NO_ACTIVE_CONTRACT')}
                                        </div>
                                    </div>
                                )}
                                {lamalOfSelectedFamilyMemberSelectedYear ? (
                                    <InsurancesLamal />
                                ) : (
                                    <InsurancesNoLamal />
                                )}
                            </div>
                            {futureProductsOfSelectedFamilyMember.lamal.length > 0 &&
                                renderFutureProducts(
                                    t('INSURANCE.FUTUR_LAMAL_TITLE'),
                                    futureProductsOfSelectedFamilyMember.lamal
                                )}
                            {lcaProductsOfSelectedFamilyMember.length > 0 &&
                                renderLca(lcaProductsOfSelectedFamilyMember)}
                            {futureProductsOfSelectedFamilyMember.lca.length > 0 &&
                                renderFutureProducts(
                                    t('INSURANCE.FUTUR_LCA_TITLE'),
                                    futureProductsOfSelectedFamilyMember.lca
                                )}
                        </>
                    ) : (
                        <NoContract />
                    )}
                </div>
            </Container>
            <PrimesPanel
                isOpen={isDiscountsPanelOpen}
                closePanel={() => setIsDiscountsPanelOpen(false)}
                selectedYear={selectedYear}
                familyMemberIndex={familyMemberIndex}
                lamal={lamalOfSelectedFamilyMemberSelectedYear}
                lcas={lcaProductsOfSelectedFamilyMember}
            />
            {shouldDisplayContract &&
                recommendedProductsOfSelectedFamilyMember.length > 0 &&
                renderRecommendedProducts(recommendedProductsOfSelectedFamilyMember)}
        </div>
    )

    return (
        <>
            <FullScreenContainer color="gray50" complementaryClasses="insurances-header-container">
                <Container>
                    <InsurancesTitle shouldDisplayContract={shouldDisplayContract} />
                    {renderTabContainer()}
                </Container>
            </FullScreenContainer>
            <AssuraLoadAndError
                status={getLoadingStatusFromLoaderList([
                    summaryLoadingStatus,
                    productsLoaderStatus
                ])}
                shouldDisplayContainer
                defaultReloadAction={() =>
                    dispatch(fetchProductsYear({ year: selectedYear, reload: true }))
                }
            >
                {renderSelectedFamilyMember()}
            </AssuraLoadAndError>
        </>
    )
}

export default withAITracking(reactPlugin, Insurances, 'Insurances', 'insurances-container')
