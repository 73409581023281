/* eslint-disable sonarjs/no-duplicate-string */
import { getDeviceInfoHeaders } from 'core/api/Helper'
import { AssuraDocumentType } from 'core/enums/DocumentType'
import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { DeviceInfoHeaders } from 'core/models/DeviceInfoHeaders'
import { Enveloppe, EnveloppesCounts, OldEnveloppe } from 'core/models/enveloppes/Enveloppe'
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import { ScanDeviceWithId } from 'core/models/enveloppes/ScanDevice'
import { AssuraTabData, ItemMetaData } from 'core/models/TabData'
import { ColorsEnum, IconNamesEnum } from 'shared/components/AssuraTabDropdown/Icon'

import { generateGUID } from '../GuidHelper'
import { mapDocumentTypeIcon } from './AttachementHelper'
import { mapStatusColor, mapStatusIcon, mapStatusTranslation } from './StatusHelper'

export const EnveloppesTabTypes = [
    'ENVELOPPES.VIEW_ALL',
    'ENVELOPPES.VIEW_NEW',
    'ENVELOPPES.VIEW_ON_GOING',
    'ENVELOPPES.VIEW_DONE'
]

export const SetSideItems = (
    data: AssuraTabData[],
    enveloppesCounts: EnveloppesCounts
): AssuraTabData[] => {
    const leftIcons = [
        IconNamesEnum.send2,
        IconNamesEnum.close,
        IconNamesEnum.doubleCheck,
        IconNamesEnum.doubleCheck
    ]
    const colors = { color: ColorsEnum.black, backgroundColor: ColorsEnum.white }

    for (let i = 0; i < data.length; i++) {
        const inError = i === 1
        const received = i === 2

        if (data[i] && leftIcons[i]) {
            data[i].leftItem = { value: leftIcons[i], ...colors } as ItemMetaData
        }

        if (data[i] && leftIcons[i] && (inError || received)) {
            data[i].rightItem = {
                value: inError ? enveloppesCounts.inError : enveloppesCounts.received,
                color: ColorsEnum.white,
                backgroundColor: inError ? ColorsEnum.redAssura : ColorsEnum.black
            } as ItemMetaData
        }
    }

    return data
}

const errorStatus = [EnveloppeStatus.TechnicalError, EnveloppeStatus.VisibilityError]
const receivedStatus = [
    EnveloppeStatus.Received,
    EnveloppeStatus.Validated,
    EnveloppeStatus.PartiallyProcessed
]

// the tab context describes cases when one or many tabs are removed. this allows the helper filterEnveloppesByType to realign the enveloppe collection
export enum tabContext {
    allTabs,
    errorsAreHidden
}

// returns the EnveloppesTabTypes contextualized according tabContext
export const getContextualizedTabList = (context: tabContext): string[] =>
    context === tabContext.errorsAreHidden
        ? [...EnveloppesTabTypes.filter((o) => o !== 'ENVELOPPES.VIEW_NEW')]
        : EnveloppesTabTypes

// returns the tab label from the context and type
export const getContextualizedTabId = (context: tabContext, typeIndex: number): number => {
    const originalLabelList = getContextualizedTabList(0)
    const contextLabelList = getContextualizedTabList(context)
    const originalLabel = originalLabelList[typeIndex]
    return contextLabelList.indexOf(originalLabel)
}

// returns the tab Label from the context and type Label
export const getContextualizedTabLabel = (context: tabContext, typeIndex: number): string => {
    const contextLabelList = getContextualizedTabList(context)
    return contextLabelList[typeIndex]
}

/// Count if received enveloppes are in error or flagged as received.
export const calculateEnvelopeCount = (enveloppes: Enveloppe[]): EnveloppesCounts => {
    return {
        inError: enveloppes.filter((o) => errorStatus.find((es) => es === o.status)).length,
        received: enveloppes.filter((o) => receivedStatus.find((es) => es === o.status)).length
    }
}

// returns the tabs calculated with the context
export const getEnveloppesTabs = (
    enveloppes: Enveloppe[],
    tabs: AssuraTabData[]
): [AssuraTabData[], EnveloppesCounts, tabContext] => {
    const newTabs: AssuraTabData[] = []
    // get enveloppe counts
    const counts = calculateEnvelopeCount(enveloppes)

    // set side Items
    SetSideItems(tabs, counts)

    // recalculate tabs according counts
    const recalculatedTabs = counts.inError > 0 ? tabs : tabs.filter((tab) => tab.uniqueKey !== 1)

    // recalculate Tabs
    for (let i = 0; i < recalculatedTabs.length; i++) {
        const newDataItem: AssuraTabData = { ...recalculatedTabs[i], uniqueKey: i }
        newTabs.push(newDataItem)
    }

    return [newTabs, counts, newTabs.length === 3 ? tabContext.errorsAreHidden : tabContext.allTabs]
}

export const filterEnveloppesByType = (
    enveloppes: EnveloppeRow[],
    typeIndex: number,
    context: tabContext
): EnveloppeRow[] => {
    const currentType = getContextualizedTabLabel(context, typeIndex)
    switch (currentType) {
        case 'ENVELOPPES.VIEW_NEW':
            return enveloppes.filter(
                (enveloppe) =>
                    enveloppe.status == EnveloppeStatus.TechnicalError ||
                    enveloppe.status == EnveloppeStatus.VisibilityError
            )
        case 'ENVELOPPES.VIEW_ON_GOING':
            return enveloppes.filter(
                (enveloppe) =>
                    enveloppe.status == EnveloppeStatus.Received ||
                    enveloppe.status == EnveloppeStatus.Validated ||
                    enveloppe.status == EnveloppeStatus.PartiallyProcessed
            )
        case 'ENVELOPPES.VIEW_DONE':
            return enveloppes.filter((enveloppe) => enveloppe.status == EnveloppeStatus.Processed)
        default:
            return enveloppes
    }
}

export const mapOldEnveloppesToEnveloppes = (enveloppes: OldEnveloppe[]): Enveloppe[] => {
    enveloppes?.forEach((enveloppe) => {
        enveloppe.clientEnveloppeId = enveloppe.clientEnveloppeId ?? generateGUID()
        enveloppe.documents?.forEach((document) => {
            document.documentType = document.documentType ?? AssuraDocumentType.JPG
        })
    })
    return enveloppes as Enveloppe[]
}

export const mapEnveloppesToRows = (
    enveloppes: Enveloppe[],
    personNames: EnveloppePerson[]
): EnveloppeRow[] => {
    return enveloppes
        ? enveloppes.map((enveloppe: Enveloppe, index: number) => {
              return {
                  id: enveloppe.clientEnveloppeId,
                  batchNumber: enveloppe.batchNumber,
                  index: index,
                  insuredPersonName:
                      personNames?.find((person) => person.policyNumber == enveloppe.policeNumber)
                          ?.personName ?? '',
                  insuredPersonLastName:
                      personNames?.find((person) => person.policyNumber == enveloppe.policeNumber)
                          ?.personLastName ?? '',
                  documentName: enveloppe.name,
                  receiveDate: enveloppe.receiveDate,
                  status: enveloppe.status,
                  statusTranslated: mapStatusTranslation(enveloppe.status),
                  statusColor: mapStatusColor(enveloppe.status),
                  statusIcon: mapStatusIcon(enveloppe.status),
                  visibilityErrorReason: enveloppe.visibilityErrorReason,
                  documentTypeIcon: mapDocumentTypeIcon(enveloppe.documents[0].documentType),
                  documents: enveloppe.documents,
                  policyNumber: enveloppe.policeNumber
              }
          })
        : []
}

export const getScanDevice = (deviceInfoHeaders?: DeviceInfoHeaders): ScanDeviceWithId => {
    if (!deviceInfoHeaders) {
        deviceInfoHeaders = getDeviceInfoHeaders()
    }

    return {
        id: deviceInfoHeaders.ClientDeviceId,
        name: `${deviceInfoHeaders.Browser}_${deviceInfoHeaders.BrowserDetails}`,
        type:
            deviceInfoHeaders.Brand && deviceInfoHeaders.Model
                ? `EC_${deviceInfoHeaders.Device} ${deviceInfoHeaders.Brand} ${deviceInfoHeaders.Model}`
                : `EC_${deviceInfoHeaders.Device} ${deviceInfoHeaders.Browser} ${deviceInfoHeaders.BrowserDetails}`,
        softwareVersion: `${deviceInfoHeaders.OsName}_${deviceInfoHeaders.OsVersion}`,
        applicationVersion: deviceInfoHeaders.ClientVersion
    }
}

export const getNoResultMessageByType = (typeIndex: number, context: tabContext): string => {
    const currentType = getContextualizedTabLabel(context, typeIndex)
    switch (currentType) {
        case 'ENVELOPPES.VIEW_ON_GOING':
            return 'ENVELOPPES.NO_DOCUMENT_MESSAGE_ON_GOING'
        case 'ENVELOPPES.VIEW_DONE':
            return 'ENVELOPPES.NO_DOCUMENT_MESSAGE_DONE'
        default:
            return 'ENVELOPPES.NO_DOCUMENT_MESSAGE'
    }
}
