import './Requests.css'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import navigationConstants from 'core/constants/navigationConstants'
import { RequestSource } from 'core/enums/RequestSource'
import { RequestRow } from 'core/models/services/RequestData'
import { ServicesFormRedirectionState } from 'core/models/services/ServicesRoute'
import useQuery from 'core/services/useQuery'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import PageHeader from 'shared/components/PageHeader/PageHeader'
import { getRequestsForTable } from 'shared/store/selectors/getRequestsForTable'
import {
    fetchRequestDetails,
    getRequests,
    setSelectedRequestId
} from 'shared/store/services/requests/requests.slice'
import { useAppDispatch } from 'shared/store/store'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import RequestsPanel from '../components/RequestsPanel/RequestsPanel'
import RequestsTable from '../components/RequestsTable/RequestsTable'

const Requests = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const state = useLocation().state as ServicesFormRedirectionState | null

    const navigate = useNavigate()

    const services = useSelector(getRequestsForTable)

    const queryRequest = useQuery().get('request')
    const [selectedRequest, setSelectedRequest] = useState<RequestRow | undefined>(undefined)

    const onRequestSelection = (request: RequestRow) => {
        if (
            request.source !== RequestSource.MIGRATION ||
            process.env.REACT_APP_SHOW_MIGRATED_DATA?.toString() === 'TRUE'
        ) {
            setSelectedRequest(request)
            dispatch(setSelectedRequestId(request.id))
            dispatch(fetchRequestDetails({ id: request.id, policyNumber: request.policyNumber }))
        }
    }

    const handleBackAction = () => {
        if (state?.origin === 'form') {
            navigate(navigationConstants.SERVICES.url)
        } else {
            navigate(-1)
        }
    }

    useEffect(() => {
        if (queryRequest) {
            const currentRequest = services.find((service) => service.id === queryRequest)
            if (currentRequest) onRequestSelection(currentRequest)
        }
    }, [queryRequest])

    useEffect(() => {
        dispatch(getRequests())
        return () => {
            dispatch(setSelectedRequestId(undefined))
        }
    }, [])

    return (
        <>
            <PageHeader
                titleKey="SERVICES.REQUESTS_TITLE"
                testId="services-requests"
                backAction={handleBackAction}
            />
            <FullScreenContainer color="white" complementaryClasses="flex-1 d-flex flex-column">
                <RequestsTable setSelectedRequest={onRequestSelection} />
            </FullScreenContainer>
            {selectedRequest && <RequestsPanel selectedRequest={selectedRequest} />}
        </>
    )
}

export default withAITracking(reactPlugin, Requests, 'Services', 'requests-container')
