export enum ServiceTopicFront {
    INSURANCE_CONTRACT = 'insuranceContract',
    PERSONAL_DATA = 'personalData',
    COVERAGE = 'coverage',
    CLAIM_ACCIDENT = 'claimAccident',
    INVOICE_REFUND = 'invoiceRefund',
    OTHER = 'other'
}

export enum ServiceTopicBack {
    INSURANCE_POLICY_PREMIUM = 'insurancePolicyOrPremium',
    CLAIM_ILLNESS_ACCIDENT = 'ClaimOrIllnessCaseOrAccidentCase',
    OTHER = 'other'
}
