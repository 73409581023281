import './DiseaseInfos.css'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

import { ServicesHospAnnouncementReason } from 'core/enums/ServicesHospAnnouncementReason'
import { HospitalizationAnnouncementRHFForm } from 'core/models/services/report/HospitalizationAnnouncementForm'
import useWindowSize from 'core/services/useWindowSize'
import { useFormPageContext } from 'modules/services/components/FormPage/FormPage'

import PickerDay from '../../components/DatePicker/PickerDay/PickerDay'
import FormBlockWithLabel from '../../components/FormBlockWithLabel/FormBlockWithLabel'
import { AssuraFormInputRHF } from '../../components/InputRHF/InputRHF'
import RadioRHF from '../../components/RadioRHF/RadioRHF'

const mandatoryField = 'COMMON.MANDATORY_FIELD'

const DiseaseInfos = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const {
        control,
        getValues,
        setValue,
        register,
        trigger,
        watch,
        formState: { errors, isSubmitted }
    } = useFormContext<HospitalizationAnnouncementRHFForm>()

    const { addRhfExtraRef } = useFormPageContext()

    const symptomStartDateId = 'symptom-start-date-date-picker'
    const symptomStartDateName = 'symptomStartDate'

    const [isFocused, setIsFocused] = useState(false)

    const symptomStartDate = watch(symptomStartDateName)

    useEffect(() => {
        if (isSubmitted) trigger('hospStartDate')
    }, [symptomStartDate])

    const handleResetAction = () => {
        setValue('afflictionDescription', '', {
            shouldValidate: isSubmitted
        })
    }

    const getAfflictionDescriptionRules = (): Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    > => {
        const min = 1
        const max = 100
        const afflictionDescriptionErrorMessage = t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
            min,
            max
        })
        return {
            required: { value: true, message: t(mandatoryField) },
            minLength: { value: min, message: afflictionDescriptionErrorMessage },
            maxLength: { value: max, message: afflictionDescriptionErrorMessage }
        }
    }

    const validateReason = () => {
        const { reason } = getValues()
        return !!reason || mandatoryField
    }

    useEffect(() => {
        addRhfExtraRef({
            name: symptomStartDateName,
            id: symptomStartDateId
        })
    }, [])

    return (
        <div className="d-flex flex-column bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <AssuraFormInputRHF
                id="services-hospitalisation-announcement-affliction-description"
                name="afflictionDescription"
                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_AFFLICTION_DESCRIPTION')}
                placeHolder={t(
                    'SERVICES.HOSPITALIZATION_ANNOUNCEMENT_AFFLICTION_DESCRIPTION_PLACEHOLDER'
                )}
                inline={isXL}
                labelSize={4}
                isInvalid={Boolean(errors?.afflictionDescription)}
                error={t(errors?.afflictionDescription?.message as string)}
                hasButton={isFocused}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onButtonClick={() => handleResetAction()}
                iconClass="icon assura-close size-24"
                register={register}
                rules={getAfflictionDescriptionRules()}
            />
            <div className="m-top-32">
                <FormBlockWithLabel label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_LABEL')}>
                    <>
                        <div className="flex-1 d-flex disease-infos-reason-container">
                            <RadioRHF
                                id={ServicesHospAnnouncementReason.ACCIDENT}
                                name="reason"
                                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_ACCIDENT')}
                                register={register}
                                classNames="flex-1 disease-infos-reason"
                                rules={{
                                    validate: validateReason
                                }}
                            />
                            <RadioRHF
                                id={ServicesHospAnnouncementReason.SICKNESS}
                                name="reason"
                                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_SICKNESS')}
                                register={register}
                                classNames="flex-1 disease-infos-reason"
                                rules={{
                                    validate: validateReason
                                }}
                            />
                            <RadioRHF
                                id={ServicesHospAnnouncementReason.CHILDBIRTH}
                                name="reason"
                                label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_CHILDBIRTH')}
                                register={register}
                                classNames="flex-1 disease-infos-reason"
                                rules={{
                                    validate: validateReason
                                }}
                            />
                        </div>
                        {errors?.reason?.message && (
                            <div className="m-top-8 labelExtraSmall c-primary">
                                {t(errors.reason.message)}
                            </div>
                        )}
                    </>
                </FormBlockWithLabel>
                <div className="m-top-32">
                    <FormBlockWithLabel
                        label={t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_SYMPTOM_START_DATE')}
                    >
                        <PickerDay
                            placeholder={t('CALENDAR.INPUT_PLACEHOLDER')}
                            testId="symptom-start-date"
                            control={control}
                            name="symptomStartDate"
                            maxDate={new Date()}
                            rules={{
                                required: { value: true, message: t(mandatoryField) }
                            }}
                            refId={symptomStartDateId}
                        />
                    </FormBlockWithLabel>
                </div>
            </div>
        </div>
    )
}

export default DiseaseInfos
