import { SyntheticEvent } from 'react'
import { Accordion } from 'react-bootstrap'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { Faq } from 'core/models/Faq'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'

import FaqAnswerItem from '../FaqAnswer/FaqAnswerItem'

type FaqQuestionAnswerContext = 'faq' | 'generalQuestionForm'

interface FaqQuestionAnswerAccordionProps {
    selectedQuestionId: string | null
    currentQuestions: Faq[] | undefined
    handleSelection: (e: SyntheticEvent<Element, Event>, id: string) => void
    handleExpansion?: () => void
    conditionalFormatting?: FaqQuestionAnswerContext
}

const FaqQuestionAnswerAccordion = ({
    selectedQuestionId,
    currentQuestions,
    handleSelection,
    handleExpansion,
    conditionalFormatting = 'faq'
}: FaqQuestionAnswerAccordionProps) => {
    const contextualPadding = conditionalFormatting === 'faq' ? '16' : '12'
    return (
        <Accordion activeKey={selectedQuestionId ? `faq-answer-${selectedQuestionId}` : ''}>
            {currentQuestions?.map((question) => {
                return (
                    <div
                        key={`faq-question-${question.id}`}
                        className="border-solid-width-1 bc-gray100 m-bottom-12"
                    >
                        <Accordion.Toggle
                            className={`faq-question-title  ${
                                selectedQuestionId === question.id
                                    ? ' active border-bottom-solid-width-1 bc-gray100'
                                    : ''
                            }`}
                            eventKey={`faq-answer-${question.id}`}
                            onClick={(e) => handleSelection(e, question.id)}
                            as="div"
                            data-testid={`faq-question-${question.id}-toggle`}
                        >
                            <i
                                className={`icon assura-${
                                    selectedQuestionId === question.id ? 'minus' : 'plus'
                                } size-16 m-left-12`}
                            />
                            <div
                                className={`p-top-${contextualPadding} p-bottom-${contextualPadding} p-right-12`}
                                data-testid={`faq-question-${question.id}`}
                            >
                                <AssuraMarkdown
                                    message={question.question}
                                    classNames="text-left paragraphSmall"
                                />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={`faq-answer-${question.id}`}
                            onEnter={handleExpansion}
                        >
                            <FaqAnswerItem
                                questionId={question.id}
                                item={
                                    Source() === ServicesSource.WEBSITE &&
                                    question.answer_website?.length > 0
                                        ? question.answer_website
                                        : question.answer
                                }
                            />
                        </Accordion.Collapse>
                    </div>
                )
            })}
        </Accordion>
    )
}

export default FaqQuestionAnswerAccordion
