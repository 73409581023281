import {
    UseValidationOptions,
    ValidationResult,
    ValidationRowItem,
    ValidationValues
} from './entities'

export const setValue = (mainValue: string, compareValue?: string): ValidationValues => {
    return { mainValue, compareValue } as ValidationValues
}

export const createValidationResult = (
    options: UseValidationOptions,
    validations: Record<string, ValidationRowItem>,
    t: CallableFunction
): ValidationResult => {
    return {
        displayTitle: options.displayTitle,
        displayValidationGuide: options.displayValidationGuide,
        displayFullValidationGuide: options.displayFullValidationGuide,
        displayValidationErrors: options.displayValidationErrors,
        title: t(options.title),
        validations: validations
    } as ValidationResult
}
