import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraEcLinkIconButton from 'shared/components/AssuraEcLinkIconButton/AssuraEcLinkIconButton'

const InsurancesNoLamal = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    return (
        <div className="insurance-no-lamal bc-gray100 m-top-56">
            <div className="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <div className="headlineMedium m-bottom-16">{t('COMMON.LAMAL')}</div>
                    <div className="paragraphLight">{t('INSURANCES.NO_LAMAL_MESSAGE')}</div>
                </div>
                <div className={`${isMobile ? 'm-top-16' : 'm-left-32'} d-flex`}>
                    <AssuraEcLinkIconButton
                        ecLinkId="646391065afb28b49a04023d"
                        testId="no-lamal-more-button"
                        icon="assura-external-link"
                        variant="primary"
                        size="medium"
                        classNames="no-lamal-more-button no-wrap"
                        label={t('COMMON.LEARN_MORE')}
                    />
                </div>
            </div>
        </div>
    )
}

export default InsurancesNoLamal
