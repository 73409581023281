import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getHospStartDate } from '../../services/report/report.slice'
import { getSelectedMemberPolicyNumber } from '../../services/services.slice'
import { getSummaryHospStartDate } from '../getSummaryHospStartDate'

export const getHasOptimaFlexVariaLca = createSelector(
    [getSummaryHospStartDate, getSelectedMemberPolicyNumber, getHospStartDate],
    (summary, selectedMemberPolicyNumber, hospStartDate) => {
        if (selectedMemberPolicyNumber && hospStartDate) {
            const familyMember = summary?.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )
            const hasOptimaFlexVaria = familyMember?.productsPerson?.products?.some(
                (productPerson) =>
                    isBefore(new Date(productPerson.startDate), hospStartDate) &&
                    productPerson.code === 'OFv'
            )
            return Boolean(hasOptimaFlexVaria)
        } else {
            return false
        }
    }
)
