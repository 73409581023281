import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraIsPending from 'shared/components/AssuraIsPending/AssuraIsPending'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'

interface PersonalDataStatusProps {
    form: PersonalDataForm
    isPending: boolean
}

const PersonalDataStatus = ({ form, isPending }: PersonalDataStatusProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const {
        state: { isRequestsAvailable },
        isFormSelected,
        selectForm
    } = usePersonalDataContext()

    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    if (
        isRequestsAvailable &&
        !isFormSelected(form) &&
        missingDataLoadingStatus === LoadingStatusEnum.OK
    ) {
        return !isPending ? (
            <AssuraIconButton
                id={`personal-data-${form}-edit-button`}
                label={isXL ? t('COMMON.MODIFY') : ''}
                icon="assura-edit"
                variant="primary"
                size="medium"
                onClick={() => selectForm(form)}
            />
        ) : (
            <AssuraIsPending testId={`personal-data-${form}`} />
        )
    } else return null
}

export default PersonalDataStatus
