/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { InputGroup } from 'react-bootstrap'

interface ButtonProps {
    id: string
    hasButton: boolean
    onButtonClick: () => void
    iconClass: string
}

export const withButton = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithButton extends React.Component<P & ButtonProps> {
        handleClick(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
            e.preventDefault()
            this.props.onButtonClick()
        }
        render() {
            const { id, hasButton, iconClass } = this.props
            return (
                <InputGroup>
                    <WrappedComponent {...(this.props as P)} />
                    {hasButton && (
                        <InputGroup.Append className="assura-input-group-append">
                            <div
                                data-testid={id + '-button'}
                                onMouseDown={(e) => this.handleClick(e)}
                                onTouchStart={(e) => this.handleClick(e)}
                            >
                                <i className={iconClass} />
                            </div>
                        </InputGroup.Append>
                    )}
                </InputGroup>
            )
        }
    }
