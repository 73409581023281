import './LabelValueInlineBlock.css'

export interface LabelValueInlineBlockItem {
    id: string
    label: string
    value: string
}

interface LabelValueInlineBlockProps {
    children: React.ReactNode | React.ReactNode[]
}

const LabelValueInlineBlock = ({ children }: LabelValueInlineBlockProps): JSX.Element => {
    return (
        <div className="label label-value-component-bloc-container border-bottom-solid-width-1 bbc-gray100">
            {children}
        </div>
    )
}

export default LabelValueInlineBlock
