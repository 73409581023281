import { AppSearchCategoryIcons } from 'core/constants/appSearch'
import searchPageItems from 'core/constants/pagesSearch'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { getServices } from 'core/helpers/services/ServicesHelper'
import { AppSearchData, AppSearchResult } from 'core/models/AppSearch'
import { SearchSuggestionCms } from 'core/models/cms/SearchSuggestionCms'
import i18next from 'i18next'

import { createSelector } from '@reduxjs/toolkit'

import { getDigcliparameters } from '../digcliParameters/digcliParameters.slice'
import { getLang } from '../general/general.slice'
import { getAppSearchFilteredSuggestionsCms } from './getAppSearchFilteredSuggestionsCms'
import { getFaqForAppSearch } from './getFaqForAppSearch'

const mergeResultAndSuggestion = (
    result: AppSearchResult,
    suggestion: SearchSuggestionCms,
    lang: string
): AppSearchResult => {
    const localizedSugg = getLocalizedObject(suggestion, lang)

    return {
        ...result,
        label: localizedSugg.label ? i18next.t(localizedSugg.label) : result.label,
        suggestionOrder: parseInt(suggestion.order)
    }
}

export const getAppSearchLocalizedData = createSelector(
    [getLang, getAppSearchFilteredSuggestionsCms, getFaqForAppSearch, getDigcliparameters],
    (lang, suggestionsCms, faq, digcliParameters): AppSearchData => {
        const data: AppSearchData = {
            PAGE: [],
            SERVICES: [],
            FAQ: []
        }

        let suggestionsList = [...suggestionsCms]

        // PAGES
        data.PAGE = searchPageItems.map((page) => {
            const suggestion = suggestionsList.find((item) => item.ec_link?._id === page.ecLinkId)

            let result: AppSearchResult = {
                ecLinkId: page.ecLinkId,
                label: i18next.t(page.label),
                icon: AppSearchCategoryIcons.PAGE,
                suggestionOrder: null
            }

            if (suggestion) {
                result = mergeResultAndSuggestion(result, suggestion, lang)
                suggestionsList = suggestionsList.filter((s) => s._id !== suggestion._id)
            }

            return result
        })

        // SERVICES
        const cards = getServices(digcliParameters?.isSymptomCheckerCustomerPortalActivated)
        const finalServices: AppSearchResult[] = []

        cards.forEach((card) => {
            card.links.forEach((link) => {
                const suggestion = suggestionsList.find(
                    (item) => item.ec_link?._id === link.ecLinkId
                )

                let result: AppSearchResult = {
                    ecLinkId: link.ecLinkId,
                    label: i18next.t(link.title),
                    icon:
                        link.icon === 'assura-right' ? AppSearchCategoryIcons.SERVICES : link.icon,
                    suggestionOrder: null
                }
                if (suggestion) {
                    result = mergeResultAndSuggestion(result, suggestion, lang)
                    suggestionsList = suggestionsList.filter((s) => s._id !== suggestion._id)
                }

                finalServices.push(result)
            })
        })

        data.SERVICES = [...finalServices]

        // FAQ
        const finalFaq: AppSearchResult[] = faq.map((item) => {
            let result: AppSearchResult = { ...item }
            const suggestion = suggestionsList.find((i) => i.ec_link?._id === item.ecLinkId)

            if (suggestion) {
                result = mergeResultAndSuggestion(result, suggestion, lang)
                suggestionsList = suggestionsList.filter((s) => s._id !== suggestion._id)
            }

            return result
        })

        data.FAQ = [...finalFaq]

        // OTHER EXTERNALS
        if (suggestionsList.length > 0) {
            suggestionsList.forEach((sugg) => {
                if (sugg.label) {
                    const result = mergeResultAndSuggestion(
                        {
                            ecLinkId: sugg.ec_link?._id,
                            label: i18next.t(sugg.label),
                            icon: AppSearchCategoryIcons.EXTERNAL,
                            suggestionOrder: null
                        },
                        sugg,
                        lang
                    )
                    data.SERVICES.push(result)
                }
            })
        }

        return data
    }
)
