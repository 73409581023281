export const SymptomCheckerGenderEnum = {
    male: 'm',
    female: 'f'
} as const

export const SymptomCheckerPartnerTypeEnum = {
    MEMBER: 'MEMBER',
    OTHER: 'OTHER'
} as const

export const SymptomCheckerInterviewDirectEnum = {
    MYSELF: 'myself',
    SOMEONE: 'someone'
} as const

export enum SymptomCheckerMappedActionsEnum {
    INITIALIZATION_COMPLETE = 'initialization-complete',
    QUESTION_ANSWERED = 'question-answered',
    INTERVIEW_FINISHED = 'interview-finished',
    PLANS_SURVEY = 'plans-survey',
    CTA_EVENT = 'cta-event'
}

export enum SymptomCheckerMappedTypesEnum {
    INTENT_SURVEY_CONSULT_MEDICAL_PROFESSIONAL = 'intent_survey_consult_medical_professional',
    INTENT_SURVEY_KIND_OF_CARE = 'intent_survey_kind_of_care',
    INTENT_SURVEY_CONTACT_FORM = 'intent_survey_contact_form'
}

export enum SymptomCheckerFirstIntentionResponseEnum {
    MEDICAL_PROFESSIONAL = 'medical_professional',
    RECOVERING_AT_HOME = 'recovering_at_home',
    NOT_SURE = 'not_sure'
}

export enum SymptomCheckerKindOfCareEnum {
    RECOVERING_AT_HOME = 'recovering_at_home',
    ALLIED_HEALTH_CARE = 'allied_health_care',
    PRIMARY_CARE = 'primary_care',
    SPECIALIST_CARE = 'specialist_care',
    URGENT_CARE = 'urgent_care',
    EMERGENCY_NO_AMBULANCE = 'emergency_no_ambulance',
    EMERGENCY_WITH_AMBULANCE = 'emergency_with_ambulance',
    NOT_SURE = 'not_sure'
}

export enum SymptomCheckerTriageEnum {
    SELF_CARE = 'self_care',
    CONSULTATION = 'consultation',
    CONSULTATION_24 = 'consultation_24',
    EMERGENCY = 'emergency',
    EMERGENCY_AMBULANCE = 'emergency_ambulance'
}
