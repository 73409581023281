import './TabIcon.css'

import Icon, { ColorsEnum, IconNamesEnum, IconSizesEnum } from './Icon'

type TabIconProps = {
    name: IconNamesEnum
    dataId: string
    size?: IconSizesEnum
    color?: ColorsEnum
    backgroundColor?: ColorsEnum
}

const TabIcon = ({
    name,
    dataId,
    size = IconSizesEnum.Size32,
    color = ColorsEnum.white,
    backgroundColor = ColorsEnum.redAssura
}: TabIconProps) => (
    <div className={'tab-icon-container'} style={{ background: backgroundColor }}>
        <Icon name={name} color={color} size={size} dataId={dataId} />
    </div>
)

export default TabIcon
