export type BreakPointsKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const BREAKPOINTS: Record<BreakPointsKey, number> = {
    xs: 414,
    sm: 672,
    md: 896,
    lg: 1120,
    xl: 1344
}

export default BREAKPOINTS
