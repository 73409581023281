import './EnvBox.css'

import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { createPortal } from 'react-dom'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import useWindowSize from 'core/services/useWindowSize'
import concatClassNames from 'core/utils/classNameUtils'

import { navBarEnvContainerId } from '../AssuraNavBar/NavBarEnv'
import { defaultEnvContainerId } from './Env'

const EnvBox = (): JSX.Element => {
    const { isMD, isSD, isXL, isXS, isXSD, width } = useWindowSize()
    const isFromBackoffice = Source() === ServicesSource.BACKOFFICE
    const buildVersion = process.env.REACT_APP_BUILD_VERSION?.toString()

    const [isHidden, setIsHidden] = useState(false)

    const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

    const toggleInvisibility = () => {
        setIsHidden(!isHidden)
    }

    const BannerTitle = (): string => {
        return `${process.env.REACT_APP_ENVIRONMENT?.toString()}${
            isFromBackoffice ? ' - SUPERVISION' : ''
        }`
    }

    const envBackgroundColorClass = `env-box-${process.env.REACT_APP_ENVIRONMENT?.toString()}`
    const mainContainerInvisibility = isHidden ? 'hidden' : 'visible'
    const mainContainerClasses = concatClassNames([
        'env-box-main-container',
        envBackgroundColorClass,
        mainContainerInvisibility
    ])

    const displaySize = (): string => {
        switch (true) {
            case isMD:
                return `MD ${width}px`
            case isSD:
                return `SD ${width}px`
            case isXL:
                return `XL ${width}px`
            case isXS:
                return `XS ${width}px`
            case isXSD:
                return `XSD ${width}px`
            default:
                return width.toString()
        }
    }

    useEffect(() => {
        //Targeted container
        const desktopNavbarContainer = document.getElementById(navBarEnvContainerId)
        const defaultContainer = document.getElementById(defaultEnvContainerId)

        setTargetElement(desktopNavbarContainer || defaultContainer)
    })

    return createPortal(
        <div className={mainContainerClasses} data-testid="env-box-component">
            <Container className="env-box-content labelExtraSmallUppercase">
                <div className="display-size">{displaySize()}</div>
                <div className="env">{BannerTitle()}</div>
                <div className="build-version">{buildVersion}</div>
                <input
                    id="show-hide"
                    data-testid="app-env-checkbox"
                    className="show-hide"
                    type={'checkbox'}
                    checked={!isHidden}
                    onChange={toggleInvisibility}
                />
            </Container>
        </div>,
        targetElement || document.body
    )
}

export default EnvBox
