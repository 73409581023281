import { useTranslation } from 'react-i18next'

const DeleteAccountInfo = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="d-flex flex-column justify-content-start align-items-start"
            data-testid="delete-account-info"
        >
            <span className="titleSmall">{t('COMMON.IMPORTANT_NOTE')}</span>
            <span className="paragraphSmallLight m-top-24">
                {t('DELETE_ACCOUNT.IMPORTANT_NOTE')}
            </span>
        </div>
    )
}

export default DeleteAccountInfo
