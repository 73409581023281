import { DocumentsGroupEnum } from 'core/enums/Documents'
import { InvoiceCheckingDetails } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import { fetchDocumentFile } from 'shared/store/documents/documents.slice'
import { useAppDispatch } from 'shared/store/store'

interface RenderPdfErrorProps {
    selectedInvoice?: InvoiceCheckingDetails
}

const RenderPdfError = ({ selectedInvoice }: RenderPdfErrorProps): JSX.Element => {
    const dispatch = useAppDispatch()
    return (
        <AssuraDocumentsError
            onClick={() => {
                if (selectedInvoice) {
                    dispatch(
                        fetchDocumentFile({
                            documentId: selectedInvoice?.documentIdentifier,
                            documentsGroup: DocumentsGroupEnum.INVOICES,
                            isRead: false,
                            reload: true
                        })
                    )
                }
            }}
        />
    )
}
export default RenderPdfError
