import { call, fork, put, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { postAccidentRequestAsyncApi } from 'core/api/services/Report'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { AccidentReportHttpResponse } from 'core/models/services/report/AccidentReportModel'
import { setBanner } from 'shared/store/banners/banners.slice'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted } from '../services.slice'
import { setSubmitStatus, submitData } from './accidentReport.slice'

export function* accidentReportSubmitSaga(action: ReturnType<typeof submitData>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.ACCIDENT_REPORT
    }
    try {
        yield put(setSubmitStatus(LoadingStatusEnum.LOADING))
        yield call(postAccidentRequestAsyncApi, action.payload)

        yield put(formSubmitted(analyticsParams))
        yield put(setSubmitStatus(LoadingStatusEnum.OK))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            const apiResponse = e as AccidentReportHttpResponse
            const errorMessageKey =
                apiResponse?.response?.status === 400
                    ? 'SERVICE.ACCIDENT_REPORT.VALIDATION_ERROR_BANNER_TEXT'
                    : 'SERVICES.SUBMISSION_ERROR'
            yield put(
                setBanner({
                    dataTestId: 'aventura-error-banner',
                    message: errorMessageKey
                })
            )
            yield put(setSubmitStatus(LoadingStatusEnum.ERROR))
            console.error('accidentReportSubmitSaga Error', e)
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* accidentReportSubmitWatcher() {
    yield takeEvery(submitData.type, accidentReportSubmitSaga)
}

const watchers = [fork(accidentReportSubmitWatcher)]

export default watchers
