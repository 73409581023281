import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { Notifications } from 'core/models/Notifications'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface NotificationsState {
    loadingStatus: LoadingStatusEnum
    data?: Notifications[]
}
const INITIAL_STATE_NOTIFICATIONS: NotificationsState = {
    loadingStatus: LoadingStatusEnum.LOADING,
    data: undefined
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: INITIAL_STATE_NOTIFICATIONS,
    reducers: {
        fetchNotifications: (state) => {
            return state
        },
        setNotificationsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.loadingStatus = action.payload
        },
        setNotifications: (state, action: PayloadAction<Notifications[]>) => {
            state.data = action.payload
        }
    }
})

export const {
    fetchNotifications,
    setNotificationsLoadingStatus,
    setNotifications
} = notificationsSlice.actions

export const getNotifications: Selector<RootState, NotificationsState> = (state: RootState) =>
    state.notifications

export default notificationsSlice.reducer
