import './TabToggleButton.css'

import { useEffect, useState } from 'react'

type TabToggleButtonProps = {
    onShow: (show: boolean) => void
    show: boolean
}

const TabToggleButton = ({ onShow, show }: TabToggleButtonProps) => {
    const [currentState, setCurrentState] = useState(false)
    const [currentClasses, setCurrentClasses] = useState('')

    useEffect(() => {
        if (currentState === show) {
            setCurrentClasses('icon tab-toggle-button size-24')
        } else {
            setCurrentClasses(
                `icon tab-toggle-button size-24 ${!show ? 'toggle-icon-down' : 'toggle-icon-up'} `
            )
            setCurrentState(show)
        }
    }, [show])

    return (
        <div
            className={`${currentClasses} m-left-12`}
            onClick={() => onShow(!show)}
            data-testid="tab-toggle-button"
        >
            <i
                className={`tab-toggle-icon icon ${!show ? 'assura-down' : 'assura-up'} `}
                data-testid="tab-toggle-button-icon"
            />
        </div>
    )
}

export default TabToggleButton
