export enum ServicesSource {
    CUSTOMER_PORTAL = 'customerPortal',
    WEBSITE = 'website',
    BACKOFFICE = 'backoffice'
}

export const Source = (): ServicesSource => {
    switch (window.location.origin) {
        case process.env.REACT_APP_FORMS:
        case process.env.REACT_APP_FORMS + ':1024':
            return ServicesSource.WEBSITE
        case process.env.REACT_APP_SUPERVISION:
        case process.env.REACT_APP_SUPERVISION + ':1024':
            return ServicesSource.BACKOFFICE
        default:
            return ServicesSource.CUSTOMER_PORTAL
    }
}
