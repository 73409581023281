import axios from 'axios'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { ModelDeductibleFormRequestBody } from 'core/models/services/coverage/ModelDeductibleForm'

export const modelDeductibleChangeDatesData = async (
    policyNumber: number,
    simulationDate?: string
): Promise<Date[]> => {
    let url = `${process.env.REACT_APP_API_COMMON}/api/v2/coverage/franchisesDates/${policyNumber}`
    if (simulationDate) {
        url += `?simulationDate=${formatDateToApiFormat(simulationDate)}`
    }
    const result = await axios.get(url)
    return result.data
}

export const modelDeductiblesChangeData = async (
    policyNumber: number,
    beginDate: string,
    simulationDate?: string
): Promise<ProductFranchise[]> => {
    let url = `${process.env.REACT_APP_API_COMMON}/api/v2/coverage/franchises/${policyNumber}/${beginDate}`
    if (simulationDate) {
        url += `?simulationDate=${formatDateToApiFormat(simulationDate)}`
    }
    const result = await axios.get(url)
    return result.data
}

export const getDoctorsData = async (policyNumber: number): Promise<Doctor[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/doctors?policyNumber=${policyNumber}`
    )
    return result.data
}

export const getDoctorProductCodesData = async (rccNumber: string): Promise<string[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyDoctors/productcodes/${rccNumber}`
    )

    return result.data
}

export const submitModelDeductibleForm = async (
    requestBody: ModelDeductibleFormRequestBody,
    simulationDate?: Date
): Promise<void> => {
    let url = `${process.env.REACT_APP_API_COMMON}/api/v2/coverage/franchises`
    if (simulationDate) {
        url += `?simulationDate=${formatDateToApiFormat(simulationDate)}`
    }
    await axios.post(url, requestBody)
}

export const submitLamalAccidentForm = async (form: FormData): Promise<string> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Coverage/changeAccidentInsuranceState`,
        form,
        {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }
    )
    return result.data
}
