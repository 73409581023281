import { dateIntervalCheck } from 'core/helpers/DateHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { SplashScreen } from 'core/models/SplashScreen'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getSplashScreenCms } from '../splashScreen/splashScreen.slice'

export const getSplashScreen = createSelector([getSplashScreenCms, getLang], (splashes, lang) => {
    const splashScreensInInterval = splashes.filter((s) =>
        dateIntervalCheck(s.validity_start, s.validity_end, new Date().toISOString())
    )
    if (splashScreensInInterval.length === 0) return null
    return getLocalizedObject(splashScreensInInterval[0], lang) as SplashScreen
})
