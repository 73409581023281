import { mapInvoicesToCheckToRows } from 'core/helpers/invoicesToCheck/InvoicesToCheckToRows'
import { InvoiceCheckingDetails } from 'core/models/invoicesToCheck/InvoicesToCheckModels'

import { createSelector } from '@reduxjs/toolkit'

import { getInvoicesToCheckDetails } from '../invoicesToCheck/invoicesToCheck.slice'
import { getPersonDataForDocuments } from './getPersonDataForDocuments'

export const getMappedInvoicesToCheckDetails = createSelector(
    [getInvoicesToCheckDetails, getPersonDataForDocuments],
    (getInvoicesToCheckDetails, personData): InvoiceCheckingDetails | null => {
        if (getInvoicesToCheckDetails) {
            const invoicesToCheckRows = mapInvoicesToCheckToRows(
                [getInvoicesToCheckDetails],
                personData
            )
            return {
                ...invoicesToCheckRows[0],
                ...getInvoicesToCheckDetails
            } as InvoiceCheckingDetails
        }
        return null
    }
)
