import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { faqCategoryCmsData, faqCmsData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FaqCategoryCms } from 'core/models/cms/FaqCategoryCms'
import { FaqCms } from 'core/models/cms/FaqCms'

import { fetchFaqCollections, setFaqCategoryCms, setFaqCms, setFaqLoadingStatus } from './faq.slice'

export function* fetchFaqCollectionsSaga() {
    try {
        yield put(setFaqLoadingStatus(LoadingStatusEnum.LOADING))
        const faqCategoryCms: FaqCategoryCms[] = yield call(faqCategoryCmsData)
        yield put(setFaqCategoryCms(faqCategoryCms))
        const FaqCms: FaqCms[] = yield call(faqCmsData)
        yield put(setFaqCms(FaqCms))
        yield put(setFaqLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setFaqLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchFaqCollectionsSaga Error', e)
    }
}

function* fetchFaqCollectionsWatcher() {
    yield takeEvery(fetchFaqCollections.type, fetchFaqCollectionsSaga)
}

const watchers = [fork(fetchFaqCollectionsWatcher)]

export default watchers
