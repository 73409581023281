export enum ProductTypeEnum {
    LAMAL = 'laMal',
    LCA = 'lca'
}

export enum ProductTypeCmsEnum {
    LAMAL = 'LAMal',
    LCA = 'LCA'
}

export enum ProductCategoryEnum {
    ACCIDENT = 'Accident',
    CAPITAL_DEATH_DISABILITY = 'CapitalDeathDisability'
}

export enum ProductPeriodicity {
    ANNUAL = 'annual',
    BIANNUAL = 'biannual',
    QUARTERLY = 'quarterly',
    MONTHLY = 'monthly'
}

export enum ProductGroupCode {
    BASIC = 'basic',
    COMPLEMENTARY = 'complementary',
    PRIVATEHOSPITALIZATION = 'privatehospitalization',
    HOSPITALIZATIONINDEMNITIES = 'hospitalizationindemnities',
    DENTALCARE = 'dentalcare',
    ALTERNATIVEMEDICINEMEDNA = 'alternativemedicinemedna',
    ALTERNATIVEMEDICINE = 'alternativemedicine',
    TRAVEL = 'travel',
    MONEYLOSS = 'moneyloss',
    PREVISIA = 'previsia',
    PREVISIAPLUS = 'previsiaplus',
    UNKNOWN = 'unknown'
}
