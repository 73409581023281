import './FaqQuestionsList.css'

import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { Faq } from 'core/models/Faq'
import useWindowSize from 'core/services/useWindowSize'
import { useFaqContext } from 'modules/faq/pages/Faq'
import ScrollToTopFloatingButton from 'shared/components/ScrollToTopFloatingButton/ScrollToTopFloatingButton'
import { getFaqQuestions } from 'shared/store/selectors/getFaqData'

import FaqQuestionAnswerAccordion from './FaqQuestionListAccordion'

const FaqQuestionsList = (): JSX.Element => {
    const { isSD, isXSD } = useWindowSize()
    const { selectedCategoryId, selectedQuestionId, setSelectedQuestionId } = useFaqContext()

    const faqQuestions = useSelector(getFaqQuestions)
    const currentQuestions = selectedCategoryId
        ? faqQuestions.get(selectedCategoryId)
        : ([] as Faq[])

    const handleSelection = (e: React.SyntheticEvent<Element, Event>, id: string) => {
        setSelectedQuestionId(id)
    }

    const titleRef = useRef<HTMLDivElement>(null)
    const faqContainer = document.getElementById('faq-container')
    const containerRef = useRef<HTMLElement>(faqContainer)

    const handleExpansion = () => {
        if (titleRef?.current) {
            titleRef.current.scrollIntoView()
        }
    }

    return (
        <>
            <div className="titleSmall m-bottom-24" ref={titleRef}>
                {currentQuestions?.[0] ? currentQuestions[0].faqCategory.title : ''}
            </div>
            <FaqQuestionAnswerAccordion
                selectedQuestionId={selectedQuestionId}
                currentQuestions={currentQuestions}
                handleSelection={handleSelection}
                handleExpansion={handleExpansion}
                conditionalFormatting="faq"
            />
            {(isXSD || isSD) && containerRef !== null && (
                <ScrollToTopFloatingButton triggeringRef={titleRef} />
            )}
        </>
    )
}

export default FaqQuestionsList
