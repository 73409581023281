/* eslint-disable sonarjs/no-nested-template-literals */
import './AssuraButton.css'

import { ButtonVariants } from 'core/enums/ButtonVariants'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'

export interface AssuraIcon {
    name: string
    size: number
    marginRight?: number
    marginLeft?: number
}

export interface AssuraButtonProps {
    text: string
    id: string
    variant: ButtonVariants
    onClick: () => void
    classNames?: string
    colorBackground?: boolean
    disabled?: boolean
    fullWidth?: boolean
    icon?: AssuraIcon
    iconPosition?: 'left' | 'right'
    loaderPosition?: 'left' | 'right'
    hasLoader?: boolean
}

const AssuraButton = ({
    text,
    id,
    variant,
    onClick,
    classNames,
    colorBackground,
    disabled,
    fullWidth,
    icon,
    hasLoader,
    iconPosition = 'left',
    loaderPosition = 'right'
}: AssuraButtonProps): JSX.Element => {
    return (
        <button
            data-testid={id}
            onClick={hasLoader ? undefined : onClick}
            className={`assura-button btn btn-${variant}${colorBackground ? ' inverted' : ''}${
                fullWidth ? ' btn-block' : ''
            }${classNames ? ` ${classNames}` : ''}`}
            disabled={disabled}
        >
            <div className="d-flex align-items-center justify-content-center">
                {iconPosition === 'left' && icon && (
                    <i
                        className={`m-right-${icon.marginRight ? icon.marginRight : 8} m-left-${
                            icon.marginLeft ? icon.marginLeft : 0
                        } icon size-${icon.size} ${icon.name}`}
                        data-testid={`${id}-icon-left`}
                    />
                )}
                {loaderPosition === 'left' && hasLoader && !disabled && (
                    <div className={`m-left-8`} data-testid={`${id}-loader-left`}>
                        <ActivityIndicator />
                    </div>
                )}
                <span>{text}</span>
                {loaderPosition === 'right' && hasLoader && !disabled && (
                    <div className={`m-left-8`} data-testid={`${id}-loader-right`}>
                        <ActivityIndicator />
                    </div>
                )}
                {iconPosition === 'right' && icon && (
                    <i
                        className={`m-right-${icon.marginRight ? icon.marginRight : 8} m-left-${
                            icon.marginLeft ? icon.marginLeft : 0
                        } icon size-${icon.size} ${icon.name}`}
                        data-testid={`${id}-icon-right`}
                    />
                )}
            </div>
        </button>
    )
}

export default AssuraButton
