import './EnveloppesBackToScanUploadButton.css'

import { useEffect, useState } from 'react'
import { ErrorCode, FileRejection, FileWithPath, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { DROPZONE_ACCEPTED_PDF } from 'core/constants/Dropzone'
import { generateGUID } from 'core/helpers/GuidHelper'
import pdfAnalyzer, { PdfAnalyzerError, PdfAnalyzerReport } from 'core/helpers/PdfAnalyzer'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { resetBanners, setBanner } from 'shared/store/banners/banners.slice'

import { useBackToScanContext } from '../EnveloppesBackToScanForm/EnveloppesBackToScanForm'

interface EnveloppesBackToScanUploadButtonProps {
    index: number
    testId: string
    setIsLoading: (state: boolean) => void
}

let pdfTimeout: NodeJS.Timeout

const EnveloppesBackToScanUploadButton = ({
    index,
    testId,
    setIsLoading
}: EnveloppesBackToScanUploadButtonProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const defaultError = 'DOCUMENTS.PDF_LOAD_ERROR_GENERIC'

    const [fileError, setFileError] = useState<string>('')

    const { addDocument } = useBackToScanContext()

    const onDrop = (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
        setIsLoading(true)
        if (fileRejections && fileRejections.length > 0) {
            handleRejectedFile(fileRejections[0])
        } else {
            handleAcceptedFile(acceptedFiles[0])
        }
    }

    const { getInputProps, open } = useDropzone({
        onDrop,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: {
            ...DROPZONE_ACCEPTED_PDF
        },
        maxSize: 10000000, //10MB
        useFsAccessApi: false,
        multiple: false
    })

    const handleClick = () => {
        dispatch(resetBanners())
        open()
    }

    const setImportError = (error: string) => {
        setIsLoading(false)
        setFileError(error)
    }

    const handleRejectedFile = (rejectedFile: FileRejection) => {
        getErrorMessage((rejectedFile.errors[0]?.code as ErrorCode) || undefined)
    }

    const getErrorMessage = (error: ErrorCode | PdfAnalyzerError | undefined) => {
        let fileError
        switch (error) {
            case ErrorCode.FileInvalidType:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_INVALID_TYPE'
                break
            case ErrorCode.TooManyFiles:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_MANY_FILES'
                break
            case ErrorCode.FileTooLarge:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_FILE_TOO_BIG'
                break
            case PdfAnalyzerError.PasswordException:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_PASSWORD'
                break
            default:
                fileError = defaultError
        }
        setImportError(fileError)
    }

    const pdfAnalyze = async (pdfFileBase64: string): Promise<PdfAnalyzerReport> => {
        return await pdfAnalyzer(pdfFileBase64)
    }

    const handleAcceptedFile = (acceptedFile: FileWithPath) => {
        const reader = new FileReader()
        reader.onload = () => {
            const binarystr = reader.result
            const pdf64 = (binarystr && binarystr.toString()) || ''
            pdfAnalyze(pdf64).then((result) =>
                onDocumentLoad(result as PdfAnalyzerReport, acceptedFile.name, pdf64)
            )
            clearTimeout(pdfTimeout)
            pdfTimeout = setTimeout(() => {
                setFileError(defaultError)
                setIsLoading(false)
            }, 10000)
        }

        reader.onerror = () => {
            setFileError(defaultError)
        }
        reader.readAsDataURL(acceptedFile)
    }

    const onDocumentLoad = (
        pdfReport: PdfAnalyzerReport,
        fileName: string,
        pdfFileBase64: string
    ) => {
        clearTimeout(pdfTimeout)
        if (pdfReport.isValid) {
            if (pdfReport.pagesCount < 100) {
                setIsLoading(false)
                const id = generateGUID()
                addDocument(index, {
                    id,
                    fileName,
                    pdfFileBase64,
                    pagesCount: pdfReport.pagesCount,
                    isValid: true
                })
                setFileError('')
            } else {
                setImportError('DOCUMENTS.PDF_LOAD_ERROR_TOO_MANY_PAGES')
            }
        } else {
            getErrorMessage(pdfReport.error)
        }
    }

    useEffect(() => {
        if (fileError) {
            dispatch(
                setBanner({
                    dataTestId: testId,
                    message: t(fileError)
                })
            )
        }
    }, [fileError])

    return (
        <>
            <input {...getInputProps()} />
            <AssuraButton
                text={t('ENVELOPPES.BACK_TO_SCAN_REPLACE')}
                id={testId}
                variant="primary"
                onClick={handleClick}
                icon={{
                    name: 'assura-refresh',
                    size: 16
                }}
                classNames="enveloppes-back-to-scan-upload-button"
                fullWidth
            />
        </>
    )
}

export default EnveloppesBackToScanUploadButton
