import { AppSearch, UserSearchPayload } from 'core/models/AppSearch'
import { SearchSuggestionCms } from 'core/models/cms/SearchSuggestionCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_APP_SEARCH: AppSearch = {
    suggestionsCms: [],
    userText: '',
    searchText: ''
}

export const appSearchSlice = createSlice({
    name: 'appSearch',
    initialState: INITIAL_STATE_APP_SEARCH,
    reducers: {
        setSearchSuggestionsCms: (state, action: PayloadAction<SearchSuggestionCms[]>) => {
            state.suggestionsCms = action.payload
        },
        setUserText: (state, action: PayloadAction<UserSearchPayload>) => {
            state.userText = action.payload.text
            state.searchText = action.payload.shouldTriggerSearch
                ? action.payload.text
                : state.searchText
        },
        resetSearch: (state: AppSearch) => {
            state.userText = ''
            state.searchText = ''
        }
    }
})

export const {
    setSearchSuggestionsCms,
    setUserText,
    resetSearch
} = appSearchSlice.actions

export const getSearchSuggestionsCms: Selector<RootState, SearchSuggestionCms[]> = (state) =>
    state.appSearch.suggestionsCms

export const getUserText: Selector<RootState, string> = (state) => state.appSearch.userText

export const getSearchText: Selector<RootState, string> = (state) => state.appSearch.searchText

export default appSearchSlice.reducer
