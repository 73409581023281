import './SymptomCheckerLandingPage.css'

import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import productsNamesConstants from 'core/constants/productsNamesConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getSymptomCheckerLandingIcon } from 'shared/store/selectors/getSymptomCheckerLandingIcon'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import LandingPageBlock from '../components/LandingPage/LandingPageBlock'
import LandingPageHealthRoute from '../components/LandingPage/LandingPageHealthRoute'
import LandingPageQualiMedRules from '../components/LandingPage/LandingPageQualiMedRules'
import LandingPageUsefulInfo from '../components/LandingPage/LandingPageUsefulInfo'

const TITLE_KEY = 'SYMPTOM_CHECKER.LANDING_PAGE_TITLE'

const SymptomCheckerLandingPage = (): JSX.Element => {
    const { t } = useTranslation()

    const symptomCheckerLandingIconPath = useSelector(getSymptomCheckerLandingIcon)

    const topLabel = t(TITLE_KEY) === TITLE_KEY ? '' : t(TITLE_KEY)

    useEffect(() => {
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.PAGE_VIEW, {
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: productsNamesConstants.PRODUCTS.QUALIMED,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }, [])

    return (
        <FullScreenContainer color="gray50" complementaryClasses="d-flex flex-column flex-1">
            <Container className="flex-1">
                <LandingPageBlock
                    classNames="align-items-center p-right-20 p-left-20"
                    isTransparent
                >
                    {topLabel && (
                        <div className="paragraphLight m-bottom-24 text-center">{topLabel}</div>
                    )}
                    {symptomCheckerLandingIconPath && (
                        <img
                            className="image-size-32"
                            src={symptomCheckerLandingIconPath}
                            alt="icon"
                            data-testid="symptom-checker-landing-page-top-icon"
                        />
                    )}
                    <div className="titleSmall text-center p-left-12 p-right-12 m-top-16">
                        {t('SYMPTOM_CHECKER.LANDING_PAGE_MAIN_TITLE')}
                    </div>
                    <div className="paragraph text-center m-top-16">
                        {t('SYMPTOM_CHECKER.LANDING_PAGE_SUBTITLE')}
                    </div>
                </LandingPageBlock>
                <LandingPageBlock classNames="m-top-40 p-32">
                    <LandingPageQualiMedRules />
                    <LandingPageUsefulInfo />
                </LandingPageBlock>
                <LandingPageBlock classNames="m-top-40 p-32">
                    <LandingPageHealthRoute />
                </LandingPageBlock>
            </Container>
        </FullScreenContainer>
    )
}

export default withAITracking(
    reactPlugin,
    SymptomCheckerLandingPage,
    'SymptomCheckerLandingPage',
    'symptom-checker-landing-page'
)
