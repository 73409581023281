import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { openInNewTab } from 'core/utils/onClickUtils'
import pendingIcon from 'shared/assets/images/pending.svg'

interface PendingRequestProps {
    children?: JSX.Element
}

const PendingRequest = ({ children }: PendingRequestProps): JSX.Element => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const linkText =
        Source() === ServicesSource.WEBSITE
            ? 'SERVICES.SEE_PENDING_REQUESTS_EC'
            : 'SERVICES.SEE_PENDING_REQUESTS'
    const onLinkClicked = () => {
        if (Source() === ServicesSource.WEBSITE) {
            openInNewTab(process.env.REACT_APP_FRONT as string)
        } else {
            navigate(`${navigationConstants.REQUESTS.url}`)
        }
    }

    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center">
            <img src={pendingIcon} className="image-size-56" alt="pending-request-icon" />
            <div className="headlineSmall m-top-24 m-bottom-24">
                {t('SERVICES.HAS_PENDING_REQUEST')}
            </div>
            <div
                className="assura-link paragraphLight"
                onClick={() => onLinkClicked()}
                data-testid="pending-request-link"
            >
                {t(linkText)}
            </div>

            {children}
        </div>
    )
}

export default PendingRequest
