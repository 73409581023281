import { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { removeBanner, setBanner, setForbiddenBanner } from 'shared/store/banners/banners.slice'
import { getServiceSubmitStatus } from 'shared/store/services/services.slice'

import { FormPageProps, useFormPageContext } from './FormPage'

type FormPageSubmitButtonProps<T> = Pick<
    FormPageProps<T>,
    'onSuccess' | 'submitButtonText' | 'shouldCheckNoChange' | 'noChangeMessage'
>

const FormPageSubmitButton = <T,>({
    onSuccess,
    submitButtonText = 'COMMON.SEND',
    shouldCheckNoChange = false,
    noChangeMessage
}: FormPageSubmitButtonProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { handleSubmit, formState } = useFormContext()

    const { rhfExtraRefs } = useFormPageContext()

    const { isDirty, dirtyFields, isSubmitted } = formState

    const submitServiceStatus = useSelector(getServiceSubmitStatus)

    const [canFocus, setCanFocus] = useState(true)

    const invalidFormBannerId = 'services-invalid-form'

    const onValid: SubmitHandler<FieldValues> = (values) => {
        if (
            shouldCheckNoChange &&
            (!isDirty || (Object.keys(dirtyFields).length === 1 && dirtyFields.consent))
        ) {
            dispatch(
                setBanner({
                    dataTestId: 'services-no-change',
                    message: noChangeMessage ?? 'FORM.NO_CHANGE_ERROR'
                })
            )
            return
        }

        if (!hasWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }

        onSuccess(values as T)
    }

    const setFieldErrorBanner = () => {
        dispatch(
            setBanner({
                id: invalidFormBannerId,
                dataTestId: invalidFormBannerId,
                message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
            })
        )
    }

    const onError = () => {
        setCanFocus(true)
    }

    useEffect(() => {
        if (isSubmitted) {
            if (Object.keys(formState.errors).length === 0) {
                dispatch(removeBanner(invalidFormBannerId))
            } else {
                setFieldErrorBanner()
            }
        }
    }, [formState])

    useEffect(() => {
        if (formState.errors && canFocus) {
            const elements = Object.keys(formState.errors).reduce<HTMLElement[]>((acc, name) => {
                const rhfRef = document.getElementsByName(name)[0]
                const extraRef = rhfExtraRefs?.find((item) => item.name === name)

                if (rhfRef) {
                    acc.push(rhfRef)
                } else if (extraRef) {
                    const extraElement = document.getElementById(extraRef.id)
                    if (extraElement) acc.push(extraElement)
                }
                return acc
            }, [])

            elements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)

            if (elements.length > 0) {
                const errorElement = elements[0]
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }) // scrollIntoView options are not supported in Safari
                errorElement.focus({ preventScroll: true })
                setCanFocus(false) // so the form doesn't suddenly jump to the next input that has error.
            }
        }
    }, [formState, canFocus])

    return (
        <AssuraButton
            text={t(submitButtonText)}
            id="services-form-submit-button"
            variant="primary"
            onClick={handleSubmit(onValid, onError)}
            hasLoader={submitServiceStatus === LoadingStatusEnum.LOADING}
        />
    )
}

export default FormPageSubmitButton
