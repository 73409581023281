/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import { ChangeEvent, useState } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { phoneFormatter } from 'core/utils/phoneUtils'
import {
    compositeOptions,
    getValidationRulesFromOptions,
    validationOptions
} from 'core/validators/rules'
import { getErrorMessage } from 'modules/services/aventura/helpers/validations'
import { DatePickerWithLabelAndErrorsRHF } from 'modules/services/components/DatePickerWithLabelRHF/DatePickerWithLabelRHF'
import {
    AssuraFormInputRHF,
    AssuraFormInputRHFLabelWithRightIcon
} from 'modules/services/components/InputRHF/InputRHF'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'
import { AssuraFormTextAreaRHF } from 'modules/services/components/TextAreaRHF/TextAreaRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'

import { serviceName } from './accidentReportHelpers'

export const useCustomControls = (formName: string) => {
    const { t } = useTranslation()
    const { isMobile, isMD } = useWindowSize()
    const { watchSiblings } = useStepperContext()
    const { register, control, formState, setValue, resetField, getValues } = useFormContext()
    const { errors } = formState
    const [isFocus, setIsFocus] = useState<string>('')

    const PhoneInput = (
        id: string,
        cmsKey: string,
        options?: Partial<validationOptions>,
        inline?: boolean,
        placeHolder?: string,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: string | boolean | number | unknown) => void,
        labelClassName?: string
    ): JSX.Element => {
        const rules = options ? getValidationRulesFromOptions(t, options) : {}
        const sibling = watchSiblings()

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.currentTarget.value

            const val = phoneFormatter(value as string)
            setValue(id, val, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(val)
        }

        const handleOnBlur = (): void => {
            setIsFocus('')
        }

        const handleFocus = () => {
            setIsFocus(id)
        }

        const handleClear = () => {
            resetField(id)
        }
        return (
            <AssuraFormInputRHF
                id={id}
                name={id}
                label={t(`${serviceName}.${formName}.${cmsKey}_LABEL`)}
                placeHolder={
                    placeHolder
                        ? t(`${serviceName}.${formName}.${placeHolder}_PLACEHOLDER`)
                        : t(`${serviceName}.YOUR_MESSAGE`)
                }
                rules={rules}
                isInvalid={getErrorMessage(errors, id) !== ''}
                error={getErrorMessage(errors, id)}
                inline={inline !== undefined ? inline : !(isMobile || isMD)}
                labelSize={4}
                register={register}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                onFocus={handleFocus}
                autocomplete="off"
                hasButton={isFocus === id}
                onButtonClick={handleClear}
                iconClass="icon assura-close size-24"
                labelClassName={labelClassName ?? 'paragraphMedium'}
            />
        )
    }

    const TextInput = (
        id: string,
        cmsKey: string,
        options?: Partial<validationOptions>,
        inline?: boolean,
        placeHolder?: string,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: string | boolean | number | unknown) => void,
        labelClassName?: string
    ): JSX.Element => {
        const rules = options ? getValidationRulesFromOptions(t, options) : {}
        const sibling = watchSiblings()

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.currentTarget.value

            setValue(id, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(value)
        }

        const handleOnBlur = (): void => {
            setIsFocus('')
        }

        const handleFocus = () => {
            setIsFocus(id)
        }

        const handleClear = () => {
            resetField(id)
        }

        return (
            <AssuraFormInputRHF
                id={id}
                name={id}
                label={t(`${serviceName}.${formName}.${cmsKey}_LABEL`)}
                placeHolder={
                    placeHolder
                        ? t(`${serviceName}.${formName}.${placeHolder}_PLACEHOLDER`)
                        : t(`${serviceName}.YOUR_MESSAGE`)
                }
                rules={rules}
                isInvalid={getErrorMessage(errors, id) !== ''}
                error={getErrorMessage(errors, id)}
                inline={inline !== undefined ? inline : !(isMobile || isMD)}
                labelSize={4}
                register={register}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                autocomplete="off"
                onFocus={handleFocus}
                hasButton={isFocus === id}
                onButtonClick={handleClear}
                iconClass="icon assura-close size-24"
                labelClassName={labelClassName ?? 'paragraphMedium'}
            />
        )
    }

    const TextInputWithRightIcon = (
        id: string,
        cmsKey: string,
        icon: string,
        options?: Partial<validationOptions>,
        inline?: boolean,
        placeHolder?: string,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: string | boolean | number | unknown) => void,
        labelClassName?: string
    ): JSX.Element => {
        const rules = options ? getValidationRulesFromOptions(t, options) : {}
        const sibling = watchSiblings()

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.currentTarget.value

            setValue(id, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(value)
        }

        const handleOnBlur = (): void => {
            setIsFocus('')
        }

        const handleFocus = () => {
            setIsFocus(id)
        }
        const handleClear = () => {
            resetField(id)
        }

        return (
            <AssuraFormInputRHFLabelWithRightIcon
                id={id}
                name={id}
                rightIcon={icon}
                isFocus={true}
                label={t(`${serviceName}.${formName}.${cmsKey}_LABEL`)}
                placeHolder={
                    placeHolder
                        ? t(`${serviceName}.${formName}.${placeHolder}_PLACEHOLDER`)
                        : t(`${serviceName}.YOUR_MESSAGE`)
                }
                rules={rules}
                isInvalid={getErrorMessage(errors, id) !== ''}
                error={getErrorMessage(errors, id)}
                inline={inline !== undefined ? inline : !(isMobile || isMD)}
                labelSize={4}
                register={register}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                autocomplete="off"
                onFocus={handleFocus}
                hasButton={isFocus === id}
                onButtonClick={handleClear}
                iconClass={''}
                labelClassName={labelClassName ?? 'paragraphMedium'}
            />
        )
    }

    const TextareaInput = (
        id: string,
        cmsKey: string,
        options: compositeOptions,
        inline?: boolean,
        placeHolder?: string,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: string | boolean | number | unknown) => void,
        labelClassName?: string
    ): JSX.Element => {
        const rules = getValidationRulesFromOptions(t, options)
        const sibling = watchSiblings()

        const handleOnChange = (value: string): void => {
            setValue(id, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(value)
        }

        const handleOnBlur = (): void => {
            setIsFocus('')
        }

        const handleFocus = () => {
            setIsFocus(id)
        }

        return (
            <AssuraFormTextAreaRHF
                id={id}
                name={id}
                label={t(`${serviceName}.${formName}.${cmsKey}_LABEL`)}
                placeholder={
                    placeHolder
                        ? t(`${serviceName}.${formName}.${placeHolder}_PLACEHOLDER`)
                        : t(`${serviceName}.YOUR_MESSAGE`)
                }
                inline={inline !== undefined ? inline : !(isMobile || isMD)}
                isInvalid={getErrorMessage(errors, id) !== ''}
                error={getErrorMessage(errors, id)}
                rules={rules}
                maxLength={options.maxLength}
                register={register}
                onBlur={handleOnBlur}
                onFocus={handleFocus}
                onChange={handleOnChange}
                labelClassName={labelClassName ?? 'paragraphMedium'}
            />
        )
    }

    const SelectInput = (
        id: string,
        name: string,
        items: SelectMap<unknown>,
        options: validationOptions,
        inline?: boolean,
        onSelected?: (value: any) => void
    ): JSX.Element => {
        const rules = getValidationRulesFromOptions(t, options)
        const sibling = watchSiblings()

        const handleOnBlur = (value: any): void => {
            setValue(id, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            onSelected && onSelected(value)
            setIsFocus('')
        }
        return (
            <AssuraFormSelectRHF
                id={id}
                name={id}
                label={t(`${serviceName}.${formName}.${name}_LABEL`)}
                placeHolder={t(`${serviceName}.${formName}.${name}_PLACEHOLDER`)}
                rules={rules}
                items={items}
                labelSize={4}
                inline={inline !== undefined ? inline : !(isMobile || isMD)}
                register={register}
                onSelected={handleOnBlur}
                control={control}
            />
        )
    }

    const RadioButtonInput = (
        yes: boolean,
        id: string,
        groupName: string,
        onClick: (value: string | number | boolean | undefined) => void | undefined,
        options: validationOptions
    ): JSX.Element => {
        const rules = getValidationRulesFromOptions(t, options)

        return (
            <RadioRHF
                id={id}
                name={groupName}
                label={yes ? t('COMMON.YES') : t('COMMON.NO')}
                onClick={onClick}
                register={register}
                rules={rules}
            />
        )
    }

    const RadioGroup = (
        label: string,
        groupName: string,
        options: validationOptions,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: string | boolean | number | unknown) => void,
        invertColumnLength?: boolean,
        frameClassName?: string
    ): JSX.Element => {
        const error = getErrorMessage(errors, groupName)
        const sibling = watchSiblings()

        const handleChange = (value: string | number | boolean | undefined) => {
            setValue(groupName, value, {
                shouldValidate: sibling?.currentStep?.hasBeenValidated,
                shouldDirty: true,
                shouldTouch: true
            })
            changed && changed(value)
            setIsFocus('')
        }

        const mobile = isMobile || isMD
        const computedFlexDirection = mobile ? 'column' : 'row'
        const spaceBottomMobile24 = mobile ? 'm-bottom-24' : ''

        return (
            <Row
                className={`radio-button-wrapper ${frameClassName ?? ''} ${
                    error ? 'is-invalid' : ''
                }`}
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: computedFlexDirection
                }}
                data-testid={`${formName?.replace('_', '-')?.toLowerCase?.()}-${groupName}`}
                /* data-test-value MUST contains empty space char so it's computed at render. it's needed for tests purposes */
                data-test-value={getValues(groupName) ?? ' '}
            >
                <Col
                    xl={invertColumnLength ? 8 : 4}
                    sm={12}
                    xs={12}
                    className={`${spaceBottomMobile24}`}
                >
                    <div className={`justify-content-flex-start paragraphMedium`}>
                        {t(`${serviceName}.${formName}.${label}`)}
                    </div>
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignContent: 'space-between',
                        flexDirection: 'column'
                    }}
                    xl={invertColumnLength ? 4 : 8}
                >
                    <Row
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flex: '1',
                            flexDirection: 'row',
                            alignContent: 'space-between',
                            marginLeft: '0',
                            marginRight: invertColumnLength && !mobile ? '-20px' : 'unset',
                            justifyContent:
                                invertColumnLength && !mobile ? 'flex-end' : 'flex-start'
                        }}
                    >
                        <div
                            style={{
                                width: '160px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flex: '1',
                                    flexDirection: isMobile ? 'column' : 'row'
                                }}
                                id={groupName}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '60px'
                                    }}
                                    className={`${isMobile ? 'm-bottom-16' : 'm-right-16'}`}
                                >
                                    {RadioButtonInput(
                                        true,
                                        `${groupName}Yes`,
                                        groupName,
                                        handleChange,
                                        options
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '65px'
                                    }}
                                    className={`${isMobile ? '' : 'm-left-16'}`}
                                >
                                    {RadioButtonInput(
                                        false,
                                        `${groupName}No`,
                                        groupName,
                                        handleChange,
                                        options
                                    )}
                                </div>
                            </div>
                            {error && (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-start'
                                    }}
                                    className="labelExtraSmall c-error m-top-8"
                                    data-testid={`${groupName}-error`}
                                >
                                    {t(error)}
                                </div>
                            )}
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }

    const DatePicker = (
        id: string,
        cmsKey: string,
        options: Partial<validationOptions>,
        inline?: boolean,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: Date | [Date | null, Date | null] | null) => void,
        labelClassName?: string
    ): JSX.Element => {
        const rules = getValidationRulesFromOptions(t, options)
        const sibling = watchSiblings()

        const handleOnBlur = (date: Date | [Date | null, Date | null] | null): void => {
            setValue(id, date, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(date)
            setIsFocus('')
        }

        return (
            <div id={id}>
                <DatePickerWithLabelAndErrorsRHF
                    id={id}
                    name={id}
                    label={cmsKey ? t(`${serviceName}.${formName}.${cmsKey}_LABEL`) : ''}
                    placeholderKey="COMMON.DATE_FORMAT_PLACEHOLDER"
                    testId={id}
                    inline={inline !== undefined ? inline : !(isMobile || isMD)}
                    className="bg-white"
                    rules={rules}
                    onDateChange={handleOnBlur}
                    isInvalid={getErrorMessage(errors, id) !== ''}
                    error={getErrorMessage(errors, id)}
                    labelClassName={labelClassName ?? 'paragraph'}
                />
            </div>
        )
    }

    const TimePicker = (
        id: string,
        cmsKey: string,
        options: Partial<validationOptions>,
        inline?: boolean,
        changed?: (value: Date | [Date | null, Date | null] | null) => void,
        labelClassName?: string
        // needed in order to trigger form updates without validate RHF
    ): JSX.Element => {
        const rules = getValidationRulesFromOptions(t, options)
        const sibling = watchSiblings()

        const handleOnBlur = (date: Date | [Date | null, Date | null] | null): void => {
            setValue(id, date, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: sibling?.currentStep?.hasBeenValidated
            })
            changed && changed(date)
            setIsFocus('')
        }
        return (
            <div id={id}>
                <DatePickerWithLabelAndErrorsRHF
                    id={id}
                    name={id}
                    label={''}
                    //label={t(`${serviceName}.${formName}.${cmsKey}_LABEL`)}
                    placeholderKey="COMMON.TIME_FORMAT_PLACEHOLDER"
                    testId={id}
                    inline={inline !== undefined ? inline : !(isMobile || isMD)}
                    className="bg-white"
                    rules={rules}
                    isInvalid={getErrorMessage(errors, id) !== ''}
                    error={getErrorMessage(errors, id)}
                    onDateChange={handleOnBlur}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    labelClassName={labelClassName ?? 'paragraph'}
                />
            </div>
        )
    }

    const StartEndDateGroup = (
        groupName: string,
        cmsGroupKey: string,
        cmsKey: string,
        startOptions: validationOptions,
        endOptions: validationOptions,
        showStart?: boolean,
        showEnd?: boolean,
        // needed in order to trigger form updates without validate RHF
        changed?: (value: Date | [Date | null, Date | null] | null) => void,
        labelClassName?: string,
        childsLabelClassName?: string
    ): JSX.Element => {
        return (
            <Row className={`radio-button-wrapper`}>
                <Col xl={4} sm={12} xs={12}>
                    <div
                        className={`justify-content-flex-start ${
                            labelClassName ? labelClassName : 'paragraphMedium'
                        } ${isMobile || isMD ? 'm-bottom-24' : ''}`}
                        data-testid={`${formName
                            ?.replace('_', '-')
                            ?.toLowerCase?.()}-${groupName?.toLowerCase?.()}`}
                    >
                        {t(`${serviceName}.${formName}.${cmsGroupKey}`)}
                    </div>
                </Col>
                <Col xl={8} sm={12} xs={12}>
                    <Row>
                        {showStart && (
                            <Col
                                xl={6}
                                sm={12}
                                xs={12}
                                className={(isMobile || isMD) && showEnd ? 'm-bottom-32' : ''}
                            >
                                {DatePicker(
                                    `${groupName}StartDate`,
                                    `${cmsKey}_START_DATE`,
                                    startOptions,
                                    false,
                                    changed,
                                    childsLabelClassName
                                )}
                            </Col>
                        )}
                        {showEnd && (
                            <Col xl={6} sm={12} xs={12}>
                                {DatePicker(
                                    `${groupName}EndDate`,
                                    `${cmsKey}_END_DATE`,
                                    endOptions,
                                    false,
                                    changed,
                                    childsLabelClassName
                                )}
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        )
    }

    return {
        PhoneInput,
        TextInput,
        TextareaInput,
        SelectInput,
        TimePicker,
        RadioButtonInput,
        TextInputWithRightIcon,
        RadioGroup,
        DatePicker,
        StartEndDateGroup
    }
}
