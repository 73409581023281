import './TabAvatar.css'

type TabAvatarProps = {
    dataId: string
    src: string
}

const TabAvatar = ({ dataId, src }: TabAvatarProps) => {
    return (
        <div className={'tab-avatar-container bc-gray50 border-solid-width-1'}>
            <div className={'tab-avatar-image-holder'}>
                <img src={src} className={'tab-avatar-img'} data-testid={dataId} />
            </div>
        </div>
    )
}
export default TabAvatar
