import './SearchDoctorResultItem.css'

import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { dateIntervalCheck } from 'core/helpers/DateHelper'
import { mapSearchDoctorToDoctorPersonalData } from 'core/helpers/services/DoctorHelper'
import { SearchDoctor } from 'core/models/services/contract/SearchDoctor'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'
import { getSortedDoctorsOfSelectedFamilyMember } from 'shared/store/selectors/services/getSortedDoctorsOfSelectedFamilyMember'

import DoctorData from '../Doctor/DoctorData'

interface SearchDoctorResultsItemProps {
    searchDoctor: SearchDoctor
}

const SearchDoctorResultsItem = ({ searchDoctor }: SearchDoctorResultsItemProps): JSX.Element => {
    const {
        register,
        getValues,
        watch,
        clearErrors,
        formState: { errors }
    } = useFormContext()

    const doctorsOfSelectedFamilyMember = useSelector(getSortedDoctorsOfSelectedFamilyMember)
    const newDoctorId = watch('newDoctorId')

    const checkIsSameDoctor = (id: string, startDate?: Date) => {
        let isSameDoctor = false

        if (doctorsOfSelectedFamilyMember) {
            isSameDoctor = doctorsOfSelectedFamilyMember.some((contractDoctor) => {
                const currentZsr = contractDoctor.zsrNumber
                const currentStartDate = contractDoctor.startDate
                const currentEndDate = contractDoctor.endDate
                if (startDate) {
                    return (
                        dateIntervalCheck(
                            currentStartDate,
                            currentEndDate,
                            startDate.toISOString()
                        ) && currentZsr === id
                    )
                }
            })
        }
        return isSameDoctor
    }
    const isSelected = newDoctorId && newDoctorId === searchDoctor.id
    const hasMandatoryError = errors?.newDoctorId?.message === 'COMMON.MANDATORY_FIELD'

    const validateNewDoctorId = () => {
        const { newDoctorId, validityDate } = getValues()
        if (checkIsSameDoctor(newDoctorId, validityDate)) {
            return 'SERVICES.NEW_DOCTOR_IDENTICAL'
        } else {
            if (newDoctorId) {
                clearErrors('searchDoctorName')
                return true
            } else {
                return 'COMMON.MANDATORY_FIELD'
            }
        }
    }

    return (
        <div
            className={`search-doctor-result-item-container p-24 btc-gray100${
                isSelected ? ' blc-primary' : ''
            }${hasMandatoryError ? ' bg-error-background' : ''}`}
        >
            <RadioRHF
                id={searchDoctor.id}
                name="newDoctorId"
                register={register}
                classNames="d-flex"
                labelClassNames="flex-1"
                label={
                    <DoctorData
                        doctor={mapSearchDoctorToDoctorPersonalData(searchDoctor)}
                        iconPosition="right"
                        nameClassNames={`paragraphMedium ${isSelected ? 'c-primary' : 'c-black'}`}
                    />
                }
                rules={{
                    validate: validateNewDoctorId
                }}
            />
        </div>
    )
}

export default SearchDoctorResultsItem
