import navigationConstants, { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'

import { createSelector } from '@reduxjs/toolkit'

import { getPath } from '../general/general.slice'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'
import { getSummaryHospStartDate } from './getSummaryHospStartDate'
import { getSummaryLamalAccidentWantedDate } from './getSummaryLamalAccidentWantedDate'
import { getSummarySelectedYear } from './getSummarySelectedYear'
import { getSummaryWantedDate } from './getSummaryWantedDate'

export const getSummaryByPage = createSelector(
    [
        getSummaryCurrentYear,
        getSummarySelectedYear,
        getSummaryWantedDate,
        getSummaryLamalAccidentWantedDate,
        getSummaryHospStartDate,
        getPath
    ],
    (
        summaryCurrent,
        summarySelected,
        summaryWanted,
        lamalAccidentSummaryWanted,
        summaryHospStartDate,
        page
    ) => {
        if (
            page === navigationConstants.INSURANCES.url ||
            page === navigationConstants.LAMAL_RULES.url
        ) {
            return summarySelected
        } else if (page === SERVICES_FORM_ROUTES.CONTRACT_FAMILY_DOCTOR.url) {
            return summaryWanted
        } else if (page === SERVICES_FORM_ROUTES.COVERAGE_LAMAL_ACCIDENT.url) {
            return lamalAccidentSummaryWanted
        } else if (page === SERVICES_FORM_ROUTES.REPORT_HOSP_ANNOUNCEMENT.url) {
            return summaryHospStartDate
        } else {
            return summaryCurrent
        }
    }
)
