import './ReceptionMode.css'

import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MissingDataType } from 'core/enums/MissingData'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import NoValidAddress from 'modules/services/components/NoValidAddress/NoValidAddress'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { getSelectedMemberPolicyNumber } from 'shared/store/services/services.slice'

import CheckboxRHF from '../CheckboxRHF/CheckboxRHF'
import EmailField from '../EmailField/EmailField'
import { useFormPageContext } from '../FormPage/FormPage'

interface ReceptionModeProps {
    hasEcAccount: boolean
    hasRegularMail: boolean
    hasEmail: boolean
    defaultEmail: string
}
const ReceptionMode = ({
    hasEcAccount,
    hasRegularMail,
    hasEmail,
    defaultEmail
}: ReceptionModeProps): JSX.Element | null => {
    const {
        getValues,
        register,
        watch,
        formState: { errors }
    } = useFormContext()
    const { t } = useTranslation()

    const validateReceptionMode = () => {
        const { receptionMode } = getValues()
        return !!receptionMode || 'COMMON.MANDATORY_FIELD'
    }

    const emailValue = watch('email')
    const receptionModeValue = watch('receptionMode')
    const isMailReceptionModeSelected = receptionModeValue === ServicesReceptionMode.MAIL

    const { setDisplaySubmitButton } = useFormPageContext()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber) as number

    const missingData = useSelector(getMissingDataInRequest)

    const isNotAllowedToSelectMail =
        isMailReceptionModeSelected &&
        selectedPolicyNumber &&
        missingData &&
        missingData[selectedPolicyNumber]?.includes(MissingDataType.ADDRESS_MISSING)

    useEffect(() => {
        setDisplaySubmitButton(!isNotAllowedToSelectMail)
    }, [isNotAllowedToSelectMail])

    if (hasEcAccount || hasRegularMail || hasEmail) {
        return (
            <>
                <div className="reception-mode-container bg-white bc-gray100 m-top-32 p-32">
                    <Row>
                        <Col xl={4} md={12} className="d-flex position-relative">
                            <div className="paragraphMedium">{t('SERVICES.RECEPTION_MODE')}</div>
                        </Col>
                        <Col xl={8} md={12} className="d-flex flex-column">
                            <div className="d-flex reception-mode-type-container">
                                {hasEcAccount && (
                                    <RadioRHF
                                        id={ServicesReceptionMode.ELECTRONIC}
                                        name="receptionMode"
                                        label={t('SERVICES.RECEPTION_BY_INBOX')}
                                        register={register}
                                        classNames="flex-1 reception-mode-type"
                                        rules={{
                                            validate: validateReceptionMode
                                        }}
                                    />
                                )}
                                {hasEmail && (
                                    <RadioRHF
                                        id={ServicesReceptionMode.EMAIL}
                                        name="receptionMode"
                                        label={t('SERVICES.RECEPTION_BY_EMAIL')}
                                        register={register}
                                        classNames="flex-1 reception-mode-type"
                                        rules={{
                                            validate: validateReceptionMode
                                        }}
                                    />
                                )}
                                {hasRegularMail && (
                                    <RadioRHF
                                        id={ServicesReceptionMode.MAIL}
                                        name="receptionMode"
                                        label={t('SERVICES.RECEPTION_BY_MAIL')}
                                        register={register}
                                        classNames="flex-1 reception-mode-type"
                                        rules={{
                                            validate: validateReceptionMode
                                        }}
                                    />
                                )}
                            </div>
                            {errors?.receptionMode?.message && (
                                <div className="labelExtraSmall c-primary">
                                    {t(errors.receptionMode.message as string)}
                                </div>
                            )}
                        </Col>
                    </Row>
                    {receptionModeValue === ServicesReceptionMode.EMAIL && (
                        <>
                            <div className="m-top-48">
                                <EmailField isReadOnly={Source() === ServicesSource.WEBSITE} />
                            </div>
                            {(defaultEmail === '' || defaultEmail !== emailValue) && (
                                <div className="m-top-48">
                                    <CheckboxRHF
                                        id="updateEmail"
                                        name="updateEmail"
                                        label={t('SERVICES.CHANGE_MAIL')}
                                        register={register}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                {isNotAllowedToSelectMail && (
                    <NoValidAddress message={t('SERVICES.COMMON_NO_VALID_ADDRESS')} />
                )}
            </>
        )
    } else {
        return null
    }
}

export default ReceptionMode
