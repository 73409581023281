import './FaqAnswerItem.css'

import { Fragment } from 'react'

import { AnswerBulletPoint } from 'core/models/Faq'

import FaqAnswerBulletPoint from './FaqAnswerBulletPoint'

interface FaqAnswerItemProps {
    questionId: string
    item: AnswerBulletPoint[]
}

const FaqAnswerItem = ({ questionId, item }: FaqAnswerItemProps): JSX.Element => {
    return (
        <div className="faq-answer-item">
            {item.map((answerBulletPoint, index) => {
                return (
                    <Fragment key={`${questionId}-answer-${index}`}>
                        <FaqAnswerBulletPoint answerBulletPoint={answerBulletPoint} />
                    </Fragment>
                )
            })}
        </div>
    )
}

export default FaqAnswerItem
