import './CommunicationsPanel.css'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { mapDocumentFile } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { DocumentsGroup } from 'core/models/documents/Document'
import useWindowSize from 'core/services/useWindowSize'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'
import {
    getSelectedCommunication,
    setSelectedCommunicationId
} from 'shared/store/communications/communications.slice'
import { fetchDocumentFile, getDocumentFiles } from 'shared/store/documents/documents.slice'
import { useAppDispatch } from 'shared/store/store'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import CommunicationsPanelHeader from './CommunicationsPanelHeader'

interface CommunicationsPanelProps {
    documentsGroup: DocumentsGroup
}

const CommunicationsPanel = ({ documentsGroup }: CommunicationsPanelProps): JSX.Element => {
    const windowSize = useWindowSize()
    const dispatch = useAppDispatch()

    const selectedDocument = useSelector(getSelectedCommunication)

    const documents = useSelector(getDocumentFiles)

    let mappedDocumentFile: AssuraDocument | undefined
    if (selectedDocument) mappedDocumentFile = mapDocumentFile(selectedDocument, documents)

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => mappedDocumentFile?.fileName ?? ''
        }
    })

    const renderPdfError = (): JSX.Element => (
        <AssuraDocumentsError
            onClick={() => {
                if (selectedDocument) {
                    dispatch(
                        fetchDocumentFile({
                            documentId: selectedDocument?.documentId,
                            documentsGroup,
                            isRead: selectedDocument.isRead,
                            reload: true
                        })
                    )
                }
            }}
        />
    )

    const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

    const closePanel = () => {
        dispatch(setSelectedCommunicationId(null))
    }

    useEffect(() => {
        if (selectedDocument && mappedDocumentFile === undefined) {
            dispatch(
                fetchDocumentFile({
                    documentId: selectedDocument?.documentId,
                    documentsGroup,
                    isRead: selectedDocument.isRead
                })
            )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        }
    }, [selectedDocument])

    return (
        <AssuraPanel
            type={'right'}
            isOpen={selectedDocument !== null}
            size={windowSize.isXL ? 75 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <>
                {selectedDocument !== null && (
                    <div className="communications-pdf-viewer">
                        <CommunicationsPanelHeader
                            selectedDocument={selectedDocument}
                            closePanel={closePanel}
                            toolbarPluginInstance={toolbarPluginInstance}
                            statusIsOk={mappedDocumentFile?.loadStatus === LoadingStatusEnum.OK}
                            doc={mappedDocumentFile}
                        />
                        <AssuraLoadAndError
                            status={mappedDocumentFile?.loadStatus ?? LoadingStatusEnum.ERROR}
                            ErrorComponent={renderPdfError()}
                        >
                            <AssuraPdfViewer
                                toolbarPluginInstance={toolbarPluginInstance}
                                doc={mappedDocumentFile}
                                renderPdfError={renderPdfError}
                                renderPdfLoader={renderPdfLoader}
                            />
                        </AssuraLoadAndError>
                    </div>
                )}
            </>
        </AssuraPanel>
    )
}

export default CommunicationsPanel
