import HowItWorks from 'core/models/HowItWorks'

import { createSelector } from '@reduxjs/toolkit'

import { getInitialPolicyNumber } from '../familyMember/familyMember.slice'
import { getProductsCms } from '../products/products.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'
import { RootState } from '../store'
import { getSummaryByPage } from './getSummaryByPage'

export const getHowItWorksData = createSelector(
    [
        getProductsCms,
        (state: RootState) => state.products.year,
        getInitialPolicyNumber,
        getSelectedMemberPolicyNumber,
        getSummaryByPage
    ],
    (
        productCms,
        yearSelected,
        initialPolicyNumber,
        selectedMemberPolicyNumber,
        summary
    ): HowItWorks => {
        return {
            productCms,
            yearSelected,
            initialPolicyNumber,
            selectedMemberPolicyNumber,
            summary
        }
    }
)
