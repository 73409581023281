import axios from 'axios'
import { getAssuraDocumentType } from 'core/helpers/documents/DocumentFileHelper'
import { RequestDocument } from 'core/models/services/RequestData'
import { openInNewTab } from 'core/utils/onClickUtils'

import { convertBlobToBase64 } from './Images'

export const fetchDocumentFileApi = async (id: string): Promise<string> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Documents/${id}`, {
        responseType: 'blob'
    })

    const blob = new Blob([result.data], { type: 'application/pdf' })

    return await convertBlobToBase64(blob)
}

export const fetchAttachment = (
    attachment: RequestDocument,
    setError: React.Dispatch<React.SetStateAction<boolean>> | null,
    setLoading: (error: boolean) => void
): void => {
    const type = getAssuraDocumentType(attachment.fileName)
    if (type) {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_COMMON}/api/v2/Documents/${attachment.id}`, {
                responseType: 'blob'
            })
            .then(({ data }) => {
                const blob = new Blob([data], { type })
                const downloadUrl = window.URL.createObjectURL(blob)
                openInNewTab(downloadUrl)
            })
            .catch(() => {
                if (setError) setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }
}

export const fetchDocumentFileToDirectDownload = async (id: string): Promise<void> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Documents/${id}`, {
        responseType: 'blob'
    })

    const blob = new Blob([result.data], { type: 'application/pdf' })
    const downloadUrl = window.URL.createObjectURL(blob)
    openInNewTab(downloadUrl)
}
