import { useSelector } from 'react-redux'

import { getDateFilterSelections } from 'core/helpers/CommonAssuraTableFilters'
import { DocumentsFilterSelectionValue } from 'core/models/documents/Document'
import { TableFilterState } from 'core/models/TableFilter'
import { getUtnSelectionsForFilter } from 'shared/store/selectors/getUtnSelectionsForFilter'

const getDocumentsFilterState = (
    defaultUtn?: number
): TableFilterState<DocumentsFilterSelectionValue> => {
    const filters: TableFilterState<DocumentsFilterSelectionValue> = {}

    filters['date'] = {
        label: 'DOCUMENTS.FILTER_BY_YEAR',
        selections: getDateFilterSelections()
    }

    const memberSelections = useSelector(getUtnSelectionsForFilter)
    const newSelections = memberSelections ? new Map(memberSelections) : null

    if (newSelections) {
        if (defaultUtn) {
            const defaultSelection = Array.from(newSelections.entries()).find(
                ([_key, item]) => item.value === defaultUtn
            )
            if (defaultSelection)
                newSelections.set(defaultSelection[0], {
                    ...defaultSelection[1],
                    isSelected: true
                })
        }

        filters['utn'] = {
            label: 'COMMON_INSURED',
            selections: newSelections
        }
    }

    return filters
}

export default getDocumentsFilterState
