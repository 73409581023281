import { FilteredFrequentRequest } from 'core/models/FrequentRequest'

import { createSelector } from '@reduxjs/toolkit'

import { getFrequentRequestsCms } from '../services/frequentRequests/frequentRequests.slice'

export const getFilteredFrequentRequests = createSelector([getFrequentRequestsCms], (requestsCms) => {
    return requestsCms
        .filter((requestCms) => requestCms.is_active)
        .map((request) => {
            return {
                _id: request._id,
                icon_id: request.icon_id,
                label: request.label,
                label_it: request.label_it,
                label_de: request.label_de,
                label_en: request.label_en,
                ec_link: request.ec_link._id
            } as FilteredFrequentRequest
        })
        .slice(0, 4)
})
