import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    CommonDocumentsState,
    Document,
    DocumentsData,
    DocumentsDataState,
    FetchDocumentsCommonPayload
} from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export type CommunicationsState = CommonDocumentsState &
    DocumentsDataState & {
        notificationsCount: number
    }

const INITIAL_STATE_COMMUNICATIONS: CommunicationsState = {
    selectedDocument: null,
    initLoadingStatus: LoadingStatusEnum.OK,
    filteringLoadingStatus: LoadingStatusEnum.OK,
    paginatingLoadingStatus: LoadingStatusEnum.OK,
    documents: [],
    hasMore: false,
    paginationStartDate: null,
    notificationsCount: 0
}

export const communicationsSlice = createSlice({
    name: 'communications',
    initialState: INITIAL_STATE_COMMUNICATIONS,
    reducers: {
        setSelectedCommunicationId: (
            state: CommunicationsState,
            _action: PayloadAction<string | null>
        ) => {
            return state
        },
        setSelectedDocument: (
            state: CommunicationsState,
            action: PayloadAction<DocumentRow | null>
        ) => {
            state.selectedDocument = action.payload
        },
        fetchCommunications: (
            _state: CommunicationsState,
            _action: PayloadAction<FetchDocumentsCommonPayload>
        ) => {
            return _state
        },
        setCommunications: (state, action: PayloadAction<DocumentsData>) => {
            state.documents = action.payload.documents
            state.hasMore = action.payload.hasMore
            state.paginationStartDate = action.payload.paginationStartDate
        },
        setInitLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.initLoadingStatus = action.payload
        },
        setFilteringLoadingStatus: (
            state: CommunicationsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.filteringLoadingStatus = action.payload
        },
        setPaginatingLoadingStatus: (
            state: CommunicationsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.paginatingLoadingStatus = action.payload
        },
        setCommunicationToReadStatus: (
            state: CommunicationsState,
            action: PayloadAction<string>
        ) => {
            const documentId = action.payload

            const selectedDocumentIndex = state.documents.findIndex((doc) => doc.id === documentId)
            if (selectedDocumentIndex !== -1) {
                state.documents.splice(selectedDocumentIndex, 1, {
                    ...state.documents[selectedDocumentIndex],
                    isRead: true
                })
            }
        },
        fetchCommunicationsNotificationCount: (state: CommunicationsState) => {
            return state
        },
        setNotificationCount: (state: CommunicationsState, action: PayloadAction<number>) => {
            state.notificationsCount = action.payload
        }
    }
})

export const {
    setSelectedCommunicationId,
    setSelectedDocument,
    fetchCommunications,
    setCommunications,
    setInitLoadingStatus,
    setFilteringLoadingStatus,
    setPaginatingLoadingStatus,
    setCommunicationToReadStatus,
    fetchCommunicationsNotificationCount,
    setNotificationCount
} = communicationsSlice.actions

export const getSelectedCommunication: Selector<RootState, DocumentRow | null> = (
    state: RootState
) => state.communications.selectedDocument

export const getCommunicationsState: Selector<RootState, CommunicationsState> = (
    state: RootState
) => state.communications

export const getCommunications: Selector<RootState, Document[]> = (state: RootState) =>
    state.communications.documents

export const getCommunicationsInitLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.communications.initLoadingStatus

export const getCommunicationsNotificationCount: Selector<RootState, number> = (state: RootState) =>
    state.communications.notificationsCount

export default communicationsSlice.reducer
