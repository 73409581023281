import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'

interface AssuraIsPendingProps {
    testId: string
}

const AssuraIsPending = ({ testId }: AssuraIsPendingProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    return (
        <div data-testid={`${testId}-is-pending`} className="d-flex c-gray500">
            <i className="icon assura-pending-1 size-24" />
            {isXL && <div className="cta m-left-8">{t('COMMON.IS_PENDING')}</div>}
        </div>
    )
}

export default AssuraIsPending
