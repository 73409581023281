import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'

const AddressStreetComp = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const {
        register,
        formState: { errors },
        setValue
    } = useFormContext()

    const [fieldFocused, setFieldFocused] = useState(false)

    return (
        <div className="m-top-48">
            <AssuraFormInputRHF
                inline={isXL}
                labelSize={4}
                label="C/O"
                name="streetComp"
                id="services-personal-data-address-streetComp"
                isInvalid={Boolean(errors?.streetComp)}
                error={errors.streetComp?.message as string}
                hasButton={fieldFocused}
                onButtonClick={() => setValue('streetComp', '')}
                iconClass="icon assura-close size-24"
                rules={{
                    maxLength: {
                        value: 100,
                        message: t('FORM.INPUT_MAX_CHARACTERS', { number: 100 })
                    }
                }}
                register={register}
                onFocus={() => setFieldFocused(true)}
                onBlur={() => setFieldFocused(false)}
            />
        </div>
    )
}

export default AddressStreetComp
