import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'

import { createSelector } from '@reduxjs/toolkit'

import { getUserUtn } from '../general/general.slice'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'

export const getConnectedFamilyMember = createSelector([getSummaryCurrentYear, getUserUtn], (summary, userUtn): ConnectedFamilyMember | undefined => {
    const connectedPerson = summary?.insuredPersons.find(
        (insuredPerson) => insuredPerson.userTiersNumber === userUtn
    )
    if (connectedPerson) {
        return {
            lastName: connectedPerson.lastName,
            firstName: connectedPerson.firstName,
            policyNumber: connectedPerson.policyNumber,
            userTiersNumber: connectedPerson.userTiersNumber,
            login: connectedPerson.login
        }
    }
    return undefined
})
