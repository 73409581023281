import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import '@assura/bootstrap/assura-bootstrap/css/assura-bootstrap.min.css'
import 'core/helpers/ConsoleHelper'
import 'core/interceptors/Config'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import axios from 'axios'
import { LANGUAGES } from 'core/constants/lang'
import { getAuthenticationConfiguration } from 'core/helpers/AuthenticationHelper'
import { setLangCookie } from 'core/helpers/CookieHelper'
import Manifest from 'core/models/Manifest'
import { GlobalHistory } from 'shared/components/GlobalHistory/GlobalHistory'
import { AppInsightDatasCustomProvider } from 'shared/contexts/AppInsightContext'
import store from 'shared/store/store'

import App from './App'

const signedInCallback = () => {
    ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <AppInsightDatasCustomProvider>
                    <BrowserRouter>
                        <GlobalHistory /> {/* This component is used to navigate from anywhere in the app */}
                        <App />
                    </BrowserRouter>
                </AppInsightDatasCustomProvider>
            </React.StrictMode>
        </Provider>,
        document.getElementById('root')
    )
}

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())
if (LANGUAGES.find((l) => l === params.culture)) {
    setLangCookie(params.culture)
}

export const buildVersionKey = 'buildVersion'

axios.get(`/manifest.json?timespan=${new Date().getTime()}`).then((resp: Manifest) => {
    const buildVersion = resp.data.build_version
    const localStorageBuildVersion = localStorage.getItem(buildVersionKey)
    if (localStorageBuildVersion !== buildVersion) {
        if (caches) {
            caches.keys().then(function (names) {
                for (const name of names) caches.delete(name)
            })
        }
        const separator = window.location.href.includes('?') ? '&' : '?'
        const newLocation = `${window.location.href}${separator}version=${buildVersion}`
        localStorage.setItem(buildVersionKey, buildVersion)
        window.location.href = newLocation
    }
})

const authService = getAuthenticationConfiguration(signedInCallback)

authService.setup()
authService.setupRefreshToken()

export default authService
