import axios from 'axios'
import { MissingData } from 'core/models/MissingData'

export const fetchMissingDataApi = async (): Promise<MissingData[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/missingdata`
    )

    return result.data
}
