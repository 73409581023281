/**
 * Stepper reducer ACTIONS
 */
export enum StepperActionsKind {
    INIT = 'INIT',
    RESET = 'RESET',
    ADD_STEP = 'ADD_STEP',
    SET_FIELDS = 'SET_FIELDS',
    GO_TO_STEP = 'GO_TO_STEP',
    COMPUTE_SIBLING = 'COMPUTE_SIBLING',
    COMPUTE_VALIDATION = 'COMPUTE_VALIDATION',
    UPDATE_RHF_COMMON = 'UPDATE_RHF_COMMON',
    SET_DATA = 'SET_DATA',
    SET_SUBMITTED_TIMES = 'SET_SUBMITTED_TIMES',
    SET_VISIBLE = 'SET_VISIBLE',
    SET_STEP_SIZE = 'SET_STEP_SIZE',
    NAVIGATION_REQUEST = 'NAVIGATION_REQUEST',
    ADD_ERROR = 'ADD_ERROR',
    SUBMIT_REQUEST = 'SUBMIT_REQUEST',
    ADD_CUSTOM_VALIDATORS = 'ADD_CUSTOM_VALIDATORS',
    REMOVE_ERROR = 'REMOVE_ERROR',
    CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS',
    SET_FIELD_DEFAULT_VALUE = 'SET_FIELD_DEFAULT_VALUE',
    SET_STEPPER_DEFAULT_VALUE = 'SET_STEPPER_DEFAULT_VALUE'
}
