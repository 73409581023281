import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import AssuraIconButton from '../AssuraIconButton/AssuraIconButton'
import FullScreenContainer from '../FullScreenContainer/FullScreenContainer'

interface PageHeaderProps {
    titleKey: string
    testId: string
    backAction?: () => void
    classNames?: string
}

const PageHeader = ({ titleKey, testId, backAction, classNames }: PageHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <FullScreenContainer
            color="gray50"
            complementaryClasses={classNames}
            data-testid={`${testId}-container`}
        >
            <Container className="d-flex flex-column align-items-start p-top-56 p-bottom-40">
                {backAction && (
                    <AssuraIconButton
                        id={`${testId}-back-to`}
                        icon="assura-back"
                        variant="primary"
                        size="medium"
                        onClick={backAction}
                        label={t('COMMON.BACK')}
                        classNames="m-bottom-16"
                    />
                )}
                <span className="title break-word" data-testid={`${testId}-title`}>
                    {t(titleKey)}
                </span>
            </Container>
        </FullScreenContainer>
    )
}

export default PageHeader
