import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getPaymentFrequencyStartDate } from 'core/helpers/services/PaymentFrequencyHelper'
import { PaymentFrequencyRHF } from 'core/models/services/financial/PaymentFrequency'
import ConsentCheckBoxRHF from 'modules/services/components/ConsentCheckBoxRHF/ConsentCheckBoxRHF'
import FormPage from 'modules/services/components/FormPage/FormPage'
import NoHead from 'modules/services/components/NoHead/NoHead'
import NotAllowed from 'modules/services/components/NotAllowed/NotAllowed'
import PendingRequest from 'modules/services/components/PendingRequest/PendingRequest'
import { getHeadOfFamily } from 'shared/store/general/general.slice'
import { getFamilyGroupSettings } from 'shared/store/selectors/getFamilyGroupSettings'
import { getPeriodicityCurrentYearAndConnectedMember } from 'shared/store/selectors/getPeriodicityCurrentYearAndConnectedMember'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'
import { isTherePaymentFrequencyInRequests } from 'shared/store/selectors/isThereInRequests'
import {
    getServicesPeriodicity,
    onSubmitPaymentFrequency
} from 'shared/store/services/financial/periodicity.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PaymentFrequencySelect from '../components/PaymentFrequencySelect/PaymentFrequencySelect'
import PaymentFrequencyStartDate from '../components/PaymentFrequencyStartDate/PaymentFrequencyStartDate'

const ServicesPaymentFrequencyModification = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const familyGroup = useSelector(getFamilyGroupSettings)
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? false
    const headOfFamily = useSelector(getHeadOfFamily)
    const isPending = useSelector(isTherePaymentFrequencyInRequests)
    const servicesPeriodicity = useSelector(getServicesPeriodicity)
    const currentPeriodicity = useSelector(getPeriodicityCurrentYearAndConnectedMember)

    const isFromPublic = Source() === ServicesSource.WEBSITE
    const shouldConsent = isFromPublic || familyGroup.length > 1

    const initialValues: PaymentFrequencyRHF = {
        periodicity: undefined,
        changeDate: undefined,
        consent: !shouldConsent
    }

    const [defaultValues, setDefaultValues] = useState<PaymentFrequencyRHF>(initialValues)

    const getNotAllowed = () => {
        if (isFromPublic && !isHeadOfFamily) {
            return (
                <NoHead
                    title={t('SERVICES.FINANCIAL_PAYMENT_FREQUENCY_NO_HEAD_TITLE')}
                    message={
                        <Trans
                            i18nKey="SERVICES.FINANCIAL_PAYMENT_FREQUENCY_NO_HEAD_TEXT"
                            values={{ firstName: headOfFamily?.firstname ?? '' }}
                            components={{ b: <b /> }}
                        />
                    }
                />
            )
        }
        if (servicesPeriodicity.wsStatus === HttpResponseEnum.CONFLICT) {
            return (
                <NotAllowed
                    title={t('SERVICES.NOT_ALLOWED_REQUEST')}
                    message={t('COMMON.CONTACT_US')}
                    dataTestId="services-payment-freaquency-modification-not-allowed"
                />
            )
        }
        return undefined
    }

    const onSuccess = (values: PaymentFrequencyRHF) => {
        if (values.periodicity && values.changeDate) {
            dispatch(
                onSubmitPaymentFrequency({
                    periodicity: values.periodicity,
                    changeDate: values.changeDate
                })
            )
        }
    }

    useEffect(() => {
        if (currentPeriodicity && servicesPeriodicity.periodicities.length > 0) {
            setDefaultValues({
                ...defaultValues,
                periodicity: currentPeriodicity,
                changeDate: getPaymentFrequencyStartDate(
                    currentPeriodicity,
                    servicesPeriodicity.periodicities
                )
            })
        }
    }, [currentPeriodicity, servicesPeriodicity])

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.PAYMENT_FREQ_MODIFICATION}
            formTitle={{
                title: t('SERVICES.FINANCIAL_PAYMENT_FREQUENCY_TITLE'),
                category: t('SERVICES.PAYMENT_AND_INVOICING')
            }}
            onSuccess={onSuccess}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.PAYMENT_FREQ_MODIFICATION} />}
            isPending={isPending ? <PendingRequest /> : undefined}
            notAllowed={getNotAllowed()}
            shouldCheckNoChange
            noChangeMessage="SERVICES.FINANCIAL_PAYMENT_FREQUENCY_NO_CHANGE_ERROR"
        >
            <>
                <PaymentFrequencySelect
                    currentPeriodicity={currentPeriodicity}
                    servicesPeriodicity={servicesPeriodicity.periodicities}
                />
                <PaymentFrequencyStartDate />
                {shouldConsent && <ConsentCheckBoxRHF name="consent" />}
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    ServicesPaymentFrequencyModification,
    'ServicesPaymentFrequencyModification',
    'services-form-container'
)
