import './AssuraNotFound.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import NavigationConstants from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { GetAssetIcon } from 'core/utils/assetUtils'
import { getRedirectionParameters } from 'shared/store/general/general.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AssuraButton from '../AssuraButton/AssuraButton'

const AssuraNotFound = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const redirectionParameters = useSelector(getRedirectionParameters)

    const goToHome = () => {
        if (Source() === ServicesSource.WEBSITE) {
            window.location.href =
                redirectionParameters.origin && redirectionParameters.origin !== ''
                    ? redirectionParameters.origin
                    : (process.env.REACT_APP_PUBLIC?.toString() as string)
        } else {
            navigate(NavigationConstants.HOME.url)
        }
    }

    return (
        <div
            className="d-flex flex-column align-items-center p-top-16 p-bottom-16"
            style={{ width: 416 }}
        >
            <img
                src={GetAssetIcon('direction')}
                className="assura-not-found-icon"
                alt="direction"
            />
            <div className="label c-primary m-top-40">
                {t('COMMON.ERROR_CODE', { code: '404' })}
            </div>

            <div className="headlineMedium m-top-16" style={{ textAlign: 'center' }}>
                {t('NOT_FOUND.TITLE')}
            </div>

            <AssuraButton
                classNames="m-top-40"
                text={t('NOT_FOUND.GO_HOME')}
                id="assura-not-found-go-home"
                variant="primary"
                onClick={goToHome}
            />
        </div>
    )
}

export default withAITracking(
    reactPlugin,
    AssuraNotFound,
    'AssuraNotFound',
    'assura-not-found-container container'
)
