import './GeneralQuestionFormLinks.css'

import { useDispatch } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { GeneralQuestions } from 'core/models/cms/GeneralQuestionTopicCms'
import { RequestEcLink } from 'core/models/services/ServicesHub'
import { GetAssetIcon } from 'core/utils/assetUtils'
import RequestsEcLink from 'modules/servicesHub/components/RequestsEcLink/RequestsEcLink'
import { setSelectedServiceForm } from 'shared/store/services/services.slice'

interface GeneralQuestionsFormLinksProps {
    generalQuestions: GeneralQuestions
}

const GeneralQuestionFormLinks = ({
    generalQuestions
}: GeneralQuestionsFormLinksProps): JSX.Element => {
    const dispatch = useDispatch()

    // How many times a form is requested from general question
    const handleClickCallback = (link_url: string) => {
        // TODO: clean-up once we found a better way to handle this:
        // This is a temporary FIX. When we come from public toward Personal data form we need to
        // land in the PersonalDataService form whereas we should pick PersonalData when we redirect from EC.
        // we keep it that way until we found another cleanest alternative.
        const redirectUrl =
            Source() !== ServicesSource.WEBSITE && link_url === 'nameOrCivilStatusChange'
                ? 'PersonalData'
                : link_url
        setTimeout(() => {
            dispatch(
                setSelectedServiceForm({
                    topic_category: generalQuestions?.id,
                    visit_origin: ServiceType.GENERAL_QUESTION,
                    target_form: redirectUrl
                })
            )

            sendEvent(analyticsConstants.EVENTS.WEB_FORM_SELECTION, {
                [analyticsConstants.PARAMS.VISIT_ORIGIN]: ServiceType.GENERAL_QUESTION,
                [analyticsConstants.PARAMS.REQUEST_PURPOSE]: redirectUrl,
                [analyticsConstants.PARAMS.TOPIC_CATEGORY]: generalQuestions?.id
            })
        }, 100)
    }

    return (
        <div className="gc-form-link-frame bg-white border-solid-width-1 bc-gray100 m-top-32 m-bottom-8">
            <div className="gc-form-link-title d-flex justify-content-start align-items-center m-top-32 m-left-32 m-right-32 m-bottom-24">
                <img
                    src={GetAssetIcon(generalQuestions.forms_icon)}
                    alt={generalQuestions.forms_icon}
                    width={56}
                    height={56}
                />
                <span className="m-left-16 headlineSmallMedium" data-testid="gc-faq-section-title">
                    {generalQuestions.forms_title}
                </span>
            </div>

            <div className="gc-form-link-items d-flex flex-1 flex-column align-items-stretch m-right-32 m-left-32 m-bottom-32 border-top-solid-width-1 bc-gray100">
                {generalQuestions.forms_collection?.map((formItem) => {
                    if (!formItem || formItem?.ec_link === null) return null
                    // TODO: clean-up once we found a better way to handle this:
                    // This is a temporary FIX. When we come from public toward Personal data form we need to
                    // land in the PersonalDataService form whereas we should pick PersonalData when we redirect from EC.
                    // we keep it that way until we found another cleanest alternative.
                    const redirectUrlId =
                        Source() !== ServicesSource.WEBSITE &&
                        formItem?.ec_link?.display === 'nameOrCivilStatusChange'
                            ? '64639307fc453a5adc0a53c1'
                            : formItem?.ec_link._id

                    const ecLink: RequestEcLink = {
                        title: formItem?.title,
                        ecLinkId: redirectUrlId,
                        icon: '' // not needed
                    }
                    return (
                        <RequestsEcLink
                            dataTestId="general-question-ec-link-"
                            key={`general-question-services-${ecLink.ecLinkId}`}
                            link={ecLink}
                            callback={handleClickCallback}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default GeneralQuestionFormLinks
