import { MissingDataType } from 'core/enums/MissingData'
import { RequestStatus } from 'core/enums/RequestStatus'
import { ServiceType } from 'core/enums/ServiceType'
import { RequestData } from 'core/models/services/RequestData'

import { createSelector } from '@reduxjs/toolkit'

import { getMissingData } from '../missingData/missingData.slice'
import { getServicesRequests } from '../services/requests/requests.slice'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'

// Liste des données manquantes qui n'ont pas de demande de modification en cours associée

const mapMissingDataToRequest = (missingDataType: MissingDataType): ServiceType | undefined => {
    switch (missingDataType) {
        case MissingDataType.EMAIL_MISSING:
            return ServiceType.EMAIL_CHANGE
        case MissingDataType.PHONE_NUMBER_MISSING:
            return ServiceType.PHONE_CHANGE
        case MissingDataType.IBAN_MISSING:
            return ServiceType.BANK_REL_CHANGE
        case MissingDataType.MARITAL_STATUS_MISSING:
            return ServiceType.NAME_CIVIL_CHANGE
        case MissingDataType.ADDRESS_MISSING:
            return ServiceType.ADDRESS_CHANGE
        default:
            return undefined
    }
}

const isDataInActiveRequest = (
    policyNumber: number,
    missingDataType: MissingDataType,
    requests: RequestData[]
): boolean => {
    const requestType = mapMissingDataToRequest(missingDataType)
    return requests.some(
        (request) =>
            request.policyNumber === policyNumber &&
            request.type === requestType &&
            request.status !== RequestStatus.CANCELLED
    )
}

export const getMissingDataInRequest = createSelector(
    [getSummaryCurrentYear, getMissingData, getServicesRequests],
    (summary, missingData, requests): Record<number, MissingDataType[]> => {
        return summary.insuredPersons.reduce<Record<number, MissingDataType[]>>(
            (acc, personSummary) => {
                const currentMissingData: MissingDataType[] = []

                const missingDataForMember =
                    missingData &&
                    Array.isArray(missingData) &&
                    missingData?.find((item) => item.policyNumber === personSummary.policyNumber)

                if (missingDataForMember)
                    missingDataForMember.missingDatas.forEach((type) => {
                        if (!isDataInActiveRequest(personSummary.policyNumber, type, requests)) {
                            currentMissingData.push(type)
                        }
                    })

                if (currentMissingData.length > 0) {
                    acc[personSummary.policyNumber] = currentMissingData
                }

                return acc
            },
            {} as Record<number, MissingDataType[]>
        )
    }
)
