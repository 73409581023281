import './EnveloppesSubmissionDropzone.css'

import { useState } from 'react'
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DROPZONE_ACCEPTED_PDF } from 'core/constants/Dropzone'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { generateGUID } from 'core/helpers/GuidHelper'
import pdfAnalyzer, { PdfAnalyzerError, PdfAnalyzerReport } from 'core/helpers/PdfAnalyzer'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import AssuraDropzone from 'shared/components/AssuraDropzone/AssuraDropzone'

import { ErrorMessage } from '@hookform/error-message'

let pdfTimeout: NodeJS.Timeout

interface EnveloppesSubmissionDropzoneProps {
    addPdf: (droppedFile: DroppedFile) => void
}

const EnveloppesSubmissionDropzone = ({
    addPdf
}: EnveloppesSubmissionDropzoneProps): JSX.Element => {
    const { t } = useTranslation()
    const defaultError = 'DOCUMENTS.PDF_LOAD_ERROR_GENERIC'
    const {
        formState: { errors }
    } = useFormContext()

    const [fileError, setFileError] = useState<string>('')
    const [importFileStatus, setImportFileStatus] = useState<LoadingStatusEnum>()

    const setImportError = (error: string) => {
        setImportFileStatus(LoadingStatusEnum.ERROR)
        setFileError(error)
    }

    const handleRejectedFile = (rejectedFile: FileRejection) => {
        getErrorMessage((rejectedFile.errors[0]?.code as ErrorCode) || undefined)
    }

    const getErrorMessage = (error: ErrorCode | PdfAnalyzerError | undefined) => {
        let fileError
        switch (error) {
            case ErrorCode.FileInvalidType:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_INVALID_TYPE'
                break
            case ErrorCode.TooManyFiles:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_MANY_FILES'
                break
            case ErrorCode.FileTooLarge:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_FILE_TOO_BIG'
                break
            case PdfAnalyzerError.PasswordException:
                fileError = 'DOCUMENTS.PDF_LOAD_ERROR_PASSWORD'
                break
            default:
                fileError = defaultError
        }
        setImportError(fileError)
    }

    const handleAcceptedFile = (acceptedFile: FileWithPath) => {
        const reader = new FileReader()
        reader.onload = () => {
            const binarystr = reader.result
            const pdf64 = (binarystr && binarystr.toString()) || ''
            pdfAnalyze(pdf64).then((result) =>
                onDocumentLoad(result as PdfAnalyzerReport, acceptedFile.name, pdf64)
            )
            clearTimeout(pdfTimeout)
            pdfTimeout = setTimeout(() => {
                setFileError(defaultError)
                setImportFileStatus(LoadingStatusEnum.ERROR)
            }, 10000)
        }

        reader.onerror = () => {
            setFileError(defaultError)
        }
        reader.readAsDataURL(acceptedFile)
    }

    const onDocumentLoad = (
        pdfReport: PdfAnalyzerReport,
        fileName: string,
        pdfFileBase64: string
    ) => {
        clearTimeout(pdfTimeout)
        if (pdfReport.isValid) {
            if (pdfReport.pagesCount < 100) {
                setImportFileStatus(LoadingStatusEnum.OK)
                const id = generateGUID()
                addPdf({
                    id,
                    fileName,
                    pdfFileBase64,
                    pagesCount: pdfReport.pagesCount,
                    isValid: true
                })
                setFileError('')
            } else {
                setImportError('DOCUMENTS.PDF_LOAD_ERROR_TOO_MANY_PAGES')
            }
        } else {
            getErrorMessage(pdfReport.error)
        }
    }

    const pdfAnalyze = async (pdfFileBase64: string): Promise<PdfAnalyzerReport> => {
        return await pdfAnalyzer(pdfFileBase64)
    }

    return (
        <div className="enveloppes-submission-dropzone-container bg-white bc-gray100" id="enveloppes-submission-dropzone">
            <div className="labelMedium m-bottom-32 white-space-pre-line">
                {t('ENVELOPPES.SUBMISSION_DROPZONE_INFORMATION')}
            </div>
            <AssuraDropzone
                testId="enveloppes-submission"
                accept={{
                    ...DROPZONE_ACCEPTED_PDF
                }}
                multiple={false}
                maxSize={10000000} //10MB
                handleRejectedFile={handleRejectedFile}
                handleAcceptedFile={handleAcceptedFile}
                importFileStatus={importFileStatus}
                changeImportFileStatus={(status) => setImportFileStatus(status)}
                loadingText={t('ENVELOPPES.IMPORT_LOADING')}
                errorText={t(fileError)}
                acceptedFileText={t('ENVELOPPES.IMPORT_FILE_HELPER')}
                mandatoryErrorText={fileError}
                successComponent={null}
            />
            <ErrorMessage
                errors={errors}
                name="documents"
                render={({ message }) => (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid="enveloppes-submission-dropped-files-error"
                    >
                        {t(message)}
                    </div>
                )}
            />
        </div>
    )
}

export default EnveloppesSubmissionDropzone
