/* eslint-disable sonarjs/cognitive-complexity */
import './AssuraTableHeader.css'

import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import analyticsConstants from 'core/constants/analyticsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    FilterSelectionValue,
    TableFilterId,
    TableFilterSelectionData,
    TableFilterSelectionId,
    TableFilterSelections,
    TableFilterState
} from 'core/models/TableFilter'
import concatClassNames from 'core/utils/classNameUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

import AssuraTableFilter from './AssuraTableFilter'

export interface AssuraTableHeaderRef {
    globalResetAction: () => void
    hasActivatedFilter: boolean
}

interface AssuraTableHeaderProps<T extends FilterSelectionValue> {
    context: string
    filters: TableFilterState<T>
    onFilterChange: (state: TableFilterState<T>) => void
    filteringStatus: LoadingStatusEnum
}

const AssuraTableHeader = forwardRef(
    <T extends FilterSelectionValue>(
        { context, filters, onFilterChange, filteringStatus }: AssuraTableHeaderProps<T>,
        ref: Ref<AssuraTableHeaderRef>
    ): JSX.Element => {
        const { t } = useTranslation()

        const hasActivatedFilter = Object.values(filters ?? {})
            .reduce<TableFilterSelectionData<T>[]>((acc, filter) => {
                filter.selections.forEach((selectionData) => acc.push(selectionData))
                return acc
            }, [])
            .some((selection) => selection.isSelected)

        const filterIds = Object.keys(filters ?? {})

        const filtersCountClass = filterIds.length > 2 ? 'three-filters' : 'two-filters'
        const filtersClass = concatClassNames(['assura-table-header-filters', filtersCountClass])

        const updateFilterSelections = (
            currentSelections: TableFilterSelections<T>,
            selectionId: TableFilterSelectionId | null
        ): TableFilterSelections<T> => {
            const newStateSelections = new Map() as TableFilterSelections<T>
            for (const [id, selectionData] of currentSelections) {
                newStateSelections.set(id, {
                    ...selectionData,
                    isSelected: id === selectionId
                })
            }

            return newStateSelections
        }

        const handleOnFilterSelection = (
            filterId: TableFilterId,
            selectionId: TableFilterSelectionId | null
        ) => {
            const currentFilterState = { ...filters }
            const currentFilter = currentFilterState[filterId]
            if (onFilterChange && currentFilter) {
                const newStateSelections = updateFilterSelections(
                    currentFilter.selections,
                    selectionId
                )
                currentFilterState[filterId].selections = newStateSelections
                onFilterChange(currentFilterState)
            }

            //Analytics
            if (selectionId)
                sendEvent(analyticsConstants.EVENTS.FILTER, {
                    [analyticsConstants.PARAMS.FILTER_CONTEXT]: context,
                    [analyticsConstants.PARAMS.FILTER_TYPE]: filterId,
                    [analyticsConstants.PARAMS.FILTER_VALUE]: selectionId
                })
        }

        const globalResetAction = () => {
            const newState = filterIds.reduce<TableFilterState<T>>((acc, filterId) => {
                const currentFilter = filters[filterId]
                if (currentFilter) {
                    acc[filterId] = {
                        ...currentFilter,
                        selections: updateFilterSelections(currentFilter.selections, null)
                    }
                }
                return acc
            }, {})
            if (Object.keys(newState).length === filterIds.length) onFilterChange(newState)
        }

        useImperativeHandle(ref, () => ({ globalResetAction, hasActivatedFilter }))

        return (
            <>
                <tr className={filtersClass} data-testid="assura-table-header">
                    {Object.entries(filters).map(([filterId, filter]) => (
                        <AssuraTableFilter
                            id={filterId}
                            filter={filter}
                            key={`table-filter-${filterId}`}
                            onFilterSelection={handleOnFilterSelection}
                            isDisabled={filteringStatus === LoadingStatusEnum.LOADING}
                        />
                    ))}
                </tr>
                {hasActivatedFilter && (
                    <tr className="m-top-24">
                        <td>
                            <AssuraIconButton
                                label={t('FILTERS.EMPTY_VIEW_RESET')}
                                id="assura-table-filters-reset"
                                icon="assura-refresh"
                                variant="primary"
                                size="small"
                                onClick={globalResetAction}
                            />
                        </td>
                    </tr>
                )}
            </>
        )
    }
)

export default AssuraTableHeader as <T extends FilterSelectionValue>(
    props: AssuraTableHeaderProps<T> & { ref: Ref<AssuraTableHeaderRef> }
) => JSX.Element
