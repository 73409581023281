import { createContext, useContext, useEffect, useState } from 'react'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendDocPageView } from 'core/helpers/AnalyticsHelper'
import { emptyFunction } from 'core/helpers/functionHelper'
import { SideNavigationContext, SideNavigationProviderProps } from 'core/models/SideNavigation'
import useWindowSize from 'core/services/useWindowSize'

import AssuraLoadAndError from '../AssuraLoadAndError/AssuraLoadAndError'

const Context = createContext<SideNavigationContext>({
    selectedId: undefined,
    isMobileContentSelection: false,
    containerPadding: 0,
    menuWidth: 0,
    defaultUrl: '',
    handleSelection: emptyFunction,
    resetSelectedId: emptyFunction,
    selectDefaultId: emptyFunction
})

const SideNavigationProvider = ({
    children,
    defaultSelectedId,
    dataLoadingStatus,
    defaultUrl
}: SideNavigationProviderProps): JSX.Element => {
    const menuWidth = 400
    const { isMobile, width, maxContainerWidth, isXSD, isSD } = useWindowSize()

    const [selectedId, setSelectedId] = useState<string | undefined>(
        isMobile ? undefined : defaultSelectedId
    )
    const [isMobileContentSelection, setIsMobileContentSelection] = useState(false)
    const [containerPadding, setContainerPadding] = useState(0)

    const sideNavigationLoadingStatus =
        dataLoadingStatus === LoadingStatusEnum.ERROR ? LoadingStatusEnum.OK : dataLoadingStatus

    const handleSelection = (id: string | undefined) => {
        setSelectedId(id)
    }

    const resetSelectedId = () => {
        setSelectedId(undefined)
    }

    const selectDefaultId = () => {
        setSelectedId(defaultSelectedId)
    }

    const contextValue = {
        selectedId,
        isMobileContentSelection,
        containerPadding,
        menuWidth,
        defaultUrl,
        handleSelection,
        resetSelectedId,
        selectDefaultId
    }

    useEffect(() => {
        if (!isMobile && selectedId === undefined) {
            setSelectedId(defaultSelectedId)
        }

        setContainerPadding(isXSD || isSD ? 0 : (width - maxContainerWidth) / 2)
    }, [width])

    useEffect(() => {
        setIsMobileContentSelection(isMobile && selectedId !== undefined)
    }, [isMobile, selectedId])

    useEffect(() => {
        if (selectedId !== undefined) {
            sendDocPageView(selectedId)
        }
    }, [selectedId])

    return (
        <AssuraLoadAndError status={sideNavigationLoadingStatus}>
            <Context.Provider value={contextValue}>{children}</Context.Provider>
        </AssuraLoadAndError>
    )
}

const useSideNavigationContext = (): SideNavigationContext => {
    return useContext(Context)
}

export { SideNavigationProvider, useSideNavigationContext }
