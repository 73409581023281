import { createSelector } from '@reduxjs/toolkit'

import { getHeadOfFamily } from '../general/general.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export const isConnectedUserTheHeadOfFamily = createSelector([getConnectedFamilyMember, getHeadOfFamily], (connectedPerson, headOfFamily):
    | boolean
    | undefined => {
    if (connectedPerson && headOfFamily) {
        return connectedPerson.policyNumber === headOfFamily.policyNumber
    } else {
        return undefined
    }
})
