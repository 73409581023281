import { AssetsCmsDocument } from 'core/models/cms/AssetCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_ASSETS: AssetsCmsDocument[] = []

export const assetsSlice = createSlice({
    name: 'assets',
    initialState: INITIAL_STATE_ASSETS,
    reducers: {
        fetchAssets: (state: AssetsCmsDocument[]) => {
            return state
        },
        setAssets: (state: AssetsCmsDocument[], action: PayloadAction<AssetsCmsDocument[]>) =>
            (state = action.payload)
    }
})

export const { fetchAssets, setAssets } = assetsSlice.actions

export const getCmsAssets: Selector<RootState, AssetsCmsDocument[]> = (state) => state.assets

export default assetsSlice.reducer
