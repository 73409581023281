import './StartDoctorSearch.css'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import doctorChoiceIcon from 'shared/assets/images/doctor_choice.png'

const StartDoctorSearch = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="m-top-48 p-top-56 p-bottom-56 d-flex flex-column align-items-center">
            <img src={doctorChoiceIcon} className="image-size-56" alt="doctor-icon" />
            <div className="start-doctor-search-text m-top-24 paragraphSmall">
                {t('SERVICES.SEARCH_DOCTOR')}
            </div>
        </div>
    )
}

export default StartDoctorSearch
