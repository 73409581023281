import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    InvoiceChecking,
    InvoiceCheckingAnswersWrapper,
    InvoiceCheckingDetails
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface InvoicesToCheckState {
    invoicesToCheck: InvoiceChecking[]
    invoicesToCheckDetails: InvoiceCheckingDetails | null
    invoicesToCheckLoadingStatus: LoadingStatusEnum
    invoicesToCheckDetailsLoadingStatus: LoadingStatusEnum
    invoicesToCheckFilteringStatus: LoadingStatusEnum
}

const INITIAL_STATE_INVOICE_TO_CHECK: InvoicesToCheckState = {
    invoicesToCheck: [],
    invoicesToCheckDetails: null,
    invoicesToCheckLoadingStatus: LoadingStatusEnum.OK,
    invoicesToCheckDetailsLoadingStatus: LoadingStatusEnum.OK,
    invoicesToCheckFilteringStatus: LoadingStatusEnum.OK
}

export const invoicesToCheckSlice = createSlice({
    name: 'invoicesToCheck',
    initialState: INITIAL_STATE_INVOICE_TO_CHECK,
    reducers: {
        setInvoicesToCheckLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.invoicesToCheckLoadingStatus = action.payload
            return state
        },
        setInvoicesToCheckDetailsLoadingStatus: (
            state,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.invoicesToCheckDetailsLoadingStatus = action.payload
            return state
        },
        setInvoicesToCheckFilteringStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.invoicesToCheckFilteringStatus = action.payload
            return state
        },
        fetchInvoicesToCheck: (state) => {
            return state
        },
        fetchInvoicesToCheckDetails: (state, _action: PayloadAction<number>) => {
            return state
        },
        setInvoicesToCheck: (state, action: PayloadAction<InvoiceChecking[]>) => {
            state.invoicesToCheck = action.payload
            return state
        },
        setInvoicesToCheckDetails: (
            state,
            action: PayloadAction<InvoiceCheckingDetails | null>
        ) => {
            state.invoicesToCheckDetails = action.payload
            return state
        },
        submitInvoiceToCheckAnswers: (
            state,
            _action: PayloadAction<InvoiceCheckingAnswersWrapper>
        ) => {
            return state
        },
        refuseToAnswer: (state, _action: PayloadAction<string>) => {
            return state
        }
    }
})

export const {
    setInvoicesToCheckLoadingStatus,
    setInvoicesToCheck,
    fetchInvoicesToCheck,
    submitInvoiceToCheckAnswers,
    fetchInvoicesToCheckDetails,
    setInvoicesToCheckDetails,
    setInvoicesToCheckDetailsLoadingStatus,
    refuseToAnswer,
    setInvoicesToCheckFilteringStatus
} = invoicesToCheckSlice.actions

export const getInvoicesToCheckLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.invoicesToCheck.invoicesToCheckLoadingStatus

export const getInvoicesToCheckDetailsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state
) => state.invoicesToCheck.invoicesToCheckDetailsLoadingStatus

export const getInvoicesToCheckFilteringStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.invoicesToCheck.invoicesToCheckFilteringStatus

export const getInvoicesToCheckCount: Selector<RootState, number> = (state) =>
    state.invoicesToCheck.invoicesToCheck.filter(
        (o) => o.status === InvoiceCheckingStatusEnum.TO_CHECK
    ).length

export const getInvoicesToCheck: Selector<RootState, InvoiceChecking[]> = (state) =>
    state.invoicesToCheck.invoicesToCheck

export const getInvoicesToCheckDetails: Selector<RootState, InvoiceCheckingDetails | null> = (
    state
) => state.invoicesToCheck.invoicesToCheckDetails

export default invoicesToCheckSlice.reducer
