import './EnveloppesPdfViewerSubHeader.css'

import { isSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { openBase64InNewTab } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

import { RenderDownloadProps } from '@react-pdf-viewer/get-file'
import { RenderCurrentPageLabelProps } from '@react-pdf-viewer/page-navigation'
import { RenderPrintProps } from '@react-pdf-viewer/print'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom'

interface EnveloppesPdfViewerSubHeaderProps {
    totalDocuments: number
    selectedPdfIndex: number
    setSelectedPdfIndex: (index: number) => void
    toolbarPluginInstance: ToolbarPlugin
    loadingStatusIsOk: boolean
    isDownloadable: boolean
    document: AssuraDocument | undefined
}

const EnveloppesPdfViewerSubHeader = ({
    totalDocuments,
    selectedPdfIndex,
    setSelectedPdfIndex,
    toolbarPluginInstance,
    loadingStatusIsOk,
    isDownloadable,
    document
}: EnveloppesPdfViewerSubHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile, isXL } = useWindowSize()

    const { CurrentPageLabel } = toolbarPluginInstance.pageNavigationPluginInstance

    const { Download } = toolbarPluginInstance.getFilePluginInstance
    const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance
    const { Print } = toolbarPluginInstance.printPluginInstance

    const nextDocument = () => {
        if (selectedPdfIndex < totalDocuments - 1) setSelectedPdfIndex(selectedPdfIndex + 1)
    }

    const previousDocument = () => {
        if (selectedPdfIndex !== 0) setSelectedPdfIndex(selectedPdfIndex - 1)
    }

    // INFO: The Print plugin doesn't work on Safari
    const onSafariPrint = () => {
        if (document) openBase64InNewTab(document.base64)
    }

    return (
        <div className="enveloppes-pdf-viewer-subheader-container">
            {totalDocuments > 1 && (
                <div
                    className={`enveloppes-panel-subheader-arrow ${
                        selectedPdfIndex === 0 ? 'bg-gray100 c-gray300' : 'bg-primary c-white'
                    }`}
                    onClick={() => previousDocument()}
                    data-testid="enveloppes-panel-subheader-arrow-previous"
                >
                    <i className="icon assura-left size-32" />
                </div>
            )}
            <div className="enveloppes-pdf-viewer-subheader-center-container">
                <div className="flex-1 d-flex justify-content-start align-items-center">
                    {totalDocuments > 1 && (
                        <span className="labelSmall">
                            {t('ENVELOPPES.PANEL_DOCUMENTS_COUNT', {
                                currentDocument: selectedPdfIndex + 1,
                                totalDocuments
                            })}
                        </span>
                    )}
                </div>
                {!(isMobile && totalDocuments > 1) && loadingStatusIsOk && (
                    <div className="labelSmall flex-1 d-flex align-items-center justify-content-center align-items-center">
                        <CurrentPageLabel>
                            {(props: RenderCurrentPageLabelProps) =>
                                props.numberOfPages > 1 ? (
                                    <>
                                        <div className="m-right-12">{t('COMMON.PAGE')}</div>
                                        <span>{`${props.currentPage + 1} / ${
                                            props.numberOfPages
                                        }`}</span>
                                    </>
                                ) : (
                                    <div />
                                )
                            }
                        </CurrentPageLabel>
                    </div>
                )}
                <div className="flex-1 d-flex justify-content-end align-items-center">
                    {loadingStatusIsOk && (
                        <>
                            {isXL && (
                                <>
                                    <ZoomOut>
                                        {(props: RenderZoomOutProps) => (
                                            <AssuraIconButton
                                                id="enveloppes-panel-header-zoom-out"
                                                icon="assura-zoom-out"
                                                variant="default"
                                                size="medium"
                                                onClick={props.onClick}
                                            />
                                        )}
                                    </ZoomOut>
                                    <ZoomIn>
                                        {(props: RenderZoomInProps) => (
                                            <AssuraIconButton
                                                id="enveloppes-panel-header-zoom-in"
                                                icon="assura-zoom"
                                                variant="default"
                                                size="medium"
                                                onClick={props.onClick}
                                                classNames="m-left-16"
                                            />
                                        )}
                                    </ZoomIn>
                                </>
                            )}
                            {isDownloadable && (
                                <Download>
                                    {(props: RenderDownloadProps) => (
                                        <AssuraIconButton
                                            id="enveloppes-panel-header-download"
                                            icon="assura-download"
                                            variant="primary"
                                            size="medium"
                                            onClick={props.onClick}
                                            classNames="m-left-16"
                                        />
                                    )}
                                </Download>
                            )}
                            {!isMobile && (
                                <Print>
                                    {(props: RenderPrintProps) => (
                                        <AssuraIconButton
                                            id="communications-panel-header-print"
                                            icon="assura-print"
                                            variant="primary"
                                            size="medium"
                                            onClick={isSafari ? onSafariPrint : props.onClick}
                                            classNames="m-left-16"
                                        />
                                    )}
                                </Print>
                            )}
                        </>
                    )}
                </div>
            </div>
            {totalDocuments > 1 && (
                <div
                    className={`enveloppes-panel-subheader-arrow ${
                        selectedPdfIndex === totalDocuments - 1
                            ? 'bg-gray100 c-gray300'
                            : 'bg-primary c-white'
                    }`}
                    onClick={() => nextDocument()}
                    data-testid="enveloppes-panel-subheader-arrow-next"
                >
                    <i className="icon assura-right size-32" />
                </div>
            )}
        </div>
    )
}

export default EnveloppesPdfViewerSubHeader
