import { useDispatch, useSelector } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import {
    getGoToServicesFormSagaExecuted,
    goToServicesForm
} from 'shared/store/services/services.slice'

// When the user forces a form page refresh, reexecute the goToServicesFormSaga
export const useFormRefresh = (type: ServiceType): void => {
    const dispatch = useDispatch()
    const goToServicesFormSagaExecuted = useSelector(getGoToServicesFormSagaExecuted)
    if (!goToServicesFormSagaExecuted) {
        dispatch(goToServicesForm({ type }))
    }
}
