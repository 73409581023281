import { ElementType } from 'react'
import { useSelector } from 'react-redux'

import { AssuraLinkEnum } from 'core/enums/AssuraLinkEnum'
import { getEcLinksLocalized } from 'shared/store/selectors/getEcLinksLocalized'

import AssuraLink, { AssuraLinkProps } from '../AssuraLink/AssuraLink'

export type AssuraEcLinkProps = AssuraLinkProps<ElementType> & {
    ecLinkId: string
}

const AssuraEcLink = ({ ecLinkId, ...props }: AssuraEcLinkProps): JSX.Element | null => {
    const ecLinks = useSelector(getEcLinksLocalized)

    const ecLink = ecLinks.find((item) => item._id === ecLinkId)

    const currentType =
        ecLink?._id === '646391c0ec5ba3566e00d4e5' ? AssuraLinkEnum.CALCULATOR : ecLink?.type

    return <AssuraLink {...props} type={currentType} link_url={ecLink?.link_url} />
}

export default AssuraEcLink
