import './SettingsCredentialsEditPanelHeader.css'

import { useTranslation } from 'react-i18next'

import TranslationKeys from 'core/constants/translationKeys'
import { CredentialEditMode } from 'core/enums/CredentialEditMode'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

type SettingsCredentialsPanelHeaderProps = {
    mode: CredentialEditMode
    closePanel: () => void
}

const SettingsCredentialsPanelHeader = ({ ...props }: SettingsCredentialsPanelHeaderProps) => {
    const { t } = useTranslation()

    const CentralBlock = (): JSX.Element => (
        <div className={'settings-credentials-panel-header'}>
            <label
                data-testid={'setting-header-label'}
                className={'labelMedium assura-panel-center-block-ellipsis'}
            >
                {props.mode === CredentialEditMode.Login
                    ? t(TranslationKeys.Credentials.login.edit.label)
                    : t(TranslationKeys.Credentials.password.edit.label)}
            </label>
        </div>
    )

    return (
        <AssuraPanelHeader
            sideBlockWidthXs={10}
            sideBlockWidthAboveXs={60}
            forceLeftSideHidden={false}
            forceSmallSideBlocks={true}
            centerBlock={<CentralBlock />}
            closePanel={props.closePanel}
        />
    )
}

export default SettingsCredentialsPanelHeader
