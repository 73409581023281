import { Doctor, DoctorPersonalData } from '../../models/familyGroup/Doctor'
import { SearchDoctor } from '../../models/services/contract/SearchDoctor'
import { extractDoctorPhone } from '../DoctorsPhoneHelper'

export const mapSearchDoctorToDoctorPersonalData = (
    searchDoctor: SearchDoctor
): DoctorPersonalData => {
    return {
        firstName: searchDoctor.additionalName1 ?? '',
        lastName: searchDoctor.name ?? '',
        street: searchDoctor.address ?? '',
        zipCode: searchDoctor.zipCode ?? 0,
        locality: searchDoctor.city ?? '',
        phoneNumber: searchDoctor.phoneNumber ?? ''
    }
}

export const mapDoctorToPersonalData = (doctor: Doctor): DoctorPersonalData => {
    return {
        firstName: doctor.firstName,
        lastName: doctor.lastName,
        street: doctor.street,
        zipCode: doctor.zipCode,
        locality: doctor.locality,
        phoneNumber: extractDoctorPhone(doctor.phones)
    }
}
