import { EnveloppeImage } from 'core/models/EnveloppeImage'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_ENVELOPPEIMAGES: Record<string, EnveloppeImage> = {}

export interface DataFetchEnveloppeImageByName {
    reload?: boolean
    fileName: string
    type: string
}

export interface DataFetchEnveloppeImageByNameSuccess {
    fileName: string
    enveloppeImage: EnveloppeImage
}

export const imagesSlice = createSlice({
    name: 'enveloppeImages',
    initialState: INITIAL_STATE_ENVELOPPEIMAGES,
    reducers: {
        fetchEnveloppeImageByName: (
            state,
            _action: PayloadAction<DataFetchEnveloppeImageByName>
        ) => {
            return state
        },
        updateEnveloppeImageByName: (
            state,
            action: PayloadAction<DataFetchEnveloppeImageByNameSuccess>
        ) => {
            state[action.payload.fileName] = {
                type: action.payload.enveloppeImage.type,
                base64: action.payload.enveloppeImage.base64,
                loadStatus: action.payload.enveloppeImage.loadStatus
            }
        }
    }
})

export const { fetchEnveloppeImageByName, updateEnveloppeImageByName } = imagesSlice.actions

export const getEnveloppeImagesState: Selector<RootState, Record<string, EnveloppeImage>> = (
    state
) => state.enveloppeImages

export default imagesSlice.reducer
