import './SettingsAddCard.css'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import { ReactComponent as AddImg } from 'shared/assets/images/add.svg'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { goToServicesForm } from 'shared/store/services/services.slice'

const SettingsAddCard = (): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const avatarSize = isXL ? 152 : 96
    return (
        <div
            className="settings-add-card-container bc-gray100"
            onClick={() =>
                dispatch(
                    goToServicesForm({
                        type: ServiceType.FAMILY_GROUP_CHANGE
                    })
                )
            }
        >
            <AssuraAvatar
                id="settings-add-card-avatar"
                mainContainerWidth={avatarSize}
                avatarContainerWidth={avatarSize}
                border={{
                    width: 1,
                    color: 'gray300'
                }}
            >
                <AddImg />
            </AssuraAvatar>
            <AssuraIconButton
                id="settings-add-card-button"
                icon="assura-plus"
                variant="primary"
                size="medium"
                label={t('PERSONAL_DATA.ADD_MEMBER')}
                classNames="settings-add-card-button btc-gray100"
            />
        </div>
    )
}

export default SettingsAddCard
