import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { fetchEnveloppeImage } from 'core/api/Images'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { EnveloppeImage } from 'core/models/EnveloppeImage'

import {
    fetchEnveloppeImageByName,
    getEnveloppeImagesState,
    updateEnveloppeImageByName
} from './enveloppeImages.slice'

export function* fetchEnveloppeImageByNameAsync(
    action: ReturnType<typeof fetchEnveloppeImageByName>
) {
    try {
        const enveloppeImages: Record<string, EnveloppeImage> = yield select(
            getEnveloppeImagesState
        )

        if (
            !action.payload.reload &&
            enveloppeImages[action.payload.fileName] &&
            enveloppeImages[action.payload.fileName].loadStatus === LoadingStatusEnum.OK
        ) {
            return
        }

        yield put(
            updateEnveloppeImageByName({
                fileName: action.payload.fileName,
                enveloppeImage: {
                    type: action.payload.type,
                    base64: '',
                    loadStatus: LoadingStatusEnum.LOADING
                }
            })
        )

        const fetchImageResult: EnveloppeImage = yield call(
            fetchEnveloppeImage,
            action.payload.fileName,
            action.payload.type
        )

        yield put(
            updateEnveloppeImageByName({
                fileName: action.payload.fileName,
                enveloppeImage: fetchImageResult
            })
        )
    } catch (e) {
        console.error('fetchImageByNameAsync Error', e)
        yield put(
            updateEnveloppeImageByName({
                fileName: action.payload.fileName,
                enveloppeImage: {
                    type: action.payload.type,
                    base64: '',
                    loadStatus: LoadingStatusEnum.ERROR
                }
            })
        )
    }
}

function* fetchEnveloppeImageByNameWatcher() {
    yield takeEvery(fetchEnveloppeImageByName.type, fetchEnveloppeImageByNameAsync)
}

const watchers = [fork(fetchEnveloppeImageByNameWatcher)]

export default watchers
