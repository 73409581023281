import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DocumentsGroupEnum, FetchDocumentsTypeEnum } from 'core/enums/Documents'
import { mapDocumentsSelectionsToState } from 'core/helpers/CommonAssuraTableFilters'
import getDocumentsFilterState from 'core/helpers/documents/DocumentsFilterState'
import { DocumentsFilterSelectionValue, FetchDocumentsType } from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { TableFilterState } from 'core/models/TableFilter'
import useQuery from 'core/services/useQuery'
import AssuraTable from 'shared/components/AssuraTable/AssuraTable'
import { PanelDatasProvider } from 'shared/contexts/PanelDatasContext'
import {
    fetchInvoicesData,
    getFinancialDocumentsFilteringLoadingStatus,
    getFinancialDocumentsPaginatingLoadingStatus,
    getInvoicesData,
    setSelectedFinancialDocumentId
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getSortedInvoicesForTable } from 'shared/store/selectors/getSortedInvoicesForTable'
import { useAppDispatch } from 'shared/store/store'

import ClaimsSettlementPanel from '../ClaimsSettlementPanel/ClaimsSettlementPanel'
import FinancialsInvoicesPanel from '../FinancialsInvoicesPanel/FinancialsInvoicesPanel'
import InvoicesTableRow from './InvoicesTableRow'

const FinancialsInvoices = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const query = useQuery()
    const externalDefaultUtn = query.get('utn')

    const lang = useSelector(getLang)

    const filteringLoadingStatus = useSelector(getFinancialDocumentsFilteringLoadingStatus)
    const paginatingLoadingStatus = useSelector(getFinancialDocumentsPaginatingLoadingStatus)

    const invoicesData = useSelector(getInvoicesData)

    const { hasMore, initLoadingStatus, paginationStartDate } = invoicesData

    const initialFilters = getDocumentsFilterState(
        externalDefaultUtn ? Number(externalDefaultUtn) : undefined
    )

    const sortedInvoices = useSelector(getSortedInvoicesForTable)

    const [filters, setFilters] = useState<TableFilterState<DocumentsFilterSelectionValue>>({
        ...initialFilters
    })

    const isInitialisation = useRef(true)

    const onFilterSelection = (newFilterState: TableFilterState<DocumentsFilterSelectionValue>) => {
        setFilters({ ...newFilterState })
    }

    const onRowSelection = (row: DocumentRow) => {
        dispatch(setSelectedFinancialDocumentId({ documentId: row.documentId }))
    }

    const fetchInvoices = (type: FetchDocumentsType) => {
        const filterState = mapDocumentsSelectionsToState(filters)
        dispatch(
            fetchInvoicesData({
                type,
                paginationDate: paginationStartDate,
                filters: filterState
            })
        )
    }

    useEffect(() => {
        fetchInvoices(
            isInitialisation.current
                ? FetchDocumentsTypeEnum.INIT
                : FetchDocumentsTypeEnum.FILTERING
        )
        isInitialisation.current = false
    }, [filters, lang])

    return (
        <>
            <div className="side-navigation-content-table">
                <AssuraTable
                    id={DocumentsGroupEnum.INVOICES}
                    rows={sortedInvoices}
                    rowComponent={(row) => <InvoicesTableRow row={row} />}
                    filters={filters}
                    loadStatus={initLoadingStatus}
                    paginationStatus={paginatingLoadingStatus}
                    filteringStatus={filteringLoadingStatus}
                    onReload={() => fetchInvoices(FetchDocumentsTypeEnum.INIT)}
                    onFilterChange={onFilterSelection}
                    onRowSelection={onRowSelection}
                    noResultMessages={{
                        datas: { label: t('INBOX.EMPTY_VIEW_TITLE') },
                        filters: { label: t('INBOX.EMPTY_VIEW_FILTERS_TITLE') }
                    }}
                    hasMore={hasMore}
                    onMoreSelection={() => fetchInvoices(FetchDocumentsTypeEnum.PAGINATING)}
                />
            </div>
            <PanelDatasProvider>
                <FinancialsInvoicesPanel />
                <ClaimsSettlementPanel />
            </PanelDatasProvider>
        </>
    )
}

export default FinancialsInvoices
