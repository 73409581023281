import { call, delay, fork, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects'

import axios from 'axios'
import {
    contractDoctorsData,
    searchDoctorsData,
    submitFamilyDoctorForm
} from 'core/api/services/Doctors'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { FamilyDoctorRequestBody } from 'core/models/services/contract/FamilyDoctor'

import { fetchProductsByMemberAndByYearAsync } from '../../familySummaries/familySummaries.saga'
import { getConnectedFamilyMember } from '../../selectors/getConnectedFamilyMember'
import { getSummaryCurrentYear } from '../../selectors/getSummaryCurrentYear'
import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import {
    DataFetchContractDoctors,
    DataSearchDoctorsSuccess,
    fetchContractDoctorsAndProducts,
    fetchContractDoctorsSuccess,
    fetchSearchDoctors,
    fetchSearchDoctorsSuccess,
    getDoctorSearchName,
    getFamilyDoctors,
    getSelectedCanton,
    onSubmitFamilyDoctor,
    setContractDoctorsAndProductsLoadingStatus,
    setContractDoctorsLoadingStatus,
    setSearchDoctorsLoadingStatus
} from './doctors.slice'

export function* initContractDoctors() {
    try {
        const connectedFamilyMember: ConnectedFamilyMember = yield select(getConnectedFamilyMember)

        yield call(fetchContractDoctors, { policyNumber: connectedFamilyMember?.policyNumber })
    } catch (e) {
        console.error('initContractDoctors Error', e)
        throw e
    }
}

function* fetchContractDoctorsAndProductsSaga(
    action: ReturnType<typeof fetchContractDoctorsAndProducts>
) {
    try {
        const { policyNumber, year, reload } = action.payload
        const summaryCurrentYear: SummaryResult = yield select(getSummaryCurrentYear)
        const memberList = summaryCurrentYear.insuredPersons
        const policyNumberIndex = memberList.findIndex(
            (person) => person.policyNumber === policyNumber
        )
        yield put(setContractDoctorsAndProductsLoadingStatus(LoadingStatusEnum.LOADING))
        yield call(fetchContractDoctors, { policyNumber, reload })
        yield call<typeof fetchProductsByMemberAndByYearAsync>(
            fetchProductsByMemberAndByYearAsync,
            {
                type: 'familySummaries/fetchProductsByMemberAndByYear',
                payload: {
                    year,
                    index: policyNumberIndex,
                    reload,
                    shouldThrow: true
                }
            }
        )
        yield put(setContractDoctorsAndProductsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchContractDoctorsAndProductsSaga Error', e)
        yield put(setContractDoctorsAndProductsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchContractDoctors(params: DataFetchContractDoctors) {
    try {
        const { policyNumber, reload } = params
        const familyDoctors: Record<number, Doctor[]> = yield select(getFamilyDoctors)
        if (!familyDoctors[policyNumber] && !reload) {
            yield put(setContractDoctorsLoadingStatus(LoadingStatusEnum.LOADING))
            const contractDoctors: Doctor[] = yield call(contractDoctorsData, policyNumber)
            yield put(fetchContractDoctorsSuccess({ policyNumber, contractDoctors }))
            yield put(setContractDoctorsLoadingStatus(LoadingStatusEnum.OK))
        }
    } catch (e) {
        console.error('fetchContractDoctors Error', e)
        yield put(setContractDoctorsLoadingStatus(LoadingStatusEnum.ERROR))
        throw e
    }
}

function* fetchSearchDoctorsSaga(action: ReturnType<typeof fetchSearchDoctors>) {
    try {
        yield put(setSearchDoctorsLoadingStatus(LoadingStatusEnum.LOADING))
        yield delay(500)
        const doctorSearchName: string = yield select(getDoctorSearchName)
        const selectedCanton: string = yield select(getSelectedCanton)
        const { code } = action.payload

        if (doctorSearchName?.length > 2 && selectedCanton) {
            const searchDoctorsDataResults: DataSearchDoctorsSuccess = yield call(
                searchDoctorsData,
                doctorSearchName,
                code,
                selectedCanton
            )
            yield put(fetchSearchDoctorsSuccess(searchDoctorsDataResults))
        }
        yield put(setSearchDoctorsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchSearchDoctorsSaga Error', e)
        if (axios.isAxiosError(e) && e.response?.status === 404) {
            yield put(fetchSearchDoctorsSuccess({ hasMoreResults: false, results: [] }))
            yield put(setSearchDoctorsLoadingStatus(LoadingStatusEnum.OK))
        } else {
            yield put(setSearchDoctorsLoadingStatus(LoadingStatusEnum.ERROR))
        }
    }
}

function* onSubmitFamilyDoctorSaga(action: ReturnType<typeof onSubmitFamilyDoctor>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.FAMILY_DOCTOR
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const familyDoctorForm = action.payload
        const formToSubmit: FamilyDoctorRequestBody = {
            policyNumber: familyDoctorForm.selectedPolicyNumber ?? 0,
            validityDate: (familyDoctorForm.validityDate as Date).toISOString(),
            newDoctorId:
                familyDoctorForm.doctorType === ServicesDoctorType.PEDIATRICIAN
                    ? '9999189'
                    : (familyDoctorForm.newDoctorId as string)
        }
        yield call(submitFamilyDoctorForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitFamilyDoctorSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* fetchContractDoctorsAndProductsWatcher() {
    yield takeEvery(fetchContractDoctorsAndProducts.type, fetchContractDoctorsAndProductsSaga)
}

function* fetchSearchDoctorsWatcher() {
    yield takeLatest(fetchSearchDoctors.type, fetchSearchDoctorsSaga)
}

function* onSubmitFamilyDoctorWatcher() {
    yield takeLatest(onSubmitFamilyDoctor.type, onSubmitFamilyDoctorSaga)
}

const watchers = [
    fork(fetchContractDoctorsAndProductsWatcher),
    fork(fetchSearchDoctorsWatcher),
    fork(onSubmitFamilyDoctorWatcher)
]

export default watchers
