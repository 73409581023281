/* eslint-disable sonarjs/cognitive-complexity */
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'
import { InvoiceChecking } from 'core/models/invoicesToCheck/InvoicesToCheckModels'

import { formatDateToApiFormat } from '../DateFormatHelper'

export const mapInvoicesToCheckToRows = (
    invoicesToCheck: InvoiceChecking[],
    personNames: EnveloppePerson[]
): InvoiceChecking[] => {
    const itcRows: InvoiceChecking[] = []

    if (invoicesToCheck?.length > 0)
        invoicesToCheck.forEach((itcDetail: InvoiceChecking) => {
            const person = personNames?.find(
                (person) => person.policyNumber === itcDetail.partnerNumber
            )

            const insuredPersonInitials = person
                ? (person.personName.charAt(0) + person.personLastName.charAt(0)).toUpperCase()
                : ''

            const newDoc: InvoiceChecking = {
                ...itcDetail,
                insuredPersonInitials: insuredPersonInitials,
                creationDate: formatDateToApiFormat(itcDetail.creationDate),
                insuredPersonName: person?.personName ?? '',
                insuredPersonLastName: person?.personLastName ?? ''
            }
            itcRows.push(newDoc)
        })
    return itcRows
}
