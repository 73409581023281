import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'
import NoActiveContract from 'modules/services/components/NoActiveContract/NoActiveContract'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import {
    getModelDeductibleDates,
    MODEL_DEDUCTIBLE_UNSELECTED_DATE
} from 'shared/store/services/coverage/coverage.slice'

const ModelDeductibleBeginDateSelect = (): JSX.Element => {
    const { t } = useTranslation()
    const { setValue, register } = useFormContext()

    const modelDeductibleDates = useSelector(getModelDeductibleDates)

    useEffect(() => {
        setValue(
            'beginDate',
            modelDeductibleDates.length === 1
                ? formatDate(modelDeductibleDates[0])
                : MODEL_DEDUCTIBLE_UNSELECTED_DATE
        )
    }, [modelDeductibleDates])

    const getBeginDateMap = () => {
        const beginDateMap = new Map() as SelectMap<string>
        modelDeductibleDates.map((d) => beginDateMap.set(formatDate(d), formatDate(d)))
        return beginDateMap
    }

    const dateLabel = t('SERVICES.MODEL_DEDUCTIBLE_DATE_LABEL')

    if (modelDeductibleDates.length === 1) {
        return (
            <AssuraFormInputRHF
                name="beginDate"
                id="services-model-deductible-begin-date"
                label={dateLabel}
                inline
                labelSize={4}
                register={register}
                rules={{}}
                isInvalid={false}
                error={''}
                hasButton={false}
                onButtonClick={() => void 0}
                iconClass="icon assura-close size-24"
                readOnly={true}
            />
        )
    } else if (modelDeductibleDates.length > 1) {
        return (
            <AssuraFormSelectRHF
                name="beginDate"
                id="services-model-deductible-begin-date"
                label={dateLabel}
                items={getBeginDateMap()}
                placeHolder={t('SERVICES.MODEL_DEDUCTIBLE_DATE_SELECT')}
                inline
                labelSize={4}
            />
        )
    } else {
        return <NoActiveContract />
    }
}

export default ModelDeductibleBeginDateSelect
