import './ModalAssura.css'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import AssuraButton from 'shared/components/AssuraButton/AssuraButton'

interface ModalAssuraProps {
    id: string
    visible: boolean
    onHide: () => void
    image?: string
    title?: string
    description?: string
    buttonTitle?: string
    onButtonClicked?: () => void
    showCancelButton?: boolean
}

const ModalAssura = ({
    id,
    visible,
    onHide,
    image,
    title,
    description,
    buttonTitle,
    onButtonClicked,
    showCancelButton
}: ModalAssuraProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <>
            <Modal show={visible} onHide={() => onHide()}>
                <div className="modal-assura-container" data-testid={`${id}-modal-assura`}>
                    <div
                        className="modal-assura-close-icon"
                        onClick={() => onHide()}
                        data-testid={`${id}-modal-assura-close-icon-container`}
                    >
                        <i className="icon assura-close c-black size-32" />
                    </div>
                    {image && (
                        <img className="modal-assura-image" src={image} alt={'modal image'} />
                    )}
                    {title && <div className="headlineSmallMedium modal-assura-title">{title}</div>}
                    {description && (
                        <div className="paragraphSmallLight modal-assura-description">
                            {description}
                        </div>
                    )}
                    {buttonTitle && onButtonClicked && (
                        <div className="d-flex modal-assura-button-container">
                            {showCancelButton && (
                                <div className="m-right-12">
                                    <AssuraButton
                                        text={t('COMMON.CANCEL')}
                                        id={`${id}-modal-assura-cancel-button`}
                                        variant="secondary"
                                        onClick={onHide}
                                    />
                                </div>
                            )}
                            <AssuraButton
                                text={buttonTitle}
                                id={`${id}-modal-assura-button`}
                                variant="primary"
                                onClick={onButtonClicked}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default ModalAssura
