import './RequestsPanelMain.css'

import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'

interface RequestsPanelMainProps {
    title: string
    category?: string
    children: React.ReactNode
}

const RequestPanelContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(
    null
)

const RequestsPanelMain = ({ title, category, children }: RequestsPanelMainProps): JSX.Element => {
    const { t } = useTranslation()
    const [error, setError] = useState(false)

    return (
        <div className="requests-panel-main-container">
            {error && (
                <div className="requests-panel-main-banner-container">
                    <AssuraBanner
                        id="requests-details-contact-general-question"
                        message={t('COMMON.ERROR')}
                        onClose={() => setError(false)}
                        variant="alert"
                    />
                </div>
            )}
            <div className="requests-panel-main-subcontainer">
                {category && (
                    <div className="labelExtraSmall c-gray500 m-bottom-8">
                        {category.toUpperCase()}
                    </div>
                )}
                <div className="headlineSmallMedium">{title}</div>
            </div>
            <RequestPanelContext.Provider value={setError}>{children}</RequestPanelContext.Provider>
        </div>
    )
}

export const useRequestPanelContext = (): React.Dispatch<React.SetStateAction<boolean>> | null => {
    return useContext(RequestPanelContext)
}

export default RequestsPanelMain
