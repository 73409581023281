import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { fetchQrCodeApi } from 'core/api/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { QrCode } from 'core/models/documents/QrCode'

import { fetchQrCode, getQrCodes, setQrCode } from './qrCodes.slice'

export function* fetchQrCodeSaga(action: ReturnType<typeof fetchQrCode>) {
    const selectedId = action.payload.documentId

    try {
        const qrCodes: Record<string, QrCode> = yield select(getQrCodes)

        if (
            !action.payload.reload &&
            qrCodes[selectedId] &&
            qrCodes[selectedId].loadStatus === LoadingStatusEnum.OK
        ) {
            return
        }

        yield put(
            setQrCode({
                documentId: selectedId,
                loadStatus: LoadingStatusEnum.LOADING,
                data: null
            })
        )

        const qrCodeData: string = yield call(fetchQrCodeApi, selectedId)

        yield put(
            setQrCode({
                documentId: selectedId,
                loadStatus: LoadingStatusEnum.OK,
                data: qrCodeData
            })
        )
    } catch (e) {
        console.error('onfetchQrCodeSaga Error', e)
        yield put(
            setQrCode({
                documentId: selectedId,
                loadStatus: LoadingStatusEnum.ERROR,
                data: null
            })
        )
    }
}

function* fetchQrCodeWatcher() {
    yield takeEvery(fetchQrCode.type, fetchQrCodeSaga)
}

const watchers = [fork(fetchQrCodeWatcher)]

export default watchers
