import './CmsManagedSplashScreen.css'

import { Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import { SymptomCheckerEcLinkId } from 'core/constants/ecLinkIds'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import AssuraEcLinkButton from 'shared/components/AssuraEcLinkButton/AssuraEcLinkButton'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'
import { getSplashScreen } from 'shared/store/selectors/getSplashScreen'

const CmsManagedSplashScreen = () => {
    const splashScreen = useSelector(getSplashScreen)
    if (!splashScreen) return null

    const digcliParameters = useSelector(getDigcliparameters)
    if (
        splashScreen.cta_ec_link._id === SymptomCheckerEcLinkId &&
        digcliParameters?.isSymptomCheckerCustomerPortalActivated === 'false'
    )
        return null

    const onClickSendAnalytics = (link: string) => {
        // bypass if not symptom checkeritem
        if (!link.includes('Symptom')) return
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_CLICK, {
            [analyticsConstants.PARAMS.ENTRY_POINT]:
                entryPointsConstants.ENTRY_POINTS.SPLASH_SCREEN,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }

    return (
        <Container
            className={`cms-managed-splashscreen-frame d-flex align-items-center justify-content-center`}
        >
            <Row className={`os-ss-row d-flex`}>
                <div className={`os-ss-text-col d-flex`}>
                    <div className={`os-ss-grey-panel-frame d-flex`}>
                        <div className={`os-ss-text-frame d-flex`}>
                            <div className="os-ss-text-punchline headlineLarge d-flex align-items-center justify-content-center m-bottom-24">
                                {splashScreen.title}
                            </div>
                            <div className="os-ss-text-features paragraphLight d-flex align-items-center justify-content-center m-bottom-40">
                                {splashScreen.text}
                            </div>
                            <div className="os-ss-link d-flex justify-content-start">
                                <AssuraEcLinkButton
                                    ecLinkId={splashScreen.cta_ec_link._id}
                                    testId="splashscreen-ec-link"
                                    text={splashScreen.cta_text}
                                    variant="secondary"
                                    callback={onClickSendAnalytics}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`os-ss-image-col d-flex`}>
                    <div className={`os-ss-grey-panel-frame-overflow d-flex`} />
                    <div className={`os-ss-image-frame d-flex`}>
                        {splashScreen.image?.path && (
                            <div
                                className="os-ss-image d-flex"
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_CMS_ASSET}${splashScreen.image.path})`
                                }}
                            />
                        )}
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default CmsManagedSplashScreen
