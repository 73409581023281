export interface SearchPageItem {
    ecLinkId: string
    label: string
}
const searchPageItems: SearchPageItem[] = [
    { ecLinkId: '646391f2970ee2076806ee55', label: 'FAMILY_GROUP.COVERAGE_TITLE' },
    { ecLinkId: '6463920efbf14b3565055dc5', label: 'ENVELOPPES.SEND_ENVELOPPE' },
    { ecLinkId: '64cccb6b40761c36a0071682', label: 'FINANCIALS.NAVIGATION_ENVELOPPES' },
    { ecLinkId: '651549bd98bb17b8c709b0f8', label: 'HOME.INVOICES_LAST_TITLE' },
    { ecLinkId: '6515498298bb17b8c709b0f5', label: 'HOME.SETTLEMENTS_LAST_TITLE' },
    { ecLinkId: '651549e9c693428aa0023f83', label: 'INBOX.CATEGORY_FILTER_1' },
    { ecLinkId: '65154a028e2d7b160b041583', label: 'COMMUNICATIONS.NAVIGATION_CORRESPONDENCE' },
    { ecLinkId: '646392bf6057daba880692d2', label: 'SERVICES.TITLE' },
    { ecLinkId: '646cb39998914d1ae70f06b2', label: 'SERVICES.REQUESTS_TITLE'},
    { ecLinkId: '64639307fc453a5adc0a53c1', label: 'PERSONAL_DATA.TITLE' },
    { ecLinkId: '646393278d334edee608c015', label: 'SETTINGS.MAIN_TITLE' },
    { ecLinkId: '646393455afb28b49a040243', label: 'FAQ.TITLE' }
]

export default searchPageItems
