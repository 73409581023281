import './Home.css'

import { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { FetchDocumentsTypeEnum } from 'core/enums/Documents'
import { AssuraTabData } from 'core/models/TabData'
import { getCurrentYear } from 'core/utils/dateUtils'
import { getPersonTabFromPolicyNumber, peopleToTab } from 'core/utils/TabUtils'
import AssuraMessage from 'modules/home/components/AssuraMessage/AssuraMessage'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import { AssuraTabContainer } from 'shared/components/AssuraTabContainer/AssuraTabContainer'
import BaseInsurance from 'shared/components/BaseInsurance/BaseInsurance'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import {
    getLamalProductOfSelectedFamilyMember,
    getLcaProductsOfSelectedFamilyMember,
    getSelectedFamilyMember,
    getSummaryByPage
} from 'shared/store/combineSelectors'
import {
    fetchFamilyMember,
    fetchInitialPolicyNumber,
    getInitialPolicyNumber
} from 'shared/store/familyMember/familyMember.slice'
import {
    fetchInvoicesData,
    fetchRefundsData
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { getLang } from 'shared/store/general/general.slice'
import { fetchProductsYear } from 'shared/store/products/products.slice'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import HomeDesktopTitle from '../components/HomeDesktopTitle/HomeDesktopTitle'
import HomePersonalData from '../components/HomePersonalData/HomePersonalData'
import Infos from '../components/Infos/Infos'
import LastInvoices from '../components/LastInvoices/LastInvoices'
import LastRefunds from '../components/LastRefunds/LastRefunds'
import { default as LastSends } from '../components/LastSends/LastSends'
import LcaCardLightList from '../components/LcaCardLightList/LcaCardLightList'
import ScrollToDashboardFloatingButton from '../components/ScrollToDashboardFloatingButton/ScrollToDashboardFloatingButton'
import SplashScreensGroup from '../components/SplashScreens/SplashScreensGroup'

const bodyWithStickyElementClass = 'body-with-sticky-element'

const Home = (): JSX.Element => {   
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const isInitialisation = useRef(true)

    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? true
    const [isNoHeadOfFamilyBannerVisible, setIsNoHeadOfFamilyBannerVisible] = useState(false)
    const familySummary = useSelector(getSummaryByPage)
    const lang = useSelector(getLang)
    const selectedFamilyMember = useSelector(getSelectedFamilyMember)
    const lamalProductOfSelectedFamilyMember = useSelector(getLamalProductOfSelectedFamilyMember)
    const lcaProductsOfSelectedFamilyMember = useSelector(getLcaProductsOfSelectedFamilyMember)

    const initialPolicyNumber = useSelector(getInitialPolicyNumber)

    // Used to keep track of selected Tab
    const [selectedPerson, setSelectedPerson] = useState<AssuraTabData | undefined>(undefined)

    const peopleTabs = peopleToTab(familySummary?.insuredPersons)

    const dashboardRef = useRef<HTMLDivElement | null>(null)

    const fetchFinancialDocuments = (utn: number | null) => {
        const defaultProps = {
            type: FetchDocumentsTypeEnum.INIT,
            paginationDate: null,
            docsByPage: 3,
            filters: {
                utn,
                date: null
            }
        }

        dispatch(fetchInvoicesData(defaultProps))
        dispatch(fetchRefundsData(defaultProps))
    }

    useEffect(() => {
        setIsNoHeadOfFamilyBannerVisible(!isHeadOfFamily)
    }, [isHeadOfFamily])

    useEffect(() => {
        if (isNoHeadOfFamilyBannerVisible) {
            document.body.classList.add(bodyWithStickyElementClass)
        } else {
            document.body.classList.remove(bodyWithStickyElementClass)
        }
        return () => {
            document.body.classList.remove(bodyWithStickyElementClass)
        }
    }, [isNoHeadOfFamilyBannerVisible])

    const switchSelectedMember = (id: number) => {
        const person = peopleTabs.find((o) => o.uniqueKey === id)
        if (!person) return
        const selectedMember = familySummary?.insuredPersons?.find(
            (member) => member.policyNumber === person.businessId
        )
        const newUtn = selectedMember?.userTiersNumber ?? null

        dispatch(fetchFamilyMember({ selectedIndex: id }))
        fetchFinancialDocuments(newUtn)
    }


    // Select by policy number
    const switchPerson = (policy: number) => {
        if (peopleTabs.length === 0) return
        const tmpSelectedPerson = getPersonTabFromPolicyNumber(peopleTabs, policy)
        setSelectedPerson(tmpSelectedPerson)
        switchSelectedMember(tmpSelectedPerson.uniqueKey)
    }

    useEffect(() => {
        // Reset to the current year for the "How It Works" page
        dispatch(fetchProductsYear({ year: getCurrentYear() }))

        // if policy number is in the store, it is the "How It Works" page closing
        if (initialPolicyNumber) {
            switchPerson(initialPolicyNumber)
            dispatch(fetchInitialPolicyNumber({ initialPolicyNumber: 0 }))
            return
        }
        // reset selected person
        setSelectedPerson(undefined)
        switchSelectedMember(0)
    }, [])

    useEffect(() => {
        if (!isInitialisation.current) {
            const utn = selectedFamilyMember?.userTiersNumber ?? null
            fetchFinancialDocuments(utn)
        }
        isInitialisation.current = false
    }, [lang])

    return (
        <div className="home-page">
            <AssuraMessage />
            {isNoHeadOfFamilyBannerVisible && (
                <div className="no-head-banner-container">
                    <AssuraBanner
                        id="no-head-of-family-banner"
                        message={t('HOME.NO_HEAD_OF_FAMILY')}
                        onClose={() => setIsNoHeadOfFamilyBannerVisible(false)}
                        variant="alert"
                    />
                </div>
            )}
            <FullScreenContainer color="gray50">
                <Container>
                    <Infos />
                </Container>
                <Container>
                    {familySummary?.insuredPersons?.length > 1 && (
                        <AssuraTabContainer
                            data={peopleTabs}
                            selectedId={selectedPerson?.uniqueKey}
                            dataId="tab-assures-member"
                            onClick={switchSelectedMember}
                        />
                    )}
                </Container>
            </FullScreenContainer>
            <Container>
                <Row className="m-top-40" ref={dashboardRef} data-testid="home-dashboard">
                    <HomeDesktopTitle titleKey="HOME.BOTTOM_SIDE.INSURANCES.TITLE" />
                    <HomePersonalData />
                    <Col xs={12} md={8} xl={5} className="home-block-col home-base-insurance-col">
                        <BaseInsurance
                            lamalProduct={lamalProductOfSelectedFamilyMember}
                            isFromHome
                        />
                    </Col>
                    <Col xs={12} md={6} xl={4} className="home-block-col home-lca-block-col">
                        <LcaCardLightList lcaProducts={lcaProductsOfSelectedFamilyMember} />
                    </Col>
                    <HomeDesktopTitle titleKey="HOME.NOTIFICATIONS.FINANCES_TITLE" />
                    <LastInvoices />
                    <LastRefunds />
                    <LastSends />
                </Row>
            </Container>
            <ScrollToDashboardFloatingButton dashboardRef={dashboardRef} />
            <Container className="home-splashscreens-frame">
                <Row>
                    <SplashScreensGroup />
                </Row>
            </Container>
        </div>
    )
}

export default withAITracking(reactPlugin, Home, 'Home', 'home-container')
