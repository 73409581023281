import { useEffect, useState } from 'react'

import BREAKPOINTS from 'core/constants/breakpoints'

export interface NavBarHeights {
    navBarHeight: number
    navBarUpHeight: number
    navBarDownHeight: number
}

const getNavBarHeights = (): NavBarHeights => {
    const { md, xl } = BREAKPOINTS
    const width = window.innerWidth

    const navBarUpHeight = width >= xl ? 48 : 0
    const navBarDownHeight = width < md ? 64 : 96

    return {
        navBarHeight: navBarUpHeight + navBarDownHeight,
        navBarUpHeight,
        navBarDownHeight
    }
}

const useNavBarHeights = (): NavBarHeights => {
    const [navBarHeights, setNavBarHeights] = useState<NavBarHeights>(getNavBarHeights())

    useEffect(() => {
        const handleResize = () => {
            setNavBarHeights(getNavBarHeights())
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return navBarHeights
}

export default useNavBarHeights
