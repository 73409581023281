import { DocumentsFiltersState } from 'core/models/documents/Document'
import { TableFilterSelections, TableFilterState } from 'core/models/TableFilter'
import { setDate, subMonths } from 'date-fns'
import formatISO from 'date-fns/formatISO'

export interface DateFilterSelectionValue {
    startDate: Date
    endDate: Date
}

export interface DateStringFilterSelectionValue {
    startDate: string
    endDate: string
}

export const getDateFilterSelections = (): TableFilterSelections<DateFilterSelectionValue> => {
    const today = new Date()
    const actualYear = today.getFullYear()
    const firstDayofCurrentMonth = setDate(today, 1)

    const dateFilters: TableFilterSelections<DateFilterSelectionValue> = new Map()

    dateFilters.set('currentMonth', {
        label: 'COMMON.BLOTTER_FILTER_MONTH_CURRENT',
        value: {
            startDate: firstDayofCurrentMonth,
            endDate: today
        },
        isSelected: false,
        shouldBeTranslated: true
    })

    dateFilters.set('lastThreeMonths', {
        label: { key: 'COMMON.BLOTTER_FILTER_MONTH_LAST', variableObject: { number: 3 } },
        value: {
            startDate: subMonths(firstDayofCurrentMonth, 3),
            endDate: today
        },
        isSelected: false,
        shouldBeTranslated: true
    })

    dateFilters.set('lastSixMonths', {
        label: { key: 'COMMON.BLOTTER_FILTER_MONTH_LAST', variableObject: { number: 6 } },
        value: {
            startDate: subMonths(firstDayofCurrentMonth, 6),
            endDate: today
        },
        isSelected: false,
        shouldBeTranslated: true
    })

    const yearItems = [
        'currentYear',
        'yearMinusOne',
        'yearMinusTwo',
        'yearMinusThree',
        'yearMinusFour'
    ]

    yearItems.forEach((id, index) => {
        const currentYear = actualYear - index
        dateFilters.set(id, {
            label: { key: 'COMMON.BLOTTER_FILTER_YEAR', variableObject: { year: currentYear } },
            value: {
                startDate: new Date(currentYear, 0, 1),
                endDate: new Date(currentYear, 11, 31)
            },
            isSelected: false,
            shouldBeTranslated: true
        })
    })

    return dateFilters
}

export const mapDocumentsSelectionsToState = <T>(
    selections: TableFilterState<T>
): DocumentsFiltersState => {
    const filterState = {
        utn: null,
        date: null
    } as DocumentsFiltersState

    const date =
        selections.date &&
        (Array.from(selections.date.selections.values()).find((value) => value.isSelected)
            ?.value as DateFilterSelectionValue | undefined)

    const utn =
        selections.utn &&
        (Array.from(selections.utn.selections.values()).find((value) => value.isSelected)?.value as
            | number
            | undefined)

    if (date?.startDate && date?.endDate)
        filterState.date = {
            startDate: formatISO(date.startDate),
            endDate: formatISO(date.endDate)
        }

    if (utn) filterState.utn = utn

    return filterState
}
