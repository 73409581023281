import { useTranslation } from 'react-i18next'

import { ServicesHubItem } from 'core/models/services/ServicesHub'

interface AllRequestsItemRectoProps {
    item: ServicesHubItem
}

const AllRequestsItemRecto = ({ item }: AllRequestsItemRectoProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column justify-content-start align-items-center m-top-80 flex-1">
            <img src={item.icon} alt="illustration" width="56" height="56" />
            <span
                className="headlineSmallMedium m-top-16 text-center"
                data-testid={`services-hub-all-requests-item-recto-${item.id}-title`}
            >
                {t(item.title)}
            </span>
            {item.message ? (
                <span
                    className="paragraphLight m-top-24 text-center"
                    data-testid={`services-hub-all-requests-item-recto-${item.id}-message`}
                >
                    {t(item.message)}
                </span>
            ) : null}
        </div>
    )
}

export default AllRequestsItemRecto
