import { RequestStatus } from 'core/enums/RequestStatus'

export const mapStatusColor = (status: RequestStatus): string => {
    switch (status) {
        case RequestStatus.PENDING:
            return 'c-black'
        case RequestStatus.COMPLETED:
            return 'c-success'
        case RequestStatus.CANCELLED:
            return 'c-error'
        default:
            console.log(`Service status not supported ${status}`)
            return ''
    }
}

export const mapStatusTranslation = (
    status: RequestStatus,
    processingDate: string | null
): string => {
    switch (status) {
        case RequestStatus.PENDING:
            return 'STATUS.IN_PROGRESS'
        case RequestStatus.COMPLETED:
            return processingDate ? 'STATUS.COMPLETED_DATE' : 'STATUS.COMPLETED_REQUEST'
        case RequestStatus.CANCELLED:
            return 'STATUS.CANCELLED'
        default:
            console.log(`Service status not supported ${status}`)
            return ''
    }
}

export const mapStatusIcon = (status: RequestStatus): string => {
    switch (status) {
        case RequestStatus.PENDING:
            return 'assura-partially-treated'
        case RequestStatus.COMPLETED:
            return 'assura-double-check'
        case RequestStatus.CANCELLED:
            return 'assura-close'
        default:
            console.log(`Service status not supported ${status}`)
            return ''
    }
}
