import './AssuraTabContainer.css'

import { useEffect, useState } from 'react'

import { AssuraTabData, TabOptions, TabTypeEnum } from 'core/models/TabData'
import useWindowSize from 'core/services/useWindowSize'
import { except } from 'core/utils/ArrayUtils'
import {
    getSelectedTab,
    initialTabState,
    setAssuraItemDataSelected,
    setInitialSelectedCollection
} from 'core/utils/TabUtils'

import { TabDropdown } from '../AssuraTabDropdown/TabDropdown'
import AssuraTabItems from '../AssuraTabItems/AssuraTabItems'

type AssuraTabContainerProps = {
    data: AssuraTabData[]
    selectedId?: AssuraTabData['uniqueKey']
    onClick: (id: AssuraTabData['uniqueKey']) => void
    dataId: string
    options?: TabOptions
    // threshold : above this number we switch to dropdown.
    switchThreshold?: number
}
export const AssuraTabContainer = ({
    data,
    dataId,
    onClick,
    selectedId,
    options,
    switchThreshold = 6
}: AssuraTabContainerProps) => {
    const { isXL, width } = useWindowSize()
    const [currentDataset, setCurrentDataset] = useState<AssuraTabData[]>([])
    const [currentItem, setCurrentItem] = useState<AssuraTabData>(initialTabState)

    const [tabType, setTabType] = useState<TabTypeEnum>(TabTypeEnum.Dropdown)

    // keep track of previous data
    const [previousDataset, setPreviousDataset] = useState<AssuraTabData[]>([])

    const switchRule = !isXL || data.length > switchThreshold

    useEffect(() => {
        setSelectedDataAndDataSet()
        setTabType(defineType)
        // track current => become previous at the next pass if Data is renewed
        setPreviousDataset(data)
    }, [width, data, except(data, previousDataset)])

    useEffect(() => {
        if (selectedId === undefined) return
        selectItemHandler(selectedId)
    }, [selectedId])

    // ensure to fallback on the first index if current selected is removed from collection
    useEffect(() => {
        if (!selectedId) return
        if (!data.find((o) => o.uniqueKey === currentItem.uniqueKey)) {
            selectItemHandler(0)
        }
    }, [data])

    const setSelectedDataAndDataSet = () => {
        const currentOrStoredItem = getSelectedTab(data, currentItem)
        if (currentOrStoredItem === currentItem) return
        setCurrentItem(currentOrStoredItem)
        setCurrentDataset(setInitialSelectedCollection(data, currentOrStoredItem))
    }

    const selectItemHandler = (id: AssuraTabData['uniqueKey']) => {
        // Avoid reselect twice an alement.
        if (currentItem.uniqueKey === id || !currentDataset || !currentDataset.length) return
        const selected = setAssuraItemDataSelected(currentDataset, id)

        if (!selected) return
        const [currentData, dataSet] = selected

        setCurrentItem(currentData)
        setCurrentDataset(dataSet)

        // send the selected item to the client
        onClick(id)
    }

    const defineType: TabTypeEnum = switchRule ? TabTypeEnum.Dropdown : TabTypeEnum.Tabs

    return (
        <div className={'assura-tab-container'}>
            {currentDataset && tabType === TabTypeEnum.Tabs ? (
                <AssuraTabItems
                    data={currentDataset}
                    select={selectItemHandler}
                    dataId={dataId}
                    options={options}
                />
            ) : (
                <TabDropdown
                    data={currentDataset}
                    select={selectItemHandler}
                    dataId={dataId}
                    options={options}
                />
            )}
        </div>
    )
}
