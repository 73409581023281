import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import {
    AventuraAdditionalFamilyMemberModel,
    AventuraForm,
    AventuraPriceDetailsModel,
    AventuraPricingSummaryModel,
    membersSection
} from 'core/models/services/coverage/AventuraForm'
import { ExtraRHFRef } from 'core/models/services/RHF'
/* eslint-disable sonarjs/cognitive-complexity */
import { TFunction } from 'i18next'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import {
    removeAllNonPermanentBanners,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import { getAventuraFamily, getAventuraForm, getSubmitStatus } from 'shared/store/combineSelectors'
import {
    setFamilyMembers,
    setFormData,
    submitData
} from 'shared/store/services/aventura/aventura.slice'

import { AventuraFormManager } from '../../helpers/aventuraHelpers'
import { getDuplicateMember, getErrorMessage, isMemberEmpty } from '../../helpers/validations'
import InputRhf from '../Inputs/inputRhf'
import MemberTitle from '../sections/MembersTitle'
import Pricing from '../sections/Pricing'
import Submit from '../sections/Submit'
import TravelDates from '../sections/TravelDates'
import AventuraAdditionalMember from './AventuraAdditionalMember'

type AventuraProps = {
    t: TFunction
    people: PersonSummary[]
    prices: AventuraPriceDetailsModel[]
    isMobile: boolean
    isTablet: boolean
}

const Aventura = ({ people, t, isMobile, isTablet, prices }: AventuraProps): JSX.Element => {
    const dispatch = useDispatch()
    const aventuraFormData = useSelector(getAventuraForm)
    const aventuraMembers = useSelector(getAventuraFamily)
    const submitStatus = useSelector(getSubmitStatus)
    const {
        setValue,
        register,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        formState
    } = useFormContext()
    const { errors } = formState

    const [canFocus, setCanFocus] = useState(true)
    const [extraRhfRefs, setExtraRhfRefs] = useState<ExtraRHFRef[]>([])

    const [peopleCountList] = useState<SelectMap<string>>(AventuraFormManager.getPeopleCountList())
    const [peopleList] = useState<SelectMap<string | number>>(
        AventuraFormManager.getPeopleList(people, t)
    )
    const [pricing, setPricing] = useState<AventuraPricingSummaryModel | undefined>(undefined)

    // gether data from RHF
    const peopleCountValue: number = parseInt(watch('peopleCount'))
    const travelReason: Date = watch('travelReason')
    const travelDestination: Date = watch('travelDestination')
    const travelStartDate: Date = watch('travelStart')
    const travelEndDate: Date = watch('travelEnd')

    const mapMembersToRhfRefs = (member: AventuraAdditionalFamilyMemberModel): ExtraRHFRef[] => {
        return Object.keys(member).reduce<ExtraRHFRef[]>((acc, key) => {
            if (key === 'birthDate')
                acc.push({
                    id: `${membersSection}[${member.id}].${key}`,
                    name: `${membersSection}[${member.id}].${key}`
                })
            return acc
        }, [])
    }

    // set the current date as start date for request travelStart
    useEffect(() => {
        const aventuraDataBody = {
            ...aventuraFormData,
            travelReason: travelReason ? travelReason : '',
            travelDestination: travelDestination ? travelDestination : '',
            travelStart: travelStartDate ? formatDateToApiFormat(travelStartDate) : travelStartDate,
            travelEnd: travelEndDate ? formatDateToApiFormat(travelEndDate) : travelEndDate
        } as AventuraForm
        dispatch(setFormData(aventuraDataBody))
    }, [travelReason, travelDestination, travelStartDate, travelEndDate])

    // check and validate date inversion
    useEffect(() => {
        if (!travelEndDate || !travelStartDate) return
        clearErrors(['travelStart', 'travelEnd'])
        if (travelStartDate > travelEndDate) {
            setError('travelStart', {
                message: t('SERVICE.AVENTURA_VALIDATION_INVALID_DATE_INVERSION')
            })
            setError('travelEnd', { message: t('SERVICE.AVENTURA_VALIDATION_INVALID_DATE') })
        }
    }, [travelStartDate, travelEndDate])

    // Generate Pricing or warn user about date overflow
    useEffect(() => {
        if (!travelEndDate || !travelStartDate || !peopleCountValue || isNaN(peopleCountValue))
            return

        const pricingModel = AventuraFormManager.computePrice(
            peopleCountValue,
            travelStartDate,
            travelEndDate,
            prices,
            t
        )
        setPricing((_state) => (_state = pricingModel))
    }, [peopleCountValue, travelStartDate, travelEndDate, prices])

    // when a value in the "people count" are selected then
    // adds or remove members.
    useEffect(() => {
        if (!peopleCountValue || peopleCountValue === aventuraMembers.length) return
        const members = AventuraFormManager.addOrRemoveMembers(peopleCountValue, aventuraMembers)
        const newRhfRefs = members.reduce<ExtraRHFRef[]>((acc, member) => {
            const memberRefs = mapMembersToRhfRefs(member)
            return acc.concat(memberRefs)
        }, [])
        setExtraRhfRefs(newRhfRefs)
        dispatch(setFamilyMembers(members))
        setValue(membersSection, members)
    }, [peopleCountValue])

    const validateAndSubmit = () => {
        if (!hasWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }

        handleSubmit(submitSuccess, submitError)()
    }

    const validateduplicates = (): boolean => {
        const unique: AventuraAdditionalFamilyMemberModel[] = []
        for (const aventuraMember of aventuraMembers) {
            const duplicate = getDuplicateMember(aventuraMember, unique)
            if (isMemberEmpty(aventuraMember)) continue
            if (!duplicate) {
                if (!isMemberEmpty(aventuraMember)) unique.push(aventuraMember)
            } else {
                if (!isMemberEmpty(duplicate)) return false
            }
        }
        return true
    }

    const submitSuccess = async () => {
        onSubmit(true)
    }

    const submitError = async () => {
        setCanFocus(true)
    }

    const onSubmit = (isRHFFormValid: boolean) => {
        if (!isRHFFormValid) return
        if (!validateduplicates()) return

        const aventuraDataBody = AventuraFormManager.prepareSubmitData(
            aventuraFormData,
            peopleCountValue
        )

        dispatch(removeAllNonPermanentBanners())
        dispatch(submitData(aventuraDataBody))
    }
    const validationMandatory = 'COMMON.MANDATORY_FIELD'

    const validationLengthMessage = t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
        min: 2,
        max: 255
    })
    const inlineLabel = isTablet || isMobile

    const extractRefForFocus = (name: string): HTMLElement | undefined => {
        const rhfRef = document.getElementsByName(name)[0]
        const extraRef = extraRhfRefs?.find((item) => item.name === name)

        if (rhfRef) return rhfRef
        if (extraRef) {
            const extraElement = document.getElementById(extraRef.id)

            if (extraElement) return extraElement
        }
        return undefined
    }

    const extractErrorsFromMemberSection = (memberIndex: string, keys: string[]): HTMLElement[] => {
        const elements: HTMLElement[] = []
        keys.forEach((key) => {
            const inputElement = extractRefForFocus(`${membersSection}[${memberIndex}].${key}`)
            if (inputElement) elements.push(inputElement)
        })

        return elements
    }

    useEffect(() => {
        if (formState.errors && canFocus) {
            const elements = Object.keys(formState.errors).reduce<HTMLElement[]>((acc, name) => {
                if (name === membersSection) {
                    const memberErrorsList = errors[membersSection]
                    if (memberErrorsList) {
                        for (const [index, inputs] of Object.entries(memberErrorsList)) {
                            const extractedElements = extractErrorsFromMemberSection(
                                index,
                                Object.keys(inputs)
                            )
                            acc = acc.concat(extractedElements)
                        }
                    }
                } else {
                    const extractedRef = extractRefForFocus(name)
                    if (extractedRef) acc.push(extractedRef)
                }

                return acc
            }, [])
            elements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)

            if (elements.length > 0) {
                const errorElement = elements[0]
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                errorElement.focus({ preventScroll: true })
                setCanFocus(false)
            }
        }
    }, [formState, canFocus])

    return (
        <>
            <Container className="av-travel-details-block bg-white m-bottom-32 bc-gray100">
                <Row className="justify-content-start m-bottom-48">
                    <Col>
                        <InputRhf
                            id={'travelReason'}
                            name={'travelReason'}
                            label={t('SERVICE.AVENTURA_FORM_TRAVEL_REASON_LABEL')}
                            placeholder={t('SERVICE.AVENTURA_FORM_TRAVEL_REASON_PLACEHOLDER')}
                            inlineLabel={!inlineLabel}
                            minValue={2}
                            maxValue={255}
                            lengthCheckMessage={validationLengthMessage}
                            mandatoryCheckMessage={validationMandatory}
                            isInvalid={getErrorMessage(errors, 'travelReason') !== ''}
                            errors={t(getErrorMessage(errors, 'travelReason'))}
                            register={register}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-start  m-bottom-48">
                    <Col>
                        <InputRhf
                            id="travelDestination"
                            name="travelDestination"
                            label={t('SERVICE.AVENTURA_FORM_TRAVEL_DESTINATION_LABEL')}
                            placeholder={t('SERVICE.AVENTURA_FORM_TRAVEL_DESTINATION_PLACEHOLDER')}
                            inlineLabel={!inlineLabel}
                            minValue={2}
                            maxValue={255}
                            lengthCheckMessage={validationLengthMessage}
                            mandatoryCheckMessage={validationMandatory}
                            isInvalid={getErrorMessage(errors, 'travelDestination') !== ''}
                            errors={t(getErrorMessage(errors, 'travelDestination'))}
                            register={register}
                        />
                    </Col>
                </Row>

                <TravelDates
                    isMobile={isMobile}
                    isTablet={isTablet}
                    travelStartDate={travelStartDate}
                />

                <Row
                    className={`justify-content-start ${
                        pricing && (!pricing.error || pricing.price) ? 'm-bottom-48' : ''
                    }`}
                >
                    <Col>
                        <AssuraFormSelectRHF
                            name="peopleCount"
                            id="aventura-people-count"
                            label={t('SERVICE.AVENTURA_FORM_PEOPLE_COUNT_LABEL')}
                            items={peopleCountList}
                            labelSize={4}
                            inline={!(isMobile || isTablet)}
                            placeHolder={t('SERVICE.AVENTURA_FORM_PEOPLE_COUNT_PLACEHOLDER')}
                        />
                    </Col>
                </Row>

                <>
                    {pricing && !pricing.error && pricing.price > 0 && (
                        <Row className="justify-content-start m-0">
                            <Pricing inline={isMobile || isTablet} pricing={pricing} />
                        </Row>
                    )}

                    {pricing && pricing.error && (
                        <Row className="justify-content-center m-top-48">
                            <Col>
                                <div
                                    className="aventura-days-limit-warning"
                                    data-testid="aventura-days-limit-warning"
                                >
                                    <i className="icon assura-warning-circle size-24 c-primary"></i>
                                    <span className="labelExtraSmall">{pricing.error}</span>
                                </div>
                            </Col>
                        </Row>
                    )}
                </>
            </Container>
            {pricing && !pricing.error && pricing.price > 0 && (
                <>
                    <MemberTitle aventuraMembers={aventuraMembers} />

                    {aventuraMembers.map((person: AventuraAdditionalFamilyMemberModel) => {
                        return (
                            <AventuraAdditionalMember
                                key={person.id}
                                person={person}
                                people={people}
                                t={t}
                                peopleList={peopleList}
                                aventuraMembers={aventuraMembers}
                                isMobile={isMobile}
                                isTablet={isTablet}
                            />
                        )
                    })}

                    <Submit
                        membersCount={aventuraMembers.length}
                        submitStatus={submitStatus}
                        validateAndSubmit={validateAndSubmit}
                        register={register}
                    />
                </>
            )}
        </>
    )
}

export default Aventura
