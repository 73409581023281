import './SettingsInformationLogin.css'

import { useEffect, useState } from 'react'
import { Accordion, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { deactivate2fa, is2faActive, request2fa } from 'core/api/DoubleAuthentication'
import TranslationKeys from 'core/constants/translationKeys'
import { CredentialEditMode } from 'core/enums/CredentialEditMode'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { getCurrentYear } from 'core/utils/dateUtils'
import { openInNewTab } from 'core/utils/onClickUtils'
import { newSettingOption, SettingsOptions } from 'modules/settings/pages/Settings'
import ToggleOff from 'shared/assets/images/toggle_off.png'
import ToggleOn from 'shared/assets/images/toggle_on.png'
import AssuraCmsLink from 'shared/components/AssuraCmsLink/AssuraCmsLink'
import ModalAssura from 'shared/components/ModalAssura/ModalAssura'
import { logout } from 'shared/store/authentication/authentication.slice'
import {
    fetchSummaryByYear,
    getIsDoubleAuthenticationActive,
    getSummaryLoadingStatus,
    setIsDoubleAuthenticationActive
} from 'shared/store/familySummaries/familySummaries.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getConnectedUserInfos } from 'shared/store/selectors/getConnectedUserInfos'
import { getCredentialsForceReload } from 'shared/store/settings/credentials/credentials.slice'
import { useAppDispatch } from 'shared/store/store'

import AssuraLoadAndError from '../../../../shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraTextButton from '../../../../shared/components/AssuraTextButton/AssuraTextButton'

interface DoubleAuthenticationProps {
    setIsDoubleAuthErrorVisible: (b: boolean) => void
    setIsDoubleAuthMailErrorVisible: (b: boolean) => void
}

const DoubleAuthInformation = ({
    setIsDoubleAuthErrorVisible,
    setIsDoubleAuthMailErrorVisible
}: DoubleAuthenticationProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const assets = useSelector(getAssets)
    const doubleAuthIsActive = useSelector(getIsDoubleAuthenticationActive)
    const connectedUser = useSelector(getConnectedUserInfos)

    const [isActivateModalVisible, setIsActivateModalVisible] = useState(false)
    const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState(false)

    const deactivateDoubleAuthentication = () => {
        deactivate2fa()
            .then(() => {
                is2faActive().then((response) => {
                    dispatch(setIsDoubleAuthenticationActive(response))
                })
            })
            .catch(() => {
                setIsDoubleAuthErrorVisible(true)
            })
            .finally(() => {
                setIsDeactivateModalVisible(false)
            })
    }

    const requestDoubleAuthentication = () => {
        request2fa()
            .then(() => {
                dispatch(logout())
            })
            .catch(() => {
                setIsDoubleAuthErrorVisible(true)
            })
            .finally(() => {
                setIsActivateModalVisible(false)
            })
    }

    const activateDoubleAuthenticationModal = () => {
        if (connectedUser?.email) setIsActivateModalVisible(true)
        else setIsDoubleAuthMailErrorVisible(true)
    }

    const okButton = t('COMMON.ALERT_BUTTON_OK')

    return (
        <>
            <ModalAssura
                id="settings-information-activate-double-authentication"
                visible={isActivateModalVisible}
                onHide={() => setIsActivateModalVisible(false)}
                title={t('ACCOUNT_DOUBLE_AUTH.ENABLE_TITLE')}
                description={t('ACCOUNT_DOUBLE_AUTH.ENABLE_DESCRIPTION')}
                buttonTitle={okButton}
                onButtonClicked={requestDoubleAuthentication}
                showCancelButton={true}
            />
            <ModalAssura
                id="settings-information-deactivate-double-authentication"
                visible={isDeactivateModalVisible}
                onHide={() => setIsDeactivateModalVisible(false)}
                title={t('ACCOUNT_DOUBLE_AUTH.DISABLE_TITLE')}
                description={t('ACCOUNT_DOUBLE_AUTH.DISABLE_DESCRIPTION')}
                buttonTitle={okButton}
                onButtonClicked={deactivateDoubleAuthentication}
                showCancelButton={true}
            />
            <div className="d-flex m-top-24 double-auth-wrap">
                <div className="d-flex align-items-center m-right-40">
                    {doubleAuthIsActive ? (
                        <>
                            <div
                                onClick={() => setIsDeactivateModalVisible(true)}
                                className="double-auth-toggle m-right-16 labelSmall c-gray500"
                            >
                                {t('ACCOUNT_DOUBLE_AUTH.DISABLED')}
                            </div>
                            <img
                                onClick={() => setIsDeactivateModalVisible(true)}
                                className="double-auth-toggle"
                                src={ToggleOn}
                            />
                            <div className="m-left-16 labelSmall">
                                {t('ACCOUNT_DOUBLE_AUTH.ENABLED')}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="m-right-16 labelSmall">
                                {t('ACCOUNT_DOUBLE_AUTH.DISABLED')}
                            </div>
                            <img
                                onClick={activateDoubleAuthenticationModal}
                                className="double-auth-toggle"
                                src={ToggleOff}
                            />
                            <div
                                onClick={activateDoubleAuthenticationModal}
                                className="double-auth-toggle m-left-16 labelSmall c-gray500"
                            >
                                {t('ACCOUNT_DOUBLE_AUTH.ENABLED')}
                            </div>
                        </>
                    )}
                </div>
                {doubleAuthIsActive && (
                    <div
                        className="double-auth-link double-auth-t-margin double-auth-manage bc-gray100 m-right-16 p-right-16"
                        onClick={() =>
                            openInNewTab(
                                process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD'
                                    ? 'https://sse-int.trustid.ch/assura_assure'
                                    : 'https://sse.trustid.ch/assura_assure'
                            )
                        }
                    >
                        <i className="icon assura-edit size-24" />
                        <span className="labelSmall m-left-8">
                            {t('ACCOUNT_DOUBLE_AUTH.MANAGE')}
                        </span>
                    </div>
                )}
                <AssuraCmsLink
                    asset={assets.Guide_2FA}
                    title={t('ACCOUNT_DOUBLE_AUTH.GUIDE')}
                    classNames="double-auth-link double-auth-t-margin labelSmall"
                    icon={{ name: 'assura-info-circle', size: 24 }}
                />
            </div>
        </>
    )
}

export type SettingsInformationLoginProps = {
    editAction: (options: SettingsOptions) => void
    setIsDoubleAuthErrorVisible: (b: boolean) => void
    setIsDoubleAuthMailErrorVisible: (b: boolean) => void
}

const SettingsInformationLogin = ({ ...props }: SettingsInformationLoginProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(LoadingStatusEnum.LOADING)
    const [isDoubleAuthVisible, setIsDoubleAuthVisible] = useState(false)

    const forceReload = useSelector(getCredentialsForceReload)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const connectedUserInfos = useSelector(getConnectedUserInfos)

    const dispatch = useAppDispatch()

    const [login, setLogin] = useState<string>('')
    // here we don't need to get the exact password
    const [password] = useState<string>('password')

    useEffect(() => {
        dispatch(
            fetchSummaryByYear({
                shouldFetchProducts: false,
                year: getCurrentYear(),
                reload: true
            })
        )
    }, [])

    useEffect(() => {
        setLoading(summaryLoadingStatus)
        if (!connectedUserInfos || !connectedUserInfos?.login) return
        setLogin(connectedUserInfos?.login)
        setLoading(LoadingStatusEnum.OK)
    }, [connectedUserInfos])

    useEffect(() => {
        if (!forceReload) return
        if (!connectedUserInfos || !connectedUserInfos?.login) return
        setLoading(LoadingStatusEnum.LOADING)
        dispatch(
            fetchSummaryByYear({
                shouldFetchProducts: false,
                year: getCurrentYear(),
                reload: true
            })
        )
    }, [forceReload])

    const editAllowed = Source() === ServicesSource.CUSTOMER_PORTAL

    return (
        <div className={'settings-information-login-card'}>
            <label data-testid={'settings-information-label'} className={'headlineSmallMedium'}>
                {t(TranslationKeys.Credentials.title)}
            </label>
            <div className={'settings-information-login-content'}>
                <div className={'divider'} />
                <AssuraLoadAndError status={loading} shouldDisplayContainer>
                    <Row>
                        <AssuraTextButton
                            dataTestId={'settings-information-login'}
                            label={t(TranslationKeys.Credentials.login.label)}
                            value={login}
                            onEditAction={() =>
                                props.editAction(newSettingOption(CredentialEditMode.Login, login))
                            }
                            type={'text'}
                            editAllowed={editAllowed}
                        />
                        <AssuraTextButton
                            dataTestId={'settings-information-password'}
                            label={t(TranslationKeys.Credentials.password.label)}
                            value={password}
                            onEditAction={() =>
                                props.editAction(newSettingOption(CredentialEditMode.Password))
                            }
                            type={'password'}
                            editAllowed={editAllowed}
                        />
                    </Row>
                    <>
                        {editAllowed && (
                            <Row>
                                <Accordion className="accordion">
                                    <Accordion.Toggle
                                        className="double-auth-title cursor-pointer d-flex align-items-center labelMedium"
                                        eventKey="double-auth"
                                        onClick={() => {
                                            setIsDoubleAuthVisible(!isDoubleAuthVisible)
                                        }}
                                    >
                                        <div className="d-flex">
                                            <span
                                                className={`icon size-20 m-right-8 ${
                                                    isDoubleAuthVisible
                                                        ? 'assura-down'
                                                        : 'assura-right'
                                                }`}
                                            />
                                            <div style={{ textAlign: 'left' }}>
                                                {t('ACCOUNT_DOUBLE_AUTH.TITLE')}
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="double-auth">
                                        <DoubleAuthInformation
                                            setIsDoubleAuthErrorVisible={
                                                props.setIsDoubleAuthErrorVisible
                                            }
                                            setIsDoubleAuthMailErrorVisible={
                                                props.setIsDoubleAuthMailErrorVisible
                                            }
                                        />
                                    </Accordion.Collapse>
                                </Accordion>
                            </Row>
                        )}
                    </>
                </AssuraLoadAndError>
            </div>
        </div>
    )
}

export default SettingsInformationLogin
