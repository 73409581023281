import './TabDropdownDrawer.css'

import { useEffect, useRef, useState } from 'react'

import { AssuraTabData, ItemMetaData } from 'core/models/TabData'
import useWindowSize from 'core/services/useWindowSize'

import TabDropdownItem from './TabDropdownItem'

export type TabDropdownDrawerProps = {
    // the inner data structure
    data: AssuraTabData[]
    show: boolean
    currentSelectedItemId: AssuraTabData['uniqueKey'] | null
    dataId: string
    // overrides the default item component.
    // To create a new one please refers to component: DropdownItem
    itemTemplate?: (data: AssuraTabData, show: boolean) => React.ReactElement

    // Overrides the inner item components.
    // ------------------------------------
    // The inner item value template.
    valueTemplate?: (data: AssuraTabData) => React.ReactElement
    // The inner left item artifact template.
    // icon / initials / badge / avatar
    leftTemplate?: (data: ItemMetaData) => React.ReactElement
    // The inner right item artifact template.
    // icon / initials / badge / avatar
    rightTemplate?: (data: ItemMetaData) => React.ReactElement

    // selection item
    select: (id: AssuraTabData['uniqueKey']) => void
}

export const TabDropdownDrawer = ({
    data,
    show,
    currentSelectedItemId,
    dataId,
    itemTemplate,
    valueTemplate,
    leftTemplate,
    rightTemplate,
    select
}: TabDropdownDrawerProps) => {
    const { height } = useWindowSize()
    const [drawerPosition, setAppearsBottom] = useState<boolean>(false)
    const currentPositionY = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTimeout(() => {
            const divElement = currentPositionY.current?.getBoundingClientRect()
            if (!divElement) return

            const elementPositionY = Number.parseInt(divElement.y.toString())
            const elementHeight = Number.parseInt(divElement.height.toString())

            setAppearsBottom(elementPositionY + elementHeight >= height)
        }, 80)
    }, [currentPositionY])

    return (
        <div
            ref={currentPositionY}
            className={`${
                show ? 'tab-dropdown-drawer' : 'tab-dropdown-drawer-hidden'
            }  tab-dropdown-default`}
            style={drawerPosition ? { bottom: '80px' } : { top: '80px' }}
        >
            <div
                className={`${
                    show ? 'tab-dropdown-drawer-content' : 'tab-dropdown-drawer-content-hidden'
                } tab-dropdown-drawer-content-default`}
            >
                {data.map((itemData) => (
                    <div
                        key={itemData.uniqueKey}
                        data-testid={`${dataId}-tab-${itemData.uniqueKey}`}
                        className={`${
                            currentSelectedItemId && currentSelectedItemId === itemData.uniqueKey
                                ? 'active'
                                : ''
                        } tab-dropdown-drawer-item-container`}
                        onClick={() => select(itemData.uniqueKey)}
                    >
                        {itemTemplate ? (
                            itemTemplate(itemData, show)
                        ) : (
                            <TabDropdownItem
                                data={itemData}
                                show={show}
                                valueTemplate={valueTemplate}
                                leftTemplate={leftTemplate}
                                rightTemplate={rightTemplate}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
