import './NavBarSearch.css'

import { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useNavBarHeights from 'core/services/useNavBarHeights'
import useOnClickOutside from 'core/services/useOnClickOutside'
import useWindowSize from 'core/services/useWindowSize'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'
import { getUserText, resetSearch } from 'shared/store/appSearch/appSearch.slice'
import { getAppSearchFilteredSections } from 'shared/store/selectors/getAppSearchFilteredSections'
import { useAppDispatch } from 'shared/store/store'

import AppSearchBar from '../AppSearch/AppSearchBar'
import AppSearchNoResult from '../AppSearch/AppSearchNoResult'
import AppSearchResultItem from '../AppSearch/AppSearchResultItem'
import AppSearchSectionBlock from '../AppSearch/AppSearchSectionBlock'
import AppSearchSuggestions from '../AppSearch/AppSearchSuggestions'
import AssuraIconButton from '../AssuraIconButton/AssuraIconButton'

const NavBarSearch = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { isXL, isMobile, height } = useWindowSize()
    const { navBarHeight, navBarUpHeight } = useNavBarHeights()
    const { closeNavBarSearch } = useAssuraNavBarContext()

    const userInput = useSelector(getUserText)
    const userIsSearching = userInput.length > 2

    const MAX_RESULTS_PER_SECTION = 3

    const sections = useSelector(getAppSearchFilteredSections)
    const shouldDisplayMoreResults =
        sections.some((section) => section.results.length > MAX_RESULTS_PER_SECTION) &&
        userIsSearching

    const filteredSections = sections.map((item) => {
        return {
            ...item,
            results: item.results.slice(0, MAX_RESULTS_PER_SECTION)
        }
    })

    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleOutsideClick = () => {
        closeNavBarSearch()
    }

    const handleCloseNavBarSearch = () => {
        dispatch(resetSearch())
        closeNavBarSearch()
    }

    const showAllresults = () => {
        closeNavBarSearch()
        navigate(`${navigationConstants.SEARCH.url}`)
    }

    const handleEnterKeyPress = () => {
        if (shouldDisplayMoreResults) showAllresults()
    }

    const outsideClickExcludedElementId = 'navigation-search-cta'
    useOnClickOutside(wrapperRef, handleOutsideClick, outsideClickExcludedElementId)

    useEffect(() => {
        if (wrapperRef?.current) {
            wrapperRef.current.style.height = isMobile ? `${height - navBarHeight}px` : 'auto'
        }
    }, [height, isMobile, navBarHeight])

    return (
        <RemoveScroll>
            <div
                className="navbar-search-backdrop-container"
                data-testid="navbar-search-backdrop-container"
                style={{
                    top: (isXL ? navBarUpHeight : navBarHeight) + 1
                }}
            >
                <div
                    className="bg-white d-flex flex-column"
                    ref={wrapperRef}
                    data-testid="navbar-search-backdrop-wrapper"
                >
                    <Container>
                        {isMobile && (
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-start align-items-center m-top-32 m-bottom-24"
                                    onClick={handleCloseNavBarSearch}
                                    data-testid="navbar-search-backdrop-mobile-container"
                                >
                                    <i className="icon assura-back size-24" />
                                    <span className="cta m-left-8">{t('COMMON.BACK')}</span>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col
                                xl={{ span: 8, offset: 2 }}
                                className={`p-top-16 p-bottom-16${
                                    isMobile
                                        ? ' border-solid-width-1 bc-gray300 m-left-16 m-right-16'
                                        : ''
                                }`}
                            >
                                <AppSearchBar
                                    handleEnterKeyPress={handleEnterKeyPress}
                                    hasAutoComplete
                                />
                            </Col>
                        </Row>
                    </Container>
                    {userIsSearching ? (
                        <>
                            {filteredSections.length > 0 ? (
                                <div
                                    className="d-flex flex-column flex-1"
                                    data-testid="app-search-sections-container"
                                >
                                    <div className="d-flex flex-column justify-content-start flex-1">
                                        {filteredSections.map((section, i) => (
                                            <AppSearchSectionBlock
                                                testId={`app-search-section-${section.id}`}
                                                sectionTitleKey={section.sectionTitleKey}
                                                colProps={{ xl: { span: 8, offset: 2 } }}
                                                hasTopBorder={!(isMobile && i === 0)}
                                                key={`app-search-section-${section.id}`}
                                            >
                                                <>
                                                    {section.results.map((result, index) => (
                                                        <Col
                                                            xl={{ span: 8, offset: 2 }}
                                                            className="d-flex flex-column justify-content-center align-items-start"
                                                            key={`app-search-section-${section.id}-${index}`}
                                                        >
                                                            <AppSearchResultItem
                                                                {...result}
                                                                index={index}
                                                                section={section.id}
                                                                hasStrongText
                                                                callback={handleCloseNavBarSearch}
                                                            />
                                                        </Col>
                                                    ))}
                                                </>
                                            </AppSearchSectionBlock>
                                        ))}
                                    </div>
                                    {shouldDisplayMoreResults && (
                                        <AppSearchSectionBlock
                                            testId="app-search-section-see-all"
                                            hasTopBorder
                                            backgroundColor="gray50"
                                        >
                                            <Col className="d-flex justify-content-center align-items-center">
                                                <AssuraIconButton
                                                    id="assura-app-search-see-all"
                                                    icon="assura-plus"
                                                    variant="primary"
                                                    size="small"
                                                    onClick={showAllresults}
                                                    label={t('SEARCH.SEE_ALL_RESULTS')}
                                                    classNames="align-self-center"
                                                />
                                            </Col>
                                        </AppSearchSectionBlock>
                                    )}
                                </div>
                            ) : (
                                <AppSearchSectionBlock
                                    hasTopBorder={!isMobile}
                                    testId="app-search-no-result-section"
                                >
                                    <Col
                                        xs={12}
                                        md={4}
                                        xl={{ span: 3, offset: 2 }}
                                        className="navbar-search-no-result-container"
                                    >
                                        <AppSearchNoResult />
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={8}
                                        xl={5}
                                        className="d-flex align-items-center"
                                    >
                                        <AppSearchSuggestions
                                            selectionCallBack={handleCloseNavBarSearch}
                                        />
                                    </Col>
                                </AppSearchSectionBlock>
                            )}
                        </>
                    ) : (
                        <AppSearchSectionBlock
                            hasTopBorder={!isMobile}
                            testId="app-search-suggestions-section"
                        >
                            <Col xl={{ span: 10, offset: 2 }}>
                                <AppSearchSuggestions selectionCallBack={handleCloseNavBarSearch} />
                            </Col>
                        </AppSearchSectionBlock>
                    )}
                </div>
            </div>
        </RemoveScroll>
    )
}

export default NavBarSearch
