import './EnveloppesSubmissionPanel.css'

import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import ActivityIndicator from '../../../../shared/components/ActivityIndicator/ActivityIndicator'
import EnveloppesSubmissionPanelHeader from './EnveloppesSubmissionPanelHeader'

interface EnveloppesSubmissionPanelProps {
    pdf: AssuraDocument
    isPanelOpen: boolean
    closePanel: () => void
}
const EnveloppesSubmissionPanel = (props: EnveloppesSubmissionPanelProps): JSX.Element => {
    const windowSize = useWindowSize()
    const toolbarPluginInstance = toolbarPlugin()

    const { pdf, isPanelOpen, closePanel } = props

    const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

    return (
        <AssuraPanel
            type={'right'}
            isOpen={isPanelOpen}
            size={windowSize.isXL ? 75 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <div className="enveloppes-submission-panel-container">
                <EnveloppesSubmissionPanelHeader
                    fileName={pdf.fileName}
                    closePanel={closePanel}
                    toolbarPluginInstance={toolbarPluginInstance}
                />
                <AssuraPdfViewer
                    toolbarPluginInstance={toolbarPluginInstance}
                    doc={pdf}
                    renderPdfLoader={renderPdfLoader}
                />
            </div>
        </AssuraPanel>
    )
}

export default EnveloppesSubmissionPanel
