import './InvoicesTableRow.css'

import { useTranslation } from 'react-i18next'

import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'
import concatClassNames from 'core/utils/classNameUtils'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import HiddenField from 'shared/components/AssuraTable/Fields/HiddenField'
import ReadStatusField from 'shared/components/AssuraTable/Fields/ReadStatusField'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

import InvoicesTableAmountField from './InvoicesTableAmountField'
import InvoicesTableRowMobile from './InvoicesTableRowMobile'

interface InvoicesTableRowProps {
    row: DocumentRow
}

const InvoicesTableRow = ({ row }: InvoicesTableRowProps): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()

    const unreadClass = row.isRead ? 'label' : 'labelMedium c-black'
    const dateClass = concatClassNames(['invoices-date-field', unreadClass])
    const documentNameClass = concatClassNames(['invoices-document-name-field', unreadClass])

    const label = `${row.documentName} ${t('COMMON.FOR', {
        item: row.insuredPersonName
    })}`

    return isXL ? (
        <>
            <ReadStatusField isRead={row.isRead} rowId={row.documentId} />
            <DateField date={row.creationDate} rowId={row.documentId} classNames={dateClass} />
            <TextField
                label={label}
                rowId={row.documentId}
                classNames={documentNameClass}
                fieldId="documentName"
            />
            {!row.isMigrated && row.financialStatusData ? (
                <StatusField
                    status={row.financialStatusData.translationKey}
                    statusIcon={row.financialStatusData.icon}
                    statusColor={row.financialStatusData.color}
                    rowId={row.documentId}
                    classNames="invoices-table-row-status"
                    labelClassNames={unreadClass}
                />
            ) : (
                <HiddenField classNames="invoices-table-row-status" />
            )}
            <InvoicesTableAmountField row={row} />
            <GoToField rowId={row.documentId} marginLeft={40} />
        </>
    ) : (
        <InvoicesTableRowMobile row={row} />
    )
}

export default InvoicesTableRow
