import './AssuraMessage.css'

import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'

const AssuraMessage = (): JSX.Element | null => {
    const { t } = useTranslation()

    const generalMessageKey = 'HOME.GENERAL_MESSAGE'
    const generalMessage = t(generalMessageKey)

    if (!generalMessage || generalMessage === '' || generalMessage === generalMessageKey)
        return null
    return (
        <FullScreenContainer color="gray100">
            <Container>
                <Row>
                    <Col xs={12} className="assura-message-container c-black">
                        <i className="icon size-24 assura-info-circle m-right-8" />
                        <div data-testid="assura-message" className="paragraphSmall align-self-center">
                            <AssuraMarkdown message={generalMessage} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </FullScreenContainer>
    )
}

export default AssuraMessage
