import { getCurrentYear, getDifferenceInCalendarYears } from 'core/utils/dateUtils'
import {
    getBeginDate,
    getCurrentSelectedModel
} from 'shared/store/services/coverage/coverage.slice'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../../familySummaries/familySummaries.slice'
import { getSelectedMemberPolicyNumber } from '../../services/services.slice'

export const getCanHavePediatricianForModelDeductible = createSelector(
    [getFamilySummaries, getSelectedMemberPolicyNumber, getBeginDate, getCurrentSelectedModel],
    (familySummaries, selectedMemberPolicyNumber, beginDate, code) => {
        let canHavePediatrician = false
        // no need to get summary according to beginDate. CurrentYear is enough for what we want to test
        const summaryResult = familySummaries[getCurrentYear()]
        if (summaryResult) {
            const selectedFamilyMember = summaryResult.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )
            if (
                selectedFamilyMember &&
                code === 'RMd' &&
                beginDate &&
                getDifferenceInCalendarYears(
                    beginDate,
                    new Date(selectedFamilyMember.dateOfBirth)
                ) < 12
            ) {
                canHavePediatrician = true
            }
        }
        return canHavePediatrician
    }
)
