import './TripCancellation.css'

import { useTranslation } from 'react-i18next'

import { reactPlugin } from 'AppInsights'
import { ServiceType } from 'core/enums/ServiceType'
import FormPage from 'modules/services/components/FormPage/FormPage'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import TripCancellationHeader from '../components/TripCancellationHeader'
import TripCancellationLink, { TripCancellationLinkProps } from '../components/TripCancellationLink'

const TripCancellation = (): JSX.Element => {
    const { t } = useTranslation()

    const links: TripCancellationLinkProps[] = [
        {
            labelKey: 'SERVICES.TRIP_CANCELLATION_BEFORE',
            ecLinkId: '64a2d4acd9aa0c797c07a592'
        },
        {
            labelKey: 'SERVICES.TRIP_CANCELLATION_DURING',
            ecLinkId: '64a2d4d685607cc54a074192'
        },
        {
            labelKey: 'SERVICES.TRIP_CANCELLATION_LEISURE',
            ecLinkId: '64a2d57786e7fd7ff2017334'
        }
    ]

    return (
        <FormPage
            _defaultValues={{}}
            serviceType={ServiceType.TRIP_CANCELLATION}
            formTitle={{
                title: t('SERVICES.TRIP_CANCELLATION_TITLE'),
                category: t('SERVICES.TRIP_CANCELLATION_CATEGORY')
            }}
            onSuccess={() => undefined}
            goodToKnow={
                <GoodToKnow
                    goodToKnowId={ServiceType.TRIP_CANCELLATION}
                />
            }
            shouldDisplaySubmitButton={false}
        >
            <>
                <div className="trip-cancellation-form">
                    <TripCancellationHeader />
                    <div className="d-flex flex-column border-top-solid-width-1 btc-gray100 m-top-24">
                        {links.map((link) => (
                            <TripCancellationLink {...link} key={link.ecLinkId} />
                        ))}
                    </div>
                </div>
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    TripCancellation,
    'TripCancellation',
    'trip-cancellation-container'
)
