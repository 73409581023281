import { createBrowserHistory } from 'history'
import { buildVersionKey } from 'index'

import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web'

// do not delete commented configurations.
const correlationHeaderDomains: string[] = [
    'localhost',
    'localhost:3000'
    // 'e-assura.ch',
    // 'e-assura.ch:1024',
    // 'acc-cup.e-assura.ch',
    // 'acc-cup.e-assura.ch:1024',
    // 'acc-cup-forms.e-assura.ch',
    // 'acc-cup-forms.e-assura.ch:1024',
    // 'acc-cup-support.e-assura.ch',
    // 'acc-cup-support.e-assura.ch:1024',
    // 'dev-cup.e-assura.ch',
    // 'dev-cup-forms.e-assura.ch',
    // 'dev-cup-support.e-assura.ch',
    // 'int-cup.e-assura.ch',
    // 'int-cup-forms.e-assura.ch',
    // 'int-cup-support.e-assura.ch',
    // 'int-cup.e-assura.ch',
    // 'int-cup.e-assura.ch:1024',
    // 'int-cup-forms.e-assura.ch',
    // 'int-cup-forms.e-assura.ch:1024',
    // 'int-cup-support.e-assura.ch',
    // 'int-cup-support.e-assura.ch:1024'
]

const browserHistory = createBrowserHistory({ basename: '' })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        },
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        overridePageViewDuration: true,
        enableCorsCorrelation: true,
        correlationHeaderDomains: correlationHeaderDomains,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableFetchTracking: false,
        disableAjaxTracking: false,
        disableCorrelationHeaders: false,
        disableExceptionTracking: false
    }
})
appInsights.loadAppInsights()
appInsights.trackPageView()

appInsights.addTelemetryInitializer((envelope) => {
    if (envelope.tags) {
        envelope.tags['ai.cloud.role'] = 'Customer Portal'
    }
    if (envelope.baseData) {
        envelope.baseData.properties['Website version'] =
            localStorage.getItem(buildVersionKey) ?? 'No version detected in localstorage'
    }
})

export { reactPlugin, appInsights }
