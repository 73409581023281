import './DiscoveryRequests.css'

import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ServicesHubItem } from 'core/models/services/ServicesHub'
import image from 'shared/assets/images/services-discovery.png'
import EcLinkHandler from 'shared/components/EcLinkHandler/EcLinkHandler'
import { getAssets } from 'shared/store/selectors/getAssets'

import RequestsEcLink from '../RequestsEcLink/RequestsEcLink'

interface DiscoveryRequestsProps {
    item: ServicesHubItem
}

const DiscoveryRequests = ({ item }: DiscoveryRequestsProps): JSX.Element => {
    const { t } = useTranslation()

    const links = item.links
    const assets = useSelector(getAssets)

    const onClickSendAnalytics = (link: string) => {
        // bypass if not symptom checkeritem
        if (!link.includes('Symptom')) return
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_CLICK, {
            [analyticsConstants.PARAMS.ENTRY_POINT]:
                entryPointsConstants.ENTRY_POINTS.DISCOVERY_LINK,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }

    return (
        <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <div className="services-hub-discovery-requests-container">
                <div
                    className="services-hub-discovery-requests-image"
                    style={{ backgroundImage: `url(${assets.Image_OnlineServices?.fr ?? image})` }}
                />
                <div className="services-hub-discovery-requests-message">
                    <span className="headlineLarge">{t('SERVICES.DISCOVERY_TITLE')}</span>
                    <span className="paragraphLight m-top-24">
                        {t('SERVICES.DISCOVERY_MESSAGE')}
                    </span>
                </div>
                {links ? (
                    <div className="services-hub-discovery-requests-links-container">
                        <div className="requests-list-container">
                            <>
                                {links.map((link) => (
                                    <EcLinkHandler
                                        ecLinkId={link.ecLinkId}
                                        key={`discovery-${link.ecLinkId}`}
                                    >
                                        <RequestsEcLink
                                            link={link}
                                            callback={onClickSendAnalytics}
                                        />
                                    </EcLinkHandler>
                                ))}
                            </>
                        </div>
                    </div>
                ) : null}
            </div>
        </Col>
    )
}

export default DiscoveryRequests
