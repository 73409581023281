import './PrimesPanel.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { Product } from 'core/models/Product'
import { TranslationKey, TranslationKeyWithVariable } from 'core/models/Translations'
import useWindowSize from 'core/services/useWindowSize'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { fetchDiscountsByMemberAndByYear } from 'shared/store/familySummaries/familySummaries.slice'
import { getDiscountsLoaderStatus } from 'shared/store/selectors/getDiscountsLoaderStatus'

import PrimesPanelDiscountsInfo from './PrimesPanelDiscountsInfo'
import PrimesPanelFooter from './PrimesPanelFooter'
import PrimesPanelHeader from './PrimesPanelHeader'
import PrimesPanelLamal from './PrimesPanelLamal'
import PrimesPanelLcas from './PrimesPanelLcas'
import PrimesPanelMemberInfo from './PrimesPanelMemberInfo'

interface DiscountsPanelProps {
    isOpen: boolean
    closePanel: () => void
    selectedYear: number
    familyMemberIndex: number
    lamal?: ProductPerson
    lcas?: Product[]
}
const PrimesPanel = ({
    isOpen,
    closePanel,
    selectedYear,
    familyMemberIndex,
    lamal,
    lcas
}: DiscountsPanelProps): JSX.Element => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isMD, isXL } = useWindowSize()

    const [calculatorErrorBanner, setCalculatorErrorBanner] = useState<
        TranslationKey | TranslationKeyWithVariable
    >()

    const discountsLoaderStatus = useSelector(getDiscountsLoaderStatus)

    useEffect(() => {
        setCalculatorErrorBanner(undefined)
    }, [isOpen])

    return (
        <AssuraPanel
            type={'right'}
            isOpen={isOpen}
            size={isMD ? 69 : isXL ? 43 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <div className="primes-panel-container">
                {Boolean(calculatorErrorBanner) && (
                    <div className="primes-panel-calculator-banner-container">
                        <AssuraBanner
                            id="primes-panel-calculator-banner"
                            message={
                                typeof calculatorErrorBanner === 'object'
                                    ? t(calculatorErrorBanner.key, {
                                          ...calculatorErrorBanner.variableObject
                                      })
                                    : t(calculatorErrorBanner as string)
                            }
                            onClose={() => setCalculatorErrorBanner(undefined)}
                            variant="alert"
                        />
                    </div>
                )}
                <PrimesPanelHeader closePanel={closePanel} selectedYear={selectedYear} />
                <AssuraLoadAndError
                    status={discountsLoaderStatus}
                    defaultReloadAction={() => {
                        dispatch(
                            fetchDiscountsByMemberAndByYear({
                                index: familyMemberIndex,
                                year: selectedYear,
                                reload: true
                            })
                        )
                    }}
                >
                    <div className="primes-panel-body-container">
                        <PrimesPanelMemberInfo />
                        {lamal && (
                            <>
                                <div className="primes-panel-separator horizontal-separator bg-gray100" />
                                <PrimesPanelLamal lamal={lamal} />
                            </>
                        )}
                        <div className="primes-panel-separator horizontal-separator bg-gray100" />
                        {lcas && lcas.length > 0 && (
                            <PrimesPanelLcas lcas={lcas} selectedYear={selectedYear} />
                        )}
                        <PrimesPanelDiscountsInfo />
                    </div>
                    <PrimesPanelFooter setCalculatorErrorBanner={setCalculatorErrorBanner} />
                </AssuraLoadAndError>
            </div>
        </AssuraPanel>
    )
}

export default PrimesPanel
