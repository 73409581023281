import './SettingsCard.css'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { MissingDataType } from 'core/enums/MissingData'
import { ServiceTopicFront } from 'core/enums/ServiceTopic'
import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { PersonSettings } from 'shared/store/selectors/getFamilyGroupSettings'
import { goToServicesForm } from 'shared/store/services/services.slice'

interface SettingsCardProps {
    id: number
    settings: PersonSettings
}

const getMissingMessage = (missingDatas: MissingDataType[]): string | undefined => {
    if (missingDatas.length > 1) return 'SETTINGS.MISSING_DATA'
    if (missingDatas.length === 1) {
        switch (missingDatas[0]) {
            case MissingDataType.MARITAL_STATUS_MISSING:
                return 'SETTINGS.MISSING_MARITAL_STATUS'
            case MissingDataType.EMAIL_MISSING:
                return 'SETTINGS.MISSING_EMAIL'
            case MissingDataType.PHONE_NUMBER_MISSING:
                return 'SETTINGS.MISSING_PHONE'
            case MissingDataType.IBAN_MISSING:
                return 'SETTINGS.MISSING_IBAN'
            case MissingDataType.ADDRESS_MISSING:
                return 'SETTINGS.MISSING_ADDRESS'
            default:
                return undefined
        }
    }
    return undefined
}

const SettingsCard = ({ id, settings }: SettingsCardProps): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { firstName, policyNumber, lastName, profilePicture, missingDatas } = settings
    const missingMessage = getMissingMessage(missingDatas)

    const cardHeight = isXL ? 352 : 193
    const avatarSize = isXL ? 160 : 104

    const initials =
        firstName && lastName ? (firstName.charAt(0) + lastName.charAt(0)).toUpperCase() : ''

    const editAction = (policyNumber: number) => {
        if (!policyNumber) return
        navigate(`${navigationConstants.PERSONAL_DATA.url}?policy=${policyNumber}`)
    }
    return (
        <div
            className="settings-card-container bg-gray50 bc-gray100"
            style={{ height: `${cardHeight}px` }}
            data-testid={`settings-card-${id}`}
        >
            <div className="settings-card-data-container">
                <AssuraAvatar
                    id={`settings-card-avatar-${id}`}
                    mainContainerWidth={avatarSize}
                    avatarContainerWidth={avatarSize}
                    border={{
                        width: 4,
                        color: 'white'
                    }}
                >
                    {/* TODO: Update quand Photo avatar disponible */}
                    {profilePicture ? (
                        <img
                            data-testid="setting-avatar-image"
                            src={profilePicture.userPhotoImage}
                            alt="illustration"
                        />
                    ) : (
                        <div className={isXL ? 'headlineLargeMedium' : 'headlineMedium'}>
                            {initials}
                        </div>
                    )}
                </AssuraAvatar>
                <div className="settings-card-name-info-container">
                    <div
                        className="headlineSmallMedium"
                        data-testid={`settings-card-first-name-${id}`}
                    >
                        {firstName}
                    </div>

                    {missingMessage && (
                        <div
                            className="d-flex align-items-center justify-content-center c-primary"
                            style={{ marginTop: `${isXL ? 4 : 8}px` }}
                            data-testid={`settings-card-missingData-${id}`}
                        >
                            <i
                                data-testid={`settings-card-missingData-icon-${id}`}
                                className="icon assura-warning-circle size-16"
                            />
                            <span
                                data-testid={`settings-card-missingData-message-${id}`}
                                className="labelSmall m-left-8"
                            >
                                {t(missingMessage)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div
                className="settings-card-actions-container bc-gray100"
                data-testid={`settings-card-actions-container-${id}`}
            >
                <AssuraIconButton
                    id={`settings-card-remove-button-${id}`}
                    icon="assura-minus"
                    variant="primary"
                    size="medium"
                    onClick={() =>
                        dispatch(
                            goToServicesForm({
                                type: ServiceType.GENERAL_QUESTION,
                                query: {
                                    key: 'topic',
                                    value: ServiceTopicFront.PERSONAL_DATA
                                }
                            })
                        )
                    }
                    label={t('COMMON.REMOVE')}
                    classNames="settings-card-actions-button settings-card-actions-button-border brc-gray100"
                />
                <AssuraIconButton
                    id={`settings-card-edit-button-${id}`}
                    icon="assura-edit"
                    variant="primary"
                    size="medium"
                    onClick={() => editAction(policyNumber)}
                    label={t('COMMON.MODIFY')}
                    classNames="settings-card-actions-button"
                />
            </div>
        </div>
    )
}

export default SettingsCard
