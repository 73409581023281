import './GeneralQuestionGoodToKnow.css'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { setKeepSelectedMember } from 'shared/store/services/services.slice'

const GeneralQuestionGoodToKnow = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isFromWebsite = Source() === ServicesSource.WEBSITE

    const message = isFromWebsite
        ? t('SERVICES.GENERAL_QUESTION_GOOD_TO_KNOW_WEBSITE')
        : t('SERVICES.GENERAL_QUESTION_GOOD_TO_KNOW_EC')

    const handleNavigation = () => {
        dispatch(setKeepSelectedMember())
        navigate(navigationConstants.SERVICES.url)
    }

    return (
        <div className="white-space-pre-line">
            <div className="titleSmall">{t('COMMON.NEED_ASSISTANCE')}</div>
            <div className="paragraphMedium m-top-24">{t('ACCOUNT_CREATION.CALL')}</div>
            <div className="paragraphSmallLight">{t('CONTACT.SCHEDULE')}</div>
            <div className="general-good-to-know-separator horizontal-separator bg-gray100" />
            <div className="titleSmall">{t('COMMON.GOOD_TO_KNOW')}</div>
            <div className="paragraphSmallLight m-top-24">{message}</div>
            {!isFromWebsite && (
                <div className="gc-gtn-cta-frame d-flex justify-content-start m-top-24">
                    <AssuraButton
                        onClick={handleNavigation}
                        id="see-document-sample-button"
                        variant="secondary"
                        text={t('SERVICES.GO_TO_SERVICES')}
                    />
                </div>
            )}
        </div>
    )
}

export default GeneralQuestionGoodToKnow
