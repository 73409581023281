import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { RequestRow } from 'core/models/services/RequestData'
import useWindowSize from 'core/services/useWindowSize'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { getSelectedRequest } from 'shared/store/selectors/getSelectedRequest'
import {
    fetchRequestDetails,
    getSelectedRequestId,
    getServicesRequestDetailsLoadingStatus,
    setSelectedRequestId
} from 'shared/store/services/requests/requests.slice'

import getRequestsPanelComponent from '../getRequestsPanelComponent'
import RequestsPanelHeader from '../RequestsPanelHeader/RequestsPanelHeader'
import RequestsPanelMain from '../RequestsPanelMain/RequestsPanelMain'

interface RequestsPanelProps {
    selectedRequest: RequestRow
}
const RequestsPanel = ({ selectedRequest }: RequestsPanelProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMD, isXL } = useWindowSize()
    const dispatch = useDispatch()

    const selectedRequestId = useSelector(getSelectedRequestId)
    const selectedRequestDetails = useSelector(getSelectedRequest)
    const servicesRequestDetailsLoadingStatus = useSelector(getServicesRequestDetailsLoadingStatus)

    const closePanel = () => {
        dispatch(setSelectedRequestId(undefined))
    }

    const RequestsComponent = getRequestsPanelComponent(
        selectedRequestDetails?.type ?? ServiceType.UNKNOWN,
        selectedRequest,
        selectedRequestDetails?.values ?? []
    )

    const backOfficeUser = selectedRequestDetails?.backOfficeUser

    return (
        <AssuraPanel
            type="right"
            isOpen={selectedRequestId !== undefined}
            size={isMD ? 69 : isXL ? 43 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <>
                {selectedRequest && selectedRequestId && (
                    <>
                        <RequestsPanelHeader
                            selectedRequest={selectedRequest}
                            closePanel={closePanel}
                        />
                        <AssuraLoadAndError
                            status={servicesRequestDetailsLoadingStatus}
                            defaultReloadAction={() =>
                                dispatch(
                                    fetchRequestDetails({
                                        id: selectedRequestId,
                                        policyNumber: selectedRequest.policyNumber,
                                        reload: true
                                    })
                                )
                            }
                        >
                            <>
                                {backOfficeUser && backOfficeUser !== '' && (
                                    <div className="labelSmall p-top-32 p-left-32 p-right-32">{`${t(
                                        'SERVICES.BACK_OFFICE_USER'
                                    )} ${backOfficeUser}`}</div>
                                )}
                                <RequestsPanelMain
                                    title={RequestsComponent.title}
                                    category={RequestsComponent.category}
                                >
                                    {RequestsComponent.component}
                                </RequestsPanelMain>
                            </>
                        </AssuraLoadAndError>
                    </>
                )}
            </>
        </AssuraPanel>
    )
}

export default RequestsPanel
