import './AssuraTableLoadAndError.css'

import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import { LoadingStatusProps } from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'

interface AssuraTableLoadAndErrorProps extends LoadingStatusProps {
    children: React.ReactNode | React.ReactNode[]
}

const AssuraTableLoadAndError = ({
    status,
    defaultReloadAction,
    children
}: AssuraTableLoadAndErrorProps): JSX.Element => {
    const { t } = useTranslation()
    if (status === LoadingStatusEnum.OK) {
        return <>{children}</>
    } else {
        return (
            <tbody className="assura-table-load-and-error-body">
                <tr>
                    <td className="assura-table-load-and-error-content">
                        {status === LoadingStatusEnum.LOADING ? (
                            <ActivityIndicator size={40} />
                        ) : (
                            <>
                                <div
                                    data-testid="assura-table-load-and-error-reload"
                                    className="m-bottom-8 cursor-pointer"
                                    onClick={() => defaultReloadAction && defaultReloadAction()}
                                >
                                    <i className="icon assura-refresh size-32 c-primary" />
                                </div>
                                <div className="labelSmall">{t('ERROR_STATE.TITLE')}</div>
                            </>
                        )}
                    </td>
                </tr>
            </tbody>
        )
    }
}

export default AssuraTableLoadAndError
