import './ServicesPersonalDataTitle.css'

import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useWindowSize from 'core/services/useWindowSize'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { logout } from 'shared/store/authentication/authentication.slice'

const ServicesPersonalDataTitle = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const dispatch = useDispatch()

    return (
        <Container>
            <Row className="services-personal-data-title-container">
                <Col xs={12} md={8} className="services-personal-data-title">
                    <span className="title break-word" data-testid="services-personal-data-title">
                        {t('PERSONAL_DATA.TITLE')}
                    </span>
                </Col>
                <Col xs={12} md={4} className="services-personal-data-title-logout">
                    <AssuraButton
                        id="services-personal-data-title-logout"
                        onClick={() => dispatch(logout())}
                        text={t('MORE.LOGOUT')}
                        variant="primary"
                        fullWidth={isMobile}
                        icon={{
                            name: 'assura-logout',
                            size: 24
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default ServicesPersonalDataTitle
