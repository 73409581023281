import { PopinItem } from 'core/models/Popin'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface PopinState {
    selectedPopinItem: PopinItem | null
    extraData?: unknown
}

interface PopinItemPayload {
    popinItem: PopinItem
    extraData?: unknown
}

const INITIAL_STATE_POPIN: PopinState = {
    selectedPopinItem: null,
    extraData: undefined
}

export const popinSlice = createSlice({
    name: 'popin',
    initialState: INITIAL_STATE_POPIN,
    reducers: {
        setSelectedPopinItem: (state, action: PayloadAction<PopinItemPayload>) => {
            state.selectedPopinItem = action.payload.popinItem
            state.extraData = action.payload.extraData
        },
        resetSelectedPopinItem: (state) => {
            state.selectedPopinItem = null
        }
    }
})

export const { setSelectedPopinItem, resetSelectedPopinItem } = popinSlice.actions

export default popinSlice.reducer

export const getSelectedPopinItem: Selector<RootState, PopinItem | null> = (state) =>
    state.popin.selectedPopinItem

export const getPopinExtraData: Selector<RootState, unknown | undefined> = (state) =>
    state.popin.extraData
