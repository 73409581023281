import 'react-sliding-side-panel/lib/index.css'
import './AssuraPanel.css'

import { useEffect } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'

import useWindowSize from 'core/services/useWindowSize'

interface AssuraPanelProps {
    type: PanelType
    isOpen: boolean
    size: number
    panelContainerClassName: string
    backdropClicked: () => void
    children: React.ReactElement | null
    onPanelClosed?: () => void
}

const AssuraPanel = ({
    type,
    isOpen,
    size,
    panelContainerClassName,
    backdropClicked,
    onPanelClosed,
    children
}: AssuraPanelProps): JSX.Element => {
    const { height } = useWindowSize()

    useEffect(() => {
        const ref = document.getElementsByClassName('panel')[0] as HTMLDivElement | undefined
        if (ref && isOpen) ref.style.height = `${height}px`
    }, [height, isOpen])

    return (
        <RemoveScroll enabled={isOpen}>
            <SlidingPanel
                type={type}
                isOpen={isOpen}
                size={size}
                panelContainerClassName={panelContainerClassName}
                backdropClicked={backdropClicked}
                onClosed={onPanelClosed}
            >
                {children}
            </SlidingPanel>
        </RemoveScroll>
    )
}

export default AssuraPanel
