import { all, AllEffect, ForkEffect } from 'redux-saga/effects'

import AssetsSaga from './assets/assets.saga'
import AuthenticationSaga from './authentication/authentication.saga'
import BannersSaga from './banners/banners.saga'
import ClaimsSettlementSaga from './claimsSettlement/claimsSettlement.saga'
import CommunicationsSaga from './communications/communications.saga'
import DigcliParametersSaga from './digcliParameters/digcliParameters.saga'
import DocumentsSaga from './documents/documents.saga'
import EcLinksSaga from './ecLinks/ecLinks.saga'
import EnveloppeImagesSaga from './enveloppeImages/enveloppeImages.saga'
import EnveloppesSaga from './enveloppes/enveloppes.saga'
import FamilyMemberSaga from './familyMember/familyMember.saga'
import FamilyPicturesSaga from './familyPictures/familyPictures.saga'
import FamilySummariesSaga from './familySummaries/familySummaries.saga'
import FaqSaga from './faq/faq.saga'
import FinancialDocumentsSaga from './financialDocuments/financialDocuments.saga'
import GeneralSaga from './general/general.saga'
import GeneralQuestionTopicSaga from './generalQuestionTopic/generalQuestionTopic.saga'
import invoicesToCheckSaga from './invoicesToCheck/invoicesToCheck.saga'
import LocalitiesSaga from './localities/localities.saga'
import MissingDataSaga from './missingData/missingData.saga'
import NewsSaga from './news/news.saga'
import NotificationsSaga from './notifications/notifications.saga'
import ProductsSaga from './products/products.saga'
import QrCodesSaga from './qrCodes/qrCodes.saga'
import QuickAccessSaga from './quickAccess/quickAccess.saga'
import AccidentReport from './services/accidentReport/accidentReport.saga'
import ServicesAddressSaga from './services/address/address.saga'
import AventuraSaga from './services/aventura/aventura.saga'
import ServicesCertificatesSaga from './services/certificates/certificates.saga'
import ServicesContactSaga from './services/contact/contact.saga'
import ServicesContactInfosSaga from './services/contactInfos/contactInfos.saga'
import ServicesCoverageSaga from './services/coverage/coverage.saga'
import ServicesDoctorsSaga from './services/doctors/doctors.saga'
import ServicesFamilyGroupSaga from './services/familyGroup/familyGroup.saga'
import ServicesFinancialSaga from './services/financial/financial.saga'
import ServicesPeriodicitySaga from './services/financial/periodicity.saga'
import ServicesFrequentRequestsSaga from './services/frequentRequests/frequentRequests.saga'
import ServicesReportSaga from './services/report/report.saga'
import ServicesRequestsSaga from './services/requests/requests.saga'
import ServicesSaga from './services/services.saga'
import CredentialsSaga from './settings/credentials/credentials.saga'
import DeleteAccountSaga from './settings/deleteAccount/deleteAccount.saga'
import EcPlusmigrationSaga from './settings/ecPlusMigration/ecPlusMigration.saga'
import QualimedRemindSaga from './settings/qualiMedRemind/qualiMedRemind.saga'
import SplashScreenSaga from './splashScreen/splashScreen.saga'
import SymptomCheckerSaga from './symptomChecker/symptomChecker.saga'
import UnpaidStatsSaga from './unpaidStats/unpaidStats.saga'
import WhatsNewSaga from './whatsNew/whatsNew.saga'

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    const watchers = [
        ...AuthenticationSaga,
        ...AventuraSaga,
        ...AccidentReport,
        ...CredentialsSaga,
        ...FamilySummariesSaga,
        ...FamilyPicturesSaga,
        ...FamilyMemberSaga,
        ...EnveloppeImagesSaga,
        ...EnveloppesSaga,
        ...DocumentsSaga,
        ...FinancialDocumentsSaga,
        ...QrCodesSaga,
        ...ProductsSaga,
        ...LocalitiesSaga,
        ...NotificationsSaga,
        ...UnpaidStatsSaga,
        ...MissingDataSaga,
        ...CommunicationsSaga,
        ...GeneralSaga,
        ...GeneralQuestionTopicSaga,
        ...NewsSaga,
        ...AssetsSaga,
        ...BannersSaga,
        ...ClaimsSettlementSaga,
        ...FaqSaga,
        ...EcLinksSaga,
        ...ServicesSaga,
        ...ServicesContactInfosSaga,
        ...ServicesAddressSaga,
        ...ServicesFinancialSaga,
        ...ServicesPeriodicitySaga,
        ...ServicesCertificatesSaga,
        ...ServicesDoctorsSaga,
        ...ServicesCoverageSaga,
        ...ServicesReportSaga,
        ...ServicesRequestsSaga,
        ...ServicesContactSaga,
        ...ServicesFamilyGroupSaga,
        ...QuickAccessSaga,
        ...DigcliParametersSaga,
        ...EcPlusmigrationSaga,
        ...ServicesFrequentRequestsSaga,
        ...DeleteAccountSaga,
        ...invoicesToCheckSaga,
        ...QualimedRemindSaga,
        ...WhatsNewSaga,
        ...SplashScreenSaga,
        ...SymptomCheckerSaga
    ]

    yield all(watchers)
}
