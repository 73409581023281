import { DocumentsGroupEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    CommonDocumentsState,
    Document,
    DocumentsData,
    DocumentsDataState,
    DocumentsFiltersState,
    DocumentsGroup,
    FetchDocumentsPayload,
    SetSelectedDocumentIdPayload
} from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { ReadDocument } from 'core/models/documents/UnReadDocuments'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export const defaultFinancialDocumentsFiltersState: DocumentsFiltersState = {
    utn: null,
    date: null
}

export interface FinancialDocumentsState extends CommonDocumentsState {
    selectedDocumentsGroup: DocumentsGroup
    invoicesData: DocumentsDataState
    refundsData: DocumentsDataState
}

export interface FinancialDocumentInitLoadingStatus {
    selectedDocumentsGroup: DocumentsGroup
    initLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_FINANCIAL_DOCUMENTS: FinancialDocumentsState = {
    selectedDocumentsGroup: DocumentsGroupEnum.INVOICES,
    selectedDocument: null,
    filteringLoadingStatus: LoadingStatusEnum.OK,
    paginatingLoadingStatus: LoadingStatusEnum.OK,
    invoicesData: {
        initLoadingStatus: LoadingStatusEnum.OK,
        documents: [],
        hasMore: false,
        paginationStartDate: null
    },
    refundsData: {
        initLoadingStatus: LoadingStatusEnum.OK,
        documents: [],
        hasMore: false,
        paginationStartDate: null
    }
}

export const financialDocumentsSlice = createSlice({
    name: 'financialDocuments',
    initialState: INITIAL_STATE_FINANCIAL_DOCUMENTS,
    reducers: {
        setSelectedDocumentsGroup: (
            state: FinancialDocumentsState,
            action: PayloadAction<DocumentsGroup>
        ) => {
            state.selectedDocumentsGroup = action.payload
        },
        setSelectedFinancialDocumentId: (
            state: FinancialDocumentsState,
            _action: PayloadAction<SetSelectedDocumentIdPayload>
        ) => {
            return state
        },
        setSelectedDocument: (
            state: FinancialDocumentsState,
            action: PayloadAction<DocumentRow | null>
        ) => {
            state.selectedDocument = action.payload
        },
        fetchInvoicesData: (
            state: FinancialDocumentsState,
            _action: PayloadAction<FetchDocumentsPayload>
        ) => {
            return state
        },
        fetchRefundsData: (
            state: FinancialDocumentsState,
            _action: PayloadAction<FetchDocumentsPayload>
        ) => {
            return state
        },
        seInvoicesData: (state: FinancialDocumentsState, action: PayloadAction<DocumentsData>) => {
            state.invoicesData = {
                ...action.payload,
                initLoadingStatus: state.invoicesData.initLoadingStatus
            }
        },
        setRefundsData: (state: FinancialDocumentsState, action: PayloadAction<DocumentsData>) => {
            state.refundsData = {
                ...action.payload,
                initLoadingStatus: state.refundsData.initLoadingStatus
            }
        },
        setInitLoadingStatus: (
            state: FinancialDocumentsState,
            action: PayloadAction<FinancialDocumentInitLoadingStatus>
        ) => {
            if (action.payload.selectedDocumentsGroup === DocumentsGroupEnum.INVOICES) {
                state.invoicesData.initLoadingStatus = action.payload.initLoadingStatus
            }
            if (action.payload.selectedDocumentsGroup === DocumentsGroupEnum.REFUNDS) {
                state.refundsData.initLoadingStatus = action.payload.initLoadingStatus
            }
        },
        setFilteringLoadingStatus: (
            state: FinancialDocumentsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.filteringLoadingStatus = action.payload
        },
        setPaginatingLoadingStatus: (
            state: FinancialDocumentsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.paginatingLoadingStatus = action.payload
        },
        setFinancialDocumentToReadStatus: (
            state: FinancialDocumentsState,
            action: PayloadAction<ReadDocument>
        ) => {
            const { documentId, documentsGroup } = action.payload

            if (documentsGroup === DocumentsGroupEnum.INVOICES) {
                const selectedDocumentIndex = state.invoicesData.documents.findIndex(
                    (doc) => doc.id === documentId
                )
                if (selectedDocumentIndex !== -1) {
                    state.invoicesData.documents.splice(selectedDocumentIndex, 1, {
                        ...state.invoicesData.documents[selectedDocumentIndex],
                        isRead: true
                    })
                }
            }
            if (documentsGroup === DocumentsGroupEnum.REFUNDS) {
                const selectedDocumentIndex = state.refundsData.documents.findIndex(
                    (doc) => doc.id === documentId
                )
                if (selectedDocumentIndex !== -1) {
                    state.refundsData.documents.splice(selectedDocumentIndex, 1, {
                        ...state.refundsData.documents[selectedDocumentIndex],
                        isRead: true
                    })
                }
            }
        },
        reset: () => INITIAL_STATE_FINANCIAL_DOCUMENTS
    }
})

export const {
    setSelectedDocumentsGroup,
    setSelectedFinancialDocumentId,
    setSelectedDocument,
    fetchInvoicesData,
    fetchRefundsData,
    seInvoicesData,
    setRefundsData,
    setInitLoadingStatus,
    setFilteringLoadingStatus,
    setPaginatingLoadingStatus,
    setFinancialDocumentToReadStatus,
    reset
} = financialDocumentsSlice.actions

export const getSelectedFinancialDocumentsGroup: Selector<RootState, DocumentsGroup> = (
    state: RootState
) => state.financialDocuments.selectedDocumentsGroup

export const getSelectedFinancialDocument: Selector<RootState, DocumentRow | null> = (
    state: RootState
) => state.financialDocuments.selectedDocument

export const getInvoicesData: Selector<RootState, DocumentsDataState> = (state: RootState) =>
    state.financialDocuments.invoicesData

export const getRefundsData: Selector<RootState, DocumentsDataState> = (state: RootState) =>
    state.financialDocuments.refundsData

export const getInvoicesDocuments: Selector<RootState, Document[]> = (state: RootState) =>
    state.financialDocuments.invoicesData.documents

export const getRefundsDocuments: Selector<RootState, Document[]> = (state: RootState) =>
    state.financialDocuments.refundsData.documents

export const getFinancialDocumentsFilteringLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.financialDocuments.filteringLoadingStatus

export const getFinancialDocumentsPaginatingLoadingStatus: Selector<
    RootState,
    LoadingStatusEnum
> = (state: RootState) => state.financialDocuments.paginatingLoadingStatus

export default financialDocumentsSlice.reducer
