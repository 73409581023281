import { useSelector } from 'react-redux'

import { RequestStatus } from 'core/enums/RequestStatus'
import { getDateFilterSelections } from 'core/helpers/CommonAssuraTableFilters'
import { TableFilterSelections, TableFilterState } from 'core/models/TableFilter'
import { getPolicyNumberSelectionsForFilter } from 'shared/store/selectors/getPolicyNumberSelectionsForFilter'

import { RequestsFilterSelectionValue } from './RequestsTable'

const getRequestsFilterState = (): TableFilterState<RequestsFilterSelectionValue> => {   
    const filters: TableFilterState<RequestsFilterSelectionValue> = {}

    filters['date'] = {
        label: 'DOCUMENTS.FILTER_BY_YEAR',
        selections: getDateFilterSelections()
    }

    const memberSelections = useSelector(getPolicyNumberSelectionsForFilter)
    if (memberSelections)
        filters['member'] = {
            label: 'COMMON_INSURED',
            selections: memberSelections
        }

    const statusSelections: TableFilterSelections<RequestStatus> = new Map([
        [
            RequestStatus.PENDING,
            {
                label: 'STATUS.IN_PROGRESS',
                value: RequestStatus.PENDING,
                isSelected: false,
                shouldBeTranslated: true
            }
        ],
        [
            RequestStatus.COMPLETED,
            {
                label: 'STATUS.COMPLETED_REQUEST',
                value: RequestStatus.COMPLETED,
                isSelected: false,
                shouldBeTranslated: true
            }
        ],
        [
            RequestStatus.CANCELLED,
            {
                label: 'STATUS.CANCELLED',
                value: RequestStatus.CANCELLED,
                isSelected: false,
                shouldBeTranslated: true
            }
        ]
    ])
    filters['status'] = {
        label: 'DOCUMENTS.FILTER_BY_STATUS',
        selections: statusSelections
    }

    return filters
}

export default getRequestsFilterState
