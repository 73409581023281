import './TabDropdownSwitch.css'

import { useEffect, useState } from 'react'

import { AssuraTabData, ItemMetaData } from 'core/models/TabData'

import { TabDropdownDrawer } from './TabDropdownDrawer'
import TabDropdownItem from './TabDropdownItem'
import TabToggleButton from './TabToggleButton'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const defaultToggle = (
    currentSelectedItem?: AssuraTabData,
    valueTemplate?: (data?: AssuraTabData) => React.ReactElement,
    onShow?: (show: boolean) => void,
    show?: boolean
) => {
    return (
        <div
            className="tab-dropdown-display-item"
            id="generic-dropdown"
            data-testid="generic-dropdown"
            onClick={() => onShow!(!show)}
        >
            <div
                className={`tab-dropdown-display-item-container d-flex align-items-center headlineSmallMedium`}
            >
                {valueTemplate ? (
                    valueTemplate(currentSelectedItem)
                ) : (
                    <TabDropdownItem data={currentSelectedItem ?? ({} as AssuraTabData)} />
                )}
                <TabToggleButton onShow={onShow!} show={show!} />
            </div>
        </div>
    )
}

export type TabDropdownSwitchProps = {
    // the inner data structure
    data: AssuraTabData[]
    currentSelectedItem?: AssuraTabData
    dataId: string

    // overrides the default label item with toggle button.
    // to create a new one please refers to component: displayDefault
    labelToggleTemplate?: (
        data?: AssuraTabData,
        valueTemplate?: (data?: AssuraTabData) => React.ReactElement,
        onClick?: (show: boolean) => void,
        isVisible?: boolean
    ) => React.ReactElement

    // overrides the default item component.
    // to create a new one please refers to component: DropdownItem
    itemTemplate?: (data?: AssuraTabData) => React.ReactElement

    // overrides the inner item components.
    // ------------------------------------
    // the inner item value template.
    valueTemplate?: (data?: AssuraTabData) => React.ReactElement

    // the inner left item artifact template.
    // icon / initials / badge / avatar
    leftTemplate?: (data?: ItemMetaData) => React.ReactElement

    // the inner right item artifact template.
    // icon / initials / badge / avatar
    rightTemplate?: (data?: ItemMetaData) => React.ReactElement

    // selection item
    select: (id: AssuraTabData['uniqueKey']) => void

    // drawer is open
    isOpen: (open: boolean) => void
    opened: boolean
}

// As I do not want to display anything on left side for drawer items
const customLeftTemplate = () => <></>

// As I do not want to display the same text format in the drop down items
const customDisplayTemplate = (data?: AssuraTabData) => (
    <span
        className={'tab-dropdown-item-value-container max-2-text-lines ellipsis labelSmall'}
        data-testid={`${data?.uniqueKey}-dropdown-value-item`}
    >
        {data?.label}
    </span>
)

export const TabDropdownSwitch = ({
    data,
    currentSelectedItem,
    dataId,
    labelToggleTemplate,
    itemTemplate,
    valueTemplate,
    leftTemplate,
    rightTemplate,
    select,
    isOpen,
    opened
}: TabDropdownSwitchProps) => {
    const [show, setShow] = useState(opened)
    const [mountDrawer, setMountDrawer] = useState(opened)

    const onShow = (show: boolean) => {
        setShow(show)
        isOpen(show)
    }
    const onSelect = (id: AssuraTabData['uniqueKey']) => {
        setShow(!show)
        isOpen(!show)
        setMountDrawer(false)
        select(id)
    }

    useEffect(() => {
        if (opened) return
        setShow(opened)
    }, [opened])

    useEffect(() => {
        if (mountDrawer === show) return
        if (show) {
            setMountDrawer(show)
            return
        }
        setTimeout(() => {
            setMountDrawer(false)
        }, 90)
    }, [show])

    return (
        <>
            {labelToggleTemplate
                ? labelToggleTemplate(currentSelectedItem, valueTemplate, onShow, show)
                : defaultToggle(currentSelectedItem, valueTemplate, onShow, show)}

            {mountDrawer && (
                <TabDropdownDrawer
                    currentSelectedItemId={currentSelectedItem!.uniqueKey}
                    show={show}
                    data={data}
                    dataId={dataId}
                    select={onSelect}
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate ? valueTemplate : customDisplayTemplate}
                    leftTemplate={leftTemplate ? leftTemplate : customLeftTemplate}
                    rightTemplate={rightTemplate}
                />
            )}
        </>
    )
}
