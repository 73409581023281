import { createSelector } from '@reduxjs/toolkit'

import {
    getSelectedClaimsSettlement,
    getSelectedReceiptIndex
} from '../claimsSettlement/claimsSettlement.slice'

export const getSelectedClaimsSettlementReceipt = createSelector(
    [getSelectedClaimsSettlement, getSelectedReceiptIndex],
    (selectedClaimSettlement, selectedReceiptIndex) => {
        if (selectedClaimSettlement && selectedReceiptIndex !== null) {
            return selectedClaimSettlement.documents[selectedReceiptIndex]
        } else {
            return undefined
        }
    }
)
