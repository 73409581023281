import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { WhatsNew, WhatsNewCmsFiltered, WhatsNewPanel } from 'core/models/WhatsNew'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getWhatsNew } from '../whatsNew/whatsNew.slice'

export const getSelectedWhatsNew = createSelector([getWhatsNew, getLang], (whatsNew, lang) => {
    if (whatsNew) {
        const filteredWhatsNew = getLocalizedObject(whatsNew, lang) as WhatsNewCmsFiltered

        const panels = [...filteredWhatsNew.panels]
            .sort((a, b) => parseInt(a.value.order) - parseInt(b.value.order))
            .map(
                (panel) =>
                    ({
                        title: panel.value.title,
                        description: panel.value.description,
                        image: panel.value.image?.path,
                        icon: panel.value.icon
                    }) as WhatsNewPanel
            )

        return {
            id: filteredWhatsNew.id,
            validity_start: filteredWhatsNew.validity_start,
            validity_end: filteredWhatsNew.validity_end,
            date: filteredWhatsNew.date,
            cta_text: filteredWhatsNew.cta_text,
            link_ecl: filteredWhatsNew.link_ecl,
            panels
        } as WhatsNew
    } else {
        return null
    }
})
