import './HomeBlock.css'

import { ReactNode } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraTableLoadAndError from 'shared/components/AssuraTable/AssuraTableLoadAndError/AssuraTableLoadAndError'

interface HomeBlockProps {
    title: string
    testId: string
    loadingStatus: LoadingStatusEnum
    children: ReactNode
    reloadAction: () => void
    headerAction?: () => void
}

const HomeBlock = ({
    title,
    testId,
    loadingStatus,
    children,
    reloadAction,
    headerAction
}: HomeBlockProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Col xs={12} md={6} xl={4} className="home-block-col">
            <div className="home-block">
                <div className="home-block-header">
                    <div
                        className="home-block-header-title headlineSmallMedium"
                        data-testid={`home-block-${testId}-title`}
                    >
                        {title}
                    </div>
                    {headerAction && loadingStatus === LoadingStatusEnum.OK && (
                        <div className="home-block-action">
                            <AssuraIconButton
                                id={`home-block-${testId}-show-all`}
                                icon="assura-next"
                                variant="primary"
                                size="medium"
                                onClick={headerAction}
                                label={t('COMMON.SEE_ALL')}
                            />
                        </div>
                    )}
                </div>
                <table className="home-block-table">
                    <AssuraTableLoadAndError
                        status={loadingStatus}
                        defaultReloadAction={reloadAction}
                    >
                        <tbody className="home-block-body">{children}</tbody>
                    </AssuraTableLoadAndError>
                </table>
            </div>
        </Col>
    )
}
export default HomeBlock
