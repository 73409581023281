import { openInNewTab } from 'core/utils/onClickUtils'

export interface AssuraNewTabLinkProps {
    testId?: string
    title?: string
    url: string
    classNames?: string
    icon?: {
        size: number
        name: string
    }
    callback?: (href: string) => void
}

const AssuraNewTabLink = ({
    testId,
    title,
    url,
    classNames,
    icon,
    callback
}: AssuraNewTabLinkProps): JSX.Element => {
    let currentTestId = 'assura-new-tab-link'
    if (testId) currentTestId = `${currentTestId}-${testId}`

    const onClick = (): void => {
        openInNewTab(url)
        if (callback) {
            callback(url)
        }
    }
    const content = (): JSX.Element => {
        if (icon && title)
            return (
                <div className="d-flex align-items-center">
                    <i
                        className={`icon size-${icon.size} ${icon.name}`}
                        data-testid={`${currentTestId}-icon`}
                    />
                    <span className="m-left-8">{title}</span>
                </div>
            )
        if (icon) return <i className={`icon size-${icon.size} ${icon.name}`} />
        return <span>{title}</span>
    }

    const classNamesParams = classNames ? ` ${classNames}` : ''

    return (
        <div
            className={`cursor-pointer${classNamesParams}`}
            onClick={onClick}
            data-testid={currentTestId}
        >
            {content()}
        </div>
    )
}

export default AssuraNewTabLink
