import { AppSearchCategoryIcons } from 'core/constants/appSearch'
import navigationConstants from 'core/constants/navigationConstants'
import { AppSearchResult } from 'core/models/AppSearch'

import { createSelector } from '@reduxjs/toolkit'

import { getEcLinks } from '../ecLinks/ecLinks.slice'
import { getFaqQuestions } from './getFaqData'

export const getFaqForAppSearch = createSelector(
    [getFaqQuestions, getEcLinks],
    (faqCms, ecLinks) => {
        const faqData: AppSearchResult[] = []

        for (const faq of faqCms.values()) {
            for (const item of faq) {
                const link = `${navigationConstants.FAQ.url}?category=${item.faqCategory.id}&question=${item.id}`
                const currentEcLink = ecLinks.find(e => e.link_url === link)
                faqData.push({
                    label: item.question,
                    link,
                    icon: AppSearchCategoryIcons.FAQ,
                    suggestionOrder: null,
                    ecLinkId: currentEcLink?._id
                })
            }
        }

        return faqData
    }
)
