import { call, fork, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects'

import { goodToKnowCmsData } from 'core/api/Cms'
import { setDeviceInfoHeaders } from 'core/api/Helper'
import { getAventuraPricingAsyncApi } from 'core/api/services/Aventura'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants, { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ToastType } from 'core/enums/ToastType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    getFormRoute,
    getWSToCallsForFormByServiceTypeAndSource
} from 'core/helpers/services/ServicesFormHelper'
import { SelectedServiceForm } from 'core/models/analytics/analytics'
import { GoodToKnowCms } from 'core/models/cms/GoodToKnowCms'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { AventuraPriceDetailsModel } from 'core/models/services/coverage/AventuraForm'
import { getCurrentYear } from 'core/utils/dateUtils'
import i18next from 'i18next'
import { globalNavigate } from 'shared/components/GlobalHistory/GlobalHistory'

import { removeAllNonPermanentBanners, setBanner } from '../banners/banners.slice'
import {
    fetchProductsByMemberAndByYearAsync,
    fetchSummaryByYearAsync
} from '../familySummaries/familySummaries.saga'
import { fetchProductsByMemberAndByYear } from '../familySummaries/familySummaries.slice'
import { getPath, setLocationPathname } from '../general/general.slice'
import { fetchMissingDataSaga } from '../missingData/missingData.saga'
import { getConnectedFamilyMember } from '../selectors/getConnectedFamilyMember'
import { getSummaryCurrentYear } from '../selectors/getSummaryCurrentYear'
import { setToastInfo } from '../toast/toast.slice'
import { setPriceData } from './aventura/aventura.slice'
import { initContactInfos } from './contactInfos/contactInfos.saga'
import { fetchContactInfos } from './contactInfos/contactInfos.slice'
import { initContractDoctors } from './doctors/doctors.saga'
import { fetchPeriodicitySaga } from './financial/periodicity.saga'
import { getRequestsSaga } from './requests/requests.saga'
import {
    fetchContactDataAndProducts,
    fetchGoodToKnowCms,
    fetchSummaryAndProducts,
    formSubmitted,
    formSubmittedWithCustomMessage,
    getSelectedServiceForm,
    goToServicesForm,
    setFamilyGroupResult,
    setFormDataLoadingStatus,
    setGoodToKnowCms,
    setKeepSelectedMember,
    setSelectedServiceForm,
    setSubmitServiceStatus
} from './services.slice'

export function* initSelectedPolicyNumber() {
    try {
        const connectedFamilyMember: ConnectedFamilyMember = yield select(getConnectedFamilyMember)

        yield put(
            setFamilyGroupResult({
                selectedPolicyNumber: connectedFamilyMember?.policyNumber,
                isAllFamilyMembers: false
            })
        )
    } catch (e) {
        console.error('initSelectedPolicyNumber Error', e)
        throw e
    }
}

function* goToServicesFormSaga(action: ReturnType<typeof goToServicesForm>) {
    try {
        const { type, query } = action.payload

        yield put(setFormDataLoadingStatus(LoadingStatusEnum.LOADING))
        const formRoute = getFormRoute(type)
        const queryToUse = query ? `?${query.key}=${query.value}` : ''
        globalNavigate(`${formRoute}${queryToUse}`)

        const wsToCalls = getWSToCallsForFormByServiceTypeAndSource(type)

        if (wsToCalls.shouldUpdateDeviceInfoHeaders) {
            yield call(setDeviceInfoHeaders)
        }
        if (wsToCalls.shouldFetchSummary) {
            yield call<typeof fetchSummaryByYearAsync>(fetchSummaryByYearAsync, {
                type: 'familySummaries/fetchSummaryByYear',
                payload: {
                    year: getCurrentYear(),
                    shouldFetchProducts: wsToCalls.shouldFetchProducts,
                    shouldThrow: true
                }
            })
        }
        yield call(initSelectedPolicyNumber)
        if (wsToCalls.shouldFetchContactData) {
            yield call(initContactInfos)
        }
        if (wsToCalls.shouldFetchDoctors) {
            yield call(initContractDoctors)
        }
        if (wsToCalls.shouldFetchRequests) {
            yield call<typeof getRequestsSaga>(getRequestsSaga, {
                type: 'requests/getRequests',
                payload: { shouldThrow: true }
            })
        }
        if (wsToCalls.shouldFetchPeriodicity) {
            yield call(fetchPeriodicitySaga)
        }
        if (wsToCalls.shouldFetchAventuraPricings) {
            // Will fetch princings for aventura.
            const data: AventuraPriceDetailsModel[] | undefined = yield call(
                getAventuraPricingAsyncApi
            )
            // then, transform them to well structured data and inserted in the store.
            if (
                !data ||
                (Array.isArray(data) &&
                    (data as unknown as AventuraPriceDetailsModel[]).length === 0)
            ) {
                throw 'aventura pricing was not properly loaded.'
            }
            yield put(setPriceData(data))
        }
        if (wsToCalls.shouldFetchMissingData) {
            yield call(fetchMissingDataSaga)
        }
        yield put(setFormDataLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setFormDataLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('goToServicesForm Error', e)
    }
}

function* formSubmittedSaga(action: ReturnType<typeof formSubmitted>) {
    try {
        const { form_category, request_purpose } = action.payload
        const selectedServiceFormData: SelectedServiceForm = yield select(getSelectedServiceForm)

        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND, {
            form_category,
            request_purpose,
            ...selectedServiceFormData
        })

        if (Source() !== ServicesSource.WEBSITE) {
            yield put(
                setToastInfo({
                    message: i18next.t(
                        action.payload.toastMessage ?? 'SERVICES.SUBMISSION_SUCCESS'
                    ),
                    type: ToastType.SUCCESS
                })
            )

            globalNavigate(action.payload.redirectUrl ?? `${navigationConstants.REQUESTS.url}`, {
                state: {
                    origin: 'form'
                }
            })
        } else {
            globalNavigate(action.payload.websiteRedirectUrl ?? SERVICES_FORM_ROUTES.SUCCESS.url)
        }

        if (action.payload.errorMessage) {
            yield put(
                setBanner({
                    dataTestId: 'request-banner-error',
                    message: action.payload.errorMessage
                })
            )
        }
    } catch (e) {
        console.error('formSubmittedSaga Error', e)
    }
}

function* formSubmittedWithCustomMessageSaga(
    action: ReturnType<typeof formSubmittedWithCustomMessage>
) {
    try {
        if (Source() !== ServicesSource.WEBSITE && action.payload) {
            yield put(
                setToastInfo({
                    message: i18next.t(action.payload?.toastMessage),
                    type: ToastType.SUCCESS
                })
            )
            globalNavigate(
                action.payload.redirectRouteName ?? `${navigationConstants.REQUESTS.url}`,
                {
                    state: {
                        origin: 'form'
                    }
                }
            )
        } else {
            globalNavigate(SERVICES_FORM_ROUTES.SUCCESS.url)
        }
    } catch (e) {
        console.error('formSubmittedSaga Error', e)
    }
}

export function* fetchContactDataAndProductsSaga(
    action: ReturnType<typeof fetchContactDataAndProducts>
) {
    try {
        const { policyNumber, reload } = action.payload
        const summaryCurrentYear: SummaryResult = yield select(getSummaryCurrentYear)
        const memberList = summaryCurrentYear.insuredPersons
        const policyNumberIndex = memberList.findIndex(
            (person) => person.policyNumber === policyNumber
        )
        yield put(
            fetchProductsByMemberAndByYear({
                year: getCurrentYear(),
                index: policyNumberIndex,
                reload
            })
        )
        yield put(
            fetchContactInfos({
                selectedPolicyNumber: policyNumber,
                reload
            })
        )
    } catch (e) {
        console.error('fetchContactDataAndProductsSaga Error', e)
    }
}

export function* fetchSummaryAndProductsSaga(action: ReturnType<typeof fetchSummaryAndProducts>) {
    try {
        const { policyNumber, year, reload } = action.payload
        const summaryCurrentYear: SummaryResult = yield select(getSummaryCurrentYear)
        const memberList = summaryCurrentYear.insuredPersons
        const policyNumberIndex = memberList.findIndex(
            (person) => person.policyNumber === policyNumber
        )
        yield call<typeof fetchSummaryByYearAsync>(fetchSummaryByYearAsync, {
            type: 'familySummaries/fetchSummaryByYear',
            payload: {
                year: year,
                shouldFetchProducts: false,
                reload,
                shouldThrow: true
            }
        })
        yield call<typeof fetchProductsByMemberAndByYearAsync>(
            fetchProductsByMemberAndByYearAsync,
            {
                type: 'familySummaries/fetchProductsByMemberAndByYear',
                payload: {
                    year: year,
                    index: policyNumberIndex,
                    reload,
                    shouldThrow: true
                }
            }
        )
    } catch (e) {
        console.error('fetchSummaryAndProductsSaga Error', e)
    }
}

export function* fetchGoodToKnowCmsSaga() {
    try {
        const goodToKnowCms: GoodToKnowCms[] = yield call(goodToKnowCmsData)
        yield put(setGoodToKnowCms(goodToKnowCms))
    } catch (e) {
        console.error('fetchGoodToKnowCmsSaga Error', e)
    }
}

export function* setSubmitServiceStatusSaga(action: ReturnType<typeof setSubmitServiceStatus>) {
    try {
        if (action.payload === LoadingStatusEnum.ERROR) {
            yield put(
                setBanner({
                    dataTestId: 'services-form-error',
                    message: 'SERVICES.SUBMISSION_ERROR'
                })
            )
        } else {
            yield put(removeAllNonPermanentBanners())
        }
    } catch (e) {
        console.log('setSubmitServiceStatusSaga Error', e)
    }
}
// clears contact form context data: selected user (keeps user selected in the selected form) and analytics purposes infos.
// this data is used when user is redirected to a slf form
// we need to clear them once it's submitted or the route changes
function* resetControlFormData() {
    try {
        const currentPath: string = yield select(getPath)
        const selectedServiceForm: SelectedServiceForm = yield select(getSelectedServiceForm)

        if (selectedServiceForm && selectedServiceForm?.target_form !== currentPath) {
            yield put(setKeepSelectedMember())
            yield put(setSelectedServiceForm())
        }
    } catch (e) {
        console.error('resetControlFormData Error', e)
    }
}

// we use the setLocationPathname event to trigger the above cleansing
function* resetControlFormDataWatcher() {
    yield takeEvery(setLocationPathname.type, resetControlFormData)
}

function* goToServicesFormWatcher() {
    yield takeEvery(goToServicesForm.type, goToServicesFormSaga)
}

function* formSubmittedFormWatcher() {
    yield takeEvery(formSubmitted.type, formSubmittedSaga)
}

function* formSubmittedWithCustomMessageFormWatcher() {
    yield takeEvery(formSubmittedWithCustomMessage.type, formSubmittedWithCustomMessageSaga)
}

function* fetchContactDataAndProductsWatcher() {
    yield takeEvery(fetchContactDataAndProducts.type, fetchContactDataAndProductsSaga)
}

function* fetchSummaryAndProductsWatcher() {
    yield takeEvery(fetchSummaryAndProducts.type, fetchSummaryAndProductsSaga)
}

function* fetchGoodToKnowCmsWatcher() {
    yield takeEvery(fetchGoodToKnowCms.type, fetchGoodToKnowCmsSaga)
}

function* fetchSetSubmitServiceStatusWatcher() {
    yield takeLatest(setSubmitServiceStatus.type, setSubmitServiceStatusSaga)
}

const watchers = [
    fork(goToServicesFormWatcher),
    fork(formSubmittedFormWatcher),
    fork(formSubmittedWithCustomMessageFormWatcher),
    fork(fetchContactDataAndProductsWatcher),
    fork(fetchSummaryAndProductsWatcher),
    fork(fetchGoodToKnowCmsWatcher),
    fork(fetchSetSubmitServiceStatusWatcher),
    fork(resetControlFormDataWatcher)
]

export default watchers
