import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { splashScreenCmsData } from 'core/api/Cms'
import { SplashScreenCms } from 'core/models/cms/SplashScreenCms'

import { fetchSplashScreenCms, fetchSplashScreenCmsSuccess } from './splashScreen.slice'

function* fetchSplashScreenCmsSaga() {
    try {
        const splashScreenData: SplashScreenCms[] = yield call(splashScreenCmsData)

        yield put(fetchSplashScreenCmsSuccess(splashScreenData))
    } catch (e) {
        console.error('fetchSplashScreenCmsSaga Error', e)
    }
}

function* fetchSplashScreenCmsWatcher() {
    yield takeEvery(fetchSplashScreenCms.type, fetchSplashScreenCmsSaga)
}

const watchers = [fork(fetchSplashScreenCmsWatcher)]

export default watchers
