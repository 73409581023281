import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FetchDocumentsTypeEnum } from 'core/enums/Documents'
import { mapDocumentsSelectionsToState } from 'core/helpers/CommonAssuraTableFilters'
import getDocumentsFilterState from 'core/helpers/documents/DocumentsFilterState'
import {
    DocumentsFilterSelectionValue,
    DocumentsGroup,
    FetchDocumentsType
} from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { TableFilterState } from 'core/models/TableFilter'
import AssuraTable from 'shared/components/AssuraTable/AssuraTable'
import {
    fetchCommunications,
    getCommunicationsState,
    setSelectedCommunicationId
} from 'shared/store/communications/communications.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getSortedCommunicationsForTable } from 'shared/store/selectors/getSortedCommunicationsForTable'
import { useAppDispatch } from 'shared/store/store'

import CommunicationsPanel from '../CommunicationsPanel/CommunicationsPanel'
import CommunicationsRow from '../CommunicationsRow/CommunicationsRow'

interface CommunicationsTableProps {
    documentsGroup: DocumentsGroup
}

const CommunicationsTable = ({ documentsGroup }: CommunicationsTableProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const lang = useSelector(getLang)
    const communicationsState = useSelector(getCommunicationsState)

    const {
        hasMore,
        initLoadingStatus,
        filteringLoadingStatus,
        paginatingLoadingStatus,
        paginationStartDate
    } = communicationsState

    const sortedCommunications = useSelector(getSortedCommunicationsForTable) as DocumentRow[]

    const initialFilters = getDocumentsFilterState()

    const [filters, setFilters] = useState<TableFilterState<DocumentsFilterSelectionValue>>({
        ...initialFilters
    })

    const isInitialisation = useRef(true)

    const onFilterSelection = (newFilterState: TableFilterState<DocumentsFilterSelectionValue>) => {
        setFilters({ ...newFilterState })
    }

    const onRowSelection = (row: DocumentRow) => {
        dispatch(setSelectedCommunicationId(row.documentId))
    }

    const getCommunications = (type: FetchDocumentsType) => {
        const filterState = mapDocumentsSelectionsToState(filters)

        dispatch(
            fetchCommunications({
                selectedDocumentsGroup: documentsGroup,
                type,
                paginationDate: paginationStartDate,
                filters: filterState
            })
        )
    }

    useEffect(() => {
        getCommunications(
            isInitialisation.current
                ? FetchDocumentsTypeEnum.INIT
                : FetchDocumentsTypeEnum.FILTERING
        )
        isInitialisation.current = false
    }, [filters, lang])

    return (
        <>
            <div className="side-navigation-content-table">
                <AssuraTable
                    id={documentsGroup}
                    rows={sortedCommunications}
                    rowComponent={(row) => <CommunicationsRow row={row} />}
                    filters={filters}
                    loadStatus={initLoadingStatus}
                    paginationStatus={paginatingLoadingStatus}
                    filteringStatus={filteringLoadingStatus}
                    onReload={() => getCommunications(FetchDocumentsTypeEnum.INIT)}
                    onFilterChange={onFilterSelection}
                    onRowSelection={onRowSelection}
                    noResultMessages={{
                        datas: { label: t('COMMUNICATIONS.NO_DOCUMENTS') },
                        filters: { label: t('INBOX.EMPTY_VIEW_FILTERS_TITLE') }
                    }}
                    hasMore={hasMore}
                    onMoreSelection={() => getCommunications(FetchDocumentsTypeEnum.PAGINATING)}
                />
            </div>
            <CommunicationsPanel documentsGroup={documentsGroup} />
        </>
    )
}

export default CommunicationsTable
