import './ClaimsSettlementPanel.css'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import DocumentCategory from 'core/enums/DocumentCategory'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { mapDocumentFile } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { leftPanelWidth, usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import {
    fetchClaimsSettlement,
    getClaimsSettlementLoadingStatus,
    getSelectedClaimsSettlement,
    getSelectedReceiptIndex
} from 'shared/store/claimsSettlement/claimsSettlement.slice'
import { fetchDocumentFile, getDocumentFiles } from 'shared/store/documents/documents.slice'
import {
    getSelectedFinancialDocument,
    getSelectedFinancialDocumentsGroup,
    setSelectedFinancialDocumentId
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { useAppDispatch } from 'shared/store/store'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import ClaimsSettlementSubHeader from '../ClaimsSettlementPdfSubHeader/ClaimsSettlementSubHeader'
import ClaimsSettlementReceiptPanel from '../ClaimsSettlementReceiptPanel/ClaimsSettlementReceiptPanel'
import PaymentDocumentPanelHeader from '../PaymentDocumentPanelHeader/PaymentDocumentPanelHeader'
import ClaimsSettlementPanelDetails from './ClaimsSettlementPanelDetails'
import ClaimsSettlementPdfViewer from './ClaimsSettlementPdfViewer'

const ClaimsSettlementPanel = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { isXL } = useWindowSize()

    const selectedDocumentsGroup = useSelector(getSelectedFinancialDocumentsGroup)
    const selectedDocument = useSelector(getSelectedFinancialDocument)
    const documents = useSelector(getDocumentFiles)
    const claimsSettlementLoadingStatus = useSelector(getClaimsSettlementLoadingStatus)
    const selectedClaimsSettlement = useSelector(getSelectedClaimsSettlement)
    const selectedReceiptIndex = useSelector(getSelectedReceiptIndex)

    const { panelDatas, update } = usePanelDatasContext()

    const [isDisplay, setIsDisplay] = useState(false)

    let mappedDocumentFile: AssuraDocument | undefined
    if (selectedDocument) mappedDocumentFile = mapDocumentFile(selectedDocument, documents)

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => mappedDocumentFile?.fileName ?? ''
        }
    })

    const getPdf = () => {
        if (isDisplay && selectedDocument) {
            if (mappedDocumentFile === undefined)
                dispatch(
                    fetchDocumentFile({
                        documentId: selectedDocument?.documentId,
                        documentsGroup: selectedDocumentsGroup,
                        isRead: selectedDocument.isRead
                    })
                )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
            update({
                hasLeftPanel: false,
                hasPDFPanel: true
            })
        }
    }

    const getShouldDisplayLeftPanel = (): boolean => {
        let shouldDisplayLeftPanel = panelDatas.hasLeftPanel
        if (panelDatas.hasPDFPanel && !isXL) {
            shouldDisplayLeftPanel = false
        }
        return shouldDisplayLeftPanel
    }

    const closePanel = () => {
        dispatch(setSelectedFinancialDocumentId({ documentId: null }))
    }

    useEffect(() => {
        setIsDisplay(
            selectedDocument !== null &&
                selectedDocument.categoryId === DocumentCategory.CLAIMS_SETTLEMENT
        )
    }, [selectedDocument])

    useEffect(() => {
        if (isDisplay && selectedDocument) {
            if (
                selectedDocument.categoryId === DocumentCategory.CLAIMS_SETTLEMENT &&
                !!selectedDocument.claimsSettlementId &&
                !selectedDocument.isMigrated &&
                !selectedDocument.canceled
            ) {
                dispatch(fetchClaimsSettlement(selectedDocument.claimsSettlementId))
                update({
                    hasLeftPanel: true,
                    hasPDFPanel: false
                })
            } else {
                getPdf()
            }
        } else {
            update({
                hasLeftPanel: false,
                hasPDFPanel: false
            })
        }
    }, [isDisplay])

    useEffect(() => {
        if (
            claimsSettlementLoadingStatus === LoadingStatusEnum.ERROR ||
            selectedClaimsSettlement?.corrective
        ) {
            getPdf()
        }
    }, [selectedClaimsSettlement, claimsSettlementLoadingStatus])

    return (
        <>
            <AssuraPanel
                type={'right'}
                isOpen={isDisplay}
                size={panelDatas.panelWidth}
                panelContainerClassName="bg-gray50"
                backdropClicked={closePanel}
            >
                <>
                    {isDisplay && selectedDocument !== null && (
                        <div className="claims-settlement-panel-container">
                            <PaymentDocumentPanelHeader
                                selectedDocument={selectedDocument}
                                closePanel={closePanel}
                                toolbarPluginInstance={toolbarPluginInstance}
                                statusIsOk={mappedDocumentFile?.loadStatus === LoadingStatusEnum.OK}
                                doc={mappedDocumentFile}
                            />
                            <div className="d-flex flex-1 overflow-hidden">
                                {getShouldDisplayLeftPanel() && (
                                    <div
                                        className="d-flex"
                                        style={
                                            panelDatas.hasPDFPanel
                                                ? { flex: `0 0 ${leftPanelWidth}px` }
                                                : { flex: 1 }
                                        }
                                    >
                                        <ClaimsSettlementPanelDetails
                                            selectedDocument={selectedDocument}
                                        />
                                    </div>
                                )}
                                {panelDatas.hasPDFPanel && (
                                    <div className="d-flex flex-column flex-1 bg-gray100">
                                        <ClaimsSettlementSubHeader />
                                        <ClaimsSettlementPdfViewer
                                            documentFile={mappedDocumentFile}
                                            toolbarPluginInstance={toolbarPluginInstance}
                                            isSelectedDocumentRead={selectedDocument.isRead}
                                            isCanceled={selectedDocument.canceled}
                                            shouldDisplayBanner
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            </AssuraPanel>
            {selectedReceiptIndex !== null && (
                <div className="claims-settlement-panel-receipt-backdrop">
                    <ClaimsSettlementReceiptPanel />
                </div>
            )}
        </>
    )
}

export default ClaimsSettlementPanel
