import { LoadingStatusEnum } from '../enums/LoadingStatusEnum'

export const getLoadingStatusFromLoaderList = (list: LoadingStatusEnum[]): LoadingStatusEnum => {
    if (list.find((loader) => loader === LoadingStatusEnum.ERROR)) {
        return LoadingStatusEnum.ERROR
    } else if (list.find((loader) => loader === LoadingStatusEnum.LOADING)) {
        return LoadingStatusEnum.LOADING
    } else {
        return LoadingStatusEnum.OK
    }
}
