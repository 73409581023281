import './AssuraImage.css'

export interface AssuraImageProps {
    index: number
    id: string
    src: string
    onClick?: () => void
}

export const AssuraImage = (props: AssuraImageProps): JSX.Element => {
    return (
        <div data-testid={`${props.id}-${props.index}`} className="image-viewer-content">
            <img key={props.index} src={props.src} onClick={() => props.onClick?.()} alt="" />
        </div>
    )
}
