import './Infos.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import { SymptomCheckerEcLinkId } from 'core/constants/ecLinkIds'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { QuickAccess } from 'core/models/cms/QuickAccessCms'
import { assets } from 'core/utils/assetUtils'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { fetchCommunicationsNotificationCount } from 'shared/store/communications/communications.slice'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'
import { onFetchEnveloppes } from 'shared/store/enveloppes/enveloppes.slice'
import {
    fetchMissingData,
    getMissingDataLoadingStatus
} from 'shared/store/missingData/missingData.slice'
import { fetchNewsCms, getNewsLoadingStatus } from 'shared/store/news/news.slice'
import { fetchNotifications } from 'shared/store/notifications/notifications.slice'
import {
    fetchQuickAccessCms,
    getQuickAccessLoadingStatus
} from 'shared/store/quickAccess/quickAccess.slice'
import { getHomeNotificationCommunicationsAndInvoices } from 'shared/store/selectors/getHomeNotificationCommunicationsAndInvoices'
import {
    getHomeNotificationEnveloppes,
    getHomeNotificationMissingsAndRequests,
    getHomeNotificationUnpaidStats
} from 'shared/store/selectors/getHomeNotifications'
import { getQuickAccessLocalized } from 'shared/store/selectors/getQuickAccessLocalized'
import {
    getRequests,
    getServicesRequestsLoadingStatus
} from 'shared/store/services/requests/requests.slice'
import { fetchUnpaidStats } from 'shared/store/unpaidStats/unpaidStats.slice'

import HomeNotificationsCard from '../HomeNotificationsCard/HomeNotificationsCard'
import HomeQuickAccessCard from '../HomeQuickAccessCard/HomeQuickAccessCard'
import News from '../News/News'

const Infos = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const unpaidStats = useSelector(getHomeNotificationUnpaidStats)
    const enveloppes = useSelector(getHomeNotificationEnveloppes)
    const missingRequests = useSelector(getHomeNotificationMissingsAndRequests)
    const newsLoadingStatus = useSelector(getNewsLoadingStatus)
    const requestsLoading = useSelector(getServicesRequestsLoadingStatus)
    const missingLoading = useSelector(getMissingDataLoadingStatus)
    const quickAccessData = useSelector(getQuickAccessLocalized)
    const quickAccessStatus = useSelector(getQuickAccessLoadingStatus)
    const allHomeNotificationsLoadingStatus = useSelector(
        getHomeNotificationCommunicationsAndInvoices
    )
    const digcliParameters = useSelector(getDigcliparameters)

    const getUnpaidStatsIcon = (): JSX.Element | undefined => {
        return unpaidStats.data?.criticality !== 0 ? (
            <i
                className="icon assura-warning-circle c-primary"
                data-testid="notifications-card-icon-warning-unpaid-stats"
            />
        ) : undefined
    }

    const onClickSendAnalytics = (link: string) => {
        // bypass if not symptom checkeritem
        if (!link.includes('Symptom')) return
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_CLICK, {
            [analyticsConstants.PARAMS.ENTRY_POINT]: entryPointsConstants.ENTRY_POINTS.QUICK_ACCESS,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }

    return (
        <Row className="home-infos-container">
            <Col className="home-infos-notifications-col" xs={12} md={6} xl={4}>
                <div className="headlineSmall m-bottom-16" data-testid="home-infos-finance-title">
                    {t('HOME.NOTIFICATIONS.FINANCES_TITLE')}
                </div>
                <HomeNotificationsCard
                    notification={unpaidStats.data}
                    loadingStatus={unpaidStats.loadingStatus}
                    reloadAction={() => dispatch(fetchUnpaidStats())}
                    iconComponent={getUnpaidStatsIcon()}
                    key="unpaidStats"
                />
                <HomeNotificationsCard
                    notification={enveloppes.data}
                    loadingStatus={enveloppes.loadingStatus}
                    reloadAction={() => dispatch(onFetchEnveloppes())}
                    key="enveloppes"
                />
                <div
                    className="headlineSmall m-bottom-16 m-top-24"
                    data-testid="home-infos-communication-title"
                >
                    {t('HOME.NOTIFICATIONS.COMMUNICATION_TITLE')}
                </div>
                <>
                    {allHomeNotificationsLoadingStatus.data?.hasInvoicesToCheck ? (
                        <HomeNotificationsCard
                            notification={allHomeNotificationsLoadingStatus.data?.invoicesToCheck}
                            loadingStatus={allHomeNotificationsLoadingStatus.loadingStatus}
                            reloadAction={() => dispatch(fetchNotifications())}
                            extraUrlQuery={
                                allHomeNotificationsLoadingStatus.data?.hasInvoicesToCheck
                                    ? 'itc-open-unique=1'
                                    : undefined
                            }
                            key="invoicesToCheck"
                        />
                    ) : (
                        <HomeNotificationsCard
                            notification={allHomeNotificationsLoadingStatus.data?.communication}
                            loadingStatus={allHomeNotificationsLoadingStatus.loadingStatus}
                            reloadAction={() => dispatch(fetchCommunicationsNotificationCount())}
                            key="communications"
                        />
                    )}
                </>
                <div
                    className="home-infos-service-title headlineSmall m-top-24"
                    data-testid="home-infos-service-title"
                >
                    {t('HOME.NOTIFICATIONS.SELF_SERVICES_TITLE')}
                </div>
                <HomeNotificationsCard
                    notification={missingRequests.data}
                    loadingStatus={missingRequests.loadingStatus}
                    reloadAction={() => {
                        if (missingLoading === LoadingStatusEnum.ERROR) dispatch(fetchMissingData())
                        if (requestsLoading === LoadingStatusEnum.ERROR) dispatch(getRequests())
                    }}
                    key="requests"
                />
            </Col>
            <Col className="home-infos-quick-access-col" xs={12} md={6} xl={4}>
                <div className="headlineSmall m-bottom-16" data-testid="home-quick-access-title">
                    {t('HOME.QUICK_ACCESS.TITLE')}
                </div>
                <div className="home-infos-quick-access-container-content">
                    <AssuraLoadAndError
                        status={quickAccessStatus}
                        defaultReloadAction={() => dispatch(fetchQuickAccessCms())}
                        activityIndicatorSize={24}
                    >
                        {quickAccessData.map((quickAccess: QuickAccess) => {
                            return <HomeQuickAccessCard key={quickAccess._id} data={quickAccess} />
                        })}
                    </AssuraLoadAndError>
                </div>
                {digcliParameters?.isSymptomCheckerCustomerPortalActivated !== 'false' && (
                    <>
                        <div
                            className="headlineSmall m-bottom-16 m-top-24"
                            data-testid="home-symptom-checker-title"
                        >
                            {t('SYMPTOM_CHECKER.TITLE')}
                        </div>
                        <AssuraEcLink
                            ecLinkId={SymptomCheckerEcLinkId}
                            testId="home-quick-access-card-symptom-checker"
                            classNames="d-flex flex-1 animated-block bg-white bc-gray100 border-solid-width-1 p-top-24 p-bottom-24 p-left-24 p-right-16"
                            callback={onClickSendAnalytics}
                        >
                            <div className="d-flex w-100">
                                <div className="d-flex align-items-center m-right-24">
                                    <img
                                        src={assets.symptom_checker_form}
                                        alt="symptom-checker"
                                        height={48}
                                    />
                                </div>
                                <div className="flex-1 d-flex flex-column justify-content-center">
                                    <div className="label">{t('SYMPTOM_CHECKER.TITLE')}</div>
                                    <div className="labelSmall c-gray500">
                                        {t('HOME.SYMPTOM_CHECKER.DESCRIPTION')}
                                    </div>
                                </div>
                                <AssuraIconButton
                                    id={`symptom-checker-card-link`}
                                    icon="assura-right"
                                    variant="primary"
                                    size="small"
                                    classNames="c-gray300"
                                />
                            </div>
                        </AssuraEcLink>
                    </>
                )}
            </Col>
            <Col className="home-infos-carrousel-col" xs={12} xl={4}>
                <div className="home-infos-carrousel-frame bg-white">
                    <AssuraLoadAndError
                        status={newsLoadingStatus}
                        defaultReloadAction={() => dispatch(fetchNewsCms())}
                        activityIndicatorSize={24}
                    >
                        <News />
                    </AssuraLoadAndError>
                </div>
            </Col>
        </Row>
    )
}

export default Infos
