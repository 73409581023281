import { ProductCategoryCms } from 'core/models/cms/ProductCategoryCms'
import { ProductCms } from 'core/models/cms/ProductCms'
import { ProductGroupCms } from 'core/models/cms/ProductGroupCms'
import { ProductOverviewCms } from 'core/models/cms/ProductOverView'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface FetchProductsYearPayload {
    year: number
    reload?: boolean
}

interface ProductsState {
    year: number
    productsCms: ProductCms[]
    productsOverviewCms: ProductOverviewCms[]
    productCategoriesCms: ProductCategoryCms[]
    recommendedProductGroupCms: ProductGroupCms
}

const INITIAL_STATE_PRODUCTS: ProductsState = {
    year: getCurrentYear(),
    productsCms: [],
    productsOverviewCms: [],
    productCategoriesCms: [],
    recommendedProductGroupCms: {
        _id: '',
        id: '',
        products: [],
        categories: []
    }
}

export const productsSlice = createSlice({
    name: 'products',
    initialState: INITIAL_STATE_PRODUCTS,
    reducers: {
        initProductsCmsData: (state) => {
            return state
        },
        fetchProductsYear: (state, action: PayloadAction<FetchProductsYearPayload>) => {
            state.year = action.payload.year
        },

        fetchProductsCms: (state) => {
            return state
        },
        fetchProductsCmsSuccess: (state, action: PayloadAction<ProductCms[]>) => {
            state.productsCms = action.payload
        },

        fetchProductsOverviewCms: (state) => {
            return state
        },
        fetchProductsOverviewCmsSuccess: (state, action: PayloadAction<ProductOverviewCms[]>) => {
            state.productsOverviewCms = action.payload
        },

        fetchProductCategoriesCms: (state) => {
            return state
        },
        fetchProductCategoriesCmsSuccess: (state, action: PayloadAction<ProductCategoryCms[]>) => {
            state.productCategoriesCms = action.payload
        },

        fetchRecommendedProductGroupCms: (state) => {
            return state
        },
        fetchRecommendedProductGroupCmsSuccess: (state, action: PayloadAction<ProductGroupCms>) => {
            state.recommendedProductGroupCms = action.payload
        }
    }
})

export const {
    initProductsCmsData,
    fetchProductsYear,
    fetchProductsCms,
    fetchProductsCmsSuccess,
    fetchProductsOverviewCms,
    fetchProductsOverviewCmsSuccess,
    fetchProductCategoriesCms,
    fetchProductCategoriesCmsSuccess,
    fetchRecommendedProductGroupCms,
    fetchRecommendedProductGroupCmsSuccess
} = productsSlice.actions

export const getProductsYear: Selector<RootState, number> = (state) => state.products.year

export const getProductsCms: Selector<RootState, ProductCms[]> = (state) =>
    state.products.productsCms

export const getProductsOverviewCms: Selector<RootState, ProductOverviewCms[]> = (state) =>
    state.products.productsOverviewCms

export const getProductCategoriesCms: Selector<RootState, ProductCategoryCms[]> = (state) =>
    state.products.productCategoriesCms

export const getRecommendedProductGroupCms: Selector<RootState, ProductGroupCms> = (state) =>
    state.products.recommendedProductGroupCms

export default productsSlice.reducer
