import { DropzoneState } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

import { emptyFunction } from '../../../core/helpers/functionHelper'
import { useAssuraDropzoneContext } from './AssuraDropzone'

interface AssuraDropzoneErrorProps {
    testId: string
    text: string
}

const AssuraDropzoneError = ({ testId, text }: AssuraDropzoneErrorProps): JSX.Element => {
    const { t } = useTranslation()

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useAssuraDropzoneContext() as DropzoneState

    const dragActiveDropzoneClass = isDragActive ? 'bg-gray20 bc-gray500' : 'bg-error-background bc-primary'
    return (
        <div className="d-flex flex-column">
            <div
                {...getRootProps({
                    className: 'assura-dropzone p-40 ' + dragActiveDropzoneClass
                })}
                data-testid={`${testId}-dropzone-error`}
            >
                <input {...getInputProps()} />
                <i className="icon assura-error size-32 c-primary" />
                <div
                    data-testid={`${testId}-dropzone-error-message`}
                    className="paragraphSmall c-black assura-dropzone-error-message"
                >
                    {text}
                </div>
                <AssuraIconButton
                    id="assura-dropzone-error-upload-button"
                    icon="assura-upload"
                    variant="primary"
                    size="medium"
                    onClick={emptyFunction}
                    label={t('COMMON.UPLOAD')}
                />
            </div>
        </div>
    )
}

export default AssuraDropzoneError
