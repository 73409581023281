import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { emailRules } from 'core/utils/TextUtils'

import { AssuraFormInputRHF } from '../InputRHF/InputRHF'

interface EmailFieldProps {
    isReadOnly?: boolean
}

const EmailField = ({ isReadOnly }: EmailFieldProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const [isFocused, setIsFocused] = useState(false)

    const {
        setValue,
        register,
        formState: { errors, isSubmitted }
    } = useFormContext()

    const handleResetAction = () => {
        setValue('email', '', {
            shouldValidate: isSubmitted
        })
    }

    return (
        <AssuraFormInputRHF
            id={'email'}
            name={'email'}
            readOnly={isReadOnly}
            label={t('COMMON.EMAIL_ADDRESS')}
            inline={isXL}
            labelSize={4}
            isInvalid={Boolean(errors?.email)}
            error={t(errors?.email?.message as string)}
            hasButton={!isReadOnly && isFocused}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onButtonClick={() => handleResetAction()}
            iconClass="icon assura-close size-24"
            register={register}
            rules={emailRules}
        />
    )
}

export default EmailField
