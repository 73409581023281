import './NoContract.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import image from 'shared/assets/images/Assura_Portraits_005_crop.jpg'
import AssuraEcLinkButton from 'shared/components/AssuraEcLinkButton/AssuraEcLinkButton'

const NoContract = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Row className="no-contract-container">
            <Col xs={12} md={6} xl={5}>
                <div className="no-contract-image" style={{ backgroundImage: `url(${image})` }} />
            </Col>
            <Col xs={12} md={6} xl={{ span: 4, offset: 1 }} className="no-contract-content-block">
                <p className="headlineLarge" data-testid="insurrances-no-contract-title">
                    {t('COVERAGE.DISCOVER_INSURANCE_TITLE')}
                </p>
                <p className="paragraphSmallLight" style={{ marginBottom: '40px' }}>
                    {t('COVERAGE.DISCOVER_INSURANCE_DESCRIPTION')}
                </p>
                <AssuraEcLinkButton
                    ecLinkId="646391065afb28b49a04023d"
                    testId="no-contract-see-more-button"
                    text={t('COMMON.LEARN_MORE')}
                    variant="primary"
                    icon={{
                        name: 'assura-external-link',
                        size: 16
                    }}
                />
            </Col>
        </Row>
    )
}

export default NoContract
