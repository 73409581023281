import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface ContractFamilyDoctorProps {
    values: RequestDetailsValues[]
}

const ContractFamilyDoctor = ({ values }: ContractFamilyDoctorProps): JSX.Element => {
    const { t } = useTranslation()

    const lastName = values.find((v) => v.id === 'DoctorLastName')
    const firstName = values.find((v) => v.id === 'DoctorFirstName')
    const streetNumber = values.find((v) => v.id === 'DoctorAddress')
    const npa = values.find((v) => v.id === 'DoctorZipCode')
    const locality = values.find((v) => v.id === 'DoctorLocality')
    const phone = values.find((v) => v.id === 'DoctorPhoneNumber')
    const validityDate = values.find((v) => v.id === 'ValidFrom')

    const label = t('CONTRACT.FAMILY_DOCTOR')

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            {firstName?.old && (
                <>
                    <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
                    <div className="requests-panel-main-block m-bottom-32">
                        <LabelValueInlineBlock>
                            <div className="align-self-end">{label}</div>
                            <div className="text-right">
                                <div data-testid="requests-details-contract-family-doctor-name-old">
                                    {firstName?.old ?? ''} {lastName?.old ?? ''}
                                </div>
                                <div data-testid="requests-details-contract-family-doctor-street-number-old">
                                    {(streetNumber?.old as string) ?? ''}
                                </div>
                                <div data-testid="requests-details-contract-family-doctor-locality-old">
                                    {npa?.old ?? ''} {locality?.old ?? ''}
                                </div>
                                <div data-testid="requests-details-contract-family-doctor-phone-old">
                                    {phone?.old ?? ''}
                                </div>
                            </div>
                        </LabelValueInlineBlock>
                    </div>
                </>
            )}
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                <>
                    {validityDate && validityDate.new && (
                        <div className="label c-primary">
                            {t('DOCUMENTS.PROCESSING_DATE', {
                                processingDate: formatDate(validityDate.new as string)
                            })}
                        </div>
                    )}
                </>
            </RequestsPanelSubtitle>
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div className="align-self-end">{label}</div>
                    <div className="text-right">
                        <div data-testid="requests-details-contract-family-doctor-name-new">
                            {(firstName?.new as string) ?? ''} {(lastName?.new as string) ?? ''}
                        </div>
                        <div data-testid="requests-details-contract-family-doctor-street-number-new">
                            {(streetNumber?.new as string) ?? ''}
                        </div>
                        <div data-testid="requests-details-contract-family-doctor-locality-new">
                            {(npa?.new as string) ?? ''} {(locality?.new as string) ?? ''}
                        </div>
                        <div data-testid="requests-details-contract-family-doctor-phone-new">
                            {(phone?.new as string) ?? ''}
                        </div>
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default ContractFamilyDoctor
