import navigationConstants from 'core/constants/navigationConstants'
import { HomeNotificationsColors } from 'core/enums/HomeNotificationsColors'
import {
    HomeNotificationsCardData,
    HomeNotificationsCriticality
} from 'core/models/HomeNotifications'
import { EnveloppesCountByStatus } from 'shared/store/selectors/getEnveloppesCountByStatus'

const mapEnveloppesToHomeNotification = (
    statusCount: EnveloppesCountByStatus
): HomeNotificationsCardData => {
    let title = 'HOME.NOTIFICATIONS_ENVELOPPES_TITLE'
    let criticality: HomeNotificationsCriticality = 0
    let color = HomeNotificationsColors.GRAY
    let count = 0
    const link = `${navigationConstants.FINANCIALS.url}?item=enveloppes`

    if (statusCount.error > 0) {
        title = 'HOME.NOTIFICATIONS_ENVELOPPES_TITLE_2'
        criticality = 2
        color = HomeNotificationsColors.RED
        count = statusCount.error
    } else if (statusCount.processing > 0) {
        criticality = 1
        color = HomeNotificationsColors.BLACK
        count = statusCount.processing
    }

    return {
        testId: 'enveloppes',
        criticality,
        color,
        count,
        title,
        link
    }
}

export default mapEnveloppesToHomeNotification
