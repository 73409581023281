import { createSlice } from '@reduxjs/toolkit'

interface AuthenticationState {
    isAuthenticated: boolean
}

const INITIAL_STATE_AUTHENTICATION: AuthenticationState = {
    isAuthenticated: false
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: INITIAL_STATE_AUTHENTICATION,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false
        }
    }
})

export const { logout } = authenticationSlice.actions

export default authenticationSlice.reducer
