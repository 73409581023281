import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { Product } from 'core/models/Product'
import { openInNewTab } from 'core/utils/onClickUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'

interface ProductCardBodyProps {
    product: Product
}

const ProductCardBody = ({ product }: ProductCardBodyProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = useSelector(getLang) as keyof AssetUrlCms
    const assets = useSelector(getAssets)
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms

    const AssetToLink = (item: string): string | undefined | null => {
        const result = Object.entries(assets).find((element) => element[0] === item)
        if (!result) return undefined

        if (lang === ENGLISH_LANGUAGE && contactLang && !result?.[1][ENGLISH_LANGUAGE]) {
            return result?.[1][contactLang]
        }
        return result?.[1][lang] as string
    }

    const handleNavigateToAsset = (): void => {
        const assetUrl = AssetToLink(product.conditionsLca as string)
        if (!assetUrl) {
            dispatch(
                setBanner({
                    dataTestId: 'lca-conditions-error',
                    message: 'COMMON.ERROR'
                })
            )
            return
        }
        openInNewTab(assetUrl)
    }

    return (
        <>
            <div
                data-testid={`product-card-body-description-${product.insuranceId}`}
                className="paragraphSmallLight product-card-body-container"
            >
                {product.description}
                {product.conditionsLca && (
                    <AssuraIconButton
                        id="cta-conditions-lca"
                        icon="assura-doc"
                        variant="primary"
                        size="medium"
                        onClick={() => handleNavigateToAsset()}
                        label={t('DOCUMENTS.CONDITIONS_LINKS')}
                        classNames="m-top-12 justify-content-start"
                    />
                )}
            </div>
            {product.startDate && (
                <div className="paragraphExtraSmall product-card-body-date-container">
                    {t('INSURANCE.START_DATE')}
                    <span
                        data-testid={`product-start-date-${product.insuranceId}`}
                        className="c-primary"
                    >
                        {` ${formatDate(product.startDate)}`}
                    </span>
                </div>
            )}
        </>
    )
}

export default ProductCardBody
