import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { resetBanners } from 'shared/store/banners/banners.slice'
import { getKeepSelectedMember, setFamilyGroupResult } from 'shared/store/services/services.slice'

import FamilyGroup, { FamilyGroupResult } from '../FamilyGroup/FamilyGroup'

export interface FamilyGroupProps {
    hasAllFamilyButton?: boolean
    selectAction: (datas: FamilyGroupResult) => void
    defaultSelectedPolicyNumber?: number
}

export interface FormPageFamilyGroupProps extends FamilyGroupProps {
    serviceType: ServiceType
}

const FormPageFamilyGroup = ({
    hasAllFamilyButton,
    selectAction,
    serviceType,
    defaultSelectedPolicyNumber
}: FormPageFamilyGroupProps): JSX.Element => {
    const dispatch = useDispatch()

    const keptMember = useSelector(getKeepSelectedMember)
    const { clearErrors } = useFormContext()

    const handleSelectMember = (datas: FamilyGroupResult) => {
        clearErrors()
        dispatch(resetBanners())
        dispatch(setFamilyGroupResult(datas))
        selectAction(datas)
    }

    return (
        <FamilyGroup
            hasDefaultSelectedMember={!!keptMember?.selectedPolicyNumber}
            defaultSelectedPolicyNumber={
                keptMember?.selectedPolicyNumber ?? defaultSelectedPolicyNumber
            }
            hasAllFamilyButton={hasAllFamilyButton}
            selectAction={handleSelectMember}
            serviceType={serviceType}
            source={Source()}
        />
    )
}

export default FormPageFamilyGroup
