import { EcStatus } from 'core/enums/FamilyGroup'
import { addDays } from 'core/helpers/DateFormatHelper'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getDigcliparameters } from '../digcliParameters/digcliParameters.slice'
import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getHeadOfFamily } from '../general/general.slice'
import { getLastUpdate } from '../settings/ecPlusMigration/ecPlusMigration.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export const showEcPlusPopup = createSelector(
    [
        getDigcliparameters,
        getLastUpdate,
        getFamilySummaries,
        getHeadOfFamily,
        getConnectedFamilyMember
    ],
    (parameters, lastUpdate, summaries, headOfFamily, connectedPerson): boolean => {
        return (
            parameters?.EcPlusPopupActive === 'true' &&
            (lastUpdate === null ||
                addDays(lastUpdate, +parameters.EcPlusPopupDaysInterval) < new Date()) &&
            summaries[getCurrentYear()].ecStatus === EcStatus.EC &&
            headOfFamily?.policyNumber === connectedPerson?.policyNumber
        )
    }
)
