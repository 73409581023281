import './DeleteAccount.css'

import { Col, Container, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { DeleteAccountRHF } from 'core/models/DeleteAccount'
import useWindowSize from 'core/services/useWindowSize'
import FormTitle from 'modules/services/components/FormTitle/FormTitle'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getIsAccountDeleted } from 'shared/store/settings/deleteAccount/deleteAccount.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import DeleteAccountDisclaimer from '../components/DeleteAccountDisclaimer/DeleteAccountDisclaimer'
import DeleteAccountForm from '../components/DeleteAccountForm/DeleteAccountForm'
import DeleteAccountInfo from '../components/DeleteAccountInfo/DeleteAccountInfo'
import DeleteAccountPopin from '../components/DeleteAccountPopin/DeleteAccountPopin'

const DeleteAccount = (): JSX.Element => {
    const { isMobile } = useWindowSize()
    const { t } = useTranslation()

    const isAccountDeleted = useSelector(getIsAccountDeleted)

    const defaultValues: DeleteAccountRHF = {
        policyNumber: '',
        hasConsent: false
    }

    const methods = useForm<DeleteAccountRHF>({ defaultValues })

    return (
        <>
            <FullScreenContainer color="gray20">
                <Container>
                    <FormProvider {...methods}>
                        <Row className="p-bottom-80">
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                                {!isMobile && <FormTitle title={t('DELETE_ACCOUNT.TITLE')} />}
                                <DeleteAccountDisclaimer />
                                <DeleteAccountForm />
                            </Col>
                            <Col
                                xs={{ span: 12, order: 1 }}
                                md={{ span: 4, order: 2 }}
                                xl={{ span: 4, offset: 1 }}
                            >
                                {isMobile && <FormTitle title={t('DELETE_ACCOUNT.TITLE')} />}
                                <DeleteAccountInfo />
                            </Col>
                        </Row>
                    </FormProvider>
                </Container>
            </FullScreenContainer>
            {isAccountDeleted && <DeleteAccountPopin />}
        </>
    )
}

export default withAITracking(
    reactPlugin,
    DeleteAccount,
    'DeleteAccount',
    'delete-account-container'
)
