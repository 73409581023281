import axios from 'axios'

export const getCguAcceptationStatus = async (): Promise<boolean> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/ToS/hasToValidateNewToS?source=customerPortal`
    )
    return result.data
}

export const updateCguAcceptationStatus = async (): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/ToS/validateToS?source=customerPortal`)
