import { ProductTypeEnum } from 'core/enums/Product'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../../familySummaries/familySummaries.slice'
import { getWantedDate } from '../../services/doctors/doctors.slice'
import { getSelectedMemberPolicyNumber } from '../../services/services.slice'
import { getLamalWithDoctorCodes } from '../getLamalWithDoctorCodes'

export const getDoctorLamalOfSelectedFamilyMember = createSelector(
    [getFamilySummaries, getSelectedMemberPolicyNumber, getWantedDate, getLamalWithDoctorCodes],
    (familySummaries, selectedMemberPolicyNumber, wantedDate, lamalWithDoctorCodes) => {
        let activeDoctorLamal: ProductPerson | undefined = undefined

        const summaryResult = familySummaries[wantedDate?.getFullYear() ?? getCurrentYear()]
        if (summaryResult) {
            const selectedFamilyMember = summaryResult.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )
            if (selectedFamilyMember) {
                const doctorLamals = selectedFamilyMember.productsPerson?.products?.filter(
                    (productPerson) =>
                        (wantedDate ? new Date(productPerson.startDate) <= wantedDate : true) &&
                        productPerson.type === ProductTypeEnum.LAMAL &&
                        lamalWithDoctorCodes.includes(productPerson.code)
                )
                // Get the Lamal which is active at the wantedDate chosen by user.
                // For example user with RMD (startDate 01/01/22) and RPH (startDate 01/08/22). If wantedDate is 01/09/22 RPH should be chosen
                activeDoctorLamal = doctorLamals?.sort(
                    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                )[0]
            }
        }
        return activeDoctorLamal
    }
)
