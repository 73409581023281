import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { ProductCms } from 'core/models/cms/ProductCms'
import { ProductOverviewCms } from 'core/models/cms/ProductOverView'
import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getProductsCms, getProductsOverviewCms } from 'shared/store/products/products.slice'
import {
    getCurrentDeductible,
    getCurrentModel,
    getModelDeductibles
} from 'shared/store/services/coverage/coverage.slice'

import ModelDeductiblePanel from '../ModelDeductiblePanel/ModelDeductiblePanel'

interface ProductItemProps {
    index: number
    modelDeductible: ProductFranchise
    active: boolean
    action: () => void
    openInfoPanel: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productInfo: ProductOverviewCms
    ) => void
}

const ProductItem = ({
    index,
    modelDeductible,
    active,
    action,
    openInfoPanel
}: ProductItemProps) => {
    const { t } = useTranslation()
    const productsInformation = useSelector(getProductsCms)
    const productsOverviewInformation = useSelector(getProductsOverviewCms)
    const currentDeductible = useSelector(getCurrentDeductible)
    const currentModel = useSelector(getCurrentModel)
    const lang = useSelector(getLang)

    const productCMS = getLocalizedObject(
        productsInformation.find((pi) => pi.insurance_id === modelDeductible.product),
        lang
    ) as ProductCms

    const productOverviewCMS = getLocalizedObject(
        productsOverviewInformation.find((pi) => pi.identifier === modelDeductible.product),
        lang
    ) as ProductOverviewCms

    productOverviewCMS.productName = productCMS.title

    if (!productCMS || !productCMS.title) return <></>
    return (
        <Col
            xs={12}
            md={6}
            xl={3}
            data-testid={`card-model-${index}`}
            onClick={action}
            className="m-top-24 d-flex"
        >
            <div className={'product-card bc-gray100' + (active ? ' active' : '')}>
                <div className="card-model-header">
                    <div className="bc-gray100 d-flex justify-content-between align-items-center">
                        <div>
                            <div className={active ? 'labelSmallMedium' : 'labelSmall'}>
                                {productCMS.title.toUpperCase()}
                            </div>
                            <div className="titleSmall m-top-8">{`CHF ${modelDeductible.prime.toFixed(
                                2
                            )}`}</div>
                        </div>
                        <img
                            src={`${process.env.REACT_APP_CMS_ASSET}${
                                active
                                    ? productOverviewCMS.icon_selected.path
                                    : productOverviewCMS.icon.path
                            }`}
                            alt={productCMS.title.toUpperCase()}
                        />
                    </div>
                </div>
                <div className="card-model-body">
                    <div className="bc-gray100">
                        <div className="c-gray500 m-top-24 paragraphSmall">
                            <AssuraMarkdown message={productOverviewCMS.card_description} />
                        </div>
                    </div>
                </div>
                <div className="card-model-footer">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {modelDeductible.franchiseAmount === currentDeductible &&
                            modelDeductible.product === currentModel && (
                                <>
                                    <span className="labelExtraSmall">
                                        {t('SERVICES.MODEL_DEDUCTIBLE_CURRENT')}
                                    </span>
                                    <i
                                        className="icon assura-check-circle size-16 m-left-8"
                                        data-testid="model-check-icon"
                                        style={{ marginRight: '0' }}
                                    />
                                </>
                            )}
                    </div>
                    <AssuraIconButton
                        id={`lca-card-display-verso-button-${modelDeductible.product}`}
                        icon="assura-plus"
                        variant="primary"
                        size="medium"
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                            openInfoPanel(e, productOverviewCMS)
                        }
                        classNames="m-left-8"
                        label={t('COMMON.MORE_DETAILS')}
                    />
                </div>
            </div>
        </Col>
    )
}

const premiumCompare = (a: ProductFranchise, b: ProductFranchise): number => a.prime - b.prime

const ModelDeductibleSelectModel = (): JSX.Element => {
    const { t } = useTranslation()
    const { setValue, watch } = useFormContext()
    const dispatch = useDispatch()

    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [currentInfo, setCurrentInfo] = useState<ProductOverviewCms | undefined>(undefined)

    const deductibleWatch = watch('deductible')
    const modelWatch = watch('model')

    const modelDeductibles = useSelector(getModelDeductibles)

    const products = modelDeductibles.filter((x) => x.franchiseAmount === +deductibleWatch)
    products.sort(premiumCompare)

    const switchSelectedModel = (selectedModel: string) => {
        setValue('model', selectedModel)
        setValue('newDoctorId', undefined)
        setValue('searchDoctorName', '')
    }

    const closeInfoPanel = () => {
        setCurrentInfo(undefined)
        setIsPanelOpen(false)
    }

    const openInfoPanel = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productInfo: ProductOverviewCms
    ) => {
        e.stopPropagation()
        setCurrentInfo(productInfo)
        setIsPanelOpen(true)
    }

    useEffect(() => {
        const bannerMessageKey = 'SERVICES.MODEL_DEDUCTIBLE_BANNER_MESSAGE'
        const bannerMessage = t(bannerMessageKey)

        if (!bannerMessage || bannerMessage === '' || bannerMessage === bannerMessageKey) return

        dispatch(
            setBanner({
                dataTestId: 'services-model-deductible-banner',
                message: bannerMessageKey,
                variant: 'default',
                permanent: true
            })
        )
    }, [])

    return (
        <FullScreenContainer color="white">
            <Container className="m-bottom-56">
                <Row>
                    <div className="products-cards w-100">
                        {products.map((product, index) => {
                            return (
                                <ProductItem
                                    key={index}
                                    index={index}
                                    modelDeductible={product}
                                    active={modelWatch === product.product}
                                    action={() => switchSelectedModel(product.product)}
                                    openInfoPanel={openInfoPanel}
                                />
                            )
                        })}
                    </div>
                </Row>
            </Container>
            <ModelDeductiblePanel
                openPanel={isPanelOpen}
                closePanel={closeInfoPanel}
                currentInfo={currentInfo}
            />
        </FullScreenContainer>
    )
}

export default ModelDeductibleSelectModel
