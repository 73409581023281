import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSummaryCurrentYear } from '../combineSelectors'
import { getUserUtn } from '../general/general.slice'
import { getProductsYearByPage } from './getProductsYearByPage'

export const getPeriodicityCurrentYearAndConnectedMember = createSelector(
    [getUserUtn, getSummaryCurrentYear, getProductsYearByPage],
    (connectedMemberUtn, summary, selectedYear) => {
        if (connectedMemberUtn) {
            const connectedPersonSummary = summary.insuredPersons.find(
                (p) => p.userTiersNumber === connectedMemberUtn
            )

            if (connectedPersonSummary) {
                const productsFilteredByDate =
                    connectedPersonSummary.productsPerson?.products.filter(
                        (x) => new Date(x.startDate) <= getCheckDate(selectedYear)
                    )

                if (productsFilteredByDate && productsFilteredByDate?.length > 0) {
                    return productsFilteredByDate[0].periodicity
                }
            }
        }

        return undefined
    }
)
