import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { assets } from 'core/utils/assetUtils'
import { getSearchText } from 'shared/store/appSearch/appSearch.slice'

const AppSearchNoResult = (): JSX.Element => {
    const { t } = useTranslation()
    const searchText = useSelector(getSearchText)

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={assets.question} alt="search-no-result" width={56} height={56} />
            <span
                className="m-top-24 m-bottom-8 headlineSmallMedium"
                data-testid="app-search-no-result-title"
            >
                {t('SEARCH.NO_RESULT_OOPS')}
            </span>
            <div className="max-2-text-lines text-center">
                <Trans
                    i18nKey="SEARCH.NO_RESULT_MESSAGE"
                    values={{ search: searchText }}
                    components={{ 1: <b /> }}
                />
            </div>
        </div>
    )
}

export default AppSearchNoResult
