import './AssuraNavBar.css'

import { useEffect, useRef } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useNavBarHeights from 'core/services/useNavBarHeights'
import useWindowSize from 'core/services/useWindowSize'
import assuraLogo from 'shared/assets/images/assuraLogo.png'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

import NavBarCalculator from './NavBarCalculator'
import NavBarEnv from './NavBarEnv'
import NavBarEnveloppeSubmission from './NavBarEnveloppeSubmission'
import NavBarFaq from './NavBarFaq'
import NavBarLangSelector from './NavBarLangSelector'
import NavBarLinksBlock from './NavBarLinksBlock'
import NavBarProfileDropdown from './NavBarProfileDropdown'
import NavBarSearch from './NavBarSearch'
import NavBarSearchCTA from './NavBarSearchCTA'

const AssuraNavBar = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMD, isXL, height } = useWindowSize()
    const { navBarDownHeight } = useNavBarHeights()

    const navigate = useNavigate()
    const isXLRef = useRef<boolean>(isXL)
    const { isNavBarMenuOpen, setNavBarMenuState, isNavBarSearchOpen } = useAssuraNavBarContext()

    useEffect(() => {
        const navbar = document.getElementById('responsive-navbar-nav')
        if (navbar && isNavBarMenuOpen && !isXL)
            navbar.style.height = `${height - navBarDownHeight}px`
        if (navbar && isXL) navbar.style.height = `${navBarDownHeight}px`

        if (isNavBarMenuOpen && isXL && !isXLRef.current) setNavBarMenuState(false)

        isXLRef.current = isXL
    }, [height, isNavBarMenuOpen, isXL, navBarDownHeight])

    return (
        <div className="navbar-container fixed-top">
            <div className="navbar-content-container">
                {isXL && (
                    <Navbar
                        id="assuraDesktopNavBar"
                        expand="xl"
                        className="justify-content-end"
                        data-testid="assura-desktop-navbar"
                    >
                        <NavBarEnv />
                        <NavBarSearchCTA />
                        <NavBarProfileDropdown />
                        <NavBarFaq />
                        <NavBarLangSelector />
                    </Navbar>
                )}
                <Navbar
                    id="assuraNavBar"
                    expand="xl"
                    className="navbar-light bg-assura-menu"
                    expanded={isNavBarMenuOpen}
                >
                    <Navbar.Brand>
                        <div
                            onClick={() => {
                                navigate(navigationConstants.HOME.url)
                                setNavBarMenuState(false)
                            }}
                            className="d-flex justify-content-center align-items-center"
                            data-testid="navbar-brand"
                        >
                            <img
                                className="assura-brand"
                                data-testid="navigation-home"
                                src={assuraLogo}
                                width="140"
                                height="22"
                                alt="assura"
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Navbar.Brand>
                    {!isXL && !isNavBarMenuOpen && (
                        <>
                            <NavBarSearchCTA />
                            <NavBarProfileDropdown />
                        </>
                    )}
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={() => setNavBarMenuState(!isNavBarMenuOpen)}
                        className={`navbar-toggle ${
                            isMD && 'd-flex flex-column align-items-center'
                        }`}
                    >
                        <i
                            data-testid="navbar-toggle-icon"
                            className={`icon assura-${
                                isNavBarMenuOpen ? 'close' : 'menu'
                            } size-24 c-black`}
                        />
                        {isMD && (
                            <span className="labelExtraSmallMedium m-top-4 c-black">
                                {t(isNavBarMenuOpen ? 'COMMON.CLOSE' : 'COMMON.MENU')}
                            </span>
                        )}
                    </Navbar.Toggle>
                    <RemoveScroll enabled={isNavBarMenuOpen} forwardProps>
                        <Navbar.Collapse>
                            <Nav
                                id="responsive-navbar-nav"
                                className="c-black"
                                data-testid="responsive-navbar-nav"
                            >
                                <NavBarLinksBlock />
                            </Nav>
                        </Navbar.Collapse>
                    </RemoveScroll>
                    <div className="navbar-sticky-content">
                        {isXL && (
                            <>
                                <NavBarEnveloppeSubmission />
                                <NavBarCalculator />
                            </>
                        )}
                    </div>
                </Navbar>
            </div>
            {isNavBarSearchOpen && <NavBarSearch />}
        </div>
    )
}

export default AssuraNavBar
