import { call, fork, put, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { postAventuraRequestAsyncApi } from 'core/api/services/Aventura'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { AventuraHttpResponse } from 'core/models/services/coverage/AventuraForm'
import { setBanner } from 'shared/store/banners/banners.slice'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted } from '../services.slice'
import { setSubmitStatus, submitData } from './aventura.slice'

export function* aventuraSubmitSaga(action: ReturnType<typeof submitData>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.AVENTURA
    }
    try {
        yield put(setSubmitStatus(LoadingStatusEnum.LOADING))
        yield call(postAventuraRequestAsyncApi, action.payload)
        yield put(formSubmitted(analyticsParams))
        yield put(setSubmitStatus(LoadingStatusEnum.OK))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
        const apiResponse = e as AventuraHttpResponse
        const errorMessageKey =
            apiResponse?.response?.status === 400
                ? 'SERVICE.AVENTURA_VALIDATION_ERROR_BANNER_TEXT'
                : 'SERVICES.SUBMISSION_ERROR'
        yield put(
            setBanner({
                dataTestId: 'aventura-error-banner',
                message: errorMessageKey
            })
        )

        yield put(setSubmitStatus(LoadingStatusEnum.ERROR))
        console.error('aventuraSubmitSaga Error', e)
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* aventuraSubmitWatcher() {
    yield takeEvery(submitData.type, aventuraSubmitSaga)
}

const watchers = [fork(aventuraSubmitWatcher)]

export default watchers
