import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getFormByRoute } from 'core/helpers/services/ServicesFormHelper'
import { openInNewTab } from 'core/utils/onClickUtils'
import { goToServicesForm } from 'shared/store/services/services.slice'

const URL_SEPARATOR = '/'

export default (): CallableFunction => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (url: string) => {
        if (!url.startsWith(URL_SEPARATOR)) {
            openInNewTab(url)
            return
        }

        const foundServiceType = getFormByRoute(url)
        if (foundServiceType) {
            dispatch(goToServicesForm({ type: foundServiceType }))
            return
        }

        navigate(url)
    }
}
