import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { goToServicesForm } from 'shared/store/services/services.slice'

import NotAllowed from '../NotAllowed/NotAllowed'

interface NoValidEmailProps {
    text: string
}

const NoValidEmail = ({ text }: NoValidEmailProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <NotAllowed
            title={t('FORM.NO_VALID_EMAIL_TITLE')}
            message={text}
            link={
                <div
                    className="assura-link paragraphSmallLight m-top-24"
                    onClick={() => dispatch(goToServicesForm({ type: ServiceType.EMAIL_CHANGE }))}
                    data-testid="no-valid-email-link"
                >
                    {t('FORM.NO_VALID_EMAIL_LINK')}
                </div>
            }
            dataTestId="no-valid-email"
        />
    )
}

export default NoValidEmail
