import './NavBarFaq.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useWindowSize from 'core/services/useWindowSize'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

const NavBarFaq = (): JSX.Element => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const { setNavBarMenuState } = useAssuraNavBarContext()

    const handleOnClick = () => {
        if (!isXL) setNavBarMenuState(false)
        navigate(navigationConstants.FAQ.url)
    }

    return (
        <div className="nav-faq-nav-item" onClick={handleOnClick} data-testid="navigation-faq">
            <i className={`icon assura-help-circle c-black size-${isXL ? '16' : '24'}`} />
            <span className="labelExtraSmall link-label">{t('NAVIGATION_BAR.FAQ')}</span>
        </div>
    )
}

export default NavBarFaq
