import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServiceType } from 'core/enums/ServiceType'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import {
    GeneralQuestionForm,
    GeneralQuestionRHFForm,
    GeneralQuestionRHFFormSubmit
} from 'core/models/services/contact/GeneralQuestionForm'
import useQuery from 'core/services/useQuery'
import { getPhoneNumber, phoneFormatted } from 'core/utils/phoneUtils'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { onSubmitGeneralQuestion } from 'shared/store/services/contact/contact.slice'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getFamilyGroupResult, setKeepSelectedMember } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import FormPage from '../../components/FormPage/FormPage'
import GeneralQuestionContent from '../components/GeneralQuestionContent/GeneralQuestionContent'
import GeneralQuestionGoodToKnow from '../components/GeneralQuestionGoodToKnow/GeneralQuestionGoodToKnow'
import GeneralQuestionTopics from '../components/GeneralQuestionTopics/GeneralQuestionTopics'

const GeneralQuestion = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [initialTopic, setInitialTopic] = useState<null | string>()
    const personalData = useSelector(getFullPersonalData)
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const phone = personalData.phone
    if (!phone.data) phone.data = {} as PhoneDatas

    const fullPhoneNumber = getPhoneNumber(phone.data)

    const topicQuery = useQuery().get('topic')
    if (topicQuery && !initialTopic) setInitialTopic(topicQuery)

    const initialDefaultValues: GeneralQuestionRHFForm = {
        topic: initialTopic ?? '',
        message: '',
        phone: phoneFormatted(fullPhoneNumber),
        email: personalData.email.data ?? ''
    }

    const [defaultValues, setDefaultValues] = useState<GeneralQuestionRHFForm>(initialDefaultValues)

    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)

    useEffect(() => {
        // ensure we do not have aay previous kept selected member entering the form
        dispatch(setKeepSelectedMember())
        return () => {
            dispatch(setKeepSelectedMember())
        }
    }, [])

    useEffect(() => {
        const phone = personalData.phone
        if (!phone.data) phone.data = {} as PhoneDatas

        const fullPhoneNumber = getPhoneNumber(phone.data)
        setDefaultValues({
            ...initialDefaultValues,
            phone: phoneFormatted(fullPhoneNumber),
            email: personalData.email.data ?? ''
        })
    }, [personalData])

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactInfos({
                    selectedPolicyNumber: familyGroupResult.selectedPolicyNumber
                })
            )
        dispatch(setKeepSelectedMember(familyGroupResult))
    }

    const onSuccess = (values: GeneralQuestionRHFForm) => {
        const isFamilyMemberSelected = familyGroupResult.selectedPolicyNumber
        if (isFamilyMemberSelected && values.topic) {
            const generalQuestionForm: GeneralQuestionForm = {
                ...(values as GeneralQuestionRHFFormSubmit),
                ...familyGroupResult
            }
            dispatch(onSubmitGeneralQuestion(generalQuestionForm))
        }
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.GENERAL_QUESTION}
            formTitle={{
                title: t('SERVICES.CONTACT_FORM'),
                category: t('SERVICES.INFORMATION_REQUEST')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            shouldDisplaySubmitButton={
                showSubmitButton &&
                Boolean(familyGroupResult.selectedPolicyNumber) &&
                contactDataLoadStatus === LoadingStatusEnum.OK
            }
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GeneralQuestionGoodToKnow />}
        >
            <AssuraLoadAndError
                status={contactDataLoadStatus}
                shouldDisplayContainer
                defaultReloadAction={() =>
                    dispatch(
                        fetchContactInfos({
                            selectedPolicyNumber: familyGroupResult.selectedPolicyNumber,
                            reload: true
                        })
                    )
                }
            >
                <GeneralQuestionTopics />
                <GeneralQuestionContent
                    showSubmitButton={(show: boolean) => setShowSubmitButton(show)}
                />
            </AssuraLoadAndError>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    GeneralQuestion,
    'GeneralQuestion',
    'services-form-container'
)
