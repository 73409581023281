import './InputWithHelperRHF.css'

import { useState } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormBlockWithLabel from 'modules/services/components/FormBlockWithLabel/FormBlockWithLabel'

import { ErrorMessage } from '@hookform/error-message'

import { AssuraFormInputRHFButton } from '../InputRHF/InputRHF'

export interface InputHelperValidation {
    text: string
    valid: boolean
}

interface InputHelper {
    title: string
    validations: Record<string, InputHelperValidation>
}

interface InputWithHelperProps {
    name: string
    label: string
    placeHolder: string
    testId: string
    inputHelper: InputHelper
    rules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

const InputWithHelperRHF = ({
    name,
    label,
    placeHolder,
    testId,
    inputHelper,
    rules
}: InputWithHelperProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        register,
        formState: { errors, isSubmitted },
        setValue,
        trigger
    } = useFormContext()

    const [fieldFocused, setFieldFocused] = useState(false)
    const isInvalid = Boolean(errors[name])

    const handleOnBlur = (): void => {
        setFieldFocused(false)
        if (isSubmitted) trigger(name)
    }

    return (
        <FormBlockWithLabel label={label} isFirstChildInput>
            <div className="flex-1 d-flex flex-column">
                <AssuraFormInputRHFButton
                    id={testId}
                    name={name}
                    isInvalid={isInvalid}
                    onBlur={handleOnBlur}
                    onFocus={() => setFieldFocused(true)}
                    register={register}
                    rules={rules}
                    hasButton={fieldFocused}
                    onButtonClick={() => setValue(name, '')}
                    iconClass="icon assura-close size-24"
                    placeHolder={placeHolder}
                />
                {fieldFocused ? (
                    <div className="input-with-helper-text-container">
                        <div className="labelExtraSmall m-bottom-8">{inputHelper.title}</div>
                        {Object.keys(inputHelper.validations).map((key) => {
                            const helper = inputHelper.validations[key]
                            return (
                                <div
                                    className={`labelExtraSmall d-flex c-${
                                        helper.valid ? 'success' : 'primary'
                                    }`}
                                    key={`helper-${key}`}
                                >
                                    <i
                                        data-testid={`${testId}-helper-rule-${key}-icon`}
                                        className={`icon assura-${
                                            helper.valid ? 'check' : 'close'
                                        } size-16`}
                                    />
                                    <div className="m-left-8">{helper.text}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                            <div
                                className="labelExtraSmall c-primary m-top-8"
                                data-testid={testId + '-error'}
                            >
                                {t(message)}
                            </div>
                        )}
                    />
                )}
            </div>
        </FormBlockWithLabel>
    )
}

export default InputWithHelperRHF
