import { useState } from 'react'
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import {
    DROPZONE_ACCEPTED_IMAGES,
    DROPZONE_ACCEPTED_PDF,
    DROPZONE_ACCEPTED_WORD,
    DROPZONE_ACCEPTED_WORDXML
} from 'core/constants/Dropzone'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import DropzoneRHF from 'modules/services/components/DropzoneRHF/DropzoneRHF'

const GeneralQuestionDropzone = (): JSX.Element => {
    const { t } = useTranslation()

    const [importFileStatus, setImportFileStatus] = useState<LoadingStatusEnum>()
    const [filename, setFilename] = useState<string>()
    const [fileError, setFileError] = useState<string>('')

    const handleAcceptedFile = (acceptedFile: FileWithPath) => {
        setImportFileStatus(LoadingStatusEnum.OK)
        setFilename(acceptedFile.name?.split('.')?.[0])
    }

    const handleRejectedFile = (rejectedFile: FileRejection) => {
        const error = rejectedFile.errors[0]
        let fileError
        switch (error.code) {
            case ErrorCode.FileInvalidType:
                fileError = 'SERVICES.CONTACT_ATTACHMENT_IMPORT_ERROR_INVALID_TYPE'
                break
            case ErrorCode.TooManyFiles:
                fileError = 'SERVICES.CONTACT_ATTACHMENT_IMPORT_ERROR_MANY_FILES'
                break
            case ErrorCode.FileTooLarge:
                fileError = 'SERVICES.CONTACT_ATTACHMENT_IMPORT_ERROR_GENERIC'
                break
            default:
                fileError = 'SERVICES.CONTACT_ATTACHMENT_IMPORT_ERROR_GENERIC'
        }
        setImportFileStatus(LoadingStatusEnum.ERROR)
        setFilename(undefined)
        setFileError(fileError)
    }

    const resetImport = () => {
        setImportFileStatus(undefined)
        setFilename(undefined)
        setFileError('')
    }

    return (
        <div>
            <div className="labelMedium m-bottom-24">{t('DROPZONE.ATTACHMENT')}</div>
            <DropzoneRHF
                name="attachment"
                testId="general-question"
                accept={{
                    ...DROPZONE_ACCEPTED_PDF,
                    ...DROPZONE_ACCEPTED_IMAGES,
                    ...DROPZONE_ACCEPTED_WORD,
                    ...DROPZONE_ACCEPTED_WORDXML
                }}
                multiple={false}
                maxSize={4000000} //4MB
                handleRejectedFile={handleRejectedFile}
                handleAcceptedFile={handleAcceptedFile}
                importFileStatus={importFileStatus}
                changeImportFileStatus={setImportFileStatus}
                loadingText={t('DROPZONE.ATTACHMENT_IMPORT_LOADING')}
                errorText={t(fileError)}
                acceptedFileText={t('SERVICES.CONTACT_ATTACHMENT_FILES_TYPE')}
                filename={filename}
                onDelete={resetImport}
            />
        </div>
    )
}

export default GeneralQuestionDropzone
