import { useTranslation } from 'react-i18next'

import { AventuraAdditionalFamilyMemberModel } from 'core/models/services/coverage/AventuraForm'

type MemberTitleProps = {
    aventuraMembers: AventuraAdditionalFamilyMemberModel[]
}

const MemberTitle = ({ aventuraMembers }: MemberTitleProps) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="headline m-bottom-8" data-testid="services-form-members-info-title">
                {t('SERVICE.AVENTURA_MEMBERS_INFO_TITLE')}
            </div>

            <div
                className="labelSmallLight c-gray500 m-bottom-32"
                data-testid="services-form-members-count"
            >
                {aventuraMembers?.length === 1 ? (
                    <>{t('SERVICE.AVENTURA_TRIP_SUMMARY_SUB_TITLE')}</>
                ) : (
                    <>
                        {t('SERVICE.AVENTURA_TRIP_SUMMARY_MANY_SUB_TITLE', {
                            people: aventuraMembers?.length.toString()
                        })}
                    </>
                )}
            </div>
        </>
    )
}
export default MemberTitle
