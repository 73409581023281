import { ReactNode } from 'react'
import { Col, ColProps, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface AppSearchSectionBlockProps {
    testId: string
    sectionTitleKey?: string
    colProps?: ColProps
    hasTopBorder?: boolean
    backgroundColor?: string
    children: ReactNode
}

const AppSearchSectionBlock = ({
    testId,
    sectionTitleKey,
    colProps = { xs: 12 },
    hasTopBorder,
    backgroundColor,
    children
}: AppSearchSectionBlockProps): JSX.Element => {
    const { t } = useTranslation()
    let wrapperClassName = hasTopBorder ? ' border-top-solid-width-1 btc-gray100' : ''
    if (backgroundColor) wrapperClassName += ` bg-${backgroundColor}`

    return (
        <div
            className={`p-top-16 p-bottom-16${wrapperClassName}`}
            data-testid={`app-search-section-block-wrapper-${testId}`}
        >
            <Container>
                {sectionTitleKey && (
                    <Row>
                        <Col {...colProps}>
                            <div className="labelExtraSmall c-gray500 p-top-8 p-bottom-8">
                                {t(sectionTitleKey)}
                            </div>
                        </Col>
                    </Row>
                )}
                <Row>{children}</Row>
            </Container>
        </div>
    )
}

export default AppSearchSectionBlock
