export enum ServiceType {
    ACCIDENT_REPORT = 'accidentReport',
    ACCIDENT_REPORT_BIS = 'accidentReportBis',
    ADDRESS_CHANGE = 'addressChange',
    ADVISOR_CONTACT_FORM = 'advisorContactForm',
    ADVISOR_CONTACT_MOBILE = 'advisorContactMobile',
    AUTO_LAMAL_ACC_SUSP_INCL = 'automaticLAMalAccidentSuspensionInclusion',
    AVENTURA_INSURANCE_REQUEST = 'aventureInsuranceRequest',
    BANK_REL_CHANGE = 'bankingRelationshipsChange',
    BILLING_QUESTION = 'billingQuestion',
    CDEC_REQUEST = 'cdecRequest',
    CERTIFICATE_CATTI = 'certificateCATTI',
    CERTIFICATE_CATTP = 'certificateCATTP',
    CERTIFICATE_DELEGATION = 'certificateDelegation',
    CLAIM_ABROAD = 'claimAbroad',
    CLAIM_ABROAD_REPORT = 'claimAbroadReport',
    CONTACT_LIT_DEPARTMENT = 'contactLitigationDepartment',
    CONTRACT_CHANGE = 'contractChange',
    COVER_CARD_ORDER = 'coverCardOrder',
    COVER_PAGE_PRINTING = 'coverPagePrinting',
    DEDUCTIBLE_CHANGE = 'deductibleChange',
    DENTAL_ACCIDENT_REPORT = 'dentalAccidentReport',
    E_BILLING_REG_PROC = 'eBillingRegistrationProcessing',
    E_BILLING_TERM_PROC = 'eBillingTerminationProcessing',
    EMAIL_CHANGE = 'emailChange',
    FAMILY_DOCTOR_CHANGE = 'familyDoctorChange',
    FAMILY_GROUP_CHANGE = 'familyGroupChange',
    GENERAL_QUESTION = 'generalQuestion',
    HELP_REQUEST = 'helpRequest',
    HOSP_ANNOUNCEMENT = 'hospitalizationAnnouncement',
    INSURANCE_COV_QUESTION = 'insuranceCoverageQuestion',
    LAMAL_ACC_SUSP_INCL = 'laMalAccidentSuspensionInclusion',
    LPIMP_REQUEST = 'lpimpRequest',
    LSV_REQUEST = 'lsvRequest',
    MEMBERSHIP_REQUEST = 'membershipRequest',
    NAME_CIVIL_CHANGE = 'nameOrCivilStatusChange',
    PAYMENT_FREQ_MODIFICATION = 'paymentFrequencyModification',
    PAYMENT_METHOD_CHANGE = 'paymentMethodChange',
    PHONE_CHANGE = 'phoneChange',
    POSTAL_MAILING = 'postalMailing',
    PROC_ADDITIONAL_DATA = 'processingAdditionalData',
    PROMOTIONS_FORM = 'promotionsForm',
    PROVI_REPL_CERTIFICATE = 'provisionalReplacementCertificate',
    SUBSIDIES_CONTACT = 'subsidiesContact',
    TRIP_CANCELLATION = 'tripCancellation',
    UNKNOWN = 'unknown'
}
