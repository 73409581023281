import {
    ServicesCommonRequestBody,
    ServicesCommonRequestBodyWithAllFamily
} from 'core/models/services/ServicesCommonRequestBody'

import { createSelector } from '@reduxjs/toolkit'

import { getServicesDatas } from '../services/services.slice'

export const getServicesCommonRequestBody = createSelector(
    [getServicesDatas],
    (servicesDatas): ServicesCommonRequestBody => {
        return {
            policyNumber: servicesDatas.familyGroup.selectedPolicyNumber ?? 0
        }
    }
)

export const getServicesCommonRequestBodyWithAllFamily = createSelector(
    [getServicesDatas],
    (servicesDatas): ServicesCommonRequestBodyWithAllFamily => {
        return {
            policyNumber: servicesDatas.familyGroup.selectedPolicyNumber ?? 0,
            affectsAllFamilyMembers: servicesDatas.familyGroup.isAllFamilyMembers
        }
    }
)
