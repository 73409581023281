import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import mapCommunicationsToHomeNotification from 'core/helpers/notifications/CommunicationsHelpers'
import mapEnveloppesToHomeNotification from 'core/helpers/notifications/EnveloppesHelper'
import mapMissingsAndRequestsToHomeNotification from 'core/helpers/notifications/MissingsRequestsHelper'
import mapUnpaidStatsToHomeNotification from 'core/helpers/notifications/UnpaidStatsHelper'
import { HomeNotificationsCardState } from 'core/models/HomeNotifications'

import { createSelector } from '@reduxjs/toolkit'

import {
    getCommunicationsInitLoadingStatus,
    getCommunicationsNotificationCount
} from '../communications/communications.slice'
import { getEnveloppeLoadingStatus } from '../enveloppes/enveloppes.slice'
import { getMissingDataLoadingStatus } from '../missingData/missingData.slice'
import { getServicesRequestsLoadingStatus } from '../services/requests/requests.slice'
import { getUnpaidStatsState } from '../unpaidStats/unpaidStats.slice'
import { getEnveloppesCountByStatus } from './getEnveloppesCountByStatus'
import { getFamilyGroupSettings } from './getFamilyGroupSettings'
import { getMissingDataInRequest } from './getMissingDataInRequest'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'
import { getFilteredRequests } from './services/getFilteredRequests'

export const getHomeNotificationUnpaidStats = createSelector(
    [getUnpaidStatsState, getSummaryCurrentYear],
    (unpaidStats, summary): HomeNotificationsCardState => {
        const { data, loadingStatus } = unpaidStats

        return {
            loadingStatus,
            data: data ? mapUnpaidStatsToHomeNotification(data, summary.ecStatus) : undefined
        }
    }
)

export const getHomeNotificationEnveloppes = createSelector(
    [getEnveloppesCountByStatus, getEnveloppeLoadingStatus],
    (statusCount, loadingStatus): HomeNotificationsCardState => {
        return {
            loadingStatus,
            data: mapEnveloppesToHomeNotification(statusCount)
        }
    }
)

export const getHomeNotificationCommunications = createSelector(
    [getCommunicationsNotificationCount, getCommunicationsInitLoadingStatus],
    (communicationsCount, loadingStatus): HomeNotificationsCardState => {
        return {
            loadingStatus,
            data: mapCommunicationsToHomeNotification(communicationsCount)
        }
    }
)

export const getHomeNotificationMissingsAndRequests = createSelector(
    [
        getFamilyGroupSettings,
        getFilteredRequests,
        getMissingDataInRequest,
        getMissingDataLoadingStatus,
        getServicesRequestsLoadingStatus
    ],
    (
        familyGroup,
        requests,
        missingData,
        missingDataLoading,
        requestsLoading
    ): HomeNotificationsCardState => {
        const loadingStatus = getLoadingStatusFromLoaderList([missingDataLoading, requestsLoading])

        if (missingData && requests) {
            return {
                loadingStatus,
                data: mapMissingsAndRequestsToHomeNotification(missingData, requests, familyGroup)
            }
        } else {
            return {
                loadingStatus,
                data: undefined
            }
        }
    }
)
