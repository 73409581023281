import '../AssuraMainBanner/AssuraMainBanner.css'

import bannerProps from 'core/constants/bannerProps'
import { BannerVariant } from 'core/enums/BannerVariant'

interface AssuraBannerProps {
    id: string
    message: string
    onClose?: () => void
    variant: BannerVariant
    styleOverride?: 'default' | 'relative'
    cmsLink?: JSX.Element
}

const AssuraBanner = ({
    id,
    message,
    onClose,
    variant,
    styleOverride = 'default',
    cmsLink
}: AssuraBannerProps): JSX.Element => {
    const p = bannerProps[variant]

    return (
        <div
            className={
                styleOverride === 'default'
                    ? 'assura-banner-container'
                    : 'assura-banner-container-relative'
            }
            data-testid={`${id}-assura-banner-container`}
        >
            <div className="container">
                <div className={`inner bg-${p.backgroundColor}`}>
                    <i className={`icon size-24 assura-${p.iconTitle} c-${p.iconColor}`} />
                    <div className="assura-banner-message-container labelExtraSmall">
                        <div data-testid={`${id}-assura-banner-message`}>{message}</div>
                        {cmsLink}
                    </div>
                    {onClose && (
                        <div
                            data-testid={`${id}-assura-banner-close-button`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onClose()}
                        >
                            <i className="icon size-16 assura-close c-black" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AssuraBanner
