import { RequestStatus } from 'core/enums/RequestStatus'
import { ServiceType } from 'core/enums/ServiceType'

import { createSelector } from '@reduxjs/toolkit'

import { getServicesRequests } from '../services/requests/requests.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export interface PersonalDataPendingRequests {
    basic: boolean
    phone: boolean
    email: boolean
    address: boolean
}

export const getPersonalDataPendingRequests = createSelector(
    [getSelectedMemberPolicyNumber, getConnectedFamilyMember, getServicesRequests],
    (selectedPolicyNumber, connectedPerson, requests): PersonalDataPendingRequests => {
        const result: PersonalDataPendingRequests = {
            basic: false,
            phone: false,
            email: false,
            address: false
        }

        const keysOfPersonalDataPendingRequests = [
            ['basic', ServiceType.NAME_CIVIL_CHANGE],
            ['phone', ServiceType.PHONE_CHANGE],
            ['email', ServiceType.EMAIL_CHANGE],
            ['address', ServiceType.ADDRESS_CHANGE]
        ]

        keysOfPersonalDataPendingRequests.forEach((key) => {
            const hasPendingRequest = requests.find(
                (request) =>
                    request.status === RequestStatus.PENDING &&
                    request.type === key[1] &&
                    request.policyNumber ===
                        (connectedPerson && key[0] === 'address'
                            ? connectedPerson.policyNumber
                            : selectedPolicyNumber)
            )

            result[key[0] as keyof PersonalDataPendingRequests] = !!hasPendingRequest
        })

        return result
    }
)
