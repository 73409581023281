import EmailField from 'modules/services/components/EmailField/EmailField'
import PhoneField from 'modules/services/components/PhoneField/PhoneField'

const ContactBlock = (): JSX.Element => {
    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <PhoneField />
            <div className="m-top-48">
                <EmailField />
            </div>
        </div>
    )
}

export default ContactBlock
