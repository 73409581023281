import { AddMemberFormProps } from 'core/models/services/familyGroup/AddMember'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const familyGroupSlice = createSlice({
    name: 'servicesFamilyGroup',
    initialState: {},
    reducers: {
        onSubmitAddMember: (state, _action: PayloadAction<AddMemberFormProps>) => {
            return state
        }
    }
})

export const { onSubmitAddMember } = familyGroupSlice.actions

export default familyGroupSlice.reducer
