import { FormCategory, RequestPurpose } from 'core/enums/AnalyticsEnums'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { SelectedServiceForm } from 'core/models/analytics/analytics'
import { GoodToKnowCms } from 'core/models/cms/GoodToKnowCms'
import { ServiceRoute } from 'core/models/services/ServicesRoute'
import { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

interface FormSubmittedPayload {
    redirectUrl?: string
    websiteRedirectUrl?: string
    toastMessage?: string
    errorMessage?: string
    form_category: FormCategory
    request_purpose: RequestPurpose
}

interface FormSubmittedModel {
    redirectRouteName: string
    toastMessage: string
}

interface DataFetchContactDataAndProducts {
    policyNumber: number
    reload?: boolean
}

interface DataFetchSummaryAndProducts {
    policyNumber: number
    year: number
    reload?: boolean
}

export interface ServicesState {
    familyGroup: FamilyGroupResult
    submitServiceStatus: LoadingStatusEnum
    formDataLoadingStatus: LoadingStatusEnum
    goodToKnowCms: GoodToKnowCms[]
    goToServicesFormSagaExecuted: boolean
    keepSelectedMember?: FamilyGroupResult
    selectedServiceForm?: SelectedServiceForm
}

const INITIAL_STATE_SERVICES: ServicesState = {
    familyGroup: {
        selectedPolicyNumber: 0,
        isAllFamilyMembers: false
    },
    submitServiceStatus: LoadingStatusEnum.OK,
    formDataLoadingStatus: LoadingStatusEnum.LOADING,
    goodToKnowCms: [],
    goToServicesFormSagaExecuted: false,
    keepSelectedMember: undefined,
    selectedServiceForm: undefined
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState: INITIAL_STATE_SERVICES,
    reducers: {
        goToServicesForm: (state, _action: PayloadAction<ServiceRoute>) => {
            state.goToServicesFormSagaExecuted = true
            return state
        },
        formSubmitted: (state, _action: PayloadAction<FormSubmittedPayload>) => {
            return state
        },
        formSubmittedWithCustomMessage: (state, _action: PayloadAction<FormSubmittedModel>) => {
            return state
        },
        setFamilyGroupResult: (state, action: PayloadAction<FamilyGroupResult>) => {
            state.familyGroup = action.payload
        },
        setSubmitServiceStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.submitServiceStatus = action.payload
        },
        setFormDataLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.formDataLoadingStatus = action.payload
        },
        fetchContactDataAndProducts: (
            state,
            _action: PayloadAction<DataFetchContactDataAndProducts>
        ) => {
            return state
        },
        fetchSummaryAndProducts: (state, _action: PayloadAction<DataFetchSummaryAndProducts>) => {
            return state
        },
        fetchGoodToKnowCms: (state) => {
            return state
        },
        setGoodToKnowCms: (state, action: PayloadAction<GoodToKnowCms[]>) => {
            state.goodToKnowCms = action.payload
        },
        setKeepSelectedMember: (state, action: PayloadAction<FamilyGroupResult | undefined>) => {
            state.keepSelectedMember = action.payload
        },
        setSelectedServiceForm: (state, action: PayloadAction<SelectedServiceForm | undefined>) => {
            state.selectedServiceForm = action.payload
        }
    }
})

export const {
    goToServicesForm,
    formSubmitted,
    formSubmittedWithCustomMessage,
    setFamilyGroupResult,
    setSubmitServiceStatus,
    setFormDataLoadingStatus,
    fetchContactDataAndProducts,
    fetchSummaryAndProducts,
    fetchGoodToKnowCms,
    setGoodToKnowCms,
    setKeepSelectedMember,
    setSelectedServiceForm
} = servicesSlice.actions

export const getFamilyGroupResult: Selector<RootState, FamilyGroupResult> = (state) =>
    state.services.familyGroup

export const getSelectedMemberPolicyNumber: Selector<RootState, number> = (state: RootState) =>
    state.services.familyGroup.selectedPolicyNumber

export const getServicesDatas: Selector<RootState, ServicesState> = (state) => state.services

export const getServiceSubmitStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.services.submitServiceStatus

export const getFormDataLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.services.formDataLoadingStatus

export const getGoodToKnowCms: Selector<RootState, GoodToKnowCms[]> = (state) =>
    state.services.goodToKnowCms

export const getGoToServicesFormSagaExecuted: Selector<RootState, boolean> = (state) =>
    state.services.goToServicesFormSagaExecuted

export const getKeepSelectedMember: Selector<RootState, FamilyGroupResult | undefined> = (state) =>
    state.services.keepSelectedMember

export const getSelectedServiceForm: Selector<RootState, SelectedServiceForm | undefined> = (
    state
) => state.services.selectedServiceForm

export default servicesSlice.reducer
