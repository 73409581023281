import './MobileAppSplashScreen.css'

import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import image from 'shared/assets/images/ec-homepage-splashscreen-crop.png'
import AppStoreBadge from 'shared/components/MobileStore/AppStoreBadge'
import PlayStoreBadge from 'shared/components/MobileStore/PlayStoreBadge'
import { getAssets } from 'shared/store/selectors/getAssets'

const MobileAppSplashScreen = () => {
    const { t } = useTranslation()
    const assets = useSelector(getAssets)

    return (
        <Container className={`mobile-app-splashscreen-frame d-flex `}>
            <div className={`mobile-app-splashscreen-row d-flex`}>
                <div className={`moa-ss-phones-column d-flex `}>
                    <div className="moa-ss-phones-frame ">
                        <div
                            className="moa-ss-phones "
                            style={{
                                backgroundImage: `url(${assets.SplashScreen_Phones_Images?.fr ?? image})`
                            }}
                        ></div>
                    </div>
                </div>
                <div className={`moa-ss-text-column d-flex `}>
                    <div className="moa-ss-text-frame d-flex ">
                        <div className="moa-ss-text-title labelExtraSmallMedium d-flex align-items-center justify-content-center m-bottom-16">
                            {t('SPLASHSCREEN.TITLE')}
                        </div>
                        <div className="moa-ss-text-punchline headlineLargeMedium  d-flex align-items-center justify-content-center m-bottom-24">
                            {t('SPLASHSCREEN.PUNCHLINE')}
                        </div>
                        <div className="moa-ss-text-features paragraphLight  d-flex align-items-center justify-content-center m-bottom-48">
                            {t('SPLASHSCREEN.FEATURES')}
                        </div>
                        <div className="moa-ss-badges-frame d-flex">
                            <PlayStoreBadge
                                testId="moa-ss-playstore-badge"
                                width={160}
                                height={47}
                                classNames="moa-ss-playstore-badge-frame"
                            />
                            <AppStoreBadge
                                testId="moa-ss-appstore-badge"
                                width={160}
                                height={47}
                                classNames=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default MobileAppSplashScreen
