import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    InvoiceChecking,
    InvoiceCheckingDetails,
    InvoicesToCheckFilterSelectionValue
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import { TableFilterState } from 'core/models/TableFilter'
import useQuery from 'core/services/useQuery'
import AssuraTable from 'shared/components/AssuraTable/AssuraTable'
import { PanelDatasProvider } from 'shared/contexts/PanelDatasContext'
import { getLang } from 'shared/store/general/general.slice'
import {
    fetchInvoicesToCheck,
    fetchInvoicesToCheckDetails,
    getInvoicesToCheckFilteringStatus,
    getInvoicesToCheckLoadingStatus
} from 'shared/store/invoicesToCheck/invoicesToCheck.slice'
import { getSortedInvoicesToCheckForTable } from 'shared/store/selectors/getSortedInvoicesToCheckForTable'
import { useAppDispatch } from 'shared/store/store'

import InvoicesToCheckPanel from '../InvoicesToCheckPanel/InvoicesToCheckPanel/InvoicesToCheckPanel'
import InvoicesToCheckRowUI from '../InvoicesToCheckRow/InvoicesToCheckRowUI'
import InvoicesToCheckTableFilter from './InvoicesToCheckTableFilter'
import { getFilteredInvoicesToCheck } from './InvoicesToCheckTableHelper'

const InvoicesToCheckTable = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigation = useNavigate()
    const { t } = useTranslation()
    const initialFilters = InvoicesToCheckTableFilter()

    const query = useQuery()

    const externalInvoiceToCheckId = query.get('id')
    const itcOpenUnique = query.get('itc-open-unique')

    const [itcOpenUniqueFlag, setItcOpenUniqueFlag] = useState<boolean>(false)
    const [itcOpenFromExternalId, setItcOpenFromExternalId] = useState<string | undefined>()

    const lang = useSelector(getLang)
    const invoicesToCheckLoadingStatus = useSelector(getInvoicesToCheckLoadingStatus)
    const filteringStatus = useSelector(getInvoicesToCheckFilteringStatus)

    const sortedInvoices = useSelector(getSortedInvoicesToCheckForTable) as InvoiceCheckingDetails[]

    const [filters, setFilters] = useState<TableFilterState<InvoicesToCheckFilterSelectionValue>>({
        ...initialFilters
    })

    const [invoicesRows, setInvoicesRows] = useState<InvoiceChecking[]>([])

    const onFilterSelection = (
        newFilterState: TableFilterState<InvoicesToCheckFilterSelectionValue>
    ) => {
        setFilters({ ...newFilterState })
    }

    const onRowSelection = (row: InvoiceChecking) => {
        dispatch(fetchInvoicesToCheckDetails(Number(row.id)))
    }

    useEffect(() => {
        dispatch(fetchInvoicesToCheck())
    }, [lang])

    useEffect(() => {
        const filteredAndSortedInvoices = getFilteredInvoicesToCheck(sortedInvoices, filters)
        setInvoicesRows(filteredAndSortedInvoices)
    }, [sortedInvoices, filters])

    useEffect(() => {
        if (!itcOpenFromExternalId || sortedInvoices.length === 0) return
        dispatch(fetchInvoicesToCheckDetails(Number(itcOpenFromExternalId)))
        setItcOpenFromExternalId(undefined)
        // reset query string
        navigation(location.pathname, { replace: false })
    }, [itcOpenFromExternalId, sortedInvoices])

    useEffect(() => {
        if (!itcOpenUniqueFlag) return

        const itemToOpen = sortedInvoices.filter(
            (o) => o.status === InvoiceCheckingStatusEnum.TO_CHECK
        )

        if (itemToOpen.length === 1) {
            // open detail if only one invoice
            dispatch(fetchInvoicesToCheckDetails(Number(itemToOpen[0].id)))
            // and/or clear trigger
            setItcOpenUniqueFlag(false)
        }
        // and/or clear query
        navigation(location.pathname, { replace: false })
    }, [sortedInvoices, itcOpenUniqueFlag])

    useEffect(() => {
        if (itcOpenUnique === '1') {
            setItcOpenUniqueFlag(true)
        }
        if (externalInvoiceToCheckId) {
            setItcOpenFromExternalId(externalInvoiceToCheckId)
        }
    }, [itcOpenUnique, externalInvoiceToCheckId])

    return (
        <>
            <div className="side-navigation-content-table">
                <AssuraTable
                    id={'invoices-to-check'}
                    rows={invoicesRows}
                    rowComponent={(row) => <InvoicesToCheckRowUI row={row} />}
                    filters={filters}
                    loadStatus={invoicesToCheckLoadingStatus}
                    paginationStatus={LoadingStatusEnum.OK}
                    filteringStatus={filteringStatus}
                    onReload={() => dispatch(fetchInvoicesToCheck())}
                    onFilterChange={onFilterSelection}
                    onRowSelection={onRowSelection}
                    noResultMessages={{
                        datas: { label: t('COMMUNICATIONS.INVOICES_TO_CHECK.NO_INVOICES') },
                        filters: { label: t('INBOX.EMPTY_VIEW_FILTERS_TITLE') }
                    }}
                />
            </div>
            <PanelDatasProvider>
                <InvoicesToCheckPanel />
            </PanelDatasProvider>
        </>
    )
}

export default InvoicesToCheckTable
