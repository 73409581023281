import { useTranslation } from 'react-i18next'

interface FrequencySelectItemRebateProps {
    rebate: number
}

const FrequencySelectItemRebate = ({ rebate }: FrequencySelectItemRebateProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column justify-content-center align-items-center m-right-32">
            <div className="labelExtraSmallMedium c-gray500">{t('Rabais')}</div>
            <div className="headlineSmallMedium">{`${rebate}%`}</div>
        </div>
    )
}

export default FrequencySelectItemRebate
