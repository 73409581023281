import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { GeneralQuestionTopic } from 'core/models/cms/GeneralQuestionTopicCms'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

export interface GeneralQuestionTopicState {
    generalQuestionTopicLoadingStatus: LoadingStatusEnum
    generalQuestionTopic: GeneralQuestionTopic[]
}

const INITIAL_STATE_GENERAL_QUESTION: GeneralQuestionTopicState = {
    generalQuestionTopicLoadingStatus: LoadingStatusEnum.LOADING,
    generalQuestionTopic: []
}

export const generalQuestionTopicSlice = createSlice({
    name: 'generalQuestionTopic',
    initialState: INITIAL_STATE_GENERAL_QUESTION,
    reducers: {
        setGeneralQuestionTopicLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.generalQuestionTopicLoadingStatus = action.payload
        },
        fetchGeneralQuestionTopic: (state) => {
            return state
        },
        setGeneralQuestionTopic: (state, action: PayloadAction<GeneralQuestionTopic[]>) => {
            state.generalQuestionTopic = action.payload
        }
    }
})

export const {
    setGeneralQuestionTopicLoadingStatus,
    fetchGeneralQuestionTopic,
    setGeneralQuestionTopic
} = generalQuestionTopicSlice.actions

export const getGeneralQuestionTopicLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state
) => state.generalQuestionTopic.generalQuestionTopicLoadingStatus

export const getGeneralQuestionTopic: Selector<RootState, GeneralQuestionTopic[]> = (state) =>
    state.generalQuestionTopic.generalQuestionTopic

export default generalQuestionTopicSlice.reducer
