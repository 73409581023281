import './AttestationType.css'

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'

const AttestationType = (): JSX.Element => {
    const {
        getValues,
        register,
        clearErrors,
        formState: { errors }
    } = useFormContext()
    const { t } = useTranslation()

    const validateAttestationType = () => {
        const { baseInsurance, complementaryInsurance } = getValues()
        if (baseInsurance || complementaryInsurance) {
            clearErrors('baseInsurance')
            clearErrors('complementaryInsurance')
            return true
        } else {
            return 'SERVICES.ATTESTATION_TYPE_ERROR'
        }
    }

    const attestationTypeError =
        t(errors?.baseInsurance?.message as string) ||
        t(errors?.complementaryInsurance?.message as string)

    return (
        <Row>
            <Col xl={4} md={12} className="d-flex">
                <div className="paragraphMedium">{t('SERVICES.ATTESTATION_TYPE')}</div>
            </Col>
            <Col xl={8} md={12} className="d-flex flex-column">
                <div className="d-flex attestation-type-container">
                    <CheckboxRHF
                        id={'baseInsurance'}
                        name="baseInsurance"
                        label={t('SERVICES.BASE_INSURANCE')}
                        register={register}
                        classNames="flex-1 attestation-type"
                        rules={{
                            validate: validateAttestationType
                        }}
                    />
                    <CheckboxRHF
                        id={'complementaryInsurance'}
                        name="complementaryInsurance"
                        label={t('SERVICES.COMPLEMENTARY_INSURANCE')}
                        register={register}
                        classNames="flex-1 attestation-type"
                        rules={{
                            validate: validateAttestationType
                        }}
                    />
                </div>
                {attestationTypeError && (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid="services-attestation-type-error"
                    >
                        {attestationTypeError}
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default AttestationType
