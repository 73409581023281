import { MaritalStatusEnum, UndefinedMaritalStatusEnum } from 'core/enums/FamilyGroup'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import { ViewAddress } from 'core/models/familyGroup/ViewAddress'

import { createSelector } from '@reduxjs/toolkit'

import { getPersonalDataPendingRequests } from './getPersonalDataPendingRequests'
import { getSelectedMemberContact } from './getSelectedMemberContact'
import { getSelectedMemberSummary } from './getSelectedMemberSummary'

export interface BasicView {
    firstName: string
    lastName: string
    maritalStatus: MaritalStatusEnum | null
    dateOfBirth: string
    gender: string
    isPending: boolean
}

export interface PhoneView {
    data: PhoneDatas | null
    isPending: boolean
}

export interface EmailView {
    data: string | null
    isPending: boolean
}

export interface AddressView {
    data: ViewAddress | null
    isPending: boolean
}

export interface PersonalDataView {
    contactDataLoadStatus: LoadingStatusEnum
    policyNumber: number
    basic: BasicView
    phone: PhoneView
    email: EmailView
    address: AddressView
}

export const getFullPersonalData = createSelector(
    [getSelectedMemberSummary, getSelectedMemberContact, getPersonalDataPendingRequests],
    (personSummary, contact, personalDataPendingRequests): PersonalDataView => {
        const contactData = contact?.datas
        const unknownMaritalStatus = [
            UndefinedMaritalStatusEnum.unknown,
            UndefinedMaritalStatusEnum.undefined
        ]

        const maritalStatus =
            personSummary &&
            !unknownMaritalStatus.includes(
                personSummary.maritalStatus as UndefinedMaritalStatusEnum
            )
                ? (personSummary.maritalStatus as MaritalStatusEnum)
                : null

        return {
            contactDataLoadStatus: contact?.loadingStatus ?? LoadingStatusEnum.LOADING,
            policyNumber: personSummary?.policyNumber ?? 0,
            basic: {
                firstName: personSummary?.firstName ?? '',
                lastName: personSummary?.lastName ?? '',
                maritalStatus,
                dateOfBirth: personSummary?.dateOfBirth ?? '',
                gender: personSummary?.gender ?? '',
                isPending: personalDataPendingRequests.basic
            },
            phone: {
                data: contactData?.phone ?? null,
                isPending: personalDataPendingRequests.phone
            },
            email: {
                data: contactData?.email ?? null,
                isPending: personalDataPendingRequests.email
            },
            address: {
                data: contactData?.address ?? null,
                isPending: personalDataPendingRequests.address
            }
        }
    }
)
