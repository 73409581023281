import { CSSProperties } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'

import { withError } from 'shared/hoc/WithError'
import { withIconButton } from 'shared/hoc/WithIconButton'

import PickerDayWithYear from '../DatePicker/PickerDayWithYear/PickerDayWithYear'

interface DatePickerWithLabelRHFProps {
    name: string
    label: string
    placeholderKey: string
    rules?: RegisterOptions
    className?: string
    style?: CSSProperties
    testId: string
    inline?: boolean
    minDate?: Date | null
    maxDate?: Date | null
    addFuturYearHeadroomCount?: number
    register?: UseFormRegister<FieldValues>
    showTimeSelect?: boolean
    showTimeSelectOnly?: boolean
    onDateChange?: ((date: Date | [Date | null, Date | null] | null) => void) | undefined
    onFocus?: (event: FocusEvent) => void
    labelClassName?: string
}

const DatePickerWithLabelRHF = ({
    name,
    label,
    placeholderKey,
    rules,
    className,
    style,
    testId,
    minDate,
    maxDate,
    addFuturYearHeadroomCount,
    inline = true,
    register,
    showTimeSelect,
    showTimeSelectOnly,
    onDateChange,
    onFocus,
    labelClassName
}: DatePickerWithLabelRHFProps): JSX.Element => {
    const { t } = useTranslation()
    const { control } = useFormContext()
    return (
        <div className={className} style={style}>
            <Row
                style={{
                    flexDirection: inline ? 'row' : 'column',
                    alignItems: inline ? 'center' : ''
                }}
                data-testid={`${testId}-row`}
            >
                <Col md={inline ? 4 : 12} xs={12}>
                    <div className={`${labelClassName ? labelClassName : 'paragraphMedium'}`}>
                        {t(label)}
                    </div>
                </Col>
                <Col>
                    <PickerDayWithYear
                        placeholder={t(placeholderKey)}
                        testId={testId}
                        control={control}
                        name={name}
                        rules={rules}
                        minDate={minDate}
                        maxDate={maxDate}
                        onFocus={onFocus}
                        addFuturYearHeadroomCount={addFuturYearHeadroomCount}
                        register={register}
                        showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        onDateChange={onDateChange}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default DatePickerWithLabelRHF

export const DatePickerWithLabelAndErrorsRHF = withError(DatePickerWithLabelRHF)
export const DatePickerWithLabelAndErrorsAndButtonRHF = withIconButton(
    withError(DatePickerWithLabelRHF)
)
