import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { quickAccessCmsData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { QuickAccessCms } from 'core/models/cms/QuickAccessCms'

import {
    fetchQuickAccessCms,
    fetchQuickAccessCmsSuccess,
    setQuickAccessLoadingStatus
} from './quickAccess.slice'

function* fetchQuickAccessCmsSaga() {
    try {
        yield put(setQuickAccessLoadingStatus(LoadingStatusEnum.LOADING))
        const quickAccessData: QuickAccessCms[] = yield call(quickAccessCmsData)
        const sortedQuickAccess: QuickAccessCms[] = quickAccessData?.sort(
            (a, b) => Number(a.order) - Number(b.order) || Number(a._id) - Number(b._id)
        )

        yield put(fetchQuickAccessCmsSuccess(sortedQuickAccess))
        yield put(setQuickAccessLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setQuickAccessLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchQuickAccessCmsSaga Error', e)
    }
}

function* fetchQuickAccessCmsWatcher() {
    yield takeEvery(fetchQuickAccessCms.type, fetchQuickAccessCmsSaga)
}

const watchers = [fork(fetchQuickAccessCmsWatcher)]

export default watchers
