import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedEnveloppeIdState } from '../enveloppes/enveloppes.slice'
import { getEnveloppesForDataTable } from './getEnveloppesForDataTable'

export const getSelectedEnveloppe = createSelector(
    [getEnveloppesForDataTable, getSelectedEnveloppeIdState],
    (list, selectedId): EnveloppeRow | undefined => list.find((env) => env.id === selectedId)
)
