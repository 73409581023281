import './SettingsCredentialsEditPasswordForm.css'

import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Credentials from 'core/constants/translationKeys/credentials'
import { assuraProblems } from 'core/enums/AssuraProblems'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { PasswordForm } from 'core/models/services/settings/PasswordForm'
import { setValue } from 'core/validators/helpers'
import {
    useNewPasswordValidation,
    useOldPasswordValidation,
    usePasswordCompareValidation
} from 'core/validators/password'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraFormInputWithValidations from 'shared/components/AssuraFormInputWithValidations/AssuraFormInputWithValidations'
import { FieldsContextProvider } from 'shared/contexts/FieldsContext'
import {
    getCredentialSubmissionStatus,
    getUpdateResults,
    onSubmitUpdatePassword,
    setUpdateStatus,
    UpdateResult
} from 'shared/store/settings/credentials/credentials.slice'
import { useAppDispatch } from 'shared/store/store'

const fieldNames = {
    newPassword: 'newPassword',
    oldPassword: 'oldPassword',
    newPasswordCheck: 'newPasswordCheck'
}

const initialFieldsData: PasswordForm = { newPassword: '', oldPassword: '', newPasswordCheck: '' }

const SettingsCredentialsEditPasswordForm = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(LoadingStatusEnum.OK)

    const updateStatus = useSelector(getCredentialSubmissionStatus)

    const [unHandledErrorVisible, setUnHandledErrorVisible] = useState(false)
    const updateResults = useSelector(getUpdateResults)

    const formMethods = useForm<PasswordForm>({
        mode: 'onSubmit',
        defaultValues: initialFieldsData
    })

    const { watch, setError, reset, clearErrors } = formMethods

    const fieldCollection = Object.entries(initialFieldsData).map((o) => {
        return {
            name: o[0],
            isDirty: false,
            isFocus: false,
            isPristine: true
        }
    })

    const data = watch()

    const [
        oldPasswordValidationResult,
        oldPasswordValidationRules,
        oldPasswordOptions
    ] = useOldPasswordValidation(setValue(data.oldPassword))

    const [
        newPasswordValidationResult,
        newPasswordValidationRules,
        newPasswordOptions
    ] = useNewPasswordValidation(setValue(data.newPassword))

    useEffect(() => {
        return () => {
            dispatch(setUpdateStatus(LoadingStatusEnum.OK))
        }
    }, [])

    useEffect(() => {
        setUnHandledErrorVisible(updateStatus === LoadingStatusEnum.ERROR)
    }, [updateStatus])

    const [
        checkPasswordValidationResult,
        checkPasswordValidationRules,
        checkPasswordOptions
    ] = usePasswordCompareValidation(setValue(data.newPassword, data.newPasswordCheck))

    useEffect(() => {
        if (!updateResults) return
        handleUpdateResults(updateResults)
    }, [updateResults])

    const handleUpdateResults = (result: UpdateResult) => {
        setUnHandledErrorVisible(false)
        clearErrors()
        reset()
        switch (result.type) {
            case assuraProblems.PasswordIncorrect:
                setError('oldPassword', {
                    message: t(Credentials.password.errors.currentIsIncorrect)
                })
                return
            case assuraProblems.PasswordLength:
                setError('oldPassword', {
                    message: t(Credentials.password.errors.lengthOverflow)
                })
                return
            case assuraProblems.PasswordFormat:
                setError('oldPassword', {
                    message: t(Credentials.password.errors.format)
                })
                return
            case assuraProblems.NotSamePassword:
                setError('oldPassword', {
                    message: t(Credentials.password.errors.compareEqualityNotMatch)
                })
                return
            default:
                setIsUpdating(LoadingStatusEnum.OK)
                setUnHandledErrorVisible(true)
                return
        }
    }

    const onSubmitResolved = (data: FieldValues) => {
        const formToSubmit: PasswordForm = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
            newPasswordCheck: data.newPasswordCheck
        }
        dispatch(onSubmitUpdatePassword(formToSubmit))
    }

    return (
        <>
            {unHandledErrorVisible && (
                <div className="settings-credentials-error-banner-container">
                    <AssuraBanner
                        id="settings-credentials-error-banner-error"
                        data-testid="settings-credentials-error-banner-container-test-id"
                        message={t(Credentials.password.errors.unknownException)}
                        onClose={() => setUnHandledErrorVisible(false)}
                        variant="alert"
                    />
                </div>
            )}
            <div className="settings-credentials-edit-card">
                <FormProvider {...formMethods}>
                    <Form className="m-top-48">
                        <FieldsContextProvider fieldList={fieldCollection}>
                            <AssuraFormInputWithValidations
                                name={fieldNames.oldPassword}
                                label={t(Credentials.password.edit.oldPasswordLabel)}
                                placeHolder={t(
                                    Credentials.password.edit.oldPasswordLabelPlaceHolder
                                )}
                                dataTestId="credentials-old-password"
                                type="password"
                                validationResults={oldPasswordValidationResult}
                                validationRules={oldPasswordValidationRules()}
                                options={oldPasswordOptions}
                            />

                            <AssuraFormInputWithValidations
                                name={fieldNames.newPassword}
                                label={t(Credentials.password.edit.newPasswordLabel)}
                                placeHolder={t(
                                    Credentials.password.edit.newPasswordLabelPlaceHolder
                                )}
                                dataTestId="credentials-new-password"
                                type="password"
                                validationResults={newPasswordValidationResult}
                                validationRules={newPasswordValidationRules()}
                                options={newPasswordOptions}
                            />

                            <AssuraFormInputWithValidations
                                name={fieldNames.newPasswordCheck}
                                label={t(Credentials.password.edit.confirmPasswordLabel)}
                                placeHolder={t(
                                    Credentials.password.edit.confirmPasswordLabelPlaceHolder
                                )}
                                dataTestId="credentials-confirm-password"
                                type="password"
                                validationResults={checkPasswordValidationResult}
                                validationRules={checkPasswordValidationRules()}
                                options={checkPasswordOptions}
                            />
                        </FieldsContextProvider>
                        <div className="settings-credentials-password-banner m-top-40 bg-gray100 c-black">
                            <i className="icon size-24 assura-info-circle m-right-8" />
                            <div className="paragraphSmall">{t('ACCOUNT_EDIT.PASSWORD_INFO')}</div>
                        </div>
                        <div className="m-top-48">
                            <SubmitButtonWithScroll
                                text={t(Credentials.sendButtonLabel)}
                                disabled={isUpdating === LoadingStatusEnum.LOADING}
                                onSubmit={onSubmitResolved}
                                id="services-form-submit-button"
                                hasLoader={isUpdating === LoadingStatusEnum.LOADING}
                            />
                        </div>
                    </Form>
                </FormProvider>
            </div>
        </>
    )
}

export default SettingsCredentialsEditPasswordForm
