import { useState } from 'react'
import { useSelector } from 'react-redux'

import { submitProvisionalReplacementCertificateDL } from 'core/api/services/Certificates'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { ProvisionalReplacementCertificateRequestBody } from 'core/models/services/certificates/ProvisionalReplacementCertificateForm'
import { addDays } from 'date-fns'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'
import { useAppDispatch } from 'shared/store/store'

import DocumentsCardLink from './DocumentsCardLink'

const DocumentsCardLinkReplacement = (): JSX.Element => {
    const dispatch = useAppDispatch()

    const selectedMember = useSelector(getSelectedFamilyMember)

    const [isLoaderVisible, setIsLoaderVisible] = useState(false)

    const isLoading = (isLoading: boolean) => {
        setIsLoaderVisible(isLoading)
    }

    const isOnError = (isOnError: boolean) => {
        setIsLoaderVisible(false)
        if (isOnError)
            dispatch(
                setBanner({
                    dataTestId: 'documents-card-replacement',
                    message: 'COMMON.ERROR'
                })
            )
    }

    const body: ProvisionalReplacementCertificateRequestBody = {
        policyNumber: selectedMember.policyNumber,
        validityStartDate: formatDateToApiFormat(new Date()),
        validityEndDate: formatDateToApiFormat(addDays(new Date(), 10))
    }

    const onReplacementClick = () => {
        submitProvisionalReplacementCertificateDL(body, isOnError, isLoading)
    }

    return (
        <DocumentsCardLink
            labelKey="SERVICES.REQUEST_TYPE_PROVI_REPL_CERTIFICATE"
            testId="replacement"
            onClick={onReplacementClick}
            isLoading={isLoaderVisible}
        />
    )
}

export default DocumentsCardLinkReplacement
