import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchAttachment } from 'core/api/DocumentFile'
import { PhoneType } from 'core/enums/PhoneType'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import { RequestDetailsValues, RequestDocument } from 'core/models/services/RequestData'
import { getPhoneNumber, phoneFormatted } from 'core/utils/phoneUtils'
import AssuraDownloadLink from 'shared/components/AssuraDownloadLink/AssuraDownloadLink'
import LabelValueInlineBlock, { LabelValueInlineBlockItem } from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import { useRequestPanelContext } from '../RequestsPanelMain/RequestsPanelMain'

interface ContactGeneralQuestionProps {
    values: RequestDetailsValues[]
    attachment?: RequestDocument
}

const ContactGeneralQuestion = ({
    values,
    attachment
}: ContactGeneralQuestionProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setloading] = useState(false)
    const setError = useRequestPanelContext()

    const message = values.find((v) => v.id === 'Notes')

    const activeFields: LabelValueInlineBlockItem[] = []

    const handleDownloadClick = () => {
        if (attachment) fetchAttachment(attachment, setError, setloading)
    }

    const regionalCode = values.find((v) => v.id === 'PhoneRegionalCode')
    const number = values.find((v) => v.id === 'PhoneNumber')
    if (regionalCode?.new && number?.new) {
        const phoneData: PhoneDatas = {
            type: 'unknown' as PhoneType,
            internationalCode: 41,
            regionalCode: regionalCode.new as string | null,
            phoneNumber: number.new as string | null
        }
        activeFields.push({
            id: 'phone',
            label: t('PERSONAL_DATA.CONTACT_PHONE_LABEL'),
            value: phoneFormatted(getPhoneNumber(phoneData))
        })
    }

    const email = values.find((v) => v.id === 'EmailAddress')
    if (email && email.new)
        activeFields.push({
            id: 'email',
            label: t('PERSONAL_DATA.EMAIL'),
            value: email.new as string
        })

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <div className="requests-panel-main-block">
                <div className="label c-gray500 m-bottom-12">
                    {t('CONTACT.GENERAL_QUESTION_REQUEST_MESSAGE')}
                </div>
                <div
                    data-testid="requests-details-contact-general-question-message"
                    className="label p-bottom-24 m-bottom-12 border-bottom-solid-width-1 bbc-gray100 white-space-pre-line"
                >
                    {(message?.new as string) ?? ''}
                </div>
            </div>
            <div className="requests-panel-main-block">
                {attachment && (
                    <LabelValueInlineBlock key="download">
                        <div>{t('CONTACT.GENERAL_QUESTION_REQUEST_FILE')}</div>
                        <AssuraDownloadLink
                            testId="requests-details-contact-general-question-download"
                            label={t('COMMON.DOWNLOAD')}
                            labelClass="label"
                            isLoading={loading}
                            onClick={handleDownloadClick}
                        />
                    </LabelValueInlineBlock>
                )}
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={field.label}>
                        <div>{field.label}</div>
                        <div data-testid={`requests-details-contact-general-question-${field.id}`}>
                            {field.value}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
        </div>
    )
}

export default ContactGeneralQuestion
