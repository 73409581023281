import './AssuraCircularProgress.css'

import { pieChartSize } from 'core/helpers/ClaimsSettlementPanelHelper'
import { CircularProgressSegment, TextProgressOptions } from 'core/models/AssuraCircularProgress'

import Segment from './components/Segment'
import TextProgress from './components/TextProgress'

interface AssuraCircularProgressProps {
    segments: CircularProgressSegment[]
    textOptions: TextProgressOptions
}

const AssuraCircularProgress = ({ segments, textOptions }: AssuraCircularProgressProps) => {
    return (
        <div
            data-testid="assura-circular-progress-container"
            style={{
                width: pieChartSize,
                height: pieChartSize,
                background: 'transparent',
                position: 'relative',
                display: 'flex',
                transformOrigin: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <svg width="100%" height="100%" style={{ transform: 'rotate(-90deg)' }}>
                {segments?.map((s: CircularProgressSegment) => {
                    return <Segment key={s.name} data={s} />
                })}
            </svg>
            <TextProgress options={textOptions} />
        </div>
    )
}
export default AssuraCircularProgress
