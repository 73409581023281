import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'

interface BasicInfoInputProps {
    id: string
    name: string
    label: string
    placeHolder?: string
}

const BasicInfoInput = ({ id, name, label, placeHolder }: BasicInfoInputProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const {
        state: { fieldFocused },
        isFormSelected,
        setFocus
    } = usePersonalDataContext()
    const isEnabled = isFormSelected(PersonalDataForm.BASIC)

    const {
        setValue,
        formState: { errors, isSubmitted },
        register
    } = useFormContext()

    const inputError =
        name === 'firstName'
            ? 'FORM.INPUT_FIRST_NAME_CHECK_LENGTH'
            : 'FORM.INPUT_LAST_NAME_CHECK_LENGTH'

    const rules = {
        minLength: { value: 2, message: inputError },
        maxLength: { value: 30, message: inputError },
        required: { value: true, message: 'COMMON.MANDATORY_FIELD' }
    }

    const handleResetAction = () => {
        setValue(name, '', {
            shouldValidate: isSubmitted
        })
    }

    const getErrorMessage = () => {
        const errorMessage = errors[name]?.message as string
        return t(
            errorMessage || '',
            errorMessage === 'FORM.INPUT_FIRST_NAME_CHECK_LENGTH' ||
                errorMessage === 'FORM.INPUT_LAST_NAME_CHECK_LENGTH'
                ? { min: 2, max: 30 }
                : {}
        )
    }

    return (
        <AssuraFormInputRHF
            id={`services-personal-data-${id}`}
            name={name}
            inline={isXL}
            labelSize={4}
            label={label}
            onBlur={() => {
                setFocus('')
            }}
            onFocus={() => setFocus(name)}
            hasButton={fieldFocused === name}
            onButtonClick={() => handleResetAction()}
            iconClass="icon assura-close size-24"
            rules={rules}
            placeHolder={placeHolder}
            isInvalid={Boolean(errors[name])}
            error={getErrorMessage()}
            register={register}
            disabled={!isEnabled}
        />
    )
}

export default BasicInfoInput
