import './AssuraInput.css'

import React from 'react'
import { Form } from 'react-bootstrap'

import { withButton } from 'shared/hoc/WithButton'
import { withError } from 'shared/hoc/WithError'
import { withIconButton } from 'shared/hoc/WithIconButton'
import { withLabel } from 'shared/hoc/WithLabel'

export interface AssuraInputProp {
    id: string
    value: string
    isInvalid?: boolean
    placeholder?: string
    onChange?: (value: string) => void
    onBlur?: (value: string) => void
    onFocus?: () => void
    minLength?: number
    maxLength?: number
    disabled?: boolean
    readOnly?: boolean
    type?: string
    classNames?: string
}

export const AssuraInput = (props: AssuraInputProp): JSX.Element => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event.target.value)
        }
    }
    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            props.onBlur(event.currentTarget.value ?? '')
        }
    }

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus()
        }
    }

    return (
        <Form.Control
            id={props.id}
            data-testid={props.id}
            isInvalid={props.isInvalid}
            type={props.type ? props.type : 'text'}
            value={props.value}
            placeholder={props.placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            className={props.classNames ? props.classNames : 'label'}
            minLength={props.minLength}
            maxLength={props.maxLength}
            disabled={props.disabled}
            readOnly={props.readOnly}
        />
    )
}

export const AssuraInputWL = withLabel(AssuraInput)
export const AssuraInputWE = withError(AssuraInput)
export const AssuraInputWB = withButton(AssuraInput)
export const AssuraFormInput = withLabel(withButton(withError(AssuraInput)))
export const AssuraFormInputWithButtonNoLabel = withIconButton(AssuraInput)
export const AssuraFormInputNoLabel = withButton(withError(AssuraInput))
