import '../../components/FormBlockWithLabel/FormBlockWithLabel.css'

import { Col, Row } from 'react-bootstrap'

import useWindowSize from 'core/services/useWindowSize'

interface WithLabelBlockProps {
    label: string
    isFirstChildInput?: boolean
    children: React.ReactNode
}
/** inspired by FormBlockWithLabel */
const WithLabelBlock = ({
    label,
    isFirstChildInput,
    children
}: WithLabelBlockProps): JSX.Element => {
    const { isMD } = useWindowSize()
    return (
        <Row>
            <Col
                xl={4}
                md={12}
                sm={12}
                style={{
                    top: 0,
                    marginTop: isMD ? '15px' : 'unset'
                }}
                className={`d-flex align-items-flex-start ${isFirstChildInput ? 'form-block-with-label-input' : ''}`}
            >
                <div className="paragraphMedium">{label}</div>
            </Col>
            <Col xl={8} md={12} sm={12} className="d-flex flex-column">
                {children}
            </Col>
        </Row>
    )
}

export default WithLabelBlock  
