import './FaqAnswerBulletPoint.css'

import { generateGUID } from 'core/helpers/GuidHelper'
import { AnswerBulletPoint } from 'core/models/Faq'
import AssuraEcLinkMarkdown from 'shared/components/AssuraEcLinkMarkdown/AssuraEcLinkMarkdown'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'

interface FaqAnswerBulletPointProps {
    answerBulletPoint: AnswerBulletPoint
}

const FaqAnswerBulletPoint = ({ answerBulletPoint }: FaqAnswerBulletPointProps): JSX.Element => {
    return (
        <div className="faq-answer-bulletpoint-container">
            {answerBulletPoint.bulletPoint && (
                <div className="faq-answer-bulletpoint-left titleLarge c-primary m-right-8">
                    {answerBulletPoint.bulletPoint}
                </div>
            )}
            <div className="d-flex flex-column justify-content-center align-items-start flex-1">
                {answerBulletPoint.title && (
                    <div className="headline p-bottom-16">
                        <AssuraMarkdown message={answerBulletPoint.title} />
                    </div>
                )}
                <div className="faq-answer-bulletpoint-content-container">
                    {answerBulletPoint.description && (
                        <div className="faq-answer-bulletpoint-content-text paragraphSmall">
                            <AssuraEcLinkMarkdown message={answerBulletPoint.description} />
                        </div>
                    )}
                    {answerBulletPoint.images && answerBulletPoint.images.length > 0 && (
                        <div className="faq-answer-bulletpoint-right">
                            {answerBulletPoint.images.map((image) => (
                                <img
                                    src={`${process.env.REACT_APP_CMS_ASSET}${image.path}`}
                                    alt="image2"
                                    className="faq-answer-bulletpoint-image"
                                    key={generateGUID()}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FaqAnswerBulletPoint
