import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FrequentRequestCms } from 'core/models/cms/FrequentRequestCms'
import { FrequentRequestsState } from 'core/models/FrequentRequest'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

const INITIAL_STATE_FREQUENT_REQUESTS: FrequentRequestsState = {
    frequentRequestsLoadingStatus: LoadingStatusEnum.LOADING,
    data: []
}

export const appSearchSlice = createSlice({
    name: 'servicesFrequentRequests',
    initialState: INITIAL_STATE_FREQUENT_REQUESTS,
    reducers: {
        setFrequentRequestsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.frequentRequestsLoadingStatus = action.payload
        },
        fetchFrequentRequests: (state) => {
            return state
        },
        setFrequentRequests: (state, action: PayloadAction<FrequentRequestCms[]>) => {
            state.data = action.payload
        }
    }
})

export const { fetchFrequentRequests, setFrequentRequestsLoadingStatus, setFrequentRequests } = appSearchSlice.actions

export const getFrequentRequestsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.servicesFrequentRequests.frequentRequestsLoadingStatus

export const getFrequentRequestsCms: Selector<RootState, FrequentRequestCms[]> = (state) =>
    state.servicesFrequentRequests.data

export default appSearchSlice.reducer
