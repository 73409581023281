import { useTranslation } from 'react-i18next'

import { PaymentTypeEnum } from 'core/enums/Documents'
import { formatNumber } from 'core/utils/TextUtils'
import { ColorsEnum } from 'shared/components/AssuraTabDropdown/Icon'

import PaymentTypeIcon from '../PaymentTypeIcon/PaymentTypeIcon'

interface ClaimsSettlementCostsLegendLabelProps {
    cost: number
    label: string
    shouldDisplayTypeIndicator?: boolean
    isRefund?: boolean
    isAssuraLegend?: boolean
    labelClassNames?: string
}

const ClaimsSettlementCostsLegendLabel = ({
    cost,
    label,
    shouldDisplayTypeIndicator,
    isRefund,
    isAssuraLegend,
    labelClassNames
}: ClaimsSettlementCostsLegendLabelProps): JSX.Element => {
    const { t } = useTranslation()

    const labelClassNamesToApplied = labelClassNames ? ` ${labelClassNames}` : ''

    return (
        <div className={`d-flex flex-column${labelClassNamesToApplied}`}>
            <div className="d-flex align-items-center">
                <div
                    style={{
                        height: 12,
                        width: 12,
                        backgroundColor: isAssuraLegend ? ColorsEnum.success : ColorsEnum.error
                    }}
                />
                <div className="labelSmall c-gray500 m-left-8">{t(label)}</div>
            </div>
            <div
                className={`${
                    shouldDisplayTypeIndicator ? 'm-top-8' : 'm-top-4'
                } d-flex align-items-center`}
            >
                {shouldDisplayTypeIndicator && (
                    <PaymentTypeIcon
                        paymentType={isRefund ? PaymentTypeEnum.REFUND : PaymentTypeEnum.INVOICE}
                        testId="claims-settlement-costs-legend-label-payment-type-icon"
                        containerClassNames="m-right-8"
                    />
                )}
                <div
                    className="labelMedium"
                    data-testid={`claims-settlement-costs-${
                        isAssuraLegend ? 'assura' : 'insured'
                    }-price`}
                >
                    {t('COMMON.PRICE', { price: formatNumber(cost) })}
                </div>
            </div>
        </div>
    )
}

export default ClaimsSettlementCostsLegendLabel
