import { CSSProperties } from 'react'
import { Col, Row } from 'react-bootstrap'

type ColrowProps = {
    ///
    isRowIf: boolean
    colClasses?: string
    rowClasses?: string
    rowStyle?: CSSProperties
    leftChildren: React.ReactNode
    rightChildren: React.ReactNode
}

const Colrow = ({
    isRowIf,
    rowStyle,
    colClasses,
    rowClasses,
    leftChildren,
    rightChildren
}: ColrowProps) => {
    return (
        <>
            {isRowIf ? (
                <Row style={rowStyle} className={rowClasses}>
                    <Col className={colClasses}>{leftChildren}</Col>
                    <Col className={colClasses}>{rightChildren}</Col>
                </Row>
            ) : (
                <>
                    <Col className={colClasses}>{leftChildren}</Col>
                    <Col>{rightChildren}</Col>
                </>
            )}
        </>
    )
}

export default Colrow
