import navigationConstants from 'core/constants/navigationConstants'
import { HomeNotificationsColors } from 'core/enums/HomeNotificationsColors'
import {
    HomeNotificationsCardData,
    HomeNotificationsCriticality
} from 'core/models/HomeNotifications'

const mapCofaToHomeNotification = (invoicesCount: number): HomeNotificationsCardData => {
    const title =
        invoicesCount > 1
            ? 'COMMUNICATIONS.INVOICES_TO_CHECK.NOTIFICATION_TITLE_PLURAL'
            : 'COMMUNICATIONS.INVOICES_TO_CHECK.NOTIFICATION_TITLE'
    const criticality: HomeNotificationsCriticality = 0
    const color = HomeNotificationsColors.BLACK
    const count = invoicesCount
    const link = `${navigationConstants.COMMUNICATIONS.url}?item=invoicesToCheck`

    return {
        testId: 'invoicesToCheck',
        criticality,
        color,
        count,
        title,
        link
    }
}

export default mapCofaToHomeNotification
