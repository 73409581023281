import { useTranslation } from 'react-i18next'

import { PhoneType } from 'core/enums/PhoneType'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import { RequestDetailsValues, RequestDetailsValuesView } from 'core/models/services/RequestData'
import { getPhoneNumber, phoneFormatted } from 'core/utils/phoneUtils'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface PersonalDataPhoneProps {
    values: RequestDetailsValues[]
}

const PersonalDataPhone = ({ values }: PersonalDataPhoneProps): JSX.Element => {
    const { t } = useTranslation()

    const regionalCode = values.find((v) => v.id === 'PhoneRegionalCode')
    const number = values.find((v) => v.id === 'PhoneNumber')
    const type = values.find((v) => v.id === 'PhoneNumberType')

    const getPhoneType = (type: PhoneType): string => {
        switch (type) {
            case 'fixed':
                return `(${t('PERSONAL_DATA.PHONE_TYPE_FIXED')}) `
            case 'mobile':
                return `(${t('PERSONAL_DATA.PHONE_TYPE_MOBILE')}) `
            default:
                return ''
        }
    }

    const oldPhoneData: PhoneDatas = {
        type: ((type?.old as string) || 'unknown').toLowerCase() as PhoneType,
        internationalCode: 41,
        regionalCode: (regionalCode?.old as string) || '',
        phoneNumber: (number?.old as string) || ''
    }

    const newPhoneData: PhoneDatas = {
        type: ((type?.new as string) || 'unknown').toLowerCase() as PhoneType,
        internationalCode: 41,
        regionalCode: (regionalCode?.new as string) || '',
        phoneNumber: (number?.new as string) || ''
    }

    const activeFields: RequestDetailsValuesView = {
        testId: 'phone',
        label: t('PERSONAL_DATA.CONTACT_PHONE_LABEL'),
        old:
            oldPhoneData.phoneNumber !== ''
                ? `${getPhoneType(oldPhoneData.type)}${phoneFormatted(
                      getPhoneNumber(oldPhoneData)
                  )}`
                : '',
        new: `${getPhoneType(newPhoneData.type)}${phoneFormatted(getPhoneNumber(newPhoneData))}`
    }

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-personal-data-${activeFields.testId}-old`}>
                        {activeFields.old as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')} />
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-personal-data-${activeFields.testId}-new`}>
                        {activeFields.new as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default PersonalDataPhone
