/* eslint-disable sonarjs/no-nested-template-literals */
import './DoctorData.css'

import { DoctorPersonalData } from 'core/models/familyGroup/Doctor'
import useWindowSize from 'core/services/useWindowSize'
import { phoneFormatted } from 'core/utils/phoneUtils'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import doctorIcon from 'shared/assets/images/doctor.png'

interface DoctorProps {
    doctor: DoctorPersonalData
    iconPosition: 'left' | 'right'
    nameClassNames?: string
}

const DoctorData = ({ doctor, iconPosition, nameClassNames }: DoctorProps): JSX.Element => {
    const { isXSD } = useWindowSize()
    const nameClassNamesToApplied = nameClassNames ?? 'labelSmallMedium'
    return (
        <div
            className={`d-flex align-items-center ${
                iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse'
            }`}
        >
            {!isXSD && <img src={doctorIcon} className="doctor-data-icon" alt="doctor-icon" />}
            <div className={`flex-1${!isXSD ? ` m-${iconPosition}-12` : ''}`}>
                <div className={`${nameClassNamesToApplied}`}>
                    {doctor.firstName} {doctor.lastName}
                </div>
                <div className="doctor-data-address-container labelExtraSmall d-flex flex-column c-gray500">
                    <div>{stringUpperCaseFirstCharacter(doctor.street)}</div>
                    <div className="doctor-data-peronal-data-line">
                        {doctor.zipCode} {doctor.locality}
                    </div>
                    {doctor.phoneNumber && (
                        <div className="doctor-data-peronal-data-line">
                            {phoneFormatted(doctor.phoneNumber)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DoctorData
