import { BilledPrimeForm } from 'core/models/services/certificates/BilledPrimeForm'
import { DelegationForm } from 'core/models/services/certificates/Delegation'
import { InsuranceAttestationForm } from 'core/models/services/certificates/InsuranceAttestationForm'
import { InsuranceCardForm } from 'core/models/services/certificates/InsuranceCardForm'
import { MedicalFeesForm } from 'core/models/services/certificates/MedicalFeesForm'
import { ProvisionalReplacementCertificateForm } from 'core/models/services/certificates/ProvisionalReplacementCertificateForm'
import { StatementDetailsForm } from 'core/models/services/certificates/StatementDetailsForm'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const certificatesSlice = createSlice({
    name: 'certificates',
    initialState: {},
    reducers: {
        onSubmitBilledPrime: (state, _action: PayloadAction<BilledPrimeForm>) => {
            return state
        },
        onSubmitInsuranceCard: (state, _action: PayloadAction<InsuranceCardForm>) => {
            return state
        },
        onSubmitMedicalFees: (state, _action: PayloadAction<MedicalFeesForm>) => {
            return state
        },
        onSubmitInsuranceAttestation: (state, _action: PayloadAction<InsuranceAttestationForm>) => {
            return state
        },
        onSubmitProvisionalReplacementCertificate: (
            state,
            _action: PayloadAction<ProvisionalReplacementCertificateForm>
        ) => {
            return state
        },
        onSubmitDelegationCertificate: (state, _action: PayloadAction<DelegationForm>) => {
            return state
        },
        onSubmitStatementDetails: (state, _action: PayloadAction<StatementDetailsForm>) => {
            return state
        }
    }
})

export const {
    onSubmitBilledPrime,
    onSubmitMedicalFees,
    onSubmitInsuranceCard,
    onSubmitInsuranceAttestation,
    onSubmitProvisionalReplacementCertificate,
    onSubmitDelegationCertificate,
    onSubmitStatementDetails
} = certificatesSlice.actions

export default certificatesSlice.reducer
