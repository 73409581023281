import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

import { RenderCurrentPageLabelProps } from '@react-pdf-viewer/page-navigation'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom'

interface EnveloppesSubmissionPanelHeaderProps {
    fileName: string
    closePanel: () => void
    toolbarPluginInstance: ToolbarPlugin
}

const EnveloppesSubmissionPanelHeader = ({
    fileName,
    closePanel,
    toolbarPluginInstance
}: EnveloppesSubmissionPanelHeaderProps): JSX.Element => {
    const { isMobile, isXL } = useWindowSize()
    const { t } = useTranslation()

    // LEFT-BLOCK
    const { CurrentPageLabel } = toolbarPluginInstance.pageNavigationPluginInstance

    const EnveloppesSubmissionPanelHeaderLeft = (): JSX.Element => {
        return (
            <div className="d-flex align-items-center labelSmall c-gray700">
                {!isMobile && (
                    <>
                        <div className="p-right-12">{t('COMMON.PAGE')}</div>
                        <CurrentPageLabel>
                            {(props: RenderCurrentPageLabelProps) => (
                                <div>{`${props.currentPage + 1} / ${props.numberOfPages}`}</div>
                            )}
                        </CurrentPageLabel>
                    </>
                )}
            </div>
        )
    }

    // CENTER-BLOCK
    const EnveloppesSubmissionPanelHeaderCenter = (): JSX.Element => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <i
                    data-testid="enveloppes-submission-panel-header-document-type"
                    className="icon assura-attachment size-24"
                />
                <span
                    data-testid="enveloppes-submission-panel-header-document-name"
                    className="p-left-8 labelMedium"
                >
                    {fileName}
                </span>
            </div>
        )
    }

    // RIGHT-BLOCK
    const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance

    const EnveloppesSubmissionPanelHeaderRight = (): JSX.Element => {
        return (
            <div
                className={`d-flex justify-content-center align-items-center m-right-${
                    isMobile ? 16 : 32
                }`}
            >
                {isXL && (
                    <>
                        <ZoomOut>
                            {(props: RenderZoomOutProps) => (
                                <div
                                    data-testid="enveloppes-submission-panel-header-zoom-out"
                                    onClick={props.onClick}
                                >
                                    <i className="icon size-24 assura-zoom-out c-black" />
                                </div>
                            )}
                        </ZoomOut>
                        <ZoomIn>
                            {(props: RenderZoomInProps) => (
                                <div
                                    data-testid="enveloppes-submission-panel-header-zoom-in"
                                    className="m-left-16"
                                    onClick={props.onClick}
                                >
                                    <i className="icon size-24 assura-zoom c-black" />
                                </div>
                            )}
                        </ZoomIn>
                    </>
                )}
            </div>
        )
    }

    return (
        <AssuraPanelHeader
            leftBlock={<EnveloppesSubmissionPanelHeaderLeft />}
            centerBlock={<EnveloppesSubmissionPanelHeaderCenter />}
            rightBlock={<EnveloppesSubmissionPanelHeaderRight />}
            closePanel={closePanel}
        />
    )
}

export default EnveloppesSubmissionPanelHeader
