import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { PaymentFrequencyRHF } from 'core/models/services/financial/PaymentFrequency'
import useWindowSize from 'core/services/useWindowSize'
import { AssuraInputWL } from 'shared/components/AssuraInput/AssuraInput'

const PaymentFrequencyStartDate = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const { watch } = useFormContext<PaymentFrequencyRHF>()
    const startDate = watch('changeDate')

    return startDate ? (
        <div className="border-solid-width-1 bg-white bc-gray100 m-top-32 p-32">
            <AssuraInputWL
                id="services-payment-frequency-start-date"
                label={t('SERVICES.START_DATE')}
                inline={isXL}
                labelSize={4}
                value={formatDate(startDate)}
                readOnly
            />
        </div>
    ) : null
}

export default PaymentFrequencyStartDate
