import axios from 'axios'
import {
    InvoiceChecking,
    InvoiceCheckingAnswers,
    InvoiceCheckingAnswersWrapper,
    InvoiceCheckingDetails
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'

export const fetchInvoiceToCheckApi = async (): Promise<InvoiceChecking[] | undefined> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/InvoicesToCheck`)
    return result.data
}

export const fetchInvoiceToCheckDetailsApi = async (
    invoiceToCheckId?: number
): Promise<InvoiceCheckingDetails | undefined> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/InvoicesToCheck/${invoiceToCheckId}`
    )
    return result.data
}

export const submitInvoiceToCheckAnswersApi = async (
    wrapper: InvoiceCheckingAnswersWrapper
): Promise<string> => {
    const body: InvoiceCheckingAnswers = {
        isConsentChecked: wrapper.isConsentChecked,
        questions: wrapper.questions
    }
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/InvoicesToCheck/${wrapper.id}/responses`,
        body
    )

    return result.data
}

export const refuseToAnswerInvoiceToCheckApi = async (
    invoiceToCheckId: string
): Promise<string> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/InvoicesToCheck/${invoiceToCheckId}/refusal`,
        {}
    )

    return result.data
}
