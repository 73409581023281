import './AssuraPdfViewer.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/print/lib/styles/index.css'

import { AssuraDocumentType } from 'core/enums/DocumentType'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { AssuraDocument } from 'core/models/AssuraDocument'

import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'

interface AssuraPdfViewerProps {
    toolbarPluginInstance: ToolbarPlugin
    doc: AssuraDocument | undefined
    renderPdfError?: () => JSX.Element
    renderPdfLoader?: () => JSX.Element
}

const AssuraPdfViewer = (props: AssuraPdfViewerProps) => {
    const { doc, renderPdfError, renderPdfLoader, toolbarPluginInstance } = props

    return (
        <div className="assura-pdf-viewer-container bg-gray100" data-testid="assura-pdf-viewer">
            {doc && doc.loadStatus === LoadingStatusEnum.OK && doc.type === AssuraDocumentType.PDF && (
                <Viewer
                    fileUrl={doc.base64}
                    // fileUrl={`test${doc.base64}`} // force error
                    plugins={[toolbarPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageFit}
                    renderError={renderPdfError}
                    renderLoader={renderPdfLoader}
                />
            )}
        </div>
    )
}
export default AssuraPdfViewer
