import './PaymentDocumentPanelBVRBlock.css'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ToastType } from 'core/enums/ToastType'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraQrCode from 'shared/components/AssuraQrCode/AssuraQrCode'
import { setToastInfo } from 'shared/store/toast/toast.slice'

interface PaymentDocumentPanelBVRBlockProps {
    selectedDocument: DocumentRow
}

const PaymentDocumentPanelBVRBlock = ({
    selectedDocument
}: PaymentDocumentPanelBVRBlockProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const bvr = selectedDocument.bvrReference

    const copyBVR = () => {
        if (bvr) {
            navigator.clipboard.writeText(bvr)
            dispatch(
                setToastInfo({
                    message: t('STATEMENTS.COPIED_BVR'),
                    type: ToastType.SUCCESS
                })
            )
        }
    }

    return (
        <div
            className="payment-document-panel-bvr-block-container"
            data-testid="payment-document-panel-bvr-block"
        >
            <>
                <div className="payment-document-panel-qr-code-container">
                    <AssuraQrCode
                        testId="payment-document-panel"
                        documentId={selectedDocument.documentId}
                    />
                </div>
                <div className="labelMedium payment-document-panel-bvr-code-info">
                    {t('STATEMENTS.PANEL_QR_CODE_SCAN')}
                </div>
                <div className="d-flex align-items-center justify-content-center m-top-40 m-bottom-40">
                    <div className="payment-document-panel-bvr-separator bg-gray100" />
                    <div className="labelExtraSmall c-gray500 payment-document-panel-bvr-separator-text">
                        {t('COMMON.OR').toUpperCase()}
                    </div>
                    <div className="payment-document-panel-bvr-separator bg-gray100" />
                </div>
                <div className="paragraphExtraSmall c-gray500">
                    {t('STATEMENTS.PANEL_BVR_HELP')}
                </div>
                <div className="labelExtraSmallMedium c-black payment-document-panel-bvr-text">
                    {bvr}
                </div>
                <AssuraButton
                    text={t('STATEMENTS.PANEL_BVR_COPY')}
                    id="payment-document-panel-bvr-copy-button"
                    variant="secondary"
                    onClick={copyBVR}
                    icon={{
                        name: 'assura-copy',
                        size: 24
                    }}
                />
            </>
        </div>
    )
}

export default PaymentDocumentPanelBVRBlock
