import { ColorsEnum, IconNamesEnum } from 'shared/components/AssuraTabDropdown/Icon'

export interface AssuraFilterDatas {
    label: string
    selectedValue: number | string
    uniqueKey: number | string
    badge?: {
        value: number | string
        color: string
    }
}

export type AssuraTabData = {
    label: string
    // used to reference explicitely the related business object if needed otherwhise use uniqueKey.
    businessId?: string | number
    // mainly used for CSS
    active: boolean
    // mainly used for separators calculation
    selectedValue: number | null
    // used internally by components should remains a number because of sorting matters
    uniqueKey: number
    leftItem?: ItemMetaData
    rightItem?: ItemMetaData
    // Will depends on what custom data the template will be built on so for this case it's any.
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    templateData?: any

    [key: string]: string | undefined | number | unknown
}

export type TabOptions = {
    // used in Tab => reprensents the selected Item .
    labelTemplate?: (
        data?: AssuraTabData | null,
        valueTemplate?: (data?: AssuraTabData) => React.ReactElement,
        onClick?: (id: AssuraTabData['uniqueKey']) => void // operates only for dropdown drawer
    ) => React.ReactElement

    // used in Dropdown => represents the selected Item / toggle label button.
    labelToggleTemplate?: (
        data?: AssuraTabData,
        valueTemplate?: (data?: AssuraTabData) => React.ReactElement,
        onClick?: (show: boolean) => void,
        isVisible?: boolean
    ) => React.ReactElement

    // used in Dropdown
    // overrides the default item component.
    // to create a new one please refer to component container: DropdownItem / Tab Item
    itemTemplate?: (data?: AssuraTabData) => React.ReactElement

    // used in Dropdown
    // overrides the inner item components.
    // ------------------------------------
    // the inner item value template.
    valueTemplate?: (data?: AssuraTabData) => React.ReactElement

    // used in Dropdown
    // the inner left item artifact template. => only for dropdowns
    // icon / initials / badge / avatar
    leftTemplate?: (data?: ItemMetaData) => React.ReactElement

    // used in Dropdown
    // the inner right item artifact template. => only for dropdowns
    // icon / initials / badge / avatar
    rightTemplate?: (data?: ItemMetaData) => React.ReactElement
}

export type ItemMetaData = {
    value: number | string | IconNamesEnum
    color?: ColorsEnum
    backgroundColor?: ColorsEnum
    imageUrl?: string
}

export enum TabTypeEnum {
    Tabs = 0,
    Dropdown = 1
}
