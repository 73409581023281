import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { digcliParameters } from 'core/api/Cms'
import { DigcliParametersCms } from 'core/models/cms/DicliParametersCms'

import { fetchDigcliParameters, fetchDigcliParametersOnSucess } from './digcliParameters.slice'

export function* fetchDigcliParametersSaga() {
    try {
        const params: DigcliParametersCms = yield call(digcliParameters)
        yield put(fetchDigcliParametersOnSucess(params))
    } catch (e) {
        console.error('fetchDigcliParametersSaga Error', e)
    }
}

function* fetchDigcliParametersWatcher() {
    yield takeEvery(fetchDigcliParameters.type, fetchDigcliParametersSaga)
}

const watchers = [fork(fetchDigcliParametersWatcher)]

export default watchers
