import './Settings.css'

import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { CredentialEditMode } from 'core/enums/CredentialEditMode'
import SettingsInformationLogin from 'modules/settings/components/SettingsInformationLogin/SettingsInformationLogin'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { removeBanner, setBanner } from 'shared/store/banners/banners.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import FamilyGroupSettings from '../components/FamilyGroupSettings/FamilyGroupSettings'
import SettingsCredentialsPanel from '../components/SettingsCredentialsEditPanel/SettingsCredentialsEditPanel'
import SettingsTitle from '../components/SettingsTitle/SettingsTitle'

export type SettingsOptions = {
    mode: CredentialEditMode
    data?: string
}
export const newSettingOption = (mode: CredentialEditMode, data?: string): SettingsOptions => {
    return { mode, data }
}

const settingsDoubleAuthId = 'settings-double-auth-error-banner-error'

const Settings = (): JSX.Element => {
    const dispatch = useDispatch()
    const [isInEditMode, setIsInEditMode] = useState(false)
    const [editOptions, setEditOptions] = useState<SettingsOptions>(
        newSettingOption(CredentialEditMode.Login)
    )
    const [isDoubleAuthErrorVisible, setIsDoubleAuthErrorVisible] = useState(false)
    const [isDoubleAuthMailErrorVisible, setIsDoubleAuthMailErrorVisible] = useState(false)

    const closePanel = () => {
        setIsInEditMode(false)
    }

    const onEditAction = (options: SettingsOptions) => {
        setEditOptions(options)
        setIsInEditMode(true)
    }

    useEffect(() => {
        if (isDoubleAuthErrorVisible) {
            dispatch(
                setBanner({
                    id: settingsDoubleAuthId,
                    dataTestId: 'settings-double-auth-error-banner-container-test-id',
                    message: 'ACCOUNT_DOUBLE_AUTH.UNEXPECTED_ERROR'
                })
            )
        } else {
            dispatch(removeBanner(settingsDoubleAuthId))
        }
    }, [isDoubleAuthErrorVisible])

    useEffect(() => {
        const id = `${settingsDoubleAuthId}-mail`
        if (isDoubleAuthMailErrorVisible) {
            dispatch(
                setBanner({
                    id: id,
                    dataTestId: 'settings-double-auth-error-banner-container-test-id',
                    message: 'ACCOUNT_DOUBLE_AUTH.UNEXPECTED_ERROR'
                })
            )
        } else {
            dispatch(removeBanner(id))
        }
    }, [isDoubleAuthMailErrorVisible])

    return (
        <>
            <FullScreenContainer color="gray50">
                <Container>
                    <SettingsTitle />
                </Container>
                <Container>
                    <SettingsInformationLogin
                        editAction={onEditAction}
                        setIsDoubleAuthErrorVisible={setIsDoubleAuthErrorVisible}
                        setIsDoubleAuthMailErrorVisible={setIsDoubleAuthMailErrorVisible}
                    />
                </Container>
            </FullScreenContainer>
            <FullScreenContainer>
                <FamilyGroupSettings />
            </FullScreenContainer>
            <SettingsCredentialsPanel
                options={editOptions}
                openEditPanel={isInEditMode}
                closePanel={closePanel}
            />
        </>
    )
}

export default withAITracking(reactPlugin, Settings, 'Settings', 'settings-container')
