export const setLangCookie = (lang: string): void => {
    const encodedCookieData = encodeURIComponent(`c=${lang}|uic=${lang}`)
    document.cookie = `.AspNetCore.Culture=${encodedCookieData}; domain=e-assura.ch; SameSite=Lax; Secure`
}

export const getCookie = (cname: string): string => {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let c of ca) {
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}
