import { FormCheck } from 'react-bootstrap'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

export interface CheckboxRHFProps {
    id: string
    name: string
    label: string
    defaultValue?: boolean
    register: UseFormRegister<FieldValues>
    onClick?: (value: boolean) => void
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    classNames?: string
    labelClassNames?: string
}

const CheckboxRHF = ({
    id,
    name,
    label,
    register,
    onClick,
    defaultValue,
    rules,
    classNames,
    labelClassNames
}: CheckboxRHFProps): JSX.Element => {
    const labelClassNamesToApplied = labelClassNames ?? 'paragraph'

    const handleClick = (value: boolean) => {
        onClick && onClick(value)
    }

    return (
        <div
            className={`custom-control custom-checkbox${classNames ? ' ' + classNames : ''}`}
            data-testid={`checkbox-${id}-container`}
        >
            <input
                id={id}
                onClick={(e) => handleClick(e.currentTarget.checked)}
                data-testid={`checkbox-${id}`}
                className="custom-control-input"
                type={'checkbox'}
                {...register(name, { ...rules })}
                defaultChecked={defaultValue}
            />
            <FormCheck.Label
                className={`custom-control-label ${labelClassNamesToApplied}`}
                htmlFor={id}
            >
                {label}
            </FormCheck.Label>
        </div>
    )
}

export default CheckboxRHF
