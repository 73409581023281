import { useTranslation } from 'react-i18next'

import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'

export interface TripCancellationLinkProps {
    labelKey: string
    ecLinkId: string
}

const TripCancellationLink = ({ labelKey, ecLinkId }: TripCancellationLinkProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <AssuraEcLink
            ecLinkId={ecLinkId}
            classNames="d-flex align-items-center flex-1 p-top-12 p-bottom-12 border-bottom-solid-width-1 bbc-gray100 cursor-pointer"
            testId={`trip-cancellation-link-${ecLinkId}`}
        >
            <span
                className="labelSmall m-right-8 flex-1"
                data-testid={`trip-cancellation-link-${ecLinkId}-label`}
            >
                {t(labelKey)}
            </span>
            <i className="icon assura-external-link size-16 c-primary" />
        </AssuraEcLink>
    )
}

export default TripCancellationLink
