import './FinancialsInvoicesPanel.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import DocumentCategory from 'core/enums/DocumentCategory'
import { DocumentsGroupEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { mapDocumentFile } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'
import { leftPanelWidth, usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import { fetchDocumentFile, getDocumentFiles } from 'shared/store/documents/documents.slice'
import {
    getSelectedFinancialDocument,
    getSelectedFinancialDocumentsGroup,
    setSelectedFinancialDocumentId
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { useAppDispatch } from 'shared/store/store'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import PaymentDocumentPanelBVRBlock from '../PaymentDocumentPanelBVRBlock/PaymentDocumentPanelBVRBlock'
import PaymentDocumentPanelHeader from '../PaymentDocumentPanelHeader/PaymentDocumentPanelHeader'
import FinancialsInvoicesPanelDatasBlock from './FinancialsInvoicesPanelDatasBlock'

const FinancialsInvoicesPanel = (): JSX.Element => {
    const { isXL, width } = useWindowSize()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { panelDatas, update } = usePanelDatasContext()

    const [isDisplay, setIsDisplay] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [shouldSwitchPdf, setShouldSwitchPdf] = useState(true)
    const [isBannerVisible, setIsBannerVisible] = useState(false)

    const selectedDocumentsGroup = useSelector(getSelectedFinancialDocumentsGroup)
    const selectedDocument = useSelector(getSelectedFinancialDocument)
    const isCanceled = Boolean(selectedDocument?.canceled)

    const documents = useSelector(getDocumentFiles)

    let mappedDocumentFile: AssuraDocument | undefined
    if (selectedDocument) mappedDocumentFile = mapDocumentFile(selectedDocument, documents)

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => mappedDocumentFile?.fileName ?? ''
        }
    })

    const renderPdfError = (): JSX.Element => (
        <AssuraDocumentsError
            onClick={() => {
                if (selectedDocument) {
                    dispatch(
                        fetchDocumentFile({
                            documentId: selectedDocument?.documentId,
                            documentsGroup: selectedDocumentsGroup,
                            isRead: selectedDocument.isRead,
                            reload: true
                        })
                    )
                }
            }}
        />
    )

    const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

    const updatePanelDatas = (shouldDisplayPDF?: boolean): void => {
        // keep it that way so we can figure out easily the result
        const hasBvr =
            selectedDocument?.fullyPaid === false &&
            selectedDocument.inFavour === 'STATEMENTS_VALUE_TYPE_ASSURA' &&
            !selectedDocument.isMigrated &&
            !selectedDocument.canceled

        update({
            hasLeftPanel: hasBvr,
            hasPDFPanel: shouldDisplayPDF
        })
    }

    const closePanel = () => {
        dispatch(setSelectedFinancialDocumentId({ documentId: null }))
        // if (shouldSwitchPdf) setShouldSwitchPdf(false)
    }

    useEffect(() => {
        setIsDisplay(
            selectedDocument !== null &&
                selectedDocument.categoryId !== DocumentCategory.CLAIMS_SETTLEMENT
        )
    }, [selectedDocument])

    useEffect(() => {
        if (isDisplay && selectedDocument && mappedDocumentFile === undefined) {
            dispatch(
                fetchDocumentFile({
                    documentId: selectedDocument.documentId,
                    documentsGroup: DocumentsGroupEnum.INVOICES,
                    isRead: selectedDocument.isRead
                })
            )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        } else {
            // cleanup store variables in order to avoid request for qrCodes when it's not required.
            update({
                hasLeftPanel: false,
                hasPDFPanel: false
            })
        }
    }, [isDisplay])

    useEffect(() => {
        if (isDisplay) updatePanelDatas(shouldSwitchPdf ? true : undefined)
    }, [width, isDisplay, shouldSwitchPdf])

    useEffect(() => {
        setIsBannerVisible(panelDatas.hasPDFPanel && isCanceled)
    }, [panelDatas.hasPDFPanel, selectedDocument])

    return (
        <AssuraPanel
            type={'right'}
            isOpen={isDisplay}
            size={panelDatas.panelWidth}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <>
                {isDisplay && selectedDocument !== null && (
                    <div className="financials-invoices-panel-container">
                        <PaymentDocumentPanelHeader
                            selectedDocument={selectedDocument}
                            closePanel={closePanel}
                            toolbarPluginInstance={toolbarPluginInstance}
                            statusIsOk={mappedDocumentFile?.loadStatus === LoadingStatusEnum.OK}
                            doc={mappedDocumentFile}
                        />
                        <div className="financials-invoices-panel-sub-container">
                            {isXL && panelDatas.hasLeftPanel && (
                                <div
                                    className="financials-invoices-panel-bvr-container"
                                    style={{ flex: `0 0 ${leftPanelWidth}px` }}
                                >
                                    <FinancialsInvoicesPanelDatasBlock
                                        selectedDocument={selectedDocument}
                                        // setShouldSwitchPdf={() => setShouldSwitchPdf(!shouldSwitchPdf)}
                                        // shouldSwitchPdf={shouldSwitchPdf}
                                    />
                                    {selectedDocument.bvrReference && (
                                        <PaymentDocumentPanelBVRBlock
                                            selectedDocument={selectedDocument}
                                        />
                                    )}
                                </div>
                            )}
                            {panelDatas.hasPDFPanel && (
                                <AssuraLoadAndError
                                    status={
                                        mappedDocumentFile?.loadStatus ?? LoadingStatusEnum.ERROR
                                    }
                                    ErrorComponent={renderPdfError()}
                                >
                                    <div className="financials-invoices-panel-pdf-container">
                                        {isBannerVisible && (
                                            <div className="financials-invoices-panel-banners-container">
                                                <AssuraBanner
                                                    id="statements-pdf-banner"
                                                    message={t(
                                                        isCanceled
                                                            ? 'FINANCIALS.CANCELED_DOCUMENT_BANNER'
                                                            : 'STATEMENTS.PANEL_BANNER'
                                                    )}
                                                    onClose={() => setIsBannerVisible(false)}
                                                    variant="default"
                                                />
                                            </div>
                                        )}
                                        <AssuraPdfViewer
                                            toolbarPluginInstance={toolbarPluginInstance}
                                            doc={mappedDocumentFile}
                                            renderPdfError={renderPdfError}
                                            renderPdfLoader={renderPdfLoader}
                                        />
                                    </div>
                                </AssuraLoadAndError>
                            )}
                        </div>
                    </div>
                )}
            </>
        </AssuraPanel>
    )
}

export default FinancialsInvoicesPanel
