import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import useWindowSize from 'core/services/useWindowSize'
import BaseInsurance from 'shared/components/BaseInsurance/BaseInsurance'
import {
    getLamalProductOfSelectedFamilyMember,
    getSummaryByPage
} from 'shared/store/combineSelectors'
import { fetchPolicyDocumentList } from 'shared/store/documents/documents.slice'
import { RootState, useAppDispatch } from 'shared/store/store'

import LamalSpecificationsCard from '../LamalSpecificationCard/LamalSpecificationsCard'

const InsurancesLamal = (): JSX.Element => {
    const { isMobile } = useWindowSize()
    const dispatch = useAppDispatch()

    const familyMemberIndex = useSelector((state: RootState) => state.familyMember.index)

    const lamalProductOfSelectedFamilyMember = useSelector(getLamalProductOfSelectedFamilyMember)
    const familySummary = useSelector(getSummaryByPage)

    useEffect(() => {
        dispatch(fetchPolicyDocumentList())
    }, [])

    return (
        <div className="row m-top-52">
            <div
                className="col-xl-6 col-md-6 col-12"
                style={{ display: 'flex', paddingRight: isMobile ? 16 : 0 }}
            >
                {lamalProductOfSelectedFamilyMember && (
                    <BaseInsurance lamalProduct={lamalProductOfSelectedFamilyMember} />
                )}
            </div>
            <div
                className="col-xl-6 col-md-6 col-12"
                style={{ display: 'flex', paddingLeft: isMobile ? 16 : 0 }}
            >
                {lamalProductOfSelectedFamilyMember && (
                    <LamalSpecificationsCard
                        accident={lamalProductOfSelectedFamilyMember.accident}
                        code={lamalProductOfSelectedFamilyMember.code}
                        doctor={
                            familySummary.insuredPersons[familyMemberIndex]?.productsPerson?.doctor
                        }
                        choiceOfDoctor={lamalProductOfSelectedFamilyMember.choiceOfDoctor}
                        choiceOfPharmacy={lamalProductOfSelectedFamilyMember.choiceOfPharmacy}
                    />
                )}
            </div>
        </div>
    )
}

export default InsurancesLamal
