import './HomePersonalData.css'

import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import {
    getLamalProductOfSelectedFamilyMember,
    getLcaProductsOfSelectedFamilyMember
} from 'shared/store/combineSelectors'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { useAppDispatch } from 'shared/store/store'

import HomePersonalDataContract from './HomePersonalDataContract'
import HomePersonalDataPersonBig from './HomePersonalDataPersonBig'
import HomePersonalDataPersonSmall from './HomePersonalDataPersonSmall'

const HomePersonalData = (): JSX.Element | null => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const selectedMember = useSelector(getSelectedFamilyMember)

    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const loadingStatus = productsLoaderStatus === LoadingStatusEnum.ERROR ? LoadingStatusEnum.OK : productsLoaderStatus

    const lamalProductOfSelectedFamilyMember = useSelector(getLamalProductOfSelectedFamilyMember)
    const lcaProductsOfSelectedFamilyMember = useSelector(getLcaProductsOfSelectedFamilyMember)
    const shouldDisplayContractDataBlock =
        !!lamalProductOfSelectedFamilyMember || lcaProductsOfSelectedFamilyMember.length > 0 || productsLoaderStatus === LoadingStatusEnum.ERROR

    const editAction = () => {
        dispatch(
            fetchContactInfos({
                selectedPolicyNumber: selectedMember.policyNumber
            })
        )
        navigate(
            `${navigationConstants.PERSONAL_DATA.url}?policy=${selectedMember.policyNumber}`
        )
    }

    const personData = {
        firstName: selectedMember.firstName,
        lastName: selectedMember.lastName,
        policyNumber: selectedMember.policyNumber,
        editAction
    }

    return (
        <Col xs={12} md={4} xl={3} className="home-block-col home-personal-data-col">
            <div className="home-personal-data-container" data-testid="personal-data-component">
                <AssuraLoadAndError status={loadingStatus} activityIndicatorSize={24}>
                    {shouldDisplayContractDataBlock ? (
                        <div className="home-personal-data-has-contract-container">
                            <HomePersonalDataPersonSmall {...personData} />
                            <HomePersonalDataContract />
                        </div>
                    ) : (
                        <HomePersonalDataPersonBig {...personData} />
                    )}
                </AssuraLoadAndError>
            </div>
        </Col>
    )
}

export default HomePersonalData
