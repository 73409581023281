import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ClaimsSettlement } from 'core/models/documents/ClaimsSettlement'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface ClaimsSettlementState {
    selectedClaimSettlement?: ClaimsSettlement
    claimsSettlementLoadingStatus: LoadingStatusEnum
    selectedReceiptIndex: number | null
}

const INITIAL_STATE_CLAIMS_SETTLEMENTS: ClaimsSettlementState = {
    selectedClaimSettlement: undefined,
    claimsSettlementLoadingStatus: LoadingStatusEnum.OK,
    selectedReceiptIndex: null
}

export const claimsSettlementSlice = createSlice({
    name: 'claimsSettlement',
    initialState: INITIAL_STATE_CLAIMS_SETTLEMENTS,
    reducers: {
        fetchClaimsSettlement: (state, _action: PayloadAction<string>) => {
            return state
        },
        setClaimsSettlementLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.claimsSettlementLoadingStatus = action.payload
        },
        setClaimsSettlement: (state, action: PayloadAction<ClaimsSettlement>) => {
            state.selectedClaimSettlement = action.payload
        },
        setSelectedReceiptIndex: (state, action: PayloadAction<number | null>) => {
            state.selectedReceiptIndex = action.payload
        }
    }
})

export const {
    fetchClaimsSettlement,
    setClaimsSettlementLoadingStatus,
    setClaimsSettlement,
    setSelectedReceiptIndex
} = claimsSettlementSlice.actions

export const getClaimsSettlementLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.claimsSettlement.claimsSettlementLoadingStatus

export const getSelectedClaimsSettlement: Selector<RootState, ClaimsSettlement | undefined> = (
    state
) => state.claimsSettlement.selectedClaimSettlement

export const getSelectedReceiptIndex: Selector<RootState, number | null> = (state) =>
    state.claimsSettlement.selectedReceiptIndex

export default claimsSettlementSlice.reducer
