import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchDocumentFileApi, fetchDocumentFileToDirectDownload } from 'core/api/DocumentFile'
import { fetchPoliceDocApi, fetchUnreadDocumentsApi } from 'core/api/Documents'
import { DocumentsGroupEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { DocumentFile } from 'core/models/DocumentFile'
import { DocumentsDataWS } from 'core/models/documents/Document'
import { UnreadDocumentApi } from 'core/models/documents/UnReadDocuments'

import { setBanner } from '../banners/banners.slice'
import { setCommunicationToReadStatus } from '../communications/communications.slice'
import { setFinancialDocumentToReadStatus } from '../financialDocuments/financialDocuments.slice'
import {
    fetchDocumentFile,
    fetchPolicyDocument,
    fetchPolicyDocumentList,
    fetchUnreadDocuments,
    getDocumentFiles,
    setDocumentFile,
    setPolicyDocumentLoadingStatus,
    setPolicyDocuments,
    setUnreadDocuments,
    setUnreadDocumentsLoadingStatus
} from './documents.slice'

export function* fetchDocumentFileSaga(action: ReturnType<typeof fetchDocumentFile>) {
    const { documentId, documentsGroup, isRead, reload } = action.payload

    try {
        const documentFiles: Record<string, DocumentFile> = yield select(getDocumentFiles)

        if (
            !reload &&
            documentFiles[documentId] &&
            documentFiles[documentId].loadStatus === LoadingStatusEnum.OK
        ) {
            return
        }

        yield put(
            setDocumentFile({
                documentId,
                documentFile: {
                    base64: '',
                    loadStatus: LoadingStatusEnum.LOADING
                }
            })
        )

        const documentFile: string = yield call(fetchDocumentFileApi, documentId)

        yield put(
            setDocumentFile({
                documentId,
                documentFile: {
                    base64: documentFile,
                    loadStatus: LoadingStatusEnum.OK
                }
            })
        )

        if (!isRead && Source() !== ServicesSource.BACKOFFICE) {
            switch (documentsGroup) {
                case DocumentsGroupEnum.INVOICES:
                case DocumentsGroupEnum.REFUNDS:
                    yield put(setFinancialDocumentToReadStatus({ documentId, documentsGroup }))
                    break
                case DocumentsGroupEnum.CONTRACTUAL:
                case DocumentsGroupEnum.CORRESPONDENCE:
                    yield put(setCommunicationToReadStatus(documentId))
                    break
                default:
                    break
            }
            yield put(fetchUnreadDocuments({ shouldUpdateLoadStatus: false }))
        }
    } catch (e) {
        console.error('onGetDocumentFileSaga Error', e)
        yield put(
            setDocumentFile({
                documentId,
                documentFile: {
                    base64: '',
                    loadStatus: LoadingStatusEnum.ERROR
                }
            })
        )
    }
}

export function* fetchUnreadDocumentsSaga(action: ReturnType<typeof fetchUnreadDocuments>) {
    const { shouldUpdateLoadStatus } = action.payload
    try {
        if (shouldUpdateLoadStatus)
            yield put(setUnreadDocumentsLoadingStatus(LoadingStatusEnum.LOADING))
        const unreadDocuments: UnreadDocumentApi = yield call(fetchUnreadDocumentsApi)
        yield put(setUnreadDocuments(unreadDocuments.unreadDocuments))
        if (shouldUpdateLoadStatus) yield put(setUnreadDocumentsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        if (shouldUpdateLoadStatus)
            yield put(setUnreadDocumentsLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchUnreadDocumentsSaga Error', e)
    }
}

export function* fetchPolicyDocumentListSaga() {
    try {
        const policyDocuments: DocumentsDataWS | null = yield call(fetchPoliceDocApi)
        yield put(setPolicyDocuments(policyDocuments as DocumentsDataWS))
    } catch (e) {
        console.error('fetchPolicyDocumentListSaga Error', e)
    }
}

export function* fetchPolicyDocumentSaga(action: ReturnType<typeof fetchPolicyDocument>) {
    try {
        yield put(setPolicyDocumentLoadingStatus(LoadingStatusEnum.LOADING))
        const documentId = action.payload
        yield call(fetchDocumentFileToDirectDownload, documentId)
        yield put(setPolicyDocumentLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setPolicyDocumentLoadingStatus(LoadingStatusEnum.OK))
        yield put(
            setBanner({
                dataTestId: 'fetch-policy',
                message: 'COMMON.ERROR'
            })
        )
        console.error('fetchPolicyDocumentSaga Error', e)
    }
}

function* fetchDocumentFileWatcher() {
    yield takeEvery(fetchDocumentFile.type, fetchDocumentFileSaga)
}

function* fetchUnreadDocumentsWatcher() {
    yield takeEvery(fetchUnreadDocuments.type, fetchUnreadDocumentsSaga)
}

function* fetchPolicyDocumentWatcher() {
    yield takeLatest(fetchPolicyDocument.type, fetchPolicyDocumentSaga)
}

function* fetchPolicyDocumentListWatcher() {
    yield takeLatest(fetchPolicyDocumentList.type, fetchPolicyDocumentListSaga)
}

const watchers = [
    fork(fetchDocumentFileWatcher),
    fork(fetchUnreadDocumentsWatcher),
    fork(fetchPolicyDocumentWatcher),
    fork(fetchPolicyDocumentListWatcher)
]

export default watchers
