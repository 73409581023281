import './NavBarEnv.css'

import useNavBarHeights from 'core/services/useNavBarHeights'

export const navBarEnvContainerId = 'nav-bar-env'

const NavBarEnv = (): JSX.Element => {
    const { navBarUpHeight } = useNavBarHeights()
    return (
        <div
            className="nav-bar-env-container"
            id={navBarEnvContainerId}
            data-testid={navBarEnvContainerId}
            style={{ height: `${navBarUpHeight}px` }}
        />
    )
}

export default NavBarEnv
