import { Canton } from 'core/enums/Canton'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { FamilyDoctorForm } from 'core/models/services/contract/FamilyDoctor'
import { SearchDoctor } from 'core/models/services/contract/SearchDoctor'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

export interface DataFetchContractDoctors {
    policyNumber: number
    reload?: boolean
}

interface DataFetchContractDoctorsAndProducts {
    policyNumber: number
    year: number
    reload?: boolean
}

interface DataFetchContractDoctorsSuccess {
    policyNumber: number
    contractDoctors: Doctor[]
}

interface DataFetchSearchDoctors {
    code: string
}

export interface DataSearchDoctorsSuccess {
    hasMoreResults: boolean
    results: SearchDoctor[]
}

interface DoctorsState {
    familyDoctors: Record<number, Doctor[]>
    contractDoctorsLoadingStatus: LoadingStatusEnum
    contractDoctorsAndProductsLoadingStatus: LoadingStatusEnum
    wantedDate?: string
    doctorSearchName: string
    selectedCanton: Canton
    searchDoctorsLoadingStatus: LoadingStatusEnum
    searchDoctors: SearchDoctor[]
    searchDoctorsHasMoreResults: boolean
}

const INITIAL_STATE_DOCTORS: DoctorsState = {
    familyDoctors: {},
    contractDoctorsLoadingStatus: LoadingStatusEnum.OK,
    contractDoctorsAndProductsLoadingStatus: LoadingStatusEnum.OK,
    wantedDate: undefined,
    doctorSearchName: '',
    selectedCanton: Canton.ALL_SWITZERLAND,
    searchDoctorsLoadingStatus: LoadingStatusEnum.OK,
    searchDoctors: [],
    searchDoctorsHasMoreResults: false
}

export const doctorsSlice = createSlice({
    name: 'doctors',
    initialState: INITIAL_STATE_DOCTORS,
    reducers: {
        fetchContractDoctorsAndProducts: (
            state,
            _action: PayloadAction<DataFetchContractDoctorsAndProducts>
        ) => {
            return state
        },
        setContractDoctorsAndProductsLoadingStatus: (
            state,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.contractDoctorsAndProductsLoadingStatus = action.payload
        },
        fetchContractDoctorsSuccess: (
            state,
            action: PayloadAction<DataFetchContractDoctorsSuccess>
        ) => {
            state.familyDoctors[action.payload.policyNumber] = action.payload.contractDoctors
        },
        setContractDoctorsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.contractDoctorsLoadingStatus = action.payload
        },
        setWantedDate: (state, action: PayloadAction<string | undefined>) => {
            state.wantedDate = action.payload
        },
        setDoctorSearchName: (state, action: PayloadAction<string>) => {
            state.doctorSearchName = action.payload
        },
        setSelectedCanton: (state, action: PayloadAction<Canton>) => {
            state.selectedCanton = action.payload
        },
        fetchSearchDoctors: (state, _action: PayloadAction<DataFetchSearchDoctors>) => {
            return state
        },
        fetchSearchDoctorsSuccess: (state, action: PayloadAction<DataSearchDoctorsSuccess>) => {
            state.searchDoctors = action.payload.results
            state.searchDoctorsHasMoreResults = action.payload.hasMoreResults
        },
        setSearchDoctorsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.searchDoctorsLoadingStatus = action.payload
        },
        onSubmitFamilyDoctor: (state, _action: PayloadAction<FamilyDoctorForm>) => {
            return state
        }
    }
})

export const {
    fetchContractDoctorsAndProducts,
    setContractDoctorsAndProductsLoadingStatus,
    fetchContractDoctorsSuccess,
    setContractDoctorsLoadingStatus,
    setWantedDate,
    setDoctorSearchName,
    setSelectedCanton,
    fetchSearchDoctors,
    fetchSearchDoctorsSuccess,
    setSearchDoctorsLoadingStatus,
    onSubmitFamilyDoctor
} = doctorsSlice.actions

export const getFamilyDoctors: Selector<RootState, Record<number, Doctor[]>> = (state) =>
    state.servicesDoctors.familyDoctors

export const getContractDoctorsAndProductsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state
) => state.servicesDoctors.contractDoctorsAndProductsLoadingStatus

export const getContractDoctorsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.servicesDoctors.contractDoctorsLoadingStatus

export const getWantedDate: Selector<RootState, Date | undefined> = (state: RootState) =>
    state.servicesDoctors.wantedDate ? new Date(state.servicesDoctors.wantedDate) : undefined

export const getDoctorSearchName: Selector<RootState, string> = (state) =>
    state.servicesDoctors.doctorSearchName

export const getSelectedCanton: Selector<RootState, Canton> = (state) =>
    state.servicesDoctors.selectedCanton

export const getSearchDoctorsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.servicesDoctors.searchDoctorsLoadingStatus

export const getSearchDoctors: Selector<RootState, SearchDoctor[]> = (state) =>
    state.servicesDoctors.searchDoctors

export const getSearchDoctorsHasMoreResults: Selector<RootState, boolean> = (state) =>
    state.servicesDoctors.searchDoctorsHasMoreResults

export default doctorsSlice.reducer
