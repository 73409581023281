import './ProductCardPanelFooter.css'

import { useTranslation } from 'react-i18next'

import { openInNewTab } from 'core/utils/onClickUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface ProductCardPanelFooterProps {
    url: string
}

const ProductCardPanelFooter = ({ url }: ProductCardPanelFooterProps): JSX.Element => {
    const { t } = useTranslation()
    
    return (
        <div className="product-card-panel-footer-container border-top-solid-width-1 btc-gray100">
            <AssuraIconButton
                id="product-card-panel-external-documentation"
                icon="assura-external-link"
                variant="primary"
                size="medium"
                onClick={() => openInNewTab(url)}
                label={t('INSURANCES.PRODUCT_CARD_PANEL_FOOTER_LINK')}
            />
        </div>
    )
}

export default ProductCardPanelFooter
