import './InvoicesTableAmountField.css'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import concatClassNames from 'core/utils/classNameUtils'
import { formatNumber } from 'core/utils/TextUtils'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

interface InvoicesTableAmountFieldProps {
    row: DocumentRow
}
const InvoicesTableAmountField = ({ row }: InvoicesTableAmountFieldProps): JSX.Element => {
    const label = row.valueAmount > 0 ? `CHF ${formatNumber(row.valueAmount, true)}` : ' '
    const unreadClass = row.isRead ? 'label' : 'labelMedium c-black'
    const amountClass = concatClassNames(['max-1-text-line', unreadClass])

    return (
        <td
            className="invoices-table-amount-container"
            data-testid={`assura-table-row-${row.documentId}-amount-field`}
        >
            {!row.isMigrated && (
                <table>
                    <tbody>
                        <tr className="invoices-table-amount-row">
                            <TextField
                                label={label}
                                rowId={row.documentId}
                                classNames={amountClass}
                                fieldId="amount-label"
                            />
                            {!row.canceled && row.deadLineDate && (
                                <TextField
                                    label={formatDate(row.deadLineDate)}
                                    rowId={row.documentId}
                                    classNames="labelExtraSmall invoices-table-amount-date"
                                    fieldId="amount-date"
                                />
                            )}
                        </tr>
                    </tbody>
                </table>
            )}
        </td>
    )
}

export default InvoicesTableAmountField
