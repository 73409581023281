import { useNavigate } from 'react-router-dom'

import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import EnveloppesTableDocumentsField from 'modules/financials/components/FinancialsEnveloppes/EnveloppesTableDocumentsField'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import StatusIconField from 'shared/components/AssuraTable/Fields/StatusIconField'
import { setSelectedEnveloppeId } from 'shared/store/enveloppes/enveloppes.slice'
import { useAppDispatch } from 'shared/store/store'

interface LastSendsRowProps {
    row: EnveloppeRow
    url: string
}

const LastSendsRow = ({ row, url }: LastSendsRowProps): JSX.Element => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isXSD } = useWindowSize()

    const handleOnClick = () => {
        dispatch(setSelectedEnveloppeId(row.id))
        navigate(url)
    }

    const rowId = `enveloppes-${row.index}`

    return (
        <tr
            className="home-block-row"
            onClick={handleOnClick}
            data-testid={`home-block-enveloppes-row-${row.index}`}
        >
            <td className="flex-1">
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8 ">
                            <StatusIconField
                                statusColor={row.statusColor}
                                rowId={rowId}
                                isMobileStyle
                            />
                            <DateField
                                date={row.receiveDate ?? ''}
                                rowId={rowId}
                                classNames="labelExtraSmall c-gray500"
                                isMobileStyle
                            />
                            <Separator />
                            <StatusField
                                status={row.statusTranslated}
                                statusIcon={row.statusIcon}
                                statusColor={row.statusColor}
                                rowId={rowId}
                                labelClassNames="labelExtraSmall c-gray500"
                                isMobileStyle
                            />
                        </tr>
                        <tr>
                            <EnveloppesTableDocumentsField
                                enveloppe={row}
                                rowId={rowId}
                                labelClassName="labelMedium"
                                mobileStyle
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            <GoToField rowId={rowId} marginLeft={isXSD ? undefined : 8} />
        </tr>
    )
}

export default LastSendsRow
