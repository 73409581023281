import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ClaimsSettlementTypeEnum } from 'core/enums/ClaimsSettlementTypeEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    getCostsLabels,
    getCostsValues,
    getPercentage
} from 'core/helpers/ClaimsSettlementPanelHelper'
import { ClaimsSettlement } from 'core/models/documents/ClaimsSettlement'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import { fetchDocumentFile } from 'shared/store/documents/documents.slice'
import { getSelectedFinancialDocumentsGroup } from 'shared/store/financialDocuments/financialDocuments.slice'
import { useAppDispatch } from 'shared/store/store'

import ClaimsSettlementCosts from '../ClaimsSettlementCosts/ClaimsSettlementCosts'
import ClaimsSettlementPanelCostsTpTg from './ClaimsSettlementPanelCostsTpTg'

interface ClaimsSettlementPanelCostsProps {
    selectedClaimsSettlement: ClaimsSettlement
    isSelectedDocumentRead: boolean
}

const ClaimsSettlementPanelCosts = ({
    selectedClaimsSettlement,
    isSelectedDocumentRead
}: ClaimsSettlementPanelCostsProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { panelDatas, update } = usePanelDatasContext()

    const selectedDocumentsGroup = useSelector(getSelectedFinancialDocumentsGroup)

    const isPdfDisplayed = panelDatas.hasPDFPanel

    const isRefund = selectedClaimsSettlement.type === ClaimsSettlementTypeEnum.REFUND

    const costsLabels = getCostsLabels(selectedClaimsSettlement.type)
    const costsValues = getCostsValues(costsLabels, selectedClaimsSettlement)
    const percentage = getPercentage(costsValues)

    const displayPdf = (shouldDisplayPdf: boolean) => {
        if (shouldDisplayPdf) {
            dispatch(
                fetchDocumentFile({
                    documentId: selectedClaimsSettlement.documentIdentifier,
                    documentsGroup: selectedDocumentsGroup,
                    isRead: isSelectedDocumentRead
                })
            )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        }
        update({ hasPDFPanel: shouldDisplayPdf })
    }

    return (
        <div>
            <LabelValueInlineBlock>
                <div>{t('CLAIMS_SETTLEMENT.NUMBER_OF_RECEIPTS_LABEL')} </div>
                <div className="align-self-end no-wrap">
                    {t('CLAIMS_SETTLEMENT.NUMBER_OF_RECEIPTS', {
                        numberOfReceipts: selectedClaimsSettlement.documents?.length
                    })}
                </div>
            </LabelValueInlineBlock>
            <LabelValueInlineBlock>
                <div>{t(costsLabels.totalCostsLabel)}</div>
                <div className="align-self-end no-wrap">
                    {t('COMMON.PRICE', {
                        price: formatNumber(selectedClaimsSettlement?.totalCosts)
                    })}
                </div>
            </LabelValueInlineBlock>
            <ClaimsSettlementPanelCostsTpTg selectedClaimsSettlement={selectedClaimsSettlement} />
            <ClaimsSettlementCosts
                percentage={percentage}
                costsLabels={costsLabels}
                costsValues={costsValues}
                isRefund={isRefund}
                type={selectedClaimsSettlement.type}
            />
            {isPdfDisplayed ? (
                <AssuraIconButton
                    id="claims-settlements-hide-pdf"
                    icon="assura-close"
                    variant="primary"
                    size="medium"
                    onClick={() => displayPdf(false)}
                    label={t('CLAIMS_SETTLEMENT.HIDE_PDF')}
                    classNames="d-flex justify-content-start m-top-32"
                />
            ) : (
                <AssuraIconButton
                    id="claims-settlement-see-pdf"
                    icon="assura-pdf"
                    variant="primary"
                    size="medium"
                    onClick={() => displayPdf(true)}
                    label={t('CLAIMS_SETTLEMENT.DISPLAY_PDF')}
                    classNames="d-flex justify-content-start m-top-32"
                />
            )}
        </div>
    )
}

export default ClaimsSettlementPanelCosts
