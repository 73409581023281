import { useTranslation } from 'react-i18next'

import { PrimesDiscount } from 'core/models/familyGroup/PrimesDiscount'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraPopover from 'shared/components/AssuraPopover/AssuraPopover'

interface PrimesPanelDiscountsAmountProps {
    discountInfo: PrimesDiscount
}
const PrimesPanelDiscountsAmount = ({
    discountInfo
}: PrimesPanelDiscountsAmountProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex align-items-end p-top-16 p-bottom-12 border-bottom-solid-width-1 bc-gray100">
            <div className="d-flex align-items-center flex-1">
                <div
                    className="paragraphSmallLight"
                    data-testid={`primes-panel-discounts-${discountInfo.baseLabel.toLowerCase()}-title`}
                >
                    {t(`PRIMES.DISCOUNTS_${discountInfo.baseLabel}_LABEL`)}
                </div>
                <AssuraPopover
                    id={`primes-panel-discounts-${discountInfo.baseLabel.toLowerCase()}-popover`}
                    position="bottom"
                    triggerType={['hover', 'focus']}
                    content={
                        <div className="paragraphSmallLight">
                            {t(`PRIMES.DISCOUNTS_${discountInfo.baseLabel}_POPOVER`)}
                        </div>
                    }
                    isInsidePanel
                >
                    <div className="m-left-8">
                        <i
                            className="icon assura-info-circle c-primary size-16"
                            data-testid={`primes-panel-discounts-${discountInfo.baseLabel.toLowerCase()}-icon`}
                        />
                    </div>
                </AssuraPopover>
            </div>
            <div
                className="paragraphSmallMedium c-gray500 m-left-16"
                data-testid={`primes-panel-discounts-${discountInfo.baseLabel.toLowerCase()}-value`}
            >
                {formatNumber(discountInfo.value)}
            </div>
        </div>
    )
}

export default PrimesPanelDiscountsAmount
