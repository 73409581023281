import { MissingDataType } from 'core/enums/MissingData'
import { ProfilePicture } from 'core/models/familyGroup/ProfilePicture'

import { createSelector } from '@reduxjs/toolkit'

import { getMissingDataInRequest } from './getMissingDataInRequest'
import { getSummaryCurrentYear } from './getSummaryCurrentYear'

export interface PersonSettings {
    policyNumber: number
    firstName: string
    lastName: string
    profilePicture?: ProfilePicture
    missingDatas: MissingDataType[]
}

export const getFamilyGroupSettings = createSelector([getSummaryCurrentYear, getMissingDataInRequest], (summary, missingData): PersonSettings[] => {
    const familyGroupSettings: PersonSettings[] = []

    summary.insuredPersons.forEach((personSummary) => {
        familyGroupSettings.push({
            policyNumber: personSummary.policyNumber,
            firstName: personSummary.firstName,
            lastName: personSummary.lastName,
            profilePicture: personSummary.profilePicture,
            missingDatas: missingData[personSummary.policyNumber] || []
        })
    })

    return familyGroupSettings
})
