import { SplashScreenCms } from 'core/models/cms/SplashScreenCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface SplashScreenState {
    splashScreenCms: SplashScreenCms[]
}

const INITIAL_STATE_SPLASHSCREEN: SplashScreenState = {
    splashScreenCms: []
}

export const splashScreenSlice = createSlice({
    name: 'splashScreen',
    initialState: INITIAL_STATE_SPLASHSCREEN,
    reducers: {
        fetchSplashScreenCms: (state) => {
            return state
        },
        fetchSplashScreenCmsSuccess: (state, action: PayloadAction<SplashScreenCms[]>) => {
            state.splashScreenCms = action.payload
        }
    }
})

export const { fetchSplashScreenCms, fetchSplashScreenCmsSuccess } = splashScreenSlice.actions

export default splashScreenSlice.reducer

export const getSplashScreenCms: Selector<RootState, SplashScreenCms[]> = (state) =>
    state.splashScreen.splashScreenCms
