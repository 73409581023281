import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { FinancialFetchResponse } from 'core/models/services/personalData/Financial'
import capitalImg from 'shared/assets/images/capital.png'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { goToServicesForm } from 'shared/store/services/services.slice'

interface FinancialDataProps {
    financialData: Omit<FinancialFetchResponse, 'ownerAddress' | 'ownerLocality'>
    isPending: boolean
}
const FinancialData = ({ financialData, isPending }: FinancialDataProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    return (
        <>
            <div className="d-flex m-bottom-40" style={{ flexGrow: 2 }}>
                <img
                    data-testid="financial-image"
                    src={capitalImg}
                    alt="financial image"
                    className="personal-data-financial-img"
                />
                <div className="personal-data-financial-text paragraph c-black">
                    <div data-testid="personal-data-financial-owner">
                        {financialData.ownerName ?? ''}
                    </div>
                    <div data-testid="personal-data-financial-account">
                        {financialData.type === 'iban'
                            ? financialData.iban ?? ''
                            : financialData.ccp ?? ''}
                    </div>
                </div>
            </div>
            <AssuraButton
                text={t(isPending ? 'COMMON.IS_PENDING' : 'COMMON.MODIFY')}
                id="personal-data-financial-edit-button"
                variant="secondary"
                onClick={() => dispatch(goToServicesForm({ type: ServiceType.BANK_REL_CHANGE }))}
                icon={{
                    name: isPending ? 'assura-pending-1' : 'assura-edit',
                    size: 24
                }}
                fullWidth
                disabled={isPending}
            />
        </>
    )
}

export default FinancialData
