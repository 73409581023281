import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface PersonalDataAddressProps {
    values: RequestDetailsValues[]
}

const PersonalDataAddress = ({ values }: PersonalDataAddressProps): JSX.Element => {
    const { t } = useTranslation()

    const street = values.find((v) => v.id === 'Street')
    const number = values.find((v) => v.id === 'StreetNumber')
    const comp = values.find((v) => v.id === 'StreetAdd')
    const npa = values.find((v) => v.id === 'ZipCode')
    const locality = values.find((v) => v.id === 'City')
    const validFrom = values.find((v) => v.id === 'ValidFrom')

    const label = t('PERSONAL_DATA.ADDRESS')

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                <LabelValueInlineBlock>
                    <div className="align-self-end">{label}</div>
                    <div className="text-right">
                        <div data-testid="requests-details-personal-data-address-street-old">
                            {street?.old ?? ''} {number?.old ?? ''}
                        </div>
                        {comp?.old && (
                            <div data-testid="requests-details-personal-data-address-comp-old">
                                {comp.old as string}
                            </div>
                        )}
                        <div data-testid="requests-details-personal-data-address-locality-old">
                            {npa?.old ?? ''} {locality?.old ?? ''}
                        </div>
                    </div>
                </LabelValueInlineBlock>
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                <>
                    {validFrom && (
                        <div className="label c-primary">
                            {t('DOCUMENTS.PROCESSING_DATE', {
                                processingDate: formatDate(validFrom.new as string)
                            })}
                        </div>
                    )}
                </>
            </RequestsPanelSubtitle>
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div className="align-self-end">{label}</div>
                    <div className="text-right">
                        <div data-testid="requests-details-personal-data-address-street-new">
                            {(street?.new as string) ?? ''} {(number?.new as string) ?? ''}
                        </div>
                        {comp?.new && (
                            <div data-testid="requests-details-personal-data-address-comp-new">
                                {comp.new as string}
                            </div>
                        )}
                        <div data-testid="requests-details-personal-data-address-locality-new">
                            {(npa?.new as string) ?? ''} {(locality?.new as string) ?? ''}
                        </div>
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default PersonalDataAddress
