import { ClaimsSettlementTypeEnum } from 'core/enums/ClaimsSettlementTypeEnum'
import {
    ClaimsSettlementCostsLabel,
    ClaimsSettlementCostsValues
} from 'core/helpers/ClaimsSettlementPanelHelper'
import useWindowSize from 'core/services/useWindowSize'

import ClaimsSettlementCostsLegendLabel from './ClaimsSettlementCostsLegendLabel'
import ClaimsSettlementCostsPieChart from './ClaimsSettlementCostsPieChart'

interface ClaimsSettlementCostsProps {
    percentage?: number
    costsLabels: ClaimsSettlementCostsLabel
    costsValues: ClaimsSettlementCostsValues
    isRefund: boolean
    type: ClaimsSettlementTypeEnum
}

const ClaimsSettlementCosts = ({
    percentage,
    costsLabels,
    costsValues,
    isRefund,
    type
}: ClaimsSettlementCostsProps): JSX.Element => {
    const { isXSD } = useWindowSize()

    return percentage !== undefined &&
        costsValues.assuraCosts.value !== undefined &&
        type !== ClaimsSettlementTypeEnum.TPTG ? (
        <div className={`d-flex m-top-32 ${isXSD ? 'flex-column' : 'align-items-center'}`}>
            <ClaimsSettlementCostsPieChart
                percentage={percentage}
                percentageLabel={costsLabels.percentageLabel}
            />
            <div className={`d-flex flex-column flex-1 ${isXSD ? 'm-top-24' : 'm-left-48'}`}>
                <ClaimsSettlementCostsLegendLabel
                    cost={costsValues.assuraCosts.value}
                    label={costsValues.assuraCosts.label}
                    shouldDisplayTypeIndicator={isRefund}
                    isRefund={isRefund}
                    isAssuraLegend
                />
                <ClaimsSettlementCostsLegendLabel
                    cost={costsValues.insuredCosts.value}
                    label={costsValues.insuredCosts.label}
                    shouldDisplayTypeIndicator={type === ClaimsSettlementTypeEnum.INVOICE}
                    isRefund={isRefund}
                    labelClassNames="m-top-24"
                />
            </div>
        </div>
    ) : (
        <></>
    )
}

export default ClaimsSettlementCosts
