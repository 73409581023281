import { DiscountsPerson } from '../models/familyGroup/DiscountsPerson'
import { PrimesDiscount } from '../models/familyGroup/PrimesDiscount'
import { Product } from '../models/Product'

export const prepareLcasData = (lcas: Product[]): Record<string, Product[]> => {
    const lcasByCategory: Record<string, Product[]> = {}
    lcas.forEach((lca) => {
        if (lca.categoryTitle && lcasByCategory[lca.categoryTitle]) {
            lcasByCategory[lca.categoryTitle].push(lca)
        } else if (lca.categoryTitle && !lcasByCategory[lca.categoryTitle]) {
            lcasByCategory[lca.categoryTitle] = [lca]
        }
    })

    return lcasByCategory
}
export const prepareDiscountsData = (
    discountsPerson: DiscountsPerson | undefined
): PrimesDiscount[] => {
    const primesDiscountsData: PrimesDiscount[] = []

    if (discountsPerson) {
        discountsPerson.combinationDiscountsTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'COMBINATION',
                value: -Math.abs(discountsPerson.combinationDiscountsTotal)
            })

        discountsPerson.specialDiscountTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'SPECIAL',
                value: -Math.abs(discountsPerson.specialDiscountTotal)
            })

        discountsPerson.familyDiscountTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'FAMILY',
                value: -Math.abs(discountsPerson.familyDiscountTotal)
            })

        discountsPerson.childrenDiscountTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'CHILDREN',
                value: -Math.abs(discountsPerson.childrenDiscountTotal)
            })

        discountsPerson.tevDiscountTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'TEV',
                value: -Math.abs(discountsPerson.tevDiscountTotal)
            })

        discountsPerson.collectivityDiscountTotal !== 0 &&
            primesDiscountsData.push({
                baseLabel: 'COLLECTIVITY',
                value: -Math.abs(discountsPerson.collectivityDiscountTotal)
            })
    }

    return primesDiscountsData
}
