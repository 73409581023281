import './SideNavigationPage.css'

import { Container } from 'react-bootstrap'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { SideNavigationItemId, SideNavigationList } from 'core/models/SideNavigation'

import SideNavigationBackground from './SideNavigationBackground'
import SideNavigationContent from './SideNavigationContent'
import SideNavigationMenu from './SideNavigationMenu'
import SideNavigationMobileHeader from './SideNavigationMobileHeader'
import { SideNavigationProvider } from './SideNavigationProvider'

interface SideNavigationPageProps<T extends SideNavigationItemId> {
    title: string
    defaultSelectedId: T
    items: SideNavigationList<T>
    dataLoadingStatus: LoadingStatusEnum
    defaultUrl: string
}

const SideNavigationPage = <T extends SideNavigationItemId>({
    dataLoadingStatus,
    defaultSelectedId,
    defaultUrl,
    ...props
}: SideNavigationPageProps<T>): JSX.Element => {
    return (
        <SideNavigationProvider
            defaultSelectedId={defaultSelectedId}
            dataLoadingStatus={dataLoadingStatus}
            defaultUrl={defaultUrl}
        >
            <SideNavigationMobileHeader items={props.items} />
            <Container className="side-navigation-page-container">
                <SideNavigationMenu {...props} />
                <SideNavigationContent items={props.items} />
            </Container>
            <SideNavigationBackground />
        </SideNavigationProvider>
    )
}

export default SideNavigationPage
