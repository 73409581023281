import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { PaymentFrequency, Periodicity } from 'core/models/services/financial/PaymentFrequency'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

export interface PeriodicityState {
    periodicities: Periodicity[]
    wsStatus: HttpResponseEnum
}
const INITIAL_STATE_PERIODICITY: PeriodicityState = {
    periodicities: [],
    wsStatus: HttpResponseEnum.SUCCESS
}

export const periodicitySlice = createSlice({
    name: 'servicesPeriodicity',
    initialState: INITIAL_STATE_PERIODICITY,
    reducers: {
        fetchPeriodicity: (state) => {
            return state
        },
        setPeriodicity: (state, action: PayloadAction<PeriodicityState>) =>
            (state = action.payload),
        onSubmitPaymentFrequency: (state, _action: PayloadAction<PaymentFrequency>) => {
            return state
        }
    }
})

export const {
    fetchPeriodicity,
    setPeriodicity,
    onSubmitPaymentFrequency
} = periodicitySlice.actions

export const getServicesPeriodicity: Selector<RootState, PeriodicityState> = (state) =>
    state.servicesPeriodicity

export default periodicitySlice.reducer
