import './WebsiteHeader.css'

import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ReactComponent as LeftArrow } from 'shared/assets/images/services/left-arrow-website.svg'
import { getRedirectionParameters } from 'shared/store/general/general.slice'

const WebsiteHeader = (): JSX.Element => {
    const { t } = useTranslation()
    const redirectionParameters = useSelector(getRedirectionParameters)

    const goBackToWebsite = () => {
        window.location.href = redirectionParameters.origin
    }

    return (
        <div className="bbc-gray100 bg-white website-header-full-container fixed-top">
            <Container>
                <div className="website-header-container">
                    <div className="website-header-support-container">
                        <div
                            className="website-header-support-link-container d-flex align-items-center"
                            onClick={goBackToWebsite}
                            data-testid="website-header-support-link-container"
                        >
                            <div className="website-header-support-link-arrow">
                                <LeftArrow />
                            </div>
                            <span className="website-header-text">
                                {t('SERVICES.WEBSITE_SUPPORT')}
                            </span>
                        </div>
                    </div>
                    <div>
                        <span className="website-header-service-container website-header-text ">
                            {t('SERVICES.WEBSITE_ONLINE_SERVICES')}
                        </span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default WebsiteHeader
