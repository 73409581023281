import { ProductCategoryEnum, ProductTypeEnum } from 'core/enums/Product'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { Product } from 'core/models/Product'
import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getProducts } from '../combineSelectors'
import { getProductsYear } from '../products/products.slice'
import { getSelectedFamilyMember } from './getSelectedFamilyMember'

export type FutureProducts = { [key: string]: Product[] }

export const getFutureProductsOfSelectedFamilyMember = createSelector(
    [getProducts, getSelectedFamilyMember, getProductsYear],
    (products, selectedFamilyMember, selectedYear): FutureProducts => {
        const futureProducts: FutureProducts = {
            lamal: [],
            lca: []
        }

        const filteredProducts: { [key: string]: ProductPerson[] } = {
            lamal: [],
            lca: []
        }

        const checkdate = getCheckDate(selectedYear)

        selectedFamilyMember?.productsPerson?.products?.forEach((productPerson) => {
            if (new Date(productPerson.startDate) > checkdate) {
                if (productPerson.type === ProductTypeEnum.LAMAL) {
                    filteredProducts.lamal.push(productPerson)
                } else {
                    filteredProducts.lca.push(productPerson)
                }
            }
        })

        Object.keys(filteredProducts).forEach((type) => {
            const futureProductsOfSelectedFamilyMember: Product[] = []
            const futureAccidentProducts: Product[] = []

            filteredProducts[type].forEach((productPerson) => {
                const correspondingProduct = products.find(
                    (product) => product.insuranceId === productPerson.code
                )
                if (correspondingProduct) {
                    const enrichedProduct = { ...correspondingProduct }
                    enrichedProduct.accident = productPerson.accident
                    enrichedProduct.startDate = productPerson.startDate
                    if (type === ProductTypeEnum.LAMAL) enrichedProduct.isFuturLamal = true
                    if (ProductCategoryEnum.ACCIDENT === enrichedProduct.categoryId) {
                        futureAccidentProducts.push(enrichedProduct)
                    } else {
                        futureProductsOfSelectedFamilyMember.push(enrichedProduct)
                    }
                }
            })

            futureProducts[type] =
                futureProductsOfSelectedFamilyMember.concat(futureAccidentProducts)
        })

        return futureProducts
    }
)
