import './FullScreenOffset.css'

import { Col, Container, Row } from 'react-bootstrap'

import { AbsolutePosition } from 'core/enums/AbsolutePosition'
import useWindowSize from 'core/services/useWindowSize'

export interface FullScreenColoredOffset {
    secondColorClass: string
    position: AbsolutePosition
    bootStrapColumnNumber: number
}

interface FullScreenOffsetProps {
    mainColor: string
    offset: FullScreenColoredOffset
}

const FullScreenOffset = ({ mainColor, offset }: FullScreenOffsetProps): JSX.Element => {
    const { width, maxContainerWidth } = useWindowSize()

    const { secondColorClass, position, bootStrapColumnNumber } = offset

    const colProps = {
        xs: {
            span: bootStrapColumnNumber,
            offset: position === AbsolutePosition.LEFT ? 0 : 12 - bootStrapColumnNumber
        }
    }

    const HALF_GUTTER = 16

    return (
        <div
            className={`full-screen-offset-container ${mainColor}`}
            data-testid="full-screen-offset-main-container"
        >
            <Container className="full-screen-offset-block">
                <Row className="full-screen-offset-block">
                    <Col
                        className={`full-screen-offset-col ${secondColorClass}`}
                        {...colProps}
                        data-testid="full-screen-offset-block"
                    >
                        <div>&nbsp;</div>
                    </Col>
                </Row>
            </Container>
            <div
                className={`full-screen-offset-variable-block ${secondColorClass}`}
                style={{
                    left: position === AbsolutePosition.LEFT ? 0 : 'unset',
                    right: position === AbsolutePosition.RIGHT ? 0 : 'unset',
                    width: `${(width - maxContainerWidth) / 2 + HALF_GUTTER}px`
                }}
                data-testid="full-screen-offset-variable-block"
            />
        </div>
    )
}

export default FullScreenOffset
