import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ClaimsSettlementTypeEnum } from 'core/enums/ClaimsSettlementTypeEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    getCostsLabels,
    getPercentage,
    getReceiptCostsValues
} from 'core/helpers/ClaimsSettlementPanelHelper'
import { ClaimsSettlementReceipt } from 'core/models/documents/ClaimsSettlementReceipt'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import { fetchDocumentFile } from 'shared/store/documents/documents.slice'
import { getSelectedFinancialDocumentsGroup } from 'shared/store/financialDocuments/financialDocuments.slice'
import { useAppDispatch } from 'shared/store/store'

import ClaimsSettlementCosts from '../ClaimsSettlementCosts/ClaimsSettlementCosts'

interface ClaimsSettlementReceiptPanelCostsProps {
    selectedClaimsSettlementReceipt: ClaimsSettlementReceipt
    isSelectedDocumentRead: boolean
}

const ClaimsSettlementReceiptPanelCosts = ({
    selectedClaimsSettlementReceipt,
    isSelectedDocumentRead
}: ClaimsSettlementReceiptPanelCostsProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const selectedDocumentsGroup = useSelector(getSelectedFinancialDocumentsGroup)

    const { panelDatas, update } = usePanelDatasContext()
    const isPdfDisplayed = panelDatas.hasPDFPanel

    const isRefund = selectedClaimsSettlementReceipt.type === ClaimsSettlementTypeEnum.REFUND

    const costsLabels = getCostsLabels(selectedClaimsSettlementReceipt.type, true)
    const receiptCostsValues = getReceiptCostsValues(costsLabels, selectedClaimsSettlementReceipt)
    const percentage = getPercentage(receiptCostsValues)

    const displayPdf = (shouldDisplayPdf: boolean) => {
        if (shouldDisplayPdf && selectedClaimsSettlementReceipt?.documentIdentifier) {
            dispatch(
                fetchDocumentFile({
                    documentId: selectedClaimsSettlementReceipt.documentIdentifier,
                    documentsGroup: selectedDocumentsGroup,
                    isRead: isSelectedDocumentRead
                })
            )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        }
        update({ hasPDFPanel: shouldDisplayPdf })
    }

    return (
        <div className="m-top-32">
            {selectedClaimsSettlementReceipt.medicalBranch ? (
                <LabelValueInlineBlock>
                    <div>{t('CLAIMS_SETTLEMENT.RECEIPT_TYPE_LABEL')}</div>
                    <div className="align-self-end no-wrap">
                        {selectedClaimsSettlementReceipt.medicalBranch}
                    </div>
                </LabelValueInlineBlock>
            ) : (
                <></>
            )}
            <LabelValueInlineBlock>
                <div>{t('CLAIMS_SETTLEMENT.RECEIPT_TOTAL_COSTS')}</div>
                <div className="align-self-end no-wrap">
                    {t('COMMON.PRICE', {
                        price: formatNumber(selectedClaimsSettlementReceipt.totalCosts)
                    })}
                </div>
            </LabelValueInlineBlock>
            <ClaimsSettlementCosts
                percentage={percentage}
                costsLabels={costsLabels}
                costsValues={receiptCostsValues}
                isRefund={isRefund}
                type={selectedClaimsSettlementReceipt.type}
            />
            {selectedClaimsSettlementReceipt?.documentIdentifier && (
                <>
                    {isPdfDisplayed ? (
                        <AssuraIconButton
                            id="claims-settlements-receipt-hide-pdf"
                            icon="assura-close"
                            variant="primary"
                            size="medium"
                            onClick={() => displayPdf(false)}
                            label={t('CLAIMS_SETTLEMENT.RECEIPT_HIDE_PDF')}
                            classNames="d-flex justify-content-start m-top-32"
                        />
                    ) : (
                        <AssuraIconButton
                            id="claims-settlements-receipt-see-pdf"
                            icon="assura-pdf"
                            variant="primary"
                            size="medium"
                            onClick={() => displayPdf(true)}
                            label={t('CLAIMS_SETTLEMENT.RECEIPT_DISPLAY_PDF')}
                            classNames="d-flex justify-content-start m-top-32"
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default ClaimsSettlementReceiptPanelCosts
