import './BaseInsurance.css'

import { ProgressBar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import analyticsConstants from 'core/constants/analyticsConstants'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { ServiceType } from 'core/enums/ServiceType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import useWindowSize from 'core/services/useWindowSize'
import { formatNumber } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import {
    getDeductibleContributionOfSelectedFamilyMember,
    getSelectedFamilyMember
} from 'shared/store/combineSelectors'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'
import {
    fetchFamilyMember,
    fetchInitialPolicyNumber,
    getFamilyMemberIndex
} from 'shared/store/familyMember/familyMember.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { goToServicesForm } from 'shared/store/services/services.slice'
import { useAppDispatch } from 'shared/store/store'

import AssuraEcLinkButton from '../AssuraEcLinkButton/AssuraEcLinkButton'
import AssuraPopover from '../AssuraPopover/AssuraPopover'
import { useHowItWorksPdfRedirection } from './TempHowItWorksPdfRedirect/useTempHowItWorksPdf'

interface BaseInsuranceProps {
    lamalProduct: ProductPerson | undefined
    isFromHome?: boolean
}

const BaseInsurance = ({ lamalProduct, isFromHome = false }: BaseInsuranceProps): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { isMobile, isXSD } = useWindowSize()
    const digCliParameters = useSelector(getDigcliparameters)

    const insuranceTitle = lamalProduct?.title ?? ''
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const deductibleContribution = useSelector(getDeductibleContributionOfSelectedFamilyMember)
    const familyMemberIndex = useSelector(getFamilyMemberIndex)

    const { policyNumber } = useSelector(getSelectedFamilyMember)

    const redirectToInsurance = () => {
        if (!policyNumber) return
        navigate(`${navigationConstants.INSURANCES.url}?policy=${policyNumber}`)
    }

    const goToDeductibleForm = () => {
        dispatch(
            goToServicesForm({
                type: ServiceType.DEDUCTIBLE_CHANGE
            })
        )
    }

    const navigateToHowItWorks = (): void => {
        dispatch(fetchInitialPolicyNumber({ initialPolicyNumber: policyNumber }))
        navigate(navigationConstants.LAMAL_RULES.url)
        sendEvent(analyticsConstants.EVENTS.OPEN_CCM, {
            [analyticsConstants.PARAMS.ENTRY_POINT]: entryPointsConstants.ENTRY_POINTS.INSURANCES
        })
    }
    // Should be deleted once we fully implements November's How It Works
    const { handleNavigateToAsset } = useHowItWorksPdfRedirection(lamalProduct)

    const renderHeader = (insuranceTitle: string | undefined, isFromHome: boolean) => {
        return (
            <div className="base-insurance-header-container bg-white">
                <div
                    data-testid="base-insurance-lamal"
                    className="base-insurance-lamal-title-container"
                >
                    <div className="d-flex flex-column">
                        <span className="headlineSmallMedium">{t('COVERAGE.LAMAL_TITLE')}</span>
                        <span
                            data-testid="base-insurance-lamal-title"
                            className="d-flex labelSmall base-insurance-lamal-title c-gray500"
                        >
                            {insuranceTitle}
                            {digCliParameters?.isCCMWebviewActivated === 'true' &&
                                isFromHome &&
                                lamalProduct?.infoLamalPublic && (
                                    <div
                                        className="m-left-8 icon-container default clickable"
                                        onClick={navigateToHowItWorks}
                                    >
                                        <i
                                            className="icon assura-help-circle c-gray500 size-16"
                                            data-testid="lamal-info-icon"
                                        />
                                    </div>
                                )}
                        </span>
                    </div>
                    {!isFromHome && lamalProduct?.infoLamalPublic && (
                        <AssuraIconButton
                            id="cta-informations-lamal"
                            icon="assura-next"
                            variant="primary"
                            size="medium"
                            onClick={
                                digCliParameters?.isCCMWebviewActivated === 'true'
                                    ? navigateToHowItWorks
                                    : handleNavigateToAsset
                            }
                            label={`${t('COVERAGE.LAMAL_INFORMATION')} ${insuranceTitle}`}
                            classNames="m-top-4"
                        />
                    )}
                </div>
                {isFromHome && (
                    <div className="base-insurance-header-button-container">
                        <AssuraIconButton
                            id="base-insurance-show-details"
                            icon="assura-next"
                            variant="primary"
                            size="medium"
                            onClick={() => redirectToInsurance()}
                            label={t('COMMON.SEE_DETAIL')}
                        />
                    </div>
                )}
            </div>
        )
    }

    interface ProgressBarParams {
        id: string
        label: string
        currentValue: number
        totalValue: number
        popOverInfo?: JSX.Element
        isFromHome?: boolean
    }

    const renderProgressBar = ({
        id,
        label,
        currentValue,
        totalValue,
        popOverInfo,
        isFromHome
    }: ProgressBarParams) => {
        const totalValueForProgressBar = totalValue !== 0 ? totalValue : 1
        return (
            <div className="d-flex flex-column">
                <div className="base" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div
                        className="base-label"
                        style={{
                            display: 'flex',
                            flex: `${isXSD ? '100%' : '0%'}`,
                            alignItems: 'center'
                        }}
                    >
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="labelSmall">{t(label)}</div>
                            {isFromHome && (
                                <>
                                    {!isMobile ? (
                                        <div
                                            className="franchidse-modify labelExtraSmall m-left-8 cursor-pointer"
                                            onClick={goToDeductibleForm}
                                            data-testid="base-insurance-modify-button"
                                        >
                                            {t('COMMON.MODIFY')}
                                        </div>
                                    ) : (
                                        <AssuraIconButton
                                            id="base-insurance-modify-button"
                                            icon="assura-edit"
                                            variant="primary"
                                            size="small"
                                            onClick={goToDeductibleForm}
                                            classNames="m-left-8"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {popOverInfo && (
                            <AssuraPopover
                                id="base-insurance-popover"
                                position="bottom"
                                triggerType={['hover', 'focus']}
                                content={popOverInfo}
                            >
                                <div className="m-left-8">
                                    <i
                                        className="icon assura-info-circle c-gray500 size-16"
                                        data-testid="base-insurance-info-icon"
                                    />
                                </div>
                            </AssuraPopover>
                        )}
                    </div>
                    <div
                        data-testid={`base-insurance-consumed-${id}`}
                        className="labelSmall c-gray500"
                    >
                        {t('COMMON.PRICE', { price: formatNumber(currentValue) }) + ' /'}
                        &nbsp;
                    </div>
                    <div data-testid={`base-insurance-available-${id}`} className="labelSmall">
                        {t('COMMON.PRICE', { price: formatNumber(totalValue) })}
                    </div>
                </div>
                <ProgressBar now={(currentValue / totalValueForProgressBar) * 100} />
            </div>
        )
    }

    const renderBody = () => {
        return (
            <div className="base-insurance-body-container">
                {renderProgressBar({
                    id: 'franchise',
                    label: 'FAMILY_GROUP.DEDUCTIBLE',
                    currentValue: deductibleContribution?.deductibleConsumed,
                    totalValue: deductibleContribution?.deductible,
                    isFromHome
                })}
                <div style={{ marginTop: 40 }}>
                    {renderProgressBar({
                        id: 'quote-part',
                        label: 'FAMILY_GROUP.CONTRIBUTION',
                        currentValue: deductibleContribution?.contributionConsumed,
                        totalValue: deductibleContribution?.contribution,
                        popOverInfo: renderContributionPopoverContent()
                    })}
                </div>
            </div>
        )
    }

    const renderContributionPopoverContent = () => {
        return (
            <div className="paragraphSmallLight" data-testid="base-insurance-popover-content">
                {deductibleContribution?.contributionConsumed > deductibleContribution?.contribution
                    ? t('FAMILY_GROUP.CONTRIBUTION_POPUP_EXCEEDED_DESCRIPTION')
                    : t('FAMILY_GROUP.CONTRIBUTION_POPUP_DESCRIPTION')}
            </div>
        )
    }

    const renderNoLamalContent = () => {
        return (
            <div className="base-insurance-lamal-no-contract-container">
                <div
                    className="headline m-bottom-16"
                    data-testid="base-insurance-lamal-no-contract-title"
                >
                    {t('COVERAGE.DISCOVER_INSURANCE_TITLE')}
                </div>
                <div
                    className="paragraphSmallLight m-bottom-32"
                    data-testid="base-insurance-lamal-no-contract-content"
                >
                    {t('COVERAGE.DISCOVER_INSURANCE_DESCRIPTION')}
                </div>
                <AssuraEcLinkButton
                    ecLinkId="646391065afb28b49a04023d"
                    testId="base-insurance-lamal-no-contract-button"
                    text={t('COMMON.LEARN_MORE')}
                    variant="primary"
                    icon={{
                        name: 'assura-external-link',
                        size: 16
                    }}
                />
            </div>
        )
    }

    return (
        <div className="base-insurance-container bc-gray100">
            <AssuraLoadAndError
                status={productsLoaderStatus}
                defaultReloadAction={() =>
                    dispatch(fetchFamilyMember({ selectedIndex: familyMemberIndex, reload: true }))
                }
                activityIndicatorSize={24}
                shouldDisplayContainer
            >
                {lamalProduct ? (
                    <div className="base-insurance-lamal-container">
                        {renderHeader(insuranceTitle, isFromHome)}
                        {renderBody()}
                    </div>
                ) : (
                    <>{renderNoLamalContent()}</>
                )}
            </AssuraLoadAndError>
        </div>
    )
}

export default BaseInsurance
