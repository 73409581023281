import './HomeNotificationsCard.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { HomeNotificationsCardData } from 'core/models/HomeNotifications'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'

export interface HomeNotificationsCardProps {
    notification?: HomeNotificationsCardData
    loadingStatus: LoadingStatusEnum
    reloadAction: () => void
    iconComponent?: JSX.Element
    extraUrlQuery?: string | undefined
}

const HomeNotificationsCard = ({
    notification,
    reloadAction,
    loadingStatus,
    iconComponent,
    extraUrlQuery
}: HomeNotificationsCardProps): JSX.Element => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleRedirect = () => {
        const urlNavigationLink = extraUrlQuery
            ? `${notification?.link}&${extraUrlQuery}`
            : notification?.link

        notification && urlNavigationLink && navigate(urlNavigationLink)
    }

    return (
        <div
            className="notifications-card-container animated-block"
            data-testid={`notifications-card-${notification?.testId}`}
        >
            <AssuraLoadAndError
                status={loadingStatus}
                defaultReloadAction={reloadAction}
                activityIndicatorSize={24}
                shouldDisplayErrorMessage={false}
            >
                {notification ? (
                    <div className="d-flex align-items-center flex-1" onClick={handleRedirect}>
                        <div
                            className="headlineMedium notifications-card-count"
                            data-testid={`notifications-card-count-${notification.testId}`}
                        >
                            {notification.link.startsWith(navigationConstants.PERSONAL_DATA.url) ? (
                                <div className="prefix-icon">
                                    <i
                                        className="icon assura-warning-circle c-primary"
                                        data-testid={`notifications-card-icon-warning-${notification.testId}`}
                                    />
                                </div>
                            ) : iconComponent ? (
                                iconComponent
                            ) : (
                                <div className={`c-${notification.color}`}>
                                    {notification.count ?? 0}
                                </div>
                            )}
                        </div>
                        <div
                            className="label notifications-card-title"
                            data-testid={`notifications-card-title-${notification.testId}`}
                        >
                            {t(notification.title)}
                        </div>
                        <AssuraIconButton
                            id={`notifications-card-link-${notification.testId}`}
                            icon="assura-right"
                            variant="primary"
                            size="small"
                            classNames="c-gray300"
                        />
                    </div>
                ) : (
                    <div />
                )}
            </AssuraLoadAndError>
        </div>
    )
}

export default HomeNotificationsCard
