import { ProductTypeCmsEnum } from 'core/enums/Product'
import { Characteristics } from 'core/models/cms/Characteristics'
import { CharacteristicsWithValue } from 'core/models/cms/CharacteristicsWithValue'
import { ProductCategoryCmsLocalized } from 'core/models/cms/ProductCategoryCmsLocalized'
import { ProductCmsLocalized } from 'core/models/cms/ProductCmsLocalized'
import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import { AccidentVariant } from 'core/models/familyGroup/AccidentVariant'
import { ProductVariant } from 'core/models/familyGroup/ProductVariant'
import { Product } from 'core/models/Product'

const formatCharacteristics = (objectCms: CharacteristicsWithValue[] | string) => {
    const characteristics: Characteristics[] = []
    if (objectCms && typeof objectCms !== 'string') {
        objectCms.forEach((item) =>
            characteristics.push({ title: item?.value?.title, text: item?.value?.text })
        )
    }
    return characteristics
}

export const mapProductCmsToProduct = (
    productCms: ProductCmsLocalized,
    correspondingCategory: ProductCategoryCmsLocalized | undefined
): Product => {
    let iconPath
    if (productCms.type === ProductTypeCmsEnum.LAMAL) {
        iconPath = productCms?.icon ? productCms.icon.path : undefined
    } else {
        iconPath = correspondingCategory?.icon ? correspondingCategory.icon.path : undefined
    }
    return {
        id: productCms._id,
        title: productCms.title,
        description: productCms.description,
        insuranceId: productCms.insurance_id,
        detailAvailable: productCms.detail_available,
        categoryId: correspondingCategory ? correspondingCategory._id : undefined,
        categoryType: correspondingCategory ? correspondingCategory.id : undefined,
        categoryTitle: correspondingCategory ? correspondingCategory.title : undefined,
        iconPath,
        characteristics: formatCharacteristics(productCms.characteristics),
        choiceOfPharmacy: productCms.choice_of_pharmacy,
        choiceOfDoctor: productCms.choice_of_doctor,
        url: productCms.url,
        conditionsLca: productCms.conditions_lca ? productCms.conditions_lca : undefined,
        infoLamal: productCms.info_lamal ? productCms.info_lamal : undefined,
        infoLamalPublic: productCms.info_lamal_public ? productCms.info_lamal_public : undefined,
        type: productCms.type
    }
}

export const getRecommendedProductFromProductCms = (
    productCms: ProductCmsLocalized,
    correspondingCategory: ProductCategoryCmsLocalized
): RecommendedProduct => {
    return {
        id: productCms._id,
        insuranceId: productCms.insurance_id,
        title: productCms.title,
        subtitle: correspondingCategory.title,
        icon: correspondingCategory.icon?.path,
        description: productCms.description,
        characteristics: formatCharacteristics(productCms.characteristics),
        url: productCms.url,
        photo: correspondingCategory.photo?.path
    }
}

export const getRecommendedProductFromProductCategoryCms = (
    productCategoryCms: ProductCategoryCmsLocalized
): RecommendedProduct => {
    return {
        id: productCategoryCms._id,
        insuranceId: productCategoryCms.id,
        title: productCategoryCms.highlight_products,
        subtitle: productCategoryCms?.title,
        icon: productCategoryCms?.icon?.path,
        description: productCategoryCms.description,
        characteristics: formatCharacteristics(productCategoryCms.characteristics),
        url: productCategoryCms.url,
        photo: productCategoryCms.photo?.path
    }
}

export const prepareAccidentVariantsData = (
    productVariants: ProductVariant[] | undefined
): AccidentVariant[] => {
    const accidentVariantData: AccidentVariant[] = []
    const commonPriceLabel = 'COMMON.PRICE'
    const commonPricePerDayLabel = 'COMMON.PRICE_PER_DAY'
    if (productVariants && productVariants.length > 0) {
        productVariants.forEach((product) => {
            const accidentVariant: AccidentVariant = {
                productCode: '',
                capitalsData: [],
                allowancesData: [],
                healingCost: 0
            }
            accidentVariant.productCode = product.productCode
            accidentVariant.healingCost = product.healingCost

            product.deathBenefits !== 0 &&
                accidentVariant.capitalsData.push({
                    label: 'COVERAGE.DEATH_BENEFITS',
                    labelValue: commonPriceLabel,
                    value: product.deathBenefits
                })
            product.disabilityBenefits !== 0 &&
                accidentVariant.capitalsData.push({
                    label: 'COVERAGE.DISABLITY_BENEFITS',
                    labelValue: commonPriceLabel,
                    value: product.disabilityBenefits
                })
            product.hospitalBenefits !== 0 &&
                accidentVariant.capitalsData.push({
                    label: 'COVERAGE.HOSPITALIZATION_BENEFITS',
                    labelValue: commonPriceLabel,
                    value: product.hospitalBenefits
                })
            product.hospitalizationAllowance !== 0 &&
                accidentVariant.allowancesData.push({
                    label: 'COVERAGE.HOSPITALIZATION_ALLOWANCE',
                    labelValue: commonPricePerDayLabel,
                    value: product.hospitalizationAllowance
                })
            product.dailyAllowance !== 0 &&
                accidentVariant.allowancesData.push({
                    label: 'COVERAGE.DAILY_ALLOWANCE',
                    labelValue: commonPricePerDayLabel,
                    value: product.dailyAllowance
                })

            accidentVariantData.push(accidentVariant)
        })
    }

    return accidentVariantData
}

export const ConvertToProductCodes = (codes: string[], lamalWithDoctors: string[]): string[] => {
    const productCodes: string[] = []
    codes.forEach((p: string) => {
        const f = lamalWithDoctors.find((e) => e.toUpperCase() === p.toUpperCase())
        if (f) productCodes.push(f)
    })
    return productCodes
}
