import './EnveloppesPanelFooter.css'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { PopinItemEnum } from 'core/enums/Popin'
import { generateGUID } from 'core/helpers/GuidHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { EnveloppesReplaceLocationState } from 'core/models/enveloppesSubmission/EnveloppesReplaceLocationState'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraIconButtonBelow from 'shared/components/AssuraIconButtonBelow/AssuraIconButtonBelow'
import {
    DeleteEnveloppePayload,
    setRescanImagesBatchNumber,
    setRescanImagesSelectedPolicyNumber,
    setSelectedEnveloppeId
} from 'shared/store/enveloppes/enveloppes.slice'
import { setSelectedPopinItem } from 'shared/store/popin/popin.slice'

interface EnveloppesPanelFooterProps {
    policyNumber: number
    personName: string
    batchNumber: string
    isDeleting: boolean
    isImages: boolean
    enveloppeName: string
    documents: AssuraDocument[]
}

const EnveloppesPanelFooter = (props: EnveloppesPanelFooterProps): JSX.Element => {
    const {
        policyNumber,
        personName,
        batchNumber,
        isDeleting,
        isImages,
        enveloppeName,
        documents
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onReplaceEnveloppe = () => {
        if (isImages) {
            dispatch(setRescanImagesSelectedPolicyNumber(policyNumber))
            dispatch(setRescanImagesBatchNumber(batchNumber))
            dispatch(setSelectedEnveloppeId(''))
            navigate(`${navigationConstants.ENVELOPPES_SUBMISSION.url}`)
        } else {
            const files = documents.map(
                (doc, index) =>
                    ({
                        id: generateGUID(),
                        fileName: `${t('COMMON.DOCUMENT')} ${index + 1}.pdf`,
                        pdfFileBase64: doc.base64,
                        pagesCount: 0,
                        isValid:
                            doc.backToScanStatus !== EnveloppeStatus.TechnicalError &&
                            doc.backToScanStatus !== EnveloppeStatus.VisibilityError
                    }) as DroppedFile
            )

            const data: EnveloppesReplaceLocationState = {
                personName,
                batchNumber,
                enveloppeName,
                files
            }

            dispatch(setSelectedEnveloppeId(''))
            navigate(navigationConstants.ENVELOPPES_BACK_TO_SCAN.url, {
                state: data
            })
        }
    }

    const handleDeleteAction = () => {
        dispatch(
            setSelectedPopinItem({
                popinItem: PopinItemEnum.ENVELOPPES_DELETE,
                extraData: {
                    batchNumber
                } as DeleteEnveloppePayload
            })
        )
    }

    return (
        <div className="enveloppes-panel-footer-container">
            {isDeleting ? (
                <div className="enveloppes-panel-footer-spinner-container">
                    <ActivityIndicator />
                </div>
            ) : (
                <AssuraIconButtonBelow
                    id="enveloppes-panel-footer-delete-button"
                    icon="assura-delete"
                    variant="default"
                    iconSize={24}
                    labelFontClass="labelExtraSmallMedium"
                    margin={4}
                    onClick={handleDeleteAction}
                    label={t('COMMON.DELETE')}
                />
            )}
            <AssuraButton
                id="enveloppes-panel-footer-replace-button"
                onClick={onReplaceEnveloppe}
                text={t(
                    isImages ? 'ENVELOPPES.REPLACE_DOCUMENT' : 'ENVELOPPES.PANEL_BACK_TO_SCAN_CTA'
                )}
                variant="primary"
                icon={{
                    name: 'assura-refresh',
                    size: 24
                }}
            />
        </div>
    )
}

export default EnveloppesPanelFooter
