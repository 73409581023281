import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface InvoicesToCheckPanelSubHeaderProps {
    handleClose: () => void
}

const InvoicesToCheckPanelSubHeader = ({
    handleClose
}: InvoicesToCheckPanelSubHeaderProps): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()

    return (
        <>
            {!isXL && (
                <div
                    className="bg-white itc-panel-adaptive-padding-horizontal d-flex align-items-center border-bottom-solid-width-1 bbc-gray100"
                    style={{ height: 56 }}
                >
                    <AssuraIconButton
                        id="itc-panel-pdf-back"
                        icon="assura-back"
                        variant="primary"
                        size="medium"
                        onClick={handleClose}
                        label={t('COMMON.BACK')}
                    />
                </div>
            )}
        </>
    )
}

export default InvoicesToCheckPanelSubHeader
