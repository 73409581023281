import { SymptomCheckerUrls } from 'core/models/SymptomChecker'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getConcernedPartner } from '../symptomChecker/symptomChecker.slice'

export const getSymptomCheckerUrls = createSelector(
    [getLang, getConcernedPartner],
    (lang, concernedPartner) => {
        const envUrl = process.env.REACT_APP_SYMPTOM_CHECKER

        const urlTokenParam = `token=${concernedPartner?.token}`
        const urlAgeParam = concernedPartner?.age ? `age=${concernedPartner.age}` : ''
        const urlSexParam = concernedPartner?.sex ? `sex=${concernedPartner.sex}` : ''
        const urlInterviewDirectParam = concernedPartner?.interviewDirect
            ? `interview_direct=${concernedPartner.interviewDirect}`
            : ''

        const urlParameters = [urlTokenParam, urlAgeParam, urlSexParam, urlInterviewDirectParam].reduce((accumulator, currentValue) => {
            if (currentValue !== '') {
                return accumulator === ''
                    ? `${accumulator}?${currentValue}`
                    : `${accumulator}&${currentValue}`
            } else {
                return accumulator
            }
        }, '')

        const localeSuffix = lang ? `/${lang}` : ''

        const commonUrl = `${envUrl}${localeSuffix}`

        return {
            fullUrl: `${commonUrl}${urlParameters}`,
            urlWithoutParameters: commonUrl
        } as SymptomCheckerUrls
    }
)
