import { call, fork, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects'

import { updateDeviceInfo } from 'core/api/Device'
import { headOfFamilyApi } from 'core/api/HeadOfFamily'
import { setDeviceInfoHeaders } from 'core/api/Helper'
import { getCguAcceptationStatus, updateCguAcceptationStatus } from 'core/api/tos'
import analyticsConstants from 'core/constants/analyticsConstants'
import { DEFAULT_LANGUAGE } from 'core/constants/lang'
import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { initializeAnalytics, updatePageViewParams } from 'core/helpers/AnalyticsHelper'
import { setLangCookie } from 'core/helpers/CookieHelper'
import { getFormByRoute } from 'core/helpers/services/ServicesFormHelper'
import { HeadOfFamily } from 'core/models/HeadOfFamily'
import { RedirectionParameters } from 'core/models/RedirectionParameters'
import { getCurrentYear } from 'core/utils/dateUtils'
import i18n from 'i18next'
import authService from 'index'
import { globalNavigate } from 'shared/components/GlobalHistory/GlobalHistory'
import { logout } from 'shared/store/authentication/authentication.slice'

import { initAppSearchSuggestions } from '../appSearch/appSearch.saga'
import { fetchAssets } from '../assets/assets.slice'
import { fetchCommunicationsNotificationCount } from '../communications/communications.slice'
import { fetchUnreadDocuments } from '../documents/documents.slice'
import { fetchEcLinks } from '../ecLinks/ecLinks.slice'
import { onFetchEnveloppes } from '../enveloppes/enveloppes.slice'
import { reset as resetFamilyMemberSlice } from '../familyMember/familyMember.slice'
import {
    fetchFamilyProductsSaga,
    fetchSummaryByYearAsync
} from '../familySummaries/familySummaries.saga'
import { reset as resetFamilyGroupSlice } from '../familySummaries/familySummaries.slice'
import { fetchFaqCollectionsSaga } from '../faq/faq.saga'
import { reset as resetFinancialDocumentsSlice } from '../financialDocuments/financialDocuments.slice'
import { fetchGeneralQuestionTopic } from '../generalQuestionTopic/generalQuestionTopic.slice'
import { fetchMissingData } from '../missingData/missingData.slice'
import { fetchNewsCms } from '../news/news.slice'
import { fetchNotifications } from '../notifications/notifications.slice'
import { initPopinSaga } from '../popin/popin.saga'
import { initProductsCmsData } from '../products/products.slice'
import { fetchQuickAccessCms } from '../quickAccess/quickAccess.slice'
import { initContactInfos } from '../services/contactInfos/contactInfos.saga'
import { reset as resetContactInfos } from '../services/contactInfos/contactInfos.slice'
import { initFinancial } from '../services/financial/financial.slice'
import { fetchFrequentRequests } from '../services/frequentRequests/frequentRequests.slice'
import { getRequests } from '../services/requests/requests.slice'
import { initSelectedPolicyNumber } from '../services/services.saga'
import { fetchGoodToKnowCms, goToServicesForm } from '../services/services.slice'
import { fetchSplashScreenCms } from '../splashScreen/splashScreen.slice'
import { fetchUnpaidStats } from '../unpaidStats/unpaidStats.slice'
import { fetchWhatsNew } from '../whatsNew/whatsNew.slice'
import {
    getRedirectionParameters,
    initData,
    postDeviceInfo,
    resetSlices,
    setHeadOfFamily,
    setInitDataLoadingStatus,
    setLang,
    setLangSuccess,
    setUserUtn,
    validateCgu
} from './general.slice'

function* initDataSaga(action: ReturnType<typeof initData>) {
    try {
        yield put(setInitDataLoadingStatus(LoadingStatusEnum.LOADING))
        yield fork(initializeAnalytics)
        const userUtn: number | undefined = yield call([authService, authService.getUserUtn])

        yield put(setUserUtn(userUtn))

        yield fork(initHeadOfFamilySaga)
        if (Source() === ServicesSource.WEBSITE) {
            yield call(initWebsiteDataSaga)
        } else {
            yield call(initCustomerDataSaga, action)
        }
    } catch (e) {
        console.error('initDataSaga Error', e)
        yield put(setInitDataLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* initWebsiteDataSaga() {
    try {
        localStorage.removeItem('redirectionParameters')
        //Some data are mandatory to retrieve after a login. They will be fetched while going to the form
        const redirectionParameters: RedirectionParameters = yield select(getRedirectionParameters)
        yield spawn(updatePageViewParams, {
            [analyticsConstants.PARAMS.CHAT_ORIGIN]: redirectionParameters.visitOrigin
        })
        const lang = redirectionParameters.lang ?? localStorage.getItem('lang') ?? DEFAULT_LANGUAGE
        yield put(setLang(lang))
        yield fork(initCmsData)
        yield call(fetchFaqCollectionsSaga)
        const formType = redirectionParameters.form
        if (formType && Object.values(ServiceType).includes(formType)) {
            yield put(goToServicesForm({ type: formType }))
            yield put(setInitDataLoadingStatus(LoadingStatusEnum.OK))
        } else {
            if (formType) console.log(`Form type [${formType}] does not exist`)
            yield put(logout())
        }
    } catch (e) {
        console.error('initWebsiteDataSaga Error', e)
        throw e
    }
}

function* initCustomerDataSaga(action: ReturnType<typeof initData>) {
    try {
        //Some data are mandatory to retrieve after a login. Without them, we cannot go to the Home Page
        const lang = localStorage.getItem('lang') ?? DEFAULT_LANGUAGE
        yield put(setLang(lang))
        yield fork(initCmsData)
        yield put(fetchNewsCms())
        yield put(fetchSplashScreenCms())
        yield put(fetchQuickAccessCms())
        yield call(fetchFaqCollectionsSaga)
        yield call(setDeviceInfoHeaders)
        yield put(postDeviceInfo())
        yield call(initAppSearchSuggestions)
        yield call<typeof fetchSummaryByYearAsync>(fetchSummaryByYearAsync, {
            type: 'familySummaries/fetchSummaryByYear',
            payload: {
                year: getCurrentYear(),
                shouldFetchProducts: false,
                reload: action.payload.reload,
                shouldThrow: true
            }
        })
        yield fork(fetchFamilyProductsSaga)
        yield call(initSelectedPolicyNumber)
        if (Source() !== ServicesSource.BACKOFFICE) {
            yield call(initPopinSaga) //Popin should be initialized after concerned WS
        }

        if (Source() === ServicesSource.BACKOFFICE) {
            localStorage.removeItem('redirectionParameters')
            const redirectionParameters: RedirectionParameters =
                yield select(getRedirectionParameters)
            if (redirectionParameters.goToServices) globalNavigate(navigationConstants.SERVICES.url)
        }
        if (Source() === ServicesSource.CUSTOMER_PORTAL || Source() === ServicesSource.BACKOFFICE) {
            localStorage.removeItem('redirectionParameters')
            const redirectionParameters: RedirectionParameters =
                yield select(getRedirectionParameters)
            if (redirectionParameters.goToPage) {
                const foundServiceType = getFormByRoute(redirectionParameters.goToPage)
                if (foundServiceType) {
                    yield put(goToServicesForm({ type: foundServiceType }))
                } else {
                    globalNavigate(redirectionParameters.goToPage)
                }
            }
        }

        //Put setInitDataLoadingStatus OK as soon as summary and initSelectedPolicyMember are ok. Otherwise, it will take time to be processed by the JS thread if we put the action after the other actions below
        yield put(setInitDataLoadingStatus(LoadingStatusEnum.OK))
        // TODO : restore the call for the rollback Statements V2 to V1 & lastStatements block
        // const selectedMember: PersonSummary = yield select(getSelectedFamilyMember)
        // const path: string = yield select(getPath)
        // if (path === navigationConstants.ROOT.url || path === navigationConstants.HOME.url) {
        //     yield put(
        //         setSelectedDocumentCategoriesIndex({
        //             selectedDocumentCategoriesIndex: 1,
        //             filters: {
        //                 utn: selectedMember.userTiersNumber
        //             }
        //         })
        //     )
        // }
        yield put(onFetchEnveloppes())
        yield fork(initContactInfos)
        yield put(initFinancial())
        yield put(getRequests())
        yield fork(initHomeNotifications)
    } catch (e) {
        console.error('initCustomerDataSaga Error', e)
        throw e
    }
}

function* initCmsData() {
    try {
        yield put(initProductsCmsData())
        yield put(fetchAssets())
        yield put(fetchGoodToKnowCms())
        yield put(fetchEcLinks())
        yield put(fetchFrequentRequests())
        yield put(fetchGeneralQuestionTopic())
        yield put(fetchWhatsNew())
    } catch (e) {
        console.error('initCmsData Error', e)
    }
}

function* initHomeNotifications() {
    try {
        yield put(fetchUnpaidStats())
        yield put(fetchNotifications())
        yield put(fetchMissingData())
        yield put(fetchUnreadDocuments({ shouldUpdateLoadStatus: true }))
        yield put(fetchCommunicationsNotificationCount())
    } catch (e) {
        console.error('initNotifications Error', e)
    }
}

function* resetSlicesSaga() {
    try {
        //after a logout, we do not want to keep in the redux store some data
        yield put(resetFamilyGroupSlice())
        yield put(resetFamilyMemberSlice())
        yield put(resetFinancialDocumentsSlice())
        yield put(resetContactInfos())
    } catch (e) {
        console.error('resetSlicesSaga Error', e)
    }
}

function* setLangSaga(action: ReturnType<typeof setLang>) {
    try {
        setLangCookie(action.payload)
        localStorage.setItem('lang', action.payload)
        yield call(setDeviceInfoHeaders)
        yield put(setLangSuccess(action.payload))
        yield call([i18n, i18n.changeLanguage], action.payload)
        yield spawn(updatePageViewParams, {
            [analyticsConstants.PARAMS.APP_LANGUAGE]: action.payload
        })
    } catch (e) {
        console.error('setLangSaga Error', e)
    }
}

function* postDeviceInfoSaga() {
    try {
        yield call(updateDeviceInfo)
    } catch (e) {
        console.error('postDeviceInfoSaga Error', e)
    }
}

function* initHeadOfFamilySaga() {
    try {
        const headOfFamily: HeadOfFamily = yield call(headOfFamilyApi)
        yield put(setHeadOfFamily(headOfFamily))
    } catch (e) {
        console.error('initHeadOfFamilySaga Error', e)
    }
}

export function* cguPopinInit() {
    try {
        const shouldTriggerPopin: boolean = yield call(getCguAcceptationStatus)
        return shouldTriggerPopin
    } catch (e) {
        console.error('cguPopinInit Error', e)
        return false
    }
}

function* validateCguSaga() {
    try {
        yield call(updateCguAcceptationStatus)
    } catch (e) {
        console.error('validateCguSaga Error', e)
    }
}

function* initDataWatcher() {
    yield takeEvery(initData.type, initDataSaga)
}

function* resetSlicesWatcher() {
    yield takeEvery(resetSlices.type, resetSlicesSaga)
}

function* setLangWatcher() {
    yield takeEvery(setLang.type, setLangSaga)
}

function* postDeviceInfoWatcher() {
    yield takeEvery(postDeviceInfo.type, postDeviceInfoSaga)
}

function* validateCguWatcher() {
    yield takeLatest(validateCgu.type, validateCguSaga)
}

const watchers = [
    fork(initDataWatcher),
    fork(resetSlicesWatcher),
    fork(setLangWatcher),
    fork(postDeviceInfoWatcher),
    fork(validateCguWatcher)
]

export default watchers
