import './UserInfo.css'

import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { logout } from 'shared/store/authentication/authentication.slice'
import { getConnectedUserInfos } from 'shared/store/selectors/getConnectedUserInfos'

type UserInfoProps = {
    allowedSources?: ServicesSource[]
    style?: CSSProperties | undefined
}

const UserInfo = ({
    style,
    allowedSources = [ServicesSource.CUSTOMER_PORTAL]
}: UserInfoProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const connectedUserInfos = useSelector(getConnectedUserInfos)

    if (!allowedSources.includes(Source()) && connectedUserInfos) {
        const { firstName, lastName, policyNumber, address } = connectedUserInfos
        return (
            <div className="services-user-info-container bc-gray100 bg-white" style={style}>
                <div className="services-user-info-data-container">
                    <div className="services-user-info-block-container">
                        <div className="labelMedium m-bottom-8">
                            {t('PERSONAL_DATA.PERSONAL_DATA')}
                        </div>
                        <div className="label c-gray500">{`${firstName} ${lastName}`}</div>
                        <div className="label c-gray500">
                            {t('FAMILY_GROUP.POLICY_NUMBER', { policyNumber: policyNumber ?? '' })}
                        </div>
                    </div>
                    <div className="services-user-info-block-container services-user-info-address-container">
                        <div className="labelMedium m-bottom-8">{t('PERSONAL_DATA.ADDRESS')}</div>
                        <div className="label c-gray500">{`${address?.street ?? ''} ${
                            address?.streetNumber ?? ''
                        }`}</div>
                        <div className="label c-gray500">{`${address?.npaCode ?? ''} ${
                            address?.locality ?? ''
                        }`}</div>
                    </div>
                </div>
                {Source() === ServicesSource.WEBSITE && (
                    <div className="d-flex" style={{ width: 144 }}>
                        <AssuraIconButton
                            onClick={() => dispatch(logout())}
                            label={t('MORE.LOGOUT')}
                            size="medium"
                            variant="primary"
                            id="user-info-logout"
                            icon="assura-logout"
                        />
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
}

export default UserInfo
