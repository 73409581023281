import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { newsCmsData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { NewsCms } from 'core/models/cms/NewsCms'

import { fetchNewsCms, fetchNewsCmsSuccess, setNewsLoadingStatus } from './news.slice'

function* fetchNewsCmsSaga() {
    try {
        yield put(setNewsLoadingStatus(LoadingStatusEnum.LOADING))
        const newsCms: NewsCms[] = yield call(newsCmsData)
        yield put(fetchNewsCmsSuccess(newsCms))
        yield put(setNewsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setNewsLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchNewsCmsSaga Error', e)
    }
}

function* fetchNewsCmsWatcher() {
    yield takeEvery(fetchNewsCms.type, fetchNewsCmsSaga)
}

const watchers = [fork(fetchNewsCmsWatcher)]

export default watchers
