import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getWantedDate } from '../services/doctors/doctors.slice'

export const getSummaryWantedDate = createSelector(
    [getFamilySummaries, getWantedDate],
    (summaries, wantedDate) => summaries[wantedDate?.getFullYear() ?? getCurrentYear()]
)
