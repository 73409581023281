import './SymptomCheckerPopin.css'

import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { useNavigate } from 'react-router-dom'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { SymptomCheckerPartnerTypeEnum } from 'core/enums/SymptomChecker'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { SymptomCheckerPartner } from 'core/models/SymptomChecker'
import useWindowSize from 'core/services/useWindowSize'
import { GetAssetIcon } from 'core/utils/assetUtils'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import Icon, { ColorsEnum, IconNamesEnum } from 'shared/components/AssuraTabDropdown/Icon'
import { getPopinExtraData, resetSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { useAppDispatch } from 'shared/store/store'
import {
    getConcernedPartner,
    getDefaultConcernedPartner,
    getIsOnlyOneQualiMedPartner,
    getPartners,
    initSymptomCheckerForm
} from 'shared/store/symptomChecker/symptomChecker.slice'

import SymptomCheckerCgu from './SymptomCheckerCgu'

const mainContainerWidth = 64

const SymptomCheckerPopin = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { isMobile } = useWindowSize()

    const isRestartAction = useSelector(getPopinExtraData) as boolean | undefined
    const concernedPartner = useSelector(getConcernedPartner)
    const defaultConcernedPartner = useSelector(getDefaultConcernedPartner)
    const partners = useSelector(getPartners)
    const isOnlyOneQualiMedPartner = useSelector(getIsOnlyOneQualiMedPartner)

    const [isCguValidated, setIsCguValidated] = useState(isRestartAction || false)
    const [currentConcernedPartner, setCurrentConcernedPartner] =
        useState<SymptomCheckerPartner | null>(defaultConcernedPartner)

    const handleCloseAction = () => dispatch(resetSelectedPopinItem())

    const handleCancelAction = () => {
        if (isRestartAction && concernedPartner) {
            dispatch(initSymptomCheckerForm(concernedPartner))
        }
        handleCloseAction()
    }

    const handleValidation = () => {
        if (!currentConcernedPartner) return
        dispatch(initSymptomCheckerForm(currentConcernedPartner))

        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_START, {
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: currentConcernedPartner?.lamalProductCode,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })

        if (!isRestartAction) navigate(navigationConstants.SYMPTOM_CHECKER.url)
        handleCloseAction()
    }

    const handleCguValidation = () => {
        if (isOnlyOneQualiMedPartner) {
            handleValidation()
        } else {
            setIsCguValidated(true)
        }
    }

    const handlePartnerSelection = (partner: SymptomCheckerPartner) => {
        setCurrentConcernedPartner(partner)
    }

    return (
        <RemoveScroll className="symptom-checker-pop-in-content" data-testid="cgu-pop-in-content">
            {isCguValidated ? (
                <>
                    <AssuraIconButton
                        id="symptom-checker-pop-in-close"
                        icon="assura-close"
                        variant="default"
                        size="medium"
                        onClick={handleCancelAction}
                        classNames="symptom-checker-pop-in-close-button"
                    />
                    <div className="d-flex flex-column justify-content-center align-items-center p-16">
                        <img
                            src={GetAssetIcon('family')}
                            className="symptom-checker-pop-in-icon"
                            alt="symptom-checker-pop-in-icon"
                            width="80"
                            height="80"
                        />
                        <div className="headlineSmallMedium m-top-32 text-center">
                            {t('SYMPTOM_CHECKER.POPIN_TITLE')}
                        </div>
                        <div className="paragraphSmallLight m-top-16 text-center">
                            {t('SYMPTOM_CHECKER.POPIN_CONTENT')}
                        </div>
                        <div className="symptom-checker-pop-in-avatars-list">
                            {partners.map((partner, index) => (
                                <Fragment key={`symptom-checker-pop-in-avatars-${index}`}>
                                    {partner.type === SymptomCheckerPartnerTypeEnum.OTHER && (
                                        <div className="symptom-checker-pop-in-avatars-separator bg-gray300" />
                                    )}
                                    <div
                                        className="symptom-checker-pop-in-avatar"
                                        onClick={() => handlePartnerSelection(partner)}
                                        key={`symptom-checker-pop-in-avatar${index}`}
                                        data-testid={`symptom-checker-pop-in-avatar-${index}`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <AssuraAvatar
                                            id={`symptom-checker-pop-in-avatar-${index}`}
                                            label={{
                                                title:
                                                    partner.type ===
                                                    SymptomCheckerPartnerTypeEnum.OTHER
                                                        ? t('COMMON.OTHER')
                                                        : partner.firstName,
                                                classNames: `labelExtraSmallMedium ${
                                                    partner.policyNumber ===
                                                    currentConcernedPartner?.policyNumber
                                                        ? 'c-black'
                                                        : 'c-gray500'
                                                }`,
                                                marginTop: 4
                                            }}
                                            mainContainerWidth={mainContainerWidth}
                                            avatarContainerWidth={56}
                                            border={{
                                                width: 3,
                                                color: 'gray50'
                                            }}
                                            active={
                                                partner.policyNumber ===
                                                currentConcernedPartner?.policyNumber
                                            }
                                            avatarExtraClass={
                                                partner.type === SymptomCheckerPartnerTypeEnum.OTHER
                                                    ? 'symptom-checker-pop-in-avatar-other'
                                                    : undefined
                                            }
                                        >
                                            {partner.type ===
                                            SymptomCheckerPartnerTypeEnum.MEMBER ? (
                                                <div className="headlineSmallMedium">
                                                    {(
                                                        partner.firstName.charAt(0) +
                                                        partner.lastName.charAt(0)
                                                    ).toUpperCase()}
                                                </div>
                                            ) : (
                                                <Icon
                                                    name={IconNamesEnum.profile}
                                                    color={ColorsEnum.white}
                                                    size={32}
                                                    dataId="symptom-checker-pop-in-avatar-other"
                                                />
                                            )}
                                        </AssuraAvatar>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                        <AssuraButton
                            text={t('COMMON.VALIDATE')}
                            id="symptom-checker-pop-in-validate"
                            variant="primary"
                            onClick={handleValidation}
                            classNames="symptom-checker-pop-in-cta-validation"
                            fullWidth={isMobile}
                        />
                    </div>
                </>
            ) : (
                <SymptomCheckerCgu onValidation={handleCguValidation} onClose={handleCloseAction} />
            )}
        </RemoveScroll>
    )
}

export default SymptomCheckerPopin
