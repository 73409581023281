import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { fetchQrCode, getQrCodes } from 'shared/store/qrCodes/qrCodes.slice'

export interface AssuraQrCodeProps {
    testId: string
    documentId: string
}

const AssuraQrCode = ({ testId, documentId }: AssuraQrCodeProps): JSX.Element => {
    const qrList = useSelector(getQrCodes)
    const dispatch = useDispatch()
    const qr = qrList[documentId]

    useEffect(() => {
        dispatch(
            fetchQrCode({
                documentId
            })
        )
    }, [documentId])

    return (
        <AssuraLoadAndError
            status={qr?.loadStatus || LoadingStatusEnum.OK}
            key={`${testId}-qr-code-error-container`}
            defaultReloadAction={() =>
                dispatch(
                    fetchQrCode({
                        reload: true,
                        documentId
                    })
                )
                
            }
            activityIndicatorSize={24}
        >
            <>
                {qr?.data && (
                    <img
                        src={qr.data}
                        alt={`${testId}-qr-code`}
                        data-testid={`${testId}-qr-code`}
                    />
                )}
            </>
        </AssuraLoadAndError>
    )
}

export default AssuraQrCode
