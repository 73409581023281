import { createContext, ReactNode, useContext, useState } from 'react'

import { emptyFunction } from 'core/helpers/functionHelper'

interface AssuraNavBarProviderProps {
    children: ReactNode
}

export interface AssuraNavBarContext {
    isNavBarMenuOpen: boolean
    setNavBarMenuState: (state: boolean) => void
    isNavBarSearchOpen: boolean
    toggleNavBarSearch: () => void
    closeNavBarSearch: () => void
}

const AssuraNavBarContext = createContext<AssuraNavBarContext>({
    isNavBarMenuOpen: false,
    setNavBarMenuState: emptyFunction,
    isNavBarSearchOpen: false,
    toggleNavBarSearch: emptyFunction,
    closeNavBarSearch: emptyFunction
})

const AssuraNavBarProvider = ({ children }: AssuraNavBarProviderProps): JSX.Element => {
    const [isNavBarMenuOpen, setIsNavBarMenuOpen] = useState(false)
    const [isNavBarSearchOpen, setIsNavBarSearchOpen] = useState(false)

    const setNavBarMenuState = (state: boolean) => {
        setIsNavBarMenuOpen(state)
    }

    const toggleNavBarSearch = () => {
        setIsNavBarSearchOpen((prev) => !prev)
    }

    const closeNavBarSearch = () => {
        setIsNavBarSearchOpen(false)
    }

    const value: AssuraNavBarContext = {
        isNavBarMenuOpen,
        setNavBarMenuState,
        isNavBarSearchOpen,
        toggleNavBarSearch,
        closeNavBarSearch
    }

    return <AssuraNavBarContext.Provider value={value}>{children}</AssuraNavBarContext.Provider>
}

const useAssuraNavBarContext = (): AssuraNavBarContext => {
    return useContext(AssuraNavBarContext)
}

export { AssuraNavBarProvider, useAssuraNavBarContext }
