import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'

const ClaimsSettlementSubHeader = (): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()
    const { panelDatas, update } = usePanelDatasContext()

    return (
        <>
            {panelDatas.hasLeftPanel && !isXL && (
                <div
                    className="bg-white claims-settlement-panel-adaptive-padding-horizontal d-flex align-items-center border-bottom-solid-width-1 bbc-gray100"
                    style={{ height: 56 }}
                >
                    <AssuraIconButton
                        id="claims-settlement-panel-pdf-back"
                        icon="assura-back"
                        variant="primary"
                        size="medium"
                        onClick={() => update({ hasPDFPanel: false })}
                        label={t('COMMON.BACK')}
                    />
                </div>
            )}
        </>
    )
}

export default ClaimsSettlementSubHeader
