import { isAfter, isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getNewsCms } from '../news/news.slice'

export const getOrderedActiveNews = createSelector(
    [getNewsCms],
    (newsCms) => {
        const orderedNews = [...newsCms]
        orderedNews.sort((a, b) => parseInt(a?.order, 10) - parseInt(b?.order, 10))

        return orderedNews.filter((newsItem) => {
            if (!newsItem.end_date_publication || !newsItem.start_date_publication) {
                return false
            }
            const publicationStartDate = new Date(newsItem.start_date_publication)
            const publicationEndDate = new Date(newsItem.end_date_publication)
            const now = new Date()
            return isAfter(now, publicationStartDate) && isBefore(now, publicationEndDate)
        })
    }
)
