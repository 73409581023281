import './Phone.css'

import { useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import useWindowSize from 'core/services/useWindowSize'
import { getPhoneNumber, phoneFormatted, phoneRules } from 'core/utils/phoneUtils'
import { AssuraFormInputRHFNoLabel } from 'modules/services/components/InputRHF/InputRHF'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'
import { AssuraFormInputWithButtonNoLabel } from 'shared/components/AssuraInput/AssuraInput'
import {
    removeAllNonPermanentBanners,
    removeBanner,
    setBanner,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { PhoneView } from 'shared/store/selectors/getFullPersonalData'
import { postPhone } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getServiceSubmitStatus } from 'shared/store/services/services.slice'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'
import PersonalDataValidation from '../PersonalDataValidation/PersonalDataValidation'

interface PhoneProps {
    phone: PhoneView
}

interface PhoneForm {
    type: string
    phone: string
}

const Phone = ({ phone }: PhoneProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isXL } = useWindowSize()
    const isEnabledRef = useRef<boolean>(false)
    const submitServiceStatus = useSelector(getServiceSubmitStatus)
    const invalidFormBannerId = 'personal-data-phone'

    const {
        state: { fieldFocused, selectedPolicyNumber, isRequestsAvailable, isOnSuccess },
        isFormSelected,
        selectForm,
        setNoChangeError,
        setFocus,
        removeNoChangeError
    } = usePersonalDataContext()

    const isEnabled = isFormSelected(PersonalDataForm.PHONE)

    if (!phone.data) phone.data = {} as PhoneDatas

    const fullPhoneNumber = getPhoneNumber(phone.data)
    const phoneNumberFormatted = phoneFormatted(fullPhoneNumber)

    const getPhoneType = (type: string): string => {
        switch (type) {
            case 'fixed':
                return `(${t('PERSONAL_DATA.PHONE_TYPE_FIXED')}) `
            case 'mobile':
                return `(${t('PERSONAL_DATA.PHONE_TYPE_MOBILE')}) `
            default:
                return ''
        }
    }

    const setDefaultValues = () => ({
        phone: phoneNumberFormatted === '+ ' ? '+41 ' : phoneNumberFormatted,
        type: phone.data?.type ?? 'mobile'
    })

    const methods = useForm<PhoneForm>({
        defaultValues: setDefaultValues()
    })
    const { formState, setValue, register, reset, getValues } = methods
    const { errors, isDirty } = formState

    const handleCancel = () => {
        reset(setDefaultValues())
        dispatch(removeBanner(invalidFormBannerId))
    }

    const onSubmit = () => {
        if (submitServiceStatus !== LoadingStatusEnum.LOADING) {
            if (isDirty) {
                if (!hasWritePermission()) {
                    dispatch(setForbiddenBanner())
                    return
                }

                dispatch(
                    postPhone({
                        newPhoneNumber: getValues('phone'),
                        type: getValues('type')
                    })
                )
            } else {
                dispatch(removeBanner(invalidFormBannerId))
                setNoChangeError()
            }
        }
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const oldValue = getValues('phone')
        const newValue = e.currentTarget.value

        const match = new RegExp(/^\+41/).test(newValue.slice(0, 3))

        setValue('phone', !match ? `+41${oldValue.slice(3)}` : e.currentTarget.value, {
            shouldDirty: true,
            shouldValidate: true
        })
    }

    useEffect(() => {
        if (isEnabledRef.current && !isEnabled && !isOnSuccess) {
            handleCancel()
        }
        isEnabledRef.current = isEnabled
    }, [isEnabled])

    useEffect(() => {
        if (selectedPolicyNumber) handleCancel()
    }, [selectedPolicyNumber])

    useEffect(() => {
        if (isEnabled && formState.isSubmitted) {
            if (Object.keys(formState.errors).length > 0) {
                removeNoChangeError()
                dispatch(
                    setBanner({
                        id: invalidFormBannerId,
                        dataTestId: invalidFormBannerId,
                        message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
                    })
                )
            } else if (isDirty) {
                dispatch(removeAllNonPermanentBanners())
            }
        }
    }, [formState])

    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)

    return (
        <Row className="services-personal-data-phone-container">
            <Col xl={4} className="labelMedium assura-input-label-inline">
                {t('PERSONAL_DATA.CONTACT_PHONE_LABEL')}
            </Col>
            <Col
                xl={8}
                className={`labelMedium${isEnabled && isXL ? ' assura-input-label-inline' : ''}`}
            >
                <FormProvider {...methods}>
                    <form>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            {isEnabled ? (
                                <>
                                    <div className="d-flex services-personal-data-phone-type-container m-bottom-24">
                                        <RadioRHF
                                            id="mobile"
                                            name="type"
                                            label={t('PERSONAL_DATA.PHONE_TYPE_MOBILE')}
                                            register={register}
                                        />
                                        <RadioRHF
                                            id="fixed"
                                            name="type"
                                            label={t('PERSONAL_DATA.PHONE_TYPE_FIXED')}
                                            register={register}
                                            classNames="m-left-32"
                                        />
                                    </div>
                                    <AssuraFormInputRHFNoLabel
                                        name={PersonalDataForm.PHONE}
                                        id={'services-personal-data-phone-active'}
                                        isInvalid={Boolean(errors.phone)}
                                        error={t(errors.phone?.message as string)}
                                        hasButton={fieldFocused === PersonalDataForm.PHONE}
                                        onButtonClick={() => setValue('phone', '+41 ')}
                                        iconClass="icon assura-close size-24"
                                        rules={phoneRules}
                                        disabled={!isEnabled}
                                        register={register}
                                        onFocus={() => setFocus(PersonalDataForm.PHONE)}
                                        onBlur={() => {
                                            setFocus('')
                                        }}
                                        onChange={handleOnChange}
                                    />
                                </>
                            ) : (
                                <AssuraFormInputWithButtonNoLabel
                                    id={`personal-data-phone-${
                                        phone.isPending ? 'is-pending' : 'edit'
                                    }`}
                                    value={`${getPhoneType(phone.data?.type ?? '')}${
                                        phoneNumberFormatted === '+ '
                                            ? '+41 '
                                            : phoneNumberFormatted
                                    }`}
                                    isInvalid={Boolean(errors.phone)}
                                    hasButton={
                                        isRequestsAvailable &&
                                        missingDataLoadingStatus === LoadingStatusEnum.OK
                                    }
                                    onButtonClick={() => selectForm(PersonalDataForm.PHONE)}
                                    icon="assura-edit"
                                    buttonLabel={isXL ? t('COMMON.MODIFY') : ''}
                                    variant="primary"
                                    isPending={phone.isPending}
                                    disabled={!isEnabled}
                                />
                            )}
                        </div>
                        <PersonalDataValidation
                            form={PersonalDataForm.PHONE}
                            onSubmit={onSubmit}
                            classNames="m-top-48"
                        />
                    </form>
                </FormProvider>
            </Col>
        </Row>
    )
}

export default Phone
