import './AppSearchResultItem.css'

import { Trans } from 'react-i18next'

import { LinkType } from 'core/enums/LinkType'
import { AppSearchResult, AppSearchSectionId } from 'core/models/AppSearch'

import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'
import AssuraLink from '../AssuraLink/AssuraLink'

interface AppSearchResultItemContent extends Pick<AppSearchResult, 'label' | 'icon'> {
    hasStrongText?: boolean
}

interface AppSearchResultItemProps extends Omit<AppSearchResult, 'suggestionOrder'> {
    section: AppSearchSectionId
    hasStrongText?: boolean
    index: number
    callback: () => void
}

const DefaultAppSearchResultItem = ({
    label,
    icon,
    hasStrongText
}: AppSearchResultItemContent): JSX.Element => {
    return (
        <>
            {icon && <i className={`icon ${icon} size-24`} />}
            <span className="paragraphSmall flex-1 max-2-text-lines">
                {hasStrongText ? (
                    <Trans i18nKey={label} components={{ 1: <strong /> }} parent="p" />
                ) : (
                    label
                )}
            </span>
        </>
    )
}

const AppSearchResultItem = (props: AppSearchResultItemProps): JSX.Element | null => {
    const { ecLinkId, section, callback, link, index, ...contentProps } = props

    const testIdVariable = ecLinkId || `${section}-${index}`

    const commonLinkProps = {
        testId: `app-search-result-${testIdVariable}`,
        classNames: 'app-search-result-item-container',
        callback: callback
    }

    if (ecLinkId) {
        return (
            <AssuraEcLink {...commonLinkProps} ecLinkId={ecLinkId}>
                <DefaultAppSearchResultItem {...contentProps} />
            </AssuraEcLink>
        )
    } else if (section === 'FAQ') {
        return (
            <AssuraLink {...commonLinkProps} link_url={link} type={LinkType.INTERNAL}>
                <DefaultAppSearchResultItem {...contentProps} />
            </AssuraLink>
        )
    } else {
        return null
    }
}

export default AppSearchResultItem
