import './EnveloppesImageViewer.css'

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import { AssuraImage } from 'shared/components/AssuraImage/AssuraImage'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { fetchEnveloppeImageByName } from 'shared/store/enveloppeImages/enveloppeImages.slice'

import EnveloppesImageViewerHeader from './EnveloppesImageViewerHeader'

export interface EnveloppesImageViewerProps {
    images: AssuraDocument[]
    enveloppeSelected?: EnveloppeRow
    closePanel: () => void
}

const EnveloppesImageViewer = ({
    images,
    enveloppeSelected,
    closePanel
}: EnveloppesImageViewerProps): JSX.Element => {
    const dispatch = useDispatch()
    const { isXSD, isSD, isXS } = useWindowSize()

    const getErrorComponent = (doc: AssuraDocument) => {
        return (
            <AssuraDocumentsError
                onClick={() =>
                    dispatch(
                        fetchEnveloppeImageByName({
                            fileName: doc.fileName,
                            type: doc.type,
                            reload: true
                        })
                    )
                }
            />
        )
    }

    const generalLoadingStatus = images.every((image) => image.loadStatus === LoadingStatusEnum.OK)
    const [containerImageHeight, setContainerImageHeight] = useState(
        isXSD || isSD || isXS ? 50 : 90
    )

    return (
        <div className="enveloppes-image-viewer bg-gray100">
            <>
                <EnveloppesImageViewerHeader
                    enveloppeSelected={enveloppeSelected}
                    closePanel={closePanel}
                    onZoomIn={() => {
                        setContainerImageHeight(containerImageHeight + 10)
                    }}
                    onZoomOut={() => {
                        setContainerImageHeight(containerImageHeight - 10)
                    }}
                    generalLoadingStatus={generalLoadingStatus}
                />
                <div className="enveloppes-image-viewer-container">
                    {images.map((doc, index) => (
                        <div
                            key={index}
                            className="enveloppes-image-viewer-content bg-gray100"
                            style={{
                                height: containerImageHeight + 'vh'
                            }}
                        >
                            <AssuraLoadAndError
                                status={doc.loadStatus}
                                ErrorComponent={getErrorComponent(doc)}
                                key={`enveloppes-image-viewer-loader${index}`}
                            >
                                <AssuraImage
                                    id="enveloppe-image"
                                    index={index}
                                    key={index}
                                    src={doc.base64}
                                />
                            </AssuraLoadAndError>
                        </div>
                    ))}
                </div>
            </>
        </div>
    )
}

export default EnveloppesImageViewer
