import './FloatingButton.css'

import { Col, Container, Row } from 'react-bootstrap'

interface FloatingButtonProps {
    onClick: () => void
    testId: string
    direction?: 'up' | 'down'
}

const FloatingButton = ({
    onClick,
    testId,
    direction = 'up'
}: FloatingButtonProps): JSX.Element => {
    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-end align-items-center">
                    <div className="floating-button" onClick={onClick} data-testid={testId}>
                        <i
                            className={`icon assura-${direction} size-24 c-white`}
                            data-testid={`${testId}-icon`}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default FloatingButton
