/* eslint-disable sonarjs/no-duplicate-string */
import Credentials from 'core/constants/translationKeys/credentials'
import { ValidationTypeEnum } from 'core/enums/ValidationTypeEnum'
import { TFunction } from 'i18next'

import { UseValidationOptions, ValidationConfiguration } from './entities'
import { useValidation } from './useValidation'
import Validators from './validators'

const oldPasswordOptions: UseValidationOptions = {
    displayTitle: false,
    displayValidationGuide: true,
    displayFullValidationGuide: false,
    displayValidationErrors: true,
    title: Credentials.password.edit.oldPasswordLabel,
    required: true,
    min: 8,
    max: 32
}

const newPasswordOptions: UseValidationOptions = {
    displayTitle: true,
    displayValidationGuide: true,
    displayFullValidationGuide: true,
    displayValidationErrors: true,
    title: 'ACCOUNT_CREATION.GUIDANCE_MESSAGE',
    required: true,
    min: 8,
    max: 32
}

const checkPasswordOptions: UseValidationOptions = {
    displayTitle: false,
    displayValidationGuide: true,
    displayFullValidationGuide: false,
    displayValidationErrors: true,
    title: Credentials.password.edit.confirmPasswordLabel,
    required: true,
    min: 8,
    max: 32
}

export const passwordBaseConfiguration = (
    options: UseValidationOptions,
    t: TFunction
): ValidationConfiguration[] => [
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.Required,
        hint: t('COMMON.MANDATORY_FIELD'),
        error: t('COMMON.MANDATORY_FIELD'),
        method: Validators.isMandatory
    }
]

export const confirmationPasswordConfiguration = (
    options: UseValidationOptions,
    t: TFunction
): ValidationConfiguration[] => [
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.Required,
        hint: t('COMMON.MANDATORY_FIELD'),
        error: t('COMMON.MANDATORY_FIELD'),
        method: Validators.isMandatory
    },
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.MustBeEquals,
        hint: t(Credentials.password.errors.compareEqualityNotMatch),
        error: t(Credentials.password.errors.compareEqualityNotMatch),
        method: Validators.MustBeEquals
    }
]

export const passwordConfiguration = (
    options: UseValidationOptions,
    t: TFunction
): ValidationConfiguration[] => [
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.Length,
        hint: t(Credentials.password.validations.lengthRange),
        error: t(Credentials.password.edit.newPasswordHint),
        method: Validators.isLengthOverflow(options)
    },
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.RequireMinOneLetter,
        hint: t(Credentials.password.validations.mustHaveAtLeastOneLetter),
        error: t(Credentials.password.edit.newPasswordHint),
        method: Validators.hasMinOneLetter
    },
    {
        displayTitle: options.displayTitle,
        name: ValidationTypeEnum.RequireMinOneNumber,
        hint: t(Credentials.password.validations.mustHaveAtLeastOneNumber),
        error: t(Credentials.password.edit.newPasswordHint),
        method: Validators.hasMinOneNumber
    }
]

export const useOldPasswordValidation = useValidation(oldPasswordOptions)(passwordBaseConfiguration)
export const useNewPasswordValidation = useValidation(newPasswordOptions)(passwordConfiguration)
export const usePasswordCompareValidation = useValidation(checkPasswordOptions)(
    confirmationPasswordConfiguration
)
