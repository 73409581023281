import { AssetUrlCms } from 'core/models/cms/AssetCms'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getAssets } from './getAssets'

const SYMPTOM_CHECKER_LANDING_ICON_CODE = 'SC_Landing_Page_QUALIMED_Image'

export const getSymptomCheckerLandingIcon = createSelector([getLang, getAssets], (lang, assets) => {
    const scAsset = assets[SYMPTOM_CHECKER_LANDING_ICON_CODE]
    return scAsset ? scAsset[lang as keyof AssetUrlCms] : null
})
