import './YearSelector.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import PickerFiveYears from 'modules/services/components/DatePicker/PickerFiveYears/PickerFiveYears'

interface YearSelectorProps {
    label: string
    name: string
    testId: string
    oldestYear?: number
}

const YearSelector = ({ label, name, testId, oldestYear }: YearSelectorProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="year-selector-container bg-white bc-gray100 m-top-32 p-32">
            <Row>
                <Col xl={4} md={12} className="d-flex year-selector-label-container">
                    <div className="paragraphMedium">{label}</div>
                </Col>
                <Col xl={8} md={12} className="d-flex flex-column">
                    <PickerFiveYears
                        placeholder={t('SERVICES.SELECT_A_YEAR')}
                        testId={testId}
                        name={name}
                        rules={{
                            required: {
                                value: true,
                                message: 'COMMON.MANDATORY_FIELD'
                            }
                        }}
                        oldestyear={oldestYear}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default YearSelector
