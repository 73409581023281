import './AssuraMainBanner.css'

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { MainBannerItem } from 'core/models/Banners'
import { getBanners, removeBanner, resetBanners } from 'shared/store/banners/banners.slice'

interface AssuraMainBannerProps {
    top: number
}

const AssuraMainBanner = ({ top }: AssuraMainBannerProps): JSX.Element => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation()

    const initRef = useRef<boolean>(true)

    const banners: MainBannerItem[] = useSelector(getBanners)

    const handleOnClose = (id: string): void => {
        dispatch(removeBanner(id))
    }

    useEffect(() => {
        if (!initRef.current && banners.length > 0) dispatch(resetBanners())
        if (initRef.current) initRef.current = false
    }, [location])

    return (
        <div
            id="assura-main-banner-container"
            className="assura-banner-container"
            style={{ top }}
            data-testid="assura-main-banner-container"
        >
            <div className="container">
                {banners.map((banner) => (
                    <div
                        className={`inner bg-${banner.props.backgroundColor}`}
                        key={`banner-${banner.id}`}
                    >
                        <i
                            className={`icon size-24 assura-${banner.props.iconTitle} c-${banner.props.iconColor}`}
                        />
                        <div
                            data-testid={`${banner.dataTestId}-assura-banner-message`}
                            className="assura-banner-message-container labelExtraSmall break-word"
                        >
                            {typeof banner.message === 'object'
                                ? t(banner.message.key, { ...banner.message.variableObject })
                                : t(banner.message)}
                        </div>
                        {!banner.permanent && (
                            <div
                                data-testid={`${banner.dataTestId}-assura-banner-close-button`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleOnClose(banner.id)}
                            >
                                <i className="icon size-16 assura-close c-black" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AssuraMainBanner
