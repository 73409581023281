import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedFamilyMember } from '../combineSelectors'
import { getProductsYearByPage } from './getProductsYearByPage'

export const getProductPeriodicity = createSelector(
    [getSelectedFamilyMember, getProductsYearByPage],
    (selectedFamilyMember, selectedYear) => {
        const productsFilteredByDate = selectedFamilyMember?.productsPerson?.products.filter(
            (x) => new Date(x.startDate) <= getCheckDate(selectedYear)
        )

        if (productsFilteredByDate && productsFilteredByDate.length > 0) {
            return productsFilteredByDate[0].periodicity
        }

        return undefined
    }
)
