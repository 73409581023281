import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { InvoicesToCheckIsEquals } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import { mapInvoicesToCheckToRows } from 'core/helpers/invoicesToCheck/InvoicesToCheckToRows'
import { InvoiceChecking } from 'core/models/invoicesToCheck/InvoicesToCheckModels'

import { createSelector } from '@reduxjs/toolkit'

import { getInvoicesToCheck } from '../invoicesToCheck/invoicesToCheck.slice'
import { getPersonDataForDocuments } from './getPersonDataForDocuments'

export const getSortedInvoicesToCheckForTable = createSelector(
    [getInvoicesToCheck, getPersonDataForDocuments],
    (getInvoicesToCheck, personData): InvoiceChecking[] => {
        const invoicesToCheckRows = mapInvoicesToCheckToRows(getInvoicesToCheck, personData)

        return invoicesToCheckRows
            .filter((o) => {
                return InvoicesToCheckIsEquals(
                    [
                        InvoiceCheckingStatusEnum.TO_CHECK,
                        InvoiceCheckingStatusEnum.CHECKED,
                        InvoiceCheckingStatusEnum.REFUSED,
                        InvoiceCheckingStatusEnum.EXPIRED
                    ],
                    o.status
                )
            })
            .sort((a, b) => {
                return b.status.localeCompare(a.status)
            })
    }
)
