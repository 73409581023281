import './Delegation.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { DelegationForm } from 'core/models/services/certificates/Delegation'
import { getCurrentYear } from 'core/utils/dateUtils'
import { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import FormPage from 'modules/services/components/FormPage/FormPage'
import NotAllowed from 'modules/services/components/NotAllowed/NotAllowed'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getSelectedMemberSummary } from 'shared/store/selectors/getSelectedMemberSummary'
import { getDoctorLamalOfSelectedFamilyMember } from 'shared/store/selectors/services/getDoctorLamalOfSelectedFamilyMember'
import { getHasActiveLamalOfSelectedFamilyMember } from 'shared/store/selectors/services/getHasActiveContractOfSelectedFamilyMember'
import { onSubmitDelegationCertificate } from 'shared/store/services/certificates/certificates.slice'
import {
    fetchSummaryAndProducts,
    getFamilyGroupResult,
    goToServicesForm
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import NoActiveContract from '../../components/NoActiveContract/NoActiveContract'
import ContractDoctorItem from '../../contract/components/ContractDoctorItem/ContractDoctorItem'
import NoLamalWithDoctor from '../components/NoLamalWithDoctor/NoLamalWithDoctor'

const Delegation = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const hasActiveContract = useSelector(getHasActiveLamalOfSelectedFamilyMember)
    const hasLamalWithDoctor = useSelector(getDoctorLamalOfSelectedFamilyMember)
    const summary = useSelector(getSelectedMemberSummary)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const doctor = summary?.productsPerson?.doctor
    const familyGroupResult = useSelector(getFamilyGroupResult)

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        if (familyGroupResult.selectedPolicyNumber) {
            dispatch(
                fetchSummaryAndProducts({
                    policyNumber: familyGroupResult.selectedPolicyNumber,
                    year: getCurrentYear()
                })
            )
        }
    }

    const getNotAllowed = () => {
        if (!hasActiveContract) {
            return <NoActiveContract />
        }
        if (!hasLamalWithDoctor) {
            return <NoLamalWithDoctor />
        }

        if (doctor === null) {
            return (
                <NotAllowed
                    title={t('SERVICES.DELEGATION_NO_DOCTOR_MESSAGE_1')}
                    message={t('SERVICES.DELEGATION_NO_DOCTOR_MESSAGE_2')}
                    link={
                        <div
                            className="assura-link paragraphSmallLight m-top-24"
                            onClick={() =>
                                dispatch(
                                    goToServicesForm({ type: ServiceType.FAMILY_DOCTOR_CHANGE })
                                )
                            }
                        >
                            {t('SERVICES.DELEGATION_NO_DOCTOR_LINK')}
                        </div>
                    }
                    dataTestId="services-delegation-no-doctor-available"
                />
            )
        }

        return undefined
    }

    const onSuccess = () => {
        const delegationForm: DelegationForm = familyGroupResult
        dispatch(onSubmitDelegationCertificate(delegationForm))
    }

    return (
        <FormPage
            _defaultValues={{}}
            serviceType={ServiceType.CERTIFICATE_DELEGATION}
            formTitle={{
                title: t('SERVICES.DELEGATION_TITLE'),
                category: t('SERVICE.ATTESTATIONS_AND_DOCUMENTS')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.CERTIFICATE_DELEGATION} />}
            notAllowed={getNotAllowed()}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    summaryLoadingStatus,
                    productsLoaderStatus
                ]),
                defaultReloadAction: () =>
                    fetchSummaryAndProducts({
                        policyNumber: familyGroupResult.selectedPolicyNumber as number,
                        year: getCurrentYear(),
                        reload: true
                    })
            }}
            submitButtonText="CERTIFICATE.DELEGATION_DL_LABEL"
        >
            <>
                {doctor && (
                    <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
                        <ContractDoctorItem doctor={doctor} />
                    </div>
                )}
                <div className="paragraphLight m-top-32 p-bottom-24">
                    {t('SERVICES.DELEGATION_INFO')}
                </div>
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    Delegation,
    'DelegationCertificate',
    'delegation-certificate-container'
)
