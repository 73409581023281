import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ServiceType } from 'core/enums/ServiceType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { GeneralQuestions } from 'core/models/cms/GeneralQuestionTopicCms'
import { GeneralQuestionRHFForm } from 'core/models/services/contact/GeneralQuestionForm'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getGeneralQuestions } from 'shared/store/selectors/getGeneralQuestions'
import { setSelectedServiceForm } from 'shared/store/services/services.slice'

import GeneralQuestionFAQ from '../GeneralQuestionFAQ/GeneralQuestionFAQ'
import GeneralQuestionFormLinks from '../GeneralQuestionFormLinks/GeneralQuestionFormLinks'
import GeneralQuestionMainBlock from '../GeneralQuestionMainBlock/GeneralQuestionMainBlock'

interface GeneralQuestionContentProps {
    showSubmitButton: (show: boolean) => void
}

const GeneralQuestionContent = ({ showSubmitButton }: GeneralQuestionContentProps): JSX.Element => {
    const { t } = useTranslation()
    const { watch } = useFormContext<GeneralQuestionRHFForm>()
    const questions = useSelector(getGeneralQuestions) as GeneralQuestions[]
    const dispatch = useDispatch()
    const [currentTopic, setCurrentTopic] = useState<GeneralQuestions>({} as GeneralQuestions)
    const [showForm, setShowForm] = useState(false)
    const selectedTopicId = (watch('topic') as string) ?? ''
    // None relevant answer found section form
    const hideNRAFSectionForm = !selectedTopicId || selectedTopicId === ''

    const handleShowForm = () => {
        setShowForm(true)
        showSubmitButton(true)

        if (!selectedTopicId) return

        dispatch(
            setSelectedServiceForm({
                topic_category: currentTopic?.id,
                visit_origin: ServiceType.GENERAL_QUESTION,
                target_form: ''
            })
        )

        // send metric on topic selection
        setTimeout(
            () =>
                sendEvent(analyticsConstants.EVENTS.NO_RELEVANT_ANSWER_FOUND, {
                    [analyticsConstants.PARAMS.TOPIC_CATEGORY]: currentTopic?.id
                }),
            100
        )
    }

    useEffect(() => {
        setCurrentTopic(questions.find((o) => o.id === selectedTopicId) ?? ({} as GeneralQuestions))
        setShowForm(false)
        showSubmitButton(false)
    }, [selectedTopicId])

    useEffect(() => {
        if (!currentTopic?.id) return
        // we do not want to fire event
        setTimeout(
            () =>
                sendEvent(analyticsConstants.EVENTS.SELECT_TOPIC, {
                    [analyticsConstants.PARAMS.TOPIC_CATEGORY]: currentTopic?.id
                }),
            100
        )
    }, [currentTopic])

    return (
        <div className="gc-container">
            {currentTopic?.forms_collection?.length > 0 ? (
                <GeneralQuestionFormLinks generalQuestions={currentTopic} />
            ) : (
                <></>
            )}
            {currentTopic?.faq_collection?.length > 0 ? (
                <GeneralQuestionFAQ
                    generalQuestions={currentTopic}
                    faqItems={currentTopic.faq_collection}
                />
            ) : (
                <></>
            )}
            {!hideNRAFSectionForm && !showForm ? (
                <div
                    className="primary link-label labelSmall"
                    data-testid="see-document-sample-button-label"
                >
                    <div className="d-flex m-top-32 justify-content-end">
                        <AssuraIconButton
                            id="general-question"
                            icon="assura-next"
                            variant="primary"
                            size="medium"
                            onClick={handleShowForm}
                            label={t('CONTACT.GENERAL_QUESTION_NO_SATISFACTORY_ANSWERS_FOUND')}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div style={{ display: hideNRAFSectionForm || !showForm ? 'none' : 'block' }}>
                <GeneralQuestionMainBlock />
            </div>
        </div>
    )
}

export default GeneralQuestionContent
