import './PaymentFrequencySelectItem.css'

import { useFormContext } from 'react-hook-form'

import { ProductPeriodicity } from 'core/enums/Product'
import { PaymentFrequencyRHF } from 'core/models/services/financial/PaymentFrequency'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'

import FrequencySelectItemLabel from './FrequencySelectItemLabel'
import FrequencySelectItemRebate from './FrequencySelectItemRebate'

interface PaymentFrequencySelectItemProps {
    periodicity: ProductPeriodicity
    rebate: number
    isSelected: boolean
    isCurrent: boolean
}

const PaymentFrequencySelectItem = ({
    periodicity,
    rebate,
    isSelected,
    isCurrent
}: PaymentFrequencySelectItemProps): JSX.Element => {
    const { register } = useFormContext<PaymentFrequencyRHF>()

    return (
        <div className="d-flex justify-content-between align-items-center border-top-solid-width-1 btc-gray100">
            <div
                className={`services-payment-frequency-item-select-active-indicator${
                    isSelected ? ' bg-primary' : ''
                }`}
                data-testid={`services-payment-frequency-select-${periodicity}-active-indicator`}
            />
            <RadioRHF
                id={periodicity}
                name="periodicity"
                register={register}
                classNames="d-flex flex-1 m-left-24"
                label={
                    <FrequencySelectItemLabel
                        periodicity={periodicity}
                        isCurrent={isCurrent}
                        isSelected={isSelected}
                    />
                }
            />
            <FrequencySelectItemRebate rebate={rebate} />
        </div>
    )
}

export default PaymentFrequencySelectItem
