import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getHospStartDate } from '../services/report/report.slice'

export const getSummaryHospStartDate = createSelector(
    [getFamilySummaries, getHospStartDate],
    (summaries, hospStartDate) => summaries[hospStartDate?.getFullYear() ?? getCurrentYear()]
)
