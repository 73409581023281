/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export interface LabelProps {
    id: string
    label: string
    inline?: boolean
    labelSize?: number
    labelClassName?: string
}

export const withLabel = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithLabel extends React.Component<P & LabelProps> {
        render() {
            const { label, id, inline, labelSize } = this.props
            const LABEL_SIZE = labelSize ?? 6

            return inline ? (
                <Form.Group as={Row}>
                    <Form.Label
                        column
                        md={LABEL_SIZE}
                        className={`assura-input-label-inline ${
                            this.props.labelClassName ? this.props.labelClassName : 'labelMedium'
                        }`}
                        htmlFor={id}
                        data-testid={`${id}-label`}
                    >
                        {label}
                    </Form.Label>
                    <Col md={12 - LABEL_SIZE}>
                        <WrappedComponent {...(this.props as P)}></WrappedComponent>
                    </Col>
                </Form.Group>
            ) : (
                <Form.Group>
                    <Form.Label
                        className={`${
                            this.props.labelClassName ? this.props.labelClassName : 'labelMedium'
                        }`}
                        htmlFor={id}
                    >
                        {label}
                    </Form.Label>
                    <WrappedComponent {...(this.props as P)}></WrappedComponent>
                </Form.Group>
            )
        }
    }
