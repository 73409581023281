import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    AventuraAdditionalFamilyMemberModel,
    AventuraForm,
    AventuraPriceDetailsModel,
    AventuraState
} from 'core/models/services/coverage/AventuraForm'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

const INITIAL_STATE_AVENTURA: AventuraState = {
    hasPendingRequests: false,
    loadingStatus: LoadingStatusEnum.OK,
    submitStatus: LoadingStatusEnum.OK,
    errors: [],
    prices: [],
    formData: {
        policyNumber: 0,
        source: '',
        backOfficeUser: '',
        email: '',
        phoneNumber: '',
        travelReason: '',
        travelDestination: '',
        travelStart: '',
        travelEnd: '',
        paperFilled: true,
        aventuraCoverType: 'Single',
        additionalFamilyMembers: []
    }
}

export const aventuraSlice = createSlice({
    name: 'aventura',
    initialState: INITIAL_STATE_AVENTURA,
    reducers: {
        submitData: (state, _action: PayloadAction<AventuraForm>) => {
            return state as AventuraState
        },
        setSubmitStatus: (state, _action: PayloadAction<LoadingStatusEnum>) => {
            return { ...state, submitStatus: _action.payload } as AventuraState
        },
        setFormData: (state, _action: PayloadAction<AventuraForm>) => {
            return { ...state, formData: { ..._action.payload } } as AventuraState
        },
        setPriceData: (state, _action: PayloadAction<AventuraPriceDetailsModel[]>) => {
            return { ...state, prices: _action.payload } as AventuraState
        },
        addFamilyMember: (state, _action: PayloadAction<AventuraAdditionalFamilyMemberModel>) => {
            return {
                ...state,
                formData: {
                    ...state.formData,
                    additionalFamilyMembers: [
                        ...state.formData.additionalFamilyMembers.filter(
                            (o) => o.id !== _action.payload.id
                        ),
                        _action.payload
                    ].sort((a, b) => {
                        return a.id - b.id
                    })
                }
            } as AventuraState
        },
        setFamilyMembers: (
            state,
            _action: PayloadAction<AventuraAdditionalFamilyMemberModel[]>
        ) => {
            return {
                ...state,
                formData: {
                    ...state.formData,
                    additionalFamilyMembers: [..._action.payload].sort((a, b) => {
                        return a.id - b.id
                    })
                }
            } as AventuraState
        }
    }
})

export const {
    setFormData, // used in Aventura Form / Aventura root page
    setSubmitStatus, // used in Aventura SAGA
    submitData, // used in Aventura Form
    addFamilyMember, // used in Aventura Additional Member
    setFamilyMembers, // used in Aventura Form
    setPriceData // used in Aventura SAGA
} = aventuraSlice.actions

export const getAventuraStateSelector: Selector<RootState, AventuraState> = (state) =>
    state.aventura as AventuraState

export const getAventuraFormDataSelector: Selector<RootState, AventuraForm> = (state) =>
    state.aventura.formData

export const getAventuraFamilyMembersSelector: Selector<
    RootState,
    AventuraAdditionalFamilyMemberModel[]
> = (state) => state.aventura.formData.additionalFamilyMembers

export default aventuraSlice.reducer
