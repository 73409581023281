import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'

import { createSelector } from '@reduxjs/toolkit'

import { getEnveloppesForDataTable } from './getEnveloppesForDataTable'

export interface EnveloppesCountByStatus {
    processing: number
    error: number
}

export const getEnveloppesCountByStatus = createSelector(
    [getEnveloppesForDataTable],
    (enveloppeRows) => {
        let statusCount: EnveloppesCountByStatus = {
            processing: 0,
            error: 0
        }

        statusCount = enveloppeRows.reduce<typeof statusCount>((acc, env) => {
            const { status } = env
            if (
                status === EnveloppeStatus.VisibilityError ||
                status === EnveloppeStatus.TechnicalError
            ) {
                acc.error++
            } else if (
                status === EnveloppeStatus.Received ||
                status === EnveloppeStatus.Validated ||
                status === EnveloppeStatus.PartiallyProcessed
            ) {
                acc.processing++
            }
            return acc
        }, statusCount)

        return statusCount
    }
)
