import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    AccidentReportModel,
    AccidentReportState
} from 'core/models/services/report/AccidentReportModel'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

const INITIAL_ACCIDENT_REPORT_STATE: AccidentReportState = {
    submitStatus: LoadingStatusEnum.OK,
    errors: []
}
///postAccidentRequestAsyncApi
export const accidentReportSlice = createSlice({
    name: 'accidentReport',
    initialState: INITIAL_ACCIDENT_REPORT_STATE,
    reducers: {
        submitData: (state, _action: PayloadAction<AccidentReportModel>) => {
            return state as AccidentReportState
        },
        setSubmitStatus: (state, _action: PayloadAction<LoadingStatusEnum>) => {
            return { ...state, submitStatus: _action.payload } as AccidentReportState
        }
    }
})

export const {
    setSubmitStatus, // used in Accident Report SAGA
    submitData // used in Accident Report Form
} = accidentReportSlice.actions

export const getAccidentReportStateSelector: Selector<RootState, AccidentReportState> = (state) =>
    state.accidentReport as AccidentReportState

export const getAccidentSubmitStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.accidentReport.submitStatus

export default accidentReportSlice.reducer
