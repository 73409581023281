import { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import analyticsConstants from 'core/constants/analyticsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { GeneralQuestions } from 'core/models/cms/GeneralQuestionTopicCms'
import { Faq } from 'core/models/Faq'
import { GetAssetIcon } from 'core/utils/assetUtils'
import FaqQuestionAnswerAccordion from 'modules/faq/components/FaqQuestionsList/FaqQuestionListAccordion'

interface GeneralQuestionFAQ {
    generalQuestions: GeneralQuestions
    faqItems: Faq[]
}

const GeneralQuestionFAQ = ({ generalQuestions, faqItems }: GeneralQuestionFAQ): JSX.Element => {
    const { t } = useTranslation()
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null)

    const handleSelectedQuestion = (e: SyntheticEvent<Element, Event>, id: string) => {
        setSelectedQuestionId((previousId) => (previousId !== id ? id : null))

        // How many times a faq question is clicked from general question
        const faqName = generalQuestions.faq_collection.find((o) => o.id === id)

        if (!faqName) return
        setTimeout(
            () =>
                sendEvent(analyticsConstants.EVENTS.SELECT_FAQ, {
                    [analyticsConstants.PARAMS.FAQ_QUESTION]: faqName?.id,
                    [analyticsConstants.PARAMS.TOPIC_CATEGORY]: generalQuestions?.id
                }),
            100
        )
    }

    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 m-bottom-8">
            <div className="d-flex justify-content-start align-items-center m-top-32 m-left-32 m-right-32 m-bottom-24">
                <img
                    src={GetAssetIcon(generalQuestions.faq_icon)}
                    alt="gc-faq-section-icon"
                    width={56}
                    height={56}
                />
                <span className="m-left-16 headlineSmallMedium" data-testid="gc-faq-section-title">
                    {t('FAQ.TITLE')}
                </span>
            </div>

            <div className="d-flex flex-1 flex-column align-items-stretch m-right-32 m-left-32 m-bottom-32">
                <FaqQuestionAnswerAccordion
                    selectedQuestionId={selectedQuestionId}
                    currentQuestions={faqItems}
                    handleSelection={handleSelectedQuestion}
                    conditionalFormatting="generalQuestionForm"
                />
            </div>
        </div>
    )
}

export default GeneralQuestionFAQ
