import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FaqGlobalItem } from 'core/models/Faq'
import useWindowSize from 'core/services/useWindowSize'
import { useFaqContext } from 'modules/faq/pages/Faq'
import SearchBar from 'shared/components/SearchBar/SearchBar'
import { getFaqLoadingStatus } from 'shared/store/faq/faq.slice'
import { getFaqDataForSearch } from 'shared/store/selectors/getFaqData'

const FaqSearch = (): JSX.Element => {
    const { isMobile } = useWindowSize()
    const { t } = useTranslation()
    const faqSearchData = useSelector(getFaqDataForSearch)
    const faqLoadingStatus = useSelector(getFaqLoadingStatus)

    const { setExternalSelection } = useFaqContext()

    const handleSelection = (value: FaqGlobalItem) => {
        setExternalSelection(value)
    }

    return (
        <Row
            className={`faq-search ${isMobile ? 'm-bottom-16' : ''}`}
            style={{
                visibility: faqLoadingStatus === LoadingStatusEnum.OK ? 'visible' : 'hidden'
            }}
        >
            <Col xs={12} xl={8}>
                <SearchBar
                    dataTestId="faq-search"
                    charMinToStartSearch={3}
                    items={faqSearchData}
                    onSelection={handleSelection}
                    placeHolder={t('FAQ.SEARCH_PLACEHOLDER')}
                />
            </Col>
        </Row>
    )
}

export default FaqSearch
