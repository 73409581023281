/* eslint-disable sonarjs/cognitive-complexity */

import {
    InvoiceCheckingStatusEnum,
    InvoicesToCheckTranslatedStatus
} from 'core/enums/InvoicesToCheck'
import { InvoicesToCheckIsEquals } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import {
    InvoiceChecking,
    InvoicesToCheckFilterSelectionValue
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import { TableFilterState } from 'core/models/TableFilter'

export const getFilteredInvoicesToCheck = (
    invoicesToCheck: InvoiceChecking[],
    filterState: TableFilterState<InvoicesToCheckFilterSelectionValue>
): InvoiceChecking[] => {
    let newInvoicesToCheck = [...invoicesToCheck]

    // Member filter
    const memberSelections = filterState['member']?.selections

    if (memberSelections) {
        for (const selection of memberSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value.toString()
                newInvoicesToCheck = newInvoicesToCheck.filter(
                    (row) => row.partnerNumber.toString() === value
                )
            }
        }
    }

    // Status filter
    const statusSelections = filterState['status']?.selections

    if (statusSelections) {
        for (const selection of statusSelections.values()) {
            if (selection.isSelected) {
                let currentFilterStatus = ''
                if ((selection.value as InvoicesToCheckTranslatedStatus).includes('.TO_CHECK')) {
                    currentFilterStatus = InvoiceCheckingStatusEnum.TO_CHECK
                } else if (
                    (selection.value as InvoicesToCheckTranslatedStatus).includes('.CHECKED')
                ) {
                    currentFilterStatus = InvoiceCheckingStatusEnum.CHECKED
                }
                newInvoicesToCheck = newInvoicesToCheck.filter((row) => {
                    let currentStatus = row.status
                    if (
                        InvoicesToCheckIsEquals(
                            [
                                InvoiceCheckingStatusEnum.EXPIRED,
                                InvoiceCheckingStatusEnum.CHECKED,
                                InvoiceCheckingStatusEnum.REFUSED
                            ],
                            currentStatus
                        )
                    ) {
                        currentStatus = InvoiceCheckingStatusEnum.CHECKED
                    }
                    return currentFilterStatus === currentStatus
                })
            }
        }
    }

    return newInvoicesToCheck
}

export const getFilteredInvoicesToCheckInfo = (
    filterState: TableFilterState<InvoicesToCheckFilterSelectionValue>
) => {
    const hasElementsSelected = () => {
        const members = filterState['members']?.selections
        const status = filterState['status']?.selections
        return members.size + status.size
    }
    return {
        hasSelections: hasElementsSelected()
    }
}
