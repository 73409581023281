import './AssuraSelect.css'

import { Dropdown } from 'react-bootstrap'
import { FieldValues, UseFormRegister } from 'react-hook-form'

export type SelectMap<T> = Map<string, T>

export interface AssuraSelectProps<T> {
    id: string
    name?: string
    value?: T
    items: SelectMap<T>
    onChange: (value: T | null) => void
    disabled?: boolean
    containerClassnames?: string
    toggleClassnames?: string
    menuClassnames?: string
    alignRight?: boolean
    shouldDisplayValueInToggle?: boolean
    shouldDisplaySelectionInMenu?: boolean
    shouldUseValueForMenuItemTestId?: boolean
    placeHolder?: string
    register?: UseFormRegister<FieldValues>
}

const AssuraSelect = <T,>({
    id,
    name = '',
    value,
    items,
    onChange,
    disabled,
    containerClassnames,
    toggleClassnames,
    menuClassnames,
    alignRight,
    shouldDisplayValueInToggle,
    shouldDisplaySelectionInMenu,
    shouldUseValueForMenuItemTestId,
    placeHolder = '',
    register
}: AssuraSelectProps<T>): JSX.Element => {
    const containerClass = 'd-flex' + (containerClassnames ? ' ' + containerClassnames : '')
    const toggleClass = toggleClassnames ?? ''
    const menuClass = 'assura-select-menu' + (menuClassnames ? ' ' + menuClassnames : '')
    const handleSelection = (selection: string | null): void => {
        const value = items.get(selection ?? '')
        onChange(value !== undefined ? value : null)
    }

    const getLabel = (): string => {
        let label = placeHolder
        if (value !== null && value !== undefined) {
            if (shouldDisplayValueInToggle) {
                label = `${value}`
            } else {
                for (const [key, v] of items) {
                    if (v === value) label = key
                }
            }
        }

        return label
    }

    const labelColor = disabled || placeHolder === getLabel() ? ' c-gray500' : ''

    const registerDropDown = name !== '' && register ? { ...register?.(name) } : {}

    return (
        <Dropdown
            alignRight={alignRight}
            className={containerClass}
            onSelect={(val) => handleSelection(val)}
            id={`${id}-select`}
            {...registerDropDown}
        >
            <Dropdown.Toggle
                id="assura-select-toggle"
                variant="primary"
                className={toggleClass}
                disabled={disabled}
            >
                <div
                    className={`d-flex justify-content-between align-items-center${labelColor}`}
                    style={{ paddingRight: '8px' }}
                    data-testid={`${id}-select-toggle`}
                >
                    <span className="text-ellipsis">{getLabel()}</span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={menuClass}>
                {Array.from(items.entries())
                    .filter(([_label, v]) => shouldDisplaySelectionInMenu || v !== value)
                    .map(([label, v], index) => (
                        <Dropdown.Item
                            data-testid={`${id}-select-menu-item-${
                                shouldUseValueForMenuItemTestId ? v : index
                            }`}
                            key={`${id}-select-menu-item-${index}`}
                            eventKey={label}
                            className={
                                v === value ? 'bg-error-background labelSmallMedium' : 'labelSmall'
                            }
                        >
                            {label}
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default AssuraSelect
