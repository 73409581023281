import axios from 'axios'
import {
    SymptomCheckerRequestInterStepBody,
    SymptomCheckerTokenApiResponse
} from 'core/models/SymptomChecker'

export const fetchSymptomCheckerTokenApi = async (): Promise<SymptomCheckerTokenApiResponse> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v1/SymptomChecker/getToken`
    )

    return result.data
}

export const postSymptomCheckerInterStepApi = async (
    interStep: SymptomCheckerRequestInterStepBody
): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v1/SymptomChecker/insertStep`, {
        ...interStep
    })
