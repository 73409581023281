import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { FetchDocumentsTypeEnum } from 'core/enums/Documents'
import {
    fetchInvoicesData,
    getInvoicesData
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'
import { getSortedInvoicesForTable } from 'shared/store/selectors/getSortedInvoicesForTable'

import HomeBlock from '../HomeBlock/HomeBlock'
import NoLastDocuments from '../NoLastDocuments/NoLastDocuments'
import LastInvoicesRow from './LastInvoicesRow'

const LastInvoices = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const invoicesData = useSelector(getInvoicesData)
    const { initLoadingStatus } = invoicesData

    const sortedInvoices = useSelector(getSortedInvoicesForTable)
    const hasInvoices = sortedInvoices.length > 0

    const selectedMember = useSelector(getSelectedFamilyMember)

    let url = `${navigationConstants.FINANCIALS.url}`
    if (selectedMember?.userTiersNumber) {
        url = `${url}?utn=${selectedMember.userTiersNumber}`
    }

    const fetchInvoices = () => {
        dispatch(
            fetchInvoicesData({
                type: FetchDocumentsTypeEnum.INIT,
                paginationDate: null,
                docsByPage: 3,
                filters: {
                    utn: selectedMember?.userTiersNumber ?? null,
                    date: null
                }
            })
        )
    }

    return (
        <HomeBlock
            title={t('HOME.INVOICES_LAST_TITLE')}
            testId="invoices"
            loadingStatus={initLoadingStatus}
            reloadAction={fetchInvoices}
            headerAction={hasInvoices ? () => navigate(url) : undefined}
        >
            {hasInvoices ? (
                <>
                    {sortedInvoices.slice(0, 3).map((row) => (
                        <LastInvoicesRow row={row} url={url} key={row.documentId} />
                    ))}
                </>
            ) : (
                <NoLastDocuments testId="invoices" message={t('HOME.INVOICES_NO_LAST_MESSAGE')} />
            )}
        </HomeBlock>
    )
}

export default LastInvoices
