import './InsurancesTitle.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import useWindowSize from 'core/services/useWindowSize'
import { getCurrentYear } from 'core/utils/dateUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraSelect, { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { fetchProductsYear } from 'shared/store/products/products.slice'
import { RootState } from 'shared/store/store'

interface InsurancesTitleProps {
    shouldDisplayContract: boolean | ProductPerson
}

const InsurancesTitle = ({ shouldDisplayContract }: InsurancesTitleProps): JSX.Element => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { isMobile } = useWindowSize()

    const selectedYear = useSelector((state: RootState) => state.products.year)

    const currentYear = getCurrentYear()
    const yearsMap = new Map() as SelectMap<number>
    ;[currentYear - 1, currentYear, currentYear + 1].forEach((y) => {
        yearsMap.set(y.toString(), y)
    })

    const switchSelectedYear = (year: number | null) => {
        if (year) dispatch(fetchProductsYear({ year }))
    }

    return (
        <Row className="insurances-title-block">
            <Col xs={12} md={8} xl={9} className="insurances-title">
                <span className="title break-word" data-testid="insurances-title">
                    {t('FAMILY_GROUP.COVERAGE_TITLE')}
                </span>
                <AssuraSelect
                    id="insurance-coverage-years"
                    value={selectedYear}
                    items={yearsMap}
                    onChange={switchSelectedYear}
                    shouldDisplaySelectionInMenu
                    containerClassnames="insurances-years-select"
                    toggleClassnames="insurances-years-select-toggle"
                    shouldUseValueForMenuItemTestId
                />
            </Col>
            {shouldDisplayContract && (
                <Col xs={12} md={4} xl={3} className="insurances-request-button-container">
                    <AssuraButton
                        id="insurances-link-to-services"
                        onClick={() => navigate(`${navigationConstants.SERVICES.url}?card=02`)}
                        text={t('INSURANCES.TITLE_CTA')}
                        variant="primary"
                        fullWidth={isMobile}
                        icon={{
                            name: 'assura-edit',
                            size: 24
                        }}
                    />
                </Col>
            )}
        </Row>
    )
}
export default InsurancesTitle
