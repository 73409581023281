import DocumentCategory from 'core/enums/DocumentCategory'
import { DocumentsGroup } from 'core/models/documents/Document'

export const DocumentsGroupToCategories: Record<DocumentsGroup, number[]> = {
    invoices: [
        DocumentCategory.CLAIMS_SETTLEMENT,
        DocumentCategory.REMINDER,
        DocumentCategory.PREMIUM_NOTICE
    ],
    refunds: [
        DocumentCategory.CLAIMS_SETTLEMENT,
        DocumentCategory.REMINDER,
        DocumentCategory.PREMIUM_NOTICE
    ],
    correspondence: [
        DocumentCategory.COMMUNICATION,
        DocumentCategory.PROMOTION,
        DocumentCategory.APPENDIX
    ],
    contractual: [DocumentCategory.CONTRACT]
}
