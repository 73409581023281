import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import {
    GeneralQuestionTopic,
    GeneralQuestionTopicLabel
} from 'core/models/cms/GeneralQuestionTopicCms'
import { GeneralQuestionTopicCmsLocalized } from 'core/models/cms/GeneralQuestionTopicCmsLocalized'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getGeneralQuestionTopic } from '../generalQuestionTopic/generalQuestionTopic.slice'

export const getGeneralQuestionTopics = createSelector(
    [getGeneralQuestionTopic, getLang],
    (generalQuestionTopic, lang): GeneralQuestionTopicLabel[] => {
        return generalQuestionTopic
            .filter((item: GeneralQuestionTopic) => item.is_active)
            .map((gqt) => {
                const localizedGqt: GeneralQuestionTopicCmsLocalized = getLocalizedObject(gqt, lang)
                return {
                    _id: localizedGqt._id,
                    id: localizedGqt.id,
                    topic: localizedGqt.topic,
                    order: Number(localizedGqt.order)
                }
            })
            .sort((a, b) => a.order - b.order)
    }
)
