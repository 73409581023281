import './SettingsCredentialsEditPanel.css'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CredentialEditMode } from 'core/enums/CredentialEditMode'
import useWindowSize from 'core/services/useWindowSize'
import { SettingsOptions } from 'modules/settings/pages/Settings'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { getCredentialsForceReload } from 'shared/store/settings/credentials/credentials.slice'

import SettingsCredentialsEditLoginForm from '../SettingsCredentialsEditForm/SettingsCredentialsEditLoginForm'
import SettingsCredentialsEditPasswordForm from '../SettingsCredentialsEditPasswordForm/SettingsCredentialsEditPasswordForm'
import SettingsCredentialsPanelHeader from './SettingsCredentialsEditPanelHeader'

type SettingsCredentialsPanelProps = {
    openEditPanel: boolean
    options: SettingsOptions
    closePanel: () => void
}

const SettingsCredentialsPanel = ({
    openEditPanel,
    options,
    closePanel
}: SettingsCredentialsPanelProps): JSX.Element => {
    const { isXS, isMD, isXL } = useWindowSize()
    const forceReload = useSelector(getCredentialsForceReload)

    useEffect(() => {
        if (!forceReload) return
        closePanel()
    }, [forceReload])

    const sizeCalculation = (): number => {
        switch (true) {
            case isXS:
                return 100
            case isMD:
                return 50
            case isXL:
                return 34
            default:
                return 100
        }
    }

    return (
        <AssuraPanel
            type={'right'}
            isOpen={openEditPanel}
            size={sizeCalculation()}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <div data-testid="credential-viewer-test-id" className="settings-credential-viewer">
                <SettingsCredentialsPanelHeader mode={options.mode} closePanel={closePanel} />
                {options.mode === CredentialEditMode.Login ? (
                    <SettingsCredentialsEditLoginForm loginData={options.data} />
                ) : (
                    <SettingsCredentialsEditPasswordForm />
                )}
            </div>
        </AssuraPanel>
    )
}

export default SettingsCredentialsPanel
