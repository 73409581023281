import './EnveloppesBackToScanItem.css'

import { useState } from 'react'

import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraPdfThumbnail from 'shared/components/AssuraPdfThumbnail/AssuraPdfThumbnail'

import { useBackToScanContext } from '../EnveloppesBackToScanForm/EnveloppesBackToScanForm'
import EnveloppesBackToScanUploadButton from '../EnveloppesBackToScanUploadButton/EnveloppesBackToScanUploadButton'

interface EnveloppesBackToScanItemProps {
    index: number
    document: DroppedFile
}

const EnveloppesBackToScanItem = ({
    index,
    document
}: EnveloppesBackToScanItemProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const { seeDocument, updatedDocumentsIndex } = useBackToScanContext()
    const wasUpdated = updatedDocumentsIndex.includes(index)

    return (
        <div
            className="enveloppes-back-to-scan-item-container"
            data-testid={`enveloppes-back-to-scan-item-${index}-container`}
        >
            <AssuraPdfThumbnail base64={document.pdfFileBase64} />
            {!document.isValid && (
                <EnveloppesBackToScanUploadButton
                    index={index}
                    testId={`enveloppes-back-to-scan-item-upload-${index}`}
                    setIsLoading={setIsLoading}
                />
            )}
            <div className="enveloppes-back-to-scan-item-cta-container">
                {isLoading ? (
                    <ActivityIndicator size={24} />
                ) : (
                    <div
                        className="enveloppes-back-to-scan-item-cta-see"
                        data-testid={`enveloppes-back-to-scan-item-see-${index}`}
                        onClick={() => seeDocument(document)}
                    >
                        <i className="icon assura-visible size-24"></i>
                    </div>
                )}
            </div>
            {!document.isValid && <div className="enveloppes-back-to-scan-item-invalid" />}
            {wasUpdated && (
                <div
                    className="enveloppes-back-to-scan-item-updated"
                    data-testid={`enveloppes-back-to-scan-item-${index}-updated`}
                >
                    <i className="icon assura-double-check size-16 c-white" />
                </div>
            )}
        </div>
    )
}

export default EnveloppesBackToScanItem
