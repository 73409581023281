import { prepareAccidentVariantsData } from 'core/utils/ProductUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedFamilyMember } from '../combineSelectors'

export const getAccidentVariantsOfSelectedFamilyMember = createSelector([getSelectedFamilyMember], (selectedFamilyMember) => {
    const productVariants = selectedFamilyMember?.productsPerson?.productVariants
    return prepareAccidentVariantsData(productVariants)
})
