import './AssuraTabItem.css'

import React from 'react'

import { AssuraTabData } from 'core/models/TabData'

import { AssuraTabBadge } from '../AssuraTabBadge/AssuraTabBadge'
import { AssuraTabItemContent } from '../AssuraTabItemContent/AssuraTabItemContent'
import { AssuraTabMainContent } from '../AssuraTabMainContent/AssuraTabMainContent'

type DefaultItemContainerTemplateProps = {
    data: AssuraTabData
    dataId: string
    onClick: (id: AssuraTabData['uniqueKey']) => void
    contentTemplate?: (data: AssuraTabData, dataId: string) => React.ReactElement
    children: React.ReactNode
}

const DefaultItemContainerTemplate = ({
    data,
    dataId,
    onClick,
    contentTemplate,
    children
}: DefaultItemContainerTemplateProps) => {
    return (
        <AssuraTabItemContent dataId={dataId} data={data} onClick={() => onClick(data.uniqueKey)}>
            {contentTemplate ? contentTemplate(data, dataId) : children}
        </AssuraTabItemContent>
    )
}

export type AssuraTabItemProps = {
    data: AssuraTabData
    dataId: string
    separator: boolean
    itemTemplate?: (
        data?: AssuraTabData,
        valueTemplate?: (data?: AssuraTabData) => React.ReactElement,
        onClick?: (id: AssuraTabData['uniqueKey']) => void
    ) => React.ReactElement
    valueTemplate?: (data?: AssuraTabData) => React.ReactElement
    onClick: (id: AssuraTabData['uniqueKey']) => void
}

const defaultItemTemplate = (
    data: AssuraTabData,
    dataId: string,
    onClick: (id: AssuraTabData['uniqueKey']) => void,
    valueTemplate?: (data?: AssuraTabData) => React.ReactElement
): React.ReactElement => (
    <DefaultItemContainerTemplate data={data} dataId={dataId} onClick={onClick}>
        {valueTemplate ? (
            valueTemplate(data)
        ) : (
            <>
                <AssuraTabMainContent active={data.active} data={data} />
                <AssuraTabBadge dataId={dataId} data={data.rightItem} id={data.uniqueKey} />
            </>
        )}
    </DefaultItemContainerTemplate>
)

const AssuraTabItem = ({
    data,
    dataId,
    separator,
    itemTemplate,
    onClick,
    valueTemplate
}: AssuraTabItemProps) => {
    return (
        <div className="assura-tab-item-container">
            {separator ? <span className="assura-tab-separator bg-gray300" /> : null}
            {itemTemplate
                ? itemTemplate(data, valueTemplate, onClick)
                : defaultItemTemplate(data, dataId, onClick, valueTemplate)}
        </div>
    )
}

export default AssuraTabItem
