import { MobileStoreEnum } from 'core/enums/MobileStore'
import fallbackImage from 'shared/assets/images/playstore-fr.png'

import MobileStoreBadge from './MobileStoreBadge'

const playStoreEcLink = '65f005cd3a3ed4d6fe0a4af5'

interface PlayStoreBadgeProps {
    testId: string
    width: number
    height: number
    classNames?: string
}
const PlayStoreBadge = ({ testId, width, height, classNames }: PlayStoreBadgeProps) => {
    return (
        <MobileStoreBadge
            store={MobileStoreEnum.PLAY_STORE}
            ecLinkId={playStoreEcLink}
            testId={testId}
            classNames={classNames}
            width={width}
            height={height}
            fallbackImage={fallbackImage}
        />
    )
}

export default PlayStoreBadge
