import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchAttachment } from 'core/api/DocumentFile'
import { ServicesHospAnnouncementDivision } from 'core/enums/ServicesHospAnnouncementDivision'
import { ServicesHospAnnouncementReason } from 'core/enums/ServicesHospAnnouncementReason'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues, RequestDocument } from 'core/models/services/RequestData'
import AssuraDownloadLink from 'shared/components/AssuraDownloadLink/AssuraDownloadLink'
import LabelValueInlineBlock, { LabelValueInlineBlockItem } from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import { useRequestPanelContext } from '../RequestsPanelMain/RequestsPanelMain'
import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface HospitalizationAnnouncementProps {
    values: RequestDetailsValues[]
    attachment?: RequestDocument
}

const HospitalizationAnnouncement = ({
    values,
    attachment
}: HospitalizationAnnouncementProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setloading] = useState(false)
    const setError = useRequestPanelContext()

    const handleDownloadClick = () => {
        if (attachment) fetchAttachment(attachment, setError, setloading)
    }

    const activeFields: LabelValueInlineBlockItem[] = []

    const affectionNature = values.find((v) => v.id === 'AffectionNature')
    if (affectionNature?.new) {
        activeFields.push({
            id: 'affectionNature',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_AFFLICTION_DESCRIPTION'),
            value: affectionNature.new as string
        })
    }

    const reason = values.find((v) => v.id === 'HospitalizationReason')
    if (reason?.new) {
        activeFields.push({
            id: 'reason',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_LABEL'),
            value: (function () {
                switch (reason.new) {
                    case ServicesHospAnnouncementReason.ACCIDENT:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_ACCIDENT')
                    case ServicesHospAnnouncementReason.SICKNESS:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_SICKNESS')
                    case ServicesHospAnnouncementReason.CHILDBIRTH:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REASON_CHILDBIRTH')
                    default:
                        return ''
                }
            })()
        })
    }

    const symptomsDate = values.find((v) => v.id === 'SymptomsDate')
    if (symptomsDate?.new) {
        activeFields.push({
            id: 'symptomsDate',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_SYMPTOM_START_DATE'),
            value: formatDate(symptomsDate.new as string)
        })
    }

    const hospStartDate = values.find((v) => v.id === 'EntryDate')
    if (hospStartDate?.new) {
        activeFields.push({
            id: 'hospStartDate',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_START_DATE'),
            value: formatDate(hospStartDate.new as string)
        })
    }

    const hospitalName = values.find((v) => v.id === 'FacilityName')
    if (hospitalName?.new) {
        activeFields.push({
            id: 'hospitalName',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_HOSPITAL_NAME'),
            value: hospitalName.new as string
        })
    }

    const division = values.find((v) => v.id === 'Division')
    if (division?.new) {
        activeFields.push({
            id: 'division',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_LABEL'),
            value: (function () {
                switch (division.new) {
                    case ServicesHospAnnouncementDivision.GENERAL:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_GENERAL')
                    case ServicesHospAnnouncementDivision.SEMI_PRIVATE:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_SEMI_PRIVATE')
                    case ServicesHospAnnouncementDivision.PRIVATE:
                        return t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DIVISION_PRIVATE')
                    default:
                        return ''
                }
            })()
        })
    }

    const doctor = values.find((v) => v.id === 'DoctorName')
    if (doctor?.new) {
        activeFields.push({
            id: 'doctor',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DOCTOR_NAME'),
            value: doctor.new as string
        })
    }

    const duration = values.find((v) => v.id === 'Duration')
    if (duration?.new) {
        activeFields.push({
            id: 'duration',
            label: t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_DURATION'),
            value: duration.new as string
        })
    }

    const contactActiveFields: LabelValueInlineBlockItem[] = []

    const phoneNumber = values.find((v) => v.id === 'PhoneNumber')
    if (phoneNumber?.new) {
        contactActiveFields.push({
            id: 'phone',
            label: t('PERSONAL_DATA.CONTACT_PHONE_LABEL'),
            value: phoneNumber.new as string
        })
    }

    const email = values.find((v) => v.id === 'EmailAddress')
    if (email?.new)
        contactActiveFields.push({
            id: 'email',
            label: t('PERSONAL_DATA.EMAIL'),
            value: email.new as string
        })

    const remark = values.find((v) => v.id === 'Notes')

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICE.HOSPITALIZATION_INFORMATION')} />
            <div className="requests-panel-main-block m-bottom-24">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={field.label}>
                        <div>{field.label}</div>
                        <div
                            data-testid={`requests-details-hospitalization-announcement-${field.id}`}
                        >
                            {field.value}
                        </div>
                    </LabelValueInlineBlock>
                ))}
                {remark?.new && (
                    <>
                        <div className="label c-gray500 m-bottom-12">
                            {t('SERVICES.HOSPITALIZATION_ANNOUNCEMENT_REMARK')}
                        </div>
                        <div
                            data-testid="requests-details-hospitalization-announcement-remark"
                            className="label p-bottom-24 m-bottom-12 border-bottom-solid-width-1 bbc-gray100 white-space-pre-line"
                        >
                            {(remark.new as string) ?? ''}
                        </div>
                    </>
                )}
            </div>
            {contactActiveFields.length > 0 && (
                <>
                    <RequestsPanelSubtitle label={t('FAMILY_GROUP.CONTACT_INFO_LABEL')} />
                    <div className="requests-panel-main-block m-bottom-24">
                        {contactActiveFields.map((field) => (
                            <LabelValueInlineBlock key={field.label}>
                                <div>{field.label}</div>
                                <div
                                    data-testid={`requests-details-hospitalization-announcement-${field.id}`}
                                >
                                    {field.value}
                                </div>
                            </LabelValueInlineBlock>
                        ))}
                    </div>
                </>
            )}
            {attachment && (
                <>
                    <RequestsPanelSubtitle label={t('SERVICE.HOSPITALIZATION_REQUEST_DOCUMENT')} />
                    <div className="requests-panel-main-block">
                        <LabelValueInlineBlock key="download">
                            <div>{t('COMMON.DOCUMENT')}</div>
                            <AssuraDownloadLink
                                testId="requests-details-hospitalization-announcement-download"
                                label={t('COMMON.DOWNLOAD')}
                                labelClass="label"
                                isLoading={loading}
                                onClick={handleDownloadClick}
                            />
                        </LabelValueInlineBlock>
                    </div>
                </>
            )}
        </div>
    )
}

export default HospitalizationAnnouncement
