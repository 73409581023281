/* eslint-disable sonarjs/cognitive-complexity */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { FaqGlobalItem } from 'core/models/Faq'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

export type GoodToKnowHelpQuery = FaqGlobalItem

export interface GoodToKnowNeedHelpProps {
    query?: GoodToKnowHelpQuery
    ctaTranslationKey?: string // TEMPORARY: To hide "Need help" button
}

const GoodToKnowNeedHelp = ({
    query,
    ctaTranslationKey
}: GoodToKnowNeedHelpProps): JSX.Element | null => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const needHelpMessage = t(ctaTranslationKey ?? '')
    const shouldDisplayNeedHelp = query && (needHelpMessage !== ctaTranslationKey || needHelpMessage !== '');

    const goToFaqPage = () => {
                const defaultCategory = '1'

                const faqCategory = query ? (query as FaqGlobalItem).categoryId : defaultCategory
                const faqQuestion =
                    query && (query as FaqGlobalItem).questionId
                        ? `&question=${(query as FaqGlobalItem).questionId}`
                        : undefined

                return navigate(
                    `${navigationConstants.FAQ.url}?category=${faqCategory}${faqQuestion || ''}`
                )
            }
    return shouldDisplayNeedHelp ? (
        <>
            <div className="good-to-know-separator horizontal-separator bg-gray100" />
            <div className="d-flex justify-content-start m-top-24">
                <AssuraIconButton
                    onClick={goToFaqPage}
                    id="need-help-button"
                    size="medium"
                    variant="primary"
                    icon="assura-help-circle"
                    label={t(ctaTranslationKey ?? 'COMMON.NEED_HELP')}
                />
            </div>
        </>
    ) : null
}

export default GoodToKnowNeedHelp
