import { EventHandler } from 'react'

import sizeProps from 'core/constants/assuraIconButtonSizeProps'
import { IconButtonVariants } from 'core/enums/IconButtonVariants'

interface AssuraIconButtonProps {
    id: string
    icon: string
    variant: IconButtonVariants
    size: 'small' | 'medium'
    disabled?: boolean
    onClick?: (() => void) | EventHandler<never>
    classNames?: string
    label?: string
}

const AssuraIconButton = ({
    id,
    icon,
    variant,
    size,
    disabled,
    onClick,
    classNames,
    label
}: AssuraIconButtonProps): JSX.Element => {
    return (
        <div
            data-testid={id}
            className={`icon-container clickable ${variant} ${classNames ? classNames : ''}`}
            onClick={!disabled ? onClick : undefined}
        >
            <i
                className={`icon ${icon} size-${sizeProps[size].iconSize}`}
                data-testid={`${id}-icon`}
            />
            {label && (
                <div
                    className={`link-label ${sizeProps[size].labelFont}`}
                    style={{ marginLeft: `${sizeProps[size].labelMargin}px` }}
                    data-testid={`${id}-label`}
                >
                    {label}
                </div>
            )}
        </div>
    )
}

export default AssuraIconButton
