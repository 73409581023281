export default {
    default: {
       iconTitle: 'info-circle',
       iconColor: 'black',
       textColor: 'black',
       backgroundColor: 'gray100'
    },
    alert: {
        iconTitle: 'warning-circle',
        iconColor: 'error',
        textColor: 'black',
        backgroundColor: 'alert'
     },
}