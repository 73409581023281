import './BackToScanContainerMobile.css'

import { useState } from 'react'

import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'
import AssuraCarouselArrow from 'shared/components/AssuraCarousel/AssuraCarouselArrow'

interface BackToScanContainerMobileProps {
    items: JSX.Element[]
}

const BackToScanContainerMobile = ({ items }: BackToScanContainerMobileProps): JSX.Element => {
    const totalSlides = items?.length

    const [currentIndex, setCurrentIndex] = useState(0)

    const handleBeforeChange: (oldIndex: number, newIndex: number) => void = (oldIndex, newIndex) =>
        setCurrentIndex(newIndex)

    return (
        <AssuraCarousel
            beforeChange={handleBeforeChange}
            responsive={[
                {
                    breakpoint: 896,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]}
            nextArrow={
                <AssuraCarouselArrow
                    direction={'right'}
                    customClass="arrow-container arrow-backtoscan-right"
                    visible={currentIndex < totalSlides - 1}
                />
            }
            prevArrow={
                <AssuraCarouselArrow
                    direction={'left'}
                    customClass="arrow-container arrow-backtoscan-left"
                    visible={currentIndex > 0}
                />
            }
            dotsClass={'slick-dots'}
            className={totalSlides > 0 ? 'enveloppes-back-to-scan-container-mobile' : undefined}

        >
            {items}
        </AssuraCarousel>
    )
}

export default BackToScanContainerMobile
