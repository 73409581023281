import './GoodToKnow.css'

import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import { getGoodToKnow } from 'shared/store/selectors/services/getGoodToKnow'

import GoodToKnowAccordionToggle from './GoodToKnowAccordionToggle'
import GoodToKnowBody from './GoodToKnowBody'
import { GoodToKnowNeedHelpProps } from './GoodToKnowNeedHelp'

interface GoodToKnowProps {
    goodToKnowId: ServiceType | 'enveloppeSubmission' | 'enveloppeRescan'
    needHelpProps?: GoodToKnowNeedHelpProps
}

const GoodToKnow = ({ goodToKnowId, needHelpProps }: GoodToKnowProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const [isCollapsed, setIsCollapsed] = useState(true)

    const goodToKnow = useSelector(getGoodToKnow)

    const current = goodToKnow.find((item) => item.goodToKnowId === goodToKnowId)

    if (current) {
        return (
            <Accordion>
                <div className="good-to-know-container white-space-pre-line">
                    <div className="titleSmall">{current.goodToKnowTitle}</div>
                    <div
                        className={`paragraphSmallLight m-top-24 ${
                            isMobile && isCollapsed ? 'max-2-text-lines' : ''
                        }`}
                    >
                        {current.goodToKnowDescription}
                    </div>
                    {isMobile ? (
                        <Accordion.Collapse eventKey="0">
                            <GoodToKnowBody current={current} needHelpProps={needHelpProps} />
                        </Accordion.Collapse>
                    ) : (
                        <GoodToKnowBody current={current} needHelpProps={needHelpProps} />
                    )}
                </div>
                {isMobile && (
                    <GoodToKnowAccordionToggle
                        eventKey="0"
                        callback={() => setIsCollapsed(!isCollapsed)}
                        icon={`assura-${isCollapsed ? 'plus' : 'minus'}`}
                        label={`${isCollapsed ? t('COMMON.SEE_MORE') : t('COMMON.SEE_LESS')}`}
                    />
                )}
            </Accordion>
        )
    } else {
        return <></>
    }
}

export default GoodToKnow
