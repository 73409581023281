import './ContractDoctorList.css'

import { useSelector } from 'react-redux'

import { getSortedDoctorsOfSelectedFamilyMember } from 'shared/store/selectors/services/getSortedDoctorsOfSelectedFamilyMember'

import ContractDoctorItem from '../ContractDoctorItem/ContractDoctorItem'

const ContractDoctorList = (): JSX.Element | null => {
    const doctorsOfSelectedFamilyMember = useSelector(getSortedDoctorsOfSelectedFamilyMember)

    if (doctorsOfSelectedFamilyMember?.length > 0) {
        return (
            <div className="contract-doctor-list-container bg-white bc-gray100 m-top-32 p-32">
                {doctorsOfSelectedFamilyMember?.map((doctor, index) => {
                    return <ContractDoctorItem doctor={doctor} key={index.toString()} />
                })}
            </div>
        )
    } else {
        return null
    }
}

export default ContractDoctorList
