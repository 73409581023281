import { prepareDiscountsData } from 'core/helpers/PrimesPanelHelper'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedFamilyMember } from './getSelectedFamilyMember'

export const getDiscountsOfSelectedFamilyMember = createSelector([getSelectedFamilyMember], (selectedFamilyMember) => {
    const discountsPerson = selectedFamilyMember?.discountsPerson
    return prepareDiscountsData(discountsPerson)
})
