import { useTranslation } from 'react-i18next'

import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

interface ProductCardPanelHeaderProps {
    productTitle: string
    closePanel: () => void
}
const ProductCardPanelHeader = ({
    productTitle,
    closePanel
}: ProductCardPanelHeaderProps): JSX.Element => {
    const { t } = useTranslation()

    const LeftBlock = (): JSX.Element => {
        return (
            <div className="headline c-primary" data-testid="product-card-panel-header-title">
                {t(productTitle)}
            </div>
        )
    }

    return (
        <AssuraPanelHeader
            sideBlockStyleWidthAuto
            leftBlock={<LeftBlock />}
            closePanel={closePanel}
        />
    )
}

export default ProductCardPanelHeader
