import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { HandleThrowError } from 'core/models/HandleThrowError'
import {
    RequestData,
    RequestDetails,
    RequestDetailsPayload
} from 'core/models/services/RequestData'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

interface FetchRequestDetailsPayload {
    id: string
    policyNumber: number
    reload?: boolean
}

export interface RequestsState {
    requestsLoadingStatus: LoadingStatusEnum
    requests: RequestData[]
    selectedRequest?: string
    requestsDetails: Record<string, RequestDetails>
    requestDetailsLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_REQUESTS: RequestsState = {
    requestsLoadingStatus: LoadingStatusEnum.LOADING,
    requests: [],
    selectedRequest: undefined,
    requestsDetails: {},
    requestDetailsLoadingStatus: LoadingStatusEnum.LOADING,
}

export const requestsSlice = createSlice({
    name: 'requests',
    initialState: INITIAL_STATE_REQUESTS,
    reducers: {
        getRequests: (state, _action: PayloadAction<HandleThrowError | undefined>) => {
            return state
        },
        setRequestsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.requestsLoadingStatus = action.payload
        },
        setRequests: (state, action: PayloadAction<RequestData[]>) => {
            state.requests = action.payload
        },
        setSelectedRequestId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedRequest = action.payload
        },
        fetchRequestDetails: (state, _action: PayloadAction<FetchRequestDetailsPayload>) => {
            return state
        },
        setRequestDetailsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.requestDetailsLoadingStatus = action.payload
        },
        setRequestDetails: (state, action: PayloadAction<RequestDetailsPayload>) => {
            state.requestsDetails[action.payload.id] = action.payload.data
        }
    }
})

export const {
    getRequests,
    setRequestsLoadingStatus,
    setRequests,
    setSelectedRequestId,
    fetchRequestDetails,
    setRequestDetailsLoadingStatus,
    setRequestDetails,
} = requestsSlice.actions

export const getServicesRequestsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.servicesRequests.requestsLoadingStatus

export const getServicesRequests: Selector<RootState, RequestData[]> = (state) =>
    state.servicesRequests.requests

export const getSelectedRequestId: Selector<RootState, string | undefined> = (state: RootState) =>
    state.servicesRequests.selectedRequest

export const getServicesRequestsDetails: Selector<RootState, Record<string, RequestDetails>> = (
    state
) => state.servicesRequests.requestsDetails

export const getServicesRequestDetailsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state
) => state.servicesRequests.requestDetailsLoadingStatus

export default requestsSlice.reducer
