import './LcaCardLightList.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { Product } from 'core/models/Product'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getSelectedFamilyMember } from 'shared/store/combineSelectors'
import {
    fetchFamilyMember,
    getFamilyMemberIndex
} from 'shared/store/familyMember/familyMember.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'

import LcaCardLight from '../LcaCardLight/LcaCardLight'
import NoLca from '../NoLca/NoLca'

interface LcaCardLightListProps {
    lcaProducts: Product[]
}

const LcaCardLightList = ({ lcaProducts }: LcaCardLightListProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const familyMemberIndex = useSelector(getFamilyMemberIndex)
    const productCount = lcaProducts.length
    const selectedMember = useSelector(getSelectedFamilyMember)

    return (
        <div className="lca-card-light-list-container bc-gray100" data-testid="home-lca-container">
            <AssuraLoadAndError
                status={productsLoaderStatus}
                defaultReloadAction={() =>
                    dispatch(fetchFamilyMember({ selectedIndex: familyMemberIndex, reload: true }))
                }
                activityIndicatorSize={24}
            >
                {lcaProducts.length > 0 ? (
                    <>
                        <div className="lca-card-list-light-header bbc-gray100">
                            <span className="headlineSmallMedium flex-1">
                                {t('HOME.LCA_TITLE')}
                            </span>
                            <AssuraIconButton
                                id="lca-card-list-light-show-contract-button"
                                icon="assura-next"
                                variant="primary"
                                size="medium"
                                onClick={() =>
                                    navigate(
                                        `${navigationConstants.INSURANCES.url}?policy=${selectedMember.policyNumber}`
                                    )
                                }
                                label={t('COMMON.SEE_DETAIL')}
                            />
                        </div>
                        <div
                            className={`lca-card-list${
                                productCount === 1 ? ' lca-card-list-light-one' : ''
                            }`}
                            id="home-lca-card-list"
                        >
                            {lcaProducts.map((lcaProduct, index) => {
                                return (
                                    <LcaCardLight
                                        product={lcaProduct}
                                        key={index.toString()}
                                        productCount={productCount}
                                    />
                                )
                            })}
                        </div>
                    </>
                ) : (
                    <NoLca />
                )}
            </AssuraLoadAndError>
        </div>
    )
}

export default LcaCardLightList
