import './RefundsTableRow.css'

import { useTranslation } from 'react-i18next'

import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'
import concatClassNames from 'core/utils/classNameUtils'
import { formatNumber } from 'core/utils/TextUtils'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import ReadStatusField from 'shared/components/AssuraTable/Fields/ReadStatusField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

import RefundsTableRowMobile from './RefundsTableRowMobile'

interface RefundsTableRowProps {
    row: DocumentRow
}

const RefundsTableRow = ({ row }: RefundsTableRowProps): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()

    const unreadClass = row.isRead ? 'label' : 'labelMedium c-black'
    const dateClass = concatClassNames(['refunds-date-field', unreadClass])
    const documentNameClass = concatClassNames(['refunds-document-name-field', unreadClass])
    const amountClass = concatClassNames(['refunds-table-row-amount-field', unreadClass])

    const label = `${row.documentName} ${t('COMMON.FOR', {
        item: row.insuredPersonName
    })}`

    return isXL ? (
        <>
            <ReadStatusField isRead={row.isRead} rowId={row.documentId} />
            <DateField date={row.creationDate} rowId={row.documentId} classNames={dateClass} />
            <TextField
                label={label}
                rowId={row.documentId}
                classNames={documentNameClass}
                fieldId="documentName"
            />
            <TextField
                label={`CHF ${formatNumber(row.valueAmount, true)}`}
                rowId={row.documentId}
                classNames={amountClass}
                fieldId="amount"
            />
            <GoToField rowId={row.documentId} marginLeft={40} />
        </>
    ) : (
        <RefundsTableRowMobile row={row} />
    )
}

export default RefundsTableRow
