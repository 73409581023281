import { useTranslation } from 'react-i18next'

import { RequestDetailsValues, RequestDetailsValuesView } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface PersonalDataEmailProps {
    values: RequestDetailsValues[]
}

const PersonalDataEmail = ({ values }: PersonalDataEmailProps): JSX.Element => {
    const { t } = useTranslation()

    const email = values.find((v) => v.id === 'Email')

    const activeFields: RequestDetailsValuesView = {
        testId: 'email',
        label: t('PERSONAL_DATA.EMAIL'),
        old: email?.old ?? '',
        new: email?.new ?? ''
    }

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-personal-data-${activeFields.testId}-old`}>
                        {activeFields.old as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')} />
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-personal-data-${activeFields.testId}-new`}>
                        {activeFields.new as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default PersonalDataEmail
