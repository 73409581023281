import axios from 'axios'
import Locality from 'core/models/Localities'

import { Street } from '../models/Street'

export const fetchLocalities = async (npaOrLocality: string): Promise<Locality[]> => {
    const npaOrLocalityToFind = encodeURIComponent(npaOrLocality)
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON_SERVICES}/Localities/${npaOrLocalityToFind}`,
        { timeout: 20000 }
    )

    return result.data
}

export const fetchStreets = async (localityId: number, street: string): Promise<Street[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON_SERVICES}/Localities/streets/${localityId}?searchTerm=${street}&api-version=1`,
        { timeout: 20000 }
    )

    return result.data
}
