import React from 'react'

import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'

interface RecommendedProductCardBodyProps {
    recommendedProduct: RecommendedProduct
}

const RecommendedProductCardBody = ({
    recommendedProduct
}: RecommendedProductCardBodyProps): JSX.Element => {
    return (
        <div className="recommended-product-card-body-container">
            <div
                data-testid={`recommended-product-card-description-${recommendedProduct.insuranceId}`}
                className="paragraphSmallLight"
            >
                {recommendedProduct.description}
            </div>
            <div className="recommended-product-card-characteristics-list-container">
                {recommendedProduct.characteristics?.map((characteristic, index) => {
                    return (
                        <div
                            data-testid={`recommended-product-card-characteristics-container-${recommendedProduct.insuranceId}-${index}`}
                            className="recommended-product-card-characteristics-container"
                            key={index.toString()}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <i className="icon assura-check size-24 c-primary" />
                                <div
                                    data-testid={`recommended-product-card-characteristics-title-${recommendedProduct.insuranceId}-${index}`}
                                    className="labelSmall"
                                    style={{ marginLeft: 12, flex: 1 }}
                                >
                                    {characteristic.title}
                                </div>
                            </div>
                            {characteristic.text && (
                                <div
                                    className="paragraphExtraSmall c-gray500"
                                    style={{ marginTop: 4, marginLeft: 36 }}
                                >
                                    {characteristic.text}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RecommendedProductCardBody
