import { DEFAULT_LANGUAGE } from 'core/constants/lang'
import { GenderEnum, MaritalStatusCodesEnum, MaritalStatusEnum } from 'core/enums/FamilyGroup'

import familyGroupConstants from '../constants/familyGroup'

// TODO: supprimer le mapping des codes maritaux pour AS400: MaritalStatusCodesEnum
export const translateMaritalStatus = (maritalStatus: string, gender: string): string => {
    switch (maritalStatus) {
        case MaritalStatusEnum.single:
        case MaritalStatusCodesEnum.single:
            return familyGroupConstants.MARITAL_STATUS[0]
        case MaritalStatusEnum.married:
        case MaritalStatusCodesEnum.married:
            return GenderEnum.female === gender
                ? familyGroupConstants.MARITAL_STATUS[2]
                : familyGroupConstants.MARITAL_STATUS[1]
        case MaritalStatusEnum.divorced:
        case MaritalStatusCodesEnum.divorced:
            return GenderEnum.female === gender
                ? familyGroupConstants.MARITAL_STATUS[4]
                : familyGroupConstants.MARITAL_STATUS[3]
        case MaritalStatusEnum.widower:
        case MaritalStatusCodesEnum.widower:
            return GenderEnum.female === gender
                ? familyGroupConstants.MARITAL_STATUS[6]
                : familyGroupConstants.MARITAL_STATUS[5]
        case MaritalStatusEnum.separated:
        case MaritalStatusCodesEnum.separated:
            return GenderEnum.female === gender
                ? familyGroupConstants.MARITAL_STATUS[9]
                : familyGroupConstants.MARITAL_STATUS[8]
        case MaritalStatusEnum.inPartnership:
        case MaritalStatusCodesEnum.inPartnership:
            return familyGroupConstants.MARITAL_STATUS[10]
        case MaritalStatusEnum.partnershipSeparated:
        case MaritalStatusCodesEnum.partnershipSeparated:
            return familyGroupConstants.MARITAL_STATUS[11]
        default:
            return familyGroupConstants.MARITAL_STATUS[7]
    }
}

export const translateGender = (gender: string): string => {
    switch (gender) {
        case GenderEnum.male:
            return familyGroupConstants.GENDER[1]
        case GenderEnum.female:
            return familyGroupConstants.GENDER[2]
        default:
            return familyGroupConstants.GENDER[0]
    }
}

export const getContactLanguageSuffix = (contactLanguage: string): string =>
    contactLanguage === DEFAULT_LANGUAGE ? '' : `_${contactLanguage}`
