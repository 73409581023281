import { useSelector } from 'react-redux'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getSelectedClaimsSettlementReceipt } from 'shared/store/selectors/getSelectedClaimsSettlementReceipt'

import ClaimsSettlementReceiptPanelCosts from './ClaimsSettlementReceiptPanelCosts'
import ClaimsSettlementReceiptPanelCoveredSection from './ClaimsSettlementReceiptPanelCoveredSection'
import ClaimsSettlementReceiptPanelSharedSection from './ClaimsSettlementReceiptPanelSharedSection'

interface ClaimsSettlementReceiptPanelDetailsProps {
    closePanel: () => void
    isSelectedDocumentRead: boolean
}

const ClaimsSettlementReceiptPanelDetails = ({
    closePanel,
    isSelectedDocumentRead
}: ClaimsSettlementReceiptPanelDetailsProps): JSX.Element => {
    const selectedClaimsSettlementReceipt = useSelector(getSelectedClaimsSettlementReceipt)

    return selectedClaimsSettlementReceipt ? (
        <div className="claims-settlement-receipt-panel-details-container">
            <div className="claims-settlement-panel-adaptive-padding-horizontal d-flex flex-column p-top-40 p-bottom-40 bg-white">
                <div className="d-flex align-items-center">
                    <AssuraIconButton
                        id="claims-settlement-receipt-panel-back"
                        icon="assura-back"
                        variant="default"
                        size="medium"
                        onClick={closePanel}
                    />
                    <div
                        className="m-left-16 headlineSmallMedium"
                        data-testid="claims-settlement-receipt-panel-title"
                    >
                        {selectedClaimsSettlementReceipt.title}
                    </div>
                </div>
                <ClaimsSettlementReceiptPanelCosts
                    selectedClaimsSettlementReceipt={selectedClaimsSettlementReceipt}
                    isSelectedDocumentRead={isSelectedDocumentRead}
                />
            </div>
            <div className="p-top-40 p-bottom-40 flex-1">
                <ClaimsSettlementReceiptPanelCoveredSection
                    selectedClaimsSettlementReceipt={selectedClaimsSettlementReceipt}
                />
                <ClaimsSettlementReceiptPanelSharedSection
                    selectedClaimsSettlementReceipt={selectedClaimsSettlementReceipt}
                />
            </div>
        </div>
    ) : (
        <div />
    )
}

export default ClaimsSettlementReceiptPanelDetails
