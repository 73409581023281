/* eslint-disable sonarjs/no-identical-functions */
import axios from 'axios'
import {
    DocumentsData,
    DocumentsDataWS,
    DocumentsRequestBody,
    DocumentsRequestBodyV3
} from 'core/models/documents/Document'
import { UnreadDocumentApi } from 'core/models/documents/UnReadDocuments'

export const fetchDocumentsV2 = async (
    filterParameters: DocumentsRequestBody
): Promise<DocumentsData> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Documents`,
        filterParameters
    )

    return result.data
}

export const fetchDocumentsV3 = async (
    filterParameters: DocumentsRequestBodyV3
): Promise<DocumentsDataWS> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v3/Documents`,
        filterParameters
    )

    return result.data
}

export const fetchQrCodeApi = async (documentId: string): Promise<string> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/Documents/qrcode?documentReference=${documentId}`,
        {
            headers: {
                'Content-Type': 'application/xml; charset=utf-8'
            }
        }
    )

    return `data:image/svg+xml;base64,${btoa(result.data)}`
}

export const fetchUnreadDocumentsApi = async (): Promise<UnreadDocumentApi[]> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Documents/unread`)

    return result.data
}

export const fetchPoliceDocApi = async (): Promise<DocumentsData> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Documents/policedoc`)

    return result.data
}
