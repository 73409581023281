/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    dateFieldDateTimeMustBeInPast,
    isTrue,
    timeFieldDateTimeMustBeInPast
} from 'core/helpers/FieldValidationHelper'
import { AccidentDetailsModel } from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import { commonMandatoryErrorKey } from 'core/validators/constants'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { newCustomValidation } from 'modules/services/components/Stepper/core/helpers'
import { StepItem } from 'modules/services/components/Stepper/core/models'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'
import { AddressLocalitySelection } from 'modules/services/personalData/components/Address/AddressLocality'

import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'
import LocalityRHF from '../components/LocalityRHF'

const formName = 'ACCIDENT_DETAILS'
export interface AccidentDetailsFormProps {
    id: number
}

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const accidentDetailsFormFieldNameList: Array<keyof AccidentDetailsModel> = [
    'accidentDate',
    'accidentTime',
    'accidentLocation',
    'accidentLocationLabel',
    'cbstandard',
    'cbdental',
    'cbcirculation',
    'eating',
    'accidentCause',
    'training',
    'school',
    'work',
    'third',
    'thirdPartyAddress',
    'thirdPartyInsurance',
    'police',
    'policeReport',
    'witnesses'
]

// Step 5 - ACCIDENT DETAILS
const AccidentDetailsForm = ({ id }: AccidentDetailsFormProps): JSX.Element => {
    const {
        setStepVisibility,
        setError,
        unSetError,
        errors,
        resetForm,
        setCustomValidators,
        stepWatch
        /** Do not remove the following lines they will be used as soon as we upgrade the stepper and provide local storage abilities */
        // persistLocalData,
        // recoverLocalData
    } = useStepperContext()

    const { t } = useTranslation()
    const {
        register,
        getValues,
        setValue,
        trigger,
        resetField,
        watch,
        setError: setFieldError,
        clearErrors
    } = useFormContext<
        /** as we are using som fields from other steps we must concatenate their interfaces */
        AccidentDetailsModel | FieldValues
    >()
    const { isMobile, isMD } = useWindowSize()
    const { TimePicker, TextareaInput, RadioGroup, DatePicker } = useCustomControls(formName)
    const userDateValue = watch('accidentDate') as Date
    const userTimeValue = watch('accidentTime') as Date
    // const loacalityLoadingStatus = useSelector(getLocalitiesLoadingStatus)
    const currentCustomErrors = errors(id)
    const currentStep = stepWatch(id) as StepItem
    const accidentTypeError = currentCustomErrors.find((o) => o.fieldName === 'accidentType')

    const { cbdental, cbcirculation, accidentLocation, third, police } = getValues()

    /** use this trick in order to let accidentLocation drive the availability of value.
     * Indeed: accidentLocationLabel is a 'fictive' field that's used in order to keep track of the label of a locality
     * this is not linked to any control so: RHF will not have any incidence on it (i.e. usage of clearErrors, resetField etc.. )
     * if we use this directly from : const { accidentLocation } = getValues() it will never change until we fill with setValue(...)
     * as the stepper is agnostic and as we use  clearErrors, resetField we cannot clear it.
     */
    const accidentLocationLabel = accidentLocation ? getValues('accidentLocationLabel') : ''
    const eatingValue = getValues('eating')

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // Here we add a custom validation
        setCustomValidators([newCustomValidation(id, 'accidentType', validateInjured)])
    }, [])

    useEffect(() => {
        otherStepsActivation(5, eatingValue === 'eatingYes')
    }, [eatingValue])

    useEffect(() => {
        otherStepsActivation(6, cbcirculation)
    }, [cbcirculation])

    /** This effect handles when the custom validations should be invoked*/
    useEffect(() => {
        validateInjured()
    }, [currentStep && currentStep.hasBeenValidated])

    useEffect(() => {
        if (third === 'thirdNo') {
            resetField('thirdPartyAddress', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
            resetField('thirdPartyInsurance', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
        }
        if (police === 'policeNo') {
            resetField('policeReport', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
        }
    }, [third, police])

    /** helper method that handles the interaction for other steps */
    const otherStepsActivation = (stepId: number, isChecked: boolean) => {
        setStepVisibility([stepId], isChecked)
        if (isChecked === false) {
            resetForm(stepId)
        }
    }

    /** This custom validation will be executed within the context of a validation from the stepper.
     * that neans we need to get the latest values of each field involved into the validation.
     * we cannot use a value from this component.
     */
    const validateInjured = () => {
        // skip current field validation until the step is validated once.
        if (currentStep && !currentStep.hasBeenValidated) return

        const dentalInjuriesChecked = getValues('cbdental')
        const circulationChecked = getValues('cbcirculation')
        const standardChecked = getValues('cbstandard')

        if (!dentalInjuriesChecked && !circulationChecked && !standardChecked) {
            setError(id, 'accidentType', t(commonMandatoryErrorKey))
        } else {
            unSetError(id, 'accidentType')
        }
        return false
    }

    const handleaccidentType = async (field: keyof AccidentDetailsModel, value: boolean) => {
        setValue(field, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })
        if (field === 'cbdental') {
            setValue('eating', '')
        }
        validateInjured()
    }

    const thirdValidateYes = isTrue(getValues('third'), 'third')()
    const policeValidateYes = isTrue(getValues('police'), 'police')()
    const mobile = isMobile || isMD

    const handleLocalitySelection = (selection: AddressLocalitySelection) => {
        setValue('accidentLocationLabel', selection.label)
    }

    return (
        <div id={`${id}`} className="step-frame">
            <FormBlock
                titleTestId={'general-questions-about-accident'}
                title={t(`${serviceName}.${formName}.GENERAL_QUESTION_ABOUT_ACCIDENT`)}
                childrenMargin="m-bottom-48"
            >
                <Row
                    style={{
                        flexDirection: isMobile || isMD ? 'column' : 'row',
                        marginBottom: isMobile || isMD ? '0px' : '24px'
                    }}
                >
                    <Col
                        sm={12}
                        md={12}
                        xl={4}
                        style={{ marginTop: isMobile || isMD ? '0px' : '15px' }}
                        className={`paragraphMedium`}
                    >
                        {t(`${serviceName}.${formName}.ACCIDENT_DATE_TIME`)}
                    </Col>
                    <Col sm={12} md={12} xl={8}>
                        <Row>
                            <Col sm={6} md={6} xl={6}>
                                {DatePicker(
                                    'accidentDate',
                                    '',
                                    {
                                        mandatory: true,
                                        validate: (value: string | Date): boolean | string => {
                                            return dateFieldDateTimeMustBeInPast(
                                                value as Date,
                                                clearErrors,
                                                setFieldError,
                                                userTimeValue
                                            )
                                        }
                                    },
                                    false,
                                    () => trigger('accidentDate')
                                )}
                            </Col>
                            <Col sm={6} md={6} xl={6}>
                                {TimePicker(
                                    'accidentTime',
                                    '',
                                    {
                                        mandatory: true,
                                        validate: (value: string | Date): boolean | string => {
                                            return timeFieldDateTimeMustBeInPast(
                                                value as Date,
                                                clearErrors,
                                                setFieldError,
                                                userDateValue
                                            )
                                        }
                                    },
                                    false,
                                    () => trigger('accidentTime')
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <LocalityRHF
                    id="accidentLocation"
                    name="accidentLocation"
                    defaultValue={accidentLocationLabel}
                    handleSelection={handleLocalitySelection}
                />
            </FormBlock>

            <FormBlock
                titleTestId={'accident-details'}
                title={t(`${serviceName}.${formName}.ACCIDENT_DETAILS`)}
                childrenMargin="m-bottom-48"
            >
                <Row style={{ flexDirection: mobile ? 'column' : 'row' }}>
                    <Col
                        sm={12}
                        md={12}
                        xl={4}
                        className={`paragraphMedium ${mobile ? 'm-bottom-24' : ''}`}
                    >
                        {t(`${serviceName}.${formName}.ACCIDENT_TYPE`)}
                    </Col>
                    <Col sm={12} md={12} xl={8}>
                        <Row id="accidentType">
                            <Col sm={12} md={4} xl={4} className={`${mobile ? 'm-bottom-16' : ''}`}>
                                <CheckboxRHF
                                    id="cbdental"
                                    name="cbdental"
                                    label={t(`${serviceName}.${formName}.CHB_DENTAL`)}
                                    register={register}
                                    labelClassNames="paragraph"
                                    classNames="flex-1"
                                    onClick={(value) => handleaccidentType('cbdental', value)}
                                />
                            </Col>
                            <Col sm={12} md={4} xl={4} className={`${mobile ? 'm-bottom-16' : ''}`}>
                                <CheckboxRHF
                                    id="cbcirculation"
                                    name="cbcirculation"
                                    label={t(`${serviceName}.${formName}.CHB_CIRCULATION`)}
                                    register={register}
                                    labelClassNames="paragraph"
                                    classNames="flex-1"
                                    onClick={(value) => handleaccidentType('cbcirculation', value)}
                                />
                            </Col>
                            <Col sm={12} md={4} xl={4} className={`${mobile ? 'm-bottom-16' : ''}`}>
                                <CheckboxRHF
                                    id="cbstandard"
                                    name="cbstandard"
                                    label={t(`${serviceName}.${formName}.CHB_STANDARD`)}
                                    register={register}
                                    labelClassNames="paragraph"
                                    classNames="flex-1"
                                    onClick={(value) => handleaccidentType('cbstandard', value)}
                                />
                            </Col>
                        </Row>
                        {accidentTypeError?.error && (
                            <Row className="m-top-8">
                                <Col xs={8}>
                                    <div
                                        className="labelExtraSmall c-error m-top-8"
                                        data-testid={'accidentType-error'}
                                    >
                                        {accidentTypeError?.error?.message}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
                {cbdental && (
                    <>
                        {RadioGroup(
                            'EATING',
                            'eating',
                            {
                                mandatory: cbdental
                            },
                            () => trigger('eating'),
                            true
                        )}
                    </>
                )}
                {TextareaInput(
                    'accidentCause',
                    'DESCRIPTION',
                    { mandatory: true, maxLength: 2000 },
                    false
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'accident-circumstances'}
                title={t(`${serviceName}.${formName}.ACCIDENT_CIRCUMSTANCES`)}
                childrenMargin="m-bottom-48"
            >
                {RadioGroup(
                    'TRAINING',
                    'training',
                    {
                        mandatory: true
                    },
                    () => trigger('eating'),
                    true
                )}
                {RadioGroup(
                    'SCHOOL',
                    'school',
                    {
                        mandatory: true
                    },
                    () => trigger('school'),
                    true
                )}
                {RadioGroup(
                    'WORK',
                    'work',
                    {
                        mandatory: true
                    },
                    () => trigger('work'),
                    true
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'other-third-involved'}
                title={t(`${serviceName}.${formName}.OTHER_THIRD_INVOLVED`)}
                childrenMargin="m-bottom-48"
            >
                {RadioGroup(
                    'THIRD',
                    'third',
                    {
                        mandatory: true
                    },
                    () => trigger('third'),
                    true
                )}
                {thirdValidateYes && (
                    <>
                        {TextareaInput(
                            'thirdPartyAddress',
                            'THIRD_ADDRESS',
                            {
                                mandatory: thirdValidateYes,
                                maxLength: 400
                            },
                            false
                        )}
                        {TextareaInput(
                            'thirdPartyInsurance',
                            'THIRD_INSURANCE',
                            {
                                mandatory: thirdValidateYes,
                                maxLength: 400
                            },
                            false
                        )}
                    </>
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'police-report'}
                title={t(`${serviceName}.${formName}.POLICE_REPORT`)}
                childrenMargin="m-bottom-48"
            >
                {RadioGroup(
                    'POLICE',
                    'police',
                    {
                        mandatory: true
                    },
                    () => trigger('police'),
                    true
                )}
                {policeValidateYes && (
                    <>
                        {TextareaInput(
                            'policeReport',
                            'POLICE_REPORT',
                            {
                                mandatory: policeValidateYes,
                                maxLength: 400
                            },
                            false
                        )}
                    </>
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'witnesses'}
                title={t(`${serviceName}.${formName}.WITNESSES`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom="m-bottom-32"
            >
                {TextareaInput(
                    'witnesses',
                    'WITNESSES',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}

export default AccidentDetailsForm
