import { mapRequestsToRows } from 'core/helpers/services/ServicesHelper'
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'
import { RequestRow } from 'core/models/services/RequestData'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getFilteredRequests } from './services/getFilteredRequests'

export const getRequestsForTable = createSelector(
    [getFilteredRequests, getFamilySummaries],
    (requests, familySummaries): RequestRow[] => {
        const personNames: EnveloppePerson[] = familySummaries[
            getCurrentYear()
        ]?.insuredPersons.map((person) => {
            return {
                policyNumber: person.policyNumber,
                personName: person.firstName,
                personLastName: person.lastName,
                personGender: person.gender
            }
        })

        return mapRequestsToRows(requests, personNames)
    }
)
