import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { formatCcp } from 'core/helpers/services/CcpHelper'
import { formatIban } from 'core/helpers/services/IbanHelper'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface FinancialPaymentChangeProps {
    values: RequestDetailsValues[]
}

const FinancialPaymentChange = ({ values }: FinancialPaymentChangeProps): JSX.Element => {
    const { t } = useTranslation()

    const owner = values.find((v) => v.id === 'NameAndFirstName')
    const validFrom = values.find((v) => v.id === 'ValidFrom')

    const iban = values.find((v) => v.id === 'Iban')
    const ccp = values.find((v) => v.id === 'AccountNo')

    const label = t('PERSONAL_DATA.FINANCIAL_DATA_TITLE')

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                <LabelValueInlineBlock>
                    <div className="align-self-end">{label}</div>
                    <div className="text-right">
                        <div data-testid="financial-payment-change-requests-owner-old">
                            {(owner?.old as string) ?? ''}
                        </div>
                        <div data-testid="financial-payment-change-requests-number-old">
                            {iban?.old
                                ? iban.old !== ''
                                    ? `IBAN : ${formatIban(iban.old as string)}`
                                    : ''
                                : ccp && ccp.old !== ''
                                ? `${t(
                                      'FINANCIAL.PAYMENT_CHANGE_REQUEST_DETAILS_CCP_ACCOUNT'
                                  )} : ${formatCcp(ccp.old as string)}`
                                : ''}
                        </div>
                    </div>
                </LabelValueInlineBlock>
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                <>
                    {validFrom && (
                        <div className="label c-primary">
                            {t('DOCUMENTS.PROCESSING_DATE', {
                                processingDate: formatDate(validFrom.new as string)
                            })}
                        </div>
                    )}
                </>
            </RequestsPanelSubtitle>
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div className="align-self-end">{label}</div>
                    <div className="text-right">
                        <div data-testid="financial-payment-change-requests-owner-new">
                            {(owner?.new as string) ?? ''}
                        </div>
                        <div data-testid="financial-payment-change-requests-number-new">
                            {iban?.new !== ''
                                ? `IBAN : ${formatIban((iban?.new as string) ?? '')}`
                                : `${t(
                                      'FINANCIAL.PAYMENT_CHANGE_REQUEST_DETAILS_CCP_ACCOUNT'
                                  )} : ${formatCcp((ccp?.new as string) ?? '')}`}
                        </div>
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default FinancialPaymentChange
