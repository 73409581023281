import './NavBarSearchCTA.css'

import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useWindowSize from 'core/services/useWindowSize'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

const NavBarSearchCTA = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMD, isXL } = useWindowSize()
    const { toggleNavBarSearch, isNavBarSearchOpen } = useAssuraNavBarContext()

    const searchRouteMatch = useMatch({
        path: navigationConstants.SEARCH.url
    })

    const handleOnClick = () => {
        if (!searchRouteMatch) toggleNavBarSearch()
    }

    return (
        <div
            data-testid="navigation-search-cta"
            className={`navbar-search-cta-container${isNavBarSearchOpen ? ' show' : ''} ${
                isMD && 'd-flex flex-column align-items-center'
            }`}
            onClick={handleOnClick}
            id="navigation-search-cta-container"
        >
            <i
                className={`icon assura-search c-black size-${isXL ? '16' : '24'}`}
                data-testid="navigation-search-cta-icon"
                id="navigation-search-cta-icon"
            />
            {isMD && (
                <span className="labelExtraSmallMedium m-top-4 c-black">
                    {t('SEARCH.NAVBAR_CTA_LABEL')}
                </span>
            )}
            {isXL && (
                <span className="labelExtraSmall m-left-4" id="navigation-search-cta-label">
                    {t('SEARCH.NAVBAR_CTA_LABEL')}
                </span>
            )}
        </div>
    )
}
export default NavBarSearchCTA
