import './ContractDoctorItem.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { mapDoctorToPersonalData } from 'core/helpers/services/DoctorHelper'
import { Doctor } from 'core/models/familyGroup/Doctor'

import DoctorData from '../Doctor/DoctorData'

interface ContractDoctorItemProps {
    doctor: Doctor
}

const ContractDoctorItem = ({ doctor }: ContractDoctorItemProps): JSX.Element => {
    const { t } = useTranslation()

    const isCurrentDoctor = new Date(doctor.startDate) <= new Date()
    const doctorPersonalData = mapDoctorToPersonalData(doctor)

    return (
        <Row className="contract-doctor-item-container bc-gray100">
            <Col xl={4} md={12} className="d-flex flex-column">
                <div className="paragraphMedium">
                    {t(isCurrentDoctor ? 'SERVICES.CURRENT_DOCTOR' : 'SERVICES.FUTURE_DOCTOR')}
                </div>
                <div className="contract-doctor-item-date-container labelExtraSmall c-gray500 d-flex">
                    {isCurrentDoctor ? (
                        <>
                            {t('COMMON.START_DATE', { date: formatDate(doctor.startDate) })}
                            <i className="contract-doctor-item-date-icon icon assura-check-circle size-16 c-success" />
                        </>
                    ) : (
                        <>
                            {t('COMMON.FUTURE_DATE', { date: formatDate(doctor.startDate) })}
                            <i className="contract-doctor-item-date-icon icon assura-clock size-16 c-black" />
                        </>
                    )}
                </div>
            </Col>
            <Col xl={8} md={12} className="contract-doctor-item-data-container d-flex flex-column">
                <DoctorData doctor={doctorPersonalData} iconPosition="left" />
            </Col>
        </Row>
    )
}

export default ContractDoctorItem
