import { RequestDetails } from 'core/models/services/RequestData'

import { createSelector } from '@reduxjs/toolkit'

import {
    getSelectedRequestId,
    getServicesRequestsDetails
} from '../services/requests/requests.slice'

export const getSelectedRequest = createSelector([getSelectedRequestId, getServicesRequestsDetails], (selectedRequest, requestsDetails):
    | RequestDetails
    | undefined => {
    if (selectedRequest) {
        return requestsDetails[selectedRequest]
    } else {
        return undefined
    }
})
