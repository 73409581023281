import './FormBlockWithLabel.css'

import { Col, Row } from 'react-bootstrap'

interface FormBlockWithLabelProps {
    label: string
    isFirstChildInput?: boolean
    children: React.ReactNode
}

const FormBlockWithLabel = ({
    label,
    isFirstChildInput,
    children
}: FormBlockWithLabelProps): JSX.Element => {
    return (
        <Row>
            <Col
                xl={4}
                md={12}
                className={`d-flex${isFirstChildInput ? ' form-block-with-label-input' : ''}`}
                data-testid="form-block-with-label"
            >
                <div className="paragraphMedium">{label}</div>
            </Col>
            <Col xl={8} md={12} className="d-flex flex-column">
                {children}
            </Col>
        </Row>
    )
}

export default FormBlockWithLabel
