import './FamilyDoctor.css'

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import { Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { FamilyDoctorForm, FamilyDoctorFormRHF } from 'core/models/services/contract/FamilyDoctor'
import { useFormRefresh } from 'core/services/useFormRefresh'
import useWindowSize from 'core/services/useWindowSize'
import { getCurrentYear, getDifferenceInCalendarMonths } from 'core/utils/dateUtils'
import { addYears, subYears } from 'date-fns'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { isThereFamilyDoctorInRequests } from 'shared/store/selectors/isThereInRequests'
import { getDoctorLamalOfSelectedFamilyMember } from 'shared/store/selectors/services/getDoctorLamalOfSelectedFamilyMember'
import { getSortedDoctorsOfSelectedFamilyMember } from 'shared/store/selectors/services/getSortedDoctorsOfSelectedFamilyMember'
import {
    fetchContractDoctorsAndProducts,
    fetchSearchDoctors,
    getContractDoctorsAndProductsLoadingStatus,
    onSubmitFamilyDoctor,
    setWantedDate
} from 'shared/store/services/doctors/doctors.slice'
import {
    fetchSummaryAndProducts,
    getFamilyGroupResult,
    getFormDataLoadingStatus,
    getKeepSelectedMember,
    getServiceSubmitStatus,
    goToServicesForm,
    setFamilyGroupResult
} from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import FamilyGroup, { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import FormTitle from '../../components/FormTitle/FormTitle'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import StartDate from '../../components/StartDate/StartDate'
import UserInfo from '../../components/UserInfo/UserInfo'
import ChooseDoctor from '../components/ChooseDoctor/ChooseDoctor'
import ContractDoctorList from '../components/ContractDoctorList/ContractDoctorList'
import NoDoctorLamal from '../components/NoDoctorLamal/NoDoctorLamal'

const FamilyDoctor = (): JSX.Element => {
    useFormRefresh(ServiceType.FAMILY_DOCTOR_CHANGE)

    const { isMobile } = useWindowSize()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const hasPendingRequest = useSelector(isThereFamilyDoctorInRequests)

    const today = new Date()
    const minValidityDate = subYears(today, 1)
    const maxValidityDate = addYears(today, 1)
    const defaultValues: FamilyDoctorFormRHF = {
        validityDate: undefined,
        doctorType: ServicesDoctorType.DOCTOR,
        searchDoctorName: '',
        newDoctorId: undefined
    }

    const methods = useForm<FamilyDoctorFormRHF>({ defaultValues })

    const {
        watch,
        handleSubmit,
        setValue,
        reset,
        getValues,
        trigger,
        formState: { isSubmitted }
    } = methods

    const formDataLoadingStatus = useSelector(getFormDataLoadingStatus)
    const contractDoctorsAndProductsLoadingStatus = useSelector(
        getContractDoctorsAndProductsLoadingStatus
    )
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const submitServiceStatus = useSelector(getServiceSubmitStatus)
    const doctorLamal = useSelector(getDoctorLamalOfSelectedFamilyMember)
    const doctorsOfSelectedFamilyMember = useSelector(getSortedDoctorsOfSelectedFamilyMember)
    const familyGroupResult = useSelector(getFamilyGroupResult)

    const [familyMemberError, setFamilyMemberError] = useState('')

    const wantedDate = watch('validityDate')
    const searchDoctorName = watch('searchDoctorName')

    const keptMember = useSelector(getKeepSelectedMember)

    useEffect(() => {
        dispatch(setWantedDate(wantedDate ? wantedDate.toISOString() : undefined))
        if (familyGroupResult.selectedPolicyNumber) {
            dispatch(
                fetchSummaryAndProducts({
                    policyNumber: familyGroupResult.selectedPolicyNumber,
                    year: wantedDate?.getFullYear() ?? getCurrentYear()
                })
            )
        }
    }, [wantedDate])

    useEffect(() => {
        if (!wantedDate && doctorLamal) {
            const doctorLamalStartDate = new Date(doctorLamal.startDate)
            const defaultStartDate = doctorLamalStartDate > today ? doctorLamalStartDate : today
            setValue('validityDate', defaultStartDate)
        }
    }, [doctorLamal, wantedDate])

    useEffect(() => {
        if (doctorLamal && searchDoctorName && searchDoctorName.length > 2) {
            dispatch(fetchSearchDoctors({ code: doctorLamal.code }))
        }
    }, [doctorLamal])

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        dispatch(setFamilyGroupResult(familyGroupResult))
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContractDoctorsAndProducts({
                    policyNumber: familyGroupResult.selectedPolicyNumber,
                    year: getCurrentYear()
                })
            )
        setFamilyMemberError('')
        reset({ ...defaultValues })
    }

    const submitSuccess = async () => {
        onSubmit(true)
    }

    const submitError = async () => {
        onSubmit(false)
    }

    const onSubmit = (isRHFFormValid: boolean) => {
        const isFamilyMemberSelected =
            familyGroupResult.isAllFamilyMembers || familyGroupResult.selectedPolicyNumber
        if (!isFamilyMemberSelected) {
            setFamilyMemberError('COMMON.MANDATORY_FIELD')
        } else {
            setFamilyMemberError('')
        }
        if (isRHFFormValid && isFamilyMemberSelected) {
            const familyDoctorForm: FamilyDoctorForm = { ...getValues(), ...familyGroupResult }
            dispatch(onSubmitFamilyDoctor(familyDoctorForm))
        }
    }

    const validateValidityDate = () => {
        const { validityDate } = getValues()
        if (isSubmitted) {
            trigger('newDoctorId')
        }
        if (!validityDate) {
            return 'COMMON.MANDATORY_FIELD'
        } else {
            let dateIsValid = true
            doctorsOfSelectedFamilyMember?.forEach((contractDoctor) => {
                const contractDoctorStartDate = new Date(contractDoctor.startDate)
                const monthsBetweenDoctor = getDifferenceInCalendarMonths(
                    validityDate,
                    contractDoctorStartDate
                )
                if (Math.abs(monthsBetweenDoctor) < 3) {
                    dateIsValid = false
                }
            })
            if (dateIsValid) {
                return true
            } else {
                return 'SERVICES.NEW_DOCTOR_3_MONTHS_ERROR'
            }
        }
    }

    return (
        <FullScreenContainer
            color="gray20"
            complementaryClasses="container-load-error position-relative"
        >
            <AssuraLoadAndError
                status={formDataLoadingStatus}
                defaultReloadAction={() =>
                    dispatch(goToServicesForm({ type: ServiceType.FAMILY_DOCTOR_CHANGE }))
                }
            >
                <>
                    <Container>
                        <FormProvider {...methods}>
                            <Row className="p-bottom-80">
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                                    {!isMobile && (
                                        <FormTitle
                                            title={t('SERVICES.NEW_FAMILY_DOCTOR')}
                                            category={t('SERVICES.CONTRACTS_ADMINISTRATION')}
                                        />
                                    )}
                                    <div>
                                        <UserInfo />
                                        <FamilyGroup
                                            hasDefaultSelectedMember={!!keptMember}
                                            defaultSelectedPolicyNumber={
                                                keptMember?.selectedPolicyNumber
                                            }
                                            selectAction={selectMember}
                                            selectedMemberError={t(familyMemberError)}
                                            serviceType={ServiceType.FAMILY_DOCTOR_CHANGE}
                                            source={Source()}
                                        />
                                    </div>
                                    {Boolean(familyGroupResult.selectedPolicyNumber) && (
                                        <AssuraLoadAndError
                                            status={contractDoctorsAndProductsLoadingStatus}
                                            shouldDisplayContainer
                                            defaultReloadAction={() =>
                                                dispatch(
                                                    fetchContractDoctorsAndProducts({
                                                        policyNumber:
                                                            familyGroupResult.selectedPolicyNumber as number,
                                                        year: getCurrentYear(),
                                                        reload: true
                                                    })
                                                )
                                            }
                                        >
                                            {hasPendingRequest ? (
                                                <PendingRequest />
                                            ) : (
                                                <>
                                                    <ContractDoctorList />
                                                    <StartDate
                                                        name="validityDate"
                                                        minDate={minValidityDate}
                                                        maxDate={maxValidityDate}
                                                        testId="family-doctor-start-date"
                                                        rules={{
                                                            validate: validateValidityDate
                                                        }}
                                                    />
                                                    <AssuraLoadAndError
                                                        status={getLoadingStatusFromLoaderList([
                                                            summaryLoadingStatus,
                                                            productsLoaderStatus
                                                        ])}
                                                        shouldDisplayContainer
                                                        defaultReloadAction={() =>
                                                            dispatch(
                                                                fetchSummaryAndProducts({
                                                                    policyNumber:
                                                                        familyGroupResult.selectedPolicyNumber as number,
                                                                    year:
                                                                        wantedDate?.getFullYear() ??
                                                                        getCurrentYear(),
                                                                    reload: true
                                                                })
                                                            )
                                                        }
                                                    >
                                                        {doctorLamal ? (
                                                            <>
                                                                <div
                                                                    className="choose-doctor-container bg-white bc-gray100 m-top-32 p-32 d-flex flex-column"
                                                                    data-testid="services-choose-doctor"
                                                                >
                                                                    <ChooseDoctor
                                                                        code={doctorLamal.code}
                                                                    />
                                                                </div>
                                                                <div className="m-top-32">
                                                                    <AssuraButton
                                                                        text={t('COMMON.SEND')}
                                                                        id="services-family-doctor-submit-button"
                                                                        variant="primary"
                                                                        onClick={handleSubmit(
                                                                            submitSuccess,
                                                                            submitError
                                                                        )}
                                                                        hasLoader={
                                                                            submitServiceStatus ===
                                                                            LoadingStatusEnum.LOADING
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>{wantedDate && <NoDoctorLamal />}</>
                                                        )}
                                                    </AssuraLoadAndError>
                                                </>
                                            )}
                                        </AssuraLoadAndError>
                                    )}
                                </Col>
                                <Col
                                    xs={{ span: 12, order: 1 }}
                                    md={{ span: 4, order: 2 }}
                                    xl={{ span: 4, offset: 1 }}
                                >
                                    {isMobile && (
                                        <FormTitle
                                            title={t('SERVICES.NEW_FAMILY_DOCTOR')}
                                            category={t('SERVICES.CONTRACTS_ADMINISTRATION')}
                                        />
                                    )}
                                    <GoodToKnow
                                        goodToKnowId={ServiceType.FAMILY_DOCTOR_CHANGE}
                                    />
                                </Col>
                            </Row>
                        </FormProvider>
                    </Container>
                </>
            </AssuraLoadAndError>
        </FullScreenContainer>
    )
}

export default withAITracking(reactPlugin, FamilyDoctor, 'FamilyDoctor', 'family-doctor-container')
