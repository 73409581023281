import React from 'react'
import { useTranslation } from 'react-i18next'

import errorIcon from 'shared/assets/images/error.png'

const ModelDeductibleNotAllowed = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="bg-white bc-gray100 border-solid-width-1 m-top-32 p-32 d-flex flex-column align-items-center text-center">
            <img src={errorIcon} className="image-size-56" alt="not-allowed-image" />
            <div className="headlineSmall m-top-24">
                {t('SERVICES.COVERAGE_CHANGE_NOT_ALLOWED')}
            </div>
        </div>
    )
}

export default ModelDeductibleNotAllowed
