import './SymptomCheckerHeader.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { getECRoute } from 'core/helpers/NavigationHelper'
import concatClassNames from 'core/utils/classNameUtils'
import AssuraIconButtonBelow from 'shared/components/AssuraIconButtonBelow/AssuraIconButtonBelow'
import { getPath } from 'shared/store/general/general.slice'

const SymptomCheckerHeader = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const path = useSelector(getPath)
    const ecRoute = getECRoute(path)

    const [isLandingPage, setIsLandingPage] = useState(false)

    const goBackToHome = () => {
        navigate(navigationConstants.HOME.url)
    }

    const ctaPositionClass = isLandingPage
        ? 'symptom-checker-header-cta-close'
        : 'symptom-checker-header-cta-home'
    const ctaClasses = concatClassNames(['symptom-checker-header-container-cta', ctaPositionClass])

    useEffect(() => {
        setIsLandingPage(ecRoute?.url === navigationConstants.SYMPTOM_CHECKER_LANDING_PAGE.url)
    }, [path])

    return (
        <div className="bbc-gray100 bg-white symptom-checker-header-container fixed-top">
            <AssuraIconButtonBelow
                id="symptom-checker-header-cta"
                icon={isLandingPage ? 'assura-close' : 'assura-house'}
                variant="default"
                iconSize={24}
                onClick={goBackToHome}
                label={t(isLandingPage ? 'COMMON.CLOSE' : 'NAVIGATION_BAR.HOME')}
                labelFontClass="labelExtraSmall"
                margin={4}
                classNames={ctaClasses}
            />
            <div className="labelMedium flex-1 text-center">{t('SYMPTOM_CHECKER.TITLE')}</div>
        </div>
    )
}

export default SymptomCheckerHeader
