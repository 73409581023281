import './StatusIconField.css'

import concatClassNames from 'core/utils/classNameUtils'

interface StatusIconFieldProps {
    statusColor: string
    rowId: number | string
    isMobileStyle?: boolean
}
const StatusIconField = ({
    statusColor,
    rowId,
    isMobileStyle
}: StatusIconFieldProps): JSX.Element => {
    const iconColor = statusColor.replace('c-', 'bg-')
    const iconResponsiveStyle = `status-round-icon${isMobileStyle ? '-mobile' : ''}`
    const iconClasses = concatClassNames([iconColor, iconResponsiveStyle])

    return (
        <td className={iconClasses} data-testid={`assura-table-row-${rowId}-status-icon-field`} />
    )
}

export default StatusIconField
