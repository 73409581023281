import { AppSearchSection } from 'core/models/AppSearch'

import { createSelector } from '@reduxjs/toolkit'

import { getAppSearchLocalizedData } from './getAppSearchLocalizedData'

export const getAppSearchSuggestions = createSelector(
    [getAppSearchLocalizedData],
    (data) => {
        const suggestionsSection: AppSearchSection = {
            id: 'SUGGESTIONS',
            sectionTitleKey: 'SEARCH.SUGGESTIONS_TITLE',
            results: []
        }

        const suggestionResults = Object.values(data)
            .flatMap((resultsList) => {
                return resultsList.filter((result) => result.suggestionOrder !== null)
            })
            .sort((a, b) => (a.suggestionOrder ?? 1) - (b.suggestionOrder ?? 1))

        suggestionsSection.results = [...suggestionResults]

        return suggestionsSection
    }
)
