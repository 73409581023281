import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { WhatsNewCms } from 'core/models/cms/WhatsNewCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface WhatsNewState {
    whatsNews: WhatsNewCms[]
    whatsNewLoadingStatus: LoadingStatusEnum
    whatsNew: WhatsNewCms | null
    lastActiveWhatsNewId: number
    familyLastWhatsNewId: number
}

const INITIAL_STATE_UNPAID_STATS: WhatsNewState = {
    whatsNew: null,
    whatsNewLoadingStatus: LoadingStatusEnum.LOADING,
    whatsNews: [],
    lastActiveWhatsNewId: 0,
    familyLastWhatsNewId: 0
}

export const whatsNewSlice = createSlice({
    name: 'whatsNew',
    initialState: INITIAL_STATE_UNPAID_STATS,
    reducers: {
        fetchWhatsNew: (state) => {
            return state
        },
        setWhatsNewLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.whatsNewLoadingStatus = action.payload
        },
        setWhatsNews: (state, action: PayloadAction<WhatsNewCms[]>) => {
            state.whatsNews = action.payload
        },
        setWhatsNew: (state, action: PayloadAction<WhatsNewCms | null>) => {
            state.whatsNew = action.payload
        },
        setLastActiveWhatsNewId: (state, action: PayloadAction<number>) => {
            state.lastActiveWhatsNewId = action.payload
        },
        setFamilyLastWhatsNewId: (state, action: PayloadAction<number>) => {
            state.familyLastWhatsNewId = action.payload
        },
        updateFamilyLastWhatsNewId: (state, action: PayloadAction<number>) => {
            state.familyLastWhatsNewId = action.payload
        },
        selectWhatsNew: (state, _action: PayloadAction<number>) => {
            return state
        },
        selectLastWhatsNew: (state) => {
            return state
        }
    }
})

export const {
    fetchWhatsNew,
    setWhatsNewLoadingStatus,
    setWhatsNews,
    setWhatsNew,
    setLastActiveWhatsNewId,
    setFamilyLastWhatsNewId,
    updateFamilyLastWhatsNewId,
    selectWhatsNew,
    selectLastWhatsNew
} = whatsNewSlice.actions

export const getWhatsNews: Selector<RootState, WhatsNewCms[]> = (state: RootState) =>
    state.whatsNew.whatsNews

export const getWhatsNewLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state: RootState) =>
    state.whatsNew.whatsNewLoadingStatus

export const getWhatsNew: Selector<RootState, WhatsNewCms | null> = (state: RootState) =>
    state.whatsNew.whatsNew

export const geLastActiveWhatsNewId: Selector<RootState, number> = (state: RootState) =>
    state.whatsNew.lastActiveWhatsNewId

export const getFamilyLastWhatsNewId: Selector<RootState, number> = (state: RootState) =>
    state.whatsNew.familyLastWhatsNewId

export const getHasActiveWhatsNew: Selector<RootState, boolean> = (state: RootState) =>
    state.whatsNew.lastActiveWhatsNewId > 0

export default whatsNewSlice.reducer
