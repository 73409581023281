import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { fetchUnpaidStatsApi } from 'core/api/UnpaidStats'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { UnpaidStats } from 'core/models/UnpaidStats'

import {
    fetchUnpaidStats,
    setUnpaidStatsData,
    setUnpaidStatsLoadingStatus
} from './unpaidStats.slice'

function* fetchUnpaidStatsSaga() {
    try {
        yield put(setUnpaidStatsLoadingStatus(LoadingStatusEnum.LOADING))

        const unpaidStatsData: UnpaidStats = yield call(fetchUnpaidStatsApi)

        yield put(setUnpaidStatsData(unpaidStatsData))
        yield put(setUnpaidStatsLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchUnpaidStatsSaga Error', e)
        yield put(setUnpaidStatsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchUnpaidStatsWatcher() {
    yield takeEvery(fetchUnpaidStats.type, fetchUnpaidStatsSaga)
}

const watchers = [fork(fetchUnpaidStatsWatcher)]

export default watchers
