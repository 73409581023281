import { AssuraTabData, TabOptions } from 'core/models/TabData'
import {} from 'core/utils/TabUtils'

import AssuraTabItem from '../AssuraTabItem/AssuraTabItem'

type AssuraTabItemsProps = {
    data: AssuraTabData[]
    dataId: string
    // selection item
    select: (id: AssuraTabData['uniqueKey']) => void
    // templating options
    options?: TabOptions
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AssuraTabItems = ({ data, dataId, select, options }: AssuraTabItemsProps) => {
    const displaySeparator = (tabData: AssuraTabData, index: number): boolean =>
        !(
            index <= tabData.selectedValue! ||
            data[index - 1]?.selectedValue === tabData?.uniqueKey - 1
        )

    return (
        <>
            {data.map((tabData: AssuraTabData, index: number) => {
                return (
                    <AssuraTabItem
                        data={tabData}
                        key={tabData.uniqueKey}
                        separator={displaySeparator(tabData, index)}
                        onClick={select}
                        dataId={dataId}
                        itemTemplate={options?.labelTemplate}
                        valueTemplate={options?.valueTemplate}
                    />
                )
            })}
        </>
    )
}

export default AssuraTabItems
