import './NavBarCalculator.css'

import { useTranslation } from 'react-i18next'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ButtonOrigin, LinkTitle } from 'core/enums/AnalyticsEnums'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { getLocalizedUrl } from 'core/helpers/CalculatorHelper'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'

const NavBarCalculator = (): JSX.Element => {
    const { t } = useTranslation()
    const { setNavBarMenuState } = useAssuraNavBarContext()

    const handleOnClick = () => {
        setNavBarMenuState(false)

        sendEvent(analyticsConstants.EVENTS.OFFER_SUGGEST_CLICK, {
            [analyticsConstants.PARAMS.HREF]: getLocalizedUrl(process.env.REACT_APP_CALCULATOR_URL),
            [analyticsConstants.PARAMS.LINK_TITLE]: LinkTitle.NEW_OFFER,
            [analyticsConstants.PARAMS.BUTTON_ORIGIN]: ButtonOrigin.HEADER
        })
    }

    return (
        <AssuraEcLink
            ecLinkId="646391c0ec5ba3566e00d4e5"
            classNames="nav-calculator-nav-link"
            testId="nav-calculator-nav-link"
            callback={handleOnClick}
        >
            <i className="icon assura-next size-24 m-right-8" />
            <span className="cta">{t('NAVIGATION_BAR.CALCULATOR')}</span>
        </AssuraEcLink>
    )
}

export default NavBarCalculator
