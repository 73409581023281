import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import {
    AventuraAdditionalFamilyMemberModel,
    getMemberField,
    newMember
} from 'core/models/services/coverage/AventuraForm'
import { TFunction } from 'i18next'
import DatePickerWithLabelRHF from 'modules/services/components/DatePickerWithLabelRHF/DatePickerWithLabelRHF'
import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'
import { AssuraNumberInputRHF } from 'modules/services/components/NumberInputRHF/NumberInputRHF'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { addFamilyMember } from 'shared/store/services/aventura/aventura.slice'

import {
    dateValidation,
    getDuplicateMember,
    getMemberErrorMessage,
    isMemberEmpty,
    setDuplicateErrors
} from '../../helpers/validations'

type AventuraAdditionalMemberProps = {
    t: TFunction
    people: PersonSummary[]
    peopleList: SelectMap<string | number>

    aventuraMembers: AventuraAdditionalFamilyMemberModel[]
    isMobile: boolean
    isTablet: boolean
    person: AventuraAdditionalFamilyMemberModel
}

const AventuraAdditionalMember = ({
    people,
    t,
    isMobile,
    isTablet,
    peopleList,
    aventuraMembers,
    person
}: AventuraAdditionalMemberProps) => {
    const {
        setValue,
        setError,
        register,
        trigger,
        watch,

        formState: { errors, isSubmitting, isValidating, submitCount, isSubmitSuccessful }
    } = useFormContext()
    const dispatch = useDispatch()
    const [showMember, setShowMember] = useState(false)
    // prepare the sub form collection node field names as they are pointing the right child
    const personSelectorFieldName = getMemberField(person.id, 'personSelector')
    const policyNumberFieldName = getMemberField(person.id, 'policyNumber')
    const firstNameFieldName = getMemberField(person.id, 'firstName')
    const lastNameFieldName = getMemberField(person.id, 'lastName')
    const birthDateFieldName = getMemberField(person.id, 'birthDate')

    // gether data from RHF
    const selectedMember = watch(personSelectorFieldName)
    const policyNumber = watch(policyNumberFieldName) as number
    const firstName = watch(firstNameFieldName)
    const lastName = watch(lastNameFieldName)
    const birthDate = watch(birthDateFieldName)

    const getFilteredPeopleList = (): SelectMap<number | string> => {
        const newPeopleList = new Map() as SelectMap<number | string>
        for (const [key, value] of peopleList) {
            if (value !== person.id) newPeopleList.set(key, value)
        }
        return newPeopleList
    }

    useEffect(() => {
        validateduplicates()
    }, [isSubmitting, isValidating, errors])

    const validateduplicates = () => {
        const unique: AventuraAdditionalFamilyMemberModel[] = []
        for (let i = 0; i < aventuraMembers.length; i++) {
            const duplicate = getDuplicateMember(aventuraMembers[i], unique)

            if (isMemberEmpty(aventuraMembers[i])) continue

            if (!duplicate) {
                if (!isMemberEmpty(aventuraMembers[i])) unique.push(aventuraMembers[i])
            } else {
                if (!isMemberEmpty(duplicate)) setDuplicateErrors(i, setError, t)
            }
        }
    }

    useEffect(() => {
        // basically for some reason we use custom validation for this field and we need to handle some extra cases as described below.
        // As trigger reacts immediately when the value is changed (when it is invoked with the name of field). This is not really what we want because of the way the form behaves while validating fields.
        // if nothing has been submitted then we skip trigger
        // if an invalid sumit has been intented then we trigger each time we change value in this field.
        if (!isSubmitSuccessful && submitCount > 0) {
            trigger(personSelectorFieldName)
        }
    }, [selectedMember])

    // Updates the store when data are updated in the form
    useEffect(() => {
        const updateMember = newMember(
            person.id,
            policyNumber,
            firstName,
            lastName,
            birthDate ? formatDateToApiFormat(birthDate) : birthDate
        )
        dispatch(addFamilyMember(updateMember))
    }, [selectedMember, policyNumber, firstName, lastName, birthDate])

    // Update FORM: RHF as we select a person or other in the drop down
    useEffect(() => {
        if (selectedMember !== 'other') {
            const selectedMemberPerson = people.find(
                (o) => o.policyNumber === parseInt(selectedMember)
            )

            setValue(personSelectorFieldName, selectedMember)
            setValue(policyNumberFieldName, selectedMemberPerson?.policyNumber)
            setValue(lastNameFieldName, selectedMemberPerson?.lastName)
            setValue(firstNameFieldName, selectedMemberPerson?.firstName)
            setValue(
                birthDateFieldName,
                selectedMemberPerson?.dateOfBirth
                    ? formatDateToApiFormat(selectedMemberPerson?.dateOfBirth)
                    : undefined
            )
            setShowMember(() => false)
        } else {
            setValue(policyNumberFieldName, undefined)
            setValue(lastNameFieldName, undefined)
            setValue(firstNameFieldName, undefined)
            setValue(birthDateFieldName, undefined)
            setShowMember(() => true)
        }
    }, [selectedMember])

    const validationFirstNameCharsLength = 'FORM.INPUT_FIRST_NAME_CHECK_LENGTH'
    const validationLastNameCharsLength = 'FORM.INPUT_LAST_NAME_CHECK_LENGTH'
    const commonMandatory = 'COMMON.MANDATORY_FIELD'

    return (
        <Container
            id={`av-additional-members-block-${person.id}`}
            data-testid="av-additional-members-block"
            className="av-additional-members-block m-bottom-32 bg-white bc-gray100"
        >
            <Row className="justify-content-start m-bottom-32">
                <Col>
                    <span className="label">{`${t('SERVICE.AVENTURA_MEMBER_TITLE')} ${
                        person.id + 1
                    }`}</span>
                </Col>
            </Row>

            <Row
                className={`justify-content-start ${
                    !selectedMember ? '' : selectedMember === 'other' ? 'm-bottom-32' : ''
                } `}
            >
                <Col>
                    <AssuraFormSelectRHF
                        name={personSelectorFieldName}
                        id={personSelectorFieldName}
                        label={t('SERVICE.AVENTURA_MEMBER_SELECTOR_LABEL')}
                        items={getFilteredPeopleList()}
                        labelSize={4}
                        inline={!(isMobile || isTablet)}
                        placeHolder={t('SERVICE.AVENTURA_MEMBER_SELECTOR_PLACEHOLDER')}
                        rules={{
                            required: {
                                value: true,
                                message: 'COMMON.MANDATORY_FIELD'
                            }
                        }}
                    />
                </Col>
            </Row>
            {/*Even if not shown, keep this section as that's may be required later */}
            {selectedMember && selectedMember === 'other' && (
                <Row className="justify-content-start m-bottom-48">
                    <Col>
                        <AssuraNumberInputRHF
                            name={policyNumberFieldName}
                            id={policyNumberFieldName}
                            label={t('SERVICE.AVENTURA_MEMBER_POLICY_NUMBER_LABEL')}
                            placeHolder={t('SERVICE.AVENTURA_MEMBER_POLICY_NUMBER_PLACEHOLDER')}
                            inline={!(isMobile || isTablet)}
                            labelSize={4}
                            register={register}
                            maxLength={8}
                            rules={{
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: t('COMMON.POLICY_NUMBER_VALIDATION')
                                }
                            }}
                            isInvalid={getMemberErrorMessage(person, errors, 'policyNumber') !== ''}
                            error={t(getMemberErrorMessage(person, errors, 'policyNumber'))}
                            autocomplete="off"
                            hasButton={false}
                            onButtonClick={() => void 0}
                            iconClass="icon assura-close size-24"
                        />
                    </Col>
                </Row>
            )}
            {selectedMember && selectedMember === 'other' && showMember && (
                <>
                    <Row className="justify-content-start m-bottom-48">
                        <Col>
                            <AssuraFormInputRHF
                                name={lastNameFieldName}
                                id={lastNameFieldName}
                                label={t('SERVICE.AVENTURA_MEMBER_LAST_NAME_LABEL')}
                                placeHolder={t('SERVICE.AVENTURA_MEMBER_LAST_NAME_PLACEHOLDER')}
                                inline={!(isMobile || isTablet)}
                                labelSize={4}
                                register={register}
                                rules={{
                                    minLength: {
                                        value: 2,
                                        message: t(validationLastNameCharsLength, {
                                            min: 2,
                                            max: 30
                                        })
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: t(validationLastNameCharsLength, {
                                            min: 2,
                                            max: 30
                                        })
                                    },
                                    required: { value: true, message: commonMandatory }
                                }}
                                autocomplete="off"
                                isInvalid={getMemberErrorMessage(person, errors, 'lastName') !== ''}
                                error={t(getMemberErrorMessage(person, errors, 'lastName'))}
                                hasButton={false}
                                onButtonClick={() => void 0}
                                iconClass="icon assura-close size-24"
                                readOnly={selectedMember !== 'other'}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-start m-bottom-48">
                        <Col>
                            <AssuraFormInputRHF
                                name={firstNameFieldName}
                                id={firstNameFieldName}
                                label={t('SERVICE.AVENTURA_MEMBER_FIRST_NAME_LABEL')}
                                placeHolder={t('SERVICE.AVENTURA_MEMBER_FIRST_NAME_PLACEHOLDER')}
                                inline={!(isMobile || isTablet)}
                                labelSize={4}
                                register={register}
                                rules={{
                                    minLength: {
                                        value: 2,
                                        message: t(validationFirstNameCharsLength, {
                                            min: 2,
                                            max: 30
                                        })
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: t(validationFirstNameCharsLength, {
                                            min: 2,
                                            max: 30
                                        })
                                    },
                                    required: { value: true, message: commonMandatory }
                                }}
                                isInvalid={
                                    getMemberErrorMessage(person, errors, 'firstName') !== ''
                                }
                                autocomplete="off"
                                error={t(getMemberErrorMessage(person, errors, 'firstName'))}
                                hasButton={false}
                                onButtonClick={() => void 0}
                                iconClass="icon assura-close size-24"
                                readOnly={selectedMember !== 'other'}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-start">
                        <Col id={birthDateFieldName}>
                            <DatePickerWithLabelRHF
                                name={birthDateFieldName}
                                label="SERVICE.AVENTURA_MEMBER_BIRTH_DATE_LABEL"
                                placeholderKey="SERVICE.AVENTURA_MEMBER_BIRTH_DATE_PLACEHOLDER"
                                testId={birthDateFieldName}
                                className="bg-white"
                                maxDate={new Date()}
                                rules={{ validate: dateValidation }}
                                inline={!(isMobile || isTablet)}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    )
}
export default AventuraAdditionalMember
