import navigationConstants from 'core/constants/navigationConstants'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getPath } from '../general/general.slice'
import { getProductsYear } from '../products/products.slice'

export const getProductsYearByPage = createSelector(
    [getProductsYear, getPath],
    (productsYear, page) =>
        page === navigationConstants.INSURANCES.url ? productsYear : getCurrentYear()
)
