import { call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchLastWhatsNewId, updateFamilyLastWhatsNewApi } from 'core/api/Account'
import { whatsNewCmsData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { WhatsNewTargetEnum } from 'core/enums/WhatsNew'
import { WhatsNewCms } from 'core/models/cms/WhatsNewCms'
import { isAfter, isBefore } from 'date-fns'

import {
    fetchWhatsNew,
    geLastActiveWhatsNewId,
    getFamilyLastWhatsNewId,
    getWhatsNewLoadingStatus,
    getWhatsNews,
    selectLastWhatsNew,
    selectWhatsNew,
    setFamilyLastWhatsNewId,
    setLastActiveWhatsNewId,
    setWhatsNew,
    setWhatsNewLoadingStatus,
    setWhatsNews,
    updateFamilyLastWhatsNewId
} from './whatsNew.slice'

function* fetchWhatsNewSaga() {
    try {
        yield put(setWhatsNewLoadingStatus(LoadingStatusEnum.LOADING))
        const whatsNews: WhatsNewCms[] = yield call(whatsNewCmsData)

        if (whatsNews.length > 0) {
            const filteredWhatsNews = whatsNews
                .filter((item) => item.target.includes(WhatsNewTargetEnum.ECL))
                .sort((a, b) => parseInt(b.id) - parseInt(a.id))

            yield put(setWhatsNews(filteredWhatsNews))

            const lastWhatsNew = filteredWhatsNews[0]
            const startDate = new Date(lastWhatsNew.validity_start)
            const endDate = new Date(lastWhatsNew.validity_end)
            const now = new Date()

            if (isBefore(now, endDate) && isAfter(now, startDate)) {
                yield put(setLastActiveWhatsNewId(parseInt(lastWhatsNew.id)))
            }
        }

        yield put(setWhatsNewLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchWhatsNewSaga Error', e)
        yield put(setWhatsNewLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

export function* selectWhatsNewSaga(action: ReturnType<typeof selectWhatsNew>) {
    try {
        const id = action.payload
        const whatsNews: WhatsNewCms[] = yield select(getWhatsNews)

        const selectedWhatsNew = whatsNews.find((item) => parseInt(item.id) === id)

        if (selectedWhatsNew) {
            yield put(setWhatsNew(selectedWhatsNew))

            const familyLastWhatsNewId: number = yield select(getFamilyLastWhatsNewId)
            if (familyLastWhatsNewId < id) {
                yield put(updateFamilyLastWhatsNewId(id))
            }
        }
    } catch (e) {
        console.error('selectWhatsNewSaga Error', e)
    }
}

export function* selectLastWhatsNewSaga() {
    try {
        const lastActiveWhatsNewId: number = yield select(geLastActiveWhatsNewId)
        if (lastActiveWhatsNewId > 0) yield put(selectWhatsNew(lastActiveWhatsNewId))
    } catch (e) {
        console.error('selectLastWhatsNewSaga Error', e)
    }
}

export function* whatsNewPopinInit() {
    try {
        let shouldTriggerPopin = false

        const whatsNewLoadingStatus: LoadingStatusEnum = yield select(getWhatsNewLoadingStatus)

        if(whatsNewLoadingStatus === LoadingStatusEnum.LOADING) {
            yield take(setWhatsNewLoadingStatus)
        }

        const lastFamilyWhatsNewId: number = yield call(fetchLastWhatsNewId)
        yield put(setFamilyLastWhatsNewId(lastFamilyWhatsNewId))

        const lastActiveWhatsNewId: number = yield select(geLastActiveWhatsNewId)

        if (lastFamilyWhatsNewId < lastActiveWhatsNewId) {
            shouldTriggerPopin = true
        }

        return shouldTriggerPopin
    } catch (e) {
        console.error('whatsNewPopinInit Error', e)
        return false
    }
}

function* updateFamilyLastWhatsNewIdSaga(action: ReturnType<typeof updateFamilyLastWhatsNewId>) {
    try {
        const newId = action.payload
        yield call(updateFamilyLastWhatsNewApi, newId)
    } catch (e) {
        console.error('updateFamilyLastWhatsNewIdSaga Error', e)
    }
}

function* fetchWhatsNewWatcher() {
    yield takeEvery(fetchWhatsNew.type, fetchWhatsNewSaga)
}

function* updateFamilyLastWhatsNewIdWatcher() {
    yield takeLatest(updateFamilyLastWhatsNewId.type, updateFamilyLastWhatsNewIdSaga)
}

function* selectWhatsNewWatcher() {
    yield takeLatest(selectWhatsNew.type, selectWhatsNewSaga)
}

function* selectLastWhatsNewWatcher() {
    yield takeLatest(selectLastWhatsNew.type, selectLastWhatsNewSaga)
}

const watchers = [
    fork(fetchWhatsNewWatcher),
    fork(updateFamilyLastWhatsNewIdWatcher),
    fork(selectWhatsNewWatcher),
    fork(selectLastWhatsNewWatcher)
]

export default watchers
