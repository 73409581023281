import { ProductPeriodicity } from 'core/enums/Product'
import { Periodicity } from 'core/models/services/financial/PaymentFrequency'

import { formatDateToApiFormat } from '../DateFormatHelper'

export const getPaymentFrequencyStartDate = (
    selectedPeriodicity: ProductPeriodicity,
    availableDates: Periodicity[]
): string | undefined => {
    const targetedPeriodicity = availableDates.find(
        (item) => item.targetPeriodicity === selectedPeriodicity
    )

    if (targetedPeriodicity) {
        return formatDateToApiFormat(targetedPeriodicity.dateAvailable)
    }
    return undefined
}
