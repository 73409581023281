import axios from 'axios'
import { HeadOfFamily } from 'core/models/HeadOfFamily'

export const headOfFamilyApi = async (): Promise<HeadOfFamily> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/headoffamily`
    )

    return result.data
}
