import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ValueType } from 'core/models/ValueType'

export type AventuraCoverType = 'Single' | 'Couple' | 'Family'

export interface AventuraState {
    hasPendingRequests: boolean
    loadingStatus: LoadingStatusEnum
    submitStatus: LoadingStatusEnum
    errors: string[]
    prices: AventuraPriceDetailsModel[]
    formData: AventuraForm
}


export interface AventuraPriceDetailsModel {
    days: number
    priceForOnePerson: number
    priceForTwoPeople: number
    priceForThreePeople: number
}

export interface AventuraAdditionalFamilyMemberModel {
    id: number
    policyNumber: number | null
    lastName: string // Mandatory
    firstName: string // Mandatory
    birthDate: string ///'FORMAT 2022-08-30'
}

export interface AventuraForm {
    policyNumber: number
    source: string
    backOfficeUser?: string
    email: string //maxLength: 100
    phoneNumber: string //maxLength: 20
    travelReason: string //maxLength: 200
    travelDestination: string //maxLength: 200
    travelStart: string ///'FORMAT 2022-08-30'
    travelEnd: string ///'FORMAT 2022-08-30'
    paperFilled: boolean
    aventuraCoverType?: AventuraCoverType
    additionalFamilyMembers: AventuraAdditionalFamilyMemberModel[]
}

export interface AventuraRHFModel extends AventuraForm {
    personCountSelector: ValueType[]
    personSelector: ValueType[]
}

export interface AventuraPricingSummaryModel {
    price: number
    subTitle: string
    error?: string
}

export interface ValidationModel {
    minLength: ValidationDetails
    maxLength: ValidationDetails
    required: ValidationDetails
    pattern?: ValidationDetails
}

export interface ValidationDetails {
    value: string | number | boolean
    message: string
}

export const membersSection = 'additionalFamilyMembers'

export const newMember = (
    id: number,
    policyNumber: number | null,
    firstName: string,
    lastName: string,
    birthDate: string
): AventuraAdditionalFamilyMemberModel => {
    return {
        id: id,
        policyNumber: policyNumber,
        firstName: firstName,
        lastName: lastName,
        birthDate: birthDate
    } as AventuraAdditionalFamilyMemberModel
}

// this return types has also the name of the issued fieds
// starting with $.[fieldName]
// we can imagine to parde response and point out to the wrond field.
export interface AventuraErrorData {
    errors?: [x: string]
    status: number
    title: string
    traceId: string
    type: string
}

export interface AventuraHttpResponse {
    response?: AventuraServiceResponse
}
export interface AventuraServiceResponse {
    status?: number
    data?: AventuraErrorData
}

export const getMemberField = (id: number, field: string): string =>
    `${membersSection}[${id.toString()}].${field}`
