import { DigcliParametersCms as DigcliParametersCms } from 'core/models/cms/DicliParametersCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface DigcliParametersState {
    parameters: DigcliParametersCms | null
}

const INITIAL_STATE_PARAMETERS: DigcliParametersState = {
    parameters: null
}

export const digcliParametersSlice = createSlice({
    name: 'digcliParameters',
    initialState: INITIAL_STATE_PARAMETERS,
    reducers: {
        fetchDigcliParameters: (state) => {
            return state
        },
        fetchDigcliParametersOnSucess: (state, action: PayloadAction<DigcliParametersCms>) => {
            state.parameters = action.payload
        }
    }
})

export const {
    fetchDigcliParameters,
    fetchDigcliParametersOnSucess
} = digcliParametersSlice.actions

export const getDigcliparameters: Selector<RootState, DigcliParametersCms | null> = (state) =>
    state.digcliParameters.parameters

export default digcliParametersSlice.reducer
