import './PickerDay.css'

import DatePicker from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form/dist/types'
import { useSelector } from 'react-redux'

import { defaultDateFormat } from 'core/helpers/DateFormatHelper'
import locales from 'core/services/locales'
import DatePickerRHF from 'modules/services/components/DatePicker/DatePickerRHF/DatePickerRHF'
import { getLang } from 'shared/store/general/general.slice'

import PickerDayHeader from './PickerDayHeader'

/**
 * Added the extends in order to avoid following error:
 * Issues checking in progress...
 * ERROR in src/modules/services/report/components/HospitalInfos.tsx:111:17
 * TS2589: Type instantiation is excessively deep and possibly infinite.
 */
interface PickerDayProps<T extends FieldValues> {
    placeholder?: string
    testId: string
    control: Control<T>
    name: FieldPath<T>
    rules?: RegisterOptions
    minDate?: Date | null
    maxDate?: Date | null
    refId?:string
}

const PickerDay = <T extends FieldValues,>({
    placeholder,
    testId,
    control,
    name,
    rules,
    minDate,
    maxDate,
    refId
}: PickerDayProps<T>): JSX.Element => {
    const lang = useSelector(getLang)

    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState }) => (
                <DatePicker
                    selected={field.value as Date | null | undefined}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                        <DatePickerRHF
                            placeholderText={placeholder}
                            dataTestId={testId}
                            hasError={!!fieldState.error?.message}
                            errorMessage={fieldState.error?.message}
                            refId={refId}
                        />
                    }
                    locale={locales.get(lang)}
                    dateFormat={defaultDateFormat}
                    minDate={minDate}
                    maxDate={maxDate}
                    renderCustomHeader={(params) => <PickerDayHeader {...params} />}
                    popperPlacement="bottom"
                />
            )}
            rules={rules}
        />
    )
}

export default PickerDay
