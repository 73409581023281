import { useTranslation } from 'react-i18next'

import { AssuraPopinProps } from 'core/models/Popin'
import AssuraPopin from 'shared/components/AssuraPopin/AssuraPopin'
import {
    onSubmitLastUpdate,
    onSubmitLastUpdateAndActivate
} from 'shared/store/settings/ecPlusMigration/ecPlusMigration.slice'
import { useAppDispatch } from 'shared/store/store'

const EcPlusPopin = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const ecPlusProps: AssuraPopinProps = {
        showCloseButton: false,
        title: t('MIGRATION_EC_PLUS.TITLE'),
        message: t('MIGRATION_EC_PLUS.DESCRIPTION'),
        messageJustify: true,
        cancelButtonLabel: t('MIGRATION_EC_PLUS.REMIND'),
        validateButtonLabel: t('MIGRATION_EC_PLUS.ACCEPT'),
        cancelAction: () => dispatch(onSubmitLastUpdate()),
        validatedAction: () => dispatch(onSubmitLastUpdateAndActivate())
    }
    return <AssuraPopin popinProps={ecPlusProps} />
}

export default EcPlusPopin
