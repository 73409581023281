import { getDateWithNoTime } from 'core/helpers/DateFormatHelper'
import { mapDocumentsToRows } from 'core/helpers/documents/DocumentsHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getRefundsDocuments } from '../financialDocuments/financialDocuments.slice'
import { getPersonDataForDocuments } from './getPersonDataForDocuments'

export const getSortedRefundsForTable = createSelector(
    [getRefundsDocuments, getPersonDataForDocuments],
    (refunds, personData): DocumentRow[] => {
        const documents = mapDocumentsToRows(refunds, personData)

        /*We want to sort the statements according to several criteria:
        1. The most recent statement (creationDate) but we consider only the year, month and day
        2. By alphabetic order of associated family member (insuredPersonName)
        */

        return documents.sort((a, b) => {
            const aCreationDateNoTime = getDateWithNoTime(a.creationDate)
            const bCreationDateNoTime = getDateWithNoTime(b.creationDate)

            if (isBefore(bCreationDateNoTime, aCreationDateNoTime)) {
                return -1
            } else if (isBefore(aCreationDateNoTime, bCreationDateNoTime)) {
                return 1
            } else {
                return a.insuredPersonName.localeCompare(b.insuredPersonName)
            }
        })
    }
)
