import { call, fork, put, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import {
    submitBilledPrimeForm,
    submitDelegationFromForm,
    submitInsuranceAttestationForm,
    submitInsuranceCardForm,
    submitMedicalFeesForm,
    submitProvisionalReplacementCertificate,
    submitStatementDetailsForm
} from 'core/api/services/Certificates'
import { postEmailApi } from 'core/api/services/ContactInfos'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants, { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { addDays, formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { BilledPrimeFormRequestBody } from 'core/models/services/certificates/BilledPrimeForm'
import { DelegationRequestBody } from 'core/models/services/certificates/Delegation'
import { InsuranceAttestationFormRequestBody } from 'core/models/services/certificates/InsuranceAttestationForm'
import { InsuranceCardFormRequestBody } from 'core/models/services/certificates/InsuranceCardForm'
import { MedicalFeesFormRequestBody } from 'core/models/services/certificates/MedicalFeesForm'
import { ProvisionalReplacementCertificateRequestBody } from 'core/models/services/certificates/ProvisionalReplacementCertificateForm'
import { StatementDetailsFormRequestBody } from 'core/models/services/certificates/StatementDetailsForm'
import { EmailRequestBody } from 'core/models/services/personalData/ContactInfos'

import { setBanner } from '../../banners/banners.slice'
import { setEmailFormValue } from '../contactInfos/contactInfos.slice'
import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import {
    onSubmitBilledPrime,
    onSubmitDelegationCertificate,
    onSubmitInsuranceAttestation,
    onSubmitInsuranceCard,
    onSubmitMedicalFees,
    onSubmitProvisionalReplacementCertificate,
    onSubmitStatementDetails
} from './certificates.slice'

const EMAIL_UPDATE_ERROR_KEY = 'SERVICES.EMAIL_UPDATE_ERROR'

function* onSubmitBilledPrimeSaga(action: ReturnType<typeof onSubmitBilledPrime>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.BILLED_PRIME,
        errorMessage: ''
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const billedPrimeForm = action.payload
        const formToSubmit: BilledPrimeFormRequestBody = {
            policyNumber: billedPrimeForm.selectedPolicyNumber ?? 0,
            email:
                billedPrimeForm.receptionMode === ServicesReceptionMode.EMAIL
                    ? billedPrimeForm.email
                    : null,
            startYear: billedPrimeForm.startYear as number,
            endYear: billedPrimeForm.endYear,
            affectsAllFamilyMembers: billedPrimeForm.isAllFamilyMembers,
            requestLAMal: billedPrimeForm.baseInsurance,
            requestLCA: billedPrimeForm.complementaryInsurance,
            receptionType: billedPrimeForm.receptionMode as ServicesReceptionMode
        }

        if (billedPrimeForm.updateEmail) {
            try {
                yield call(postEmailApi, {
                    policyNumber: billedPrimeForm.selectedPolicyNumber,
                    newEmail: billedPrimeForm.email
                } as EmailRequestBody)
                yield put(
                    setEmailFormValue({
                        policyNumber: billedPrimeForm.selectedPolicyNumber,
                        newEmail: billedPrimeForm.email as string
                    })
                )
            } catch {
                analyticsParams.errorMessage = EMAIL_UPDATE_ERROR_KEY
            }
        }

        yield call(submitBilledPrimeForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitBilledPrimeSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitInsuranceCardSaga(action: ReturnType<typeof onSubmitInsuranceCard>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.INSURANCE_CARD
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const insuranceCardForm = action.payload
        const formToSubmit: InsuranceCardFormRequestBody = {
            provisionalCertificate: insuranceCardForm.provisionalCertificate,
            policyNumber: insuranceCardForm.selectedPolicyNumber ?? 0,
            validityStartDate: formatDateToApiFormat(new Date()),
            validityEndDate: formatDateToApiFormat(addDays(new Date(), 10))
        }

        yield call(submitInsuranceCardForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
        if (formToSubmit.provisionalCertificate) {
            try {
                yield call(submitProvisionalReplacementCertificate, formToSubmit)
            } catch (e) {
                yield put(
                    setBanner({
                        dataTestId: 'request-banner-error',
                        message: 'SERVICES.REQUEST_ERROR'
                    })
                )
            }
        }
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitMedicalFeesSaga(action: ReturnType<typeof onSubmitMedicalFees>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.MEDICAL_FEES,
        errorMessage: ''
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const medicalFeesForm = action.payload
        const formToSubmit: MedicalFeesFormRequestBody = {
            policyNumber: medicalFeesForm.selectedPolicyNumber ?? 0,
            taxYear: medicalFeesForm.taxYear as number,
            receptionType: medicalFeesForm.receptionMode as ServicesReceptionMode,
            email:
                medicalFeesForm.receptionMode === ServicesReceptionMode.EMAIL
                    ? medicalFeesForm.email
                    : null
        }

        if (medicalFeesForm.updateEmail) {
            try {
                yield call(postEmailApi, {
                    policyNumber: medicalFeesForm.selectedPolicyNumber,
                    newEmail: medicalFeesForm.email
                } as EmailRequestBody)
                yield put(
                    setEmailFormValue({
                        policyNumber: medicalFeesForm.selectedPolicyNumber,
                        newEmail: medicalFeesForm.email as string
                    })
                )
            } catch {
                analyticsParams.errorMessage = EMAIL_UPDATE_ERROR_KEY
            }
        }

        yield call(submitMedicalFeesForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitMedicalFeesSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitInsuranceAttestationSaga(
    action: ReturnType<typeof onSubmitInsuranceAttestation>
) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.INSURANCE_ATTESTATION,
        errorMessage: ''
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const insuranceAttestationForm = action.payload
        const formToSubmit: InsuranceAttestationFormRequestBody = {
            policyNumber: insuranceAttestationForm.selectedPolicyNumber ?? 0,
            affectsAllFamilyMembers: insuranceAttestationForm.isAllFamilyMembers,
            requestLAMal: insuranceAttestationForm.baseInsurance,
            requestLCA: insuranceAttestationForm.complementaryInsurance,
            receptionType: insuranceAttestationForm.receptionMode as ServicesReceptionMode,
            email:
                insuranceAttestationForm.receptionMode === ServicesReceptionMode.EMAIL
                    ? insuranceAttestationForm.email
                    : null
        }

        if (insuranceAttestationForm.updateEmail) {
            try {
                yield call(postEmailApi, {
                    policyNumber: insuranceAttestationForm.selectedPolicyNumber,
                    newEmail: insuranceAttestationForm.email
                } as EmailRequestBody)
                yield put(
                    setEmailFormValue({
                        policyNumber: insuranceAttestationForm.selectedPolicyNumber,
                        newEmail: insuranceAttestationForm.email as string
                    })
                )
            } catch {
                analyticsParams.errorMessage = EMAIL_UPDATE_ERROR_KEY
            }
        }

        yield call(submitInsuranceAttestationForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitInsuranceAttestationSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitProvisionalReplacementCertificateSaga(
    action: ReturnType<typeof onSubmitProvisionalReplacementCertificate>
) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.PROVISIONAL_REPLACEMENT_CERTIFICATE
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const provisionalReplacementCertificateForm = action.payload
        const formToSubmit: ProvisionalReplacementCertificateRequestBody = {
            policyNumber: provisionalReplacementCertificateForm.selectedPolicyNumber ?? 0,
            validityStartDate: formatDateToApiFormat(
                provisionalReplacementCertificateForm.validityStartDate
                    ? provisionalReplacementCertificateForm.validityStartDate
                    : new Date()
            ),
            validityEndDate: formatDateToApiFormat(
                provisionalReplacementCertificateForm.validityEndDate
                    ? provisionalReplacementCertificateForm.validityEndDate
                    : addDays(new Date(), 10)
            )
        }

        yield call(submitProvisionalReplacementCertificate, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.SERVICES.url,
                websiteRedirectUrl: `${SERVICES_FORM_ROUTES.SUCCESS.url}?content=download`,
                toastMessage: 'SERVICES.CERTIFICATE_DOWNLOADED',
                ...analyticsParams
            })
        )
    } catch (e) {
        console.error('onSubmitProvisionalReplacementCertificateSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* onSubmitStatementDetailsSaga(action: ReturnType<typeof onSubmitStatementDetails>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.STATEMENT_DETAILS,
        errorMessage: ''
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const statementDetailsForm = action.payload
        const formToSubmit: StatementDetailsFormRequestBody = {
            policyNumber: statementDetailsForm.selectedPolicyNumber ?? 0,
            fromYear: statementDetailsForm.fromYear as number,
            receptionType: statementDetailsForm.receptionMode as ServicesReceptionMode,
            email:
                statementDetailsForm.receptionMode === ServicesReceptionMode.EMAIL
                    ? statementDetailsForm.email
                    : null
        }

        if (statementDetailsForm.updateEmail) {
            try {
                yield call(postEmailApi, {
                    policyNumber: statementDetailsForm.selectedPolicyNumber,
                    newEmail: statementDetailsForm.email
                } as EmailRequestBody)
                yield put(
                    setEmailFormValue({
                        policyNumber: statementDetailsForm.selectedPolicyNumber,
                        newEmail: statementDetailsForm.email as string
                    })
                )
            } catch {
                analyticsParams.errorMessage = EMAIL_UPDATE_ERROR_KEY
            }
        }

        yield call(submitStatementDetailsForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitStatementDetailsSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitDelegationCertificateSaga(
    action: ReturnType<typeof onSubmitDelegationCertificate>
) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.DELEGATION
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const delegationForm = action.payload
        const formToSubmit: DelegationRequestBody = {
            policyNumber: delegationForm.selectedPolicyNumber ?? 0
        }

        yield call(submitDelegationFromForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(
            formSubmitted({
                redirectUrl: navigationConstants.SERVICES.url,
                websiteRedirectUrl: `${SERVICES_FORM_ROUTES.SUCCESS.url}?content=download`,
                toastMessage: 'SERVICES.DOCUMENT_DOWNLOADED',
                ...analyticsParams
            })
        )
    } catch (e) {
        console.error('onSubmitDelegationCertificateSaga Error', e)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* onSubmitBilledPrimeWatcher() {
    yield takeEvery(onSubmitBilledPrime.type, onSubmitBilledPrimeSaga)
}

function* onSubmitInsuranceCardWatcher() {
    yield takeEvery(onSubmitInsuranceCard.type, onSubmitInsuranceCardSaga)
}

function* onSubmitMedicalFeesWatcher() {
    yield takeEvery(onSubmitMedicalFees.type, onSubmitMedicalFeesSaga)
}

function* onSubmitInsuranceAttestationWatcher() {
    yield takeEvery(onSubmitInsuranceAttestation.type, onSubmitInsuranceAttestationSaga)
}

function* onSubmitProvisionalReplacementCertificateWatcher() {
    yield takeEvery(
        onSubmitProvisionalReplacementCertificate.type,
        onSubmitProvisionalReplacementCertificateSaga
    )
}

function* onSubmitStatementDetailsWatcher() {
    yield takeEvery(onSubmitStatementDetails.type, onSubmitStatementDetailsSaga)
}

function* onSubmitDelegationCertificateWatcher() {
    yield takeEvery(onSubmitDelegationCertificate.type, onSubmitDelegationCertificateSaga)
}

const watchers = [
    fork(onSubmitBilledPrimeWatcher),
    fork(onSubmitMedicalFeesWatcher),
    fork(onSubmitInsuranceCardWatcher),
    fork(onSubmitInsuranceAttestationWatcher),
    fork(onSubmitProvisionalReplacementCertificateWatcher),
    fork(onSubmitStatementDetailsWatcher),
    fork(onSubmitDelegationCertificateWatcher)
]

export default watchers
