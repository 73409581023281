import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { getCurrentYear } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'

export const getSummaryCurrentYear = createSelector(
    [getFamilySummaries],
    (summaries: Record<number, SummaryResult>) => {
        return (
            summaries[getCurrentYear()] ?? {
                insuredPersons: [],
                ecStatus: null,
                receivesElectronicDocument: false
            }
        )
    }
)

export const getSummaryLastYear = createSelector(
    [getFamilySummaries],
    (summaries: Record<number, SummaryResult>) => {
        return (
            summaries[getCurrentYear() - 1] ?? {
                insuredPersons: [],
                ecStatus: null,
                receivesElectronicDocument: false
            }
        )
    }
)

export const getSummaryNextYear = createSelector(
    [getFamilySummaries],
    (summaries: Record<number, SummaryResult>) => {
        return (
            summaries[getCurrentYear() + 1] ?? {
                insuredPersons: [],
                ecStatus: null,
                receivesElectronicDocument: false
            }
        )
    }
)
