import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import FloatingButton from '../FloatingButton/FloatingButton'

const ScrollToTopFloatingButtonComponent = (): JSX.Element => {
    const footerRef = document.getElementById('footer-container')
    const [isFooterVisible, setIsFooterVisible] = useState(false)

    const footerOptions = {
        root: null,
        threshold: 0
    }

    const handleOnClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const footerCallbackFunction: IntersectionObserverCallback = (entries) => {
        const [entry] = entries
        setIsFooterVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(footerCallbackFunction, footerOptions)
        if (footerRef) observer.observe(footerRef)
        return () => observer.disconnect()
    }, [footerRef])

    if (isFooterVisible && footerRef !== null) {
        return createPortal(
            <div className="scroll-to-top-floating-button-footer-container">
                <FloatingButton onClick={handleOnClick} testId="scroll-to-top-floating-button" />
            </div>,
            footerRef
        )
    } else {
        return <FloatingButton onClick={handleOnClick} testId="scroll-to-top-floating-button" />
    }
}

export default ScrollToTopFloatingButtonComponent
