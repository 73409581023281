import './AssuraCarouselArrow.css'

import { CustomArrowProps } from 'react-slick'

interface CarouselArrowProps extends CustomArrowProps {
    direction: 'left' | 'right'
    customClass?: string
    visible: boolean
}

const AssuraCarouselArrow = ({
    direction,
    customClass,
    ...customArrowProps
}: CarouselArrowProps): JSX.Element => {
    const { onClick, visible } = customArrowProps

    return (
        <div style={{ display: visible ? 'block' : 'none' }}>
            <div
                className={customClass}
                data-testid={`assura-carousel-arrow-button-${direction}`}
                onClick={onClick}
            >
                <i
                    className={`icon assura-${direction} size-24 cursor-pointer`}
                    data-testid={`assura-carousel-arrow-button-${direction}-icon`}
                />
            </div>
        </div>
    )
}

export default AssuraCarouselArrow
