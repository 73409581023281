import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AppSearchSection } from 'core/models/AppSearch'
import { getAppSearchSuggestions } from 'shared/store/selectors/getAppSearchSuggestions'

import AppSearchResultItem from './AppSearchResultItem'

interface AppSearchSuggestionsProps {
    selectionCallBack: () => void
}

const AppSearchSuggestions = ({ selectionCallBack }: AppSearchSuggestionsProps): JSX.Element => {
    const { t } = useTranslation()

    const suggestionsSection: AppSearchSection = useSelector(getAppSearchSuggestions)

    return (
        <div className="d-flex flex-column align-items-start">
            <span
                className="labelExtraSmall c-gray500 p-top-8 p-bottom-8"
                data-testid="app-search-suggestion-title"
            >
                {t(suggestionsSection.sectionTitleKey)}
            </span>
            {suggestionsSection.results.length > 0 ? (
                suggestionsSection.results.map((suggestion, index) => (
                    <AppSearchResultItem
                        {...suggestion}
                        index={index}
                        key={`app-search-suggestion-${index}`}
                        section={suggestionsSection.id}
                        callback={selectionCallBack}
                    />
                ))
            ) : (
                <div
                    className="paragraphSmall flex-1 max-2-text-lines"
                    data-testid="app-search-no-suggestion"
                >
                    {t('SEARCH.NO_SUGGESTIONS')}
                </div>
            )}
        </div>
    )
}

export default AppSearchSuggestions
