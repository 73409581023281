import { AssuraDocument } from 'core/models/AssuraDocument'
import { EnveloppeImage } from 'core/models/EnveloppeImage'
import { EnveloppeDocument } from 'core/models/enveloppes/EnveloppeDocument'

export const mapEnveloppeDocuments = (
    enveloppes: EnveloppeDocument[] | undefined,
    documents: Record<string, EnveloppeImage>
): AssuraDocument[] => {
    return ([] as AssuraDocument[]).concat(
        enveloppes && enveloppes?.length > 0
            ? enveloppes.map((document) => {
                  return {
                      type: document.documentType,
                      fileName: document.fileName,
                      base64: documents[document.fileName]?.base64 ?? '',
                      loadStatus: documents[document.fileName]?.loadStatus,
                      backToScanStatus: document.status
                  }
              })
            : []
    )
}
