import './TabDropdown.css'

import { useEffect, useRef, useState } from 'react'

import { AssuraTabData, TabOptions } from 'core/models/TabData'
import useOnClickOutside from 'core/services/useOnClickOutside'
import { getItemById, initialTabState, removeSelectedItemFromList } from 'core/utils/TabUtils'

import { TabDropdownSwitch } from './TabDropdownSwitch'

export type TabDropdownProps = {
    // the inner data structure
    data: AssuraTabData[]
    dataId: string
    // selection item
    select: (id: AssuraTabData['uniqueKey']) => void
    // templating options
    options?: TabOptions
}

export const TabDropdown = ({ data, dataId, select, options }: TabDropdownProps) => {
    const [currentItem, setCurrentItem] = useState<AssuraTabData>(initialTabState)

    const [focus, setFocus] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const currentSelectedItem = data.find((o) => o.active)
        if (!currentSelectedItem) return
        setCurrentItem(currentSelectedItem)
    }, [data.find((o) => o.active)])

    const openStatus = (open: boolean) => {
        setIsOpen(open)
    }

    const handleCloseResults = () => {
        if (isOpen) {
            setIsOpen(false)
        }
    }

    useOnClickOutside(wrapperRef, handleCloseResults)

    const selectItem = (id: AssuraTabData['uniqueKey']): void => {
        const item = getItemById(data, id)
        if (!item) return
        select(item.uniqueKey)
        setCurrentItem(item)
    }

    return (
        <div
            onFocus={() => setFocus(!focus)}
            className={'tab-dropdown'}
            data-testid={`${dataId}-tab-dropdown`}
            ref={wrapperRef}
        >
            <TabDropdownSwitch
                currentSelectedItem={currentItem}
                data={removeSelectedItemFromList(data, currentItem)}
                dataId={dataId}
                select={selectItem}
                isOpen={openStatus}
                opened={isOpen}
                labelToggleTemplate={options?.labelToggleTemplate}
                itemTemplate={options?.itemTemplate}
                valueTemplate={options?.valueTemplate}
                leftTemplate={options?.leftTemplate}
                rightTemplate={options?.rightTemplate}
            />
        </div>
    )
}
