import './AssuraMarkdown.css'

import ReactMarkdown from 'react-markdown'
import { SpecialComponents } from 'react-markdown/lib/ast-to-react'
import { NormalComponents } from 'react-markdown/lib/complex-types'

import useOpenFormOrLink from 'core/services/useOpenFormOrLink'
import remarkGfm from 'remark-gfm'

interface AssuraMarkdownProps {
    message: string
    components?:
        | Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>
        | undefined
    classNames?: string
}

const AssuraMarkdown = ({ message, components, classNames }: AssuraMarkdownProps): JSX.Element => {
    const openFormOrLink = useOpenFormOrLink()
    const extraWrapperClassName = classNames ? ` ${classNames}` : ''
    
    const defaultComponents: Partial<
        Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
    > = {
        a: (props) => {
            const { children, href } = props
            return (
                <span className="assura-link" onClick={() => openFormOrLink(href)}>
                    {children[0]}
                </span>
            )
        }
    }

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                ...defaultComponents,
                ...components
            }}
            children={message}
            className={`assura-markdown${extraWrapperClassName}`}
        />
    )
}

export default AssuraMarkdown
