import { call, fork, takeEvery } from 'redux-saga/effects'

import authService from 'index'

import { logout } from './authentication.slice'

export function* logoutSaga() {
    try {
        yield call([authService, authService.logout])
    } catch (e) {
        console.error('logoutSaga Error', e)
    }
}

function* logoutWatcher() {
    yield takeEvery(logout.type, logoutSaga)
}

const watchers = [fork(logoutWatcher)]

export default watchers
