import { NotificationType } from 'core/enums/Notifications'
import mapCofaToHomeNotification from 'core/helpers/notifications/InvoicesToCheckNotificationsHelper'
import { HomeNotificationsCardState } from 'core/models/HomeNotifications'

import { createSelector } from '@reduxjs/toolkit'

import { getNotifications } from '../notifications/notifications.slice'

export const getInvoicesToCheckNotificationStats = createSelector(
    [getNotifications],
    (getNotifications): HomeNotificationsCardState => {
        const cnt =
            getNotifications?.data?.filter(
                (o) => o.notificationType === NotificationType.INVOICE_TO_CHECK
            )?.length ?? 0

        return {
            loadingStatus: getNotifications.loadingStatus,
            data: cnt > 0 ? mapCofaToHomeNotification(cnt) : undefined
        }
    }
)
