import './WhatsNewPopin.css'

import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import Slider from 'react-slick'

import { WhatsNew, WhatsNewPanel } from 'core/models/WhatsNew'
import concatClassNames from 'core/utils/classNameUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraEcLinkButton from 'shared/components/AssuraEcLinkButton/AssuraEcLinkButton'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { resetSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { getSelectedWhatsNew } from 'shared/store/selectors/getSelectedWhatsNew'
import { useAppDispatch } from 'shared/store/store'

import WhatsNewArrow from './WhatsNewArrow'
import WhatsNewImage from './WhatsNewImage'
import WhatsNewItem from './WhatsNewItem'

const getWhatsNewPopinCta = (whatsNew: WhatsNew, handleCloseAction: () => void): JSX.Element => {
    const { t } = useTranslation()

    if (whatsNew.link_ecl) {
        return (
            <AssuraEcLinkButton
                ecLinkId={whatsNew.link_ecl._id}
                testId="whats-new-pop-in-cta"
                text={t('COMMON.LEARN_MORE')}
                variant="primary"
                callback={handleCloseAction}
            />
        )
    } else {
        return (
            <AssuraButton
                text={t('COMMON.I_UNDERSTOOD')}
                id="whats-new-pop-in-cta"
                variant="primary"
                onClick={handleCloseAction}
            />
        )
    }
}

const WhatsNewPopin = (): JSX.Element | null => {
    const dispatch = useAppDispatch()

    const whatsNew = useSelector(getSelectedWhatsNew)

    if (!whatsNew) return null

    const panels = whatsNew.panels
    const isMultiplePanels = panels.length > 1

    const [currentPanel, setCurrentPanel] = useState<WhatsNewPanel>(panels[0])
    const currentPanelPath = currentPanel.image

    const panelXlColSpecs = {
        span: currentPanelPath ? 6 : 8,
        offset: currentPanelPath ? 0 : 2
    }

    const panelColFullClass = currentPanelPath ? undefined : 'full'
    const panelColClass = concatClassNames(['whats-new-pop-in-panel-col', panelColFullClass])

    const panelContainerMultipleClass = isMultiplePanels ? 'multiple' : undefined
    const panelContainerClass = concatClassNames([
        'whats-new-pop-in-panel-container',
        panelContainerMultipleClass
    ])

    const showCloseButton = Boolean(whatsNew.link_ecl)

    const handleOnBeforeChange = (_currentSlide: number, nextSlide: number) => {
        setCurrentPanel(panels[nextSlide])
    }

    const handleCloseAction = () => {
        dispatch(resetSelectedPopinItem())
    }

    const carouselItems = whatsNew
        ? panels.map((item, index) => (
              <WhatsNewItem
                  date={whatsNew.date}
                  panel={item}
                  ctaComponent={
                      index === panels.length - 1
                          ? getWhatsNewPopinCta(whatsNew, handleCloseAction)
                          : null
                  }
                  isMultiplePanels={isMultiplePanels}
                  key={`whats-new-item-${index}`}
                  handleCloseAction={handleCloseAction}
              />
          ))
        : []

    return (
        <div className="whats-new-pop-in-back-container">
            <RemoveScroll className="container whats-new-pop-in-content">
                <Row className="h-100" data-testid="whats-new-pop-in-content">
                    {currentPanelPath && (
                        <WhatsNewImage path={currentPanelPath} key={currentPanelPath} />
                    )}
                    <Col className={panelColClass} xs={12} xl={panelXlColSpecs}>
                        <div className={panelContainerClass}>
                            <Slider
                                dots={isMultiplePanels}
                                infinite={false}
                                dotsClass="whats-new-dots"
                                autoplay={false}
                                beforeChange={handleOnBeforeChange}
                                arrows={isMultiplePanels}
                                nextArrow={<WhatsNewArrow direction="right" />}
                                prevArrow={<WhatsNewArrow direction="left" />}
                            >
                                {carouselItems}
                            </Slider>
                            {showCloseButton && (
                                <AssuraIconButton
                                    id="whats-new-pop-in-close"
                                    icon="assura-close"
                                    variant="default"
                                    size="medium"
                                    onClick={handleCloseAction}
                                    classNames="whats-new-pop-in-close-button"
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </RemoveScroll>
        </div>
    )
}

export default WhatsNewPopin
