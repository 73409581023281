/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    AccidentDetailsModel,
    IncapacityForWorkModel
} from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import { dateValidation } from 'modules/services/aventura/helpers/validations'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { IconNamesEnum } from 'shared/components/AssuraTabDropdown/Icon'

import {
    badDateSequenceValidation,
    isTrue,
    validateNaN
} from '../../../../core/helpers/FieldValidationHelper'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'INCAPACITY_FOR_WORK'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const incapacityForWorkFormFieldNameList: Array<keyof IncapacityForWorkModel> = [
    'workIncapacity',
    'incapacityStartDate',
    'rateIncapacity',
    'backToWork',
    'incapacityEndDate'
]

export interface IncapacityForWorkFormProps {
    id: number
}

/**
 * Step4 description:
 * INCAPACITY FOR WORK
 *
 */
const IncapacityForWorkForm = ({ id }: IncapacityForWorkFormProps): JSX.Element => {
    const { isMobile, isMD, isSD } = useWindowSize()
    const { getValues, setValue, trigger } = useFormContext<
        IncapacityForWorkModel & AccidentDetailsModel
    >()

    const { TextInputWithRightIcon, RadioGroup, DatePicker } = useCustomControls(formName)

    const { t } = useTranslation()

    const incapacityYes = isTrue(getValues('workIncapacity'), 'workIncapacity')()
    const backToWorkYes = isTrue(getValues('backToWork'), 'backToWork')()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        if (!incapacityYes) {
            setValue('incapacityStartDate', undefined)
            setValue('rateIncapacity', undefined)
            setValue('backToWork', undefined)
            setValue('incapacityEndDate', undefined)
        }
        if (!backToWorkYes) {
            setValue('incapacityEndDate', undefined)
        }
    }, [incapacityYes, backToWorkYes])

    return (
        <div id={`${id}`} className="step-frame">
            {/* //INCAPACITY FOR WORK */}
            <FormBlock
                titleTestId={'incapacity-for-work'}
                title={t(`${serviceName}.${formName}.INCAPACITY_FOR_WORK`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom='m-bottom-32'
            >
                {/* <>accident as-t-il provoqué incapacité de travail oui non RB</> */}
                {RadioGroup(
                    'ACCIDENT_CAUSED_INCAPACITY_FOR_WORK_LABEL',
                    'workIncapacity',
                    {
                        mandatory: true
                    },
                    () => trigger('workIncapacity')
                )}

                {/* <>incapacité de travail DEPUIS calendar POURCENTAGE numerique avec suffixe %</> */}
                {incapacityYes && (
                    <>
                        <Row
                            style={{
                                flexDirection: isMobile || isMD ? 'column' : 'row'
                            }}
                        >
                            <Col
                                xl={4}
                                className={`${isMobile || isMD ? 'm-bottom-24' : ''}`}
                                style={{ justifyContent: isMobile || isMD ? 'center' : 'space-between' }}
                            >
                                <div
                                    className="justify-content-flex-start labelMedium"
                                    data-testid={`services-${formName}-incapacityStart`}
                                >
                                    {t(
                                        `${serviceName}.${formName}.INCAPACITY_FOR_WORK_DETAIL_TITLE`
                                    )}
                                </div>
                            </Col>
                            <Col
                                style={{ padding: 0, display: 'flex', flexDirection: isSD ? 'column' : 'row' }}>
                                <Col
                                    className={` ${isMobile ? 'm-bottom-24' : 'm-bottom-48'
                                        }`}
                                >
                                    {DatePicker(
                                        `incapacityStartDate`,
                                        `INCAPACITY_FOR_WORK_START_DATE`,
                                        {
                                            mandatory: incapacityYes,
                                            validate: (value: string | Date): string | boolean => {
                                                const endDate = getValues('incapacityEndDate') as Date

                                                /** will check against accident Date and end date or only  */
                                                const firstValidationOutput = badDateSequenceValidation(
                                                    getValues('accidentDate') as Date,
                                                    value as Date,
                                                    `DATE_CANNOT_BE_EARLIER_THAN_ACCIDENT_DATE`
                                                )

                                                return firstValidationOutput !== true
                                                    ? firstValidationOutput
                                                    : endDate !== undefined
                                                        ? badDateSequenceValidation(value as Date, endDate)
                                                        : dateValidation(value as string)
                                            }
                                        },
                                        false,
                                        () => trigger('incapacityStartDate')
                                    )}
                                </Col>
                                <Col
                                    className={`${isMobile || isMD ? 'm-bottom-48' : ''}`}
                                >
                                    {TextInputWithRightIcon(
                                        'rateIncapacity',
                                        'INCAPACITED_PERCENTAGE',
                                        `assura-${IconNamesEnum.discount}`,
                                        {
                                            mandatory: incapacityYes,
                                            maxLength: 3,
                                            max: 100,
                                            min: 10,
                                            validate: (value) => validateNaN(value, t)

                                        },
                                        false,
                                        'INCAPACITED_PERCENTAGE'
                                    )}
                                </Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={12}
                                className={backToWorkYes ? 'm-bottom-48' : ''}
                            >
                                {/* <>reprise du travail oui non RB</> */}
                                {RadioGroup(
                                    'RESUME_WORK_TITLE',
                                    'backToWork',
                                    {
                                        mandatory: true
                                    },
                                    () => trigger('backToWork')
                                )}
                            </Col>
                        </Row>
                        {/* <>reprise de travail DEPUIS calendar POURCENTAGE numerique avec suffixe %</> */}
                        {backToWorkYes && (
                            <Row>
                                <Col xs={{ span: 12 }}>
                                    {DatePicker(
                                        `incapacityEndDate`,
                                        `RESUME_WORK_START_DATE`,
                                        {
                                            mandatory: backToWorkYes,

                                            validate: (value: string | Date): string | boolean => {
                                                return badDateSequenceValidation(
                                                    getValues('incapacityStartDate') as Date,
                                                    value as Date
                                                )
                                            }
                                        },
                                        !(isMobile || isMD),
                                        () => trigger('incapacityEndDate')
                                    )}
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}
export default IncapacityForWorkForm
