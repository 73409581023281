import './AssuraTabBadge.css'

import { ItemMetaData } from 'core/models/TabData'

type AssuraTabBadgeProps = {
    id: number
    dataId: string
    data?: ItemMetaData
}

export const AssuraTabBadge = ({ id, dataId, data }: AssuraTabBadgeProps) => {
    return (
        <>
            {data && data.value !== 0 && (
                <span
                    className={`labelExtraSmallMedium m-left-8 c-white assura-tab-item-badge`}
                    data-testid={`${dataId}-tab-badge-${id}`}
                    style={{ backgroundColor: data.backgroundColor }}
                >
                    {data.value}
                </span>
            )}
        </>
    )
}
