import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getContactLanguageSuffix } from 'core/helpers/FamilyGroupHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { GoodToKnowCms } from 'core/models/cms/GoodToKnowCms'
import { GoodToKnow } from 'core/models/services/GoodToKnow'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../../general/general.slice'
import { getGoodToKnowCms } from '../../services/services.slice'
import { getShortContactLanguage } from '../getShortContactLanguage'

export const getGoodToKnow = createSelector(
    [getGoodToKnowCms, getLang, getShortContactLanguage],
    (goodToKnowCms, lang, shortContactLanguage) => {
        const goodToKnow: GoodToKnow[] = []
        const shortContLangGoodToKnowLinkValue = `goodToKnowLinkValue${getContactLanguageSuffix(
            shortContactLanguage
        )}`
        const shortContLangDidYouKnowLinkValue = `didYouKnowLinkValue${getContactLanguageSuffix(
            shortContactLanguage
        )}`
        goodToKnowCms.forEach((item) => {
            if (lang === ENGLISH_LANGUAGE && !item.goodToKnowLinkValue_en) {
                item = {
                    ...item,
                    goodToKnowLinkValue_en: item[
                        shortContLangGoodToKnowLinkValue as keyof GoodToKnowCms
                    ] as string | null
                }
            }
            if (lang === ENGLISH_LANGUAGE && !item.didYouKnowLinkValue_en) {
                item = {
                    ...item,
                    didYouKnowLinkValue_en: item[
                        shortContLangDidYouKnowLinkValue as keyof GoodToKnowCms
                    ] as string | null
                }
            }
            goodToKnow.push(getLocalizedObject(item, lang))
        })

        return goodToKnow
    }
)
