import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getDateStringFromDayMonthYear } from 'core/helpers/DateFormatHelper'
import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { LamalAccidentForm } from 'core/models/services/coverage/LamalAccidentForm'
import { ModelDeductibleForm } from 'core/models/services/coverage/ModelDeductibleForm'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

export const MODEL_DEDUCTIBLE_UNSELECTED_DATE = ''

export interface DataFetchModelDeductibleDates {
    policyNumber: number
}

export interface DataFetchModelDeductibleDatesSuccess {
    modelDeductibleDates: Date[]
}

interface DataFetchModelDeductibles {
    policyNumber: number
    beginDate: string
    simulationDate?: string
}

export interface DataFetchModelDeductiblesSuccess {
    modelDeductibles: ProductFranchise[]
}

interface DataFetchMemberInformation {
    policyNumber: number
    simulationDate?: string
}

export interface DataFetchCurrentModelDeductible {
    currentDeductible: number | null
    currentModel: string | null
}

export interface DataFetchDoctorsData {
    doctors: Doctor[]
}

export interface DataFetchDoctorProductCodes {
    doctorProductCodes: string[]
}

interface CoverageState {
    modelDeductibleDates: Date[]
    modelDeductibles: ProductFranchise[]
    modelDeductiblesWsStatus: HttpResponseEnum | null
    currentDeductible: number | null
    currentModel: string | null
    loadModelDeductibleDatesStatus: LoadingStatusEnum
    loadModelDeductiblesStatus: LoadingStatusEnum
    doctors: Doctor[]
    doctorProductCodes: string[]
    lamalAccidentWantedDate?: string
    beginDate?: string
    currentSelectedModel?: string
}

const INITIAL_STATE_COVERAGE: CoverageState = {
    modelDeductibleDates: [],
    modelDeductibles: [],
    modelDeductiblesWsStatus: null,
    currentDeductible: null,
    currentModel: null,
    loadModelDeductibleDatesStatus: LoadingStatusEnum.LOADING,
    loadModelDeductiblesStatus: LoadingStatusEnum.LOADING,
    doctors: [],
    doctorProductCodes: [],
    lamalAccidentWantedDate: undefined,
    beginDate: undefined,
    currentSelectedModel: undefined
}

export const coverageSlice = createSlice({
    name: 'coverage',
    initialState: INITIAL_STATE_COVERAGE,
    reducers: {
        fetchModelDeductibleDates: (
            state,
            _action: PayloadAction<DataFetchModelDeductibleDates>
        ) => {
            return state
        },
        fetchModelDeductibleDatesSuccess: (
            state,
            action: PayloadAction<DataFetchModelDeductibleDatesSuccess>
        ) => {
            state.modelDeductibleDates = action.payload.modelDeductibleDates
        },

        fetchModelDeductibles: (state, _action: PayloadAction<DataFetchModelDeductibles>) => {
            return state
        },
        fetchModelDeductiblesSuccess: (
            state,
            action: PayloadAction<DataFetchModelDeductiblesSuccess>
        ) => {
            state.modelDeductibles = action.payload.modelDeductibles
        },

        setModelDeductiblesWsStatus: (state, action: PayloadAction<HttpResponseEnum | null>) => {
            state.modelDeductiblesWsStatus = action.payload
        },

        fetchMemberInformation: (state, _action: PayloadAction<DataFetchMemberInformation>) => {
            return state
        },

        setCurrentModelDeductible: (
            state,
            action: PayloadAction<DataFetchCurrentModelDeductible>
        ) => {
            state.currentDeductible = action.payload.currentDeductible
            state.currentModel = action.payload.currentModel
        },

        setDoctors: (state, action: PayloadAction<DataFetchDoctorsData>) => {
            state.doctors = action.payload.doctors
        },
        setDoctorProductCodes: (state, action: PayloadAction<DataFetchDoctorProductCodes>) => {
            state.doctorProductCodes = action.payload.doctorProductCodes
        },

        onSubmitModelDeductible: (state, _action: PayloadAction<ModelDeductibleForm>) => {
            return state
        },

        setModelDeductibleDatesLoaderStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.loadModelDeductibleDatesStatus = action.payload
        },
        setModelDeductiblesLoaderStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.loadModelDeductiblesStatus = action.payload
        },
        setLamalAccidentWantedDate: (state, action: PayloadAction<string | undefined>) => {
            state.lamalAccidentWantedDate = action.payload
        },
        onSubmitLamalAccident: (state, _action: PayloadAction<LamalAccidentForm>) => {
            return state
        },
        setBeginDate: (state, action: PayloadAction<string | undefined>) => {
            state.beginDate = action.payload
        },
        setCurrentSelectedModel: (state, action: PayloadAction<string | undefined>) => {
            state.currentSelectedModel = action.payload
        }
    }
})

export const {
    fetchModelDeductibleDates,
    fetchModelDeductibleDatesSuccess,
    fetchModelDeductibles,
    fetchModelDeductiblesSuccess,
    setModelDeductiblesWsStatus,
    fetchMemberInformation,
    setCurrentModelDeductible,
    setDoctors,
    setDoctorProductCodes,
    onSubmitModelDeductible,
    setModelDeductibleDatesLoaderStatus,
    setModelDeductiblesLoaderStatus,
    setLamalAccidentWantedDate,
    onSubmitLamalAccident,
    setBeginDate,
    setCurrentSelectedModel
} = coverageSlice.actions

export const getModelDeductibleDates: Selector<RootState, Date[]> = (state: RootState) =>
    state.servicesCoverage.modelDeductibleDates

export const getModelDeductibles: Selector<RootState, ProductFranchise[]> = (state: RootState) =>
    state.servicesCoverage.modelDeductibles

export const getModelDeductiblesWsStatus: Selector<RootState, HttpResponseEnum | null> = (
    state: RootState
) => state.servicesCoverage.modelDeductiblesWsStatus

export const getCurrentDeductible: Selector<RootState, number | null> = (state: RootState) =>
    state.servicesCoverage.currentDeductible

export const getCurrentModel: Selector<RootState, string | null> = (state: RootState) =>
    state.servicesCoverage.currentModel

export const getDoctors: Selector<RootState, Doctor[]> = (state: RootState) =>
    state.servicesCoverage.doctors

export const getDoctorProductCodes: Selector<RootState, string[]> = (state: RootState) =>
    state.servicesCoverage.doctorProductCodes

export const getModelDeductibleDatesLoaderStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.servicesCoverage.loadModelDeductibleDatesStatus

export const getModelDeductiblesLoaderStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.servicesCoverage.loadModelDeductiblesStatus

export const getLamalAccidentWantedDate: Selector<RootState, Date | undefined> = (state) =>
    state.servicesCoverage.lamalAccidentWantedDate
        ? new Date(state.servicesCoverage.lamalAccidentWantedDate)
        : undefined

export const getBeginDate: Selector<RootState, Date | undefined> = (state) =>
    state.servicesCoverage.beginDate
        ? new Date(getDateStringFromDayMonthYear(state.servicesCoverage.beginDate))
        : undefined

export const getCurrentSelectedModel: Selector<RootState, string | undefined> = (state) =>
    state.servicesCoverage.currentSelectedModel

export default coverageSlice.reducer
