import './ProductCardPanelBody.css'

import { useTranslation } from 'react-i18next'

import { Product } from 'core/models/Product'

interface ProductCardPanelBodyProps {
    product: Product
}

const ProductCardPanelBody = ({ product }: ProductCardPanelBodyProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="product-card-panel-body flex-1">
            <div className="d-flex justify-content-between align-items-center m-bottom-24">
                <span className="headline">{t('INSURANCES.PRODUCT_CARD_PANEL_ADVANTAGES')}</span>
                {product.iconPath && (
                    <img
                        className="product-card-panel-icon"
                        src={`${process.env.REACT_APP_CMS_ASSET}${product.iconPath}`}
                        alt="product-icon"
                        width="48"
                        height="48"
                    />
                )}
            </div>
            {product.characteristics?.map((characteristic, index) => (
                <div
                    className="d-flex flex-column align-items-start m-bottom-16"
                    key={index.toString()}
                    data-testid={`product-card-panel-characteristics-${product.insuranceId}-${index}`}
                >
                    <div className="d-flex align-items-start">
                        <i className="icon assura-check size-24 c-primary" />
                        <div className="d-flex flex-column justify-content-start align-items-start flex-1 m-left-12">
                            <div
                                data-testid={`product-card-panel-title-${product.insuranceId}-${index}`}
                                className="labelMedium"
                            >
                                {characteristic.title}
                            </div>
                            {characteristic.text && (
                                <span
                                    data-testid={`product-card-panel-text-${product.insuranceId}-${index}`}
                                    className="paragraphLight m-top-4"
                                >
                                    {characteristic.text}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}


export default ProductCardPanelBody
