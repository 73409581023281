import axios from 'axios'
import { UnpaidStats } from 'core/models/UnpaidStats'

export const fetchUnpaidStatsApi = async (): Promise<UnpaidStats> => {
    const result = await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v3/Documents/unpaidstats`
    )

    return result.data
}
