import { call, fork, select, takeEvery } from 'redux-saga/effects'

import { SummaryResult } from 'core/models/familyGroup/SummaryResult'

import { fetchProductsByMemberAndByYearAsync } from '../familySummaries/familySummaries.saga'
import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getProductsYearByPage } from '../selectors/getProductsYearByPage'
import { fetchFamilyMember } from './familyMember.slice'

export function* fetchFamilyMemberAsync(action: ReturnType<typeof fetchFamilyMember>) {
    try {
        const familySummaries: Record<number, SummaryResult> = yield select(getFamilySummaries)
        const selectedYear: number = yield select(getProductsYearByPage)
        const summary = familySummaries[selectedYear]

        if (
            !summary?.insuredPersons[action.payload.selectedIndex]?.productsPerson ||
            action.payload.reload
        ) {
            yield call<typeof fetchProductsByMemberAndByYearAsync>(
                fetchProductsByMemberAndByYearAsync,
                {
                    type: 'familySummaries/fetchProductsByMemberAndByYear',
                    payload: {
                        year: selectedYear,
                        index: action.payload.selectedIndex,
                        reload: action.payload.reload,
                        shouldThrow: true
                    }
                }
            )
        }
    } catch (e) {
        console.error('fetchFamilyMemberAsync Error', e)
    }
}

function* fetchFamilyMemberWatcher() {
    yield takeEvery(fetchFamilyMember.type, fetchFamilyMemberAsync)
}

const watchers = [fork(fetchFamilyMemberWatcher)]

export default watchers
