import './NavBarLinksBlock.css'

import { useTranslation } from 'react-i18next'

import navigationConstants from 'core/constants/navigationConstants'
import useWindowSize from 'core/services/useWindowSize'

import NavBarCalculator from './NavBarCalculator'
import NavBarEnveloppeSubmission from './NavBarEnveloppeSubmission'
import NavBarFaq from './NavBarFaq'
import NavBarLangSelector from './NavBarLangSelector'
import NavBarLink from './NavBarLink'

const NavBarLinksBlock = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    return (
        <>
            <NavBarLink
                id="financials"
                label={t('HOME.NOTIFICATIONS.FINANCES_TITLE')}
                target={navigationConstants.FINANCIALS.url}
            />
            <NavBarLink
                id="communications"
                label={t('NAVIGATION_BAR.COMMUNICATIONS')}
                target={navigationConstants.COMMUNICATIONS.url}
            />
            <NavBarLink
                id="insurances"
                label={t('NAVIGATION_BAR.INSURANCES')}
                target={navigationConstants.INSURANCES.url}
            />
            <NavBarLink
                id="services"
                label={t('NAVIGATION_BAR.SERVICES')}
                target={navigationConstants.SERVICES.url}
                classNames={!isXL ? 'border-bottom-solid-width-1 bbc-gray100' : ''}
            />
            {!isXL && (
                <div className="navbar-links-additional-content">
                    <NavBarEnveloppeSubmission />
                    <NavBarCalculator />
                    <NavBarFaq />
                    <NavBarLangSelector />
                </div>
            )}
        </>
    )
}

export default NavBarLinksBlock
