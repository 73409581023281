import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getContactLanguageSuffix } from 'core/helpers/FamilyGroupHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { ProductCategoryCms } from 'core/models/cms/ProductCategoryCms'
import { ProductCms } from 'core/models/cms/ProductCms'
import { mapProductCmsToProduct } from 'core/utils/ProductUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getProductCategoriesCms, getProductsCms } from '../products/products.slice'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getProducts = createSelector(
    [getProductsCms, getLang, getShortContactLanguage, getProductCategoriesCms],
    (productsCms, lang, shortContactLanguage, productCategoriesCms) => {
        return productsCms?.map((productCms: ProductCms) => {
            const contactLanguageLinkUrl: string | null = `url${getContactLanguageSuffix(
                shortContactLanguage
            )}`
            let correspondingCategory = productCategoriesCms?.find(
                (productCategoryCms: ProductCategoryCms) =>
                    productCategoryCms?._id === productCms?.product_category[0]?._id
            )
            if (
                lang === ENGLISH_LANGUAGE &&
                correspondingCategory &&
                !correspondingCategory.url_en
            ) {
                correspondingCategory = {
                    ...correspondingCategory,
                    url_en: correspondingCategory[
                        contactLanguageLinkUrl as keyof ProductCategoryCms
                    ] as string | null
                }
            }

            if (lang === ENGLISH_LANGUAGE && productCms && !productCms.url_en) {
                productCms = {
                    ...productCms,
                    url_en: productCms[contactLanguageLinkUrl as keyof ProductCms] as string | null
                }
            }

            return mapProductCmsToProduct(
                getLocalizedObject(productCms, lang),
                getLocalizedObject(correspondingCategory, lang)
            )
        })
    }
)
