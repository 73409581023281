import TagManager, { TagManagerArgs } from 'react-gtm-module'

import { DocumentsGroupEnum } from 'core/enums/Documents'
import { buildVersionKey } from 'index'

import analyticsConstants from '../constants/analyticsConstants'
import { InsuranceCombi, PageName, PageType } from '../enums/AnalyticsEnums'
import { ProductTypeEnum } from '../enums/Product'
import { ServicesSource, Source } from '../enums/ServicesSource'
import { ProductPerson } from '../models/familyGroup/ProductPerson'
import { NavigationRoute } from '../models/NavigationRoute'
import { WindowSize } from '../services/useWindowSize'
import { getNavigationRoute } from './NavigationHelper'

const initContainerParams: TagManagerArgs = {
    gtmId: 'GTM-TBCGR6T',
    auth: process.env.REACT_APP_ANALYTICS_AUTH,
    preview: 'env-' + process.env.REACT_APP_ANALYTICS_ENV
}

let pageViewParams: Record<string, string | number | boolean | undefined | null> = {
    [analyticsConstants.PARAMS.APP_LANGUAGE]: undefined,
    [analyticsConstants.PARAMS.IS_LIGHT_AUTH_MODE]: Source() === ServicesSource.WEBSITE,
    [analyticsConstants.PARAMS.IS_BACKOFFICE_USER]: Source() === ServicesSource.BACKOFFICE,
    [analyticsConstants.PARAMS.CHAT_ORIGIN]: undefined,
    [analyticsConstants.PARAMS.CONTACT_LANGUAGE]: undefined,
    [analyticsConstants.PARAMS.INSURANCE_COMBI]: undefined,
    [analyticsConstants.PARAMS.FAMILY_COMPOSITION]: undefined,
    [analyticsConstants.PARAMS.PAGE_NAME]: undefined,
    [analyticsConstants.PARAMS.PAGE_TYPE]: undefined,
    [analyticsConstants.PARAMS.SCREEN_WIDTH]: undefined,
    [analyticsConstants.PARAMS.SCREEN_BREAKPOINT]: undefined,
    [analyticsConstants.PARAMS.ZOOM_LEVEL]: undefined,
    [analyticsConstants.PARAMS.DEFAULT_FONTSIZE]: undefined,
    [analyticsConstants.PARAMS.MINIMUM_FONTSIZE]: undefined,
    [analyticsConstants.PARAMS.EC_VERSION]: undefined
}

export const initializeAnalytics = (): void => {
    TagManager.initialize(initContainerParams)
    prepareEcVersionInfo()
    prepareFontSizesInfos()
}

const prepareEcVersionInfo = (): void => {
    updatePageViewParams({
        [analyticsConstants.PARAMS.EC_VERSION]:
            localStorage.getItem(buildVersionKey) ?? 'No version detected in localstorage'
    })
}
const prepareFontSizesInfos = (): void => {
    const element = document.createElement('div')
    element.style.width = '1rem' //By default, 1 rem = 16px for most default settings. However, this can be changed by the user. This is why we need to use getComputedStyle after to determine the final value for default fontSize.
    element.style.fontSize = '2px' //If the user has changed the minimum font size, this will be reflected in the computed fontSize. Doesn't work in Firefox
    element.style.display = 'none'
    document.body.append(element)
    const { fontSize, width } = window.getComputedStyle(element)
    updatePageViewParams({
        [analyticsConstants.PARAMS.DEFAULT_FONTSIZE]: width,
        [analyticsConstants.PARAMS.MINIMUM_FONTSIZE]: fontSize
    })

    element.remove()
}
export const prepareInsuranceCombiInfos = (products?: ProductPerson[]): void => {
    let insuranceCombi = InsuranceCombi.NONE
    const hasLamal = products?.some((product) => product.type === ProductTypeEnum.LAMAL)
    const hasLca = products?.some((product) => product.type === ProductTypeEnum.LCA)
    if (hasLamal && hasLca) {
        insuranceCombi = InsuranceCombi.LAMAL_LCA
    } else if (hasLamal) {
        insuranceCombi = InsuranceCombi.LAMAL
    } else if (hasLca) {
        insuranceCombi = InsuranceCombi.LCA
    }

    updatePageViewParams({
        [analyticsConstants.PARAMS.INSURANCE_COMBI]: insuranceCombi
    })
}

export const preparePageTypeNameInfos = (route: string): void => {
    const navigationRoute: NavigationRoute = getNavigationRoute(route) || {
        url: '/404',
        pageName: PageName.NOT_FOUND,
        pageType: PageType.ERROR_PAGE
    }

    if (navigationRoute.pageType !== PageType.DOCUMENTS_PAGE)
        sendPageView(navigationRoute.pageName, navigationRoute.pageType)
}

export const sendPageView = (pageName: PageName, pageType: PageType): void => {
    updatePageViewParams({
        [analyticsConstants.PARAMS.PAGE_NAME]: pageName,
        [analyticsConstants.PARAMS.PAGE_TYPE]: pageType
    })
    sendEvent(analyticsConstants.EVENTS.PAGE_VIEW, pageViewParams)
}

export const prepareScreenInfos = (newSizes: WindowSize): void => {
    const { width, outerWidth, isXSD, isSD, isXS, isMD, isXL } = newSizes
    if (width !== 0) {
        let breakpoint: string | undefined = undefined
        switch (true) {
            case isXSD:
                breakpoint = 'XSD'
                break
            case isSD:
                breakpoint = 'SD'
                break
            case isXS:
                breakpoint = 'XS'
                break
            case isMD:
                breakpoint = 'MD'
                break
            case isXL:
                breakpoint = 'XL'
                break
        }

        // ZoomLevel algorithm doesn't work for Firefox
        const zoomLevel = Math.round((outerWidth / width) * 100)
        updatePageViewParams({
            [analyticsConstants.PARAMS.SCREEN_WIDTH]: width,
            [analyticsConstants.PARAMS.SCREEN_BREAKPOINT]: breakpoint,
            [analyticsConstants.PARAMS.ZOOM_LEVEL]: zoomLevel
        })
    }
}

export const updatePageViewParams = (
    params: Record<string, string | number | boolean | undefined | null>
): void => {
    pageViewParams = {
        ...pageViewParams,
        ...params
    }
}

export const sendEvent = (
    event: string,
    params?: Record<string, string | number | boolean | undefined | null>
): void => {
    TagManager.dataLayer({
        dataLayer: {
            event,
            ...params,
            ...pageViewParams
        }
    })
}

export const sendDocPageView = (page: string): void => {
    const pageName = (function () {
        switch (page) {
            case DocumentsGroupEnum.INVOICES:
                return PageName.FINANCIALS_INVOICES
            case DocumentsGroupEnum.REFUNDS:
                return PageName.FINANCIALS_REFUNDS
            case 'enveloppes':
                return PageName.FINANCIALS_ENVELOPPES
            case DocumentsGroupEnum.CORRESPONDENCE:
                return PageName.COMMUNICATIONS_CORRESPONDENCE
            case DocumentsGroupEnum.CONTRACTUAL:
                return PageName.COMMUNICATIONS_CONTRACTUAL
            default:
                return undefined
        }
    })()

    if (pageName) sendPageView(pageName, PageType.DOCUMENTS_PAGE)
}
