import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RequestSource } from 'core/enums/RequestSource'
import { RequestStatus } from 'core/enums/RequestStatus'
import { DateFilterSelectionValue } from 'core/helpers/CommonAssuraTableFilters'
import { RequestRow } from 'core/models/services/RequestData'
import { TableFilterState } from 'core/models/TableFilter'
import AssuraTable from 'shared/components/AssuraTable/AssuraTable'
import { getRequestsForTable } from 'shared/store/selectors/getRequestsForTable'
import {
    fetchRequestDetails,
    getRequests,
    getServicesRequestsLoadingStatus,
    setSelectedRequestId
} from 'shared/store/services/requests/requests.slice'
import { useAppDispatch } from 'shared/store/store'

import getRequestsFilterState from './RequestsTableFilters'
import { getFilteredRequests } from './RequestsTableHelper'
import RequestsTableRow from './RequestsTableRow'

export type RequestsFilterSelectionValue = number | RequestStatus | DateFilterSelectionValue

interface RequestsTableProps {
    setSelectedRequest: (row: RequestRow) => void
}

const RequestsTable = ({ setSelectedRequest }: RequestsTableProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const initialFilters = getRequestsFilterState()

    const [filters, setFilters] = useState<TableFilterState<RequestsFilterSelectionValue>>({
        ...initialFilters
    })

    const allRequests = useSelector(getRequestsForTable)
    const requestsLoadingStatus = useSelector(getServicesRequestsLoadingStatus)

    const paginationIncrement = 10
    const [pagination, setPagination] = useState(paginationIncrement)

    const [requests, setRequests] = useState<RequestRow[]>([])
    const paginatedRequests = requests.slice(0, pagination)

    const onFilterSelection = (newFilterState: TableFilterState<RequestsFilterSelectionValue>) => {
        setFilters({ ...newFilterState })
    }

    const onRowSelection = (row: RequestRow) => {
        if (
            row.source !== RequestSource.MIGRATION ||
            process.env.REACT_APP_SHOW_MIGRATED_DATA?.toString() === 'TRUE'
        ) {
            setSelectedRequest(row)
            dispatch(setSelectedRequestId(row.id))
            dispatch(fetchRequestDetails({ id: row.id, policyNumber: row.policyNumber }))
        }
    }

    const onMoreSelection = () => {
        setPagination((prev) => (prev += paginationIncrement))
    }

    const handleRowClasses = (row: RequestRow): string => {
        return row.shouldHideData ? 'requests-table-row-migration' : ''
    }

    useEffect(() => {
        const newRequests = getFilteredRequests(allRequests, filters)
        setRequests([...newRequests])
    }, [allRequests, pagination, filters])

    return (
        <Container className="flex-1 d-flex flex-column">
            <Row className="flex-1">
                <Col xs={12} className="d-flex">
                    <AssuraTable
                        id="requests"
                        rows={paginatedRequests}
                        rowComponent={(row) => <RequestsTableRow row={row} />}
                        filters={filters}
                        loadStatus={requestsLoadingStatus}
                        paginationStatus={requestsLoadingStatus}
                        filteringStatus={requestsLoadingStatus}
                        onReload={() => dispatch(getRequests())}
                        onFilterChange={onFilterSelection}
                        onRowSelection={onRowSelection}
                        noResultMessages={{
                            datas: { label: t('SERVICES.NO_REQUESTS') },
                            filters: { label: t('SERVICES.REQUESTS_FILTER_NO_RESULT') }
                        }}
                        hasMore={requests.length > pagination}
                        onMoreSelection={onMoreSelection}
                        extraRowClasses={handleRowClasses}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default RequestsTable
