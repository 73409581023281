import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { assetsCmsData } from 'core/api/Cms'
import { AssetsCmsDocument } from 'core/models/cms/AssetCms'

import { fetchAssets, setAssets } from './assets.slice'

export function* fetchAssetsSaga() {
    try {
        const cmsAssets: AssetsCmsDocument[] = yield call(assetsCmsData)

        yield put(setAssets(cmsAssets))
    } catch (e) {
        console.error('onfetchAssetsSaga Error', e)
    }
}

function* fetchAssetsWatcher() {
    yield takeLatest(fetchAssets.type, fetchAssetsSaga)
}

const watchers = [fork(fetchAssetsWatcher)]

export default watchers
