import './AssuraPopin.css'

import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'

import { AssuraPopinProps } from 'core/models/Popin'
import { resetSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { useAppDispatch } from 'shared/store/store'

import AssuraButton from '../AssuraButton/AssuraButton'
import AssuraIconButton from '../AssuraIconButton/AssuraIconButton'

interface PopinProps {
    popinProps: AssuraPopinProps
}

const AssuraPopin = ({ popinProps }: PopinProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const {
        showCloseButton = true,
        title,
        message,
        messageJustify = false,
        messageComplement,
        cancelButtonLabel,
        validateButtonLabel,
        cancelAction,
        validatedAction
    } = popinProps

    const handleCloseAction = () => dispatch(resetSelectedPopinItem())

    const handleCancel = () => {
        handleCloseAction()
        if (cancelAction) cancelAction()
    }

    const handleValidation = () => {
        handleCloseAction()
        validatedAction()
    }

    const cancelBtnLabel = cancelButtonLabel ? cancelButtonLabel : t('COMMON.CANCEL')

    return (
        <RemoveScroll className="assura-pop-in-container">
            {showCloseButton && (
                <AssuraIconButton
                    id="assura-pop-in-close"
                    icon="assura-close"
                    variant="default"
                    size="medium"
                    onClick={handleCancel}
                    classNames="assura-pop-in-close-button"
                />
            )}
            <div className="assura-pop-in-content" data-testid="assura-pop-in-content">
                <span className="headlineSmallMedium" data-testid="assura-pop-in-title">
                    {title}
                </span>
                <span
                    className={`paragraphSmallLight m-top-16 white-space-pre-line${
                        messageJustify ? ' justify' : ''
                    }`}
                    data-testid="assura-pop-in-message"
                >
                    {message}
                </span>
                {messageComplement ?? <>{messageComplement}</>}
                <div className="assura-pop-in-cta-container">
                    <AssuraButton
                        text={cancelBtnLabel}
                        id="assura-pop-in-cancel"
                        variant="secondary"
                        onClick={handleCancel}
                    />
                    <AssuraButton
                        text={validateButtonLabel}
                        id="assura-pop-in-validate"
                        variant="primary"
                        onClick={handleValidation}
                        classNames="assura-pop-in-cta-validation"
                    />
                </div>
            </div>
        </RemoveScroll>
    )
}

export default AssuraPopin
