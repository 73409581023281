import { useTranslation } from 'react-i18next'

import { translateMaritalStatus } from 'core/helpers/FamilyGroupHelper'
import { RequestDetailsValues, RequestDetailsValuesView } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface PersonalDataBasicProps {
    values: RequestDetailsValues[]
    gender: string
}

const PersonalDataBasic = ({ values, gender }: PersonalDataBasicProps): JSX.Element => {
    const { t } = useTranslation()

    const activeIdsSyrius = ['LastName', 'FirstName', 'CivilState']
    const activeFields: RequestDetailsValuesView[] = []

    activeIdsSyrius.forEach((id) => {
        const item = values.find((v) => v.id === id)
        if (item && item.new !== item.old)
            activeFields.push({
                testId: (function () {
                    switch (id) {
                        case activeIdsSyrius[0]:
                            return 'last-name'
                        case activeIdsSyrius[1]:
                            return 'first-name'
                        case activeIdsSyrius[2]:
                            return 'marital-status'
                        default:
                            return ''
                    }
                })(),
                label: (function () {
                    switch (id) {
                        case activeIdsSyrius[0]:
                            return t('FAMILY_GROUP.BASIC_INFO_EDIT_LAST_NAME_LABEL')
                        case activeIdsSyrius[1]:
                            return t('FAMILY_GROUP.BASIC_INFO_EDIT_FIRST_NAME_LABEL')
                        case activeIdsSyrius[2]:
                            return t('FAMILY_GROUP.BASIC_INFO_EDIT_MARITAL_STATUS_LABEL')
                        default:
                            return ''
                    }
                })(),
                old:
                    id === activeIdsSyrius[2]
                        ? t(translateMaritalStatus(item.old as string, gender))
                        : item.old,
                new:
                    id === activeIdsSyrius[2]
                        ? t(translateMaritalStatus(item.new as string, gender))
                        : item.new
            })
    })

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={`${field.label}-old`}>
                        <div>{field.label}</div>
                        <div
                            data-testid={`requests-details-personal-data-basic-${field.testId}-old`}
                        >
                            {field.old as string}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')} />
            <div className="requests-panel-main-block">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={`${field.label}-new`}>
                        <div>{field.label}</div>
                        <div
                            data-testid={`requests-details-personal-data-basic-${field.testId}-new`}
                        >
                            {field.new as string}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
        </div>
    )
}

export default PersonalDataBasic
