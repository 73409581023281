import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { FetchDocumentsTypeEnum } from 'core/enums/Documents'
import {
    fetchRefundsData,
    getRefundsData
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'
import { getSortedRefundsForTable } from 'shared/store/selectors/getSortedRefundsForTable'

import HomeBlock from '../HomeBlock/HomeBlock'
import NoLastDocuments from '../NoLastDocuments/NoLastDocuments'
import LastRefundsRow from './LastRefundsRow'

const LastRefunds = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const refundsData = useSelector(getRefundsData)
    const { initLoadingStatus } = refundsData

    const sortedRefunds = useSelector(getSortedRefundsForTable)
    const hasRefunds = sortedRefunds.length > 0

    const selectedMember = useSelector(getSelectedFamilyMember)

    let url = `${navigationConstants.FINANCIALS.url}?item=refunds`
    if (selectedMember?.userTiersNumber) {
        url = `${url}&utn=${selectedMember.userTiersNumber}`
    }

    const fetchRefunds = () => {
        dispatch(
            fetchRefundsData({
                type: FetchDocumentsTypeEnum.INIT,
                paginationDate: null,
                docsByPage: 3,
                filters: {
                    utn: selectedMember?.userTiersNumber ?? null,
                    date: null
                }
            })
        )
    }

    return (
        <HomeBlock
            title={t('HOME.SETTLEMENTS_LAST_TITLE')}
            testId="refunds"
            loadingStatus={initLoadingStatus}
            reloadAction={fetchRefunds}
            headerAction={hasRefunds ? () => navigate(url) : undefined}
        >
            {hasRefunds ? (
                <>
                    {sortedRefunds.slice(0, 3).map((row) => (
                        <LastRefundsRow row={row} url={url} key={row.documentId} />
                    ))}
                </>
            ) : (
                <NoLastDocuments testId="refunds" message={t('HOME.SETTLEMENTS_NO_LAST_MESSAGE')} />
            )}
        </HomeBlock>
    )
}

export default LastRefunds
