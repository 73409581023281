import './Aventura.css'

import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import navigationConstants from 'core/constants/navigationConstants'
import { ServiceType } from 'core/enums/ServiceType'
import { useFormRefresh } from 'core/services/useFormRefresh'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AventuraFormProvider from '../components/Form/AventuraProvider'

// this is the main Aventura Page
const Aventura = (): JSX.Element => {
    const navigate = useNavigate()
    useFormRefresh(ServiceType.AVENTURA_INSURANCE_REQUEST)

    useEffect(() => {
        if (process.env.REACT_APP_SHOW_AVENTURA?.toString() === 'FALSE') {
            navigate(`${navigationConstants.SERVICES.url}`)
        }
    }, [])

    return (
        <FullScreenContainer
            color="gray20"
            complementaryClasses="container-load-error position-relative"
        >
            <Container>
                <AventuraFormProvider />
            </Container>
        </FullScreenContainer>
    )
}

export default withAITracking(reactPlugin, Aventura, 'Aventura', 'av-container')
