import './PaymentDocumentPanelHeaderCenter.css'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'

interface PaymentDocumentPanelHeaderCenterProps {
    selectedDocument: DocumentRow
}

const PaymentDocumentPanelHeaderCenter = ({
    selectedDocument
}: PaymentDocumentPanelHeaderCenterProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()

    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <div
                    data-testid="payment-document-panel-document-name"
                    className={`justify-content-center width-inherit d-flex ${
                        isXSD ? 'labelSmallMedium' : 'labelMedium'
                    }`}
                >
                    <span className="assura-panel-center-block-ellipsis">
                        {selectedDocument.documentName}
                    </span>
                </div>
            </div>
            <div className="payment-document-panel-center-second-line">
                {!isXSD && (
                    <>
                        <span
                            className="labelSmall c-gray500"
                            data-testid={`payment-document-panel-creation-date`}
                        >
                            {selectedDocument.creationDate
                                ? formatDate(selectedDocument.creationDate)
                                : ''}
                        </span>
                        {!selectedDocument.isMigrated && ( // TODO: For migrated documents
                            <div className="payment-document-panel-field-separator bg-gray300" />
                        )}
                    </>
                )}
                {!selectedDocument.isMigrated &&
                    selectedDocument.financialStatusData && ( // TODO: For migrated documents
                        <>
                            <i
                                className={`icon ${selectedDocument.financialStatusData.icon} ${selectedDocument.financialStatusData.color} size-16`}
                            />
                            <span
                                className={`${
                                    isXSD ? 'labelExtraSmall' : 'labelSmall'
                                } payment-document-status-field text-ellipsis c-gray500`}
                                data-testid={`payment-document-panel-status`}
                            >
                                {t(selectedDocument.financialStatusData.translationKey ?? '')}
                            </span>
                        </>
                    )}
            </div>
        </>
    )
}

export default PaymentDocumentPanelHeaderCenter
