import './DocumentsCardLink.css'

import { useTranslation } from 'react-i18next'

import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'

interface DocumentsCardLinkProps {
    labelKey: string
    testId: string
    onClick: () => void
    isLoading: boolean
}

const DocumentsCardLink = ({
    labelKey,
    testId,
    onClick,
    isLoading
}: DocumentsCardLinkProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="lamal-documents-card-link-container"
            data-testid={`lamal-documents-card-link-${testId}`}
            onClick={onClick}
        >
            <span
                className="labelSmall m-right-8 flex-1"
                data-testid={`lamal-documents-card-link-${testId}-label`}
            >
                {t(labelKey)}
            </span>
            <div className="m-left-8">
                {isLoading ? (
                    <ActivityIndicator classNames="lamal-documents-card-link-loader" />
                ) : (
                    <i className="icon assura-pdf size-24" />
                )}
            </div>
        </div>
    )
}

export default DocumentsCardLink
