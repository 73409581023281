import { PaymentStatusEnum } from 'core/enums/Documents'
import { getDateWithNoTime } from 'core/helpers/DateFormatHelper'
import { mapDocumentsToRows } from 'core/helpers/documents/DocumentsHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
/* eslint-disable sonarjs/cognitive-complexity */
import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getInvoicesDocuments } from '../financialDocuments/financialDocuments.slice'
import { getPersonDataForDocuments } from './getPersonDataForDocuments'

export const getSortedInvoicesForTable = createSelector(
    [getInvoicesDocuments, getPersonDataForDocuments],
    (invoices, personData): DocumentRow[] => {
        const documents = mapDocumentsToRows(invoices, personData)

        /*We want to sort the statements according to several criteria:
        1. The most recent statement (creationDate) but we consider only the year, month and day
        2. The statement that still needs to be paid and shortly (deadlineDate)
        3. By alphabetic order of associated family member (insuredPersonName)
        */

        return documents.sort((a, b) => {
            const aCreationDateNoTime = getDateWithNoTime(a.creationDate)
            const bCreationDateNoTime = getDateWithNoTime(b.creationDate)

            if (isBefore(bCreationDateNoTime, aCreationDateNoTime)) {
                return -1
            } else if (isBefore(aCreationDateNoTime, bCreationDateNoTime)) {
                return 1
            } else {
                const aHasToBePaid =
                    a.financialStatusData?.translationKey === PaymentStatusEnum.UNPAID
                const bHasToBePaid =
                    b.financialStatusData?.translationKey === PaymentStatusEnum.UNPAID
                if (
                    (aHasToBePaid && !bHasToBePaid) ||
                    (a.deadLineDate && !b.deadLineDate) ||
                    (aHasToBePaid &&
                        bHasToBePaid &&
                        a.deadLineDate &&
                        b.deadLineDate &&
                        isBefore(new Date(a.deadLineDate), new Date(b.deadLineDate)))
                ) {
                    return -1
                } else if (
                    (!aHasToBePaid && bHasToBePaid) ||
                    (!a.deadLineDate && b.deadLineDate) ||
                    (aHasToBePaid &&
                        bHasToBePaid &&
                        a.deadLineDate &&
                        b.deadLineDate &&
                        isBefore(new Date(b.deadLineDate), new Date(a.deadLineDate)))
                ) {
                    return 1
                } else {
                    return a.insuredPersonName.localeCompare(b.insuredPersonName)
                }
            }
        })
    }
)
