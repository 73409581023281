import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { SymptomCheckerSplicer } from 'core/helpers/SymptomCheckerSplicer'
import { FrequentRequest } from 'core/models/FrequentRequest'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getFilteredFrequentRequests } from './getFilteredFrequentRequests'

export const getFrequentRequests = createSelector(
    [getFilteredFrequentRequests, getDigcliparameters, getLang],
    (filteredRequests, digcliParameters, lang) => {
        const frequent = filteredRequests.map((filteredReq) => {
            const localizedReq = getLocalizedObject(filteredReq, lang)
            return localizedReq as FrequentRequest
        })

        SymptomCheckerSplicer(digcliParameters?.isSymptomCheckerCustomerPortalActivated, frequent)

        return frequent
    }
)
