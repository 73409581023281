import './LamalRules.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import analyticsConstants from 'core/constants/analyticsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { Source } from 'core/enums/ServicesSource'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { ProductCms } from 'core/models/cms/ProductCms'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import useQuery from 'core/services/useQuery'
import FamilyGroup, { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { fetchProductsByMemberAndByYear } from 'shared/store/familySummaries/familySummaries.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getHowItWorksData } from 'shared/store/selectors/getHowItWorksData'
import { setFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

const LAMAL_TYPE = 'laMal'

const LamalRules = (): JSX.Element => {
    const query = useQuery()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const lang = useSelector(getLang)
    const howItWorksData = useSelector(getHowItWorksData)

    const [showFamilyMemberSelector, setShowFamilyMemberSelector] = useState(false)
    const [productsLoader, setProductsLoader] = useState(LoadingStatusEnum.LOADING)
    const [ignoreFirstPolicy, setIgnoreFirstPolicy] = useState(true)
    const [productUrl, setProductUrl] = useState('')

    const productInReadOnly = query.get('product')

    useEffect(() => {
        if (productInReadOnly) {
            setLamalData()
            setProductsLoader(LoadingStatusEnum.OK)
            return
        }

        // if the selected year is not the current one,
        // not all products data are already in the store
        loadProductsOfSelectedYearForAllMembers(false)
    }, [productInReadOnly])

    useEffect(() => {
        if (productInReadOnly) {
            return
        }

        let loaderStatusValue = LoadingStatusEnum.LOADING

        const errors = howItWorksData.summary.insuredPersons.filter(
            (ps) => ps.productsPerson?.loadingStatus === LoadingStatusEnum.ERROR
        )
        if (errors.length > 0) {
            loaderStatusValue = LoadingStatusEnum.ERROR
        }

        const foundOk = howItWorksData.summary.insuredPersons.filter(
            (ps) => ps.productsPerson?.loadingStatus === LoadingStatusEnum.OK
        )
        if (foundOk.length === howItWorksData.summary.insuredPersons.length) {
            loaderStatusValue = LoadingStatusEnum.OK
            setShowFamilyMemberSelector(familySelectorVisible())
        }

        setProductsLoader(loaderStatusValue)
    }, [howItWorksData.summary.insuredPersons])

    useEffect(() => {
        if (productInReadOnly) {
            return
        }

        // if not all products loaded yet, stop here
        if (productsLoader !== LoadingStatusEnum.OK) return

        // when the family group selector is displayed for the first time,
        // this useEffect is called with first family member of the group
        // we don't want this because two events will be sent to analytics
        const shouldNotContinue =
            howItWorksData.initialPolicyNumber !== 0 &&
            howItWorksData.initialPolicyNumber !== howItWorksData.selectedMemberPolicyNumber &&
            showFamilyMemberSelector &&
            ignoreFirstPolicy
        setIgnoreFirstPolicy(false)
        if (shouldNotContinue) return

        const personSummary = howItWorksData.summary.insuredPersons.find(
            (ps) => ps.policyNumber == howItWorksData.selectedMemberPolicyNumber
        )
        setLamalData(personSummary as PersonSummary)
    }, [howItWorksData.selectedMemberPolicyNumber, productsLoader])

    const loadProductsOfSelectedYearForAllMembers = (reload: boolean) => {
        howItWorksData.summary.insuredPersons.map((_item, index) =>
            dispatch(
                fetchProductsByMemberAndByYear({
                    year: howItWorksData.yearSelected,
                    index,
                    reload
                })
            )
        )
    }

    const familySelectorVisible = (): boolean => {
        if (productInReadOnly) {
            return false
        }

        if (howItWorksData.summary.insuredPersons.length === 1) return false

        // if some members have LAMal and others don't, show family selector
        const membersWithLAMal = howItWorksData.summary.insuredPersons.filter((ps) =>
            ps.productsPerson?.products.find((pp) => pp.type === LAMAL_TYPE)
        )
        if (howItWorksData.summary.insuredPersons.length !== membersWithLAMal.length) return true

        // if all family members have a LAMal, but not same model
        const firstMemberLamalProductCode = membersWithLAMal[0].productsPerson?.products.find(
            (pp) => pp.type === LAMAL_TYPE
        )?.code
        for (let i = 1; i < membersWithLAMal.length; i++) {
            if (
                membersWithLAMal[i].productsPerson?.products.find((pp) => pp.type === LAMAL_TYPE)
                    ?.code !== firstMemberLamalProductCode
            ) {
                return true
            }
        }

        // if all family members have a LAMal with the same model
        return false
    }

    const setLamalData = (personSummary?: PersonSummary) => {
        let productTitle = 'NO_LAMAL'
        let productUrl = `${process.env.REACT_APP_PUBLIC?.toString()}${t('HOW_IT_WORKS.NO_LAMAL_URL')}`

        let lamalProductCode: string | undefined

        if (productInReadOnly) {
            lamalProductCode = productInReadOnly
        } else {
            lamalProductCode = personSummary
            ? personSummary.productsPerson?.products?.find((pp) => pp.type === LAMAL_TYPE)?.code
            : undefined
        }

        if (lamalProductCode) {
            const productCms = getLocalizedObject(
                howItWorksData.productCms.find((pc) => pc.insurance_id === lamalProductCode),
                lang
            ) as ProductCms
            productTitle = productCms.title
            productUrl = `${process.env.REACT_APP_PUBLIC?.toString()}${productCms.info_lamal_public}`
        }
        setProductUrl(productUrl)
        sendEvent(analyticsConstants.EVENTS.DISPLAY_CCM, {
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: productTitle,
            [analyticsConstants.PARAMS.CCM_URI]: productUrl
        })
    }

    const selectMember = (family: FamilyGroupResult) => {
        dispatch(setFamilyGroupResult(family))
    }

    return (
        <FullScreenContainer
            color="gray20"
            complementaryClasses="d-flex flex-column position-relative"
        >
            <AssuraLoadAndError
                status={productsLoader}
                defaultReloadAction={() => loadProductsOfSelectedYearForAllMembers(true)}
            >
                {showFamilyMemberSelector && (
                    <div style={{ minHeight: '187px' }}>
                        <FamilyGroup
                            hasDefaultSelectedMember={true}
                            selectAction={selectMember}
                            defaultSelectedPolicyNumber={howItWorksData.initialPolicyNumber}
                            source={Source()}
                        />
                    </div>
                )}
                <iframe
                    key={productUrl}
                    title="LAMal_iframe"
                    src={productUrl}
                    className="d-flex flex-1"
                    style={{ border: 0 }}
                />
            </AssuraLoadAndError>
        </FullScreenContainer>
    )
}

export default withAITracking(reactPlugin, LamalRules, 'LamalRules', 'lamal-rules-container')
