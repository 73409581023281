import './PaymentChangeOwner.css'

import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormSelectRHF } from 'modules/services/components/SelectRHF/SelectRHF'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { PersonSettings } from 'shared/store/selectors/getFamilyGroupSettings'

interface PaymentChangeOwnerProps {
    familyGroup: PersonSettings[]
}
const PaymentChangeOwner = ({ familyGroup }: PaymentChangeOwnerProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    const familyGroupMap = new Map() as SelectMap<number>
    familyGroup.forEach((member) => {
        const { firstName, lastName, policyNumber } = member
        familyGroupMap.set(`${firstName} ${lastName}`, policyNumber)
    })

    return (
        <div
            className={`m-bottom-48${
                familyGroup.length < 2 ? ' payment-change-owner-container' : ''
            }`}
        >
            <AssuraFormSelectRHF
                name="accountOwnerPolicyNumber"
                id="payment-change-owner"
                label={t('PERSONAL_DATA.OWNER')}
                items={familyGroupMap}
                inline={isXL}
                labelSize={4}
                disabled={familyGroup.length < 2}
            />
        </div>
    )
}

export default PaymentChangeOwner
