import './PrimesPanelMemberInfo.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PeriodicityTrads from 'core/constants/productPeriodicityTraductions'
import { ServiceType } from 'core/enums/ServiceType'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getProductPeriodicity } from 'shared/store/selectors/getProductPeriodicity'
import { getSelectedFamilyMember } from 'shared/store/selectors/getSelectedFamilyMember'
import { goToServicesForm } from 'shared/store/services/services.slice'

const PrimesPanelMemberInfo = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const selectedMember = useSelector(getSelectedFamilyMember)
    const periodicity = useSelector(getProductPeriodicity)

    return (
        <div className="d-flex flex-column">
            <div className="headlineSmallMedium" data-testid="primes-panel-member-info-name">
                {selectedMember.firstName} {selectedMember.lastName}
            </div>
            {periodicity && (
                <div className="primes-panel-member-info-periodicity-container">
                    <div
                        className="paragraphLight"
                        data-testid="primes-panel-member-info-periodicity"
                    >{`${t('HOME.PREMIUMS_PERIODICITY')} : ${t(
                        PeriodicityTrads[periodicity]
                    )}`}</div>
                    <AssuraIconButton
                        id="primes-change-periodicity"
                        icon="assura-edit"
                        variant="primary"
                        size="small"
                        onClick={() =>
                            dispatch(
                                goToServicesForm({ type: ServiceType.PAYMENT_FREQ_MODIFICATION })
                            )
                        }
                        classNames="m-left-8"
                    />
                </div>
            )}
        </div>
    )
}

export default PrimesPanelMemberInfo
