import './TextAreaRHF.css'

import { FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

import withCharacterCountAndError from 'shared/hoc/WithCharacterCountAndError'
import { withLabel } from 'shared/hoc/WithLabel'

interface TextareaProps {
    id: string
    name: string
    isInvalid: boolean
    disabled?: boolean
    readOnly?: boolean
    register: UseFormRegister<FieldValues>
    rules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    placeholder?: string
    maxLength?: number
    onBlur?: (value: string) => void
    onChange?: (value: string) => void
    onFocus?: () => void
}

const TextAreaRHF = ({ ...props }: TextareaProps): JSX.Element => {
    const handleOnFocus = () => props.onFocus && props.onFocus()

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange && props.onChange(e.currentTarget.value ?? '')
        props.register(props.name, { ...props.rules }).onChange(e)
    }

    const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        props.onBlur && props.onBlur(e.currentTarget.value ?? '')
        props.register(props.name, { ...props.rules }).onBlur(e)
    }

    return (
        <textarea
            id={props.id}
            data-testid={props.id}
            className={`textarea-rhf m-top-24 paragraph form-control${
                props.isInvalid ? ' is-invalid' : ''
            }`}
            disabled={props.disabled}
            readOnly={props.readOnly}
            {...props.register(props.name, { ...props.rules })}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onChange={handleOnChange}
            placeholder={props.placeholder}
        />
    )
}

export default TextAreaRHF

export const AssuraFormTextAreaRHF = withLabel(withCharacterCountAndError(TextAreaRHF))
