import axios from 'axios'
import { AddressRequestBody } from 'core/models/services/personalData/Address'
import {
    BasicInfoRequestBody,
    ContactInfosFetchResponse,
    EmailRequestBody,
    PhoneRequestBody
} from 'core/models/services/personalData/ContactInfos'

export const fetchContactDataApi = async (
    policyNumber: number
): Promise<ContactInfosFetchResponse> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/contactdata?policyNumber=${policyNumber}`
    )
    return result.data
}

export const postBasicInfoApi = async (requestBody: BasicInfoRequestBody): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/basicinfo`, requestBody)

export const postPhoneApi = async (requestBody: PhoneRequestBody): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/phonenumber`, requestBody)

export const postEmailApi = async (requestBody: EmailRequestBody): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/email`, requestBody)

export const postAddressApi = async (requestBody: AddressRequestBody): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/address`, requestBody)
