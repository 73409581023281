import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { goToServicesForm } from 'shared/store/services/services.slice'

import NotAllowed from '../NotAllowed/NotAllowed'

interface NoValidPhoneNumberProps {
    text: string
}

const NoValidPhoneNumber = ({ text }: NoValidPhoneNumberProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <NotAllowed
            title={t('FAMILY_GROUP.CONTACT_PHONE_LABEL')}
            message={text}
            link={
                <div
                    className="assura-link paragraphSmallLight m-top-24"
                    onClick={() => dispatch(goToServicesForm({ type: ServiceType.PHONE_CHANGE }))}
                    data-testid="no-valid-phone-link"
                >
                    {t('FORM.NO_VALID_PHONE_LINK')}
                </div>
            }
            dataTestId="no-valid-phone"
        />
    )
}

export default NoValidPhoneNumber
