import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ServicesAddressRHFForm } from 'core/models/services/personalData/Address'
import ModalAssura from 'shared/components/ModalAssura/ModalAssura'

interface AddressConfirmModalProps {
    visible: boolean
    onHide: () => void
    submitAddress: (address: ServicesAddressRHFForm) => void
}
const AddressConfirmModal = ({
    visible,
    onHide,
    submitAddress
}: AddressConfirmModalProps): JSX.Element => {
    const { t } = useTranslation()
    const { getValues } = useFormContext<ServicesAddressRHFForm>()

    return (
        <ModalAssura
            id="services-personal-data-address-confirm"
            visible={visible}
            onHide={onHide}
            title={t('PERSONAL_DATA.ADDRESS_CONFIRM')}
            description={t('PERSONAL_DATA.ADDRESS_UNKNOWN')}
            buttonTitle={t('COMMON.ALERT_BUTTON_OK')}
            onButtonClicked={() => {
                onHide()
                submitAddress(getValues())
            }}
            showCancelButton
        />
    )
}

export default AddressConfirmModal
