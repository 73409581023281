import './HospitalizationAnnouncement.css'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { UndefinedMaritalStatusEnum } from 'core/enums/FamilyGroup'
import { ServiceType } from 'core/enums/ServiceType'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'
import { HospitalizationAnnouncementRHFForm } from 'core/models/services/report/HospitalizationAnnouncementForm'
import { getPhoneNumber, phoneFormatted } from 'core/utils/phoneUtils'
import { getFullPersonalData, PersonalDataView } from 'shared/store/selectors/getFullPersonalData'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { onSubmitHospitalizationAnnouncement } from 'shared/store/services/report/report.slice'
import { getFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import ContactBlock from '../../components/ContactBlock/ContactBlock'
import { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import FormPage from '../../components/FormPage/FormPage'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import DiseaseInfos from '../components/DiseaseInfos'
import HospitalInfos from '../components/HospitalInfos'
import HospitalizationInfos from '../components/HospitalizationInfos'

const HospitalizationAnnouncement = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const personalData = useSelector(getFullPersonalData)
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const prevPersonalData = useRef<PersonalDataView>()
    const contactDataLoadStatus = personalData.contactDataLoadStatus

    const initialDefaultValues: HospitalizationAnnouncementRHFForm = {
        afflictionDescription: '',
        reason: null,
        symptomStartDate: null,
        hospitalName: '',
        division: null,
        hospStartDate: null,
        doctor: '',
        duration: null,
        remark: '',
        phone: '',
        email: '',
        maritalStatus: UndefinedMaritalStatusEnum.undefined
    }
    const [defaultValues, setDefaultValues] =
        useState<HospitalizationAnnouncementRHFForm>(initialDefaultValues)

    useEffect(() => {
        const phone = personalData.phone
        if (!phone.data) phone.data = {} as PhoneDatas

        const fullPhoneNumber = getPhoneNumber(phone.data)
        // Needs to check if personalData has really changed. Otherwise, when selecting a hospStartDate which call fetchSummaryAndProducts, personalData is updated and reset the form
        if (JSON.stringify(prevPersonalData.current) !== JSON.stringify(personalData)) {
            setDefaultValues({
                ...initialDefaultValues,
                phone: phoneFormatted(fullPhoneNumber),
                email: personalData.email.data ?? '',
                maritalStatus: personalData.basic.maritalStatus
                    ? personalData.basic.maritalStatus
                    : UndefinedMaritalStatusEnum.unknown
            })
            prevPersonalData.current = personalData
        }
    }, [personalData])

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactInfos({
                    selectedPolicyNumber: familyGroupResult.selectedPolicyNumber
                })
            )
    }

    const onSuccess = (values: HospitalizationAnnouncementRHFForm) => {
        const { phone, symptomStartDate, hospStartDate, duration, ...rest } = values
        dispatch(
            onSubmitHospitalizationAnnouncement({
                ...rest,
                duration: duration ? `${duration} ${t('COMMON.DAYS')}` : null,
                phoneNumber: phone,
                symptomStartDate: formatDateToApiFormat(symptomStartDate as Date),
                hospStartDate: formatDateToApiFormat(hospStartDate as Date)
            })
        )
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.HOSP_ANNOUNCEMENT}
            formTitle={{
                title: t('SERVICE.HOSPITALIZATION_ANNOUNCEMENT')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.HOSP_ANNOUNCEMENT} />}
            dataLoadingStatus={{
                status: contactDataLoadStatus,
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactInfos({
                            selectedPolicyNumber: familyGroupResult.selectedPolicyNumber as number,
                            reload: true
                        })
                    )
            }}
        >
            <>
                <div className="headline m-top-48">{t('SERVICE.HOSPITALIZATION_INFORMATION')}</div>
                <DiseaseInfos />
                <HospitalInfos />
                <HospitalizationInfos />
                <div className="headline m-top-48">{t('FAMILY_GROUP.CONTACT_INFO_LABEL')}</div>
                <ContactBlock />
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    HospitalizationAnnouncement,
    'HospitalizationAnnouncement',
    'hospitalization-announcement-container'
)
