import { useEffect, useState } from 'react'

import { ValidationRowItem } from 'core/validators/entities'

import GuideRow from './GuideRow'

type InputGuideProps = {
    displayFullValidationRules?: boolean
    testId: string
    validations: Record<string, ValidationRowItem>
}
const InputGuide = ({
    displayFullValidationRules,
    testId,
    validations
}: InputGuideProps): JSX.Element => {
    const [displayValidations, setDisplayValidations] = useState<Record<string, ValidationRowItem>>(
        {}
    )

    useEffect(() => {
        if (displayFullValidationRules) {
            setDisplayValidations(validations)
            return
        }
        const firstError = getFirstValidationError()
        setDisplayValidations(firstError)
    }, [validations])

    if (!displayValidations) return <></>

    const getFirstValidationError = (): Record<string, ValidationRowItem> => {
        const record = Object.entries(validations).filter(
            (entry: [string, ValidationRowItem]) => !validations[entry[0]].valid
        )[0]
        if (!record) return {} as Record<string, ValidationRowItem>
        const obj: Record<string, ValidationRowItem> = {}
        obj[record[0]] = record[1]
        return obj
    }
    return (
        <div>
            {Object.keys(validations).map((key: string) => {
                const current: ValidationRowItem = displayValidations[key]

                if (!current) return <div key={key}></div>

                return (
                    <GuideRow
                        key={key}
                        ruleKey={key}
                        showCross={displayFullValidationRules ? displayFullValidationRules : false}
                        valid={current.valid}
                        hint={current.hint}
                        error={current.error}
                        testId={testId}
                    />
                )
            })}
        </div>
    )
}

export default InputGuide
