export enum EnveloppeStatus {
    VisibilityError = 'VisibilityError',
    TechnicalError = 'TechnicalError',
    Received = 'Received',
    ErrorHandled = 'ErrorHandled',
    PartiallyProcessed = 'PartiallyProcessed',
    Processed = 'Processed',
    Validated = 'Validated'
}

export enum TranslatedEnveloppeStatus {
    Rescan = 'STATUS.RESCAN',
    Received = 'STATUS.RECEIVED',
    PartiallyProcessed = 'STATUS.PARTIALLY_PROCESSED',
    Processed = 'STATUS.PROCESSED'
}
