import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getContactLanguageSuffix } from 'core/helpers/FamilyGroupHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { LinkCms } from 'core/models/cms/LinkCms'
import { ProductCategoryCms } from 'core/models/cms/ProductCategoryCms'
import { ProductCategoryCmsLocalized } from 'core/models/cms/ProductCategoryCmsLocalized'
import { ProductCms } from 'core/models/cms/ProductCms'
import { ProductCmsLocalized } from 'core/models/cms/ProductCmsLocalized'
import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import {
    getRecommendedProductFromProductCategoryCms,
    getRecommendedProductFromProductCms
} from 'core/utils/ProductUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import {
    getProductCategoriesCms,
    getProductsCms,
    getRecommendedProductGroupCms
} from '../products/products.slice'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getRecommendedProducts = createSelector(
    [
        getRecommendedProductGroupCms,
        getLang,
        getShortContactLanguage,
        getProductsCms,
        getProductCategoriesCms
    ],
    (productGroupCms, lang, shortContactLanguage, productsCms, productCategoriesCms) => {
        const recommendedProducts: RecommendedProduct[] = []
        const contactLanguageLinkUrl: string | null = `url${getContactLanguageSuffix(
            shortContactLanguage
        )}`
        productGroupCms.products.forEach((product: LinkCms) => {
            let correspondingProductCms = productsCms.find((productCms: ProductCms) => {
                return product._id === productCms._id
            })

            if (
                lang === ENGLISH_LANGUAGE &&
                correspondingProductCms &&
                !correspondingProductCms.url_en
            ) {
                correspondingProductCms = {
                    ...correspondingProductCms,
                    url_en: correspondingProductCms[contactLanguageLinkUrl as keyof ProductCms] as
                        | string
                        | null
                }
            }

            const localizedCorrespondingProductCms: ProductCmsLocalized = getLocalizedObject(
                correspondingProductCms,
                lang
            )

            let correspondingCategoryCms =
                productCategoriesCms &&
                productCategoriesCms.find((productCategoryCms: ProductCategoryCms) => {
                    return (
                        localizedCorrespondingProductCms?.product_category[0]?._id ===
                        productCategoryCms?._id
                    )
                })

            if (
                lang === ENGLISH_LANGUAGE &&
                correspondingCategoryCms &&
                !correspondingCategoryCms.url_en
            ) {
                correspondingCategoryCms = {
                    ...correspondingCategoryCms,
                    url_en: correspondingCategoryCms[
                        contactLanguageLinkUrl as keyof ProductCategoryCms
                    ] as string | null
                }
            }

            const localizedCorrespondingCategoryCms: ProductCategoryCmsLocalized = getLocalizedObject(
                correspondingCategoryCms,
                lang
            )

            if (correspondingProductCms && correspondingCategoryCms) {
                recommendedProducts.push(
                    getRecommendedProductFromProductCms(
                        localizedCorrespondingProductCms,
                        localizedCorrespondingCategoryCms
                    )
                )
            }
        })

        productGroupCms.categories.forEach((category: LinkCms) => {
            let correspondingCategoryCms = productCategoriesCms.find(
                (productCategoryCms: ProductCategoryCms) => {
                    return category._id === productCategoryCms._id
                }
            )
            if (
                lang === ENGLISH_LANGUAGE &&
                correspondingCategoryCms &&
                !correspondingCategoryCms.url_en
            ) {
                correspondingCategoryCms = {
                    ...correspondingCategoryCms,
                    url_en: correspondingCategoryCms[
                        contactLanguageLinkUrl as keyof ProductCategoryCms
                    ] as string | null
                }
            }
            const localizedCorrespondingCategoryCms: ProductCategoryCmsLocalized = getLocalizedObject(
                correspondingCategoryCms,
                lang
            )

            if (localizedCorrespondingCategoryCms) {
                recommendedProducts.push(
                    getRecommendedProductFromProductCategoryCms(localizedCorrespondingCategoryCms)
                )
            }
        })

        return recommendedProducts
    }
)
