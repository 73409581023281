import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'

const DoctorType = (): JSX.Element => {
    const { t } = useTranslation()

    const { register } = useFormContext()

    return (
        <div data-testid="services-choose-doctor-doctor-type">
            <div className="paragraphMedium doctor-type-label">{t('SERVICES.DOCTOR_TYPE')}</div>
            <div>
                <RadioRHF
                    id={ServicesDoctorType.PEDIATRICIAN}
                    name="doctorType"
                    label={t('SERVICES.FREE_PEDIATRICIAN_CHOICE')}
                    classNames="flex-1 m-top-24"
                    register={register}
                />

                <RadioRHF
                    id={ServicesDoctorType.DOCTOR}
                    name="doctorType"
                    label={t('SERVICES.SEARCH_FAMILY_DOCTOR')}
                    classNames="flex-1 m-top-16"
                    register={register}
                />
            </div>
        </div>
    )
}

export default DoctorType
