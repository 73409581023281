import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { AventuraPricingSummaryModel } from 'core/models/services/coverage/AventuraForm'

type PricingProps = {
    pricing: AventuraPricingSummaryModel | undefined
    inline: boolean
}

const Pricing = ({ pricing, inline }: PricingProps) => {
    const { t } = useTranslation()
    const flexStart = 'flex-start'
    return (
        <Container
            className="p-32 bg-gray50 border-solid-width-1 bc-gray100"
            style={{
                display: 'flex',
                flexDirection: inline ? 'column' : 'row',
                alignItems: 'center',
                paddingLeft: '32px',
                paddingRight: '32px'
            }}
        >
            <Col xl={4} className="m-0 p-0" style={{ display: 'flex' }}>
                <span className="paragraphMedium">
                    {t('SERVICE.AVENTURA_FORM_PRICING_SUMMARY_LABEL')}
                </span>
            </Col>
            <Col
                xl={6}
                className="m-0 p-0"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: flexStart,
                    justifyContent: flexStart
                }}
            >
                <h1 className="titleLarge" data-testid="aventura-form-pricing-summary">{`CHF ${
                    pricing && pricing.price
                }`}</h1>
                <span className="labelMedium" data-testid="aventura-form-days-summary">
                    {pricing && pricing.subTitle}
                </span>
            </Col>
        </Container>
    )
}
export default Pricing
