import './ModelDeductibleSelectDeductible.css'

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import { AssuraTabData } from 'core/models/TabData'
import { deductiblesToTab } from 'core/utils/TabUtils'
import { formatNumber } from 'core/utils/TextUtils'
import { AssuraTabContainer } from 'shared/components/AssuraTabContainer/AssuraTabContainer'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import {
    getCurrentDeductible,
    getCurrentModel,
    getModelDeductibles
} from 'shared/store/services/coverage/coverage.slice'

const getProductPremium = (
    modelDeductibles: ProductFranchise[],
    deductible: number | null,
    product: string | null
): number =>
    modelDeductibles.filter((m) => m.product === product && m.franchiseAmount === deductible)[0]
        ?.prime

const getPremiumDiffDisplay = (value: number): string => {
    if (value === 0) return ''
    const sign = value > 0 ? '+' : '-'
    const num = value > 0 ? value : value * -1
    return `${sign} ${num.toFixed(2)}`
}

export type DeductibleDisplayTemplateData = {
    currentModel: string | null
    modelDeductibles: ProductFranchise[]
    deductible: number
    currentDeductible: number | null
    label: string
}

const displayTemplate = (data?: AssuraTabData): React.ReactElement => {
    if (!data || !data?.templateData) return <></>

    const currentPremium = getProductPremium(
        data.templateData.modelDeductibles,
        data.templateData.currentDeductible,
        data.templateData.currentModel
    )

    const tabDeductiblePremium = getProductPremium(
        data.templateData.modelDeductibles,
        data.templateData.deductible,
        data.templateData.currentModel
    )
    const premiumValueDiff = tabDeductiblePremium - currentPremium

    return (
        <div className="model-deductible-template-container">
            <div className="model-deductible-product-franchise headlineSmall">
                {formatNumber(data.templateData.deductible, false)}
            </div>
            <div className="c-gray500 labelSmallMedium d-flex align-items-center">
                {data.templateData.currentDeductible !== data.templateData.deductible ? (
                    getPremiumDiffDisplay(premiumValueDiff)
                ) : (
                    <>
                        <span>{data.templateData.label}</span>
                        <i
                            className={`icon assura-check-circle size-16 m-left-8 active-icon `}
                            data-testid="deductible-check-icon"
                            style={{ marginRight: '0' }}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

const numberCompare = (order?: string) => (a: number, b: number): number => {
    return order === 'asc' ? a - b : b - a
}

const ModelDeductibleSelectDeductible = (): JSX.Element => {
    const { t } = useTranslation()
    const [tabs, setTabs] = useState<AssuraTabData[]>([])

    const { setValue, watch } = useFormContext()

    const deductibleWatch = watch('deductible')

    const modelDeductibles = useSelector(getModelDeductibles)

    const currentDeductible = useSelector(getCurrentDeductible)
    const currentModel = useSelector(getCurrentModel)

    const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined)

    const deductibles = [...new Set(modelDeductibles.map((p) => p.franchiseAmount))]
    deductibles.sort(numberCompare())

    const switchSelectedDeductible = (id: number) => {
        setValue('deductible', deductibles[id].toString())
    }

    useEffect(() => {
        if (!tabs || !tabs.length) return
        const tabId = tabs.find((o) => o.label === formatNumber(deductibleWatch, false))?.uniqueKey
        setSelectedTab(tabId)
    }, [tabs])

    useEffect(() => {
        const label = t('SERVICES.MODEL_DEDUCTIBLE_CURRENT')

        const tempTabs = deductiblesToTab(
            deductibles,
            currentModel,
            modelDeductibles,
            currentDeductible,
            label
        )
        setTabs(tempTabs)
    }, [deductibleWatch])

    return (
        <FullScreenContainer color="gray20">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="headline m-bottom-32 d-flex align-items-center">
                            <span className="c-black">
                                {t('SERVICES.MODEL_DEDUCTIBLE_DEDUCTIBLE_NEW')}
                            </span>
                            {/* TODO: Hidden for GoLive 
                            <i
                                className="icon assura-info-circle size-24 m-left-8 c-gray500"
                                data-testid="deductible-info-icon"
                            /> */}
                        </div>
                        <div className="model-deductible-tab-dropdown nav nav-tabs nav-fill nav-justified">
                            {tabs && tabs.length && (
                                <AssuraTabContainer
                                    data={tabs}
                                    selectedId={selectedTab}
                                    dataId={'model-deductible-tabs'}
                                    onClick={switchSelectedDeductible}
                                    options={{ valueTemplate: displayTemplate }}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </FullScreenContainer>
    )
}

export default ModelDeductibleSelectDeductible
