import { call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    fetchSymptomCheckerTokenApi,
    postSymptomCheckerInterStepApi
} from 'core/api/SymptomChecker'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { PopinItemEnum } from 'core/enums/Popin'
import { ProductTypeEnum } from 'core/enums/Product'
import {
    SymptomCheckerGenderEnum,
    SymptomCheckerInterviewDirectEnum,
    SymptomCheckerPartnerTypeEnum
} from 'core/enums/SymptomChecker'
import { generateUUID } from 'core/helpers/GuidHelper'
import { DigcliParametersCms } from 'core/models/cms/DicliParametersCms'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { HeadOfFamily } from 'core/models/HeadOfFamily'
import { SymptomCheckerPartner, SymptomCheckerTokenApiResponse } from 'core/models/SymptomChecker'
import { formatSymptomCheckerInterStepData } from 'core/utils/symptomCheckerUtils'
import { differenceInYears } from 'date-fns'

import { setBanner } from '../banners/banners.slice'
import { getDigcliparameters } from '../digcliParameters/digcliParameters.slice'
import {
    getFamilyProductsLoadingStatus,
    setFamilyProductsLoadingStatus
} from '../familySummaries/familySummaries.slice'
import { getHeadOfFamily } from '../general/general.slice'
import { setSelectedPopinItem } from '../popin/popin.slice'
import { getSummaryCurrentYear } from '../selectors/getSummaryCurrentYear'
import {
    getConcernedPartner,
    initSymptomChecker,
    initSymptomCheckerForm,
    sendSymptomCheckerSessionInterStep,
    setConcernedPartner,
    setIsOnlyOneQualiMedPartner,
    setPartners,
    setSymptomCheckerLoadingStatus,
    triggerSymptomCheckerPopin
} from './symptomChecker.slice'

function* updatePartnerslamalProductCode(partners: SymptomCheckerPartner[]) {
    try {
        const currentPartners = [...partners]
        const familyProductsLoadingStatus: LoadingStatusEnum = yield select(
            getFamilyProductsLoadingStatus
        )

        // TO WAIT UNTIL ALL MEMBER'S PRODUCTS ARE FETCHED
        if (familyProductsLoadingStatus === LoadingStatusEnum.LOADING) {
            yield take(setFamilyProductsLoadingStatus)
        }

        const familySummary: SummaryResult = yield select(getSummaryCurrentYear)

        familySummary.insuredPersons.forEach((person) => {
            const lamalProductCode = person.productsPerson?.products.find(
                (product) => product.type === ProductTypeEnum.LAMAL
            )?.code

            const currentPartnerIndex = partners.findIndex(
                (partner) => partner.policyNumber === person.policyNumber
            )

            if (lamalProductCode && currentPartnerIndex !== -1)
                currentPartners[currentPartnerIndex] = {
                    ...currentPartners[currentPartnerIndex],
                    lamalProductCode
                }
        })

        if (currentPartners.every((partner) => partner.lamalProductCode !== 'PAM'))
            currentPartners.push({
                token: '',
                type: SymptomCheckerPartnerTypeEnum.OTHER,
                interviewDirect: SymptomCheckerInterviewDirectEnum.SOMEONE,
                policyNumber: null,
                firstName: '',
                lastName: '',
                uuid: generateUUID(),
            })

        if (currentPartners.length === 1 && currentPartners[0].lamalProductCode === 'PAM') {
            yield put(setIsOnlyOneQualiMedPartner(true))
        }

        yield put(setPartners(currentPartners))
    } catch (e) {
        console.error('updatePartnerslamalProductCode Error', e)
        throw e
    }
}

function* triggerSymptomCheckerPopinSaga(action: ReturnType<typeof triggerSymptomCheckerPopin>) {
    try {
        const isRestartAction = action.payload

        yield put(
            setSelectedPopinItem({
                popinItem: PopinItemEnum.SYMPTOM_CHECKER,
                extraData: isRestartAction
            })
        )
    } catch (e) {
        console.error('triggerSymptomCheckerPopinSaga Error', e)
    }
}

function* initSymptomCheckerSaga() {
    try {
        const digcliParameters: DigcliParametersCms = yield select(getDigcliparameters)
        const isSymptomCheckerDeactivated =
            digcliParameters?.isSymptomCheckerCustomerPortalActivated === 'false'

        if (isSymptomCheckerDeactivated) throw new Error('Symptom Checker is not available')

        const familySummary: SummaryResult = yield select(getSummaryCurrentYear)
        const headOfFamily: HeadOfFamily | undefined = yield select(getHeadOfFamily)

        if (familySummary?.insuredPersons.length === 0) throw new Error('No insured persons found')

        const partners: SymptomCheckerPartner[] = familySummary.insuredPersons.map((person) => {
            const lamalProductCode = person.productsPerson?.products.find(
                (product) => product.type === ProductTypeEnum.LAMAL
            )?.code
            return {
                token: '',
                type: SymptomCheckerPartnerTypeEnum.MEMBER,
                policyNumber: person.policyNumber,
                interviewDirect:
                    headOfFamily?.policyNumber === person.policyNumber
                        ? SymptomCheckerInterviewDirectEnum.MYSELF
                        : SymptomCheckerInterviewDirectEnum.SOMEONE,
                firstName: person.firstName,
                lastName: person.lastName,
                uuid: generateUUID(),
                lamalProductCode,
                age: differenceInYears(new Date(), new Date(person.dateOfBirth)),
                sex:
                    person.gender === 'male'
                        ? SymptomCheckerGenderEnum.male
                        : SymptomCheckerGenderEnum.female
            }
        })

        yield put(setPartners(partners))

        yield put(triggerSymptomCheckerPopin())

        yield fork(updatePartnerslamalProductCode, partners)
    } catch (e) {
        console.error('initSymptomCheckerSaga Error', e)
        yield put(
            setBanner({
                dataTestId: 'symptomChecker',
                message: 'SYMPTOM_CHECKER.ERROR_BANNER'
            })
        )
    }
}

function* initSymptomCheckerFormSaga(action: ReturnType<typeof initSymptomCheckerForm>) {
    try {
        yield put(setSymptomCheckerLoadingStatus(LoadingStatusEnum.LOADING))
        const concernedPartner = {...action.payload}

        const symptomCheckerSessionToken: SymptomCheckerTokenApiResponse = yield call(
            fetchSymptomCheckerTokenApi
        )
        concernedPartner.token = symptomCheckerSessionToken.token

        yield put(setConcernedPartner(concernedPartner))
        yield put(setSymptomCheckerLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setSymptomCheckerLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('initSymptomCheckerFormSaga Error', e)
    }
}

function* sendSymptomCheckerSessionInterStepSaga(
    action: ReturnType<typeof sendSymptomCheckerSessionInterStep>
) {
    try {
        const { step, data } = action.payload

        const concernedPartner: SymptomCheckerPartner | null = yield select(getConcernedPartner)

        const [interStep, interStepMustBeSent] = formatSymptomCheckerInterStepData(
            data,
            step,
            concernedPartner?.policyNumber || null,
            concernedPartner?.token || '',
            concernedPartner?.lamalProductCode || null,
            concernedPartner?.uuid || ''
        )

        if (interStepMustBeSent && concernedPartner?.policyNumber !== null) {
            yield call(postSymptomCheckerInterStepApi, interStep)
        }
    } catch (e) {
        console.error('sendSymptomCheckerSessionInterStepSaga Error', e)
    }
}

function* initSymptomCheckerWatcher() {
    yield takeLatest(initSymptomChecker.type, initSymptomCheckerSaga)
}

function* triggerSymptomCheckerPopinWatcher() {
    yield takeLatest(triggerSymptomCheckerPopin.type, triggerSymptomCheckerPopinSaga)
}

function* initSymptomCheckerFormWatcher() {
    yield takeLatest(initSymptomCheckerForm.type, initSymptomCheckerFormSaga)
}

function* sendSymptomCheckerSessionInterStepWatcher() {
    yield takeEvery(sendSymptomCheckerSessionInterStep.type, sendSymptomCheckerSessionInterStepSaga)
}

const watchers = [
    fork(initSymptomCheckerWatcher),
    fork(triggerSymptomCheckerPopinWatcher),
    fork(initSymptomCheckerFormWatcher),
    fork(sendSymptomCheckerSessionInterStepWatcher)
]

export default watchers
