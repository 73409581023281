import { call, fork, put, spawn, takeEvery } from 'redux-saga/effects'

import { submitDeleteAccountApi } from 'core/api/Account'
import analyticsConstants from 'core/constants/analyticsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { setBanner } from 'shared/store/banners/banners.slice'

import {
    setDeleteAccountLoadingStatus,
    setisAccountDeleted,
    submitDeleteAccount
} from './deleteAccount.slice'

export function* submitDeleteAccountSaga(action: ReturnType<typeof submitDeleteAccount>) {
    try {
        yield put(setDeleteAccountLoadingStatus(LoadingStatusEnum.LOADING))

        const policyNumber = action.payload
        yield call(submitDeleteAccountApi, policyNumber)
        yield put(setDeleteAccountLoadingStatus(LoadingStatusEnum.OK))
        yield put(setisAccountDeleted(true))
        yield spawn(sendEvent, analyticsConstants.EVENTS.ACCOUNT_DELETE)
    } catch (e) {
        console.error('submitDeleteAccountSaga Error', e)
        yield put(setDeleteAccountLoadingStatus(LoadingStatusEnum.ERROR))
        yield put(
            setBanner({
                dataTestId: 'delete-account',
                message: 'COMMON.ERROR'
            })
        )
    }
}

function* submitDeleteAccountWatcher() {
    yield takeEvery(submitDeleteAccount.type, submitDeleteAccountSaga)
}

const watchers = [fork(submitDeleteAccountWatcher)]

export default watchers
