export enum InvoicesToCheckTranslatedStatus {
    toCheck = 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.TO_CHECK',
    checked = 'COMMUNICATIONS.INVOICES_TO_CHECK.CHECK_STATUS.CHECKED'
}

export const InvoiceCheckingStatusEnum = {
    TO_CHECK: 'toCheck',
    CHECKED: 'checked',
    EXPIRED: 'expired',
    REFUSED: 'refused'
} as const

export const InvoiceCheckingResponseTypeEnum = {
    RADIO: 'RADIO'
} as const

export const FetchInvoicesToCheckTypeEnum = {
    INIT: 'init',
    FILTERING: 'filtering'
} as const
