import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'

interface AssuraDropzoneLoadingProps {
    text: string
}
const AssuraDropzoneLoading = ({ text }: AssuraDropzoneLoadingProps): JSX.Element => {
    return (
        <div className="assura-dropzone-loading-container">
            <ActivityIndicator />
            <div className="paragraphSmall assura-dropzone-loading-message">{text}</div>
        </div>
    )
}

export default AssuraDropzoneLoading
