import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { getEcLinks } from 'shared/store/ecLinks/ecLinks.slice'

interface EcLinkHandlerProps {
    ecLinkId?: string
    children: ReactNode
}

const EcLinkHandler = ({ ecLinkId, children }: EcLinkHandlerProps): JSX.Element | null => {
    const ecLinks = useSelector(getEcLinks)

    const currentEcLink = ecLinks.find((ecLink) => ecLink._id === ecLinkId)

    return currentEcLink === undefined || currentEcLink?.is_active === false ? null : <>{children}</>
}

export default EcLinkHandler
