import navigationConstants from 'core/constants/navigationConstants'
import { HomeNotificationsColors } from 'core/enums/HomeNotificationsColors'
import { HomeNotificationsCardData } from 'core/models/HomeNotifications'

const mapCommunicationsToHomeNotification = (count: number): HomeNotificationsCardData => {
    return {
        testId: 'communications',
        criticality: count > 0 ? 1 : 0,
        color: count > 0 ? HomeNotificationsColors.BLACK : HomeNotificationsColors.GRAY,
        count,
        title: 'HOME.NOTIFICATIONS_COMMUNICATIONS_TITLE',
        link: navigationConstants.COMMUNICATIONS.url
    }
}

export default mapCommunicationsToHomeNotification
