import { initReactI18next } from 'react-i18next'

import { DEFAULT_LANGUAGE, LANGUAGES } from 'core/constants/lang'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: DEFAULT_LANGUAGE,
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            prefix: '|',
            suffix: '|'
        },
        react: { useSuspense: true },
        supportedLngs: LANGUAGES,
        backend: {
            loadPath: `${process.env.REACT_APP_CMS_SERVER}/api/lokalize/project/customerPortal/|lng|`
        }
    })
