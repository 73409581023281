import { ClaimsSettlementTypeEnum } from '../enums/ClaimsSettlementTypeEnum'
import { ClaimsSettlement } from '../models/documents/ClaimsSettlement'
import { ClaimsSettlementReceipt } from '../models/documents/ClaimsSettlementReceipt'

export const pieChartSize = 164
export const segmentStrokeWidth = 12
export const strokeDashArrayPadding = 4
export const animationDuration = 1

export interface ClaimsSettlementCostsLabel {
    totalCostsLabel: string
    percentageLabel: string
    assuraLabel: string
    insuredLabel: string
}

export interface ClaimsSettlementCostsValues {
    assuraCosts: {
        label: string
        value?: number
    }
    insuredCosts: {
        label: string
        value: number
    }
}

export const getCostsLabels = (
    claimsSettlementType: ClaimsSettlementTypeEnum,
    isForReceipt?: boolean
): ClaimsSettlementCostsLabel => {
    const labelSuffix = isForReceipt ? '_RECEIPT' : ''
    if (claimsSettlementType === ClaimsSettlementTypeEnum.INVOICE) {
        return {
            totalCostsLabel: `CLAIMS_SETTLEMENT.TOTAL_COSTS_ASSURA${labelSuffix}`,
            percentageLabel: `CLAIMS_SETTLEMENT.PAID_BY_ASSURA${labelSuffix}`,
            assuraLabel: `CLAIMS_SETTLEMENT.ASSURA_COVERS${labelSuffix}`,
            insuredLabel: `CLAIMS_SETTLEMENT.INSURED_PAYS${labelSuffix}`
        }
    } else if (claimsSettlementType === ClaimsSettlementTypeEnum.REFUND) {
        return {
            totalCostsLabel: `CLAIMS_SETTLEMENT.TOTAL_COSTS_INSURED${labelSuffix}`,
            percentageLabel: `CLAIMS_SETTLEMENT.REIMBURSED_BY_ASSURA${labelSuffix}`,
            assuraLabel: `CLAIMS_SETTLEMENT.ASSURA_REIMBURSES${labelSuffix}`,
            insuredLabel: `CLAIMS_SETTLEMENT.INSURED_PAYS${labelSuffix}`
        }
    } else {
        //TODO do something cleaner later when we have more time
        return {
            totalCostsLabel: 'CLAIMS_SETTLEMENT.TOTAL_COSTS',
            percentageLabel: '',
            assuraLabel: '',
            insuredLabel: ''
        }
    }
}

export const getCostsValues = (
    costsLabels: ClaimsSettlementCostsLabel,
    claimsSettlement: ClaimsSettlement
): ClaimsSettlementCostsValues => {
    return {
        assuraCosts: {
            label: costsLabels.assuraLabel,
            value: claimsSettlement.totalCostsCovered
        },
        insuredCosts: {
            label: costsLabels.insuredLabel,
            value: claimsSettlement.totalCostsSharedWithCostsNotCovered
        }
    }
}

export const getReceiptCostsValues = (
    costsLabels: ClaimsSettlementCostsLabel,
    receipt: ClaimsSettlementReceipt
): ClaimsSettlementCostsValues => {
    return {
        assuraCosts: {
            label: costsLabels.assuraLabel,
            value: receipt.costsCovered?.total
        },
        insuredCosts: {
            label: costsLabels.insuredLabel,
            value: receipt.totalCostsSharedWithCostsNotCovered
        }
    }
}

export const getPercentage = (costsValues: ClaimsSettlementCostsValues): number | undefined => {
    if (
        costsValues.assuraCosts.value !== undefined &&
        costsValues.assuraCosts.value + costsValues.insuredCosts.value !== 0
    ) {
        const defaultPercentage =
            (costsValues.assuraCosts.value /
                (costsValues.assuraCosts.value + costsValues.insuredCosts.value)) *
            100
        const stringPercentage = defaultPercentage.toString()
        if (stringPercentage.startsWith('0.')) {
            return 1
        } else if (stringPercentage.startsWith('99.')) {
            return 99
        } else {
            return Math.round(defaultPercentage)
        }
    } else {
        return undefined
    }
}
