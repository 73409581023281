import './AssuraTextButton.css'

import { Col, Form } from 'react-bootstrap'

import AssuraIconButton from '../AssuraIconButton/AssuraIconButton'
import AssuraText from '../AssuraText/AssuraText'

type AssuraTextButtonProps = {
    id?: string
    label: string
    value: string
    onEditAction: () => void
    type?: string
    editAllowed: boolean
    dataTestId?: string
}

const AssuraTextButton = ({ ...props }: AssuraTextButtonProps) => {
    return (
        <Form.Group as={Col} className="assura-text-button-root">
            <Form.Label className="labelMedium text-label" htmlFor={props.id}>
                {props.label}
            </Form.Label>
            <Col className="assura-text-button-group" data-testid={props.dataTestId}>
                <AssuraText
                    id={props.id}
                    value={props.value}
                    type={props.type}
                    classNames={'assura-text-button-value label'}
                />
                {props.editAllowed && (
                    <AssuraIconButton
                        id={`${props.dataTestId}-edit-button`}
                        icon="assura-edit"
                        variant="primary"
                        size="medium"
                        onClick={() => props.onEditAction()}
                        classNames={'assura-text-edit-button'}
                    />
                )}
            </Col>
        </Form.Group>
    )
}
export default AssuraTextButton
