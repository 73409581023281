import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AssuraPopinProps } from 'core/models/Popin'
import AssuraPopin from 'shared/components/AssuraPopin/AssuraPopin'
import { DeleteEnveloppePayload, onDeleteEnveloppe } from 'shared/store/enveloppes/enveloppes.slice'
import { getPopinExtraData } from 'shared/store/popin/popin.slice'
import { useAppDispatch } from 'shared/store/store'

const BackToScanDeletePopin = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const popinProps = useSelector(getPopinExtraData) as DeleteEnveloppePayload

    const ecPlusProps: AssuraPopinProps = {
        title: t('ENVELOPPES.BACK_TO_SCAN_POP_IN_TITLE'),
        message: t('ENVELOPPES.BACK_TO_SCAN_POP_IN_MESSAGE'),
        validateButtonLabel: t('ENVELOPPES.BACK_TO_SCAN_POP_IN_VALIDATION_CTA'),
        validatedAction: () => dispatch(onDeleteEnveloppe({ ...popinProps }))
    }

    return <AssuraPopin popinProps={ecPlusProps} />
}

export default BackToScanDeletePopin
