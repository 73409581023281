import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { getRequestsData, getRequestsDetailsApi } from 'core/api/services/Requests'
import analyticsConstants from 'core/constants/analyticsConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { RequestData, RequestDetails } from 'core/models/services/RequestData'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'

import {
    fetchRequestDetails,
    getRequests,
    getServicesRequestsDetails,
    setRequestDetails,
    setRequestDetailsLoadingStatus,
    setRequests,
    setRequestsLoadingStatus
} from './requests.slice'

export function* getRequestsSaga(action: ReturnType<typeof getRequests>) {
    try {
        yield put(setRequestsLoadingStatus(LoadingStatusEnum.LOADING))
        const connectedFamilyMember: ConnectedFamilyMember = yield select(getConnectedFamilyMember)
        const requests: RequestData[] = yield call(
            getRequestsData,
            connectedFamilyMember.policyNumber
        )

        yield put(setRequestsLoadingStatus(LoadingStatusEnum.OK))
        yield put(setRequests(requests))
    } catch (e) {
        console.error('getRequestsSaga Error', e)
        yield put(setRequestsLoadingStatus(LoadingStatusEnum.ERROR))
        if (action?.payload?.shouldThrow) {
            throw e
        }
    }
}

export function* fetchRequestDetailsSaga(action: ReturnType<typeof fetchRequestDetails>) {
    try {
        const { id, policyNumber, reload } = action.payload
        const currentRequests: Record<string, RequestDetails> = yield select(
            getServicesRequestsDetails
        )

        if (reload || !currentRequests[id]) {
            yield put(setRequestDetailsLoadingStatus(LoadingStatusEnum.LOADING))
            const requestDetails: RequestDetails = yield call(
                getRequestsDetailsApi,
                policyNumber,
                id
            )

            yield put(setRequestDetailsLoadingStatus(LoadingStatusEnum.OK))
            yield put(
                setRequestDetails({
                    id,
                    data: requestDetails
                })
            )
        }
        yield spawn(sendEvent, analyticsConstants.EVENTS.REQUEST_ITEM_DETAILS)
    } catch (e) {
        console.error('fetchRequestDetails Error', e)
        yield put(setRequestDetailsLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* getRequestsWatcher() {
    yield takeEvery(getRequests.type, getRequestsSaga)
}

function* fetchRequestDetailsWatcher() {
    yield takeEvery(fetchRequestDetails.type, fetchRequestDetailsSaga)
}

const watchers = [fork(getRequestsWatcher), fork(fetchRequestDetailsWatcher)]

export default watchers
