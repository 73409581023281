// This section provides support for linguistic regions in switzerland 
import { Canton } from 'core/enums/Canton'

// La Suisse est composée de quatre régions linguistiques: 
// la Suisse alémanique (germanophone), la Suisse romande (francophone), 
// la Suisse italienne et la Suisse romanche. 
// La part du français et des langues non nationales est en progression, 
// tandis que l'on observe un recul de l'allemand, de l'italien et du romanche.

// Région lémanique - VD, VS, GE - 1282 - 8718.
// Espace Mittelland - BE, FR, SO, NE, JU - 1653 - 10062.
// Nordwestschweiz - BS, BL, AG - 980 - 1959.
// Zürich - ZH - 1194 1729.
// Ostschweiz - GL, SH, AR, AI, SG, GR, TG - 1037 - 11521.
// Zentralschweiz - LU, UR, SZ, OW, NW, ZG - 659 - 4485.
// Tessin - TI - 301 - 2812.

// Nous avons trois grandes régions linguistiques, et encore quelques zones qui parlent le Romanche.
// ROM:  [JU,NE,FR,VD,GE,VS]
// DE: [BS,SO,BE,BL,OW,UR,NW,LU,AG,ZG,SZ,GL,ZH,SH,TG,SG,AR,AI,GR]
// IT [TI]


export enum Regions {
  ROM =  'ROM',
  ALE = 'ALE' ,
  IT = 'IT'
}
export interface RegionCanton {
    code: Regions
    cantons: Canton[]
}

const regionCantons: RegionCanton[] = [
    {
        code: Regions.ROM,
        cantons: [
            Canton.FRIBOURG,
            Canton.GENEVE,
            Canton.JURA,
            Canton.NEUCHATEL,
            Canton.VALAIS,
            Canton.VAUD
        ]
    },
    {
        code: Regions.ALE,
        cantons: [
            Canton.APP_RHODES_EXT,
            Canton.APP_RHODES_INT,
            Canton.ARGOVIE,
            Canton.BALE_CAMPAGNE,
            Canton.BALE_VILLE,
            Canton.BERNE,
            Canton.GLARIS,
            Canton.GRISONS,
            Canton.LUCERNE,
            Canton.NIDWALD,
            Canton.OBWALD,
            Canton.SAINT_GALL,
            Canton.SCHAFFHOUSE,
            Canton.SCHWYTZ,
            Canton.SOLEURE,
            Canton.THURGOVIE,
            Canton.URI,
            Canton.ZOUG,
            Canton.ZURICH
        ]
    },
    { code: Regions.IT, cantons: [Canton.TESSIN] }
]


export const getRegionByCanton = (canton: Canton): Regions | undefined =>
    canton &&
    regionCantons.find((region) => region.cantons.find((c) => c === canton ))?.code


