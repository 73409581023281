import { AssuraDocument } from 'core/models/AssuraDocument'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'

import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'

import ClaimsSettlementReceiptPanelHeaderCenter from '../ClaimsSettlementReceiptPanel/ClaimsSettlementReceiptPanelHeaderCenter'
import PaymentDocumentPanelHeaderCenter from './PaymentDocumentPanelHeaderCenter'
import PaymentDocumentPanelHeaderLeft from './PaymentDocumentPanelHeaderLeft'
import PaymentDocumentPanelHeaderRight from './PaymentDocumentPanelHeaderRight'

interface StatementsPanelHeaderProps {
    selectedDocument: DocumentRow
    closePanel: () => void
    toolbarPluginInstance: ToolbarPlugin
    statusIsOk: boolean
    doc?: AssuraDocument
    isClaimsSettlementReceipt?: boolean
}

const PaymentDocumentPanelHeader = ({
    selectedDocument,
    closePanel,
    toolbarPluginInstance,
    statusIsOk,
    doc,
    isClaimsSettlementReceipt
}: StatementsPanelHeaderProps): JSX.Element => {
    const { hasPDFPanel, hasLeftPanel } = usePanelDatasContext().panelDatas

    const insuredPersonName = selectedDocument.insuredPersonName
    const insuredPersonLastName = selectedDocument.insuredPersonLastName

    return (
        <AssuraPanelHeader
            leftBlock={
                <PaymentDocumentPanelHeaderLeft
                    insuredPersonName={insuredPersonName}
                    insuredPersonLastName={insuredPersonLastName}
                    statusIsOk={statusIsOk}
                    toolbarPluginInstance={toolbarPluginInstance}
                />
            }
            centerBlock={
                isClaimsSettlementReceipt ? (
                    <ClaimsSettlementReceiptPanelHeaderCenter />
                ) : (
                    <PaymentDocumentPanelHeaderCenter selectedDocument={selectedDocument} />
                )
            }
            rightBlock={
                <PaymentDocumentPanelHeaderRight
                    toolbarPluginInstance={toolbarPluginInstance}
                    statusIsOk={statusIsOk}
                    doc={doc}
                />
            }
            closePanel={closePanel}
            forceSmallSideBlocks={hasLeftPanel && !hasPDFPanel}
        />
    )
}

export default PaymentDocumentPanelHeader
