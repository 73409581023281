import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesAddressRHFForm } from 'core/models/services/personalData/Address'
import { ValueType } from 'core/models/ValueType'
import useWindowSize from 'core/services/useWindowSize'
import AutoCompleteRHF from 'modules/services/components/AutoCompleteRHF/AutoCompleteRHF'
import FormBlockWithLabel from 'modules/services/components/FormBlockWithLabel/FormBlockWithLabel'
import {
    getStreets,
    getStreetsLoadingStatus,
    searchStreets
} from 'shared/store/localities/localities.slice'

let streetNumberTimeout: NodeJS.Timeout

const AddressStreetNumber = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isMobile } = useWindowSize()

    const { getValues, setValue, watch } = useFormContext<ServicesAddressRHFForm>()

    const streetsResult = useSelector(getStreets)
    const loadingStatus = useSelector(getStreetsLoadingStatus)
    const [streetNumberAvailable, setStreetNumberAvailable] = useState<string[]>([])
    const [streetNumberResults, setStreetNumberResults] = useState<ValueType[]>([])

    const street = watch('street')

    useEffect(() => {
        clearTimeout(streetNumberTimeout)
        streetNumberTimeout = setTimeout(() => {
            if (street && streetsResult?.length > 0) {
                const streetNumbers =
                    streetsResult.find(
                        (streetResult) =>
                            streetResult.displayName?.toLowerCase() === street.toLowerCase()
                    )?.numbers || []
                setStreetNumberAvailable(streetNumbers)
            } else {
                setStreetNumberAvailable([])
            }
        }, 500)
    }, [street, streetsResult])

    const handleOnChangeStreet = (street: string) => {
        const localityId = getValues('localityId')
        if (localityId) {
            dispatch(searchStreets({ localityId, street }))
        }
        setValue('streetNumber', null)
        setStreetNumberAvailable([])
        setStreetNumberResults([])
    }

    const streetsResults = streetsResult.map<ValueType>((street) => {
        return {
            label: street.displayName,
            value: street.displayName
        }
    })

    const handleOnChangeStreetNumber = (streetNumber: string) => {
        if (streetNumber && streetNumberAvailable.length > 0) {
            const streetNumberResults = streetNumberAvailable
                .filter((streetNumberAvailable) => streetNumberAvailable.includes(streetNumber))
                ?.map((result) => {
                    return {
                        label: result,
                        value: result
                    }
                })
                ?.slice(0, 100)
            setStreetNumberResults(streetNumberResults)
        } else {
            setStreetNumberResults([])
        }
    }

    const getLengthErrorMessage = (min: number, max: number): string =>
        t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', { min, max })

    return (
        <div className="m-top-32">
            <FormBlockWithLabel label={t('PERSONAL_DATA.ADDRESS_STREET_NUMBER')} isFirstChildInput>
                <div className={`d-flex ${isMobile ? 'flex-column' : 'flex-row'}`}>
                    <div style={{ flex: 4 }}>
                        <AutoCompleteRHF
                            id="services-personal-data-address-street"
                            name="street"
                            results={streetsResults}
                            handleOnChange={handleOnChangeStreet}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('COMMON.MANDATORY_FIELD')
                                },
                                minLength: {
                                    value: 2,
                                    message: getLengthErrorMessage(2, 50)
                                },
                                maxLength: {
                                    value: 50,
                                    message: getLengthErrorMessage(2, 50)
                                }
                            }}
                            placeHolder="Chemin Pinderio"
                            loadingStatus={loadingStatus}
                        />
                    </div>
                    <div className={`flex-1 ${isMobile ? 'm-top-32' : 'm-left-32'}`}>
                        <AutoCompleteRHF
                            id="services-personal-data-address-street-number"
                            name="streetNumber"
                            key={street} //force to render a new component and then to reset streetNumber's input field when changing street
                            charMinToStartSearch={1}
                            results={streetNumberResults}
                            handleOnChange={handleOnChangeStreetNumber}
                            rules={{
                                maxLength: {
                                    value: 10,
                                    message: t('FORM.INPUT_MAX_CHARACTERS', { number: 10 })
                                }
                            }}
                            placeHolder="3"
                            loadingStatus={LoadingStatusEnum.OK}
                        />
                    </div>
                </div>
            </FormBlockWithLabel>
        </div>
    )
}

export default AddressStreetNumber
