import {
    pieChartSize,
    segmentStrokeWidth,
    strokeDashArrayPadding
} from 'core/helpers/ClaimsSettlementPanelHelper'
import { CircularProgressSegment } from 'core/models/AssuraCircularProgress'

interface SegmentProps {
    data: CircularProgressSegment
}
const Segment = ({ data }: SegmentProps) => {
    const radius = (pieChartSize - segmentStrokeWidth) / 2
    const circumference = radius * 2 * Math.PI

    const getFullStrokeDashArray = () => {
        if (data.percent) {
            let strokeDashArray = circumference * (data.percent / 100)
            if (data.percent < 100) {
                strokeDashArray -= strokeDashArrayPadding
                if (strokeDashArray < strokeDashArrayPadding) {
                    //Be sure to display circle for very low percentage. 98 vs 2 or 99 vs 1
                    strokeDashArray = strokeDashArrayPadding
                }
            }
            return strokeDashArray
        } else {
            return 0
        }
    }

    const animationKeyFrames = `
    @keyframes increaseProgress${data.name} {
      100% { 
        stroke-dasharray: ${getFullStrokeDashArray()}, ${circumference};
     }
    }`
    return (
        <g>
            <style children={animationKeyFrames} />
            <circle
                data-testid={`segment-${data.name}`}
                style={{
                    stroke: data.color,
                    fill: 'transparent',
                    transformOrigin: 'center',
                    strokeDasharray: `0, ${circumference}`,
                    animation: `${data.animationDuration}s increaseProgress${data.name} ${data.animationDelay}s forwards`
                }}
                cx="50%"
                cy="50%"
                r={radius}
                strokeWidth={segmentStrokeWidth}
                strokeDashoffset={`-${
                    data.offsetPercentStart ? circumference * (data.offsetPercentStart / 100) : 0
                }`}
            />
        </g>
    )
}
export default Segment
