import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ModelDeductibleFormRHFForm } from 'core/models/services/coverage/ModelDeductibleForm'
import PickerDay from 'modules/services/components/DatePicker/PickerDay/PickerDay'
import FormBlockWithLabel from 'modules/services/components/FormBlockWithLabel/FormBlockWithLabel'

const ModelDeductibleSimulationDate = (): JSX.Element => {
    const { t } = useTranslation()

    const { control } = useFormContext<ModelDeductibleFormRHFForm>()
    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <FormBlockWithLabel label="Simulation Date" isFirstChildInput>
                <PickerDay
                    placeholder={t('SERVICES.SELECT_A_DATE')}
                    testId="simulation-date"
                    control={control}
                    name="simulationDate"
                />
            </FormBlockWithLabel>
        </div>
    )
}

export default ModelDeductibleSimulationDate
