import navigationConstants, { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getCurrentYear } from 'core/utils/dateUtils'
import { isEmailValid } from 'core/utils/TextUtils'

const today = new Date()
const lastYear = today.getFullYear() - 1
const halfYear = new Date(getCurrentYear(), 6, 1)

interface WSToCallForForm {
    shouldUpdateDeviceInfoHeaders: boolean
    shouldFetchSummary: boolean
    shouldFetchProducts: boolean
    shouldFetchContactData: boolean
    shouldFetchFinancialData: boolean
    shouldFetchDoctors: boolean
    shouldFetchRequests: boolean
    shouldFetchPeriodicity: boolean
    shouldFetchAventuraPricings: boolean
    shouldFetchMissingData: boolean
}
const FORMS_ROUTES: { serviceType: ServiceType; url: string }[] = [
    {
        serviceType: ServiceType.NAME_CIVIL_CHANGE,
        url: SERVICES_FORM_ROUTES.PERSONAL_DATA_INFOS.url
    },
    {
        serviceType: ServiceType.EMAIL_CHANGE,
        url: SERVICES_FORM_ROUTES.PERSONAL_DATA_INFOS.url
    },
    {
        serviceType: ServiceType.PHONE_CHANGE,
        url: SERVICES_FORM_ROUTES.PERSONAL_DATA_INFOS.url
    },
    {
        serviceType: ServiceType.CERTIFICATE_CATTP,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_BILLED_PRIME.url
    },
    {
        serviceType: ServiceType.LPIMP_REQUEST,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_MEDICAL_FEES.url
    },
    {
        serviceType: ServiceType.CERTIFICATE_CATTI,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_INSURANCE_ATTESTATION.url
    },
    {
        serviceType: ServiceType.PROVI_REPL_CERTIFICATE,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_PROVISIONAL_REPLACEMENT_CERTIFICATE.url
    },
    {
        serviceType: ServiceType.COVER_CARD_ORDER,
        url: SERVICES_FORM_ROUTES.INSURANCE_CARD_ORDER.url
    },
    {
        serviceType: ServiceType.AVENTURA_INSURANCE_REQUEST,
        url: SERVICES_FORM_ROUTES.AVENTURA_INSURANCE_REQUEST.url
    },
    {
        serviceType: ServiceType.CDEC_REQUEST,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_STATEMENT_DETAILS.url
    },
    {
        serviceType: ServiceType.ADDRESS_CHANGE,
        url: SERVICES_FORM_ROUTES.PERSONAL_DATA_ADDRESS.url
    },
    {
        serviceType: ServiceType.BANK_REL_CHANGE,
        url: SERVICES_FORM_ROUTES.FINANCIAL_PAYMENT_CHANGE.url
    },
    {
        serviceType: ServiceType.PAYMENT_FREQ_MODIFICATION,
        url: SERVICES_FORM_ROUTES.FINANCIAL_PAYMENT_FREQ_MODIFICATION.url
    },
    {
        serviceType: ServiceType.FAMILY_DOCTOR_CHANGE,
        url: SERVICES_FORM_ROUTES.CONTRACT_FAMILY_DOCTOR.url
    },
    {
        serviceType: ServiceType.DEDUCTIBLE_CHANGE,
        url: SERVICES_FORM_ROUTES.COVERAGE_MODEL_DEDUCTIBLE.url
    },
    {
        serviceType: ServiceType.GENERAL_QUESTION,
        url: SERVICES_FORM_ROUTES.CONTACT_GENERAL_QUESTION.url
    },
    {
        serviceType: ServiceType.AUTO_LAMAL_ACC_SUSP_INCL,
        url: SERVICES_FORM_ROUTES.COVERAGE_LAMAL_ACCIDENT.url
    },
    {
        serviceType: ServiceType.CERTIFICATE_DELEGATION,
        url: SERVICES_FORM_ROUTES.CERTIFICATES_DELEGATION.url
    },
    {
        serviceType: ServiceType.FAMILY_GROUP_CHANGE,
        url: SERVICES_FORM_ROUTES.FAMILY_GROUP_ADD_MEMBER.url
    },
    {
        serviceType: ServiceType.HOSP_ANNOUNCEMENT,
        url: SERVICES_FORM_ROUTES.REPORT_HOSP_ANNOUNCEMENT.url
    },
    {
        serviceType: ServiceType.ACCIDENT_REPORT,
        url: SERVICES_FORM_ROUTES.ACCIDENT_REPORT.url
    },
    {
        serviceType: ServiceType.TRIP_CANCELLATION,
        url: SERVICES_FORM_ROUTES.REPORT_TRIP_CANCELLATION.url
    }
]

export const getFormRoute = (form: ServiceType): string => {
    const found = FORMS_ROUTES.find((x) => x.serviceType === form)
    if (found) return found.url
    return navigationConstants.SERVICES.url
}

export const getFormByRoute = (url: string): ServiceType | undefined => {
    const found = FORMS_ROUTES.find((x) => x.url.toLowerCase() === url.toLowerCase())
    return found?.serviceType
}

export const getWSToCallsForFormByServiceTypeAndSource = (form: ServiceType): WSToCallForForm => {
    const wsToCalls: WSToCallForForm = {
        shouldUpdateDeviceInfoHeaders: false,
        shouldFetchSummary: false,
        shouldFetchProducts: false,
        shouldFetchContactData: false,
        shouldFetchFinancialData: false,
        shouldFetchDoctors: false,
        shouldFetchRequests: true,
        shouldFetchPeriodicity: false,
        shouldFetchAventuraPricings: false,
        shouldFetchMissingData: false
    }
    if (Source() === ServicesSource.WEBSITE) {
        wsToCalls.shouldUpdateDeviceInfoHeaders = true
        wsToCalls.shouldFetchSummary = true
        wsToCalls.shouldFetchContactData = true

        switch (form) {
            case ServiceType.COVER_CARD_ORDER:
            case ServiceType.CERTIFICATE_CATTI:
                wsToCalls.shouldFetchMissingData = true
                wsToCalls.shouldFetchProducts = true
                break

            case ServiceType.AVENTURA_INSURANCE_REQUEST:
            case ServiceType.FAMILY_GROUP_CHANGE:
            case ServiceType.ADDRESS_CHANGE:
            case ServiceType.NAME_CIVIL_CHANGE:
            case ServiceType.EMAIL_CHANGE:
            case ServiceType.PHONE_CHANGE:
            case ServiceType.LPIMP_REQUEST:
            case ServiceType.CDEC_REQUEST:
            case ServiceType.CERTIFICATE_CATTP:
                wsToCalls.shouldFetchMissingData = true
                break

            case ServiceType.FAMILY_DOCTOR_CHANGE:
            case ServiceType.PROVI_REPL_CERTIFICATE:
            case ServiceType.AUTO_LAMAL_ACC_SUSP_INCL:
            case ServiceType.HOSP_ANNOUNCEMENT:
            case ServiceType.ACCIDENT_REPORT_BIS:
            case ServiceType.ACCIDENT_REPORT:
                wsToCalls.shouldFetchProducts = true
                break
        }
    }

    switch (form) {
        case ServiceType.FAMILY_DOCTOR_CHANGE:
            wsToCalls.shouldFetchDoctors = true
            break
        case ServiceType.GENERAL_QUESTION:
            wsToCalls.shouldFetchRequests = false
            break
        case ServiceType.PAYMENT_FREQ_MODIFICATION:
            wsToCalls.shouldFetchPeriodicity = true
            break
        case ServiceType.AVENTURA_INSURANCE_REQUEST:
            wsToCalls.shouldFetchAventuraPricings = true
    }

    return wsToCalls
}

export const getDefaultStartYear = (): number | undefined => {
    return today.getTime() - halfYear.getTime() < 0 ? lastYear : undefined
}

export const getDefaultEndYear = (): number => {
    return today.getFullYear()
}

export const shouldDisplayEmail = (email?: string | null): boolean => {
    let shouldDisplay = true
    if (Source() === ServicesSource.WEBSITE && (!email || !isEmailValid(email))) {
        shouldDisplay = false
    }
    return shouldDisplay
}
