import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import productsNamesConstants from 'core/constants/productsNamesConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import concatClassNames from 'core/utils/classNameUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import Icon, { ColorsEnum, IconNamesEnum } from 'shared/components/AssuraTabDropdown/Icon'
import { useHowItWorksPdfRedirection } from 'shared/components/BaseInsurance/TempHowItWorksPdfRedirect/useTempHowItWorksPdf'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'
import { getProducts } from 'shared/store/selectors/getProducts'

interface ReminderBlockProps {
    text: string
    classNames?: string
}

const ReminderBlock = ({ text, classNames }: ReminderBlockProps): JSX.Element => {
    const classes = concatClassNames(['d-flex w-100 bg-gray50 p-20', classNames])

    return (
        <div className={classes}>
            <Icon
                name={IconNamesEnum.infoCircle}
                color={ColorsEnum.error}
                size={16}
                dataId="symptom-checker-landing-page-reminder-icon"
            />
            <div className="paragraphSmall m-left-8">{text}</div>
        </div>
    )
}

const LandingPageQualiMedRules = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const digCliParameters = useSelector(getDigcliparameters)
    const products = useSelector(getProducts)

    const qualiMedProduct = products.find((product) => product.insuranceId === 'PAM')

    const { handleNavigateToAsset } = useHowItWorksPdfRedirection(qualiMedProduct)

    const qualimedReadMore = () => {
        sendEvent(analyticsConstants.EVENTS.OPEN_CCM, {
            [analyticsConstants.PARAMS.ENTRY_POINT]: entryPointsConstants.ENTRY_POINTS.SYMPTOM_CHECKER,
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: productsNamesConstants.PRODUCTS.QUALIMED,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
        if (digCliParameters?.isCCMWebviewActivated === 'true') {
            navigate(`${navigationConstants.LAMAL_RULES.url}?product=PAM`)
        } else {
            handleNavigateToAsset()
        }
    }

    return (
        <>
            <div className="titleSmall c-primary">{t('QUALIMED_RULES_REMIND.SUBTITLE')}</div>
            <ReminderBlock text={t('QUALIMED_RULES_REMIND.RULE_ONE')} classNames="m-top-20" />
            <ReminderBlock text={t('QUALIMED_RULES_REMIND.RULE_TWO')} classNames="m-top-8" />
            <AssuraIconButton
                id="symptom-checker-landing-page-more"
                icon="assura-external-link"
                variant="primary"
                size="small"
                classNames="align-self-end m-top-20"
                label={t('COMMON.LEARN_MORE')}
                onClick={qualimedReadMore}
            />
            <div className="headlineSmall m-top-32">{t('COMMON.USEFUL_INFO')}</div>
        </>
    )
}

export default LandingPageQualiMedRules
