import { useEffect, useRef } from 'react'

import { convertDataURIToBinary } from 'core/helpers/PdfAnalyzer'
import * as pdfjs from 'pdfjs-dist'

interface AssuraPdfThumbnailProps {
    base64: string
}

const AssuraPdfThumbnail = ({
    base64
}: AssuraPdfThumbnailProps): JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const renderPdf = async () => {
            const pdfAsArray = convertDataURIToBinary(base64)

            const pdf = await pdfjs.getDocument(pdfAsArray).promise
            const page = await pdf.getPage(1)
            const viewport = page.getViewport({ scale: 1 })

            const canvas = canvasRef.current
            if (canvas) {
                const canvasContext = canvas.getContext('2d')
                canvas.height = viewport.height
                canvas.width =  viewport.width

                if (canvasContext) {
                    const renderContext = { canvasContext, viewport }
                    page.render(renderContext)
                }
            }
        }

        renderPdf().catch((e) => console.error(e))
    }, [base64])

    return <canvas ref={canvasRef} style={{width: '100%'}}/>
}

export default AssuraPdfThumbnail
