import { useState } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import { AssuraNumberInputRHF } from 'modules/services/components/NumberInputRHF/NumberInputRHF'
import { getHeadOfFamily } from 'shared/store/general/general.slice'

import { ErrorMessage } from '@hookform/error-message'

import DeleteAccountFormCta from './DeleteAccountFormCta'

const DeleteAccountForm = (): JSX.Element => {
    const { t } = useTranslation()

    const headOfFamily = useSelector(getHeadOfFamily)

    const {
        setValue,
        register,
        formState: { errors }
    } = useFormContext()

    const [isFocused, setisFocused] = useState(false)

    const getPolicyNumberRules = (): Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    > => {
        return {
            required: {
                value: true,
                message: t('COMMON.MANDATORY_FIELD')
            },
            validate: (value: string): boolean | string => {
                return (
                    value === (headOfFamily?.policyNumber || '').toString() ||
                    t('DELETE_ACCOUNT.FORM_POLICY_NUMBER_ERROR')
                )
            }
        }
    }

    const handleOnClear = () => {
        setValue('policyNumber', '')
    }

    return (
        <>
            <div className="d-flex flex-column bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
                <AssuraNumberInputRHF
                    id="delete-account-policy-number"
                    name="policyNumber"
                    label={t('DELETE_ACCOUNT.FORM_POLICY_NUMBER')}
                    placeHolder={t('LOGIN.POLICE_NUMBER_PLACEHOLDER')}
                    isInvalid={Boolean(errors?.policyNumber)}
                    error={t(errors?.policyNumber?.message as string)}
                    hasButton={isFocused}
                    onFocus={() => setisFocused(true)}
                    onBlur={() => setisFocused(false)}
                    onButtonClick={handleOnClear}
                    iconClass="icon assura-close size-24"
                    register={register}
                    rules={getPolicyNumberRules()}
                />
                <CheckboxRHF
                    id="delete-account-consent"
                    name="hasConsent"
                    label={t('DELETE_ACCOUNT.FORM_CONSENT_LABEL')}
                    register={register}
                    labelClassNames="paragraphSmallLight"
                    classNames="flex-1 m-top-48"
                    rules={{
                        required: {
                            value: true,
                            message: 'COMMON.MANDATORY_FIELD'
                        }
                    }}
                />
                <ErrorMessage
                    name="hasConsent"
                    render={({ message }) => (
                        <div
                            className="labelExtraSmall c-primary m-top-8"
                            data-testid="delete-account-consent-error"
                        >
                            {t(message)}
                        </div>
                    )}
                />
            </div>
            <DeleteAccountFormCta />
        </>
    )
}

export default DeleteAccountForm
