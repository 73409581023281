import './EnveloppesSubmission.css'

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { Source } from 'core/enums/ServicesSource'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { FaqGlobalItem } from 'core/models/Faq'
import useWindowSize from 'core/services/useWindowSize'
import FamilyGroup, { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import GoodToKnow from 'modules/services/components/GoodToKnow/GoodToKnow'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getSummaryByPage } from 'shared/store/combineSelectors'
import {
    getRescanImagesSelectedPolicyNumber,
    setRescanImagesSelectedPolicyNumber
} from 'shared/store/enveloppes/enveloppes.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import EnveloppesSubmissionForm from '../components/EnveloppesSubmissionForm/EnveloppesSubmissionForm'

export interface EnveloppesSubmissionFormRhf {
    policyNumber?: number
    name: string
    documents: DroppedFile[]
}

const EnveloppesSubmission = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const dispatch = useDispatch()
    const familySummary = useSelector(getSummaryByPage)
    const policyNumber = useSelector(getRescanImagesSelectedPolicyNumber)
    const [currentPolicyNumber, setCurrentPolicyNumber] = useState(0)
    const insuredPersons = familySummary?.insuredPersons

    useEffect(() => {
        if (policyNumber === 0) return
        setCurrentPolicyNumber(policyNumber)
        dispatch(setRescanImagesSelectedPolicyNumber(0))
    }, [policyNumber])

    const defaultValues: EnveloppesSubmissionFormRhf = {
        policyNumber: undefined,
        name: '',
        documents: []
    }

    const methods = useForm<EnveloppesSubmissionFormRhf>({
        defaultValues
    })

    const selectMember = (datas: FamilyGroupResult) => {
        const member = insuredPersons.find((m) => m.policyNumber === datas.selectedPolicyNumber)
        if (member) {
            methods.setValue('policyNumber', member.policyNumber)
            methods.clearErrors('policyNumber')
        }
    }

    // TEMPORARY: To hide "Need help" button
    const needHelpTranslationKey = 'ENVELOPPES.SUBMISSION_GOOD_TO_KNOW_NEED_HELP'

    useEffect(() => {
        if (insuredPersons.length === 1)
            methods.reset({
                ...defaultValues,
                policyNumber: insuredPersons[0].policyNumber
            })
    }, [])

    const titleFeatureSubTitleRowKey = 'ENVELOPPES.SEND_ENVELOPPE_NEW_FEATURE_ROW_1'
    const titleFeatureSubTitleRowKey2 = 'ENVELOPPES.SEND_ENVELOPPE_NEW_FEATURE_ROW_2'
    const subtitle1 = t(titleFeatureSubTitleRowKey)
    const subtitle2 = t(titleFeatureSubTitleRowKey2)

    const hasSubTitles =
        !!subtitle1 &&
        subtitle1 !== titleFeatureSubTitleRowKey &&
        !!subtitle2 &&
        subtitle2 !== titleFeatureSubTitleRowKey2

    const displaySubTitles = () => (
        <>
            <p className="paragraph c-black" data-testid="enveloppes-submission-sub-title-row-1">
                {t('ENVELOPPES.SEND_ENVELOPPE_NEW_FEATURE_ROW_1')}
            </p>
            <p
                className="paragraph c-black m-bottom-56"
                data-testid="enveloppes-submission-sub-title-row-2"
            >
                {t('ENVELOPPES.SEND_ENVELOPPE_NEW_FEATURE_ROW_2')}
            </p>
        </>
    )

    return (
        <FullScreenContainer color="gray20">
            <Container className="position-relative">
                <Row className="enveloppes-submit-main">
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} xl={7}>
                        {!isMobile && (
                            <>
                                <p
                                    className={`title c-black ${
                                        hasSubTitles ? 'm-bottom-24' : 'm-bottom-56'
                                    }`}
                                    data-testid="enveloppes-submission-title"
                                >
                                    {t('ENVELOPPES.SEND_ENVELOPPE')}
                                </p>
                                {hasSubTitles && displaySubTitles()}
                            </>
                        )}
                        <FormProvider {...methods}>
                            <Controller
                                name="policyNumber"
                                render={({ field: { ref, ...restField }, fieldState }) => (
                                    <div
                                        className={`m-bottom-32 ${
                                            currentPolicyNumber !== 0 && 'd-none'
                                        }`}
                                        {...restField}
                                        ref={ref}
                                    >
                                        <FamilyGroup
                                            selectAction={selectMember}
                                            selectedMemberError={t(
                                                fieldState.error?.message as string
                                            )}
                                            source={Source()}
                                            defaultSelectedPolicyNumber={currentPolicyNumber}
                                        />
                                    </div>
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'COMMON.MANDATORY_FIELD'
                                    }
                                }}
                            />
                            <EnveloppesSubmissionForm />
                        </FormProvider>
                    </Col>
                    <Col
                        xs={{ span: 12, order: 1 }}
                        md={{ span: 4, order: 2 }}
                        xl={{ span: 4, offset: 1 }}
                    >
                        {isMobile && (
                            <>
                                <p
                                    className={`title c-black ${
                                        hasSubTitles ? 'm-bottom-24' : 'm-bottom-56'
                                    }`}
                                    data-testid="enveloppes-submission-title"
                                >
                                    {t('ENVELOPPES.SEND_ENVELOPPE')}
                                </p>
                                {hasSubTitles && displaySubTitles()}
                            </>
                        )}
                        <GoodToKnow
                            goodToKnowId="enveloppeSubmission"
                            needHelpProps={{
                                query: {
                                    categoryId: 'Category_EnvoiJustificatifs',
                                    questionId: 'Question_TutorielEnvoiJustificatifs'
                                } as FaqGlobalItem,
                                ctaTranslationKey: needHelpTranslationKey
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </FullScreenContainer>
    )
}

export default withAITracking(
    reactPlugin,
    EnveloppesSubmission,
    'EnveloppesSubmission',
    'enveloppes-submission-container'
)
