/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import { AccountNumberChecked } from 'core/models/services/financial/PaymentChange'

// Check the syntax and the checksum of the IBAN. If IBAN is OK, reformat field
export default function checkIban(field: string | null, isPublic: boolean): AccountNumberChecked {
    const rules = new Map([
        ['country', false],
        ['length', false],
        ['format', false]
    ])
    const res = { rules, value: field }

    if (field !== null) {
        const iban = intern(field).toUpperCase()

        const code = iban.substring(0, 2)
        const bban = iban.substring(4)
        const country = CountryData(code)

        res.rules.set('country', country !== null)

        if (country) {
            //Cas Public et IBAN suisse
            if (isPublic && country.code !== 'CH') res.rules.set('country', false)

            res.rules.set('length', !InvalidIBANlength(country, iban))

            if (res.rules.get('length')) {
                const bank_lng = country.bank_lng
                const bank = bban.substring(0, bank_lng)
                const account = bban.substring(bank_lng)

                if (!InvalidBank(country, bank))
                    res.rules.set('format', !InvalidAccount(country, account) && IBANokay(iban))
            }
        }

        if (IBANokay(iban)) res.value = extern(iban)
    }

    return res
}

export const formatIban = (field: string): string => {
    return extern(intern(field).toUpperCase())
}

////////////////////////// Private functions /////////////////////////

class Country {
    name: string
    code: string
    bank: [number, string][]
    acc: [number, string][]
    bank_lng: number
    acc_lng: number
    total_lng: number
    // Constructor for Country objects.
    //
    // Arguments:
    //   name      - Name of the country
    //   code      - Country Code from ISO 3166
    //   bank_form - Format of bank/branch code part (e.g. "0 4a 0 ")
    //   acc_form  - Format of account number part (e.g. "0  11  2n")

    constructor(name: string, code: string, bank_form: string, acc_form: string) {
        this.name = name
        this.code = code
        this.bank = Country_decode_format(bank_form)
        this.acc = Country_decode_format(acc_form)
        this.bank_lng = Country_calc_length(this.bank)
        this.acc_lng = Country_calc_length(this.acc)
        this.total_lng = 4 + this.bank_lng + this.acc_lng

        function Country_decode_format(form: string): [number, string][] {
            const form_list: [number, string][] = []
            const parts = form.split(' ')
            for (let i = 0; i < parts.length; ++i) {
                let part = parts[i]
                if (part != '') {
                    let typ = part.charAt(part.length - 1)
                    if (typ == 'a' || typ == 'n') part = part.substring(0, part.length - 1)
                    else typ = 'c'
                    const lng = parseInt(part)
                    form_list.push([lng, typ])
                }
            }
            return form_list
        }

        function Country_calc_length(form_list: [number, string][]) {
            let sum = 0
            for (let i = 0; i < form_list.length; ++i) sum += form_list[i][0]
            return sum
        }
    }
}

// BBAN data from ISO 13616, Country codes from ISO 3166 (www.iso.org).
const iban_data = [
    new Country('Andorra', 'AD', '0  4n 4n', '0  12   0 '),
    new Country('Albania', 'AL', '0  8n 0 ', '0  16   0 '),
    new Country('Austria', 'AT', '0  5n 0 ', '0  11n  0 '),
    new Country('Bosnia and Herzegovina', 'BA', '0  3n 3n', '0   8n  2n'),
    new Country('Belgium', 'BE', '0  3n 0 ', '0   7n  2n'),
    new Country('Bulgaria', 'BG', '0  4a 4n', '2n  8   0 '),
    new Country('Switzerland', 'CH', '0  5n 0 ', '0  12   0 '),
    new Country('Cyprus', 'CY', '0  3n 5n', '0  16   0 '),
    new Country('Czech Republic', 'CZ', '0  4n 0 ', '0  16n  0 '),
    new Country('Germany', 'DE', '0  8n 0 ', '0  10n  0 '),
    new Country('Denmark', 'DK', '0  4n 0 ', '0   9n  1n'),
    new Country('Estonia', 'EE', '0  2n 0 ', '2n 11n  1n'),
    new Country('Spain', 'ES', '0  4n 4n', '2n 10n  0 '),
    new Country('Finland', 'FI', '0  6n 0 ', '0   7n  1n'),
    new Country('Faroe Islands', 'FO', '0  4n 0 ', '0   9n  1n'),
    new Country('France', 'FR', '0  5n 5n', '0  11   2n'),
    new Country('United Kingdom', 'GB', '0  4a 6n', '0   8n  0 '),
    new Country('Gibraltar', 'GI', '0  4a 0 ', '0  15   0 '),
    new Country('Greenland', 'GL', '0  4n 0 ', '0   9n  1n'),
    new Country('Greece', 'GR', '0  3n 4n', '0  16   0 '),
    new Country('Croatia', 'HR', '0  7n 0 ', '0  10n  0 '),
    new Country('Hungary', 'HU', '0  3n 4n', '1n 15n  1n'),
    new Country('Ireland', 'IE', '0  4a 6n', '0   8n  0 '),
    new Country('Israel', 'IL', '0  3n 3n', '0  13n  0 '),
    new Country('Iceland', 'IS', '0  4n 0 ', '2n 16n  0 '),
    new Country('Italy', 'IT', '1a 5n 5n', '0  12   0 '),
    new Country('Liechtenstein', 'LI', '0  5n 0 ', '0  12   0 '),
    new Country('Lithuania', 'LT', '0  5n 0 ', '0  11n  0 '),
    new Country('Luxembourg', 'LU', '0  3n 0 ', '0  13   0 '),
    new Country('Latvia', 'LV', '0  4a 0 ', '0  13   0 '),
    new Country('Monaco', 'MC', '0  5n 5n', '0  11   2n'),
    new Country('Montenegro', 'ME', '0  3n 0 ', '0  13n  2n'),
    new Country('Macedonia, Former Yugoslav Republic of', 'MK', '0  3n 0 ', '0  10   2n'),
    new Country('Malta', 'MT', '0  4a 5n', '0  18   0 '),
    new Country('Mauritius', 'MU', '0  4a 4n', '0  15n  3a'),
    new Country('Netherlands', 'NL', '0  4a 0 ', '0  10n  0 '),
    new Country('Norway', 'NO', '0  4n 0 ', '0   6n  1n'),
    new Country('Poland', 'PL', '0  8n 0 ', '0  16n  0 '),
    new Country('Portugal', 'PT', '0  4n 4n', '0  11n  2n'),
    new Country('Romania', 'RO', '0  4a 0 ', '0  16   0 '),
    new Country('Serbia', 'RS', '0  3n 0 ', '0  13n  2n'),
    new Country('Saudi Arabia', 'SA', '0  2n 0 ', '0  18   0 '),
    new Country('Sweden', 'SE', '0  3n 0 ', '0  16n  1n'),
    new Country('Slovenia', 'SI', '0  5n 0 ', '0   8n  2n'),
    new Country('Slovak Republic', 'SK', '0  4n 0 ', '0  16n  0 '),
    new Country('San Marino', 'SM', '1a 5n 5n', '0  12   0 '),
    new Country('Tunisia', 'TN', '0  2n 3n', '0  13n  2n'),
    new Country('Turkey', 'TR', '0  5n 0 ', '1  16   0 ')
]

// Search the country code in the iban_data list.
function CountryData(code: string) {
    for (let i = 0; i < iban_data.length; ++i) if (iban_data[i].code == code) return iban_data[i]
    return null
}

// Modulo 97 for huge numbers given as digit strings.
function mod97(digit_string: string) {
    let m = 0
    for (let i = 0; i < digit_string.length; ++i)
        m = (m * 10 + parseInt(digit_string.charAt(i))) % 97
    return m
}

// Convert a capital letter into digits: A -> 10 ... Z -> 35 (ISO 13616).
function capital2digits(ch: string) {
    const capitals = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let i = 0
    for (i; i < capitals.length; ++i) if (ch == capitals.charAt(i)) break
    return i + 10
}

// Fill the string with leading zeros until length is reached.
function fill0(s: string, l: number) {
    while (s.length < l) s = '0' + s
    return s
}

// Calculate 2-digit checksum of an IBAN.
function ChecksumIBAN(iban: string) {
    const code = iban.substring(0, 2)
    const checksum = iban.substring(2, 4)
    const bban = iban.substring(4)

    // Assemble digit string
    let digits = ''
    for (let i = 0; i < bban.length; ++i) {
        const ch = bban.charAt(i).toUpperCase()
        if ('0' <= ch && ch <= '9') digits += ch
        else digits += capital2digits(ch)
    }
    for (let i = 0; i < code.length; ++i) {
        const ch = code.charAt(i)
        digits += capital2digits(ch)
    }
    digits += checksum

    // Calculate checksum
    const checksumNumber = 98 - mod97(digits)
    return fill0('' + checksumNumber, 2)
}

// Fill the account number part of IBAN with leading zeros.
function FillAccount(country: Country, account: string) {
    return fill0(account, country.acc_lng)
}

// Check if syntax of the part of IBAN is invalid.
function InvalidPart(form_list: [number, string][], iban_part: string) {
    for (let f = 0; f < form_list.length; ++f) {
        let lng = form_list[f][0]
        const typ = form_list[f][1]
        if (lng > iban_part.length) lng = iban_part.length
        for (let i = 0; i < lng; ++i) {
            const ch = iban_part.charAt(i)
            const a = 'A' <= ch && ch <= 'Z'
            const n = '0' <= ch && ch <= '9'
            const c = n || a || ('a' <= ch && ch <= 'z')
            if ((!c && typ == 'c') || (!a && typ == 'a') || (!n && typ == 'n')) return true
        }
        iban_part = iban_part.substring(lng)
    }
    return false
}

// Check if length of the bank/branch code part of IBAN is invalid.
function InvalidBankLength(country: Country, bank: string) {
    return bank.length != country.bank_lng
}

// Check if syntax of the bank/branch code part of IBAN is invalid.
function InvalidBank(country: Country, bank: string) {
    return InvalidBankLength(country, bank) || InvalidPart(country.bank, bank)
}

// Check if length of the account number part of IBAN is invalid.
function InvalidAccountLength(country: Country, account: string) {
    return account.length < 1 || account.length > country.acc_lng
}

// Check if syntax of the account number part of IBAN is invalid.
function InvalidAccount(country: Country, account: string) {
    return (
        InvalidAccountLength(country, account) ||
        InvalidPart(country.acc, FillAccount(country, account))
    )
}

// Check if length of IBAN is invalid.
function InvalidIBANlength(country: Country, iban: string) {
    return iban.length != country.total_lng
}

// Convert iban from intern value to string format (IBAN XXXX XXXX ...).
function extern(intern: string) {
    let s = ''
    for (let i = 0; i < intern.length; ++i) {
        if (i % 4 == 0 && i > 0) s += ' '
        s += intern.charAt(i)
    }
    return s
}

// Convert iban from string format to intern value.
function intern(extern: string) {
    if (extern.substring(0, 4) == 'IBAN') extern = extern.substring(4)
    let s = ''
    for (let i = 0; i < extern.length; ++i) if (extern.charAt(i) != ' ') s += extern.charAt(i)
    return s
}

// Check the checksum of an IBAN.
function IBANokay(iban: string) {
    return ChecksumIBAN(iban) == '97'
}

// http://www.iban-rechner.eu/ibancalculator/iban.html
