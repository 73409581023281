import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import {
    HomeNotificationCommunicationGroup,
    HomeNotificationsCombinedCardState
} from 'core/models/HomeNotifications'

import { createSelector } from '@reduxjs/toolkit'

import { getHomeNotificationCommunications } from './getHomeNotifications'
import { getInvoicesToCheckNotificationStats } from './getInvoicesToCheckNotifications'

export const getHomeNotificationCommunicationsAndInvoices = createSelector(
    [getHomeNotificationCommunications, getInvoicesToCheckNotificationStats],
    (
        getHomeNotificationCommunications,
        getInvoicesToCheckNotificationStats
    ): HomeNotificationsCombinedCardState => {
        const loadingStatus = getLoadingStatusFromLoaderList([
            getInvoicesToCheckNotificationStats.loadingStatus,
            getHomeNotificationCommunications.loadingStatus
        ])

        const itcCount =
            getInvoicesToCheckNotificationStats.data?.count &&
            getInvoicesToCheckNotificationStats.data?.count > 0

        return {
            loadingStatus: loadingStatus,
            data: {
                hasInvoicesToCheck: itcCount,
                communication: getHomeNotificationCommunications.data,
                invoicesToCheck: getInvoicesToCheckNotificationStats.data
            } as HomeNotificationCommunicationGroup
        }
    }
)
