import * as React from 'react'
import { useTranslation } from 'react-i18next'

import doctorIcon from 'shared/assets/images/doctor.png'

const SearchDoctorNoResults = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="m-top-48 p-top-56 p-bottom-56 d-flex flex-column align-items-center">
            <div className="d-flex flex-column align-items-center">
                <img src={doctorIcon} className="image-size-56" alt="doctor-icon" />
                <div className="paragraphSmall m-top-24">
                    {t('SERVICES.SEARCH_DOCTOR_NO_RESULTS')}
                </div>
            </div>
            <div className="m-top-56">
                <div className="paragraphSmall">
                    {t('SERVICES.SEARCH_DOCTOR_NO_RESULTS_DETAILS')}
                </div>
                <div className="paragraphSmallLight">{`\u25CF ${t(
                    'SERVICES.SEARCH_DOCTOR_NO_RESULTS_DETAILS_1'
                )}`}</div>
                <div className="paragraphSmallLight">{`\u25CF ${t(
                    'SERVICES.SEARCH_DOCTOR_NO_RESULTS_DETAILS_2'
                )}`}</div>
                <div className="paragraphSmallLight">
                    {`\u25CF ${t('SERVICES.SEARCH_DOCTOR_NO_RESULTS_DETAILS_3')}`}
                </div>
            </div>
        </div>
    )
}

export default SearchDoctorNoResults
