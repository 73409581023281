import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'

interface AssuraTableMoreButtonProps {
    paginationStatus: LoadingStatusEnum
    onMoreSelection?: () => void
    hasMore?: boolean
}

const AssuraTableMoreButton = ({
    paginationStatus,
    onMoreSelection,
    hasMore
}: AssuraTableMoreButtonProps): JSX.Element | null => {
    const { t } = useTranslation()

    return hasMore && !!onMoreSelection ? (
        <td
            className="d-flex justify-content-center align-items-center m-top-28"
            style={{ minHeight: '50px' }}
        >
            <AssuraLoadAndError status={paginationStatus} defaultReloadAction={onMoreSelection}>
                <AssuraButton
                    text={t('COMMON.SEE_MORE')}
                    id="assura-table-more-button"
                    variant="secondary"
                    onClick={() => onMoreSelection()}
                />
            </AssuraLoadAndError>
        </td>
    ) : null
}

export default AssuraTableMoreButton
