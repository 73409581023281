import { RequestStatus } from 'core/enums/RequestStatus'
import { DateFilterSelectionValue } from 'core/helpers/CommonAssuraTableFilters'
import { RequestRow } from 'core/models/services/RequestData'
import { TableFilterState } from 'core/models/TableFilter'
/* eslint-disable sonarjs/cognitive-complexity */
import { isAfter } from 'date-fns'

import { RequestsFilterSelectionValue } from './RequestsTable'

export const getFilteredRequests = (
    requests: RequestRow[],
    filterState: TableFilterState<RequestsFilterSelectionValue>
): RequestRow[] => {
    let newRequests = [...requests]

    // Year filter
    const yearSelections = filterState['date']?.selections

    if (yearSelections) {
        for (const selection of yearSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as DateFilterSelectionValue
                newRequests = newRequests.filter((row) => {
                    return (
                        Boolean(row.creationDate) &&
                        isAfter(new Date(row.creationDate ?? ''), value.startDate) &&
                        isAfter(value.endDate, new Date(row.creationDate ?? ''))
                    )
                })
            }
        }
    }

    // Member filter
    const memberSelections = filterState['member']?.selections

    if (memberSelections) {
        for (const selection of memberSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as number
                newRequests = newRequests.filter((row) => row.policyNumber === value)
            }
        }
    }

    // Status filter
    const statusSelections = filterState['status']?.selections

    if (statusSelections) {
        for (const selection of statusSelections.values()) {
            if (selection.isSelected) {
                const value = selection.value as RequestStatus
                newRequests = newRequests.filter((row) => row.status === value)
            }
        }
    }

    return newRequests
}
