import React, { ClipboardEvent } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

import { withButton } from 'shared/hoc/WithButton'
import { withError } from 'shared/hoc/WithError'
import { withIconButton } from 'shared/hoc/WithIconButton'
import { withLabel } from 'shared/hoc/WithLabel'
import { withLeftIcon } from 'shared/hoc/WithLeftIcon'
import { withRightIcon } from 'shared/hoc/WithRightIcon'

export interface InputProps {
    id: string
    name: string
    isInvalid: boolean
    onBlur?: (value: string | number | undefined | unknown) => void
    onFocus?: () => void
    disabled?: boolean
    readOnly?: boolean
    maxLength?: number | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: UseFormRegister<FieldValues | any>
    rules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    placeHolder?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onPaste?: (e: ClipboardEvent) => void
    type?: string
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    autocomplete?: 'on' | 'off'
}

const InputRHF = ({ autocomplete = 'on', ...props }: InputProps): JSX.Element => {
    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            props.onBlur(event.currentTarget.value ?? '')
        }
        props.register(props.name, { ...props.rules }).onBlur(event)
    }

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus()
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event)
        } else {
            props.register(props.name, { ...props.rules }).onChange(event)
        }
    }

    return (
        <input
            id={props.id}
            data-testid={props.id}
            className={`label form-control${props.isInvalid ? ' is-invalid' : ''}`}
            disabled={props.disabled}
            readOnly={props.readOnly}
            {...props.register(props.name, { ...props.rules })}
            onChange={onChange}
            onInput={props.onInput}
            onBlur={onBlur}
            onFocus={onFocus}
            onPaste={props.onPaste}
            maxLength={props.maxLength}
            autoComplete={autocomplete}
            placeholder={props.placeHolder}
            type={props.type ? props.type : 'text'}
            onKeyDown={props.onKeyDown}
        />
    )
}

export default InputRHF

export const AssuraFormInputRHF = withLabel(withButton(withError(InputRHF)))
export const AssuraFormInputIconButtonRHF = withLabel(withIconButton(withError(InputRHF)))
export const AssuraFormInputRHFNoLabel = withButton(withError(InputRHF))
export const AssuraFormInputRHFNoLabelWithLeftIcon = withButton(withLeftIcon(withError(InputRHF)))
export const AssuraFormInputRHFLabelWithRightIcon = withLabel(
    withButton(withRightIcon(withError(InputRHF)))
)
export const AssuraFormInputRHFButton = withButton(InputRHF)
