import './ProvisionalCertificate.css'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxRHF from '../CheckboxRHF/CheckboxRHF'

const ProvisionalCertificate = (): JSX.Element => {
    const { register } = useFormContext()
    const { t } = useTranslation()

    return (
        <div className="provisional-certificate-container bg-white bc-gray100 m-top-32 p-32">
            <div className="paragraphMedium">
                {t('SERVICES.REQUEST_TYPE_PROVI_REPL_CERTIFICATE')}
            </div>

            <div className="paragraphSmallLight m-top-24">
                {t('SERVICES.INSURANCE_CARD_PROVISIONAL_LABEL')}
            </div>
            <div className="provisional-certificate-separator bg-gray100 m-top-32"></div>
            <CheckboxRHF
                id="provisionalCertificate"
                name="provisionalCertificate"
                label={t('SERVICES.INSURANCE_CARD_PROVISIONAL_DL')}
                register={register}
                classNames="m-top-32"
                labelClassNames="paragraphSmallLight"
            />
        </div>
    )
}

export default ProvisionalCertificate
