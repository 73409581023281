import './ProvisionalReplacementCertificate.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import {
    ProvisionalReplacementCertificateForm,
    ProvisionalReplacementCertificateFormRHF
} from 'core/models/services/certificates/ProvisionalReplacementCertificateForm'
import { getCurrentYear } from 'core/utils/dateUtils'
import { addMonths, subYears } from 'date-fns'
import EndDate from 'modules/services/components/EndDate/EndDate'
import StartDate from 'modules/services/components/StartDate/StartDate'
import { fetchProductsByMemberAndByYear } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getSummaryCurrentYear } from 'shared/store/selectors/getSummaryCurrentYear'
import {
    getHasActiveLamalOfSelectedFamilyMember,
    getHasActiveLamalOfSelectedFamilyMemberLastYear,
    getHasActiveLamalOfSelectedFamilyMemberNextYear
} from 'shared/store/selectors/services/getHasActiveContractOfSelectedFamilyMember'
import { onSubmitProvisionalReplacementCertificate } from 'shared/store/services/certificates/certificates.slice'
import { getFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { FamilyGroupResult } from '../../components/FamilyGroup/FamilyGroup'
import FormPage from '../../components/FormPage/FormPage'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import NoActiveContract from '../../components/NoActiveContract/NoActiveContract'

const ProvisionalReplacementCertificate = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const memberList = useSelector(getSummaryCurrentYear).insuredPersons
    const hasActiveContract = useSelector(getHasActiveLamalOfSelectedFamilyMember)
    const hadActiveContract = useSelector(getHasActiveLamalOfSelectedFamilyMemberLastYear)
    const willHaveActiveContract = useSelector(getHasActiveLamalOfSelectedFamilyMemberNextYear)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)
    const familyGroupResult = useSelector(getFamilyGroupResult)

    const selectedMemberIndexForReload = memberList.findIndex(
        (member) => member.policyNumber === familyGroupResult.selectedPolicyNumber
    )

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        if (familyGroupResult.selectedPolicyNumber) {
            const selectedMemberIndex = memberList.findIndex(
                (member) => member.policyNumber === familyGroupResult.selectedPolicyNumber
            )
            dispatch(
                fetchProductsByMemberAndByYear({
                    index: selectedMemberIndex,
                    year: getCurrentYear()
                })
            )
            dispatch(
                fetchProductsByMemberAndByYear({
                    index: selectedMemberIndex,
                    year: getCurrentYear() - 1
                })
            )
            dispatch(
                fetchProductsByMemberAndByYear({
                    index: selectedMemberIndex,
                    year: getCurrentYear() + 1
                })
            )
        }
    }

    const onSuccess = (values: ProvisionalReplacementCertificateFormRHF) => {
        const provisionalReplacementCertificateForm: ProvisionalReplacementCertificateForm = {
            ...values,
            ...familyGroupResult
        }
        dispatch(onSubmitProvisionalReplacementCertificate(provisionalReplacementCertificateForm))
    }

    const minValidityStartDate = () => {
        const today = new Date()
        const oneYearAgo = subYears(today, 1)
        if (hadActiveContract && oneYearAgo.getFullYear() === getCurrentYear() - 1) {
            return oneYearAgo
        }
        if (hasActiveContract) {
            return new Date(getCurrentYear(), 0, 1)
        }
        if (willHaveActiveContract) {
            return new Date(getCurrentYear() + 1, 0, 1)
        }
        return null
    }

    const maxValidityStartDate = () => {
        const today = new Date()
        const threeMonthsLater = addMonths(today, 3)
        if (
            (hasActiveContract && threeMonthsLater.getFullYear() === today.getFullYear()) ||
            (willHaveActiveContract && threeMonthsLater.getFullYear() === today.getFullYear() + 1)
        ) {
            return threeMonthsLater
        }
        if (hasActiveContract) {
            return new Date(getCurrentYear(), 12, 31)
        }
        return null
    }

    const minValidityEndDate = (startDate: Date | undefined): Date | undefined => {
        if (!startDate) return undefined
        return startDate
    }

    const maxValidityEndDate = (startDate: Date | undefined): Date | undefined => {
        if (!startDate) return undefined
        const threeMonthAfter = addMonths(startDate, 3)
        const today = new Date()
        if (
            (threeMonthAfter.getFullYear() === today.getFullYear() + 1 && willHaveActiveContract) ||
            (threeMonthAfter.getFullYear() === today.getFullYear() && hasActiveContract)
        ) {
            return threeMonthAfter
        }
        return new Date(today.getFullYear(), 11, 31)
    }

    const isSupervisionMode = Source() === ServicesSource.BACKOFFICE

    return (
        <FormPage
            _defaultValues={{}}
            serviceType={ServiceType.PROVI_REPL_CERTIFICATE}
            formTitle={{
                title: t('SERVICE.REQUEST_PROVISIONAL_REPLACEMENT_CERTIFICATE'),
                category: t('SERVICE.ATTESTATIONS_AND_DOCUMENTS')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.PROVI_REPL_CERTIFICATE} />}
            notAllowed={
                (!isSupervisionMode && !hasActiveContract) ||
                (isSupervisionMode &&
                    (minValidityStartDate() === null || maxValidityStartDate() === null)) ? (
                    <NoActiveContract />
                ) : undefined
            }
            dataLoadingStatus={{
                status: productsLoaderStatus,
                defaultReloadAction: () =>
                    dispatch(
                        fetchProductsByMemberAndByYear({
                            index: selectedMemberIndexForReload,
                            year: getCurrentYear(),
                            reload: true
                        })
                    )
            }}
            submitButtonText={'COMMON.DOWNLOAD_DOCUMENT'}
        >
            {isSupervisionMode && (
                <>
                    <StartDate
                        name="validityStartDate"
                        minDate={minValidityStartDate()}
                        maxDate={maxValidityStartDate()}
                        testId="validity-start-date"
                        rules={{
                            required: {
                                value: true,
                                message: 'COMMON.MANDATORY_FIELD'
                            }
                        }}
                    />
                    <EndDate
                        name="validityEndDate"
                        testId="validity-end-date"
                        rules={{
                            required: {
                                value: true,
                                message: 'COMMON.MANDATORY_FIELD'
                            }
                        }}
                        startDateName="validityStartDate"
                        setMinimumDate={minValidityEndDate}
                        setMaximumDate={maxValidityEndDate}
                    />
                </>
            )}
            <div className="paragraphLight m-top-32">{t('SERVICES.DOCUMENT_IS_DOWNLOADABLE')}</div>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    ProvisionalReplacementCertificate,
    'ProvisionalReplacementCertificate',
    'provisional-replacement-certificate-container'
)
