import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ProductTypeCmsEnum } from 'core/enums/Product'
import { getCostsLabels, getReceiptCostsValues } from 'core/helpers/ClaimsSettlementPanelHelper'
import {
    ClaimsSettlementReceipt,
    ClaimsSettlementReceiptCostsSharedDetailsLca
} from 'core/models/documents/ClaimsSettlementReceipt'
import { formatNumber } from 'core/utils/TextUtils'
import { getProducts } from 'shared/store/selectors/getProducts'

import ClaimsSettlementReceiptPanelAmountCard from './ClaimsSettlementReceiptPanelAmountCard'

interface ClaimsSettlementReceiptPanelSharedSectionProps {
    selectedClaimsSettlementReceipt: ClaimsSettlementReceipt
}

const ClaimsSettlementReceiptPanelSharedSection = ({
    selectedClaimsSettlementReceipt
}: ClaimsSettlementReceiptPanelSharedSectionProps): JSX.Element => {
    const { t } = useTranslation()
    const products = useSelector(getProducts)
    const costsLabels = getCostsLabels(selectedClaimsSettlementReceipt.type, true)
    const receiptCostsValues = getReceiptCostsValues(costsLabels, selectedClaimsSettlementReceipt)

    const hospitalContribution =
        selectedClaimsSettlementReceipt?.costsShared?.laMal?.hospitalContribution
    const deductible = selectedClaimsSettlementReceipt?.costsShared?.laMal?.deductible
    const contribution = selectedClaimsSettlementReceipt?.costsShared?.laMal?.contribution
    const hasHospitalContribution = hospitalContribution !== undefined && hospitalContribution > 0
    const hasDeductible = deductible !== undefined && deductible > 0
    const hasContribution = contribution !== undefined && contribution > 0
    const showSharedLamal = hasHospitalContribution || hasDeductible || hasContribution

    const sharedLcaList = selectedClaimsSettlementReceipt?.costsShared?.lca
    const hasSharedLca = sharedLcaList && sharedLcaList.length > 0
    const hasCostsNotCovered = selectedClaimsSettlementReceipt.costsNotCovered > 0

    const getLamalSharedCards = (): JSX.Element => {
        return (
            <>
                {hasHospitalContribution && (
                    <ClaimsSettlementReceiptPanelAmountCard
                        title={t('CLAIMS_SETTLEMENT.RECEIPT_HOSPITALISATION_CONTRIBUTION')}
                        price={hospitalContribution as number} //validated through hasHospitalContribution
                        isRefund={false}
                        popoverLabel="CLAIMS_SETTLEMENT.HOSPITALISATION_CONTRIBUTION_MODAL_DESCRIPTION"
                    />
                )}
                {hasDeductible && (
                    <ClaimsSettlementReceiptPanelAmountCard
                        title={t('CLAIMS_SETTLEMENT.RECEIPT_DEDUCTIBLE')}
                        price={deductible as number} //validated through hasDeductible
                        isRefund={false}
                        popoverLabel="CLAIMS_SETTLEMENT.DEDUCTIBLE_MODAL_DESCRIPTION"
                    />
                )}
                {hasContribution && (
                    <ClaimsSettlementReceiptPanelAmountCard
                        title={t('CLAIMS_SETTLEMENT.RECEIPT_CONTRIBUTION')}
                        price={contribution as number} //validated through hasContribution
                        isRefund={false}
                        popoverLabel="CLAIMS_SETTLEMENT.CONTRIBUTION_MODAL_DESCRIPTION"
                    />
                )}
            </>
        )
    }

    const getLcaSharedCards = (): JSX.Element[] => {
        return (sharedLcaList as ClaimsSettlementReceiptCostsSharedDetailsLca[]).map(
            (lca, index) => {
                const deductible = lca?.amounts?.deductible
                const contribution = lca?.amounts?.contribution

                const hasDeductible = deductible !== undefined && deductible > 0
                const hasContribution = contribution !== undefined && contribution > 0
                const selectedLca = Object.values(products).find(
                    (product) =>
                        product.type === ProductTypeCmsEnum.LCA && product.insuranceId === lca.code
                )
                return (
                    <>
                        {hasDeductible && (
                            <ClaimsSettlementReceiptPanelAmountCard
                                key={`${index.toString()}_deductible`}
                                title={t('CLAIMS_SETTLEMENT.RECEIPT_DEDUCTIBLE_LCA')}
                                subTitle={selectedLca?.title}
                                price={deductible as number} // validated through hasDeductible
                                isRefund={false}
                            />
                        )}
                        {hasContribution && (
                            <ClaimsSettlementReceiptPanelAmountCard
                                key={`${index.toString()}_contribution`}
                                title={t('CLAIMS_SETTLEMENT.RECEIPT_CONTRIBUTION_LCA')}
                                subTitle={selectedLca?.title}
                                price={contribution as number} // validated through hasContribution
                                isRefund={false}
                            />
                        )}
                    </>
                )
            }
        )
    }

    return (
        <div className="m-top-40">
            <div className="claims-settlement-panel-adaptive-padding-horizontal d-flex align-items-center">
                <div className="headlineSmallMedium flex-1">
                    {t(receiptCostsValues.insuredCosts.label)}
                </div>
                <div
                    className="labelSmall m-left-8"
                    data-testid="claims-settlement-receipt-panel-shared-price"
                >
                    {t('COMMON.PRICE', {
                        price: formatNumber(receiptCostsValues.insuredCosts.value)
                    })}
                </div>
            </div>
            {(showSharedLamal || hasSharedLca || hasCostsNotCovered) && (
                <div className="m-top-24" data-testid="claims-settlement-receipt-shared-section">
                    {showSharedLamal && getLamalSharedCards()}
                    {hasSharedLca && getLcaSharedCards()}
                    {hasCostsNotCovered && (
                        <ClaimsSettlementReceiptPanelAmountCard
                            title={t('CLAIMS_SETTLEMENT.RECEIPT_COSTS_NOT_COVERED')}
                            price={selectedClaimsSettlementReceipt.costsNotCovered}
                            isRefund={false}
                            popoverLabel="CLAIMS_SETTLEMENT.COSTS_NOT_COVERED_MODAL_DESCRIPTION"
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default ClaimsSettlementReceiptPanelSharedSection
