import { animationDuration } from 'core/helpers/ClaimsSettlementPanelHelper'
import { CircularProgressSegment } from 'core/models/AssuraCircularProgress'
import AssuraCircularProgress from 'shared/components/AssuraCircularProgress/AssuraCircularProgress'
import { ColorsEnum } from 'shared/components/AssuraTabDropdown/Icon'

interface ClaimsSettlementCostsPieChartProps {
    percentage: number
    percentageLabel: string
}

const ClaimsSettlementCostsPieChart = ({
    percentage,
    percentageLabel
}: ClaimsSettlementCostsPieChartProps) => {
    const segmentData: CircularProgressSegment[] = [
        {
            name: 'assura',
            color: ColorsEnum.success,
            offsetPercentStart: 0,
            percent: percentage,
            animationDuration: animationDuration,
            animationDelay: 0
        },
        {
            name: 'insured',
            color: ColorsEnum.error,
            offsetPercentStart: percentage,
            percent: 100 - percentage,
            animationDuration: animationDuration,
            animationDelay: animationDuration
        }
    ]

    return (
        <AssuraCircularProgress
            segments={segmentData}
            textOptions={{ percentage, label: percentageLabel }}
        />
    )
}

export default ClaimsSettlementCostsPieChart
