import './ModelDeductiblePanelHeader.css'

import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

type ModelDeductiblePanelHeaderProps = {
    label?: string
    closePanel: () => void
}

const ModelDeductiblePanelHeader = ({
    ...props
}: ModelDeductiblePanelHeaderProps) => {
    const LeftBlock = (): JSX.Element => (
        <div className={'model-deductible-panel-header'}>
            <label
                data-testid={'model-deductible-label'}
                className={'headline c-primary assura-panel-left-block-ellipsis'}
            >
                {props.label}
            </label>
        </div>
    )

    return (
        <AssuraPanelHeader
            sideBlockStyleWidthAuto={true}
            leftBlock={<LeftBlock />}
            closePanel={props.closePanel}
        />
    )
}

export default ModelDeductiblePanelHeader
