import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'

import AssuraIconButton from '../AssuraIconButton/AssuraIconButton'
import { AssuraInputWL } from '../AssuraInput/AssuraInput'

interface AssuraDropzoneSuccessProps {
    testId: string
    filename: string
    onDelete: () => void
}
const AssuraDropzoneSuccess = ({
    testId,
    filename,
    onDelete
}: AssuraDropzoneSuccessProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    return (
        <div className="d-flex flex-column">
            <AssuraInputWL
                id="dropzone-success"
                label={t('DROPZONE.ATTACHMENT_NAME')}
                labelSize={4}
                inline={isXL}
                value={filename}
                readOnly
            />
            <AssuraIconButton
                id={`${testId}-dropzone-success-delete-file`}
                icon="assura-delete"
                variant="default"
                size="medium"
                onClick={onDelete}
                classNames="m-top-16 align-self-end"
                label={t('COMMON.DELETE')}
            />
        </div>
    )
}

export default AssuraDropzoneSuccess
