import axios from 'axios'
import { DataSearchDoctorsSuccess } from 'shared/store/services/doctors/doctors.slice'

import { Doctor } from '../../models/familyGroup/Doctor'
import { FamilyDoctorRequestBody } from '../../models/services/contract/FamilyDoctor'

export const contractDoctorsData = async (policyNumber: number): Promise<Doctor[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/doctors?policyNumber=${policyNumber}`
    )
    return result.data
}

export const searchDoctorsData = async (
    searchText: string,
    productCode: string,
    canton: string
): Promise<DataSearchDoctorsSuccess> => {
    let url = `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyDoctors/name?SearchText=${searchText}&ProductCode=${productCode}&MaxResults=50`
    if (canton !== 'CH') {
        url = url.concat(`&Canton=${canton}`)
    }
    const result = await axios.get(url)
    return result.data
}

export const submitFamilyDoctorForm = async (requestBody: FamilyDoctorRequestBody): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/doctor`, requestBody)
