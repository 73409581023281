import { useTranslation } from 'react-i18next'

import { ProductPeriodicity } from 'core/enums/Product'

interface PrimesPanelDiscountsPeriodicityProps {
    periodicity?: ProductPeriodicity
}

const PrimesPanelDiscountsPeriodicity = ({
    periodicity
}: PrimesPanelDiscountsPeriodicityProps): JSX.Element | null => {
    const { t } = useTranslation()

    if (periodicity === ProductPeriodicity.ANNUAL || periodicity === ProductPeriodicity.BIANNUAL) {
        return (
            <div
                className="paragraphExtraSmall p-top-16 c-gray500"
                data-testid="primes-panel-discounts-periodicity"
            >
                {t(`PRIMES.DISCOUNTS_PERIODICITY`, {
                    percentage: periodicity === ProductPeriodicity.ANNUAL ? '2' : '1'
                })}
            </div>
        )
    } else {
        return null
    }
}

export default PrimesPanelDiscountsPeriodicity
