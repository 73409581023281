export default {
    ENTRY_POINTS: {
        CAROUSEL: 'carousel',
        QUICK_ACCESS: 'quick_access',
        SERVICE_REQUEST_CARD: 'service_request_card',
        DISCOVERY_LINK: 'discovery_link',
        FREQUENT_REQUEST_ITEM: 'frequent_request_item',
        SPLASH_SCREEN: 'splash_screen',
        INVOICE_TO_CHECK: 'communications/invoicecheck',
        SYMPTOM_CHECKER: 'symptom_checker',
        INSURANCES: 'insurances',
        QUALIMED: 'qualimed'
    }
}
