import './EnveloppesBackToScanForm.css'

import { createContext, useContext, useEffect, useState } from 'react'
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { AssuraDocumentType } from 'core/enums/DocumentType'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { emptyFunction } from 'core/helpers/functionHelper'
import pdfAnalyzer from 'core/helpers/PdfAnalyzer'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { EnveloppesReplaceLocationState } from 'core/models/enveloppesSubmission/EnveloppesReplaceLocationState'
import { EnveloppesBackToScanFormRhf } from 'modules/enveloppes/pages/EnveloppesBackToScan'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import {
    removeAllNonPermanentBanners,
    setBanner,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import { getSummaryByPage } from 'shared/store/combineSelectors'
import {
    DataUploadFiles,
    getSubmitEnveloppeStatus,
    onSubmitEnveloppe
} from 'shared/store/enveloppes/enveloppes.slice'
import { useAppDispatch } from 'shared/store/store'

import EnveloppesBackToScanContainer from '../EnveloppesBackToScanContainer/EnveloppesBackToScanContainer'
import EnveloppesBackToScanDelete from '../EnveloppesBackToScanDelete/EnveloppesBackToScanDelete'
import EnveloppesSubmissionNameInput from '../EnveloppesSubmissionNameInput/EnveloppesSubmissionNameInput'
import EnveloppesSubmissionPanel from '../EnveloppesSubmissionPanel/EnveloppesSubmissionPanel'

interface BackToScanContext {
    addDocument: (index: number, droppedFile: DroppedFile) => void
    seeDocument: (droppedFile: DroppedFile) => void
    updatedDocumentsIndex: number[]
}

const BackToScanContext = createContext<BackToScanContext>({
    addDocument: emptyFunction,
    seeDocument: emptyFunction,
    updatedDocumentsIndex: []
})

const EnveloppesBackToScanForm = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const state = useLocation().state as EnveloppesReplaceLocationState

    const [loadingStatus, setLoadingStatus] = useState(LoadingStatusEnum.LOADING)

    const familySummary = useSelector(getSummaryByPage)
    const insuredPersons = familySummary?.insuredPersons

    const submitEnveloppeStatus = useSelector(getSubmitEnveloppeStatus)
    const [pagesCount, setPagesCount] = useState(0)
    const [batchNumberForReplace, setBatchNumberForReplace] = useState<string | undefined>(
        undefined
    )
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false)
    const [selectedFileForPanel, setSelectedFileForPanel] = useState<DroppedFile | undefined>(
        undefined
    )
    const [filesInErrorCount, setFilesInErrorCount] = useState(0)
    const [updatedDocumentsIndex, setUpdatedDocumentsIndex] = useState<number[]>([])

    const { setValue } = useFormContext()

    const { fields, replace, update } = useFieldArray<EnveloppesBackToScanFormRhf>({
        name: 'documents',
        rules: {}
    })

    const addDocument = (index: number, droppedFile: DroppedFile) => {
        update(index, droppedFile)
        const newIndexList = [...updatedDocumentsIndex].concat([index])
        setUpdatedDocumentsIndex(newIndexList)
    }

    const seeDocument = (droppedFile: DroppedFile) => {
        setSelectedFileForPanel(droppedFile)
        setIsPanelOpen(true)
    }

    const value: BackToScanContext = {
        addDocument,
        seeDocument,
        updatedDocumentsIndex
    }

    const onSubmit = (values: FieldValues): void => {
        if (!hasWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }
        const { policyNumber, name, documents } = values as EnveloppesBackToScanFormRhf

        if (filesInErrorCount > 0) {
            dispatch(
                setBanner({
                    dataTestId: 'enveloppes-back-to-scan-required-error',
                    message: 'ENVELOPPES.BACK_TO_SCAN_ERROR_BANNER'
                })
            )
            return
        }

        if (policyNumber && pagesCount < 100 && fields.length > 0) {
            const payload: DataUploadFiles = {
                name,
                policyNumber: policyNumber.toString(),
                batchNumber: batchNumberForReplace,
                pdfFiles: documents
            }

            dispatch(onSubmitEnveloppe(payload))
        }
    }

    useEffect(() => {
        const newPagesCount = fields.reduce(
            (acc, field) => acc + (field as DroppedFile).pagesCount,
            0
        )
        setPagesCount(newPagesCount)
    }, [fields])

    useEffect(() => {
        if (pagesCount > 99) {
            dispatch(
                setBanner({
                    dataTestId: 'enveloppes-back-to-scan-too-many-pages-error',
                    message: 'ENVELOPPES.SUBMISSION_PAGES_COUNT_ERROR'
                })
            )
        } else {
            dispatch(removeAllNonPermanentBanners())
        }
    }, [pagesCount])

    useEffect(() => {
        if (state) {
            const { personName, batchNumber, enveloppeName, files } = state
            files
                .reduce<Promise<DroppedFile[]>>(async (accPromise, droppedFile) => {
                    const report = await pdfAnalyzer(droppedFile.pdfFileBase64)
                    const acc = await accPromise
                    if (report.isValid)
                        acc.push({
                            ...droppedFile,
                            pagesCount: report.pagesCount
                        })
                    return acc
                }, Promise.resolve([]))
                .then((keepedPdfAnalyzed) => {
                    setValue('name', enveloppeName)
                    replace(keepedPdfAnalyzed)
                    const person = insuredPersons?.find(
                        (personToFind) => personToFind.firstName === personName
                    )
                    if (person) setValue('policyNumber', insuredPersons[0].policyNumber)
                    setBatchNumberForReplace(batchNumber)
                })
                .finally(() => setLoadingStatus(LoadingStatusEnum.OK))
        }
    }, [location])

    useEffect(() => {
        const count = fields.reduce<number>((acc, field) => {
            if (!(field as DroppedFile).isValid) acc++
            return acc
        }, 0)

        setFilesInErrorCount(count)
    }, [fields])

    return (
        <BackToScanContext.Provider value={value}>
            <div className="position-relative">
                <EnveloppesSubmissionNameInput testId="enveloppes-back-to-scan-name" />
                <div className="enveloppes-back-to-scan-form-container bg-white bc-gray100 m-top-32">
                    <div className="enveloppes-back-to-scan-form-title">
                        {filesInErrorCount > 0 && (
                            <div
                                className="labelSmall text-right"
                                data-testid="enveloppes-back-to-scan-form-count"
                            >
                                {t('ENVELOPPES.BACK_TO_SCAN_FORM_COUNT', {
                                    count: filesInErrorCount
                                })}
                            </div>
                        )}
                    </div>
                    {loadingStatus === LoadingStatusEnum.LOADING ? (
                        <div className="enveloppes-back-to-scan-form-spinner-container">
                            <ActivityIndicator size={40} />
                        </div>
                    ) : (
                        <>
                            <EnveloppesBackToScanContainer />
                            {batchNumberForReplace && (
                                <EnveloppesBackToScanDelete batchNumber={batchNumberForReplace} />
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="d-inline-flex enveloppes-back-to-scan-form-buttons-container">
                <AssuraButton
                    text={t('COMMON.CANCEL')}
                    id="enveloppes-back-to-scan-cancel"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                />

                <SubmitButtonWithScroll
                    onSubmit={onSubmit}
                    id="enveloppes-back-to-scan-submit"
                    hasLoader={submitEnveloppeStatus === LoadingStatusEnum.LOADING}
                    classNames="m-left-12"
                />
            </div>
            {selectedFileForPanel && (
                <EnveloppesSubmissionPanel
                    isPanelOpen={isPanelOpen}
                    closePanel={() => setIsPanelOpen(false)}
                    pdf={{
                        fileName: selectedFileForPanel.fileName,
                        base64: selectedFileForPanel.pdfFileBase64,
                        loadStatus: LoadingStatusEnum.OK,
                        type: AssuraDocumentType.PDF
                    }}
                />
            )}
        </BackToScanContext.Provider>
    )
}

export const useBackToScanContext = (): BackToScanContext => {
    return useContext(BackToScanContext)
}

export default EnveloppesBackToScanForm
