import { call, put } from 'redux-saga/effects'

import { searchSuggestionsCmsData } from 'core/api/Cms'
import { SearchSuggestionCms } from 'core/models/cms/SearchSuggestionCms'

import { setSearchSuggestionsCms } from './appSearch.slice'

export function* initAppSearchSuggestions() {
    try {
        const searchSuggestionsCms: SearchSuggestionCms[] = yield call(searchSuggestionsCmsData)

        yield put(setSearchSuggestionsCms(searchSuggestionsCms))
    } catch (e) {
        console.error('initAppSearchSuggestions Error', e)
    }
}