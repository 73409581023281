import { SymptomCheckerEcLinkId } from 'core/constants/ecLinkIds'
import { FrequentRequest } from 'core/models/FrequentRequest'
import { News } from 'core/models/News'

export const SymptomCheckerSplicer = (
    isSymptomCheckerCustomerPortalActivated: string | undefined,
    collection: News[] | FrequentRequest[]
) => {
    if (isSymptomCheckerCustomerPortalActivated === 'false') {
        const symptomCheckerIndex = collection.findIndex(
            (x) => x.ec_link === SymptomCheckerEcLinkId
        )
        if (symptomCheckerIndex !== -1) collection.splice(symptomCheckerIndex)
    }
}
