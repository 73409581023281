import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues, RequestDetailsValuesView } from 'core/models/services/RequestData'
import { formatNumber } from 'core/utils/TextUtils'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'
import { getProductsCms } from 'shared/store/products/products.slice'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface CoverageModelDeductibleProps {
    values: RequestDetailsValues[]
}

const CoverageModelDeductible = ({ values }: CoverageModelDeductibleProps): JSX.Element => {
    const { t } = useTranslation()

    const activeFields: RequestDetailsValuesView[] = []

    const productsInformation = useSelector(getProductsCms)
    const getProductTitle = (code: string): string => {
        const product = productsInformation.find((pi) => pi.insurance_id === code)
        return product?.title ?? ''
    }

    const product = values.find((v) => v.id === 'Category')
    if (product)
        activeFields.push({
            testId: 'product',
            label: t('COVERAGE.MODEL_DEDUCTIBLE_REQUEST_DETAILS_MODEL'),
            old: getProductTitle(product.old as string),
            new: getProductTitle(product.new as string)
        })

    const franchiseAmount = values.find((v) => v.id === 'Franchise')
    if (franchiseAmount)
        activeFields.push({
            testId: 'amount',
            label: t('SERVICES.MODEL_DEDUCTIBLE_DEDUCTIBLE_LABEL'),
            old: formatNumber(parseFloat((franchiseAmount.old ?? 0) as string)),
            new: formatNumber(parseFloat((franchiseAmount.new ?? 0) as string))
        })

    const validityDate = values.find((v) => v.id === 'ValidFrom')

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={`${field.label}-old`}>
                        <div>{field.label}</div>
                        <div
                            data-testid={`requests-details-coverage-model-deductible-${field.testId}-old`}
                        >
                            {field.old as string}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                <>
                    {validityDate && validityDate.new && (
                        <div className="label c-primary">
                            {t('DOCUMENTS.PROCESSING_DATE', {
                                processingDate: formatDate(validityDate.new as string)
                            })}
                        </div>
                    )}
                </>
            </RequestsPanelSubtitle>
            <div className="requests-panel-main-block">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={`${field.label}-new`}>
                        <div>{field.label}</div>
                        <div
                            data-testid={`requests-details-coverage-model-deductible-${field.testId}-new`}
                        >
                            {field.new as string}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
        </div>
    )
}

export default CoverageModelDeductible
