import './AssuraLoadAndError.css'

import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import ActivityIndicator, { ActivityIndicatorSize } from 'shared/components/ActivityIndicator/ActivityIndicator'

export interface LoadingStatusProps {
    status: LoadingStatusEnum
    defaultReloadAction?: () => void
}

interface AssuraLoadAndErrorProps extends LoadingStatusProps {
    shouldDisplayContainer?: boolean
    shouldDisplayErrorMessage?: boolean
    ErrorComponent?: JSX.Element
    children: React.ReactNode | React.ReactNode[]
    activityIndicatorSize?: ActivityIndicatorSize
}

const getDefaultErrorComponent = (
    defaultReloadAction?: () => void,
    shouldDisplayErrorMessage?: boolean
): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            data-testid="error-indicator"
            className="status-message-container"
            onClick={() => defaultReloadAction && defaultReloadAction()}
        >
            <i
                className={`icon assura-refresh size-32 c-primary ${
                    !shouldDisplayErrorMessage ? 'refresh-button-only' : 'refresh-button'
                }`}
                data-testid="refresh-button"
            />
            {shouldDisplayErrorMessage ? (
                <div className="labelSmall">{t('ERROR_STATE.TITLE')}</div>
            ) : (
                <></>
            )}
        </div>
    )
}
const AssuraLoadAndError = ({
    status,
    shouldDisplayContainer,
    shouldDisplayErrorMessage = true,
    defaultReloadAction,
    ErrorComponent,
    activityIndicatorSize = 40,
    children
}: AssuraLoadAndErrorProps): JSX.Element => {
    switch (status) {
        case LoadingStatusEnum.LOADING:
            return shouldDisplayContainer ? (
                <div className="d-flex p-top-32 p-bottom-32 extra-display-container">
                    <ActivityIndicator size={activityIndicatorSize} />
                </div>
            ) : (
                <ActivityIndicator size={activityIndicatorSize} />
            )

        case LoadingStatusEnum.ERROR:
            return ErrorComponent ? (
                ErrorComponent
            ) : shouldDisplayContainer ? (
                <div className="d-flex p-top-32 p-bottom-32 extra-display-container">
                    {getDefaultErrorComponent(defaultReloadAction, shouldDisplayErrorMessage)}
                </div>
            ) : (
                <>{getDefaultErrorComponent(defaultReloadAction, shouldDisplayErrorMessage)}</>
            )
        default:
            return <>{children}</>
    }
}

export default AssuraLoadAndError
