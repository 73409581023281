import { RequestStatus } from 'core/enums/RequestStatus'
import { ServiceType } from 'core/enums/ServiceType'
import { RequestData } from 'core/models/services/RequestData'

import { createSelector } from '@reduxjs/toolkit'

import { getServicesRequests } from '../services/requests/requests.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'

export const isTherePaymentFrequencyInRequests = createSelector(
    [getServicesRequests],
    (requests): boolean => {
        return requests.some(
            (request) =>
                request.type === ServiceType.PAYMENT_FREQ_MODIFICATION &&
                request.status === RequestStatus.PENDING
        )
    }
)

export const isThereAddMemberInRequests = createSelector(
    [getServicesRequests],
    (requests): boolean => {
        return requests.some(
            (request) =>
                request.type === ServiceType.FAMILY_GROUP_CHANGE &&
                request.status === RequestStatus.PENDING
        )
    }
)

export const isThereAventuraInRequests = createSelector(
    [getServicesRequests],
    (requests): boolean => {
        return requests.some(
            (request) =>
                request.type === ServiceType.AVENTURA_INSURANCE_REQUEST &&
                request.status === RequestStatus.PENDING
        )
    }
)

export const isThereBilledPrimeInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.CERTIFICATE_CATTP)
        )
    }
)

export const isThereInsuranceCardInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.COVER_CARD_ORDER)
        )
    }
)

export const isThereMedicalFeesInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.LPIMP_REQUEST)
        )
    }
)

export const isThereInsuranceAttestationInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.CERTIFICATE_CATTI)
        )
    }
)

export const isThereStatementDetailsInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.CDEC_REQUEST)
        )
    }
)

export const isThereAccidentSuspensionInclusionInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.AUTO_LAMAL_ACC_SUSP_INCL)
        )
    }
)

export const isThereDeductibleInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.DEDUCTIBLE_CHANGE)
        )
    }
)

export const isThereFamilyDoctorInRequests = createSelector(
    [getServicesRequests, getSelectedMemberPolicyNumber],
    (requests, policyNumber): boolean => {
        return requests.some((request) =>
            checkHasPending(request, policyNumber, ServiceType.FAMILY_DOCTOR_CHANGE)
        )
    }
)

export const checkHasPending = (request: RequestData, policyNumber: number, type: ServiceType) => {
    return (
        request.status === RequestStatus.PENDING &&
        (request.policyNumber === policyNumber || request.affectsAllFamilyMembers) &&
        request.type === type
    )
}
