import './BasicInfo.css'

import { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { BasicInfoFromForm } from 'core/models/services/personalData/ContactInfos'
import { ExtraRHFRef } from 'core/models/services/RHF'
import {
    removeAllNonPermanentBanners,
    removeBanner,
    setBanner,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import { BasicView } from 'shared/store/selectors/getFullPersonalData'
import { postBasicInfo } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getServiceSubmitStatus } from 'shared/store/services/services.slice'

import MaritalStatusSelect from '../MaritalStatusSelect/MaritalStatusSelect'
import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'
import PersonalDataStatus from '../PersonalDataStatus/PersonalDataStatus'
import PersonalDataValidation from '../PersonalDataValidation/PersonalDataValidation'
import BasicInfoInput from './BasicInfoInput'

interface BasicInfoProps {
    basicDatas: BasicView
}

const BasicInfo = ({ basicDatas }: BasicInfoProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isEnabledRef = useRef<boolean>(false)
    const submitServiceStatus = useSelector(getServiceSubmitStatus)
    const invalidFormBannerId = 'basic-info'

    const {
        state: { selectedPolicyNumber, isOnSuccess },
        isFormSelected,
        setNoChangeError,
        removeNoChangeError
    } = usePersonalDataContext()
    const isEnabled = isFormSelected(PersonalDataForm.BASIC)

    const defaultValues = {
        firstName: basicDatas.firstName,
        lastName: basicDatas.lastName,
        maritalStatus: basicDatas.maritalStatus
    }

    const methods = useForm<BasicInfoFromForm>({
        defaultValues
    })

    const { reset, getValues, formState } = methods
    const { isDirty } = formState
    const handleCancel = () => {
        reset(defaultValues)
        dispatch(removeBanner(invalidFormBannerId))
    }

    const onSubmit = () => {
        if (submitServiceStatus !== LoadingStatusEnum.LOADING) {
            if (isDirty) {
                if (!hasWritePermission()) {
                    dispatch(setForbiddenBanner())
                    return
                }

                const values = getValues()
                dispatch(postBasicInfo(values))
                reset(values)
            } else {
                dispatch(removeBanner(invalidFormBannerId))
                setNoChangeError()
            }
        }
    }

    useEffect(() => {
        if (isEnabledRef.current && !isEnabled && !isOnSuccess) {
            handleCancel()
        }
        isEnabledRef.current = isEnabled
    }, [isEnabled])

    useEffect(() => {
        if (selectedPolicyNumber) handleCancel()
    }, [selectedPolicyNumber])

    useEffect(() => {
        if (isEnabled && formState.isSubmitted) {
            if (Object.keys(formState.errors).length > 0) {
                removeNoChangeError()
                dispatch(
                    setBanner({
                        id: invalidFormBannerId,
                        dataTestId: invalidFormBannerId,
                        message: 'SERVICES.FIELD_ERROR_BANNER_MESSAGE'
                    })
                )
            } else if (isDirty) {
                dispatch(removeAllNonPermanentBanners())
            }
        }
    }, [formState])

    const extraRhfRefs: ExtraRHFRef[] = [
        {
            name: 'maritalStatus',
            id: 'services-personal-data-marital-status-select'
        }
    ]

    return (
        <>
            <div className="basic-info-top-container p-bottom-40">
                <div className="headlineLarge" data-testid={`basic-info-title`}>
                    {t('PERSONAL_DATA.CONTACT_TITLE')}
                </div>
                <PersonalDataStatus
                    form={PersonalDataForm.BASIC}
                    isPending={basicDatas.isPending}
                />
            </div>
            <FormProvider {...methods}>
                <form className="basic-info-children-container">
                    <BasicInfoInput
                        id="first-name"
                        name="firstName"
                        label={t('FAMILY_GROUP.BASIC_INFO_EDIT_FIRST_NAME_LABEL')}
                        placeHolder={`${t('PERSONAL_DATA.FIRST_NAME_PLACEHOLDER')}`}
                    />
                    <BasicInfoInput
                        id="last-name"
                        name="lastName"
                        label={t('FAMILY_GROUP.BASIC_INFO_EDIT_LAST_NAME_LABEL')}
                        placeHolder={`${t('PERSONAL_DATA.LAST_NAME_PLACEHOLDER')}`}
                    />
                    <MaritalStatusSelect userGender={basicDatas.gender} />
                    <PersonalDataValidation
                        form={PersonalDataForm.BASIC}
                        onSubmit={onSubmit}
                        extraRhfRefs={extraRhfRefs}
                    />
                </form>
            </FormProvider>
        </>
    )
}

export default BasicInfo
