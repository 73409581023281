import './NewsCard.css'

import { useTranslation } from 'react-i18next'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { News } from 'core/models/News'
import useOpenFormOrLink from 'core/services/useOpenFormOrLink'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface NewsCardProps {
    news: News
}

const CardContent = (news: News, learnMore?: JSX.Element) => (
    <>
        <div
            className="news-card-container"
            style={{
                backgroundImage: `url(${process.env.REACT_APP_CMS_ASSET}${news.image?.path})`
            }}
            data-testid={`${news._id}-container`}
        />
        <div
            className={`d-flex bg-white p-32 flex-column justify-content-center`}
            style={{
                maxHeight: '250px'
            }}
        >
            <div className={`titleSmall max-2-text-lines`} data-testid={`${news._id}-title`}>
                {news.title}
            </div>
            {news.description && (
                <div
                    className={`paragraphSmallLight p-top-8 max-3-text-lines`}
                    data-testid={`${news._id}-description`}
                >
                    {news.description}
                </div>
            )}
            {learnMore}
        </div>
    </>
)

const CarouselAssuraIconButton = (news: News, labelText: string) => (
    <div className="learn-more-button">
        <AssuraIconButton
            id={`${news._id}-learn-more-button`}
            icon="assura-next"
            variant="primary"
            size="medium"
            label={labelText}
        />
    </div>
)

const CarouselEcLinkButton = (labelText: string) => (
    <div className="c-primary labelSmall d-flex align-items-center p-top-16">
        <i
            className="icon assura-next size-24 m-right-8"
            data-testid="667e83db2a465e62c809c052-learn-more-button-icon"
        ></i>
        {labelText}
    </div>
)

const CARD_COMMON_CLASSNAMES = 'news-card-container-frame border-solid-width-1 bc-gray100'

const NewsCard = ({ news }: NewsCardProps): JSX.Element => {
    const { t } = useTranslation()
    const openFormOrLink = useOpenFormOrLink()

    const onClick = (): void => {
        openFormOrLink(news.link_url)
        sendEvent(analyticsConstants.EVENTS.BANNER_CLICK, {
            [analyticsConstants.PARAMS.HREF]: news.link_url,
            [analyticsConstants.PARAMS.CREATIVE_ID]: news._id
        })
    }

    const onClickSendAnalytics = (link: string) => {
        // bypass if not symptom checkeritem
        if (!link.includes('Symptom')) return
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_CLICK, {
            [analyticsConstants.PARAMS.ENTRY_POINT]: entryPointsConstants.ENTRY_POINTS.CAROUSEL,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }

    if (news.link_url) {
        return (
            <div className={`${CARD_COMMON_CLASSNAMES} cursor-pointer`} onClick={onClick}>
                {CardContent(news, CarouselAssuraIconButton(news, t('COMMON.LEARN_MORE')))}
            </div>
        )
    } else if (news.ec_link) {
        return (
            <AssuraEcLink
                ecLinkId={news.ec_link}
                testId="carousel_ec_link"
                classNames="flex-1"
                callback={onClickSendAnalytics}
            >
                <div className={`${CARD_COMMON_CLASSNAMES} cursor-pointer`}>
                    {CardContent(news, CarouselEcLinkButton(t('COMMON.LEARN_MORE')))}
                </div>
            </AssuraEcLink>
        )
    } else {
        return <div className={CARD_COMMON_CLASSNAMES}>{CardContent(news)}</div>
    }
}

export default NewsCard
