import { useTranslation } from 'react-i18next'

import { RequestDocument } from 'core/models/services/RequestData'

import RequestsAttachment from '../RequestsAttachment/RequestsAttachment'

interface AccidentReportProps {
    generated?: RequestDocument
    attachments?: RequestDocument[]
}

const AccidentReport = ({ generated, attachments }: AccidentReportProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            {generated && (
                <RequestsAttachment
                    attachmentLabel={t('SERVICE.ACCIDENT_REPORT_DOCUMENT')}
                    attachment={generated}
                />
            )}
            {attachments?.map((attachment, i) => (
                <RequestsAttachment
                    attachmentLabel={`${t('SERVICE.ACCIDENT_REPORT_ATTACHMENT')}${i + 1}`}
                    attachment={attachment}
                    key={attachment.id}
                />
            ))}
        </div>
    )
}

export default AccidentReport
