import './SearchBar.css'

import { Fragment, useEffect, useRef, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useOnClickOutside from 'core/services/useOnClickOutside'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'

import SearchBarNoResult from './SearchBarNoResult'

export interface SearchBarSection<T> {
    sectionTitleKey?: string
    results: SearchBarResult<T>[]
}

export interface SearchBarResult<T> {
    label: string
    value: T
}

interface SearchBarProps<T> {
    dataTestId: string
    charMinToStartSearch?: number
    items: SearchBarSection<T>[]
    onSelection: (value: T) => void
    placeHolder?: string
}

const SearchBar = <T,>({
    dataTestId,
    charMinToStartSearch,
    items,
    onSelection,
    placeHolder
}: SearchBarProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [isFocused, setIsFocused] = useState(false)

    const [sections, setSections] = useState<SearchBarSection<T>[]>([...items])

    const wrapperRef = useRef<HTMLDivElement>(null)

    const resetResults = () => setSections([...items])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.currentTarget.value
        setInputValue(currentValue)
        if (currentValue.length >= (charMinToStartSearch || 1)) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }

    const filterResults = () => {
        const matchList = items.reduce<SearchBarSection<T>[]>((acc, item) => {
            const filteredResults: SearchBarResult<T>[] = item.results
                .filter(
                    (result) =>
                        result.label &&
                        result.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                )
                .map((filteredResult) => ({
                    ...filteredResult,
                    label: filteredResult.label.replace(
                        new RegExp(inputValue.trim(), 'gi'),
                        (str) => `**${str}**`
                    )
                }))
            if (filteredResults.length > 0)
                acc.push({
                    sectionTitleKey: item.sectionTitleKey,
                    results: [...filteredResults]
                })
            return acc
        }, [])

        setSections(matchList)
    }

    const clearResults = () => {
        setIsOpen(false)
        setInputValue('')
        resetResults()
    }

    const handleOutsideClick = () => {
        setIsOpen(false)
    }

    const handleReset = (
        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        e.preventDefault()
        clearResults()
    }

    const handleSelection = (value: T) => {
        onSelection(value)
        clearResults()
    }

    const handleFocus = () => {
        setIsFocused(true)
        if (inputValue.length >= (charMinToStartSearch || 1) && !isOpen) setIsOpen(true)
    }

    useOnClickOutside(wrapperRef, handleOutsideClick)

    const handleLeftIconFocus = (
        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        e.preventDefault()
        const input = document.getElementById('assura-search-input')
        if (input && !isFocused) input.focus()
    }

    useEffect(() => {
        if (items && inputValue.length >= (charMinToStartSearch || 1)) filterResults()
    }, [items, inputValue])

    return (
        <div
            className={`search-bar-container bg-white border-solid-width-1 bc-${
                isFocused ? 'black' : 'gray300'
            }`}
            ref={wrapperRef}
        >
            <InputGroup>
                <InputGroup.Prepend
                    onMouseDown={(
                        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
                    ) => handleLeftIconFocus(e)}
                    onTouchStart={(
                        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
                    ) => handleLeftIconFocus(e)}
                >
                    <i
                        className={`icon assura-search size-24 p-left-16 p-right-8 c-${
                            isFocused ? 'black' : 'gray300'
                        }`}
                    />
                </InputGroup.Prepend>
                <input
                    id="assura-search-input"
                    data-testid={dataTestId}
                    className="flex-1 label"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={() => setIsFocused(false)}
                    value={inputValue}
                    placeholder={placeHolder}
                    autoComplete="off"
                />
                {isFocused && (
                    <div
                        className="assura-search-input-clear-container"
                        data-testid="assura-search-input-clear-button"
                        onMouseDown={(e) => handleReset(e)}
                        onTouchStart={(e) => handleReset(e)}
                    >
                        <i className="icon assura-close size-24" />
                    </div>
                )}
            </InputGroup>
            {isOpen && (
                <div className="search-bar-results-container bg-white">
                    {sections.length > 0 ? (
                        sections.map((section, i) => (
                            <Fragment key={`${dataTestId}-${i}`}>
                                {section.sectionTitleKey && (
                                    <div className="search-bar-results-section headlineMedium">
                                        {t(section.sectionTitleKey)}
                                    </div>
                                )}
                                {section.results.map((item, index) => (
                                    <div
                                        className="search-bar-results-item paragraphSmall"
                                        onClick={() => handleSelection(item.value)}
                                        data-testid={`${dataTestId}-result-${index}`}
                                        key={`${dataTestId}-result-${index}`}
                                    >
                                        <AssuraMarkdown message={item.label} classNames="flex-1" />
                                        <i className="icon assura-right size-24 c-gray300" />
                                    </div>
                                ))}
                            </Fragment>
                        ))
                    ) : (
                        <SearchBarNoResult />
                    )}
                </div>
            )}
        </div>
    )
}

export default SearchBar
