import axios from 'axios'
import { RequestData, RequestDetails } from 'core/models/services/RequestData'

export const getRequestsData = async (policyNumber: number): Promise<RequestData[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/requests?policyNumber=${policyNumber}`
    )
    return result.data.requests
}

export const getRequestsDetailsApi = async (
    policyNumber: number,
    id: string
): Promise<RequestDetails> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/request/${id}?policyNumber=${policyNumber}`
    )

    return result.data
}
