type GuideRowProps = {
    ruleKey: string
    showCross: boolean
    valid: boolean
    testId: string
    hint: string
    error: string
}

const GuideRow = ({ ...props }: GuideRowProps): JSX.Element => {
    return (
        <div
            className={`labelExtraSmall d-flex c-${props.valid ? 'success' : 'primary'}`}
            data-testid={`${props.testId}-helper-rule-${props.ruleKey}`}
        >
            {props.showCross && (
                <i
                    data-testid={`${props.testId}-helper-rule-${props.ruleKey}-icon`}
                    className={`icon assura-${props.valid ? 'check' : 'close'} size-16`}
                />
            )}
            <div className={props.showCross ? 'm-left-8' : ''}>{props.hint}</div>
        </div>
    )
}

export default GuideRow
