import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    AventuraAdditionalFamilyMemberModel,
    AventuraForm,
    AventuraPriceDetailsModel,
    AventuraState
} from 'core/models/services/coverage/AventuraForm'

import { createSelector } from '@reduxjs/toolkit'

import {
    getAventuraFamilyMembersSelector,
    getAventuraFormDataSelector,
    getAventuraStateSelector
} from '../services/aventura/aventura.slice'

export const getAventuraDailyPricesRate = createSelector(
    [getAventuraStateSelector],
    (aventuraStateData): AventuraPriceDetailsModel[] => aventuraStateData.prices
)

export const getAventuraHasPendingRequest = createSelector(
    [getAventuraStateSelector],
    (aventuraStateData): boolean => aventuraStateData.hasPendingRequests
)

export const getAventurLoadingStatus = createSelector(
    [getAventuraStateSelector],
    (aventuraStateData): LoadingStatusEnum => aventuraStateData.loadingStatus
)

export const getSubmitStatus = createSelector(
    [getAventuraStateSelector],
    (aventuraStateData): LoadingStatusEnum => aventuraStateData.submitStatus
)

export const getAventuraState = createSelector(
    [getAventuraStateSelector],
    (aventuraStateData): AventuraState => aventuraStateData
)

export const getAventuraForm = createSelector(
    [getAventuraFormDataSelector],
    (aventuraFormData): AventuraForm => aventuraFormData
)

export const getAventuraFamily = createSelector(
    [getAventuraFamilyMembersSelector],
    (familyMembers): AventuraAdditionalFamilyMemberModel[] => familyMembers
)
