import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getDoctorsOfSelectedFamilyMember } from './getDoctorsOfSelectedFamilyMember'

export const getCurrentAndFutureDoctorsOfSelectedFamilyMember = createSelector([getDoctorsOfSelectedFamilyMember], (selectedMemberDoctors) => {
    const now = new Date()
    return selectedMemberDoctors.filter((doctor) => {
        return !doctor.endDate || isBefore(now, new Date(doctor.endDate))
    })
})
