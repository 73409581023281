import { InputWithValidationsProps } from './AssuraFormInputWithValidations'
import InputGuide from './InputGuide'

type InputValidationsProps = {
    parentProps: InputWithValidationsProps
}

const InputValidations = ({ parentProps }: InputValidationsProps): JSX.Element => {
    return (
        <>
            {parentProps.validationResults?.displayTitle && parentProps.validationResults.title && (
                <div
                    className="labelExtraSmall m-bottom-8"
                    data-testid={`${parentProps.dataTestId}-helper-rule-main`}
                >
                    {parentProps.validationResults.title}
                </div>
            )}
            {parentProps.validationResults?.displayValidationGuide && (
                <>
                    <InputGuide
                        displayFullValidationRules={
                            parentProps.validationResults.displayFullValidationGuide
                        }
                        testId={parentProps.dataTestId}
                        validations={parentProps.validationResults.validations}
                    />
                </>
            )}
        </>
    )
}

export default InputValidations
