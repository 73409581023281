export default {
    PRODUCTS: {
        _id: 1,
        title: 1,
        title_it: 1,
        title_de: 1,
        title_en: 1,
        description: 1,
        description_it: 1,
        description_de: 1,
        description_en: 1,
        insurance_id: 1,
        detail_available: 1,
        characteristics: 1,
        characteristics_it: 1,
        characteristics_de: 1,
        characteristics_en: 1,
        conditions_lca: 1,
        conditions_lca_it: 1,
        conditions_lca_de: 1,
        conditions_lca_en: 1,
        info_lamal: 1,
        info_lamal_it: 1,
        info_lamal_de: 1,
        info_lamal_en: 1,
        info_lamal_public: 1,
        info_lamal_public_it: 1,
        info_lamal_public_de: 1,
        info_lamal_public_en: 1,
        product_category: 1,
        choice_of_doctor: 1,
        choice_of_doctor_it: 1,
        choice_of_doctor_de: 1,
        choice_of_doctor_en: 1,
        choice_of_pharmacy: 1,
        choice_of_pharmacy_it: 1,
        choice_of_pharmacy_de: 1,
        choice_of_pharmacy_en: 1,
        'icon.path': 1,
        'icon_selected.path': 1,
        family_doctor: 1,
        family_doctor_mandatory: 1,
        recognised_pharmacies: 1,
        description_insured: 1,
        characteristics_insured: 1,
        url: 1,
        url_it: 1,
        url_de: 1,
        url_en: 1,
        type: 1
    },

    PRODUCTS_OVERVIEW: {
        _id: 1,
        identifier: 1,
        card_description: 1,
        card_description_it: 1,
        card_description_de: 1,
        card_description_en: 1,
        'icon.path': 1,
        'icon_selected.path': 1,
        sections: 1,
        sections_it: 1,
        sections_de: 1,
        sections_en: 1
    },

    PRODUCT_CATEGORIES: {
        _id: 1,
        id: 1,
        title: 1,
        title_it: 1,
        title_de: 1,
        title_en: 1,
        description: 1,
        description_it: 1,
        description_de: 1,
        description_en: 1,
        characteristics: 1,
        characteristics_it: 1,
        characteristics_de: 1,
        characteristics_en: 1,
        highlight_products: 1,
        highlight_products_it: 1,
        highlight_products_de: 1,
        highlight_products_en: 1,
        'icon.path': 1,
        url: 1,
        url_it: 1,
        url_de: 1,
        url_en: 1,
        'photo.path': 1
    },

    PRODUCT_GROUPS: {
        _id: 1,
        id: 1,
        products: 1,
        categories: 1
    },

    NEWS: {
        _id: 1,
        order: 1,
        title: 1,
        title_it: 1,
        title_de: 1,
        title_en: 1,
        description: 1,
        description_it: 1,
        description_de: 1,
        description_en: 1,
        'image.path': 1,
        link_url: 1,
        link_url_it: 1,
        link_url_de: 1,
        link_url_en: 1,
        link_url_ec: 1,
        link_url_ec_it: 1,
        link_url_ec_de: 1,
        link_url_ec_en: 1,
        ec_link: 1,
        target: 1,
        is_webview: 1,
        localization: 1,
        start_date_publication: 1,
        end_date_publication: 1
    },
    ASSETS: {
        _id: 1,
        code: 1,
        assetName: 1,
        assetName_it: 1,
        assetName_de: 1,
        assetName_en: 1
    },
    GOOD_TO_KNOW: {
        goodToKnowId: 1,
        goodToKnowTitle: 1,
        goodToKnowTitle_it: 1,
        goodToKnowTitle_de: 1,
        goodToKnowTitle_en: 1,
        goodToKnowDescription: 1,
        goodToKnowDescription_it: 1,
        goodToKnowDescription_de: 1,
        goodToKnowDescription_en: 1,
        goodToKnowLinkType: 1,
        goodToKnowLinkIcon: 1,
        goodToKnowLinkLabel: 1,
        goodToKnowLinkLabel_it: 1,
        goodToKnowLinkLabel_de: 1,
        goodToKnowLinkLabel_en: 1,
        goodToKnowLinkValue: 1,
        goodToKnowLinkValue_it: 1,
        goodToKnowLinkValue_de: 1,
        goodToKnowLinkValue_en: 1,
        didYouKnowTitle: 1,
        didYouKnowTitle_it: 1,
        didYouKnowTitle_de: 1,
        didYouKnowTitle_en: 1,
        didYouKnowDescription: 1,
        didYouKnowDescription_it: 1,
        didYouKnowDescription_de: 1,
        didYouKnowDescription_en: 1,
        didYouKnowLinkType: 1,
        didYouKnowLinkIcon: 1,
        didYouKnowLinkLabel: 1,
        didYouKnowLinkLabel_it: 1,
        didYouKnowLinkLabel_de: 1,
        didYouKnowLinkLabel_en: 1,
        didYouKnowLinkValue: 1,
        didYouKnowLinkValue_it: 1,
        didYouKnowLinkValue_de: 1,
        didYouKnowLinkValue_en: 1,
        bulletPointsTitle: 1,
        bulletPointsTitle_it: 1,
        bulletPointsTitle_de: 1,
        bulletPointsTitle_en: 1,
        bulletPointsDescription: 1,
        bulletPointsDescription_it: 1,
        bulletPointsDescription_de: 1,
        bulletPointsDescription_en: 1,
        'bulletPointsAdditionalPoints.value': 1,
        'bulletPointsAdditionalPoints_it.value': 1,
        'bulletPointsAdditionalPoints_de.value': 1,
        'bulletPointsAdditionalPoints_en.value': 1
    },
    FAQ_CATEGORY: {
        _id: 1,
        id: 1,
        order: 1,
        is_active: 1,
        title: 1,
        title_it: 1,
        title_de: 1,
        title_en: 1
    },
    FAQ: {
        _id: 1,
        id: 1,
        order: 1,
        is_active: 1,
        question: 1,
        question_it: 1,
        question_de: 1,
        question_en: 1,
        faq_category: 1,
        answer: 1,
        answer_it: 1,
        answer_de: 1,
        answer_en: 1,
        answer_website: 1,
        answer_website_it: 1,
        answer_website_de: 1,
        answer_website_en: 1
    },
    SUGGESTIONS: {
        _id: 1,
        order: 1,
        start_date_publication: 1,
        end_date_publication: 1,
        label: 1,
        label_it: 1,
        label_de: 1,
        label_en: 1,
        ec_link: 1
    },
    EC_LINKS: {
        _id: 1,
        type: 1,
        is_active: 1,
        link_url: 1,
        link_url_it: 1,
        link_url_de: 1,
        link_url_en: 1
    },
    QUICKACCESS: {
        _id: 1,
        order: 1,
        heading: 1,
        heading_it: 1,
        heading_de: 1,
        heading_en: 1,
        sub_heading: 1,
        sub_heading_it: 1,
        sub_heading_de: 1,
        sub_heading_en: 1,
        start_date_publication: 1,
        end_date_publication: 1,
        icon_id: 1,
        ec_link: {
            _id: 1,
            link: 1,
            display: 1
        }
    },
    FREQUENT_REQUESTS: {
        _id: 1,
        is_active: 1,
        icon_id: 1,
        label: 1,
        label_it: 1,
        label_de: 1,
        label_en: 1,
        ec_link: 1
    },
    GENERAL_QUESTION_TOPIC: {
        _id: 1,
        id: 1,
        topic: 1,
        topic_it: 1,
        topic_de: 1,
        topic_en: 1,
        order: 1,
        is_active: 1,
        forms_icon: 1,
        forms_title: 1,
        forms_title_it: 1,
        forms_title_de: 1,
        forms_title_en: 1,
        forms_collection: 1,
        forms_collection_it: 1,
        forms_collection_de: 1,
        forms_collection_en: 1,
        faq_icon: 1,
        faq_title_it: 1,
        faq_title_de: 1,
        faq_title_en: 1,
        faq_collection: 1,
        faq_collection_it: 1,
        faq_collection_de: 1,
        faq_collection_en: 1
    },
    WHATS_NEW: {
        _id: 1,
        id: 1,
        validity_start: 1,
        validity_end: 1,
        target: 1,
        cta_text: 1,
        cta_text_it: 1,
        cta_text_de: 1,
        cta_text_en: 1,
        date: 1,
        link_ecl: 1,
        panels: 1,
        panels_it: 1,
        panels_de: 1,
        panels_en: 1
    },
    SPLASH_SCREEN: {
        id: 1,
        validity_start: 1,
        validity_end: 1,
        title: 1,
        title_it: 1,
        title_de: 1,
        title_en: 1,
        text: 1,
        text_it: 1,
        text_de: 1,
        text_en: 1,
        image: 1,
        cta_text: 1,
        cta_text_it: 1,
        cta_text_de: 1,
        cta_text_en: 1,
        cta_ec_link: 1
    }
}
