import './RecommendedProductCard.css'

import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import defaultImage from 'shared/assets/images/recommendedProductDefault.png'
import ProductCardHeader from 'shared/components/ProductCardHeader/ProductCardHeader'

import RecommendedProductCardBody from './RecommendedProductCardBody'
import RecommendedProductCardFooter from './RecommendedProductCardFooter'

interface RecommendedProductCardProps {
    recommendedProduct: RecommendedProduct
}

const RecommendedProductCard = (props: RecommendedProductCardProps): JSX.Element => {
    const { recommendedProduct } = props
    return (
        <div className="recommended-product-card-data-container bc-gray100 bg-white">
            <div
                className="recommended-product-card-image"
                style={{
                    backgroundImage: `url(${
                        recommendedProduct.photo
                            ? process.env.REACT_APP_CMS_ASSET + recommendedProduct.photo
                            : defaultImage
                    })`
                }}
            />
            <div className="recommended-product-card-container">
                <div className="recommanded-card-header">
                    <ProductCardHeader
                        title={recommendedProduct.title}
                        subtitle={recommendedProduct.subtitle}
                        icon={recommendedProduct.icon}
                        productId={recommendedProduct.insuranceId}
                    />
                    <RecommendedProductCardBody recommendedProduct={recommendedProduct} />
                </div>
                {recommendedProduct.url ? (
                    <RecommendedProductCardFooter recommendedProduct={recommendedProduct} />
                ) : null}
            </div>
        </div>
    )
}

export default RecommendedProductCard
