import './AssuraDropzone.css'

import { createContext, useContext } from 'react'
import {
    Accept,
    DropzoneState,
    ErrorCode,
    FileError,
    FileRejection,
    FileWithPath,
    useDropzone
} from 'react-dropzone'

import { DROPZONE_REJECTED_FORMAT_LIST } from 'core/constants/Dropzone'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'

import { emptyFunction } from '../../../core/helpers/functionHelper'
import AssuraDropzoneError from './AssuraDropzoneError'
import AssuraDropzoneLoading from './AssuraDropzoneLoading'
import AssuraDropzoneSuccess from './AssuraDropzoneSuccess'
import AssuraDropzoneWaiting from './AssuraDropzoneWaiting'

export interface AssuraDropzoneProps {
    testId: string
    accept: Accept
    multiple: boolean
    maxSize: number
    handleRejectedFile: (rejectedFile: FileRejection) => void
    handleAcceptedFile: (acceptedFile: FileWithPath) => void
    importFileStatus: LoadingStatusEnum | undefined
    changeImportFileStatus: (status: LoadingStatusEnum) => void
    loadingText: string
    errorText: string
    acceptedFileText: string
    mandatoryErrorText?: string
    filename?: string
    successComponent?: JSX.Element | null
    onDelete?: () => void
}

const AssuraDropzoneContext = createContext<DropzoneState | null>(null)

const rejectValidator = (file: File): FileError | null => {
    if (DROPZONE_REJECTED_FORMAT_LIST.some((o) => file.name.endsWith(o))) {
        return {
            code: ErrorCode.FileInvalidType,
            message: `Please use any of the accepted formats only`
        }
    }
    return null
}

const AssuraDropzone = ({
    testId,
    accept,
    multiple,
    maxSize,
    handleRejectedFile,
    handleAcceptedFile,
    importFileStatus,
    changeImportFileStatus,
    loadingText,
    errorText,
    acceptedFileText,
    mandatoryErrorText,
    filename = '',
    successComponent,
    onDelete = emptyFunction
}: AssuraDropzoneProps): JSX.Element => {
    const methods = useDropzone({
        accept,
        multiple,
        maxSize,
        validator: rejectValidator,
        onDrop: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
            changeImportFileStatus(LoadingStatusEnum.LOADING)
            if (fileRejections && fileRejections.length > 0) {
                handleRejectedFile(fileRejections[0])
            } else {
                handleAcceptedFile(acceptedFiles[0])
            }
        },
        useFsAccessApi: false
    })

    const renderView = (): JSX.Element => {
        switch (importFileStatus) {
            case LoadingStatusEnum.LOADING:
                return <AssuraDropzoneLoading text={loadingText} />
            case LoadingStatusEnum.ERROR:
                return <AssuraDropzoneError testId={testId} text={errorText} />
            case LoadingStatusEnum.OK:
                if (successComponent === null) break
                return (
                    successComponent ?? (
                        <AssuraDropzoneSuccess
                            testId={testId}
                            filename={filename}
                            onDelete={onDelete}
                        />
                    )
                )
        }

        return (
            <AssuraDropzoneWaiting
                testId={testId}
                helperText={acceptedFileText}
                mandatoryErrorText={mandatoryErrorText}
            />
        )
    }

    return (
        <AssuraDropzoneContext.Provider value={methods}>
            {renderView()}
        </AssuraDropzoneContext.Provider>
    )
}

export const useAssuraDropzoneContext = (): DropzoneState | null => {
    return useContext(AssuraDropzoneContext)
}

export default AssuraDropzone
