import './ChooseDoctor.css'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Canton } from 'core/enums/Canton'
import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import { AssuraFormInputRHFNoLabelWithLeftIcon } from 'modules/services/components/InputRHF/InputRHF'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraSelect, { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { getCanHavePediatricianByPage } from 'shared/store/selectors/getCanHavePediatricianByPage'
import {
    fetchSearchDoctors,
    fetchSearchDoctorsSuccess,
    getDoctorSearchName,
    getSearchDoctors,
    getSearchDoctorsLoadingStatus,
    getSelectedCanton,
    setDoctorSearchName,
    setSelectedCanton
} from 'shared/store/services/doctors/doctors.slice'

import DoctorType from './DoctorType'
import SearchDoctorNoResults from './SearchDoctorNoResults'
import SearchDoctorResults from './SearchDoctorResults'
import StartDoctorSearch from './StartDoctorSearch'

interface ChooseDoctorProps {
    code: string
}

const ChooseDoctor = ({ code }: ChooseDoctorProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const cantonsMap = new Map() as SelectMap<Canton>
    for (const value of Object.values(Canton)) {
        cantonsMap.set(t(`CANTON.${value}`), value)
    }

    const {
        getValues,
        setValue,
        watch,
        register,
        formState: { errors }
    } = useFormContext()

    const [isFocus, setIsFocus] = useState(false)
    const doctorSearchName = useSelector(getDoctorSearchName)
    const selectedCanton = useSelector(getSelectedCanton)

    const searchDoctors = useSelector(getSearchDoctors)
    const searchDoctorsLoadingStatus = useSelector(getSearchDoctorsLoadingStatus)
    const canHavePediatrician = useSelector(getCanHavePediatricianByPage)

    const doctorType = watch('doctorType')
    const searchDoctorName = watch('searchDoctorName')

    useEffect(() => {
        dispatch(setDoctorSearchName(searchDoctorName))
        if (!!searchDoctorName && searchDoctorName.length > 2) {
            dispatch(fetchSearchDoctors({ code }))
        } else {
            dispatch(fetchSearchDoctorsSuccess({ hasMoreResults: false, results: [] }))
        }
        setValue('newDoctorId', undefined)
    }, [searchDoctorName])

    const changeCanton = (canton: Canton | null): void => {
        if (canton) {
            dispatch(setSelectedCanton(canton))
            dispatch(fetchSearchDoctors({ code }))
            setValue('newDoctorId', undefined)
        }
    }

    const validateSearchDoctorName = () => {
        const { searchDoctorName, newDoctorId } = getValues()
        if (!searchDoctorName || searchDoctorName.length < 3 || !newDoctorId) {
            return t('SERVICES.SEARCH_DOCTOR_NAME_ERROR')
        } else {
            return true
        }
    }

    const renderContent = (): JSX.Element => {
        if (!!searchDoctorName && doctorSearchName.length < 3) {
            return <StartDoctorSearch />
        } else if (!!searchDoctorName && searchDoctors.length > 0) {
            return <SearchDoctorResults searchDoctors={searchDoctors} />
        } else {
            return <SearchDoctorNoResults />
        }
    }

    const shouldDisplayErrorMessage = Boolean(errors?.searchDoctorName) && !errors?.newDoctorId

    return (
        <div
            className="choose-doctor d-flex flex-column"
            style={doctorType === ServicesDoctorType.DOCTOR ? { minHeight: 373 } : {}}
        >
            {canHavePediatrician && <DoctorType />}
            {doctorType === ServicesDoctorType.DOCTOR && (
                <>
                    <div className={`labelMedium${canHavePediatrician ? ' m-top-48' : ''}`}>
                        {t('SERVICES.CHOOSE_DOCTOR')}
                    </div>
                    <div className="m-top-16 d-flex">
                        <div className="flex-1">
                            <AssuraFormInputRHFNoLabelWithLeftIcon
                                id="services-choose-doctor-search"
                                name="searchDoctorName"
                                placeHolder={t('SERVICES.SEARCH_BY_NAME')}
                                register={register}
                                onFocus={() => setIsFocus(true)}
                                onBlur={() => setIsFocus(false)}
                                hasButton={isFocus}
                                onButtonClick={() => setValue('searchDoctorName', '')}
                                iconClass="icon assura-close size-24"
                                isInvalid={shouldDisplayErrorMessage}
                                error={
                                    shouldDisplayErrorMessage
                                        ? (errors.searchDoctorName?.message as string)
                                        : ''
                                }
                                rules={{
                                    validate: validateSearchDoctorName
                                }}
                                leftIcon="assura-search"
                                isFocus={isFocus}
                            />
                        </div>
                        <AssuraSelect
                            id="services-choose-doctor-canton"
                            value={selectedCanton}
                            items={cantonsMap}
                            onChange={(canton) => changeCanton(canton)}
                            containerClassnames="choose-doctor-cantons-select-container"
                            toggleClassnames="choose-doctor-cantons-select-toggle"
                            menuClassnames="choose-doctor-cantons-select-menu"
                            alignRight
                            shouldDisplayValueInToggle
                        />
                    </div>

                    <AssuraLoadAndError
                        status={searchDoctorsLoadingStatus}
                        defaultReloadAction={() => dispatch(fetchSearchDoctors({ code }))}
                    >
                        {renderContent()}
                    </AssuraLoadAndError>
                </>
            )}
        </div>
    )
}

export default ChooseDoctor
