import './InvoicesToCheckPanelForm.css'

import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    InvoiceCheckingDetails,
    InvoiceCheckingRHFForm
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'

import InvoicesToCheckPanelForm from './InvoicesToCheckPanelForm'

interface InvoicesToCheckPanelFormContainerProps {
    selectedDocument?: InvoiceCheckingDetails
    handleDetailsVisibility: (isVisible: boolean) => void
}

const InvoicesToCheckPanelFormContainer = ({
    selectedDocument,
    handleDetailsVisibility
}: InvoicesToCheckPanelFormContainerProps): JSX.Element => {
    // default values definition
    const initialDefaultValues: InvoiceCheckingRHFForm = {
        isConsentChecked: false,
        questions: selectedDocument?.questions ?? []
    }
    // Co-type with DefaultValues in order to be accepted by RHF
    const _initialValues = initialDefaultValues as DefaultValues<
        FieldValues | InvoiceCheckingRHFForm
    >

    // FORM INIT
    const methods = useForm<InvoiceCheckingRHFForm>({
        defaultValues: _initialValues,
        shouldFocusError: false,
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    })

    return (
        <AssuraLoadAndError status={LoadingStatusEnum.OK}>
            <FormProvider {...methods}>
                <InvoicesToCheckPanelForm handleDetailsVisibility={handleDetailsVisibility} />
            </FormProvider>
        </AssuraLoadAndError>
    )
}

export default InvoicesToCheckPanelFormContainer
