interface FormTitleProps {
    title: string
    category?: string
}

const FormTitle = ({ title, category }: FormTitleProps): JSX.Element => {
    return (
        <div className="m-bottom-56">
            {category && (
                <div
                    className="label c-gray500 text-uppercase m-bottom-12"
                    data-testid="services-form-category"
                >
                    {category}
                </div>
            )}
            <div className="title break-word" data-testid="services-form-title">
                {title}
            </div>
        </div>
    )
}

export default FormTitle
