import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { dateIntervalCheck } from 'core/helpers/DateHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { QuickAccess } from 'core/models/cms/QuickAccessCms'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getQuickAccessCms } from '../quickAccess/quickAccess.slice'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getQuickAccessLocalized = createSelector(
    [getLang, getShortContactLanguage, getQuickAccessCms],
    (lang, contactLang, quickAccessesCms): QuickAccess[] => {
        const todayIso = new Date().toISOString()
        const today = Date.parse(todayIso)
        const quickAccessesCmsFilteredByDates = quickAccessesCms.filter(
            (x) =>
                (!x.start_date_publication && !x.end_date_publication) ||
                (x.start_date_publication &&
                    !x.end_date_publication &&
                    Date.parse(x.start_date_publication) < today) ||
                (!x.start_date_publication &&
                    x.end_date_publication &&
                    Date.parse(x.end_date_publication) > today) ||
                (x.start_date_publication &&
                    x.end_date_publication &&
                    dateIntervalCheck(x.start_date_publication, x.end_date_publication, todayIso))
        )

        return quickAccessesCmsFilteredByDates.map((quickAccessCms) => {
            if (lang === ENGLISH_LANGUAGE && !quickAccessCms.heading_en) {
                return getLocalizedObject(quickAccessCms, contactLang) as QuickAccess
            } else {
                return getLocalizedObject(quickAccessCms, lang) as QuickAccess
            }
        })
    }
)
