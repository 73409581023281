import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { UnpaidStats } from 'core/models/UnpaidStats'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface UnpaidStatsState {
    loadingStatus: LoadingStatusEnum
    data?: UnpaidStats
}

const INITIAL_STATE_UNPAID_STATS: UnpaidStatsState = {
    loadingStatus: LoadingStatusEnum.LOADING,
    data: undefined
}

export const unpaidStatsSlice = createSlice({
    name: 'unpaidStats',
    initialState: INITIAL_STATE_UNPAID_STATS,
    reducers: {
        fetchUnpaidStats: (state) => {
            return state
        },
        setUnpaidStatsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.loadingStatus = action.payload
        },
        setUnpaidStatsData: (state, action: PayloadAction<UnpaidStats | undefined>) => {
            state.data = action.payload
        }
    }
})

export const {
    fetchUnpaidStats,
    setUnpaidStatsLoadingStatus,
    setUnpaidStatsData
} = unpaidStatsSlice.actions

export const getUnpaidStatsState: Selector<RootState, UnpaidStatsState> = (state: RootState) =>
    state.unpaidStats

export const getUnpaidStatsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.unpaidStats.loadingStatus

export const getUnpaidStats: Selector<RootState, UnpaidStats | undefined> = (state: RootState) =>
    state.unpaidStats.data

export default unpaidStatsSlice.reducer
