import { useSelector } from 'react-redux'

import { PharmaFile } from 'core/helpers/services/ServicesHelper'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import pharmacyIcon from 'shared/assets/images/pharmacy.png'
import AssuraCmsLink from 'shared/components/AssuraCmsLink/AssuraCmsLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { useAppDispatch } from 'shared/store/store'

import {
    ConditionalInformations,
    displayConditionalInformations,
    SpecificationLine
} from './LamalSpecificationsCard'

interface RenderPharmacyProps {
    code: string
    choiceOfPharmacy: string | undefined
}

const Pharmacy = (props: RenderPharmacyProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const pharmacyDisplay: ConditionalInformations = displayConditionalInformations(props.code)
    const assets = useSelector(getAssets)

    const isOnError = () => {
        dispatch(
            setBanner({
                dataTestId: 'service-submission-error',
                message: 'COMMON.ERROR'
            })
        )
    }

    return (
        <SpecificationLine
            id="pharmacy"
            code={props.code}
            illustration={pharmacyIcon}
            informationsContainer={
                <span className="labelSmallMedium">
                    {props.choiceOfPharmacy &&
                        stringUpperCaseFirstCharacter(props.choiceOfPharmacy)}
                </span>
            }
            linkContainer={
                <div
                    className="cursor-pointer align-items-center"
                    style={{
                        display: pharmacyDisplay.isPharmaciesPdfListVisible ? 'flex' : 'none'
                    }}
                >
                    {PharmaFile(props.code, assets) ? (
                        <AssuraCmsLink
                            asset={PharmaFile(props.code, assets)}
                            classNames="labelExtraSmall d-block c-primary"
                            icon={{ name: 'assura-doc', size: 24 }}
                        />
                    ) : (
                        <AssuraIconButton
                            classNames="c-primary"
                            id="pharmacy-link-button"
                            icon="assura-doc"
                            variant="default"
                            size="medium"
                            onClick={isOnError}
                        />
                    )}
                </div>
            }
        />
    )
}

export default Pharmacy
