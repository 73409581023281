import { FieldType } from './AssuraFormInputWithValidations'

type PasswordToggleProps = {
    dataTestId: string
    fieldType: FieldType
    setFieldType: React.Dispatch<React.SetStateAction<FieldType>>
}

const PasswordToggle = ({ dataTestId, fieldType, setFieldType }: PasswordToggleProps) => {
    const onVisibleClick = () => {
        setFieldType((state) => {
            return {
                ...state,
                isVisible: !state.isVisible,
                type: state.isVisible ? 'text' : 'password'
            }
        })
    }
    return (
        <div className="assura-input-label-password-visible" data-testid={dataTestId + '-icon'}>
            {fieldType.isVisible ? (
                <i className={'icon size-20 assura-visible'} onClick={onVisibleClick} />
            ) : (
                <i className={'icon size-20 assura-not-visible'} onClick={onVisibleClick} />
            )}
        </div>
    )
}

export default PasswordToggle
