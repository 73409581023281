import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestRow } from 'core/models/services/RequestData'
import concatClassNames from 'core/utils/classNameUtils'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import StatusIconField from 'shared/components/AssuraTable/Fields/StatusIconField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

interface RequestsTableRowMobileProps {
    row: RequestRow
    mainTextColorClass?: string
    typeTextClasses?: string
}

const RequestsTableRowMobile = ({
    row,
    mainTextColorClass,
    typeTextClasses
}: RequestsTableRowMobileProps): JSX.Element => {
    const { t } = useTranslation()

    const goToExtraMigrationClass = row.shouldHideData ? 'hidden' : ''
    const goToClasses = concatClassNames(['p-left-8', goToExtraMigrationClass])
    const mainClasses = concatClassNames(['w-100', mainTextColorClass])
    const typeClasses = concatClassNames(['text-left label max-1-text-line', typeTextClasses])

    return (
        <>
            <td className={mainClasses}>
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8">
                            <StatusIconField
                                statusColor={row.statusColor}
                                rowId={row.index}
                                isMobileStyle
                            />
                            <DateField
                                date={row.creationDate ?? ''}
                                rowId={row.index}
                                classNames="labelExtraSmall"
                                isMobileStyle
                            />
                            <Separator />
                            <StatusField
                                status={row.statusTranslated}
                                statusIcon={row.statusIcon}
                                statusColor={row.statusColor}
                                rowId={row.index}
                                statusTranslatedOptions={
                                    row.processingDate &&
                                    row.statusTranslated === 'STATUS.COMPLETED_DATE'
                                        ? { date: formatDate(row.processingDate) }
                                        : undefined
                                }
                                labelClassNames="labelExtraSmall"
                                isMobileStyle
                            />
                            {row.insuredPersonName && (
                                <>
                                    <Separator />
                                    <TextField
                                        label={row.insuredPersonName}
                                        classNames="labelExtraSmall"
                                        rowId={row.index}
                                        fieldId="insured-person"
                                    />
                                </>
                            )}
                        </tr>
                        <tr>
                            <TextField
                                label={t(row.typeTranslated)}
                                classNames={typeClasses}
                                rowId={row.index}
                                fieldId="type"
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            <GoToField classNames={goToClasses} rowId={row.index} />
        </>
    )
}

export default RequestsTableRowMobile
