import './WhatsNewArrow.css'

import { CustomArrowProps } from 'react-slick'

import concatClassNames from 'core/utils/classNameUtils'

interface WhatsNewArrowProps extends CustomArrowProps {
    direction: 'left' | 'right'
}

const WhatsNewArrow = ({ direction, ...customArrowProps }: WhatsNewArrowProps): JSX.Element => {
    const { onClick, currentSlide, slideCount } = customArrowProps

    let isDisabled = false

    if (direction === 'left') {
        isDisabled = currentSlide === 0
    } else if (slideCount !== undefined) {
        isDisabled = currentSlide === slideCount - 1
    }

    const disabledClass = isDisabled ? 'disabled' : undefined
    const arrowClasses = concatClassNames(['whats-new-arrow-container', disabledClass, direction])

    return (
        <div
            className={arrowClasses}
            data-testid={`whats-new-arrow-icon-${direction}`}
            onClick={onClick}
        >
            <i className={`icon assura-${direction} size-24 cursor-pointer}`} />
        </div>
    )
}

export default WhatsNewArrow
