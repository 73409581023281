import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AssuraCmsLink from 'shared/components/AssuraCmsLink/AssuraCmsLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getAssets } from 'shared/store/selectors/getAssets'

const LamalAccidentDownload = (): JSX.Element => {
    const { t } = useTranslation()
    const assets = useSelector(getAssets)
    const dispatch = useDispatch()

    const handleAssetInError = () => {
        dispatch(
            setBanner({
                dataTestId: 'services-lamal-accident-laa-download-error-banner',
                message: 'FAMILY_GROUP.LOADING_ERROR'
            })
        )
    }

    return assets.Formulaire_LAA ? (
        <AssuraCmsLink
            asset={assets.Formulaire_LAA}
            title={t('SERVICES.COVERAGE_ACCIDENT_LAA_DOWNLOAD')}
            classNames="cta c-primary m-top-32"
            icon={{ name: 'assura-download', size: 24 }}
        />
    ) : (
        <AssuraIconButton
            onClick={handleAssetInError}
            id="services-lamal-accident-laa-download-error"
            size="medium"
            variant="primary"
            icon="assura-download"
            label={t('SERVICES.COVERAGE_ACCIDENT_LAA_DOWNLOAD')}
            classNames="m-top-32"
        />
    )
}

export default LamalAccidentDownload
