import { Product } from 'core/models/Product'
import useWindowSize from 'core/services/useWindowSize'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'

import ProductCardPanelFooter from '../ProductCard/ProductCardPanelFooter'
import ProductCardPanelBody from './ProductCardPanelBody'
import ProductCardPanelHeader from './ProductCardPanelHeader'

interface ProductCardPanelProps {
    product?: Product
    closePanel: () => void
}

const ProductCardPanel = ({ product, closePanel }: ProductCardPanelProps): JSX.Element => {
    const { isMD, isXL } = useWindowSize()
    return (
        <AssuraPanel
            type="right"
            isOpen={product !== undefined}
            size={isMD ? 69 : isXL ? 43 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <>
                {product && (
                    <>
                        <ProductCardPanelHeader
                            productTitle={product.title}
                            closePanel={closePanel}
                        />
                        <ProductCardPanelBody product={product} />
                        {product.url && <ProductCardPanelFooter url={product.url} />}
                    </>
                )}
            </>
        </AssuraPanel>
    )
}

export default ProductCardPanel
