import { useTranslation } from 'react-i18next'

import coverageIcon from 'shared/assets/images/coverage.png'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'

const NoActiveContract = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center"
            data-testid="no-active-contract-container"
        >
            <img src={coverageIcon} className="image-size-56" alt="no-active-contract-icon" />
            <div className="headlineSmall m-top-24 m-bottom-24">
                {t('SERVICES.NO_ACTIVE_CONTRACT')}
            </div>
            <AssuraEcLink
                ecLinkId="646391065afb28b49a04023d"
                testId="no-active-contract-link"
                classNames="assura-link paragraphSmallLight"
            >
                {t('SERVICES.DISCOVER_PRODUCTS')}
            </AssuraEcLink>
        </div>
    )
}

export default NoActiveContract
