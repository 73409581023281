export const serviceName = 'SERVICE.ACCIDENT_REPORT_FORM'

import { TFunction } from 'i18next'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'

export const getEmploymentStatusList = (t: TFunction): SelectMap<string> =>
    toMapList('SERVICE.ACCIDENT_REPORT.EMPLOYMENT_STATUS.LIST.', employmentStatusList, t)

export const toMapList = (key: string, values: string[], t: TFunction): SelectMap<string> => {
    const topics = new Map() as SelectMap<string>
    values.forEach((item) => {
        topics.set(t(`${key}${item}`), item)
    })
    return topics
}

export const employmentStatusList: string[] = [
    'EMPLOYED',
    'UNPAID',
    'INDEPENDANT',
    'STUDENT',
    'RETIRED',
    'MILITARY',
    'UNEMPLOYED',
    'CHILD',
    'APPRENTICE',
    'HOUSEWIFE'
]
