import './LamalAccident.css'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServiceType } from 'core/enums/ServiceType'
import { formatDateToApiFormat } from 'core/helpers/DateFormatHelper'
import {
    LamalAccidentForm,
    LamalAccidentFormRHF
} from 'core/models/services/coverage/LamalAccidentForm'
import { getCurrentYear } from 'core/utils/dateUtils'
import { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import FormPage from 'modules/services/components/FormPage/FormPage'
import { isThereAccidentSuspensionInclusionInRequests } from 'shared/store/selectors/isThereInRequests'
import { onSubmitLamalAccident } from 'shared/store/services/coverage/coverage.slice'
import { fetchSummaryAndProducts, getFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import LamalAccidentMainBlock from '../components/LamalAccidentMainBlock/LamalAccidentMainBlock'
import LamalAccidentSelect from '../components/LamalAccidentSelect/LamalAccidentSelect'

const LamalAccident = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false)
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const hasPendingRequest = useSelector(isThereAccidentSuspensionInclusionInRequests)

    const initialValues: LamalAccidentFormRHF = {
        effectiveDate: undefined,
        include: undefined,
        attachedFile: undefined,
        consent: false
    }

    const [defaultValues, setDefaultValues] = useState<LamalAccidentFormRHF>(initialValues)

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        setDefaultValues({ ...initialValues })
        dispatch(
            fetchSummaryAndProducts({
                policyNumber: familyGroupResult.selectedPolicyNumber,
                year: getCurrentYear()
            })
        )
    }

    const onSuccess = (values: LamalAccidentFormRHF) => {
        const lamalAccidentForm: LamalAccidentForm = {
            ...values,
            effectiveDate: values.effectiveDate ? formatDateToApiFormat(values.effectiveDate) : '',
            include: values.include === undefined ? false : values.include,
            attachedFile: values.attachedFile ? values.attachedFile : '',
            ...familyGroupResult
        }
        dispatch(onSubmitLamalAccident(lamalAccidentForm))
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.AUTO_LAMAL_ACC_SUSP_INCL}
            formTitle={{
                title: t('SERVICES.COVERAGE_ACCIDENT_TITLE'),
                category: t('SERVICES.COVERAGE_REQUEST')
            }}
            shouldDisplaySubmitButton={isSubmitAllowed}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            onSuccess={onSuccess}
            familyGroupProps={{ selectAction: selectMember }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.AUTO_LAMAL_ACC_SUSP_INCL} />}
            isPending={hasPendingRequest ? <PendingRequest /> : undefined}
        >
            <LamalAccidentSelect selectedPolicyNumber={familyGroupResult.selectedPolicyNumber} />
            <LamalAccidentMainBlock
                selectedPolicyNumber={familyGroupResult.selectedPolicyNumber}
                setIsSubmitAllowed={setIsSubmitAllowed}
            />
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    LamalAccident,
    'LamalAccident',
    'services-lamal-accident-container'
)
