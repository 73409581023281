import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../../store'

export interface DeleteAccountState {
    isAccountDeleted: boolean
    deleteAccountLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_DELETE_ACCOUNT: DeleteAccountState = {
    isAccountDeleted: false,
    deleteAccountLoadingStatus: LoadingStatusEnum.OK
}

export const deleteAccountSlice = createSlice({
    name: 'deleteAccount',
    initialState: INITIAL_STATE_DELETE_ACCOUNT,
    reducers: {
        setisAccountDeleted: (state, action: PayloadAction<boolean>) => {
            state.isAccountDeleted = action.payload
        },
        setDeleteAccountLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.deleteAccountLoadingStatus = action.payload
        },
        submitDeleteAccount: (state, _action: PayloadAction<number>) => {
            return state
        }
    }
})

export const { setisAccountDeleted, setDeleteAccountLoadingStatus, submitDeleteAccount } =
    deleteAccountSlice.actions

export const getDeleteAccountLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.deleteAccount.deleteAccountLoadingStatus

export const getIsAccountDeleted: Selector<RootState, boolean> = (state) =>
    state.deleteAccount.isAccountDeleted

export default deleteAccountSlice.reducer
