import './SideNavigationElement.css'

import { useTranslation } from 'react-i18next'

import { SideNavigationItem, SideNavigationItemId } from 'core/models/SideNavigation'
import useWindowSize from 'core/services/useWindowSize'
import concatClassNames from 'core/utils/classNameUtils'

import { useSideNavigationContext } from './SideNavigationProvider'

interface SideNavigationElementProps<T extends SideNavigationItemId> {
    id: T
    item: SideNavigationItem
}

const SideNavigationElement = <T extends SideNavigationItemId>({
    id,
    item
}: SideNavigationElementProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const { selectedId, handleSelection } = useSideNavigationContext()
    const isSelected = selectedId === id

    const selectionClass = isSelected ? 'active' : undefined
    const containerClasses = concatClassNames(['side-navigation-element-container', selectionClass])

    const onClick = () => {
        handleSelection(id)
    }

    return (
        <div
            className={containerClasses}
            onClick={onClick}
            data-testid={`side-navigation-element-${id}`}
        >
            <img src={item.icon} width={40} height={40} />
            <div className="d-flex flex-column justify-content-center m-left-16 flex-1">
                <div
                    className={`break-word ${
                        isSelected ? 'headlineSmallMedium' : 'headlineSmallRegular'
                    }`}
                    data-testid={`side-navigation-element-${id}-label`}
                >
                    {t(item.labelKey)}
                </div>
                {item.notification && (
                    <div className="d-flex justify-content-start align-items-center c-primary m-top-4">
                        <i className="icon assura-warning-circle size-8" />
                        <span
                            className="paragraphSmallMedium m-left-8 flex-1 break-word"
                            data-testid={`side-navigation-element-${id}-notification`}
                        >
                            {item.notification}
                        </span>
                    </div>
                )}
            </div>
            {item.badge && (
                <div
                    className="side-navigation-element-badge labelExtraSmallMedium"
                    data-testid={`side-navigation-element-${id}-badge`}
                >
                    {item.badge}
                </div>
            )}
            {isMobile && <i className="icon assura-right size-24 c-gray300 p-left-16" />}
            <span className="side-navigation-element-background" />
        </div>
    )
}

export default SideNavigationElement
