import axios from 'axios'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { EnveloppeImage } from 'core/models/EnveloppeImage'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'

export const fetchEnveloppeImage = async (
    fileName: string,
    type: string
): Promise<EnveloppeImage> => {
    const result = await axios.get(`${process.env.REACT_APP_API_IMAGE}/v3/Images/${fileName}`, {
        responseType: 'blob'
    })

    const blob = new Blob([result.data], { type })

    const base64 = await convertBlobToBase64(blob)

    return {
        type,
        base64,
        loadStatus: LoadingStatusEnum.OK
    }
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',')
    const mime = 'application/pdf'
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
}

export const uploadFile = async (droppedFile: DroppedFile): Promise<string> => {
    const formData = new FormData()
    const file = dataURLtoFile(droppedFile.pdfFileBase64, droppedFile.fileName)
    formData.append('file', file)

    const result = await axios.post(`${process.env.REACT_APP_API_IMAGE}/v3/Images`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
    return result.data
}

export const convertBlobToBase64 = (inputFile: Blob): Promise<string> => {
    const reader = new FileReader()

    return new Promise<string>((resolve, reject) => {
        reader.onerror = () => {
            reader.abort()
            reject(new DOMException('Problem parsing input file.'))
        }

        reader.onload = () => {
            const res = reader.result
            if (res) resolve(res.toString())
        }
        reader.readAsDataURL(inputFile)
    })
}
