import { ProfilePicture } from 'core/models/familyGroup/ProfilePicture'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_FAMILY_PICTURES: Record<number, ProfilePicture> = {}

export interface DataFetchPictureSuccess {
    policyNumber: number
    picture: ProfilePicture
}

export const familyPicturesSlice = createSlice({
    name: 'familyPictures',
    initialState: INITIAL_STATE_FAMILY_PICTURES,
    reducers: {
        fetchProfilePicture: (state, _action: PayloadAction<number>) => {
            return state
        },
        fetchProfilePictureSuccess: (state, action: PayloadAction<DataFetchPictureSuccess>) => {
            state[action.payload.policyNumber] = action.payload.picture
        },

        reset: () => INITIAL_STATE_FAMILY_PICTURES
    }
})

export const { fetchProfilePicture, fetchProfilePictureSuccess } = familyPicturesSlice.actions

export const getProfilePictures: Selector<RootState, Record<number, ProfilePicture>> = (state) =>
    state.familyPictures

export default familyPicturesSlice.reducer
