import { RegisterOptions, ValidationRule } from 'react-hook-form'

import { TFunction } from 'i18next'

import { commonMandatoryErrorKey } from './constants'
import { ValidationRowItem } from './entities'

// Generate the validation rules as expected by RHF
// This function will return
// - true when all rules are met
// - the first error message if not for one or more
// - the fallback: errorMessage if set
export const getValidationRules = (
    validations: Record<string, ValidationRowItem>,
    errorMessage: string,
    isRequired: boolean
): Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> => {
    const validationObject = () => {
        return (
            // reduce validation and collect all the valid property values.
            Object.keys(validations)
                .reduce<boolean[]>((acc, key) => {
                    acc.push(validations[key].valid)
                    return acc
                }, [])
                // Evaluate all valid value if true
                .every(Boolean) ||
            // Or reduce and collect not valid error message
            Object.keys(validations)
                .reduce<string[]>((acc, key) => {
                    if (validations[key].valid) return acc
                    if (!validations[key].error) return acc
                    acc.push(validations[key].error)
                    return acc
                }, [])
                // pick the first one
                .pop() ||
            // fallback error message
            errorMessage
        )
    }
    // validation structure for RHF
    return {
        required: {
            value: isRequired,
            message: errorMessage
        },
        validate: validationObject
    }
}

export interface validationOptions {
    mandatory?: boolean
    min?: number
    max?: number
    minLength?: number
    maxLength?: number
    validate?: (value: string) => boolean | string
}

export type compositeOptions = Required<Pick<validationOptions, 'maxLength'>> &
    Partial<Omit<validationOptions, 'maxLength'>>

/**
 * produces a validation object used by React Hook Forms
 * @param t TFunctions from i18Next
 * @param options this is an instance of validationOptions that's describes what's the output rules required by RHF
 * @returns Object that's represents Rules accepted by RHF
 */
export const getValidationRulesFromOptions = (
    t: TFunction,
    options: validationOptions
): Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> => {
    const req =
        options.mandatory !== undefined
            ? ({
                  value: options.mandatory,
                  message: t(commonMandatoryErrorKey)
              } as string | ValidationRule<boolean>)
            : ({} as string | ValidationRule<boolean>)

    const minLengthOpt = options.minLength
        ? ({
              value: options.minLength,
              message: t('FORM.INPUT_MIN_CHARACTERS', { number: options.minLength })
          } as ValidationRule<number> | undefined)
        : ({} as ValidationRule<number> | undefined)

    const maxLengthOpt = options.maxLength
        ? ({
              value: options.maxLength,
              message: t('FORM.INPUT_MAX_CHARACTERS', { number: options.maxLength })
          } as ValidationRule<number> | undefined)
        : ({} as ValidationRule<number> | undefined)

    const minOpt = options.min
        ? ({
              value: options.min,
              message: t('FORM.INPUT_MIN_VALUE', { number: options.min })
          } as ValidationRule<number> | undefined)
        : ({} as ValidationRule<number> | undefined)

    const maxOpt = options.max
        ? ({
              value: options.max,
              message: t('FORM.INPUT_MAX_VALUE', { number: options.max })
          } as ValidationRule<number> | undefined)
        : ({} as ValidationRule<number> | undefined)

    const customValidation = options.validate
        ? (options.validate as (value: string) => boolean | string)
        : ({} as (value: string) => boolean | string)

    return {
        validate: customValidation,
        minLength: minLengthOpt,
        maxLength: maxLengthOpt,
        min: minOpt,
        max: maxOpt,
        required: req
    }
}
