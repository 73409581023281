/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-switch */
import analyticsConstants from 'core/constants/analyticsConstants'
import {
    SymptomCheckerFirstIntentionResponseEnum,
    SymptomCheckerKindOfCareEnum,
    SymptomCheckerMappedActionsEnum,
    SymptomCheckerMappedTypesEnum
} from 'core/enums/SymptomChecker'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    SymptomCheckerEvent,
    SymptomCheckerRequestInterStepBody,
    SymptomCheckerResponseDataFromInterviewFinished,
    SymptomCheckerResponseDataFromPlansSurvey,
    SymptomCheckerResponseDataFromQuestionAnswered
} from 'core/models/SymptomChecker'

export const formatSymptomCheckerInterStepData = (
    dataFromWebView: SymptomCheckerEvent,
    step: number | null,
    concernedPartner: number | null,
    sessionId: string,
    productCode: string | null,
    uuid: string
): [SymptomCheckerRequestInterStepBody, boolean] => {
    const { action, data } = dataFromWebView
    let interStepMustBeSent = false
    const interStep: SymptomCheckerRequestInterStepBody = {
        concernedPartner,
        sessionId,
        productCode: null,
        step: null,
        stepValue: null
    }
    if (productCode) {
        interStep.productCode = productCode
    }
    switch (action) {
        case SymptomCheckerMappedActionsEnum.INITIALIZATION_COMPLETE:
        case SymptomCheckerMappedActionsEnum.CTA_EVENT:
            interStep.step = action
            interStepMustBeSent = true
            break
        case SymptomCheckerMappedActionsEnum.QUESTION_ANSWERED: {
            const { type, answer } = data as SymptomCheckerResponseDataFromQuestionAnswered
            switch (type) {
                case SymptomCheckerMappedTypesEnum.INTENT_SURVEY_CONSULT_MEDICAL_PROFESSIONAL:
                case SymptomCheckerMappedTypesEnum.INTENT_SURVEY_KIND_OF_CARE:
                case SymptomCheckerMappedTypesEnum.INTENT_SURVEY_CONTACT_FORM: {
                    interStep.step = type
                    if (typeof answer === 'string') {
                        interStep.stepValue = answer
                    }
                    interStepMustBeSent = true

                    if (type === SymptomCheckerMappedTypesEnum.INTENT_SURVEY_CONTACT_FORM) {
                        
                        let analyticsFirstIntentionResponse = null
                        switch (answer) {
                            case SymptomCheckerFirstIntentionResponseEnum.MEDICAL_PROFESSIONAL:
                                analyticsFirstIntentionResponse = 'yes'
                                break
                            case SymptomCheckerFirstIntentionResponseEnum.RECOVERING_AT_HOME:
                                analyticsFirstIntentionResponse = 'no'
                                break
                            case SymptomCheckerFirstIntentionResponseEnum.NOT_SURE:
                                analyticsFirstIntentionResponse = 'dont_know'
                                break
                            default:
                                analyticsFirstIntentionResponse = null
                                break
                        }

                        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_REPLY_INTENTION, {
                            [analyticsConstants.PARAMS.REPLY_VALUE]:
                                analyticsFirstIntentionResponse,
                            [analyticsConstants.PARAMS.PARTNER_UUID]: uuid,
                            [analyticsConstants.PARAMS.INSURANCE_MODEL]: productCode,
                            [analyticsConstants.PARAMS.STEP]: step,
                            [analyticsConstants.PARAMS.QUESTION_INTENTION]: 1
                        })
                    }
                    break
                }
                default:
                    break
            }
            break
        }
        case SymptomCheckerMappedActionsEnum.INTERVIEW_FINISHED: {
            interStep.step = action
            const { triageLevel, specialist, conditions } =
                data as SymptomCheckerResponseDataFromInterviewFinished

            if (triageLevel) {
                interStep.stepValue = triageLevel
            }
            interStepMustBeSent = true

            sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_DIAGNOSIS, {
                [analyticsConstants.PARAMS.DIAGNOSIS_VALUE]: triageLevel,
                [analyticsConstants.PARAMS.SPECIALIST_TYPE]: specialist?.id,
                [analyticsConstants.PARAMS.MEETING_RECOMMENDED]: specialist?.channel?.id,
                [analyticsConstants.PARAMS.CONDITIONS]: conditions?.[0]?.id,
                [analyticsConstants.PARAMS.PARTNER_UUID]: uuid,
                [analyticsConstants.PARAMS.INSURANCE_MODEL]: productCode,
                [analyticsConstants.PARAMS.STEP]: step
            })
            break
        }
        case SymptomCheckerMappedActionsEnum.PLANS_SURVEY: {
            interStep.step = action
            const { kindOfCare } = data as SymptomCheckerResponseDataFromPlansSurvey
            if (kindOfCare) {
                interStep.stepValue = kindOfCare
            }
            interStepMustBeSent = true

            let analyticsSecondIntentionResponse = null

            switch (kindOfCare) {
                case SymptomCheckerKindOfCareEnum.ALLIED_HEALTH_CARE:
                case SymptomCheckerKindOfCareEnum.PRIMARY_CARE:
                case SymptomCheckerKindOfCareEnum.SPECIALIST_CARE:
                case SymptomCheckerKindOfCareEnum.URGENT_CARE:
                case SymptomCheckerKindOfCareEnum.EMERGENCY_NO_AMBULANCE:
                case SymptomCheckerKindOfCareEnum.EMERGENCY_WITH_AMBULANCE:
                    analyticsSecondIntentionResponse = 'yes'
                    break
                case SymptomCheckerKindOfCareEnum.RECOVERING_AT_HOME:
                    analyticsSecondIntentionResponse = 'no'
                    break
                case SymptomCheckerKindOfCareEnum.NOT_SURE:
                    analyticsSecondIntentionResponse = 'dont_know'
                    break
                default:
                    analyticsSecondIntentionResponse = null
                    break
            }

            sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_REPLY_INTENTION, {
                [analyticsConstants.PARAMS.REPLY_VALUE]: analyticsSecondIntentionResponse,
                [analyticsConstants.PARAMS.PARTNER_UUID]: uuid,
                [analyticsConstants.PARAMS.INSURANCE_MODEL]: productCode,
                [analyticsConstants.PARAMS.STEP]: step,
                [analyticsConstants.PARAMS.QUESTION_INTENTION]: 2
            })
            break
        }
        default:
            break
    }
    return [interStep, interStepMustBeSent]
}
