/* eslint-disable sonarjs/no-nested-template-literals */
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from 'react-router-dom'

import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

export interface NavBarLinkProps {
    id: string
    label: string
    target: string
    classNames?: string
}

const NavBarLink = ({ id, label, target, classNames }: NavBarLinkProps): JSX.Element => {
    const { t } = useTranslation()
    const { setNavBarMenuState } = useAssuraNavBarContext()

    const match = useMatch({
        path: target
    })

    const handleClose = () => {
        setNavBarMenuState(false)
    }

    return (
        <div
            className={`nav-item${classNames ? ` ${classNames}` : ''}`}
            onClick={handleClose}
            data-testid={`navigation-${id}-container`}
        >
            <Link
                className={`nav-link headlineSmall${match ? ' active' : ''}`}
                data-testid={`navigation-${id}`}
                to={target}
            >
                {t(label)}
            </Link>
        </div>
    )
}

export default NavBarLink
