import { useTranslation } from 'react-i18next'

import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface HomePersonalDataPersonSmallProps {
    firstName: string
    lastName: string
    policyNumber: number
    editAction: () => void
}

const HomePersonalDataPersonSmall = ({
    firstName,
    lastName,
    policyNumber,
    editAction
}: HomePersonalDataPersonSmallProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex">
            <AssuraAvatar
                id="home-avatar"
                avatarContainerWidth={56}
                border={{
                    width: 3,
                    color: 'white'
                }}
            >
                <div className="headlineSmallMedium">
                    {(firstName.charAt(0) + lastName.charAt(0)).toUpperCase()}
                </div>
            </AssuraAvatar>
            <div className="flex-1 d-flex flex-column justify-content-center m-left-8">
                <div
                    data-testid="home-avatar-label"
                    className="w-100 d-flex justify-content-start"
                    style={{ marginBottom: '4px' }}
                >
                    <div className="c-black headlineSmallMedium d-flex align-items-center">{`${firstName} ${lastName}`}</div>
                    <AssuraIconButton
                        id="home-edit-button"
                        icon="assura-edit"
                        variant="primary"
                        size="small"
                        onClick={editAction}
                        classNames="m-left-8"
                    />
                </div>
                <div className="labelSmall c-gray500">
                    {t('FAMILY_GROUP.POLICY_NUMBER', { policyNumber })}
                </div>
            </div>
        </div>
    )
}

export default HomePersonalDataPersonSmall
