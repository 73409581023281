import './ModelDeductiblePanel.css'

import { ProductOverviewCms, Section } from 'core/models/cms/ProductOverView'
import useWindowSize from 'core/services/useWindowSize'
import { assetNameCmsConverter, GetAssetIcon } from 'core/utils/assetUtils'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'

import ModelDeductiblePanelHeader from './ModelDeductiblePanelHeader'

type ModelDeductibleDetailCardProps = {
    overview: ProductOverviewCms
}

const ModelDeductibleDetailCard = ({ overview }: ModelDeductibleDetailCardProps): JSX.Element => {
    const sectionsEntries = Object.entries(overview)

    const sectionValues = sectionsEntries?.find((o) => o[0] === 'sections')

    const overviewSection: Section[] = sectionValues ? sectionValues[1] : []

    return (
        <>
            {overviewSection &&
                overviewSection.map((section: Section, index: number) => {
                    return (
                        <div
                            key={index}
                            className={'model-deductible-overview-detail-card m-top-40'}
                        >
                            <div className={'model-deductible-overview-head'}>
                                <div className={'model-deductible-overview-title-holder'}>
                                    <div className={'model-deductible-overview-title headline'}>
                                        {section.value.title}
                                    </div>
                                    <div className={'model-deductible-overview-iconholder'}>
                                        <img
                                            src={GetAssetIcon(
                                                section.value.iconName,
                                                assetNameCmsConverter
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'model-deductible-overview-body paragraphLight'}>
                                <AssuraMarkdown message={section.value.body} />
                            </div>
                        </div>
                    )
                })}
        </>
    )
}

type ModelDeductiblePanelProps = {
    openPanel: boolean
    currentInfo: ProductOverviewCms | undefined
    closePanel: () => void
}

const ModelDeductiblePanel = ({ ...props }: ModelDeductiblePanelProps): JSX.Element => {
    const { isMD, isXL } = useWindowSize()

    const sizeCalculation = (): number => {
        switch (true) {
            case isMD:
                return 70
            case isXL:
                return 43
            default:
                return 100
        }
    }

    return (
        <AssuraPanel
            type={'right'}
            isOpen={props.openPanel}
            size={sizeCalculation()}
            panelContainerClassName="bg-white"
            backdropClicked={props.closePanel}
        >
            <div data-testid="model-deductible-viewer-test-id" className="model-deductible-panel">
                <ModelDeductiblePanelHeader
                    label={props.currentInfo?.productName}
                    closePanel={props.closePanel}
                />
                <div className="model-deductible-overview-detail-cards">
                    {props.currentInfo && (
                        <ModelDeductibleDetailCard overview={props.currentInfo} />
                    )}
                </div>
            </div>
        </AssuraPanel>
    )
}

export default ModelDeductiblePanel
