import './WithCharacterCountAndError.css'

import React from 'react'
import { useFormContext } from 'react-hook-form'

interface withCharacterCountAndErrorProps {
    name: string
    maxLength: number
    id: string
    isInvalid: boolean
    error: string
}

//works only with RHF
const withCharacterCountAndError =
    <P,>(WrappedComponent: React.ComponentType<P>) =>
    (props: P & withCharacterCountAndErrorProps): JSX.Element => {
        const { id, isInvalid, error, name, maxLength } = props
        const { watch } = useFormContext()
        const text: string = watch(name)

        return isInvalid ? (
            <>
                <WrappedComponent {...props} />
                <div className={`with-character-count-and-error-container m-top-16`}>
                    <div
                        key={`field-error`}
                        className="labelExtraSmall c-primary"
                        data-testid={id + '-error'}
                    >
                        {error}
                    </div>
                    <div
                        className="labelExtraSmall c-gray500 with-character-count-container"
                        data-testid={`${id}-character-count`}
                    >
                        {text?.length} / {maxLength}
                    </div>
                </div>
            </>
        ) : (
            <>
                <WrappedComponent {...props} />
                <div
                    className="labelExtraSmall c-gray500 m-top-16 with-character-count-container"
                    data-testid={`${id}-character-count`}
                >
                    {text?.length} / {maxLength}
                </div>
            </>
        )
    }

export default withCharacterCountAndError
