import { useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

interface RadioRHFProps {
    id: string
    name: string
    label: string | JSX.Element
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: UseFormRegister<FieldValues | any>
    onClick?: (value: string | boolean | number | undefined) => void | undefined
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    classNames?: string
    labelClassNames?: string
}

const RadioRHF = ({
    id,
    name,
    label,
    register,
    onClick,
    rules,
    classNames,
    labelClassNames
}: RadioRHFProps): JSX.Element => {
    const labelClassNamesToApplied = labelClassNames ?? 'paragraph'
    const [focus, setFocus] = useState<boolean>(false)

    const inputElement = document.getElementById(`#${id}`)
    const inputWidth = inputElement?.getBoundingClientRect().width
    const inputHeight = inputElement?.getBoundingClientRect().height

    const focusLeftPos = (inputWidth ? inputWidth / 2 : 0) + 1
    const focusTopPos = inputHeight ? inputHeight / 2 : 0

    const handleClick = () => {
        onClick && onClick(id)
    }
    const handleOnFocus = () => {
        setFocus((_o) => (_o = true))
    }
    const handleOnLeaveFocus = () => {
        setFocus((_o) => (_o = false))
    }
    return (
        <div
            className={`custom-control custom-radio-control custom-radio${
                classNames ? ' ' + classNames : ''
            }`}
        >
            <div
                className="focus-indicator"
                style={{
                    position: 'absolute',
                    top: `${focusTopPos}px`,
                    left: `${focusLeftPos}px`,
                    borderColor: 'var(--primary)',
                    filter: focus ? 'contrast(200%)' : '',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    width: '22px',
                    height: '22px',
                    borderRadius: '100%',
                    transform: focus ? 'scale(1.2)' : 'scale(0)',
                    transition: 'all 0.1s ease-in-out'
                }}
                data-testid={`radio-rhf-focus-indicator-${id}`}
            />
            <input
                id={id}
                data-testid={`radio-${id}`}
                value={id}
                className={`custom-control-input`}
                onClick={handleClick}
                type={'radio'}
                {...register(name, {
                    ...rules
                })}
                onFocusCapture={handleOnFocus}
                onBlurCapture={handleOnLeaveFocus}
            />

            <FormCheck.Label
                className={`custom-control-label radio-button-custom  ${labelClassNamesToApplied}`}
                htmlFor={id}
            >
                {label}
            </FormCheck.Label>
        </div>
    )
}

export default RadioRHF
