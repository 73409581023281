import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { SymptomCheckerPartner, SymptomCheckerSessionInterStep } from 'core/models/SymptomChecker'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface SymptomCheckerState {
    concernedPartner: SymptomCheckerPartner | null
    partners: SymptomCheckerPartner[]
    isOnlyOneQualiMedPartner: boolean
    symptomCheckerLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_SYMPTOM_CHECKER: SymptomCheckerState = {
    concernedPartner: null,
    partners: [],
    isOnlyOneQualiMedPartner: false,
    symptomCheckerLoadingStatus: LoadingStatusEnum.LOADING
}
export const symptomCheckerSlice = createSlice({
    name: 'symptomChecker',
    initialState: INITIAL_STATE_SYMPTOM_CHECKER,
    reducers: {
        initSymptomChecker: (state) => {
            return state
        },
        triggerSymptomCheckerPopin: (state, _action: PayloadAction<boolean | undefined>) => {
            return state
        },
        initSymptomCheckerForm: (state, _action: PayloadAction<SymptomCheckerPartner>) => {
            return state
        },
        setPartners: (state, action: PayloadAction<SymptomCheckerPartner[]>) => {
            state.partners = action.payload
        },
        setConcernedPartner: (state, action: PayloadAction<SymptomCheckerPartner>) => {
            state.concernedPartner = action.payload
        },
        setIsOnlyOneQualiMedPartner: (state, action: PayloadAction<boolean>) => {
            state.isOnlyOneQualiMedPartner = action.payload
        },
        setSymptomCheckerLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.symptomCheckerLoadingStatus = action.payload
        },
        sendSymptomCheckerSessionInterStep: (
            state,
            _action: PayloadAction<SymptomCheckerSessionInterStep>
        ) => {
            return state
        }
    }
})

export const {
    initSymptomChecker,
    triggerSymptomCheckerPopin,
    initSymptomCheckerForm,
    setPartners,
    setConcernedPartner,
    setIsOnlyOneQualiMedPartner,
    setSymptomCheckerLoadingStatus,
    sendSymptomCheckerSessionInterStep
} = symptomCheckerSlice.actions

export default symptomCheckerSlice.reducer

export const getSymptomCheckerLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.symptomChecker.symptomCheckerLoadingStatus

export const getPartners: Selector<RootState, SymptomCheckerPartner[]> = (state) =>
    state.symptomChecker.partners

export const getIsOnlyOneQualiMedPartner: Selector<RootState, boolean> = (state) =>
    state.symptomChecker.isOnlyOneQualiMedPartner

export const getConcernedPartner: Selector<RootState, SymptomCheckerPartner | null> = (state) =>
    state.symptomChecker.concernedPartner

export const getDefaultConcernedPartner: Selector<RootState, SymptomCheckerPartner | null> = (
    state
) => state.symptomChecker.partners[0] || null
