/* eslint-disable sonarjs/cognitive-complexity */
import { DocumentPaymentStatusEnum, DocumentsGroupEnum } from 'core/enums/Documents'
import { Document, DocumentPaymentStatus, DocumentsGroup } from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'

import { getFinancialStatusData } from './FinancialStatusHelper'
import { mapInFavour } from './InFavourHelper'

export const mapDocumentsToRows = (
    documents: Document[],
    personNames: EnveloppePerson[]
): DocumentRow[] => {
    const documentRows: DocumentRow[] = []

    if (documents)
        documents.forEach((document: Document) => {
            const newDoc = {
                documentId: document.id,
                documentName: document.documentName ?? '',
                categoryId: document.categoryId,
                isRead: document.isRead,
                creationDate: document.creationDate,
                claimsSettlementId: document.claimsSettlementId,
                insuredPersonName:
                    personNames?.find((person) => person.policyNumber == document.policyNumber)
                        ?.personName ?? '',
                insuredPersonLastName:
                    personNames?.find((person) => person.policyNumber == document.policyNumber)
                        ?.personLastName ?? '',
                policyNumber: document.policyNumber,
                isMigrated: document.isMigrated // TODO: For migrated documents
            }

            if (document.paymentDocuments) {
                document.paymentDocuments.forEach((paymentDocument) => {
                    const { canceled, valueAmount, valueType, fullyPaid } = paymentDocument

                    const financialStatusData = getFinancialStatusData(canceled, fullyPaid)

                    documentRows.push({
                        ...newDoc,
                        bvrReference: paymentDocument.bvrReference,
                        valueAmount,
                        unpaidAmount: paymentDocument.unpaidAmount,
                        fullyPaid,
                        canceled,
                        inFavour: mapInFavour(valueType, valueAmount),
                        deadLineDate: canceled ? null : paymentDocument.deadLineDate,
                        financialStatusData
                    })
                })
            } else {
                documentRows.push({
                    ...newDoc,
                    bvrReference: null,
                    valueAmount: 0,
                    unpaidAmount: 0,
                    inFavour: 'STATEMENTS_VALUE_TYPE_NONE',
                    deadLineDate: null,
                    fullyPaid: null,
                    financialStatusData: null,
                    canceled: false
                })
            }
        })
    return documentRows
}

export const getPaymentStatusByGroup = (group: DocumentsGroup): DocumentPaymentStatus | null => {
    switch (group) {
        case DocumentsGroupEnum.INVOICES:
            return DocumentPaymentStatusEnum.DEBIT
        case DocumentsGroupEnum.REFUNDS:
            return DocumentPaymentStatusEnum.CREDIT
        default:
            return null
    }
}
