/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicate-string */
import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import useWindowSize from 'core/services/useWindowSize'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface RequestPanelSectionProps {
    label: string | undefined
    value?: string
    children?: React.ReactNode | React.ReactNode[]
    className?: string
}

const RequestPanelSection = ({
    label,
    value,
    className,
    children
}: RequestPanelSectionProps): JSX.Element => (
    <div className={`requests-panel-main-block ${className}`}>
        <LabelValueInlineBlock>
            <div className="text-right">{label}</div>
            {children ? <>{children}</> : <div className="text-right">{value}</div>}
        </LabelValueInlineBlock>
    </div>
)

interface AventuraTravelDetailsProps {
    values: RequestDetailsValues[]
}

const AventuraTravelDetails = ({ values }: AventuraTravelDetailsProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const reason = values.find((v) => v.id === 'Reason')
    const destination = values.find((v) => v.id === 'Destination')
    const beginDate = values.find((v) => v.id === 'BeginDate')
    const endDate = values.find((v) => v.id === 'EndDate')
    const partners = values.find((v) => v.id === 'OtherPartner')?.new as []

    const beginDateFormated =
        beginDate && beginDate.new ? formatDate(new Date(beginDate.new.toString())) : ''
    const endDateFormated =
        endDate && endDate.new ? formatDate(new Date(endDate.new.toString())) : ''

    const partnersCollection = partners.map((o) => {
        const dob = o['BirthDate'] as string
        const dobFormated = dob ? dob.substring(0, 10) : ''
        return {
            policyNumber: o['OtherPolice'] as string,
            name: o['OtherName'] as string,
            firstName: o['OtherFirstName'] as string,
            dateOfBirth: dobFormated
        }
    })

    return (
        <div
            className="d-flex flex-1 flex-column justify-content-start"
            style={{ minWidth: '300px' }}
        >
            <div className="p-bottom-32">
                <RequestsPanelSubtitle label={t('SERVICE.AVENTURA_MEMBERS_INFO_TITLE')} />
                <RequestPanelSection
                    label={t('SERVICE.AVENTURA_FORM_TRAVEL_REASON_LABEL')}
                    value={reason && reason?.new?.toString()}
                />
                <RequestPanelSection
                    label={t('SERVICE.AVENTURA_FORM_TRAVEL_DESTINATION_LABEL')}
                    value={destination && destination.new?.toString()}
                />
                <RequestPanelSection
                    label={t('SERVICE.AVENTURA_FORM_PERIODICITY_LABEL')}
                    value={undefined}
                >
                    <div
                        className={isMobile ? `text-left` : 'text-right'}
                        style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
                    >
                        <div className={isMobile ? '' : 'mr-1'}>
                            {`${t(
                                'SERVICE.AVENTURA_FORM_TRAVEL_START_LABEL'
                            )} ${beginDateFormated}`}
                        </div>
                        <div className={isMobile ? 'text-right' : ''}>
                            {`${t(
                                'SERVICE.AVENTURA_FORM_TRAVEL_END_LABEL'
                            )?.toLocaleLowerCase()} ${endDateFormated}`}
                        </div>
                    </div>
                </RequestPanelSection>

                <RequestPanelSection
                    label={t('SERVICE.AVENTURA_FORM_PEOPLE_COUNT_LABEL')}
                    value={partners && partners?.length.toString()}
                />
            </div>
            {partnersCollection &&
                partnersCollection.map((person: any, index: number) => {
                    return (
                        <div className="p-bottom-32" key={index}>
                            <RequestsPanelSubtitle label={`${t('COMMON.PERSON')} ${index + 1}`} />

                            <RequestPanelSection
                                label={t('SERVICE.AVENTURA_MEMBER_POLICY_NUMBER_LABEL')}
                                value={person.policyNumber}
                            />

                            <RequestPanelSection
                                label={t('SERVICE.AVENTURA_MEMBER_LAST_NAME_LABEL')}
                                value={person.name}
                            />

                            <RequestPanelSection
                                label={t('SERVICE.AVENTURA_MEMBER_FIRST_NAME_LABEL')}
                                value={person.firstName}
                            />

                            <RequestPanelSection
                                label={t('SERVICE.AVENTURA_MEMBER_BIRTH_DATE_LABEL')}
                                value={person.dateOfBirth}
                            />
                        </div>
                    )
                })}
        </div>
    )
}

export default AventuraTravelDetails
