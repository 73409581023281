import { call, fork, put, takeEvery } from 'redux-saga/effects'

import {
    productCategoriesCmsData,
    productsCmsData,
    productsOverviewCmsData,
    recommendedProductGroupsCmsData
} from 'core/api/Cms'
import { ProductCategoryCms } from 'core/models/cms/ProductCategoryCms'
import { ProductCms } from 'core/models/cms/ProductCms'
import { ProductGroupCms } from 'core/models/cms/ProductGroupCms'
import { ProductOverviewCms } from 'core/models/cms/ProductOverView'

import { fetchSummaryByYearAsync } from '../familySummaries/familySummaries.saga'
import {
    fetchProductCategoriesCms,
    fetchProductCategoriesCmsSuccess,
    fetchProductsCms,
    fetchProductsCmsSuccess,
    fetchProductsOverviewCms,
    fetchProductsOverviewCmsSuccess,
    fetchProductsYear,
    fetchRecommendedProductGroupCms,
    fetchRecommendedProductGroupCmsSuccess,
    initProductsCmsData
} from './products.slice'

function* initProductsCmsDataAsync() {
    try {
        yield put(fetchProductsCms())
        yield put(fetchProductsOverviewCms())
        yield put(fetchProductCategoriesCms())
        yield put(fetchRecommendedProductGroupCms())
    } catch (e) {
        console.error('initProductsCmsDataAsync Error', e)
    }
}

export function* fetchProductsYearAsync(action: ReturnType<typeof fetchProductsYear>) {
    try {
        yield call<typeof fetchSummaryByYearAsync>(fetchSummaryByYearAsync, {
            type: 'familySummaries/fetchSummaryByYear',
            payload: {
                year: action.payload.year,
                shouldFetchProducts: true,
                shouldThrow: true,
                reload: action.payload.reload
            }
        })
    } catch (e) {
        console.error('fetchProductsYearAsync Error', e)
    }
}

function* fetchProductsCmsAsync() {
    try {
        const productsCms: ProductCms[] = yield call(productsCmsData)
        yield put(fetchProductsCmsSuccess(productsCms))
    } catch (e) {
        console.error('fetchProductsCmsAsync Error', e)
    }
}

function* fetchProductsOverviewCmsAsync() {
    try {
        const productsOverviewCms: ProductOverviewCms[] = yield call(productsOverviewCmsData)
        yield put(fetchProductsOverviewCmsSuccess(productsOverviewCms))
    } catch (e) {
        console.error('fetchProductsOverviewCmsAsync Error', e)
    }
}

function* fetchProductCategoriesCmsAsync() {
    try {
        const productCategoriesCms: ProductCategoryCms[] = yield call(productCategoriesCmsData)
        yield put(fetchProductCategoriesCmsSuccess(productCategoriesCms))
    } catch (e) {
        console.error('fetchProductCategoriesCmsAsync Error', e)
    }
}

function* fetchRecommendedProductGroupCmsAsync() {
    try {
        const productGroupsCms: ProductGroupCms[] = yield call(recommendedProductGroupsCmsData)
        yield put(fetchRecommendedProductGroupCmsSuccess(productGroupsCms?.[0]))
    } catch (e) {
        console.error('fetchRecommendedProductGroupsCmsAsync Error', e)
    }
}

function* initProductsCmsDataWatcher() {
    yield takeEvery(initProductsCmsData.type, initProductsCmsDataAsync)
}

function* fetchProductsYearWatcher() {
    yield takeEvery(fetchProductsYear.type, fetchProductsYearAsync)
}

function* fetchProductsCmsWatcher() {
    yield takeEvery(fetchProductsCms.type, fetchProductsCmsAsync)
}

function* fetchProductsOverviewCmsWatcher() {
    yield takeEvery(fetchProductsOverviewCms.type, fetchProductsOverviewCmsAsync)
}

function* fetchProductCategoriesCmsWatcher() {
    yield takeEvery(fetchProductCategoriesCms.type, fetchProductCategoriesCmsAsync)
}

function* fetchRecommendedProductGroupCmsWatcher() {
    yield takeEvery(fetchRecommendedProductGroupCms.type, fetchRecommendedProductGroupCmsAsync)
}

const watchers = [
    fork(initProductsCmsDataWatcher),
    fork(fetchProductsYearWatcher),
    fork(fetchProductsCmsWatcher),
    fork(fetchProductsOverviewCmsWatcher),
    fork(fetchProductCategoriesCmsWatcher),
    fork(fetchRecommendedProductGroupCmsWatcher)
]

export default watchers
