import { DeepMap, FieldError, FieldValues } from 'react-hook-form'

import { AssuraDocumentType } from 'core/enums/DocumentType'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { DocumentFile } from 'core/models/DocumentFile'
import {
    InvoiceCheckingDetails,
    InvoiceCheckingStatus
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'

export const mapInvoiceDocumentFile = (
    document: InvoiceCheckingDetails | null,
    documentFiles: Record<string, DocumentFile>
): AssuraDocument | undefined => {
    return document && documentFiles[document.documentIdentifier]
        ? {
              type: AssuraDocumentType.PDF,
              fileName: document.careProviderName.includes('.pdf')
                  ? document.careProviderName
                  : `${document.careProviderName}.pdf`,
              base64: documentFiles[document.documentIdentifier].base64 ?? '',
              loadStatus: documentFiles[document.documentIdentifier].loadStatus
          }
        : undefined
}

export const InvoicesToCheckIsEquals = (
    statusArray: InvoiceCheckingStatus[],
    status: InvoiceCheckingStatus | string
) => {
    if (typeof status === 'string') {
        return statusArray.some((o) => o === status)
    }
    return statusArray.includes(status)
}

export const codeFormatter = (code: string): string => {
    return code.replace(/\.+/gm, '-')
}

export const getErrorMessage = (
    errorCollection: DeepMap<FieldValues, FieldError>,
    fieldName: string
): string => {
    if (!errorCollection || Object.entries(errorCollection).length === 0) return ''
    if (!errorCollection[fieldName]) return ''
    return errorCollection[fieldName].message
}
