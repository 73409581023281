import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'

interface AssuraDownloadLinkProps {
    testId: string
    label: string
    labelClass: string
    isLoading: boolean
    onClick: () => void
}

const AssuraDownloadLink = ({
    testId,
    label,
    labelClass,
    isLoading,
    onClick
}: AssuraDownloadLinkProps): JSX.Element => {
    return (
        <div data-testid={testId} className="icon-container clickable primary" onClick={onClick}>
            <div className={`${labelClass} m-right-8`} data-testid={`${testId}-label`}>
                {label}
            </div>
            {isLoading ? (
                <ActivityIndicator />
            ) : (
                <i className={`icon assura-download size-24`} data-testid={`${testId}-icon`} />
            )}
        </div>
    )
}

export default AssuraDownloadLink
