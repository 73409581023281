import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AutoCompleteRHF, { AutoCompleteRHFResult } from 'modules/services/components/AutoCompleteRHF/AutoCompleteRHF'
import FormBlockWithLabel from 'modules/services/components/FormBlockWithLabel/FormBlockWithLabel'
import {
    getLocalities,
    getLocalitiesLoadingStatus,
    resetLocalities,
    searchLocalities
} from 'shared/store/localities/localities.slice'

export type AddressLocalitySelection = AutoCompleteRHFResult<number>

export interface AddressLocalityProps {
    id?: string
    name?: string
    defaultValue?: string
    handleSelection?: (selection: AddressLocalitySelection) => void
}

const AddressLocality = ({
    id = 'services-personal-data-address-locality',
    name = 'localityId',
    defaultValue,
    handleSelection
}: AddressLocalityProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const localitiesResult = useSelector(getLocalities)
    const loadingStatus = useSelector(getLocalitiesLoadingStatus)

    const results: AutoCompleteRHFResult<number>[] = localitiesResult.map((locality) => {
        return {
            label: locality.name,
            value: locality.id
        }
    })

    const handleOnChange = (inputValue: string) => {
        dispatch(searchLocalities(inputValue))
    }

    const handleReset = () => {
        dispatch(resetLocalities())
    }

    return (
        <div className="m-top-32">
            <FormBlockWithLabel label={t('PERSONAL_DATA.ADDRESS_NPA_LOCALITY')} isFirstChildInput>
                <AutoCompleteRHF
                    id={id}
                    name={name}
                    results={results}
                    handleOnChange={handleOnChange}
                    rules={{
                        required: {
                            value: true,
                            message: t('COMMON.MANDATORY_FIELD')
                        },
                        minLength: {
                            value: 2,
                            message: t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
                                min: 2,
                                max: 50
                            })
                        },
                        maxLength: {
                            value: 50,
                            message: t('COMMON.ERROR_FIELD_VALIDATION_CHARACTER', {
                                min: 2,
                                max: 50
                            })
                        },
                        shouldMatch: t('PERSONAL_DATA.ADDRESS_NPA_LOCALITY_ERROR')
                    }}
                    placeHolder="1110 Morges"
                    loadingStatus={loadingStatus}
                    handleReset={handleReset}
                    defaultValue={defaultValue}
                    onResultSelection={handleSelection}
                />
            </FormBlockWithLabel>
        </div>
    )
}

export default AddressLocality
