import {
    UseValidationOptions,
    ValidationConfiguration,
    ValidationRowItem,
    ValidationValues
} from './entities'

// this object contains all the validation methods for each cases.
// the values are passed by ValidationValues which can contains one or more value
// the validate function is used to loop through the validation collection
//  - it relies on ValidationConfiguration and produces an object structured as key (string), value(ValidationRowItem)
//  - ValidationRowItem is produced by the content of ValidationConfiguration
const Validators = {
    // this function execute the validation
    validate: (configuration: ValidationConfiguration[]) => (
        values: ValidationValues
    ): Record<string, ValidationRowItem> => {
        const newOutput: Record<string, ValidationRowItem> = {}
        configuration.forEach((item) => {
            const valid = item.method(values)
            newOutput[item.name] = {
                hint: item.hint,
                error: item.error,
                valid: valid
            }
        })
        return newOutput
    },

    isMandatory: (values: ValidationValues): boolean => !!values.mainValue,

    hasMinOneLetter: (values: ValidationValues): boolean =>
        !!values.mainValue && new RegExp(/[a-zA-Z]+/).test(values.mainValue),

    hasMinOneNumber: (values: ValidationValues): boolean =>
        !!values.mainValue && new RegExp(/[0-9]+/).test(values.mainValue),

    isLengthOverflow: (options: UseValidationOptions) => (values: ValidationValues): boolean =>
        !(values.mainValue
            ? values.mainValue.length < options.min || values.mainValue.length > options.max
            : true),

    // Define validators
    // Validate login
    isLoginValid: (values: ValidationValues): boolean => {
        // according https://www.rfc-editor.org/rfc/rfc2822#section-3.4.1 e-mail should have at least
        // local part @ domain
        return new RegExp(/^[\w@.\-_]+$/).test(values.mainValue)
    },

    MustBeEquals: (values: ValidationValues): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return AreEquals(values.mainValue, values.compareValue!)
    }
}

const AreEquals = (passwordA: string, passwordB: string): boolean =>
    !!passwordA && !!passwordB && JSON.stringify(passwordA) === JSON.stringify(passwordB)

export default Validators
