import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import {
    fetchPolicyDocument,
    getPolicyDocumentLoadingStatus
} from 'shared/store/documents/documents.slice'
import { useAppDispatch } from 'shared/store/store'

import DocumentsCardLink from './DocumentsCardLink'

interface DocumentsCardLinkPolicyProps {
    documentId: string
}

const DocumentsCardLinkPolicy = ({ documentId }: DocumentsCardLinkPolicyProps): JSX.Element => {
    const dispatch = useAppDispatch()

    const loadingStatus = useSelector(getPolicyDocumentLoadingStatus)
    const isLoading = loadingStatus === LoadingStatusEnum.LOADING

    const handleLastPolicy = () => {
        dispatch(fetchPolicyDocument(documentId))
    }

    return (
        <DocumentsCardLink
            labelKey="INSURANCES.DOCUMENTS_CARD_LINK_POLICY"
            testId="policy"
            onClick={handleLastPolicy}
            isLoading={isLoading}
        />
    )
}

export default DocumentsCardLinkPolicy
