/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LANGUAGES } from '../constants/lang'

//Source https://stackoverflow.com/a/33037683/9891500
export const flatten = (obj: any): Record<string, string> => {
    return Object.assign(
        {},
        ...(function _flatten(o): any {
            return [].concat(
                ...Object.keys(o).map((k) =>
                    typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] }
                )
            )
        })(obj)
    )
}

export const getLocalizedObject = (object: any, lang: string): any => {
    const filteredObject = { ...object }
    if (lang) {
        const languageUnderscoredArray = LANGUAGES.map((shortLocale) => `_${shortLocale}`)
        Object.keys(filteredObject).forEach((key) => {
            //Check if a key has this format XXXXXX_fr, XXXXXX_it etc.
            if (languageUnderscoredArray.includes(key.slice(-3))) {
                //Check if the end of the key match with the lang currently used in EC
                if (key.slice(-2) === lang) {
                    //Replace the value of the Key XXXXXX by the value of the key XXXXXX_it if the lang currently used is it and delete XXXXX_it
                    filteredObject[key.slice(0, key.length - 3)] = filteredObject[key]
                    delete filteredObject[key]
                } else {
                    delete filteredObject[key]
                }
            }
        })
    }
    //At the end, we should have an object with only XXXXX keys with values corresponding to the lang currently used
    return filteredObject
}

export const isStringParsableInJSON = (data: string): boolean => {
    try {
        JSON.parse(data)
    } catch (e) {
        return false
    }
    return true
}
