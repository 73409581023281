import { ProductTypeCmsEnum } from 'core/enums/Product'

import { createSelector } from '@reduxjs/toolkit'

import { getProductsCms } from '../products/products.slice'

export const getLamalWithDoctorCodes = createSelector(
    [getProductsCms],
    (productsCms) => {
        return productsCms.reduce<string[]>((acc, productCms) => {
            if (productCms.type === ProductTypeCmsEnum.LAMAL && productCms.family_doctor) {
                acc.push(productCms.insurance_id)
            }
            return acc
        }, [])
    }
)
