import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { prepareLcasData } from 'core/helpers/PrimesPanelHelper'
import { Product } from 'core/models/Product'
import { getCurrentYear } from 'core/utils/dateUtils'
import { formatNumber } from 'core/utils/TextUtils'

interface PrimesPanelLcasProps {
    selectedYear: number
    lcas: Product[]
}
const PrimesPanelLcas = ({ selectedYear, lcas }: PrimesPanelLcasProps): JSX.Element => {
    const { t } = useTranslation()

    const [totalLcasGross, setTotalLcasGross] = useState(0)
    const [lcasFormatted, setLcasFormatted] = useState<Record<string, Product[]>>({})
    const primesLcaFuturePriceDisclaimerKey = 'PRIMES.LCAS_FUTURE_PRICE_DISCLAIMER'
    const shouldDisplayLcasFutureDisclaimer =
        selectedYear === getCurrentYear() + 1 &&
        t(primesLcaFuturePriceDisclaimerKey)?.length > 0 &&
        t(primesLcaFuturePriceDisclaimerKey) !== primesLcaFuturePriceDisclaimerKey

    useEffect(() => {
        const total = lcas.reduce<number>((acc, item) => {
            return acc + (item.premium?.gross || 0)
        }, 0)
        setTotalLcasGross(total)
        setLcasFormatted(prepareLcasData(lcas))
    }, [lcas])

    return (
        <div className="d-flex flex-column">
            <div className="d-flex">
                <div className="d-flex flex-1 headlineSmallMedium">
                    <div>{t('COVERAGE.LCA_TITLE')}</div>
                    {shouldDisplayLcasFutureDisclaimer && <div className="c-primary">&nbsp;*</div>}
                </div>

                {Boolean(totalLcasGross) && (
                    <div
                        className="headlineSmallMedium m-left-16"
                        data-testid="primes-panel-lcas-total-gross"
                    >
                        {formatNumber(totalLcasGross)}
                    </div>
                )}
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                    {Object.keys(lcasFormatted).map((lcasFormattedKey, index) => (
                        <div
                            className="p-top-16 p-bottom-12 border-bottom-solid-width-1 bc-gray100"
                            key={lcasFormattedKey}
                        >
                            <div
                                className="paragraphSmallMedium"
                                data-testid={`primes-panel-lcas-category-${index}`}
                            >
                                {lcasFormattedKey}
                            </div>
                            {lcasFormatted[lcasFormattedKey].map((lcaFormatted) => (
                                <div className="d-flex" key={lcaFormatted.id}>
                                    <div
                                        className="paragraphSmallLight flex-1"
                                        data-testid={`primes-panel-lcas-${lcaFormatted.id}-title`}
                                    >
                                        {lcaFormatted.title}
                                    </div>
                                    {Boolean(lcaFormatted.premium?.gross) && (
                                        <div
                                            className="paragraphSmallMedium m-left-16 c-gray500"
                                            data-testid={`primes-panel-lcas-${lcaFormatted.id}-gross`}
                                        >
                                            {formatNumber(lcaFormatted.premium?.gross as number)}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {shouldDisplayLcasFutureDisclaimer && (
                    <div className="d-flex paragraphExtraSmall m-top-16">
                        <div className="c-primary">*&nbsp;</div>
                        <div data-testid="primes-panel-lcas-disclaimer">
                            {t('PRIMES.LCAS_FUTURE_PRICE_DISCLAIMER')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PrimesPanelLcas
