import './PopinManager.css'

import { useSelector } from 'react-redux'

import { PopinItemEnum } from 'core/enums/Popin'
import concatClassNames from 'core/utils/classNameUtils'
import CguPopin from 'shared/components/PopinManager/CguPopin/CguPopin'
import EcPlusPopin from 'shared/components/PopinManager/EcPlusPopin/EcPlusPopin'
import { getSelectedPopinItem } from 'shared/store/popin/popin.slice'

import AssuraQualiMedPopin from './AssuraQualiMedPopin/AssuraQualiMedPopin'
import BackToScanDeletePopin from './BackToScanDeletePopin/BackToScanDeletePopin'
import SymptomCheckerPopin from './SymptomCheckerPopin/SymptomCheckerPopin'
import WhatsNewPopin from './WhatsNewPopin/WhatsNewPopin'

const PopinManager = (): JSX.Element | null => {
    const selectedPopinItem = useSelector(getSelectedPopinItem)

    const getSelectedComponent = (): JSX.Element | null => {
        switch (selectedPopinItem) {
            case PopinItemEnum.CGU:
                return <CguPopin />
            case PopinItemEnum.QUALIMED:
                return <AssuraQualiMedPopin />
            case PopinItemEnum.ECPLUS:
                return <EcPlusPopin />
            case PopinItemEnum.WHATSNEW:
                return <WhatsNewPopin />
            case PopinItemEnum.ENVELOPPES_DELETE:
                return <BackToScanDeletePopin />
            case PopinItemEnum.SYMPTOM_CHECKER:
                return <SymptomCheckerPopin />
            default:
                return null
        }
    }

    const downZIndex =
        selectedPopinItem === PopinItemEnum.SYMPTOM_CHECKER ? 'down-z-index' : undefined

    const popinContainerClasses = concatClassNames(['pop-in-back-container', downZIndex])

    return selectedPopinItem ? (
        <div className={popinContainerClasses}>{getSelectedComponent()}</div>
    ) : null
}
export default PopinManager
