import { useTranslation } from 'react-i18next'

const SearchBarNoResult = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="p-16 m-top-16 m-bottom-16 paragraphSmall c-gray500"
            data-testid="faq-search-bar-no-result"
        >
            {t('SERVICES.AUTO_COMPLETE_NO_RESULT')}
        </div>
    )
}

export default SearchBarNoResult
