import { ToastType } from 'core/enums/ToastType'
import { ToastCharacteristics } from 'core/models/ToastCharacteristics'

export const getToastDisplayByType = (type?: ToastType): ToastCharacteristics => {
    const toastCharacteristics: ToastCharacteristics = {
        icon: '',
        iconColor: '',
        backgroundColor: ''
    }

    switch (type) {
        case ToastType.SUCCESS:
            toastCharacteristics.icon = 'assura-check-small'
            toastCharacteristics.iconColor = 'c-success'
            toastCharacteristics.backgroundColor = 'bg-success'
            break
        case ToastType.ERROR:
            toastCharacteristics.icon = 'assura-warning'
            toastCharacteristics.iconColor = 'c-error'
            toastCharacteristics.backgroundColor = 'bg-alert'
            break
        case ToastType.INFORMATION:
        default:
            toastCharacteristics.icon = 'assura-info'
            toastCharacteristics.iconColor = 'c-black'
            toastCharacteristics.backgroundColor = 'bg-gray100'
            break
    }
    return toastCharacteristics
}
