import * as pdfjsLib from 'pdfjs-dist'

export enum PdfAnalyzerError {
    PasswordException = 'PasswordException',
    InvalidPDFException = 'InvalidPDFException'
}

export interface PdfAnalyzerReport {
    isValid: boolean
    pagesCount: number
    error?: PdfAnalyzerError
}

export function convertDataURIToBinary(pdf: string) {
    const BASE64_MARKER = ';base64,'

    const base64Index = pdf.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    const base64 = pdf.substring(base64Index)
    const raw = window.atob(base64)
    const rawLength = raw.length
    const array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
    }
    return array
}

const pdfAnalyzer = async (pdf: string): Promise<PdfAnalyzerReport> => {
    const report: PdfAnalyzerReport = {
        isValid: true,
        pagesCount: 0
    }
    try {
        const pdfAsArray = convertDataURIToBinary(pdf)
        const loadingTask = pdfjsLib.getDocument(pdfAsArray)
        const pdfLoad = await loadingTask.promise

        report.pagesCount = pdfLoad.numPages

        return report
    } catch (e) {
        return {
            ...report,
            isValid: false,
            error: (e as Error).name as PdfAnalyzerError
        }
    }
}

export default pdfAnalyzer
