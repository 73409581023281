import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { EcLinkCms } from 'core/models/cms/EcLinkCms'
import { EcLinksState } from 'core/models/EcLink'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_EC_LINKS: EcLinksState = {
    ecLinksLoadingStatus: LoadingStatusEnum.LOADING,
    ecLinks: []
}

export const ecLinksSlice = createSlice({
    name: 'ecLinks',
    initialState: INITIAL_STATE_EC_LINKS,
    reducers: {
        fetchEcLinks: (state) => {
            return state
        },
        setEcLinks: (state, action: PayloadAction<EcLinkCms[]>) => {
            state.ecLinks = action.payload
        },
        setEcLinksLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.ecLinksLoadingStatus = action.payload
        }
    }
})

export const { fetchEcLinks, setEcLinks, setEcLinksLoadingStatus } = ecLinksSlice.actions

export const getEcLinks: Selector<RootState, EcLinkCms[]> = (state) => state.ecLinks.ecLinks

export const getEcLinksLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.ecLinks.ecLinksLoadingStatus

export default ecLinksSlice.reducer
