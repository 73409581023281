import './LandingPageUsefulInfo.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { extractDoctorPhone } from 'core/helpers/DoctorsPhoneHelper'
import { phoneFormatted } from 'core/utils/phoneUtils'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import { SpecificationLine } from 'modules/insurances/components/LamalSpecificationCard/LamalSpecificationsCard'
import doctorIcon from 'shared/assets/images/doctor.png'
import expert from 'shared/assets/images/expert.png'
import telemedicine from 'shared/assets/images/telemedicine.png'
import { getProducts, getSummaryByPage } from 'shared/store/combineSelectors'
import { getConcernedPartner } from 'shared/store/symptomChecker/symptomChecker.slice'

const LandingPageUsefulInfo = (): JSX.Element => {
    const { t } = useTranslation()

    const qualiMedCode = 'PAM'

    const products = useSelector(getProducts)
    const qualiMedProduct = Object.values(products).find(
        (product) => product.insuranceId === qualiMedCode
    )
    const choiceOfDoctor = qualiMedProduct?.choiceOfDoctor || ''

    const concernedPartner = useSelector(getConcernedPartner)
    const familySummary = useSelector(getSummaryByPage)

    const currentMember = familySummary.insuredPersons.find(
        (person) => person.policyNumber === concernedPartner?.policyNumber
    )

    const doctor = currentMember?.productsPerson?.doctor
    const phoneDoctor = extractDoctorPhone(doctor?.phones)

    return (
        <div className="symptom-checker-useful-info-container">
            <SpecificationLine
                id="symptom-checker-landing-page-doctor"
                code={qualiMedCode}
                illustration={doctorIcon}
                isNotDisplayingHorizontalSeparator
                informationsContainer={
                    doctor ? (
                        <div>
                            <span className="label">
                                {doctor?.firstName} {doctor.lastName}
                            </span>
                            <div
                                id="lamal-specification-doctor-line"
                                className="labelSmall d-flex flex-column c-gray500"
                            >
                                <span>{stringUpperCaseFirstCharacter(doctor.street)}</span>
                                <span>
                                    {doctor.zipCode} {doctor.locality}
                                </span>
                                {phoneDoctor && <span>{phoneFormatted(phoneDoctor)}</span>}
                            </div>
                        </div>
                    ) : (
                        <span className="labelSmall">
                            {choiceOfDoctor && stringUpperCaseFirstCharacter(choiceOfDoctor)}
                        </span>
                    )
                }
            />
            <SpecificationLine
                id="symptom-checker-landing-page-medgate"
                code={qualiMedCode}
                illustration={telemedicine}
                isNotDisplayingHorizontalSeparator
                informationsContainer={
                    <div>
                        <span className="label">{t('COVERAGE.QUALIMED_TITLE')}</span>
                        <div className="labelSmall d-flex flex-column c-gray500">
                            {t('COVERAGE.QUALIMED_PHONE_NUMBER')}
                        </div>
                    </div>
                }
            />
            <SpecificationLine
                id="symptom-checker-landing-page-betterDoc"
                code={qualiMedCode}
                illustration={expert}
                isNotDisplayingHorizontalSeparator
                informationsContainer={
                    <div>
                        <span className="label">BetterDoc</span>
                        <div className="labelSmall d-flex flex-column c-gray500">
                            {t('COMMON.BETTERDOC_PHONE')}
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default LandingPageUsefulInfo
