import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedPolicyMemberByPage } from './getSelectedPolicyMemberByPage'
import { getSummaryByPage } from './getSummaryByPage'

export const getDiscountsLoaderStatus = createSelector([getSummaryByPage, getSelectedPolicyMemberByPage], (summary, selectedPolicyNumber) => {
    const person = summary?.insuredPersons?.find(
        (person) => person.policyNumber === selectedPolicyNumber
    )
    if (person) {
        return person.discountsPerson?.loadingStatus ?? LoadingStatusEnum.LOADING
    } else {
        return LoadingStatusEnum.LOADING
    }
})
