import { createContext, ReactNode, useContext, useState } from 'react'

import { emptyFunction } from 'core/helpers/functionHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import CguPanelHeader from 'shared/components/CguPanel/CguPanelHeader'
import CguPanelView from 'shared/components/CguPanel/CguPanelView'

interface CguPanelProviderProps {
    children: ReactNode
}

interface CguPanelContext {
    openCguPanel: (id?: string, headerPanelTitle?:string) => void
    closeCguPanel: () => void
    setIdCgu?: (id: string) => void
    idCgu?: string
    setHeaderPanelTitle?: (headerTitle: string) => void
    headerPanelTitle?: string
}

const CguPanelContext = createContext<CguPanelContext>({
    openCguPanel: emptyFunction,
    closeCguPanel: emptyFunction,
    setIdCgu: emptyFunction,
    idCgu: undefined,
    setHeaderPanelTitle: emptyFunction,
    headerPanelTitle: undefined
})

const CguPanelProvider = ({ children }: CguPanelProviderProps): JSX.Element => {
    const windowSize = useWindowSize()

    const [isCguPanelOpen, setIsCguPanelOpen] = useState(false)
    const [idCgu, setIdCgu] = useState<string | undefined>(undefined)
    const [headerPanelTitle, setHeaderPanelTitle] = useState<string | undefined>(undefined)

    const openCguPanel = (id?: string, headerPanelTitle?:string) => {
        setIsCguPanelOpen(true)
        if (id) {
            setIdCgu(id)
        }
        if(headerPanelTitle){
            setHeaderPanelTitle(headerPanelTitle)
        }
    }

    const closeCguPanel = () => {
        setIsCguPanelOpen(false)
        setIdCgu(undefined)
        setHeaderPanelTitle(undefined)
    }

    const value = {
        openCguPanel,
        closeCguPanel,
        setIdCgu,
        idCgu,
        headerPanelTitle,
        setHeaderPanelTitle

    }

    return (
        <CguPanelContext.Provider value={value}>
            {children}
            <AssuraPanel
                type={'right'}
                isOpen={isCguPanelOpen}
                size={windowSize.isXL ? 50 : 100}
                panelContainerClassName="bg-white"
                backdropClicked={closeCguPanel}
            >
                <>
                    <CguPanelHeader headerPanelTitle={headerPanelTitle}/>
                    <CguPanelView ecLinkId={idCgu} />
                </>
            </AssuraPanel>
        </CguPanelContext.Provider>
    )
}

const useCguPanelContext = (): CguPanelContext => {
    return useContext(CguPanelContext)
}

export { CguPanelProvider, useCguPanelContext }
