import './InvoicesToCheckPanel.css'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { DocumentsGroupEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { mapInvoiceDocumentFile } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'
import { leftPanelWidth, PanelDatas, usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import { fetchDocumentFile, getDocumentFiles } from 'shared/store/documents/documents.slice'
import { setInvoicesToCheckDetails } from 'shared/store/invoicesToCheck/invoicesToCheck.slice'
import { getMappedInvoicesToCheckDetails } from 'shared/store/selectors/getMappedInvoicesToCheckDetails'
import { useAppDispatch } from 'shared/store/store'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import InvoicesToCheckPanelFormContainer from '../InvoicesToCheckPanelForm/InvoicesToCheckPanelFormContainer'
import InvoicesToCheckPanelHeader from '../InvoicesToCheckPanelHeader/InvoicesToCheckPanelHeader'
import InvoicesToCheckPanelSubHeader from '../InvoicesToCheckPanelSubHeader/InvoicesToCheckPanelSubHeader'
import RenderPdfError from './InvoicesToCheckRender'

const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

const InvoicesToCheckPanel = (): JSX.Element => {
    const { isXL } = useWindowSize()
    const dispatch = useAppDispatch()

    const { panelDatas, update } = usePanelDatasContext()

    const [shouldDisplayPdf] = useState(true)
    const [isDisplay, setIsDisplay] = useState(false)

    const selectedInvoice = useSelector(getMappedInvoicesToCheckDetails)

    const documents = useSelector(getDocumentFiles)

    let mappedDocumentFile: AssuraDocument | undefined
    if (selectedInvoice) mappedDocumentFile = mapInvoiceDocumentFile(selectedInvoice, documents)

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => mappedDocumentFile?.fileName ?? ''
        }
    })

    const closePanel = () => {
        dispatch(setInvoicesToCheckDetails(null))
    }

    const [panelDataState, setPanelDataState] = useState<PanelDatas>({
        ...panelDatas,
        hasPDFPanel: true
    })

    const handleCloseDetails = () => {
        update({
            hasLeftPanel: true,
            hasPDFPanel: false
        })
    }

    const handleDetailsVisibility = (isVisible: boolean) => {
        if (isXL) {
            update({
                hasLeftPanel: true,
                hasPDFPanel: isVisible
            })
        } else {
            update({
                hasLeftPanel: !isVisible,
                hasPDFPanel: isVisible
            })
        }
    }

    useEffect(() => {
        setIsDisplay(selectedInvoice !== null)
    }, [selectedInvoice])

    useEffect(() => {
        if (isDisplay && selectedInvoice && mappedDocumentFile === undefined) {
            dispatch(
                fetchDocumentFile({
                    documentId: selectedInvoice.documentIdentifier,
                    documentsGroup: DocumentsGroupEnum.INVOICES,
                    isRead: false
                })
            )
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        } else {
            update({
                hasLeftPanel: true,
                hasPDFPanel: true
            })
        }
    }, [isDisplay])

    useEffect(() => {
        if (isDisplay) {
            update({
                hasLeftPanel: true,
                hasPDFPanel: shouldDisplayPdf
            })
        }
    }, [isXL, isDisplay])

    useEffect(() => {
        setPanelDataState(panelDatas)
    }, [panelDatas])

    return (
        <AssuraPanel
            type={'right'}
            isOpen={isDisplay}
            size={panelDataState.panelWidth}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            <>
                {isDisplay && selectedInvoice !== null && (
                    <div className="itc-panel-container">
                        <InvoicesToCheckPanelHeader
                            selectedInvoice={selectedInvoice}
                            closePanel={closePanel}
                            toolbarPluginInstance={toolbarPluginInstance}
                            statusIsOk={mappedDocumentFile?.loadStatus === LoadingStatusEnum.OK}
                            doc={mappedDocumentFile}
                        />
                        <div className="itc-panel-sub-container">
                            {panelDataState.hasLeftPanel && (
                                <div
                                    className="itc-form-container"
                                    style={{ flex: `0 0 ${isXL ? leftPanelWidth + 'px' : '100%'}` }}
                                >
                                    <InvoicesToCheckPanelFormContainer
                                        selectedDocument={selectedInvoice}
                                        handleDetailsVisibility={handleDetailsVisibility}
                                    />
                                </div>
                            )}
                            {panelDataState.hasPDFPanel && (
                                <AssuraLoadAndError
                                    status={
                                        mappedDocumentFile?.loadStatus ?? LoadingStatusEnum.ERROR
                                    }
                                    ErrorComponent={
                                        <RenderPdfError selectedInvoice={selectedInvoice} />
                                    }
                                >
                                    <div className="itc-panel-pdf-container">
                                        <InvoicesToCheckPanelSubHeader
                                            handleClose={handleCloseDetails}
                                        />
                                        <AssuraPdfViewer
                                            toolbarPluginInstance={toolbarPluginInstance}
                                            doc={mappedDocumentFile}
                                            renderPdfError={() => (
                                                <RenderPdfError selectedInvoice={selectedInvoice} />
                                            )}
                                            renderPdfLoader={renderPdfLoader}
                                        />
                                    </div>
                                </AssuraLoadAndError>
                            )}
                        </div>
                    </div>
                )}
            </>
        </AssuraPanel>
    )
}

export default InvoicesToCheckPanel
