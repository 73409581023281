/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { InputGroup } from 'react-bootstrap'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IconButtonVariants } from 'core/enums/IconButtonVariants'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraIsPending from 'shared/components/AssuraIsPending/AssuraIsPending'

interface IconButtonProps {
    id: string
    hasButton: boolean
    onButtonClick: () => void
    icon: string
    buttonLabel: string
    variant: IconButtonVariants
    isPending: boolean
}

export const withIconButton = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithIconButton extends React.Component<P & IconButtonProps> {
        handleClick(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
            e.preventDefault()
            this.props.onButtonClick()
        }
        render() {
            const { id, hasButton, icon, buttonLabel, variant, isPending } = this.props
            return (
                <InputGroup>
                    <WrappedComponent {...(this.props as P)} />
                    {hasButton && (
                        <InputGroup.Append className="assura-input-group-append">
                            {!isPending ? (
                                <div
                                    data-testid={id + '-button-container'}
                                    onMouseDown={(e) => this.handleClick(e)}
                                    onTouchStart={(e) => this.handleClick(e)}
                                >
                                    <AssuraIconButton
                                        id={`${id}-button`}
                                        icon={icon}
                                        variant={variant}
                                        size="medium"
                                        onClick={() => null}
                                        classNames="m-left-8"
                                        label={buttonLabel}
                                    />
                                </div>
                            ) : (
                                <AssuraIsPending testId={id} />
                            )}
                        </InputGroup.Append>
                    )}
                </InputGroup>
            )
        }
    }
