import './MobileStoreBadge.css'

import { useSelector } from 'react-redux'

import { MobileStore } from 'core/models/mobileStore'
import concatClassNames from 'core/utils/classNameUtils'
import { getLang } from 'shared/store/general/general.slice'
import { getAssets } from 'shared/store/selectors/getAssets'

import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'
import { getLocalizedBadges } from './mobileLocalizedBadges'

interface MobileStoreBadgeProps {
    store: MobileStore
    ecLinkId: string
    fallbackImage: string | null
    testId: string
    width: number
    height: number
    classNames?: string
}

const MobileStoreBadge = ({
    store,
    ecLinkId,
    fallbackImage,
    testId,
    width,
    height,
    classNames
}: MobileStoreBadgeProps): JSX.Element => {
    const lang = useSelector(getLang)
    const assets = useSelector(getAssets)

    const badgeImage = getLocalizedBadges(assets, lang)

    const classes = concatClassNames([
        'd-flex align-items-stretch justify-content-center',
        classNames
    ])
    return (
        <div className={classes} style={{ width: `${width}px`, height: `${height}px` }}>
            <AssuraEcLink
                ecLinkId={ecLinkId}
                testId={testId}
                classNames="d-flex align-items-center justify-content-center flex-1"
                as="div"
            >
                <div
                    className="mobile-store-badge d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${badgeImage[store] || fallbackImage} )` }}
                />
            </AssuraEcLink>
        </div>
    )
}

export default MobileStoreBadge
