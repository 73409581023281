import './EnveloppesPanel.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AssuraDocumentType } from 'core/enums/DocumentType'
import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { mapEnveloppeDocuments } from 'core/helpers/enveloppes/DocumentHelper'
import { getRescanErrorMessage } from 'core/helpers/enveloppes/RescanErrorHelper'
import { EnveloppeError } from 'core/models/enveloppes/Enveloppe'
import useWindowSize from 'core/services/useWindowSize'
import AssuraBanner from 'shared/components/AssuraBanner/AssuraBanner'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { getEnveloppeImagesState } from 'shared/store/enveloppeImages/enveloppeImages.slice'
import {
    setDeleteEnveloppeStatus,
    setSelectedEnveloppeId
} from 'shared/store/enveloppes/enveloppes.slice'
import { getSelectedEnveloppe } from 'shared/store/selectors/getSelectedEnveloppe'
import { RootState, useAppDispatch } from 'shared/store/store'

import EnveloppesImageViewer from '../EnveloppesImageViewer/EnveloppesImageViewer'
import EnveloppesPanelFooter from '../EnveloppesPanelFooter/EnveloppesPanelFooter'
import EnveloppesPdfViewer from '../EnveloppesPdfViewer/EnveloppesPdfViewer'

const EnveloppesPanel = (): JSX.Element => {
    const { t } = useTranslation()
    const windowSize = useWindowSize()

    const [rescanBannerMessage, setRescanBannerMessage] = useState<string | undefined>('undefined')
    const [isDeleteEnveloppeErrorBannerVisible, setIsDeleteEnveloppeErrorBannerVisible] = useState(
        false
    )

    const [enveloppeError, setEnveloppeError] = useState<EnveloppeError | undefined>(undefined)

    const deleteEnveloppeStatus = useSelector(
        (state: RootState) => state.enveloppes.deleteEnveloppeStatus
    )
    const dispatch = useAppDispatch()
    const enveloppeSelected = useSelector(getSelectedEnveloppe)

    const documents = useSelector(getEnveloppeImagesState)
    const mappedDocuments = mapEnveloppeDocuments(enveloppeSelected?.documents, documents)

    const isPdf = mappedDocuments[0]?.type === AssuraDocumentType.PDF
    const isImages = mappedDocuments[0]?.type === AssuraDocumentType.JPG

    const closePanel = () => {
        dispatch(setSelectedEnveloppeId(''))
        dispatch(setDeleteEnveloppeStatus(LoadingStatusEnum.OK))
    }

    useEffect(() => {
        if (
            enveloppeSelected?.status === EnveloppeStatus.TechnicalError ||
            enveloppeSelected?.status === EnveloppeStatus.VisibilityError
        ) {
            setEnveloppeError(enveloppeSelected?.status)
        } else {
            setEnveloppeError(undefined)
        }
    }, [enveloppeSelected])

    useEffect(() => {
        if (enveloppeError && enveloppeSelected) {
            const message = getRescanErrorMessage(
                enveloppeError,
                enveloppeSelected.visibilityErrorReason,
                enveloppeSelected.documents,
                isImages
            )
            setRescanBannerMessage(message)
        } else {
            setRescanBannerMessage(undefined)
        }
    }, [enveloppeError])

    useEffect(() => {
        setIsDeleteEnveloppeErrorBannerVisible(deleteEnveloppeStatus === LoadingStatusEnum.ERROR)
    }, [deleteEnveloppeStatus])

    return (
        <AssuraPanel
            type="right"
            isOpen={enveloppeSelected !== undefined}
            size={windowSize.isXL ? 75 : 100}
            panelContainerClassName="bg-white"
            backdropClicked={closePanel}
        >
            {enveloppeSelected ? (
                <div id="enveloppes-panel-container" className="enveloppes-panel-container">
                    {(rescanBannerMessage || isDeleteEnveloppeErrorBannerVisible) && (
                        <div
                            className="enveloppe-panel-banners-container"
                            style={{ top: isPdf ? '152px' : '96px' }}
                        >
                            {rescanBannerMessage && (
                                <AssuraBanner
                                    id="enveloppe-error"
                                    message={rescanBannerMessage}
                                    onClose={() => setRescanBannerMessage(undefined)}
                                    variant="alert"
                                />
                            )}
                            {isDeleteEnveloppeErrorBannerVisible && (
                                <AssuraBanner
                                    id="enveloppe-delete-error"
                                    message={t('COMMON.ERROR')}
                                    onClose={() => setIsDeleteEnveloppeErrorBannerVisible(false)}
                                    variant="alert"
                                />
                            )}
                        </div>
                    )}
                    {isPdf && (
                        <EnveloppesPdfViewer
                            documents={mappedDocuments}
                            enveloppeSelected={enveloppeSelected}
                            closePanel={closePanel}
                        />
                    )}
                    {isImages && (
                        <EnveloppesImageViewer
                            images={mappedDocuments}
                            enveloppeSelected={enveloppeSelected}
                            closePanel={closePanel}
                        />
                    )}
                    {enveloppeError && (
                        <EnveloppesPanelFooter
                            policyNumber={enveloppeSelected.policyNumber ?? 0}
                            personName={enveloppeSelected.insuredPersonName ?? ''}
                            batchNumber={enveloppeSelected?.batchNumber ?? ''}
                            isDeleting={deleteEnveloppeStatus === LoadingStatusEnum.LOADING}
                            isImages={isImages}
                            enveloppeName={enveloppeSelected?.documentName || ''}
                            documents={mappedDocuments}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}
        </AssuraPanel>
    )
}

export default EnveloppesPanel
