import './PersonalDataBlock.css'

import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceTopicFront } from 'core/enums/ServiceTopic'
import { ServiceType } from 'core/enums/ServiceType'
import { translateGender } from 'core/helpers/FamilyGroupHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getSummaryCurrentYear } from 'shared/store/selectors/getSummaryCurrentYear'
import {
    getSelectedMemberPolicyNumber,
    goToServicesForm
} from 'shared/store/services/services.slice'

import BasicInfo from '../BasicInfo/BasicInfo'
import Email from '../Email/Email'
import Phone from '../Phone/Phone'
import Photo from '../Photo/Photo'

const PersonalDataBlock = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const dispatch = useDispatch()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)

    const familyGroup = useSelector(getSummaryCurrentYear).insuredPersons
    const selectedFullPersonalData = useSelector(getFullPersonalData)

    if (selectedPolicyNumber && selectedFullPersonalData) {
        const { policyNumber, basic, phone, email } = selectedFullPersonalData

        return (
            <Container className="position-relative">
                <Row className="m-bottom-80">
                    <Col
                        xl={7}
                        md={{ span: 7, order: 1 }}
                        xs={{ span: 12, order: 2 }}
                        className="personal-data-block"
                    >
                        <BasicInfo basicDatas={basic} />
                        <div className="personal-data-block-separator btc-gray100" />
                        <Phone phone={phone} />
                        <Email email={email} />
                    </Col>
                    <Col
                        xl={{ span: 4, offset: 1 }}
                        md={{ span: 5, order: 2 }}
                        xs={{ span: 12, order: 1 }}
                        className="personal-data-block"
                    >
                        <Photo
                            initials={(
                                basic.firstName.charAt(0) + basic.lastName.charAt(0)
                            ).toUpperCase()}
                            dateOfBirth={basic.dateOfBirth}
                            gender={
                                basic.gender !== 'undetermined'
                                    ? translateGender(basic.gender)
                                    : null
                            }
                            policyNumber={`${policyNumber}`}
                        />
                        {Source() !== ServicesSource.WEBSITE && familyGroup.length > 1 && (
                            <div
                                className={`d-flex align-items-center justify-content-center m-top-${
                                    isXL ? 48 : 32
                                }`}
                            >
                                <AssuraButton
                                    text={t('PERSONAL_DATA.QUIT')}
                                    id="personal-data-photo-quit-button"
                                    variant="secondary"
                                    onClick={() =>
                                        dispatch(
                                            goToServicesForm({
                                                type: ServiceType.GENERAL_QUESTION,
                                                query: {
                                                    key: 'topic',
                                                    value: ServiceTopicFront.PERSONAL_DATA
                                                }
                                            })
                                        )
                                    }
                                    icon={{
                                        name: 'assura-next',
                                        size: 24
                                    }}
                                    fullWidth
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return null
    }
}

export default PersonalDataBlock
