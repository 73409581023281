import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'

import { ErrorMessage } from '@hookform/error-message'

interface ConsentCheckBoxRHFProps {
    name: string
}

const ConsentCheckBoxRHF = ({ name }: ConsentCheckBoxRHFProps): JSX.Element => {
    const { t } = useTranslation()

    const { register } = useFormContext()

    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <CheckboxRHF
                id="services-form-consent"
                name={name}
                label={t('PERSONAL_DATA.ADDRESS_VALIDATION')}
                register={register}
                labelClassNames="paragraphSmallLight"
                classNames="flex-1"
                rules={{
                    required: {
                        value: true,
                        message: 'COMMON.MANDATORY_FIELD'
                    }
                }}
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <div
                        className="labelExtraSmall c-primary m-top-8"
                        data-testid="services-form-consent-error"
                    >
                        {t(message)}
                    </div>
                )}
            />
        </div>
    )
}

export default ConsentCheckBoxRHF
