import './News.css'

import React from 'react'
import { useSelector } from 'react-redux'

import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'
import AssuraCarouselArrow from 'shared/components/AssuraCarousel/AssuraCarouselArrow'
import { getNews } from 'shared/store/selectors/getNews'

import NewsCard from '../NewsCard/NewsCard'

const News = () => {
    const news = useSelector(getNews)
    const carouselItems = news?.map((newsItem) => <NewsCard key={newsItem._id} news={newsItem} />)

    return (
        <AssuraCarousel
            dotsClass={'slick-dots'}
            autoplay={true}
            infinite={true}
            speed={300}
            autoplaySpeed={5000}
            cssEase={'linear'}
            slidesToShow={1}
            slidesToScroll={1}
            nextArrow={
                <AssuraCarouselArrow
                    direction={'right'}
                    customClass="arrow-container arrow-news-right"
                    visible
                />
            }
            prevArrow={
                <AssuraCarouselArrow
                    direction={'left'}
                    customClass="arrow-container arrow-news-left"
                    visible
                />
            }
            pauseOnHover={true}
            pauseOnDotsHover={true}
            className="news-carousel-container"
        >
            {carouselItems}
        </AssuraCarousel>
    )
}

export default News
