const useScrollTo = (ref: React.RefObject<HTMLElement>): void => {
    if (ref && ref.current) {
        const rect = ref.current.getBoundingClientRect()

        if (
            rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) ||
            rect.top < 96
        )
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
    }
}

export default useScrollTo
