import './PrimesPanelFooter.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ButtonOrigin, LinkTitle } from 'core/enums/AnalyticsEnums'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { getLocalizedUrl } from 'core/helpers/CalculatorHelper'
import { TranslationKey, TranslationKeyWithVariable } from 'core/models/Translations'
import { openInNewTab } from 'core/utils/onClickUtils'
import { isEmailValid } from 'core/utils/TextUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getConnectedFamilyMemberAndEmail } from 'shared/store/selectors/getConnectedFamilyMemberAndEmail'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'

interface PrimesPanelFooterProps {
    setCalculatorErrorBanner: (key: TranslationKey | TranslationKeyWithVariable) => void
}
const PrimesPanelFooter = ({ setCalculatorErrorBanner }: PrimesPanelFooterProps): JSX.Element => {
    const { t } = useTranslation()
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? true
    const memberFirstNameAndEmail = useSelector(getConnectedFamilyMemberAndEmail)
    const isValidEmail =
        memberFirstNameAndEmail?.email && isEmailValid(memberFirstNameAndEmail.email)

    const onClick = () => {
        if (Source() === ServicesSource.BACKOFFICE) {
            setCalculatorErrorBanner('SERVICES.CALCULATOR_LINK_SUPERVISION')
            return
        }

        if (!isHeadOfFamily) {
            setCalculatorErrorBanner('SERVICES.NO_HEAD_OF_FAMILY')
            return
        }

        if (!isValidEmail) {
            setCalculatorErrorBanner({
                key: 'SERVICES.CALCULATOR_LINK_NO_EMAIL_ERROR',
                variableObject: {
                    firstName: memberFirstNameAndEmail?.firstName || ''
                }
            })
            return
        }

        openInNewTab(getLocalizedUrl(process.env.REACT_APP_CALCULATOR_URL))
        sendEvent(analyticsConstants.EVENTS.OFFER_SUGGEST_CLICK, {
            [analyticsConstants.PARAMS.HREF]: getLocalizedUrl(process.env.REACT_APP_CALCULATOR_URL),
            [analyticsConstants.PARAMS.LINK_TITLE]: LinkTitle.NEW_OFFER,
            [analyticsConstants.PARAMS.BUTTON_ORIGIN]: ButtonOrigin.DETAILS_PRIMES
        })
    }

    return (
        <div className="primes-panel-footer-container">
            <AssuraIconButton
                id="primes-calculator"
                icon="assura-external-link"
                variant="primary"
                size="medium"
                onClick={onClick}
                label={t('SERVICE.SUBSCRIBE_CONTTRACT')}
            />
        </div>
    )
}

export default PrimesPanelFooter
