import './BilledPrime.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import {
    getDefaultEndYear,
    getDefaultStartYear,
    shouldDisplayEmail
} from 'core/helpers/services/ServicesFormHelper'
import {
    BilledPrimeForm,
    BilledPrimeRHFForm
} from 'core/models/services/certificates/BilledPrimeForm'
import { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import FormPage from 'modules/services/components/FormPage/FormPage'
import { getMissingDataLoadingStatus } from 'shared/store/missingData/missingData.slice'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getHasEcAccount } from 'shared/store/selectors/getHasEcAccount'
import { isThereBilledPrimeInRequests } from 'shared/store/selectors/isThereInRequests'
import { onSubmitBilledPrime } from 'shared/store/services/certificates/certificates.slice'
import { fetchContactInfos } from 'shared/store/services/contactInfos/contactInfos.slice'
import { getFamilyGroupResult } from 'shared/store/services/services.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AttestationType from '../../components/AttestationType/AttestationType'
import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import ReceptionMode from '../../components/ReceptionMode/ReceptionMode'
import BilledPrimePeriod from '../components/BilledPrimePeriod/BilledPrimePeriod'

const BilledPrime = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const defaultStartYear = getDefaultStartYear()
    const defaultEndYear = getDefaultEndYear()
    const defaultBaseInsurance = true
    const defaultComplementaryInsurance = true
    const familyGroupResult = useSelector(getFamilyGroupResult)
    const hasEcAccount = useSelector(getHasEcAccount)
    const personalData = useSelector(getFullPersonalData)
    const hasPendingRequest = useSelector(isThereBilledPrimeInRequests)

    const contactDataLoadStatus = personalData.contactDataLoadStatus
    const missingDataLoadingStatus = useSelector(getMissingDataLoadingStatus)
    const defaultEmail = personalData.email.data ?? ''
    const hasEmail = shouldDisplayEmail(defaultEmail)
    const defaultReceptionMode = null

    const initialDefaultValues: BilledPrimeRHFForm = {
        startYear: defaultStartYear,
        endYear: defaultEndYear,
        baseInsurance: defaultBaseInsurance,
        complementaryInsurance: defaultComplementaryInsurance,
        receptionMode: defaultReceptionMode,
        email: defaultEmail,
        updateEmail: false
    }

    const [defaultValues, setDefaultValues] = useState<BilledPrimeRHFForm>(initialDefaultValues)

    const onSuccess = (values: BilledPrimeRHFForm) => {
        const billedPrimeForm: BilledPrimeForm = { ...values, ...familyGroupResult }
        dispatch(onSubmitBilledPrime(billedPrimeForm))
    }

    const selectMember = (familyGroupResult: FamilyGroupResult) => {
        familyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactInfos({
                    selectedPolicyNumber: familyGroupResult.selectedPolicyNumber
                })
            )
    }

    useEffect(() => {
        setDefaultValues({
            ...initialDefaultValues,
            email: personalData.email.data,
            receptionMode: hasEcAccount
                ? ServicesReceptionMode.ELECTRONIC
                : ServicesReceptionMode.MAIL
        })
    }, [personalData, hasEcAccount])

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.CERTIFICATE_CATTP}
            formTitle={{
                title: t('SERVICES.BILLED_PRIME_DETAILS'),
                category: t('SERVICES.TAX_CERTIFICATE_REQUEST')
            }}
            shouldDisplayFormContent={Boolean(familyGroupResult.selectedPolicyNumber)}
            shouldDisplaySubmitButton={contactDataLoadStatus === LoadingStatusEnum.OK}
            onSuccess={onSuccess}
            familyGroupProps={{
                hasAllFamilyButton: true,
                selectAction: selectMember
            }}
            userInfos
            goodToKnow={<GoodToKnow goodToKnowId={ServiceType.CERTIFICATE_CATTP} />}
            isPending={hasPendingRequest ? <PendingRequest /> : undefined}
            dataLoadingStatus={{
                status: getLoadingStatusFromLoaderList([
                    contactDataLoadStatus,
                    missingDataLoadingStatus
                ]),
                defaultReloadAction: () =>
                    dispatch(
                        fetchContactInfos({
                            selectedPolicyNumber: familyGroupResult.selectedPolicyNumber as number,
                            reload: true
                        })
                    )
            }}
        >
            <>
                <div className="billed-prime-details-container bg-white bc-gray100 m-top-32 p-32">
                    <BilledPrimePeriod />
                    <div className="m-top-48">
                        <AttestationType />
                    </div>
                </div>
                <ReceptionMode
                    hasEcAccount={hasEcAccount}
                    hasRegularMail
                    hasEmail={hasEmail}
                    defaultEmail={defaultEmail}
                />
            </>
        </FormPage>
    )
}

export default withAITracking(reactPlugin, BilledPrime, 'BilledPrime', 'billed-prime-container')
