import format from 'date-fns/format'

export const formatDateToApiFormat = (date: string | Date): string => {
    return format(new Date(date), 'yyyy-MM-dd')
}

export const defaultDateFormat = 'dd.MM.yyyy'
export const defaultTimeFormat = 'HH:mm'
export const formatDate = (date: string | Date): string => {
    return format(new Date(date), defaultDateFormat)
}

const dateParsingPattern = /(?<day>[\d]{2})\/?-?\.?\s?(?<month>[\d]{2})\/?-?\.?\s?(?<year>[\d]{4})/
const expectedDateFormat = /(?<year>[\d]{4})\/?-?\.?\s?(?<month>[\d]{2})\/?-?\.?\s?(?<day>[\d]{2})/
const longDateFormatPattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/

export const getDateFromDayMonthYear = (date: string): Date =>
    new Date(getDateStringFromDayMonthYear(date))

export const getDateStringFromDayMonthYear = (date: string): string =>
    date.replace(dateParsingPattern, '$3-$2-$1')

export const isDateFormatValid = (date: string): boolean => {
    const result = date.match(expectedDateFormat)
    return result ? result.length > 0 : false
}
export const isDateLongFormatValid = (potentialDate: string): boolean => {
    if (!potentialDate) return false
    // convert to string otherwise it throws error because numbers doesn't have .match!
    const potentialDateParsed = potentialDate.toString()
    const result = potentialDateParsed.match(longDateFormatPattern)
    return result ? result.length > 0 : false
}

export const isDateFormatValidDDMMYYYY = (date: string): boolean => {
    const result = date.match(dateParsingPattern)
    return result ? result.length > 0 : false
}

export const getDateWithNoTime = (date: string): Date => {
    const fullDate = new Date(date)
    return new Date(Date.UTC(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDate()))
}

export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}
/* */
export const getDateNumbersOnly = (dte: Date) => {
    const year = dte.getFullYear()
    const month = (dte.getMonth() + 1).toString().padStart(2, '0')
    const day = dte.getDate().toString().padStart(2, '0')
    return Number(`${year}${month}${day}`)
}

export const getTimeNumbersOnly = (time: Date) => {
    const hours = time.getHours().toString().padStart(2, '0')
    const minutes = time.getMinutes().toString().padStart(2, '0')
    const seconds = time.getSeconds().toString().padStart(2, '0')
    return Number(`${hours}${minutes}${seconds}`)
}
