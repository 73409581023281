import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { ServicesAddressRHFForm } from 'core/models/services/personalData/Address'
import { addDays, subDays } from 'date-fns'
import ConsentCheckBoxRHF from 'modules/services/components/ConsentCheckBoxRHF/ConsentCheckBoxRHF'
import FormPage from 'modules/services/components/FormPage/FormPage'
import NoHead from 'modules/services/components/NoHead/NoHead'
import { getHeadOfFamily } from 'shared/store/general/general.slice'
import { getStreets } from 'shared/store/localities/localities.slice'
import { getFamilyGroupSettings } from 'shared/store/selectors/getFamilyGroupSettings'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'
import { onSubmitAddress } from 'shared/store/services/address/address.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import GoodToKnow from '../../components/GoodToKnow/GoodToKnow'
import PendingRequest from '../../components/PendingRequest/PendingRequest'
import StartDate from '../../components/StartDate/StartDate'
import AddressConfirmModal from '../components/Address/AddressConfirmModal'
import AddressFromLocality from '../components/Address/AddressFromLocality'
import AddressLocality from '../components/Address/AddressLocality'

const ServicesAddress = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const today = new Date()
    const minValidFrom = subDays(today, 89)
    const maxValidFrom = addDays(today, 89)

    const familyGroup = useSelector(getFamilyGroupSettings)
    const isFromPublic = Source() === ServicesSource.WEBSITE
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? false
    const headOfFamily = useSelector(getHeadOfFamily)
    const isPendingRequest = useSelector(getFullPersonalData).address.isPending
    const shouldConsent = isFromPublic || familyGroup.length > 1
    const initialDefaultValues: ServicesAddressRHFForm = {
        street: '',
        streetNumber: null,
        streetComp: null,
        localityId: null,
        validFrom: new Date(),
        consent: !shouldConsent
    }
    const [defaultValues] = useState<ServicesAddressRHFForm>(initialDefaultValues)
    const streetsResult = useSelector(getStreets)
    const [isConfirmAddressModalVisible, setIsConfirmAddressModalVisible] = useState<boolean>(false)

    const submitAddress = (values: ServicesAddressRHFForm) => {
        if (values.localityId)
            dispatch(
                onSubmitAddress({
                    validFrom: values.validFrom.toISOString(),
                    newAddress: {
                        street: values.street,
                        streetNumber: values.streetNumber,
                        streetComp: values.streetComp ? `c/o${values.streetComp}` : null,
                        localityId: values.localityId
                    }
                })
            )
    }

    const checkAddress = (values: ServicesAddressRHFForm): void => {
        const { street, streetNumber } = values
        let isStreetValid = true
        let isStreetNumberValid = true

        const correspondingStreet = streetsResult?.find(
            (streetResult) => streetResult.displayName?.toLowerCase() === street.toLowerCase()
        )
        if (correspondingStreet) {
            if (streetNumber) {
                isStreetNumberValid = correspondingStreet.numbers.some(
                    (number) => number.toLowerCase() === streetNumber.toLowerCase()
                )
            }
        } else {
            isStreetValid = false
        }
        if (isStreetValid && isStreetNumberValid) {
            submitAddress(values)
        } else {
            setIsConfirmAddressModalVisible(true)
        }
    }

    return (
        <FormPage
            _defaultValues={defaultValues}
            serviceType={ServiceType.ADDRESS_CHANGE}
            formTitle={{
                title: t('PERSONAL_DATA.ADDRESS_FORM_TITLE'),
                category: t('ACCOUNT_CREATION.STEP1_TITLE')
            }}
            onSuccess={checkAddress}
            userInfos
            goodToKnow={
                <GoodToKnow
                    goodToKnowId={ServiceType.ADDRESS_CHANGE}
                />
            }
            isPending={isPendingRequest ? <PendingRequest /> : undefined}
            notAllowed={
                !isHeadOfFamily ? (
                    <NoHead
                        title={t('PERSONAL_DATA.ADDRESS_NO_HEAD_TEXT')}
                        message={
                            <Trans
                                i18nKey="PERSONAL_DATA.ADDRESS_NO_HEAD_OF_FAMILY_BLOC_BOLD"
                                values={{ firstName: headOfFamily?.firstname ?? '' }}
                                components={{ 1: <b /> }}
                            />
                        }
                    />
                ) : undefined
            }
        >
            <>
                <AddressConfirmModal
                    visible={isConfirmAddressModalVisible}
                    onHide={() => setIsConfirmAddressModalVisible(false)}
                    submitAddress={submitAddress}
                />
                <StartDate
                    name="validFrom"
                    minDate={minValidFrom}
                    maxDate={maxValidFrom}
                    testId="address-start-date"
                />
                <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
                    <div className="paragraphMedium">{t('PERSONAL_DATA.ADDRESS_NEW')}</div>
                    <AddressLocality />
                    <AddressFromLocality />
                </div>
                {shouldConsent && <ConsentCheckBoxRHF name="consent" />}
            </>
        </FormPage>
    )
}

export default withAITracking(
    reactPlugin,
    ServicesAddress,
    'ServicesAddress',
    'services-form-container'
)
