import './NoLca.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import useWindowSize from 'core/services/useWindowSize'
import defaultImage from 'shared/assets/images/recommendedProductDefault.png'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import {
    getRecommendedProductsOfSelectedFamilyMember,
    getSelectedFamilyMember
} from 'shared/store/combineSelectors'
import { getLang } from 'shared/store/general/general.slice'

const NoLca = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const selectedMember = useSelector(getSelectedFamilyMember)
    const currentLang = useSelector(getLang)

    const recommendedProductsOfSelectedFamilyMember = useSelector(
        getRecommendedProductsOfSelectedFamilyMember
    )
    const selectedRecommendedProduct = recommendedProductsOfSelectedFamilyMember[0]

    const formId = `recommended-form-${selectedRecommendedProduct?.id ?? ''}`

    const onClick = (): void => {
        sendEvent(analyticsConstants.EVENTS.PRODUCT_CARD_CLICK, {
            [analyticsConstants.PARAMS.HREF]: selectedRecommendedProduct.url,
            [analyticsConstants.PARAMS.PRODUCT_CATEGORY]: selectedRecommendedProduct.subtitle,
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: selectedRecommendedProduct.title
        })
        const formElement = document.getElementById(formId) as HTMLFormElement
        formElement?.submit()
    }

    if (selectedRecommendedProduct) {
        return (
            <>
                <div
                    className="no-lca-image"
                    style={{
                        backgroundImage: `url(${
                            selectedRecommendedProduct.photo
                                ? process.env.REACT_APP_CMS_ASSET + selectedRecommendedProduct.photo
                                : defaultImage
                        })`
                    }}
                />
                <div className="no-lca-content" data-testid="home-no-lca-content">
                    {selectedRecommendedProduct.icon && (
                        <div className="no-lca-icon-container bg-white bc-gray100">
                            <img
                                width="32"
                                height="32"
                                src={`${process.env.REACT_APP_CMS_ASSET}${selectedRecommendedProduct.icon}`}
                                alt={'icon'}
                            />
                        </div>
                    )}
                    <div className="labelExtraSmall c-gray500 no-lca-content-title">
                        {selectedRecommendedProduct.title?.toUpperCase()}
                    </div>
                    <div
                        className={`headlineSmallMedium c-black ${
                            !isMobile && 'max-2-text-lines'
                        } m-bottom-16`}
                    >
                        {selectedRecommendedProduct.description}
                    </div>
                    {selectedRecommendedProduct.url && selectedRecommendedProduct.url.length > 0 ? (
                        <form
                            id={formId}
                            action={`${process.env.REACT_APP_PUBLIC?.toString()}/load/client`}
                            method="POST"
                            target="_blank"
                            className="no-lca-more-button"
                        >
                            <input type="hidden" name="lang" value={currentLang} />
                            <input
                                type="hidden"
                                name="policyNumber"
                                value={selectedMember.policyNumber}
                            />
                            <input
                                type="hidden"
                                name="recommendedProduct"
                                value={selectedRecommendedProduct.url as string}
                            />
                            <AssuraIconButton
                                id="no-lca-more-button"
                                icon="assura-next"
                                variant="primary"
                                size="medium"
                                onClick={() => (selectedRecommendedProduct.url ? onClick() : null)}
                                label={t('COMMON.LEARN_MORE')}
                            />
                        </form>
                    ) : null}
                </div>
            </>
        )
    } else return null
}

export default NoLca
