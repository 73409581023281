import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { fetchClaimsSettlementApi } from 'core/api/ClaimsSettlements'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ClaimsSettlement } from 'core/models/documents/ClaimsSettlement'
import { DocumentsGroup } from 'core/models/documents/Document'

import { fetchUnreadDocuments } from '../documents/documents.slice'
import {
    getSelectedFinancialDocumentsGroup,
    setFinancialDocumentToReadStatus
} from '../financialDocuments/financialDocuments.slice'
import {
    fetchClaimsSettlement,
    setClaimsSettlement,
    setClaimsSettlementLoadingStatus
} from './claimsSettlement.slice'

export function* fetchClaimsSettlementSaga(action: ReturnType<typeof fetchClaimsSettlement>) {
    try {
        yield put(setClaimsSettlementLoadingStatus(LoadingStatusEnum.LOADING))

        const selectedClaimsSettlement: ClaimsSettlement = yield call(
            fetchClaimsSettlementApi,
            action.payload
        )
        yield put(setClaimsSettlement(selectedClaimsSettlement))

        if (Source() !== ServicesSource.BACKOFFICE) {
            const financialGroup: DocumentsGroup = yield select(getSelectedFinancialDocumentsGroup)
            yield put(
                setFinancialDocumentToReadStatus({
                    documentId: selectedClaimsSettlement.documentIdentifier,
                    documentsGroup: financialGroup
                })
            )

            yield put(fetchUnreadDocuments({ shouldUpdateLoadStatus: false }))
        }

        yield put(setClaimsSettlementLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchClaimsSettlementSaga Error', e)
        yield put(setClaimsSettlementLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* fetchClaimsSettlementWatcher() {
    yield takeLatest(fetchClaimsSettlement.type, fetchClaimsSettlementSaga)
}

const watchers = [fork(fetchClaimsSettlementWatcher)]

export default watchers
