/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ErrorOption } from 'react-hook-form'

import { TFunction } from 'i18next'

import { getDateNumbersOnly, getTimeNumbersOnly } from './DateFormatHelper'

export const validateNaN = (value: any, t: TFunction<'translation', undefined>) =>
    (value && !isNaN(value)) || t('COMMON.BAD_FORMAT_NUMBERS_ONLY')

export const isTrue = (value: any, fieldName: string) => () => value === `${fieldName}Yes`
export const isFalse = (value: any, fieldName: string) => () => value === `${fieldName}No`

/** from a time field which is combined with it's date, will check if a date / time is set before today) */
export const dateFieldDateTimeMustBeInPast = (
    value: Date,
    clearErrors: (name?: string | readonly string[] | string[] | undefined) => void,
    setFieldError: (
        name: string,
        error: ErrorOption,
        options?:
            | {
                  shouldFocus: boolean
              }
            | undefined
    ) => void,
    userTimeValue?: Date
) => {
    clearErrors('accidentDate')
    clearErrors('accidentTime')

    const nowDate = new Date()
    const userDateParsed = getDateNumbersOnly(value)
    const currentDateParsed = getDateNumbersOnly(nowDate)

    if (userTimeValue && userDateParsed >= currentDateParsed) {
        const userTimeParsed = getTimeNumbersOnly(userTimeValue)
        const currentTimeParsed = getTimeNumbersOnly(nowDate)

        if (userTimeParsed >= currentTimeParsed) {
            setFieldError('accidentTime', {
                message: 'COMMON.TIME_CANNOT_EXCEED_TODAY'
            })
            return 'COMMON.DATE_CANNOT_EXCEED_TODAY'
        }
    }

    if (userDateParsed > currentDateParsed) {
        return 'COMMON.DATE_CANNOT_EXCEED_TODAY'
    }

    return true
}

export const timeFieldDateTimeMustBeInPast = (
    value: Date,
    clearErrors: (name?: string | readonly string[] | string[] | undefined) => void,
    setFieldError: (
        name: string,
        error: ErrorOption,
        options?:
            | {
                  shouldFocus: boolean
              }
            | undefined
    ) => void,
    userDateValue?: Date
) => {
    clearErrors('accidentDate')
    clearErrors('accidentTime')

    if (!userDateValue) return true

    const nowDate = new Date()
    const userDateParsed = getDateNumbersOnly(userDateValue)
    const currentDateParsed = getDateNumbersOnly(nowDate)

    const userTimeParsed = getTimeNumbersOnly(value)
    const currentTimeParsed = getTimeNumbersOnly(nowDate)

    if (
        (userDateParsed === currentDateParsed && userTimeParsed >= currentTimeParsed) ||
        userDateParsed > currentDateParsed
    ) {
        setFieldError('accidentDate', {
            message: 'COMMON.DATE_CANNOT_EXCEED_TODAY'
        })
        return 'COMMON.TIME_CANNOT_EXCEED_TODAY'
    }
    return true
}

export const dateValidation = (value: string | Date | number | undefined): string | boolean => {
    if (!value) {
        return 'COMMON.MANDATORY_FIELD'
    } else {
        return true
    }
}

export const badDateSequenceValidation = (
    from: Date,
    to: Date,
    message?: string
): string | boolean => {
    if (from && !to) {
        return dateValidation(from)
    }
    const resultFrom = dateValidation(from)
    if (typeof resultFrom === 'string') return resultFrom

    const resultTo = dateValidation(to)
    if (typeof resultTo === 'string') return resultTo

    if (to < from) {
        return message ? message : 'COMMON.BAD_DATE_SEQUENCE'
    } else {
        return true
    }
}
