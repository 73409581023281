import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { submitHospitalizationAnnouncementForm } from 'core/api/services/Report'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { HospitalizationAnnouncementFormRequestBody } from 'core/models/services/report/HospitalizationAnnouncementForm'
import { ServicesCommonRequestBody } from 'core/models/services/ServicesCommonRequestBody'
import { getServicesCommonRequestBody } from 'shared/store/selectors/getServicesCommonRequestBody'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import { onSubmitHospitalizationAnnouncement } from './report.slice'

function* onSubmitHospitalizationAnnouncementSaga(
    action: ReturnType<typeof onSubmitHospitalizationAnnouncement>
) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.HOSPITALIZATION_ANNOUNCEMENT
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const hospitalizationAnnouncementForm = action.payload
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )
        const formToSubmit: HospitalizationAnnouncementFormRequestBody = {
            ...hospitalizationAnnouncementForm,
            ...commonRequestBody
        }

        yield call(submitHospitalizationAnnouncementForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitHospitalizationAnnouncementSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitHospitalizationAnnouncementWatcher() {
    yield takeEvery(
        onSubmitHospitalizationAnnouncement.type,
        onSubmitHospitalizationAnnouncementSaga
    )
}

const watchers = [fork(onSubmitHospitalizationAnnouncementWatcher)]

export default watchers
