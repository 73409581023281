import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { generalQuestionTopicData } from 'core/api/Cms'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { GeneralQuestionTopic } from 'core/models/cms/GeneralQuestionTopicCms'

import {
    fetchGeneralQuestionTopic,
    setGeneralQuestionTopic,
    setGeneralQuestionTopicLoadingStatus
} from './generalQuestionTopic.slice'

export function* fetchGeneralQuestionTopicSaga() {
    try {
        yield put(setGeneralQuestionTopicLoadingStatus(LoadingStatusEnum.LOADING))
        const generalQuestionTopic: GeneralQuestionTopic[] = yield call(generalQuestionTopicData)
        yield put(setGeneralQuestionTopic(generalQuestionTopic))
        yield put(setGeneralQuestionTopicLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        yield put(setGeneralQuestionTopicLoadingStatus(LoadingStatusEnum.ERROR))
        console.error('fetchGeneralQuestionTopicSaga Error', e)
    }
}

function* fetchGeneralQuestionTopicWatcher() {
    yield takeEvery(fetchGeneralQuestionTopic.type, fetchGeneralQuestionTopicSaga)
}

const watchers = [fork(fetchGeneralQuestionTopicWatcher)]

export default watchers
