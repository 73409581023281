/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import { PropsWithChildren, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FileWithPath } from 'react-dropzone'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { VehiclesModel } from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import { commonMandatoryErrorKey } from 'core/validators/constants'
import { getErrorMessage } from 'modules/services/aventura/helpers/validations'
import RadioRHF from 'modules/services/components/RadioRHF/RadioRHF'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { StepItem } from 'modules/services/components/Stepper/core/models'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'

import { isFalse, isTrue } from '../../../../core/helpers/FieldValidationHelper'
import AccidentReportDropzone from '../components/AccidentReportDropzone'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'VEHICLE'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const vehiclesFormFieldNameList: Array<keyof VehiclesModel> = [
    'injured',
    'vehicleDriver',
    'owner',
    'vehicleOwner',
    'ownerInsurance',
    'ownerCarPlates',
    'otherVehicleDriver',
    'otherVehicleOwner',
    'otherVehicleInsurance',
    'otherVehiclePlates',
    'otherInsuranceDeclared',
    'otherInsuranceNumber',
    'friendlyArrangement',
    'friendlyDocument'
]

export interface VehiclesFormProps extends Partial<PropsWithChildren<JSX.Element>> {
    id: number
}

type rhfRadioValueType = string | FileWithPath | undefined

// Step 7 - VEHICLE
const VehiclesForm = ({ id }: VehiclesFormProps): JSX.Element => {
    const { stepWatch } = useStepperContext()
    const { register, getValues, setValue, trigger, formState, resetField } = useFormContext<
        VehiclesModel | FieldValues
    >()
    const { isMobile, isMD } = useWindowSize()
    const { errors } = formState
    const stepState = stepWatch(id) as StepItem
    const { TextInput, TextareaInput, RadioGroup } = useCustomControls(formName)

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const { injured, friendlyArrangement, owner, otherInsuranceDeclared } = getValues()

    const injuredValidatePassenger = injured === 'injured-passenger'
    const injuredValidateIsDriverOrPassenger =
        injured === 'injured-driver' || injured === 'injured-passenger'
    //const ownerValidate = () => (['injured-driver', 'injured-passenger'].includes(getValues('injured')) && !!owner) || t(commonMandatoryErrorKey)
    const ownerValidateSelected =
        injuredValidateIsDriverOrPassenger && (owner === 'ownerYes' || owner === 'ownerNo')

    const ownerValidateNo = isFalse(getValues('owner'), 'owner')()
    const otherInsuranceDeclaredValidateYes = isTrue(
        getValues('otherInsuranceDeclared'),
        'otherInsuranceDeclared'
    )()

    const friendlyArrangementValidateYes = isTrue(
        getValues('friendlyArrangement'),
        'friendlyArrangement'
    )()

    /** remove data if not passenger */
    useEffect(() => {
        if (!injuredValidatePassenger) setValue('vehicleDriver', undefined)
        if (!injuredValidateIsDriverOrPassenger) {
            // setValue('vehicleDriver', undefined)
            const fieldsToClear = [
                'vehicleOwner',
                'otherInsuranceNumber',
                'ownerInsurance',
                'ownerCarPlates',
                'owner'
            ]

            fieldsToClear.forEach((field: string) =>
                resetField(field, { keepDirty: true, keepError: false, keepTouched: true })
            )
        }

        if (friendlyArrangement === 'friendlyArrangementNo') {
            setValue('friendlyDocument', undefined, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: stepState.hasBeenValidated
            })
            trigger('friendlyDocument')
        }

        if (!otherInsuranceDeclaredValidateYes) {
            resetField('otherInsuranceNumber', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
        }

        if (owner === 'ownerYes') {
            resetField('vehicleOwner', { keepDirty: true, keepError: false, keepTouched: true })
        }

        if (owner === 'otherInsuranceDeclaredNo') {
            resetField('otherInsuranceNumber', {
                keepDirty: true,
                keepError: false,
                keepTouched: true
            })
        }
    }, [
        injuredValidatePassenger,
        injuredValidateIsDriverOrPassenger,
        friendlyArrangement,
        owner,
        otherInsuranceDeclared
    ])

    const handleRadioButtonClick = (
        field: keyof VehiclesModel,
        value: string | FileWithPath | undefined
    ) => {
        setValue(field, value, { shouldValidate: true, shouldDirty: true })
    }

    const injuredErrors = getErrorMessage(errors, 'injured')

    const mBottom = 'm-bottom-16 radio-column'

    return (
        <div id={`${id}`} className="step-frame">
            <FormBlock
                titleTestId={'your-vehicle'}
                title={t(`${serviceName}.${formName}.YOUR_VEHICLE`)}
                childrenMargin="m-bottom-48"
            >
                <Row>
                    <Col
                        sm={12}
                        md={12}
                        xl={4}
                        className={`paragraphMedium ${isMobile || isMD ? 'm-bottom-24' : ''}`}
                        data-testid={`${formName?.replace('_', '-')?.toLowerCase?.()}-${'injured'}`}
                        /* data-test-value MUST contains empty space char so it's computed at render. it's needed for tests purposes */
                        data-test-value={getValues('injured') ?? ' '}
                    >
                        {t(`${serviceName}.${formName}.INJURED`)}
                    </Col>

                    <Col sm={12} md={12} xl={8}>
                        <Row id="injured">
                            <Col className={mBottom} sm={12} md={12} xl={12}>
                                <RadioRHF
                                    id="injured-pedestrian"
                                    name="injured"
                                    label={t(`${serviceName}.${formName}.PEDESTRIAN`)}
                                    onClick={(value) =>
                                        handleRadioButtonClick(
                                            'injured',
                                            value as rhfRadioValueType
                                        )
                                    }
                                    register={register}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t(commonMandatoryErrorKey)
                                        }
                                    }}
                                />
                            </Col>
                            <Col className={mBottom} sm={12} md={12} xl={12}>
                                <RadioRHF
                                    id="injured-driver"
                                    name="injured"
                                    label={t(`${serviceName}.${formName}.DRIVER`)}
                                    onClick={(value) =>
                                        handleRadioButtonClick(
                                            'injured',
                                            value as rhfRadioValueType
                                        )
                                    }
                                    register={register}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t(commonMandatoryErrorKey)
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={12} xl={12}>
                                <RadioRHF
                                    id="injured-passenger"
                                    name="injured"
                                    label={t(`${serviceName}.${formName}.PASSENGER`)}
                                    // eslint-disable-next-line sonarjs/no-identical-functions
                                    onClick={(value) =>
                                        handleRadioButtonClick(
                                            'injured',
                                            value as rhfRadioValueType
                                        )
                                    }
                                    register={register}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t(commonMandatoryErrorKey)
                                        }
                                    }}
                                />
                            </Col>
                            {injuredErrors && (
                                <Row className="m-top-8">
                                    <Col sm={12} md={12} xl={12}>
                                        <div
                                            className="labelExtraSmall c-error m-top-8 m-left-16"
                                            data-testid={'injured-error'}
                                        >
                                            {t(injuredErrors)}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </Col>
                </Row>

                {injuredValidatePassenger && (
                    <Row>
                        <Col>
                            {TextareaInput(
                                'vehicleDriver',
                                'VEHICLE_DRIVER',
                                {
                                    mandatory: injuredValidatePassenger,
                                    maxLength: 400
                                },
                                false
                            )}
                        </Col>
                    </Row>
                )}
                {injuredValidateIsDriverOrPassenger && (
                    <>
                        {RadioGroup(
                            'OWNER',
                            'owner',
                            {
                                mandatory: true
                            },
                            () => trigger('owner'),
                            true
                        )}
                        {ownerValidateNo && (
                            <Row className="m-top-48">
                                <Col>
                                    {TextareaInput(
                                        'vehicleOwner',
                                        'OWNER_VEHICLE',
                                        {
                                            mandatory: ownerValidateNo,
                                            maxLength: 400
                                        },
                                        false
                                    )}
                                </Col>
                            </Row>
                        )}
                        {ownerValidateSelected && (
                            <>
                                <Row className="m-top-48 m-bottom-48">
                                    <Col>
                                        {TextInput('ownerCarPlates', 'OWNER_CAR_PLATES', {
                                            mandatory: injuredValidateIsDriverOrPassenger,
                                            maxLength: 50
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {TextInput('ownerInsurance', 'OWNER_INSURANCE', {
                                            mandatory: injuredValidateIsDriverOrPassenger,
                                            maxLength: 50
                                        })}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'other-involved-vehicle'}
                title={t(`${serviceName}.${formName}.OTHER_INVOLVED_VEHICLE`)}
                childrenMargin="m-bottom-48"
            >
                {TextareaInput(
                    'otherVehicleDriver',
                    'OTHER_VEHICLE_DRIVER',
                    {
                        mandatory: false,
                        maxLength: 400
                    },
                    false
                )}
                {TextareaInput(
                    'otherVehicleOwner',
                    'OTHER_VEHICLE_OWNER',
                    {
                        mandatory: false,
                        maxLength: 400
                    },
                    false
                )}
                {TextInput('otherVehiclePlates', 'OTHER_VEHICLE_PLATES', {
                    // todo: we need to wait the feedback from business then we clear or enable the following mandatory rule
                    //  mandatory: !!otherVehicleDriver,
                    maxLength: 50
                })}
                {TextInput('otherVehicleInsurance', 'OTHER_VEHICLE_INSURANCE', {
                    mandatory: false,
                    maxLength: 50
                })}
                {RadioGroup(
                    'OTHER_INSURANCE_DECLARED',
                    'otherInsuranceDeclared',
                    {
                        mandatory: false
                    },
                    () => trigger('otherInsuranceDeclared'),
                    true
                )}
                {otherInsuranceDeclaredValidateYes && (
                    <>
                        {TextInput('otherInsuranceNumber', 'OTHER_INSURANCE_NUMBER', {
                            mandatory: otherInsuranceDeclaredValidateYes,
                            maxLength: 50
                        })}
                    </>
                )}
            </FormBlock>

            <FormBlock
                titleTestId={'friendly-report'}
                title={t(`${serviceName}.${formName}.FRIENDLY-REPORT`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom="m-bottom-32"
            >
                {RadioGroup(
                    'FRIENDLY_ARRANGEMENT',
                    'friendlyArrangement',
                    {
                        // todo: we need to wait the feedback from business then we clear or enable the following mandatory rule
                        // mandatory: injuredValidateDriver && !!otherVehiclePlates
                    },
                    () => trigger('friendlyArrangement'),
                    true
                )}
                {friendlyArrangementValidateYes && (
                    <>
                        <div className="labelMedium m-bottom-24">
                            {t(`${serviceName}.${formName}.ATTACHMENT`)}
                        </div>
                        <AccidentReportDropzone
                            name="friendlyDocument"
                            defaultValue={getValues('friendlyDocument')}
                            mandatory={friendlyArrangementValidateYes}
                            clear={() => {
                                setValue('friendlyDocument', undefined, {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                    shouldValidate: stepState.hasBeenValidated
                                })
                                trigger('friendlyDocument')
                            }}
                        />
                    </>
                )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}

export default VehiclesForm
