import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import useWindowSize from 'core/services/useWindowSize'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'
import { logout } from 'shared/store/authentication/authentication.slice'
import { getPath } from 'shared/store/general/general.slice'

const NavBarProfileContent = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setNavBarMenuState } = useAssuraNavBarContext()
    const path = useSelector(getPath)

    const handleOnClick = (target: string) => {
        if (!isXL) setNavBarMenuState(false)
        navigate(target)
    }

    return (
        <>
            <Dropdown.Item
                onClick={() => handleOnClick(navigationConstants.PERSONAL_DATA.url)}
                data-testid="navigation-profile-personal-data"
                className={`${
                    path === navigationConstants.PERSONAL_DATA.url
                        ? 'bg-error-background labelSmallMedium'
                        : ''
                }`}
            >
                <i className="icon assura-profile size-24 m-right-20" />
                {t('PERSONAL_DATA.TITLE')}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnClick(navigationConstants.SETTINGS.url)}
                data-testid="navigation-profile-settings"
                className={`${
                    path === navigationConstants.SETTINGS.url
                        ? 'bg-error-background labelSmallMedium'
                        : ''
                }`}
            >
                <i className="icon assura-settings size-24 m-right-20" />
                {t('NAVIGATION_BAR.SETTINGS')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(logout())} data-testid="navbar-logout">
                <i className="icon assura-logout size-24 m-right-20" />
                {t('MORE.LOGOUT')}
            </Dropdown.Item>
        </>
    )
}

export default NavBarProfileContent
