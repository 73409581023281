import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ServiceType } from 'core/enums/ServiceType'
import { extractDoctorPhone } from 'core/helpers/DoctorsPhoneHelper'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { phoneFormatted } from 'core/utils/phoneUtils'
import { stringUpperCaseFirstCharacter } from 'core/utils/TextUtils'
import doctorIcon from 'shared/assets/images/doctor.png'
import { getLamalWithDoctorCodes } from 'shared/store/selectors/getLamalWithDoctorCodes'
import { goToServicesForm } from 'shared/store/services/services.slice'

import { displayConditionalInformations, SpecificationLine } from './LamalSpecificationsCard'

interface RenderDoctorProps {
    doctor: Doctor | null | undefined
    code: string
    choiceOfDoctor: string | undefined
}

const DoctorLine = (props: RenderDoctorProps): JSX.Element => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { doctor, code, choiceOfDoctor } = props
    const phoneDoctor = extractDoctorPhone(doctor?.phones)
    const lamalWithDoctorCodes = useSelector(getLamalWithDoctorCodes)
    const isLamalWithDoctor = lamalWithDoctorCodes.includes(code)

    if (displayConditionalInformations(code).isDisplayingDoctor) {
        return (
            <SpecificationLine
                id="doctor"
                code={code}
                illustration={doctorIcon}
                informationsContainer={
                    doctor ? (
                        <>
                            <span className="labelSmallMedium">
                                {doctor?.firstName} {doctor.lastName}
                            </span>
                            <div
                                id="lamal-specification-doctor-line"
                                className="labelExtraSmall d-flex flex-column c-gray500"
                            >
                                <span>{stringUpperCaseFirstCharacter(doctor.street)}</span>
                                <span>
                                    {doctor.zipCode} {doctor.locality}
                                </span>
                                {phoneDoctor && <span>{phoneFormatted(phoneDoctor)}</span>}
                            </div>
                        </>
                    ) : isLamalWithDoctor ? (
                        <div className="d-flex flex-column">
                            <span className="labelSmallMedium">
                                {t('INSURANCES.LAMAL_NO_DOCTOR_TITLE')}
                            </span>
                            <span
                                className="labelExtraSmall c-gray500"
                                style={{ marginTop: '2px' }}
                            >
                                {t('INSURANCES.LAMAL_NO_DOCTOR_MESSAGE')}
                            </span>
                        </div>
                    ) : (
                        <span className="labelSmallMedium">
                            {choiceOfDoctor && stringUpperCaseFirstCharacter(choiceOfDoctor)}
                        </span>
                    )
                }
                linkContainer={
                    isLamalWithDoctor ? (
                        <div
                            className="cursor-pointer align-items-center"
                            onClick={() =>
                                dispatch(
                                    goToServicesForm({ type: ServiceType.FAMILY_DOCTOR_CHANGE })
                                )
                            }
                        >
                            <i className="icon assura-edit c-primary size-24" />
                        </div>
                    ) : undefined
                }
            />
        )
    } else {
        return <></>
    }
}
export default DoctorLine
