import './PersonalDataFooter.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import Financial from 'modules/personalData/components/Financial/Financial'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'

import Address from '../Address/Address'
import PersonalDataGood from '../PersonalDataGood/PersonalDataGood'

const PersonalDataFooter = (): JSX.Element => {
    const { t } = useTranslation()

    const isPublic = Source() === ServicesSource.WEBSITE
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? false

    return (
        <>
            <Row className="personal-data-footer-title-container">
                <Col>
                    <span className="headlineLarge break-word">
                        {t('PERSONAL_DATA.FAMILY_GROUP_DATA_TITLE')}
                    </span>
                </Col>
            </Row>
            <Row style={{ marginBottom: '120px' }} id="personal-data-footer-container">
                <Col
                    xs={12}
                    md={isPublic ? 12 : 6}
                    xl={isPublic ? 6 : 4}
                    className={isPublic ? '' : 'd-flex'}
                >
                    <Address isPublic={isPublic} isHeadOfFamily={isHeadOfFamily} />
                </Col>
                {!isPublic && (
                    <Col
                        xs={12}
                        md={6}
                        xl={4}
                        className="d-flex personal-data-footer-financial-container"
                    >
                        <Financial />
                    </Col>
                )}
                <PersonalDataGood isPublic={isPublic} isHeadOfFamily={isHeadOfFamily} />
            </Row>
        </>
    )
}

export default PersonalDataFooter
