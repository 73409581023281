import './FinancialsInvoicesPanelDatasBlock.css'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { formatNumber } from 'core/utils/TextUtils'

interface FinancialsInvoicesPanelDatasBlockProps {
    selectedDocument: DocumentRow
    // setShouldSwitchPdf: () => void
    // shouldSwitchPdf: boolean
}

const FinancialsInvoicesPanelDatasBlock = ({
    selectedDocument
}: // setShouldSwitchPdf,
// shouldSwitchPdf
FinancialsInvoicesPanelDatasBlockProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="financials-invoices-panel-datas-block-container">
            <div className="d-flex justify-content-between align-items-center">
                <div className="headlineSmallMedium">{t(selectedDocument.inFavour)}</div>
                {/* <AssuraIconButton
                    id="statements-panel-datas-pdf-toggle"
                    icon={shouldSwitchPdf ? 'assura-close' : 'assura-doc'}
                    variant="primary"
                    size="medium"
                    onClick={setShouldSwitchPdf}
                    label={t(
                        shouldSwitchPdf ? 'STATEMENTS.PANEL_CLOSE_PDF' : 'STATEMENTS.PANEL_OPEN_PDF'
                    )}
                /> */}
            </div>
            <div className="financials-invoices-panel-datas-block-info m-top-20 bbc-gray100">
                <div className="label c-gray500">{t('STATEMENTS.PANEL_AMOUNT')}</div>
                <div className="label" data-testid="statements-panel-datas-value-amount">
                    {`CHF ${formatNumber(selectedDocument.valueAmount, true)}`}
                </div>
            </div>
            <div className="financials-invoices-panel-datas-block-info bbc-gray100">
                <div className="label c-gray500">{t('STATEMENTS.PANEL_UNPAID_AMOUNT')}</div>
                <div className="label" data-testid="statements-panel-datas-unpaid-amount">
                    {`CHF ${formatNumber(selectedDocument.unpaidAmount, true)}`}
                </div>
            </div>
            <div className="financials-invoices-panel-datas-block-info bbc-gray100">
                <div className="label c-gray500">{t('INBOX.INVOICE_DEADLINE_BIS')}</div>
                <div className="label" data-testid="statements-panel-datas-dead-line-date">
                    {selectedDocument.deadLineDate ? formatDate(selectedDocument.deadLineDate) : ''}
                </div>
            </div>
        </div>
    )
}

export default FinancialsInvoicesPanelDatasBlock
