import { useTranslation } from 'react-i18next'

import { PaymentTypeEnum } from 'core/enums/Documents'
import { formatNumber } from 'core/utils/TextUtils'

import PaymentTypeIcon from '../PaymentTypeIcon/PaymentTypeIcon'

interface ClaimsSettlementPanelReceiptLabelProps {
    cost: number
    label: string
    shouldDisplayTypeIndicator?: boolean
    isRefund?: boolean
    labelClassNames?: string
}

const ClaimsSettlementPanelReceiptLabel = ({
    cost,
    label,
    shouldDisplayTypeIndicator,
    isRefund,
    labelClassNames
}: ClaimsSettlementPanelReceiptLabelProps): JSX.Element => {
    const { t } = useTranslation()

    const labelClassNamesToApplied = labelClassNames ? ` ${labelClassNames}` : ''

    return (
        <div className={`d-flex flex-1${labelClassNamesToApplied}`}>
            <div className="labelExtraSmall c-gray500">{t(label)}</div>
            <div className="d-flex align-items-center m-top-4">
                {shouldDisplayTypeIndicator && (
                    <PaymentTypeIcon
                        paymentType={isRefund ? PaymentTypeEnum.REFUND : PaymentTypeEnum.INVOICE}
                        testId="claims-settlement-panel-receipt-label-payment-type-icon"
                        isSmall
                        containerClassNames="claims-settlement-panel-type-indicator-container"
                    />
                )}
                <div className="labelSmallMedium">
                    {t('COMMON.PRICE', { price: formatNumber(cost) })}
                </div>
            </div>
        </div>
    )
}

export default ClaimsSettlementPanelReceiptLabel
