import { useTranslation } from 'react-i18next'

import PeriodicityTrads from 'core/constants/productPeriodicityTraductions'
import { ProductPeriodicity } from 'core/enums/Product'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface PaymentFrequencyModificationProps {
    values: RequestDetailsValues[]
}

const PaymentFrequencyModification = ({
    values
}: PaymentFrequencyModificationProps): JSX.Element => {
    const { t } = useTranslation()

    const periodicity = values.find((v) => v.id === 'PeriodicityCode')
    const validFrom = values.find((v) => v.id === 'ValidFrom')

    const label = t('HOME.PREMIUMS_PERIODICITY')

    const mapCodeToPeriodicity = (code: string | number): ProductPeriodicity => {
        switch (code) {
            case 0:
            case 'monthly':
                return ProductPeriodicity.MONTHLY
            case 1:
            case 'quarterly':
                return ProductPeriodicity.QUARTERLY
            case 2:
            case 'biannual':
                return ProductPeriodicity.BIANNUAL
            default:
                return ProductPeriodicity.ANNUAL
        }
    }

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            {periodicity && (
                <>
                    <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
                    <div className="requests-panel-main-block m-bottom-32">
                        <LabelValueInlineBlock>
                            <div className="align-self-end">{label}</div>
                            <div data-testid={`requests-details-payment-frequency-old`}>
                                {t(
                                    PeriodicityTrads[
                                        mapCodeToPeriodicity(periodicity?.old as string)
                                    ]
                                )}
                            </div>
                        </LabelValueInlineBlock>
                    </div>
                    {validFrom && validFrom.new && (
                        <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                            <div
                                className="label c-primary"
                                data-testid={`requests-details-payment-frequency-start-date`}
                            >
                                {t('DOCUMENTS.PROCESSING_DATE', {
                                    processingDate: formatDate(validFrom.new as string)
                                })}
                            </div>
                        </RequestsPanelSubtitle>
                    )}
                    <div className="requests-panel-main-block">
                        <LabelValueInlineBlock>
                            <div className="align-self-end">{label}</div>
                            <div data-testid={`requests-details-payment-frequency-new`}>
                                {t(
                                    PeriodicityTrads[
                                        mapCodeToPeriodicity(periodicity?.new as string)
                                    ]
                                )}
                            </div>
                        </LabelValueInlineBlock>
                    </div>
                </>
            )}
        </div>
    )
}

export default PaymentFrequencyModification
