import './DeleteAccountPopin.css'

import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'

import useWindowSize from 'core/services/useWindowSize'
import { assets } from 'core/utils/assetUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { logout } from 'shared/store/authentication/authentication.slice'
import { useAppDispatch } from 'shared/store/store'

const DeleteAccountPopin = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { isMobile } = useWindowSize()

    const handleValidation = () => {
        dispatch(logout())
    }

    return (
        <div className="delete-account-pop-in-back-container">
            <RemoveScroll>
                <div
                    className="delete-account-pop-in-content"
                    data-testid="delete-account-pop-in-content"
                >
                    <img src={assets.validated} alt="delete-account-image" width={80} height={80} />
                    <span
                        className="headlineSmallMedium m-top-32 text-center"
                        data-testid="delete-account-pop-in-title"
                    >
                        {t('DELETE_ACCOUNT.POPIN_TITLE')}
                    </span>
                    <span
                        className="paragraphSmallLight m-top-16 m-bottom-8 text-center"
                        data-testid="delete-account-pop-in-message"
                    >
                        {t('DELETE_ACCOUNT.POPIN_SUBTITLE')}
                    </span>
                    <AssuraButton
                        text={t('COMMON.I_UNDERSTOOD')}
                        id="delete-account-pop-in-validate"
                        variant="primary"
                        onClick={handleValidation}
                        classNames="m-top-32"
                        fullWidth={isMobile}
                    />
                </div>
            </RemoveScroll>
        </div>
    )
}

export default DeleteAccountPopin
