const concatClassNames = (items: (string | undefined)[]): string => {
    const classNameList: string[] = []

    items.forEach((item) => {
        if (item) {
            const extractedItems = item.split(' ')
            extractedItems.forEach((i) => classNameList.push(i))
        }
    })

    return classNameList.join(' ')
}

export default concatClassNames
