import { isSafari } from 'react-device-detect'

import { openBase64InNewTab } from 'core/helpers/documents/DocumentFileHelper'
import { AssuraDocument } from 'core/models/AssuraDocument'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'

import { RenderDownloadProps } from '@react-pdf-viewer/get-file'
import { RenderPrintProps } from '@react-pdf-viewer/print'
import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom'

interface StatementsPanelHeaderRightProps {
    toolbarPluginInstance: ToolbarPlugin
    statusIsOk: boolean
    doc?: AssuraDocument
}

const InvoicesToCheckPanelHeaderRight = ({
    toolbarPluginInstance,
    statusIsOk,
    doc
}: StatementsPanelHeaderRightProps): JSX.Element => {
    const { isMobile, isXL } = useWindowSize()
    const { hasPDFPanel, hasLeftPanel } = usePanelDatasContext().panelDatas

    const headerMargin = isMobile ? 16 : 32

    const { Download } = toolbarPluginInstance.getFilePluginInstance
    const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance
    const { Print } = toolbarPluginInstance.printPluginInstance

    // INFO: The Print plugin doesn't work on Safari
    const onSafariPrint = () => {
        if (doc) openBase64InNewTab(doc.base64)
    }

    return (
        <>
            {statusIsOk && !(hasLeftPanel && !hasPDFPanel) && (
                <div
                    className="d-flex align-items-center"
                    style={{ padding: `0 ${headerMargin}px` }}
                >
                    {isXL && (
                        <>
                            <ZoomOut>
                                {(props: RenderZoomOutProps) => (
                                    <AssuraIconButton
                                        id="itc-panel-header-zoom-out"
                                        icon="assura-zoom-out"
                                        variant="default"
                                        size="medium"
                                        onClick={props.onClick}
                                        classNames="m-right-16"
                                    />
                                )}
                            </ZoomOut>
                            <ZoomIn>
                                {(props: RenderZoomInProps) => (
                                    <AssuraIconButton
                                        id="itc-panel-header-zoom-in"
                                        icon="assura-zoom"
                                        variant="default"
                                        size="medium"
                                        onClick={props.onClick}
                                    />
                                )}
                            </ZoomIn>
                        </>
                    )}
                    <Download>
                        {(props: RenderDownloadProps) => (
                            <AssuraIconButton
                                id="itc-panel-header-download"
                                icon="assura-download"
                                variant="primary"
                                size="medium"
                                onClick={props.onClick}
                                classNames="m-left-16"
                            />
                        )}
                    </Download>
                    {!isMobile && (
                        <Print>
                            {(props: RenderPrintProps) => (
                                <AssuraIconButton
                                    id="itc-panel-header-print"
                                    icon="assura-print"
                                    variant="primary"
                                    size="medium"
                                    onClick={isSafari ? onSafariPrint : props.onClick}
                                    classNames="m-left-16"
                                />
                            )}
                        </Print>
                    )}
                </div>
            )}
        </>
    )
}

export default InvoicesToCheckPanelHeaderRight
