import './NoResults.css'

import { ElementType } from 'react'
import { useTranslation } from 'react-i18next'

import AssuraButton from '../AssuraButton/AssuraButton'

interface NoResultsProps<C extends ElementType> {
    text: string
    image: string
    dataTestId: string
    onReset?: () => void
    complementaryClasses?: string
    as?: C
}

const NoResults = <C extends ElementType = 'div'>({
    text,
    image,
    dataTestId,
    onReset,
    complementaryClasses,
    as
}: NoResultsProps<C>): JSX.Element => {
    const { t } = useTranslation()
    const As = as || 'div'

    return (
        <As
            className={`no-results-container${
                complementaryClasses ? ` ${complementaryClasses}` : ''
            }`}
            data-testid={`${dataTestId}-no-results-container`}
        >
            <img
                data-testid={`${dataTestId}-no-results-image`}
                src={image}
                className="no-results-img"
                alt={`${image}-illustration`}
            />
            <span
                className="paragraphSmall text-center text-wrap"
                data-testid={`${dataTestId}-no-results-label`}
            >
                {t(text)}
            </span>
            {onReset && (
                <AssuraButton
                    text={t('FILTERS.EMPTY_VIEW_RESET')}
                    id={`${dataTestId}-no-results-reset-button`}
                    variant="secondary"
                    onClick={onReset}
                    icon={{
                        name: 'assura-refresh',
                        size: 24
                    }}
                    classNames="m-top-32"
                />
            )}
        </As>
    )
}

export default NoResults
