import { AppSearchSectionTitle } from 'core/constants/appSearch'
import { removeDiacritics } from 'core/helpers/TextHelper'
import { AppSearchResult, AppSearchSection, AppSearchSectionKeyType } from 'core/models/AppSearch'

import { createSelector } from '@reduxjs/toolkit'

import { getSearchText } from '../appSearch/appSearch.slice'
import { getAppSearchLocalizedData } from './getAppSearchLocalizedData'

const transformUserSearchWithBold = (userSearch: string, label: string, indexStart: number[]) => {
    const userSearchLength = removeDiacritics(userSearch).trim().length - 1
    const indexEnd = indexStart.map(
        (i) => i + (label[i + userSearchLength] === ' ' ? userSearchLength - 1 : userSearchLength)
    )

    return [...label].reduce((text, letter, index) => {
        if (indexStart.includes(index)) {
            text += `<1>${letter}`
        } else if (indexEnd.includes(index)) {
            text += `${letter}</1>`
        } else {
            text += letter
        }
        return text
    }, '')
}

export const getAppSearchFilteredSections = createSelector([getSearchText, getAppSearchLocalizedData], (userSearch, data): AppSearchSection[] => {
    const finalResults: AppSearchSection[] = []

    if (userSearch.length > 2) {
        // To cover eszett ß
        const currentUserSearch = userSearch.replaceAll('ß', 'ss')

        Object.entries(data).forEach(([cat, results]) => {
            const filteredResults = results.reduce<AppSearchResult[]>((acc, result) => {
                const indexStart = [
                    ...removeDiacritics(result.label)
                        .toLowerCase()
                        .matchAll(
                            new RegExp(
                                removeDiacritics(currentUserSearch).toLowerCase().trim(),
                                'gi'
                            )
                        )
                ].reduce<number[]>((acc, a) => {
                    if (a.index !== undefined) acc.push(a.index)
                    return acc
                }, [])

                if (indexStart.length) {
                    const transformedLabel = transformUserSearchWithBold(
                        currentUserSearch,
                        result.label,
                        indexStart
                    )
                    acc.push({
                        ...result,
                        label: transformedLabel
                    })
                }
                return acc
            }, [])

            if (filteredResults.length > 0)
                finalResults.push({
                    id: cat as AppSearchSectionKeyType,
                    sectionTitleKey: AppSearchSectionTitle[cat as AppSearchSectionKeyType],
                    results: [...filteredResults]
                })
        })
    }

    return finalResults
})
