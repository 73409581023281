import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import NoActiveContract from 'modules/services/components/NoActiveContract/NoActiveContract'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import { getLamalAccidentOfSelectedFamilyMember } from 'shared/store/selectors/services/getLamalAccidentOfSelectedFamilyMember'
import { setLamalAccidentWantedDate } from 'shared/store/services/coverage/coverage.slice'
import { fetchSummaryAndProducts } from 'shared/store/services/services.slice'

import LamalAccidentAttestation from '../LamalAccidentAttestation/LamalAccidentAttestation'
import LamalAccidentInfo from '../LamalAccidentInfo/LamalAccidentInfo'
import LamalAccidentLegalNotices from '../LamalAccidentLegalNotices/LamalAccidentLegalNotices'

interface LamalAccidentMainBlockProps {
    selectedPolicyNumber?: number
    setIsSubmitAllowed: (flag: boolean) => void
}

const LamalAccidentMainBlock = ({
    selectedPolicyNumber,
    setIsSubmitAllowed
}: LamalAccidentMainBlockProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { watch, setValue } = useFormContext()

    const hasAccident = useSelector(getLamalAccidentOfSelectedFamilyMember)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)

    const effectiveDate: string | undefined = watch('effectiveDate')

    useEffect(() => {
        dispatch(setLamalAccidentWantedDate(effectiveDate))
        if (selectedPolicyNumber && effectiveDate) {
            dispatch(
                fetchSummaryAndProducts({
                    policyNumber: selectedPolicyNumber,
                    year: new Date(effectiveDate).getFullYear()
                })
            )
        }
    }, [effectiveDate, selectedPolicyNumber])

    useEffect(() => {
        if (effectiveDate && hasAccident !== null && hasAccident !== undefined) {
            setValue('include', !hasAccident)
        }
        setIsSubmitAllowed(
            effectiveDate !== undefined && hasAccident !== null && hasAccident !== undefined
        )
    }, [hasAccident, effectiveDate])

    const loadStatus = () => {
        if (effectiveDate === undefined) return LoadingStatusEnum.OK
        return getLoadingStatusFromLoaderList([summaryLoadingStatus, productsLoaderStatus])
    }

    if (!effectiveDate || !selectedPolicyNumber) {
        return null
    }

    return (
        <AssuraLoadAndError
            status={loadStatus()}
            shouldDisplayContainer
            defaultReloadAction={() =>
                dispatch(
                    fetchSummaryAndProducts({
                        policyNumber: selectedPolicyNumber,
                        year: new Date(effectiveDate).getFullYear(),
                        reload: true
                    })
                )
            }
        >
            {hasAccident === null ? (
                <NoActiveContract />
            ) : (
                <>
                    <LamalAccidentInfo hasAccident={Boolean(hasAccident)} />
                    <>
                        <div className="headline m-top-48 m-bottom-32">
                            {t(
                                hasAccident
                                    ? 'SERVICES.COVERAGE_ACCIDENT_FORM_ACTION_REMOVE'
                                    : 'SERVICES.COVERAGE_ACCIDENT_FORM_ACTION_ADD'
                            )}
                        </div>
                        {hasAccident && <LamalAccidentAttestation />}
                        <LamalAccidentLegalNotices hasAccident={hasAccident ?? true} />
                    </>
                </>
            )}
        </AssuraLoadAndError>
    )
}

export default LamalAccidentMainBlock
