import './BackToScanContainerDesktop.css'

import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'

interface BackToScanContainerDesktopProps {
    items: JSX.Element[]
}

const BackToScanContainerDesktop = ({ items }: BackToScanContainerDesktopProps): JSX.Element => {
    const { t } = useTranslation()
    const { isMD, width } = useWindowSize()

    const itemsPerRowMD = 3
    const itemsPerRowXL = 4
    const minimumRows = 2

    const [increment, setIncrement] = useState(4)
    const [pagination, setPagination] = useState(8)

    const documents = items.slice(0, pagination)

    const getMore = () => {
        setPagination((prev) => prev + increment)
    }

    useEffect(() => {
        setIncrement(isMD ? itemsPerRowMD : itemsPerRowXL)
    }, [width])

    useEffect(() => {
        const itemsPerRow = increment === itemsPerRowMD ? itemsPerRowMD : itemsPerRowXL
        if (documents.length > itemsPerRow * minimumRows) {
            setPagination(Math.round(documents.length / itemsPerRow) * itemsPerRow)
        } else {
            setPagination(itemsPerRow * minimumRows)
        }
    }, [increment])

    return (
        <>
            <div className="back-to-scan-desktop-row">
                {documents.map((item, index) => (
                    <Fragment key={`item-desktop-${index}`}>{item}</Fragment>
                ))}
            </div>
            {items.length > documents.length && (
                <div className="d-flex justify-content-center align-items-center m-top-32">
                    <AssuraButton
                        text={t('COMMON.SEE_MORE')}
                        id="back-to-scan-desktop-more-button"
                        variant="primary"
                        onClick={() => getMore()}
                    />
                </div>
            )}
        </>
    )
}

export default BackToScanContainerDesktop
