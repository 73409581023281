import { useTranslation } from 'react-i18next'

import { RequestStatus } from 'core/enums/RequestStatus'

interface InsuranceCardOrderProps {
    status: RequestStatus
}

const InsuranceCardOrder = ({ status }: InsuranceCardOrderProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="requests-panel-main-block paragraph c-gray500">
            {status === RequestStatus.COMPLETED && (
                <div>{t('SERVICES.INSURANCE_CARD_ORDER_SUCCESS')}</div>
            )}
            {status === RequestStatus.PENDING && (
                <div>{t('SERVICES.INSURANCE_CARD_ORDER_PENDING')}</div>
            )}
            {status === RequestStatus.CANCELLED && (
                <div>{t('SERVICES.INSURANCE_CARD_ORDER_CANCELLED')}</div>
            )}
        </div>
    )
}
export default InsuranceCardOrder
