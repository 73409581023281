import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import { AssuraFilterDatas, AssuraTabData } from 'core/models/TabData'
import { DeductibleDisplayTemplateData } from 'modules/services/coverage/components/ModelDeductibleSelectDeductible/ModelDeductibleSelectDeductible'
import { ColorsEnum } from 'shared/components/AssuraTabDropdown/Icon'

import { formatNumber } from './TextUtils'

export const initialTabState: AssuraTabData = {
    active: false,
    label: '',
    selectedValue: null,
    uniqueKey: 0
}

export const setAssuraItemDataSelected = (
    data: AssuraTabData[],
    id: number
): [AssuraTabData, AssuraTabData[]] | undefined => {
    const current = getItemById(data, id)
    if (!current) return undefined
    clearSelection(data)
    current.active = true
    current.selectedValue = id
    return [current, data]
}

export const clearSelection = (array: AssuraTabData[]): void =>
    array.forEach((o) => {
        o.selectedValue = null
        o.active = false
    })

export const tabFilterToTabData = (
    input: AssuraFilterDatas,
    converter: (from: string) => number
): AssuraTabData => {
    return {
        label: input.label,
        uniqueKey: input.uniqueKey as number,
        selectedValue: converter(input.selectedValue as string),
        active: false,
        badge: undefined
    } as AssuraTabData
}

export const tabFilterToTab = (input: AssuraFilterDatas, index: number): AssuraTabData => {
    return {
        label: input.label,
        uniqueKey: index,
        selectedValue: input.selectedValue as number,
        active: false,
        badge: undefined
    } as AssuraTabData
}

export const personSummaryToTab = (input: PersonSummary, index: number): AssuraTabData => {
    return {
        label: input.firstName,
        uniqueKey: index,
        businessId: input.policyNumber,
        selectedValue: null,
        active: false,
        badge: undefined,
        leftItem: input.profilePicture
            ? {
                  value: input.profilePicture.userPhotoTitle,
                  imageUrl: input.profilePicture.userPhotoImage
              }
            : {
                  value: getInitials(input),
                  backgroundColor: ColorsEnum.gray300,
                  color: ColorsEnum.white
              }
    }
}

export const toTabDataItem = (input: string, index: number): AssuraTabData => {
    return {
        label: input,
        uniqueKey: index,
        selectedValue: null,
        active: false,
        badge: undefined
    }
}

export const deductibleToTab = (
    deductible: number,
    index: number,
    currentModel: string | null,
    modelDeductibles: ProductFranchise[],
    currentDeductible: number | null,
    label: string
): AssuraTabData => {
    return {
        label: formatNumber(deductible, false),
        uniqueKey: index,
        businessId: deductible,
        selectedValue: currentDeductible && currentDeductible === deductible ? index : null,
        active: currentDeductible ? currentDeductible === deductible : false,
        badge: undefined,
        templateData: {
            currentModel,
            modelDeductibles,
            deductible,
            currentDeductible,
            label
        } as DeductibleDisplayTemplateData
    }
}

export const deductiblesToTab = (
    deductible: number[],
    currentModel: string | null,
    modelDeductibles: ProductFranchise[],
    currentDeductible: number | null,
    label: string
): AssuraTabData[] =>
    deductible.map((item, index) =>
        deductibleToTab(item, index, currentModel, modelDeductibles, currentDeductible, label)
    )

export const stringArrayToTabDataArray = (people: string[]): AssuraTabData[] =>
    people.map((item, index) => toTabDataItem(item, index))

export const filterToTab = (filters: AssuraFilterDatas[]): AssuraTabData[] =>
    filters.map((item, index) => tabFilterToTab(item, index))

export const getSelectedTabFromFilter = (
    filters: AssuraFilterDatas[],
    selectedValue: string | number,
    converter: (from: string) => number
): AssuraTabData => {
    const value = filters.find((o) => o.selectedValue === selectedValue)
    if (!value) return initialTabState
    return tabFilterToTabData(value, converter)
}

export const getSelectedTabFromType = (
    data: AssuraTabData[],
    type: string | null
): AssuraTabData => {
    const typeIndex = type ? parseInt(type) : 0
    const value = data.find((o) => o.uniqueKey === (typeIndex as number))
    return value ? value : initialTabState
}

export const getSelectedTabFromIndex = (data: AssuraTabData[], index: number): AssuraTabData => {
    const tempTab = data.find((o) => o.uniqueKey === index)
    if (!tempTab) return initialTabState
    return tempTab
}

export const peopleToTab = (people: PersonSummary[]): AssuraTabData[] =>
    people ? people.map((item, index) => personSummaryToTab(item, index)) : []

export const getPersonTabFromPolicyNumber = (
    people: AssuraTabData[],
    policyNumber: number
): AssuraTabData => {
    const item = people.find((o) => o.businessId === policyNumber)
    if (!item) return initialTabState
    return item
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getItemById = (array: AssuraTabData[], id: number): AssuraTabData =>
    array.find((o) => o.uniqueKey === id)!

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getSelectedTab = (array: AssuraTabData[], item: AssuraTabData): AssuraTabData => {
    if (!array || array.length === 0 || !item) return item
    return array.find((o) => o.uniqueKey === item.uniqueKey)!
}

export const setInitialSelectedCollection = (
    array: AssuraTabData[],
    item: AssuraTabData
): AssuraTabData[] => {
    const selected = getSelectedTab(array, item)
    clearSelection(array)
    if (!selected) return []

    selected.active = true
    selected.selectedValue = selected.uniqueKey

    const output = [selected, ...array.filter((o) => o.uniqueKey !== selected.uniqueKey)]
    return output.sort((a, b) => a.uniqueKey - b.uniqueKey)
}

export const removeSelectedItemFromList = (
    array: AssuraTabData[],
    item: AssuraTabData
): AssuraTabData[] =>
    [...array.filter((o) => o.uniqueKey !== item.uniqueKey)].sort(
        (a, b) => a.uniqueKey - b.uniqueKey
    )

export const getInitials = (person: PersonSummary): string =>
    `${person.firstName.substring(0, 1).toUpperCase()}${person.lastName
        .substring(0, 1)
        .toUpperCase()}`
