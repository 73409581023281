import './TabInitials.css'

import { ColorsEnum } from './Icon'

type TabInitialsProps = {
    initials: string
    dataId: string
    backgroundColor?: ColorsEnum
    color?: ColorsEnum
}

const TabInitials = ({ ...props }: TabInitialsProps) => (
    <div
        data-testid={props.dataId}
        className={'tab-initials-container'}
        style={{ background: props.backgroundColor, color: props.color }}
    >
        <div className={'tab-initials-holder labelSmallMedium'}>
            <span className={`tab-initials `}>{props.initials}</span>
        </div>
    </div>
)
export default TabInitials

export const isInitials = (input: string): boolean => input.length === 2 && isNaN(Number(input))
