import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'

import { createSelector } from '@reduxjs/toolkit'

import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getProductsYearByPage } from './getProductsYearByPage'

export const getPersonDataForDocuments = createSelector(
    [getFamilySummaries, getProductsYearByPage],
    (familySummaries, selectedYear): EnveloppePerson[] => {
        return familySummaries[selectedYear]?.insuredPersons.map((person) => {
            return {
                policyNumber: person.policyNumber,
                personName: person.firstName,
                personLastName: person.lastName,
                personGender: person.gender
            }
        })
    }
)
