import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { profilePictureData } from 'core/api/FamilyGroup'
import { ProfilePicture } from 'core/models/familyGroup/ProfilePicture'

import { fetchProfilePicture, fetchProfilePictureSuccess } from './familyPictures.slice'

export function* fetchProfilePictureAsync(action: ReturnType<typeof fetchProfilePicture>) {
    try {
        const profilePictureResponse: ProfilePicture = yield call(
            profilePictureData,
            action.payload
        )

        yield put(
            fetchProfilePictureSuccess({
                policyNumber: action.payload,
                picture: profilePictureResponse
            })
        )
    } catch (e) {
        console.error('fetchProfilePictureAsync Error', e)
    }
}

function* fetchProfilePictureWatcher() {
    yield takeEvery(fetchProfilePicture.type, fetchProfilePictureAsync)
}

const watchers = [fork(fetchProfilePictureWatcher)]

export default watchers
