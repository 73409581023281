import { FetchQrCodeDatas, QrCode, QrCodePayload } from 'core/models/documents/QrCode'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

const INITIAL_STATE_QRCODES: Record<string, QrCode> = {}

export const qrCodesSlice = createSlice({
    name: 'qrCodes',
    initialState: INITIAL_STATE_QRCODES,
    reducers: {
        fetchQrCode: (state: Record<string, QrCode>, _action: PayloadAction<FetchQrCodeDatas>) => {
            return state
        },
        setQrCode: (state: Record<string, QrCode>, action: PayloadAction<QrCodePayload>) => {
            state[action.payload.documentId] = {
                data: action.payload.data,
                loadStatus: action.payload.loadStatus
            }
        }
    }
})

export const { fetchQrCode, setQrCode } = qrCodesSlice.actions

export const getQrCodes: Selector<RootState, Record<string, QrCode>> = (state) => state.qrCodes

export default qrCodesSlice.reducer
