/* eslint-disable sonarjs/cognitive-complexity */
import './Financial.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getFullFinancialData } from 'shared/store/selectors/getFullFinancialData'
import { fetchFinancial } from 'shared/store/services/financial/financial.slice'
import { getSelectedMemberPolicyNumber } from 'shared/store/services/services.slice'

import ConflictedFinancialData from './ConflictedFinancialData'
import FinancialData from './FinancialData'
import NoFinancialData from './NoFinancialData'

const Financial = (): JSX.Element | null => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)
    const selectedFullFinancialData = useSelector(getFullFinancialData)

    if (selectedPolicyNumber && selectedFullFinancialData) {
        const { financialDataLoadStatus, financialData, isPending } = selectedFullFinancialData
        const hasFinancialData = financialData && (financialData.iban || financialData.ccp)
        const shouldDisplayFinancialData = isPending || hasFinancialData
        const status = selectedFullFinancialData.wsStatus

        const reloadFinancialData = (selectedPolicyNumber: number): void => {
            dispatch(fetchFinancial({ selectedPolicyNumber, reload: true }))
        }

        return (
            <div className="d-flex personal-data-financial bg-white bc-gray100 flex-1">
                <div className="headline m-bottom-32" data-testid="personal-data-financial-title">
                    {t('PERSONAL_DATA.FINANCIAL_DATA_TITLE')}
                </div>
                <AssuraLoadAndError
                    status={financialDataLoadStatus ?? LoadingStatusEnum.ERROR}
                    defaultReloadAction={() => reloadFinancialData(selectedPolicyNumber)}
                    activityIndicatorSize={24}
                >
                    {shouldDisplayFinancialData ? (
                        <FinancialData financialData={financialData} isPending={isPending} />
                    ) : status === HttpResponseEnum.NOT_FOUND ? (
                        <NoFinancialData />
                    ) : status === HttpResponseEnum.CONFLICT ? (
                        <ConflictedFinancialData />
                    ) : (
                        <></>
                    )}
                </AssuraLoadAndError>
            </div>
        )
    } else {
        return null
    }
}

export default Financial
