import { AssuraTabData } from 'core/models/TabData'

type AssuraTabMainContentProps = {
    active: boolean
    data: AssuraTabData
}

export const AssuraTabMainContent = ({ active, data }: AssuraTabMainContentProps) => (
    <p
        className={`headlineSmall${
            active ? 'Medium' : 'Regular'
        } text-center m-0 max-2-text-lines ellipsis`}
    >
        {data.label}
    </p>
)
