import { Target } from 'core/enums/Targets'
import { isEmpty } from 'core/helpers/ArrayHelper'
import { getContactLanguageSuffix } from 'core/helpers/FamilyGroupHelper'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { SymptomCheckerSplicer } from 'core/helpers/SymptomCheckerSplicer'
import { NewsCms } from 'core/models/cms/NewsCms'
import { News } from 'core/models/News'

import { createSelector } from '@reduxjs/toolkit'

import { getDigcliparameters } from '../digcliParameters/digcliParameters.slice'
import { getLang } from '../general/general.slice'
import { getConnectedUserInfos } from './getConnectedUserInfos'
import { getOrderedActiveNews } from './getOrderedActiveNews'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getNews = createSelector(
    [
        getOrderedActiveNews,
        getDigcliparameters,
        getLang,
        getShortContactLanguage,
        getConnectedUserInfos
    ],
    (news, digcliParameters, lang, shortContactLanguage, connectedUserInfos) => {
        const canton: string = connectedUserInfos?.address?.canton ?? ''
        const newsToShow: NewsCms[] = news
            .filter((newsItem) => isEmpty(newsItem.target) || newsItem.target?.includes(Target.EC))
            .filter(
                (newsItem) =>
                    isEmpty(newsItem.localization) || newsItem.localization?.includes(canton)
            )

        const localizedNews = newsToShow.map((newsItem) => {
            const contactLanguageLinkUrlEc: string | null = newsItem[
                `link_url_ec${getContactLanguageSuffix(shortContactLanguage)}` as keyof NewsCms
            ] as string | null

            const contactLanguageLinkUrl: string | null = newsItem[
                `link_url${getContactLanguageSuffix(shortContactLanguage)}` as keyof NewsCms
            ] as string | null

            const localizeNewsItem = getLocalizedObject(newsItem, lang) as News

            const linkUrlEc = localizeNewsItem.link_url_ec ?? contactLanguageLinkUrlEc
            const linkUrl = localizeNewsItem.link_url ?? contactLanguageLinkUrl

            localizeNewsItem.link_url = linkUrlEc ?? linkUrl
            localizeNewsItem.ec_link = newsItem.ec_link?._id

            return localizeNewsItem
        })

        SymptomCheckerSplicer(
            digcliParameters?.isSymptomCheckerCustomerPortalActivated,
            localizedNews
        )

        return localizedNews
    }
)
