import { ProductTypeEnum } from 'core/enums/Product'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { getCurrentYear } from 'core/utils/dateUtils'
import { getFamilySummaries } from 'shared/store/familySummaries/familySummaries.slice'
import { getLamalAccidentWantedDate } from 'shared/store/services/coverage/coverage.slice'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedMemberPolicyNumber } from '../../services/services.slice'

export const getLamalAccidentOfSelectedFamilyMember = createSelector(
    [getFamilySummaries, getSelectedMemberPolicyNumber, getLamalAccidentWantedDate],
    (familySummaries, selectedMemberPolicyNumber, wantedDate) => {
        if (wantedDate) {
            const summaryResult = familySummaries[wantedDate.getFullYear()]

            if (summaryResult) {
                const selectedFamilyMember = summaryResult.insuredPersons.find(
                    (person) => person.policyNumber === selectedMemberPolicyNumber
                )

                if (selectedFamilyMember) {
                    const lamalList = selectedFamilyMember.productsPerson?.products?.filter(
                        (productPerson) =>
                            (wantedDate ? new Date(productPerson.startDate) <= wantedDate : true) &&
                            productPerson.type === ProductTypeEnum.LAMAL
                    )

                    return HasLAMalAccident(lamalList)
                }
            }
        }
        return undefined
    }
)

export const getLamalAccidentOfSelectedFamilyMemberToday = createSelector(
    [getFamilySummaries, getSelectedMemberPolicyNumber],
    (familySummaries, selectedMemberPolicyNumber) => {
        const summaryResult = familySummaries[getCurrentYear()]

        if (summaryResult) {
            const selectedFamilyMember = summaryResult.insuredPersons.find(
                (person) => person.policyNumber === selectedMemberPolicyNumber
            )

            if (selectedFamilyMember) {
                const lamalList = selectedFamilyMember.productsPerson?.products?.filter(
                    (productPerson) =>
                        new Date(productPerson.startDate) <= new Date() &&
                        productPerson.type === ProductTypeEnum.LAMAL
                )

                return HasLAMalAccident(lamalList)
            }
        }
        return undefined
    }
)

const HasLAMalAccident = (lamalList: ProductPerson[] | undefined): boolean | null => {
    if (lamalList && lamalList.length > 0) {
        const sorted = SortLAMalByStartDate(lamalList)
        return sorted[0].accident
    }
    return null
}

const SortLAMalByStartDate = (lamalList: ProductPerson[]): ProductPerson[] => {
    return lamalList.sort(
        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    )
}
