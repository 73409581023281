import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { Enveloppe } from 'core/models/enveloppes/Enveloppe'
import { ScanDeviceWithId } from 'core/models/enveloppes/ScanDevice'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface DataUploadFiles {
    name: string
    policyNumber: string
    batchNumber?: string
    pdfFiles: DroppedFile[]
}

export interface DeleteEnveloppePayload {
    batchNumber: string
    sourceIsBackToScan?: boolean
}

export interface FileName {
    fileName: string
}

export interface DataUploadEnveloppe {
    clientEnveloppeId: string
    name: string
    scanDevice: ScanDeviceWithId
    documents: FileName[]
    policeNumber: string
    originalBatchNumber?: string
}

export interface EnveloppesState {
    enveloppes: Enveloppe[]
    enveloppesLoadingStatus: LoadingStatusEnum
    selectedEnveloppeId: string
    deleteEnveloppeStatus: LoadingStatusEnum
    submitEnveloppeStatus: LoadingStatusEnum
    rescanImagesSelectedPolicyNumber: number
    rescanImagesBatchNumber?: string
}

const INITIAL_STATE_ENVELOPPES: EnveloppesState = {
    enveloppes: [],
    enveloppesLoadingStatus: LoadingStatusEnum.LOADING,
    selectedEnveloppeId: '',
    deleteEnveloppeStatus: LoadingStatusEnum.OK,
    submitEnveloppeStatus: LoadingStatusEnum.OK,
    rescanImagesSelectedPolicyNumber: 0
}

export const enveloppesSlice = createSlice({
    name: 'enveloppes',
    initialState: INITIAL_STATE_ENVELOPPES,
    reducers: {
        onFetchEnveloppes: (state: EnveloppesState) => {
            return state
        },
        fetchEnveloppesSuccess: (state: EnveloppesState, action: PayloadAction<Enveloppe[]>) => {
            state.enveloppes = action.payload
        },
        setEnveloppesLoadingStatus: (
            state: EnveloppesState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.enveloppesLoadingStatus = action.payload
        },
        setSelectedEnveloppeId: (state: EnveloppesState, action: PayloadAction<string>) => {
            state.selectedEnveloppeId = action.payload
        },
        onDeleteEnveloppe: (
            state: EnveloppesState,
            _action: PayloadAction<DeleteEnveloppePayload>
        ) => {
            return state
        },
        setDeleteEnveloppeStatus: (
            state: EnveloppesState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.deleteEnveloppeStatus = action.payload
        },
        onSubmitEnveloppe: (state: EnveloppesState, _action: PayloadAction<DataUploadFiles>) => {
            return state
        },
        setSubmitEnveloppeStatus: (
            state: EnveloppesState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.submitEnveloppeStatus = action.payload
        },
        setRescanImagesSelectedPolicyNumber: (
            state: EnveloppesState,
            action: PayloadAction<number>
        ) => {
            state.rescanImagesSelectedPolicyNumber = action.payload
        },
        setRescanImagesBatchNumber: (
            state: EnveloppesState,
            action: PayloadAction<string | undefined>
        ) => {
            state.rescanImagesBatchNumber = action.payload
        }
    }
})

export const {
    onFetchEnveloppes,
    fetchEnveloppesSuccess,
    setEnveloppesLoadingStatus,
    setSelectedEnveloppeId,
    onDeleteEnveloppe,
    setDeleteEnveloppeStatus,
    onSubmitEnveloppe,
    setSubmitEnveloppeStatus,
    setRescanImagesSelectedPolicyNumber,
    setRescanImagesBatchNumber
} = enveloppesSlice.actions

export const getEnveloppesState: Selector<RootState, Enveloppe[]> = (state: RootState) =>
    state.enveloppes.enveloppes

export const getSelectedEnveloppeIdState: Selector<RootState, string> = (state: RootState) =>
    state.enveloppes.selectedEnveloppeId

export const getEnveloppeLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.enveloppes.enveloppesLoadingStatus

export const getSubmitEnveloppeStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.enveloppes.submitEnveloppeStatus

export const getRescanImagesSelectedPolicyNumber: Selector<RootState, number> = (
    state: RootState
) => state.enveloppes.rescanImagesSelectedPolicyNumber

export const getRescanImagesBatchNumber: Selector<RootState, string | undefined> = (
    state: RootState
) => state.enveloppes.rescanImagesBatchNumber

export default enveloppesSlice.reducer
