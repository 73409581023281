import './LamalSpecificationsCard.css'

import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Doctor } from 'core/models/familyGroup/Doctor'
import useWindowSize from 'core/services/useWindowSize'
import doctorIcon from 'shared/assets/images/doctor.png'
import expertIcon from 'shared/assets/images/expert.png'
import preventoMedIcon from 'shared/assets/images/prevento_med.png'

import LamalDocumentsCard from '../LamalDocumentsCard/LamalDocumentsCard'
import Accident from './Accident'
import DoctorLine from './DoctorLine'
import Header from './Header'
import Pharmacy from './Pharmacy'
import Telemedecine from './Telemedecine'

interface LamalSpecificationsCardProps {
    accident: boolean
    code: string
    doctor: Doctor | null | undefined
    choiceOfDoctor: string | undefined
    choiceOfPharmacy: string | undefined
}

export interface ConditionalInformations {
    code: string
    isDisplayingTelemedecineSpecifications: boolean
    telemedecineIllustration?: string
    telemedecineTitle?: string
    telemedecinePhone?: string
    isPharmaciesPdfListVisible: boolean
    isDisplayingDoctor?: boolean
}

interface SpecificationLineProps {
    id: string
    code: string
    illustration?: string
    informationsContainer: ReactNode
    linkContainer?: ReactNode
    isNotDisplayingHorizontalSeparator?: boolean
}
export const SpecificationLine = (props: SpecificationLineProps): JSX.Element => {
    return (
        <div className="specification-line">
            <div className="d-flex justify-content-between align-items-center">
                {props.illustration && (
                    <img
                        src={props.illustration}
                        className="illustration"
                        alt={`${props.illustration}-illustration`}
                        width="48"
                        height="48"
                    />
                )}
                <div
                    data-testid={`lamal-specification-${props.id}`}
                    className="d-flex flex-column justify-content-center flex-fill"
                >
                    {props.informationsContainer}
                </div>
                {props.linkContainer && (
                    <div className="d-flex align-items-center m-left-16 lamal-link-container">
                        {props.linkContainer}
                    </div>
                )}
            </div>
            <div
                className="horizontal-separator bg-gray100"
                style={{ display: props.isNotDisplayingHorizontalSeparator ? 'none' : 'flex' }}
            />
        </div>
    )
}

export const displayConditionalInformations = (code: string): ConditionalInformations => {
    const { t } = useTranslation()


    if (code === 'PRV') {
        return {
            code,
            isDisplayingTelemedecineSpecifications: true,
            telemedecineIllustration: preventoMedIcon,
            telemedecineTitle: t('COVERAGE.PRV.TITLE'),
            telemedecinePhone: t('COVERAGE.PRV.SUBTITLE'),
            isPharmaciesPdfListVisible: false,
            isDisplayingDoctor: true
        }
    } else if (code === 'ACL') {
        return {
            code,
            isDisplayingTelemedecineSpecifications: true,
            telemedecineIllustration: doctorIcon,
            telemedecineTitle: t('COVERAGE.MEDGATE_TITLE'),
            telemedecinePhone: t('COVERAGE.MEDGATE_PHONE_NUMBER'),
            isPharmaciesPdfListVisible: true,
            isDisplayingDoctor: false
        }
    } else if (code === 'PAM' || code === 'PM') {
        return {
            code,
            isDisplayingTelemedecineSpecifications: true,
            telemedecineIllustration: expertIcon,
            telemedecineTitle: t('COVERAGE.QUALIMED_TITLE'),
            telemedecinePhone: t('COVERAGE.QUALIMED_PHONE_NUMBER'),
            isPharmaciesPdfListVisible: false,
            isDisplayingDoctor: true
        }
    } else if (code === 'RPH' || code === 'RMd') {
        return {
            code,
            isDisplayingTelemedecineSpecifications: false,
            isPharmaciesPdfListVisible: true,
            isDisplayingDoctor: true
        }
    } else {
        return {
            code,
            isDisplayingTelemedecineSpecifications: false,
            isPharmaciesPdfListVisible: false,
            isDisplayingDoctor: true
        }
    }
}

const LamalSpecificationsCard = ({
    accident,
    code,
    doctor,
    choiceOfDoctor,
    choiceOfPharmacy
}: LamalSpecificationsCardProps): JSX.Element => {
    const { width } = useWindowSize()

    const [isRecto, setIsRecto] = useState(true)
    const [cardHeight, setCardHeight] = useState(0)
    const cardRef = useRef<HTMLDivElement>(null)

    const switchCard = () => {
        setIsRecto((prev) => !prev)
    }

    useLayoutEffect(() => {
        const card = cardRef.current
        if (card && isRecto) {
            setCardHeight(card.clientHeight)
        }
    }, [isRecto, width])

    return (
        <>
            <div className="card-container d-flex flex-column flex-fill bg-gray20">
                {isRecto ? (
                    <div ref={cardRef}>
                        <Header switchCard={switchCard} />
                        <DoctorLine doctor={doctor} code={code} choiceOfDoctor={choiceOfDoctor} />
                        <Telemedecine code={code} />
                        <Pharmacy code={code} choiceOfPharmacy={choiceOfPharmacy} />
                        <div className="d-flex justify-content-between lamal-specification-card-footer">
                            <Accident accident={accident} />
                        </div>
                    </div>
                ) : (
                    <LamalDocumentsCard switchCard={switchCard} cardHeight={cardHeight} />
                )}
            </div>
        </>
    )
}

export default LamalSpecificationsCard
