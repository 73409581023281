import { PhoneDatas } from 'core/models/familyGroup/PhoneDatas'

// Order: Numéro mobile -> Numéro fixe -> Unknown

export const extractDoctorPhone = (phones?: PhoneDatas[] | null): string | undefined => {
    if(phones) {
        const mobilePhone = phones.find(phone => phone.type === 'mobile')
        if(mobilePhone && mobilePhone.phoneNumber) return mobilePhone.phoneNumber

        const fixedPhone = phones.find(phone => phone.type === 'fixed')
        if(fixedPhone && fixedPhone.phoneNumber) return fixedPhone.phoneNumber

        const unknownPhone = phones.find(phone => phone.type === 'unknown')
        if(unknownPhone && unknownPhone.phoneNumber) return unknownPhone.phoneNumber
    }

    return undefined
}
