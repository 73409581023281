import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { DocumentFile } from 'core/models/DocumentFile'
import { DocumentsDataWS, DocumentsGroup } from 'core/models/documents/Document'
import { UnreadDocument } from 'core/models/documents/UnReadDocuments'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface FetchUnreadDocumentsPayload {
    shouldUpdateLoadStatus?: boolean
}

export interface SelectedDocument {
    documentId: string
    documentsGroup: DocumentsGroup
    isRead: boolean
    reload?: boolean
}

export interface DocumentFileDatas {
    documentId: string
    documentFile: DocumentFile
}

export interface DocumentsState {
    documentFiles: Record<string, DocumentFile>
    unreadDocuments: UnreadDocument[]
    unreadDocumentsLoadingStatus: LoadingStatusEnum
    policyDocuments: DocumentsDataWS | null
    policyDocumentLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_DOCUMENTS: DocumentsState = {
    documentFiles: {},
    unreadDocuments: [],
    unreadDocumentsLoadingStatus: LoadingStatusEnum.OK,
    policyDocuments: null,
    policyDocumentLoadingStatus: LoadingStatusEnum.OK
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState: INITIAL_STATE_DOCUMENTS,
    reducers: {
        fetchDocumentFile: (state: DocumentsState, _action: PayloadAction<SelectedDocument>) => {
            return state
        },
        setDocumentFile: (state: DocumentsState, action: PayloadAction<DocumentFileDatas>) => {
            state.documentFiles[action.payload.documentId] = {
                base64: action.payload.documentFile.base64,
                loadStatus: action.payload.documentFile.loadStatus
            }
        },
        fetchUnreadDocuments: (
            state: DocumentsState,
            _action: PayloadAction<FetchUnreadDocumentsPayload>
        ) => {
            return state
        },
        setUnreadDocuments: (state: DocumentsState, action: PayloadAction<UnreadDocument[]>) => {
            state.unreadDocuments = action.payload
        },
        setUnreadDocumentsLoadingStatus: (
            state: DocumentsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.unreadDocumentsLoadingStatus = action.payload
        },
        fetchPolicyDocumentList: (state: DocumentsState) => {
            return state
        },
        fetchPolicyDocument: (state: DocumentsState, _action: PayloadAction<string>) => {
            return state
        },
        setPolicyDocuments: (state: DocumentsState, action: PayloadAction<DocumentsDataWS>) => {
            state.policyDocuments = action.payload
        },
        setPolicyDocumentLoadingStatus: (
            state: DocumentsState,
            action: PayloadAction<LoadingStatusEnum>
        ) => {
            state.policyDocumentLoadingStatus = action.payload
        }
    }
})

export const {
    fetchDocumentFile,
    setDocumentFile,
    fetchUnreadDocuments,
    setUnreadDocuments,
    fetchPolicyDocumentList,
    setUnreadDocumentsLoadingStatus,
    fetchPolicyDocument,
    setPolicyDocuments,
    setPolicyDocumentLoadingStatus
} = documentsSlice.actions

export const getDocumentFiles: Selector<RootState, Record<number, DocumentFile>> = (state) =>
    state.documents.documentFiles

export const getUnreadDocuments: Selector<RootState, UnreadDocument[]> = (state: RootState) =>
    state.documents.unreadDocuments

export const getUnreadDocumentsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.documents.unreadDocumentsLoadingStatus

export const getPolicyDocumentLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.documents.policyDocumentLoadingStatus

export const getPolicyDocumentList: Selector<RootState, DocumentsDataWS | null> = (
    state: RootState
) => state.documents.policyDocuments

export default documentsSlice.reducer
