
import { Children } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface FormBlockProps {
    titleTestId: string
    title?: string
    children?: React.ReactNode | React.ReactNode[]
    childrenMargin?: string
    childrenTopMargin?: string
    frameMarginBottom?: string
}

const FormBlock = ({ titleTestId, title, children, childrenMargin, childrenTopMargin, frameMarginBottom }: FormBlockProps) => {
    const arrayChildren = Children.toArray(children)

    return (
        <>
            {title && (
                <Container className="ar-block-title p-0 m-bottom-32 noborder">
                    <Row className="justify-content-start m-bottom-32">
                        <Col>
                            <div className="headline" data-testid={`services.${titleTestId}`}>
                                {title}
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
            {arrayChildren && (
                <Container className={`ar-block bg-white bc-gray100 p-top-32 p-bottom-32 ${frameMarginBottom ? frameMarginBottom : 'm-bottom-48'}`}>
                    {Children.map(arrayChildren, (child: React.ReactNode, index: number) => {
                        return (
                            <Row
                                key={index}
                                className={`justify-content-start ${index < arrayChildren.length - 1 ? childrenMargin : ''
                                    } ${index > 0 && index < arrayChildren.length - 1 ? childrenTopMargin : ''}     `}
                            >
                                <Col className=' m-left-16 m-right-16' >{child}</Col>
                            </Row>
                        )
                    })}
                </Container>
            )}
        </>
    )
}

export default FormBlock
