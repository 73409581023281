import { User, UserManager, UserManagerSettings } from 'oidc-client-ts'

export interface AuthenticationOptions {
    authority: string
    client_id: string
    redirect_uri: string
    scope: string
    post_logout_redirect_uri: string
    signedInCallback: (user: User) => void
    silentRenewError?: () => void
    ui_locales?: string
    loadUserInfo?: boolean
}

const REFRESH_INTERVAL = 5 * 60 * 1000
export class AuthenticationService {
    options: AuthenticationOptions
    userManager: UserManager
    User: User | undefined
    interval: NodeJS.Timeout | undefined

    constructor(options: AuthenticationOptions) {
        this.options = options

        const config: UserManagerSettings = {
            authority: this.options.authority,
            client_id: this.options.client_id,
            redirect_uri: this.options.redirect_uri,
            response_type: 'code',
            scope: this.options.scope,
            post_logout_redirect_uri: this.options.post_logout_redirect_uri,
            automaticSilentRenew: true,
            ui_locales: this.options.ui_locales,
            loadUserInfo: this.options.loadUserInfo
        }

        this.userManager = new UserManager(config)
    }

    public setup(): void {
        this.userManager.events.addSilentRenewError(() => {
            this.options?.silentRenewError?.()
        })
        this.userManager.getUser().then((user: User | null) => {
            if (user) {
                this.User = user
                this.options.signedInCallback(user)
            } else {
                setTimeout(() => {
                    this.userManager.signinRedirect()
                }, 500)
            }
        })
    }

    public setupRefreshToken(): void {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(() => {
            this.renewToken().then((user: User | null) => {
                if (user) this.User = user
            })
        }, REFRESH_INTERVAL)
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser()
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect()
    }

    public renewToken(): Promise<User | null> {
        return this.userManager.signinSilent()
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect()
    }

    public getUserUtn(): number | undefined {
        const sub: string | undefined = this.User?.profile?.sub
        let utn: number | undefined
        if (sub) {
            const subToNumber = parseInt(sub, 10)
            utn = isNaN(subToNumber) ? undefined : subToNumber
        }
        return utn
    }
}
