import { AssuraDocumentType } from 'core/enums/DocumentType'

export const mapDocumentTypeIcon = (status: AssuraDocumentType | null): string => {
    switch (status) {
        case AssuraDocumentType.JPG:
        case null:
            return 'assura-photo'
        case AssuraDocumentType.PDF:
            return 'assura-attachment'
        default:
            return 'assura-photo'
    }
}
