import { Enveloppe } from 'core/models/enveloppes/Enveloppe'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'

import { createSelector } from '@reduxjs/toolkit'

import { getEnveloppesState } from '../enveloppes/enveloppes.slice'
import { getFamilySummaries } from '../familySummaries/familySummaries.slice'
import { getProductsYearByPage } from './getProductsYearByPage'

export interface EnveloppeAndSummary {
    enveloppes: Enveloppe[]
    summary: Record<number, SummaryResult>
    selectedYear: number
}

export const getEnveloppesAndSummary = createSelector(
    [getEnveloppesState, getFamilySummaries, getProductsYearByPage],
    (enveloppes, familySummaries, year): EnveloppeAndSummary => {
        return {
            enveloppes: enveloppes,
            summary: familySummaries,
            selectedYear: year
        }
    }
)
