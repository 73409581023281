import { AccidentReportModel } from '../../../../core/models/services/report/AccidentReportModel'

const defaultDate = new Date('01.01.2023')
const defaultDateEnd = new Date('06.01.2023')

export const defaultMockValues: AccidentReportModel = {
    // Personal Data
    policyNumber: 1601776,
    email: 'test@me.com',
    phoneNumber: '+41 76 555 55 55',
    coverage: 0,
    employmentStatus: 'EMPLOYED',
    weeklyHoursWork: 8,
    stillUnemployed: 'stillUnemployedYes',

    // Employment
    profession: 'analyst',
    employer: 'analytics INC.',
    employerInsurance: 'ASSURA',
    employerInsuranceKnow: 'employerInsuranceKnowYes',
    employerInsuranceIncidentNumber: '234235',
    unemploymentBenefits: 'unemploymentBenefitsYes',
    unemploymentStartDate: defaultDate,
    unemploymentEndDate: defaultDateEnd,

    // AccidentDetailsForm
    accidentDate: defaultDate,
    accidentTime: new Date(),
    accidentLocation: 558600151,
    accidentLocationLabel: '1004 Lausanne',
    cbstandard: true,
    cbdental: true,
    cbcirculation: true,
    eating: 'eatingYes',
    accidentCause: 'BLABLA',
    training: 'trainingYes',
    school: 'schoolYes',
    work: 'workYes',
    third: 'thirdYes',
    thirdPartyAddress: 'Address 12 1009 pully',
    thirdPartyInsurance: 'AXA',
    police: 'policeYes',
    policeReport: 'undefined',
    witnesses: 'mister witness',

    // WoundForm
    bodyPartInjured: 'All arms',
    injuryNature: 'Broken Arm',
    firstDoctor: 'super doctor',
    processStartDate: defaultDate,
    processOver: 'processOverNo',
    processDoctor: 'I am the process doctor',
    hospitalization: 'hospitalizationYes',
    stillHospitalized: 'stillHospitalizedYes',
    hospitalizationStartDate: defaultDate,
    hospitalizationEndDate: defaultDateEnd,

    // Work Incapacity
    workIncapacity: 'workIncapacityYes',
    incapacityStartDate: defaultDate,
    rateIncapacity: 85,
    backToWork: 'backToWorkYes',
    incapacityEndDate: defaultDateEnd,

    // DentalInjuriesForm
    foodType: 'noddles',
    unusualEvent: 'eating noddles with big fork',
    strange: 'it was',
    possession: 'i was possessed',
    manufactured: 'manufacturedYes',
    acquisition: 'acquisitionYes',
    bill: 'billYes',
    productBill: undefined,
    announce: 'announceYes',
    announceDetails: 'announceDetails details',
    sellerInsurance: 'WINTENTHUR',

    // VehiclesForm
    injured: 'injured-driver',
    vehicleDriver: 'I was NOT the driver',
    owner: 'ownerYes',
    ownerInsurance: 'VAUDOISE',
    ownerCarPlates: 'VF 4rt345534',
    otherVehicleDriver: 'dsdv',
    otherVehicleOwner: 'ME',
    otherVehicleInsurance: 'AXA',
    otherVehiclePlates: 'VD 3425345',
    otherInsuranceDeclared: 'otherInsuranceDeclaredYes',
    otherInsuranceNumber: '75496541',
    friendlyArrangement: 'friendlyArrangementNo',
    friendlyDocument: undefined,

    // AccidentInsuranceForm
    otherInsurance: 'otherInsuranceYes',
    otherInsuranceName: 'AXA',
    otherInsuranceCoverage: 'FULL',
    otherDocuments: undefined,
    cbconfirm: true
}
