import './CommunicationsRow.css'

import { DocumentRow } from 'core/models/documents/DocumentRow'
import useWindowSize from 'core/services/useWindowSize'
import concatClassNames from 'core/utils/classNameUtils'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import ReadStatusField from 'shared/components/AssuraTable/Fields/ReadStatusField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

import CommunicationsRowMobile from './CommunicationsRowMobile'

interface CommunicationsRowProps {
    row: DocumentRow
}

const CommunicationsRow = ({ row }: CommunicationsRowProps): JSX.Element => {
    const { isXL } = useWindowSize()

    const unreadClass = row.isRead ? 'label' : 'labelMedium c-black'
    const dateClass = concatClassNames(['communications-date-field', unreadClass])
    const documentNameClass = concatClassNames(['communications-document-name-field', unreadClass])
    const personNameClass = concatClassNames(['communications-person-name', unreadClass])

    return isXL ? (
        <>
            <ReadStatusField isRead={row.isRead} rowId={row.documentId} />
            <DateField date={row.creationDate} rowId={row.documentId} classNames={dateClass} />
            <TextField
                label={row.documentName}
                rowId={row.documentId}
                classNames={documentNameClass}
                fieldId="documentName"
            />
            <TextField
                label={row.insuredPersonName}
                rowId={row.documentId}
                classNames={personNameClass}
                fieldId="insured-person"
            />
            <GoToField rowId={row.documentId} marginLeft={40} />
        </>
    ) : (
        <CommunicationsRowMobile row={row} />
    )
}

export default CommunicationsRow
