import { useSelector } from 'react-redux'

import { DocumentRow } from 'core/models/documents/DocumentRow'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import {
    fetchClaimsSettlement,
    getClaimsSettlementLoadingStatus,
    getSelectedClaimsSettlement
} from 'shared/store/claimsSettlement/claimsSettlement.slice'
import { useAppDispatch } from 'shared/store/store'

import PaymentDocumentPanelBVRBlock from '../PaymentDocumentPanelBVRBlock/PaymentDocumentPanelBVRBlock'
import ClaimsSettlementPanelCosts from './ClaimsSettlementPanelCosts'
import ClaimsSettlementPanelReceiptList from './ClaimsSettlementPanelReceiptList'

interface ClaimsSettlementPanelDetailsProps {
    selectedDocument: DocumentRow
}
const ClaimsSettlementPanelDetails = ({
    selectedDocument
}: ClaimsSettlementPanelDetailsProps): JSX.Element => {
    const dispatch = useAppDispatch()

    const selectedClaimsSettlement = useSelector(getSelectedClaimsSettlement)
    const claimsSettlementLoadingStatus = useSelector(getClaimsSettlementLoadingStatus)

    const shouldDisplayBVRBlock =
        selectedDocument?.fullyPaid === false &&
        selectedDocument.inFavour === 'STATEMENTS_VALUE_TYPE_ASSURA' &&
        !selectedDocument.isMigrated &&
        !selectedDocument.canceled &&
        selectedDocument.bvrReference

    return (
        <AssuraLoadAndError
            status={claimsSettlementLoadingStatus}
            defaultReloadAction={() => {
                dispatch(fetchClaimsSettlement(selectedDocument.claimsSettlementId as string))
            }}
        >
            {selectedClaimsSettlement && (
                <div
                    className="claims-settlement-panel-details-container"
                    data-testid="claims-settlement-panel-details"
                >
                    <div className="claims-settlement-panel-adaptive-padding-horizontal d-flex flex-column  p-top-40 p-bottom-40 bg-white">
                        <ClaimsSettlementPanelCosts
                            selectedClaimsSettlement={selectedClaimsSettlement}
                            isSelectedDocumentRead={selectedDocument.isRead}
                        />
                        {shouldDisplayBVRBlock && (
                            <div>
                                <div className="claims-settlement-panel-details-separator horizontal-separator bg-gray100" />
                                {/*bvrReference inside claimsSettlement is always null. We need to use the one inside document*/}
                                <PaymentDocumentPanelBVRBlock selectedDocument={selectedDocument} />
                            </div>
                        )}
                    </div>
                    <ClaimsSettlementPanelReceiptList
                        selectedClaimsSettlement={selectedClaimsSettlement}
                    />
                </div>
            )}
        </AssuraLoadAndError>
    )
}

export default ClaimsSettlementPanelDetails
