import './PaymentFrequencySelect.css'

import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ProductPeriodicity } from 'core/enums/Product'
import { getPaymentFrequencyStartDate } from 'core/helpers/services/PaymentFrequencyHelper'
import {
    PaymentFrequencyPeriodicityAndRebate,
    PaymentFrequencyRHF,
    Periodicity
} from 'core/models/services/financial/PaymentFrequency'

import PaymentFrequencySelectItem from '../PaymentFrequencySelectItem/PaymentFrequencySelectItem'

interface PaymentFrequencySelectProps {
    currentPeriodicity?: ProductPeriodicity
    servicesPeriodicity: Periodicity[]
}

const periodicityAndRebateList: PaymentFrequencyPeriodicityAndRebate[] = [
    {
        periodicity: ProductPeriodicity.ANNUAL,
        rebate: 2
    },
    {
        periodicity: ProductPeriodicity.BIANNUAL,
        rebate: 1
    },
    {
        periodicity: ProductPeriodicity.QUARTERLY,
        rebate: 0
    },
    {
        periodicity: ProductPeriodicity.MONTHLY,
        rebate: 0
    }
]

const PaymentFrequencySelect = ({
    currentPeriodicity,
    servicesPeriodicity
}: PaymentFrequencySelectProps): JSX.Element => {
    const { t } = useTranslation()

    const { watch, setValue } = useFormContext<PaymentFrequencyRHF>()
    const selectedPeriodicity = watch('periodicity')

    useEffect(() => {
        if (selectedPeriodicity && servicesPeriodicity.length) {
            setValue(
                'changeDate',
                getPaymentFrequencyStartDate(selectedPeriodicity, servicesPeriodicity)
            )
        }
    }, [selectedPeriodicity, servicesPeriodicity])

    return (
        <div className="d-flex flex-column justify-content-center align-items-start p-32 bc-gray100 border-solid-width-1 bg-white">
            <div className="m-bottom-24 paragraphMedium">
                {t('SERVICES.FINANCIAL_PAYMENT_FREQUENCY_SELECT_TITLE')}
            </div>
            <div className="m-bottom-32 paragraphSmallLight">
                {t('SERVICES.FINANCIAL_PAYMENT_FREQUENCY_SELECT_TEXT')}
            </div>
            <div className="services-payment-frequency-select-items">
                {periodicityAndRebateList.map((item) => (
                    <PaymentFrequencySelectItem
                        key={item.periodicity}
                        periodicity={item.periodicity}
                        rebate={item.rebate}
                        isSelected={selectedPeriodicity === item.periodicity}
                        isCurrent={currentPeriodicity === item.periodicity}
                    />
                ))}
            </div>
        </div>
    )
}

export default PaymentFrequencySelect
