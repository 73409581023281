import './NoLastDocuments.css'

import { assets } from 'core/utils/assetUtils'

interface NoLastDocumentsProps {
    testId: string
    message: string
}

const NoLastDocuments = ({ testId, message }: NoLastDocumentsProps): JSX.Element => {
    return (
        <tr className="no-last-documents-row">
            <td className="no-last-documents-container" data-testid={`no-last-documents-${testId}`}>
                <img src={assets.document_error} alt="illustration" />
                <div className="paragraphSmall text-center">{message}</div>
            </td>
        </tr>
    )
}

export default NoLastDocuments
