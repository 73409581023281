import './SelectRHF.css'

import { Control, Controller, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AssuraSelect, { AssuraSelectProps } from 'shared/components/AssuraSelect/AssuraSelect'
import { withLabel } from 'shared/hoc/WithLabel'

export interface SelectRHFProps<T> extends Omit<AssuraSelectProps<T>, 'value' | 'onChange'> {
    name: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<FieldValues, any>
    register?: UseFormRegister<FieldValues>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelected?: (value: any) => void
    rules?: RegisterOptions
}

const SelectRHF = <T,>({
    name,
    rules,
    register,
    control,
    onSelected,
    ...props
}: SelectRHFProps<T>): JSX.Element => {
    const { t } = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelectionChanged = (value: any) => {
        onSelected && onSelected(value)
    }

    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            defaultValue={null}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                    <AssuraSelect
                        {...props}
                        name={name}
                        register={register}
                        value={value}
                        onChange={(selection) => {
                            onChange(selection)
                            handleSelectionChanged(selection)
                        }}
                        containerClassnames={`select-rhf-container form-control${
                            error ? ' is-invalid' : ''
                        }`}
                        toggleClassnames="select-rhf-toggle"
                        menuClassnames="select-rhf-menu"
                    />
                    {error && (
                        <div
                            className="labelExtraSmall c-error m-top-8"
                            data-testid={props.id + '-error'}
                        >
                            {t(error.message ?? '')}
                        </div>
                    )}
                </>
            )}
        />
    )
}

export default SelectRHF

export const AssuraFormSelectRHF = withLabel(SelectRHF)
