import './Members.css'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'

import { useAddMemberContext } from '../pages/AddMember'

const Members = (): JSX.Element => {
    const { t } = useTranslation()

    const { members, selectedMemberId, addMember, selectMember } = useAddMemberContext()

    const {
        formState: { isSubmitted }
    } = useFormContext()

    const mainContainerWidth = 64

    const onAddClick = (): void => {
        addMember()
    }

    const onMemberSelect = (index: string): void => {
        selectMember(index)
    }

    return (
        <div className="services-add-member-container bg-white bc-gray100">
            <div className="labelMedium m-bottom-24 m-right-24">
                {t('SERVICES.ADD_MEMBER_NEW_MEMBERS')}
            </div>
            <div
                id="services-add-member-members-container"
                className="d-flex justify-content-start align-items-center"
            >
                <>
                    {members.map((member, index) => (
                        <div
                            onClick={() => onMemberSelect(member.id)}
                            key={`services-add-member-item-container-${index}`}
                            data-testid={`services-add-member-item-container-${index}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <AssuraAvatar
                                id={`services-add-member-item-${index}`}
                                label={{
                                    title: member.firstName || `${t('COMMON.PERSON')} ${index + 1}`,
                                    classNames: `labelExtraSmallMedium ${
                                        member.id === selectedMemberId ? 'c-black' : 'c-gray500'
                                    }`,
                                    marginTop: 4
                                }}
                                mainContainerWidth={mainContainerWidth}
                                avatarContainerWidth={56}
                                border={{
                                    width: 3,
                                    color: 'gray50'
                                }}
                                active={member.id === selectedMemberId}
                                hasBadge={isSubmitted}
                                badgeColor={member.isValid ? 'success' : 'primary'}
                            >
                                {member.firstName && member.lastName ? (
                                    <div className="headlineSmallMedium">
                                        {member.firstName.charAt(0).toUpperCase() +
                                            member.lastName.charAt(0).toUpperCase()}
                                    </div>
                                ) : (
                                    <i className="icon assura-profile size-32 c-white" />
                                )}
                            </AssuraAvatar>
                        </div>
                    ))}
                    {members.length < 5 && (
                        <div
                            onClick={onAddClick}
                            key="services-add-member-add-button"
                            style={{ cursor: 'pointer' }}
                        >
                            <AssuraAvatar
                                id="services-add-member-add-button"
                                label={{
                                    title: t('COMMON.ADD'),
                                    classNames: 'labelExtraSmallMedium c-gray500',
                                    marginTop: 4
                                }}
                                mainContainerWidth={mainContainerWidth}
                                avatarContainerWidth={56}
                                border={{
                                    width: 2,
                                    color: 'gray100'
                                }}
                                inactiveBackgroundColor="white"
                            >
                                <i className="icon assura-plus size-24 c-gray300" />
                            </AssuraAvatar>
                        </div>
                    )}
                </>
            </div>
        </div>
    )
}

export default Members
