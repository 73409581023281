import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { phoneRules } from 'core/utils/phoneUtils'

import { AssuraFormInputRHF } from '../InputRHF/InputRHF'

const PhoneField = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const [isFocused, setIsFocused] = useState(false)

    const {
        setValue,
        register,
        getValues,
        formState: { errors, isSubmitted }
    } = useFormContext()

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const oldValue = getValues('phone')
        const newValue = e.currentTarget.value

        const match = new RegExp(/^\+41/).test(newValue.slice(0, 3))

        setValue('phone', !match ? `+41${oldValue.slice(3)}` : e.currentTarget.value, {
            shouldValidate: isSubmitted
        })
    }

    return (
        <AssuraFormInputRHF
            name="phone"
            id="phone"
            label={t('PERSONAL_DATA.CONTACT_PHONE_LABEL')}
            inline={isXL}
            labelSize={4}
            isInvalid={Boolean(errors.phone)}
            error={t(errors.phone?.message as string)}
            hasButton={isFocused}
            onButtonClick={() => setValue('phone', '+41 ')}
            iconClass="icon assura-close size-24"
            rules={phoneRules}
            register={register}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
                setIsFocused(false)
            }}
            onChange={handleOnChange}
        />
    )
}

export default PhoneField
