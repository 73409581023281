import './SearchDoctorResults.css'

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SearchDoctor } from 'core/models/services/contract/SearchDoctor'
import { getSearchDoctorsHasMoreResults } from 'shared/store/services/doctors/doctors.slice'

import SearchDoctorResultsItem from './SearchDoctorResultsItem'

interface SearchDoctorResultsProps {
    searchDoctors: SearchDoctor[]
}

const SearchDoctorResults = ({ searchDoctors }: SearchDoctorResultsProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        formState: { errors }
    } = useFormContext()

    const hasMoreResults = useSelector(getSearchDoctorsHasMoreResults)

    return (
        <div className="d-flex flex-column" data-testid="services-choose-doctor-search-results">
            <div className="d-flex align-items-center m-top-32">
                <div className="labelSmall">
                    {t('SERVICES.RESULTS_NUMBER', { results: searchDoctors.length })}
                </div>
                {hasMoreResults && (
                    <div className="d-flex align-items-center m-left-24">
                        <i className="icon assura-info-circle size-24 c-primary" />
                        <div className="paragraphSmallLight m-left-8">
                            {t('SERVICES.SEARCH_DOCTOR_MORE_RESULTS')}
                        </div>
                    </div>
                )}
            </div>
            <div className="search-doctor-results-list-container m-top-48">
                {searchDoctors.map((searchDoctor) => {
                    return (
                        <SearchDoctorResultsItem
                            searchDoctor={searchDoctor}
                            key={searchDoctor.id}
                        />
                    )
                })}
            </div>
            {errors?.newDoctorId?.message && (
                <div
                    className="labelExtraSmall c-primary m-top-48"
                    data-testid="services-choose-doctor-selected-error"
                >
                    {t(errors.newDoctorId.message as string)}
                </div>
            )}
        </div>
    )
}

export default SearchDoctorResults
