import axios from 'axios'
import { DiscountsPersonResponse } from 'core/models/familyGroup/DiscountsPerson'
import { ProductsPersonResponse } from 'core/models/familyGroup/ProductsPerson'
import { ProductVariant } from 'core/models/familyGroup/ProductVariant'
import { ProfilePicture } from 'core/models/familyGroup/ProfilePicture'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'

export const summaryResultData = async (year: number): Promise<SummaryResult> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/summary?year=${year}`
    )
    return result.data
}

export const profilePictureData = async (policyNumber: number): Promise<ProfilePicture> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/profilePicture/${policyNumber}`
    )
    return result.data
}

export const productsPersonData = async (
    utn: number,
    year: number
): Promise<ProductsPersonResponse> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/products?userTiersNumber=${utn}&year=${year}&includeFuture=true`
    )
    return result.data
}

export const productVariantsData = async (
    policyNumber: number,
    year: number
): Promise<ProductVariant[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/productVariants/${policyNumber}?year=${year}`
    )
    return result.data
}

export const discountsPersonData = async (
    policyNumber: number,
    year: number
): Promise<DiscountsPersonResponse> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/discounts?policyNumber=${policyNumber}&year=${year}`
    )
    return result.data
}
