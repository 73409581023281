import './InvoicesToCheckPanelForm.css'

import { useState } from 'react'
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { InvoiceCheckingStatusEnum } from 'core/enums/InvoicesToCheck'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { InvoicesToCheckIsEquals } from 'core/helpers/invoicesToCheck/InvoicesToCheckHelper'
import {
    InvoiceCheckingAnswer,
    InvoiceCheckingAnswered,
    InvoiceCheckingRHFForm
} from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import {
    getInvoicesToCheckDetails,
    refuseToAnswer,
    setInvoicesToCheckDetails,
    submitInvoiceToCheckAnswers
} from 'shared/store/invoicesToCheck/invoicesToCheck.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import RadioBoxQuestion from './components/RadioBoxQuestion/RadioBoxQuestion'

export interface InvoicesToCheckFormContextProps {
    handleDetailsVisibility: (isVisible: boolean) => void
}

const InvoicesToCheckPanelForm = ({
    handleDetailsVisibility
}: InvoicesToCheckFormContextProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { panelDatas } = usePanelDatasContext()
    const invoiceToCheckDetails = useSelector(getInvoicesToCheckDetails)
    const { register, getValues, setError, clearErrors, formState } = useFormContext<
        FieldValues | InvoiceCheckingRHFForm
    >()
    const { isValid } = formState
    const [isSubmitting, setIsSubmitting] = useState<LoadingStatusEnum>(LoadingStatusEnum.OK)
    const [rejectInProgress, setRejectInProgress] = useState<LoadingStatusEnum>(
        LoadingStatusEnum.OK
    )

    const questionaryId = invoiceToCheckDetails?.id ?? ''
    const careProviderName = invoiceToCheckDetails?.careProviderName ?? ''
    const creationDate = invoiceToCheckDetails?.creationDate ?? ''
    const status = invoiceToCheckDetails?.status ?? ''
    const questionaryTitle = invoiceToCheckDetails?.questionaryTitle ?? ''
    const responseDate = invoiceToCheckDetails?.responseDate ?? ''

    const responseDateFormatted = responseDate ? formatDate(responseDate) : ''

    const formIsClosed = InvoicesToCheckIsEquals(
        [
            InvoiceCheckingStatusEnum.EXPIRED,
            InvoiceCheckingStatusEnum.CHECKED,
            InvoiceCheckingStatusEnum.REFUSED
        ],
        status
    )

    const closedText =
        InvoiceCheckingStatusEnum.REFUSED === status
            ? t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM.REFUSE_TO_ANSWER_TEXT', {
                  date: responseDateFormatted
              })
            : InvoiceCheckingStatusEnum.EXPIRED === status
              ? t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM_STATUS_CLOSED', {
                    date: responseDateFormatted
                })
              : null

    const { fields } = useFieldArray<InvoiceCheckingRHFForm>({
        name: 'questions'
    })

    const isNotValid = () => {
        const tmpQuestions = getValues('questions')
        clearErrors()
        for (const q of tmpQuestions) {
            const responsesArray = Array.from<InvoiceCheckingAnswer>(q.responses)
            if (responsesArray.some((o: InvoiceCheckingAnswer) => o?.value !== null)) continue
            setError(`QuestionGroup${q.code}`, {
                type: 'required',
                message: 'COMMUNICATIONS.INVOICES_TO_CHECK.MANDATORY_QUESTION'
            })
        }
    }

    const handleSubmit = () => {
        isNotValid()
        if (!isValid) return

        const questionsAnswersCollection: InvoiceCheckingAnswered[] = []
        const tmpQuestions = getValues('questions')
        for (const q of tmpQuestions) {
            const responses: InvoiceCheckingAnswer[] = []
            for (const r of q.responses) {
                if (r.value === null) continue
                const value = r.value
                // responses
                responses.push({
                    code: r.code,
                    value: value
                })
            }
            // questions
            const invoiceCheckingAnswered: InvoiceCheckingAnswered = {
                code: q.code,
                responses: responses
            }
            questionsAnswersCollection.push(invoiceCheckingAnswered)
        }
        setIsSubmitting(LoadingStatusEnum.LOADING)
        dispatch(
            submitInvoiceToCheckAnswers({
                id: questionaryId,
                isConsentChecked: getValues('isConsentChecked'),
                questions: questionsAnswersCollection
            })
        )
    }

    const handleRefuse = () => {
        setRejectInProgress(LoadingStatusEnum.LOADING)
        dispatch(refuseToAnswer(questionaryId))
    }

    const handleCancel = () => {
        setIsSubmitting(LoadingStatusEnum.OK)
        setRejectInProgress(LoadingStatusEnum.OK)
        dispatch(setInvoicesToCheckDetails(null))
    }

    const openCloseButtonLabel = panelDatas.hasPDFPanel
        ? 'COMMUNICATIONS.INVOICES_TO_CHECK.FORM.CLOSE_DETAIL_LABEL'
        : 'COMMUNICATIONS.INVOICES_TO_CHECK.FORM.OPEN_DETAIL_LABEL'

    return (
        <>
            <div className={`itc-form-sub-container d-flex`}>
                <div className={`itc-form-sub-container-content `}>
                    <div className={`itc-form-header d-flex`}>
                        <div className={`prompt d-flex`}>
                            <AssuraMarkdown
                                message={t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM.PROMPT', {
                                    careProvider: careProviderName
                                })}
                                classNames="paragraphLight white-space-pre-line d-flex"
                            />
                        </div>
                        <div className={`health-provider d-flex`}>
                            <div className={`health-provider-label label c-gray500`}>
                                {t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM.CARE_PROVIDER_LABEL')}
                            </div>
                            <div className={`health-provider-value label d-flex`}>
                                {careProviderName}
                            </div>
                        </div>
                        <div className={`invoice-date d-flex`}>
                            <div className={`invoice-date-label label d-flex c-gray500`}>
                                {t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM.INVOICE_DATE_LABEL')}
                            </div>
                            <div className={`invoice-date-value label d-flex`}>
                                {creationDate ? formatDate(creationDate) : ''}
                            </div>
                        </div>
                        <div className={`close-detail d-flex`}>
                            <AssuraIconButton
                                onClick={() => handleDetailsVisibility?.(!panelDatas.hasPDFPanel)}
                                id="close-button"
                                size="medium"
                                variant="primary"
                                icon={`assura-${!panelDatas.hasPDFPanel ? 'pdf' : 'close'}`}
                                label={t(openCloseButtonLabel)}
                            />
                        </div>
                    </div>

                    <div className={`itc-form-offense-frame d-flex bg-gray50`}>
                        {formIsClosed && closedText !== null && (
                            <div
                                className={`itc-form-state-text d-flex bg-white m-bottom-32 p-32 border-solid-width-1 bc-gray100`}
                            >
                                <div className="d-flex m-right-12 home-personal-data-rate-disclamer-icon">
                                    <i className="icon assura-info-circle size-24 c-gray500"></i>
                                </div>
                                <div className={`state-text paragraphSmallLight c-black`}>
                                    {closedText}
                                </div>
                            </div>
                        )}

                        <div className={`offense-text headlineSmallMedium`}>{questionaryTitle}</div>
                    </div>

                    <div className={`itc-form-questions d-flex`}>
                        {fields?.map((q: FieldValues, index: number) => {
                            return (
                                // Here will come Question type switch until now only one was developed: Radio Type
                                <RadioBoxQuestion
                                    key={q.code}
                                    index={index}
                                    register={register}
                                    formIsClosed={formIsClosed}
                                    question={q}
                                />
                            )
                        })}
                    </div>
                    {!formIsClosed && (
                        <div className={`itc-form-consent d-flex bg-gray50`}>
                            <div className={`consent d-flex`}>
                                <div className={`consent-check d-flex`}>
                                    <CheckboxRHF
                                        id="isConsentChecked"
                                        name="isConsentChecked"
                                        label={''}
                                        labelClassNames="paragraph d-flex"
                                        classNames="flex-1"
                                        onClick={undefined}
                                        register={register}
                                    />
                                </div>
                                <div className={`consent-text d-flex`}>
                                    <AssuraMarkdown
                                        message={t(
                                            'COMMUNICATIONS.INVOICES_TO_CHECK.FORM.CONSENT_TEXT'
                                        )}
                                        classNames="paragraphSmallLight white-space-pre-line d-flex"
                                    />
                                </div>
                            </div>

                            <div className={`buttons-container d-flex`}>
                                <div className={`cancel`}>
                                    <AssuraButton
                                        id={`invoices-to-check-cancel`}
                                        onClick={() => handleCancel()}
                                        text={t('COMMON.CANCEL')}
                                        variant="secondary"
                                        disabled={
                                            isSubmitting === LoadingStatusEnum.LOADING ||
                                            rejectInProgress === LoadingStatusEnum.LOADING
                                        }
                                    />
                                </div>
                                <div className={`submit`}>
                                    <AssuraButton
                                        id={`invoices-to-check-send`}
                                        onClick={() => handleSubmit()}
                                        text={t('COMMON.SEND')}
                                        variant="primary"
                                        disabled={rejectInProgress === LoadingStatusEnum.LOADING}
                                        hasLoader={isSubmitting === LoadingStatusEnum.LOADING}
                                    />
                                </div>
                            </div>
                            <div className={`invoices-to-check-refuse d-flex`}>
                                <AssuraIconButton
                                    onClick={() => handleRefuse()}
                                    classNames={`refuse-button m-right-8`}
                                    id="close-button"
                                    size="medium"
                                    variant="primary"
                                    icon={``}
                                    disabled={
                                        isSubmitting === LoadingStatusEnum.LOADING ||
                                        rejectInProgress === LoadingStatusEnum.LOADING
                                    }
                                    label={t(
                                        'COMMUNICATIONS.INVOICES_TO_CHECK.FORM.REFUSE_TO_ANSWER'
                                    )}
                                />
                                {rejectInProgress === LoadingStatusEnum.LOADING && (
                                    <div className="invoices-to-check-refuse-loading d-flex">
                                        <ActivityIndicator size={24} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={`itc-form-info-footer`}>
                <AssuraIconButton
                    id="need-help-button"
                    size="medium"
                    variant="primary"
                    icon="assura-info-circle"
                    label={t('COMMUNICATIONS.INVOICES_TO_CHECK.FORM.CONTACT_TEXT')}
                />
            </div>
        </>
    )
}

export default withAITracking(
    reactPlugin,
    InvoicesToCheckPanelForm,
    'InvoicesToCheckPanelForm',
    'invoices-to-check-panel-form'
)
