import './HomeQuickAccessCard.css'

import { useTranslation } from 'react-i18next'

import { QuickAccess } from 'core/models/cms/QuickAccessCms'
import { GetAssetIcon } from 'core/utils/assetUtils'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

export interface HomeQuickAccessCardProps {
    data: QuickAccess
}

const HomeQuickAccessCard = ({ data }: HomeQuickAccessCardProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="quick-access-card-container animated-block"
            data-testid={`quick-access-card-red-border-${data._id}`}
        >
            {data && (
                <AssuraEcLink
                    ecLinkId={data.ec_link._id ?? ''}
                    classNames="quick-access-assura-ec-link"
                    testId={`quick-access-link-${data.ec_link._id || ''}`}
                >
                    <div
                        className="quick-access-card-icon-wrapper"
                        data-testid={`quick-access-card-icon-${data._id}`}
                    >
                        <i>
                            <img
                                src={data.icon_id !== null ? GetAssetIcon(data.icon_id) : ''}
                                className="quick-access-card-icon"
                                alt="direction"
                            />
                        </i>
                    </div>
                    <div className="quick-access-card-label-wrapper">
                        <div
                            className="label quick-access-card-title"
                            data-testid={`quick-access-card-title-${data._id}`}
                        >
                            {t(data.heading || '')}
                        </div>
                        {data.sub_heading && (
                            <div
                                className="labelSmall quick-access-card-title c-gray500"
                                data-testid={`quick-access-card-sub-title-${data._id}`}
                            >
                                {t(data.sub_heading)}
                            </div>
                        )}
                    </div>
                    <AssuraIconButton
                        id={`quick-access-card-link-${data._id}`}
                        icon="assura-right"
                        size="small"
                        variant="default"
                        classNames="c-gray300"
                    />
                </AssuraEcLink>
            )}
        </div>
    )
}

export default HomeQuickAccessCard
