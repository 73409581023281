import './AssuraDocumentsError.css'

import { useTranslation } from 'react-i18next'

import documentError from 'shared/assets/images/document_error.png'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'

interface AssuraDocumentsErrorProps {
    onClick: () => void
}

const AssuraDocumentsError = ({ onClick }: AssuraDocumentsErrorProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div
            className="assura-documents-error-container bg-gray100"
            data-testid="assura-documents-error-container"
        >
            <img
                data-testid="assura-documents-error-image"
                src={documentError}
                className="assura-documents-error-img"
                alt={`${documentError}-illustration`}
            />
            <span className="paragraphSmall">{t('DOCUMENTS.ERROR_MESSAGE')}</span>
            <AssuraButton
                text={t('LOGIN.MANDATORY_WS_MODAL_BUTTON_TITLE')}
                id="assura-documents-error-button"
                variant="primary"
                onClick={() => onClick()}
                classNames="m-top-32"
            />
        </div>
    )
}

export default AssuraDocumentsError
