import './SideNavigationContent.css'

import { SideNavigationItemId, SideNavigationList } from 'core/models/SideNavigation'

import { useSideNavigationContext } from './SideNavigationProvider'

interface SideNavigationContentProps<T extends SideNavigationItemId> {
    items: SideNavigationList<T>
}

const SideNavigationContent = <T extends SideNavigationItemId>({
    items
}: SideNavigationContentProps<T>): JSX.Element | null => {
    const { selectedId } = useSideNavigationContext()

    const Component = items.get(selectedId as T)?.Content

    return Component ? (
        <div className="side-navigation-content-container">
            <Component />
        </div>
    ) : null
}

export default SideNavigationContent
