/* eslint-disable sonarjs/cognitive-complexity */
import './Address.css'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { MissingDataType } from 'core/enums/MissingData'
import { ServiceType } from 'core/enums/ServiceType'
import useWindowSize from 'core/services/useWindowSize'
import addressImg from 'shared/assets/images/address.png'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import {
    getSelectedMemberPolicyNumber,
    goToServicesForm
} from 'shared/store/services/services.slice'

import NoAddressData from './NoAddressData'

interface AddressProps {
    isPublic: boolean
    isHeadOfFamily: boolean
}

const Address = ({ isPublic, isHeadOfFamily }: AddressProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()
    const dispatch = useDispatch()

    const selectedPolicyNumber = useSelector(getSelectedMemberPolicyNumber)

    const selectedFullPersonalData = useSelector(getFullPersonalData)

    const missingData = useSelector(getMissingDataInRequest)

    if (selectedPolicyNumber && selectedFullPersonalData) {
        const { address } = selectedFullPersonalData

        return (
            <>
                <div
                    className={`personal-data-address bg-white bc-gray100${
                        isPublic ? '' : ' flex-1'
                    }`}
                >
                    <div className="headline m-bottom-32">{t('PERSONAL_DATA.ADDRESS')}</div>

                    <div
                        className="personal-data-address-sub-container"
                        style={{ flexDirection: isPublic && !isMobile ? 'row' : 'column' }}
                    >
                        {missingData[selectedPolicyNumber] &&
                        missingData[selectedPolicyNumber]?.includes(
                            MissingDataType.ADDRESS_MISSING
                        ) ? (
                            <NoAddressData />
                        ) : (
                            <>
                                <div
                                    className={`d-flex${
                                        !(isPublic && !isMobile) ? ' m-bottom-40' : ''
                                    }`}
                                >
                                    <img
                                        data-testid="address-image"
                                        src={addressImg}
                                        className="personal-data-address-img"
                                        alt="address image"
                                        width="48"
                                        height="48"
                                    />
                                    <div className="personal-data-address-text paragraph c-black">
                                        <div data-testid="personal-data-address-street">
                                            {address.data?.street ?? ''}{' '}
                                            {address.data?.streetNumber ?? ''}
                                        </div>
                                        <div data-testid="personal-data-address-comp">
                                            {address.data?.streetComp ?? ''}
                                        </div>
                                        <div data-testid="personal-data-address-locality">
                                            {address.data?.npaCode ?? ''}{' '}
                                            {address.data?.locality ?? ''}
                                        </div>
                                    </div>
                                </div>
                                <AssuraButton
                                    text={t(
                                        address.isPending ? 'COMMON.IS_PENDING' : 'COMMON.MODIFY'
                                    )}
                                    id="personal-data-address-edit-button"
                                    variant="secondary"
                                    onClick={() =>
                                        dispatch(
                                            goToServicesForm({
                                                type: ServiceType.ADDRESS_CHANGE
                                            })
                                        )
                                    }
                                    icon={{
                                        name: address.isPending
                                            ? 'assura-pending-1'
                                            : 'assura-edit',
                                        size: 24
                                    }}
                                    fullWidth={!isPublic}
                                    disabled={address.isPending || (isPublic && !isHeadOfFamily)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}

export default Address
