import { PaymentChange } from 'core/models/services/financial/PaymentChange'
import {
    Financial,
    FinancialLoadingPayload,
    SetFinancialPayload
} from 'core/models/services/personalData/Financial'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

interface FetchFinancialPayload {
    reload?: boolean
    selectedPolicyNumber: number
}

const INITIAL_STATE_FINANCIAL: Record<number, Financial> = {}

export const contactSlice = createSlice({
    name: 'financial',
    initialState: INITIAL_STATE_FINANCIAL,
    reducers: {
        initFinancial: (state) => {
            return state
        },
        fetchFinancial: (
            state: Record<number, Financial>,
            _action: PayloadAction<FetchFinancialPayload>
        ) => {
            return state
        },
        setFinancial: (
            state: Record<number, Financial>,
            action: PayloadAction<SetFinancialPayload>
        ) => {
            state[action.payload.selectedPolicyNumber] = {
                ...state[action.payload.selectedPolicyNumber],
                datas: action.payload.newDatas,
                wsStatus: action.payload.wsStatus
            }
        },
        setLoadingStatus: (
            state: Record<number, Financial>,
            action: PayloadAction<FinancialLoadingPayload>
        ) => {
            state[action.payload.selectedPolicyNumber] = {
                ...state[action.payload.selectedPolicyNumber],
                loadingStatus: action.payload.value
            }
        },
        onSubmitPaymentChange: (state, _action: PayloadAction<PaymentChange>) => {
            return state
        },
        reset: () => INITIAL_STATE_FINANCIAL
    }
})

export const {
    initFinancial,
    fetchFinancial,
    setFinancial,
    setLoadingStatus,
    onSubmitPaymentChange,
    reset
} = contactSlice.actions

export const getFinancial: Selector<RootState, Record<number, Financial>> = (state) =>
    state.servicesFinancial

export default contactSlice.reducer
