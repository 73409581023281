import './FaqCategoriesList.css'

import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import useWindowSize from 'core/services/useWindowSize'
import { useFaqContext } from 'modules/faq/pages/Faq'
import { getFaqCategories } from 'shared/store/selectors/getFaqData'

const FaqCategoriesList = (): JSX.Element => {
    const { width } = useWindowSize()
    const { selectedCategoryId, setSelectedCategoryId } = useFaqContext()
    const faqDataCategories = useSelector(getFaqCategories)

    const handleSelection = (id: string) => {
        setSelectedCategoryId(id)
    }

    useLayoutEffect(() => {
        const containerPaddingLeft = 16
        let translation = containerPaddingLeft

        if (width <= 1440) {
            const faqContainer = document.getElementById('faq-container')
            const style = window.getComputedStyle(faqContainer as Element)
            const marginLeft = parseInt(style.marginLeft.replace('px', ''))
            if (marginLeft) translation += marginLeft
        }

        const categoriesItems = document.querySelectorAll<HTMLElement>(
            '.faq-category-item-background'
        )
        categoriesItems.forEach((item) => (item.style.left = `-${translation}px`))
    }, [width])

    return (
        <div className="faq-categories-list">
            {faqDataCategories.map((catgegory) => (
                <div
                    className={`faq-categories-item headlineSmallRegular ${
                        selectedCategoryId === catgegory.id ? 'active' : ''
                    }`}
                    key={`faq-category-${catgegory.id}`}
                    onClick={() => handleSelection(catgegory.id)}
                    data-testid={`faq-category-${catgegory.id}`}
                >
                    <span className="max-2-text-lines">{catgegory.title}</span>
                    <div className="faq-category-icon-container">
                        <i className="icon assura-right size-24" />
                    </div>
                    <span className="faq-category-item-background" />
                </div>
            ))}
        </div>
    )
}

export default FaqCategoriesList
