/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { useStore } from 'react-redux'

import { AnyAction, Store } from 'redux'

import { appInsights } from 'AppInsights'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'

import { Util } from '@microsoft/applicationinsights-web'

type SagaLoadingStatus = {
    service: string
    loaded: boolean
}

type PageViewTracking = {
    pageName: string
    isLoaded?: boolean
    finished?: boolean
    started?: boolean
    loadingStatuses: SagaLoadingStatus[]
}

type PageData = {
    name: string
    uri: string
}

type InsightContextPayload = {
    data: PageData
    loadingStatuses: SagaLoadingStatus[]
}

type InsightPayload = {
    name: string
    loadingStatuses: SagaLoadingStatus[]
}

enum InsightStoreActions {
    setSagasLoadingStatus = 'SET_SAGAS_LOADING_STATUS',
    loadingComplete = 'LOADING_COMPLETE',
    startTracing = 'START_TRACKING',
    startTracingRefetch = 'START_TRACKING_REFETCH',
    stopTracing = 'STOP_TRACKING',
    setCurrentPage = 'SET_CURRENT_PAGE'
}

type InsightStore = {
    currentPage: string | undefined
    pageTracking: PageViewTracking[]
}

interface InsightAction {
    type: InsightStoreActions
    payload: InsightPayload
}

const initialState: InsightStore = {
    currentPage: undefined,
    pageTracking: []
}
const insightReducer = (state: InsightStore, action: InsightAction): InsightStore => {
    switch (action.type) {
        case 'START_TRACKING_REFETCH':
            const notStartedButAlreadyLoaded = state.pageTracking.find(
                (o) => o.isLoaded && o.pageName === action.payload.name && !o.started && o.finished
            )
            if (!notStartedButAlreadyLoaded) {
                return {
                    ...state,
                    pageTracking: [
                        ...state.pageTracking.filter((o) => o.pageName !== action.payload.name),
                        {
                            isLoaded: false,
                            finished: false,
                            started: true,
                            pageName: action.payload.name,
                            loadingStatuses: []
                        }
                    ]
                }
            }
            return state
        case 'START_TRACKING':
            const isStarted = state.pageTracking.find(
                (o) => !o.started && o.pageName === action.payload.name
            )
            if (!isStarted) {
                return {
                    ...state,
                    pageTracking: [
                        ...state.pageTracking.filter((o) => o.pageName !== action.payload.name),
                        {
                            isLoaded: false,
                            finished: false,
                            started: true,
                            pageName: action.payload.name,
                            loadingStatuses: []
                        }
                    ]
                }
            }
            return state
        case 'STOP_TRACKING':
            const started = state.pageTracking.find(
                (o) => o.isLoaded && !o.finished && o.started && o.pageName === action.payload.name
            )
            if (started) {
                return {
                    ...state,
                    pageTracking: [
                        ...state.pageTracking.filter((o) => o.pageName !== started.pageName),
                        {
                            ...started,
                            started: false,
                            finished: true
                        }
                    ]
                }
            }
            return state
        case 'LOADING_COMPLETE':
            const startedAndnotYetComplete = state.pageTracking.find(
                (o) => !o.isLoaded && !o.finished && o.started && o.pageName === action.payload.name
            )
            if (startedAndnotYetComplete) {
                const loaded =
                    startedAndnotYetComplete.loadingStatuses.length > 0
                        ? startedAndnotYetComplete.loadingStatuses.every((o) => o.loaded)
                        : false

                return {
                    ...state,
                    pageTracking: [
                        ...state.pageTracking.filter(
                            (o) => o.pageName !== startedAndnotYetComplete.pageName
                        ),
                        {
                            ...startedAndnotYetComplete,
                            isLoaded: loaded
                        }
                    ]
                }
            }
            return state
        case 'SET_SAGAS_LOADING_STATUS':
            const existingStatusPage = state.pageTracking.find(
                (o) => !o.isLoaded && !o.finished && o.started && o.pageName === action.payload.name
            )
            if (existingStatusPage) {
                return {
                    ...state,
                    pageTracking: [
                        ...state.pageTracking.filter(
                            (o) => o.pageName !== existingStatusPage.pageName
                        ),
                        {
                            ...existingStatusPage,
                            loadingStatuses: [...action.payload.loadingStatuses]
                        }
                    ]
                }
            }
            return state
        case 'SET_CURRENT_PAGE':
            return { ...state, currentPage: action.payload.name }
        default:
            return state
    }
}

const startTrace = (name: string) => {
    if (!appInsights) return
    appInsights.addTelemetryInitializer((envelope: any) => {
        envelope.data.someField = `EC Custom Page Tracker ${name}`
    })
    appInsights.context.telemetryTrace.traceID = Util.generateW3CId()
    appInsights.startTrackPage(name)
}

const stopTrace = (name: string) => {
    if (!appInsights) return
    appInsights.stopTrackPage(name)
}

const getPageName = (store: any): PageData | null => {
    if (!store || !store.router) return null
    const name = store.router.location?.pathname.toString()

    if (!name) return null
    if (name === 'localhost:3000/' || name === '/' || name === '/Home')
        return { name: 'Home', uri: name } as PageData
    return { name: name.substring(1), uri: name } as PageData
}

interface AppInsightContext {
    pageTrackings: PageViewTracking[]
}

const AppInsightCustomContext = createContext<AppInsightContext>({
    pageTrackings: []
})

type AppInsightContextProps = {
    children: React.ReactNode | React.ReactNode[]
}

const AppInsightDatasCustomProvider = ({ children }: AppInsightContextProps): JSX.Element => {
    const store = useStore()
    const [state, dispatch] = useReducer(insightReducer, initialState)

    const [currentState, setCurrentState] = useState<InsightContextPayload>({
        data: {} as PageData,
        loadingStatuses: []
    } as InsightContextPayload)

    const currentPageState = (): PageViewTracking | undefined =>
        state.currentPage
            ? state.pageTracking.find((o) => o.pageName === state.currentPage)
            : undefined

    const isStarted = (name: string, givenState: InsightStore): boolean => {
        if (!givenState || !givenState.pageTracking || !givenState.pageTracking.find) return false

        const foundPage = givenState.pageTracking.find((o) => o.pageName === name)

        return foundPage ? (foundPage.started !== undefined ? foundPage.started : false) : false
    }

    const isLoaded = (name: string, givenState: InsightStore): boolean => {
        if (!givenState || !givenState.pageTracking || !givenState.pageTracking.find) return false

        const foundPage = givenState.pageTracking.find((o) => o.pageName === name)

        return foundPage ? (foundPage.isLoaded !== undefined ? foundPage.isLoaded : false) : false
    }

    useEffect(() => {
        if (sessionStorage.getItem('currentPage')) {
            sessionStorage.removeItem('currentPage')
        }

        const unsubscribe = store.subscribe(handleChange(store))

        return () => {
            dispatch
            unsubscribe()
            if (sessionStorage.getItem('currentPage')) {
                sessionStorage.removeItem('currentPage')
            }
        }
    }, [])

    useEffect(() => {
        const page = currentPageState()
        if (!page || !page?.pageName) return

        const pageName = page?.pageName

        const currentSessionPage = sessionStorage.getItem('currentPage')

        if (!currentSessionPage && pageName && page.started === true && page.finished === false) {
            sessionStorage.setItem('currentPage', pageName)
            startTrace(pageName)
        }

        if (currentSessionPage === pageName && page.started === false && page.finished === true) {
            sessionStorage.removeItem('currentPage')
            stopTrace(pageName)
        }
    }, [currentPageState()])

    useEffect(() => {
        if (!currentState.data.name || !state) return

        if (!state.currentPage || state.currentPage !== currentState.data.name) {
            dispatch({
                type: InsightStoreActions.setCurrentPage,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: []
                }
            })
            return
        }

        const loaded = currentState.loadingStatuses.every((o) => o.loaded)

        const started = isStarted(currentState.data.name, state)
        const finished = isLoaded(currentState.data.name, state)

        if (!started && !loaded && finished) {
            dispatch({
                type: InsightStoreActions.startTracingRefetch,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: currentState.loadingStatuses
                }
            })
        } else if (!started) {
            dispatch({
                type: InsightStoreActions.startTracing,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: currentState.loadingStatuses
                }
            })
        }

        if (loaded) {
            dispatch({
                type: InsightStoreActions.setSagasLoadingStatus,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: currentState.loadingStatuses
                }
            })

            dispatch({
                type: InsightStoreActions.loadingComplete,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: currentState.loadingStatuses
                }
            })
        }

        if (finished) {
            dispatch({
                type: InsightStoreActions.stopTracing,
                payload: {
                    name: currentState.data.name,
                    loadingStatuses: currentState.loadingStatuses
                }
            })
        }
    }, [currentState, state])

    // these calls are not mandatory
    const excludeXhrList = ['services', 'localities']

    const handleChange = (store: Store<any, AnyAction>) => () => {
        const localState = store.getState()
        const pageData = getPageName(localState)

        if (!pageData) return

        const loadedServices: SagaLoadingStatus[] = []
        Object.entries(localState).forEach((o: any) => {
            Object.entries(o[1]).forEach((inner) => {
                if (
                    inner[0].includes('oadingStatus') &&
                    !loadedServices.find((ls: any) => ls.service === o[0]) &&
                    !excludeXhrList.includes(o[0])
                ) {
                    loadedServices.push({
                        service: o[0],
                        loaded: [LoadingStatusEnum.OK, LoadingStatusEnum.ERROR].includes(
                            inner[1] as LoadingStatusEnum
                        )
                    } as SagaLoadingStatus)
                }
            })
        })

        setCurrentState({ data: pageData, loadingStatuses: loadedServices })
    }

    const value: AppInsightContext = {
        pageTrackings: state.pageTracking
    }

    return (
        <AppInsightCustomContext.Provider value={value}>
            {children}
        </AppInsightCustomContext.Provider>
    )
}

const useAppInsightCustomContext = (): AppInsightContext => {
    return useContext(AppInsightCustomContext)
}

export { useAppInsightCustomContext, AppInsightDatasCustomProvider }
