import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { logout } from 'shared/store/authentication/authentication.slice'

import NotAllowed from '../NotAllowed/NotAllowed'

interface NoHeadProps {
    title: string
    message: string | React.ReactElement
}

const NoHead = ({ title, message }: NoHeadProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <NotAllowed
            title={title}
            message={message}
            link={
                <div
                    className="assura-link paragraphSmallLight m-top-24"
                    onClick={() => dispatch(logout())}
                >
                    {t('MORE.LOGOUT')}
                </div>
            }
            dataTestId="no-head"
        />
    )
}

export default NoHead
