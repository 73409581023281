import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PopinItemEnum } from 'core/enums/Popin'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { ServicesRouteQuery } from 'core/models/services/ServicesRoute'
import { TranslationKey, TranslationKeyWithVariable } from 'core/models/Translations'
import { openInNewTab } from 'core/utils/onClickUtils'
import { isEmailValid } from 'core/utils/TextUtils'
import { setBanner } from 'shared/store/banners/banners.slice'
import { setSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { getConnectedFamilyMemberAndEmail } from 'shared/store/selectors/getConnectedFamilyMemberAndEmail'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'
import { goToServicesForm } from 'shared/store/services/services.slice'
import { useAppDispatch } from 'shared/store/store'
import { initSymptomChecker } from 'shared/store/symptomChecker/symptomChecker.slice'
import { getWhatsNews, selectWhatsNew } from 'shared/store/whatsNew/whatsNew.slice'

export const getErrorMethod = (
    testId: string,
    errorMessage?: TranslationKey | TranslationKeyWithVariable
) => {
    const dispatch = useAppDispatch()

    return () => {
        dispatch(
            setBanner({
                dataTestId: testId,
                message: errorMessage || 'COMMON.ERROR'
            })
        )
    }
}

export const getFormMethod = (link_url: string, linkQuery?: ServicesRouteQuery) => {
    const dispatch = useAppDispatch()

    return () => {
        dispatch(
            goToServicesForm({
                type: link_url as ServiceType,
                query: linkQuery
            })
        )
    }
}

export const getInternalMethod = (link_url: string) => {
    const navigate = useNavigate()
    return () => {
        navigate(link_url)
    }
}

export const getExternalMethod = (link_url: string, testId: string) => {
    return link_url !== ''
        ? () => {
              openInNewTab(link_url)
          }
        : getErrorMethod(testId)
}

export const getCalculatorMethod = (link_url: string, testId: string) => {
    const isHeadOfFamily = useSelector(isConnectedUserTheHeadOfFamily) ?? true
    const memberFirstNameAndEmail = useSelector(getConnectedFamilyMemberAndEmail)
    const isValidEmail =
        memberFirstNameAndEmail?.email && isEmailValid(memberFirstNameAndEmail.email)

    let errorMessage: TranslationKey | TranslationKeyWithVariable | undefined = undefined

    if (Source() === ServicesSource.BACKOFFICE)
        errorMessage = 'SERVICES.CALCULATOR_LINK_SUPERVISION'

    if (!isHeadOfFamily) {
        errorMessage = 'SERVICES.NO_HEAD_OF_FAMILY'
    }
    if (!isValidEmail) {
        errorMessage = {
            key: 'SERVICES.CALCULATOR_LINK_NO_EMAIL_ERROR',
            variableObject: {
                firstName: memberFirstNameAndEmail?.firstName || ''
            }
        }
    }

    return errorMessage ? getErrorMethod(testId, errorMessage) : getExternalMethod(link_url, testId)
}

export const getWhatsNewMethod = (link_url: string, testId: string) => {
    const dispatch = useAppDispatch()

    const whatsNews = useSelector(getWhatsNews)
    const selectedWhatsNew = whatsNews.find((item) => item.id === link_url)

    return selectedWhatsNew
        ? () => {
              dispatch(selectWhatsNew(parseInt(selectedWhatsNew.id)))
              dispatch(setSelectedPopinItem({ popinItem: PopinItemEnum.WHATSNEW }))
          }
        : getErrorMethod(testId)
}

export const getSymptomCheckerMethod = () => {
    const dispatch = useAppDispatch()
    return () => dispatch(initSymptomChecker())
}
