import './App.css'
import './modules/services/components/DatePicker/ReactDatePicker.css'
// React Slick
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
// MIGRATION OLD BOOTSTRAP
import 'core/services/i18n'
import './shared/components/AssuraDropdown/AssuraDropdown.css'

import { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import navigationConstants, { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { preparePageTypeNameInfos } from 'core/helpers/AnalyticsHelper'
import { getECRoute, getServiceRoute } from 'core/helpers/NavigationHelper'
import { getScrollbarWidth } from 'core/helpers/ScrollbarHelper'
import useNavBarHeights from 'core/services/useNavBarHeights'
import ServiceCustomerHeader from 'modules/services/components/CustomerHeader/CustomerHeader'
import ServiceFooter from 'modules/services/components/Footer/Footer'
import ServiceWebsiteHeader from 'modules/services/components/WebsiteHeader/WebsiteHeader'
import SymptomCheckerHeader from 'modules/symptomChecker/components/SymptomCheckerHeader'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AppLoadAndError from 'shared/components/AppLoadAndError/AppLoadAndError'
import AssuraFooter from 'shared/components/AssuraFooter/AssuraFooter'
import AssuraMainBanner from 'shared/components/AssuraMainBanner/AssuraMainBanner'
import AssuraNavBar from 'shared/components/AssuraNavBar/AssuraNavBar'
import AssuraToast from 'shared/components/AssuraToast/AssuraToast'
import BSGuide from 'shared/components/BSGuide/BSGuide'
import { Env } from 'shared/components/Env/Env'
import PopinManager from 'shared/components/PopinManager/PopinManager'
import ScrollToTop from 'shared/components/ScrollToTop/ScrollToTop'
import { AssuraNavBarProvider } from 'shared/contexts/AssuraNavBarContext'
import { CguPanelProvider } from 'shared/contexts/CguPanelContext'
import {
    getInitDataLoadingStatus,
    getPath,
    initData,
    setLocationPathname
} from 'shared/store/general/general.slice'

import { Worker } from '@react-pdf-viewer/core'

import AppRoutes from './AppRoutes'

function App(): JSX.Element {
    const dispatch = useDispatch()
    const path = useSelector(getPath)
    const { navBarHeight } = useNavBarHeights()

    const location = useLocation()

    const initDataLoadingStatus = useSelector(getInitDataLoadingStatus)
    const routesContainerRef = useRef<HTMLDivElement>(null)
    const [scrollbarWidth, setScrollbarWidth] = useState(15)
    const [shouldDisplayServiceWebsiteHeader, setShouldDisplayServiceWebsiteHeader] =
        useState(false)
    const [shouldDisplayServiceCustomerHeader, setShouldDisplayServiceCustomerHeader] =
        useState(false)
    const [shouldDisplaySymptomCheckerComponents, setShouldDisplaySymptomCheckerComponents] =
        useState(false)
    const [shouldDisplayServiceFooter, setShouldDisplayServiceFooter] = useState(false)

    useEffect(() => {
        dispatch(initData({}))
        const scrollbarDivWidth = getScrollbarWidth()
        setScrollbarWidth(scrollbarDivWidth)
    }, [])

    useEffect(() => {
        preparePageTypeNameInfos(path)
        setShouldDisplayServiceWebsiteHeader(Source() === ServicesSource.WEBSITE)
        const serviceRoute = getServiceRoute(path)
        const ecRoute = getECRoute(path)

        setShouldDisplaySymptomCheckerComponents(
            [
                navigationConstants.SYMPTOM_CHECKER.url,
                navigationConstants.SYMPTOM_CHECKER_LANDING_PAGE.url
            ].includes(ecRoute?.url || '')
        )
        setShouldDisplayServiceFooter(
            (!!serviceRoute && path !== SERVICES_FORM_ROUTES.SUCCESS.url) ||
                ecRoute?.url === navigationConstants.LAMAL_RULES.url
        )
        if (Source() !== ServicesSource.WEBSITE) {
            setShouldDisplayServiceCustomerHeader(
                !!serviceRoute || ecRoute?.url === navigationConstants.LAMAL_RULES.url
            )
        }
    }, [path, Source()])

    useEffect(() => {
        if (location.pathname) dispatch(setLocationPathname(location.pathname))
    }, [location])

    const getNavbar = (): JSX.Element => {
        if (shouldDisplayServiceWebsiteHeader) {
            return <ServiceWebsiteHeader />
        } else if (shouldDisplayServiceCustomerHeader) {
            return <ServiceCustomerHeader />
        } else if (shouldDisplaySymptomCheckerComponents) {
            return <SymptomCheckerHeader />
        } else {
            return (
                <AssuraNavBarProvider>
                    <AssuraNavBar />
                </AssuraNavBarProvider>
            )
        }
    }

    // Top banner position (px)
    const bannerTopForPublicAndForm = 98
    const bannerPaddingTop = 8
    const bannerTopForEC = navBarHeight + bannerPaddingTop

    return (
        <>
            <div className="app" translate="no">
                <Env />
                <Suspense fallback={<ActivityIndicator />}>
                    <AppLoadAndError status={initDataLoadingStatus}>
                        <CguPanelProvider>
                            {getNavbar()}
                            <div className={`app-routes-container`} ref={routesContainerRef}>
                                <AssuraMainBanner
                                    top={
                                        shouldDisplayServiceWebsiteHeader ||
                                        shouldDisplayServiceCustomerHeader
                                            ? bannerTopForPublicAndForm
                                            : bannerTopForEC
                                    }
                                />
                                <ScrollToTop />
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/legacy/build/pdf.worker.min.js">
                                    <AppRoutes />
                                </Worker>
                                {Source() !== ServicesSource.WEBSITE &&
                                    !shouldDisplayServiceFooter &&
                                    !shouldDisplaySymptomCheckerComponents && <AssuraFooter />}
                                <AssuraToast />
                            </div>
                            {shouldDisplayServiceFooter && <ServiceFooter />}
                            <PopinManager />
                        </CguPanelProvider>
                    </AppLoadAndError>
                </Suspense>
            </div>
            {process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD' && (
                <BSGuide paddingLeft={scrollbarWidth} />
            )}
        </>
    )
}

export default App
