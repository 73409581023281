import './AssuraPanelHeader.css'

import { useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraIconButtonBelow from 'shared/components/AssuraIconButtonBelow/AssuraIconButtonBelow'

interface AssuraPanelHeaderProps {
    leftBlock?: JSX.Element | JSX.Element[]
    centerBlock?: JSX.Element | JSX.Element[]
    rightBlock?: JSX.Element | JSX.Element[]
    closePanel: () => void
    forceSmallSideBlocks?: boolean
    forceLeftSideHidden?: boolean
    sideBlockWidthXs?: number
    sideBlockWidthAboveXs?: number
    sideBlockStyleWidthAuto?: boolean
}
// TODO: Refactor and rethink the way we inject styling properties. post go live.
// Idea: pass an option parameter which is a set of styling options. Or pass noting and style the templates outside.
const AssuraPanelHeader = ({
    leftBlock,
    centerBlock,
    rightBlock,
    closePanel,
    sideBlockStyleWidthAuto,
    forceSmallSideBlocks,
    forceLeftSideHidden,
    sideBlockWidthXs,
    sideBlockWidthAboveXs
}: AssuraPanelHeaderProps): JSX.Element => {
    const { isXL, isMobile } = useWindowSize()
    const { t } = useTranslation()

    const sideBlockWidthXsWidth: number = sideBlockWidthXs ? sideBlockWidthXs : 64
    const sideBlockWidthWidth: number = sideBlockWidthAboveXs ? sideBlockWidthAboveXs : 200

    const sideBlockWidth =
        isMobile || forceSmallSideBlocks ? sideBlockWidthXsWidth : sideBlockWidthWidth
    const panelMargin = isMobile ? 16 : 32

    const hiddenBlock = {
        width: `0`,
        margin: `0`
    }

    const sideBlockStyle = {
        width: sideBlockStyleWidthAuto ? 'auto' : `${sideBlockWidth}px`,
        margin: `0 ${panelMargin}px`
    }

    const panelRef = useRef<HTMLDivElement>(null)
    const leftRef = useRef<HTMLDivElement>(null)
    const centerRef = useRef<HTMLDivElement>(null)
    const rightRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (panelRef.current && leftRef.current && rightRef.current && centerRef.current) {
            centerRef.current.style.width = `${
                panelRef.current.offsetWidth -
                leftRef.current.offsetWidth -
                rightRef.current.offsetWidth -
                4 * panelMargin
            }px`
        }
    })

    return (
        <div className="assura-panel-header-container bbc-gray100 bg-white" ref={panelRef}>
            <div
                className="assura-panel-header-block justify-content-start"
                style={forceLeftSideHidden ? hiddenBlock : sideBlockStyle}
                ref={leftRef}
            >
                {leftBlock}
            </div>
            <div className="assura-panel-center-block" ref={centerRef}>
                {centerBlock}
            </div>
            <div
                className="assura-panel-header-block justify-content-end"
                style={sideBlockStyle}
                ref={rightRef}
            >
                {rightBlock}
                {isXL ? (
                    <AssuraIconButtonBelow
                        id="close-panel"
                        icon="assura-close"
                        variant="default"
                        iconSize={24}
                        onClick={closePanel}
                        label={t('COMMON.CLOSE')}
                        labelFontClass="labelExtraSmallMedium"
                        margin={4}
                    />
                ) : (
                    <AssuraIconButton
                        id="close-panel"
                        icon="assura-close"
                        variant="default"
                        size="medium"
                        onClick={closePanel}
                    />
                )}
            </div>
        </div>
    )
}

export default AssuraPanelHeader
