import { useState } from 'react'
import { useSelector } from 'react-redux'

import { submitDelegationDL } from 'core/api/services/Certificates'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getSelectedFamilyMember } from 'shared/store/combineSelectors'
import { useAppDispatch } from 'shared/store/store'

import DocumentsCardLink from './DocumentsCardLink'

const DocumentsCardLinkDelegation = (): JSX.Element => {
    const selectedMember = useSelector(getSelectedFamilyMember)

    const dispatch = useAppDispatch()

    const [isLoaderVisible, setIsLoaderVisible] = useState(false)

    const isLoading = (isLoading: boolean) => {
        setIsLoaderVisible(isLoading)
    }

    const isOnError = (isOnError: boolean) => {
        setIsLoaderVisible(false)
        if (isOnError)
            dispatch(
                setBanner({
                    dataTestId: 'documents-card-delegation',
                    message: 'COMMON.ERROR'
                })
            )
    }

    const onDelegationClick = () => {
        submitDelegationDL(selectedMember.policyNumber, isOnError, isLoading)
    }

    return (
        <DocumentsCardLink
            labelKey="SERVICES.DELEGATION_TITLE"
            testId="delegation"
            onClick={onDelegationClick}
            isLoading={isLoaderVisible}
        />
    )
}

export default DocumentsCardLinkDelegation
