import './SymptomCheckerCgu.css'

import { useEffect, useRef, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import useWindowSize from 'core/services/useWindowSize'
import { GetAssetIcon } from 'core/utils/assetUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { useCguPanelContext } from 'shared/contexts/CguPanelContext'
import { resetSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { useAppDispatch } from 'shared/store/store'

interface SymptomCheckerCguProps {
    onValidation: () => void
    onClose: () => void
}

const SymptomCheckerCgu = ({ onValidation, onClose }: SymptomCheckerCguProps): JSX.Element => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { isMobile } = useWindowSize()

    const { openCguPanel } = useCguPanelContext()

    const currentLocation = useRef(location.pathname)

    const [isCguChecked, setIsCguChecked] = useState(false)
    const [shouldDisplayError, setShouldDisplayError] = useState(false)

    const onCguLinkClick = () => {
        openCguPanel('66d80778ab0e7434cd07c572', t('SYMPTOM_CHECKER.CGU_HEADER_TITLE'))
    }

    const handleOnCheck = (checked: boolean) => {
        setIsCguChecked(checked)
        if (shouldDisplayError && checked) setShouldDisplayError(false)
    }

    const handleValidation = () => {
        if (isCguChecked) {
            // symptom checker analytics
            sendEvent(analyticsConstants.EVENTS.CGU_ACCEPTATION, {
                [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
            })

            onValidation()
        } else {
            setShouldDisplayError(true)
        }
    }

    useEffect(() => {
        if (currentLocation.current !== location.pathname) dispatch(resetSelectedPopinItem())
    }, [location])

    return (
        <>
            <AssuraIconButton
                id="symptom-checker-pop-in-close"
                icon="assura-close"
                variant="default"
                size="medium"
                onClick={onClose}
                classNames="symptom-checker-pop-in-close-button"
            />
            <div className="d-flex flex-column justify-content-center align-items-center p-16">
                <img
                    src={GetAssetIcon('security_check')}
                    className="symptom-checker-pop-in-icon"
                    alt="symptom-checker-pop-in-icon"
                    width="80"
                    height="80"
                />
                <div className="headlineSmallMedium m-top-32 text-center">
                    {t('SYMPTOM_CHECKER.CGU_TITLE')}
                </div>
                <div className="paragraphSmallLight m-top-16 text-center">
                    {t('SYMPTOM_CHECKER.CGU_CONTENT_1')}
                </div>
                <div className="paragraphSmallLight m-top-16 text-center">
                    {t('SYMPTOM_CHECKER.CGU_CONTENT_2')}
                </div>
                <div
                    className="custom-control custom-checkbox m-top-16 align-self-start"
                    data-testid="symptom-checker-cgu-popin-checkbox"
                >
                    <input
                        id="symptom-checker-cgu-popin-checkbox"
                        onClick={(e) => handleOnCheck(e.currentTarget.checked)}
                        data-testid="symptom-checker-cgu-popin-checkbox"
                        className="custom-control-input"
                        type="checkbox"
                        defaultChecked={isCguChecked}
                    />
                    <FormCheck.Label
                        className="custom-control-label small"
                        htmlFor="symptom-checker-cgu-popin-checkbox"
                    >
                        <Trans
                            i18nKey="SYMPTOM_CHECKER.CGU_CONSENT_LABEL"
                            components={{
                                a: (
                                    <span
                                        className=" c-primary symptom-checker-cgu-link"
                                        onClick={onCguLinkClick}
                                    />
                                )
                            }}
                        />
                    </FormCheck.Label>
                </div>
                {shouldDisplayError && (
                    <span
                        className="labelExtraSmallMedium c-error m-top-8 align-self-start"
                        data-testid="symptom-checker-cgu-popin-message"
                    >
                        {t('CGU_ACCEPTATION.ERROR_MESSAGE')}
                    </span>
                )}
                <AssuraButton
                    text={t('COMMON.CONTINUE')}
                    id="symptom-checker-pop-in-cgu-validate"
                    variant="primary"
                    onClick={handleValidation}
                    classNames="m-top-32"
                    fullWidth={isMobile}
                />
            </div>
        </>
    )
}

export default SymptomCheckerCgu
