import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'

interface HomeDesktopTitleProps {
    titleKey: string
}

const HomeDesktopTitle = ({ titleKey }: HomeDesktopTitleProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    return isXL ? (
        <Col xl={12} className="m-bottom-24">
            <div className="headlineSmall" data-testid="home-header-message">
                {t(titleKey)}
            </div>
        </Col>
    ) : null
}

export default HomeDesktopTitle
