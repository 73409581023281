// Each object bellow must match a value of the AssuraDocumentType enum
export const DROPZONE_ACCEPTED_PDF = { 'application/pdf': ['.pdf'] }
export const DROPZONE_ACCEPTED_WORD = { 'application/msword': ['.doc'] }
export const DROPZONE_ACCEPTED_IMAGES = { '': ['.jpg', '.jpeg'] }
export const DROPZONE_ACCEPTED_WORDXML = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
}

// some extended/enhanced files format are sharing the same MIME TYPE as their basic.
// They are not necessarily accepted by backend main or third processes.
// Therefor, they must be specified as unwanted. Use the following list in order to filter them out.
export const DROPZONE_REJECTED_FORMAT_LIST = ['.dot', 'jfif', '.dotx']
