import navigationConstants from 'core/constants/navigationConstants'
import { EcStatus } from 'core/enums/FamilyGroup'
import { HomeNotificationsColors } from 'core/enums/HomeNotificationsColors'
import {
    HomeNotificationsCardData,
    HomeNotificationsCriticality
} from 'core/models/HomeNotifications'
import { UnpaidStats } from 'core/models/UnpaidStats'

const mapUnpaidStatsToHomeNotification = (
    unpaidStats: UnpaidStats,
    ecStatus: EcStatus | null
): HomeNotificationsCardData => {
    const { unpaid, unpaidPremiumsCloseToDeadLineDate } = unpaidStats

    // TODO: Information is not correct for the moment (reactivate test in Infos.spec.tsx)
    let criticality: HomeNotificationsCriticality = 4
    let color = HomeNotificationsColors.BLACK
    const link = navigationConstants.FINANCIALS.url

    if (ecStatus === EcStatus.EC_PLUS) {
        if (unpaid > 0) {
            criticality = unpaidPremiumsCloseToDeadLineDate ? 2 : 1
            color = unpaidPremiumsCloseToDeadLineDate
                ? HomeNotificationsColors.RED
                : HomeNotificationsColors.BLACK
        } else {
            criticality = 0
            color = HomeNotificationsColors.GRAY
        }
    }

    return {
        testId: 'unpaid-stats',
        criticality,
        color,
        count: unpaid,
        title: 'HOME.NOTIFICATIONS_UNPAID_TITLE',
        link
    }
}

export default mapUnpaidStatsToHomeNotification
