import { createSelector } from '@reduxjs/toolkit'

import { getAppSearchFilteredSections } from './getAppSearchFilteredSections'

export const getAppSearchResultsCount = createSelector(
    [getAppSearchFilteredSections],
    (sections): number => {
        return sections.reduce<number>((acc, item) => {
            return (acc += item.results.length)
        }, 0)
    }
)
