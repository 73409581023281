import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AssuraFormTextAreaRHF } from 'modules/services/components/TextAreaRHF/TextAreaRHF'

import GeneralQuestionDropzone from '../GeneralQuestionDropzone/GeneralQuestionDropzone'

const GeneralQuestionDetails = (): JSX.Element => {
    const { t } = useTranslation()

    const {
        register,
        formState: { errors }
    } = useFormContext()

    return (
        <div className="bg-white border-solid-width-1 bc-gray100 m-top-32 p-32">
            <AssuraFormTextAreaRHF
                id="services-general-question-message"
                name="message"
                label={t('COMMON.MESSAGE')}
                inline={false}
                register={register}
                isInvalid={Boolean(errors?.message)}
                error={t(errors?.message?.message as string)}
                placeholder={t('COMMON.YOUR_MESSAGE')}
                rules={{
                    required: {
                        value: true,
                        message: t('COMMON.MANDATORY_FIELD')
                    },
                    maxLength: {
                        value: 2000,
                        message: t('FORM.INPUT_MAX_CHARACTERS', { number: 2000 })
                    }
                }}
                maxLength={2000}
            />
            <div className="m-top-48">
                <GeneralQuestionDropzone />
            </div>
        </div>
    )
}

export default GeneralQuestionDetails
