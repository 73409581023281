import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import {
    ConditionalInformations,
    displayConditionalInformations,
    SpecificationLine
} from './LamalSpecificationsCard'

interface RenderTelemedecineProps {
    code: string
}

const Telemedecine = (props: RenderTelemedecineProps): JSX.Element => {
    const telemedecineDisplay: ConditionalInformations = displayConditionalInformations(props.code)

    if (telemedecineDisplay.isDisplayingTelemedecineSpecifications) {
        return (
            <SpecificationLine
                id="telemedecine"
                code={props.code}
                illustration={telemedecineDisplay.telemedecineIllustration}
                informationsContainer={
                    <div className="d-flex flex-column">
                        <span className="labelSmallMedium">
                            {telemedecineDisplay.telemedecineTitle}
                        </span>
                        <span className="labelExtraSmall c-gray500">
                            {telemedecineDisplay.telemedecinePhone}
                        </span>
                    </div>
                }
                linkContainer={
                    <div
                        className="cursor-pointer align-items-center"
                        style={{ display: isMobile ? 'flex' : 'none' }}
                    >
                        <Link
                            to={{ pathname: 'tel:' + telemedecineDisplay.telemedecinePhone }}
                            className="d-none d-sm-block"
                        >
                            <i className="icon assura-phone c-primary size-24" />
                        </Link>
                    </div>
                }
            />
        )
    } else return <></>
}

export default Telemedecine
