import { useTranslation } from 'react-i18next'

import concatClassNames from 'core/utils/classNameUtils'

interface StatusFieldProps {
    status: string
    statusIcon: string
    statusColor: string
    statusTranslatedOptions?: Record<string, string>
    rowId: number | string
    isMobileStyle?: boolean
    classNames?: string
    labelClassNames?: string
}
const StatusField = ({
    status,
    statusIcon,
    statusColor,
    statusTranslatedOptions,
    rowId,
    isMobileStyle,
    classNames,
    labelClassNames
}: StatusFieldProps): JSX.Element => {
    const { t } = useTranslation()

    const labelClasses = concatClassNames(['break-word', labelClassNames])

    return (
        <td className={classNames} data-testid={`assura-table-row-${rowId}-status-field`}>
            <table>
                <tbody>
                    <tr className="d-flex align-items-center">
                        <td>
                            <i
                                className={`icon ${statusIcon} ${statusColor} size-${
                                    isMobileStyle ? 16 : 24
                                }`}
                                data-testid={`assura-table-row-${rowId}-status-field-icon`}
                            />
                        </td>
                        <td className="d-flex align-items-center">
                            <span
                                className={labelClasses}
                                style={{
                                    marginLeft: `${isMobileStyle ? 4 : 8}px`,
                                    textTransform: 'uppercase'
                                }}
                                data-testid={`assura-table-row-${rowId}-status-field-label`}
                            >
                                {statusTranslatedOptions
                                    ? t(status, statusTranslatedOptions)
                                    : t(status)}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    )
}

export default StatusField
