import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock, { LabelValueInlineBlockItem } from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface AddMemberProps {
    values: RequestDetailsValues[]
}

const AddMember = ({ values }: AddMemberProps): JSX.Element => {
    const { t } = useTranslation()

    const requestMembers: LabelValueInlineBlockItem[][] = []

    const members = values.find((v) => v.id === 'OtherInsurances')
    if (members) {
        const membersList = members.new as Record<string, unknown>[]
        membersList.forEach((member, index) => {
            const newMemberFields: LabelValueInlineBlockItem[] = []
            Object.entries(member).forEach(([key, value]) => {
                newMemberFields.push({
                    id: `${key}-${index}`,
                    label: (function () {
                        switch (key) {
                            case 'FirstName':
                                return t('FAMILY_GROUP.BASIC_INFO_EDIT_FIRST_NAME_LABEL')
                            case 'LastName':
                                return t('FAMILY_GROUP.BASIC_INFO_EDIT_LAST_NAME_LABEL')
                            case 'PolicyNumber':
                                return t('LOGIN.POLICE_NUMBER_LABEL')
                            default:
                                return ''
                        }
                    })(),
                    value: value as string
                })
            })
            requestMembers.push(newMemberFields)
        })
    }

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            {requestMembers.map((member, index) => (
                <Fragment key={`add-member-person-${index}`}>
                    <RequestsPanelSubtitle label={`${t('COMMON.PERSON')} ${index + 1}`} />
                    <div className="requests-panel-main-block m-bottom-32">
                        {member.map((field) => (
                            <LabelValueInlineBlock key={`${field.id}`}>
                                <div>{field.label}</div>
                                <div data-testid={`requests-details-add-member-${field.id}`}>
                                    {field.value as string}
                                </div>
                            </LabelValueInlineBlock>
                        ))}
                    </div>
                </Fragment>
            ))}
        </div>
    )
}

export default AddMember
