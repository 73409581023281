import {
    all,
    call,
    delay,
    fork,
    ForkEffect,
    put,
    spawn,
    takeEvery,
    takeLatest
} from 'redux-saga/effects'

import { deleteEnveloppe, fetchEnveloppes, uploadEnveloppe } from 'core/api/Enveloppes'
import { uploadFile } from 'core/api/Images'
import analyticsConstants from 'core/constants/analyticsConstants'
import navigationConstants from 'core/constants/navigationConstants'
import { FormCategory, SubmissionRequestPurpose } from 'core/enums/AnalyticsEnums'
import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ToastType } from 'core/enums/ToastType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    getScanDevice,
    mapOldEnveloppesToEnveloppes
} from 'core/helpers/enveloppes/EnveloppeHelper'
import { generateGUID } from 'core/helpers/GuidHelper'
import { Enveloppe, OldEnveloppe } from 'core/models/enveloppes/Enveloppe'
import i18next from 'i18next'
import { globalNavigate } from 'shared/components/GlobalHistory/GlobalHistory'

import { setBanner } from '../banners/banners.slice'
import { setToastInfo } from '../toast/toast.slice'
import {
    DataUploadEnveloppe,
    fetchEnveloppesSuccess,
    FileName,
    onDeleteEnveloppe,
    onFetchEnveloppes,
    onSubmitEnveloppe,
    setDeleteEnveloppeStatus,
    setEnveloppesLoadingStatus,
    setSelectedEnveloppeId,
    setSubmitEnveloppeStatus
} from './enveloppes.slice'

export function* fetchEnveloppesSaga() {
    try {
        yield put(setEnveloppesLoadingStatus(LoadingStatusEnum.LOADING))
        const enveloppes: OldEnveloppe[] = yield call(fetchEnveloppes)
        const enveloppesToKeep = enveloppes
            ?.filter((enveloppe) => enveloppe.status !== EnveloppeStatus.ErrorHandled)
            .sort((a, b) => Number(new Date(b.receiveDate)) - Number(new Date(a.receiveDate)))

        const newEnveloppes: Enveloppe[] = yield call(
            mapOldEnveloppesToEnveloppes,
            enveloppesToKeep
        )

        yield put(fetchEnveloppesSuccess(newEnveloppes))
        yield put(setEnveloppesLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('onFetchEnveloppesSaga Error', e)
        yield put(setEnveloppesLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

export function* deleteEnveloppeSaga(action: ReturnType<typeof onDeleteEnveloppe>) {
    try {
        const { batchNumber, sourceIsBackToScan } = action.payload
        yield put(setDeleteEnveloppeStatus(LoadingStatusEnum.LOADING))
        yield call(deleteEnveloppe, batchNumber)
        yield put(setDeleteEnveloppeStatus(LoadingStatusEnum.OK))
        yield put(onFetchEnveloppes())
        if (sourceIsBackToScan) {
            globalNavigate(navigationConstants.ENVELOPPES.url)
        } else {
            yield put(setSelectedEnveloppeId(''))
        }
        yield put(
            setToastInfo({
                message: i18next.t('DOCUMENTS.ENVELOPPE_DELETED_SUCCESS'),
                type: ToastType.SUCCESS
            })
        )
    } catch (e) {
        console.error('deleteEnveloppeSaga Error', e)
        yield put(setDeleteEnveloppeStatus(LoadingStatusEnum.ERROR))
    }
}

export function* submitEnveloppeSaga(action: ReturnType<typeof onSubmitEnveloppe>) {
    const analyticsParams = {
        form_category: FormCategory.SUBMISSION,
        request_purpose: SubmissionRequestPurpose.INVOICE
    }
    try {
        const { name, policyNumber, batchNumber, pdfFiles } = action.payload
        yield put(setSubmitEnveloppeStatus(LoadingStatusEnum.LOADING))

        if (batchNumber) {
            yield call(deleteEnveloppe, batchNumber)
        }

        const uploadCalls = pdfFiles.map((file) => {
            return call(function* () {
                const fileId: string = yield call(uploadFile, file)
                return {
                    fileName: fileId
                }
            })
        })

        const documents: FileName[] = yield all(uploadCalls)

        const enveloppe: DataUploadEnveloppe = {
            clientEnveloppeId: generateGUID(),
            name,
            scanDevice: getScanDevice(),
            documents: [...documents],
            policeNumber: policyNumber,
            originalBatchNumber: batchNumber
        }
        yield call(uploadEnveloppe, enveloppe)
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND, analyticsParams)
        yield delay(5000) //Do not call enveloppe's WS to soon
        yield put(setSubmitEnveloppeStatus(LoadingStatusEnum.OK))
        globalNavigate(`${navigationConstants.FINANCIALS.url}?item=enveloppes`)
        yield put(onFetchEnveloppes())
        yield put(
            setToastInfo({
                message: i18next.t('ENVELOPPES.SUBMISSION_SUCCESS'),
                type: ToastType.SUCCESS
            })
        )
    } catch (e) {
        console.error('submitEnveloppeSaga Error', e)
        yield put(setSubmitEnveloppeStatus(LoadingStatusEnum.ERROR))
        yield put(
            setBanner({
                dataTestId: 'enveloppe-submission-error',
                message: 'ENVELOPPES.SUBMISSION_ERROR'
            })
        )
        yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
    }
}

function* fetchEnveloppesWatcher(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(onFetchEnveloppes.type, fetchEnveloppesSaga)
}

function* deleteEnveloppeWatcher() {
    yield takeEvery(onDeleteEnveloppe.type, deleteEnveloppeSaga)
}

function* submitEnveloppeWatcher() {
    yield takeLatest(onSubmitEnveloppe.type, submitEnveloppeSaga)
}

const watchers = [
    fork(fetchEnveloppesWatcher),
    fork(deleteEnveloppeWatcher),
    fork(submitEnveloppeWatcher)
]

export default watchers
