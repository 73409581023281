import { useTranslation } from 'react-i18next'

import { ProductTypeEnum } from 'core/enums/Product'
import { RequestStatus } from 'core/enums/RequestStatus'
import { ServicesReceptionMode } from 'core/enums/ServicesReceptionMode'
import { RequestDetailsValues } from 'core/models/services/RequestData'
import LabelValueInlineBlock, { LabelValueInlineBlockItem } from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsCommunicationLink, { RequestReceptionType } from '../RequestsCommunicationLink/RequestsCommunicationLink'

interface CertificateCattiProps {
    values: RequestDetailsValues[]
    status: RequestStatus
}

const CertificateCatti = ({ values, status }: CertificateCattiProps): JSX.Element => {
    const { t } = useTranslation()

    const activeFields: LabelValueInlineBlockItem[] = []

    // Type d’attestation
    const type = values.find((v) => v.id === 'CertificateType')
    if (type)
        activeFields.push({
            id: 'attestation-type',
            label: t('SERVICES.ATTESTATION_TYPE'),
            value: (function () {
                switch (type.new) {
                    case ProductTypeEnum.LAMAL:
                        return t('COMMON.LAMAL')
                    case ProductTypeEnum.LCA:
                        return t('COMMON.LCA')
                    default:
                        return t('COMMON.LAMAL_AND_LCA')
                }
            })()
        })

    // Mode de réception
    const receptionType = values.find((v) => v.id === 'ReceptionType')
    if (receptionType)
        activeFields.push({
            id: 'reception-mode',
            label: t('SERVICES.RECEPTION_MODE'),
            value: (function () {
                switch (receptionType.new) {
                    case ServicesReceptionMode.ELECTRONIC:
                        return t('SERVICES.RECEPTION_BY_INBOX')
                    case ServicesReceptionMode.EMAIL:
                        return t('SERVICES.RECEPTION_BY_EMAIL')
                    case ServicesReceptionMode.MAIL:
                        return t('SERVICES.RECEPTION_BY_MAIL')
                    default:
                        return ''
                }
            })()
        })

    // Email
    if (receptionType?.new === ServicesReceptionMode.EMAIL) {
        const email = values.find((v) => v.id === 'Email')
        activeFields.push({
            id: 'email',
            label: t('PERSONAL_DATA.EMAIL'),
            value: (email?.new as string) ?? ''
        })
    }

    return (
        <div className="d-flex flex-1 flex-column">
            <div className="requests-panel-main-block">
                {activeFields.map((field) => (
                    <LabelValueInlineBlock key={field.label}>
                        <div>{field.label}</div>
                        <div data-testid={`requests-details-certificate-catti-${field.id}`}>
                            {field.value}
                        </div>
                    </LabelValueInlineBlock>
                ))}
            </div>
            <RequestsCommunicationLink
                receptionType={receptionType?.new as RequestReceptionType}
                status={status}
            />
        </div>
    )
}

export default CertificateCatti
