import { SERVICES_FORM_ROUTES } from 'core/constants/navigationConstants'

import { createSelector } from '@reduxjs/toolkit'

import { getPath } from '../general/general.slice'
import { getCanHavePediatricianForFamilyDoctor } from './services/getCanHavePediatricianForFamilyDoctor'
import { getCanHavePediatricianForModelDeductible } from './services/getCanHavePediatricianForModelDeductible'

export const getCanHavePediatricianByPage = createSelector(
    [getCanHavePediatricianForModelDeductible, getCanHavePediatricianForFamilyDoctor, getPath],
    (ModelDeductiblePediatrician, FamilyDoctorPediatrician, page) => {
        if (page === SERVICES_FORM_ROUTES.COVERAGE_MODEL_DEDUCTIBLE.url) {
            return ModelDeductiblePediatrician
        } else if (page === SERVICES_FORM_ROUTES.CONTRACT_FAMILY_DOCTOR.url) {
            return FamilyDoctorPediatrician
        } else {
            return undefined
        }
    }
)
