import './AssuraTabItemContent.css'

import { AssuraTabData } from 'core/models/TabData'

type AssuraTabItemContentProps = {
    data: AssuraTabData
    dataId: string
    children: React.ReactNode
    onClick: (key: number) => void
}

export const AssuraTabItemContent = ({
    data,
    dataId,
    children,
    onClick
}: AssuraTabItemContentProps) => (
    <div
        key={data.uniqueKey}
        data-testid={`${dataId}-tab-${data.uniqueKey}`}
        className={`assura-tab ${data.active ? 'assura-tab-active' : ''}`}
        onClick={() => onClick(data.uniqueKey)}
    >
        {children}
    </div>
)
