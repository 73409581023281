import './ClaimsSettlementReceiptPanel.css'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { mapClaimsSettlementReceiptDocumentFile } from 'core/helpers/documents/DocumentFileHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraPanel from 'shared/components/AssuraPanel/AssuraPanel'
import { leftPanelWidth, usePanelDatasContext } from 'shared/contexts/PanelDatasContext'
import {
    getSelectedReceiptIndex,
    setSelectedReceiptIndex
} from 'shared/store/claimsSettlement/claimsSettlement.slice'
import { getDocumentFiles } from 'shared/store/documents/documents.slice'
import {
    getSelectedFinancialDocument,
    setSelectedFinancialDocumentId
} from 'shared/store/financialDocuments/financialDocuments.slice'
import { getSelectedClaimsSettlementReceipt } from 'shared/store/selectors/getSelectedClaimsSettlementReceipt'
import { useAppDispatch } from 'shared/store/store'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import ClaimsSettlementPdfViewer from '../ClaimsSettlementPanel/ClaimsSettlementPdfViewer'
import ClaimsSettlementSubHeader from '../ClaimsSettlementPdfSubHeader/ClaimsSettlementSubHeader'
import PaymentDocumentPanelHeader from '../PaymentDocumentPanelHeader/PaymentDocumentPanelHeader'
import ClaimsSettlementReceiptPanelDetails from './ClaimsSettlementReceiptPanelDetails'

const ClaimsSettlementReceiptPanel = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { isXL } = useWindowSize()

    const selectedDocument = useSelector(getSelectedFinancialDocument)
    const selectedReceiptIndex = useSelector(getSelectedReceiptIndex)
    const selectedClaimsSettlementReceipt = useSelector(getSelectedClaimsSettlementReceipt)
    const documents = useSelector(getDocumentFiles)

    const { panelDatas, update } = usePanelDatasContext()

    const mappedClaimsSettlementReceiptDocumentFile = mapClaimsSettlementReceiptDocumentFile(
        selectedClaimsSettlementReceipt,
        documents
    )
    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => mappedClaimsSettlementReceiptDocumentFile?.fileName ?? ''
        }
    })

    useEffect(() => {
        update({ hasPDFPanel: false })

        return () => {
            //If the claimsSettlement's pdf was displayed when accessing receipt info, we display it again when closing receipt
            update({ hasPDFPanel: panelDatas.hasPDFPanel })
        }
    }, [])

    const closeReceiptPanel = () => {
        dispatch(setSelectedReceiptIndex(null))
    }

    const closeAllPanels = () => {
        closeReceiptPanel()
        dispatch(setSelectedFinancialDocumentId({ documentId: null }))
    }

    const getShouldDisplayLeftPanel = (): boolean => {
        let shouldDisplayLeftPanel = panelDatas.hasLeftPanel
        if (panelDatas.hasPDFPanel && !isXL) {
            shouldDisplayLeftPanel = false
        }
        return shouldDisplayLeftPanel
    }

    return (
        <AssuraPanel
            type={'right'}
            isOpen={selectedReceiptIndex !== null}
            size={panelDatas.panelWidth}
            panelContainerClassName="bg-gray50"
            backdropClicked={closeAllPanels}
        >
            <>
                {selectedDocument !== null && (
                    <div className="claims-settlement-receipt-panel-container">
                        <PaymentDocumentPanelHeader
                            selectedDocument={selectedDocument}
                            closePanel={closeAllPanels}
                            toolbarPluginInstance={toolbarPluginInstance}
                            statusIsOk={
                                mappedClaimsSettlementReceiptDocumentFile?.loadStatus ===
                                LoadingStatusEnum.OK
                            }
                            doc={mappedClaimsSettlementReceiptDocumentFile}
                            isClaimsSettlementReceipt
                        />
                        {selectedReceiptIndex !== null && (
                            <div className="d-flex flex-1 overflow-hidden">
                                {getShouldDisplayLeftPanel() && (
                                    <div
                                        className="d-flex"
                                        style={
                                            panelDatas.hasPDFPanel
                                                ? { flex: `0 0 ${leftPanelWidth}px` }
                                                : { flex: 1 }
                                        }
                                    >
                                        <ClaimsSettlementReceiptPanelDetails
                                            closePanel={closeReceiptPanel}
                                            isSelectedDocumentRead={
                                                selectedDocument?.isRead ?? true
                                            }
                                        />
                                    </div>
                                )}
                                {panelDatas.hasPDFPanel && (
                                    <div className="d-flex flex-column flex-1 bg-gray100">
                                        <ClaimsSettlementSubHeader />
                                        <ClaimsSettlementPdfViewer
                                            documentFile={mappedClaimsSettlementReceiptDocumentFile}
                                            toolbarPluginInstance={toolbarPluginInstance}
                                            isSelectedDocumentRead={selectedDocument.isRead}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        </AssuraPanel>
    )
}

export default ClaimsSettlementReceiptPanel
