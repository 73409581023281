import { createSelector } from '@reduxjs/toolkit'

import { getSelectedMemberSummary } from './getSelectedMemberSummary'

export interface PersonCoverageAndAccident {
    coverageType: number
    hasAccidentCoverage: boolean
}

export const getPersonCoverageAndAccident = createSelector(
    [getSelectedMemberSummary],
    (getSelectedMemberSummary) => {
        const personProducts = getSelectedMemberSummary?.productsPerson
        const products = personProducts?.products
        let coverageType = 3
        let hasAccidentCoverage = false

        if (products?.find((o) => ['W', 'WE', 'X'].includes(o.code))) {
            hasAccidentCoverage = true
            coverageType = 1
        } else if (products?.find((o) => o.type === 'laMal' && o.accident)) {
            hasAccidentCoverage = true
            coverageType = 2
        }

        return personProducts && products
            ? {
                  coverageType,
                  hasAccidentCoverage
              }
            : undefined
    }
)
