import { getRegionByCanton, Regions } from 'core/constants/regions'
import { Canton } from 'core/enums/Canton'
import { EcLinkTypeEnum } from 'core/enums/EcLinkType'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { EcLinkCms } from 'core/models/cms/EcLinkCms'

/* eslint-disable sonarjs/cognitive-complexity */
import { createSelector } from '@reduxjs/toolkit'

import { getEcLinks } from '../ecLinks/ecLinks.slice'
import { getAssets } from './getAssets'
import { getConnectedUserInfos } from './getConnectedUserInfos'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getEcLinksFiltered = createSelector(
    [getEcLinks, getAssets, getShortContactLanguage, getConnectedUserInfos],
    (ecLinksCms, assets, contactLang, personalData): EcLinkCms[] => {
        const accidentReportIsActive =
            process.env.REACT_APP_SHOW_ACCIDENT_REPORT?.toString() === 'TRUE'
        const aventuraIsActive = process.env.REACT_APP_SHOW_AVENTURA?.toString() === 'TRUE'

        return ecLinksCms.reduce<EcLinkCms[]>((acc, ecLinksCmsItem) => {
            if (ecLinksCmsItem.is_active) {
                let currentEcLinkCms: EcLinkCms | undefined = undefined

                switch (ecLinksCmsItem._id) {
                    case '64639024b0b09225b701fd98':
                        // TODO DELETE IF ACCIDENT FORM IS IN PROD
                        if (!accidentReportIsActive) {
                            currentEcLinkCms = { ...ecLinksCmsItem }
                        }
                        break

                    case '64638eb8763dd5b42000a6c2':
                        // TODO DELETE IF ACCIDENT FORM IS IN PROD
                        if (accidentReportIsActive) {
                            currentEcLinkCms = { ...ecLinksCmsItem }
                        }
                        break

                    case '6463909dfc453a5adc0a53be': {
                        const region = personalData
                            ? getRegionByCanton(personalData?.address?.canton as Canton) ??
                              Regions.ROM
                            : Regions.ROM

                        currentEcLinkCms = {
                            ...ecLinksCmsItem,
                            link_url: `PageAccompagnement_Envoi_Frais_Medicaux_${region}`,
                            link_url_it: `PageAccompagnement_Envoi_Frais_Medicaux_${region}`,
                            link_url_de: `PageAccompagnement_Envoi_Frais_Medicaux_${region}`,
                            link_url_en: `PageAccompagnement_Envoi_Frais_Medicaux_${region}`
                        }

                        break
                    }

                    case '646391c0ec5ba3566e00d4e5': {
                        const url = process.env.REACT_APP_CALCULATOR_URL || ''
                        const pattern = '/load/client'
                        currentEcLinkCms = {
                            ...ecLinksCmsItem,
                            link_url: url.replace(pattern, '/fr/load/client'),
                            link_url_it: url.replace(pattern, '/it/load/client'),
                            link_url_de: url.replace(pattern, '/de/load/client'),
                            link_url_en: ''
                        }
                        break
                    }

                    case '64638f7ccc796c009008f455':
                        // TODO DELETE IF AVENTURA IS IN PROD
                        if (aventuraIsActive) {
                            currentEcLinkCms = { ...ecLinksCmsItem }
                        }
                        break

                    default: {
                        currentEcLinkCms = { ...ecLinksCmsItem }
                    }
                }

                if (currentEcLinkCms?.type === EcLinkTypeEnum.EXTERNAL_CMS) {
                    const asset = assets[currentEcLinkCms.link_url]
                    let link_en = asset?.en || undefined
                    if (!link_en && asset)
                        link_en = asset[contactLang as keyof AssetUrlCms] || undefined

                    currentEcLinkCms = {
                        ...ecLinksCmsItem,
                        link_url: asset?.fr || '',
                        link_url_it: asset?.it || '',
                        link_url_de: asset?.de || '',
                        link_url_en: link_en || ''
                    }
                }

                if (currentEcLinkCms) acc.push(currentEcLinkCms)
            }
            return acc
        }, [])
    }
)
