import { addMinutes, format } from 'date-fns'

import { MaintenanceModel } from '../../models/cms/Maintenance'

const AreDateTimeValid = (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
): boolean => {
    return !!(
        startDate &&
        endDate &&
        startTime &&
        endTime &&
        Date.parse(`${startDate} ${startTime}`) &&
        Date.parse(`${endDate} ${endTime}`)
    )
}

const InProgress = (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
): boolean => {
    const nowDate = new Date()
    const nowDateString = format(
        addMinutes(nowDate, nowDate.getTimezoneOffset()),
        'yyyy-MM-dd HH:mm:ss.sss'
    )
    const now = Date.parse(nowDateString)
    const startDateParsed = Date.parse(`${startDate} ${startTime}`)
    const endDateParsed = Date.parse(`${endDate} ${endTime}`)

    return now >= startDateParsed && now <= endDateParsed
}

export const IsMaintenanceInProgress = (maintenance: MaintenanceModel): boolean => {
    return (
        AreDateTimeValid(
            maintenance.startDate,
            maintenance.startTime,
            maintenance.endDate,
            maintenance.endTime
        ) &&
        InProgress(
            maintenance.startDate,
            maintenance.startTime,
            maintenance.endDate,
            maintenance.endTime
        )
    )
}
