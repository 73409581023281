import { mapEnveloppesToRows } from 'core/helpers/enveloppes/EnveloppeHelper'
import { EnveloppePerson } from 'core/models/enveloppes/EnveloppePerson'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'

import { createSelector } from '@reduxjs/toolkit'

import { EnveloppeAndSummary, getEnveloppesAndSummary } from './getEnveloppes'

export const getEnveloppesForDataTable = createSelector(
    [getEnveloppesAndSummary],
    (data: EnveloppeAndSummary): EnveloppeRow[] => {
        const personNames: EnveloppePerson[] = data?.summary[data.selectedYear]?.insuredPersons.map(
            (person) => {
                return {
                    policyNumber: person.policyNumber,
                    personName: person.firstName,
                    personLastName: person.lastName,
                    personGender: person.gender
                }
            }
        )

        return mapEnveloppesToRows(data.enveloppes, personNames)
    }
)
