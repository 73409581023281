import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedFamilyMember } from '../combineSelectors'
import { getProductsYearByPage } from './getProductsYearByPage'

export const getNetPremiums = createSelector(
    [getSelectedFamilyMember, getProductsYearByPage],
    (selectedFamilyMember, selectedYear) => {
        const productsFilteredByDate = selectedFamilyMember?.productsPerson?.products.filter(
            (x) => new Date(x.startDate) <= getCheckDate(selectedYear)
        )
        const total = productsFilteredByDate?.map((p) => p.premium.net)?.reduce((a, b) => a + b, 0)

        return total ? total : 0
    }
)
