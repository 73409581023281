import './SettingsTitle.css'

import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const SettingsTitle = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Row className="settings-title-container">
            <Col xs={12} className="settings-title">
                <div className="title break-word" data-testid="settings-title">
                    {t('SETTINGS.MAIN_TITLE')}
                </div>
            </Col>
        </Row>
    )
}

export default SettingsTitle
