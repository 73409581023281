import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { PopinItemEnum } from 'core/enums/Popin'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { DeleteEnveloppePayload } from 'shared/store/enveloppes/enveloppes.slice'
import { setSelectedPopinItem } from 'shared/store/popin/popin.slice'

interface EnveloppesBackToScanDeleteProps {
    batchNumber: string
}

const EnveloppesBackToScanDelete = ({
    batchNumber
}: EnveloppesBackToScanDeleteProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleDeleteAction = () => {
        dispatch(
            setSelectedPopinItem({
                popinItem: PopinItemEnum.ENVELOPPES_DELETE,
                extraData: {
                    batchNumber,
                    sourceIsBackToScan: true
                } as DeleteEnveloppePayload
            })
        )
    }

    return (
        <AssuraIconButton
            onClick={handleDeleteAction}
            id="back-to-scan-form-delete-cta"
            size="medium"
            variant="default"
            icon="assura-delete"
            label={t('ENVELOPPES.BACK_TO_SCAN_POP_IN_VALIDATION_CTA')}
            classNames="justify-content-start m-top-32"
        />
    )
}

export default EnveloppesBackToScanDelete
