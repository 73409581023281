import { useTranslation } from 'react-i18next'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { RequestDetailsValues, RequestDetailsValuesView } from 'core/models/services/RequestData'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import RequestsPanelSubtitle from '../RequestsPanelSubtitle/RequestsPanelSubtitle'

interface CoverageLamalAccidentProps {
    values: RequestDetailsValues[]
}

const CoverageLamalAccident = ({ values }: CoverageLamalAccidentProps): JSX.Element => {
    const { t } = useTranslation()

    const status = values.find((v) => v.id === 'ChangeType')
    const validityDate = values.find((v) => v.id === 'ValidFrom')

    const mapStatusCodeToText = (statusCode: string): string => {
        if (statusCode === '1') return t('COMMON.INCLUDED')
        if (statusCode === '2') return t('COMMON.NOT_INCLUDED')
        return ''
    }

    const activeFields: RequestDetailsValuesView = {
        testId: 'accident',
        label: t('SERVICES.COVERAGE_ACCIDENT_REQUEST_VALUE'),
        old: mapStatusCodeToText((status?.old as string) ?? ''),
        new: mapStatusCodeToText((status?.new as string) ?? '')
    }

    return (
        <div className="d-flex flex-1 flex-column justify-content-start">
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_OLD_VALUE')} />
            <div className="requests-panel-main-block m-bottom-32">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-coverage-lamal-${activeFields.testId}-old`}>
                        {activeFields.old as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
            <RequestsPanelSubtitle label={t('SERVICES.REQUESTS_DETAILS_NEW_VALUE')}>
                <>
                    {validityDate && validityDate.new && (
                        <div
                            className="label c-primary"
                            data-testid="requests-details-coverage-lamal-start-date"
                        >
                            {t('DOCUMENTS.PROCESSING_DATE', {
                                processingDate: formatDate(validityDate.new as string)
                            })}
                        </div>
                    )}
                </>
            </RequestsPanelSubtitle>
            <div className="requests-panel-main-block">
                <LabelValueInlineBlock>
                    <div>{activeFields.label}</div>
                    <div data-testid={`requests-details-coverage-lamal-${activeFields.testId}-new`}>
                        {activeFields.new as string}
                    </div>
                </LabelValueInlineBlock>
            </div>
        </div>
    )
}

export default CoverageLamalAccident
