import { SearchSuggestionCms } from './cms/SearchSuggestionCms'

export interface UserSearchPayload {
    text: string
    shouldTriggerSearch: boolean
}

export interface AppSearch {
    suggestionsCms: SearchSuggestionCms[]
    userText: string
    searchText: string
}

export enum AppSearchSectionKeyEnum {
    PAGE = 'PAGE',
    SERVICES = 'SERVICES',
    FAQ = 'FAQ',
}

export type AppSearchSectionKeyType = keyof typeof AppSearchSectionKeyEnum

export type AppSearchData = Record<AppSearchSectionKeyType, AppSearchResult[]>

export interface AppSearchResult {
    ecLinkId?: string
    label: string
    icon: string
    suggestionOrder: number | null
    link?: string
    id?: string
}

export type AppSearchSectionId = AppSearchSectionKeyType | 'SUGGESTIONS'

export interface AppSearchSection {
    id: AppSearchSectionId
    sectionTitleKey: string
    results: AppSearchResult[]
}
