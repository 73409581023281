import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { MissingData } from 'core/models/MissingData'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface MissingDataState {
    loadingStatus: LoadingStatusEnum
    data?: MissingData[]
}

const INITIAL_STATE_MISSING_DATA: MissingDataState = {
    loadingStatus: LoadingStatusEnum.LOADING,
    data: undefined
}

export const missingDataSlice = createSlice({
    name: 'missingData',
    initialState: INITIAL_STATE_MISSING_DATA,
    reducers: {
        fetchMissingData: (state) => {
            return state
        },
        setMissingDataLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.loadingStatus = action.payload
        },
        setMissingData: (state, action: PayloadAction<MissingData[]>) => {
            state.data = action.payload
        }
    }
})

export const {
    fetchMissingData,
    setMissingDataLoadingStatus,
    setMissingData
} = missingDataSlice.actions

export const getMissingData: Selector<RootState, MissingData[] | undefined> = (state: RootState) =>
    state.missingData.data

export const getMissingDataLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.missingData.loadingStatus

export default missingDataSlice.reducer
