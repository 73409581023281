import './LamalAccidentInfo.css'

import { useTranslation } from 'react-i18next'

import { GetAssetIcon } from 'core/utils/assetUtils'

interface LamalAccidentInfoProps {
    hasAccident: boolean
}

const LamalAccidentInfo = ({ hasAccident }: LamalAccidentInfoProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex justify-content-start align-items-center bg-white bc-gray100 border-solid-width-1 m-top-32 p-32">
            <img
                src={GetAssetIcon('accident')}
                className="lamal-accident-info-icon "
                alt="lamal-accident-info-icon"
                width="48"
                height="48"
            />
            <div className="d-flex flex-column justify-content-center align-items-start flex-1 m-left-12">
                <div
                    className="labelSmallMedium c-black lamal-accident-info-text"
                    data-testid="lamal-accident-info-title"
                >
                    {t(
                        hasAccident
                            ? 'SERVICES.COVERAGE_ACCIDENT_INFO_TITLE_INCLUDED'
                            : 'SERVICES.COVERAGE_ACCIDENT_INFO_TITLE_NOT_INCLUDED'
                    )}
                </div>
                <div className="labelExtraSmall c-gray500">
                    {t(
                        hasAccident
                            ? 'SERVICES.COVERAGE_ACCIDENT_INFO_SUBTITLE_INCLUDED'
                            : 'SERVICES.COVERAGE_ACCIDENT_INFO_SUBTITLE_NOT_INCLUDED'
                    )}
                </div>
            </div>
        </div>
    )
}

export default LamalAccidentInfo
