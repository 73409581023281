import { useEffect, useState } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ExtraRHFRef } from 'core/models/services/RHF'
import AssuraButton, { AssuraButtonProps } from 'shared/components/AssuraButton/AssuraButton'

interface SubmitButtonWithScrollProps extends Omit<AssuraButtonProps, 'text' | 'onClick' | 'variant'> {
    text?: string
    onSubmit: (values: FieldValues) => void
    extraRhfRefs?: ExtraRHFRef[]
}

const SubmitButtonWithScroll = ({
    text,
    id,
    onSubmit,
    hasLoader,
    disabled,
    classNames,
    extraRhfRefs,
    ...rest
}: SubmitButtonWithScrollProps): JSX.Element => {
    const { t } = useTranslation()
    const [canFocus, setCanFocus] = useState(true)

    const onError = () => {
        setCanFocus(true)
    }

    const { handleSubmit, formState } = useFormContext()

    useEffect(() => {
        if (formState.errors && canFocus) {
            const elements = Object.keys(formState.errors).reduce<HTMLElement[]>((acc, name) => {
                const rhfRef = document.getElementsByName(name)[0]
                const extraRef = extraRhfRefs?.find((item) => item.name === name)

                if (rhfRef) {
                    acc.push(rhfRef)
                } else if (extraRef) {
                    const extraElement = document.getElementById(extraRef.id)
                    if (extraElement) acc.push(extraElement)
                }
                return acc
            }, [])

            elements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)

            if (elements.length > 0) {
                const errorElement = elements[0]
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                errorElement.focus({ preventScroll: true })
                setCanFocus(false)
            }
        }
    }, [formState, canFocus])

    return (
        <AssuraButton
            text={text ?? t('COMMON.SEND')}
            id={id}
            variant="primary"
            onClick={handleSubmit(onSubmit, onError)}
            hasLoader={hasLoader}
            disabled={disabled}
            classNames={classNames}
            {...rest}
        />
    )
}

export default SubmitButtonWithScroll
