export default {
    MARITAL_STATUS: [
        'PERSONAL_DATA.MARITAL_STATUS_SINGLE',
        'PERSONAL_DATA.MARITAL_STATUS_MARRIED',
        'PERSONAL_DATA.MARITAL_STATUS_MARRIED_F',
        'PERSONAL_DATA.MARITAL_STATUS_DIVORCED',
        'PERSONAL_DATA.MARITAL_STATUS_DIVORCED_F',
        'PERSONAL_DATA.MARITAL_STATUS_WIDOWER',
        'PERSONAL_DATA.MARITAL_STATUS_WIDOWER_F',
        'PERSONAL_DATA.MARITAL_STATUS_UNKNOWN',
        'PERSONAL_DATA.MARITAL_STATUS_SEPARATED',
        'PERSONAL_DATA.MARITAL_STATUS_SEPARATED_F',
        'PERSONAL_DATA.MARITAL_STATUS_INPARTNERSHIP',
        'PERSONAL_DATA.MARITAL_STATUS_PARTNERSHIPSEPARATED'
    ],
    GENDER: [
        'PERSONAL_DATA.GENDER_UNDETERMINED',
        'PERSONAL_DATA.GENDER_MALE',
        'PERSONAL_DATA.GENDER_FEMALE'
    ]
}