import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import Locality from 'core/models/Localities'
import { Street } from 'core/models/Street'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface DataSearchStreets {
    localityId: number
    street: string
}
interface LocalitiesState {
    localitiesLoadingStatus: LoadingStatusEnum
    localities: Locality[]
    streetsLoadingStatus: LoadingStatusEnum
    streets: Street[]
}

const INITIAL_STATE_LOCALITIES: LocalitiesState = {
    localitiesLoadingStatus: LoadingStatusEnum.LOADING,
    localities: [],
    streetsLoadingStatus: LoadingStatusEnum.LOADING,
    streets: []
}

export const localitiesSlice = createSlice({
    name: 'localities',
    initialState: INITIAL_STATE_LOCALITIES,
    reducers: {
        searchLocalities: (state, _action: PayloadAction<string>) => {
            return state
        },
        setLocalities: (state, action: PayloadAction<Locality[]>) => {
            state.localities = action.payload
        },
        setLocalitiesLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.localitiesLoadingStatus = action.payload
        },
        resetLocalities: (state) => {
            state.localitiesLoadingStatus = LoadingStatusEnum.LOADING
            state.localities = []
        },
        searchStreets: (state, _action: PayloadAction<DataSearchStreets>) => {
            return state
        },
        setStreets: (state, action: PayloadAction<Street[]>) => {
            state.streets = action.payload
        },
        setStreetsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.streetsLoadingStatus = action.payload
        },
        resetStreets: (state) => {
            state.streetsLoadingStatus = LoadingStatusEnum.LOADING
            state.streets = []
        }
    }
})

export const {
    searchLocalities,
    setLocalities,
    setLocalitiesLoadingStatus,
    resetLocalities,
    searchStreets,
    setStreets,
    setStreetsLoadingStatus,
    resetStreets
} = localitiesSlice.actions

export const getLocalities: Selector<RootState, Locality[]> = (state: RootState) =>
    state.localities.localities

export const getLocalitiesLoadingStatus: Selector<RootState, LoadingStatusEnum> = (
    state: RootState
) => state.localities.localitiesLoadingStatus

export const getStreets: Selector<RootState, Street[]> = (state: RootState) =>
    state.localities.streets

export const getStreetsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state: RootState) =>
    state.localities.streetsLoadingStatus

export default localitiesSlice.reducer
