import './FrequentRequestsItem.css'

import analyticsConstants from 'core/constants/analyticsConstants'
import analyticsTypesConstants from 'core/constants/analyticsTypesConstants'
import entryPointsConstants from 'core/constants/entryPointsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { FrequentRequest } from 'core/models/FrequentRequest'
import { GetAssetIcon } from 'core/utils/assetUtils'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'

interface FrequentRequestsItemProps {
    request: FrequentRequest
}

const FrequentRequestsItem = ({ request }: FrequentRequestsItemProps): JSX.Element => {
    const { label, icon_id, ec_link } = request
    const onClickSendAnalytics = (link: string) => {
        // bypass if not symptom checkeritem
        if (!link.includes('Symptom')) return
        // symptom checker analytics
        sendEvent(analyticsConstants.EVENTS.SYMPTOM_CHECKER_CLICK, {
            [analyticsConstants.PARAMS.ENTRY_POINT]:
                entryPointsConstants.ENTRY_POINTS.FREQUENT_REQUEST_ITEM,
            [analyticsConstants.PARAMS.TYPE]: analyticsTypesConstants.TYPES.SYMPTOM_CHECKER
        })
    }
    return (
        <AssuraEcLink
            ecLinkId={ec_link}
            testId={`frequent-requests-link-${ec_link}`}
            callback={onClickSendAnalytics}
        >
            <div className="services-frequent-requests-item-container">
                <img
                    src={GetAssetIcon(icon_id)}
                    alt={`image-${label}`}
                    className="services-frequent-requests-item-image"
                />
                <div className="label flex-1 m-right-24 m-left-24">{label}</div>
                <i className="icon assura-right size-16 c-gray300" />
            </div>
        </AssuraEcLink>
    )
}

export default FrequentRequestsItem
