import { useTranslation } from 'react-i18next'

import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import AssuraAvatar from 'shared/components/AssuraAvatar/AssuraAvatar'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

interface EnveloppesImageViewerHeaderProps {
    enveloppeSelected?: EnveloppeRow
    closePanel: () => void
    onZoomIn: () => void
    onZoomOut: () => void
    generalLoadingStatus: boolean
}

const EnveloppesImageViewerHeader = ({
    enveloppeSelected,
    closePanel,
    onZoomIn,
    onZoomOut,
    generalLoadingStatus
}: EnveloppesImageViewerHeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL, isMobile } = useWindowSize()

    const insuredPersonName = enveloppeSelected?.insuredPersonName

    // LEFT-BLOCK
    const initials = insuredPersonName
        ? (
              insuredPersonName.charAt(0) + enveloppeSelected?.insuredPersonLastName.charAt(0)
          ).toUpperCase()
        : ''

    const numberImages = enveloppeSelected?.documents.length ?? 0

    const EnveloppesHeaderLeft = (): JSX.Element => {
        return (
            <>
                <AssuraAvatar
                    id="enveloppe-avatar"
                    label={
                        insuredPersonName
                            ? {
                                  title: insuredPersonName,
                                  classNames: 'labelExtraSmallMedium c-gray500',
                                  marginTop: 4
                              }
                            : undefined
                    }
                    mainContainerWidth={64}
                    avatarContainerWidth={32}
                >
                    <div className="labelSmallMedium">{initials}</div>
                </AssuraAvatar>
                {generalLoadingStatus && !isMobile && (
                    <div
                        className="d-flex align-items-center labelSmall c-gray700 m-left-32"
                        style={{ width: '104px' }}
                    >
                        <div>{`${numberImages} ${
                            numberImages > 1
                                ? t('ENVELOPPES.PANEL_PAGES')
                                : t('ENVELOPPES.PANEL_PAGE')
                        }`}</div>
                    </div>
                )}
            </>
        )
    }

    // CENTER-BLOCK
    const EnveloppesHeaderCenter = (): JSX.Element => {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center">
                    <i
                        data-testid="enveloppe-header-document-type"
                        className={`icon ${enveloppeSelected?.documentTypeIcon} size-24`}
                    />
                    <span
                        data-testid="enveloppe-header-document-name"
                        className="p-left-8 labelMedium assura-panel-center-block-ellipsis"
                    >
                        {enveloppeSelected?.documentName}
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <i
                        data-testid="enveloppe-header-status-icon"
                        className={`icon ${enveloppeSelected?.statusIcon} ${enveloppeSelected?.statusColor} size-24`}
                    />
                    <span
                        data-testid="enveloppe-header-status"
                        className="labelSmall c-gray500 enveloppes-panel-header-status p-left-8"
                    >
                        {t(enveloppeSelected?.statusTranslated ?? '').toUpperCase()}
                    </span>
                </div>
            </>
        )
    }

    // RIGHT-BLOCK
    const EnveloppesHeaderRight = (): JSX.Element => {
        return (
            <div
                className={`d-flex justify-content-center align-items-center m-right-${
                    isMobile ? 16 : 32
                }`}
            >
                <AssuraIconButton
                    id="enveloppes-panel-header-zoom-out"
                    icon="assura-zoom-out"
                    variant="default"
                    size="medium"
                    onClick={onZoomOut}
                />
                <AssuraIconButton
                    id="enveloppes-panel-header-zoom-in"
                    icon="assura-zoom"
                    variant="default"
                    size="medium"
                    onClick={onZoomIn}
                    classNames="m-left-16"
                />
            </div>
        )
    }

    return (
        <AssuraPanelHeader
            leftBlock={<EnveloppesHeaderLeft />}
            centerBlock={<EnveloppesHeaderCenter />}
            rightBlock={generalLoadingStatus && isXL ? <EnveloppesHeaderRight /> : undefined}
            closePanel={closePanel}
        />
    )
}

export default EnveloppesImageViewerHeader
