import './ScrollToTopFloatingButton.css'

import { ElementType, useEffect, useState } from 'react'

import useNavBarHeights from 'core/services/useNavBarHeights'

import ScrollToTopFloatingButtonComponent from './ScrollToTopFloatingButtonComponent'

interface ScrollToTopFloatingButtonProps<C extends ElementType> {
    triggeringRef: React.RefObject<HTMLElement>
    as?: C
}

const ScrollToTopFloatingButton = <C extends ElementType = 'div'>({
    triggeringRef,
    as
}: ScrollToTopFloatingButtonProps<C>): JSX.Element | null => {
    const As = as || 'div'

    const { navBarHeight } = useNavBarHeights()

    const [isVisible, setIsVisible] = useState(false)

    const triggeringRefOptions = {
        root: null,
        rootMargin: `-${navBarHeight}px 0px 0px 0px`,
        threshold: 0
    }

    const triggeringRefCallbackFunction: IntersectionObserverCallback = (entries) => {
        const [entry] = entries
        setIsVisible(!entry.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            triggeringRefCallbackFunction,
            triggeringRefOptions
        )
        if (triggeringRef?.current) observer.observe(triggeringRef.current)

        return () => observer.disconnect()
    }, [triggeringRef])

    return isVisible ? (
        <As className="scroll-to-top-floating-button-main-container">
            <ScrollToTopFloatingButtonComponent />
        </As>
    ) : null
}

export default ScrollToTopFloatingButton
