import { ProductCategoryEnum, ProductTypeEnum } from 'core/enums/Product'
import { Product } from 'core/models/Product'
import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getSelectedFamilyMember } from '../combineSelectors'
import { getProducts } from './getProducts'
import { getProductsYearByPage } from './getProductsYearByPage'

export const getLcaProductsOfSelectedFamilyMember = createSelector(
    [getProducts, getSelectedFamilyMember, getProductsYearByPage],
    (products, selectedFamilyMember, selectedYear) => {
        const lcaProductsOfSelectedFamilyMember: Product[] = []
        const accidentProducts: Product[] = []
        const lcaProducts = selectedFamilyMember?.productsPerson?.products?.filter(
            (productPerson) =>
                productPerson.type !== ProductTypeEnum.LAMAL &&
                new Date(productPerson.startDate) <= getCheckDate(selectedYear)
        )
        lcaProducts?.forEach((productPerson) => {
            const correspondingProduct = products.find(
                (product) => product.insuranceId === productPerson.code
            )
            if (correspondingProduct) {
                const enrichedLcaProduct = { ...correspondingProduct }
                enrichedLcaProduct.accident = productPerson.accident
                enrichedLcaProduct.premium = productPerson.premium
                if (ProductCategoryEnum.ACCIDENT === enrichedLcaProduct.categoryId) {
                    accidentProducts.push(enrichedLcaProduct)
                } else {
                    lcaProductsOfSelectedFamilyMember.push(enrichedLcaProduct)
                }
            }
        })

        return lcaProductsOfSelectedFamilyMember.concat(accidentProducts)
    }
)
