import { AssuraDocumentType } from 'core/enums/DocumentType'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { DocumentFile } from 'core/models/DocumentFile'
import { ClaimsSettlementReceipt } from 'core/models/documents/ClaimsSettlementReceipt'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { openInNewTab } from 'core/utils/onClickUtils'

export const mapDocumentFile = (
    document: DocumentRow | undefined,
    documentFiles: Record<string, DocumentFile>
): AssuraDocument | undefined => {
    return document && documentFiles[document.documentId]
        ? {
              type: AssuraDocumentType.PDF,
              fileName: document.documentName.includes('.pdf')
                  ? document.documentName
                  : `${document.documentName}.pdf`,
              base64: documentFiles[document.documentId].base64 ?? '',
              loadStatus: documentFiles[document.documentId].loadStatus
          }
        : undefined
}

export const mapClaimsSettlementReceiptDocumentFile = (
    receipt: ClaimsSettlementReceipt | undefined,
    documentFiles: Record<string, DocumentFile>
): AssuraDocument | undefined => {
    return receipt && receipt.documentIdentifier && documentFiles[receipt.documentIdentifier]
        ? {
              type: AssuraDocumentType.PDF,
              fileName: receipt.title.includes('.pdf') ? receipt.title : `${receipt.title}.pdf`,
              base64: documentFiles[receipt.documentIdentifier].base64 ?? '',
              loadStatus: documentFiles[receipt.documentIdentifier].loadStatus
          }
        : undefined
}

export const getAssuraDocumentType = (fileName: string): AssuraDocumentType | undefined => {
    const fileExtension = fileName.split('.').pop()

    switch (fileExtension) {
        case 'pdf':
            return AssuraDocumentType.PDF
        case 'doc':
            return AssuraDocumentType.DOC
        case 'docx':
            return AssuraDocumentType.DOCX
        case 'jpg':
        case 'jpeg':
            return AssuraDocumentType.JPG
        default:
            return undefined
    }
}

export const openBase64InNewTab = (base64: string): void => {
    fetch(base64)
        .then((res) => res.blob())
        .then((blob) => {
            const downloadUrl = window.URL.createObjectURL(blob)
            openInNewTab(downloadUrl)
        })
}
