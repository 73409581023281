import { FileWithPath } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface EnveloppesSubmissionAcceptedFileProps {
    index: number
    droppedFile: DroppedFile
    seeFile: (file: FileWithPath) => void
    onDelete: (id: number) => void
}

const EnveloppesSubmissionAcceptedFile = ({
    index,
    droppedFile,
    seeFile,
    onDelete
}: EnveloppesSubmissionAcceptedFileProps): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()

    return (
        <div
            className="label d-flex justify-content-between align-items-center p-top-8 p-bottom-8 border-bottom-solid-width-1 bbc-gray100"
            data-testid={`enveloppes-submission-accepted-file-${index}`}
        >
            <span className="text-ellipsis flex-1 p-right-8">{droppedFile.fileName}</span>
            <div className="d-flex">
                <AssuraIconButton
                    id={`enveloppes-submission-see-file-${index}`}
                    icon="assura-visible"
                    variant="primary"
                    size="medium"
                    label={isXL ? t('ENVELOPPES.SEE_FILE') : undefined}
                    onClick={seeFile}
                />
                <AssuraIconButton
                    id={`enveloppes-submission-delete-file-${index}`}
                    icon="assura-delete"
                    variant="default"
                    size="medium"
                    onClick={onDelete}
                    classNames="m-left-16"
                    label={isXL ? t('COMMON.DELETE') : undefined}
                />
            </div>
        </div>
    )
}

export default EnveloppesSubmissionAcceptedFile
