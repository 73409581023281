import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { FaqCategoryCms } from 'core/models/cms/FaqCategoryCms'
import { FaqCms } from 'core/models/cms/FaqCms'
import { RootState } from 'shared/store/store'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

export interface FaqState {
    faqLoadingStatus: LoadingStatusEnum
    faqCategoryCms: FaqCategoryCms[]
    faqCms: FaqCms[]
}

const INITIAL_STATE_FAQ: FaqState = {
    faqLoadingStatus: LoadingStatusEnum.LOADING,
    faqCategoryCms: [],
    faqCms: []
}

export const faqSlice = createSlice({
    name: 'faq',
    initialState: INITIAL_STATE_FAQ,
    reducers: {
        setFaqLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.faqLoadingStatus = action.payload
        },
        fetchFaqCollections: (state) => {
            return state
        },
        setFaqCategoryCms: (state, action: PayloadAction<FaqCategoryCms[]>) => {
            state.faqCategoryCms = action.payload
        },
        setFaqCms: (state, action: PayloadAction<FaqCms[]>) => {
            state.faqCms = action.payload
        }
    }
})

export const {
    setFaqLoadingStatus,
    fetchFaqCollections,
    setFaqCategoryCms,
    setFaqCms
} = faqSlice.actions

export const getFaqLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.faq.faqLoadingStatus

export const getFaqCategoryCms: Selector<RootState, FaqCategoryCms[]> = (state) =>
    state.faq.faqCategoryCms

export const getFaqCms: Selector<RootState, FaqCms[]> = (state) => state.faq.faqCms

export default faqSlice.reducer
