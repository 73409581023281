import { DropzoneState } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { useAssuraDropzoneContext } from './AssuraDropzone'

interface AssuraDropzoneWaitingProps {
    testId: string
    helperText: string
    mandatoryErrorText?: string
}

const AssuraDropzoneWaiting = ({
    testId,
    helperText,
    mandatoryErrorText
}: AssuraDropzoneWaitingProps): JSX.Element => {
    const { getRootProps, getInputProps, isDragActive } =
        useAssuraDropzoneContext() as DropzoneState

    const dragActiveDropzoneClass = isDragActive
        ? 'bg-gray20 bc-gray500'
        : `${mandatoryErrorText ? 'bg-error-background' : 'bg-white'} bc-primary`
    const dragActiveTextClass = isDragActive ? 'c-black' : 'c-primary'
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column">
            <div
                {...getRootProps({
                    className: 'text-center assura-dropzone ' + dragActiveDropzoneClass
                })}
                data-testid={`${testId}-dropzone-waiting`}
            >
                <input {...getInputProps()} />
                <div className={'paragraph ' + dragActiveTextClass}>
                    {t('DROPZONE.IMPORT_DRAG_N_DROP')}
                </div>
                <div className={'paragraph ' + dragActiveTextClass}>{t('COMMON.OR')}</div>
                <div className={'paragraph text-decoration-underline ' + dragActiveTextClass}>
                    {t('DROPZONE.IMPORT_CLICK_UPLOAD')}
                </div>
                <div className="labelExtraSmall c-gray500 text-center" style={{ marginTop: 16 }}>
                    {helperText}
                </div>
            </div>
            {mandatoryErrorText && (
                <div
                    data-testid={`${testId}-dropzone-waiting-error`}
                    className="p-top-24 labelExtraSmall c-error"
                >
                    {mandatoryErrorText}
                </div>
            )}
        </div>
    )
}

export default AssuraDropzoneWaiting
