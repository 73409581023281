import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { AssuraFormInputIconButtonRHF } from 'modules/services/components/InputRHF/InputRHF'

interface EnveloppesSubmissionNameInputProps {
    testId: string
}

const EnveloppesSubmissionNameInput = ({
    testId
}: EnveloppesSubmissionNameInputProps): JSX.Element => {
    const { isXL } = useWindowSize()
    const { t } = useTranslation()

    const [isFocused, setIsFocused] = useState(false)

    const {
        setValue,
        register,
        setFocus,
        formState: { errors }
    } = useFormContext()

    const lengthErrorKey = 'COMMON.ERROR_FIELD_VALIDATION_CHARACTER'

    const rules = {
        minLength: { value: 2, message: lengthErrorKey },
        maxLength: { value: 30, message: lengthErrorKey },
        required: { value: true, message: 'COMMON.MANDATORY_FIELD' }
    }

    const getErrorMessage = () => {
        const errorMessage = errors['name']?.message as string
        return t(errorMessage || '', errorMessage === lengthErrorKey ? { min: 2, max: 30 } : {})
    }
    
    return (
        <div className="bg-white bc-gray100 border-solid-width-1 p-32 m-top-32">
            <AssuraFormInputIconButtonRHF
                id={testId}
                name="name"
                label={t('ENVELOPPES.SUBMISSION_FILENAME')}
                inline={isXL}
                labelSize={4}
                isInvalid={Boolean(errors?.name)}
                error={getErrorMessage()}
                hasButton={true}
                icon={isFocused ? 'assura-close' : 'assura-edit'}
                onButtonClick={() => (isFocused ? setValue('name', '') : setFocus('name'))}
                buttonLabel=""
                variant="default"
                isPending={false}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                register={register}
                rules={rules}
                placeHolder={t('ENVELOPPES.SUBMISSION_FILENAME_PLACEHOLDER')}
            />
        </div>
    )
}

export default EnveloppesSubmissionNameInput
