import { PageName, PageType } from '../enums/AnalyticsEnums'
import { NavigationRoute } from '../models/NavigationRoute'

const EC_ROUTES: Record<string, NavigationRoute> = {
    ROOT: {
        url: PageName.ROOT,
        pageName: PageName.ROOT,
        pageType: PageType.HOME_PAGE
    },
    HOME: {
        url: PageName.HOME,
        pageName: PageName.HOME,
        pageType: PageType.HOME_PAGE
    },
    ENVELOPPES_SUBMISSION: {
        url: PageName.ENVELOPPES_SUBMISSION,
        pageName: PageName.ENVELOPPES_SUBMISSION,
        pageType: PageType.ENVELOPPES_PAGE
    },
    ENVELOPPES_BACK_TO_SCAN: {
        url: PageName.ENVELOPPES_BACK_TO_SCAN,
        pageName: PageName.ENVELOPPES_BACK_TO_SCAN,
        pageType: PageType.ENVELOPPES_PAGE
    },
    INSURANCES: {
        url: PageName.INSURANCES,
        pageName: PageName.INSURANCES,
        pageType: PageType.INSURANCES_PAGE
    },
    REQUESTS: {
        url: PageName.SERVICES_SUBMITTED_REQUESTS,
        pageName: PageName.SERVICES_SUBMITTED_REQUESTS,
        pageType: PageType.SERVICES_PAGE
    },
    SERVICES: {
        url: PageName.SERVICES_HUB,
        pageName: PageName.SERVICES_HUB,
        pageType: PageType.SERVICES_PAGE
    },
    SERVICES_ITEMS: {
        url: '/Services/*',
        pageName: PageName.SERVICES_ITEMS,
        pageType: PageType.SERVICES_PAGE
    },
    PERSONAL_DATA: {
        url: PageName.PERSONAL_DATA,
        pageName: PageName.PERSONAL_DATA,
        pageType: PageType.MY_ACCOUNT_PAGE
    },
    SETTINGS: {
        url: PageName.SETTINGS,
        pageName: PageName.SETTINGS,
        pageType: PageType.MY_ACCOUNT_PAGE
    },
    FAQ: {
        url: PageName.FAQ,
        pageName: PageName.FAQ,
        pageType: PageType.FAQ_PAGE
    },
    SEARCH: {
        url: PageName.SEARCH,
        pageName: PageName.SEARCH,
        pageType: PageType.SEARCH_PAGE
    },
    FINANCIALS: {
        url: '/Financials',
        pageName: PageName.FINANCIALS_INVOICES,
        pageType: PageType.DOCUMENTS_PAGE
    },
    COMMUNICATIONS: {
        url: '/Communications',
        pageName: PageName.COMMUNICATIONS_CORRESPONDENCE,
        pageType: PageType.DOCUMENTS_PAGE
    },
    DELETE_ACCOUNT: {
        url: PageName.DELETE_ACCOUNT,
        pageName: PageName.DELETE_ACCOUNT,
        pageType: PageType.DELETE_ACCOUNT_PAGE
    },
    LAMAL_RULES: {
        url: PageName.LAMAL_RULES,
        pageName: PageName.LAMAL_RULES,
        pageType: PageType.LAMAL_RULES_PAGE
    },
    SYMPTOM_CHECKER: {
        url: PageName.SYMPTOM_CHECKER,
        pageName: PageName.SYMPTOM_CHECKER,
        pageType: PageType.SYMPTOM_CHECKER_PAGE
    },
    SYMPTOM_CHECKER_LANDING_PAGE: {
        url: PageName.SYMPTOM_CHECKER_LANDING_PAGE,
        pageName: PageName.SYMPTOM_CHECKER_LANDING_PAGE,
        pageType: PageType.SYMPTOM_CHECKER_PAGE
    }
}

export const SERVICES_FORM_ROUTES: Record<string, NavigationRoute> = {
    SUCCESS: {
        url: PageName.SERVICES_SUCCESS,
        pageName: PageName.SERVICES_SUCCESS,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_BILLED_PRIME: {
        url: PageName.SERVICES_CERTIFICATES_BILLED_PRIME,
        pageName: PageName.SERVICES_CERTIFICATES_BILLED_PRIME,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_MEDICAL_FEES: {
        url: PageName.SERVICES_CERTIFICATES_MEDICAL_FEES,
        pageName: PageName.SERVICES_CERTIFICATES_MEDICAL_FEES,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_INSURANCE_ATTESTATION: {
        url: PageName.SERVICES_CERTIFICATES_INSURANCE_ATTESTATION,
        pageName: PageName.SERVICES_CERTIFICATES_INSURANCE_ATTESTATION,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_PROVISIONAL_REPLACEMENT_CERTIFICATE: {
        url: PageName.SERVICES_CERTIFICATES_PROVISIONAL_REPLACEMENT_CERTIFICATE,
        pageName: PageName.SERVICES_CERTIFICATES_PROVISIONAL_REPLACEMENT_CERTIFICATE,
        pageType: PageType.SERVICES_PAGE
    },
    INSURANCE_CARD_ORDER: {
        url: PageName.SERVICES_INSURANCE_CARD_ORDER,
        pageName: PageName.SERVICES_INSURANCE_CARD_ORDER,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_STATEMENT_DETAILS: {
        url: PageName.SERVICES_CERTIFICATES_STATEMENT_DETAILS,
        pageName: PageName.SERVICES_CERTIFICATES_STATEMENT_DETAILS,
        pageType: PageType.SERVICES_PAGE
    },
    CERTIFICATES_DELEGATION: {
        url: PageName.SERVICES_CERTIFICATES_DELEGATION,
        pageName: PageName.SERVICES_CERTIFICATES_DELEGATION,
        pageType: PageType.SERVICES_PAGE
    },
    PERSONAL_DATA_INFOS: {
        url: PageName.SERVICES_PERSONAL_DATA_INFOS,
        pageName: PageName.SERVICES_PERSONAL_DATA_INFOS,
        pageType: PageType.SERVICES_PAGE
    },
    PERSONAL_DATA_ADDRESS: {
        url: PageName.SERVICES_PERSONAL_DATA_ADDRESS,
        pageName: PageName.SERVICES_PERSONAL_DATA_ADDRESS,
        pageType: PageType.SERVICES_PAGE
    },
    FINANCIAL_PAYMENT_CHANGE: {
        url: PageName.SERVICES_FINANCIAL_PAYMENT_CHANGE,
        pageName: PageName.SERVICES_FINANCIAL_PAYMENT_CHANGE,
        pageType: PageType.SERVICES_PAGE
    },
    FINANCIAL_PAYMENT_FREQ_MODIFICATION: {
        url: PageName.SERVICES_FINANCIAL_PAYMENT_FREQUENCY_MODIFICATION,
        pageName: PageName.SERVICES_FINANCIAL_PAYMENT_FREQUENCY_MODIFICATION,
        pageType: PageType.SERVICES_PAGE
    },
    CONTRACT_FAMILY_DOCTOR: {
        url: PageName.SERVICES_CONTRACT_FAMILY_DOCTOR,
        pageName: PageName.SERVICES_CONTRACT_FAMILY_DOCTOR,
        pageType: PageType.SERVICES_PAGE
    },
    COVERAGE_MODEL_DEDUCTIBLE: {
        url: PageName.SERVICES_COVERAGE_MODEL_DEDUCTIBLE,
        pageName: PageName.SERVICES_COVERAGE_MODEL_DEDUCTIBLE,
        pageType: PageType.SERVICES_PAGE
    },
    COVERAGE_LAMAL_ACCIDENT: {
        url: PageName.SERVICES_COVERAGE_LAMAL_ACCIDENT,
        pageName: PageName.SERVICES_COVERAGE_LAMAL_ACCIDENT,
        pageType: PageType.SERVICES_PAGE
    },
    CONTACT_GENERAL_QUESTION: {
        url: PageName.SERVICES_CONTACT_GENERAL_QUESTION,
        pageName: PageName.SERVICES_CONTACT_GENERAL_QUESTION,
        pageType: PageType.CONTACT_PAGE
    },
    FAMILY_GROUP_ADD_MEMBER: {
        url: PageName.SERVICES_FAMILY_GROUP_ADD_MEMBER,
        pageName: PageName.SERVICES_FAMILY_GROUP_ADD_MEMBER,
        pageType: PageType.SERVICES_PAGE
    },
    AVENTURA_INSURANCE_REQUEST: {
        url: PageName.SERVICES_AVENTURA_INSURANCE_REQUEST,
        pageName: PageName.SERVICES_AVENTURA_INSURANCE_REQUEST,
        pageType: PageType.SERVICES_PAGE
    },
    REPORT_HOSP_ANNOUNCEMENT: {
        url: PageName.SERVICES_REPORT_HOSPITALIZATION_ANNOUNCEMENT,
        pageName: PageName.SERVICES_REPORT_HOSPITALIZATION_ANNOUNCEMENT,
        pageType: PageType.SERVICES_PAGE
    },
    REPORT_TRIP_CANCELLATION: {
        url: PageName.SERVICES_REPORT_TRIP_CANCELLATION,
        pageName: PageName.SERVICES_REPORT_TRIP_CANCELLATION,
        pageType: PageType.SERVICES_PAGE
    },
    ACCIDENT_REPORT: {
        url: PageName.SERVICES_REPORT_ACCIDENT_REPORT,
        pageName: PageName.SERVICES_REPORT_ACCIDENT_REPORT,
        pageType: PageType.SERVICES_PAGE
    }
}

export default EC_ROUTES
