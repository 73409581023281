import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { addDays } from 'date-fns'
import DatePickerWithLabelRHF from 'modules/services/components/DatePickerWithLabelRHF/DatePickerWithLabelRHF'

import { dateValidation } from '../../helpers/validations'
import Colrow from '../Colrow/Colrow'

type TravelDatesProps = {
    isMobile: boolean
    isTablet: boolean
    travelStartDate: Date
}

const TravelDates = ({ isMobile, isTablet, travelStartDate }: TravelDatesProps) => {
    const { t } = useTranslation()

    return (
        <Row
            className={`justify-content-start m-bottom-48 ${
                isMobile || isTablet ? '' : 'row-cols-3 '
            }`}
            style={{ flexDirection: isMobile || isTablet ? 'column' : 'row' }}
        >
            <Col className="m-bottom-24">
                <span className="labelMedium" data-testid="services-form-periodicity">
                    {t('SERVICE.AVENTURA_FORM_PERIODICITY_LABEL')}
                </span>
            </Col>

            <Colrow
                isRowIf={isTablet}
                rowStyle={{ width: '100%', margin: 0 }}
                colClasses={`${isMobile ? 'm-bottom-32' : ''}`}
                rowClasses={`${!(isMobile || isTablet) ? 'm-bottom-48' : ''}`}
                leftChildren={
                    <DatePickerWithLabelRHF
                        name="travelStart"
                        style={{
                            border: 'none',
                            flexDirection: isMobile || isTablet ? 'row' : 'column'
                        }}
                        label="SERVICE.AVENTURA_FORM_TRAVEL_START_LABEL"
                        placeholderKey="SERVICE.AVENTURA_FORM_TRAVEL_START_PLACEHOLDER"
                        testId="travelStart"
                        minDate={addDays(new Date(), 1)}
                        inline={false}
                        className="bg-white"
                        rules={{
                            validate: dateValidation
                        }}
                        addFuturYearHeadroomCount={1}
                    />
                }
                rightChildren={
                    <DatePickerWithLabelRHF
                        name="travelEnd"
                        label="SERVICE.AVENTURA_FORM_TRAVEL_END_LABEL"
                        placeholderKey="SERVICE.AVENTURA_FORM_TRAVEL_END_PLACEHOLDER"
                        minDate={travelStartDate}
                        inline={false}
                        testId="travelEnd"
                        className="bg-white"
                        rules={{
                            validate: dateValidation
                        }}
                        addFuturYearHeadroomCount={1}
                    />
                }
            />
        </Row>
    )
}
export default TravelDates
