import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchAttachment } from 'core/api/DocumentFile'
import { RequestDocument } from 'core/models/services/RequestData'
import AssuraDownloadLink from 'shared/components/AssuraDownloadLink/AssuraDownloadLink'
import LabelValueInlineBlock from 'shared/components/LabelValueInlineBlock/LabelValueInlineBlock'

import { useRequestPanelContext } from '../RequestsPanelMain/RequestsPanelMain'

interface RequestsAttachementProps {
    attachmentLabel: string
    attachment: RequestDocument
}

const RequestsAttachment = ({
    attachmentLabel,
    attachment
}: RequestsAttachementProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const setError = useRequestPanelContext()
    const handleDownloadClick = () => {
        fetchAttachment(attachment, setError, setLoading)
    }

    return (
        <div className="requests-panel-main-block">
            <LabelValueInlineBlock>
                <div>{attachmentLabel}</div>
                <AssuraDownloadLink
                    testId="requests-attachement-download"
                    label={t('COMMON.DOWNLOAD')}
                    labelClass="label"
                    isLoading={loading}
                    onClick={handleDownloadClick}
                />
            </LabelValueInlineBlock>
        </div>
    )
}

export default RequestsAttachment
