import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { EnveloppeVisibilityErrorReason } from 'core/enums/EnveloppeVisibilityErrorReason'
import { EnveloppeError } from 'core/models/enveloppes/Enveloppe'
import { EnveloppeDocument } from 'core/models/enveloppes/EnveloppeDocument'
import i18next from 'i18next'

export const getRescanErrorMessage = (
    enveloppeError: EnveloppeError,
    visibilityErrorReason: EnveloppeVisibilityErrorReason | null,
    documents: EnveloppeDocument[],
    isImages: boolean
): string => {
    if (enveloppeError === EnveloppeStatus.TechnicalError) {
        return i18next.t('DOCUMENTS.RESCAN_TECHNICAL_ERROR')
    } else {
        if (isImages) {
            return getVisibilityErrorMessage(visibilityErrorReason, documents)
        } else {
            return getPdfVisibilityErrorMessage(documents)
        }
    }
}

const getVisibilityErrorMessage = (
    visibilityErrorReason: EnveloppeVisibilityErrorReason | null,
    documents: EnveloppeDocument[]
): string => {
    const part1 = getVisibilityErrorMessagePart1(visibilityErrorReason)
    const part2 = i18next.t('DOCUMENTS.RESCAN_VISIBILITY_ERROR')
    const part3 = getVisibilityErrorMessagePart3(EnveloppeStatus.VisibilityError, documents)
    return `${part1} ${part2}${part3}`
}

const getVisibilityErrorMessagePart1 = (
    visibilityErrorReason: EnveloppeVisibilityErrorReason | null
): string => {
    const visibilityErrorReasonKey = Object.keys(EnveloppeVisibilityErrorReason).find(
        (key) =>
            EnveloppeVisibilityErrorReason[key as keyof typeof EnveloppeVisibilityErrorReason] ===
            visibilityErrorReason
    )
    if (visibilityErrorReasonKey) {
        return i18next.t(`DOCUMENTS.RESCAN_VISIBILITY_${visibilityErrorReasonKey}`)
    } else {
        return i18next.t('DOCUMENTS.RESCAN_VISIBILITY_UNKNOWN')
    }
}

const getVisibilityErrorMessagePart3 = (
    enveloppeStatus: EnveloppeStatus.VisibilityError,
    documents: EnveloppeDocument[]
) => {
    const documentsInError = []
    for (let i = 0; i < documents.length; i++) {
        if (documents[i].status === enveloppeStatus) {
            documentsInError.push(i + 1)
        }
    }

    if (documentsInError.length === 0 || documentsInError.length === documents.length) {
        return '.'
    } else if (documentsInError.length >= 2) {
        let lastPage = ''
        let middlePages = ''
        for (let i = 0; i < documentsInError.length; i++) {
            if (i === 0) {
                middlePages = `${documentsInError[i]}`
            } else if (i === documentsInError.length - 1) {
                lastPage = documentsInError[i].toString()
            } else {
                middlePages = `${middlePages}, ${documentsInError[i]}`
            }
        }
        return ' ' + i18next.t('DOCUMENTS.RESCAN_PARTIAL_PLURAL', { middlePages, lastPage })
    } else {
        return (
            ' ' +
            i18next.t('DOCUMENTS.RESCAN_PARTIAL_SINGULAR', { pageNumber: documentsInError[0] })
        )
    }
}

const getPdfVisibilityErrorMessage = (documents: EnveloppeDocument[]): string => {
    const documentsInErrorIndex = documents.reduce<number[]>((acc, doc, index) => {
        if (
            doc.status === EnveloppeStatus.TechnicalError ||
            doc.status === EnveloppeStatus.VisibilityError
        )
            acc.push(index + 1)
        return acc
    }, [])

    return i18next.t('ENVELOPPES.PANEL_BACK_TO_SCAN_BANNER', {
        index: documentsInErrorIndex.join(', ')
    })
}
