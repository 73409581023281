import { useTranslation } from 'react-i18next'

import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'

interface DiscountsPanelHeaderProps {
    selectedYear: number
    closePanel: () => void
}
const PrimesPanelHeader = ({
    selectedYear,
    closePanel
}: DiscountsPanelHeaderProps): JSX.Element => {
    const { t } = useTranslation()

    const LeftBlock = (): JSX.Element => {
        return (
            <div className={'headline c-primary'} data-testid="primes-panel-discounts-header-title">
                {t('PRIMES.PRIMES_DETAILS', { year: selectedYear })}
            </div>
        )
    }

    return (
        <AssuraPanelHeader
            sideBlockStyleWidthAuto
            leftBlock={<LeftBlock />}
            closePanel={closePanel}
        />
    )
}

export default PrimesPanelHeader
