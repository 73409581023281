import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { fetchPeriodicityApi, postPaymentFrequencyApi } from 'core/api/services/Financial'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ConnectedFamilyMember } from 'core/models/ConnectedFamilyMember'
import { Periodicity } from 'core/models/services/financial/PaymentFrequency'
import { ServicesCommonRequestBody } from 'core/models/services/ServicesCommonRequestBody'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'
import { getServicesCommonRequestBody } from 'shared/store/selectors/getServicesCommonRequestBody'
import { isConnectedUserTheHeadOfFamily } from 'shared/store/selectors/isConnectedUserTheHeadOfFamily'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import { fetchPeriodicity, onSubmitPaymentFrequency, setPeriodicity } from './periodicity.slice'

export function* fetchPeriodicitySaga() {
    try {
        const connectedFamilyMember: ConnectedFamilyMember | undefined = yield select(
            getConnectedFamilyMember
        )

        let isHeadOfFamily = true

        if (Source() === ServicesSource.WEBSITE) {
            isHeadOfFamily = yield select(isConnectedUserTheHeadOfFamily) ?? false
        }

        if (connectedFamilyMember && isHeadOfFamily) {
            const periodicitiesAvailable: Periodicity[] = yield call(
                fetchPeriodicityApi,
                connectedFamilyMember.policyNumber
            )

            yield put(
                setPeriodicity({
                    periodicities: periodicitiesAvailable,
                    wsStatus: HttpResponseEnum.SUCCESS
                })
            )
        }
    } catch (e) {
        console.error('fetchPeriodicity Error', e)
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(
                setPeriodicity({
                    periodicities: [],
                    wsStatus: HttpResponseEnum.CONFLICT
                })
            )
        } else {
            throw e
        }
    }
}

function* onSubmitPaymentFrequencySaga(action: ReturnType<typeof onSubmitPaymentFrequency>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.PAYMENT_FREQ_MODIFICATION
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const datasFromForm = action.payload
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )

        yield call(postPaymentFrequencyApi, {
            ...datasFromForm,
            ...commonRequestBody
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitPaymentFrequencySaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* fetchPeriodicityWatcher() {
    yield takeEvery(fetchPeriodicity.type, fetchPeriodicitySaga)
}

function* onSubmitPaymentFrequencyWatcher() {
    yield takeEvery(onSubmitPaymentFrequency.type, onSubmitPaymentFrequencySaga)
}

const watchers = [fork(fetchPeriodicityWatcher), fork(onSubmitPaymentFrequencyWatcher)]

export default watchers
