import { mapDocumentsToRows } from 'core/helpers/documents/DocumentsHelper'
import { DocumentRow } from 'core/models/documents/DocumentRow'
import { isBefore } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'

import { getCommunications } from '../communications/communications.slice'
import { getPersonDataForDocuments } from './getPersonDataForDocuments'

export const getSortedCommunicationsForTable = createSelector(
    [getCommunications, getPersonDataForDocuments],
    (communications, personData): DocumentRow[] => {
        const documents = mapDocumentsToRows(communications, personData)

        /* 2024-02-02 Criteria for sorting communications:
        1. creationDate (decrease order)
        2. InsuredPersonName (increase order)
        3. documentName (increase order)
        */

        return documents.sort((a, b) => {
            const hasCreationDate = a.creationDate && b.creationDate
            const aCreationDate = new Date(a.creationDate || '')
            const bCreationDate = new Date(b.creationDate)

            const nameCompare = a.insuredPersonName.localeCompare(b.insuredPersonName)

            if (hasCreationDate && isBefore(bCreationDate, aCreationDate)) {
                return -1
            } else if (hasCreationDate && isBefore(aCreationDate, bCreationDate)) {
                return 1
            } else if (nameCompare !== 0) {
                return nameCompare
            } else {
                return a.documentName.localeCompare(b.documentName)
            }
        })
    }
)
