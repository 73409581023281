import { getDateStringFromDayMonthYear } from 'core/helpers/DateFormatHelper'
import { getDiffDays } from 'core/helpers/DateHelper'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import {
    AventuraAdditionalFamilyMemberModel,
    AventuraForm,
    AventuraPriceDetailsModel,
    AventuraPricingSummaryModel,
    newMember
} from 'core/models/services/coverage/AventuraForm'
import { TFunction } from 'i18next'
import { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'

const maxPeopleCount = 6

export const AventuraFormManager = {
    getPeopleCountList: (): SelectMap<string> => {
        const tmpPeopleCount = new Map() as SelectMap<string>
        for (let pc = 1; pc < maxPeopleCount + 1; pc++) {
            tmpPeopleCount.set(pc.toString(), pc.toString())
        }
        return tmpPeopleCount
    },
    getPeopleList: (people: PersonSummary[], t: TFunction): SelectMap<number | string> => {
        const tmpPeopleList = new Map() as SelectMap<number | string>

        for (const pl of people) {
            tmpPeopleList.set(`${pl.lastName} ${pl.firstName}`, pl.policyNumber)
        }

        tmpPeopleList.set(t('SERVICE.AVENTURA_PERSON_SELECTOR_OTHER_OPTION'), 'other')

        return tmpPeopleList
    },
    computePrice: (
        peopleCountValue: number,
        travelStartDate: Date,
        travelEndDate: Date,
        prices: AventuraPriceDetailsModel[],
        t: TFunction
    ): AventuraPricingSummaryModel | undefined => {
        const days = getDiffDays(new Date(travelStartDate), new Date(travelEndDate)) + 1

        const dayRate = prices && Array.isArray(prices) && prices.find((o) => o.days >= days)

        if (dayRate) {
            const rate = (() => {
                if (peopleCountValue === 1) return dayRate.priceForOnePerson
                if (peopleCountValue === 2) return dayRate.priceForTwoPeople
                if (peopleCountValue >= 3) return dayRate.priceForThreePeople
                return 0
            })()

            if (rate) {
                let pricingSubTitle = t('SERVICE.AVENTURA_FORM_PRICING_TRIP_SUMMARY_TEXT')
                pricingSubTitle = pricingSubTitle.replace('DAYS', days.toString())
                const pricingModel: AventuraPricingSummaryModel = {
                    price: rate,
                    subTitle: pricingSubTitle,
                    error: undefined
                }
                return pricingModel
            }
        } else {
            const pricingModel: AventuraPricingSummaryModel = {
                price: 0,
                subTitle: '',
                error: t('SERVICE.AVENTURA_FORM_DAYS_LIMIT_OVERFLOWS_WARNING')
            }
            return pricingModel
        }
    },
    addOrRemoveMembers: (
        peopleCountValue: number,
        aventuraMembers: AventuraAdditionalFamilyMemberModel[]
    ): AventuraAdditionalFamilyMemberModel[] => {
        // remove member
        if (peopleCountValue < aventuraMembers.length) {
            return aventuraMembers.slice(0, peopleCountValue)
        }
        // add member
        if (peopleCountValue > aventuraMembers.length) {
            const amount = peopleCountValue - aventuraMembers.length
            const newMembersSlots: AventuraAdditionalFamilyMemberModel[] = []
            for (let m = 0; m < amount; m++) {
                newMembersSlots.push(newMember(aventuraMembers.length + m, null, '', '', ''))
            }
            return [...aventuraMembers, ...newMembersSlots]
        }
        return []
    },
    prepareSubmitData: (aventuraFormData: AventuraForm, peopleCountValue: number): AventuraForm => {
        let tmpMembers: AventuraAdditionalFamilyMemberModel[] = []

        aventuraFormData.additionalFamilyMembers.map(
            (member: AventuraAdditionalFamilyMemberModel) => {
                const formattedBirthDate = getDateStringFromDayMonthYear(member.birthDate)

                tmpMembers.push({
                    ...member,
                    policyNumber: !member.policyNumber ? null : member.policyNumber,
                    birthDate: member.birthDate ? formattedBirthDate : member.birthDate
                })
            }
        )
        // retain only correct first count count of persons
        tmpMembers = tmpMembers.slice(0, peopleCountValue)

        const formattedTravelStartDate = getDateStringFromDayMonthYear(aventuraFormData.travelStart)
        const formattedTravelEndDate = getDateStringFromDayMonthYear(aventuraFormData.travelEnd)

        return {
            ...aventuraFormData,
            additionalFamilyMembers: [...tmpMembers],
            travelStart: formattedTravelStartDate,
            travelEnd: formattedTravelEndDate
        } as AventuraForm

        // Keep it as i may want to validate here
        // if (!aventuraDataBody.phoneNumber) {
        //     dispatch(setError(['Phoneis mandatory']))
        // }
    }
}
