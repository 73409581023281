import './NavBarEnveloppeSubmission.css'

import { useTranslation } from 'react-i18next'

import useWindowSize from 'core/services/useWindowSize'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'

import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'

const NavBarEnveloppeSubmission = (): JSX.Element => {
    const { t } = useTranslation()
    const { isXL } = useWindowSize()
    const { setNavBarMenuState } = useAssuraNavBarContext()

    const handleOnClick = () => {
        if (!isXL) setNavBarMenuState(false)
    }

    return (
        <AssuraEcLink
            ecLinkId="6463920efbf14b3565055dc5"
            classNames="nav-enveloppe-submission-nav-link"
            testId="navbar-enveloppe-submission"
            callback={handleOnClick}
        >
            <i
                className="icon assura-send2 size-24 m-right-8"
                data-testid="navbar-enveloppe-submission-icon"
            />
            <span className="cta">{t('NAVIGATION_BAR.SEND_ENVELOPPE_CTA')}</span>
        </AssuraEcLink>
    )
}

export default NavBarEnveloppeSubmission
