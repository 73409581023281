import { GeneralQuestionForm } from 'core/models/services/contact/GeneralQuestionForm'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {},
    reducers: {
        onSubmitGeneralQuestion: (state, _action: PayloadAction<GeneralQuestionForm>) => {
            return state
        }
    }
})

export const { onSubmitGeneralQuestion } = contactSlice.actions

export default contactSlice.reducer
