import { createSelector } from '@reduxjs/toolkit'

import { getPolicyDocumentList } from '../documents/documents.slice'
import { getSelectedFamilyMember } from './getSelectedFamilyMember'

export const getPolicyDocumentIdOfSelectedFamilyMember = createSelector(
    [getSelectedFamilyMember, getPolicyDocumentList],
    (selectedFamilyMember, policyDocuments) => {
        const selectedMemberDocument = policyDocuments?.documents.find(
            (doc) => doc.policyNumber === selectedFamilyMember.policyNumber
        )

        return selectedMemberDocument?.id
    }
)
