import './NoDoctorLamal.css'

import React from 'react'
import { useTranslation } from 'react-i18next'

import coverageIcon from 'shared/assets/images/coverage.png'

const NoDoctorLamal = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="no-active-contract-container bg-white bc-gray100 m-top-32 p-32 d-flex flex-column align-items-center text-center">
            <img src={coverageIcon} className="image-size-56" alt="no-active-contract-icon" />
            <div className="headlineSmall m-top-24">{t('SERVICES.NO_DOCTOR_LAMAL')}</div>
        </div>
    )
}

export default NoDoctorLamal
