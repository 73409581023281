/* eslint-disable sonarjs/cognitive-complexity */
import '../components/accidentReport.css'

import { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import { ServiceType } from 'core/enums/ServiceType'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { PersonSummary } from 'core/models/familyGroup/PersonSummary'
import { PersonalDataModel } from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import { phoneFormatter, phoneRulesValidate } from 'core/utils/phoneUtils'
import { isEmailValid } from 'core/utils/TextUtils'
import FamilyGroup, { FamilyGroupResult } from 'modules/services/components/FamilyGroup/FamilyGroup'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'
import { newVisibilitySkipPattern } from 'modules/services/components/Stepper/core/helpers'
import { StepItem } from 'modules/services/components/Stepper/core/models'
import { useStepperContext } from 'modules/services/components/Stepper/Stepper'
import UserInfo from 'modules/services/components/UserInfo/UserInfo'
import { ReactComponent as ExclamationMark } from 'shared/assets/images/exclamationMark.svg'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import { getSummaryByPage } from 'shared/store/combineSelectors'
import { getSummaryLoadingStatus } from 'shared/store/familySummaries/familySummaries.slice'
import { getPersonCoverageAndAccident } from 'shared/store/selectors/getAccidentCoverage'
import { getConnectedFamilyMember } from 'shared/store/selectors/getConnectedFamilyMember'
import { getFullPersonalData } from 'shared/store/selectors/getFullPersonalData'
import { getProductsLoaderStatus } from 'shared/store/selectors/getProductsLoaderStatus'
import {
    fetchContactDataAndProducts,
    getFormDataLoadingStatus,
    getKeepSelectedMember,
    goToServicesForm,
    setFamilyGroupResult,
    setKeepSelectedMember
} from 'shared/store/services/services.slice'

import { validateNaN } from '../../../../core/helpers/FieldValidationHelper'
import { getEmploymentStatusList, serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'PERSONAL_DATA'
const stillUnemployedYes = 'stillUnemployedYes'
interface FormSubmitErrors {
    selectedMemberError: string
    selectedFileError: string
    selectedFileNameError: string
}

export const personalDataFormFieldNameList: Array<keyof PersonalDataModel> = [
    'policyNumber',
    'email',
    'coverage',
    'phoneNumber',
    'employmentStatus',
    'weeklyHoursWork',
    'stillUnemployed'
]

export interface PersonalDataFormProps {
    id: number
}

const selectedMemberKey = 'selectedMember'
const contactDataTouchedKey = 'contactDataTouched'

interface ContactDataTouched {
    email: boolean
    phone: boolean
}

/**
 * Step1 description:
 * PERSONAL DATA
 *
 */
const PersonalDataForm = ({ id }: PersonalDataFormProps): JSX.Element => {
    const dispatch = useDispatch()
    const { isMobile, isMD } = useWindowSize()
    const currentForm = useRef(null)
    const { t } = useTranslation()
    const isPublic = Source() === ServicesSource.WEBSITE
    const personalData = useSelector(getFullPersonalData)
    const contactDataLoadStatus = personalData.contactDataLoadStatus

    const formDataLoadingStatus = useSelector(getFormDataLoadingStatus)
    const summaryLoadingStatus = useSelector(getSummaryLoadingStatus)
    const productsLoaderStatus = useSelector(getProductsLoaderStatus)

    const loadingStatus = getLoadingStatusFromLoaderList([
        contactDataLoadStatus,
        formDataLoadingStatus,
        summaryLoadingStatus,
        productsLoaderStatus
    ])

    const familySummary = useSelector(getSummaryByPage)
    const insuredPersons = familySummary?.insuredPersons

    const selectedMember = useSelector(getConnectedFamilyMember)

    const connectedMemberPolicyNumber = selectedMember?.policyNumber ?? 0
    //const insuredPersons = familySummary?.ecStatus
    const connectedMember = familySummary?.insuredPersons.find(
        (o) => o.policyNumber === connectedMemberPolicyNumber
    )
    // when it's selected from ouside the form (i.e. contact Form)
    const keptMember = useSelector(getKeepSelectedMember)
    const keptFamilySummaryMember = familySummary?.insuredPersons.find(
        (o) => o.policyNumber === keptMember?.selectedPolicyNumber
    )

    const personCoverageAndAccident = useSelector(getPersonCoverageAndAccident)

    const hasAccident = personCoverageAndAccident
        ? personCoverageAndAccident?.hasAccidentCoverage
        : false

    const { stepWatch, currentState, setStepVisibility, setStepperDefaultValue, resetForm } =
        useStepperContext()

    const { getValues, setValue, trigger } = useFormContext<PersonalDataModel>()

    const { PhoneInput, TextInput, SelectInput, RadioGroup } = useCustomControls(formName)

    const [formSubmitErrors, setFormSubmitErrors] = useState<FormSubmitErrors>({
        selectedMemberError: '',
        selectedFileError: '',
        selectedFileNameError: ''
    })

    const stepperState = currentState()
    const stepState = stepWatch(id) as StepItem

    const { policyNumber, stillUnemployed, employmentStatus } = getValues()
    const weeklyHoursWork = getValues('weeklyHoursWork') as number

    const isEmployeeOrApprentice = ['EMPLOYED', 'APPRENTICE'].includes(employmentStatus ?? '')
    const isUnemployed = ['UNEMPLOYED'].includes(employmentStatus ?? '')
    const hasStillUnemployed = stillUnemployed === stillUnemployedYes

    /** Custom Stepper Selector for selectedMember */
    const stepperDefaultMember =
        stepperState?.defaultValue && stepperState.defaultValue.length > 0
            ? (stepperState.defaultValue.find((o) => o.name === selectedMemberKey)
                  ?.value as PersonSummary)
            : undefined

    /** Custom Stepper Selector for contactDataTouched */
    const contactDataTouched =
        stepperState?.defaultValue && stepperState.defaultValue.length > 0
            ? (stepperState.defaultValue.find((o) => o.name === contactDataTouchedKey)
                  ?.value as ContactDataTouched)
            : undefined

    const [selectedLocalMember, setSelectedLocalMember] = useState<PersonSummary | undefined>(
        stepperDefaultMember ? stepperDefaultMember : isPublic ? connectedMember : undefined
    )

    const shouldDisplayContent = isPublic || !!selectedLocalMember

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        // Visibilité de ACC-E2
        // SI... [EmploymentStatus] = Salarié(e) ou apprenti(e)
        // ET/OU SI... [StillUnemployed] = True
        // ALORS... Afficher
        // SINON... passer cette étape
        const activateForm =
            selectedLocalMember !== undefined && loadingStatus === LoadingStatusEnum.OK

        /** Policy Number */
        if (selectedLocalMember?.policyNumber !== policyNumber) {
            setValue('policyNumber', selectedLocalMember?.policyNumber, {
                shouldDirty: true,
                shouldTouch: true
            })
        } else {
            if (connectedMember && isPublic) {
                setSelectedLocalMember(connectedMember)
                setFormSubmitErrors({ ...formSubmitErrors, selectedMemberError: '' })
            }

            // must be disabled until loadingStatus is ok
            setStepVisibility(
                [1, 2, 3, 4, 5, 6, 7],
                !!selectedLocalMember,
                // SKIP SEQUENCE
                [
                    newVisibilitySkipPattern(5, [
                        { name: 'cbdental', skipIfValue: false },
                        { name: 'eating', skipIfValue: 'eatingNo' }
                    ]),
                    newVisibilitySkipPattern(6, [{ name: 'cbcirculation', skipIfValue: false }])
                ]
            )

            if (personCoverageAndAccident) {
                setValue('coverage', personCoverageAndAccident?.coverageType)
            }
        }

        /** Coverage */
        const currentCoverage = getValues('coverage')
        if (
            personCoverageAndAccident &&
            currentCoverage !== personCoverageAndAccident?.coverageType
        ) {
            setValue('coverage', personCoverageAndAccident?.coverageType, {
                shouldDirty: true,
                shouldTouch: true
            })
        }

        if (selectedLocalMember && activateForm) {
            setStepVisibility(
                [1],
                !!selectedLocalMember &&
                    (isEmployeeOrApprentice ||
                        hasStillUnemployed ||
                        stillUnemployed === 'stillUnemployedYes')
            )

            if (
                !(
                    isEmployeeOrApprentice ||
                    hasStillUnemployed ||
                    stillUnemployed === 'stillUnemployedYes'
                )
            ) {
                resetForm(1)
            }

            if (!isEmployeeOrApprentice) {
                setValue('weeklyHoursWork', undefined, { shouldDirty: true, shouldTouch: true })
            } else {
                setValue('stillUnemployed', undefined, { shouldDirty: true, shouldTouch: true })
            }

            // display employment step
            setStepVisibility([2, 3, 4, 7], !!selectedLocalMember)

            // check if the email was modifier by the user
            const currentEmail = getValues('email')
            if (
                !currentEmail ||
                (contactDataTouched?.email === false &&
                    personalData.email &&
                    currentEmail !== personalData.email.data)
            ) {
                // if the email is not filled then the process will take this one
                setValue('email', personalData.email?.data ?? undefined, {
                    shouldDirty: true,
                    shouldTouch: true
                })
            }
            // check if the phone was modifier by the user
            const currentPhone = getValues('phoneNumber')
            if (
                !currentPhone ||
                (currentPhone.length > 16 && currentPhone.length < 12) ||
                (contactDataTouched?.phone === false &&
                    personalData?.phone?.data?.phoneNumber !== currentPhone)
            ) {
                // if the phone is not filled then the process will take this one
                setValue(
                    'phoneNumber',
                    phoneFormatter(personalData?.phone?.data?.phoneNumber ?? ''),
                    {
                        shouldDirty: true,
                        shouldTouch: true
                    }
                )
            }
        }
    }, [
        employmentStatus,
        stillUnemployed,
        selectedLocalMember,
        loadingStatus,
        policyNumber,
        connectedMember
    ])

    useEffect(() => {
        if (!keptFamilySummaryMember) return
        const tmpSelectedPerson: FamilyGroupResult = {
            isAllFamilyMembers: false,
            selectedPolicyNumber: keptFamilySummaryMember?.policyNumber
        }
        selectMember(tmpSelectedPerson)
    }, [keptFamilySummaryMember])

    const selectMember = (data: FamilyGroupResult) => {
        if (!data) return

        const member = insuredPersons.find(
            (m) =>
                m.policyNumber === keptFamilySummaryMember?.policyNumber ||
                m.policyNumber === data.selectedPolicyNumber
        )

        if (!member) return

        const newDataFamilyGroupResult = { ...data, ...member, policyNumber: member?.policyNumber }

        const policyNumber = getValues('policyNumber')

        if (policyNumber === member.policyNumber) return

        dispatch(setKeepSelectedMember(newDataFamilyGroupResult))
        setSelectedLocalMember(member)

        setFormSubmitErrors({ ...formSubmitErrors, selectedMemberError: '' })
        setStepperDefaultValue({ name: selectedMemberKey, value: member })

        setStepperDefaultValue({
            name: contactDataTouchedKey,
            value: { email: false, phone: false }
        })

        dispatch(setFamilyGroupResult(newDataFamilyGroupResult))

        newDataFamilyGroupResult.selectedPolicyNumber &&
            dispatch(
                fetchContactDataAndProducts({
                    policyNumber: newDataFamilyGroupResult.selectedPolicyNumber
                })
            )
    }

    const shouldDisplayWeeklyHoursRules =
        weeklyHoursWork && typeof weeklyHoursWork === 'string'
            ? Number.parseInt(weeklyHoursWork) > 8
            : weeklyHoursWork > 8
    const shouldDisplayUnemploymentRules = stillUnemployed && stillUnemployed === stillUnemployedYes
    const shouldDisplayAccidentsRules = hasAccident === false

    return (
        <div id={`${id}`} className="step-frame" ref={currentForm}>
            {!isPublic ? (
                <div className="m-bottom-48">
                    <FamilyGroup
                        selectAction={selectMember}
                        selectedMemberError={t(formSubmitErrors.selectedMemberError)}
                        serviceType={ServiceType.ACCIDENT_REPORT_BIS}
                        source={Source()}
                        hasDefaultSelectedMember={!!selectedLocalMember}
                        defaultSelectedPolicyNumber={selectedLocalMember?.policyNumber}
                    />
                </div>
            ) : (
                <>
                    {/* APPLICANT INFORMATION */}
                    <FormBlock titleTestId={'accident-details'} childrenMargin="m-bottom-48">
                        <UserInfo
                            allowedSources={[]}
                            style={{
                                border: 'none',
                                padding: 0,
                                margin: 0
                            }}
                        />
                    </FormBlock>
                </>
            )}
            {!shouldDisplayContent ? (
                <></>
            ) : (
                <AssuraLoadAndError
                    status={loadingStatus}
                    shouldDisplayContainer
                    defaultReloadAction={() =>
                        dispatch(
                            goToServicesForm({
                                type: ServiceType.ACCIDENT_REPORT
                            })
                        )
                    }
                >
                    {selectedMember ? (
                        <>
                            <FormBlock
                                titleTestId={'applicant-details'}
                                title={t(`${serviceName}.${formName}.APPLICANT_DETAILS`)}
                                childrenMargin="m-bottom-48"
                            >
                                {TextInput(
                                    'email',
                                    'APPLICANT_E_MAIL',
                                    {
                                        mandatory: true,
                                        minLength: 3,
                                        maxLength: 100,
                                        validate: (text: string): boolean | string => {
                                            return (
                                                isEmailValid(text) ||
                                                t('COMMON.EMAIL_ERROR_BAD_FORMAT')
                                            )
                                        }
                                    },
                                    !(isMobile || isMD),
                                    'APPLICANT_E_MAIL',
                                    () => {
                                        stepState?.hasBeenValidated && trigger('email')

                                        // avoid reset value if no family member was changed for this value
                                        setStepperDefaultValue({
                                            name: contactDataTouchedKey,
                                            value: {
                                                ...contactDataTouched,
                                                email: true
                                            }
                                        })
                                    }
                                )}

                                {PhoneInput(
                                    'phoneNumber',
                                    'APPLICANT_PHONE',
                                    {
                                        mandatory: true,
                                        validate: (value: string): boolean | string => {
                                            const validate = phoneRulesValidate(value)
                                            if (typeof validate === 'boolean' && validate)
                                                return true
                                            return t(phoneRulesValidate(value) as string)
                                        }
                                    },
                                    !(isMobile || isMD),
                                    'APPLICANT_PHONE',
                                    () => {
                                        stepState?.hasBeenValidated && trigger('phoneNumber')
                                        // avoid reset value if no family member was changed for this value
                                        setStepperDefaultValue({
                                            name: contactDataTouchedKey,
                                            value: {
                                                ...contactDataTouched,
                                                phone: true
                                            }
                                        })
                                    }
                                )}
                            </FormBlock>

                            {/* APPLICANT DETAILS */}
                            <FormBlock
                                titleTestId={'employment-status-at-the-accident-time'}
                                title={t(
                                    `${serviceName}.${formName}.EMPLOYMENT_STATUS_AT_THE_ACCIDENT_TIME`
                                )}
                                childrenTopMargin="m-top-48"
                                frameMarginBottom="m-bottom-32"
                            >
                                {SelectInput(
                                    'employmentStatus',
                                    'APPLICANT_EMPLOYMENT_STATUS',
                                    getEmploymentStatusList(t),
                                    { mandatory: true },
                                    !(isMobile || isMD),
                                    () => trigger('employmentStatus')
                                )}

                                {employmentStatus !== undefined &&
                                    !isUnemployed &&
                                    isEmployeeOrApprentice &&
                                    TextInput(
                                        'weeklyHoursWork',
                                        'APPLICANT_WEEK_WORKED_HOURS',
                                        {
                                            mandatory: !isUnemployed && isEmployeeOrApprentice,
                                            min: 4,
                                            max: 99,
                                            validate: (value) => validateNaN(value, t)
                                        },
                                        undefined,
                                        'APPLICANT_WEEK_WORKED_HOURS',
                                        () => trigger('weeklyHoursWork')
                                    )}

                                {employmentStatus !== undefined &&
                                    isUnemployed &&
                                    RadioGroup(
                                        'APPLICANT_UNEMPLOYMENT_BENEFITS_TITLE',
                                        'stillUnemployed',
                                        {
                                            mandatory: isUnemployed
                                        },
                                        () => trigger('stillUnemployed')
                                    )}

                                <>
                                    {(shouldDisplayWeeklyHoursRules ||
                                        shouldDisplayUnemploymentRules ||
                                        shouldDisplayAccidentsRules) && (
                                        <Container className="m-top-48">
                                            <Row className="p-32 bg-gray20 border-solid-width-1 bc-gray100">
                                                <Col
                                                    className="declaration-box"
                                                    data-testid="weekly-hours-declaration-box"
                                                >
                                                    <div className="col-icon">
                                                        <div className="exclamation-mark-icon">
                                                            <ExclamationMark />
                                                        </div>
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <div>
                                                            {shouldDisplayWeeklyHoursRules ? (
                                                                <>
                                                                    {/* Vous travaillez plus de 8 heures par semaine? Dans ce cas,.... */}
                                                                    {t(
                                                                        `SERVICE.ACCIDENT_REPORT.ACCIDENT_COVERAGE.LEGAL_NOTICE_ADD_4`
                                                                    )}
                                                                </>
                                                            ) : shouldDisplayUnemploymentRules ? (
                                                                <>
                                                                    {/* Depuis le 1er janvier 1996, la Caisse na....  */}
                                                                    {t(
                                                                        `SERVICE.ACCIDENT_REPORT.EMPLOYMENTSTATUS_UNEMPLOYED_MESSAGE`
                                                                    )}
                                                                </>
                                                            ) : shouldDisplayAccidentsRules ? (
                                                                <>
                                                                    {/* Has Accident */}
                                                                    {t(
                                                                        `SERVICE.ACCIDENT_REPORT.COVERAGE_ACCIDENT_INFO_SUBTITLE_NOT_INCLUDED`
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    )}
                                </>
                            </FormBlock>
                            <StepperFooter />
                        </>
                    ) : (
                        <></>
                    )}
                </AssuraLoadAndError>
            )}
        </div>
    )
}
export default PersonalDataForm
