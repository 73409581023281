import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { EnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { AssuraDocument } from 'core/models/AssuraDocument'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import ActivityIndicator from 'shared/components/ActivityIndicator/ActivityIndicator'
import AssuraDocumentsError from 'shared/components/AssuraDocumentsError/AssuraDocumentsError'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import AssuraPdfViewer from 'shared/components/AssuraPdfViewer/AssuraPdfViewer'
import { fetchEnveloppeImageByName } from 'shared/store/enveloppeImages/enveloppeImages.slice'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import EnveloppesPdfViewerHeader from './EnveloppesPdfViewerHeader'
import EnveloppesPdfViewerSubHeader from './EnveloppesPdfViewerSubHeader'

interface EnveloppesPdfViewerProps {
    documents: AssuraDocument[]
    enveloppeSelected?: EnveloppeRow
    closePanel: () => void
}

const EnveloppesPdfViewer = ({
    documents,
    enveloppeSelected,
    closePanel
}: EnveloppesPdfViewerProps): JSX.Element => {
    const dispatch = useDispatch()

    const [selectedPdfIndex, setSelectedPdfIndex] = useState(0)
    const isDownloadable =
        enveloppeSelected?.status === EnveloppeStatus.Received ||
        enveloppeSelected?.status === EnveloppeStatus.Validated

    const totalDocuments = documents.length

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => documents[selectedPdfIndex]?.fileName ?? ''
        }
    })

    const componentError = (): JSX.Element => {
        return (
            <AssuraDocumentsError
                onClick={() =>
                    dispatch(
                        fetchEnveloppeImageByName({
                            fileName: documents[selectedPdfIndex].fileName,
                            type: documents[selectedPdfIndex].type,
                            reload: true
                        })
                    )
                }
            />
        )
    }

    const renderPdfError = (): JSX.Element => componentError()

    const renderPdfLoader = (): JSX.Element => <ActivityIndicator size={40} />

    return (
        <>
            <EnveloppesPdfViewerHeader
                enveloppeSelected={enveloppeSelected}
                closePanel={closePanel}
                totalDocuments={totalDocuments}
            />
            <EnveloppesPdfViewerSubHeader
                totalDocuments={totalDocuments}
                selectedPdfIndex={selectedPdfIndex}
                setSelectedPdfIndex={setSelectedPdfIndex}
                toolbarPluginInstance={toolbarPluginInstance}
                loadingStatusIsOk={documents[selectedPdfIndex].loadStatus === LoadingStatusEnum.OK}
                isDownloadable={isDownloadable}
                document={documents[selectedPdfIndex]}
            />
            <AssuraLoadAndError
                status={documents[selectedPdfIndex].loadStatus}
                ErrorComponent={componentError()}
            >
                <AssuraPdfViewer
                    toolbarPluginInstance={toolbarPluginInstance}
                    doc={documents[selectedPdfIndex]}
                    renderPdfError={renderPdfError}
                    renderPdfLoader={renderPdfLoader}
                />
            </AssuraLoadAndError>
        </>
    )
}

export default EnveloppesPdfViewer
