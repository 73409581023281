import { ProductTypeEnum } from 'core/enums/Product'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { getCheckDate } from 'core/utils/dateUtils'

import { createSelector } from '@reduxjs/toolkit'

import { getProducts, getSelectedFamilyMember } from '../combineSelectors'
import { getProductsYear } from '../products/products.slice'

export const getLamalProductOfSelectedFamilyMember = createSelector(
    [getProducts, getSelectedFamilyMember],
    (products, selectedFamilyMember) => {
        let enrichedLamalProduct: ProductPerson | undefined = undefined
        const lamalProduct = selectedFamilyMember?.productsPerson?.products?.find(
            (productPerson) => productPerson.type === ProductTypeEnum.LAMAL
        )
        if (lamalProduct) {
            enrichedLamalProduct = { ...lamalProduct }
            const correspondingProduct = products.find(
                (product) => product.insuranceId === lamalProduct.code
            )
            if (correspondingProduct) {
                enrichedLamalProduct.title = correspondingProduct.title
                enrichedLamalProduct.choiceOfPharmacy = correspondingProduct.choiceOfPharmacy
                enrichedLamalProduct.choiceOfDoctor = correspondingProduct.choiceOfDoctor
                enrichedLamalProduct.infoLamal = correspondingProduct.infoLamal
                enrichedLamalProduct.infoLamalPublic = correspondingProduct.infoLamalPublic
            }
        }

        return enrichedLamalProduct
    }
)

export const getLamalProductOfSelectedFamilyMemberSelectedYear = createSelector(
    [getProducts, getSelectedFamilyMember, getProductsYear],
    (products, selectedFamilyMember, selectedYear) => {
        let enrichedLamalProduct: ProductPerson | undefined = undefined
        const lamalProduct = selectedFamilyMember?.productsPerson?.products?.find(
            (productPerson) =>
                productPerson.type === ProductTypeEnum.LAMAL &&
                new Date(productPerson.startDate) <= getCheckDate(selectedYear)
        )
        if (lamalProduct) {
            enrichedLamalProduct = { ...lamalProduct }
            const correspondingProduct = products.find(
                (product) => product.insuranceId === lamalProduct.code
            )
            if (correspondingProduct) {
                enrichedLamalProduct.title = correspondingProduct.title
                enrichedLamalProduct.choiceOfPharmacy = correspondingProduct.choiceOfPharmacy
                enrichedLamalProduct.choiceOfDoctor = correspondingProduct.choiceOfDoctor
            }
        }

        return enrichedLamalProduct
    }
)
