import { useEffect, useState } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { commonMandatoryErrorKey } from './constants'
import {
    UseValidationOptions,
    ValidationConfiguration,
    ValidationResult,
    ValidationValues
} from './entities'
import { createValidationResult } from './helpers'
import { getValidationRules } from './rules'
import Validators from './validators'

// This custom hook is intended tobe used to provide an encapsulated way to validate a field.
//
// It works like a factory based on: UseValidationOptions defined for each type of field.
// the last dependency is the validation rules based on : ValidationConfiguration
//
// Outputs validation Result and validation Rules
// - validation Rules are needed by RHF in order to register the input field. It provides a datastructure which describes the validation rule
// - validation Result are needed by InputErrors and InputValidations in order to display validation issues/errors
//
// How to use it ?
// 1) define a file for the field you want to validate.
// 2) in this file define :
//                          - UseValidationOptions
//                          - ValidationConfiguration
//                          - export [use[FieldName]]Validation = useValidation(UseValidationOptions)(ValidationConfiguration)
//
//
// 3) in the form file use the field AssuraFormInputWithValidations
// 4) define the fieldHook const [FieldName]ValidationResult, [FieldName]ValidationRules = use[FieldName]Validation(setValue(data.newLogin))
//
export const useValidation = (options: UseValidationOptions) => (
    configuration: (options: UseValidationOptions, t: TFunction) => ValidationConfiguration[]
) => (
    // use the entities.setValue() function helper in order to set values easely.
    value: ValidationValues
): [
    ValidationResult,
    () => Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>,
    UseValidationOptions
] => {
    const { t } = useTranslation()

    const [validResult, setVaildResult] = useState<ValidationResult>(
        createValidationResult(options, {}, t)
    )

    // preconfigure validation method with the configuration object, options and translation helper.
    const validationMethod = Validators.validate(configuration(options, t))

    // when the value.mainValue, value.compareValue are updated then we execute the validation methods defined in configuration
    useEffect(() => {
        setVaildResult(createValidationResult(options, validationMethod(value), t))
    }, [value.mainValue, value.compareValue])

    // return validated result
    // return validation rules
    return [
        validResult,
        () =>
            getValidationRules(
                validResult.validations,
                t(commonMandatoryErrorKey),
                options.required
            ),
        options
    ]
}
