import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ServicesHubItem } from 'core/models/services/ServicesHub'

import AllRequestsItem from './AllRequestsItem'

interface AllRequestsProps {
    items: ServicesHubItem[]
}

const AllRequests = ({ items }: AllRequestsProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Container>
            <Row>
                <Col className="m-bottom-32 headlineSmall">{t('SERVICES.ALL_REQUESTS')}</Col>
            </Row>
            <Row>
                {items.map((item) => (
                    <AllRequestsItem
                        item={item}
                        key={`all-requests-item-${item.id}`}
                    />
                ))}
            </Row>
        </Container>
    )
}

export default AllRequests
