import { FieldValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'

import { ErrorMessage } from '@hookform/error-message'

type SubmitProps = {
    membersCount: number
    register: UseFormRegister<FieldValues>
    submitStatus: LoadingStatusEnum
    validateAndSubmit: () => void
}

const Submit = ({ membersCount, register, submitStatus, validateAndSubmit }: SubmitProps) => {
    const { t } = useTranslation()

    return (
        <>
            {membersCount > 1 && (
                <div
                    className="av-submit-confirmation-block bg-white bc-gray100 m-bottom-32"
                    style={{ alignItems: 'flex-start' }}
                >
                    <CheckboxRHF
                        id={'services-form-consent'}
                        name="consent"
                        label={t('SERVICE.AVENTURA_SUBMIT_CONFIRMATION_CHECK_TEXT')}
                        register={register}
                        classNames="flex-1 submit-confirmation-check"
                        rules={{
                            required: {
                                value: true,
                                message: 'COMMON.MANDATORY_FIELD'
                            }
                        }}
                    />
                    <ErrorMessage
                        name="consent"
                        render={({ message }) => (
                            <div
                                className="labelExtraSmall c-primary m-top-8 m-left-32"
                                data-testid="services-form-consent-error"
                            >
                                {t(message)}
                            </div>
                        )}
                    />
                    <div className="av-submit-confirmation-data"></div>
                </div>
            )}
            <div className="av-submit-block">
                <div className="av-submit-block-data">
                    <AssuraButton
                        text={t('COMMON.SEND')}
                        id="services-form-submit-button"
                        variant="primary"
                        onClick={validateAndSubmit}
                        hasLoader={submitStatus === LoadingStatusEnum.LOADING}
                    />
                </div>
            </div>
        </>
    )
}
export default Submit
