import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { RequestStatus } from 'core/enums/RequestStatus'
import { ServiceType } from 'core/enums/ServiceType'
import { FinancialFetchResponse } from 'core/models/services/personalData/Financial'

import { createSelector } from '@reduxjs/toolkit'

import { HttpResponseEnum } from '../../../core/enums/HttpResponseEnum'
import { getFinancial } from '../services/financial/financial.slice'
import { getServicesRequests } from '../services/requests/requests.slice'
import { getSelectedMemberPolicyNumber } from '../services/services.slice'
import { getConnectedFamilyMember } from './getConnectedFamilyMember'

export interface FinancialDataView {
    financialDataLoadStatus: LoadingStatusEnum
    financialData: Omit<FinancialFetchResponse, 'ownerAddress' | 'ownerLocality'>
    isPending: boolean, 
    wsStatus: HttpResponseEnum
}

export const getFullFinancialData = createSelector(
    [getFinancial, getSelectedMemberPolicyNumber, getServicesRequests, getConnectedFamilyMember],
    (financialList, policyNumber, requests, connectedPerson): FinancialDataView => {
        const financialData = financialList[policyNumber ?? 0]

        const hasPendingRequest = requests.find(
            (request) =>
                request.status === RequestStatus.PENDING &&
                request.type === ServiceType.BANK_REL_CHANGE &&
                request.policyNumber === connectedPerson?.policyNumber
        )
        return {
            financialDataLoadStatus: financialData?.loadingStatus ?? LoadingStatusEnum.ERROR,
            financialData:
                {
                    ownerName: financialData?.datas?.ownerName ?? null,
                    type: financialData?.datas?.type ?? 'iban',
                    iban: financialData?.datas?.iban ?? null,
                    ccp: financialData?.datas?.ccp ?? null
                } ?? null,
            isPending: !!hasPendingRequest, 
            wsStatus: financialData?.wsStatus ?? HttpResponseEnum.SUCCESS
        }
    }
)
