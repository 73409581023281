import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { NewsCms } from 'core/models/cms/NewsCms'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface NewsState {
    newsCms: NewsCms[]
    newsLoadingStatus: LoadingStatusEnum
}

const INITIAL_STATE_NEWS: NewsState = {
    newsCms: [],
    newsLoadingStatus: LoadingStatusEnum.LOADING
}
export const newsSlice = createSlice({
    name: 'news',
    initialState: INITIAL_STATE_NEWS,
    reducers: {
        fetchNewsCms: (state) => {
            return state
        },
        fetchNewsCmsSuccess: (state, action: PayloadAction<NewsCms[]>) => {
            state.newsCms = action.payload
        },
        setNewsLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.newsLoadingStatus = action.payload
        }
    }
})

export const { fetchNewsCms, fetchNewsCmsSuccess, setNewsLoadingStatus } = newsSlice.actions

export default newsSlice.reducer

export const getNewsCms: Selector<RootState, NewsCms[]> = (state) => state.news.newsCms

export const getNewsLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.news.newsLoadingStatus
