import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchDocumentsV3 } from 'core/api/Documents'
import { DocumentsGroupToCategories } from 'core/constants/documentsCategories'
import { DocumentsGroupEnum, FetchDocumentsTypeEnum } from 'core/enums/Documents'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getPaymentStatusByGroup } from 'core/helpers/documents/DocumentsHelper'
import {
    Document,
    DocumentsData,
    DocumentsDataState,
    DocumentsDataWS,
    DocumentsGroup,
    DocumentsRequestBodyV3,
    FetchDocumentsCommonPayload
} from 'core/models/documents/Document'
import { DocumentRow } from 'core/models/documents/DocumentRow'

import { getSortedInvoicesForTable } from '../selectors/getSortedInvoicesForTable'
import { getSortedRefundsForTable } from '../selectors/getSortedRefundsForTable'
import {
    fetchInvoicesData,
    fetchRefundsData,
    getFinancialDocumentsFilteringLoadingStatus,
    getFinancialDocumentsPaginatingLoadingStatus,
    getInvoicesData,
    getRefundsData,
    getSelectedFinancialDocumentsGroup,
    seInvoicesData,
    setFilteringLoadingStatus,
    setInitLoadingStatus,
    setPaginatingLoadingStatus,
    setRefundsData,
    setSelectedDocument,
    setSelectedDocumentsGroup,
    setSelectedFinancialDocumentId
} from './financialDocuments.slice'

function* getDocuments(payload: FetchDocumentsCommonPayload) {
    try {
        const { selectedDocumentsGroup, type, paginationDate, filters, docsByPage } = payload

        const { date = null, utn = null } = filters ?? {}
        const paymentStatus = getPaymentStatusByGroup(selectedDocumentsGroup)
        const categoriesId = DocumentsGroupToCategories[selectedDocumentsGroup]

        const paginationArchivingStartDate =
            type === FetchDocumentsTypeEnum.PAGINATING ? paginationDate : null

        const requestBody: DocumentsRequestBodyV3 = {
            paymentStatus,
            userTiersNumber: utn,
            startDate: date?.startDate ?? null,
            endDate: date?.endDate ?? null,
            categoriesId,
            docsByPage: docsByPage || 10,
            paginationArchivingStartDate
        }

        const documentsData: DocumentsDataWS = yield call(fetchDocumentsV3, requestBody)

        let currentDocuments: Document[] = []

        if (type === FetchDocumentsTypeEnum.PAGINATING) {
            if (selectedDocumentsGroup === DocumentsGroupEnum.INVOICES) {
                const currentDocumentsData: DocumentsData = yield select(getInvoicesData)
                currentDocuments = [...currentDocumentsData.documents]
            }
            if (selectedDocumentsGroup === DocumentsGroupEnum.REFUNDS) {
                const currentDocumentsData: DocumentsData = yield select(getRefundsData)
                currentDocuments = [...currentDocumentsData.documents]
            }
        }

        const newState: DocumentsData = {
            documents: currentDocuments.concat(...documentsData.documents),
            hasMore: documentsData.hasMore,
            paginationStartDate: documentsData.paginationStartDate
        }
        if (selectedDocumentsGroup === DocumentsGroupEnum.INVOICES) {
            yield put(seInvoicesData(newState))
        } else if (selectedDocumentsGroup === DocumentsGroupEnum.REFUNDS) {
            yield put(setRefundsData(newState))
        }
    } catch (e) {
        console.error('getDocuments Error', e)
        throw e
    }
}

function* getFilteredDocumentsSaga(payload: FetchDocumentsCommonPayload) {
    try {
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.LOADING))
        yield call(getDocuments, payload)
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.OK))

        const invoicesData: DocumentsDataState = yield select(getInvoicesData)
        const refundsData: DocumentsDataState = yield select(getRefundsData)

        if (invoicesData.initLoadingStatus === LoadingStatusEnum.LOADING) {
            yield put(
                setInitLoadingStatus({
                    selectedDocumentsGroup: DocumentsGroupEnum.INVOICES,
                    initLoadingStatus: LoadingStatusEnum.OK
                })
            )
        }
        if (refundsData.initLoadingStatus === LoadingStatusEnum.LOADING) {
            yield put(
                setInitLoadingStatus({
                    selectedDocumentsGroup: DocumentsGroupEnum.REFUNDS,
                    initLoadingStatus: LoadingStatusEnum.OK
                })
            )
        }
    } catch (e) {
        console.error('getFilteredDocumentsSaga Error', e)
        yield put(setFilteringLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* getNextPageSaga(payload: FetchDocumentsCommonPayload) {
    try {
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.LOADING))
        yield call(getDocuments, payload)
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('getNextPageSaga Error', e)
        yield put(setPaginatingLoadingStatus(LoadingStatusEnum.ERROR))
    }
}

function* getDocumentsSaga(payload: FetchDocumentsCommonPayload) {
    try {
        yield put(
            setInitLoadingStatus({
                selectedDocumentsGroup: payload.selectedDocumentsGroup,
                initLoadingStatus: LoadingStatusEnum.LOADING
            })
        )
        const filteringLoadingStatus: LoadingStatusEnum = yield select(
            getFinancialDocumentsFilteringLoadingStatus
        )
        if (filteringLoadingStatus === LoadingStatusEnum.ERROR) {
            yield put(setFilteringLoadingStatus(LoadingStatusEnum.OK))
        }
        const paginatingLoadingStatus: LoadingStatusEnum = yield select(
            getFinancialDocumentsPaginatingLoadingStatus
        )
        if (paginatingLoadingStatus === LoadingStatusEnum.ERROR) {
            yield put(setPaginatingLoadingStatus(LoadingStatusEnum.OK))
        }

        yield put(setSelectedDocumentsGroup(payload.selectedDocumentsGroup))

        yield call(getDocuments, payload)
        yield put(
            setInitLoadingStatus({
                selectedDocumentsGroup: payload.selectedDocumentsGroup,
                initLoadingStatus: LoadingStatusEnum.OK
            })
        )
    } catch (e) {
        console.error('getDocumentsSaga Error', e)
        yield put(
            setInitLoadingStatus({
                selectedDocumentsGroup: payload.selectedDocumentsGroup,
                initLoadingStatus: LoadingStatusEnum.ERROR
            })
        )
    }
}

export function* fetchFinancialDocumentsDataSaga(payload: FetchDocumentsCommonPayload) {
    try {
        switch (payload.type) {
            case FetchDocumentsTypeEnum.FILTERING:
                yield call(getFilteredDocumentsSaga, payload)
                break
            case FetchDocumentsTypeEnum.PAGINATING:
                yield call(getNextPageSaga, payload)
                break
            default:
                yield call(getDocumentsSaga, payload)
                break
        }
    } catch (e) {
        console.error('fetchFinancialDocumentsDataSaga Error', e)
    }
}

export function* fetchInvoicesDataSaga(action: ReturnType<typeof fetchInvoicesData>) {
    try {
        yield call(fetchFinancialDocumentsDataSaga, {
            ...action.payload,
            selectedDocumentsGroup: DocumentsGroupEnum.INVOICES
        })
    } catch (e) {
        console.error('fetchinvoiceDataSaga Error', e)
    }
}

export function* fetchRefundsDataSaga(action: ReturnType<typeof fetchRefundsData>) {
    try {
        yield call(fetchFinancialDocumentsDataSaga, {
            ...action.payload,
            selectedDocumentsGroup: DocumentsGroupEnum.REFUNDS
        })
    } catch (e) {
        console.error('fetchRefundsDataSaga Error', e)
    }
}

export function* setSelectedFinancialDocumentIdSaga(
    action: ReturnType<typeof setSelectedFinancialDocumentId>
) {
    try {
        const { documentId, documentsGroup } = action.payload

        if (documentsGroup) {
            yield put(setSelectedDocumentsGroup(documentsGroup))
        }

        const selectedDocumentsGroup: DocumentsGroup = yield select(
            getSelectedFinancialDocumentsGroup
        )

        let newSelectedDocument: DocumentRow | null = null

        if (documentId !== null) {
            if (selectedDocumentsGroup === DocumentsGroupEnum.INVOICES) {
                const invoicesRows: DocumentRow[] = yield select(getSortedInvoicesForTable)
                const document = invoicesRows.find(
                    (doc: DocumentRow) => doc.documentId === documentId
                )
                newSelectedDocument = document ?? null
            }

            if (selectedDocumentsGroup === DocumentsGroupEnum.REFUNDS) {
                const refundsRows: DocumentRow[] = yield select(getSortedRefundsForTable)
                const document = refundsRows.find(
                    (doc: DocumentRow) => doc.documentId === documentId
                )
                newSelectedDocument = document ?? null
            }
        }

        yield put(setSelectedDocument(newSelectedDocument))
    } catch (e) {
        console.error('setSelectedFinancialDocumentIdSaga Error', e)
    }
}

function* setSelectedFinancialDocumentIdWatcher() {
    yield takeEvery(setSelectedFinancialDocumentId.type, setSelectedFinancialDocumentIdSaga)
}

function* fetchInvoicesDataWatcher() {
    yield takeLatest(fetchInvoicesData.type, fetchInvoicesDataSaga)
}

function* fetchRefundsDataWatcher() {
    yield takeLatest(fetchRefundsData.type, fetchRefundsDataSaga)
}

const watchers = [
    fork(setSelectedFinancialDocumentIdWatcher),
    fork(fetchInvoicesDataWatcher),
    fork(fetchRefundsDataWatcher)
]

export default watchers
