import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import useWindowSize from 'core/services/useWindowSize'
import DateField from 'shared/components/AssuraTable/Fields/DateField'
import GoToField from 'shared/components/AssuraTable/Fields/GoToField'
import Separator from 'shared/components/AssuraTable/Fields/Separator'
import StatusField from 'shared/components/AssuraTable/Fields/StatusField'
import StatusIconField from 'shared/components/AssuraTable/Fields/StatusIconField'
import TextField from 'shared/components/AssuraTable/Fields/TextField'

import EnveloppesTableDocumentsField from './EnveloppesTableDocumentsField'

interface EnveloppesTableRowMobileProps {
    row: EnveloppeRow
}

const EnveloppesTableRowMobile = ({ row }: EnveloppesTableRowMobileProps): JSX.Element => {
    const { isXL } = useWindowSize()

    const rowId = `enveloppes-${row.index}`

    return (
        <>
            <td className="w-100">
                <table className="w-100">
                    <tbody>
                        <tr className="d-flex justify-content-start align-items-center m-bottom-8">
                            <StatusIconField
                                statusColor={row.statusColor}
                                rowId={rowId}
                                isMobileStyle
                            />
                            <DateField
                                date={row.receiveDate ?? ''}
                                rowId={rowId}
                                classNames="labelExtraSmall"
                                isMobileStyle
                            />
                            <Separator />
                            <StatusField
                                status={row.statusTranslated}
                                statusIcon={row.statusIcon}
                                statusColor={row.statusColor}
                                rowId={rowId}
                                labelClassNames="labelExtraSmall"
                                isMobileStyle
                            />
                            {row.insuredPersonName && (
                                <>
                                    <Separator />
                                    <TextField
                                        label={row.insuredPersonName}
                                        classNames="labelExtraSmall"
                                        rowId={rowId}
                                        fieldId="insured-person"
                                    />
                                </>
                            )}
                        </tr>
                        <tr>
                            <EnveloppesTableDocumentsField
                                enveloppe={row}
                                rowId={rowId}
                                labelClassName="label"
                                mobileStyle={!isXL}
                            />
                        </tr>
                    </tbody>
                </table>
            </td>
            <GoToField rowId={rowId} marginLeft={8} />
        </>
    )
}

export default EnveloppesTableRowMobile
