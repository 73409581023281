import { all, call, put, select, spawn } from 'redux-saga/effects'

import { PopinItemEnum } from 'core/enums/Popin'
import { PopinItem } from 'core/models/Popin'

import { cguPopinInit } from '../general/general.saga'
import { showEcPlusPopup } from '../selectors/showEcPlusPopup'
import { qualiMedRemindPopinInit } from '../settings/qualiMedRemind/qualiMedRemind.saga'
import { selectLastWhatsNewSaga, whatsNewPopinInit } from '../whatsNew/whatsNew.saga'
import { getSelectedPopinItem, setSelectedPopinItem } from './popin.slice'

//-- ORDER FOR POPIN ITEMS --//
// 1 - CGU (Mandatory)
// 2 - QUALIMED (Optional)
// 3 - EC+ (Optional)
// 4 - WHATS NEW (Optional)

function* triggerPopinItem(targetedItem: PopinItem) {
    const selectedItem: PopinItem | null = yield select(getSelectedPopinItem)
    if (selectedItem === null) {
        yield put(setSelectedPopinItem({ popinItem: targetedItem }))
    }
}

export function* initPopinSaga() {
    //MANDATORY POPIN
    let shouldTriggerOptionals = false

    try {
        const [cguStatus]: [boolean] = yield all([call(cguPopinInit)]) // ADD HERE OTHER MANDATORY POPIN

        // ORDER FOR MANDATORY POPIN ITEMS
        if (cguStatus) { 
            yield call(triggerPopinItem, PopinItemEnum.CGU)
        } else {
            // KEEP THIS ELSE BLOCK IN LAST POSITION
            shouldTriggerOptionals = true
        }
    } catch (e) {
        console.error('initPopinSaga Error', e)
    } finally {
        if (shouldTriggerOptionals) {
            yield spawn(optionalPopinSaga)
        }
    }
}

function* optionalPopinSaga() {
    try {
        const [qualiMedRulesStatus, ecPlusStatus, whatsNewStatus]: [boolean, boolean, boolean] =
            yield all([ // ADD HERE OTHER OPTIONAL POPIN
                call(qualiMedRemindPopinInit),
                select(showEcPlusPopup),
                call(whatsNewPopinInit)
            ])

        let optionalPopin: PopinItem | null = null

        // ORDER FOR OPTIONAL POPIN ITEMS & CALLBACK IF NEEDED
        if (qualiMedRulesStatus) {
            optionalPopin = PopinItemEnum.QUALIMED
        } else if (ecPlusStatus) {
            optionalPopin = PopinItemEnum.ECPLUS
        } else if (whatsNewStatus) {
            yield call(selectLastWhatsNewSaga)
            optionalPopin = PopinItemEnum.WHATSNEW
        }
 
        if (optionalPopin) {
            yield call(triggerPopinItem, optionalPopin)
        }
    } catch (e) {
        console.error('optionalPopinSaga Error', e)
    }
}
