import { useTranslation } from 'react-i18next'

import { useCguPanelContext } from 'shared/contexts/CguPanelContext'

import AssuraPanelHeader from '../AssuraPanelHeader/AssuraPanelHeader'

interface CguPanelHeaderProps {
    headerPanelTitle?: string
}

const CguPanelHeaderCenter = ({ headerPanelTitle }: CguPanelHeaderProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex align-items-center justify-content-center">
            <div
                data-testid="cgu-panel-header-title"
                className="labelMedium assura-panel-center-block-ellipsis"
            >
                {headerPanelTitle || t('COMMON.CGU')}
            </div>
        </div>
    )
}

const CguPanelHeader = ({ headerPanelTitle }: CguPanelHeaderProps): JSX.Element => {
    const { closeCguPanel } = useCguPanelContext()

    return (
        <AssuraPanelHeader
            centerBlock={<CguPanelHeaderCenter headerPanelTitle={headerPanelTitle} />}
            closePanel={closeCguPanel}
            sideBlockWidthAboveXs={40}
        />
    )
}

export default CguPanelHeader
