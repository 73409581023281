import { useEffect, useState } from 'react'
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AssuraDocumentType } from 'core/enums/DocumentType'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { hasWritePermission } from 'core/helpers/AuthenticationHelper'
import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import { ExtraRHFRef } from 'core/models/services/RHF'
import { EnveloppesSubmissionFormRhf } from 'modules/enveloppes/pages/EnveloppesSubmission'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import {
    removeAllNonPermanentBanners,
    setBanner,
    setForbiddenBanner
} from 'shared/store/banners/banners.slice'
import {
    DataUploadFiles,
    getRescanImagesBatchNumber,
    getSubmitEnveloppeStatus,
    onSubmitEnveloppe,
    setRescanImagesBatchNumber
} from 'shared/store/enveloppes/enveloppes.slice'
import { useAppDispatch } from 'shared/store/store'

import EnveloppesSubmissionAcceptedFile from '../EnveloppesSubmissionAcceptedFile/EnveloppesSubmissionAcceptedFile'
import EnveloppesSubmissionDropzone from '../EnveloppesSubmissionDropzone/EnveloppesSubmissionDropzone'
import EnveloppesSubmissionNameInput from '../EnveloppesSubmissionNameInput/EnveloppesSubmissionNameInput'
import EnveloppesSubmissionPanel from '../EnveloppesSubmissionPanel/EnveloppesSubmissionPanel'

const EnveloppesSubmissionForm = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const submitEnveloppeStatus = useSelector(getSubmitEnveloppeStatus)
    const rescanImagesBatchNumber = useSelector(getRescanImagesBatchNumber)

    const [pagesCount, setPagesCount] = useState(0)
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false)
    const [pdfFileBase64, setPdfFileBase64] = useState<string>('')
    const [pdfFileName, setPdfFileName] = useState<string>('')
    const [firstFileIsLoaded, setFirstFileIsLoaded] = useState(false)
    const [currentRescanImagesBatchNumber, setCurrentRescanImagesBatchNumber] = useState<
        string | undefined
    >(undefined)

    useEffect(() => {
        if (!rescanImagesBatchNumber) return
        setCurrentRescanImagesBatchNumber(rescanImagesBatchNumber)
        dispatch(setRescanImagesBatchNumber(undefined))
    }, [rescanImagesBatchNumber])

    const rhfDocumentsName = 'documents'
    const {
        setError,
        clearErrors,
        watch,
        setValue,
        trigger,
        formState: { isSubmitted }
    } = useFormContext()

    const { fields, append, remove } = useFieldArray<EnveloppesSubmissionFormRhf>({
        name: rhfDocumentsName
    })

    const extraRhfRefs: ExtraRHFRef[] = [
        {
            name: rhfDocumentsName,
            id: 'enveloppes-submission-dropzone'
        }
    ]

    const addPdf = (droppedFile: DroppedFile) => {
        if (!firstFileIsLoaded) setFirstFileIsLoaded(true)
        append(droppedFile)
    }

    const handleSeeFile = (pdf64: string, fileName: string) => {
        setPdfFileBase64(pdf64)
        setPdfFileName(fileName)
        setIsPanelOpen(true)
    }

    const handleRemovePdf = (index: number) => {
        remove(index)
    }

    const onSubmit = (values: FieldValues): void => {
        if (!hasWritePermission()) {
            dispatch(setForbiddenBanner())
            return
        }

        const { policyNumber, name, documents } = values as EnveloppesSubmissionFormRhf

        if (policyNumber && pagesCount < 100 && fields.length > 0) {
            const payload: DataUploadFiles = {
                name,
                policyNumber: policyNumber.toString(),
                pdfFiles: documents,
                batchNumber: currentRescanImagesBatchNumber
            }

            dispatch(onSubmitEnveloppe(payload))
        }
    }

    const enveloppeName = watch('name')

    useEffect(() => {
        if (fields.length === 0 && isSubmitted) {
            setError('documents', {
                type: 'required',
                message: 'COMMON.MANDATORY_FIELD'
            })
        } else {
            clearErrors('documents')
            if (fields.length === 1 && enveloppeName === '')
                setValue('name', fields[0].fileName.replace('.pdf', ''))
            if (isSubmitted) trigger('name')
        }

        const newPagesCount = fields.reduce((acc, field) => acc + field.pagesCount, 0)
        setPagesCount(newPagesCount)
    }, [fields, isSubmitted])

    useEffect(() => {
        if (pagesCount > 99) {
            dispatch(
                setBanner({
                    dataTestId: 'enveloppes-submission-too-many-pages-error',
                    message: 'ENVELOPPES.SUBMISSION_PAGES_COUNT_ERROR'
                })
            )
        } else {
            dispatch(removeAllNonPermanentBanners())
        }
    }, [pagesCount])

    return (
        <>
            <div className="position-relative">
                {firstFileIsLoaded && (
                    <div className="enveloppes-submission-dropped-files-container border-solid-width-1 p-32 m-top-32 m-bottom-32 bg-white bc-gray100">
                        <div className="labelMedium m-bottom-24">
                            {t('ENVELOPPES.SUBMISSION_ACCEPTED_FILES_TITLE', {
                                count: fields.length
                            })}
                        </div>
                        {fields.map((field, index) => (
                            <EnveloppesSubmissionAcceptedFile
                                index={index}
                                key={field.id}
                                droppedFile={field}
                                seeFile={() => handleSeeFile(field.pdfFileBase64, field.fileName)}
                                onDelete={() => handleRemovePdf(index)}
                            />
                        ))}
                    </div>
                )}
                <EnveloppesSubmissionDropzone addPdf={addPdf} />
                {firstFileIsLoaded && (
                    <EnveloppesSubmissionNameInput testId="enveloppes-submission-name" />
                )}
                <EnveloppesSubmissionPanel
                    isPanelOpen={isPanelOpen}
                    closePanel={() => setIsPanelOpen(false)}
                    pdf={{
                        fileName: pdfFileName,
                        base64: pdfFileBase64,
                        loadStatus: LoadingStatusEnum.OK,
                        type: AssuraDocumentType.PDF
                    }}
                />
            </div>
            <div className="d-inline-flex enveloppes-submit-buttons-container">
                <AssuraButton
                    text={t('COMMON.CANCEL')}
                    id="enveloppes-submission-cancel-button"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                />
                <SubmitButtonWithScroll
                    id="enveloppes-submission-submit-button"
                    onSubmit={onSubmit}
                    hasLoader={submitEnveloppeStatus === LoadingStatusEnum.LOADING}
                    classNames="m-left-12"
                    extraRhfRefs={extraRhfRefs}
                />
            </div>
        </>
    )
}

export default EnveloppesSubmissionForm
