import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import { Product } from 'core/models/Product'

import { createSelector } from '@reduxjs/toolkit'

import { getFutureProductsOfSelectedFamilyMember } from './getFutureProductsOfSelectedFamilyMember'
import { getLcaProductsOfSelectedFamilyMember } from './getLcaProductsOfSelectedFamilyMember'
import { getRecommendedProducts } from './getRecommendedProducts'

export const getRecommendedProductsOfSelectedFamilyMember = createSelector(
    [
        getRecommendedProducts,
        getLcaProductsOfSelectedFamilyMember,
        getFutureProductsOfSelectedFamilyMember
    ],
    (
        recommendedProducts,
        lcaProductsOfSelectedFamilyMember,
        lcaProductsFutureOfSelectedFamilyMember
    ) => {
        
        const recommendedProductsOfSelectedFamilyMember: RecommendedProduct[] = []
        const lcaProducts = lcaProductsOfSelectedFamilyMember.concat(
            lcaProductsFutureOfSelectedFamilyMember.lca
        )

        recommendedProducts.forEach((recommendedProduct: RecommendedProduct) => {
            const userHasRecommended = lcaProducts.find((lcaProduct: Product) => {
                const userHasProduct = recommendedProduct.id === lcaProduct.id
                const userHasCategory = recommendedProduct.id === lcaProduct.categoryId

                return userHasProduct || userHasCategory
            })

            if (!userHasRecommended) {
                recommendedProductsOfSelectedFamilyMember.push(recommendedProduct)
            }
        })
        
        return recommendedProductsOfSelectedFamilyMember
    }
)
