import { FieldValues, UseFormRegister } from 'react-hook-form'

import { AssuraFormInputRHF } from 'modules/services/components/InputRHF/InputRHF'

import { rulesLengthAndRequired } from '../../helpers/validations'

type InputRhfProps = {
    id: string
    name: string
    label: string
    placeholder: string
    inlineLabel: boolean
    errors: string
    isInvalid: boolean
    minValue: number
    maxValue: number
    lengthCheckMessage: string
    mandatoryCheckMessage: string
    register: UseFormRegister<FieldValues>
}

const InputRhf = ({
    id,
    name,
    label,
    placeholder,
    inlineLabel,
    minValue,
    maxValue,
    lengthCheckMessage,
    mandatoryCheckMessage,
    errors,
    register,
    isInvalid
}: InputRhfProps) => {
    return (
        <AssuraFormInputRHF
            id={id}
            name={name}
            label={label}
            placeHolder={placeholder}
            inline={inlineLabel}
            labelSize={4}
            register={register}
            rules={rulesLengthAndRequired(
                minValue,
                maxValue,
                lengthCheckMessage,
                mandatoryCheckMessage
            )}
            autocomplete="off"
            isInvalid={isInvalid}
            error={errors}
            hasButton={false}
            onButtonClick={() => void 0}
            iconClass="icon assura-close size-24"
            readOnly={false}
        />
    )
}
export default InputRhf
