// https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/

export const openInNewTab = async (url: string): Promise<void> => {
    if (process.env.REACT_APP_TEST_E2E?.toString() === 'TRUE') {
        window.open(url, '_blank')
        return
    }

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
