import '../PickerDay/PickerDay.css'

import DatePicker from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form/dist/types'
import { useSelector } from 'react-redux'

import { defaultDateFormat, defaultTimeFormat } from 'core/helpers/DateFormatHelper'
import locales from 'core/services/locales'
import DatePickerRHF from 'modules/services/components/DatePicker/DatePickerRHF/DatePickerRHF'
import { getLang } from 'shared/store/general/general.slice'

import PickerDayWithYearHeader from './PickerDayWithYearHeader'

interface PickerDayWithYearProps<T extends FieldValues> {
    placeholder?: string
    testId: string
    control: Control<T>
    name: Path<T>
    rules?: RegisterOptions
    minDate?: Date | null
    maxDate?: Date | null
    addFuturYearHeadroomCount?: number
    register?: UseFormRegister<FieldValues>
    onDateChange?: (date: Date | [Date | null, Date | null] | null) => void
    showTimeSelect?: boolean
    showTimeSelectOnly?: boolean
    onFocus?: (event: FocusEvent) => void
}

const PickerDayWithYear = <T extends FieldValues>({
    placeholder,
    testId,
    control,
    name,
    rules,
    minDate,
    maxDate,
    // In the header year dropdown, it adds years to the list on the top.
    addFuturYearHeadroomCount,
    register,
    showTimeSelect = false,
    showTimeSelectOnly = false,
    onDateChange,
    onFocus
}: PickerDayWithYearProps<T>): JSX.Element => {
    const lang = useSelector(getLang)

    const registerComponent = name !== '' && register ? { ...register?.(name) } : {}

    const handleOnDateChanged = (date: Date | [Date | null, Date | null] | null) => {
        onDateChange && onDateChange(date)
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState }) => (
                <DatePicker
                    selected={field.value as Date | null | undefined}
                    onChange={(date) => {
                        field.onChange(date)
                        handleOnDateChanged(date)
                    }}
                    customInput={
                        <DatePickerRHF
                            placeholderText={placeholder}
                            dataTestId={testId}
                            hasError={!!fieldState.error?.message}
                            errorMessage={fieldState.error?.message}
                            type={showTimeSelect ? 'time' : 'date'}
                        />
                    }
                    popperModifiers={[
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                                tether: false,
                                altAxis: true
                            }
                        }
                    ]}
                    {...registerComponent}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    locale={locales.get(lang)}
                    timeIntervals={5}
                    minDate={minDate}
                    maxDate={maxDate}
                    timeCaption="Time"
                    onFocus={() => onFocus}
                    dateFormat={showTimeSelect ? defaultTimeFormat : defaultDateFormat}
                    renderCustomHeader={(params) => (
                        <PickerDayWithYearHeader
                            addFuturYearHeadroomCount={addFuturYearHeadroomCount}
                            {...params}
                        />
                    )}
                    popperPlacement="bottom"
                />
            )}
            rules={rules}
        />
    )
}

export default PickerDayWithYear
