import { createSelector } from '@reduxjs/toolkit'

import { getFamilyDoctors } from '../../services/doctors/doctors.slice'
import { getSelectedMemberPolicyNumber } from '../../services/services.slice'

export const getDoctorsOfSelectedFamilyMember = createSelector(
    [getFamilyDoctors, getSelectedMemberPolicyNumber],
    (familyDoctors, selectedMemberPolicyNumber) => {
        if (selectedMemberPolicyNumber) {
            return familyDoctors[selectedMemberPolicyNumber] ?? []
        } else {
            return []
        }
    }
)
