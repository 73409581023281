import './WebsiteSuccess.css'

import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import useQuery from 'core/services/useQuery'
import { openInNewTab } from 'core/utils/onClickUtils'
import validatedIcon from 'shared/assets/images/validated.png'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getRedirectionParameters } from 'shared/store/general/general.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

enum SuccessContent {
    DOWNLOAD = 'download'
}
const getContent = (): JSX.Element => {
    const { t } = useTranslation()
    const contentQuery = useQuery().get('content')

    const goToServices = () => {
        openInNewTab(process.env.REACT_APP_FRONT as string)
    }
    return contentQuery === SuccessContent.DOWNLOAD ? (
        <>
            <div className="headlineMedium m-top-40">{t('SERVICES.DOCUMENT_DOWNLOADED')}</div>
        </>
    ) : (
        <>
            <div className="headlineMedium m-top-40">{t('SERVICES.SUBMISSION_SUCCESS')}</div>
            <div className="paragraphLight m-top-16 m-bottom-24">
                {t('SERVICES.SUBMISSION_SUCCESS_DELAY')}
            </div>
            <div
                className="assura-link-default paragraphSmallLight c-primary"
                onClick={goToServices}
                data-testid="website-success-go-to-services"
            >
                {t('SERVICES.SEE_PENDING_REQUESTS_EC')}
            </div>
        </>
    )
}
const WebsiteSuccess = (): JSX.Element => {
    const { t } = useTranslation()
    const redirectionParameters = useSelector(getRedirectionParameters)
    const goBackServices = () => {
        window.location.href = redirectionParameters.origin
    }
    return (
        <FullScreenContainer color="gray20" complementaryClasses="d-flex justify-content-center">
            <Container className="d-flex justify-content-center">
                <div className="website-success-info-container p-top-20 p-bottom-20">
                    <img
                        src={validatedIcon}
                        className="website-success-icon"
                        alt="website-success-icon"
                    />
                    {getContent()}
                    <div className="m-top-40">
                        <AssuraButton
                            text={t('SERVICES.GO_BACK_SERVICES')}
                            id="website-success-go-back-services"
                            variant="primary"
                            onClick={goBackServices}
                        />
                    </div>
                </div>
            </Container>
        </FullScreenContainer>
    )
}
export default withAITracking(
    reactPlugin,
    WebsiteSuccess,
    'WebsiteSuccess',
    'website-success-container'
)
