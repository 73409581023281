export const DocumentsGroupEnum = {
    INVOICES: 'invoices',
    REFUNDS: 'refunds',
    CORRESPONDENCE: 'correspondence',
    CONTRACTUAL: 'contractual'
} as const

export const DocumentPaymentStatusEnum = {
    CREDIT: 'credit',
    DEBIT: 'debit'
} as const

export const PaymentStatusEnum = {
    PAID: 'INBOX.PAYMENT_STATUS_PAID',
    UNPAID: 'INBOX.DOCUMENT_NOT_FULLY_PAID',
    CANCELED: 'INBOX.PAYMENT_STATUS_CANCELED',
} as const

export const FetchDocumentsTypeEnum = {
    INIT: 'init',
    FILTERING: 'filtering',
    PAGINATING: 'paginating'
} as const

export const PaymentTypeEnum = {
    INVOICE: 'invoice',
    REFUND: 'refund',
    CANCELED: 'canceled'
} as const
