import { useFormContext } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'

import PickerDay from 'modules/services/components/DatePicker/PickerDay/PickerDay'

import FormBlockWithLabel from '../FormBlockWithLabel/FormBlockWithLabel'

interface StartDateProps {
    name: string
    minDate?: Date | null
    maxDate?: Date | null
    rules?: RegisterOptions
    testId: string
}
const StartDate = ({ name, minDate, maxDate, rules, testId }: StartDateProps): JSX.Element => {
    const { t } = useTranslation()

    const { control } = useFormContext()
    return (
        <div className="border-solid-width-1 bg-white bc-gray100 m-top-32 p-32">
            <FormBlockWithLabel label={t('SERVICES.START_DATE')} isFirstChildInput>
                <PickerDay
                    placeholder={t('SERVICES.SELECT_A_DATE')}
                    testId={testId}
                    control={control}
                    name={name}
                    minDate={minDate}
                    maxDate={maxDate}
                    rules={rules}
                />
            </FormBlockWithLabel>
        </div>
    )
}

export default StartDate
