import { useTranslation } from 'react-i18next'

import AssuraEcLinkMarkdown from 'shared/components/AssuraEcLinkMarkdown/AssuraEcLinkMarkdown'

const TripCancellationHeader = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="paragraphSmallLight">
            <AssuraEcLinkMarkdown
                message={t('SERVICES.TRIP_CANCELLATION_HEADER_1', { ecLinkId: '64a2d688f63b08dfe70a1ff2' })}
                testId="trip-cancellation-header-link"
            />
            <div className="m-top-20">{t('SERVICES.TRIP_CANCELLATION_HEADER_2')}</div>
        </div>
    )
}

export default TripCancellationHeader
