import { useTranslation } from 'react-i18next'

import { ClaimsSettlementTypeEnum } from 'core/enums/ClaimsSettlementTypeEnum'
import { getCostsLabels, getReceiptCostsValues } from 'core/helpers/ClaimsSettlementPanelHelper'
import { formatDate } from 'core/helpers/DateFormatHelper'
import { ClaimsSettlementReceipt } from 'core/models/documents/ClaimsSettlementReceipt'
import useWindowSize from 'core/services/useWindowSize'
import { setSelectedReceiptIndex } from 'shared/store/claimsSettlement/claimsSettlement.slice'
import { useAppDispatch } from 'shared/store/store'

import ClaimsSettlementPanelReceiptLabel from './ClaimsSettlementPanelReceiptLabel'

interface ClaimsSettlementPanelReceiptItemProps {
    index: number
    receipt: ClaimsSettlementReceipt
}

const ClaimsSettlementPanelReceiptItem = ({
    index,
    receipt
}: ClaimsSettlementPanelReceiptItemProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { isXSD } = useWindowSize()
    const flexColumn = 'flex-column'

    const isRefund = receipt.type === ClaimsSettlementTypeEnum.REFUND
    const costsLabels = getCostsLabels(receipt.type, true)
    const receiptCostsValues = getReceiptCostsValues(costsLabels, receipt)
    const shouldDisplayDetail =
        receipt.totalCosts && receiptCostsValues.assuraCosts.value !== undefined

    const getTreatmentDate = (startDate: string, endDate: string): string => {
        const treatmentStartDateFormatted = formatDate(startDate)
        const treatmentEndDateFormatted = formatDate(endDate)
        return treatmentStartDateFormatted === treatmentEndDateFormatted
            ? t('CLAIMS_SETTLEMENT.RECEIPT_TREATMENT_DAY', {
                  treatmentStartDate: treatmentStartDateFormatted
              })
            : t('CLAIMS_SETTLEMENT.RECEIPT_TREATMENT_PERIOD', {
                  treatmentStartDate: treatmentStartDateFormatted,
                  treatmentEndDate: treatmentEndDateFormatted
              })
    }

    return (
        <div
            className="bg-white m-top-16 border-top-solid-width-1 border-bottom-solid-width-1 bc-gray100 cursor-pointer"
            data-testid={`claims-settlement-panel-receipt-item-${index}`}
            onClick={() => dispatch(setSelectedReceiptIndex(index))}
        >
            <div className="claims-settlement-panel-adaptive-padding-horizontal d-flex align-items-center p-top-16 p-bottom-16">
                <div className="flex-1">
                    <div className="labelMedium">{receipt.title}</div>
                    {receipt.treatmentStartDate && receipt.treatmentEndDate && (
                        <div className="labelExtraSmall c-gray500 m-top-4">
                            {getTreatmentDate(receipt.treatmentStartDate, receipt.treatmentEndDate)}
                        </div>
                    )}
                </div>
                <i className="icon assura-right size-16 c-gray300 m-left-16" />
            </div>
            {shouldDisplayDetail && (
                <div
                    className={`claims-settlement-panel-adaptive-padding-horizontal d-flex border-top-solid-width-1 bc-gray100 p-top-16 p-bottom-16 ${
                        isXSD ? flexColumn : ''
                    }`}
                >
                    <ClaimsSettlementPanelReceiptLabel
                        cost={receipt.totalCosts}
                        label="CLAIMS_SETTLEMENT.RECEIPT_TOTAL_COSTS"
                        labelClassNames={
                            isXSD ? 'align-items-center justify-content-between' : flexColumn
                        }
                    />
                    <ClaimsSettlementPanelReceiptLabel
                        cost={receiptCostsValues.assuraCosts.value as number} //validated through shouldDisplayDetail
                        label={receiptCostsValues.assuraCosts.label}
                        shouldDisplayTypeIndicator={isRefund}
                        isRefund={isRefund}
                        labelClassNames={
                            isXSD
                                ? 'align-items-center justify-content-between m-top-8'
                                : flexColumn
                        }
                    />
                    <ClaimsSettlementPanelReceiptLabel
                        cost={receiptCostsValues.insuredCosts.value}
                        label={receiptCostsValues.insuredCosts.label}
                        shouldDisplayTypeIndicator={
                            receipt.type === ClaimsSettlementTypeEnum.INVOICE
                        }
                        isRefund={isRefund}
                        labelClassNames={
                            isXSD
                                ? 'align-items-center justify-content-between m-top-8'
                                : 'flex-column align-items-end'
                        }
                    />
                </div>
            )}
        </div>
    )
}

export default ClaimsSettlementPanelReceiptItem
