import './DeleteAccountDisclaimer.css'

import { useTranslation } from 'react-i18next'

const DeleteAccountDisclaimer = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div
            className="delete-account-disclaimer-container"
            data-testid="delete-account-disclaimer"
        >
            <span className="paragraphMedium m-bottom-24">
                {t('DELETE_ACCOUNT.DISCLAIMER_TITLE')}
            </span>
            <ul className="delete-account-disclaimer-list paragraphSmallLight">
                <li>{t('DELETE_ACCOUNT.DISCLAIMER_TEXT_1')}</li>
                <li>{t('DELETE_ACCOUNT.DISCLAIMER_TEXT_2')}</li>
            </ul>
        </div>
    )
}

export default DeleteAccountDisclaimer
