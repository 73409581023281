import './LcaCardLight.css'

import { Product } from 'core/models/Product'

interface LcaCardProps {
    product: Product
    productCount: number
}

const LcaCardLight = (props: LcaCardProps) => {
    const { product, productCount } = props
    return (
        <div
            className={`lca-card-light-container btc-gray100`}
            style={{
                height:
                    productCount === 1
                        ? 'auto'
                        : productCount === 2
                        ? 'calc(100% / 2)'
                        : productCount === 3
                        ? '95px'
                        : '95.33px'
            }}
        >
            {product.iconPath ? (
                <img
                    className="lca-icon"
                    src={`${process.env.REACT_APP_CMS_ASSET}${product.iconPath} `}
                    alt={'icon'}
                />
            ) : (
                <div className="no-icon" />
            )}

            <div className="text-container">
                <span className="labelExtraSmall category-title c-gray500">
                    {product.categoryTitle}
                </span>
                <span
                    className={
                        productCount === 1 ? 'headlineSmallMedium text-center' : 'labelSmallMedium'
                    }
                >
                    {product.title}
                </span>
            </div>
        </div>
    )
}

export default LcaCardLight
