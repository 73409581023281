import { Col, Row } from 'react-bootstrap'

import concatClassNames from 'core/utils/classNameUtils'

interface LandingPageBlockProps {
    children: React.ReactNode
    classNames?: string
    isTransparent?: boolean
}

const LandingPageBlock = ({
    children,
    classNames,
    isTransparent
}: LandingPageBlockProps): JSX.Element => {
    const backgroundColor = isTransparent
        ? undefined
        : 'bg-white border-solid-width-1 border-solid-width-1 bc-gray100'
    const classes = concatClassNames(['d-flex flex-column', backgroundColor, classNames])

    return (
        <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                <div className={classes}>{children}</div>
            </Col>
        </Row>
    )
}

export default LandingPageBlock
