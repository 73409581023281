import { AssuraDocument } from 'core/models/AssuraDocument'
import { InvoiceCheckingDetails } from 'core/models/invoicesToCheck/InvoicesToCheckModels'
import AssuraPanelHeader from 'shared/components/AssuraPanelHeader/AssuraPanelHeader'
import { usePanelDatasContext } from 'shared/contexts/PanelDatasContext'

import { ToolbarPlugin } from '@react-pdf-viewer/toolbar'

import InvoicesToCheckPanelHeaderCenter from './InvoicesToCheckPanelHeaderCenter'
import InvoicesToCheckPanelHeaderLeft from './InvoicesToCheckPanelHeaderLeft'
import InvoicesToCheckPanelHeaderRight from './InvoicesToCheckPanelHeaderRight'

interface InvoicesToCheckPanelHeaderProps {
    selectedInvoice?: InvoiceCheckingDetails
    closePanel: () => void
    toolbarPluginInstance: ToolbarPlugin
    statusIsOk: boolean
    doc?: AssuraDocument
}

const InvoicesToCheckPanelHeader = ({
    selectedInvoice,
    closePanel,
    toolbarPluginInstance,
    statusIsOk,
    doc
}: InvoicesToCheckPanelHeaderProps): JSX.Element => {
    const { hasPDFPanel, hasLeftPanel } = usePanelDatasContext().panelDatas

    return (
        <AssuraPanelHeader
            leftBlock={
                <InvoicesToCheckPanelHeaderLeft
                    insuredPersonName={selectedInvoice?.insuredPersonName}
                    insuredPersonLastName={selectedInvoice?.insuredPersonLastName}
                    statusIsOk={statusIsOk}
                    toolbarPluginInstance={toolbarPluginInstance}
                />
            }
            centerBlock={<InvoicesToCheckPanelHeaderCenter selectedInvoice={selectedInvoice} />}
            rightBlock={
                <InvoicesToCheckPanelHeaderRight
                    toolbarPluginInstance={toolbarPluginInstance}
                    statusIsOk={statusIsOk}
                    doc={doc}
                />
            }
            closePanel={closePanel}
            forceSmallSideBlocks={hasLeftPanel && !hasPDFPanel}
        />
    )
}

export default InvoicesToCheckPanelHeader
