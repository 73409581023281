import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { DroppedFile } from 'core/models/enveloppes/DroppedFile'
import useWindowSize from 'core/services/useWindowSize'

import EnveloppesBackToScanItem from '../EnveloppesBackToScanItem/EnveloppesBackToScanItem'
import BackToScanContainerDesktop from './BackToScanContainerDesktop'
import BackToScanContainerMobile from './BackToScanContainerMobile'

const EnveloppesBackToScanContainer = (): JSX.Element => {
    const { watch } = useFormContext()
    const { isMobile } = useWindowSize()
    const documents = watch('documents') as DroppedFile[]

    const [items, setItems] = useState<JSX.Element[]>([])

    useEffect(() => {
        const currentItems = documents.map((doc, index) => (
            <EnveloppesBackToScanItem
                document={doc}
                index={index}
                key={`enveloppesBackToScanItem-${doc.id}`}
            />
        ))
        setItems(currentItems)
    }, [documents])

    return isMobile ? (
        <BackToScanContainerMobile items={items} />
    ) : (
        <BackToScanContainerDesktop items={items} />
    )
}

export default EnveloppesBackToScanContainer
