/* eslint-disable sonarjs/no-nested-template-literals */
import './PersonalDataValidation.css'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ExtraRHFRef } from 'core/models/services/RHF'
import SubmitButtonWithScroll from 'modules/services/components/SubmitButtonWithScroll/SubmitButtonWithScroll'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { getServiceSubmitStatus } from 'shared/store/services/services.slice'

import { PersonalDataForm, usePersonalDataContext } from '../PersonalDataBlock/PersonalDataContext'

interface PersonalDataValidationProps {
    form: PersonalDataForm
    onSubmit: () => void
    classNames?: string
    extraRhfRefs?: ExtraRHFRef[]
}

const PersonalDataValidation = ({
    form,
    onSubmit,
    classNames,
    extraRhfRefs
}: PersonalDataValidationProps): JSX.Element | null => {
    const { t } = useTranslation()
    const { handleCancel, isFormSelected } = usePersonalDataContext()

    const submitServiceStatus = useSelector(getServiceSubmitStatus)

    return isFormSelected(form) ? (
        <div className={`personal-data-validation-container${classNames ? ` ${classNames}` : ''}`}>
            <AssuraButton
                id={`personal-data-${form}-cancel`}
                onClick={() => handleCancel()}
                text={t('COMMON.CANCEL')}
                variant="secondary"
                disabled={submitServiceStatus === LoadingStatusEnum.LOADING}
            />
            <SubmitButtonWithScroll
                id={`personal-data-${form}-validate`}
                onSubmit={onSubmit}
                hasLoader={submitServiceStatus === LoadingStatusEnum.LOADING}
                extraRhfRefs={extraRhfRefs}
            />
        </div>
    ) : null
}

export default PersonalDataValidation
