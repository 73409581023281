import './FamilyGroupSettings.css'

import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getFamilyGroupSettings } from 'shared/store/selectors/getFamilyGroupSettings'

import SettingsAddCard from '../SettingsAddCard/SettingsAddCard'
import SettingsCard from '../SettingsCard/SettingsCard'

const FamilyGroupSettings = (): JSX.Element => {
    const { t } = useTranslation()

    const familyGroupSettings = useSelector(getFamilyGroupSettings)

    const getCardCol = (index: string, children: React.ReactNode): JSX.Element => (
        <Col xs={12} md={6} xl={3} className="m-top-32" key={`settings-card-${index}`}>
            {children}
        </Col>
    )

    return (
        <>
            <Container className="settings-family-group-container">
                <Row>
                    <Col
                        xs={12}
                        className="headlineLarge"
                        data-testid="settings-family-group-title"
                    >
                        {t('SETTINGS.TITLE')}
                    </Col>
                    {familyGroupSettings.length < 2 && (
                        <Col xs={12} md={9} xl={6} className="paragraphLight m-top-16">
                            {t('SETTINGS.SUBTITLE_ONE_MEMBER')}
                        </Col>
                    )}
                </Row>
                <Row className="settings-family-group-list">
                    {familyGroupSettings.map((personSettings, index) =>
                        getCardCol(
                            `${index}`,
                            <SettingsCard id={index} settings={personSettings} />
                        )
                    )}
                    {getCardCol('add', <SettingsAddCard />)}
                </Row>
            </Container>
        </>
    )
}

export default FamilyGroupSettings
