import axios from 'axios'
import { PaymentChangeRequestBody } from 'core/models/services/financial/PaymentChange'
import {
    PaymentFrequencyRequestBody,
    Periodicity
} from 'core/models/services/financial/PaymentFrequency'
import { FinancialFetchResponse } from 'core/models/services/personalData/Financial'

export const fetchFinancialApi = async (policyNumber: number): Promise<FinancialFetchResponse> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/financialdata?policyNumber=${policyNumber}`
    )

    return result.data
}

export const postPaymentChangeApi = async (requestBody: PaymentChangeRequestBody): Promise<void> =>
    axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/financialdataupdate`,
        requestBody
    )

export const fetchPeriodicityApi = async (policyNumber: number): Promise<Periodicity[]> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/periodicityavailable/${policyNumber}`
    )

    return result.data
}

export const postPaymentFrequencyApi = async (
    requestBody: PaymentFrequencyRequestBody
): Promise<void> =>
    await axios.post(
        `${process.env.REACT_APP_API_COMMON}/api/v2/FamilyGroup/periodicity`,
        requestBody
    )
