import './SideNavigationMobileHeader.css'

import { useEffect } from 'react'
import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom'

import { SideNavigationItemId, SideNavigationList } from 'core/models/SideNavigation'

import PageHeader from '../PageHeader/PageHeader'
import { useSideNavigationContext } from './SideNavigationProvider'

interface SideNavigationMobileHeaderProps<T extends SideNavigationItemId> {
    items: SideNavigationList<T>
}

const SideNavigationMobileHeader = <T extends SideNavigationItemId>({
    items
}: SideNavigationMobileHeaderProps<T>): JSX.Element | null => {
    const navigate = useNavigate()
    const navType = useNavigationType()

    const { selectedId, isMobileContentSelection, resetSelectedId, defaultUrl } =
        useSideNavigationContext()

    const handleBackButton = () => {
        resetSelectedId()
    }

    const title = items.get(selectedId as T)?.labelKey ?? ''

    useEffect(() => {
        if (isMobileContentSelection)
            return () => {
                if (navType === NavigationType.Pop && isMobileContentSelection) {
                    navigate(defaultUrl)
                }
            }
    }, [history, isMobileContentSelection])

    return isMobileContentSelection ? (
        <PageHeader
            titleKey={title}
            testId="side-navigation-mobile"
            backAction={handleBackButton}
            classNames="side-navigation-mobile-header"
        />
    ) : null
}

export default SideNavigationMobileHeader
