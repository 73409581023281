import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { animationDuration } from 'core/helpers/ClaimsSettlementPanelHelper'
import { TextProgressOptions } from 'core/models/AssuraCircularProgress'

interface TextProgressProps {
    options: TextProgressOptions
}

const TextProgress = ({ options }: TextProgressProps) => {
    const { t } = useTranslation()

    const [counter, setCounter] = useState<number>(0)

    useEffect(() => {
        const max = options.percentage
        let currentCount = 0
        if (max > 0) {
            const counterIncrement = setInterval(() => {
                currentCount++
                if (currentCount >= max) {
                    clearInterval(counterIncrement)
                }
                setCounter(currentCount)
            }, (animationDuration * 1000) / max) //increment value during the animationDuration of the first pie chart's segment
            return () => clearInterval(counterIncrement)
        }
    }, [options.percentage])

    return (
        <div className="assura-circular-text-progress-container p-left-20 p-right-20 d-flex flex-column justify-content-center align-items-center">
            <div className="headlineMedium" data-testid="text-progress-counter">
                {counter}%
            </div>
            <div className="labelSmall c-gray500" data-testid="text-progress-label">
                {t(options.label)}
            </div>
        </div>
    )
}
export default TextProgress
