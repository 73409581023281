import './Env.css'

import { ServicesSource, Source } from 'core/enums/ServicesSource'

import EnvBox from './EnvBox'

export const defaultEnvContainerId = 'env-box'

export const Env = (): JSX.Element | null => {
    const isFromBackoffice = Source() === ServicesSource.BACKOFFICE

    return process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD' || isFromBackoffice ? (
        <div id={defaultEnvContainerId} className="env-box" data-testid={defaultEnvContainerId}>
            <EnvBox />
        </div>
    ) : null
}
