import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ServicesSource, Source } from 'core/enums/ServicesSource'
import checkIban from 'core/helpers/services/IbanHelper'
import InputWithHelperRHF, { InputHelperValidation } from 'modules/services/components/InputWithHelperRHF/InputWithHelperRHF'

const PaymentChangeNumber = (): JSX.Element => {
    const { t } = useTranslation()
    const { setValue, watch } = useFormContext()

    const isPublic = Source() === ServicesSource.WEBSITE

    const [ibanValidations, setIbanValidations] = useState<Record<string, InputHelperValidation>>({
        country: {
            text: t(
                isPublic
                    ? 'PERSONAL_DATA.FINANCIAL_IBAN_RULE_START_PUBLIC'
                    : 'PERSONAL_DATA.FINANCIAL_IBAN_RULE_START'
            ),
            valid: false
        },
        length: {
            text: t('PERSONAL_DATA.FINANCIAL_IBAN_RULE_LENGTH'),
            valid: false
        },
        format: {
            text: t('PERSONAL_DATA.FINANCIAL_IBAN_RULE_FORMAT'),
            valid: false
        }
    })

    const ibanValue: string | null = watch('iban')

    useEffect(() => {
        const ibanState = checkIban(ibanValue, isPublic)
        setIbanValidations(
            Object.keys(ibanValidations).reduce<Record<string, InputHelperValidation>>(
                (acc, key) => {
                    acc[key] = {
                        ...ibanValidations[key],
                        valid: ibanState.rules.get(key) ?? false
                    }
                    return acc
                },
                {}
            )
        )
        if (Array.from(ibanState.rules.values()).some(Boolean)) setValue('iban', ibanState.value)
    }, [ibanValue])

    return (
        <Row>
            <Col>
                <InputWithHelperRHF
                    name="iban"
                    label={t('PERSONAL_DATA.FINANCIAL_ACCOUNT_IBAN_NUMBER')}
                    placeHolder="EX : CH56 0483 5012 3456 7800 9"
                    testId="payment-change-number-iban"
                    inputHelper={{
                        title: t('PERSONAL_DATA.FINANCIAL_IBAN_RULE_MESSAGE'),
                        validations: ibanValidations
                    }}
                    rules={{
                        required: {
                            value: true,
                            message: t('COMMON.MANDATORY_FIELD')
                        },
                        validate: () =>
                            Object.keys(ibanValidations)
                                .reduce<boolean[]>((acc, key) => {
                                    acc.push(ibanValidations[key].valid)
                                    return acc
                                }, [])
                                .every(Boolean) ||
                            (t('PERSONAL_DATA.FINANCIAL_IBAN_ERROR') as string)
                    }}
                />
            </Col>
        </Row>
    )
}

export default PaymentChangeNumber
