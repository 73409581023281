import { useDispatch, useSelector } from 'react-redux'

import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { ServiceGoodToKnowLinkType } from 'core/enums/ServiceGoodToKnowLinkType'
import { ServiceType } from 'core/enums/ServiceType'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { GoodToKnow } from 'core/models/services/GoodToKnow'
import { openInNewTab } from 'core/utils/onClickUtils'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import { getLang } from 'shared/store/general/general.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'
import { goToServicesForm } from 'shared/store/services/services.slice'

import GoodToKnowNeedHelp, { GoodToKnowNeedHelpProps } from './GoodToKnowNeedHelp'

interface GoodToKnowBodyProps {
    current: GoodToKnow
    needHelpProps?: GoodToKnowNeedHelpProps
    ctaTranslationKey?: string
}

const GoodToKnowBody = ({ current, needHelpProps = {} }: GoodToKnowBodyProps): JSX.Element => {
    const dispatch = useDispatch()

    const lang = useSelector(getLang) as keyof AssetUrlCms
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms
    const assets = useSelector(getAssets)

    const hasGoodToKnowLink = current.goodToKnowLinkLabel && current.goodToKnowLinkValue
    const hasDidYouKnowLink = current.didYouKnowLinkLabel && current.didYouKnowLinkValue
    const hasDidYouKnowBloc =
        current.didYouKnowTitle || current.didYouKnowDescription || hasDidYouKnowLink
    const hasBulletPointsAdditionalPoints =
        current.bulletPointsAdditionalPoints && current.bulletPointsAdditionalPoints?.length > 0
    const hasBulletPointsAdditionalPointsBloc =
        current.bulletPointsTitle ||
        current.bulletPointsDescription ||
        hasBulletPointsAdditionalPoints

    const handleClick = (
        linkType: ServiceGoodToKnowLinkType | null,
        linkValue: string | null
    ): void => {
        switch (linkType) {
            case ServiceGoodToKnowLinkType.WEBFORM:
                dispatch(goToServicesForm({ type: linkValue as ServiceType }))
                break
            case ServiceGoodToKnowLinkType.URL:
                if (linkValue) {
                    openInNewTab(linkValue)
                }
                break
            case ServiceGoodToKnowLinkType.ASSET:
                if (linkValue) {
                    const asset = assets[linkValue]
                    const assetUrl =
                        lang === ENGLISH_LANGUAGE && !asset.en ? asset[contactLang] : asset[lang]
                    if (assetUrl) {
                        openInNewTab(assetUrl)
                    }
                }
                break
            default:
                break
        }
    }

    return (
        <>
            {hasGoodToKnowLink && (
                <div
                    className="d-flex justify-content-start m-top-24"
                    data-testid="good-to-know-good-to-know-link"
                >
                    <AssuraIconButton
                        onClick={() =>
                            handleClick(current.goodToKnowLinkType, current.goodToKnowLinkValue)
                        }
                        id={`good-to-know-link-${current.goodToKnowId}`}
                        size="medium"
                        variant="primary"
                        icon={`assura-${current.goodToKnowLinkIcon}`}
                        label={current.goodToKnowLinkLabel as string}
                    />
                </div>
            )}
            {hasDidYouKnowBloc && (
                <>
                    <div className="good-to-know-separator horizontal-separator bg-gray100" />
                    {current.didYouKnowTitle && (
                        <div className="paragraphMedium m-top-24">{current.didYouKnowTitle}</div>
                    )}
                    {current.didYouKnowDescription && (
                        <div className="paragraphSmallLight m-top-24">
                            {current.didYouKnowDescription}
                        </div>
                    )}
                    {hasDidYouKnowLink && (
                        <div
                            className="d-flex justify-content-start m-top-24"
                            data-testid="good-to-know-did-you-know-link"
                        >
                            <AssuraIconButton
                                onClick={() =>
                                    handleClick(
                                        current.didYouKnowLinkType,
                                        current.didYouKnowLinkValue
                                    )
                                }
                                id={`did-you-know-link-${current.goodToKnowId}`}
                                size="medium"
                                variant="primary"
                                icon={`assura-${current.didYouKnowLinkIcon}`}
                                label={current.didYouKnowLinkLabel as string}
                            />
                        </div>
                    )}
                </>
            )}
            {hasBulletPointsAdditionalPointsBloc && (
                <>
                    <div className="good-to-know-separator horizontal-separator bg-gray100" />
                    {current.bulletPointsTitle && (
                        <div className="paragraphMedium m-top-24">{current.bulletPointsTitle}</div>
                    )}
                    {current.bulletPointsDescription && (
                        <div className="paragraphSmallLight m-top-24">
                            {current.bulletPointsDescription}
                        </div>
                    )}
                    {hasBulletPointsAdditionalPoints && (
                        <div
                            className="d-flex flex-column m-top-8"
                            data-testid="good-to-know-bullet-points-additional-points"
                        >
                            {current.bulletPointsAdditionalPoints?.map((point, index) => (
                                <div className="d-flex m-top-16" key={index.toString()}>
                                    <i className="icon assura-check size-24 c-primary" />
                                    <div className="paragraphSmall m-left-12">{point.value}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            <GoodToKnowNeedHelp {...needHelpProps} />
        </>
    )
}

export default GoodToKnowBody
