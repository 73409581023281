import { useSelector } from 'react-redux'

import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { getLang } from 'shared/store/general/general.slice'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'

import AssuraNewTabLink, { AssuraNewTabLinkProps } from '../AssuraNewTabLink/AssuraNewTabLink'

interface AssuraCmsLinkProps extends Omit<AssuraNewTabLinkProps, 'url'> {
    asset: AssetUrlCms
}

const AssuraCmsLink = ({ asset, ...rest }: AssuraCmsLinkProps): JSX.Element => {
    const lang = useSelector(getLang) as keyof AssetUrlCms
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms

    if (asset) {
        const cmsLink = (function () {
            if (lang === ENGLISH_LANGUAGE && !asset.en) return asset[contactLang] || undefined
            return asset[lang] || undefined
        })()

        if (cmsLink) {
            return <AssuraNewTabLink url={cmsLink} {...rest} />
        }
    }

    return <div className={rest.classNames}>{rest.title}</div>
}

export default AssuraCmsLink
