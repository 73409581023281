import axios from 'axios'
import { OldEnveloppe } from 'core/models/enveloppes/Enveloppe'
import { EnveloppeHandleError } from 'core/models/enveloppes/EnveloppeHandleError'
import { DataUploadEnveloppe } from 'shared/store/enveloppes/enveloppes.slice'

export const fetchEnveloppes = async (): Promise<OldEnveloppe[]> => {
    const result = await axios.get(`${process.env.REACT_APP_API_IMAGE}/v4/Enveloppes`)

    return result.data
}

export const deleteEnveloppe = async (batchNumber: string): Promise<EnveloppeHandleError> => {
    const result = await axios.put(
        `${process.env.REACT_APP_API_IMAGE}/v4/Enveloppes/${batchNumber}/HandleError`
    )
    return result.data
}

export const uploadEnveloppe = async (enveloppe: DataUploadEnveloppe): Promise<string> => {
    const result = await axios.post(`${process.env.REACT_APP_API_IMAGE}/v3/Enveloppes`, enveloppe)
    return result.data
}
