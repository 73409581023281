import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import { postAddMemberApi } from 'core/api/services/FamilyGroup'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { ServicesCommonRequestBody } from 'core/models/services/ServicesCommonRequestBody'
import { getServicesCommonRequestBody } from 'shared/store/selectors/getServicesCommonRequestBody'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import { onSubmitAddMember } from './familyGroup.slice'

function* onSubmitAddMemberSaga(action: ReturnType<typeof onSubmitAddMember>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.ADD_MEMBER
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))
        const datasFromForm = action.payload
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )

        yield call(postAddMemberApi, {
            ...datasFromForm,
            ...commonRequestBody
        })

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(getRequests())

        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitAddMemberSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* onSubmitAddMemberWatcher() {
    yield takeEvery(onSubmitAddMember.type, onSubmitAddMemberSaga)
}

const watchers = [fork(onSubmitAddMemberWatcher)]

export default watchers
