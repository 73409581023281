import '../components/accidentReport.css'

import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmploymentModel } from 'core/models/services/report/AccidentReportModel'
import useWindowSize from 'core/services/useWindowSize'
import StepperFooter from 'modules/services/components/Stepper/components/StepperFooter'

import {
    badDateSequenceValidation,
    dateValidation,
    isTrue
} from '../../../../core/helpers/FieldValidationHelper'
import { serviceName } from '../components/accidentReportHelpers'
import { useCustomControls } from '../components/componentsHelpers'
import FormBlock from '../components/FormBlock'

const formName = 'EMPLOYMENT'

/** this list is based on the corresponding interface and represents all the fields involved in this step */
export const employmentFormFieldNameList: Array<keyof EmploymentModel> = [
    'profession',
    'employer',
    'employerInsurance',
    'employerInsuranceKnow',
    'employerInsuranceIncidentNumber',
    'unemploymentBenefits',
    'unemploymentStartDate',
    'unemploymentEndDate'
]

export interface EmploymentFormProps {
    id: number
}

/**
 * Step2 description:
 * EMPLOYMENT
 *
 */
const EmploymentForm = ({ id }: EmploymentFormProps): JSX.Element => {
    const { getValues, setValue, resetField, trigger } = useFormContext<EmploymentModel>()

    const { TextInput, TextareaInput, RadioGroup, DatePicker } = useCustomControls(formName)
    const { t } = useTranslation()
    const { isMobile, isMD } = useWindowSize()
    const { unemploymentBenefits } = getValues()

    const employerInsuranceKnowYes = isTrue(
        getValues('employerInsuranceKnow'),
        'employerInsuranceKnow'
    )()
    const unemploymentBenefitsYes = isTrue(
        getValues('unemploymentBenefits'),
        'unemploymentBenefits'
    )()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        // Visibilité de ACC-E3
        // SI... Formulaire accident
        // ALORS... Afficher
        // SINON... formulaire à l’étranger passer cette étape

        if (!employerInsuranceKnowYes) {
            setValue('employerInsuranceIncidentNumber', undefined)
        }
        if (!unemploymentBenefitsYes) {
            resetField('unemploymentStartDate', {
                keepDirty: false,
                keepTouched: false,
                keepError: false
            })
            resetField('unemploymentEndDate', {
                keepDirty: false,
                keepTouched: false,
                keepError: false
            })
        }
    }, [employerInsuranceKnowYes, unemploymentBenefitsYes, unemploymentBenefits])

    return (
        <div id={`${id}`} className="step-frame">
            <FormBlock
                titleTestId={'employment-questions'}
                title={t(`${serviceName}.${formName}.EMPLOYMENT_QUESTION`)}
                childrenMargin="m-bottom-48"
            >
                {TextInput('profession', 'EMPLOYMENT', {
                    mandatory: true,
                    maxLength: 100
                })}
                {TextareaInput(
                    'employer',
                    'EMPLOYER',
                    {
                        mandatory: true,
                        maxLength: 400
                    },
                    false
                )}
                {TextInput('employerInsurance', 'EMPLOYER_ACCIDENT_INSURANCE', {
                    mandatory: false,
                    maxLength: 50
                })}
                {RadioGroup(
                    'IS_EMPLOYER_ACCIDENT_INSURANCE_AWARE',
                    'employerInsuranceKnow',
                    {
                        mandatory: true
                    },
                    () => trigger('employerInsuranceKnow')
                )}
                {employerInsuranceKnowYes &&
                    TextInput('employerInsuranceIncidentNumber', 'EMPLOYER_SINISTER_NUMBER', {
                        mandatory: employerInsuranceKnowYes,
                        maxLength: 50
                    })}
            </FormBlock>

            <FormBlock
                titleTestId={'unemployment-benefits-questions'}
                title={t(`${serviceName}.${formName}.UNEMPLOYMENT_BENEFITS_QUESTIONS`)}
                childrenMargin="m-bottom-48"
                frameMarginBottom="m-bottom-32"
            >
                {RadioGroup(
                    'ANY_UNEMPLOYMENT_BENEFITS_TITLE',
                    'unemploymentBenefits',
                    {
                        mandatory: true
                    },
                    () => trigger('unemploymentBenefits')
                )}
                {unemploymentBenefitsYes &&
                    DatePicker(
                        'unemploymentStartDate',
                        'UNEMPLOYED_SINCE',
                        {
                            mandatory: unemploymentBenefitsYes,
                            validate: (value: string | Date): string | boolean => {
                                const endDate = getValues('unemploymentEndDate') as Date
                                if (endDate === undefined) {
                                    return dateValidation(value as string)
                                }
                                return badDateSequenceValidation(value as Date, endDate)
                            }
                        },
                        !(isMobile || isMD),
                        () => trigger('unemploymentStartDate')
                    )}
                {unemploymentBenefitsYes &&
                    DatePicker(
                        'unemploymentEndDate',
                        'UNEMPLOYED_UNTIL',
                        {
                            mandatory: false,
                            validate: (value: string | Date): string | boolean => {
                                return badDateSequenceValidation(
                                    getValues('unemploymentStartDate') as Date,
                                    value as Date
                                )
                            }
                        },
                        !(isMobile || isMD),
                        () => trigger('unemploymentEndDate')
                    )}
            </FormBlock>

            <StepperFooter />
        </div>
    )
}
export default EmploymentForm
