import { createContext, useContext, useEffect, useState } from 'react'

import { reactPlugin } from 'AppInsights'
import { emptyFunction } from 'core/helpers/functionHelper'
import { generateGUID } from 'core/helpers/GuidHelper'
import {
    AddMemberFormProps,
    AddMemberRHFForm,
    NewMember,
    NewMemberDatas,
    NewMemberWS
} from 'core/models/services/familyGroup/AddMember'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AddMemberForm from '../components/AddMemberForm'

export const newMemberDefaultValues: AddMemberRHFForm = {
    firstName: '',
    lastName: '',
    policyNumber: '',
    consentLogin: false,
    consentAddress: false
}

const newMemberContextDefaultValues: NewMember = {
    ...newMemberDefaultValues,
    id: '',
    isValid: false
}

interface AddMemberContext {
    selectedMember: NewMemberDatas | null
    selectedMemberId: string | null
    members: NewMember[]
    isFormValid: boolean
    selectMember: (index: string) => void
    addMember: () => void
    removeMember: () => void
    updateValue: (name: keyof NewMemberDatas, value: NewMemberDatas[keyof NewMemberDatas]) => void
    selectFirstMemberInError: () => void
    getAddFamilyMembers: () => AddMemberFormProps
}

const AddMemberContext = createContext<AddMemberContext>({
    selectedMember: null,
    selectedMemberId: null,
    members: [],
    isFormValid: false,
    selectMember: emptyFunction,
    addMember: emptyFunction,
    removeMember: emptyFunction,
    updateValue: emptyFunction,
    selectFirstMemberInError: emptyFunction,
    getAddFamilyMembers: () => ({
        addFamilyMembers: []
    })
})

const AddMember = (): JSX.Element => {
    const [selectedMember, setSelectedMember] = useState<NewMember | null>(null)
    const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null)
    const [members, setMembers] = useState<NewMember[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    const selectMember = (index: string | null) => {
        setSelectedMemberId(index)
    }

    const addMember = () => {
        const currentMembers = [...members]
        const id = generateGUID()
        const newMember: NewMember = {
            ...newMemberContextDefaultValues,
            id
        }
        if (selectedMember) {
            newMember.consentLogin = selectedMember.consentLogin
            newMember.consentAddress = selectedMember.consentAddress
        }
        currentMembers.push(newMember)
        setMembers([...currentMembers])
        selectMember(id)
    }

    const removeMember = () => {
        if (selectedMemberId === null) return
        const currentMembers = [...members]
        const selectedMemberIndex = currentMembers.findIndex(
            (member) => member.id === selectedMemberId
        )
        if (selectedMemberIndex === -1) return

        currentMembers.splice(selectedMemberIndex, 1)
        setMembers([...currentMembers])
        const newSelectedMemberIndex = selectedMemberIndex > 0 ? selectedMemberIndex - 1 : 0
        selectMember(currentMembers[newSelectedMemberIndex].id)
    }

    const updateValue = (
        name: keyof NewMemberDatas,
        value: NewMemberDatas[keyof NewMemberDatas]
    ) => {
        if (selectedMemberId !== null) {
            const currentMembers = [...members]
            const currentMemberIndex = currentMembers.findIndex((m) => m.id === selectedMemberId)
            if (currentMemberIndex !== -1) {
                let updatedMember = {
                    ...currentMembers[currentMemberIndex],
                    [name]: value
                }
                updatedMember = { ...updatedMember, isValid: getValidationState(updatedMember) }
                currentMembers.splice(currentMemberIndex, 1, { ...updatedMember })
                setMembers([...currentMembers])
            }
        }
    }

    const getValidationState = (member: NewMember): boolean => {
        if (member) {
            return Object.entries(member).every(([key, value]) => {
                if (key === 'policyNumber') {
                    return value && value.length >= 4 && value.length <= 8
                } else if (key === 'firstName' || key === 'lastName') {
                    return value && value.length >= 2 && value.length <= 30
                } else {
                    return true
                }
            })
        }
        return false
    }

    const selectFirstMemberInError = () => {
        const firstMemberInError = members.find((member) => !member.isValid)
        if (firstMemberInError) {
            selectMember(firstMemberInError.id)
        }
    }

    const getAddFamilyMembers = (): AddMemberFormProps => {
        const membersList: NewMemberWS[] = members.map((member) => ({
            firstName: member.firstName,
            lastName: member.lastName,
            policyNumber: parseInt(member.policyNumber)
        }))

        return {
            addFamilyMembers: membersList
        }
    }

    useEffect(() => {
        if (members.length) setIsFormValid(members.every((member) => member.isValid))
    }, [members])

    useEffect(() => {
        if (selectedMemberId !== null) {
            const currentMember = members.find((member) => member.id === selectedMemberId)
            if (currentMember) setSelectedMember(currentMember)
        }
    }, [selectedMemberId])

    const value: AddMemberContext = {
        selectedMember,
        selectedMemberId,
        members,
        isFormValid,
        selectMember,
        addMember,
        removeMember,
        updateValue,
        selectFirstMemberInError,
        getAddFamilyMembers
    }

    return <AddMemberContext.Provider value={value}>{<AddMemberForm />}</AddMemberContext.Provider>
}

export const useAddMemberContext = (): AddMemberContext => {
    return useContext(AddMemberContext)
}

export default withAITracking(reactPlugin, AddMember, 'AddMember', 'services-form-container')
